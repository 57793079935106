import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Switch from 'src/shared/components/form-inputs/Switch';

interface Props {
  isOpen: boolean;
  toggle: Function;
  requiredName: string;
  multiplicableName: string;
}

const AttributeSettingsModal: React.FC<Props> = ({
  isOpen,
  toggle,
  requiredName,
  multiplicableName,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} size="sm">
      <ModalHeader>{t('questionnaire.attributeSettings')}</ModalHeader>
      <ModalBody>
        <Switch
          name={requiredName}
          variant="pill"
          color="info"
          label="common.required"
          className="mb-1"
        />
        <Switch name={multiplicableName} variant="pill" color="info" label="common.multiplicable" />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => toggle()}>
          {t('common.close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AttributeSettingsModal;
