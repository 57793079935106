import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Row,
  Col,
  Badge,
} from 'reactstrap';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import moment from 'moment';

import {
  getSubscribers,
  sendViberMessageToSubscribingList,
} from 'src/redux/services/subscribing-lists.service';
import {
  SendViberMessageFormModel,
  SubscriberModel,
  SubscribingListModel,
} from 'src/shared/models';
import {
  TextField,
  TextArea,
  Spinner,
  SingleDate,
  TimePicker,
  EmployeeTags,
  List,
} from 'src/shared/components';
import { viberMessageSchema } from 'src/shared/schemas/validation.schema';
import { mergeDateWithTime } from 'src/shared/utils/merge-date-time';
import addToast from 'src/shared/utils/notification.util';

import styles from 'src/views/SubscribingLists/SubscribingLists.module.scss';

const MESSAGING_PLATFORM = 'Viber';

interface Props {
  appId: number;
  list: SubscribingListModel | undefined;
  isOpen: boolean;
  toggleModal: Function;
}

const SendViberMessageModal: React.FC<Props> = ({ appId, list, isOpen, toggleModal }) => {
  const { t } = useTranslation();

  const initialValues: SendViberMessageFormModel = {
    name: list && list.name ? list.name : '',
    messgingPlatform: MESSAGING_PLATFORM,
    employeeTags: list && list.employeeTags ? list.employeeTags : [],
    message: '',
    sendDate: moment(),
    sendTime: moment(),
  };

  const [subscribers, setSubscribers] = useState<SubscriberModel[]>([]);

  useEffect(() => {
    if (!isOpen || !list || list.refreshTypeAutomatic) {
      return;
    }

    getSubscribersData();
    /* eslint-disable-next-line */
  }, [list]);

  const getSubscribersData = async () => {
    const subscribers = await getSubscribers(appId, `${list?.stringId}`, { size: 9999, page: 0 });
    setSubscribers(subscribers.content);
  };

  const sendViberMessage = async (
    values: SendViberMessageFormModel,
    { setSubmitting }: FormikHelpers<SendViberMessageFormModel>,
  ) => {
    setSubmitting(true);

    if (list) {
      await sendViberMessageToSubscribingList(appId, list.id, {
        message: values.message,
        startAt: mergeDateWithTime(values.sendDate, values.sendTime),
      });

      setSubmitting(false);

      addToast('success', `${t('subscribingLists.viberMessageScheduledMessage')}`);
      toggleModal();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={() => toggleModal()}>
      <ModalHeader>{t('subscribingLists.messageSubscribersViaViber')}</ModalHeader>

      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          ...initialValues,
        }}
        validationSchema={viberMessageSchema}
        onSubmit={sendViberMessage}
      >
        {({ isSubmitting, errors, values }) => (
          <Form>
            <ModalBody>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="name">{t('common.subscribingList')}</Label>
                    <TextField name="name" disabled />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="stringId">{t('common.messgingPlatform')}</Label>
                    <TextField name="messgingPlatform" disabled />
                  </FormGroup>
                </Col>

                <Col md={12}>
                  <FormGroup>
                    <Label for="message">{t('common.message')}</Label>
                    <TextArea
                      name="message"
                      rows="8"
                      placeholder={t('common.messagePlaceholder')}
                    />
                  </FormGroup>
                </Col>

                <Col md={12}>
                  <FormGroup>
                    <Label>{t('employee.tags')}</Label>
                    <EmployeeTags
                      employeeTags={values.employeeTags}
                      disabled
                      createPermission="employee_employee-tag-assign_create"
                    />
                  </FormGroup>
                </Col>

                {list && !list.refreshTypeAutomatic && (
                  <Col md={12} className={`${styles.subscriberField}`}>
                    <Label className={`${styles.subscriberListLabel}`}>
                      {t('subscribingLists.subscribers')} <Badge>{subscribers.length}</Badge>
                    </Label>
                    <List
                      data={subscribers}
                      fetchData={() => {}}
                      hidePager
                      totalElements={subscribers.length}
                      loaded
                      noDataLabel={t('subscribingLists.noSubscribers')}
                      fields={[
                        {
                          key: 'employee',
                          label: t('employee.employee'),
                          render: item => item.employee?.key,
                        },
                        {
                          key: 'targetMessagingPlatform',
                          label: t('common.platform'),
                          columnStyle: 'platform',
                        },
                        {
                          key: 'createdAt',
                          label: t('common.created'),
                          dateTime: true,
                        },
                      ]}
                    />
                  </Col>
                )}
              </Row>

              <div className={`${styles.viberSendFooter} mt-4`}>
                <Row>
                  <Col md={8}>
                    <FormGroup>
                      <Label for="sendDate">{t('common.sendAt')}</Label>
                      <Field component={SingleDate} id="sendDate" name="sendDate" />
                      <Field component={TimePicker} name="sendTime" />
                      {(errors.sendDate || errors.sendTime) && (
                        <div className="input-error-message">
                          {errors.sendDate || errors.sendTime}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col className="text-right pt-1">
                    <div className="mt-4" />
                    <Spinner loading={isSubmitting} className="mr-1" />
                    <Button className="mr-1" type="submit" color="primary" disabled={isSubmitting}>
                      {t('common.send')}
                    </Button>
                    <Button color="secondary" onClick={(e: React.MouseEvent) => toggleModal(e)}>
                      {t('common.cancel')}
                    </Button>
                  </Col>
                </Row>
              </div>
            </ModalBody>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default SendViberMessageModal;
