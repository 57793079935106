import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Table } from 'reactstrap';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import { FeedbackModel } from '../../../redux/actions/feedbacks.action';

interface Props extends RouteComponentProps {
  feedbacks: any;
  queryParams: any;
  pageChange: any;
  activePage: any;
}

const FeedbacksTable: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { feedbacks, queryParams, pageChange, activePage } = props;

  return (
    <Fragment>
      <div className="table-responsive">
        <Table responsive hover striped className="feedback-table">
          <thead>
            <tr>
              <th className="date">{t('common.date')}</th>
              <th className="message">{t('common.message')}</th>
            </tr>
          </thead>
          <tbody>
            {props.feedbacks.content &&
              props.feedbacks.content.map((feedback: FeedbackModel) => {
                return (
                  <tr key={feedback.id}>
                    <td>{moment(feedback.createdAt).format('LLL')}</td>
                    <td>
                      {feedback.feedbackMessage ? (
                        feedback.feedbackMessage
                      ) : (
                        <span className="font-italic">{t('feedbacks.noFeedbackMessage')}</span>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>

      <Pagination
        activePage={activePage}
        itemsCountPerPage={queryParams.size}
        totalItemsCount={feedbacks.totalElements}
        pageRangeDisplayed={10}
        onChange={pageChange}
      />
    </Fragment>
  );
};

export default withRouter(FeedbacksTable);
