import { Dispatch } from 'redux';
import { QueryParams } from '../../shared/models/query-params.model';
import {
  getTalentPoolCandidates as getTalentPoolCandidatesService,
  getTalentPoolCandidatesAnswers as getTalentPoolCandidatesAnswersService,
  getTalentPoolCandidate as getTalentPoolCandidateService,
  getTalentPoolCandidateAnswers as getTalentPoolCandidateAnswersService,
  updateTalentPoolCandidate as updateTalentPoolCandidateService,
  deleteTalentPoolCandidate as deleteTalentPoolCandidateService,
  markTalentPoolCandidate as markTalentPoolCandidateService,
} from '../services/talent-pools.service';

export const GET_TALENT_POOL_CANDIDATES_REQUEST =
  'talent-pools-candidates/GET_TALENT_POOL_CANDIDATES_REQUEST';
export const GET_TALENT_POOL_CANDIDATES_SUCCESS =
  'talent-pools-candidates/GET_TALENT_POOL_CANDIDATES_SUCCESS';
export const GET_TALENT_POOL_CANDIDATES_ERROR =
  'talent-pools-candidates/GET_TALENT_POOL_CANDIDATES_ERROR';

export const GET_TALENT_POOL_CANDIDATE_REQUEST =
  'talent-pools-candidates/GET_TALENT_POOL_CANDIDATE_REQUEST';
export const GET_TALENT_POOL_CANDIDATE_SUCCESS =
  'talent-pools-candidates/GET_TALENT_POOL_CANDIDATE_SUCCESS';
export const GET_TALENT_POOL_CANDIDATE_ERROR =
  'talent-pools-candidates/GET_TALENT_POOL_CANDIDATE_ERROR';

export const GET_TALENT_POOL_CANDIDATES_ANSWERS_REQUEST =
  'talent-pools-candidates/GET_TALENT_POOL_CANDIDATES_ANSWERS_REQUEST';
export const GET_TALENT_POOL_CANDIDATES_ANSWERS_SUCCESS =
  'talent-pools-candidates/GET_TALENT_POOL_CANDIDATES_ANSWERS_SUCCESS';
export const GET_TALENT_POOL_CANDIDATES_ANSWERS_ERROR =
  'talent-pools-candidates/GET_TALENT_POOL_CANDIDATES_ANSWERS_ERROR';

export const GET_TALENT_POOL_CANDIDATE_ANSWERS_REQUEST =
  'talent-pools-candidates/GET_TALENT_POOL_CANDIDATE_ANSWERS_REQUEST';
export const GET_TALENT_POOL_CANDIDATE_ANSWERS_SUCCESS =
  'talent-pools-candidates/GET_TALENT_POOL_CANDIDATE_ANSWERS_SUCCESS';
export const GET_TALENT_POOL_CANDIDATE_ANSWERS_ERROR =
  'talent-pools-candidates/GET_TALENT_POOL_CANDIDATE_ANSWERS_ERROR';

export const UPDATE_TALENT_POOL_CANDIDATE_REQUEST =
  'talent-pools-candidates/UPDATE_TALENT_POOL_CANDIDATE_REQUEST';
export const UPDATE_TALENT_POOL_CANDIDATE_SUCCESS =
  'talent-pools-candidates/UPDATE_TALENT_POOL_CANDIDATE_SUCCESS';
export const UPDATE_TALENT_POOL_CANDIDATE_ERROR =
  'talent-pools-candidates/UPDATE_TALENT_POOL_CANDIDATE_ERROR';

export const DELETE_TALENT_POOL_CANDIDATE_REQUEST =
  'talent-pools-candidates/DELETE_TALENT_POOL_CANDIDATE_REQUEST';
export const DELETE_TALENT_POOL_CANDIDATE_SUCCESS =
  'talent-pools-candidates/DELETE_TALENT_POOL_CANDIDATE_SUCCESS';
export const DELETE_TALENT_POOL_CANDIDATE_ERROR =
  'talent-pools-candidates/DELETE_TALENT_POOL_CANDIDATE_ERROR';

export const MARK_TALENT_POOL_CANDIDATE_REQUEST =
  'talent-pools-candidates/MARK_TALENT_POOL_CANDIDATE_REQUEST';
export const MARK_TALENT_POOL_CANDIDATE_SUCCESS =
  'talent-pools-candidates/MARK_TALENT_POOL_CANDIDATE_SUCCESS';
export const MARK_TALENT_POOL_CANDIDATE_ERROR =
  'talent-pools-candidates/MARK_TALENT_POOL_CANDIDATE_ERROR';

export interface TalentPoolCandidateModel {
  id: string;
  name: string;
  phone: string;
  email: string;
  marked: boolean;
  talentPoolId: number;
  createdAt: Date;
  updatedAt: Date | null;
}

export const getTalentPoolCandidates = (talentPoolId: number, queryParams: QueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TALENT_POOL_CANDIDATES_REQUEST,
    });

    const payload = await getTalentPoolCandidatesService(talentPoolId, queryParams);

    try {
      dispatch({
        type: GET_TALENT_POOL_CANDIDATES_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: GET_TALENT_POOL_CANDIDATES_ERROR,
        error,
      });
    }
  };
};

export const getTalentPoolCandidatesAnswers = (talentPoolId: number, queryParams: QueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TALENT_POOL_CANDIDATES_ANSWERS_REQUEST,
    });

    const answers = await getTalentPoolCandidatesAnswersService(talentPoolId, queryParams);

    try {
      dispatch({
        type: GET_TALENT_POOL_CANDIDATES_ANSWERS_SUCCESS,
        payload: answers,
      });
    } catch (error) {
      dispatch({
        type: GET_TALENT_POOL_CANDIDATES_ANSWERS_ERROR,
        error,
      });
    }
  };
};

export const getTalentPoolCandidate = (talentPoolId: number, candidateId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TALENT_POOL_CANDIDATE_REQUEST,
    });

    const candidate = await getTalentPoolCandidateService(talentPoolId, candidateId);

    try {
      dispatch({
        type: GET_TALENT_POOL_CANDIDATE_SUCCESS,
        payload: candidate,
      });
    } catch (error) {
      dispatch({
        type: GET_TALENT_POOL_CANDIDATE_ERROR,
        error,
      });
    }
  };
};

export const getTalentPoolCandidateAnwers = (talentPoolId: number, candidateId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TALENT_POOL_CANDIDATE_ANSWERS_REQUEST,
    });

    const answers = await getTalentPoolCandidateAnswersService(talentPoolId, candidateId);

    try {
      dispatch({
        type: GET_TALENT_POOL_CANDIDATE_ANSWERS_SUCCESS,
        payload: answers,
      });
    } catch (error) {
      dispatch({
        type: GET_TALENT_POOL_CANDIDATE_ANSWERS_ERROR,
        error,
      });
    }
  };
};

export const updateTalentPoolCandidate = (
  talentPoolId: number,
  candidateId: number,
  candidateData: TalentPoolCandidateModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_TALENT_POOL_CANDIDATE_REQUEST,
    });

    const candidate = await updateTalentPoolCandidateService(
      talentPoolId,
      candidateId,
      candidateData,
    );

    try {
      dispatch({
        type: UPDATE_TALENT_POOL_CANDIDATE_SUCCESS,
        payload: candidate,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_TALENT_POOL_CANDIDATE_ERROR,
        error,
      });
    }
  };
};

export const deleteTalentPoolCandidate = (talentPoolId: number, candidateId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_TALENT_POOL_CANDIDATE_REQUEST,
    });

    const payload = await deleteTalentPoolCandidateService(talentPoolId, candidateId);

    try {
      dispatch({
        type: DELETE_TALENT_POOL_CANDIDATE_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: DELETE_TALENT_POOL_CANDIDATE_ERROR,
        error,
      });
    }
  };
};

export const markTalentPoolCandidate = (
  talentPoolId: number,
  candidateId: number,
  marked: object,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: MARK_TALENT_POOL_CANDIDATE_REQUEST,
    });

    const payload = await markTalentPoolCandidateService(talentPoolId, candidateId, marked);

    try {
      dispatch({
        type: MARK_TALENT_POOL_CANDIDATE_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: MARK_TALENT_POOL_CANDIDATE_ERROR,
        error,
      });
    }
  };
};
