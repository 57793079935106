import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { QuestionnaireLabelCategory } from '../../../shared/models/questionnaire.model';
import { SelectGroupModel } from '../../../shared/models/common.model';

import { getGroupedSelectOptionObjects } from '../../../shared/utils/misc.util';

import { getPositions } from '../../../redux/actions/positions.action';

import PositionLabelFilter from './components/PositionLabelFilter';
import PositionSearchInput from './components/PositionSearchInput';

interface Props {
  labelCategories: QuestionnaireLabelCategory[];
  setQueryParams: Function;
}

const PositionFiltersContainer: React.FC<Props> = ({ labelCategories, setQueryParams }) => {
  const labelOptions: SelectGroupModel[] = useMemo(
    () => getGroupedSelectOptionObjects(labelCategories, 'text', 'labels', 'text', 'id'),
    [labelCategories],
  );

  return (
    <Row className="mb-3">
      <Col lg="3" md="4">
        <PositionLabelFilter options={labelOptions} setQueryParams={setQueryParams} />
      </Col>
      <Col lg="3" md="4">
        <PositionSearchInput setQueryParams={setQueryParams} />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  labelCategories: state.questionnaireLabelCategories.resources.data,
});

const mapDispatchToProps = {
  getPositions,
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionFiltersContainer);
