import { get, patch, post, del } from '../../shared/services/http.service';
import { QueryParams } from '../../shared/models/query-params.model';
import { TalentPoolModels } from '../actions/talent-pools.action';
import { TalentPoolCandidateModel } from '../actions/talent-pools-candidates.action';

export const getTalentPools = async (queryParams: QueryParams) => {
  return get('/talentpools', { params: queryParams });
};

export const getTalentPool = async (talentPoolId: number) => {
  return get(`/talentpools/${talentPoolId}`);
};

export const createTalentPool = async (talentPool: TalentPoolModels) => {
  return post('/talentpools', { data: talentPool });
};

export const updateTalentPool = async (talentPoolId: number, talentPool: TalentPoolModels) => {
  return patch(`/talentpools/${talentPoolId}`, { data: talentPool });
};

export const deleteTalentPool = async (talentPoolId: number) => {
  return del(`/talentpools/${talentPoolId}`);
};

export const getTalentPoolCandidates = async (talentPoolId: number, queryParams: QueryParams) => {
  return get(`/talentpools/${talentPoolId}/users`, { params: queryParams });
};

export const getTalentPoolCandidatesAnswers = async (
  talentPoolId: number,
  queryParams: QueryParams,
) => {
  return get(`/talentpools/${talentPoolId}/users/answers`, { params: queryParams });
};

export const getTalentPoolCandidate = async (talentPoolId: number, candidateId: number) => {
  return get(`/talentpools/${talentPoolId}/users/${candidateId}`);
};

export const getTalentPoolCandidateAnswers = async (talentPoolId: number, candidateId: number) => {
  return get(`/talentpools/${talentPoolId}/users/${candidateId}/answers`);
};

export const updateTalentPoolCandidate = async (
  talentPoolId: number,
  candidateId: number,
  talentPoolCandidate: TalentPoolCandidateModel,
) => {
  return patch(`/talentpools/${talentPoolId}/users/${candidateId}`, { data: talentPoolCandidate });
};

export const deleteTalentPoolCandidate = async (talentPoolId: number, candidateId: number) => {
  return del(`/talentpools/${talentPoolId}/users/${candidateId}`);
};

export const markTalentPoolCandidate = async (
  talentPoolId: number,
  candidateId: number,
  marked: object,
) => {
  return patch(`/talentpools/${talentPoolId}/users/${candidateId}/mark`, { data: marked });
};
