import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import styles from './DefaultNavFooter.module.scss';
import changelog from "../../views/About/data/changelog.json"

interface Props {
  userGroup: string;
}


const DefaultNavFooter: React.FC<Props> = ({ userGroup}) => {
  const { t } = useTranslation();
 const data: any = changelog;
 
  return (
    <div className="sidebar-footer"> 
    
      
      <div className={styles.aboutContainer}>
  
        {userGroup === 'Chatbot Admin' &&  (
          <Fragment>
            <span className={styles.version}>{data[0].version}</span>
            <span className={styles.separator}>&bull;</span>
            <Link to="/about" className={styles.link}>
              {t('common.about')}
            </Link>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default DefaultNavFooter;
