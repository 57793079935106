import { Dispatch } from 'redux';
import getUploadedDocumentsService from '../services/uploaded-documents.service';
import { UploadedDocumentType } from '../../shared/models/uploaded-document.model';
import { QueryParams } from '../../shared/models/query-params.model';

export const GET_UPLOADED_DOCUMENTS_REQUEST = 'uploaded-documents/GET_UPLOADED_DOCUMENTS_REQUEST';
export const GET_UPLOADED_DOCUMENTS_SUCCESS = 'uploaded-documents/GET_UPLOADED_DOCUMENTS_SUCCESS';
export const GET_UPLOADED_DOCUMENTS_ERROR = 'uploaded-documents/GET_UPLOADED_DOCUMENTS_ERROR';

export const getUploadedDocuments = (
  applicationId: number,
  type: UploadedDocumentType,
  queryParams: QueryParams,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_UPLOADED_DOCUMENTS_REQUEST,
    });

    try {
      const payload = await getUploadedDocumentsService(applicationId, type, queryParams);

      dispatch({
        type: GET_UPLOADED_DOCUMENTS_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: GET_UPLOADED_DOCUMENTS_ERROR,
        error,
      });
    }
  };
};
