import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Label,
} from 'reactstrap';
import { Formik, Form } from 'formik';

import { StateModel } from 'src/shared/models/default-state.model';
import { changePassword } from '../../../redux/actions/account.action';
import { ChangePasswordModel } from '../../../redux/reducers/account.reducer';
import { changePasswordSchema } from '../../../shared/schemas/validation.schema';

import PasswordField from '../../../shared/components/form-inputs/PasswordField';
import CheckPermission from '../../../shared/components/CheckPermission';
import Spinner from '../../../shared/components/Spinner';

interface Props {
  changePassword: Function;
  changePasswordState: StateModel;
}

const INIT_VALUES = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
};

const ChangePassword: React.FC<Props> = props => {
  const { t } = useTranslation();
  const handleSubmit = async (
    values: ChangePasswordModel,
    resetForm: Function,
  ) => {
    const { changePassword } = props;
    const { currentPassword, newPassword, newPasswordConfirmation } = values;

    await changePassword({
      currentPassword,
      newPassword,
      newPasswordConfirmation,
    });

    if (!props.changePasswordState.error) {
      resetForm();
    }
  };

  return (
    <Card>
      <CardHeader>
        <p className="card-title font-weight-bold mb-0">
          {t('common.changePassword')}
        </p>
      </CardHeader>
      <CardBody>
        <Formik
          initialValues={{ ...INIT_VALUES }}
          validationSchema={changePasswordSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            await handleSubmit(values, resetForm);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormGroup>
                <Label for="currentPassword">
                  {t('account.currentPassword')}
                </Label>
                <PasswordField name="currentPassword" />
              </FormGroup>

              <FormGroup>
                <Label for="newPassword">{t('account.newPassword')}</Label>
                <PasswordField name="newPassword" />
              </FormGroup>

              <FormGroup>
                <Label for="newPasswordConfirmation">
                  {t('account.confirmPassword')}
                </Label>
                <PasswordField name="newPasswordConfirmation" />
              </FormGroup>

              <div className="d-flex justify-content-end align-items-center">
                <Spinner loading={isSubmitting} className="mr-2" />
                <CheckPermission
                  variant="enableIf"
                  permissions={['change-password_update']}
                >
                  <Button type="submit" color="primary" disabled={isSubmitting}>
                    {t('common.submit')}
                  </Button>
                </CheckPermission>
              </div>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({
  changePasswordState: state.accountChangePassword,
});

const mapDispatchToProps = {
  changePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
