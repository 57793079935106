import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { useDeepEffect } from 'src/shared/hooks';
import { PositionsQueryParams } from 'src/shared/models/query-params.model';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { getPositions } from 'src/redux/actions/positions.action';
import { getShortListPositions } from 'src/redux/actions/short-list.action';
import { getLabelCategories } from 'src/redux/actions/questionnaire.action';
import { getPlatforms } from 'src/redux/actions/platforms.action';

import PositionsHeader from './components/PositionsHeader';
import PositionFiltersContainer from './PositionFilters/PositionFiltersContainer';
import PositionsTable from './components/PositionsTable';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  getPlatforms: Function;
  getPositions: Function;
  getLabelCategories: Function;
  getShortListPositions: Function;
}

const Positions: React.FC<Props> = ({
  application,
  getApplication,
  getPlatforms,
  getPositions,
  getLabelCategories,
  getShortListPositions,
}) => {
  const { appId } = useParams<{ appId: string }>();
  const [queryParams, setQueryParams] = useState<PositionsQueryParams>({
    page: 0,
    size: 20,
    labels: [],
    search: '',
  });

  useEffect(() => {
    if (appId) {
      getPlatforms(appId);
      getLabelCategories(appId);

      if (!application.id) {
        getApplication(appId);
      }
    }
    /* eslint-disable-next-line */
  }, []);

  useDeepEffect(() => {
    if (appId) {
      getPositions(appId, queryParams);
      getShortListPositions(appId);
    }
  }, [queryParams]);

  return (
    <Row className="animated fadeIn">
      <Col>
        <PositionsHeader />
        <PositionFiltersContainer setQueryParams={setQueryParams} />
        <PositionsTable queryParams={queryParams} setQueryParams={setQueryParams} />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  platforms: state.platforms.data,
  labelCategories: state.questionnaireLabelCategories.resources.data,
});

const mapDispatchToProps = {
  getApplication,
  getPlatforms,
  getPositions,
  getShortListPositions,
  getLabelCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(Positions);
