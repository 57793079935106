import moment from 'moment';

const filterValidQueryParams = (providedParams: any, validParams: any) => {
  let result: object = {
    ...validParams,
  };

  Object.keys(providedParams).forEach(key => {
    if (key in validParams && providedParams[key]) {
      result = {
        ...result,
        [key]: providedParams[key],
      };
    }
  });

  return result;
};

const formatDatesToIsoString = (queryParams: any) => {
  const { from, to } = queryParams;

  const formattedParams = {
    ...queryParams,
    from: from
      ? moment(from)
          .utc()
          .startOf('day')
          .toISOString()
      : null,
    to: to
      ? moment(to)
          .utc()
          .endOf('day')
          .toISOString()
      : null,
  };

  return formattedParams;
};

const getFormattedQueryParams = (queryParams: any) => {
  const { from, to } = queryParams;

  const formattedParams = {
    ...queryParams,
    from: moment(from).format('YYYY-MM-DD'),
    to: moment(to).format('YYYY-MM-DD'),
  };

  return formattedParams;
};

export { filterValidQueryParams, formatDatesToIsoString, getFormattedQueryParams };
