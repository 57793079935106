import { post } from '../../shared/services/http.service';
import { QueryParams } from '../../shared/models/query-params.model';
import { HandoverMessageModel } from '../../shared/models/conversations.model';
import { ConversationHistoryFilters } from 'src/shared/models/conversation-history.model';

export const getConversations = async (
  applicationId: string,
  filters: ConversationHistoryFilters,
  queryParams: QueryParams,
) => {
  return post(`/applications/${applicationId}/conversations`, {
    params: queryParams,
    data: filters,
  });
};

export const setHandoverStatus = async (userId: string, handover: boolean) => {
  return post(`/conversations/${userId}/handover`, { data: { handover } });
};

export const sendHandoverMessage = async (userId: string, message: HandoverMessageModel) => {
  return post(`/conversations/${userId}/handover/message`, { data: message });
};

export default getConversations;
