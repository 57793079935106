import React from 'react';

import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';

import styles from './scss/Facebook.module.scss';

interface Props {
  message: ConversationHistoryModel;
}

const MediaMessage: React.FC<Props> = (props: Props) => {
  const { message } = props;

  return (
    <div className={styles.stickerMessage}>
      <img src={message.messageAsRaw} alt="Sticker Message" />
    </div>
  );
};

export default MediaMessage;
