import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import Spinner from '../Spinner';

interface Props {
  title: string;
  text: string;
  item?: string | undefined;
  cancel: Function;
  confirm: Function;
  isOpen: boolean;
  isLoading: boolean;
}

const ConfirmModal: React.FC<Props> = ({
  title,
  text,
  item,
  confirm,
  cancel,
  isOpen,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} className="modal-md">
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <p className="mt-3">{text}</p>
        {item && (
          <p>
            <strong>{item}</strong>
          </p>
        )}
      </ModalBody>
      <ModalFooter>
        <Spinner loading={isLoading} className="mr-1" />

        <Button color="danger" disabled={isLoading} onClick={(e: React.MouseEvent) => confirm(e)}>
          {t('common.confirm')}
        </Button>
        <Button onClick={(e: React.MouseEvent) => cancel(e)} color="secondary">
          {t('common.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
