import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup, InputGroup, InputGroupAddon, Label, Table } from 'reactstrap';
import { FieldArray, FormikValues, useFormikContext } from 'formik';

import { PositionQuestionAnswerOptionModel } from 'src/shared/models/positions.model';

import TextField from 'src/shared/components/form-inputs/TextField';

import styles from 'src/views/Positions/Positions.module.scss';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import uniqueId from 'lodash/uniqueId';
import { EditableTextInput } from 'src/shared/components';

interface Props {
  questionAnswerOptions?: PositionQuestionAnswerOptionModel[];
  answerIdsToDelete: number[];
  setAnswerIdsToDelete: Function;
}

const AnswerChoiceForm: React.FC<Props> = ({
  questionAnswerOptions,
  answerIdsToDelete,
  setAnswerIdsToDelete,
}) => {
  const { t } = useTranslation();
  const { setFieldValue, values }: FormikValues = useFormikContext();
  const [answerOptions, setAnswerOptions] = useState<Array<PositionQuestionAnswerOptionModel>>([]);

  const isValidAnswer = (
    answers: string[],
    answer: { newanswer: string; newexternalId: string },
  ) => {
    if (!answer) return false;
    if (answers.includes(answer.newanswer)) return false;

    setFieldValue('newanswer', '');
    setFieldValue('newexternalId', '');
    return true;
  };

  const handleQuestionAnswerOptionDelete = (answer: PositionQuestionAnswerOptionModel) => {
    const { id } = answer;
    setAnswerIdsToDelete((prevState: number[]) => [...prevState, id]);
  };
  useEffect(() => {
    setAnswerOptions(values.answerOptions || []);
  }, [values.answerOptions]);

  const updateAnswerText = (answer: PositionQuestionAnswerOptionModel, value: string) => {
    answerOptions.filter(e => e.id === answer.id).map(e => (e.text = value.toString()));
    setAnswerOptions(answerOptions);
    setFieldValue('answerOptions', answerOptions);
  };

  const updateAnswerExternalId = (answer: PositionQuestionAnswerOptionModel, value: string) => {
    answerOptions.filter(e => e.id === answer.id).map(e => (e.externalId = value.toString()));
    setAnswerOptions(answerOptions);
    setFieldValue('answerOptions', answerOptions);
  };

  const updateNewAnswerText = (index: number, value: string) => {
    values.answers[index].answer = values.answers[index].name = values.answers[
      index
    ].value = value.toString();
    setFieldValue('answers', values.answers);
  };

  const updateNewAnswerExternalId = (index: number, value: string) => {
    values.answers[index].externalId = value.toString();
    setFieldValue('answers', values.answers);
  };

  const addAnswer = (data: { newexternalId: string; newanswer: string }) => {
    const options = values.answers || [];
    options.push({
      externalId: data.newexternalId || null,
      answer: data.newanswer,
      name: data.newanswer,
    });
    setFieldValue('answers', options);
  };

  const reOrderElements = (sourceIndex: number, destinationIndex: number) => {
    const tempAddedLabels: any[] = Array.from(answerOptions);
    const [removed] = tempAddedLabels.splice(sourceIndex, 1);

    tempAddedLabels.splice(destinationIndex, 0, removed);

    return tempAddedLabels;
  };

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;
    if (!destination || source.index === destination.index) {
      return null;
    }

    const reOrderedAnswers = reOrderElements(source.index, destination.index);
    const orderedAnswers = reOrderedAnswers.map((answer, idx) => ({
      ...answer,
      orderNumber: idx,
    }));

    values.answerSorting = orderedAnswers;

    setAnswerOptions(orderedAnswers);
    setFieldValue('answerOptions', orderedAnswers);
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any, answerId: number | undefined) => {
    const data = {
      background: isDragging ? '#d4ebf2 ' : '',
      border: isDragging ? '2px solid #99d0e0 ' : '',
      display: isDragging ? 'table' : '',
      ...draggableStyle,
    };

    if (answerId && answerIdsToDelete.indexOf(answerId) >= 0) {
      data.background = '#F9B5B5';
      data.display = 'none';
    }

    return data;
  };

  const getAnswerValue = (answer: any) => {
    return answer.text || answer.answer || '-';
  };

  const getAnswerExternalId = (answer: any) => {
    return answer.externalId || '-';
  };

  const getAnswerValueEdit = (answer: any) => {
    return answer.text || answer.answer || '';
  };

  const getAnswerExternalIdEdit = (answer: any) => {
    return answer.externalId || '';
  };

  return (
    <FieldArray name="answers">
      {arrayHelpers => (
        <Fragment>
          <FormGroup>
            <InputGroup>
              <Label for="newexternalId">{t('common.externalId')}</Label>
              <TextField name="newexternalId" id="externalIdInput" />
            </InputGroup>
            <InputGroup>
              <Label for="newanswer">{t('common.answer')}</Label>
              <TextField name="newanswer" id="answerInput" />
              <InputGroupAddon addonType="append" style={{ zIndex: 0 }}>
                <Button
                  onClick={() => {
                    if (isValidAnswer(values.answers, values.newanswer)) addAnswer(values);
                  }}
                >
                  {t('common.add')}
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>

          <Fragment>
            {((answerOptions && answerOptions.length > 0) ||
              (values.answers && values.answers.length > 0)) && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable key={uniqueId('answedroppable-')} droppableId="answers">
                  {provided => (
                    <Table className={styles.answersTable} hover striped size="sm">
                      <thead>
                        <tr>
                          <th className={styles.answer}>{t('common.externalId')}</th>
                          <th className={styles.answer}>{t('common.answer')}</th>
                          <th className={styles.delete}>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody ref={provided.innerRef} {...provided.droppableProps}>
                        {(answerOptions as PositionQuestionAnswerOptionModel[])
                          .sort((a, b) => a.orderNumber - b.orderNumber)
                          .map((answer: any, index: number) => {
                            const anserId = uniqueId(answer.id);
                            return (
                              <Draggable draggableId={anserId} index={index} key={anserId}>
                                {(provided, snapshot) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                      answer.id,
                                    )}
                                  >
                                    <td className={styles.answer}>
                                      <EditableTextInput
                                        text={getAnswerExternalId(answer)}
                                        name="externalId"
                                        data={getAnswerExternalIdEdit(answer)}
                                        canBeEmpty
                                        updateData={(appId: string, id: number, value: string) => {
                                          updateAnswerExternalId(answer, value);
                                        }}
                                      />
                                    </td>
                                    <td className={styles.answer}>
                                      <EditableTextInput
                                        text={getAnswerValue(answer)}
                                        name="text"
                                        data={getAnswerValueEdit(answer)}
                                        canBeEmpty
                                        updateData={(appId: string, id: number, value: string) => {
                                          updateAnswerText(answer, value);
                                        }}
                                      />
                                    </td>
                                    <td className={styles.delete}>
                                      <Button
                                        color="danger"
                                        onClick={() => handleQuestionAnswerOptionDelete(answer)}
                                      >
                                        <i className="far fa-trash-alt" />
                                      </Button>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                        {(values.answers as PositionQuestionAnswerOptionModel[])
                          .sort((a, b) => a.orderNumber - b.orderNumber)
                          .map((answer, index) => {
                            return (
                              <tr
                                key={uniqueId('answedraggable-')}
                                style={getItemStyle(false, false, answer.id)}
                              >
                                <td className={styles.answer}>
                                  <EditableTextInput
                                    text={getAnswerExternalId(values.answers[index])}
                                    name={`externalId_${index}`}
                                    data={getAnswerExternalIdEdit(values.answers[index])}
                                    canBeEmpty
                                    updateData={(appId: any, id: any, value: any) => {
                                      updateNewAnswerExternalId(index, value);
                                    }}
                                  />
                                </td>
                                <td className={styles.answer}>
                                  <EditableTextInput
                                    text={getAnswerValue(values.answers[index])}
                                    name={`answer_${index}`}
                                    data={getAnswerValueEdit(values.answers[index])}
                                    canBeEmpty
                                    updateData={(appId: any, id: any, value: any) => {
                                      updateNewAnswerText(index, value);
                                    }}
                                  />
                                </td>
                                <td className={styles.delete}>
                                  <Button color="danger" onClick={() => arrayHelpers.remove(index)}>
                                    <i className="far fa-trash-alt" />
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Fragment>
        </Fragment>
      )}
    </FieldArray>
  );
};

export default AnswerChoiceForm;
