import { Dispatch } from 'redux';
import {
  getAuditLog as getAuditLogService,
  exportAuditLog as exportAuditLogService,
} from '../services/audit-log.service';
import {
  AuditLogQueryParams,
  AuditLogExportQueryParams,
} from '../../shared/models/query-params.model';

export const GET_AUDIT_LOG_REQUEST = 'audit-log/GET_AUDIT_LOG_REQUEST';
export const GET_AUDIT_LOG_SUCCESS = 'audit-log/GET_AUDIT_LOG_SUCCESS';
export const GET_AUDIT_LOG_ERROR = 'audit-log/GET_AUDIT_LOG_ERROR';

export const EXPORT_AUDIT_LOG_REQUEST = 'audit-log/EXPORT_AUDIT_LOG_REQUEST';
export const EXPORT_AUDIT_LOG_SUCCESS = 'audit-log/EXPORT_AUDIT_LOG_SUCCESS';
export const EXPORT_AUDIT_LOG_ERROR = 'audit-log/EXPORT_AUDIT_LOG_ERROR';

export const getAuditLog = (params: AuditLogQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_AUDIT_LOG_REQUEST,
    });

    const payload = await getAuditLogService(params);

    try {
      dispatch({
        type: GET_AUDIT_LOG_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: GET_AUDIT_LOG_ERROR,
        error,
      });
    }
  };
};

export const exportAuditLog = (params: AuditLogExportQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EXPORT_AUDIT_LOG_REQUEST,
    });

    const payload = await exportAuditLogService(params);

    try {
      dispatch({
        type: EXPORT_AUDIT_LOG_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: EXPORT_AUDIT_LOG_ERROR,
        error,
      });
    }
  };
};
