import { QuestionnaireLabel, QuestionnaireLabelCategory, SelectModel } from '../models';

export const getDefaultLabelValue = (
  labelCategories: QuestionnaireLabelCategory[],
  labelSlug: string | undefined,
) =>
  getDefaultValue(labelCategories, labelSlug, () => {
    let item: QuestionnaireLabel | undefined = undefined;

    labelCategories.forEach((category: QuestionnaireLabelCategory) => {
      category.labels?.forEach(label => {
        if (label.slug === labelSlug) {
          item = { ...label, parentLabel: category.text };
        }
      });
    });

    return item;
  });

export const getDefaultLabelCategoryValue = (
  labelCategories: QuestionnaireLabelCategory[],
  labelCategorySlug: string | undefined,
) =>
  getDefaultValue(labelCategories, labelCategorySlug, () => {
    let item: QuestionnaireLabel | undefined = undefined;

    labelCategories.forEach((category: QuestionnaireLabelCategory) => {
      if (category.slug === labelCategorySlug) {
        item = { ...category, parentLabel: category.text, order: 0 } as any;
      }
    });

    return item;
  });

const getDefaultValue = (
  labelCategories: QuestionnaireLabelCategory[],
  slug: string | undefined,
  selectorFunc: () => QuestionnaireLabel | undefined,
) => {
  if (!labelCategories.length || !slug) return undefined;

  const selectedLabel = selectorFunc();

  if (selectedLabel) {
    const selectedLabelOption: SelectModel = {
      label: selectedLabel.text,
      value: selectedLabel.slug,
      parentLabel: selectedLabel.parentLabel || undefined,
    };
    return selectedLabelOption;
  }

  return undefined;
};
