import { get, post, patch, del } from '../../shared/services/http.service';
import { CreateApplicationSettingModel } from '../actions/application-settings.action';

export const getApplicationSettings = async (applicationId: string) => {
  return get(`/applications/${applicationId}/settings`);
};

export const createApplicationSetting = async (
  applicationId: string,
  setting: CreateApplicationSettingModel,
) => {
  return post(`/applications/${applicationId}/settings`, {
    data: setting,
  });
};

export const updateApplicationSetting = async (
  applicationId: string,
  key: string,
  value: object,
) => {
  return patch(`/applications/${applicationId}/settings/${key}`, {
    data: value,
  });
};

export const deleteApplicationSetting = async (
  applicationId: string,
  key: string,
) => {
  return del(`/applications/${applicationId}/settings/${key}`);
};
