import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, ButtonProps } from 'reactstrap';

import styles from '../scss/Viber.module.scss';

interface ViberButtonProps extends ButtonProps {
  value: string | number;
  className?: string;
  marginLeft?: boolean;
  marginBottom?: boolean;
  noMargin?: boolean;
}

const ViberButton: FC<ViberButtonProps> = ({
  className,
  children,
  value,
  marginLeft,
  marginBottom,
  noMargin,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Button
      className={classNames(styles.viberButtonTemplateButton, className, {
        [styles.viberButtonTemplateButtonMarginLeft]: marginLeft,
        [styles.viberButtonTemplateButtonMarginBottom]: marginBottom,
        [styles.viberButtonTemplateButtonNoMargin]: noMargin,
      })}
      onClick={() => alert(t('viber.numberOfValue', { value }))}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ViberButton;
