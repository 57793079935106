import React, { Fragment } from 'react';

type Sizes = 'xs' | 'sm' | 'lg' | '2x' | '3x' | '5x' | '7x' | '10x';

interface Props {
  size?: Sizes;
  loading: boolean;
  className?: string;
}

const Spinner: React.FC<Props> = (props: Props) => {
  const { size, loading, className } = props;

  return (
    <Fragment>
      {loading && <i className={`fas fa-spinner fa-spin fa-${size} ${className}`} />}
    </Fragment>
  );
};

Spinner.defaultProps = {
  size: 'lg',
  loading: false,
  className: '',
};

export default Spinner;
