import React from 'react';
import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';

import styles from './scss/Hangouts.module.scss';

interface Props {
  message: ConversationHistoryModel;
}

const getImageUrl = (message: ConversationHistoryModel) => {
  const URLRegex = /[-a-zA-Z0-9@:%_.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_.~#?&//=]*)?/gi;
  const regex = new RegExp(URLRegex);

  const imageUrl = message.messageAsRaw.match(regex);

  return imageUrl ? imageUrl[0] : '';
};

const ImageMessage: React.FC<Props> = (props: Props) => {
  const { message } = props;

  const imageUrl = getImageUrl(message);

  return (
    <div className={styles.imageMessage}>
      <img src={imageUrl} alt="Message" />
    </div>
  );
};

export default ImageMessage;
