import React, { useEffect, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Col, Row, Card, CardBody, CardHeader, Button } from 'reactstrap';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import {
  getSurveyQuestions,
  revertSurveyQuestions,
  getSurvey,
  revertSurvey,
} from 'src/redux/actions/surveys.action';
import {
  PieChart,
  TimeFromNow,
  AnimatedLine,
  ExportButton,
  PageHeader,
} from 'src/shared/components';
import {
  SurveyQuestionsModel,
  SurveyModel,
  SurveyQuestionAnswerModel,
  SurveyAnswerTypes,
  UserProfileSurveyQuestionAnswers,
} from 'src/shared/models';
import { exportReport as exportReportService } from 'src/redux/services/survey.service';
import saveFile from 'src/shared/utils/file-saver.util';
import { fileNames } from 'src/shared/settings';

import SurveyHeaderTile, { TileType } from './SurveyHeaderTile/SurveyHeaderTile';
import CompletedRatio from './CompletedRatio/CompletedRatio';
import SortIndex from './SortIndex';

import styles from './SurveysReport.module.scss';
import FreeTextAnswersModal from './components/FreeTextAnswersModal';

interface SurveysReportProps {
  getSurveyQuestions: Function;
  revertSurveyQuestions: Function;
  getSurvey: Function;
  revertSurvey: Function;
  getApplication: Function;
  application: ApplicationModel;
  surveyQuestions: SurveyQuestionsModel[];
  survey: SurveyModel;
}

const colors = ['#00aeef', '#dd1b16', '#4cd137', '#ffa600', '#e46651'];

const SurveysReport: FC<SurveysReportProps> = ({
  getSurveyQuestions,
  revertSurveyQuestions,
  getSurvey,
  revertSurvey,
  getApplication,
  application,
  surveyQuestions,
  survey,
}) => {
  const { t } = useTranslation();
  const { appId, surveyId } = useParams<{ appId: string; surveyId: string }>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalData, setModalData] = useState<UserProfileSurveyQuestionAnswers[]>([]);

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    revertSurveyQuestions();
    revertSurvey();
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    getSurveyQuestions(appId, surveyId);
    getSurvey(appId, surveyId);
    /* eslint-disable-next-line */
  }, []);

  const isEndsAtFuture = survey && new Date(survey.endsAt).getTime() > new Date().getTime();

  const exportReport = async () => {
    const response = await exportReportService(appId, surveyId);

    if (response) {
      saveFile(response, fileNames.SURVEY_REPORT_EXPORT, 'xlsx');
    }
  };

  const openAnswersModal = (surveyQuestionAnswers: SurveyQuestionAnswerModel[], index: number) => {
    setModalData(surveyQuestionAnswers[0].userProfileSurveyQuestionAnswers);
    setModalTitle(`#${index + 1}`);
    setIsModalOpen(true);
  };

  const showFreeTextAnswers = (surveyQuestionAnswers: SurveyQuestionAnswerModel[]) => {
    if (!surveyQuestionAnswers?.length) {
      return false;
    }

    return surveyQuestionAnswers[0].type === SurveyAnswerTypes.SURVEY_QUESTION_ANSWER_TYPE_TEXT;
  };

  return (
    <div className="animated fadeIn">
      <PageHeader title={t('surveys.reportOfOnboarding', { title: survey && survey.name })} />
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <SurveyHeaderTile
                  label={t('surveys.subscribed')}
                  value={survey && survey.numberOfSurveySubscriptions}
                  type={TileType.SUBSCIBED}
                />
                <SurveyHeaderTile
                  label={t('surveys.completed')}
                  value={
                    survey && (
                      <CompletedRatio
                        completed={survey.numberOfCompleted}
                        total={survey.numberOfSurveySubscriptions}
                      />
                    )
                  }
                  type={TileType.COMPLETED}
                />
                <SurveyHeaderTile
                  label={t('surveys.running')}
                  value={survey && <TimeFromNow id="running" time={survey.startsAt} />}
                  type={TileType.RUNNING}
                />
                <SurveyHeaderTile
                  label={t('surveys.remaining')}
                  value={
                    survey && isEndsAtFuture ? (
                      <TimeFromNow id="remaining" time={survey.endsAt} />
                    ) : (
                      0
                    )
                  }
                  type={TileType.RUNNING}
                />
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col className="font-weight-bold">{t('surveys.questionsReport')}</Col>
                <Col className="text-right">
                  <ExportButton
                    onClick={exportReport}
                    permission="survey_survey_report_export_read"
                  />
                </Col>
              </Row>
              {surveyQuestions && surveyQuestions.length > 0 ? (
                surveyQuestions.map(
                  ({ surveyQuestionAnswers, id, text, numberOfAnswers }, index) => (
                    <Row key={id}>
                      <Col sm={12}>
                        <hr />
                        <SortIndex index={index} />
                        {text}
                      </Col>
                      <Col sm={5} className={styles.chartContainer}>
                        <PieChart
                          data={surveyQuestionAnswers.map(
                            ({ id, text, numberOfSubscriberAnswered }) => ({
                              id: id.toString(),
                              label: text,
                              value: numberOfSubscriberAnswered,
                            }),
                          )}
                          colors={colors}
                          innerRadius={0.01}
                          padAngle={1}
                          margin={{ top: 35, bottom: 15, right: 100 }}
                          enableRadialLabels={false}
                          enableSlicesLabels={false}
                        />
                      </Col>
                      <Col sm={7} className="mt-4">
                        {surveyQuestionAnswers.map(
                          (
                            { id, text, numberOfSubscriberAnswered, originalIndex },
                            answerIndex,
                          ) => (
                            <Col key={id} className="mb-3">
                              <Col sm={12} className="d-flex">
                                <Col xs={8} sm={10} className="pl-0">
                                  <SortIndex index={originalIndex} />
                                  {text}
                                </Col>
                                <Col xs={4} sm={2} className="pr-0 d-flex justify-content-end">
                                  <CompletedRatio
                                    completed={numberOfSubscriberAnswered}
                                    total={numberOfAnswers}
                                  />
                                </Col>
                              </Col>
                              <Col sm={12}>
                                <AnimatedLine
                                  value={`${(numberOfSubscriberAnswered / numberOfAnswers) * 100}%`}
                                  color={colors[answerIndex]}
                                />
                              </Col>
                            </Col>
                          ),
                        )}
                        {showFreeTextAnswers(surveyQuestionAnswers) && (
                          <div className="text-md-right pr-1">
                            <Button
                              className="mr-4 btn-sm right"
                              onClick={() => openAnswersModal(surveyQuestionAnswers, index)}
                            >
                              {t('surveys.freeTextAnswers')}
                            </Button>
                          </div>
                        )}
                      </Col>
                    </Row>
                  ),
                )
              ) : (
                <Row>
                  <Col className="text-center">{t('surveys.noQuestions')}</Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <FreeTextAnswersModal
        isOpen={isModalOpen}
        setModalState={setIsModalOpen}
        title={modalTitle}
        data={modalData}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    surveyQuestions: state.surveyQuestions.data.content,
    survey: state.surveyGeneral.data.survey,
  };
};

const mapDispatchToProps = {
  getSurveyQuestions,
  getApplication,
  revertSurveyQuestions,
  getSurvey,
  revertSurvey,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveysReport);
