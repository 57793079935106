import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import { QuestionnaireMessage } from 'src/shared/models';

interface ConnectionsModalProps {
  isOpen: boolean;
  selectedMessage: QuestionnaireMessage | undefined;
  messages: QuestionnaireMessage[];

  toggle: (isOpen: boolean) => void;
  setSelectedMessage: (message: QuestionnaireMessage) => void;
}

const ConnectionsModal: FC<ConnectionsModalProps> = ({
  isOpen,
  selectedMessage,
  messages,
  toggle,
  setSelectedMessage,
}) => {
  const { t } = useTranslation();

  const nextMessages = useMemo(() => {
    const retVal: QuestionnaireMessage[] = [];
    var nextMessageIds = selectedMessage?.answers.map(a => a.nextMessageId);
    if (nextMessageIds) {
      nextMessageIds?.forEach(id => {
        const filtered = messages.filter(m => m.id === id);
        if (filtered.length > 0) {
          retVal.push(filtered[0]);
        }
      });
    }
    return retVal;
  }, [selectedMessage, messages]);

  const parentMessages = useMemo(() => {
    const retVal: QuestionnaireMessage[] = [];
    var parentMessageIds = selectedMessage?.parentAnswers.map(a => a.messageId);
    if (parentMessageIds) {
      parentMessageIds?.forEach(id => {
        const filtered = messages.filter(m => m.id === id);
        if (filtered.length > 0) {
          retVal.push(filtered[0]);
        }
      });
    }
    return retVal;
  }, [selectedMessage, messages]);

  const messageCardClicked = async (messageId: number) => {
    const filtered = messages.filter(m => m.id === messageId);
    if (filtered.length > 0) {
      setSelectedMessage(filtered[0]);
    }
  };

  const nextMessageCardClicked = async (message: QuestionnaireMessage) => {
    setSelectedMessage(message);
  };

  return (
    <>
      <Modal size="xl" isOpen={isOpen} toggle={() => toggle(false)} style={{ maxWidth: '100%' }}>
        <ModalHeader toggle={() => toggle(false)}>
          {t('questionnaire.connectionsModal.connections')}
        </ModalHeader>
        <ModalBody>
          <Row className="flex-nowrap overflow-auto">
            {selectedMessage?.parentAnswers.map(answer => {
              const filtered = parentMessages.filter(m => m.id === answer.messageId);
              let parentMessage: QuestionnaireMessage | undefined = undefined;
              if (filtered.length > 0) {
                parentMessage = filtered[0];
              }
              return (
                <Col key={answer.id} sm={3}>
                  <Card
                    style={{ cursor: 'pointer' }}
                    onClick={() => messageCardClicked(answer.messageId!)}
                  >
                    <CardHeader className="text-center font-weight-bold">
                      {answer.messageId} [{parentMessage?.type}]
                    </CardHeader>
                    <CardBody>{parentMessage?.text}</CardBody>
                    <CardFooter className="text-center">
                      {t('questionnaire.connectionsModal.answer')}: {answer.id} ["{answer.text}"]
                    </CardFooter>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader className="text-center font-weight-bold">
                  {selectedMessage?.id} [{selectedMessage?.type}]
                </CardHeader>
                <CardBody className="text-center">{selectedMessage?.text}</CardBody>
                <CardFooter className="text-center">
                  <Row className="flex-nowrap overflow-auto">
                    {selectedMessage?.answers.map(answer => {
                      const filtered = nextMessages.filter(m => m.id === answer.nextMessageId);
                      let nextMessage: QuestionnaireMessage | undefined = undefined;
                      if (filtered.length > 0) {
                        nextMessage = filtered[0];
                      }
                      return (
                        <Col key={answer.id} sm={2} className="text-center border-right">
                          <Row className="border-bottom">
                            <Col>
                              {t('questionnaire.connectionsModal.answer')}: {answer.id} ["
                              {answer.text}
                              "]
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {nextMessage && (
                                <Card
                                  className="bg-warning mt-3"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => nextMessageCardClicked(nextMessage!)}
                                >
                                  <CardHeader className="text-center font-weight-bold">
                                    {nextMessage.id} [{nextMessage.type}]
                                  </CardHeader>
                                  <CardBody>{nextMessage.text}</CardBody>
                                </Card>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ConnectionsModal;
