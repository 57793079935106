import React, { Fragment } from 'react';
import { useField, FieldAttributes } from 'formik';
import { Input, FormFeedback } from 'reactstrap';

const PasswordField: React.FC<FieldAttributes<{}>> = ({
  disabled,
  className,
  placeholder,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const { name, value, onBlur, onChange } = field;
  const { error, touched } = meta;

  const valid = touched && !error;
  const invalid = touched && !!error;
  const errorText = error || undefined;

  return (
    <Fragment>
      <Input
        type="password"
        name={name}
        value={value as string}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        valid={valid}
        invalid={invalid}
        disabled={disabled}
        className={className}
      />
      <FormFeedback>{errorText}</FormFeedback>
    </Fragment>
  );
};

export default PasswordField;
