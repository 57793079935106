import { StateModel } from 'src/shared/models';
import {
  GET_AVERAGED_ISSUE_REPLY_TIME_BY_CONTEXT_DATA_REPORT,
  GET_AVERAGED_ISSUE_REPLY_TIME_BY_CONTEXT_DATA_REPORT_SUCCESS,
  GET_AVERAGED_ISSUE_REPLY_TIME_BY_CONTEXT_DATA_REPORT_ERROR,
  GET_AVERAGED_ISSUE_REPLY_TIME_BY_DASHBOARD_USER_REPORT,
  GET_AVERAGED_ISSUE_REPLY_TIME_BY_DASHBOARD_USER_REPORT_SUCCESS,
  GET_AVERAGED_ISSUE_REPLY_TIME_BY_DASHBOARD_USER_REPORT_ERROR
} from '../actions/issue-reports.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: { averagedIssueReplyTimeByContextDataReport: [], averagedIssueReplyTimeByDashboardUserReport: [] },
};

export const issueReportsReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case GET_AVERAGED_ISSUE_REPLY_TIME_BY_CONTEXT_DATA_REPORT:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_AVERAGED_ISSUE_REPLY_TIME_BY_CONTEXT_DATA_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          averagedIssueReplyTimeByContextDataReport: payload,
          averagedIssueReplyTimeByDashboardUserReport: state.data.averagedIssueReplyTimeByDashboardUserReport,
        },
      };
    case GET_AVERAGED_ISSUE_REPLY_TIME_BY_CONTEXT_DATA_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };
    case GET_AVERAGED_ISSUE_REPLY_TIME_BY_DASHBOARD_USER_REPORT:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_AVERAGED_ISSUE_REPLY_TIME_BY_DASHBOARD_USER_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          averagedIssueReplyTimeByContextDataReport: state.data.averagedIssueReplyTimeByContextDataReport,
          averagedIssueReplyTimeByDashboardUserReport: payload,
        },
      };
    case GET_AVERAGED_ISSUE_REPLY_TIME_BY_DASHBOARD_USER_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };
    default:
      return state;
  }
};
