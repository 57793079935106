import React, { Fragment, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Button } from 'reactstrap';
import { Formik, Form } from 'formik';

import { PositionCriteraModel } from '../../../../../shared/models/positions.model';

import { prefilterCriteriaCategorySchema } from '../../../../../shared/schemas/validation.schema';

import {
  createPositionCriteria,
  updatePositionCriteria,
  deletePositionCriteria,
} from '../../../../../redux/actions/position-criterias.action';
import {
  setPositionSidebarType,
  setQuestionToEdit,
} from '../../../../../redux/actions/positions.action';

import TextField from '../../../../../shared/components/form-inputs/TextField';
import TagPill from '../../../../../shared/components/TagPill';
import Spinner from '../../../../../shared/components/Spinner';
import SidebarActionButtons from './components/SidebarActionButtons';

import styles from '../../../Positions.module.scss';
import addToast from '../../../../../shared/utils/notification.util';
import CheckPermission from 'src/shared/components/CheckPermission';
import hasPermission from 'src/shared/services/permissions.service';

interface Props {
  isCriteriaCategories?: boolean;
  criterias: PositionCriteraModel[];
  criteriasLoading: boolean;
  criteriasLoaded: boolean;
  createPositionCriteria: Function;
  updatePositionCriteria: Function;
  deletePositionCriteria: Function;
}

const PositionQuestionCreateAndEditSidebar: React.FC<Props> = ({
  isCriteriaCategories = false,
  criterias,
  criteriasLoading,
  criteriasLoaded,
  createPositionCriteria,
  updatePositionCriteria,
  deletePositionCriteria,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  useLayoutEffect(() => {
    setFocusOnInput();
  }, []);

  const handleSubmit = async (category: string, resetForm: Function) => {
    const isCategoryExists = criterias.find(criteria => criteria.name === category);

    if (isCategoryExists) {
      return addToast('error', t('positions.prefilterCriteriaCategoryExists'));
    }

    if (appId && category) {
      await createPositionCriteria(appId, category);
      setFocusOnInput();
      resetForm();
    }

    return null;
  };

  const handleDelete = async (categoryId: number | undefined) => {
    if (appId && categoryId) {
      await deletePositionCriteria(appId, categoryId);
    }
  };

  const handleEdit = async (criteria: PositionCriteraModel, newName: string) => {
    if (criteria.name === newName) return null;

    if (appId && criteria && newName) {
      await updatePositionCriteria(appId, criteria.id, newName);
    }
  };

  const setFocusOnInput = () => {
    const nameInput = document.getElementById('criteriaCategoryNameInput') as HTMLInputElement;
    if (nameInput) nameInput.focus();
  };

  return (
    <Row className="animated fadeIn">
      <Col>
        <Formik
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{ name: '' }}
          validationSchema={prefilterCriteriaCategorySchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            await handleSubmit(values.name, resetForm);
            setSubmitting(false);
          }}
        >
          {({ values, isSubmitting }) => (
            <Fragment>
              <Form>
                <FormGroup>
                  <div className="d-flex">
                    <CheckPermission variant="enableIf" permissions={['position_criteria_create']}>
                      <TextField
                        id="criteriaCategoryNameInput"
                        name="name"
                        placeholder={t('positions.nameOfCategoryPlaceholder')}
                        autoFocus
                      />
                    </CheckPermission>

                    <CheckPermission variant="enableIf" permissions={['position_criteria_create']}>
                      <Button
                        type="submit"
                        color="primary"
                        style={{ maxHeight: 35 }}
                        className="ml-2"
                        disabled={isSubmitting || !values.name}
                      >
                        {t('common.create')}
                      </Button>
                    </CheckPermission>
                  </div>
                </FormGroup>
              </Form>

              <Fragment>
                {criteriasLoading && (
                  <div className="d-flex justify-content-center p-4">
                    <Spinner loading={criteriasLoading} />
                  </div>
                )}

                {criteriasLoaded && !criterias.length && (
                  <p className="text-center text-muted mb-0 p-4">
                    {t('positions.noPrefilterCriteriaCategoriesFound')}
                  </p>
                )}

                {criterias.length > 0 && (
                  <div className={styles.criteriaCategoriesContainer}>
                    {criterias.map(criteria => (
                      <TagPill
                        key={criteria.id}
                        item={criteria}
                        isDeleteable={hasPermission(['position_criteria_delete'])}
                        handleDelete={() => handleDelete(criteria.id)}
                        isEditable={hasPermission(['position_criteria_update'])}
                        handleEdit={handleEdit}
                      />
                    ))}
                  </div>
                )}

                {!isCriteriaCategories && (
                  <SidebarActionButtons isSubmitting={isSubmitting} showSubmit={false} showBack />
                )}
              </Fragment>
            </Fragment>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  criterias: state.positionCriterias.data.positionCriterias.data?.content || [],
  criteriasLoading: state.positionCriterias.data.positionCriterias.loading,
  criteriasLoaded: state.positionCriterias.data.positionCriterias.loaded,
});

const mapDispatchToProps = {
  createPositionCriteria,
  updatePositionCriteria,
  deletePositionCriteria,
  setPositionSidebarType,
  setQuestionToEdit,
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionQuestionCreateAndEditSidebar);
