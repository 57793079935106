import React from 'react';

import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';

interface Props {
  loadOptions: LoadOptions<any, any>;
  handlePositionChange: Function;
}

const PositionFilterAsync: React.FC<Props> = ({ loadOptions, handlePositionChange }) => {
  const styles = {
    container: (base: any) => ({
      ...base,
      flex: 1,
      maxWidth: 288,
    }),
  };

  return (
    <AsyncPaginate
      styles={styles}
      loadOptions={loadOptions}
      onChange={(option: any) => handlePositionChange(option?.value || null)}
      isClearable
    />
  );
};

export default PositionFilterAsync;
