import React, { Fragment } from 'react';
import { useField, useFormikContext } from 'formik';
import { FormFeedback } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';

import { SelectModel } from 'src/shared/models/common.model';

const ReactCreateableSelect: React.FC<any> = ({ options, ...props }) => {
  const [field, meta] = useField<any>(props);
  const { setFieldValue } = useFormikContext();
  const { name, value, onBlur } = field;
  const { error /* touched */ } = meta;

  // const valid = touched && !error;
  // const invalid = touched && !!error;
  const errorText = error || undefined;

  return (
    <Fragment>
      <CreatableSelect
        name={name}
        options={options}
        value={value}
        onBlur={onBlur}
        onChange={(option: SelectModel) => setFieldValue(name, option || [])}
        {...props}
      />
      {errorText && <FormFeedback>{errorText}</FormFeedback>}
    </Fragment>
  );
};

export default ReactCreateableSelect;
