import { Dispatch } from 'redux';
import {
  getSignedInEmployeeReport as getSignedInEmployeeReportService,
  getNotSignedInEmployeeReport as getNotSignedInEmployeeReportService,
  getSignedInEmployeeList as getSignedInEmployeeListService,
} from '../services/employee-report.service';

export const GET_SIGNED_IN_EMPLOYEE_REPORT = 'employeeReport/GET_SIGNED_IN_EMPLOYEE_REPORT';
export const GET_SIGNED_IN_EMPLOYEE_REPORT_SUCCESS =
  'employeeReport/GET_SIGNED_IN_EMPLOYEE_REPORT_SUCCESS';
export const GET_SIGNED_IN_EMPLOYEE_REPORT_ERROR =
  'employeeReport/GET_SIGNED_IN_EMPLOYEE_REPORT_ERROR';

export const GET_SIGNED_IN_EMPLOYEE_LIST = 'employeeReport/GET_SIGNED_IN_EMPLOYEE_LIST';
export const GET_SIGNED_IN_EMPLOYEE_LIST_SUCCESS =
  'employeeReport/GET_SIGNED_IN_EMPLOYEE_LIST_SUCCESS';
export const GET_SIGNED_IN_EMPLOYEE_LIST_ERROR = 'employeeReport/GET_SIGNED_IN_EMPLOYEE_LIST_ERROR';

export const GET_NOT_SIGNED_IN_EMPLOYEE_REPORT = 'employeeReport/GET_NOT_SIGNED_IN_EMPLOYEE_REPORT';
export const GET_NOT_SIGNED_IN_EMPLOYEE_REPORT_SUCCESS =
  'employeeReport/GET_NOT_SIGNED_IN_EMPLOYEE_REPORT_SUCCESS';
export const GET_NOT_SIGNED_IN_EMPLOYEE_REPORT_ERROR =
  'employeeReport/GET_NOT_SIGNED_IN_EMPLOYEE_REPORT_ERROR';

export const getSignedInEmployeeReport = (appId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_SIGNED_IN_EMPLOYEE_REPORT,
    });

    try {
      const response = await getSignedInEmployeeReportService(appId);
      response.forEach((v, i) => (v.id = i));
      dispatch({
        type: GET_SIGNED_IN_EMPLOYEE_REPORT_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SIGNED_IN_EMPLOYEE_REPORT_ERROR,
        error,
      });
    }
  };
};

export const getSignedInEmployeeList = (appId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_SIGNED_IN_EMPLOYEE_LIST,
    });

    try {
      const response = await getSignedInEmployeeListService(appId);
      response.forEach((v, i) => (v.id = i));
      dispatch({
        type: GET_SIGNED_IN_EMPLOYEE_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_SIGNED_IN_EMPLOYEE_LIST_ERROR,
        error,
      });
    }
  };
};

export const getNotSignedInEmployeeReport = (appId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_NOT_SIGNED_IN_EMPLOYEE_REPORT,
    });

    try {
      const response = await getNotSignedInEmployeeReportService(appId);
      response.forEach((v, i) => (v.id = i));
      dispatch({
        type: GET_NOT_SIGNED_IN_EMPLOYEE_REPORT_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_NOT_SIGNED_IN_EMPLOYEE_REPORT_ERROR,
        error,
      });
    }
  };
};
