import { StateModel } from '../../shared/models/default-state.model';

import {
  GET_CUSTOM_LISTS_REQUEST,
  GET_CUSTOM_LISTS_SUCCESS,
  GET_CUSTOM_LISTS_ERROR,
  GET_CUSTOM_LIST_REQUEST,
  GET_CUSTOM_LIST_SUCCESS,
  GET_CUSTOM_LIST_ERROR,
  CREATE_CUSTOM_LIST_REQUEST,
  CREATE_CUSTOM_LIST_SUCCESS,
  CREATE_CUSTOM_LIST_ERROR,
  UPDATE_CUSTOM_LIST_REQUEST,
  UPDATE_CUSTOM_LIST_SUCCESS,
  UPDATE_CUSTOM_LIST_ERROR,
  DELETE_CUSTOM_LIST_REQUEST,
  DELETE_CUSTOM_LIST_SUCCESS,
  DELETE_CUSTOM_LIST_ERROR,
} from '../actions/custom-lists.action';
import { CustomListModel } from 'src/shared/models/custom-lists.model';

interface CustomListsStateModel {
  resources: StateModel;
  resource: StateModel;
  create: StateModel;
  update: StateModel;
  delete: StateModel;
}

const INITIAL_STATE = {
  resources: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  resource: {
    loading: false,
    loaded: false,
    error: null,
    data: {},
  },
  create: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  update: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  delete: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
};

// eslint-disable-next-line import/prefer-default-export
export const customListsReducer = (state: CustomListsStateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_CUSTOM_LISTS_REQUEST:
      return {
        ...state,
        resources: {
          ...state.resources,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case GET_CUSTOM_LISTS_SUCCESS:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case GET_CUSTOM_LISTS_ERROR:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case GET_CUSTOM_LIST_REQUEST:
      return {
        ...state,
        resource: {
          ...state.resource,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case GET_CUSTOM_LIST_SUCCESS:
      return {
        ...state,
        resource: {
          ...state.resource,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case GET_CUSTOM_LIST_ERROR:
      return {
        ...state,
        resource: {
          ...state.resource,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CREATE_CUSTOM_LIST_REQUEST:
      return {
        ...state,
        create: {
          ...state.create,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case CREATE_CUSTOM_LIST_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: true,
          data: action.payload,
        },
        resources: {
          ...state.resources,
          data: {
            ...state.resources.data,
            content: [...state.resources.data.content, action.payload],
          },
        },
      };

    case CREATE_CUSTOM_LIST_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case UPDATE_CUSTOM_LIST_REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case UPDATE_CUSTOM_LIST_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          loaded: true,
          data: action.payload,
        },
        resources: {
          ...state.resources,
          data: {
            ...state.resources.data,
            content: state.resources.data.content.map((cl: CustomListModel) =>
              cl.id === action.payload.id ? action.payload : cl,
            ),
          },
        },
      };

    case UPDATE_CUSTOM_LIST_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case DELETE_CUSTOM_LIST_REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case DELETE_CUSTOM_LIST_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          loaded: true,
          data: action.payload,
        },
        resources: {
          ...state.resources,
          data: {
            ...state.resources.data,
            content: state.resources.data.content.filter(
              (td: CustomListModel) => td.id !== action.payload.id,
            ),
          },
        },
      };

    case DELETE_CUSTOM_LIST_ERROR:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};
