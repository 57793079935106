import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardBodyProps } from 'reactstrap';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { QueryParams, defaultQueryParams, ListContentModel } from 'src/shared/models';
import { PageHeader, PageHeaderProps } from 'src/shared/components';
import { ListContent } from './ListContent';

interface ListProps<T> extends ListContentModel<T> {
  useCardContent?: boolean;
  createPermission?: string;
  createRedirectPath?: string;
  pageHeaderProps?: PageHeaderProps;
  tableBodyProps?: CardBodyProps;
  hideTableHead?: boolean;
}

export const List = <T extends { id: number } = any>({
  data,
  fetchData,
  loaded,
  updateList,
  createModal,
  filterItems,
  title,
  useCardContent,
  createPermission,
  createRedirectPath,
  pageHeaderProps,
  hideTableHead = false,
  ...props
}: ListProps<T>) => {
  const { search } = useLocation();
  const [queryParams, setQueryParams] = useState<QueryParams>(defaultQueryParams);
  const [activePage, setActivePage] = useState<number>(1);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const noData = loaded && !data.length;

  useEffect(() => {
    const queryValues = queryString.parse(search);

    if (queryValues.page) {
      setActivePage(Number(queryValues.page));
    }

    const sortByField = props.fields?.find(field => field.sort);
    if (sortByField) {
      const sortString = `${sortByField.key},${sortByField.sort}`;
      setQueryParams({ ...defaultQueryParams, sort: sortString });
      queryParams.sort = sortString;
    } else {
      delete queryParams.sort;
    }

    if (props.defaultItemOnPage) {
      setQueryParams({
        ...defaultQueryParams,
        size: props.defaultItemOnPage,
        limit: props.defaultItemOnPage,
      });
      queryParams.size = props.defaultItemOnPage;
      queryParams.limit = props.defaultItemOnPage;
    }

    fetchData(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateList) {
      fetchData(queryParams);
    }
  }, [updateList, fetchData, queryParams]);

  const pageChange = (index: number) => {
    const newQueryParams = {
      ...queryParams,
      page: index - 1,
    };
    setQueryParams(newQueryParams);
    setActivePage(index);

    fetchData(newQueryParams);
    window.scrollTo(0, 0);
  };

  const closeModal = (saved?: boolean) => {
    if (saved) {
      fetchData(queryParams);
    }
    setIsModalOpen(false);
  };

  return (
    <>
      <>
        {title ? (
          <PageHeader
            title={title}
            onCreateButtonClick={!createRedirectPath ? setIsModalOpen : undefined}
            showCreateButton={!!createPermission || !!createRedirectPath}
            {...pageHeaderProps}
          />
        ) : null}

        {useCardContent ? (
          <Card>
            <CardBody className="overflow-auto" {...props.tableBodyProps}>
              {filterItems ? filterItems() : null}
              <ListContent
                data={data}
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                noData={noData}
                fetchData={fetchData}
                activePage={activePage}
                pageChange={pageChange}
                loaded={loaded}
                hideTableHead={hideTableHead}
                {...props}
              />
            </CardBody>
          </Card>
        ) : (
          <>
            {filterItems ? filterItems() : null}
            <ListContent
              data={data}
              queryParams={queryParams}
              setQueryParams={setQueryParams}
              noData={noData}
              fetchData={fetchData}
              activePage={activePage}
              pageChange={pageChange}
              loaded={loaded}
              hideTableHead={hideTableHead}
              {...props}
            />
          </>
        )}

        {createModal ? createModal(isModalOpen, closeModal) : null}
      </>
    </>
  );
};

export default List;
