import React, { FC, useEffect, useState } from 'react';
import RCTimePicker, { TimePickerProps as RCTimePickerProps } from 'rc-time-picker';
import { FieldProps } from 'formik';
import moment from 'moment';

import { useActiveElement } from 'src/shared/hooks';

import styles from './TimePicker.module.scss';

interface TimePickerProps extends FieldProps {
  id?: string;
  name?: string;
}

const TimePicker: FC<TimePickerProps & RCTimePickerProps> = ({
  id,
  name,
  field,
  form,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const ref = React.useRef<RCTimePicker>() as React.MutableRefObject<RCTimePicker>;
  const focusedElement = useActiveElement();

  useEffect(() => {
    const isCurrentComponentFocused = focusedElement === (ref.current as any).picker;

    if (isCurrentComponentFocused) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedElement]);

  const onOpen = (): void => {
    setOpen(true);
    ref.current?.focus();
    const listener = (event: KeyboardEvent) => {
      //ENTER
      if (event.key === 'Enter') {
        event.preventDefault();
        document.removeEventListener('keydown', listener);
        setOpen(false);
      }
    };
    document.addEventListener('keydown', listener);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <RCTimePicker
        ref={ref}
        id={field.name}
        name={field.name}
        className={styles.timePicker}
        popupClassName={styles.timePickerPopUp}
        allowEmpty={false}
        defaultValue={field.value}
        placement="topLeft"
        showSecond={false}
        onChange={(time: moment.Moment) => {
          form.setFieldValue(field.name, time);
        }}
        value={field.value}
        focusOnOpen={true}
        onOpen={onOpen}
        onClose={onClose}
        open={open}
        {...props}
      />
    </>
  );
};

export default TimePicker;
