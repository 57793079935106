import React, { Fragment } from 'react';
import classNames from 'classnames';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { uniqueId } from 'lodash';

import { ListModel, ListRowModel, ListRowsModel } from 'src/shared/models';

import styles from './List.module.scss';
import { ListFields } from './ListFields';

interface ListProps<T> extends ListModel<T>, ListRowModel<T>, ListRowsModel<T> {}

export const ListRows = <T extends { id: number } = any>({
  data,
  onDragEnd,
  highlightFirstRow,
  customRowClassName,
  ...props
}: ListProps<T>) => {
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
    return {
      background: isDragging ? '#d4ebf2 ' : '',
      border: isDragging ? '2px solid #99d0e0 ' : '',
      display: isDragging ? 'table' : '',
      ...draggableStyle,
    };
  };

  const reOrderElements = (sourceIndex: number, destinationIndex: number) => {
    const tempAddedLabels: any[] = Array.from(data);
    const [removed] = tempAddedLabels.splice(sourceIndex, 1);

    tempAddedLabels.splice(destinationIndex, 0, removed);

    return tempAddedLabels;
  };

  const onDragEndFunction = async (result: DropResult) => {
    if (!onDragEnd) {
      return;
    }

    const { source, destination } = result;
    if (!destination || source.index === destination.index) {
      return null;
    }

    const reOrderedItems = reOrderElements(source.index, destination.index);
    const orderedItems = reOrderedItems.map((answer, idx) => ({
      ...answer,
      orderNumber: idx,
    }));

    onDragEnd(orderedItems);
  };

  return (
    <Fragment>
      {onDragEnd && (
        <DragDropContext onDragEnd={onDragEndFunction}>
          <Droppable key={uniqueId('listdroppable-')} droppableId="answers">
            {provided => (
              <tbody ref={provided.innerRef}>
                {data.map((item: T, index) => (
                  <Draggable
                    isDragDisabled={!onDragEnd}
                    draggableId={item.id + ''}
                    index={index}
                    key={item.id + ''}
                  >
                    {(provided, snapshot) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={item.id}
                        className={classNames(
                          styles.tableRow,
                          {
                            [styles.highlightFirstRow]: highlightFirstRow && index === 0,
                          },
                          customRowClassName ? customRowClassName(item) : '',
                        )}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <ListFields item={item} index={index} {...props} />
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {!onDragEnd && (
        <tbody>
          {data.map((item: T, index) => (
            <tr
              key={item.id || uniqueId('item')}
              className={classNames(
                styles.tableRow,
                {
                  [styles.highlightFirstRow]: highlightFirstRow && index === 0,
                },
                customRowClassName ? customRowClassName(item) : '',
              )}
            >
              <ListFields item={item} index={index} {...props} />
            </tr>
          ))}
        </tbody>
      )}
    </Fragment>
  );
};

export default ListRows;
