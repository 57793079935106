import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { AppSwitch } from '@coreui/react';

import { ApplicationModel } from 'src/redux/actions/applications.action';
import { StateModel } from 'src/shared/models/default-state.model';
import {
  setHandoverStatus,
  setConversationsStatus,
  setConversationStatus,
  setCurrentConversation,
  ConversationModel,
} from 'src/redux/actions/conversations.action';
import { getConversationStatus } from 'src/shared/utils/conversations.util';

import styles from '../../../ConversationHistory.module.scss';

interface Props {
  application: ApplicationModel;
  conversations: ConversationModel[];
  currentConversation: ConversationModel;
  platform: string;
  setHandoverStatus: Function;
  setConversationsStatus: Function;
  setConversationStatus: Function;
  setCurrentConversation: Function;
  handoverState: StateModel;
}

const HandoverSwitch: React.FC<Props> = ({
  application,
  conversations,
  currentConversation,
  platform,
  setHandoverStatus,
  setConversationStatus,
  setCurrentConversation,
  handoverState,
}) => {
  const { userId } = useParams()
  const { t } = useTranslation();

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;

    if (userId && application.id && currentConversation) {
      const currentConversationWithStatus = getConversationStatus([
        { ...currentConversation, handoverStatus: checked },
      ]);

      if (currentConversationWithStatus) {
        setConversationStatus(currentConversationWithStatus);
        setCurrentConversation(currentConversationWithStatus[0]);
        setHandoverStatus(userId, checked);
      }
    }
  };

  return (
    <div className={styles.handoverSwitch}>
      <AppSwitch
        className="mr-2"
        variant="pill"
        color="primary"
        checked={currentConversation.handoverStatus}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSwitchChange(e)}
      />
      <span>{t('conversationHistory.muteChatbot')}</span>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  conversations: state.conversations.data.content,
  currentConversation: state.conversations.data.currentConversation,
  platform: state.conversations.data.currentPlatform,
  handoverState: state.conversationHandover,
});

const mapDispatchToProps = {
  setHandoverStatus,
  setConversationsStatus,
  setConversationStatus,
  setCurrentConversation,
};

export default connect(mapStateToProps, mapDispatchToProps)(HandoverSwitch);
