import { StateModel } from '../../shared/models/default-state.model';

import {
  GET_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_REQUEST,
  GET_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_SUCCESS,
  GET_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_ERROR,
  EXPORT_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_REQUEST,
  EXPORT_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_SUCCESS,
  EXPORT_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_ERROR,
  GET_ANALITICS_GROUP_BY_LIST_REQUEST,
  GET_ANALITICS_GROUP_BY_LIST_SUCCESS,
  GET_ANALITICS_GROUP_BY_LIST_ERROR,
  GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_REQUEST,
  GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_SUCCESS,
  GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_ERROR,
  EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_REQUEST,
  EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_SUCCESS,
  EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_ERROR,
  GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_REQUEST,
  GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_SUCCESS,
  GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_ERROR,
  EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_REQUEST,
  EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_SUCCESS,
  EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_ERROR,
} from '../actions/usage-statistics.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export const getDailyCountOfConversationsAndMessagesReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case GET_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case GET_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const getAnalyticsGroupByListReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_ANALITICS_GROUP_BY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case GET_ANALITICS_GROUP_BY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_ANALITICS_GROUP_BY_LIST_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const exportDailyCountOfConversationsAndMessagesReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case EXPORT_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case EXPORT_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case EXPORT_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const getHourlyDistributionOfAllMessagesPercentReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };

    case GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const exportHourlyDistributionOfAllMessagesPercentReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };

    case EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const getHourlyDistributionOfAllMessagesAvgCountReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };

    case GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const exportHourlyDistributionOfAllMessagesAvgCountReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };

    case EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
