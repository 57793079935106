import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import { setSelectedOption } from '../../../redux/actions/account.action';

interface Props extends RouteComponentProps<{ option: string }> {
  setSelectedOption: Function;
}

const AccountSideBar: React.FC<Props> = props => {
  const { t } = useTranslation();

  const isActive = (selectedOption: string) => {
    const paramOption = props.match.params.option;

    return paramOption === selectedOption;
  };

  const renderNavItems = (items: object[]) => {
    return items.map((item: any, index: number) => {
      return (
        <NavLink to={`/account/${item.id}`} style={{ textDecoration: 'none' }} key={item.id}>
          <div
            className={classNames({
              'list-group-item list-group-item-action': true,
              active: isActive(item.id),
            })}
            id={item.id}
            data-toggle="list"
            role="tab"
            tabIndex={index}
            onClick={() => props.setSelectedOption(item.id)}
          >
            {item.name}
          </div>
        </NavLink>
      );
    });
  };

  const navItems = [
    {
      name: `${t('common.changePassword')}`,
      id: 'change-password',
    },
  ];

  return (
    <div className="card">
      <div className="card-header">
        <p className="card-title font-weight-bold mb-0">{t('account.accountSettings')}</p>
      </div>

      <div className="list-group list-group-flush" role="tablist">
        {navItems && navItems.length ? renderNavItems(navItems) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  selectedOption: state.accountSelectedOption.data,
});

const mapDispatchToProps = {
  setSelectedOption,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountSideBar));
