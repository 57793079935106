import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown as ReactButtonDropdown,
} from 'reactstrap';
import { CheckPermission, Spinner } from '.';

interface ButtonDropdownProps {
  titleKey: string;
  items: ButtonDropDownItem[];
  onClick: (id: string) => void;
  permission?: string;
  disabled?: boolean;
  loading?: boolean;
}

export interface ButtonDropDownItem {
  id: string;
  text: string;
  icon: string;
}

const ButtonDropdown: FC<ButtonDropdownProps> = props => {
  const { t } = useTranslation();
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  return (
    <CheckPermission variant="displayIf" permissions={props.permission ? [props.permission] : []}>
      <ReactButtonDropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        direction="down"
        disabled={props.disabled}
      >
        <DropdownToggle caret color="primary">
          <Spinner loading={props.loading || false} className="mr-2" />
          {t(props.titleKey)}
        </DropdownToggle>
        <DropdownMenu>
          {props.items.map((item: ButtonDropDownItem) => (
            <DropdownItem key={item.text} onClick={() => props.onClick(item.id)}>
              <i className={item.icon} /> {item.text}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ReactButtonDropdown>
    </CheckPermission>
  );
};

export default ButtonDropdown;
