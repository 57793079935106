import { get, post, patch, del } from '../../shared/services/http.service';
import { GeneralQueryParams } from '../../shared/models/query-params.model';
import { CustomListModel } from 'src/shared/models/custom-lists.model';

export const getCustomLists = async (applicationId: string, params: GeneralQueryParams) => {
  return get(`/applications/${applicationId}/custom-lists`, { params });
};

export const getCustomList = async (applicationId: string, customListId: number) => {
  return get(`/applications/${applicationId}/custom-lists/${customListId}`);
};

export const createCustomList = async (applicationId: string, customList: CustomListModel) => {
  return post(`/applications/${applicationId}/custom-lists`, { data: customList });
};

export const updateCustomList = async (
  applicationId: string,
  customListId: number,
  customList: CustomListModel,
) => {
  return patch(`/applications/${applicationId}/custom-lists/${customListId}`, { data: customList });
};

export const deleteCustomList = async (applicationId: string, customListId: number) => {
  return del(`/applications/${applicationId}/custom-lists/${customListId}`);
};
