import React from 'react';

import { ExportButton } from 'src/shared/components';

interface Props {
  disabled: boolean;
  exportSubscribers: Function;
  exporting: boolean;
}

const SubscribersExport: React.FC<Props> = (props: Props) => {
  const { disabled, exportSubscribers, exporting } = props;

  return (
    <div className="d-flex justify-content-end align-items-center">
      <ExportButton
        disabled={disabled}
        onClick={() => exportSubscribers()}
        permission="auditlogs_read"
        exporting={exporting}
      />
    </div>
  );
};

export default SubscribersExport;
