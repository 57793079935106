import React, { Component, Fragment } from 'react';
import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';
import TextMessage from './TextMessage';

import styles from './scss/LiveChat.module.scss';

interface Props {
  message: ConversationHistoryModel;
}

interface State {
  text: string;
  buttons: string[];
}

class QuickReply extends Component<Props> {
  state: State = {
    text: '',
    buttons: [],
  };

  componentDidMount() {
    const { message } = this.props;
    const cleanedMessage = message.messageAsText.replace(/\n/g, ' ');
    this.renderTemplate(cleanedMessage);
  }

  renderTemplate(message: any) {
    const buttonsHtml = [];
    let currentMessage = null;

    try {
      currentMessage = JSON.parse(message);
      const buttons = currentMessage.livechatQuickReplies;

      for (let i = 0; i < buttons.length; i += 1) {
        buttonsHtml.push(
          <button key={i} type="button" className={styles.quickReply} disabled>
            {buttons[i].title}
          </button>,
        );
      }

      this.setState({
        text: currentMessage.text || '',
        buttons: buttonsHtml,
      });
    } catch (error) {
      this.setState({
        text: message,
      });
    }
  }

  render() {
    const { message } = this.props;
    const { text, buttons } = this.state;

    return (
      <Fragment>
        {text && <TextMessage message={message} buttonTemplateText={text} />}
        <div className={styles.quickReplies}>{buttons}</div>
      </Fragment>
    );
  }
}

export default QuickReply;
