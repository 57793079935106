import React, { Component, Fragment } from 'react';

import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';
import { generateId } from '../../../../../../../../shared/utils/generate-id.util';

import TextMessage from './TextMessage';

import styles from './scss/Facebook.module.scss';

interface Props {
  message: ConversationHistoryModel;
}

interface State {
  text: string;
  outputTemplate: any;
}

class ButtonTemplate extends Component<Props> {
  state: State = {
    text: '',
    outputTemplate: null,
  };

  componentDidMount() {
    const { message } = this.props;
    // Replacing \n characters with space
    // JSON.parse() would fail otherwise
    const cleanedMessage = message.messageAsText.replace(/\n/g, ' ');
    this.renderTemplate(cleanedMessage);
  }

  async renderTemplate(msg: any) {
    const outputButtons = [];
    const outputTemplate = [];
    let currentTemplate = null;
    let text = '';
    let buttons = null;

    try {
      currentTemplate = JSON.parse(msg);
      // eslint-disable-next-line
      text = currentTemplate.text;
      buttons = currentTemplate.buttonTemplateButtons;

      for (let i = 0; i < buttons.length; i += 1) {
        outputButtons.push(
          <button type="button" key={i} disabled>
            {buttons[i].title}
          </button>,
        );
      }

      outputTemplate.push(outputButtons);

      this.setState({ text, outputTemplate });
    } catch (error) {
      this.setState({ outputTemplate: msg });
    }
  }

  render() {
    const { message } = this.props;
    const { text, outputTemplate } = this.state;

    return (
      <Fragment>
        <TextMessage message={message} buttonTemplateText={text} />
        <div className={styles.buttonTemplate} key={generateId(5)}>
          {outputTemplate}
        </div>
      </Fragment>
    );
  }
}

export default ButtonTemplate;
