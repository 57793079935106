import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// @ts-ignore
import { AppSwitch } from '@coreui/react';
import Select from 'react-select';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { Formik, Field, FormikValues } from 'formik';
import _ from 'lodash';
import moment from 'moment';

import { DateRange } from 'src/shared/components';
import DynamicGroupBy from 'src/shared/components/dynamic-group-by/DynamicGroupBy';
import { configurationConstants } from 'src/shared/constants/configuration.constants';
import SEARCH_TIMER from 'src/shared/constants/filter.constants';
import { PlatformModel } from 'src/shared/models';
import { ConversationHistoryFilters } from 'src/shared/models/conversation-history.model';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';
import { getActivePlatformObjects } from 'src/shared/utils';

interface IConversationHistoryFilterProps {
  platforms: string[];
  filterChanged: Function;
  filter: ConversationHistoryFilters;
  handleDynamicGroupByChange: (grouping: DynamicGroupByModel) => Promise<void>;
}

const ConversationHistoryFilter: FC<IConversationHistoryFilterProps> = props => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [activePlatforms, setActivePlatforms] = useState<PlatformModel[]>([]);

  useEffect(() => {
    if (props.platforms.length !== 0 && activePlatforms.length === 0) {
      const activePlatformObjects = getActivePlatformObjects(props.platforms);
      setActivePlatforms(activePlatformObjects);
    }
  }, [props.platforms, activePlatforms.length]);

  const updateFilter = (values: FormikValues) => {
    props.filterChanged({ filters: values });
  };

  const debouncedCallApi = _.debounce((values: FormikValues) => updateFilter(values), SEARCH_TIMER);

  const handleFilterChange = (values: FormikValues) => {
    debouncedCallApi(values);
    return {};
  };
  
  return (
    <>
      <Formik
        validateOnBlur={false}
        initialValues={props.filter.filters}
        onSubmit={() => {}}
        validate={handleFilterChange}
        validateOnChange
        enableReinitialize
      >
        {({ setFieldValue, values, setValues }) => (
          <Row>
            <Col lg="3">
              <FormGroup>
                <Label>{t('common.date')}</Label>
                <Field
                  startDate={values.startDate}
                  endDate={values.endDate}
                  component={DateRange}
                  isOutsideRange={() => false}
                  handleDateChange={(startDate: moment.Moment, endDate: moment.Moment) => {
                    if (!startDate || !endDate) return null;
                    const from = moment(startDate).utc().startOf('day').toISOString();
                    const to = moment(endDate).utc().endOf('day').toISOString();

                    setValues(
                      {
                        ...values,
                        startDate: from,
                        endDate: to,
                      },
                      true,
                    );
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Platform</Label>
                <Select
                  placeholder={t('common.allPlatforms')}
                  value={values.selectedPlatforms}
                  options={activePlatforms}
                  isDisabled={!activePlatforms.length}
                  isMulti
                  //@ts-ignore
                  onChange={(e: any) => {
                    setValues({ ...values, selectedPlatforms: e }, true);                    
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="1">
              <FormGroup>
                <Label>{t('conversationHistory.showHandoveredUsersOnly')}</Label>
                <AppSwitch
                  name="waiting"
                  className="mr-2"
                  variant="pill"
                  color="warning"
                  checked={values.waiting}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { checked } = e.currentTarget;
                    setValues({ ...values, waiting: checked });
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="5">
              <FormGroup>
                <DynamicGroupBy
                  appId={appId}
                  isMulti
                  handleDynamicGroupByChange={props.handleDynamicGroupByChange}
                  configurationNameSpace={configurationConstants.coreConservationsFilter}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
      </Formik>
    </>
  );
};

export default ConversationHistoryFilter;
