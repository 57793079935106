import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, CardBody, Form, Row, FormGroup, Input, Button, Alert } from 'reactstrap';
import uniqueId from 'lodash/uniqueId';

import hasPermission from 'src/shared/services/permissions.service';

import TagPill from 'src/shared/components/TagPill';
import CheckPermission from 'src/shared/components/CheckPermission';

import styles from '../TrainingData.module.scss';

interface Props {
  sentences: string[];
  sentenceToAdd: string;
  setSentenceToAdd: Function;
  handleSentenceAdd: Function;
  handleSentenceUpdate: Function;
  alertText: string;
}

const TrainingDataCardBody: React.FC<Props> = ({
  sentences,
  sentenceToAdd,
  setSentenceToAdd,
  handleSentenceAdd,
  handleSentenceUpdate,
  alertText,
}) => {
  const { t } = useTranslation();

  return (
    <CardBody>
      {alertText && (
        <Alert color="danger" className="text-center">
          {alertText}
        </Alert>
      )}

      <CheckPermission variant="displayIf" permissions={['training-data_update']}>
        <Form className="mb-3">
          <Row form>
            <Col lg={4}>
              <FormGroup className="d-flex">
                <Input
                  type="text"
                  name="sentence"
                  id="sentence"
                  placeholder={t('trainingData.addASentence')}
                  value={sentenceToAdd}
                  onChange={e => setSentenceToAdd(e.target.value)}
                />
                <Button
                  className="ml-2"
                  disabled={!sentenceToAdd}
                  onClick={() => handleSentenceAdd()}
                >
                  {t('common.add')}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CheckPermission>

      <div className={styles.tagPillContainer}>
        {sentences.map(sentence => (
          <TagPill
            key={uniqueId('sentence-')}
            item={sentence}
            isDeleteConfirmRequired={false}
            isDeleteable={hasPermission(['training-data_update'])}
            isEditable={hasPermission(['training-data_update'])}
            handleEdit={handleSentenceUpdate}
            handleDelete={handleSentenceUpdate}
          />
        ))}
      </div>
    </CardBody>
  );
};

export default TrainingDataCardBody;
