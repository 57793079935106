import React, { Fragment } from 'react';
import i18n from 'src/i18n';
import { UserModel } from 'src/shared/models/user.model';
import { renderDifference } from 'src/views/AuditLog/helpers/renderHelpers';

interface Props {
  updatedUser: UserModel;
  savedUser: UserModel;
}

const UpdatedUser: React.FC<Props> = ({ updatedUser, savedUser }) => {
  return (
    <Fragment>
      <p className="font-weight-bold">{i18n.t('auditLog.detailsOfUpdatedUser')}</p>

      <table className="table table-sm">
        <tbody>
          <tr>
            <td>{i18n.t('common.id')}</td>
            <td>{updatedUser.id}</td>
          </tr>
          <tr>
            <td>{i18n.t('common.firstName')}</td>
            <td>{renderDifference(updatedUser.firstName, savedUser.firstName)}</td>
          </tr>
          <tr>
            <td>{i18n.t('common.lastName')}</td>
            <td>{renderDifference(updatedUser.lastName, savedUser.lastName)}</td>
          </tr>
          <tr>
            <td>{i18n.t('common.email')}</td>
            <td>{renderDifference(updatedUser.email, savedUser.email)}</td>
          </tr>
          <tr>
            <td>{i18n.t('common.userGroup')}</td>
            <td>{renderDifference(updatedUser.userGroupName, savedUser.userGroupName)}</td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

export default UpdatedUser;
