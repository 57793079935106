/* eslint-disable no-eval */
import React, { Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';

import { ConversationModel } from '../../../../../redux/actions/conversations.action';

import styles from '../../../ConversationHistory.module.scss';

import LiveChatLogo from '../../../../../assets/img/platforms/livechat.png';
import userAvatar from '../../../../../assets/img/avatars/user.svg';
import { ApplicationModel } from 'src/redux/actions/applications.action';
import { uniqueId } from 'lodash';

import {
  ConversationHistoryModel,
} from '../../../../../redux/actions/conversation-history.action';


interface Props extends RouteComponentProps<{ userId: string; }> {
  conversations: ConversationModel[];
  handleConversationSelect: Function;
  application: ApplicationModel;
  conversationHistory: ConversationHistoryModel[];
}

const renderPlatformLogo = (platform: string) => {
  switch (platform) {
    case 'Livechat':
      return <img src={LiveChatLogo} className={styles.liveChat} alt="LiveChat Logo" />;
    case 'HangoutsChat':
      return <i className={`${styles.googleHangouts} fab fa-google`} />;
    case 'Facebook':
      return <i className={`${styles.facebookMessenger} fab fa-facebook-messenger`} />;
    case 'Viber':
      return <i className={`${styles.viber} fab fa-viber`} />;

    default:
      return null;
  }
};

const renderStatusIcon = (status: number) => {
  switch (status) {
    case 0:
      return <span className={`${styles.statusIcon} ${styles.offline}`} />;
    case 1:
      return <span className={`${styles.statusIcon} ${styles.online}`} />;
    case 2:
      return <span className={`${styles.statusIcon} ${styles.waiting}`} />;
    default:
      return null;
  }
};

const getLastUsageTime = (uuid:string, messages:ConversationHistoryModel[]) => {
  for (let i = messages.length - 1; i >= 0; i--) {
    if (messages[i].uuid === uuid) {
      return messages[i].creationTimestamp;
    }
  }

  return null
};

const SidebarContactRow: React.FC<Props> = (props: Props) => {
  const { conversations, handleConversationSelect, conversationHistory } = props;

  const isConversationSelected = (uuid: string) => {
    const { userId } = props.match.params;
    return uuid === userId;
  };

  const CSSstring = (string: string) => {
    if (!string) {
      return {};
    }
    const cssJson = `{"${string.replace(/; /g, '", "').replace(/: /g, '": "').replace(';', '')}"}`;
    const obj = JSON.parse(cssJson);

    const keyValues = Object.keys(obj).map(key => {
      const camelCased = key.replace(/-[a-z]/g, g => g[1].toUpperCase());
      return { [camelCased]: obj[key] };
    });

    return Object.assign({}, ...keyValues);
  };

  const renderNormalFields = (conversation: ConversationModel) => {
    return (
      <p className={styles.userName}>
        {conversation.name}
        {conversation?.employee?.key && <> [{conversation?.employee.key}]</>}
      </p>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderCustomFields = (element: ConversationModel) => {
    const { application } = props;

    if (!application || !application.dashboardConfiguration) {
      return '';
    }

    const configItem = JSON.parse(application.dashboardConfiguration).find(
      (config: { [key: string]: string; }) => config.namespace === 'core.conversations.list',
    );
    if (!configItem) {
      return renderNormalFields(element);
    }

    const columns = configItem?.configuration?.columns;
    if (!columns) {
      return renderNormalFields(element);
    }

    const safeEval = (script: string) => {
      try {
        return eval(script);
      } catch (e) {
        console.error(e);
      }
    };

    return (
      <div>
        {columns.map(
          (col: { [key: string]: string; }) =>
            (col.showBlockIfNull || safeEval(col.evalScript)) && (
              <Fragment key={uniqueId('renderCustomFields')}>
                {col.showTitle && (
                  <div style={CSSstring(col?.blockStyle)}>
                    <span style={CSSstring(col?.titleStyle)}>{col.title}: </span>
                    <span style={CSSstring(col?.textStyle)}>
                      {safeEval(col.evalScript) || col?.placeHolderText}
                    </span>
                  </div>
                )}
                {!col.showTitle && (
                  <span style={CSSstring(col?.textStyle)}>
                    {`${safeEval(col.evalScript)} ` || '-'}
                  </span>
                )}
              </Fragment>
            ),
        )}
      </div>
    );
  };

  const addedUuids = new Set();

  return (
    <Fragment>
      {conversations.map((conversation: ConversationModel, i: number) => {

        if (addedUuids.has(conversation.uuid)) {
          return null;
        }
        addedUuids.add(conversation.uuid);

        const rowClasses = classNames(
          styles.sidebarRow,
          isConversationSelected(conversation.uuid) && styles.selectedRow,
        );

        const lastUsageTime = getLastUsageTime(conversation.uuid, conversationHistory) || conversation.lastChatbotTimestamp || conversation.creationTimestamp;

        return (
          <div
            key={uniqueId(conversation.uuid)}
            role="button"
            tabIndex={i}
            className={rowClasses}
            onClick={() => handleConversationSelect(conversation)}
          >
            <img src={userAvatar} alt="User Avatar" />
            {renderStatusIcon(conversation.status)}
            <div className={styles.messageInfo}>
              {!isConversationSelected(conversation.uuid) && renderNormalFields(conversation)}
              {isConversationSelected(conversation.uuid) && renderCustomFields(conversation)}
              <p className={styles.dateOfLastMessage}>
                {moment(lastUsageTime).fromNow()}
              </p>
            </div>
            <span className={styles.platformLogo}>
              {renderPlatformLogo(conversation.messagingPlatform)}
            </span>
          </div>
        );
      })}
    </Fragment>
  );
};

export default withRouter(SidebarContactRow);
