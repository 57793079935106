import React, { useEffect, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import {
  ListSortDirections,
  PageQueryParams,
  QueryParams,
  TicketFeedbackTypeModel,
  TicketModel,
  TicketStatusTypeModel,
  TicketFilters,
  HelpdeskGroupModel,
} from 'src/shared/models';

import { getTickets } from 'src/redux/actions/tickets.action';
import { getGroups as getGroupsService } from 'src/redux/services/ticket.service';
import { List } from 'src/shared/components';
import TicketFilter from './TicketFilter';
import { Button, UncontrolledTooltip } from 'reactstrap';
import TicketStatusHistoryModal from './TicketStatusHistoryModal';

const ellipsisLength = 60;

interface ITicketsProps {
  application: ApplicationModel;
  getApplication: Function;
  getTickets: (appId: string, queryParams: PageQueryParams, filter: TicketFilters) => void;
  tickets: TicketModel[];
  numberOfTickets: number;
  ticketsLoading: boolean;
  ticketsLoaded: boolean;
  ticketsError: boolean;
}

const Tickets: FC<ITicketsProps> = ({
  application,
  getApplication,
  getTickets,
  tickets,
  numberOfTickets,
  ticketsLoading,
  ticketsLoaded,
  ticketsError,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [queryParamsDef, setQueryParamsDef] = useState<QueryParams>({
    page: 0,
    size: 50,
    limit: 50,
  });
  const [filter, setFilter] = useState<TicketFilters>({
    answererEmployeeKey: '',
    questionerEmployeeKey: '',
    groups: '',
    status: '',
    from: '',
    to: '',
  });
  const [groups, setGroups] = useState<HelpdeskGroupModel[]>([]);
  const [isStatusHistoryModalOpen, setStatusHistoryModalOpen] = useState<boolean>(false);
  const [historyItem, setHistoryItemOpen] = useState<TicketModel | null>(null);

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    refreshGroups();
    /* eslint-disable-next-line */
  }, [appId]);

  useEffect(() => {
    fetchTikets(queryParamsDef);
    /* eslint-disable-next-line */
  }, [filter]);

  const refreshGroups = async () => {
    const groups = await getGroupsService(appId);
    setGroups(groups);
  };

  const fetchTikets = async (queryParams: QueryParams) => {
    setQueryParamsDef(queryParams);
    getTickets(appId, queryParams, filter);
  };

  const handleDateFilterChange = (filterValues: TicketFilters): void => {
    setFilter(filterValues);
  };

  const statusHistoryShow = (ticket: TicketModel) => {
    setHistoryItemOpen(ticket);
    setStatusHistoryModalOpen(true);
  };

  const getLineClass = (status: string | undefined) => {
    if (!status) {
      return '';
    }

    if (['DELIVERED_ANSWER', 'DONE'].indexOf(status) !== -1) {
      return 'bg-success';
    }
    if (['ANSWERED', 'WAITING'].indexOf(status) !== -1) {
      return 'bg-warning';
    }
    if (['MISSING_QUESTIONER'].indexOf(status) !== -1) {
      return 'bg-secondary';
    }

    return '';
  };

  return (
    <div className="animated fadeIn">
      <List
        useCardContent
        data={tickets}
        totalElements={numberOfTickets}
        loaded={ticketsLoaded}
        loading={ticketsLoading}
        error={ticketsError}
        fetchData={fetchTikets}
        title={t('common.tickets')}
        filterItems={() => (
          <TicketFilter filterChanged={handleDateFilterChange} filter={filter} groups={groups} />
        )}
        customRowClassName={(item: TicketModel) => getLineClass(item?.currentStatus?.status)}
        fields={[
          { key: 'id', label: t('common.id') },
          {
            key: 'createdAt',
            label: t('common.created'),
            dateTime: true,
            sort: ListSortDirections.DESC,
            columnStyle: 'user-select-none',
          },
          {
            key: 'questionerEmployee',
            label: t('tickets.questionerEmployee'),
            render: item =>
              `${item?.questionerEmployee?.key} ${item?.questionerEmployee?.attributes
                ?.map(attribute => attribute.value)
                .join(' ')}`,
          },
          { key: 'message', label: t('common.question'), ellipsis: ellipsisLength },
          {
            key: 'answer',
            label: t('common.answer'),
            render: (item, index) => {
              const answer = item.ticketTimeline?.find(
                timeLine => timeLine.status === TicketStatusTypeModel.ANSWERED,
              )?.metadata?.answer;
              return (
                answer && (
                  <>
                    <span id={`ellipsis-${index}`}>
                      {answer.substr(0, ellipsisLength)}
                      {answer.length > ellipsisLength ? '...' : ''}
                    </span>
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`ellipsis-${index}`}
                      trigger="hover"
                    >
                      {answer}
                    </UncontrolledTooltip>
                  </>
                )
              );
            },
          },
          { key: 'helpdeskGroupName', label: t('tickets.group') },
          {
            key: 'answererEmployee',
            label: t('tickets.answererEmployee'),
            render: item =>
              `${item?.answererEmployee?.key || ''} ${
                item?.answererEmployee?.attributes?.map(attribute => attribute.value).join(' ') ||
                ''
              }`,
          },
          {
            key: 'currentStatus',
            label: t('common.status'),
            render: item => item.currentStatus?.status,
          },
          {
            key: 'tickets.statusHistory',
            label: t('tickets.statusHistory'),
            render: item => (
              <Button onClick={() => statusHistoryShow(item)} className="btn btn-sm text-center">
                <i className="fas fa-history " />
              </Button>
            ),
          },
          { key: 'currentStatus.updatedAt', label: t('tickets.statusCreated'), dateTime: true },
          {
            key: 'currentStatus.status',
            label: t('tickets.feedback'),
            render: item => {
              return (
                item?.currentStatus?.status === TicketStatusTypeModel.DONE &&
                item?.currentStatus?.metadata?.feedback && (
                  <div className="text-center">
                    <Button
                      className={`btn btn-sm btn-light ${
                        item.currentStatus.metadata.feedback === TicketFeedbackTypeModel.POSITIVE
                          ? 'text-success'
                          : 'text-danger'
                      }`}
                    >
                      <i
                        className={`fas ${
                          item.currentStatus.metadata.feedback === TicketFeedbackTypeModel.POSITIVE
                            ? 'fa-plus'
                            : 'fa-minus'
                        }`}
                      />
                    </Button>
                  </div>
                )
              );
            },
          },
        ]}
        defaultItemOnPage={50}
        noDataLabel={t('tickets.noTickets')}
      />
      <TicketStatusHistoryModal
        isOpen={isStatusHistoryModalOpen}
        toggleModalState={setStatusHistoryModalOpen}
        editDocument={historyItem}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    tickets: state.tickets.data.content,
    numberOfTickets: state.tickets.data.totalElements,
    ticketsLoading: state.tickets.loading,
    ticketsLoaded: state.tickets.loaded,
    ticketsError: state.tickets.error,
  };
};

const mapDispatchToProps = {
  getApplication,
  getTickets,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
