import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';

import { getAveragedIssueReplyTimeByDashboardUserReport } from 'src/redux/actions/issue-reports.action';
import { exportAveragedIssueReplyTimeReport } from 'src/redux/services/issue-report.service';

import { default as DateRangeFilter } from '../UsageStatistics/components/Filter';
import { ExportButton, List } from 'src/shared/components';

import { DateRangeQueryParams } from 'src/shared/models';
import { AveragedIssueReplyTimeReportEntry, AveragedIssueReplyTimeReports } from 'src/shared/models/issue-reports.model';

import { configurationConstants } from 'src/shared/constants/configuration.constants';
import { getListFieldsByConfiguration } from 'src/shared/utils/configuration.util';

import saveFile from 'src/shared/utils/file-saver.util';
import { fileNames } from 'src/shared/settings';

interface Props {
    averagedIssueReplyTimeByDashboardUserReport: AveragedIssueReplyTimeReports;
    application: ApplicationModel;
    loaded: boolean,
    loading: boolean,
    error: boolean,
    getApplication: Function;
    getAveragedIssueReplyTimeByDashboardUserReport: (appId: string, queryParams: DateRangeQueryParams) => void;
}

const IssueReportReplyTimeAveragesByDashboardUser: FC<Props> = ({
    averagedIssueReplyTimeByDashboardUserReport,
    application,
    loaded,
    loading,
    error,
    getApplication,
    getAveragedIssueReplyTimeByDashboardUserReport
}) => {
    const { t } = useTranslation();
    const { appId } = useParams<{ appId: string; }>();
    const [queryParams, setQueryParams] = useState<DateRangeQueryParams>({
        from: moment().utc().subtract(7, 'days').startOf('day').toISOString(),
        to: moment().utc().endOf('day').toISOString(),
    });

    useEffect(() => {
        if (!application.id) {
            getApplication(appId);
        }
        /* eslint-disable-next-line */
    }, []);

    useEffect(() => {
        fetchItems();
        /* eslint-disable-next-line */
    }, [queryParams]);

    const fetchItems = async () => {
        return getAveragedIssueReplyTimeByDashboardUserReport(appId, queryParams);
    };

    const configuredFields = useMemo(() => {
        return getListFieldsByConfiguration(
            application,
            configurationConstants.coreIssueReportsAveragedIssueReplyTimeByDashboardUserReportList,
            t,
        );
    }, [application, t]);

    const fields = [
        ...configuredFields,
        {
            key: 'average',
            label: t('issueReports.average'),
            render: (item: AveragedIssueReplyTimeReportEntry)  => {
                const years = moment.duration(item.average, 's').years();
                const months = moment.duration(item.average, 's').months();
                const days = moment.duration(item.average, 's').days();
                const hours = moment.duration(item.average, 's').hours();
                const minutes = moment.duration(item.average, 's').minutes();

                return `${years > 0 ? `${years} ${t('issueReports.year')} ` : ''}${months > 0 ? `${months} ${t('issueReports.month')} ` : ''}${days > 0 ? `${days} ${t('issueReports.day')} ` : ''}${hours > 0 ? `${hours} ${t('issueReports.hour')} ` : ''}${minutes > 0 ? `${minutes} ${t('issueReports.minute')} ` : ''}`;
            }
        }
            
    ] 

    const handleDateChange = async (from: string, to: string) => {
        setQueryParams(
            Object.assign(queryParams, {
                from: moment(from).startOf('day').toISOString(),
                to: moment(to).endOf('day').toISOString(),
            }),
        );
    };

    const exportReport = async () => {
        const response = await exportAveragedIssueReplyTimeReport(appId, queryParams);

        if (response) {
            saveFile(response, fileNames.ISSUE_AVERAGE_REPLY_TIME_REPORT_EXCEL, 'xlsx');
        }
    };

    return (
        <div className="animated fadeIn">
            <Row>
                <Col>
                    <h4>{t('issueReports.averagedReplyTimeReportsByDashboardUser')}</h4>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col className="d-flex justify-content-start">
                    <DateRangeFilter handleDateChange={handleDateChange} getAllData={fetchItems} />
                </Col>
                <Col className="d-flex justify-content-end">
                    <ExportButton
                        className="ml-2"
                        disabled={averagedIssueReplyTimeByDashboardUserReport === undefined || averagedIssueReplyTimeByDashboardUserReport.length === 0}
                        onClick={exportReport}
                        permission="issue_issue_report_read"
                        tooltipProps={{ target: 'exportButton', placement: 'left' }}
                        exportTooltipLabel={t('common.exportToExcel')}
                    />
                </Col>
            </Row>
            <Card>
                <CardBody>
                    <List
                        hidePager
                        data={averagedIssueReplyTimeByDashboardUserReport}
                        totalElements={averagedIssueReplyTimeByDashboardUserReport.length}
                        loaded={loaded}
                        loading={loading}
                        error={error}
                        noDataLabel={t('issueReports.noData')}
                        fetchData={() => { }}
                        fields={fields}
                    />
                </CardBody>
            </Card>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        averagedIssueReplyTimeByDashboardUserReport: state.issueReports.data.averagedIssueReplyTimeByDashboardUserReport,
        application: state.application.data,
        loaded: state.issueReports.loaded,
        loading: state.issueReports.loading,
        error: state.issueReports.error
    };
};

const mapDispatchToProps = {
    getApplication,
    getAveragedIssueReplyTimeByDashboardUserReport
};

export default connect(mapStateToProps, mapDispatchToProps)(IssueReportReplyTimeAveragesByDashboardUser);