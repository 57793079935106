import React, { Component } from 'react';
import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';
import TextMessage from './TextMessage';
interface Props {
  message: ConversationHistoryModel;
}

interface State {
  text: string;
}

class MultiListResponse extends Component<Props, State> {
  state: State = {
    text: '',
  };

  componentDidMount() {
    const { message } = this.props;
    this.renderTemplate(message);
  }

  renderTemplate = (msg: any) => {
    try {
      const message: any = JSON.parse(msg.messageAsText);
      const { selectedOptions } = message;

      if (selectedOptions && selectedOptions.length) {
        let text: string = '';

        selectedOptions.map((option: any) => {
          text += `${option.name} ${option.value}\n`;
          return text;
        });

        this.setState({
          text,
        });
      } else {
        this.setState({
          text: 'No option was selected.',
        });
      }
    } catch (error) {
      this.setState({
        text: error,
      });
    }
  };

  render() {
    const { text } = this.state;
    const { message } = this.props;

    return <TextMessage message={message} buttonTemplateText={text} />;
  }
}

export default MultiListResponse;
