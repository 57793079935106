import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

interface Props {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const PositionEditWarningModal: React.FC<Props> = ({ isOpen, onConfirm, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} toggle={onCancel}>
      <ModalHeader toggle={onCancel}>{t('common.warning')}</ModalHeader>
      <ModalBody style={{ whiteSpace: 'pre-line' }}>
        {t('positions.positionEditWarningMessage')}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onConfirm}>
          {t('common.continue')}
        </Button>
        <Button color="secondary" onClick={onCancel}>
          {t('common.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PositionEditWarningModal;
