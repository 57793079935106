import { useSortable } from '@dnd-kit/sortable';
import { FC } from 'react';
import Thumbnail, { ThumbnailProps } from './Thumbnail';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';

export interface SortableThumbnailProps extends ThumbnailProps {}

const SortableThumbnail: FC<SortableThumbnailProps> = props => {
  const sortable = useSortable({ id: props.id });
  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = sortable;

  const cstyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Thumbnail
      ref={setNodeRef}
      style={cstyle}
      faded={isDragging}
      {...props}
      {...attributes}
      {...listeners}
    />
  );
};

export default SortableThumbnail;
