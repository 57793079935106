import { Dispatch } from 'redux';
import { PersonaModel } from 'src/shared/models/persona.model';
import {
  getPersonas as getPersonasService,
  getPersona as getPersonaService,
  createPersona as createPersonaService,
  updatePersona as updatePersonaService,
  deletePersona as deletePersonaService,
} from '../services/personas.service';

export const GET_PERSONAS_REQUEST = 'personas/GET_PERSONAS_REQUEST';
export const GET_PERSONAS_SUCCESS = 'personas/GET_PERSONAS_SUCCESS';
export const GET_PERSONAS_ERROR = 'personas/GET_PERSONAS_ERROR';

export const GET_PERSONA_REQUEST = 'personas/GET_PERSONA_REQUEST';
export const GET_PERSONA_SUCCESS = 'personas/GET_PERSONA_SUCCESS';
export const GET_PERSONA_ERROR = 'personas/GET_PERSONA_ERROR';

export const CREATE_PERSONA_REQUEST = 'personas/CREATE_PERSONA_REQUEST';
export const CREATE_PERSONA_SUCCESS = 'personas/CREATE_PERSONA_SUCCESS';
export const CREATE_PERSONA_ERROR = 'personas/CREATE_PERSONA_ERROR';

export const UPDATE_PERSONA_REQUEST = 'personas/UPDATE_PERSONA_REQUEST';
export const UPDATE_PERSONA_SUCCESS = 'personas/UPDATE_PERSONA_SUCCESS';
export const UPDATE_PERSONA_ERROR = 'personas/UPDATE_PERSONA_ERROR';

export const DELETE_PERSONA_REQUEST = 'personas/DELETE_PERSONA_REQUEST';
export const DELETE_PERSONA_SUCCESS = 'personas/DELETE_PERSONA_SUCCESS';
export const DELETE_PERSONA_ERROR = 'personas/DELETE_PERSONA_ERROR';

export const SET_PERSONA = 'personas/SET_PERSONA';

export const getPersonas = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_PERSONAS_REQUEST,
    });

    try {
      dispatch({
        type: GET_PERSONAS_SUCCESS,
        payload: await getPersonasService(),
      });
    } catch (error) {
      dispatch({
        type: GET_PERSONAS_ERROR,
        error,
      });
    }
  };
};

export const getPersona = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_PERSONA_REQUEST,
    });

    try {
      dispatch({
        type: GET_PERSONA_SUCCESS,
        payload: await getPersonaService(id),
      });
    } catch (error) {
      dispatch({
        type: GET_PERSONA_ERROR,
        error,
      });
    }
  };
};

export const createPersona = (persona: PersonaModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_PERSONA_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_PERSONA_SUCCESS,
        payload: await createPersonaService(persona),
      });
    } catch (error) {
      dispatch({
        type: CREATE_PERSONA_ERROR,
        error,
      });
    }
  };
};

export const updatePersona = (id: number, persona: PersonaModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_PERSONA_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_PERSONA_SUCCESS,
        payload: await updatePersonaService(id, persona),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PERSONA_ERROR,
        error,
      });
    }
  };
};

export const deletePersona = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_PERSONA_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_PERSONA_SUCCESS,
        payload: await deletePersonaService(id),
      });
    } catch (error) {
      dispatch({
        type: DELETE_PERSONA_ERROR,
        error,
      });
    }
  };
};

export const setPersona = (persona: PersonaModel) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_PERSONA,
      payload: persona,
    });
  };
};
