import { StateModel } from 'src/shared/models';
import {
  GET_HELPDESK_INSTANT_ANSWERS,
  GET_HELPDESK_INSTANT_ANSWERS_ERROR,
  GET_HELPDESK_INSTANT_ANSWERS_SUCCESS,
  DELETE_HELPDESK_INSTANT_ANSWER,
  DELETE_HELPDESK_INSTANT_ANSWER_SUCCESS,
  DELETE_HELPDESK_INSTANT_ANSWER_ERROR,
} from '../actions/helpdesk-instant-answers.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const helpdeskInstantAnswersReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case GET_HELPDESK_INSTANT_ANSWERS:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_HELPDESK_INSTANT_ANSWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload,
      };
    case GET_HELPDESK_INSTANT_ANSWERS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };
    case DELETE_HELPDESK_INSTANT_ANSWER:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case DELETE_HELPDESK_INSTANT_ANSWER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          content: state.data.content.filter(({ id }: any) => id !== payload.helpdeskGroupId),
        },
      };
    case DELETE_HELPDESK_INSTANT_ANSWER_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };

    default:
      return state;
  }
};

export default helpdeskInstantAnswersReducer;
