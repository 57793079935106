import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Button } from 'reactstrap';

import { CustomListItemModel } from 'src/shared/models/custom-lists.model';

import CheckPermission from 'src/shared/components/CheckPermission';

import styles from '../CustomListItems.module.scss';

interface Props {
  customListItems: CustomListItemModel[];
  setModalOpen: Function;
  setConfirmModalOpen: Function;
  setModalEvent: Function;
  setSelectedCustomListItem: Function;
}

const CustomListItemsTable: React.FC<Props> = ({
  customListItems,
  setModalOpen,
  setConfirmModalOpen,
  setModalEvent,
  setSelectedCustomListItem,
}) => {
  const { t } = useTranslation();

  const renderBodyRows = () => {
    return customListItems.map(cli => (
      <tr key={cli.id}>
        <td className={styles.name}>{cli.name}</td>
        <td className={styles.parent}>{cli.parent?.name}</td>
        <td className={styles.operations}>
          <CheckPermission variant="enableIf" permissions={['customlist_custom-lists-item_update']}>
            <Button
              size="sm"
              className="mr-2"
              onClick={() => {
                setModalOpen(true);
                setModalEvent('edit');
                setSelectedCustomListItem(cli);
              }}
            >
              <i className="far fa-edit" />
            </Button>
          </CheckPermission>

          <CheckPermission variant="enableIf" permissions={['customlist_custom-lists-item_delete']}>
            <Button
              color="danger"
              size="sm"
              onClick={() => {
                setConfirmModalOpen(true);
                setSelectedCustomListItem(cli);
              }}
            >
              <i className="far fa-trash-alt" />
            </Button>
          </CheckPermission>
        </td>
      </tr>
    ));
  };

  return (
    <Table striped hover size="sm" className={styles.customListItemsTable}>
      <thead>
        <tr>
          <th className={styles.name}>{t('common.name')}</th>
          <th className={styles.parent}>{t('common.parent')}</th>
          <th className={styles.operations}>{t('common.operations')}</th>
        </tr>
      </thead>

      <tbody>{renderBodyRows()}</tbody>
    </Table>
  );
};

export default CustomListItemsTable;
