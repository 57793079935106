import React from 'react';
import { TagModel } from 'src/shared/models';

import styles from './Tags.module.scss';

interface Props {
  tag: TagModel;
  setSelectedTag: Function;
  toggleModal: Function;
  toggleConfirmModal: Function;
}

const TagPill: React.FC<Props> = ({ tag, setSelectedTag, toggleModal, toggleConfirmModal }) => {
  return (
    <div className={styles.tagPillContainer}>
      <div className={styles.tagPill}>
        <span>{tag.name}</span>
      </div>
      <div className={styles.tagPillOperations}>
        <button
          type="button"
          className={styles.tagPillButton}
          onClick={() => {
            setSelectedTag(tag);
            toggleModal('edit');
          }}
        >
          <i className="far fa-edit" />
        </button>
        <button
          type="button"
          className={styles.tagPillButton}
          onClick={() => {
            setSelectedTag(tag);
            toggleConfirmModal();
          }}
        >
          <i className="far fa-trash-alt" />
        </button>
      </div>
    </div>
  );
};

export default TagPill;
