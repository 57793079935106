import { Dispatch } from 'redux';
import { QueryParams } from '../../shared/models/query-params.model';
import {
  getUnrecognizedSentences as getUnrecognizedSentencesService,
  exportUnrecognizedSentences as exportUnrecognizedSentencesService,
  resetUnrecognizedSentences as resetUnrecognizedSentencesService,
} from '../services/unrecognized-sentences.service';

export const GET_UNRECOGNIZED_SENTENCES_REQUEST =
  'unrecognized-sentences/GET_UNRECOGNIZED_SENTENCES_REQUEST';
export const GET_UNRECOGNIZED_SENTENCES_SUCCESS =
  'unrecognized-sentences/GET_UNRECOGNIZED_SENTENCES_SUCCESS';
export const GET_UNRECOGNIZED_SENTENCES_ERROR =
  'unrecognized-sentences/GET_UNRECOGNIZED_SENTENCES_ERROR';

export const EXPORT_UNRECOGNIZED_SENTENCES_REQUEST =
  'unrecognized-sentences/EXPORT_UNRECOGNIZED_SENTENCES_REQUEST';
export const EXPORT_UNRECOGNIZED_SENTENCES_SUCCESS =
  'unrecognized-sentences/EXPORT_UNRECOGNIZED_SENTENCES_SUCCESS';
export const EXPORT_UNRECOGNIZED_SENTENCES_ERROR =
  'unrecognized-sentences/EXPORT_UNRECOGNIZED_SENTENCES_ERROR';

export const RESET_UNRECOGNIZED_SENTENCES_REQUEST =
  'unrecognized-sentences/RESET_UNRECOGNIZED_SENTENCES_REQUEST';
export const RESET_UNRECOGNIZED_SENTENCES_SUCCESS =
  'unrecognized-sentences/RESET_UNRECOGNIZED_SENTENCES_SUCCESS';
export const RESET_UNRECOGNIZED_SENTENCES_ERROR =
  'unrecognized-sentences/RESET_UNRECOGNIZED_SENTENCES_ERROR';

export interface UnrecognizedSentencesModel {
  id: number;
  createdAt: string;
  sentence: string;
  entityInstances: Object;
  status: string;
  lastMenuItem: string;
  userId?: number;
}

export const getUnrecognizedSentences = (applicationId: number, queryParams: QueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_UNRECOGNIZED_SENTENCES_REQUEST,
    });

    try {
      const unrecognizedSentences: UnrecognizedSentencesModel[] = await getUnrecognizedSentencesService(
        applicationId,
        queryParams,
      );

      dispatch({
        type: GET_UNRECOGNIZED_SENTENCES_SUCCESS,
        payload: unrecognizedSentences,
      });
    } catch (error) {
      dispatch({
        type: GET_UNRECOGNIZED_SENTENCES_ERROR,
        error,
      });

      throw error;
    }
  };
};

export const exportUnrecognizedSentences = (applicationId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EXPORT_UNRECOGNIZED_SENTENCES_REQUEST,
    });

    try {
      const payload = await exportUnrecognizedSentencesService(applicationId);

      dispatch({
        type: EXPORT_UNRECOGNIZED_SENTENCES_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: EXPORT_UNRECOGNIZED_SENTENCES_ERROR,
        error,
      });

      throw error;
    }
  };
};

export const resetUnrecognizedSentences = (applicationId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: RESET_UNRECOGNIZED_SENTENCES_REQUEST,
    });

    try {
      const payload = await resetUnrecognizedSentencesService(applicationId);

      dispatch({
        type: RESET_UNRECOGNIZED_SENTENCES_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: RESET_UNRECOGNIZED_SENTENCES_ERROR,
        error,
      });

      throw error;
    }
  };
};
