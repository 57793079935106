import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Col,
  Label,
} from 'reactstrap';
import { Formik, Form } from 'formik';

import { createTag, updateTag } from 'src/redux/actions/employee.action';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { StateModel, TagModel, TagCategoryModel } from 'src/shared/models';
import { TextField, Spinner } from 'src/shared/components';
import { tagSchema } from 'src/shared/schemas/validation.schema';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  isOpen: boolean;
  toggleModal: Function;
  event: string | undefined;
  getCategoryTags: Function;
  createTag: Function;
  create: StateModel;
  updateTag: Function;
  update: StateModel;
  selectedTag: TagModel | undefined;
  selectedTagCategory: TagCategoryModel | undefined;
}

const TagModal: React.FC<Props> = ({
  application,
  getApplication,
  isOpen,
  toggleModal,
  event,
  getCategoryTags,
  selectedTag,
  selectedTagCategory,
  createTag,
  create,
  updateTag,
  update,
}) => {
  const { appId } = useParams();

  const { t } = useTranslation();
  const isEditing = event === 'edit';

  useEffect(() => {
    const { loaded, error } = create;
    if (loaded && !error) {
      toggleModal('create', false);
      getCategoryTags(appId, selectedTagCategory?.id);
    }
    // eslint-disable-next-line
  }, [create]);

  useEffect(() => {
    const { loaded, error } = update;
    if (loaded && !error) {
      toggleModal('update', false);
      getCategoryTags(appId, selectedTagCategory?.id);
    }
    // eslint-disable-next-line
  }, [update]);

  const onSubmit = async (name: string) => {
    if (isEditing && selectedTag) {
      const { id: tagId, categoryId } = selectedTag;
      updateTag(appId, categoryId, tagId, name);
    } else if (selectedTagCategory) {
      const { id } = selectedTagCategory;
      createTag(appId, id, name);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={() => toggleModal(event)}>
      <ModalHeader>{isEditing ? t('employee.editTag') : t('employee.createTag')}</ModalHeader>

      <Formik
        initialValues={{
          name: (selectedTag && selectedTag.name) || '',
        }}
        validationSchema={tagSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await onSubmit(values.name);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <FormGroup row>
                <Col sm="4">
                  <Label>{t('common.name')}</Label>
                </Col>
                <Col>
                  <TextField name="name" placeholder={t('employee.nameOfTag')} />
                </Col>
              </FormGroup>
            </ModalBody>

            <ModalFooter>
              <Spinner loading={create.loading || update.loading} className="mr-1" />
              <Button type="submit" disabled={isSubmitting} color="primary">
                {isEditing ? t('common.save') : t('common.create')}
              </Button>
              <Button color="secondary" onClick={() => toggleModal(event)}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  create: state.createTag,
  update: state.updateTag,
});

const mapDispatchToProps = {
  getApplication,

  createTag,
  updateTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(TagModal);
