import { Dispatch } from 'redux';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';
import { IntentStatisticsQueryParams } from 'src/shared/models/query-params.model';
import {
  getTopicStatistics as getTopicStatisticsService,
  exportTopicStatistics as exportTopicStatisticsService,
} from '../services/topic-statistics.service';

export const GET_TOPIC_STATISTICS_REQUEST = 'topic-statistics/GET_TOPIC_STATISTICS_REQUEST';
export const GET_TOPIC_STATISTICS_SUCCESS = 'topic-statistics/GET_TOPIC_STATISTICS_SUCCESS';
export const GET_TOPIC_STATISTICS_ERROR = 'topic-statistics/GET_TOPIC_STATISTICS_ERROR';
export const EXPORT_TOPIC_STATISTICS_REQUEST = 'topic-statistics/EXPORT_TOPIC_STATISTICS_REQUEST';
export const EXPORT_TOPIC_STATISTICS_SUCCESS = 'topic-statistics/EXPORT_TOPIC_STATISTICS_SUCCESS';
export const EXPORT_TOPIC_STATISTICS_ERROR = 'topic-statistics/EXPORT_TOPIC_STATISTICS_ERROR';

export const getTopicStatistics = (
  applicationId: number,
  queryParams: IntentStatisticsQueryParams,
  filters: DynamicGroupByModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TOPIC_STATISTICS_REQUEST,
    });

    try {
      const payload = await getTopicStatisticsService(applicationId, queryParams, filters);

      dispatch({
        type: GET_TOPIC_STATISTICS_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: GET_TOPIC_STATISTICS_ERROR,
        error,
      });
      throw error;
    }
  };
};

export const exportTopicStatistics = (
  applicationId: number,
  queryParams: IntentStatisticsQueryParams,
  filters: DynamicGroupByModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EXPORT_TOPIC_STATISTICS_REQUEST,
    });

    try {
      const payload = await exportTopicStatisticsService(applicationId, queryParams, filters);

      dispatch({
        type: EXPORT_TOPIC_STATISTICS_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: EXPORT_TOPIC_STATISTICS_ERROR,
        error,
      });
      throw error;
    }
  };
};
