import { StateModel } from 'src/shared/models';
import {
  CLEAR_TRANSLATIONS,
  GET_EVENT_TRANSLATIONS_ERROR,
  GET_EVENT_TRANSLATIONS_REQUEST,
  GET_EVENT_TRANSLATIONS_SUCCESS,
} from '../actions/content-translations.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const contentTranslationsEventReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case GET_EVENT_TRANSLATIONS_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_EVENT_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload,
      };
    case GET_EVENT_TRANSLATIONS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };
    case CLEAR_TRANSLATIONS: {
      return { INITIAL_STATE };
    }
    default:
      return state;
  }
};
