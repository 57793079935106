import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import uniqBy from 'lodash/uniqBy';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { SelectModel } from 'src/shared/models/common.model';
import { PositionApplicantModel } from 'src/shared/models/position-applicants.model';

import { getSelectOptionObjects } from 'src/shared/utils/misc.util';

import PositionApplicantsPositionFilter from './PositionApplicantsPositionFilter';
import { Sorter } from 'src/shared/components';
import { StateModel } from 'src/shared/models/default-state.model';
import { getPositions } from 'src/redux/actions/positions.action';
import ButtonDropdown, { ButtonDropDownItem } from 'src/shared/components/ButtonDropdown';
import {
  exportIcon,
  exportMessageToDisplay,
  exportType,
} from 'src/shared/constants/export.constants';

interface Props {
  applicants: PositionApplicantModel[];
  handlePositionSelect: Function;
  handleExport: (id: string) => Promise<void>;
  setQueryParams: Function;
  getPositions: Function;
  positions: StateModel;
}

const PositionApplicantsFilters: React.FC<Props> = ({
  applicants,
  handlePositionSelect,
  handleExport,
  setQueryParams,
  positions,
  getPositions,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [positionOptions, setPositionOptions] = useState<SelectModel[]>([]);
  const sortTypes = [{ label: t('common.appliedAt'), value: 'appliedAt' }];

  const exportDropdownItems: ButtonDropDownItem[] = [
    {
      id: exportType.CSV,
      text: exportMessageToDisplay[exportType.CSV],
      icon: exportIcon[exportType.CSV],
    },
    {
      id: exportType.EXCEL,
      text: exportMessageToDisplay[exportType.EXCEL],
      icon: exportIcon[exportType.EXCEL],
    },
  ];

  useEffect(() => {
    if (!positionOptions.length) {
      getPositionSelectOptions();
    }
    /* eslint-disable-next-line */
  }, [applicants]);

  useEffect(() => {
    getPositions(appId);
  }, [getPositions, appId]);

  const getPositionSelectOptions = () => {
    if (!positions.data?.content?.length) return undefined;
    // if (!applicants?.length) return undefined;

    const pData = positions.data?.content;
    const optionObjects = getSelectOptionObjects(pData, 'name', 'id');
    const uniqueOptionObjects = uniqBy(optionObjects, 'value');

    return setPositionOptions(_.sortBy(uniqueOptionObjects, ['label']));
  };

  return (
    <Row className="mb-3">
      <Col lg="3" md="4">
        <PositionApplicantsPositionFilter
          options={positionOptions}
          handleSelectChange={handlePositionSelect}
        />
      </Col>

      <Col lg="3" md="4">
        <div className="mt-2 mt-md-0">
          <Sorter setQueryParams={setQueryParams} sortTypes={sortTypes} />
        </div>
      </Col>

      <Col className="d-flex justify-content-end align-items-end">
        <ButtonDropdown
          disabled={!applicants?.length}
          titleKey="common.export"
          permission="position_applicants_read"
          onClick={handleExport}
          items={exportDropdownItems}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  applicants: state.positionApplicants.data?.content,
  positions: state.positions.data.positions,
});

const mapDispatchToProps = {
  getPositions,
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionApplicantsFilters);
