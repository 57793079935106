import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { ApplicationModel } from '../../../redux/actions/applications.action';

interface Props extends RouteComponentProps {
  header: string;
  mainText: string;
  icon: string;
  color: string;
  variant: string;
  application: ApplicationModel;
}

const ApplicationStatCard: React.FC<Props | any> = (props: Props | any) => {
  const {
    className,
    cssModule,
    header,
    mainText,
    icon,
    color,
    staticContext,
    ...attributes
  } = props;

  const card = { style: 'clearfix', color, icon, classes: '' };
  card.classes = classNames(className, card.style, 'p-0', cssModule);

  const lead = { style: 'h5 mb-0', color, classes: '' };
  lead.classes = classNames(lead.style, `text-${card.color}`, 'pt-3');

  const blockIcon = (bIcon: any) => {
    const classes = classNames(bIcon, `bg-${card.color}`, 'p-4', 'font-2xl mr-3 float-left');
    return <i className={classes} />;
  };

  return (
    <Card>
      <CardBody className={card.classes} {...attributes}>
        {blockIcon(card.icon)}
        <div className={lead.classes}>{header}</div>
        <div className="text-muted text-uppercase font-weight-bold font-xs">{mainText}</div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ApplicationStatCard));
