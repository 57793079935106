import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { connect } from 'react-redux';

import moment from 'moment';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { EmployeeTags, List } from 'src/shared/components';
import { Operations, PageQueryParams, StateModel } from 'src/shared/models';
import { EventModel, EventStatus } from 'src/shared/models/event.model';
import { getTagCategories } from 'src/redux/actions/employee.action';

import {
  deleteEvent,
  getEvents,
  revertEvent,
  removeEventTag as removeTag,
  addTagToEvent as addTag,
} from 'src/redux/actions/events.action';

import StatusButton from './components/StatusButton';

export interface ITaggable {
  removeTag: Function;
  removeTagState: StateModel;
  getTagCategories: Function;
  tagCategories: StateModel;
  addTag: Function;
  addTagToState: StateModel;
}

interface IEventsProps extends ITaggable {
  getEvents: (appId: string, queryParams: PageQueryParams) => void;
  deleteEvent: (appId: string, eventId: number) => Promise<void>;
  events: EventModel[];
  numberOfEvents: number;
  eventsLoading: boolean;
  eventsLoaded: boolean;
  eventsError: boolean;
  getApplication: Function;
  revertEvent: Function;
  application: ApplicationModel;
}

const Events: FC<IEventsProps> = ({
  getEvents,
  deleteEvent,
  events,
  numberOfEvents,
  eventsLoading,
  eventsLoaded,
  eventsError,
  getApplication,
  revertEvent,
  application,
  removeTag,
  removeTagState,
  getTagCategories,
  tagCategories,
  addTag,
  addTagToState,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  useEffect(() => {
    revertEvent();
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  const fetchEvents = async (queryParams: PageQueryParams) => {
    await getEvents(appId, queryParams);
  };

  const removeEvent = async (event: EventModel) => {
    await deleteEvent(appId, event.id);
  };

  useEffect(() => {
    const { loaded, error } = removeTagState;
    if (loaded && !error) {
      fetchEvents({ page: 0, size: 20 });
    }
    // eslint-disable-next-line
  }, [removeTagState]);

  useEffect(() => {
    const { loaded, error } = addTagToState;
    if (loaded && !error) {
      fetchEvents({ page: 0, size: 20 });
    }
    // eslint-disable-next-line
  }, [addTagToState]);

  return (
    <div className="animated fadeIn">
      <List
        useCardContent
        data={events}
        totalElements={numberOfEvents}
        loaded={eventsLoaded}
        loading={eventsLoading}
        error={eventsError}
        fetchData={(params: PageQueryParams) => {
          return fetchEvents(params);
        }}
        title={t('events.events')}
        createRedirectPath="create"
        fields={[
          { key: 'title', label: t('common.title') },
          { key: 'openDate', label: t('common.startsAt'), date: true },
          { key: 'closeDate', label: t('common.endsAt'), date: true },
          { key: 'description', label: t('common.description'), columnStyle: 'long' },
          {
            key: 'employeeTags',
            label: t('surveys.tags'),
            render: (event, index) => (
              <EmployeeTags
                employeeTags={event.employeeTags || []}
                removeTag={(tagId: string) => removeTag(appId, event.id, tagId)}
                removePermission="employee_tag-event_delete"
                createPermission="employee_tag-event_create"
                index={index}
                taggableModel={event}
                getTagCategories={getTagCategories}
                tagCategories={tagCategories}
                addTagToEntity={addTag}
              />
            ),
          },
          {
            key: 'status',
            label: t('common.status'),
            render: event => (
              <StatusButton
                data={{
                  startsAt: moment(event.openDate),
                  endsAt: moment(event.closeDate),
                  isDraft: event.status === EventStatus.DRAFT,
                }}
              />
            ),
          },
          {
            key: 'operations',
            label: t('common.operations'),
            operations: [Operations.REPORT, Operations.UPDATE, Operations.DELETE],
          },
        ]}
        noDataLabel={t('events.noEvents')}
        deleteFunction={removeEvent}
        deleteTitle={t('events.deleteEvent')}
        deleteText={t('events.deleteEventConfirmMessage')}
        updatePermission="event_event_update"
        deletePermission="event_event_update"
        reportRedirectPath="report"
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    events: state.events.data.content,
    numberOfEvents: state.events.data.totalElements,
    eventsLoading: state.events.loading,
    eventsLoaded: state.events.loaded,
    eventsError: state.events.error,
    application: state.application.data,
    //TAG
    removeTagState: state.removeSurveyTag,
    tagCategories: state.tagCategories,
    addTagToState: state.addTagToSurvey,
  };
};

const mapDispatchToProps = {
  getEvents,
  getApplication,
  deleteEvent,
  revertEvent,
  //TAG
  removeTag,
  getTagCategories,
  addTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
