import React, { Component } from 'react';

import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';
import { isValidUrl } from '../../../../../../../../shared/utils/url-validate.util';
import { parseFileNameWithExtension } from '../../../../../../../../shared/utils/parse.util';

import TextMessage from './TextMessage';

interface Props {
  message: ConversationHistoryModel;
}

interface State {
  name: string;
  url: string;
}

class SendFile extends Component<Props> {
  state: State = {
    name: '',
    url: '',
  };

  componentDidMount() {
    const { message } = this.props;
    this.getFileDetails(message);
  }

  getFileDetails(message: any) {
    let name = 'Unknown File';
    let url = '#';

    try {
      // eslint-disable-next-line
      name = JSON.parse(message.messageAsText).fileName;
      // eslint-disable-next-line
      url = JSON.parse(message.messageAsText).filePath;
    } catch (error) {
      if (isValidUrl(message.messageAsText)) {
        const fileName = parseFileNameWithExtension(message.messageAsText);
        if (fileName) name = fileName;
        url = message.messageAsText;
      }
    }

    this.setState({
      name,
      url,
    });
  }

  render() {
    const { message } = this.props;
    const { name, url } = this.state;

    return <TextMessage message={message} file={{ name, url }} />;
  }
}

export default SendFile;
