import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormikContext } from 'formik';
import { Row, Col, FormGroup, Label } from 'reactstrap';

import { StateModel } from 'src/shared/models/default-state.model';
import { SelectModel } from 'src/shared/models/common.model';
import CreateTypes from 'src/shared/models/create-types';

import {
  getAttributeValidators,
  createAttributeValidator,
  getRegexReplacers,
  createRegexReplacer,
} from 'src/redux/actions/questionnaire.action';

import TextField from 'src/shared/components/form-inputs/TextField';
import ReactSelect from 'src/shared/components/form-inputs/ReactSelect';
import CreatePopover from './components/CreatePopover';
import RegexReplacer from './components/RegexReplacer';
import AttributeSettingsModal from './components/AttributeSettingsModal';
import PlusLabel from './components/PlusLabel';

import styles from '../../Questionnaire.module.scss';
import { Switch } from '../../../../shared/components';
import {
  QuestionnaireMessageMetadataValidatorType,
} from '../../../../shared/models';

interface Props {
  options: SelectModel[];
  replacerOptions: SelectModel[];
  getAttributeValidators: Function;
  createAttributeValidatorEvent: StateModel;
  createAttributeValidator: Function;
  getRegexReplacers: Function;
  createRegexReplacerEvent: StateModel;
  createRegexReplacer: Function;
}

const validatorTypeOptions: SelectModel[] = [
  {
    label: QuestionnaireMessageMetadataValidatorType.REGEX_VALIDATOR,
    value: QuestionnaireMessageMetadataValidatorType.REGEX_VALIDATOR,
  },
  {
    label: QuestionnaireMessageMetadataValidatorType.LOCATION_VALIDATOR,
    value: QuestionnaireMessageMetadataValidatorType.LOCATION_VALIDATOR,
  },
  {
    label: QuestionnaireMessageMetadataValidatorType.NONE,
    value: QuestionnaireMessageMetadataValidatorType.NONE,
  },
];

const saveLocationOptions: SelectModel[] = [
  {
    label: 'attribute',
    value: 'attribute',
  },
  {
    label: 'positionAttribute',
    value: 'positionAttribute',
  },
  {
    label: 'none',
    value: 'none',
  },
];

const OpenEndedQuestionForm: React.FC<Props> = ({
  options,
  replacerOptions,
  getAttributeValidators,
  createAttributeValidatorEvent,
  createAttributeValidator,
  getRegexReplacers,
  createRegexReplacerEvent,
  createRegexReplacer,
}) => {
  const { t } = useTranslation();
  const { values, setFieldValue }: FormikValues = useFormikContext();


  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [popoverRegexReplacerOpen, setPopoverRegexReplacerOpen] = useState<boolean>(false);

  useEffect(() => {
    getAttributeValidators();
    getRegexReplacers();
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    const { loaded, error, data } = createAttributeValidatorEvent;

    if (loaded && !error) {
      getAttributeValidators();
      setFieldValue('attributeValidator', { label: data.title, value: data.pattern });
      togglePopover();
    }
    /* eslint-disable-next-line */
  }, [createAttributeValidatorEvent]);


  useEffect(() => {
    const { loaded, error } = createRegexReplacerEvent;

    if (loaded && !error) {
      getRegexReplacers();
      toggleRegexReplacerPopover();
    }
    /* eslint-disable-next-line */
  }, [createRegexReplacerEvent]);

  const handleAttributeValueCreate = async (values: FormikValues) => {
    await createAttributeValidator(values);
  };

  const handleRegexReplacerValueCreate = async (values: FormikValues) => {
    await createRegexReplacer(values);
  };

  const toggleModal = () => setModalOpen(!modalOpen);
  const togglePopover = () => setPopoverOpen(!popoverOpen);
  const toggleRegexReplacerPopover = () =>
    setPopoverRegexReplacerOpen(!popoverRegexReplacerOpen);

  const showCreateRegexReplacerPopover =
    values.regexReplacers && values.regexReplacers.length > 0;


  return (
    <Fragment>
      <FormGroup>
        <Label for="text">{t('common.text')}</Label>
        <TextField name="text" />
      </FormGroup>

      <Row form>
        <Col>
          <FormGroup>
            <Label for="saveLocation">{t('common.saveLocation')}</Label>
            <ReactSelect name="saveLocation" options={saveLocationOptions}/>
          </FormGroup>
        </Col>
      </Row>

      <Row form>
        <Col>
          <FormGroup>

            <RegexReplacer
              showCreateRegexReplacerPopover={showCreateRegexReplacerPopover}
              replacerOptions={replacerOptions}
            />
          </FormGroup>

        </Col>

      </Row>

      <Row form>
        <Col>
          <FormGroup>
            <Label for="validatorType">{t('questionnaire.validatorType')}</Label>
            <ReactSelect name="validatorType" options={validatorTypeOptions}/>
          </FormGroup>
        </Col>
      </Row>

      {values.validatorType.value === QuestionnaireMessageMetadataValidatorType.REGEX_VALIDATOR && (
        <Row form>
          <Col md={4}>
            <FormGroup>

              <PlusLabel
                id="createPopover"
                labelFor="regularExpression"
                title={t('questionnaire.attributeValidator')}
              />
              <div className="d-flex flex-row">
                <ReactSelect
                  className={styles.select}
                  name="regularExpression"
                  options={options}
                  isClearable
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
      )
      }

      {values.validatorType.value === QuestionnaireMessageMetadataValidatorType.LOCATION_VALIDATOR && (

        <Row form>
          <Col md={4}>
            <FormGroup>
              <Label for="isoRegionCode">{t('questionnaire.isoRegionCode')}<br/>
                <small><a href="https://en.wikipedia.org/wiki/ISO_3166-2" target="_blank"
                          rel="noopener noreferrer">{t('questionnaire.isoRegionCodeInfo')}</a></small>
              </Label>

              <TextField name="isoRegionCode"/>
            </FormGroup>
          </Col>
        </Row>
      )
      }


      <Row form>
        <Col>
          <FormGroup>
            <Switch
              name="faultTolerant"
              variant="pill"
              color="info"
              label="questionnaire.faultTolerant"
              labelPosition="before"
            />
          </FormGroup>
        </Col>

      </Row>


      <AttributeSettingsModal
        isOpen={modalOpen}
        toggle={toggleModal}
        requiredName="attributeRequired"
        multiplicableName="attributeMultiplicable"
      />
      {values.validatorType.value === QuestionnaireMessageMetadataValidatorType.REGEX_VALIDATOR && (

        <CreatePopover
          isOpen={popoverOpen}
          createType={CreateTypes.ATTRIBUTE_VALIDATOR}
          toggle={togglePopover}
          handleOnSubmit={handleAttributeValueCreate}
        />
      )}

      {showCreateRegexReplacerPopover ? (
        <CreatePopover
          id="createRegexReplacerPopover"
          isOpen={popoverRegexReplacerOpen}
          createType={CreateTypes.REGEX_REPLACER}
          toggle={toggleRegexReplacerPopover}
          handleOnSubmit={handleRegexReplacerValueCreate}
        />
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  createAttributeValidatorEvent: state.attributeValidators.create,
  createRegexReplacerEvent: state.regexReplacers.create,
});

const mapDispatchToProps = {
  getAttributeValidators,
  createAttributeValidator,
  getRegexReplacers: getRegexReplacers,
  createRegexReplacer: createRegexReplacer,
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenEndedQuestionForm);
