import { StateModel } from '../../shared/models/default-state.model';
import {
  ACCOUNT_SET_SELECTED_OPTION_REQUEST,
  ACCOUNT_SET_SELECTED_OPTION_SUCCESS,
  ACCOUNT_SET_SELECTED_OPTION_ERROR,
  ACCOUNT_CHANGE_PASSWORD_REQUEST,
  ACCOUNT_CHANGE_PASSWORD_SUCCESS,
  ACCOUNT_CHANGE_PASSWORD_ERROR,
} from '../actions/account.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export interface ChangePasswordModel {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export const accountSelectedOptionReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ACCOUNT_SET_SELECTED_OPTION_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case ACCOUNT_SET_SELECTED_OPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case ACCOUNT_SET_SELECTED_OPTION_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const accountChangePasswordReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ACCOUNT_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case ACCOUNT_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case ACCOUNT_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
