import { Dispatch } from 'redux';
import {
  getLabel as getLabelService,
  getLabels as getLabelsService,
  createLabel as createLabelService,
  updateLabel as updateLabelService,
  deleteLabel as deleteLabelService,
  exportLabels as exportLabelsService,
  addLabelsToPosition as addLabelsToPositionService,
  removeLabelFromPosition as removeLabelFromPositionService,
} from '../services/position-labels.service';
import { PositionLabelModel } from '../../shared/models/positions.model';
import { GeneralQueryParams } from '../../shared/models/query-params.model';

export const GET_LABEL_REQUEST = 'position-labels/GET_LABEL_REQUEST';
export const GET_LABEL_SUCCESS = 'position-labels/GET_LABEL_SUCCESS';
export const GET_LABEL_ERROR = 'position-labels/GET_LABEL_ERROR';

export const GET_LABELS_REQUEST = 'position-labels/GET_LABELS_REQUEST';
export const GET_LABELS_SUCCESS = 'position-labels/GET_LABELS_SUCCESS';
export const GET_LABELS_ERROR = 'position-labels/GET_LABELS_ERROR';

export const CREATE_LABEL_REQUEST = 'position-labels/CREATE_LABEL_REQUEST';
export const CREATE_LABEL_SUCCESS = 'position-labels/CREATE_LABEL_SUCCESS';
export const CREATE_LABEL_ERROR = 'position-labels/CREATE_LABEL_ERROR';

export const UPDATE_LABEL_REQUEST = 'position-labels/UPDATE_LABEL_REQUEST';
export const UPDATE_LABEL_SUCCESS = 'position-labels/UPDATE_LABEL_SUCCESS';
export const UPDATE_LABEL_ERROR = 'position-labels/UPDATE_LABEL_ERROR';

export const DELETE_LABEL_REQUEST = 'position-labels/DELETE_LABEL_REQUEST';
export const DELETE_LABEL_SUCCESS = 'position-labels/DELETE_LABEL_SUCCESS';
export const DELETE_LABEL_ERROR = 'position-labels/DELETE_LABEL_ERROR';

export const EXPORT_LABELS_REQUEST = 'position-labels/EXPORT_LABELS_REQUEST';
export const EXPORT_LABELS_SUCCESS = 'position-labels/EXPORT_LABELS_SUCCESS';
export const EXPORT_LABELS_ERROR = 'position-labels/EXPORT_LABELS_ERROR';

export const ADD_LABEL_TO_POSITION_REQUEST = 'position-labels/ADD_LABEL_TO_POSITION_REQUEST';
export const ADD_LABEL_TO_POSITION_SUCCESS = 'position-labels/ADD_LABEL_TO_POSITION_SUCCESS';
export const ADD_LABEL_TO_POSITION_ERROR = 'position-labels/ADD_LABEL_TO_POSITION_ERROR';

export const REMOVE_LABEL_FROM_POSITION_REQUEST =
  'position-labels/REMOVE_LABEL_FROM_POSITION_REQUEST';
export const REMOVE_LABEL_FROM_POSITION_SUCCESS =
  'position-labels/REMOVE_LABEL_FROM_POSITION_SUCCESS';
export const REMOVE_LABEL_FROM_POSITION_ERROR = 'position-labels/REMOVE_LABEL_FROM_POSITION_ERROR';

export const CLEAR_ADD_LABELS_TO_POSITION = 'position-labels/CLEAR_ADD_LABELS_TO_POSITION';
export const CLEAR_CREATE_LABEL = 'position-labels/CLEAR_CREATE_LABEL';
export const CLEAR_UPDATE_LABEL = 'position-labels/CLEAR_UPDATE_LABEL';
export const CLEAR_DELETE_LABEL = 'position-labels/CLEAR_DELETE_LABEL';

export const getLabel = (applicationId: string, labelId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_LABEL_REQUEST,
    });

    try {
      dispatch({
        type: GET_LABEL_SUCCESS,
        payload: await getLabelService(applicationId, labelId),
      });
    } catch (error) {
      dispatch({
        type: GET_LABEL_ERROR,
        error,
      });
    }
  };
};

export const getLabels = (applicationId: string, queryParams: GeneralQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_LABELS_REQUEST,
    });

    try {
      dispatch({
        type: GET_LABELS_SUCCESS,
        payload: await getLabelsService(applicationId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_LABELS_ERROR,
        error,
      });
    }
  };
};

export const createLabel = (applicationId: string, position: PositionLabelModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_LABEL_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_LABEL_SUCCESS,
        payload: await createLabelService(applicationId, position),
      });
    } catch (error) {
      dispatch({
        type: CREATE_LABEL_ERROR,
        error,
      });
    }
  };
};

export const updateLabel = (
  applicationId: string,
  labelId: number,
  position: PositionLabelModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_LABEL_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_LABEL_SUCCESS,
        payload: await updateLabelService(applicationId, labelId, position),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_LABEL_ERROR,
        error,
      });
    }
  };
};

export const deleteLabel = (applicationId: string, labelId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_LABEL_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_LABEL_SUCCESS,
        payload: await deleteLabelService(applicationId, labelId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_LABEL_ERROR,
        error,
      });
    }
  };
};

export const exportLabels = (applicationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EXPORT_LABELS_REQUEST,
    });

    try {
      dispatch({
        type: EXPORT_LABELS_SUCCESS,
        payload: await exportLabelsService(applicationId),
      });
    } catch (error) {
      dispatch({
        type: EXPORT_LABELS_ERROR,
        error,
      });
    }
  };
};

export const addLabelsToPosition = (
  applicationId: string,
  positionId: number,
  labelId: number[],
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ADD_LABEL_TO_POSITION_REQUEST,
    });

    try {
      dispatch({
        type: ADD_LABEL_TO_POSITION_SUCCESS,
        payload: await addLabelsToPositionService(applicationId, positionId, labelId),
      });
    } catch (error) {
      dispatch({
        type: ADD_LABEL_TO_POSITION_ERROR,
        error,
      });
    }
  };
};

export const removeLabelFromPosition = (
  applicationId: string,
  positionId: number,
  labelId: number,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REMOVE_LABEL_FROM_POSITION_REQUEST,
    });

    try {
      dispatch({
        type: REMOVE_LABEL_FROM_POSITION_SUCCESS,
        payload: await removeLabelFromPositionService(applicationId, positionId, labelId),
      });
    } catch (error) {
      dispatch({
        type: REMOVE_LABEL_FROM_POSITION_ERROR,
        error,
      });
    }
  };
};

export const clearAddLabelsToPosition = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_ADD_LABELS_TO_POSITION,
    });
  };
};

export const clearCreateLabel = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_CREATE_LABEL,
    });
  };
};

export const clearUpdateLabel = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_UPDATE_LABEL,
    });
  };
};

export const clearDeleteLabel = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_DELETE_LABEL,
    });
  };
};
