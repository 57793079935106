import { Dispatch } from 'redux';

import { GeneralQueryParams } from 'src/shared/models/query-params.model';

import {
  getInterviews as getInterviewsService,
  getInterview as getInterviewService,
  createInterview as createInterviewService,
  updateInterview as updateInterviewService,
  deleteInterview as deleteInterviewService,
} from '../services/interviews.service';
import { InterviewModel } from 'src/shared/models/interviews.model';

export const GET_INTERVIEWS_REQUEST = 'interviews/GET_INTERVIEWS_REQUEST';
export const GET_INTERVIEWS_SUCCESS = 'interviews/GET_INTERVIEWS_SUCCESS';
export const GET_INTERVIEWS_ERROR = 'interviews/GET_INTERVIEWS_ERROR';

export const GET_INTERVIEW_REQUEST = 'interviews/GET_INTERVIEW_REQUEST';
export const GET_INTERVIEW_SUCCESS = 'interviews/GET_INTERVIEW_SUCCESS';
export const GET_INTERVIEW_ERROR = 'interviews/GET_INTERVIEW_ERROR';

export const CREATE_INTERVIEW_REQUEST = 'interviews/CREATE_INTERVIEW_REQUEST';
export const CREATE_INTERVIEW_SUCCESS = 'interviews/CREATE_INTERVIEW_SUCCESS';
export const CREATE_INTERVIEW_ERROR = 'interviews/CREATE_INTERVIEW_ERROR';

export const UPDATE_INTERVIEW_REQUEST = 'interviews/UPDATE_INTERVIEW_REQUEST';
export const UPDATE_INTERVIEW_SUCCESS = 'interviews/UPDATE_INTERVIEW_SUCCESS';
export const UPDATE_INTERVIEW_ERROR = 'interviews/UPDATE_INTERVIEW_ERROR';

export const DELETE_INTERVIEW_REQUEST = 'interviews/DELETE_INTERVIEW_REQUEST';
export const DELETE_INTERVIEW_SUCCESS = 'interviews/DELETE_INTERVIEW_SUCCESS';
export const DELETE_INTERVIEW_ERROR = 'interviews/DELETE_INTERVIEW_ERROR';

export const getInterviews = (applicationId: string, queryParams: GeneralQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_INTERVIEWS_REQUEST,
    });

    try {
      dispatch({
        type: GET_INTERVIEWS_SUCCESS,
        payload: await getInterviewsService(applicationId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_INTERVIEWS_ERROR,
        error,
      });
    }
  };
};

export const getInterview = (applicationId: string, eventId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_INTERVIEW_REQUEST,
    });

    try {
      dispatch({
        type: GET_INTERVIEW_SUCCESS,
        payload: await getInterviewService(applicationId, eventId),
      });
    } catch (error) {
      dispatch({
        type: GET_INTERVIEW_ERROR,
        error,
      });
    }
  };
};

export const createInterview = (applicationId: string, interview: InterviewModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_INTERVIEW_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_INTERVIEW_SUCCESS,
        payload: await createInterviewService(applicationId, interview),
      });
    } catch (error) {
      dispatch({
        type: CREATE_INTERVIEW_ERROR,
        error,
      });
    }
  };
};

export const updateInterview = (
  applicationId: string,
  eventId: number,
  interview: InterviewModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_INTERVIEW_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_INTERVIEW_SUCCESS,
        payload: await updateInterviewService(applicationId, eventId, interview),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_INTERVIEW_ERROR,
        error,
      });
    }
  };
};

export const deleteInterview = (applicationId: string, eventId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_INTERVIEW_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_INTERVIEW_SUCCESS,
        payload: await deleteInterviewService(applicationId, eventId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_INTERVIEW_ERROR,
        error,
      });
    }
  };
};
