import React, { Fragment } from 'react';
import { Table } from 'reactstrap';
import Pagination from 'react-js-pagination';
import { useTranslation } from 'react-i18next';

import {
  AuditLogModel,
  AuditLogEventModel,
} from '../../../shared/models/audit-log.model';
import { UserModel } from '../../../shared/models/user.model';

import AuditLogTableRow from './AuditLogTableRow';

import styles from '../AuditLog.module.scss';

interface Props {
  events: AuditLogEventModel[];
  auditLogs: AuditLogModel[];
  revealUser: Function;
  revealedUsers: UserModel[];
  activePage: number;
  size: number;
  totalElements: number;
  pageChange: Function;
  toggleModal: Function;
}

const AuditLogTable: React.FC<Props> = props => {
  const [t] = useTranslation();
  const {
    events,
    auditLogs,
    revealUser,
    revealedUsers,
    activePage,
    size,
    totalElements,
    pageChange,
    toggleModal,
  } = props;

  return (
    <Fragment>
      {auditLogs.length > 0 && (
        <Fragment>
          <Table responsive striped hover size="sm">
            <thead>
              <tr>
                <th className={styles.logId}>{t('auditLog.logId')}</th>
                <th className={styles.time}>{t('common.time')}</th>
                <th className={styles.application}>
                  {t('common.application')}
                </th>
                <th className={styles.user}>{t('common.user')}</th>
                <th className={styles.eventType}>{t('auditLog.eventType')}</th>
                <th>Details: </th>
              </tr>
            </thead>
            <tbody>
              <AuditLogTableRow
                events={events}
                auditLogs={auditLogs}
                revealUser={revealUser}
                revealedUsers={revealedUsers}
                toggleModal={toggleModal}
              />
             
            </tbody>
          </Table>

          <Pagination
            activePage={activePage}
            itemsCountPerPage={size}
            totalItemsCount={totalElements}
            pageRangeDisplayed={5}
            onChange={(index: number) => pageChange(index)}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default AuditLogTable;
