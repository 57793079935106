import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Formik, Form } from 'formik';

import { Spinner, TextField } from 'src/shared/components';
import { LanguageBase, LanguageEditorModalModel } from 'src/shared/models/language-settings.model';
import {
  createLanguageSetting,
  getLanguageSettings,
} from 'src/redux/actions/language-settings.action';
import { useParams } from 'react-router';
import { languageEditorSchema } from 'src/shared/schemas/validation.schema';

export interface LanguageEditorModalProps {
  isOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  createLanguageSetting: (appId: string, data: LanguageBase) => Promise<void>;
  getLanguageSettings: (appId: string) => Promise<void>;
}

const LanguageEditorModal: FC<LanguageEditorModalProps> = ({
  isOpen,
  setIsModalOpen,
  createLanguageSetting,
  getLanguageSettings,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const handleSubmit = async (model: LanguageEditorModalModel) => {
    await createLanguageSetting(appId, model);
    await getLanguageSettings(appId);
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{t('translations.createLanguage')}</ModalHeader>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{ name: '', languageCode: '' }}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmit(values as any);
          setSubmitting(false);
          setIsModalOpen(false);
        }}
        validationSchema={languageEditorSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="name">{t('common.name')}</Label>
                    <TextField name="name" />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="languageCode">{t('translations.languageCode')}</Label>
                    <TextField name="languageCode" />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Spinner loading={isSubmitting} />

              <Button type="submit" color="success" disabled={isSubmitting}>
                {t('common.save')}
              </Button>

              <Button color="secondary" onClick={() => setIsModalOpen(false)}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = {
  createLanguageSetting,
  getLanguageSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageEditorModal);
