import { get, post, patch, del } from '../../shared/services/http.service';
import { PositionsQueryParams } from '../../shared/models/query-params.model';
import {
  PositionModel,
  PositionQuestionModel,
  PositionCloneModel,
  PositionStatus,
} from '../../shared/models/positions.model';

export const getPosition = async (applicationId: string, positionId: number) => {
  return get(`/recruiter/applications/${applicationId}/position/positions/${positionId}`);
};

export const getPositions = async (applicationId: string, queryParams?: PositionsQueryParams) => {
  return get(`/recruiter/applications/${applicationId}/position/positions`, {
    params: queryParams,
  });
};

export const createPosition = async (applicationId: string, position: PositionModel) => {
  return post(`/recruiter/applications/${applicationId}/position/positions`, {
    data: position,
  });
};

export const updatePosition = async (
  applicationId: string,
  positionId: number,
  position: PositionModel,
) => {
  return patch(`/recruiter/applications/${applicationId}/position/positions/${positionId}`, {
    data: position,
  });
};

export const updatePositionOrder = async (
  applicationId: string,
  positionId: number,
  order: number,
) => {
  return patch(`/recruiter/applications/${applicationId}/position/positions/${positionId}/order`, {
    data: { order },
  });
};

export const updatePositionStatus = async (
  applicationId: string,
  positionId: number,
  status: PositionStatus,
) => {
  return patch(`/recruiter/applications/${applicationId}/position/positions/${positionId}/status`, {
    data: { status },
  });
};

export const deletePosition = async (applicationId: string, positionId: number) => {
  return del(`/recruiter/applications/${applicationId}/position/positions/${positionId}`);
};

export const clonePosition = async (
  applicationId: string,
  positionId: number,
  position: PositionCloneModel,
) => {
  return post(`/recruiter/applications/${applicationId}/position/positions/${positionId}/clone`, {
    data: position,
  });
};

export const exportPositions = async (applicationId: string) => {
  return get(`/recruiter/applications/${applicationId}/position/positions/export/csv`);
};

export const getPositionCriteria = async (applicationId: string, criteriaId: number) => {
  return get(`/recruiter/applications/${applicationId}/shortlist/criterias/${criteriaId}`);
};

export const getPositionCriterias = async (applicationId: string) => {
  return get(`/recruiter/applications/${applicationId}/shortlist/criterias`);
};

export const createPositionCriteria = async (applicationId: string, name: string) => {
  return post(`/recruiter/applications/${applicationId}/shortlist/criterias`, { data: name });
};

export const updatePositionCriteria = async (
  applicationId: string,
  criteriaId: number,
  name: string,
) => {
  return patch(`/recruiter/applications/${applicationId}/shortlist/criterias/${criteriaId}`, {
    data: name,
  });
};

export const deletePositionCriteria = async (applicationId: string, criteriaId: number) => {
  return del(`/recruiter/applications/${applicationId}/shortlist/criterias/${criteriaId}`);
};

export const getPositionQuestion = async (
  applicationId: string,
  positionId: number,
  questionId: number,
) => {
  return get(
    `/recruiter/applications/${applicationId}/position/positions/${positionId}/questions/${questionId}`,
  );
};

export const getPositionQuestions = async (applicationId: string, positionId: number) => {
  return get(`/recruiter/applications/${applicationId}/position/positions/${positionId}/questions`);
};

export const createPositionQuestion = async (
  applicationId: string,
  positionId: number,
  question: PositionQuestionModel,
) => {
  return post(
    `/recruiter/applications/${applicationId}/position/positions/${positionId}/questions`,
    { data: question },
  );
};

export const updatePositionQuestion = async (
  applicationId: string,
  positionId: number,
  questionId: number,
  questionText: string,
) => {
  return patch(
    `/recruiter/applications/${applicationId}/position/positions/${positionId}/questions/${questionId}`,
    {
      data: questionText,
    },
  );
};

export const deletePositionQuestion = async (
  applicationId: string,
  positionId: number,
  questionId: number,
) => {
  return del(
    `/recruiter/applications/${applicationId}/position/positions/${positionId}/questions/${questionId}`,
  );
};

export const updatePositionQuestionOrder = async (
  applicationId: string,
  positionId: number,
  ids: number[],
) => {
  return post(
    `/recruiter/applications/${applicationId}/shortlist/positions/${positionId}/questions/sort`,
    {
      data: ids,
    },
  );
};

export const getPositionQuestionAnswerOption = async (
  applicationId: string,
  positionId: number,
  questionId: number,
  answerOptionId: number,
) => {
  return get(
    `/recruiter/applications/${applicationId}/position/positions/${positionId}/questions/${questionId}/answer-options/${answerOptionId}`,
  );
};

export const getPositionQuestionAnswerOptions = async (
  applicationId: string,
  positionId: number,
  questionId: number,
) => {
  return get(
    `/recruiter/applications/${applicationId}/position/positions/${positionId}/questions/${questionId}/answer-options`,
  );
};

export const createPositionQuestionAnswerOption = async (
  applicationId: string,
  positionId: number,
  questionId: number,
  answer: string,
) => {
  return post(
    `/recruiter/applications/${applicationId}/position/positions/${positionId}/questions/${questionId}/answer-options`,
    { data: answer },
  );
};

export const updatePositionQuestionAnswerOption = async (
  applicationId: string,
  positionId: number,
  questionId: number,
  answerOptionId: number,
  answer: string,
) => {
  return patch(
    `/recruiter/applications/${applicationId}/position/positions/${positionId}/questions/${questionId}/answer-options/${answerOptionId}`,
    { data: answer },
  );
};

export const deletePositionQuestionAnswerOption = async (
  applicationId: string,
  positionId: number,
  questionId: number,
  answerOptionId: number,
) => {
  return del(
    `/recruiter/applications/${applicationId}/position/positions/${positionId}/questions/${questionId}/answer-options/${answerOptionId}`,
  );
};
