import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label } from 'reactstrap';
import { Formik, Form, Field, FormikValues } from 'formik';
import moment from 'moment';

import { positionCloningSchema } from 'src/shared/schemas/validation.schema';
import { PositionModel } from 'src/shared/models/positions.model';

import TextField from 'src/shared/components/form-inputs/TextField';
import TextArea from 'src/shared/components/form-inputs/TextArea';
import DateRangeFormik from 'src/shared/components/date-pickers/DateRangeFormik';
import Switch from 'src/shared/components/form-inputs/Switch';
import Spinner from 'src/shared/components/Spinner';

import styles from '../Positions.module.scss';

interface Props {
  isOpen: boolean;
  cancel: Function;
  getPositions: Function;
  clonePosition: Function;
  selectedPosition: PositionModel | undefined;
}

const PositionCloningModal: React.FC<Props> = ({
  isOpen,
  cancel,
  getPositions,
  clonePosition,
  selectedPosition,
}) => {
  const { appId } = useParams<{ appId: string }>();
  const { t } = useTranslation();

  const handleOnSubmit = async (values: FormikValues) => {
    const clonedPositionValues: FormikValues = {
      ...values,
      cloneShortListDefiniton: !values.clonePrefilterCriterias
        ? false
        : values.cloneShortListDefiniton,
      startsAt: moment(values.startDate).utc().startOf('day').toISOString(),
      expiresAt: moment(values.endDate).utc().endOf('day').toISOString(),
    };

    delete clonedPositionValues.startDate;
    delete clonedPositionValues.endDate;

    if (selectedPosition?.id) {
      await clonePosition(appId, selectedPosition.id, clonedPositionValues);
      getPositions(appId);
      cancel();
    }
  };

  return (
    <Modal isOpen={isOpen} className="modal-md">
      <ModalHeader>{t('positions.cloningPosition')}</ModalHeader>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          name: selectedPosition?.name || '',
          description: selectedPosition?.description || '',
          startDate: moment(),
          endDate: moment().add(7, 'day'),
          cloneLabels: true,
          clonePrefilterCriterias: true,
          cloneShortListDefiniton: true,
          cloneApplicants: true,
        }}
        validationSchema={positionCloningSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleOnSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <ModalBody>
              <FormGroup>
                <Label for="name">{t('common.name')}</Label>
                <TextField name="name" />
              </FormGroup>

              <FormGroup>
                <Label for="description">{t('common.description')}</Label>
                <TextArea name="description" rows="5" />
              </FormGroup>

              <FormGroup>
                <Label for="activeDates">{t('common.startAndExpirationDates')}</Label>
                <Field component={DateRangeFormik} id="activeDates" name="activeDates" />
              </FormGroup>

              <FormGroup className={styles.cloneSwitches}>
                <Label for="switches">{t('common.options')}</Label>
                <Switch
                  name="cloneLabels"
                  color="primary"
                  variant="pill"
                  label={t('common.labels')}
                />
                <Switch
                  name="cloneApplicants"
                  color="primary"
                  variant="pill"
                  label={t('common.applicants')}
                />
                <Switch
                  name="clonePrefilterCriterias"
                  color="primary"
                  variant="pill"
                  label={t('common.prefilterCriterias')}
                />
                {values.clonePrefilterCriterias && (
                  <Switch
                    name="cloneShortListDefiniton"
                    color="primary"
                    variant="pill"
                    label={t('common.shortListDefinition')}
                  />
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Spinner loading={isSubmitting} className="mr-1" />

              <Button type="submit" color="primary" disabled={isSubmitting}>
                {t('common.clone')}
              </Button>

              <Button onClick={() => cancel()} color="secondary">
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default PositionCloningModal;
