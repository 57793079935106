import classnames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useMemo, useState, FC } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { ApplicationModel } from 'src/redux/actions/applications.action';
import { ColumnConfigurationType } from 'src/shared/models/configuration.model';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';
import { post } from 'src/shared/services/http.service';
import { getConfiguration } from 'src/shared/utils/configuration.util';
import { DynamicGroupByState } from '../dynamic-group-by/DynamicGroupBy';
import Spinner from '../Spinner';
import i18n from 'src/i18n';
import NoData from '../NoData';

export interface DynamicTabsProps {
  configurationNameSpace: string;
  application: ApplicationModel;
  handleDynamicGroupByChange: (grouping: DynamicGroupByModel) => Promise<void>;
  children: any;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type UI_TYPE = 'LOADING' | 'TABS' | 'SIMPLE';

const DynamicTabs: FC<DynamicTabsProps> = ({
  configurationNameSpace,
  application,
  handleDynamicGroupByChange,
  children,
}) => {
  // Selected values
  const [state, setState] = useState<DynamicGroupByState>();
  // Dropdown items
  const [items, setItems] = useState<DynamicGroupByState>();
  const [activeTab, setActiveTab] = useState<string>();
  const [selectedPropName, setSelectedPropName] = useState<string>();
  const [init, setInit] = useState<boolean>(true);
  const [uiType, setUiType] = useState<UI_TYPE>('LOADING');

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application]);

  useEffect(() => {
    if (state !== undefined) {
      const newTab = state[selectedPropName as any][0];
      setActiveTab(newTab);

      if (!newTab || newTab === activeTab) {
        return;
      }

      triggerDynamicGroupChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const triggerDynamicGroupChange = () => {
    if (state === undefined) {
      return;
    }

    let values = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key in state) {
      if (state[key].length !== 0) {
        values = {
          ...values,
          ...{ [key]: state[key] },
        };
      }
    }

    handleDynamicGroupByChange(values);
  };

  const configuration = useMemo(() => getConfiguration(application, configurationNameSpace), [
    application,
    configurationNameSpace,
  ]);

  const getData = async () => {
    const endpoint = configuration?.configuration.endpoint;
    const firstTab = _.find(
      configuration?.configuration.columns,
      p => p.type === ColumnConfigurationType.Tabs,
    );
    if (endpoint && firstTab) {
      const url = endpoint.replace('{applicationId}', application.id.toString());
      const response = await post(url, { data: state || {} });
      setItems(response);
      // trigger tabs (useMemo) only once
      setInit(false);
    } else {
      handleDynamicGroupByChange({});
      setUiType('SIMPLE');
    }
  };

  const tabs = useMemo(() => {
    if (configuration && items) {
      const firstTab = _.find(
        configuration?.configuration.columns,
        p => p.type === ColumnConfigurationType.Tabs,
      );
      if (firstTab && items) {
        const tabsContent = items[firstTab.propertyName!];
        // setActiveTab(tabsContent[0]);
        setSelectedPropName(firstTab.propertyName!);

        const obj = {};
        // @ts-ignore
        obj[firstTab.propertyName!] = [tabsContent[0]];
        setState(obj);
        setUiType('TABS');
        return tabsContent;
      }
    } // eslint-disable-next-line
  }, [configuration, init, items]);

  const toggleTab = (tab: string) => {
    if (activeTab !== tab && application.id) {
      const obj = {};
      // @ts-ignore
      obj[selectedPropName] = [tab];
      setState(obj);
    }
  };

  const handleChange = (values: { [key: string]: { label: string; value: string }[] }) => {
    const model: DynamicGroupByModel = {};

    // Selecting only value.
    Object.keys(values).map(prop => {
      // eslint-disable-next-line no-return-assign
      return (model[prop] = values[prop].map(i => i.value));
    });

    setState(model);
  };

  return uiType === 'LOADING' ? (
    <Spinner loading={true} />
  ) : uiType === 'TABS' ? (
    tabs?.length === 0 ? (
      <NoData label={i18n.t('common.noData')} />
    ) : (
      <>
        <Nav tabs>
          {tabs?.map(t => (
            <NavItem key={t}>
              <NavLink
                className={classnames({ active: activeTab === t })}
                onClick={() => {
                  const obj: { [key: string]: { label: string; value: string }[] } = {};
                  (obj[selectedPropName!] as any) = [{ label: t, value: t }];
                  toggleTab(t);
                  handleChange(obj);
                }}
              >
                {t}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          {tabs?.map(t => (
            <TabPane key={t} tabId={t}>
              {/* Workaround for loading only the active tab (lazy load tabs when clicked) */}
              {activeTab === t && children}
            </TabPane>
          ))}
        </TabContent>
      </>
    )
  ) : (
    <>{children}</>
  );
};

export default DynamicTabs;
