import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import ApplicationStatCard from './components/ApplicationStatCard';
import ApplicationTrendsCard from './components/ApplicationTrendsCard';
import ApplicationSocialCard from './components/ApplicationSocialCard';
import { getApplication, ApplicationModel } from '../../redux/actions/applications.action';
import { getPlatforms } from '../../redux/actions/platforms.action';

import CheckPermission from '../../shared/components/CheckPermission';

import './ApplicationSummary.scss';

interface ChartDataModel {
  id: string;
  data: object[];
}

interface Props extends RouteComponentProps<{ id: string }>, WithTranslation {
  getApplication: Function;
  application: ApplicationModel;
  getPlatforms: Function;
  platforms: string[];
}

interface State {
  overallConversationsTrendDataSum: number;
  overallConversationsCardChartData: ChartDataModel[] | null;
  overallVisitorsTrendDataSum: number;
  overallVisitorsCardChartData: ChartDataModel[] | null;

  facebookChartData: ChartDataModel[] | null;
  facebookConversationsTrendDataSum: number;
  facebookVisitorsTrendDataSum: number;

  hangoutsChartData: ChartDataModel[] | null;
  hangoutsConversationsTrendDataSum: number;
  hangoutsVisitorsTrendDataSum: number;

  livechatChartData: ChartDataModel[] | null;
  livechatConversationsTrendDataSum: number;
  livechatVisitorsTrendDataSum: number;

  viberChartData: ChartDataModel[] | null;
  viberConversationsTrendDataSum: number;
  viberChatVisitorsTrendDataSum: number;

  overallConversationTickValue: number[] | null;
  overallVisitorTickValue: number[] | null;

}

class Summary extends Component<Props> {

  state: State = {
    overallConversationsTrendDataSum: 0,
    overallConversationsCardChartData: null,
    overallVisitorsTrendDataSum: 0,
    overallVisitorsCardChartData: null,

    facebookChartData: null,
    facebookConversationsTrendDataSum: 0,
    facebookVisitorsTrendDataSum: 0,

    hangoutsChartData: null,
    hangoutsConversationsTrendDataSum: 0,
    hangoutsVisitorsTrendDataSum: 0,

    livechatChartData: null,
    livechatConversationsTrendDataSum: 0,
    livechatVisitorsTrendDataSum: 0,

    viberChartData: null,
    viberConversationsTrendDataSum: 0,
    viberChatVisitorsTrendDataSum: 0,

    overallConversationTickValue: null,
    overallVisitorTickValue: null,
  };

  async componentDidMount() {
    if (!this.props.application.id) {
      await this.props.getApplication(this.props.match.params.id);
    }

    await this.props.getPlatforms(this.props.application.id);

    const overallConversationData =  this.getOverallConversationsData();
    const overallVisitorsData = this.getOverallVisitorsData();
    const facebookChartData = this.getFacebookChartData();
    const hangoutsChartData = this.getHangoutsChartData();
    const livechatChartData = this.getLiveChatChartData();
    const viberChartData = this.getViberChartData();
    this.setState({
      overallConversationsTrendDataSum: overallConversationData.sum,
      overallConversationsCardChartData: overallConversationData.dataset,
      overallVisitorsTrendDataSum: overallVisitorsData.sum,
      overallVisitorsCardChartData: overallVisitorsData.dataset,
      facebookChartData: facebookChartData.dataset,
      facebookConversationsTrendDataSum: facebookChartData.conversationsSum,
      facebookVisitorsTrendDataSum: facebookChartData.visitorsSum,
      livechatChartData: livechatChartData.dataset,
      livechatConversationsTrendDataSum: livechatChartData.conversationsSum,
      livechatVisitorsTrendDataSum: livechatChartData.visitorsSum,
      viberChartData: viberChartData.dataset,
      viberConversationsTrendDataSum: viberChartData.conversationsSum,
      viberChatVisitorsTrendDataSum: viberChartData.visitorsSum,
      hangoutsChartData: hangoutsChartData.dataset,
      hangoutsConversationsTrendDataSum: hangoutsChartData.conversationsSum,
      hangoutsVisitorsTrendDataSum: hangoutsChartData.visitorsSum,
      overallConversationTickValue: overallConversationData.tickValue,
      overallVisitorTickValue: overallVisitorsData.tickValue,
    })
  }

  async componentDidUpdate(nextProps: any) {
    const nextPath = nextProps.location.pathname;
    const currentPath = this.props.location.pathname;

    if (nextPath !== currentPath) {

      const overallConversationData =  this.getOverallConversationsData();
      const overallVisitorsData = this.getOverallVisitorsData();
      const facebookChartData = this.getFacebookChartData();
      const hangoutsChartData = this.getHangoutsChartData();
      const livechatChartData = this.getLiveChatChartData();
      const viberChartData = this.getViberChartData();
      this.setState({
        overallConversationsTrendDataSum: overallConversationData.sum,
        overallConversationsCardChartData: overallConversationData.dataset,
        overallVisitorsTrendDataSum: overallVisitorsData.sum,
        overallVisitorsCardChartData: overallVisitorsData.dataset,
        facebookChartData: facebookChartData.dataset,
        facebookConversationsTrendDataSum: facebookChartData.conversationsSum,
        facebookVisitorsTrendDataSum: facebookChartData.visitorsSum,
        livechatChartData: livechatChartData.dataset,
        livechatConversationsTrendDataSum: livechatChartData.conversationsSum,
        livechatVisitorsTrendDataSum: livechatChartData.visitorsSum,
        viberChartData: viberChartData.dataset,
        viberConversationsTrendDataSum: viberChartData.conversationsSum,
        viberChatVisitorsTrendDataSum: viberChartData.visitorsSum,
        hangoutsChartData: hangoutsChartData.dataset,
        hangoutsConversationsTrendDataSum: hangoutsChartData.conversationsSum,
        hangoutsVisitorsTrendDataSum: hangoutsChartData.visitorsSum,
        overallConversationTickValue: overallConversationData.tickValue,
        overallVisitorTickValue: overallVisitorsData.tickValue,

      })
    }
  }

  getOverallConversationsData() {
    const { application, t } = this.props;
    const dataSet: ChartDataModel[] = [
      {
        id: t('applicationSummary.conversations'),
        data: [],
      },
    ];
    let sum: number = 0;

    const { overallConversationsTrend } = application;

    for (const i in overallConversationsTrend) {
      const dataObject: any = {
        x: i,
        // @ts-ignore
        y: overallConversationsTrend[i],
      };
      // @ts-ignore
      sum += overallConversationsTrend[i];

      dataSet[0].data.push(dataObject);
    }

    const returnObject: any = {
      sum: sum,
      dataset: dataSet,
      tickValue: this.tickValue(dataSet),
    };

    return returnObject

  }

  tickValue(dataSet:any) {
    let min = Math.min.apply(Math, dataSet[0].data.map((o: any) => o.y))
    let max = Math.max.apply(Math, dataSet[0].data.map((o: any) => o.y))
    if (min === 0 && max === 0){
      return [0]
    } else return [min,
      ((max-min)/4),
      ((max-min)/2) ,
      ((max-min)/2 + (max-min)/4) ,
      max]
  }

  getOverallVisitorsData() {
    const { application,t } = this.props;
    const dataSet: ChartDataModel[] = [
      {
        id: t('applicationSummary.visitors'),
        data: [],
      },
    ];
    let sum: number = 0;

    const { overallVisitorsTrend } = application;

    for (const i in overallVisitorsTrend) {
      const dataObject: any = {
        x: i,
        // @ts-ignore
        y: overallVisitorsTrend[i],
      };

      // @ts-ignore
      sum += overallVisitorsTrend[i];

      dataSet[0].data.push(dataObject);
    }

    const returnObject: any = {
      sum: sum,
      dataset: dataSet,
      tickValue: this.tickValue(dataSet),
    };

    return returnObject
  }

  getFacebookChartData() {
    const { application,t } = this.props;
    const facebookChartData: ChartDataModel[] = [
      {
        id: t('applicationSummary.conversations'),
        data: [],
      },
      {
        id: t('applicationSummary.visitors'),
        data: [],
      },
    ];
    let conversationsSum: number = 0;
    let visitorsSum: number = 0;

    const { facebookConversationsTrend, facebookVisitorsTrend } = application;

    for (const i in facebookConversationsTrend) {
      const conversationsDataObject = {
        x: i,
        // @ts-ignore
        y: facebookConversationsTrend[i],
      };
      // @ts-ignore
      conversationsSum += facebookConversationsTrend[i];
      facebookChartData[0].data.push(conversationsDataObject);

      const visitorsDataObject = {
        x: i,
        // @ts-ignore
        y: facebookVisitorsTrend[i],
      };
      // @ts-ignore
      visitorsSum += facebookVisitorsTrend[i];
      facebookChartData[1].data.push(visitorsDataObject);
    }

    const returnObject: any = {
      conversationsSum: conversationsSum,
      visitorsSum: visitorsSum,
      dataset: facebookChartData,
    };

    return returnObject

  }

  getLiveChatChartData() {
    const { application,t } = this.props;
    const livechatChartData: ChartDataModel[] = [
      {
        id: t('applicationSummary.conversations'),
        data: [],
      },
      {
        id: t('applicationSummary.visitors'),
        data: [],
      },
    ];
    let conversationsSum: number = 0;
    let visitorsSum: number = 0;

    const { livechatConversationsTrend, livechatChatVisitorsTrend } = application;

    for (const i in livechatConversationsTrend) {
      const conversationsDataObject = {
        x: i,
        // @ts-ignore
        y: livechatConversationsTrend[i],
      };
      // @ts-ignore
      conversationsSum += livechatConversationsTrend[i];
      livechatChartData[0].data.push(conversationsDataObject);

      const visitorsDataObject = {
        x: i,
        // @ts-ignore
        y: livechatChatVisitorsTrend[i],
      };
      // @ts-ignore
      visitorsSum += livechatChatVisitorsTrend[i];
      livechatChartData[1].data.push(visitorsDataObject);
    }

    const returnObject: any = {
      conversationsSum: conversationsSum,
      visitorsSum: visitorsSum,
      dataset: livechatChartData,
    };

    return returnObject

  }

  getViberChartData() {
    const { application,t } = this.props;
    const viberChartData: ChartDataModel[] = [
      {
        id: t('applicationSummary.conversations'),
        data: [],
      },
      {
        id: t('applicationSummary.visitors'),
        data: [],
      },
    ];
    let conversationsSum: number = 0;
    let visitorsSum: number = 0;

    const { viberConversationsTrend, viberChatVisitorsTrend } = application;

    for (const i in viberConversationsTrend) {
      const conversationsDataObject = {
        x: i,
        // @ts-ignore
        y: viberConversationsTrend[i],
      };
      // @ts-ignore
      conversationsSum += viberConversationsTrend[i];
      viberChartData[0].data.push(conversationsDataObject);

      const visitorsDataObject = {
        x: i,
        // @ts-ignore
        y: viberChatVisitorsTrend[i],
      };
      // @ts-ignore
      visitorsSum += viberChatVisitorsTrend[i];
      viberChartData[1].data.push(visitorsDataObject);
    }

    const returnObject: any = {
      conversationsSum: conversationsSum,
      visitorsSum: visitorsSum,
      dataset: viberChartData,
    };

    return returnObject

  }

  getHangoutsChartData() {
    const { application,t } = this.props;

    const hangoutsChartData: ChartDataModel[] = [
      {
        id: t('applicationSummary.conversations'),
        data: [],
      },
      {
        id: t('applicationSummary.visitors'),
        data: [],
      },
    ];
    let conversationsSum: number = 0;
    let visitorsSum: number = 0;

    const { hangoutsChatConversationsTrend, hangoutsChatVisitorsTrend } = application;

    for (const i in hangoutsChatConversationsTrend) {
      const conversationsDataObject = {
        x: i,
        // @ts-ignore
        y: hangoutsChatConversationsTrend[i],
      };
      // @ts-ignore
      conversationsSum += hangoutsChatConversationsTrend[i];
      hangoutsChartData[0].data.push(conversationsDataObject);

      const visitorsDataObject = {
        x: i,
        // @ts-ignore
        y: hangoutsChatVisitorsTrend[i],
      };
      // @ts-ignore
      visitorsSum += hangoutsChatVisitorsTrend[i];
      hangoutsChartData[1].data.push(visitorsDataObject);
    }

    const returnObject: any = {
      conversationsSum: conversationsSum,
      visitorsSum: visitorsSum,
      dataset: hangoutsChartData,
    };

    return returnObject
  }

  navigateTo(to: string) {
    const { application } = this.props;

    this.props.history.push(`/application/${application.id}/${to}`);
  }
  render() {
    const { application, t } = this.props;
    const {
      overallConversationsTrendDataSum,
      overallConversationsCardChartData,
      overallVisitorsTrendDataSum,
      overallVisitorsCardChartData,

      facebookChartData,
      facebookConversationsTrendDataSum,
      facebookVisitorsTrendDataSum,

      hangoutsChartData,
      hangoutsConversationsTrendDataSum,
      hangoutsVisitorsTrendDataSum,

      livechatChartData,
      livechatConversationsTrendDataSum,
      livechatVisitorsTrendDataSum,

      viberChartData,
      viberConversationsTrendDataSum,
      viberChatVisitorsTrendDataSum,

      overallConversationTickValue,
      overallVisitorTickValue
    } = this.state;
    return (

      <div className="animated fadeIn summary">
        <Row>
          <Col lg="4" md="12" xs="12" sm="12">
            <ApplicationStatCard
              header={application.overallUsersCount || 0}
              mainText={t('applicationSummary.totalVisitors')}
              icon="fa fa-user-friends"
              color="success"
              variant="1"
            />
          </Col>
          <Col lg="4" md="12" xs="12" sm="12">
            <CheckPermission variant="enableIf" permissions={['conversations_read']}>
              <ApplicationStatCard
                className="total-conversations-widget"
                header={application.overallConversationsCount || 0}
                mainText={t('applicationSummary.totalConversations')}
                icon="fa fa-comments"
                color="success"
                variant="1"
                onClick={() => this.navigateTo('conversations')}
                disabled
              />
            </CheckPermission>
          </Col>
          <Col lg="4" md="12" xs="12" sm="12">
            <ApplicationStatCard
              header={application.handoveredCount || 0}
              mainText={t('applicationSummary.totalUsersWaiting')}
              icon="far fa-clock"
              color="warning"
              variant="1"
            />
          </Col>
        </Row>

        <Row>
          <Col lg="6" xs="12" sm="6">
            <ApplicationTrendsCard
              bodyHeader={overallConversationsTrendDataSum}
              bodySubHeader={t('applicationSummary.recentConversationsTrend')}
              chartData={overallConversationsCardChartData}
              tickValue={overallConversationTickValue}
          />
          </Col>
          <Col lg="6" xs="12" sm="6">
            <ApplicationTrendsCard
              bodyHeader={overallVisitorsTrendDataSum}
              bodySubHeader={t('applicationSummary.recentVisitorsTrend')}
              chartData={overallVisitorsCardChartData}
              tickValue={overallVisitorTickValue}
            />
          </Col>
        </Row>

        <Row>
          <Col lg="3" md="3" xs="6">
            <ApplicationSocialCard
              variant="messenger"
              icon="fab fa-facebook-messenger"
              chartData={facebookChartData}
              conversations={facebookConversationsTrendDataSum}
              visitors={facebookVisitorsTrendDataSum}
            />
          </Col>
          <Col lg="3" md="3" xs="6">
            <ApplicationSocialCard
              variant="hangouts"
              icon="fab fa-google"
              chartData={hangoutsChartData}
              conversations={hangoutsConversationsTrendDataSum}
              visitors={hangoutsVisitorsTrendDataSum}
            />
          </Col>
          <Col lg="3" md="3" xs="6">
            <ApplicationSocialCard
              variant="livechat"
              icon="fas fa-comment-dots"
              chartData={livechatChartData}
              conversations={livechatConversationsTrendDataSum}
              visitors={livechatVisitorsTrendDataSum}
            />
          </Col>
          <Col lg="3" md="3" xs="6">
            <ApplicationSocialCard
              variant="viber"
              icon="fab fa-viber"
              chartData={viberChartData}
              conversations={viberConversationsTrendDataSum}
              visitors={viberChatVisitorsTrendDataSum}
            />
          </Col>

        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  platforms: state.platforms.data,
});

const mapDispatchToProps = {
  getApplication,
  getPlatforms,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Summary)));
