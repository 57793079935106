import { get, post, patch, del } from '../../shared/services/http.service';
import { CustomFieldsQueryParams } from '../../shared/models/query-params.model';
import { CustomFieldModel } from 'src/shared/models/custom-fields.model';

export const getCustomFields = async (
  applicationId: string,
  queryParams: CustomFieldsQueryParams,
) => {
  return get(`/applications/${applicationId}/custom-fields`, {
    params: queryParams,
  });
};

export const createCustomField = async (applicationId: string, customField: CustomFieldModel) => {
  return post(`/applications/${applicationId}/custom-fields`, { data: customField });
};

export const updateCustomField = async (
  applicationId: string,
  customFieldId: string,
  customField: CustomFieldModel,
) => {
  return patch(`/applications/${applicationId}/custom-fields/${customFieldId}`, {
    data: customField,
  });
};

export const deleteCustomField = async (applicationId: string, customFieldId: string) => {
  return del(`/applications/${applicationId}/custom-fields/${customFieldId}`);
};
