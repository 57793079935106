import React, { FC, ReactText, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Button } from 'reactstrap';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import {
  deleteFollowUpNotification,
  getFollowUpNotifications,
} from 'src/redux/actions/follow-up-notifications.action';
import { getPlatforms } from 'src/redux/actions/platforms.action';
import { List } from 'src/shared/components';
import { QueryParams, Operations, SelectModel, PageQueryParams } from 'src/shared/models';
import { FollowUpNotificationModel } from 'src/shared/models/follow-up-notifications.model';
import FollowUpNotificationsEditorModal from './FollowUpNotificationsEditorModal';
import { getDynamicClowFlowMenuItems } from 'src/redux/services/dynamic-conv-flow.service';

interface FollowUpNotificationsProps {
  application: ApplicationModel;
  followUpNotifications: FollowUpNotificationModel[];
  platforms: string[];
  getPlatforms: (applicationId: number) => Promise<void>;
  numberOfFollowUpNotifications: number;
  followUpNotificationsLoading: boolean;
  followUpNotificationsLoaded: boolean;
  followUpNotificationsError: boolean;
  getApplication: (applicationId: number, lastLoginAt?: string) => Promise<void>;
  getFollowUpNotifications: (appId: string, queryParams: PageQueryParams) => Promise<void>;
  deleteFollowUpNotification: (appId: string, followUpNotificationId: number) => Promise<void>;
}

const FollowUpNotifications: FC<FollowUpNotificationsProps> = ({
  application,
  followUpNotifications,
  platforms,
  getPlatforms,
  getApplication,
  getFollowUpNotifications,
  numberOfFollowUpNotifications,
  followUpNotificationsLoading,
  followUpNotificationsLoaded,
  followUpNotificationsError,
  deleteFollowUpNotification,
}) => {
  const { appId } = useParams<{ appId: string }>();

  /* eslint-disable-next-line */
  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: 0,
    size: 20,
  });
  const { t } = useTranslation();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [dynamicConvFlowMenuItems, setDynamicConvFlowMenuItems] = useState<SelectModel[]>([]);
  const [dynamicConvFlowMenuItemsSlug, setDynamicConvFlowMenuItemsSlug] = useState<SelectModel[]>(
    [],
  );

  const [editableItem, setEditableItem] = useState<FollowUpNotificationModel>();

  useEffect(() => {
    if (!application.id) {
      getApplication(appId as any);
    }
    if (!platforms || platforms.length === 0) {
      getPlatforms(appId as any);
    }
    if (dynamicConvFlowMenuItems.length === 0) {
      getDynamicClowFlowMenuItems(appId).then(
        (r: { id: number; slug: string; title: string }[]) => {
          const mapped = r.map(item => {
            return { label: item.title, value: item.id };
          });

          const mappedSlug = r.map(item => {
            return { label: item.title, value: item.slug };
          });

          setDynamicConvFlowMenuItemsSlug(mappedSlug);
          setDynamicConvFlowMenuItems(mapped);
        },
      );
    }
    /* eslint-disable-next-line */
  }, []);

  const fetch = async () => {
    await getFollowUpNotifications(appId, queryParams);
  };

  const remove = async (item: FollowUpNotificationModel) => {
    await deleteFollowUpNotification(appId as string, item.id);
  };

  const edit = async (id: ReactText) => {
    setEditableItem(followUpNotifications.find(e => e.id === id));
    setIsCreateModalOpen(true);
  };

  const renderStartsAt = (item: FollowUpNotificationModel): React.ReactNode => {
    const formatTime = (time: string) => {
      return time.toLocaleLowerCase().substring(2, time.length);
    };

    return item.startsAt.map(i => {
      return (
        <div key={i.value}>
          {i.messagingPlatform} - {formatTime(i.value)},{' '}
        </div>
      );
    });
  };

  const renderMessage = (item: FollowUpNotificationModel): React.ReactNode => {
    return (
      <>
        {item.message.text}
        {item.message.buttons && (
          <>
            : <br />
          </>
        )}
        {item.message.buttons?.map(b => {
          return (
            <>
              {b.text}#{b.value}
              <br/>
            </>
          );
        })}
      </>
    );
  };

  const renderMenuItems = (item: FollowUpNotificationModel) => {
    return (
      <>
        {item.triggerDynamicConversationFlowMenuItems.map(item => (
          <Button key={item.id} className="btn btn-secondary mr-1 mb-1">
            {item.title}
          </Button>
        ))}
      </>
    );
  };
  return (
    <>
      <div className="animated fadeIn">
        <List
          useCardContent
          data={followUpNotifications}
          totalElements={numberOfFollowUpNotifications}
          loaded={followUpNotificationsLoaded}
          loading={followUpNotificationsLoading}
          error={followUpNotificationsError}
          fetchData={fetch}
          title={t('common.followUpNotifications')}
          createModal={(isModalOpen, toggleModal) => (
            <FollowUpNotificationsEditorModal
              isOpen={isModalOpen}
              platforms={platforms}
              editableItem={undefined}
              dynamicConvFlowMenuItems={dynamicConvFlowMenuItems}
              dynamicConvFlowMenuItemsSlug={dynamicConvFlowMenuItemsSlug}
              setIsModalOpen={toggleModal}
            />
          )}
          fields={[
            { key: 'name', label: t('common.title') },
            {
              key: 'message',
              label: t('common.message'),
              render: renderMessage,
            },
            { key: 'startsAt', label: t('common.startsAt'), render: renderStartsAt },
            {
              key: 'triggerDynamicConversationFlowMenuItems',
              label: t('common.triggerDynamicConversationFlowMenuItems'),
              render: renderMenuItems,
            },
            {
              key: 'operations',
              label: t('common.operations'),
              operations: [Operations.UPDATE, Operations.DELETE],
            },
          ]}
          noDataLabel={t('followUpNotifications.noItems')}
          deleteFunction={remove}
          editFunction={edit}
          deleteTitle={t('followUpNotifications.delete')}
          deleteText={t('followUpNotifications.deleteConfirmMessage')}
          createPermission="follow_up_notification_create"
          updatePermission="follow_up_notification_update"
          deletePermission="follow_up_notification_delete"
        />
      </div>
      <FollowUpNotificationsEditorModal
        isOpen={isCreateModalOpen}
        platforms={platforms}
        editableItem={editableItem}
        dynamicConvFlowMenuItems={dynamicConvFlowMenuItems}
        dynamicConvFlowMenuItemsSlug={dynamicConvFlowMenuItemsSlug}
        setIsModalOpen={setIsCreateModalOpen}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    followUpNotifications: state.followUpNotifications.data.content,
    platforms: state.platforms.data,
    numberOfFollowUpNotifications: state.followUpNotifications.data.totalElements,
    followUpNotificationsLoading: state.followUpNotifications.loading,
    followUpNotificationsLoaded: state.followUpNotifications.loaded,
    followUpNotificationsError: state.followUpNotifications.error,
  };
};

const mapDispatchToProps = {
  getApplication,
  getFollowUpNotifications,
  deleteFollowUpNotification,
  getPlatforms,
};

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpNotifications);
