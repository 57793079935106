import { Dispatch } from 'redux';
import { uploadFaqDataset as uploadFaqDatasetService } from '../services/faq-dataset.service';

export const UPLOAD_FAQ_DATASET_REQUEST = 'faq-dataset/UPLOAD_FAQ_DATASET_REQUEST';
export const UPLOAD_FAQ_DATASET_SUCCESS = 'faq-dataset/UPLOAD_FAQ_DATASET_SUCCESS';
export const UPLOAD_FAQ_DATASET_ERROR = 'faq-dataset/UPLOAD_FAQ_DATASET_ERROR';

export const FAQ_DATASET_CLEAR_ERROR = 'faqDataset/FAQ_DATASET_CLEAR_ERROR';

export interface FaqDatasetUploadModel {
  url: string;
  fieldDelimiter: string;
  quote: string;
  escape: string;
  keywordDelimiter: string;
  updateMethod: string;
}

export const uploadFaqDataset = (
  applicationId: number,
  platform: string,
  payload: FaqDatasetUploadModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPLOAD_FAQ_DATASET_REQUEST,
    });

    try {
      dispatch({
        type: UPLOAD_FAQ_DATASET_SUCCESS,
        payload: await uploadFaqDatasetService(applicationId, platform, payload),
      });
    } catch (error) {
      dispatch({
        type: UPLOAD_FAQ_DATASET_ERROR,
        error,
      });
    }
  };
};

export const clearListErrors = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: FAQ_DATASET_CLEAR_ERROR,
    });
  };
};
