import React, { Fragment } from 'react';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Pagination from 'react-js-pagination';
import { IntentStatisticModel } from '../../../shared/models/intent-statistics.model';

import styles from '../IntentStatistics.module.scss';

interface Props {
  intentStatistics: IntentStatisticModel[];
  activePage: number;
  size: number;
  totalElements: number;
  pageChange: Function;
}

const IntentStatisticsTable: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const {
    intentStatistics,
    activePage,
    size,
    totalElements,
    pageChange,
  } = props;

  return (
    <Fragment>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th className={styles.intent}>{t('common.intent')}</th>
            <th className={styles.count}>{t('common.count')}</th>
            <th className={styles.ratio}>{t('common.ratio')}</th>
          </tr>
        </thead>
        <tbody>
          {intentStatistics.map((statEl: any) => {
            return (
              <tr key={statEl.name}>
                <td className={styles.intent}>{statEl.name}</td>
                <td className={styles.count}>{statEl.count}</td>
                <td className={styles.ratio}>
                  {(statEl.ratio * 100).toFixed(2)} %
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Pagination
        activePage={activePage}
        itemsCountPerPage={size}
        totalItemsCount={totalElements}
        pageRangeDisplayed={5}
        onChange={(index: number) => pageChange(index)}
      />
    </Fragment>
  );
};

export default IntentStatisticsTable;
