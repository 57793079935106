import { StateModel } from '../../shared/models/default-state.model';

import {
  GET_TALENT_POOLS_REQUEST,
  GET_TALENT_POOLS_SUCCESS,
  GET_TALENT_POOLS_ERROR,
  GET_TALENT_POOL_REQUEST,
  GET_TALENT_POOL_SUCCESS,
  GET_TALENT_POOL_ERROR,
  CREATE_TALENT_POOL_REQUEST,
  CREATE_TALENT_POOL_SUCCESS,
  CREATE_TALENT_POOL_ERROR,
  UPDATE_TALENT_POOL_REQUEST,
  UPDATE_TALENT_POOL_SUCCESS,
  UPDATE_TALENT_POOL_ERROR,
  DELETE_TALENT_POOL_REQUEST,
  DELETE_TALENT_POOL_SUCCESS,
  DELETE_TALENT_POOL_ERROR,
} from '../actions/talent-pools.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export const talentPoolsReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_TALENT_POOLS_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_TALENT_POOLS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_TALENT_POOLS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const talentPoolReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_TALENT_POOL_REQUEST:
    case CREATE_TALENT_POOL_REQUEST:
    case UPDATE_TALENT_POOL_REQUEST:
    case DELETE_TALENT_POOL_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_TALENT_POOL_SUCCESS:
    case CREATE_TALENT_POOL_SUCCESS:
    case UPDATE_TALENT_POOL_SUCCESS:
    case DELETE_TALENT_POOL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_TALENT_POOL_ERROR:
    case CREATE_TALENT_POOL_ERROR:
    case UPDATE_TALENT_POOL_ERROR:
    case DELETE_TALENT_POOL_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
