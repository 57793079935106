import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Row, Label } from 'reactstrap';

import { useFormikContext, ArrayHelpers, FieldArray } from 'formik';

import { ReactSelect, TextField } from 'src/shared/components';
import { SelectModel } from 'src/shared/models';

interface ContextControllerFormProps {}

export interface ContextControllerContextAction {
  method: SelectModel;
  key?: string;
  value?: string;
  function?: string;
}

export enum ContextControllerMethod {
  SERVICE = 'SERVICE',
  CONTEXT_SET = 'CONTEXT_SET',
  CONTEXT_REMOVE = 'CONTEXT_REMOVE',
}

const ContextControllerForm: FC<ContextControllerFormProps> = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<{
    contextActions: ContextControllerContextAction[];
  }>();

  const remove = (arrayHelpers: ArrayHelpers, index: number) => {
    arrayHelpers.remove(index);
  };

  const add = (arrayHelpers: ArrayHelpers) => {
    arrayHelpers.push({ method: '', key: '', value: '', function: '' });
  };

  const methods = useMemo(() => {
    return Object.keys(ContextControllerMethod).map(enumValue => {
      return {
        value: enumValue,
        label: t(`questionnaire.contextControllerForm.actions.${enumValue.toLowerCase()}`),
      };
    });
  }, [t]);

  return (
    <FieldArray
      name="contextActions"
      render={arrayHelpers => (
        <>
          <FormGroup>
            {values.contextActions.length > 0 && (
              <Row>
                {Object.keys(values.contextActions[0]).map(name => (
                  <Col key={name}>
                    <Label>{t(`common.${name}`)}</Label>
                  </Col>
                ))}
                <Col lg={1}></Col>
              </Row>
            )}

            {values.contextActions &&
              values.contextActions.map((contextAction, index) => (
                <Row className="mb-2" key={index}>
                  <Col>
                    <ReactSelect
                      name={`contextActions[${index}].method`}
                      options={methods}
                      onChanged={(allowedFileMimeTypes: SelectModel) => {
                        setFieldValue(`contextActions[${index}].key`, '');
                        setFieldValue(`contextActions[${index}].value`, '');
                        setFieldValue(`contextActions[${index}].function`, '');
                      }}
                    />
                  </Col>
                  <Col>
                    {(contextAction.method.value === ContextControllerMethod.CONTEXT_SET ||
                      contextAction.method.value === ContextControllerMethod.CONTEXT_REMOVE) && (
                      <TextField name={`contextActions[${index}].key`} />
                    )}
                  </Col>
                  <Col>
                    {contextAction.method.value === ContextControllerMethod.CONTEXT_SET && (
                      <TextField name={`contextActions[${index}].value`} />
                    )}
                  </Col>
                  <Col>
                    {contextAction.method.value === ContextControllerMethod.SERVICE && (
                      <TextField name={`contextActions[${index}].function`} />
                    )}
                  </Col>
                  <Col lg={1}>
                    <div>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => remove(arrayHelpers, index)}
                      >
                        <i className="far fa-trash-alt" />
                      </button>
                    </div>
                  </Col>
                </Row>
              ))}
            <Row className="justify-content-center mt-3">
              <button type="button" className="btn btn-primary" onClick={() => add(arrayHelpers)}>
                <span className="mr-2">{t('common.addAction')}</span>
                <i className="fas fa-plus" />
              </button>
            </Row>
          </FormGroup>
        </>
      )}
    />
  );
};

export default ContextControllerForm;
