import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody, Alert } from 'reactstrap';

import { StateModel } from 'src/shared/models/default-state.model';
import { CustomFieldModel } from 'src/shared/models/custom-fields.model';

import { getApplication, ApplicationModel } from 'src/redux/actions/applications.action';
import {
  getCustomFields,
  clearCustomFields,
  deleteCustomField,
} from 'src/redux/actions/custom-fields.action';

import CustomFieldsHeader from './components/CustomFieldsHeader';
import CustomFieldsTable from './components/CustomFieldsTable';
import CustomFieldModal from './components/CustomFieldModal';
import CustomFieldConfigModal from './components/CustomFieldConfigModal';
import ConfirmModal from 'src/shared/components/modals/ConfirmModal';
import Spinner from 'src/shared/components/Spinner';

export type ModalEventType = 'create' | 'edit' | 'confirm' | 'config' | undefined;

interface Props {
  getApplication: Function;
  application: ApplicationModel;
  getCustomFields: Function;
  customFields: StateModel;
  deleteCustomField: Function;
  deleteEvent: StateModel;
  clearCustomFields: Function;
}

const CustomFields: React.FC<Props> = ({
  getApplication,
  application,
  getCustomFields,
  customFields,
  deleteCustomField,
  deleteEvent,
  clearCustomFields,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [isConfigModalOpen, setConfigModalOpen] = useState<boolean>(false);
  const [modalEvent, setModalEvent] = useState<ModalEventType | undefined>(undefined);
  const [selectedCustomField, setSelectedCustomField] = useState<CustomFieldModel | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }

    getCustomFields(appId);

    return () => {
      clearCustomFields();
    };
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    if (!isModalOpen && selectedCustomField) {
      setSelectedCustomField(undefined);
    }
    /* eslint-disable-next-line */
  }, [isModalOpen]);

  const handleDelete = async () => {
    if (appId && selectedCustomField) {
      await deleteCustomField(appId, selectedCustomField.id);
      getCustomFields(appId);
      toggleModal('confirm');
    }
  };

  const toggleModal = (
    event: ModalEventType,
    customField: CustomFieldModel | undefined = undefined,
  ) => {
    setSelectedCustomField(customField);
    setModalEvent(event);

    switch (event) {
      case 'create':
        setModalOpen(!isModalOpen);
        break;

      case 'edit':
        setModalOpen(!isModalOpen);
        break;

      case 'confirm':
        setConfirmModalOpen(!isConfirmModalOpen);
        break;

      case 'config':
        setConfigModalOpen(!isConfigModalOpen);
        break;

      default:
        break;
    }
  };

  return (
    <Fragment>
      <Row className="animated fadeIn">
        <Col>
          <CustomFieldsHeader toggleModal={toggleModal} />
          <Card>
            <CardBody>
              {customFields.loading && (
                <div className="d-flex justify-content-center">
                  <Spinner loading={customFields.loading} size="2x" />
                </div>
              )}

              {customFields.loaded && !customFields.data?.length && (
                <Alert color="info" className="text-center mb-0">
                  {t('customFields.noCustomFieldsFound')}
                </Alert>
              )}

              {customFields.loaded && customFields.data?.length > 0 && (
                <CustomFieldsTable customFields={customFields.data} toggleModal={toggleModal} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <CustomFieldConfigModal
        isOpen={isConfigModalOpen}
        selectedCustomField={selectedCustomField}
        toggleModal={() => toggleModal('config')}
      />

      <CustomFieldModal
        isOpen={isModalOpen}
        modalEvent={modalEvent}
        selectedCustomField={selectedCustomField}
        toggleModal={() => setModalOpen(false)}
      />

      <ConfirmModal
        title={t('customFields.deleteCustomField')}
        text={t('customFields.deleteCustomFieldConfirmMessage')}
        item={selectedCustomField?.key}
        isOpen={isConfirmModalOpen}
        isLoading={deleteEvent.loading}
        confirm={handleDelete}
        cancel={() => toggleModal('confirm')}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  customFields: state.customFields.data.customFields,
  deleteEvent: state.customFields.data.deleteCustomField,
});

const mapDispatchToProps = {
  getApplication,
  getCustomFields,
  deleteCustomField,
  clearCustomFields,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFields);
