import { Dispatch } from 'redux';
import { QueryParams } from '../../shared/models/query-params.model';
import {
  getTalentPools as getTalentPoolsService,
  getTalentPool as getTalentPoolService,
  createTalentPool as createTalentPoolService,
  updateTalentPool as updateTalentPoolService,
  deleteTalentPool as deleteTalentPoolService,
} from '../services/talent-pools.service';

export const GET_TALENT_POOLS_REQUEST = 'talent-pools/GET_TALENT_POOLS_REQUEST';
export const GET_TALENT_POOLS_SUCCESS = 'talent-pools/GET_TALENT_POOLS_SUCCESS';
export const GET_TALENT_POOLS_ERROR = 'talent-pools/GET_TALENT_POOLS_ERROR';

export const GET_TALENT_POOL_REQUEST = 'talent-pools/GET_TALENT_POOL_REQUEST';
export const GET_TALENT_POOL_SUCCESS = 'talent-pools/GET_TALENT_POOL_SUCCESS';
export const GET_TALENT_POOL_ERROR = 'talent-pools/GET_TALENT_POOL_ERROR';

export const CREATE_TALENT_POOL_REQUEST = 'talent-pools/CREATE_TALENT_POOL_REQUEST';
export const CREATE_TALENT_POOL_SUCCESS = 'talent-pools/CREATE_TALENT_POOL_SUCCESS';
export const CREATE_TALENT_POOL_ERROR = 'talent-pools/CREATE_TALENT_POOL_ERROR';

export const UPDATE_TALENT_POOL_REQUEST = 'talent-pools/UPDATE_TALENT_POOL_REQUEST';
export const UPDATE_TALENT_POOL_SUCCESS = 'talent-pools/UPDATE_TALENT_POOL_SUCCESS';
export const UPDATE_TALENT_POOL_ERROR = 'talent-pools/UPDATE_TALENT_POOL_ERROR';

export const DELETE_TALENT_POOL_REQUEST = 'talent-pools/DELETE_TALENT_POOL_REQUEST';
export const DELETE_TALENT_POOL_SUCCESS = 'talent-pools/DELETE_TALENT_POOL_SUCCESS';
export const DELETE_TALENT_POOL_ERROR = 'talent-pools/DELETE_TALENT_POOL_ERROR';

export interface TalentPoolModels {
  id?: number;
  name: string;
  tenantId: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export const getTalentPools = (queryParams: QueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TALENT_POOLS_REQUEST,
    });

    const talentPools = await getTalentPoolsService(queryParams);

    try {
      dispatch({
        type: GET_TALENT_POOLS_SUCCESS,
        payload: talentPools,
      });
    } catch (error) {
      dispatch({
        type: GET_TALENT_POOLS_ERROR,
        error,
      });
    }
  };
};

export const getTalentPool = (talentPoolId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TALENT_POOL_REQUEST,
    });

    const talentPool: TalentPoolModels = await getTalentPoolService(talentPoolId);

    try {
      dispatch({
        type: GET_TALENT_POOL_SUCCESS,
        payload: talentPool,
      });
    } catch (error) {
      dispatch({
        type: GET_TALENT_POOL_ERROR,
        error,
      });
    }
  };
};

export const createTalentPool = (talentPool: TalentPoolModels) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_TALENT_POOL_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_TALENT_POOL_SUCCESS,
        payload: await createTalentPoolService(talentPool),
      });
    } catch (error) {
      dispatch({
        type: CREATE_TALENT_POOL_ERROR,
        error,
      });
    }
  };
};

export const updateTalentPool = (talentPoolId: number, talentPool: TalentPoolModels) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_TALENT_POOL_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_TALENT_POOL_SUCCESS,
        payload: await updateTalentPoolService(talentPoolId, talentPool),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_TALENT_POOL_ERROR,
        error,
      });
    }
  };
};

export const deleteTalentPool = (talentPoolId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_TALENT_POOL_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_TALENT_POOL_SUCCESS,
        payload: await deleteTalentPoolService(talentPoolId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_TALENT_POOL_ERROR,
        error,
      });
    }
  };
};
