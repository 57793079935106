import { get, post } from '../../shared/services/http.service';
import { PageQueryParams } from 'src/shared/models';
import { DynamicConvFlowImportModel } from 'src/shared/models/dynamic-conv-flow.model';

export enum ImportTypes {
  IMPORT_DYNAMIC_CONVFLOW = 'IMPORT_DYNAMIC_CONVFLOW',
  IMPORT_CONVFLOW_VARIABLES = 'IMPORT_CONVFLOW_VARIABLES',
}
const baseUrl = (applicationId: string) => `/applications/${applicationId}/import`;

export const getDynamicConvFlows = async (
  applicationId: string,
  params: PageQueryParams,
  type: ImportTypes,
) => {
  return get(`${baseUrl(applicationId)}/imports?type=${type}`, { params });
};

export const getDynamicClowFlowMenuItems = async (applicationId: string) =>
  get(`applications/${applicationId}/dynamic-conversation-flow/menuitems`);

export const createDynamicConvFlow = async (
  applicationId: string,
  data: DynamicConvFlowImportModel,
  type: ImportTypes,
) => {
  if (typeof data.configId === 'object') {
    // eslint-disable-next-line radix, no-param-reassign
    data.configId = parseInt(data.configId.value);
  }
  return post(
    `/applications/${applicationId}/${
      type === ImportTypes.IMPORT_DYNAMIC_CONVFLOW
        ? 'dynamic-conversation-flow'
        : 'conversationflow/variables'
    }/import/xlsx`,
    { data },
  );
};
