import React, { Component, Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import 'react-dates/initialize';
import { DateRangePicker, isInclusivelyBeforeDay, isSameDay } from 'react-dates';
import classNames from 'classnames';
import moment from 'moment';

import 'react-dates/lib/css/_datepicker.css';

interface Props extends WithTranslation {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  handleDateChange: Function;
}

interface State {
  startDate: any;
  endDate: any;
  focusedInput: any;
  dateHasChanged: boolean;
}

class DateRangeFilter extends Component<Props, State> {
  state: State = {
    startDate: null,
    endDate: null,
    focusedInput: null,
    dateHasChanged: false,
  };

  componentDidUpdate = (prev: Props) => {
    const { startDate, endDate } = this.props;

    if (prev.startDate !== startDate && prev.endDate !== endDate) {
      this.setState({
        startDate,
        endDate,
      });
    }
  };

  handleDateChange = (startDate: moment.Moment, endDate: moment.Moment) => {
    if (startDate && endDate) {
      if (!this.state.dateHasChanged) return null;

      this.setState({
        startDate,
        endDate,
        dateHasChanged: false,
      });

      this.props.handleDateChange(startDate, endDate);
    }

    return null;
  };

  // Dátumválasztó bezárása
  handleFocusChange = (focusedInput: string | null) => {
    this.setState({
      focusedInput,
    });
  };

  handlePresetClick = (startDate: moment.Moment, endDate: moment.Moment) => {
    this.setState(
      {
        dateHasChanged: true,
      },
      () => {
        this.handleDateChange(startDate, endDate);
        this.handleFocusChange(null);
      },
    );
  };

  renderDatePresets = () => {
    const { t } = this.props;

    const presets = [
      {
        text: `${t('common.today')}`,
        start: moment(),
        end: moment(),
      },
      {
        text: `${t('common.lastWeek')}`,
        start: moment().subtract(7, 'days'),
        end: moment(),
      },
      {
        text: `${t('common.lastMonth')}`,
        start: moment().subtract(1, 'month'),
        end: moment(),
      },
      {
        text: `${t('common.lastThreeMonth')}`,
        start: moment().subtract(3, 'month'),
        end: moment(),
      },
    ];

    return (
      <div className="PresetDateRangePicker_panel">
        {presets.map(({ text, start, end }) => {
          const isSelected =
            isSameDay(start, this.state.startDate) && isSameDay(end, this.state.endDate);

          return (
            <button
              key={text}
              type="button"
              className={classNames('PresetDateRangePicker_button', {
                PresetDateRangePicker_button__selected: isSelected,
              })}
              onClick={() => this.handlePresetClick(start, end)}
            >
              {text}
            </button>
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        <DateRangePicker
          startDate={this.state.startDate}
          startDateId="startDate"
          endDate={this.state.endDate}
          endDateId="endDate"
          onDatesChange={({ startDate, endDate }) =>
            this.setState({ startDate, endDate, dateHasChanged: true })
          }
          onFocusChange={(focusedInput: string | null) => this.handleFocusChange(focusedInput)}
          onClose={({ startDate, endDate }) => this.handleDateChange(startDate, endDate)}
          focusedInput={this.state.focusedInput}
          isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
          renderCalendarInfo={() => this.renderDatePresets()}
          displayFormat="YYYY-MM-DD"
          firstDayOfWeek={1}
          numberOfMonths={1}
          minimumNights={0}
        />
      </Fragment>
    );
  }
}

export default withTranslation()(DateRangeFilter);
