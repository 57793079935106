import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardBody, Alert, Button } from 'reactstrap';
import queryString from 'query-string';

import { StateModel } from 'src/shared/models/default-state.model';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { getPlatforms } from 'src/redux/actions/platforms.action';
import {
  getApplicationSettings,
  createApplicationSetting,
  updateApplicationSetting,
  deleteApplicationSetting,
  CreateApplicationSettingModel,
} from 'src/redux/actions/application-settings.action';

import { getActivePlatformObjects } from 'src/shared/utils/platforms.util';
import addToast from 'src/shared/utils/notification.util';

import SettingsForm from './components/SettingsForm';
import SettingCreateModal from './components/SettingCreateModal';
import { CheckPermission, PageHeader, Spinner, ConfirmModal } from 'src/shared/components';
import { PlatformModel } from 'src/shared/models';

import styles from './ApplicationSettings.module.scss';

interface Props extends RouteComponentProps<{ id: string }>, WithTranslation {
  getApplication: Function;
  application: ApplicationModel;
  getPlatforms: Function;
  platforms: any;
  getApplicationSettings: Function;
  createApplicationSetting: Function;
  updateApplicationSetting: Function;
  deleteApplicationSetting: Function;
  applicationSettings: StateModel;
  applicationSettingCreate: StateModel;
  applicationSettingUpdate: StateModel;
  applicationSettingDelete: StateModel;
}

interface State {
  activePlatforms: PlatformModel[];
  selectedPlatform: PlatformModel;
  selectedSettingKey: string | undefined;
  isCreateModalOpen: boolean;
  isDeleteConfirmModalOpen: boolean;
}

class ApplicationSettings extends Component<Props> {
  state: State = {
    activePlatforms: [],
    selectedPlatform: {
      label: '',
      value: '',
    },
    selectedSettingKey: undefined,
    isCreateModalOpen: false,
    isDeleteConfirmModalOpen: false,
  };

  componentDidMount = async () => {
    const queryValues = queryString.parse(this.props.location.search);
    let platformObject: PlatformModel | undefined = {
      label: '',
      value: '',
    };

    if (!this.props.application.id) {
      await this.props.getApplication(this.props.match.params.id);
    }

    // Aktív platformok lekérdezése.
    await this.getActivePlatforms();

    // Abban az esetben, ha az URL-ben található platform.
    if (queryValues.platform) {
      platformObject = this.findPlatformObjectByValue(queryValues.platform.toString());
    }

    // Ha az URL-ben található platform és van találat, azaz érvényes paltform van megadva.
    if (platformObject && platformObject.value) {
      this.setPlatform(platformObject);
      // Ha az URL-ben nem található platform vagy nem volt találat, azaz érvénytelen platform volt megadva.
      // Ebben az esetben (ha van aktív platform) az aktív platformok közül az első kerül kiválasztásra
    } else if (this.state.activePlatforms.length > 0) {
      this.setPlatform(this.state.activePlatforms[0]);
    } else {
      return null;
    }

    // A kiválasztott platformhoz tartozó beállítások lekérdezése
    if (this.state.selectedPlatform) {
      this.getApplicationSettings();
    }
  };

  setPlatform(platform: PlatformModel) {
    this.setState({
      selectedPlatform: platform,
    });
  }

  getActivePlatforms = async () => {
    await this.props.getPlatforms(this.props.match.params.id);
    const { platforms } = this.props;

    if (!platforms.data.length) {
      return null;
    }

    // Aktív platform objektumok létrehozása
    const activePlatformObjects: PlatformModel[] = getActivePlatformObjects(platforms.data);

    this.setState((prevState: State) => ({
      activePlatforms: [...prevState.activePlatforms, ...activePlatformObjects],
    }));
  };

  getApplicationSettings = async () => {
    const { id } = this.props.application;
    const { value: platform } = this.state.selectedPlatform;

    await this.props.getApplicationSettings(id, platform);
    this.props.history.push(`/application/${id}/system-management/settings`);
  };

  updateApplicationSetting = async (key: string, value: string) => {
    const { t } = this.props;
    const { id } = this.props.application;

    if (!value) {
      return addToast('error', `${t('applicationSettings.valueRequired')}`);
    }

    await this.props.updateApplicationSetting(id, key, { value });

    const { error } = this.props.applicationSettings;

    if (!error) {
      addToast('success', `${t('applicationSettings.successfulSettingUpdateMessage')}`);
    }

    this.getApplicationSettings();
  };

  createApplicationSetting = async (setting: CreateApplicationSettingModel) => {
    const { id } = this.props.application;

    await this.props.createApplicationSetting(id, setting);

    const { error } = this.props.applicationSettingCreate;

    if (!error) {
      this.toggleCreateModal();
      this.getApplicationSettings();
    }
  };

  deleteApplicationSetting = async () => {
    const { t } = this.props;
    const { id } = this.props.application;
    const { selectedSettingKey } = this.state;

    await this.props.deleteApplicationSetting(id, selectedSettingKey);

    const { error } = this.props.applicationSettings;

    if (!error) {
      addToast('success', `${t('applicationSettings.successfulSettingDeleteMessage')}`);
    }

    this.toggleDeleteConfirmModal();
    this.getApplicationSettings();
  };

  handleSelectionChange = (selectedPlatform: PlatformModel) => {
    this.setState({ selectedPlatform }, () => this.getApplicationSettings());
  };

  toggleCreateModal = () => {
    this.setState((prevState: State) => ({
      isCreateModalOpen: !prevState.isCreateModalOpen,
    }));
  };

  toggleDeleteConfirmModal = (key?: string) => {
    this.setState((prevState: State) => ({
      isDeleteConfirmModalOpen: !prevState.isDeleteConfirmModalOpen,
      selectedSettingKey: key,
    }));
  };

  findPlatformObjectByValue(platformValue: string) {
    const { activePlatforms } = this.state;

    return activePlatforms.find((platform: PlatformModel) => {
      return platform.value === platformValue;
    });
  }

  render() {
    const {
      platforms,
      applicationSettings,
      applicationSettingCreate,
      // applicationSettingUpdate,
      applicationSettingDelete,
      t,
    } = this.props;
    const {
      selectedSettingKey,
      isCreateModalOpen,
      isDeleteConfirmModalOpen,
    } = this.state;

    return (
      <Row className="animated fadeIn">
        <Col>
          <PageHeader title={t('common.settings')} />

          {platforms.loaded && !platforms.data.length && (
            <div className={styles.noData}>{t('common.noActivePlatforms')}</div>
          )}

          {platforms.loaded && platforms.data.length > 0 && (
            <Card>
              <CardHeader>
                <Row>
                  <CheckPermission
                    variant="displayIf"
                    permissions={['application-settings_create']}
                  >
                    <Col className="d-flex justify-content-end">
                      <Button
                        color="success"
                        disabled={platforms.loaded && !platforms.data.length}
                        onClick={() => this.toggleCreateModal()}
                      >
                        {t('applicationSettings.newSetting')}
                      </Button>
                    </Col>
                  </CheckPermission>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    {applicationSettings.loaded && !applicationSettings.data.length && (
                      <Alert className="mb-0 text-center" color="info">
                        {t('applicationSettings.noSettingsForPlatform')}
                      </Alert>
                    )}
                  </Col>
                </Row>

                <Row className="d-flex justify-content-center">
                  <Col lg="4">
                    <SettingsForm
                      applicationSettings={applicationSettings}
                      getApplicationSettings={this.getApplicationSettings}
                      updateApplicationSetting={this.updateApplicationSetting}
                      toggleDeleteConfirmModal={this.toggleDeleteConfirmModal}
                    />
                  </Col>
                </Row>

                {applicationSettings.loading && (
                  <div className="d-flex justify-content-center">
                    <Spinner loading={applicationSettings.loading} size="2x" />
                  </div>
                )}
              </CardBody>
            </Card>
          )}

          <SettingCreateModal
            isOpen={isCreateModalOpen}
            isLoading={applicationSettingCreate.loading}
            toggleCreateModal={() => this.toggleCreateModal()}
            applicationSettings={applicationSettings}
            getApplicationSettings={this.getApplicationSettings}
            createApplicationSetting={this.createApplicationSetting}
          />

          <ConfirmModal
            title={t('applicationSettings.deleteSetting')}
            text={t('applicationSettings.settingDeleteConfirmation')}
            item={selectedSettingKey}
            cancel={() => this.toggleDeleteConfirmModal()}
            confirm={() => this.deleteApplicationSetting()}
            isOpen={isDeleteConfirmModalOpen}
            isLoading={applicationSettingDelete.loading}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  platforms: state.platforms,
  applicationSettings: state.applicationSettings,
  applicationSettingCreate: state.applicationSettingCreate,
  applicationSettingUpdate: state.applicationSettingUpdate,
  applicationSettingDelete: state.applicationSettingDelete,
});

const mapDispatchToProps = {
  getApplication,
  getPlatforms,
  getApplicationSettings,
  createApplicationSetting,
  updateApplicationSetting,
  deleteApplicationSetting,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(ApplicationSettings)));
