import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import { ArrayHelpers, FieldArray, useFormikContext } from 'formik';

import { KeyValuePair } from 'src/shared/models';
import { TextField } from '..';

interface KeyValueListProps {}

const KeyValueList: FC<KeyValueListProps> = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<{
    parameters: Array<KeyValuePair<string>>;
  }>();

  const remove = (arrayHelpers: ArrayHelpers, index: number) => {
    arrayHelpers.remove(index);
  };

  const add = (arrayHelpers: ArrayHelpers) => {
    arrayHelpers.push({ key: '', value: '' });
  };

  return (
    <FieldArray
      name="parameters"
      render={arrayHelpers => (
        <>
          <Col lg={12}>
            <FormGroup>
              {values.parameters.length > 0 && (
                <Row>
                  <Col>
                    <Label>{t('common.key')}</Label>
                  </Col>
                  <Col>
                    <Label>{t('common.value')}</Label>
                  </Col>
                  <Col lg={1}></Col>
                </Row>
              )}

              {values.parameters &&
                values.parameters.map((parameter, index) => (
                  <div className="d-flex flex-row mb-3" key={index}>
                    <Col>
                      <TextField name={`parameters[${index}].key`} />
                    </Col>
                    <Col>
                      <TextField name={`parameters[${index}].value`} />
                    </Col>
                    <Col lg={1}>
                      <div>
                        <button
                          type="button"
                          className="btn btn-danger ml-1"
                          onClick={() => remove(arrayHelpers, index)}
                        >
                          <i className="far fa-trash-alt" />
                        </button>
                      </div>
                    </Col>
                  </div>
                ))}

              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary ml-1"
                  onClick={() => add(arrayHelpers)}
                >
                  <span className="mr-2">{t('common.addParameter')}</span>
                  <i className="fas fa-plus" />
                </button>
              </div>
            </FormGroup>
          </Col>
        </>
      )}
    />
  );
};

export default KeyValueList;
