import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  FormGroup,
  Label,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { Formik, Form, FormikValues, FieldArray } from 'formik';

import { StateModel } from 'src/shared/models/default-state.model';

import { trainingDataSchema } from 'src/shared/schemas/validation.schema';

import { createTrainingData } from 'src/redux/actions/training-data.action';

import TextField from 'src/shared/components/form-inputs/TextField';
import TagPill from 'src/shared/components/TagPill';
import Spinner from 'src/shared/components/Spinner';

import styles from '../TrainingData.module.scss';

interface Props {
  isOpen: boolean;
  toggleModal: Function;
  isInQuestionnaire?: boolean;
  createTrainingData: Function;
  createEvent: StateModel;
}

const TrainingDataCreateModal: React.FC<Props> = ({
  isOpen,
  toggleModal,
  isInQuestionnaire = false,
  createTrainingData,
  createEvent,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const [alertText, setAlertText] = useState<string>('');

  const handleSubmit = async (values: FormikValues) => {
    const { name, sentences } = values;

    setAlertText('');

    if (!sentences.length) {
      setAlertText(t('trainingData.addAtleastOneSentence'));
      return;
    }

    const trainingDataValues = {
      name: isInQuestionnaire ? `BASE_${name}` : name,
      sentences,
    };

    await createTrainingData(appId, trainingDataValues);
    toggleModal();
  };

  const setInputFocus = (id: string) => {
    const input = document.getElementById(id);
    if (input) input.focus();
  };

  return (
    <Modal isOpen={isOpen} toggle={() => toggleModal()}>
      <ModalHeader>{t('trainingData.createTrainingData')}</ModalHeader>

      <Formik
        initialValues={{
          name: '',
          sentence: '',
          sentences: [],
        }}
        validationSchema={trainingDataSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <ModalBody>
              {alertText && (
                <Alert color="danger" className="text-center">
                  {alertText}
                </Alert>
              )}

              <FormGroup>
                <Col>
                  <InputGroup>
                    <Label>{t('common.name')}</Label>
                    {isInQuestionnaire && (
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText style={{ maxHeight: 35 }}>BASE_</InputGroupText>
                      </InputGroupAddon>
                    )}
                    <TextField name="name" />
                  </InputGroup>
                </Col>
              </FormGroup>

              <FieldArray
                name="sentences"
                render={arrayHelpers => (
                  <Fragment>
                    <FormGroup>
                      <Col>
                        <Label>{t('common.sentences')}</Label>
                        <div className="d-flex">
                          <TextField
                            id="sentenceInput"
                            name="sentence"
                            onKeyDown={(e: React.KeyboardEvent) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                arrayHelpers.push(values.sentence);
                                setFieldValue('sentence', '');
                                setInputFocus('sentenceInput');
                              }
                            }}
                          />
                          <Button
                            className="ml-2"
                            onClick={() => {
                              arrayHelpers.push(values.sentence);
                              setFieldValue('sentence', '');
                              setInputFocus('sentenceInput');
                            }}
                          >
                            {t('common.add')}
                          </Button>
                        </div>
                      </Col>
                    </FormGroup>

                    {values.sentences.length > 0 && (
                      <div className={styles.tagPillContainer}>
                        {values.sentences.map((sentence, idx) => (
                          <TagPill
                            key={sentence}
                            item={sentence}
                            isEditable={false}
                            isDeleteConfirmRequired={false}
                            handleDelete={() => arrayHelpers.remove(idx)}
                          />
                        ))}
                      </div>
                    )}
                  </Fragment>
                )}
              />
            </ModalBody>

            <ModalFooter>
              <Spinner loading={createEvent.loading} className="mr-1" />
              <Button type="submit" disabled={isSubmitting} color="primary">
                {t('common.create')}
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  toggleModal();
                  setAlertText('');
                }}
              >
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  createEvent: state.trainingData.create,
});

const mapDispatchToProps = {
  createTrainingData,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainingDataCreateModal);
