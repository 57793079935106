import React, { useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Popover, PopoverBody } from 'reactstrap';
import Select from 'react-select';
import { connect } from 'react-redux';

import { ApplicationModel } from 'src/redux/actions/applications.action';
import { StateModel } from 'src/shared/models';
import { TagCategoryModel, TagModel } from 'src/shared/models/employee.model';

import styles from './Tags.module.scss';

interface EmployeeTagSelectPopoverProps {
  employeeTags?: TagModel[] | null;
  addTagToEntity: Function;
  tagCategories: StateModel;
  getTagCategories: Function;
  application: ApplicationModel;
}

const EmployeeTagSelectPopover: FC<EmployeeTagSelectPopoverProps> = ({
  employeeTags,
  addTagToEntity,
  tagCategories,
  getTagCategories,
  application,
}) => {
  const { t } = useTranslation();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [tagsToAssign, setTagsToAssign] = useState<string[]>([]);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const formatGroupLabel = (data: any) => (
    <div className={styles.selectGroup}>
      <span>{data.label}</span>
      <span className={styles.selectGroupBadge}>{data.options.length}</span>
    </div>
  );

  const getOptions = async () => {
    const options: any = [];

    tagCategories.data.map((tagCategory: TagCategoryModel) => {
      const groupObjects: any = [];
      const optionObject = {
        label: '',
        options: [],
      };

      tagCategory.tags?.map((tag: TagModel) => {
        if (!employeeTags?.find(employeeTag => employeeTag.id === tag.id)) {
          return groupObjects.push({ label: tag.name, value: tag.id, original: tag });
        }

        return null;
      });

      optionObject.label = tagCategory.name;
      optionObject.options = groupObjects;

      return options.push(optionObject);
    });

    setOptions(options);
  };

  const handleButtonClick = async () => {
    if (!tagsToAssign.length) return;

    const tagIds: string[] = [];

    tagsToAssign.map((tag: any) => {
      return tagIds.push(tag.value);
    });

    const toAssign = tagsToAssign.map((tag: any) => tag.original);

    addTagToEntity(toAssign);

    toggle();
  };

  useEffect(() => {
    const { data, loaded, error } = tagCategories;
    if (loaded && !error && data.length > 0) {
      getOptions();
    }
    // eslint-disable-next-line
  }, [tagCategories]);

  useEffect(() => {
    if (popoverOpen) {
      getTagCategories(application.id);
    }
    // eslint-disable-next-line
  }, [popoverOpen]);

  return (
    <Popover
      style={{ width: 274 }}
      placement="bottom"
      isOpen={popoverOpen}
      target={`Popover-0`}
      toggle={toggle}
    >
      <PopoverBody style={{ width: 274 }}>
        <Select
          isMulti
          placeholder={t('common.select')}
          options={options}
          formatGroupLabel={formatGroupLabel}
          onChange={options => setTagsToAssign(options || [])}
        />
        <Row className="mt-2">
          <Col className="pr-1">
            <Button color="secondary" className="w-100" onClick={() => toggle()}>
              {t('common.close')}
            </Button>
          </Col>
          <Col className="pl-1">
            <Button
              color="primary"
              className="w-100"
              disabled={!tagsToAssign.length}
              onClick={() => handleButtonClick()}
            >
              {t('common.save')}
            </Button>
          </Col>
        </Row>
      </PopoverBody>
    </Popover>
  );
};
const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
  };
};

export default connect(mapStateToProps)(EmployeeTagSelectPopover);
