import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  positionSidebarHeaderTitles,
  positionSidebarTypes,
} from '../../../../../../shared/constants/positions.constants';

import {
  setPositionSidebarType,
  setQuestionToEdit,
} from '../../../../../../redux/actions/positions.action';

interface Props {
  setQuestionToEdit: Function;
  sidebarType: string;
  setPositionSidebarType: Function;
}

const SidebarHeader: React.FC<Props> = ({
  setQuestionToEdit,
  sidebarType,
  setPositionSidebarType,
}) => {
  const { t } = useTranslation();

  const showCloseButton = sidebarType !== positionSidebarTypes.SHORTCUTS;

  return (
    <div className="d-flex justify-content-between align-items-baseline">
      <h6 className="m-0">{t(positionSidebarHeaderTitles[sidebarType])}</h6>

      <button
        type="button"
        className="btn btn-ghost-secondary"
        style={{ visibility: showCloseButton ? 'visible' : 'hidden' }}
        onClick={() => {
          setQuestionToEdit(null);
          setPositionSidebarType(positionSidebarTypes.SHORTCUTS);
        }}
      >
        <i className="fas fa-times" />
      </button>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  sidebarType: state.positions.data.sidebarType,
});

const mapDispatchToProps = {
  setQuestionToEdit,
  setPositionSidebarType,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
