import { Dispatch } from 'redux';
import { TrainingDatasetModel } from '../../shared/models/dataset.model';
import { importRecruiterDataset as importRecruiterDatasetService } from '../services/recruiter-import.service';

export const IMPORT_RECRUITER_REQUEST = 'dataset/IMPORT_RECRUITER_REQUEST';
export const IMPORT_RECRUITER_REQUEST_SUCCESS = 'dataset/IMPORT_RECRUITER_REQUEST_SUCCESS';
export const IMPORT_RECRUITER_REQUEST_ERROR = 'dataset/IMPORT_RECRUITER_REQUEST_ERROR';
export const IMPORT_RECRUITER_CLEAR_ERROR = 'dataset/IMPORT_RECRUITER_CLEAR_ERROR';

export const importRecruiterDataset = (applicationId: number, data: TrainingDatasetModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: IMPORT_RECRUITER_REQUEST,
    });

    const response = await importRecruiterDatasetService(applicationId, data);

    try {
      dispatch({
        type: IMPORT_RECRUITER_REQUEST_SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: IMPORT_RECRUITER_REQUEST_ERROR,
        error,
      });

      throw error;
    }
  };
};

export const clearRecruiterDatasetErrors = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: IMPORT_RECRUITER_CLEAR_ERROR,
    });
  };
};
