import { get, patch, post, del } from 'src/shared/services/http.service';
import {
  SubscribingListModel,
  EmailMessageModel,
  SendViberMessageModel,
  QueryParams,
  GeneralQueryParams,
} from 'src/shared/models';

export const getSubscribingLists = async (applicationId: number, params: GeneralQueryParams) => {
  return get(`/applications/${applicationId}/subscribing-lists/pages`, { params });
};

export const getSubscribingList = async (applicationId: number, listId: number) => {
  return get(`/applications/${applicationId}/subscribing-lists/${listId}`);
};

export const createSubscribingList = async (applicationId: number, data: SubscribingListModel) => {
  return post(`/applications/${applicationId}/subscribing-lists`, { data });
};

export const updateSubscribingList = async (
  applicationId: number,
  listId: number,
  data: SubscribingListModel,
) => {
  return patch(`/applications/${applicationId}/subscribing-lists/${listId}`, { data });
};

export const deleteSubscribingList = async (applicationId: number, listId: number) => {
  return del(`/applications/${applicationId}/subscribing-lists/${listId}`);
};

export const addTagToSubscribingList = async (
  applicationId: number,
  listId: number,
  tagIds: string[],
) => {
  let query: any = tagIds.map(tagId =>
    post(`/hrops/applications/${applicationId}/tag/subscribing-lists/${listId}/tags/${tagId}`),
  );
  return Promise.all(query);
  // TODO: replace after batch api works
  //return post(`/hrops/applications/${applicationId}/survey/surveys/${surveyId}/tags/assign`, { data: tagIds });
};

export const sendViberMessageToSubscribingList = async (
  applicationId: number,
  listId: number,
  data: SendViberMessageModel,
) => {
  return post(
    `/applications/${applicationId}/subscribing-lists/${listId}/outbound-messages/viber`,
    {
      data,
    },
  );
};

export const removeSubscribingListTag = async (
  applicationId: number,
  listId: number,
  tagId: string,
) => {
  return del(`/hrops/applications/${applicationId}/tag/subscribing-lists/${listId}/tags/${tagId}`);
};

export const getSubscribers = async (
  applicationId: number,
  listSlug: string,
  params: QueryParams,
) => {
  return get(`/applications/${applicationId}/subscribing-lists/${listSlug}/subscribers`, {
    params,
  });
};

export const deleteSubscriber = async (
  applicationId: number,
  listSlug: string,
  subscriberSlug: string,
  platform: string,
) => {
  return del(
    `/applications/${applicationId}/subscribing-lists/${listSlug}/subscribers/${subscriberSlug}/platforms/${platform}`,
  );
};

export const exportSubscribers = async (applicationId: number, listSlug: string) => {
  return get(`/applications/${applicationId}/subscribing-lists/${listSlug}/subscribers/export/xlsx`, {
    requestConfig: {
      responseType: 'blob',
    }
  });
};

export const getTargetMessagingPlatforms = async (applicationId: number, listId: number) => {
  return get(
    `/applications/${applicationId}/subscribing-lists/${listId}/target-messaging-platforms`,
  );
};

export const sendMessageToSubscribers = async (
  applicationId: number,
  listId: number,
  platform: string,
  data: EmailMessageModel,
) => {
  return post(
    `/applications/${applicationId}/subscribing-lists/${listId}/outbound-messages/${platform}`,
    { data },
  );
};
