import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

import { CheckPermission, PageHeader } from 'src/shared/components';

interface Props {
  setModalOpen: Function;
  setModalEvent: Function;
}

const CustomListsHeader: React.FC<Props> = ({ setModalOpen, setModalEvent }) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      title={t('common.customLists')}
      rightComponent={
        <CheckPermission variant="displayIf" permissions={['customlist_custom-list_create']}>
          <Button
            color="primary"
            onClick={() => {
              setModalOpen(true);
              setModalEvent('create');
            }}
          >
            {t('common.create')}
          </Button>
        </CheckPermission>
      }
    />
  );
};

export default CustomListsHeader;
