import { post } from '../../shared/services/http.service';
import { UsageStatisticsQueryParams } from '../../shared/models/query-params.model';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';

export const getDailyCountOfConversationsAndMessages = async (
  applicationId: number,
  params: UsageStatisticsQueryParams,
  grouping: DynamicGroupByModel,
) => {
  if (typeof params.groupByValues === 'string' && !params.groupByValues) {
    // eslint-disable-next-line no-param-reassign
    delete params.groupByValues;
  }
  return post(`/applications/${applicationId}/analytics/conversations-messages-daily-count`, {
    params,
    data: {
      grouping: grouping,
    },
  });
};

export const exportDailyCountOfConversationsAndMessages = async (
  applicationId: number,
  params: UsageStatisticsQueryParams,
  grouping: DynamicGroupByModel,
) => {
  if (typeof params.groupByValues === 'string' && !params.groupByValues) {
    // eslint-disable-next-line no-param-reassign
    delete params.groupByValues;
  }
  return post(
    `/applications/${applicationId}/analytics/conversations-messages-daily-count/export/csv`,
    {
      params,
      data: {
        grouping: grouping,
      },
    },
  );
};

export const getHourlyDistributionOfAllMessagesPercent = async (
  applicationId: number,
  params: UsageStatisticsQueryParams,
  grouping: DynamicGroupByModel,
) => {
  return post(
    `/applications/${applicationId}/analytics/daily-messages-hourly-percent-distribution`,
    {
      params,
      data: {
        grouping: grouping,
      },
    },
  );
};

export const exportHourlyDistributionOfAllMessagesPercent = async (
  applicationId: number,
  params: UsageStatisticsQueryParams,
  grouping: DynamicGroupByModel,
) => {
  if (typeof params.groupByValues === 'string' && !params.groupByValues) {
    // eslint-disable-next-line no-param-reassign
    delete params.groupByValues;
  }
  return post(
    `/applications/${applicationId}/analytics/daily-messages-hourly-percent-distribution/export/csv`,
    {
      params,
      data: {
        grouping: grouping,
      },
    },
  );
};

export const getHourlyDistributionOfAllMessagesAvgCount = async (
  applicationId: number,
  params: UsageStatisticsQueryParams,
  grouping: DynamicGroupByModel,
) => {
  return post(
    `/applications/${applicationId}/analytics/daily-messages-hourly-average-distribution`,
    {
      params,
      data: {
        grouping: grouping,
      },
    },
  );
};

export const exportHourlyDistributionOfAllMessagesAvgCount = async (
  applicationId: number,
  params: UsageStatisticsQueryParams,
  grouping: DynamicGroupByModel,
) => {
  if (typeof params.groupByValues === 'string' && !params.groupByValues) {
    // eslint-disable-next-line no-param-reassign
    delete params.groupByValues;
  }
  return post(
    `/applications/${applicationId}/analytics/daily-messages-hourly-average-distribution/export/csv`,
    {
      params,
      data: {
        grouping: grouping,
      },
    },
  );
};
