import { ANSWER_TYPES, Operations } from 'src/shared/models';
import {
  ImageAnswerFormItem,
  TextOrOptionAnswerFormItem,
  OptionAnswerFormItem,
} from './QuestionsAndAnswers';

export const getFields = (values: any, t: any) => {
  switch (values.type.value) {
    case ANSWER_TYPES.IMAGE: {
      return [
        {
          key: 'allowedFileExtensions',
          label: t('common.allowedFileExtensions'),
          render: (item: ImageAnswerFormItem) =>
            (item as ImageAnswerFormItem)?.allowedFileExtensions,
        },
        {
          key: 'invalidFileExtensionMessage',
          label: t('common.invalidFileExtensionMessage'),
          render: (item: ImageAnswerFormItem) =>
            (item as ImageAnswerFormItem)?.invalidFileExtensionMessage,
        },
        {
          key: 'allowedMaxFileSizeInBytes',
          label: t('common.allowedMaxFileSizeInBytes'),
          render: (item: ImageAnswerFormItem) =>
            (item as ImageAnswerFormItem)?.allowedMaxFileSizeInBytes,
        },
        {
          key: 'invalidFileSizeMessage',
          label: t('common.invalidFileSizeMessage'),
          render: (item: ImageAnswerFormItem) =>
            (item as ImageAnswerFormItem)?.invalidFileSizeMessage,
        },
        {
          key: 'operations',
          label: t('common.operations'),
          operations: [Operations.DELETE],
        },
      ];
    }
    case ANSWER_TYPES.OPTIONS: {
      return [
        {
          key: 'text',
          label: t('common.text'),
          render: (item: TextOrOptionAnswerFormItem) => (item as TextOrOptionAnswerFormItem)?.text,
        },
        {
          key: 'value',
          label: t('common.value'),
          render: (item: TextOrOptionAnswerFormItem) => (item as TextOrOptionAnswerFormItem)?.value,
        },
        {
          key: 'jumpToSlug',
          label: t('common.jumpToSlug'),
          render: (item: TextOrOptionAnswerFormItem) => {
            return ((item as OptionAnswerFormItem)?.jumpToSlug as any).value;
          },
        },
        {
          key: 'operations',
          label: t('common.operations'),
          operations: [Operations.DELETE],
        },
      ];
    }
    case ANSWER_TYPES.TEXT: {
      return [
        {
          key: 'text',
          label: t('common.text'),
          render: (item: TextOrOptionAnswerFormItem) => (item as TextOrOptionAnswerFormItem)?.text,
        },
        {
          key: 'value',
          label: t('common.value'),
          render: (item: TextOrOptionAnswerFormItem) => (item as TextOrOptionAnswerFormItem)?.value,
        },
        {
          key: 'operations',
          label: t('common.operations'),
          operations: [Operations.DELETE],
        },
      ];
    }
    default:
      return [];
  }
};
