import { StateModel } from '../../shared/models/default-state.model';
import {
  GET_LIST_REQUEST,
  GET_LIST_SUCCESS,
  GET_LIST_ERROR,
  CREATE_LIST_REQUEST,
  CREATE_LIST_SUCCESS,
  CREATE_LIST_ERROR,
  UPDATE_LIST_REQUEST,
  UPDATE_LIST_SUCCESS,
  UPDATE_LIST_ERROR,
  LIST_CLEAR_ERROR,
} from '../actions/lists.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export interface ListModel {
  createdAt?: string;
  description?: string;
  id?: number;
  name?: string;
  tenantId?: number;
  updatedAt?: string;
}

export const listReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_LIST_REQUEST:
    case CREATE_LIST_REQUEST:
    case UPDATE_LIST_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
        error: null,
      };

    case GET_LIST_SUCCESS:
    case CREATE_LIST_SUCCESS:
    case UPDATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };

    case GET_LIST_ERROR:
    case CREATE_LIST_ERROR:
    case UPDATE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    case LIST_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default listReducer;
