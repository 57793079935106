import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose as reduxCompose,
  Store,
} from 'redux';
import thunk from 'redux-thunk';

import { CLEAR_STORE } from './actions/store.action';

import { languageReducer } from './reducers/language.reducer';
import { applicationReducer } from './reducers/application.reducer';
import { applicationsReducer } from './reducers/applications.reducer';
import {
  getApplicationSettingsReducer,
  createApplicationSettingReducer,
  updateApplicationSettingReducer,
  deleteApplicationSettingReducer,
} from './reducers/appliction-settings.reducer';
import {
  userReducer,
  tenantUserReducer,
  tenantUsersReducer,
  tenantUserCreateReducer,
  tenantUserUpdateReducer,
  tenantUserDeleteReducer,
  tenantUserGroupsReducer,
} from './reducers/user.reducer';
import {
  conversationsReducer,
  conversationsQueryParamsReducer,
  conversationsHandoverReducer,
} from './reducers/conversations.reducer';
import { authReducer } from './reducers/auth.reducer';
import { errorHandler } from '../shared/middlewares/errorHandler';
// import { stateToLocalStorage } from '../shared/middlewares/stateToLocalStorage';
import { listsReducer } from './reducers/lists.reducer';
import { listReducer } from './reducers/list.reducer';
import { listElementsReducer } from './reducers/list-elements.reducer';
import { listElementReducer } from './reducers/list-element.reducer';
import { permissionsReducer } from './reducers/permissions.reducer';
import { signatureForUploadReducer, signatureForDeleteReducer } from './reducers/signature.reducer';
import { platformsReducer } from './reducers/platforms.reducer';
import {
  conversationHistoryReducer,
  currentUserReducer,
} from './reducers/conversation-history.reducer';
import { faqDatasetReducer } from './reducers/faq-dataset.reducer';
import { dynamicMenuReducer } from './reducers/dynamic-menu.reducer';
import { dynamicFaqReducer } from './reducers/dynamic-faq.reducer';
import { dynamicConvFlowReducer } from './reducers/dynamic-conv-flow.reducer';
import {
  subscribingListsReducer,
  subscribingListReducer,
  subscribingListCreateReducer,
  subscribingListUpdateReducer,
  subscribingListDeleteReducer,
  subscribersReducer,
  subscriberDeleteReducer,
  addTagToSubscribersReducer,
  removeSubscribersTagReducer,
  exportSubscribersReducer,
  targetMessagingPlatformsReducer,
  sendMessageToSubscribersReducer,
} from './reducers/subscribing-lists.reducer';
import { batchMessagesReducer } from './reducers/batch-messages.reducer';
import { trainingDatasetReducer } from './reducers/dataset.reducer';
import {
  feedbackSummaryReducer,
  feedbacksReducer,
  platformsWithFeedbackReducer,
  exportFeedbacksReducer,
} from './reducers/feedbacks.reducer';
import {
  getUnrecognizedSentencesReducer,
  exportUnrecognizedSentencesReducer,
  resetUnrecognizedSentencesReducer,
} from './reducers/unrecognized-sentences.reducer';
import {
  accountSelectedOptionReducer,
  accountChangePasswordReducer,
} from './reducers/account.reducer';
import {
  getDailyCountOfConversationsAndMessagesReducer,
  exportDailyCountOfConversationsAndMessagesReducer,
  getHourlyDistributionOfAllMessagesPercentReducer,
  getAnalyticsGroupByListReducer,
  exportHourlyDistributionOfAllMessagesPercentReducer,
  getHourlyDistributionOfAllMessagesAvgCountReducer,
  exportHourlyDistributionOfAllMessagesAvgCountReducer,
} from './reducers/usage-statistics.reducer';
import {
  intentStatisticsReducer,
  exportIntentStatisticsReducer,
} from './reducers/intent-statistics.reducer';
import {
  topicStatisticsReducer,
  exportTopicStatisticsReducer,
} from './reducers/topic-statistics.reducer';
import {
  talentPoolApplicantsReducer,
  talentPoolApplicantReducer,
} from './reducers/talent-pool.reducer';
import { talentPoolReducer, talentPoolsReducer } from './reducers/talent-pools.reducer';
import {
  talentPoolCandidateReducer,
  talentPoolCandidateAnswersReducer,
  talentPoolCandidatesReducer,
  talentPoolCandidatesAnswersReducer,
} from './reducers/talent-pools-candidates.reducer';
import { auditLogReducer, auditLogExportReducer } from './reducers/audit-log.reducer';
import {
  complaintsReducer,
  complaintsExportReducer,
  complaintsClearReducer,
} from './reducers/complaints.reducer';
import {
  employeeDatasetReducer,
  tagCategoriesReducer,
  createTagCategoryReducer,
  updateTagCategoryReducer,
  deleteTagCategoryReducer,
  categoryTagsReducer,
  createTagReducer,
  updateTagReducer,
  deleteTagReducer,
  employeesReducer,
  employeeReducer,
  createEmployeeReducer,
  updateEmployeeReducer,
  deleteEmployeeReducer,
  addTagToEmployeeReducer,
  removeEmployeeTagReducer,
} from './reducers/employee.reducer';
import { dynamicEmployeeReducer } from './reducers/dynamic-employee.reducer';
import { salaryAdvanceReducer } from './reducers/salaryAdvances.reducer';
import { positionsReducer } from './reducers/positions.reducer';
import { positionLabelsReducer } from './reducers/position-labels.reducer';
import { positionCriteriasReducer } from './reducers/position-criterias.reducer';
import { criteriaImportReducer } from './reducers/criteria-import.reducer';
import {
  positionQuestionsReducer,
  positionQuestionAnswerOptionsReducer,
} from './reducers/position-questions.reducer';
import { positionFilterQuestionsReducer } from './reducers/position-filter-questions.reducer';
import {
  personasReducer,
  personaReducer,
  personaCreateReducer,
  personaUpdateReducer,
  personaDeleteReducer,
  selectedPersonaReducer,
} from './reducers/personas.reducer';
import {
  messagesReducer,
  labelCategoriesReducer,
  labelsReducer,
  attributeValidatorsReducer,
  regexReplacersReducer,
  expressionsReducer,
} from './reducers/questionnaire.reducer';
import { positionApplicantsReducer } from './reducers/position-applicants.reducer';
import { customFieldsReducer } from './reducers/custom-fields.reducer';
import { customListsReducer } from './reducers/custom-lists.reducer';
import { customListItemsReducer } from './reducers/custom-list-items.reducer';
import { trainingDataReducer } from './reducers/training-data.reducer';
import { shortListReducer } from './reducers/short-list.reducer';
import { interviewsReducer } from './reducers/interviews.reducer';
import { interviewAttendeesReducer } from './reducers/interview-attendees.reducer';
import { recruiterImportDatasetReducer } from './reducers/recruiter-import.reducer';
import uploadedDocumentsReducer from './reducers/uploaded-documents.reducer';
import {
  surveysReducer,
  addTagToSurveyReducer,
  removeSurveyTagReducer,
} from './reducers/surveys.reducer';
import { surveySubscribersReducer } from './reducers/surveysubscribers.reducer';
import { importConfigurationReducer } from './reducers/import-configuration.reducer';
import { messageToAdminReducer } from './reducers/message-to-admin.reducer';
import { surveyGeneralReducer, deleteSurveyReducer } from './reducers/survey.reducer';
import { surveyQuestionsReducer } from './reducers/survey-questions.reducer';
import { surveyQuestionAnswersReducer } from './reducers/survey-question-answers.reducer';
import { conversationFlowVariableReducer } from './reducers/conversation-flow-variables.reducer';
import { ticketsReducer } from './reducers/tickets.reducer';
import {
  helpdeskGroupsReducer,
  addTagToHelpdeskGroupReducer,
  removeHelpdeskGroupTagReducer,
} from './reducers/helpdesk-groups.reducer';
import { helpdeskInstantAnswersReducer } from './reducers/helpdesk-instant-answers.action.reducer';
import { eventsReducer } from './reducers/events.reducer';
import { eventGeneralReducer } from './reducers/event.reducer';
import { eventReportReducer } from './reducers/event-report.reducer';
import { followUpNotificationsReducer } from './reducers/follow-up-notifications.reducer';
import { formsReducer } from './reducers/forms.reducer';
import { formGeneralReducer } from './reducers/form.reducer';
import { formDetailedReportReducer } from './reducers/form-detailed-report.reducer';
import { languageSettingsReducer } from './reducers/language-settings.reducer';
import { contentTranslationsSurveyReducer } from './reducers/content-translations-survey.reducer';
import { contentTranslationsEventReducer } from './reducers/content-translations-event.reducer';
import { employeeReportsReducer } from './reducers/employee-reports.reducer';
import { issueCategoriesReducer } from './reducers/issue-categories.reducer';
import { issuesReducer } from './reducers/issues.reducer';
import { issueReportsReducer } from './reducers/issue-reports.reducer';

const appReducer = combineReducers({
  // Language
  language: languageReducer,
  // Auth
  token: authReducer,
  permissions: permissionsReducer,
  // Users
  user: userReducer,
  tenantUser: tenantUserReducer,
  tenantUserCreate: tenantUserCreateReducer,
  tenantUserUpdate: tenantUserUpdateReducer,
  tenantUserDelete: tenantUserDeleteReducer,
  tenantUsers: tenantUsersReducer,
  tenantUserGroups: tenantUserGroupsReducer,
  // Applications
  platforms: platformsReducer,
  application: applicationReducer,
  applications: applicationsReducer,
  applicationSettings: getApplicationSettingsReducer,
  applicationSettingCreate: createApplicationSettingReducer,
  applicationSettingUpdate: updateApplicationSettingReducer,
  applicationSettingDelete: deleteApplicationSettingReducer,
  // Custom Fields
  customFields: customFieldsReducer,
  // Custom Lists
  customLists: customListsReducer,
  customListItems: customListItemsReducer,
  // Conversation Flow Variables
  conversationFlowVariables: conversationFlowVariableReducer,
  // Conversations
  conversations: conversationsReducer,
  conversationsQueryParams: conversationsQueryParamsReducer,
  conversationsHandover: conversationsHandoverReducer,
  // Conversation History
  conversationHistory: conversationHistoryReducer,
  conversationHistoryCurrentUser: currentUserReducer,
  // Folders & Documents
  list: listReducer,
  lists: listsReducer,
  listElement: listElementReducer,
  listElements: listElementsReducer,
  // Signature
  signatureForUpload: signatureForUploadReducer,
  signatureForDelete: signatureForDeleteReducer,
  // FAQ
  faqDataset: faqDatasetReducer,
  dynamicFaq: dynamicFaqReducer,
  // Dymanic menu
  dynamicMenu: dynamicMenuReducer,
  // Dynamic conf flow
  dynamicConvFlow: dynamicConvFlowReducer,
  // Subscribing Lists
  subscribingLists: subscribingListsReducer,
  subscribingList: subscribingListReducer,
  subscribingListCreate: subscribingListCreateReducer,
  subscribingListUpdate: subscribingListUpdateReducer,
  subscribingListDelete: subscribingListDeleteReducer,
  subscribers: subscribersReducer,
  subscriberDelete: subscriberDeleteReducer,
  addTagToSubscribers: addTagToSubscribersReducer,
  removeSubscribersTag: removeSubscribersTagReducer,
  exportSubscribers: exportSubscribersReducer,
  targetMessagingPlatforms: targetMessagingPlatformsReducer,
  sendMessageToSubscribers: sendMessageToSubscribersReducer,
  // Batch Messages
  batchMessages: batchMessagesReducer,
  // Dataset
  trainingDataset: trainingDatasetReducer,
  // Feedback
  feedbackSummary: feedbackSummaryReducer,
  feedbacks: feedbacksReducer,
  platformsWithFeedbacks: platformsWithFeedbackReducer,
  exportFeedbacks: exportFeedbacksReducer,
  // Unrecognized Sentences
  getUnrecognizedSentences: getUnrecognizedSentencesReducer,
  exportUnrecognizedSentences: exportUnrecognizedSentencesReducer,
  resetUnrecognizedSentences: resetUnrecognizedSentencesReducer,
  // Account
  accountSelectedOption: accountSelectedOptionReducer,
  accountChangePassword: accountChangePasswordReducer,
  // Usage Statistics
  dailyCountOfConversationsAndMessages: getDailyCountOfConversationsAndMessagesReducer,
  exportDailyCountOfConversationsAndMessages: exportDailyCountOfConversationsAndMessagesReducer,
  hourlyDistributionOfAllMessagesPercent: getHourlyDistributionOfAllMessagesPercentReducer,
  analyticsGroupByList: getAnalyticsGroupByListReducer,
  exportHourlyDistributionOfAllMessagesPercent: exportHourlyDistributionOfAllMessagesPercentReducer,
  hourlyDistributionOfAllMessagesAvgCount: getHourlyDistributionOfAllMessagesAvgCountReducer,
  exportHourlyDistributionOfAllMessagesAvgCount: exportHourlyDistributionOfAllMessagesAvgCountReducer,
  // Intent Statistics
  intentStatistics: intentStatisticsReducer,
  exportIntentStatistics: exportIntentStatisticsReducer,
  // Topic Statistics
  topicStatistics: topicStatisticsReducer,
  exportTopicStatistics: exportTopicStatisticsReducer,
  // Talent Pools
  talentPool: talentPoolReducer,
  talentPools: talentPoolsReducer,
  // Talent Pools Candidates
  talentPoolCandidate: talentPoolCandidateReducer,
  talentPoolCandidateAnswers: talentPoolCandidateAnswersReducer,
  talentPoolCandidates: talentPoolCandidatesReducer,
  talentPoolCandidatesAnswers: talentPoolCandidatesAnswersReducer,
  // Talent Pool Applicants
  talentPoolApplicants: talentPoolApplicantsReducer,
  talentPoolApplicant: talentPoolApplicantReducer,
  // Audit Log
  auditLog: auditLogReducer,
  auditLogExport: auditLogExportReducer,
  // Complaints
  complaints: complaintsReducer,
  complaintsExport: complaintsExportReducer,
  complaintsClear: complaintsClearReducer,
  // Employee
  employeeDataset: employeeDatasetReducer,
  tagCategories: tagCategoriesReducer,
  createTagCategory: createTagCategoryReducer,
  updateTagCategory: updateTagCategoryReducer,
  deleteTagCategory: deleteTagCategoryReducer,
  categoryTags: categoryTagsReducer,
  createTag: createTagReducer,
  updateTag: updateTagReducer,
  deleteTag: deleteTagReducer,
  employees: employeesReducer,
  employee: employeeReducer,
  createEmployee: createEmployeeReducer,
  updateEmployee: updateEmployeeReducer,
  deleteEmployee: deleteEmployeeReducer,
  addTagToEmployee: addTagToEmployeeReducer,
  removeEmployeeTag: removeEmployeeTagReducer,
  dynamicEmployee: dynamicEmployeeReducer,
  criteriaImport: criteriaImportReducer,
  // Salary Advance
  salaryAdvance: salaryAdvanceReducer,
  // Positions
  positions: positionsReducer,
  positionLabels: positionLabelsReducer,
  positionCriterias: positionCriteriasReducer,
  positionQuestions: positionQuestionsReducer,
  positionQuestionAnswerOptions: positionQuestionAnswerOptionsReducer,
  positionApplicants: positionApplicantsReducer,
  // Position Filter Question
  positionFilterQuestions: positionFilterQuestionsReducer,
  // Personas
  personas: personasReducer,
  persona: personaReducer,
  personaCreate: personaCreateReducer,
  personaUpdate: personaUpdateReducer,
  personaDelete: personaDeleteReducer,
  selectedPersona: selectedPersonaReducer,
  // Questionnaire
  questionnaireMessages: messagesReducer,
  questionnaireLabelCategories: labelCategoriesReducer,
  questionnaireLabels: labelsReducer,
  attributeValidators: attributeValidatorsReducer,
  regexReplacers: regexReplacersReducer,
  expressions: expressionsReducer,
  // Training Data
  trainingData: trainingDataReducer,
  // Short List
  shortList: shortListReducer,
  interviews: interviewsReducer,
  interviewAttendees: interviewAttendeesReducer,
  recruiterImportDataset: recruiterImportDatasetReducer,
  uploadedDocuments: uploadedDocumentsReducer,
  // Surveys
  surveys: surveysReducer,
  surveySubscribers: surveySubscribersReducer,
  surveyGeneral: surveyGeneralReducer,
  deleteSurvey: deleteSurveyReducer,
  addTagToSurvey: addTagToSurveyReducer,
  removeSurveyTag: removeSurveyTagReducer,
  surveyQuestions: surveyQuestionsReducer,
  surveyQuestionAnswers: surveyQuestionAnswersReducer,
  // Events
  events: eventsReducer,
  eventGeneral: eventGeneralReducer,
  eventReport: eventReportReducer,
  //Forms
  forms: formsReducer,
  form: formGeneralReducer,
  formDetailedReport: formDetailedReportReducer,
  // Import configuration
  importConfigurations: importConfigurationReducer,
  // Message to admin
  messageToAdmin: messageToAdminReducer,
  // Helpdesk
  tickets: ticketsReducer,
  helpdeskGroups: helpdeskGroupsReducer,
  addTagToHelpdeskGroup: addTagToHelpdeskGroupReducer,
  removeHelpdeskGroupTag: removeHelpdeskGroupTagReducer,
  helpdeskInstantAnswers: helpdeskInstantAnswersReducer,
  followUpNotifications: followUpNotificationsReducer,
  languageSettings: languageSettingsReducer,
  contentTranslationsSurvey: contentTranslationsSurveyReducer,
  contentTranslationsEvent: contentTranslationsEventReducer,
  employeeReports: employeeReportsReducer,
  // Issues
  issueCategories: issueCategoriesReducer,
  issues: issuesReducer,
  issueReports: issueReportsReducer
});

const compose =
  typeof window !== 'undefined'
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || reduxCompose
    : reduxCompose;

const rootReducer = (state: any, action: any) => {
  if (action.type === CLEAR_STORE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const store: Store = createStore(rootReducer, compose(applyMiddleware(errorHandler, thunk)));

// stateToLocalStorage(store, ['platforms']);

export default store;
