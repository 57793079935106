import moment from 'moment';
import React from 'react';
import i18n from 'src/i18n';
import { MetaDataModel } from 'src/shared/models';

interface Props {
  data: MetaDataModel;
}

const UserLoginEvent: React.FC<Props> = ({ data }) => {
  return (
    <>
      <p className="font-weight-bold">{i18n.t('auditLog.userLoginEvent')}</p>
      <table className="table table-sm">
        <tbody>
          <tr>
            <td>{i18n.t('common.created')}</td>
            <td>{moment(data?.createdAt).format('LLL')}</td>
          </tr>
          <tr>
            <td>{i18n.t('common.email')}</td>
            <td>{data.email}</td>
          </tr>
          <tr>
            <td>{i18n.t('auditLog.logId')}</td>
            <td>{data.userId}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default UserLoginEvent;
