import { StateModel } from '../../shared/models/default-state.model';
import { GET_LISTS_REQUEST, GET_LISTS_SUCCESS, GET_LISTS_ERROR } from '../actions/lists.action';
import { PaginationAPIModel } from '../../shared/models/pagination.model';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export interface ListModel {
  createdAt?: string;
  description?: string;
  id?: number;
  name?: string;
  slug?: string;
  tenantId?: number;
  updatedAt?: string;
  imageUrl?: string;
  fileUrl?: string;
  size?: number;
  gallery?: boolean;
}

export interface ListAPIModel extends PaginationAPIModel {
  content: ListModel[];
}

export interface ListUpdateModel {
  description?: string;
  name?: string;
}

export const listsReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_LISTS_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_LISTS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default listsReducer;
