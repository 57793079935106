import { StateModel } from 'src/shared/models';
import {
  GET_HELPDESK_GROUPS,
  GET_HELPDESK_GROUPS_ERROR,
  GET_HELPDESK_GROUPS_SUCCESS,
  DELETE_HELPDESK_GROUP,
  DELETE_HELPDESK_GROUP_SUCCESS,
  DELETE_HELPDESK_GROUP_ERROR,
  ADD_TAG_TO_HELPDESK_GROUP_REQUEST,
  ADD_TAG_TO_HELPDESK_GROUP_SUCCESS,
  ADD_TAG_TO_HELPDESK_GROUP_ERROR,
  REMOVE_HELPDESK_GROUP_TAG_REQUEST,
  REMOVE_HELPDESK_GROUP_TAG_SUCCESS,
  REMOVE_HELPDESK_GROUP_TAG_ERROR,
} from '../actions/helpdesk-groups.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const helpdeskGroupsReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case GET_HELPDESK_GROUPS:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_HELPDESK_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload,
      };
    case GET_HELPDESK_GROUPS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };
    case DELETE_HELPDESK_GROUP:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case DELETE_HELPDESK_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          content: state.data.content.filter(({ id }: any) => id !== payload.helpdeskGroupId),
        },
      };
    case DELETE_HELPDESK_GROUP_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };

    default:
      return state;
  }
};

export const addTagToHelpdeskGroupReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ADD_TAG_TO_HELPDESK_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case ADD_TAG_TO_HELPDESK_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case ADD_TAG_TO_HELPDESK_GROUP_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const removeHelpdeskGroupTagReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case REMOVE_HELPDESK_GROUP_TAG_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case REMOVE_HELPDESK_GROUP_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case REMOVE_HELPDESK_GROUP_TAG_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
