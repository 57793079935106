import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import moment from 'moment';

import { getUsersTimezoneOffsetToUtc } from 'src/shared/utils/time.util';
import { getSelectOptionObjects } from 'src/shared/utils/misc.util';

import {
  ApplicationEfficiencyReportQueryParams,
  ApplicationEfficiencyReportType,
} from 'src/shared/models/query-params.model';
import { getDailyApplicationProcessEfficiency } from 'src/redux/services/application-efficiency.service';
import { getApplication, ApplicationModel } from 'src/redux/actions/applications.action';
import { getPositions } from 'src/redux/services/positions.service';

import DailyApplicationProcessEfficiency from './components/DailyApplicationProcessEfficiency';
import ChartTypeButtons from './components/ChartTypeButtons';
import { DateRange, PageHeader } from 'src/shared/components';

import PositionFilterAsync from './components/PositionFilterAsync';

const INIT_FROM_VALUE = moment().subtract(7, 'days').startOf('day').utc().toISOString();
const INIT_TO_VALUE = moment().endOf('day').utc().toISOString();

interface Props {
  application: ApplicationModel;
  getApplication: Function;
}

const ApplicationEfficiencyReport: React.FC<Props> = ({ application, getApplication }) => {
  const { t } = useTranslation();
  const { id: appId } = useParams<{ id: string }>();
  const [chartType, setChartType] = useState<ApplicationEfficiencyReportType>('percentage');
  const [chartData, setChartData] = useState<any[]>([]);
  const [applicationChartData, setApplicationChartData] = useState<any[]>([]);
  const [queryParams, setQueryParams] = useState<ApplicationEfficiencyReportQueryParams>({
    from: INIT_FROM_VALUE,
    to: INIT_TO_VALUE,
    timezoneOffset: getUsersTimezoneOffsetToUtc(),
    type: 'percentage',
    positionId: null,
  });

  useEffect(() => {
    if (appId) {
      if (!application.id) {
        getApplication(appId);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleChartTypeChange = (type: ApplicationEfficiencyReportType) => {
    setQueryParams({
      ...queryParams,
      type: type,
    });
    setChartType(type);
  };

  useEffect(() => {
    getChartData();
    // eslint-disable-next-line
  }, [queryParams]);

  const handleDateChange = (startDate: moment.Moment, endDate: moment.Moment) => {
    if (!startDate || !endDate) return null;
    const from = moment(startDate).utc().startOf('day').toISOString();
    const to = moment(endDate).utc().endOf('day').toISOString();

    return setQueryParams(prevState => ({
      ...prevState,
      from,
      to,
    }));
  };

  const handlePositionChange = (positionId: number) => {
    setQueryParams(prevState => ({
      ...prevState,
      positionId,
    }));
  };

  const getChartData = async () => {
    const data = await getDailyApplicationProcessEfficiency(appId, queryParams);
    setChartData(data);
    const applicationChartData = await getDailyApplicationProcessEfficiency(appId, {
      ...queryParams,
      ...{ type: 'applications' },
    });
    setApplicationChartData(applicationChartData);
  };

  // const exportChartData = async () => {
  //   const data = await exportDailyApplicationProcessEfficiency(appId, queryParams);

  //   if (data) {
  //     saveFile(data, fileNames.APPLICATION_EFFICIENCY_REPORT_EXPORT, 'csv');
  //   }
  // };

  let currentPage = -1;
  let prevSearch = '';
  const loadOptions = async (search: string) => {
    const PAGE_SIZE = 50;
    currentPage += 1;

    if (search !== prevSearch) {
      currentPage = 0;
    }

    const data = await getPositions(appId, {
      page: currentPage,
      size: PAGE_SIZE,
      search,
    });

    prevSearch = search;

    return {
      options: getSelectOptionObjects(data.content, 'name', 'id'),
      hasMore: !data.last,
    };
  };

  return (
    <div className="animated fadeIn">
      <PageHeader title={t('common.applicationEfficiency')} />

      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col sm="6" lg="4">
                  <DateRange
                    startDate={queryParams.from}
                    endDate={queryParams.to}
                    handleDateChange={(startDate: moment.Moment, endDate: moment.Moment) =>
                      handleDateChange(startDate, endDate)
                    }
                  />
                </Col>
                <Col sm="6" lg="5">
                  <PositionFilterAsync
                    loadOptions={loadOptions}
                    handlePositionChange={handlePositionChange}
                  />
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <DailyApplicationProcessEfficiency
                    chartData={applicationChartData}
                    title={t('applicationEfficiency.dailyApplications')}
                  />
                </Col>
              </Row>
            </CardBody>
            <CardHeader>
              <Row>
                <Col sm="12" lg="2">
                  <ChartTypeButtons chartType={chartType} setChartType={handleChartTypeChange} />
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <DailyApplicationProcessEfficiency
                    chartData={chartData}
                    title={t('applicationEfficiency.dailyApplicationProcessEfficiency')}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
});

const mapDispatchToProps = {
  getApplication,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationEfficiencyReport);
