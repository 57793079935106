import React, { Component, Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import 'react-dates/initialize';
import { DateRangePicker, isInclusivelyBeforeDay, isSameDay } from 'react-dates';
import moment from 'moment';
import { ValueType } from 'react-select/src/types';
import Select from 'react-select';
import classNames from 'classnames';

import { AuditLogEventModel } from 'src/shared/models/audit-log.model';
import { UserModel } from '../../../shared/models/user.model';

import styles from '../AuditLog.module.scss';

interface Props extends WithTranslation {
  events: AuditLogEventModel[];
  startDate: moment.Moment;
  endDate: moment.Moment;
  tenantUsers: UserModel[];
  selectedEvent?: string;
  selectedUser?: number;
  handleEventTypeChange: Function;
  handleTenantUserChange: Function;
  handleDateChange: Function;
}

interface State {
  startDate: any;
  endDate: any;
  focusedInput: any;
  dateHasChanged: boolean;
}

type SelectType = {
  value: string | number;
  label: string;
};

class AuditLogFilters extends Component<Props, State> {
  state: State = {
    startDate: null,
    endDate: null,
    focusedInput: null,
    dateHasChanged: false,
  };

  componentDidUpdate = (prev: Props) => {
    const { startDate, endDate } = this.props;

    if (prev.startDate !== startDate && prev.endDate !== endDate) {
      this.setState({
        startDate,
        endDate,
      });
    }
  };

  handleDateChange = (startDate: moment.Moment, endDate: moment.Moment) => {
    if (startDate && endDate) {
      if (!this.state.dateHasChanged) return null;

      this.setState({
        startDate,
        endDate,
        dateHasChanged: false,
      });

      this.props.handleDateChange(startDate, endDate);
    }

    return null;
  };

  // Dátumválasztó bezárása
  handleFocusChange = (focusedInput: string | null) => {
    this.setState({
      focusedInput,
    });
  };

  handlePresetClick = (startDate: moment.Moment, endDate: moment.Moment) => {
    this.setState(
      {
        dateHasChanged: true,
      },
      () => {
        this.handleDateChange(startDate, endDate);
        this.handleFocusChange(null);
      },
    );
  };

  renderDatePresets = () => {
    const { t } = this.props;

    const presets = [
      {
        text: `${t('common.today')}`,
        start: moment(),
        end: moment(),
      },
      {
        text: `${t('common.lastWeek')}`,
        start: moment().subtract(7, 'days'),
        end: moment(),
      },
      {
        text: `${t('common.lastMonth')}`,
        start: moment().subtract(1, 'month'),
        end: moment(),
      },
      {
        text: `${t('common.lastThreeMonth')}`,
        start: moment().subtract(3, 'month'),
        end: moment(),
      },
    ];

    return (
      <div className="PresetDateRangePicker_panel">
        {presets.map(({ text, start, end }) => {
          const isSelected =
            isSameDay(start, this.state.startDate) && isSameDay(end, this.state.endDate);

          return (
            <button
              key={text}
              type="button"
              className={classNames('PresetDateRangePicker_button', {
                PresetDateRangePicker_button__selected: isSelected,
              })}
              onClick={() => this.handlePresetClick(start, end)}
            >
              {text}
            </button>
          );
        })}
      </div>
    );
  };

  render() {
    const {
      events,
      tenantUsers,
      selectedUser,
      selectedEvent,
      handleEventTypeChange,
      handleTenantUserChange,
      t,
    } = this.props;

    const defaultEvent = { label: `${t('common.allEvents')}`, value: 'null' };
    const defaultUser = {
      label: tenantUsers && tenantUsers.length ? `${t('common.allUsers')}` : 'No Users',
      value: 'null',
    };

    const getSelectedEventObject = (event?: string) => {
      return event ? events.find(eventType => eventType.value === event) : defaultEvent;
    };

    const getTenantUserObjects = (users: UserModel[]) => {
      if (users && users.length > 0) {
        return users.map((user: UserModel) => {
          return {
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
          };
        });
      }

      return [];
    };

    const users: SelectType[] = getTenantUserObjects(tenantUsers);

    const getSelectedUserObject = (userId?: number) => {
      return userId && users.length
        ? users.find(user => user.value === Number(userId))
        : defaultUser;
    };

    return (
      <Fragment>
        <div className={styles.datePicker}>
          <DateRangePicker
            startDate={this.state.startDate}
            startDateId="startDate"
            endDate={this.state.endDate}
            endDateId="endDate"
            onDatesChange={({ startDate, endDate }) =>
              this.setState({ startDate, endDate, dateHasChanged: true })
            }
            onFocusChange={(focusedInput: string | null) => this.handleFocusChange(focusedInput)}
            onClose={({ startDate, endDate }) => this.handleDateChange(startDate, endDate)}
            focusedInput={this.state.focusedInput}
            isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
            renderCalendarInfo={() => this.renderDatePresets()}
            displayFormat="YYYY-MM-DD"
            firstDayOfWeek={1}
            numberOfMonths={1}
            minimumNights={0}
          />
        </div>

        <Select
          className={styles.select}
          value={getSelectedEventObject(selectedEvent)}
          options={events.filter(e => e.description)}
          placeholder="Select an event type..."
          isClearable
          isSearchable
          isDisabled={!events.length}
          onChange={(option: ValueType<SelectType>) => handleEventTypeChange(option)}
        />

        <Select
          className={styles.select}
          value={getSelectedUserObject(selectedUser)}
          options={users}
          placeholder="Select a user..."
          isClearable
          isSearchable
          isDisabled={!users.length}
          onChange={(option: ValueType<SelectType>) => handleTenantUserChange(option)}
        />
      </Fragment>
    );
  }
}

export default withTranslation()(AuditLogFilters);
