import { StateModel } from '../../shared/models/default-state.model';

import {
  GET_INTERVIEW_REQUEST,
  GET_INTERVIEW_SUCCESS,
  GET_INTERVIEW_ERROR,
  GET_INTERVIEWS_REQUEST,
  GET_INTERVIEWS_SUCCESS,
  GET_INTERVIEWS_ERROR,
  CREATE_INTERVIEW_REQUEST,
  CREATE_INTERVIEW_SUCCESS,
  CREATE_INTERVIEW_ERROR,
  UPDATE_INTERVIEW_REQUEST,
  UPDATE_INTERVIEW_SUCCESS,
  UPDATE_INTERVIEW_ERROR,
  DELETE_INTERVIEW_REQUEST,
  DELETE_INTERVIEW_SUCCESS,
  DELETE_INTERVIEW_ERROR,
} from '../actions/interviews.action';
import { InterviewModel } from 'src/shared/models/interviews.model';

interface InterviewsStateModel {
  interview: StateModel;
  resources: StateModel;
  create: StateModel;
  update: StateModel;
  delete: StateModel;
}

const INITIAL_STATE = {
    interview: {
        loading: false,
        loaded: false,
        error: null,
        data: [],
    },
  resources: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  create: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  update: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  delete: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
};

// eslint-disable-next-line import/prefer-default-export
export const interviewsReducer = (state: InterviewsStateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_INTERVIEWS_REQUEST:
      return {
        ...state,
        resources: {
          ...state.resources,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case GET_INTERVIEWS_SUCCESS:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case GET_INTERVIEWS_ERROR:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    case GET_INTERVIEW_REQUEST:
      return {
        ...state,
          interview: {
          ...state.resources,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case GET_INTERVIEW_SUCCESS:
      return {
        ...state,
          interview: {
          ...state.resources,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case GET_INTERVIEW_ERROR:
      return {
        ...state,
          interview: {
          ...state.resources,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CREATE_INTERVIEW_REQUEST:
      return {
        ...state,
        create: {
          ...state.create,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case CREATE_INTERVIEW_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: true,
          data: action.payload,
        },
        resources: {
          ...state.resources,
          data: {
            ...state.resources.data,
            content: [...state.resources.data.content, action.payload],
          },
        },
      };

    case CREATE_INTERVIEW_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case UPDATE_INTERVIEW_REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case UPDATE_INTERVIEW_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          loaded: true,
          data: action.payload,
        },
        resources: {
          ...state.resources,
          data: {
            ...state.resources.data,
            content: state.resources.data.content.map((iv: InterviewModel) =>
              iv.id === action.payload.id ? action.payload : iv,
            ),
          },
        },
      };

    case UPDATE_INTERVIEW_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case DELETE_INTERVIEW_REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case DELETE_INTERVIEW_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          loaded: true,
          data: action.payload,
        },
        resources: {
          ...state.resources,
          data: {
            ...state.resources.data,
            content: state.resources.data.content.filter(
              (iv: InterviewModel) => iv.id !== action.payload.id,
            ),
          },
        },
      };

    case DELETE_INTERVIEW_ERROR:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};
