import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap';

import { CustomListModel } from 'src/shared/models/custom-lists.model';

import CheckPermission from 'src/shared/components/CheckPermission';

interface Props {
  customList: CustomListModel;
  setModalOpen: Function;
  setModalEvent: Function;
}

const CustomListItemsHeader: React.FC<Props> = ({ customList, setModalOpen, setModalEvent }) => {
  const { t } = useTranslation();

  return (
    <Row className="mb-4">
      <Col className="d-flex justify-content-between">
        <h4>{customList?.name}</h4>

        <CheckPermission variant="displayIf" permissions={['customlist_custom-lists-item_create']}>
          <Button
            color="primary"
            onClick={() => {
              setModalOpen(true);
              setModalEvent('create');
            }}
          >
            {t('common.create')}
          </Button>
        </CheckPermission>
      </Col>
    </Row>
  );
};

export default CustomListItemsHeader;
