export const parseFileExtension = (fileName: string) => {
  const ext = /^.+\.([a-zA-Z0-9]+)$/.exec(fileName);
  return ext && ext[1] ? ext[1] : '';
};

export const isImageExtension = (ext: string) => {
  return ['jpg', 'jpeg', 'gif', 'png', 'bmp'].indexOf(ext.toLowerCase()) !== -1;
};

export const parseFileNameWithExtension = (fileUrl: string) => {
  const fileNameWithoutExtension = /[^/?#]*\.[^/?#]*(\?.*)?(#.*)?$/.exec(fileUrl);
  return fileNameWithoutExtension && fileNameWithoutExtension[0]
    ? fileNameWithoutExtension[0]
    : null;
};

export const parseFileNameWithoutExtension = (fileUrl: string) => {
  const fileNameWithoutExtension = /([^\\/]+)(?=\.\w+$)/.exec(fileUrl);
  return fileNameWithoutExtension && fileNameWithoutExtension[0]
    ? fileNameWithoutExtension[0]
    : null;
};
