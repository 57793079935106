import { StateModel } from '../../shared/models/default-state.model';

import {
  GET_CUSTOM_FIELDS_REQUEST,
  GET_CUSTOM_FIELDS_SUCCESS,
  GET_CUSTOM_FIELDS_ERROR,
  CREATE_CUSTOM_FIELD_REQUEST,
  CREATE_CUSTOM_FIELD_SUCCESS,
  CREATE_CUSTOM_FIELD_ERROR,
  UPDATE_CUSTOM_FIELD_REQUEST,
  UPDATE_CUSTOM_FIELD_SUCCESS,
  UPDATE_CUSTOM_FIELD_ERROR,
  DELETE_CUSTOM_FIELD_REQUEST,
  DELETE_CUSTOM_FIELD_SUCCESS,
  DELETE_CUSTOM_FIELD_ERROR,
  CLEAR_CUSTOM_FIELDS,
} from '../actions/custom-fields.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {
    customFields: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    createCustomField: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    updateCustomField: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    deleteCustomField: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
  },
};

export const customFieldsReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CLEAR_CUSTOM_FIELDS:
      return INITIAL_STATE;

    case GET_CUSTOM_FIELDS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          customFields: {
            loading: true,
            loaded: false,
            error: null,
            data: [],
          },
        },
      };

    case GET_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          customFields: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case GET_CUSTOM_FIELDS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          customFields: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case CREATE_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          createCustomField: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case CREATE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          createCustomField: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case CREATE_CUSTOM_FIELD_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          createCustomField: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case UPDATE_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          updateCustomField: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case UPDATE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          updateCustomField: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case UPDATE_CUSTOM_FIELD_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          updateCustomField: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case DELETE_CUSTOM_FIELD_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          deleteCustomField: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case DELETE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          deleteCustomField: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case DELETE_CUSTOM_FIELD_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          deleteCustomField: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    default:
      return state;
  }
};

export default customFieldsReducer;
