import React, { Component } from 'react';
import { FieldProps } from 'formik';
import get from 'lodash/get';

import DragAndDrop, { DragAndDropProps } from './DragAndDrop';

interface DragAndDropFieldProps extends FieldProps, DragAndDropProps {
  label?: React.ReactNode;
}

class DragAndDropField extends Component<DragAndDropFieldProps> {
  setFile = async (file: File) => {
    const { form, field } = this.props;

    if (form && field) {
      form.setFieldTouched(field.name, true, false);
      form.setFieldValue(field.name, file, false);
    }
  };

  setFileError = (error: string) => {
    const { form, field } = this.props;

    form.setFieldError(field.name, error);
    form.setFieldTouched(field.name, true, false);
  };

  render() {
    const { field, form, label, ...props } = this.props;
    const errorMessage = get(form.errors, field.name);
    const file = field.value || this.props.file;

    return (
      <fieldset className="border p-3" style={{ minHeight: 240 }}>
        {label ? <legend className="col-form-label w-auto">{label}</legend> : null}
        <DragAndDrop
          {...props}
          setFile={this.setFile}
          setFileError={this.setFileError}
          fileError={errorMessage}
          file={file}
        />
      </fieldset>
    );
  }
}

export default DragAndDropField;
