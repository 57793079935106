import React from 'react';
import { useTranslation } from 'react-i18next';

import { PositionsQueryParams } from '../../../../shared/models/query-params.model';
import { SelectGroupModel, SelectModel } from '../../../../shared/models/common.model';

import Select from 'react-select';

interface Props {
  options: SelectGroupModel[];
  setQueryParams: Function;
}

const PositionLabelFilter: React.FC<Props> = ({ options, setQueryParams }) => {
  const { t } = useTranslation();

  const handleOnChange = (options: any) => {
    setQueryParams((prevState: PositionsQueryParams) => ({
      ...prevState,
      page: 0,
      labels: (options || []).map((option: SelectModel) => option.value),
    }));
  };

  return (
    <div className="d-flex flex-column">
      <span className="mb-2">{t('common.labels')}</span>
      <Select
        isMulti
        isClearable
        placeholder={!options ? t('common.noOptions') : t('common.all')}
        noOptionsMessage={() => t('common.noOptions')}
        options={options || []}
        onChange={options => handleOnChange(options)}
      />
    </div>
  );
};

export default PositionLabelFilter;
