import React from 'react';
import { Button } from 'reactstrap';
import uniqueId from 'lodash/uniqueId';
import i18n from '../../i18n';

import { ApplicantAttributeModel } from '../models/talent-pool.model';
import { SelectModel } from '../models/common.model';
import { CustomFieldModel, CustomFieldConfigModel } from '../models/custom-fields.model';
import { PositionAttributeModel } from '../models/position-applicants.model';

import { isValidUrl } from './url-validate.util';

import csvIcon from '../../assets/img/icons/csv.svg';
import xlsxIcon from '../../assets/img/icons/xlsx.svg';
import jsonIcon from '../../assets/img/icons/json.svg';
import { AttendeeAttributeModel } from '../models/interviews.model';
import { getSignedUrlAndOpenFile } from '../services/upload.service';

const urlTypeCustomfieldEnding = '_url';

export const getSelectOptionObjects = (
  dataSet: any,
  labelAttr: string,
  valueAttr: string,
  additionalAttr?: string,
) => {
  const optionObjects: SelectModel[] = dataSet.map((el: any) => ({
    id: el.id,
    label: el[labelAttr],
    value: el[valueAttr],
    additional: additionalAttr && el[additionalAttr],
  }));

  return optionObjects;
};

export const getGroupedSelectOptionObjects = (
  dataSet: any,
  groupLabelAttr: string,
  optionsAttr: string,
  labelAttr: string,
  valueAttr: string,
) => {
  const optionObjects = dataSet.map((el: any) => ({
    label: el[groupLabelAttr],
    options: el[optionsAttr]?.map((option: any) => ({
      label: option[labelAttr],
      value: option[valueAttr],
      parentLabel: el[groupLabelAttr],
    })),
  }));

  return optionObjects;
};

export const getAttributeValueByKey = (attributes: ApplicantAttributeModel[], key: string) => {
  if (!attributes) return '—';

  const result = attributes.find(attribute => attribute.key === key)?.value;
  return result || '—';
};

export const copyToClipboard = (value: string) => {
  const $textarea = document.createElement('textarea');
  $textarea.value = value;
  document.body.appendChild($textarea);
  $textarea.select();
  document.execCommand('copy');
  document.body.removeChild($textarea);
};

const filterFieldsByList = (customFields: CustomFieldModel[] = [], list: string = '') => {
  if (!list) {
    return customFields;
  }
  return customFields.filter(field => {
    let returnValue: boolean = false;
    try {
      const parsedConfig = JSON.parse(field.config);
      parsedConfig.forEach((config: CustomFieldConfigModel) => {
        if (config[list]?.visible) {
          returnValue = true;
        }
      });
    } catch {
      return returnValue;
    }
    return returnValue;
  });
};

export const renderCustomFieldCellsForHeader = (
  customFields: CustomFieldModel[] = [],
  list: string = '',
) =>
  filterFieldsByList(customFields, list).map(customField => {
    return <th key={uniqueId('cfh-')}>{i18n.t(customField.title)}</th>;
  });

export const renderCustomFieldCellsForBody = (
  customFields: CustomFieldModel[] = [],
  attributes: ApplicantAttributeModel[] = [],
  positionAttributes: PositionAttributeModel[] = [],
  list: string = '',
) => {
  return filterFieldsByList(customFields, list).map(customField => {
    const attributeValue =
      positionAttributes.filter(pAttribute => pAttribute.id.key === customField.key)[0]?.value ||
      attributes.filter(attribute => attribute.key === customField.key)[0]?.value;

    const cellContent = isValidUrl(attributeValue) ? (
      <a href={attributeValue} target="_blank" rel="noopener noreferrer" download>
        <Button size="sm" onClick={e => e.stopPropagation()}>
          <i className="fas fa-cloud-download-alt" />
        </Button>
      </a>
    ) : customField.key.substr(customField.key.length - 4) === urlTypeCustomfieldEnding ? (
      <Button
        className="btn-sm"
        onClick={() => {
          getSignedUrlAndOpenFile(attributeValue);
        }}
      >
        <i className="file-icon far fa-file-alt" />
      </Button>
    ) : (
      attributeValue || '—'
    );

    return <td key={uniqueId('cfb-')}>{cellContent}</td>;
  });
};

export const renderCustomFieldCellsForAttendeeBody = (
  customFields: CustomFieldModel[] = [],
  attributes: AttendeeAttributeModel[] = [],
) => {
  return customFields.map(customField => {
    const attributeValue = attributes.filter(
      attribute => attribute.attributeKey === customField.key,
    )[0]?.attributeValue;

    const cellContent = isValidUrl(attributeValue || '') ? (
      <a href={attributeValue} target="_blank" rel="noopener noreferrer" download>
        <Button size="sm" onClick={e => e.stopPropagation()}>
          <i className="fas fa-cloud-download-alt" />
        </Button>
      </a>
    ) : customField.key.substr(customField.key.length - 4) === urlTypeCustomfieldEnding ? (
      <Button
        className="btn-sm"
        onClick={() => {
          getSignedUrlAndOpenFile(attributeValue);
        }}
      >
        <i className="file-icon far fa-file-alt" />
      </Button>
    ) : (
      attributeValue || '—'
    );

    return <td key={uniqueId('cfb-')}>{cellContent}</td>;
  });
};

// Drag & Drop Item Style
export const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  background: isDragging ? '#d4ebf2 ' : '',
  border: isDragging ? '2px solid #99d0e0 ' : '',
  display: isDragging ? 'table' : '',
  ...draggableStyle,
});

export const swapItemsInArray = (data: any[], sourceIndex: number, destinationIndex: number) => {
  const tempData = data;
  const tempItem = tempData[sourceIndex];
  tempData[sourceIndex] = tempData[destinationIndex];
  tempData[destinationIndex] = tempItem;

  return tempData;
};

export const sortWithNullIgnore = (a: number | null, b: number | null, ascending: boolean) => {
  if (a === b) {
    return 0;
  }

  if (a === null) {
    return 1;
  }
  if (b === null) {
    return -1;
  }

  if (ascending) {
    return a < b ? -1 : 1;
  }

  return a < b ? 1 : -1;
};

export const getCustomFieldKeys = (customFields: CustomFieldModel[], list: string) => {
  const customFieldKeys: string[] = [];

  customFields.forEach(cf => {
    const { config } = cf;

    try {
      const parsedConfig = JSON.parse(config);

      parsedConfig.forEach((config: CustomFieldConfigModel) => {
        if (config[list]?.visible) {
          customFieldKeys.push(cf.key);
        }
      });
    } catch {
      // eslint-disable-next-line no-console
      console.error('Failed to parse custom field config: ', config);
    }
  });

  return customFieldKeys;
};

// Dropzone
export const renderSelectedFile = (file: File, fileExt: string) => {
  const getIcon = () => {
    switch (fileExt) {
      case 'csv':
        return csvIcon;
      case 'xlsx':
        return xlsxIcon;
      case 'json':
        return jsonIcon;
      default:
        return '';
    }
  };

  if (file) {
    return (
      <div className="selected-file">
        <img src={getIcon()} alt="File Icon" />
        <div className="selected-file-details">
          <p>{file.name}</p>
          <p>{(file.size / 1024 ** 2).toFixed(2)} MB</p>
        </div>
      </div>
    );
  }

  return null;
};

export const safeFilter = (filtered: any[]): any => {
  let field = undefined;
  if (filtered.length > 0) {
    field = filtered[0];
  }
  return field;
};

export const getNewFilters = (filters: any) => {
  let newFilters = {} as any;

  if (!filters) {
    return newFilters;
  }

  for (var prop in filters.filters) {
    if (
      prop !== 'startDate' &&
      prop !== 'endDate' &&
      prop !== 'waiting' &&
      prop !== 'selectedPlatforms' &&
      // @ts-ignore
      filters.filters[prop] !== '' &&
      // @ts-ignore
      filters.filters[prop] !== null &&
      filters.filters[prop]?.length !== 0
    ) {
      // @ts-ignore
      newFilters[prop] = filters.filters[prop];
    }
  }

  return newFilters;
};
