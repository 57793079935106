import React, { Component } from 'react';
import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';

import placeholder from 'src/assets/img/other/image_placeholder.jpg';

import styles from './scss/Viber.module.scss';

interface Props {
  message: ConversationHistoryModel;
}

interface State {
  outputTemplate: any;
}

class ViberGalleryTemplate extends Component<Props> {
  state: State = {
    outputTemplate: [],
  };

  componentDidMount() {
    this.renderTemplate(this.props.message);
  }

  renderTemplate(message: any) {
    const outputTemplate: any = [];
    let currentTemplate = null;
    let genericElements = null;

    try {
      // eslint-disable-next-line
      currentTemplate = JSON.parse(message.messageAsText.replace(/\n\s*\n/g, ''));
      // eslint-disable-next-line
      genericElements = currentTemplate.viberGenericElements || currentTemplate.genericElements;
    } catch (error) {
      this.setState({
        outputTemplate: [],
      });
    }



    if (currentTemplate) {
      genericElements.map((el: any) => {
        return outputTemplate.push(
          <div key={el.title} className={styles.galleryTemplate}>
            {el.imageUrl !== 'null' && (
              <a href={el.fileUrl} target="_blank" rel="noopener noreferrer">
                <img
                src={el.imageUrl ? el.imageUrl : placeholder}
                className={styles.templateImage}
                alt="Gallery card"
              />
              </a>
            )}
          </div>
        );
      });

      this.setState({
        outputTemplate,
      });
    }
  }

  render() {
    return <div className={styles.galleryTemplate}>{this.state.outputTemplate}</div>;
  }
}

export default ViberGalleryTemplate;
