import React, { FC } from 'react';
import { Formik, FormikValues } from 'formik';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';

import { TextField } from 'src/shared/components';
import { TicketFilters, TicketStatusTypeModel, SelectModel } from 'src/shared/models';
import Filter from '../UsageStatistics/components/Filter';
import SEARCH_TIMER from 'src/shared/constants/filter.constants';

interface ConversationFlowFilterProps {
  filterChanged: Function;
  filter: TicketFilters;
}

const HelpdeskFilter: FC<ConversationFlowFilterProps> = ({ filterChanged, filter }) => {
  const { t } = useTranslation();

  const updateFilter = (values: FormikValues) => {
    filterChanged({
      ...filter,
      ...values,
      groups: values.groups?.map((group: SelectModel) => group.value).join(','),
      status: values.status?.value === TicketStatusTypeModel.ALL ? '' : values.status?.value,
    });
  };

  const debouncedCallApi = _.debounce((values: FormikValues) => updateFilter(values), SEARCH_TIMER);

  const handleFilterChange = (values: FormikValues) => {
    debouncedCallApi(values);
    return {};
  };

  const handleDateFilterChange = (from: string, to: string): void => {
    filterChanged({
      ...filter,
      from: moment(from).toISOString(),
      to: moment(to).toISOString(),
    });
  };

  return (
    <Formik
      validateOnBlur={false}
      initialValues={{
        answererEmployeeKey: '',
        questionerEmployeeKey: '',
      }}
      onSubmit={() => {}}
      validate={handleFilterChange}
      validateOnChange
      enableReinitialize
    >
      {() => (
        <Row>
          <Col lg="4">
            <FormGroup>
              <Label for="name">{t('common.created')}</Label>
              <Filter handleDateChange={handleDateFilterChange} getAllData={() => {}} startEmpty />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="name">{t('tickets.answererEmployee')}</Label>
              <TextField name="answererEmployeeKey" />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="name">{t('tickets.questionerEmployee')}</Label>
              <TextField name="questionerEmployeeKey" />
            </FormGroup>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

export default HelpdeskFilter;
