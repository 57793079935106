import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label } from 'reactstrap';
import { Formik, Form, FormikValues } from 'formik';
import isEqual from 'lodash/isEqual';

import { RegexReplacerModel } from 'src/shared/models/questionnaire.model';
import { regexReplacerSchema } from 'src/shared/schemas/validation.schema';
import TextField from 'src/shared/components/form-inputs/TextField';
import Spinner from 'src/shared/components/Spinner';

interface Props {
  isOpen: boolean;
  toggleModal: () => void;
  confirm: (values: FormikValues) => Promise<void>;
  selectedRegexReplacer: RegexReplacerModel | undefined;
}

const RegexReplacePatternsModal: React.FC<Props> = ({
  isOpen,
  toggleModal,
  confirm,
  selectedRegexReplacer: selectedAttributeValidator,
}) => {
  const { t } = useTranslation();

  const title = 'regexReplacerPatterns.createRegexReplacerPattern';
  const submitButtonText = 'common.create';

  const getInitialFormValues = () => ({
    title: selectedAttributeValidator?.title || '',
    pattern: selectedAttributeValidator?.pattern || '',
    replaceTo: selectedAttributeValidator?.replaceTo || '',
  });

  const initialFormValues = getInitialFormValues();

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{t(title)}</ModalHeader>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialFormValues}
        validationSchema={regexReplacerSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (isEqual(values, initialFormValues)) {
            toggleModal();
            return;
          }
          await confirm(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <FormGroup>
                <Label for="title">{t('common.title')}</Label>
                <TextField name="title" />
              </FormGroup>
              <FormGroup>
                <Label for="pattern">{t('common.regexPattern')}</Label>
                <TextField name="pattern" />
              </FormGroup>
              <FormGroup>
                <Label for="pattern">{t('common.replaceTo')}</Label>
                <TextField name="replaceTo" />
              </FormGroup>
            </ModalBody>

            <ModalFooter>
              <Spinner loading={isSubmitting} />

              <Button type="submit" color="primary" disabled={isSubmitting}>
                {t(submitButtonText)}
              </Button>

              <Button color="secondary" onClick={toggleModal}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default RegexReplacePatternsModal;
