import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { AppSwitch } from '@coreui/react';

interface Props {
  handleChange: Function;
  switchChecked: boolean;
  disabled: boolean;
}

const Filter: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { handleChange, switchChecked, disabled } = props;

  return (
    <Fragment>
      <AppSwitch
        className="mr-2"
        variant="pill"
        color="primary"
        size="lg"
        checked={switchChecked}
        disabled={disabled}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
      />
      <span>{t('unrecognizedSentences.userSuggestionsOnly')}</span>
    </Fragment>
  );
};

export default Filter;
