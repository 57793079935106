import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FormikValues, useFormikContext } from 'formik';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Alert,
} from 'reactstrap';

import { SelectModel } from 'src/shared/models/common.model';
import { newLabelSchema } from 'src/shared/schemas/validation.schema';

import { createLabelCategory, createLabel } from 'src/redux/services/questionnaire.service';

import Switch from 'src/shared/components/form-inputs/Switch';
import TextField from 'src/shared/components/form-inputs/TextField';
import ReactSelect from 'src/shared/components/form-inputs/ReactSelect';
import Spinner from 'src/shared/components/Spinner';

interface Props {
  isOpen: boolean;
  toggle: React.Dispatch<React.SetStateAction<boolean>>;
  labelCategoryOptions: SelectModel[];
  answerIndex: number | undefined;
  getLabelCategories: Function;
}

const LabelCreateModal: React.FC<Props> = ({
  isOpen,
  toggle,
  labelCategoryOptions,
  answerIndex,
  getLabelCategories,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const { setFieldValue } = useFormikContext();
  const [alertText, setAlertText] = useState<string>('');

  const setCreatedLabel = (labelSlug: string, labelText: string) => {
    if (labelSlug && labelText && answerIndex! >= 0) {
      setFieldValue(`answers[${answerIndex}].answerActionRootData.labelSlug`, labelSlug);
      setFieldValue(`answers[${answerIndex}].answerActionRootData.label`, {
        label: labelText,
        value: labelSlug,
      });
    }
  };

  const handleOnSubmit = async (values: FormikValues) => {
    const { existingLabelCategory, createNewLabelCategory, newLabelCategory, label } = values;

    if (alertText) setAlertText('');

    if (!createNewLabelCategory && !Object.keys(existingLabelCategory).length) {
      setAlertText(t('common.selectALabelCategory'));
      return;
    }

    if (createNewLabelCategory && appId) {
      const createdLabelCategory = await createLabelCategory(appId, newLabelCategory);
      const { id: categoryId } = createdLabelCategory;

      if (categoryId) {
        const createdLabel = await createLabel(appId, categoryId, label);
        const { slug: labelSlug, text } = createdLabel;

        setCreatedLabel(labelSlug, text);
      }
    }

    if (!createNewLabelCategory && Object.keys(existingLabelCategory).length && appId) {
      const createdLabel = await createLabel(appId, existingLabelCategory.value, label);
      const { slug: labelSlug, text } = createdLabel;

      setCreatedLabel(labelSlug, text);
    }

    getLabelCategories(appId);
    toggle(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={() => toggle(false)}>
      <ModalHeader toggle={() => toggle(false)}>{t('common.createLabel')}</ModalHeader>

      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          createNewLabelCategory: false,
          existingLabelCategory: {},
          newLabelCategory: '',
          label: '',
        }}
        validationSchema={newLabelSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleOnSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <ModalBody>
              {alertText && (
                <Alert color="danger" className="text-center">
                  {alertText}
                </Alert>
              )}
              <FormGroup className="d-flex align-items-center">
                <Switch
                  name="createNewLabelCategory"
                  className="mr-2"
                  variant="pill"
                  color="primary"
                  size="lg"
                  checked={values.createNewLabelCategory}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('createNewLabelCategory', e.currentTarget.checked)
                  }
                />
                <span>{t('common.newLabelCategory')}</span>
              </FormGroup>

              <FormGroup>
                <Label for="newLabelCategory">{t('common.labelCategory')}</Label>
                {values.createNewLabelCategory ? (
                  <TextField name="newLabelCategory" />
                ) : (
                  <ReactSelect name="existingLabelCategory" options={labelCategoryOptions} />
                )}
              </FormGroup>

              <FormGroup>
                <Label for="label">{t('common.label')}</Label>
                <TextField name="label" />
              </FormGroup>
            </ModalBody>

            <ModalFooter>
              {isSubmitting && <Spinner loading={isSubmitting} className="mr-1" />}
              <Button type="submit" color="primary" disabled={isSubmitting}>
                {t('common.create')}
              </Button>
              <Button color="secondary" onClick={() => toggle(false)}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default LabelCreateModal;
