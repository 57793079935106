import { PersonaModel } from 'src/shared/models/persona.model';
import { StateModel } from '../../shared/models/default-state.model';

import {
  GET_PERSONAS_REQUEST,
  GET_PERSONAS_SUCCESS,
  GET_PERSONAS_ERROR,
  GET_PERSONA_REQUEST,
  GET_PERSONA_SUCCESS,
  GET_PERSONA_ERROR,
  CREATE_PERSONA_REQUEST,
  CREATE_PERSONA_SUCCESS,
  CREATE_PERSONA_ERROR,
  UPDATE_PERSONA_REQUEST,
  UPDATE_PERSONA_SUCCESS,
  UPDATE_PERSONA_ERROR,
  DELETE_PERSONA_REQUEST,
  DELETE_PERSONA_SUCCESS,
  DELETE_PERSONA_ERROR,
  SET_PERSONA,
} from '../actions/personas.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

const SELECTED_PERSONA_STATE: PersonaModel = {
  id: null,
  name: '',
  avatar: '',
};

export const personasReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_PERSONAS_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_PERSONAS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      };

    case GET_PERSONAS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const personaReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_PERSONA_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_PERSONA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      };

    case GET_PERSONA_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const personaCreateReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CREATE_PERSONA_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case CREATE_PERSONA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      };

    case CREATE_PERSONA_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const personaUpdateReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case UPDATE_PERSONA_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case UPDATE_PERSONA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      };

    case UPDATE_PERSONA_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const personaDeleteReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DELETE_PERSONA_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case DELETE_PERSONA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      };

    case DELETE_PERSONA_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const selectedPersonaReducer = (state = SELECTED_PERSONA_STATE, action: any) => {
  switch (action.type) {
    case SET_PERSONA:
      localStorage.setItem('selectedPersona', JSON.stringify(action.payload));

      return {
        ...action.payload,
      };

    default:
      return state;
  }
};
