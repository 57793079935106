import React, { FC, useEffect, useState } from 'react';

import styles from './AnimatedLine.module.scss';

interface AnimatedLineProps {
  value: number | string;
  color: string;
}

const AnimatedLine: FC<AnimatedLineProps> = ({ value, color }) => {
  const [delayedValue, setDelayedValue] = useState<number | string | null>(null);

  useEffect(() => {
    if (value && delayedValue === null) {
      setDelayedValue(value);
    }
  }, [value, delayedValue]);

  return (
    <div className={styles.backgroundBar}>
      <div
        className={styles.completedBar}
        style={{
          width: delayedValue || 0,
          backgroundColor: color,
        }}
      />
    </div>
  );
};

export default AnimatedLine;
