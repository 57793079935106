import React, { useState } from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

interface Props {
  id: string;
  disabled?: boolean;
  form: {
    setFieldValue: any;
    values: any;
  };
}

const SingleDate: React.FC<Props> = ({ id, disabled, form: { setFieldValue, values } }: Props) => {
  const [focused, setFocusedInput] = useState(false);

  return (
    <SingleDatePicker
      id={id}
      date={values[id]}
      disabled={disabled}
      onDateChange={(date: moment.Moment | null) => {
        setFieldValue(id, date);
      }}
      focused={focused}
      onFocusChange={({ focused }) => setFocusedInput(!!focused)}
      displayFormat="YYYY-MM-DD"
      openDirection="up"
      firstDayOfWeek={1}
      numberOfMonths={1}
    />
  );
};

export default SingleDate;
