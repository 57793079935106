import React, { Component, Fragment } from 'react';

export interface ValidationErrorModel {
  message: string;
  type: string;
}

interface Props {
  error: ValidationErrorModel;
}

class Validation extends Component<Props> {
  modifyChildren(child: any) {
    const classNames: string[] = ['form-control'];

    if (this.props.error) {
      classNames.push('is-invalid');
    }

    return React.cloneElement(child, {
      className: classNames.join(' '),
    });
  }

  render() {
    return (
      <Fragment>
        {React.Children.map(this.props.children, (child) => this.modifyChildren(child))}
        <div className="invalid-feedback">{this.props.error ? this.props.error.message : null}</div>
      </Fragment>
    );
  }
}

export default Validation;
