import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { Formik, Form } from 'formik';

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Col,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';

import {
  createFollowUpNotification,
  getFollowUpNotifications,
  updateFollowUpNotification,
} from 'src/redux/actions/follow-up-notifications.action';
import { ReactSelect, TextField, Spinner } from 'src/shared/components';
import { PageQueryParams, SelectModel } from 'src/shared/models';
import {
  FollowUpNotificationModel,
  FollowUpNotificationsCreateRequest,
  FollowUpNotificationsEditorModel,
} from 'src/shared/models/follow-up-notifications.model';
import { followUpNotificationsEditorSchema } from 'src/shared/schemas/validation.schema';
import MessageComposer from './components/MessageComposer';
import StartsAtComposer from './components/StartsAtComposer';

interface FollowUpNotificationsEditorModalProps {
  isOpen: boolean;
  platforms: string[];
  editableItem: FollowUpNotificationModel | undefined;
  dynamicConvFlowMenuItems: SelectModel[];
  dynamicConvFlowMenuItemsSlug: SelectModel[];
  setIsModalOpen: (value: boolean) => void;
  createFollowUpNotification: (
    appId: string,
    request: FollowUpNotificationsCreateRequest,
  ) => Promise<void>;
  updateFollowUpNotification: (
    appId: string,
    followUpNotificationId: number,
    request: FollowUpNotificationsCreateRequest,
  ) => Promise<void>;
  getFollowUpNotifications: (appId: string, queryParams: PageQueryParams) => Promise<void>;
}

const FollowUpNotificationsEditorModal: FC<FollowUpNotificationsEditorModalProps> = ({
  isOpen,
  platforms,
  editableItem,
  dynamicConvFlowMenuItems,
  dynamicConvFlowMenuItemsSlug,
  setIsModalOpen,
  createFollowUpNotification,
  updateFollowUpNotification,
  getFollowUpNotifications,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const handleSubmit = async (values: FollowUpNotificationsEditorModel) => {
    const request: FollowUpNotificationsCreateRequest = {
      id: editableItem?.id,
      applicationId: editableItem?.applicationId,
      name: values.name,
      message: {
        type: values.message.type,
        text: values.message.text,
        buttons: values.message.buttons.map(item => {
          return { text: item.text, value: item.value.value };
        }),
      },
      startsAt: values.startsAt.map(item => {
        return { messagingPlatform: item.messagingPlatform.value, value: item.value.value };
      }),
      triggerDynamicConversationFlowMenuItems: values.triggerDynamicConversationFlowMenuItems.map(
        item => {
          return item.value;
        },
      ),
    };

    if (request.message?.buttons?.length === 0) {
      delete request.message.type;
      delete request.message.buttons;
    } else {
      request.message.type = 'buttonTemplate';
    }

    if (editableItem) {
      await updateFollowUpNotification(appId, editableItem.id, request);
    } else {
      await createFollowUpNotification(appId, request);
    }
    await getFollowUpNotifications(appId, { page: 0, size: 20 });
  };

  const startsAtValueOptions: SelectModel[] = [
    { label: '10 minutes', value: 'PT10M' },
    { label: 'Half an hour', value: 'PT30M' },
    { label: 'One hour', value: 'PT1H' },
    { label: 'Three hour', value: 'PT3H' },
  ];

  const formInitValues: FollowUpNotificationsEditorModel = useMemo(
    () =>
      editableItem
        ? {
            name: editableItem.name,
            startsAt: editableItem.startsAt.map(i => {
              return {
                messagingPlatform: { label: i.messagingPlatform, value: i.messagingPlatform },
                value: {
                  label:
                    startsAtValueOptions?.filter(o => o.value === i.value)[0]?.label || i.value,
                  value: i.value,
                },
              };
            }),
            message: {
              type: editableItem.message?.type || '',
              text: editableItem.message.text,
              buttons:
                editableItem.message?.buttons?.map(button => {
                  return {
                    text: button.text,
                    value: {
                      label:
                        dynamicConvFlowMenuItemsSlug.filter(s => s.value === button.value)[0]
                          ?.label || button.value,
                      value: button.value,
                    },
                  };
                }) || [],
            },
            triggerDynamicConversationFlowMenuItems: dynamicConvFlowMenuItems.filter(
              menuItem =>
                editableItem.triggerDynamicConversationFlowMenuItems
                  .map(i => i.id)
                  .indexOf(menuItem.value) > -1,
            ),
          }
        : {
            name: '',
            startsAt: [],
            message: {
              type: '',
              text: '',
              buttons: [],
            },
            triggerDynamicConversationFlowMenuItems: [],
          },
    [editableItem, dynamicConvFlowMenuItems, startsAtValueOptions, dynamicConvFlowMenuItemsSlug],
  );

  return (
    <Modal isOpen={isOpen} className="modal-lg">
      <ModalHeader>{t('followUpNotifications.create')}</ModalHeader>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={formInitValues}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmit(values as any);
          setSubmitting(false);
          setIsModalOpen(false);
        }}
        validationSchema={followUpNotificationsEditorSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="name">{t('common.title')}</Label>
                    <TextField name="name" />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <StartsAtComposer
                    platforms={platforms}
                    startsAtValueOptions={startsAtValueOptions}
                  />
                </Col>
              </Row>
              <Row form>
                <Col>
                  <MessageComposer dynamicConvFlowMenuItemsSlug={dynamicConvFlowMenuItemsSlug} />
                </Col>
              </Row>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="triggerDynamicConversationFlowMenuItems">
                      {t('common.triggerDynamicConversationFlowMenuItems')}
                    </Label>
                    <ReactSelect
                      name="triggerDynamicConversationFlowMenuItems"
                      isMulti
                      options={dynamicConvFlowMenuItems}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Spinner loading={isSubmitting} />

              <Button type="submit" color="success" disabled={isSubmitting}>
                {t('common.save')}
              </Button>

              <Button color="secondary" onClick={() => setIsModalOpen(false)}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = {
  createFollowUpNotification,
  updateFollowUpNotification,
  getFollowUpNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpNotificationsEditorModal);
