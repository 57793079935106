import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Pagination from 'react-js-pagination';
import { Alert, Card, CardBody, Col, Row, Table } from 'reactstrap';

import {
  PageQueryParams,
  StateModel,
  InterviewAttendeeModel,
  CustomFieldModel,
} from 'src/shared/models';
import {
  renderCustomFieldCellsForAttendeeBody,
  renderCustomFieldCellsForHeader,
} from '../../../shared/utils/misc.util';
import i18n from '../../../i18n';

import { getInterviewAttendees } from '../../../redux/actions/interview-attendees.action';

import Spinner from 'src/shared/components/Spinner';
import ServerError from '../../../shared/components/ServerError';
import { useDeepEffect, usePreviousProps } from 'src/shared/hooks';
import { ApplicationModel, getApplication } from '../../../redux/actions/applications.action';
import { clearCustomFields, getCustomFields } from '../../../redux/actions/custom-fields.action';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  interviewAttendees: StateModel;
  getInterviewAttendees: Function;
  customFields: CustomFieldModel[];
  getCustomFields: Function;
}

const InterviewAttendeesTable: React.FC<Props> = ({
  application,
  getApplication,
  interviewAttendees,
  getInterviewAttendees,
  customFields,
  getCustomFields,
}) => {
  const { t } = useTranslation();
  const { appId, eventId } = useParams();

  const [queryParams, setQueryParams] = useState<PageQueryParams>({
    page: 0,
    size: 20,
  });

  const prevProps = usePreviousProps({ appId });

  useDeepEffect(() => {
    getInterviewAttendees(appId, eventId, queryParams);
  }, [eventId, queryParams]);

  const handlePageChange = (index: number) => {
    setQueryParams(prevState => ({
      ...prevState,
      page: index - 1,
    }));
  };

  useEffect(() => {
    if (+prevProps.appId !== +appId) {
      if (!application.id) {
        getApplication(appId);
      }

      getCustomFields(appId);
    }

    return () => {
      clearCustomFields();
    };
  }, [getApplication, getCustomFields, prevProps, appId, application]);

  return (
    <Fragment>
      <Row>
        <Col>
          <Card>
            <CardBody>
              {interviewAttendees.error && <ServerError />}

              {interviewAttendees.loading && (
                <div className="d-flex justify-content-center">
                  <Spinner loading={interviewAttendees.loading} size="2x" />
                </div>
              )}

              {interviewAttendees.loaded && !interviewAttendees.data?.content?.length && (
                <Alert color="info" className="text-center mb-0">
                  {t('positions.noPositionsFound')}
                </Alert>
              )}

              {interviewAttendees.data?.content?.length > 0 && (
                <Fragment>
                  <Table striped hover size="sm">
                    <thead>
                      <tr>
                        {renderCustomFieldCellsForHeader(customFields)}
                        <th>{i18n.t('common.position')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {interviewAttendees.data.content.map((attendee: InterviewAttendeeModel) => (
                        <tr key={attendee.id}>
                          {renderCustomFieldCellsForAttendeeBody(customFields, attendee.attributes)}
                          <td>{attendee.position && attendee.position.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <Pagination
                    activePage={queryParams.page + 1}
                    itemsCountPerPage={queryParams.size}
                    totalItemsCount={interviewAttendees.data?.totalElements}
                    pageRangeDisplayed={5}
                    onChange={(index: number) => handlePageChange(index)}
                  />
                </Fragment>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  customFields: state.customFields.data.customFields.data,
  customFieldsState: state.customFields.data.customFields,
  interviewAttendees: state.interviewAttendees.resources,
});

const mapDispatchToProps = {
  getApplication,
  getCustomFields,
  clearCustomFields,
  getInterviewAttendees,
};

export default connect(mapStateToProps, mapDispatchToProps)(InterviewAttendeesTable);
