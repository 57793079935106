import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { Formik, Form, FormikValues } from 'formik';

import { ListModel } from '../../../redux/reducers/lists.reducer';
import { FileModel } from '../../models/file.model';
import { isValidUrl } from '../../utils/url-validate.util';
import { readFileAsDataURL } from '../../../redux/services/filereader.service';
import { folderSchema } from '../../schemas/validation.schema';
import { getList } from '../../../redux/actions/lists.action';

import TextArea from '../form-inputs/TextArea';
import TextField from '../form-inputs/TextField';
import Spinner from '../Spinner';
import { getSignedUrlForDownloadService } from 'src/shared/services/upload.service';
import Switch from 'src/shared/components/form-inputs/Switch';

interface Props extends RouteComponentProps, WithTranslation {
  list: ListModel | undefined;
  allowToSetGallery: Boolean;
  cancel: Function;
  confirm: Function;
  isOpen: boolean;
  isLoading: boolean;
  errors?: any;
}

interface State {
  data: ListModel;
  image: FileModel;
  deletedFileUrl?: string | null;
}

export class FolderEditModal extends Component<Props, State> {
  state: State = {
    data: {
      name: '',
      slug: '',
      description: '',
      gallery: false,
    },
    image: {
      file: undefined,
      source: undefined,
    },
    deletedFileUrl: '',
  };

  componentDidUpdate = async (prevProps: Props) => {
    const { list: prevList } = prevProps;
    const { list: currList } = this.props;

    if (!prevList && currList) {
      this.setState({
        data: {
          name: currList.name,
          slug: currList.slug,
          description: currList.description,
          gallery: currList.gallery || false,
        },
        image: {
          file: undefined,
          source: currList.imageUrl
            ? await getSignedUrlForDownloadService(currList.imageUrl)
            : undefined,
        },
        deletedFileUrl: '',
      });
    }
  };

  confirmEdit = async (values: FormikValues) => {
    const { confirm, list } = this.props;
    const { name, description, image, gallery } = values;
    const { deletedFileUrl } = this.state;

    await confirm(
      {
        ...list,
        name,
        description,
        gallery,
      },
      image,
      deletedFileUrl,
    );
  };

  fileChange = async (event: React.FormEvent<HTMLInputElement>, deletedFileUrl: string) => {
    const { files } = event.currentTarget;

    if (files) {
      const fileSource = await readFileAsDataURL(files[0]);

      this.setState({
        image: {
          file: files[0],
          source: fileSource,
        },
      });
    }

    if (deletedFileUrl && isValidUrl(deletedFileUrl)) {
      this.setState({
        deletedFileUrl,
      });
    }
  };

  removeImage(deletedFileUrl: string) {
    (document.getElementById('image') as HTMLInputElement).value = '';

    this.setState({
      image: {
        file: undefined,
        source: undefined,
      },
    });

    if (isValidUrl(deletedFileUrl)) {
      this.setState({
        deletedFileUrl,
      });
    }
  }

  render() {
    const { isOpen, isLoading, cancel, allowToSetGallery, t } = this.props;
    const { data, image } = this.state;

    return (
      <Modal isOpen={isOpen} className="modal-lg">
        <ModalHeader>Update Folder</ModalHeader>
        <Formik
          initialValues={{ ...data, image: undefined }}
          validationSchema={folderSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await this.confirmEdit(values);
            setSubmitting(false);
          }}
        >
          {({ errors, isSubmitting, setFieldValue }) => (
            <Form>
              <ModalBody>
                <FormGroup row>
                  <Col md="3">
                    <Label>
                      {t('common.name')}
                      <sup className="text-danger"> *</sup>
                    </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <TextField
                      name="name"
                      placeholder={t('foldersAndDocuments.folderNamePlaceholder')}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col md="3">
                    <Label for="name">{t('common.gallery')}</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Switch
                      name="gallery"
                      color="primary"
                      variant="pill"
                      disabled={!allowToSetGallery}
                      label={t('common.gallery')}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col md="3">
                    <Label>
                      {t('common.id')}
                      <sup className="text-danger"> *</sup>
                    </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <TextField name="slug" disabled />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col md="3">
                    <Label>{t('common.description')}</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <TextArea
                      name="description"
                      placeholder={t('foldersAndDocuments.descriptionPlaceholder')}
                      rows="5"
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col md="3">
                    <Label>{t('common.image')}</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <div className="d-flex w-100">
                      <Input
                        id="image"
                        type="file"
                        name="image"
                        accept="image/*"
                        onChange={async e => {
                          await this.fileChange(e, image.source);
                          setFieldValue('image', this.state.image.file);
                        }}
                      />

                      <div className="justify-content-end d-flex" style={{ flex: 1 }}>
                        {image.source && (
                          <Button
                            className="text-dark"
                            color="danger"
                            size="md"
                            onClick={() => {
                              this.removeImage(image.source);
                              setFieldValue('image', undefined);
                            }}
                          >
                            <i className="far fa-trash-alt" />
                          </Button>
                        )}
                      </div>
                    </div>

                    {image.source && !errors.image && (
                      <img
                        className="img-thumbnail mt-2"
                        width="300"
                        src={image.source}
                        alt="Folder Attachment"
                      />
                    )}
                  </Col>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Spinner loading={isSubmitting || isLoading} className="mr-1" />

                <Button type="submit" color="success" disabled={isSubmitting || isLoading}>
                  {t('common.update')}
                </Button>
                <Button onClick={() => cancel()} color="secondary">
                  {t('common.cancel')}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state: any) => ({
  list: state.list.data,
});

const mapDispatchToProps = {
  getList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(FolderEditModal)));
