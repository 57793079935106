import { StateModel } from '../../shared/models/default-state.model';

import {
  GET_APPLICATION_SETTINGS_REQUEST,
  GET_APPLICATION_SETTINGS_SUCCESS,
  GET_APPLICATION_SETTINGS_ERROR,
  CREATE_APPLICATION_SETTING_REQUEST,
  CREATE_APPLICATION_SETTING_SUCCESS,
  CREATE_APPLICATION_SETTING_ERROR,
  UPDATE_APPLICATION_SETTING_REQUEST,
  UPDATE_APPLICATION_SETTING_SUCCESS,
  UPDATE_APPLICATION_SETTING_ERROR,
  DELETE_APPLICATION_SETTING_REQUEST,
  DELETE_APPLICATION_SETTING_SUCCESS,
  DELETE_APPLICATION_SETTING_ERROR,
} from '../actions/application-settings.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export const getApplicationSettingsReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_APPLICATION_SETTINGS_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_APPLICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_APPLICATION_SETTINGS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const createApplicationSettingReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CREATE_APPLICATION_SETTING_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case CREATE_APPLICATION_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      };

    case CREATE_APPLICATION_SETTING_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const updateApplicationSettingReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case UPDATE_APPLICATION_SETTING_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case UPDATE_APPLICATION_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case UPDATE_APPLICATION_SETTING_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const deleteApplicationSettingReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DELETE_APPLICATION_SETTING_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case DELETE_APPLICATION_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case DELETE_APPLICATION_SETTING_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
