import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { PositionModel } from '../../../shared/models/positions.model';
import { QuestionnaireLabel } from '../../../shared/models/questionnaire.model';

import {
  positionSidebarTypes,
  positionStatus,
} from '../../../shared/constants/positions.constants';

import { ApplicationModel, getApplication } from '../../../redux/actions/applications.action';
import {
  clearCreatedPosition,
  clearPosition,
  getPosition,
  setPositionSidebarType,
  setQuestionToEdit,
} from '../../../redux/actions/positions.action';
import { getPositionCriterias } from '../../../redux/actions/position-criterias.action';
import { getLabelCategories } from '../../../redux/actions/questionnaire.action';
import { getPlatforms } from '../../../redux/actions/platforms.action';

import PositionCreateAndEditHeader from './components/PositionCreateAndEditHeader';
import PositionCreateAndEditFormContainer from './components/PositionCreateAndEditFormContainer';
import PositionSidebarContainer from './PositionSidebarContainer';
import PositionEditWarningModal from '../components/PositionEditWarningModal';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  platforms: string[];
  getPlatforms: Function;
  position: PositionModel;
  getPosition: Function;
  labels: QuestionnaireLabel[];
  getLabelCategories: Function;
  getPositionCriterias: Function;
  sidebarType: string;
  setPositionSidebarType: Function;
  setQuestionToEdit: Function;
  clearPosition: Function;
  clearCreatedPosition: Function;
}

const PositionCreateAndEdit: React.FC<Props> = ({
  application,
  getApplication,
  platforms,
  getPlatforms,
  position,
  getPosition,
  labels,
  getLabelCategories,
  getPositionCriterias,
  sidebarType,
  setPositionSidebarType,
  setQuestionToEdit,
  clearPosition,
  clearCreatedPosition,
}) => {
  const history = useHistory();
  const { appId, positionId } = useParams<{ appId: string; positionId: string }>();
  const [isEditWarningModalOpen, setEditWarningModalOpen] = useState<boolean>(false);

  const isEditing = !!positionId;

  const redirectToPositions = () => {
    history.push(`/application/${appId}/recruiter/positions`);
  };

  useEffect(() => {
    getPositionCriterias(appId);
    getPlatforms(Number(appId));

    return () => {
      clearPosition();
      clearCreatedPosition();
      setPositionSidebarType(positionSidebarTypes.SHORTCUTS);
      setQuestionToEdit(null);
    };
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    if (appId) {
      if (!application.id) {
        getApplication(appId);
      }

      if (isEditing) {
        getPosition(appId, Number(positionId));
      }

      getLabelCategories(appId);
    }
    /* eslint-disable-next-line */
  }, [positionId]);

  useEffect(() => {
    if (isEditing && position?.id) {
      const { status } = position;

      if (status !== positionStatus.DRAFT) {
        setEditWarningModalOpen(true);
      }
    }
  }, [isEditing, position]);

  return (
    <Fragment>
      <Row className="animated fadeIn">
        <Col>
          <PositionCreateAndEditHeader isEditing={isEditing} positionName={position?.name} />
          <Row>
            <Col md={8}>
              <PositionCreateAndEditFormContainer position={position} labels={labels} />
            </Col>

            <Col md={4}>
              <PositionSidebarContainer
                sidebarType={sidebarType}
                positionSlug={position?.slug}
                platforms={platforms}
                facebookPageId={application.facebookPageId}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <PositionEditWarningModal
        isOpen={isEditWarningModalOpen}
        onConfirm={() => setEditWarningModalOpen(false)}
        onCancel={redirectToPositions}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  platforms: state.platforms.data,
  position: state.positions.data.position.data,
  labels: state.questionnaireLabelCategories.resources.data,
  sidebarType: state.positions.data.sidebarType,
});

const mapDispatchToProps = {
  getApplication,
  getPlatforms,
  getPosition,
  getLabelCategories,
  getPositionCriterias,
  clearPosition,
  clearCreatedPosition,
  setPositionSidebarType,
  setQuestionToEdit,
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionCreateAndEdit);
