import React from 'react';
import i18n from 'src/i18n';
import { MetaDataModel, MetaAnswerModel } from 'src/shared/models';

interface Props {
  data: MetaDataModel;
}

const Answers: React.FC<Props> = ({ data }) => {
  return (
    <>
      <p className="font-weight-bold">{i18n.t('auditLog.detailsOfCreatedDocumentAnswers')}</p>
      <table className="table table-sm">
        <tbody>
          <tr>
            <td>{i18n.t('auditLog.answers')}</td>
            <td>
              {data.answers &&
                (data.answers as MetaAnswerModel[]).map(({ text }) => <p key={text}>{text}</p>)}
            </td>
          </tr>
          <tr>
            <td>{i18n.t('auditLog.eventType')}</td>
            <td>{data.type}</td>
          </tr>
          <tr>
            <td>{i18n.t('auditLog.questionText')}</td>
            <td>{data.text}</td>
          </tr>
          <tr>
            <td>{i18n.t('auditLog.rootAnswerIds')}</td>
            <td>{data.rootAnswerIds}</td>
          </tr>
          <tr>
            <td>{i18n.t('auditLog.messageId')}</td>
            <td>{data.messageId}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Answers;
