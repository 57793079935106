import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';

import { TagCategoryModel, TagModel } from 'src/shared/models/employee.model';
import { getCategoryTags, deleteTag } from 'src/redux/actions/employee.action';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { StateModel } from 'src/shared/models';
import { ConfirmModal, EmpoyeeTagPill, TagModal, TagCreatePill } from 'src/shared/components';

import styles from './Tags.module.scss';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  selectedTagCategory: TagCategoryModel | undefined;
  getCategoryTags: Function;
  categoryTags: StateModel;
  deleteTag: Function;
  deleteState: StateModel;
}

const TagCategoryDetails: React.FC<Props> = ({
  application,
  getApplication,
  selectedTagCategory: category,
  getCategoryTags,
  categoryTags,
  deleteTag,
  deleteState,
}) => {
  const { appId } = useParams();
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<TagModel | undefined>(undefined);
  const [event, setEvent] = useState<string | undefined>(undefined);

  useEffect(() => {
    const { loaded, error } = deleteState;
    if (loaded && !error) {
      toggleConfirmModal(false);
      getCategoryTags(appId, category?.id);
    }
    // eslint-disable-next-line
  }, [deleteState]);

  const confirmDelete = () => {
    if (category && selectedTag) {
      deleteTag(appId, category.id, selectedTag.id);
      toggleConfirmModal(false);
    }
  };

  const toggleModal = (event?: string, value: boolean | null = null) => {
    if (event) {
      setEvent(event);
      if (event === 'create') {
        setSelectedTag(undefined);
      }
    }

    let newValue = !isModalOpen;
    if (value !== null) {
      newValue = value;
    }
    setModalOpen(newValue);
  };

  const toggleConfirmModal = (value: boolean | null = null) => {
    let newValue = !isConfirmModalOpen;
    if (value !== null) {
      newValue = value;
    }
    setConfirmModalOpen(newValue);
  };

  return (
    <Fragment>
      <Row className="mb-4">
        <Col className="d-flex justify-content-between align-items-center">
          <h6 className="m-0">{category && category.name}</h6>
          {category && (
            <button
              type="button"
              className="btn btn-outline-success btn-sm"
              onClick={() => toggleModal('create')}
            >
              {t('employee.newTag')}
            </button>
          )}
        </Col>
      </Row>

      <div className={styles.detailsContainer}>
        {category && (
          <div className={styles.tagPillsContainer}>
            {categoryTags.loaded && categoryTags.data.length > 0 && (
              <Fragment>
                {categoryTags.data.map((tag: TagModel) => {
                  return (
                    <EmpoyeeTagPill
                      key={tag.id}
                      tag={tag}
                      setSelectedTag={setSelectedTag}
                      toggleModal={toggleModal}
                      toggleConfirmModal={toggleConfirmModal}
                    />
                  );
                })}
              </Fragment>
            )}

            {!categoryTags.data.length && (
              <TagCreatePill
                toggleModal={toggleModal}
                context="new"
                createPermission="employee_employee-tag-assign_create"
              />
            )}
          </div>
        )}
      </div>

      <TagModal
        event={event}
        getCategoryTags={getCategoryTags}
        isOpen={isModalOpen}
        selectedTag={selectedTag}
        selectedTagCategory={category}
        toggleModal={toggleModal}
      />

      <ConfirmModal
        title={t('employee.deleteTag')}
        text={t('employee.deleteTagConfirmMessage')}
        item={selectedTag && selectedTag.name}
        cancel={() => toggleConfirmModal(false)}
        confirm={confirmDelete}
        isLoading={deleteState.loading}
        isOpen={isConfirmModalOpen}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  categoryTags: state.categoryTags,
  deleteState: state.deleteTag,
});

const mapDispatchToProps = {
  getApplication,
  getCategoryTags,
  deleteTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(TagCategoryDetails);
