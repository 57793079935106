import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';

import { createEventDate, getEvent } from 'src/redux/actions/events.action';
import { deleteEventDate, updateEventDate } from 'src/redux/services/event.service';
import { SingleDate, TimePicker, List, EditableTextInput, TextField } from 'src/shared/components';
import { Operations } from 'src/shared/models';
import { EventDateModel, EventModel } from 'src/shared/models/event.model';

import settings from 'src/shared/settings';
import { mergeDateWithTime } from 'src/shared/utils';

interface EventDatesProps {
  event: EventModel;
  getEvent: (appId: string, eventId: string) => void;
  createEventDate: (
    appId: string,
    eventId: string,
    eventDateData: EventDateModel,
  ) => Promise<EventDateModel>;
  eventDates: EventDateModel[];
  numberOfDates: number;
  eventDatesLoaded: boolean;
  eventDatesLoading: boolean;
  eventDatesError: boolean;
  isEditingDisabled?: boolean;
}
const EventDates: FC<EventDatesProps> = ({
  event,
  getEvent,
  createEventDate,
  eventDates,
  numberOfDates,
  eventDatesLoaded,
  eventDatesLoading,
  eventDatesError,
  isEditingDisabled,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const onSubmit = async (values: any) => {
    const data: any = {
      title: values.title,
      limit: values.limit || -1,
      startDateTime: mergeDateWithTime(values.dateStartDate, values.startTime),
      endDateTime: mergeDateWithTime(values.dateEndDate, values.endTime),
    };
    if (!event.id) return;
    await createEventDate(appId, event.id.toString(), data);
    getEvent(appId, event.id.toString());
  };

  const fetchEventDates = () => {
    //No need to fetch here
  };

  const removeEventDate = async (eventDate: EventDateModel) => {
    await deleteEventDate(appId, event.id, eventDate.id);
    getEvent(appId, event.id.toString());
  };

  const editEventDate = async () => {};

  const editEventDateInline = async (eventDate: EventDateModel) => {
    await updateEventDate(appId, event.id, eventDate.id, eventDate);
    getEvent(appId, event.id.toString());
  };

  return (
    <fieldset className="border p-3">
      <legend className="col-form-label w-auto font-weight-bold">{t('common.eventDates')}</legend>
      <Formik initialValues={{}} onSubmit={onSubmit} enableReinitialize>
        <Form autoComplete="off">
          <Row form>
            <Col lg="6">
              <FormGroup>
                <Label for="dateStartDate">{t('common.from')}</Label>
                <Field
                  component={SingleDate}
                  id="dateStartDate"
                  name="dateStartDate"
                  disabled={isEditingDisabled}
                />
                <Field component={TimePicker} name="startTime" disabled={isEditingDisabled} />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label for="dateEndDate">{t('common.to')}</Label>
                <Field
                  component={SingleDate}
                  id="dateEndDate"
                  name="dateEndDate"
                  disabled={isEditingDisabled}
                />
                <Field component={TimePicker} name="endTime" disabled={isEditingDisabled} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <Label for="limit">{t('common.limit')}</Label>
                <TextField name="limit" disabled={isEditingDisabled} />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label for="title">{t('common.title')}</Label>
                <TextField name="title" disabled={isEditingDisabled} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label></Label>
                <div className="d-flex justify-content-end align-items-end">
                  <Button type="submit" color="primary" disabled={isEditingDisabled}>
                    {t('common.add')}
                  </Button>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Formik>
      <Row>
        <Col>
          <List
            hidePager
            data={eventDates}
            totalElements={numberOfDates}
            loaded={eventDatesLoaded}
            loading={eventDatesLoading}
            error={eventDatesError}
            fetchData={fetchEventDates}
            noDataLabel={t('events.noEvents')}
            editFunction={editEventDate}
            deleteFunction={removeEventDate}
            deleteTitle={t('events.deleteEvent')}
            deleteText={t('events.deleteEventConfirmMessage')}
            updatePermission="event_date_update"
            deletePermission="event_date_delete"
            fields={[
              {
                key: 'title',
                label: t('common.title'),
                render: item => (
                  <EditableTextInput
                    name="title"
                    data={item.title}
                    canBeEmpty
                    updateData={(appId: string, id: number, value: string) => {
                      item.title = value;
                      editEventDateInline(item);
                    }}
                  />
                ),
              },
              {
                key: 'startDateTime',
                label: t('common.from'),
                render: item => (
                  <EditableTextInput
                    text={moment(item.startDateTime).format(settings.DATE_TIME_FORMAT)}
                    name="startDateTime"
                    data={moment(item.startDateTime).format(settings.DATE_TIME_FORMAT)}
                    canBeEmpty
                    updateData={(appId: string, id: number, value: string) => {
                      item.startDateTime = moment(value);
                      editEventDateInline(item);
                    }}
                  />
                ),
              },
              {
                key: 'endDateTime',
                label: t('common.to'),
                render: item => (
                  <EditableTextInput
                    text={moment(item.endDateTime).format(settings.DATE_TIME_FORMAT)}
                    name="endDateTime"
                    data={moment(item.endDateTime).format(settings.DATE_TIME_FORMAT)}
                    canBeEmpty
                    updateData={(appId: string, id: number, value: string) => {
                      item.endDateTime = moment(value);
                      editEventDateInline(item);
                    }}
                  />
                ),
              },
              {
                key: 'limit',
                label: t('common.limit'),
                render: item => (
                  <EditableTextInput
                    text={item.limit?.toString() || ''}
                    name="text"
                    data={item.limit?.toString() || ''}
                    canBeEmpty
                    updateData={(appId: string, id: number, value: number) => {
                      item.limit = value;
                      editEventDateInline(item);
                    }}
                  />
                ),
              },
              {
                key: 'operations',
                label: t('common.operations'),
                operations: isEditingDisabled ? [] : [Operations.DELETE],
              },
            ]}
          />
        </Col>
      </Row>
    </fieldset>
  );
};

const mapStateToProps = (state: any) => ({
  eventDates: state.eventGeneral.data?.event?.dates,
  numberOfDates: state.eventGeneral.data?.event?.dates.length,
  eventDatesLoading: state.eventGeneral.loading,
  eventDatesLoaded: state.eventGeneral.loaded,
  eventDatesError: state.eventGeneral.error,
});

const mapDispatchToProps = {
  createEventDate,
  getEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDates);
