import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Row } from 'reactstrap';
import { ApplicationModel } from '../../redux/actions/applications.action';
import Application from './components/Application';

interface Props extends RouteComponentProps {
  applications: ApplicationModel[];
}

const Applications: React.FC<Props> = ({ applications, history }) => {
  useEffect(() => {
    if (applications.length === 1) {
      history.push(`/application/${applications[0].id}/summary`);
    }
  });

  return (
    <Row className="animated fadeIn">
      {applications.map((application: ApplicationModel, index: number) => {
        return <Application key={application.name} index={index} application={application} />;
      })}
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  applications: state.applications.data,
});

export default connect(mapStateToProps)(withRouter(Applications));
