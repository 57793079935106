import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';

import { PositionsQueryParams } from '../../../../shared/models/query-params.model';

import { useDebounce } from '../../../../shared/hooks';

interface Props {
  setQueryParams: Function;
}

const PositionSearchInput: React.FC<Props> = ({ setQueryParams }) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>('');

  const delay = !searchValue.length ? 0 : 500;
  const debouncedSearchValue = useDebounce(searchValue, delay);

  useEffect(() => {
    if (!debouncedSearchValue.length || debouncedSearchValue.length > 2) {
      setQueryParams((prevState: PositionsQueryParams) => ({
        ...prevState,
        page: 0,
        search: debouncedSearchValue,
      }));
    }
  }, [debouncedSearchValue, setQueryParams]);

  return (
    <div className="d-flex flex-column mt-2 mt-md-0">
      <span className="mb-2">{t('common.search')}</span>
      <Input
        type="search"
        style={{ height: 38 }}
        placeholder={t('common.typeSomething')}
        onChange={e => setSearchValue(e.target.value)}
      />
    </div>
  );
};

export default PositionSearchInput;
