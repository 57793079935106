import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader } from 'src/shared/components';

const PositionApplicantsHeader = () => {
  const { t } = useTranslation();

  return <PageHeader title={t('common.positionApplications')} />;
};

export default PositionApplicantsHeader;
