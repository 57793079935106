import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { withoutPositionOption } from 'src/shared/constants/positions.constants';

import { ApplicantsQueryParams } from 'src/shared/models/query-params.model';
import { PositionModel } from 'src/shared/models/positions.model';
import { QuestionnaireLabelCategory } from 'src/shared/models/questionnaire.model';

import { getSelectOptionObjects, getGroupedSelectOptionObjects } from 'src/shared/utils/misc.util';

import { getPositions } from 'src/redux/actions/positions.action';
import { getLabelCategories } from 'src/redux/actions/questionnaire.action';

import TalentPoolPositionFilter from './TalentPoolPositionFilter';
import TalentPoolLabelFilter from './TalentPoolLabelFilter';
import TalentPoolSorter from './TalentPoolSorter';

interface Props {
  getPositions: Function;
  getLabelCategories: Function;
  setQueryParams: Function;
  positions: PositionModel[];
  labels: QuestionnaireLabelCategory[];
}

const TalentPoolFilters: React.FC<Props> = ({
  getPositions,
  getLabelCategories,
  setQueryParams,
  positions,
  labels,
}) => {
  const { appId } = useParams<{ appId: string }>();
  const [positionOptions, setPositionOptions] = useState<any>(null);
  const [selectedPositions, setSelectedPositions] = useState<any[]>([]);
  const [labelOptions, setLabelOptions] = useState<any>(null);
  const [selectedLabels, setSelectedLabels] = useState<any[]>([]);

  useEffect(() => {
    getPositions(appId);
    getLabelCategories(appId);
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    if (positions?.length > 0) {
      const positionOptionObjects = getSelectOptionObjects(positions, 'name', 'id');
      positionOptionObjects.unshift(withoutPositionOption);
      setPositionOptions(positionOptionObjects);
    }
    /* eslint-disable-next-line */
  }, [positions]);

  useEffect(() => {
    if (labels?.length > 0) {
      const labelObjects = getGroupedSelectOptionObjects(labels, 'text', 'labels', 'text', 'id');
      setLabelOptions(labelObjects);
    }
    /* eslint-disable-next-line */
  }, [labels]);

  useEffect(() => {
    const positionIds: number[] = selectedPositions?.map((position: any) => position.value);

    setQueryParams((prevState: ApplicantsQueryParams) => ({
      ...prevState,
      page: 0,
      positions: positionIds?.length > 0 ? positionIds.join(',') : '',
    }));
    /* eslint-disable-next-line */
  }, [selectedPositions]);

  useEffect(() => {
    const labelIds: number[] = selectedLabels?.map((label: any) => label.value);

    setQueryParams((prevState: ApplicantsQueryParams) => ({
      ...prevState,
      page: 0,
      labels: labelIds?.length > 0 ? labelIds.join(',') : '',
    }));
    /* eslint-disable-next-line */
  }, [selectedLabels]);

  return (
    <Row className="mb-3">
      <Col lg="3">
        <TalentPoolSorter setQueryParams={setQueryParams} />
      </Col>

      <Col className="d-flex justify-content-end">
        <Col className="p-0 mr-3" lg="3">
          <TalentPoolLabelFilter options={labelOptions} handleSelectChange={setSelectedLabels} />
        </Col>

        <Col className="p-0" lg="3">
          <TalentPoolPositionFilter
            options={positionOptions}
            handleSelectChange={setSelectedPositions}
          />
        </Col>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  applicantsState: state.talentPoolApplicants,
  positions: state.positions.data.positions.data?.content,
  labels: state.questionnaireLabelCategories.resources.data,
});

const mapDispatchToProps = {
  getPositions,
  getLabelCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(TalentPoolFilters);
