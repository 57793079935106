import { get, post } from '../../shared/services/http.service';
import { PageQueryParams } from 'src/shared/models';
import { DynamicFaqImportModel } from 'src/shared/models/dynamic-faq.model';

const baseUrl = (applicationId: string) => `/applications/${applicationId}/import`;

export const getDynamicFaqs = async (applicationId: string, params: PageQueryParams) => {
  return get(`${baseUrl(applicationId)}/imports?type=IMPORT_DYNAMIC_FAQ`, { params });
};

export const createDynamicFaq = async (applicationId: string, data: DynamicFaqImportModel) => {
  if (typeof data.configId === 'object') {
    data.configId = parseInt(data.configId.value);
  }
  return post(`/applications/${applicationId}/dynamicfaq/faq/import/xlsx`, { data });
};
