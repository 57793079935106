export interface ViberCarouselTemplateModel {
  carousels: ViberCarouselModel[];
}

export interface ViberCarouselModel {
  sections: ViberCarouselSectionModel[];
}

export interface ViberCarouselSectionModel {
  actionType: ViberCarouselActionType;
  align: ViberCarouselAlign;
  bgColor: string;
  columnPart: number;
  columns: number;
  rows: number;
  text: string;
  textSize: ViberCarouselTextSize;
  url: string;
  value: string;
}

export enum ViberCarouselTextSize {
  SMALL = 'small',
  REGULAR = 'regular',
  LARGE = 'large',
}

export enum ViberCarouselAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum ViberCarouselActionType {
  REPLY = 'reply',
  NONE = 'none',
  OPEN = 'open',
}
