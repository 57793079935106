import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { SelectModel } from 'src/shared/models/common.model';

interface Props {
  options: SelectModel[] | undefined;
  handleSelectChange: Function;
}

const PositionApplicantsPositionFilter: React.FC<Props> = ({ options, handleSelectChange }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column">
      <span className="mb-2">{t('common.positions')}</span>
      <Select
        isMulti
        isClearable
        placeholder={!options ? t('common.noOptions') : t('common.all')}
        noOptionsMessage={() => t('common.noOptions')}
        options={options || []}
        onChange={option => handleSelectChange(option)}
      />
    </div>
  );
};

export default PositionApplicantsPositionFilter;
