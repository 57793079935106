import React, { Fragment } from 'react';
import { useField, FieldAttributes } from 'formik';
import { Input, FormFeedback } from 'reactstrap';

interface ValueModel {
  label: string;
  value: string;
}

const Select: React.FC<FieldAttributes<{ options: ValueModel[] }>> = ({
  options,
  className,
  ...props
}) => {
  const [field, meta] = useField<any>(props);
  const { name, value, onBlur, onChange } = field;
  const { error, touched } = meta;

  const valid = touched && !error;
  const invalid = touched && !!error;
  const errorText = error || undefined;

  return (
    <Fragment>
      <Input
        type="select"
        name={name}
        value={value as string}
        onBlur={onBlur}
        onChange={onChange}
        valid={valid}
        invalid={invalid}
        className={className}
      >
        {options.map((option: ValueModel) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </Input>
      <FormFeedback>{errorText}</FormFeedback>
    </Fragment>
  );
};

export default Select;
