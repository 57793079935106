import React, { FC } from 'react';
import classNames from 'classnames';

import { ViberCarouselSectionModel } from 'src/shared/models';
import { ViberButton, ViberLink } from '../components';

const ROW_HEIGHT = 37;
const BUTTON_ROW_HEIGHT = 56;

interface ViberSectionTemplateProps extends ViberCarouselSectionModel {
  index: number;
  sections: ViberCarouselSectionModel[];
  link?: boolean;
  button?: boolean;
}

const ViberSectionTemplate: FC<ViberSectionTemplateProps> = ({
  bgColor,
  align,
  textSize,
  text,
  columns,
  rows,
  sections,
  url,
  value,
  index,
  link,
  button,
}) => (
  <div
    className={classNames({ 'mb-1': index !== sections.length - 1, 'p-2': !button })}
    style={{
      backgroundColor: bgColor,
      textAlign: align,
      fontSize: textSize,
      height: `${button ? BUTTON_ROW_HEIGHT : ROW_HEIGHT * rows}px`,
      width: `${100 / columns}%`,
    }}
  >
    {link ? (
      <ViberLink href={url} text={text} />
    ) : button ? (
      <ViberButton
        key={value}
        value={value}
        className="w-100"
        style={{
          backgroundColor: bgColor,
          textAlign: align,
          fontSize: textSize,
        }}
        noMargin
      >
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </ViberButton>
    ) : (
      <div dangerouslySetInnerHTML={{ __html: text }} />
    )}
  </div>
);

export default ViberSectionTemplate;
