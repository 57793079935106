import React, { FC } from 'react';
import { ConversationHistoryModel } from 'src/redux/actions/conversation-history.action';
import uniqueId from 'lodash/uniqueId';

import { ViberCustomKeyboardTemplateModel } from 'src/shared/models';
import { ViberButton, ViberLink, ViberButtonContainer } from './components';

const MAX_WIDTH = 100;

interface ViberCustomKeyboardTemplateProps {
  message: ConversationHistoryModel;
}

const ViberCustomKeyboardTemplate: FC<ViberCustomKeyboardTemplateProps> = ({ message }) => {
  const text: string = message.messageAsText;
  const viberMessage: ViberCustomKeyboardTemplateModel = JSON.parse(text);
  const style = { width: `calc(${MAX_WIDTH / viberMessage.columns}% - 4px)` };

  return viberMessage.viberButtonTemplateButtons &&
    viberMessage.viberButtonTemplateButtons.length > 0 &&
    viberMessage ? (
    <ViberButtonContainer text={viberMessage.text}>
      {viberMessage.viberButtonTemplateButtons.map(el =>
        el.url ? (
          <div style={style}>
            <ViberLink href={el.url} text={el.text} key={uniqueId('link-')} />
          </div>
        ) : (
          <ViberButton key={el.value} value={el.value} style={style}>
            {viberMessage.viberButtonTemplateButtons ? (
              <div dangerouslySetInnerHTML={{ __html: el.text }} />
            ) : (
              el.text
            )}
          </ViberButton>
        ),
      )}
    </ViberButtonContainer>
  ) : null;
};

export default ViberCustomKeyboardTemplate;
