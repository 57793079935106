import store from '../../redux';

export function hasPermission(requiredPermissions: string[]): boolean {
  const { permissions } = store.getState();
  const userPermissions = permissions && permissions.data ? permissions.data : [];

  if (requiredPermissions.length && userPermissions.length) {
    for (let i = 0; i < requiredPermissions.length; i += 1) {
      if (!userPermissions.includes(requiredPermissions[i])) {
        return false;
      }
    }
  }

  return true;
}

export default hasPermission;
