import { Dispatch } from 'redux';

import {
  getLanguageSettings as getLanguageSettingsService,
  createLanguageSetting as createLanguageSettingService,
  deleteLanguageSetting as deleteLanguageSettingService,
} from 'src/redux/services/language-settings.service';
import { LanguageBase } from 'src/shared/models/language-settings.model';

export const GET_LANGUAGE_SETTINGS = 'language-settings/GET_LANGUAGE_SETTINGS';
export const GET_LANGUAGE_SETTINGS_SUCCESS = 'language-settings/GET_LANGUAGE_SETTINGS_SUCCESS';
export const GET_LANGUAGE_SETTINGS_ERROR = 'language-settings/GET_LANGUAGE_SETTINGS_ERROR';

export const CREATE_LANGUAGE_SETTING = 'language-settings/CREATE_LANGUAGE_SETTING';
export const CREATE_LANGUAGE_SETTING_SUCCESS = 'language-settings/CREATE_LANGUAGE_SETTING_SUCCESS';
export const CREATE_LANGUAGE_SETTING_ERROR = 'language-settings/CREATE_LANGUAGE_SETTING_ERROR';

export const DELETE_LANGUAGE_SETTINGS = 'language-settings/DELETE_LANGUAGE_SETTINGS';
export const DELETE_LANGUAGE_SETTINGS_SUCCESS =
  'language-settings/DELETE_LANGUAGE_SETTINGS_SUCCESS';
export const DELETE_LANGUAGE_SETTINGS_ERROR = 'language-settings/DELETE_LANGUAGE_SETTINGS_ERROR';

export const REVERT_LANGUAGE_SETTINGS = 'language-settings/CLEAR_LANGUAGE_SETTINGS';

export const getLanguageSettings = (appId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_LANGUAGE_SETTINGS,
    });

    let languageSettings = await getLanguageSettingsService(appId);
    languageSettings.forEach((setting, i) => (setting.id = i));
    try {
      dispatch({
        type: GET_LANGUAGE_SETTINGS_SUCCESS,
        payload: languageSettings,
      });
    } catch (error) {
      dispatch({
        type: GET_LANGUAGE_SETTINGS_ERROR,
        error,
      });
    }
  };
};

export const deleteLanguageSetting = (appId: string, languageSettingCode: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_LANGUAGE_SETTINGS,
    });

    try {
      await deleteLanguageSettingService(appId, languageSettingCode);
      dispatch({
        type: DELETE_LANGUAGE_SETTINGS_SUCCESS,
        payload: { languageSettingCode },
      });
    } catch (error) {
      dispatch({
        type: DELETE_LANGUAGE_SETTINGS_ERROR,
        error,
      });
    }
  };
};

export const createLanguageSetting = (appId: string, data: LanguageBase) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_LANGUAGE_SETTING,
    });

    try {
      dispatch({
        type: CREATE_LANGUAGE_SETTING_SUCCESS,
        payload: await createLanguageSettingService(appId, data),
      });
    } catch (error) {
      dispatch({
        type: CREATE_LANGUAGE_SETTING_ERROR,
        error,
      });
    }
  };
};

export const revertLanguageSettings = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REVERT_LANGUAGE_SETTINGS,
    });
  };
};
