import React, { FC } from 'react';

import { Formik, Form, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import _ from 'lodash';

import { ReactSelect, TextField } from 'src/shared/components';
import { ConversationFlowFilters, SelectModel } from 'src/shared/models';
import SEARCH_TIMER from 'src/shared/constants/filter.constants';

interface ConversationFlowFilterProps {
  platforms: string[];
  filterChanged: (values: ConversationFlowFilters) => void;
  setActivePlatform: (platform: string) => void;
}

const ConversationFlowFilter: FC<ConversationFlowFilterProps> = ({
  platforms,
  filterChanged,
  setActivePlatform,
}) => {
  const { t } = useTranslation();
  const updateFilter = (values: FormikValues) => {
    filterChanged(values);
  };

  const debouncedCallApi = _.debounce((values: FormikValues) => updateFilter(values), SEARCH_TIMER);

  const handleFilterChange = (values: FormikValues) => {
    debouncedCallApi(values);
    return {};
  };

  return (
    <Formik
      validate={handleFilterChange}
      validateOnBlur={false}
      initialValues={{ platform: { label: platforms[0], value: platforms[0] } }}
      onSubmit={() => {}}
      enableReinitialize
      validateOnChange
    >
      <Form>
        <Row>
          <Col lg="3">
            <FormGroup>
              <Label>{t('common.platform')}</Label>
              <ReactSelect
                name="platform"
                onChanged={(platform: SelectModel) => setActivePlatform(platform.value)}
                options={platforms.map(platform => ({ label: platform, value: platform }))}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label for="searchKey">{t('common.key')}</Label>
              <TextField name="searchKey" />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label for="searchValue">{t('common.value')}</Label>
              <TextField name="searchValue" />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </Formik>
  );
};

export default ConversationFlowFilter;
