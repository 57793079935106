import React, { FC } from 'react';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';

import { ConversationHistoryModel } from 'src/redux/actions/conversation-history.action';
import { ViberCarouselTemplateModel, ViberCarouselActionType } from 'src/shared/models';
import ViberSectionTemplate from './ViberSectionTemplate';

import styles from '../scss/Viber.module.scss';

interface Props {
  message: ConversationHistoryModel;
}

const ViberCarouselTemplate: FC<Props> = ({ message }) => {
  const text: string = message.messageAsText;
  const viberMessage: ViberCarouselTemplateModel = JSON.parse(text);

  return viberMessage.carousels && viberMessage.carousels.length > 0 ? (
    <div className="d-flex flex-wrap">
      {viberMessage.carousels.map(({ sections }) => (
        <div
          key={uniqueId('carousel-')}
          className={classNames('d-flex flex-wrap m-1', styles.carouselCard)}
        >
          {sections &&
            sections.map((section, index) => (
              <ViberSectionTemplate
                key={uniqueId('section-')}
                index={index}
                sections={sections}
                link={section.actionType === ViberCarouselActionType.OPEN}
                button={section.actionType === ViberCarouselActionType.REPLY}
                {...section}
              />
            ))}
        </div>
      ))}
    </div>
  ) : null;
};

export default ViberCarouselTemplate;
