import { get } from '../../shared/services/http.service';
import { QueryParams } from '../../shared/models/query-params.model';

export const getFeedbackSummary = async (
  applicationId: number,
  platform: string,
  queryParams: object,
) => {
  return get(`/applications/${applicationId}/platform/${platform}/feedbacks/summary`, {
    params: queryParams,
  });
};

export const getFeedbacks = async (
  applicationId: number,
  platform: string,
  queryParams: QueryParams,
) => {
  return get(`/applications/${applicationId}/platform/${platform}/feedbacks/list`, {
    params: queryParams,
  });
};

export const getPlatformsWithFeedback = async (applicationId: number) => {
  return get(`/applications/${applicationId}/feedbacks/platform`);
};

export const exportFeedbacks = async (
  applicationId: number,
  platform: string,
  queryParams: object,
) => {
  return get(`/applications/${applicationId}/platform/${platform}/feedbacks/export/csv`, {
    params: queryParams,
  });
};
