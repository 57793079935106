import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  FormGroup,
  Label,
} from 'reactstrap';
import { Formik, Form, FormikValues } from 'formik';

import { documentSchema } from '../../schemas/validation.schema';

import Spinner from '../Spinner';
import TextField from '../form-inputs/TextField';
import File from '../form-inputs/File';

interface Props {
  cancel: Function;
  confirm: Function;
  isOpen: boolean;
  isLoading: boolean;
  errors?: any;
}

const INIT_VALUES = {
  name: '',
  shortDescription: '',
  description: '',
  document: undefined,
  image: undefined,
};

export const DocumentAddModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { isOpen, cancel } = props;

  const confirmCreate = async (values: FormikValues) => {
    const { confirm } = props;
    const { name, description, shortDescription, image, document } = values;

    await confirm(
      {
        name,
        shortDescription,
        description,
        priority: 0,
      },
      image,
      document,
    );
  };

  return (
    <Modal isOpen={isOpen} className="modal-lg">
      <ModalHeader>{t('foldersAndDocuments.createDocument')}</ModalHeader>
      <Formik
        initialValues={{
          ...INIT_VALUES,
        }}
        validationSchema={documentSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await confirmCreate(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <ModalBody>
              <FormGroup row>
                <Col md="3">
                  <Label for="name">
                    {t('common.name')}
                    <sup className="text-danger"> *</sup>
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <TextField
                    name="name"
                    placeholder={`${t('foldersAndDocuments.documentNamePlaceholder')}`}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md="3">
                  <Label>{t('foldersAndDocuments.shortDescription')}</Label>
                </Col>
                <Col xs="12" md="9">
                  <TextField
                    name="shortDescription"
                    placeholder={`${t('foldersAndDocuments.shortDescriptionPlaceholder')}`}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md="3">
                  <Label>{t('common.description')}</Label>
                </Col>
                <Col xs="12" md="9">
                  <TextField
                    name="description"
                    placeholder={`${t('foldersAndDocuments.descriptionPlaceholder')}`}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md="3">
                  <Label>{t('common.document')}</Label>
                </Col>
                <Col xs="12" md="9">
                  <File name="document" setFieldValue={setFieldValue} />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md="3">
                  <Label>{t('common.image')}</Label>
                </Col>
                <Col xs="12" md="9">
                  <File name="image" setFieldValue={setFieldValue} />
                </Col>
              </FormGroup>
            </ModalBody>

            <ModalFooter>
              <Spinner loading={isSubmitting} className="mr-2" />
              <Button type="submit" color="success" disabled={isSubmitting}>
                {t('common.create')}
              </Button>
              <Button onClick={() => cancel()} color="secondary">
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default DocumentAddModal;
