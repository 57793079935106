// src/redux/services/auth.service.ts

import { post } from '../../shared/services/http.service';
import { getToken } from './token.service';

const AUTH_JAVA_ENABLED = process.env.REACT_APP_AUTH_JAVA_ENABLED !== 'false';

export interface AuthCredentials {}

export interface LoginCredentials extends AuthCredentials{
  email: string;
  password: string;
}

export interface IdTokenAuthCredentials extends AuthCredentials{
  idToken: string;
}

export const login = async (credentials: LoginCredentials) => {
  if (AUTH_JAVA_ENABLED) {
    return post('/login/username', { data: credentials }).then((response) => {
      localStorage.setItem('userObject', JSON.stringify({tenantId: response.userData.tenantId, userId: response.userData.userId}));
      
      return response;
    });
  } else {
    return post('/login', { data: credentials }).then((response) => {
      localStorage.setItem('userObject', JSON.stringify({tenantId: response.userData.tenantId, userId: response.userData.userId}));
      
      return response;
    });
  }
};

export const idToken = async (credentials: IdTokenAuthCredentials) => {
  return post('/login/id-token', {data: credentials}).then((response) => {
    localStorage.setItem('userObject', JSON.stringify({tenantId: response.userData.tenantId, userId: response.userData.userId}));
      
    return response;
  });
};


export function isAuthenticated() {
  const token = getToken();
  return !!token;
}
