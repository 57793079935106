import React, { FC } from 'react';
import { Formik, FormikValues } from 'formik';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';

import { OutgoingMessagesReportFilters } from 'src/shared/models';
import Filter from '../UsageStatistics/components/Filter';
import SEARCH_TIMER from 'src/shared/constants/filter.constants';

interface ConversationFlowFilterProps {
  filterChanged: Function;
  filter: OutgoingMessagesReportFilters;
}

const OutgoingMessagesReportFilter: FC<ConversationFlowFilterProps> = ({
  filterChanged,
  filter,
}) => {
  const { t } = useTranslation();

  const updateFilter = (values: FormikValues) => {
    filterChanged({
      ...filter,
      ...values,
    });
  };

  const debouncedCallApi = _.debounce((values: FormikValues) => updateFilter(values), SEARCH_TIMER);

  const handleFilterChange = (values: FormikValues) => {
    debouncedCallApi(values);
    return {};
  };

  const handleDateFilterChange = (from: string, to: string): void => {
    filterChanged({
      from: moment(from).toISOString(),
      to: moment(to).toISOString(),
    });
  };

  return (
    <Formik
      validateOnBlur={false}
      initialValues={{
        answererEmployeeKey: '',
        questionerEmployeeKey: '',
      }}
      onSubmit={() => {}}
      validate={handleFilterChange}
      validateOnChange
      enableReinitialize
    >
      {() => (
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>
                {t('common.startDate')}, {t('common.endDate')}
              </Label>
              <Filter handleDateChange={handleDateFilterChange} getAllData={() => {}} startEmpty />
            </FormGroup>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

export default OutgoingMessagesReportFilter;
