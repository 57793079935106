import { Dispatch } from 'redux';

import {
    getIssues as getIssuesService
} from 'src/redux/services/issues.service';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';
import { IssuesQueryParams } from 'src/shared/models/issues.model';

export const GET_ISSUES = 'issue/GET_ISSUES';
export const GET_ISSUES_SUCCESS = 'issue/GET_ISSUES_SUCCESS';
export const GET_ISSUES_ERROR = 'issue/GET_ISSUES_ERROR';

export const getIssues = (appId: string, queryParams: IssuesQueryParams, grouping: DynamicGroupByModel, tabs: DynamicGroupByModel) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: GET_ISSUES,
        });
        try {
            dispatch({
                type: GET_ISSUES_SUCCESS,
                payload: await getIssuesService(appId, queryParams, { ...grouping, ...tabs }),
            });
        } catch (error) {
            dispatch({
                type: GET_ISSUES_ERROR,
                error,
            });
        }
    };
};