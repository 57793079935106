import { StateModel } from '../../shared/models/default-state.model';
import {
  GET_POSITION_QUESTION_REQUEST,
  GET_POSITION_QUESTION_SUCCESS,
  GET_POSITION_QUESTION_ERROR,
  CLEAR_POSITION_QUESTION,
  GET_POSITION_QUESTIONS_REQUEST,
  GET_POSITION_QUESTIONS_SUCCESS,
  GET_POSITION_QUESTIONS_ERROR,
  CREATE_POSITION_QUESTION_REQUEST,
  CREATE_POSITION_QUESTION_SUCCESS,
  CREATE_POSITION_QUESTION_ERROR,
  UPDATE_POSITION_QUESTION_REQUEST,
  UPDATE_POSITION_QUESTION_SUCCESS,
  UPDATE_POSITION_QUESTION_ERROR,
  DELETE_POSITION_QUESTION_REQUEST,
  DELETE_POSITION_QUESTION_SUCCESS,
  DELETE_POSITION_QUESTION_ERROR,
  GET_POSITION_QUESTION_ANSWER_OPTION_REQUEST,
  GET_POSITION_QUESTION_ANSWER_OPTION_SUCCESS,
  GET_POSITION_QUESTION_ANSWER_OPTION_ERROR,
  GET_POSITION_QUESTION_ANSWER_OPTIONS_REQUEST,
  GET_POSITION_QUESTION_ANSWER_OPTIONS_SUCCESS,
  GET_POSITION_QUESTION_ANSWER_OPTIONS_ERROR,
  CREATE_POSITION_QUESTION_ANSWER_OPTION_REQUEST,
  CREATE_POSITION_QUESTION_ANSWER_OPTION_SUCCESS,
  CREATE_POSITION_QUESTION_ANSWER_OPTION_ERROR,
  UPDATE_POSITION_QUESTION_ANSWER_OPTION_REQUEST,
  UPDATE_POSITION_QUESTION_ANSWER_OPTION_SUCCESS,
  UPDATE_POSITION_QUESTION_ANSWER_OPTION_ERROR,
  UPDATE_POSITION_QUESTION_ANSWER_OPTION_VALUES_REQUEST,
  UPDATE_POSITION_QUESTION_ANSWER_OPTION_VALUES_SUCCESS,
  UPDATE_POSITION_QUESTION_ANSWER_OPTION_VALUES_ERROR,
  DELETE_POSITION_QUESTION_ANSWER_OPTION_REQUEST,
  DELETE_POSITION_QUESTION_ANSWER_OPTION_SUCCESS,
  DELETE_POSITION_QUESTION_ANSWER_OPTION_ERROR,
  SET_CREATED_OR_EDITED_POSITION_QUESTION,
} from '../actions/position-question.action';

const POSITION_QUESTIONS_INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {
    positionQuestion: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    positionQuestions: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    createPositionQuestion: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    updatePositionQuestion: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    deletePositionQuestion: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    createdOrEditedPositionQuestion: {
      data: null,
      method: null,
    },
  },
};

const POSITION_QUESTION_ANSWER_OPTIONS_INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {
    positionQuestionAnswerOption: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    positionQuestionAnswerOptions: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    createPositionAnswerOption: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    createPositionQuestionAnswerOptions: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    updatePositionAnswerOption: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    updatePositionQuestionAnswerOption: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    updatePositionAnswerOptionValues: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    deletePositionQuestionAnswerOption: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
  },
};

export const positionQuestionsReducer = (
  state: StateModel = POSITION_QUESTIONS_INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case GET_POSITION_QUESTION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          positionQuestion: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case GET_POSITION_QUESTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          positionQuestion: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case GET_POSITION_QUESTION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          positionQuestion: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case CLEAR_POSITION_QUESTION:
      return {
        ...state,
        data: {
          ...state.data,
          positionQuestion: {
            loading: false,
            loaded: false,
            error: null,
            data: [],
          },
        },
      };

    case GET_POSITION_QUESTIONS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          positionQuestions: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case GET_POSITION_QUESTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          positionQuestions: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case GET_POSITION_QUESTIONS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          positionQuestions: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case CREATE_POSITION_QUESTION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          createPositionQuestion: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case CREATE_POSITION_QUESTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          createPositionQuestion: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case CREATE_POSITION_QUESTION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          createPositionQuestion: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case UPDATE_POSITION_QUESTION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          updatePositionQuestion: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case UPDATE_POSITION_QUESTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          updatePositionQuestion: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case UPDATE_POSITION_QUESTION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          updatePositionQuestion: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case DELETE_POSITION_QUESTION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          deletePositionQuestion: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case DELETE_POSITION_QUESTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          deletePositionQuestion: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case DELETE_POSITION_QUESTION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          deletePositionQuestion: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case SET_CREATED_OR_EDITED_POSITION_QUESTION:
      return {
        ...state,
        data: {
          ...state.data,
          createdOrEditedPositionQuestion: {
            data: action.payload,
            method: action.method,
          },
        },
      };

    default:
      return state;
  }
};

export const positionQuestionAnswerOptionsReducer = (
  state: StateModel = POSITION_QUESTION_ANSWER_OPTIONS_INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case GET_POSITION_QUESTION_ANSWER_OPTION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          positionQuestionAnswerOption: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case GET_POSITION_QUESTION_ANSWER_OPTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          positionQuestionAnswerOption: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case GET_POSITION_QUESTION_ANSWER_OPTION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          positionQuestionAnswerOption: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case GET_POSITION_QUESTION_ANSWER_OPTIONS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          positionQuestionAnswerOptions: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case GET_POSITION_QUESTION_ANSWER_OPTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          positionQuestionAnswerOptions: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case GET_POSITION_QUESTION_ANSWER_OPTIONS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          positionQuestionAnswerOptions: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case CREATE_POSITION_QUESTION_ANSWER_OPTION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          createPositionQuestionAnswerOptions: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case CREATE_POSITION_QUESTION_ANSWER_OPTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          createPositionQuestionAnswerOptions: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case CREATE_POSITION_QUESTION_ANSWER_OPTION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          createPositionQuestionAnswerOptions: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case UPDATE_POSITION_QUESTION_ANSWER_OPTION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          updatePositionQuestionAnswerOption: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case UPDATE_POSITION_QUESTION_ANSWER_OPTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          updatePositionQuestionAnswerOption: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case UPDATE_POSITION_QUESTION_ANSWER_OPTION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          updatePositionQuestionAnswerOption: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case UPDATE_POSITION_QUESTION_ANSWER_OPTION_VALUES_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          updatePositionAnswerOptionValues: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case UPDATE_POSITION_QUESTION_ANSWER_OPTION_VALUES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          updatePositionAnswerOptionValues: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case UPDATE_POSITION_QUESTION_ANSWER_OPTION_VALUES_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          updatePositionAnswerOptionValues: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case DELETE_POSITION_QUESTION_ANSWER_OPTION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          deletePositionQuestionAnswerOption: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case DELETE_POSITION_QUESTION_ANSWER_OPTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          deletePositionQuestionAnswerOption: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case DELETE_POSITION_QUESTION_ANSWER_OPTION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          deletePositionQuestionAnswerOption: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    default:
      return state;
  }
};
