import { Dispatch } from 'redux';
import {
  getApplicationSettings as getApplicationSettingsService,
  updateApplicationSetting as updateApplicationSettingService,
  createApplicationSetting as createApplicationSettingService,
  deleteApplicationSetting as deleteApplicationSettingService,
} from '../services/application-settings.service';

export const GET_APPLICATION_SETTINGS_REQUEST =
  'application-settings/GET_APPLICATION_SETTINGS_REQUEST';
export const GET_APPLICATION_SETTINGS_SUCCESS =
  'application-settings/GET_APPLICATION_SETTINGS_SUCCESS';
export const GET_APPLICATION_SETTINGS_ERROR = 'application-settings/GET_APPLICATION_SETTINGS_ERROR';

export const CREATE_APPLICATION_SETTING_REQUEST =
  'application-settings/CREATE_APPLICATION_SETTING_REQUEST';
export const CREATE_APPLICATION_SETTING_SUCCESS =
  'application-settings/CREATE_APPLICATION_SETTING_SUCCESS';
export const CREATE_APPLICATION_SETTING_ERROR =
  'application-settings/CREATE_APPLICATION_SETTING_ERROR';

export const UPDATE_APPLICATION_SETTING_REQUEST =
  'application-settings/UPDATE_APPLICATION_SETTING_REQUEST';
export const UPDATE_APPLICATION_SETTING_SUCCESS =
  'application-settings/UPDATE_APPLICATION_SETTING_SUCCESS';
export const UPDATE_APPLICATION_SETTING_ERROR =
  'application-settings/UPDATE_APPLICATION_SETTING_ERROR';

export const DELETE_APPLICATION_SETTING_REQUEST =
  'application-settings/DELETE_APPLICATION_SETTING_REQUEST';
export const DELETE_APPLICATION_SETTING_SUCCESS =
  'application-settings/DELETE_APPLICATION_SETTING_SUCCESS';
export const DELETE_APPLICATION_SETTING_ERROR =
  'application-settings/DELETE_APPLICATION_SETTING_ERROR';

export interface ApplicationSettingModel {
  applicationId: string;
  messagingPlatform: string;
  key: string;
  value: string;
}

export interface CreateApplicationSettingModel {
  key: string;
  value: string;
  addNewKey: boolean;
}

export const getApplicationSettings = (applicationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_APPLICATION_SETTINGS_REQUEST,
    });

    const settings = await getApplicationSettingsService(applicationId);

    try {
      dispatch({
        type: GET_APPLICATION_SETTINGS_SUCCESS,
        payload: settings,
      });
    } catch (error) {
      dispatch({
        type: GET_APPLICATION_SETTINGS_ERROR,
        error,
      });
    }
  };
};

export const createApplicationSetting = (
  applicationId: string,
  setting: CreateApplicationSettingModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_APPLICATION_SETTING_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_APPLICATION_SETTING_SUCCESS,
        payload: await createApplicationSettingService(applicationId, setting),
      });
    } catch (error) {
      dispatch({
        type: CREATE_APPLICATION_SETTING_ERROR,
        error,
      });
    }
  };
};

export const updateApplicationSetting = (
  applicationId: string,
  key: string,
  value: object,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_APPLICATION_SETTING_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_APPLICATION_SETTING_SUCCESS,
        payload: await updateApplicationSettingService(applicationId, key, value),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_APPLICATION_SETTING_ERROR,
        error,
      });
    }
  };
};

export const deleteApplicationSetting = (applicationId: string, key: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_APPLICATION_SETTING_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_APPLICATION_SETTING_SUCCESS,
        payload: await deleteApplicationSettingService(applicationId, key),
      });
    } catch (error) {
      dispatch({
        type: DELETE_APPLICATION_SETTING_ERROR,
        error,
      });
    }
  };
};
