import React, { useEffect, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { Col, Row, FormGroup, Label, Button, Card, CardBody } from 'reactstrap';
import { Formik, Form, Field, FormikHelpers, FormikValues } from 'formik';
import moment from 'moment-timezone';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import SurveysEditorShortcutsSidebar from 'src/views/Surveys/components/sidebars/SurveysEditorShortcutsSidebar';
import {
  addTagToSurvey,
  removeSurveyTag,
  createSurvey,
  updateSurvey,
  getSurvey,
  revertSurvey,
  revertSurveyQuestions,
  getSurveyQuestions,
} from 'src/redux/actions/surveys.action';

import {
  TextField,
  Switch,
  TextArea,
  SingleDate,
  ReactSelect,
  TimePicker,
  Spinner,
  EmployeeTags,
  PageHeader,
  DateTime,
  ColorPicker,
} from 'src/shared/components';
import {
  getRetryInterval,
  getDeliverOffset,
  getInterval,
} from 'src/shared/constants/surveys.constants';
import { defaultSelect } from 'src/shared/constants/select.constants';
import { surveySchema } from 'src/shared/schemas/validation.schema';
import {
  SelectModel,
  StateModel,
  SurveyEditorFormModel,
  SurveyModel,
  SurveyQuestionModel,
  TagModel,
  SurveyStartMessageJson,
} from 'src/shared/models';
import { mergeDateWithTime } from 'src/shared/utils/merge-date-time';
import { updateTags } from 'src/shared/utils/update-tags';
import SurveyQuestionsList from './components/SurveyQuestionsList';
import SurveysEditorQuestionEditSidebar from './components/sidebars/SurveysEditorQuestionEditSidebar';
import { getTagCategories } from 'src/redux/actions/employee.action';

interface SurveysEditorProps {
  edit?: boolean;
  getSurvey: Function;
  getApplication: Function;
  createSurvey: Function;
  updateSurvey: Function;
  revertSurvey: Function;
  revertSurveyQuestions: Function;
  application: ApplicationModel;
  addTagToSurvey: Function;
  removeSurveyTag: Function;
  survey: SurveyModel;
  getSurveyQuestions: (appId: string, surveyId: string) => void;
  getTagCategories: Function;
  tagCategories: StateModel;
}

const INIT_VALUES: SurveyEditorFormModel = {
  name: '',
  mandatory: false,
  description: '',
  startDate: moment().add(1, 'day'),
  startTime: moment(),
  endDate: moment().add(2, 'days'),
  endTime: moment(),
  startMessage: '',
  retries: 1,
  employeeTags: [],
  retryInterval: { ...defaultSelect },
  deliverOffset: { ...defaultSelect },
  notification: false,
};

const SurveysEditor: FC<SurveysEditorProps> = ({
  edit,
  getSurvey,
  getApplication,
  application,
  createSurvey,
  updateSurvey,
  revertSurvey,
  revertSurveyQuestions,
  survey,
  addTagToSurvey,
  removeSurveyTag,
  getSurveyQuestions,
  getTagCategories,
  tagCategories,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { appId, surveyId } = useParams<{ appId: string; surveyId: string }>();
  const [editSurveyQuestionItem, setEditSurveyQuestionItem] = useState<SurveyQuestionModel | false>(
    false,
  );
  const [newTags, setNewTags] = useState<any>([]);
  const retryInterval = getRetryInterval();
  const deliverOffset = getDeliverOffset();

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    revertSurvey();
    revertSurveyQuestions();
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    /* eslint-disable-next-line */
    edit && getSurvey(appId, surveyId);
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    if (typeof survey === 'undefined') {
      return;
    }
    setNewTags(survey.employeeTags ? [...survey.employeeTags] : []);
    /* eslint-disable-next-line */
  }, [survey]);

  const createElementFromHTML = (htmlString: string): Element | null => {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    return div.firstElementChild;
  };

  const generateStartMessageJson = (values: FormikValues): string => {
    return JSON.stringify({
      type: 'buttonTemplate',
      text: values.startMessageText,
      buttons: [
        {
          text: `<font color=${values.startMessageButton1Color}>${values.startMessageButton1Message}</font>`,
          value: survey?.slug || '',
        },
        {
          text: `<font color=${values.startMessageButton2Color}>${values.startMessageButton2Message}</font>`,
          value: `retry_later_${survey?.slug}`,
        },
      ],
    });
  };

  const handleSubmit = async (
    { startDate, startTime, endDate, endTime, ...values }: SurveyEditorFormModel,
    { setSubmitting, setFieldError }: FormikHelpers<SurveyEditorFormModel>,
  ) => {
    setSubmitting(true);
    const startMessageText = generateStartMessageJson(values);

    const data = {
      ...values,
      retryInterval: values.retryInterval.value,
      deliverOffset: values.deliverOffset.value,
      startsAt: mergeDateWithTime(startDate, startTime),
      endsAt: mergeDateWithTime(endDate, endTime),
      startMessage: startMessageText,
    };

    if (edit) {
      if (!data.notification) {
        data.retries = 0;
        data.retryInterval = null;
        data.deliverOffset = null;
      } else {
        let errorFound = false;
        if (data.retryInterval === '') {
          setFieldError(
            'retryInterval',
            t('errors.isRequired', { item: t('common.retryInterval') }),
          );
          errorFound = true;
        }
        if (data.deliverOffset === '') {
          setFieldError(
            'deliverOffset',
            t('errors.isRequired', { item: t('common.deliverOffset') }),
          );
          errorFound = true;
        }

        if (errorFound) {
          return;
        }
      }

      await updateTags(
        survey.employeeTags || [],
        newTags,
        (toAddIds: string[]) => addTagToSurvey(appId, surveyId, toAddIds),
        (tag: string) => removeSurveyTag(appId, surveyId, tag),
      );

      await updateSurvey(appId, surveyId, data);
      setSubmitting(false);
    } else {
      const { name, description, startsAt, endsAt, mandatory } = data;
      const { id } = await createSurvey(appId, { name, description, startsAt, endsAt, mandatory });

      setSubmitting(false);
      redirectToEditSurvey(id);
    }
  };

  const disableStartDateChange = (survey: SurveyEditorFormModel): boolean => {
    if (survey.startDate === null || survey.startTime === null) return false;
    return !!(surveyId && moment().isAfter(mergeDateWithTime(survey.startDate, survey.startTime)));
  };

  const isAfterStart = (): boolean => {
    return survey ? !!moment().isAfter(survey.startsAt) : false;
  };

  const redirectToEditSurvey = (surveyId: number) => {
    history.replace(`/application/${appId}/surveys/${surveyId}/edit`);
  };

  const getInitialFormValues = () => {
    let startMessageParams = {
      startMessageText: '',
      startMessageButton1Message: '',
      startMessageButton2Message: '',
      startMessageButton1Color: '',
      startMessageButton2Color: '',
    };

    if (survey.startMessage) {
      const extraChar = '```';

      const parsedData: SurveyStartMessageJson =
        survey.startMessage.indexOf(extraChar) === 0
          ? JSON.parse(
              survey.startMessage.substr(
                survey.startMessage.indexOf(extraChar) + 3,
                survey.startMessage.lastIndexOf(extraChar) - 3,
              ),
            )
          : JSON.parse(survey.startMessage);

      const bunnton1Dom = createElementFromHTML(parsedData.buttons[0].text);
      const bunnton2Dom = createElementFromHTML(parsedData.buttons[1].text);

      startMessageParams = {
        startMessageText: parsedData.text,
        startMessageButton1Message: bunnton1Dom?.textContent || '',
        startMessageButton2Message: bunnton2Dom?.textContent || '',
        startMessageButton1Color: bunnton1Dom?.attributes[0]?.value || '#fff',
        startMessageButton2Color: bunnton2Dom?.attributes[0]?.value || '#fff',
      };
    }

    return {
      ...survey,
      ...startMessageParams,
      startMessage:
        survey.startMessage ||
        `{"type":"buttonTemplate","text":"","buttons":[{"text":"<font color=#FFFFFF>${t(
          'common.yes',
        )}</font>","value":"${survey.slug}"},{"text":"<font color=#FFFFFF>${t(
          'common.no',
        )}</font>","value":"retry_later_${survey.slug}"}]}`,
      retries: survey.retries !== null ? survey.retries : INIT_VALUES.retries,
      employeeTags: survey.employeeTags || [],
      retryInterval: getInterval(retryInterval, survey.retryInterval),
      deliverOffset: getInterval(deliverOffset, survey.deliverOffset),
      startDate: moment(survey.startsAt),
      startTime: moment(survey.startsAt),
      endDate: moment(survey.endsAt),
      endTime: moment(survey.endsAt),
    };
  };

  const initialFormValues = survey ? getInitialFormValues() : INIT_VALUES;

  const addEmployeeTagToSurvey = (tagsToAssign: SelectModel[]) => {
    const newTagsToSave = [...newTags];
    tagsToAssign.map(e => newTagsToSave.push({ id: e.value, name: e.label, categoryId: '' }));
    setNewTags(newTagsToSave);
  };

  const removeEmployeeSurveyTag = (tagId: string) => {
    setNewTags(newTags.filter((e: TagModel) => e.id !== tagId));
  };

  return (
    <div className="animated fadeIn">
      <PageHeader title={edit ? t('surveys.editSurvey') : t('surveys.createSurvey')} />
      <Row>
        <Col md={8}>
          <Card>
            <CardBody>
              <Formik
                initialValues={initialFormValues}
                validationSchema={surveySchema(edit)}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ isSubmitting, setFieldValue, values, errors }) => {
                  const notificationDateTime =
                    values.startDate &&
                    values.startTime &&
                    moment(mergeDateWithTime(values.startDate, values.startTime)).add(
                      values.deliverOffset.amount,
                      values.deliverOffset.unit,
                    );

                  const isStartDateChangeDisabled = disableStartDateChange(values);

                  return (
                    <Form>
                      <fieldset className="border p-3">
                        <legend className="col-form-label w-auto font-weight-bold">
                          {t('common.generalInformations')}
                        </legend>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="name">{t('common.name')}</Label>
                              <TextField name="name" />
                            </FormGroup>

                            <FormGroup>
                              <Label for="startDate">{t('common.startTime')}</Label>
                              <Field
                                component={SingleDate}
                                id="startDate"
                                name="startDate"
                                disabled={isStartDateChangeDisabled}
                              />
                              <Field
                                component={TimePicker}
                                name="startTime"
                                disabled={isStartDateChangeDisabled}
                              />
                              {(errors.startDate || errors.startTime) && (
                                <div className="input-error-message">
                                  {errors.startDate || errors.startTime}
                                </div>
                              )}
                              {values.deliverOffset && values.deliverOffset.value ? (
                                <div className="mt-2">
                                  <span className="mr-1">{t('surveys.first_notification')}</span>
                                  <DateTime dateTime={notificationDateTime} />
                                </div>
                              ) : null}
                            </FormGroup>

                            <FormGroup>
                              <Label for="endDate">{t('common.endTime')}</Label>
                              <Field component={SingleDate} id="endDate" name="endDate" />
                              <Field component={TimePicker} name="endTime" />
                              {(errors.endDate || errors.endTime) && (
                                <div className="input-error-message">
                                  {errors.endDate || errors.endTime}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="description">{t('common.description')}</Label>
                              <TextArea name="description" rows="4" />
                            </FormGroup>
                            {values.slug && (
                              <FormGroup>
                                <Label for="name">{t('common.slug')}</Label>
                                <TextField name="slug" disabled />
                              </FormGroup>
                            )}
                            <FormGroup>
                              <Label for="name">{t('common.mandatory')}</Label>
                              <Switch
                                name="mandatory"
                                className="mr-2"
                                variant="pill"
                                color="primary"
                                size="lg"
                                checked={values.mandatory}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setFieldValue('mandatory', e.currentTarget.checked)
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <div className="d-flex justify-content-end align-items-center mt-4">
                              {isSubmitting && <Spinner loading={isSubmitting} className="mr-1" />}

                              <Button type="submit" color="primary" disabled={isSubmitting}>
                                {edit ? t('common.save') : t('common.create')}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </fieldset>

                      <fieldset className="border p-3">
                        <legend className="col-form-label w-auto font-weight-bold">
                          {t('surveys.sending_delivering_configuration')}
                        </legend>

                        <FormGroup>
                          <Label for="description">{t('surveys.start_message')}</Label>
                          <TextArea name="startMessageText" disabled={!edit} rows="4" />
                        </FormGroup>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="startMessageButton1Message">
                                {t('surveys.startMessageButton1Text')}
                              </Label>
                              <TextField name="startMessageButton1Message" disabled={!edit} />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <ColorPicker
                                name="startMessageButton1Color"
                                label="surveys.startMessageButton1Color"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="startMessageButton2Message">
                                {t('surveys.startMessageButton2Text')}
                              </Label>
                              <TextField name="startMessageButton2Message" disabled={!edit} />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <ColorPicker
                                name="startMessageButton2Color"
                                label="surveys.startMessageButton2Color"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="notification">{t('common.notification')}</Label>
                              <Switch
                                name="notification"
                                className="mr-2"
                                variant="pill"
                                color="primary"
                                size="lg"
                                checked={values.notification}
                                disabled={!edit}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  setFieldValue('notification', e.currentTarget.checked);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <Label for="retries">{t('common.retries')}</Label>
                              <TextField name="retries" disabled={!edit || !values.notification} />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <Label for="retryInterval">{t('common.retryInterval')}</Label>
                              <ReactSelect
                                name="retryInterval"
                                options={retryInterval}
                                isDisabled={!edit || !values.notification}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <Label for="deliverOffset">{t('common.deliverOffset')}</Label>
                              <ReactSelect
                                name="deliverOffset"
                                options={deliverOffset}
                                isDisabled={!edit || !values.notification}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </fieldset>

                      {surveyId && survey && (
                        <fieldset className="border p-3">
                          <legend className="col-form-label w-auto font-weight-bold">
                            {t('surveys.add_tags')}
                          </legend>

                          <EmployeeTags
                            employeeTags={newTags}
                            removeTag={(tagId: string) => removeEmployeeSurveyTag(tagId)}
                            removePermission="employee_employee-tag-assign_delete"
                            createPermission="employee_employee-tag-assign_create"
                            taggableModel={survey}
                            getTagCategories={getTagCategories}
                            tagCategories={tagCategories}
                            addTagToEntity={(
                              appId: string,
                              surveyId: string,
                              tagIds: string[],
                              tagsToAssign: SelectModel[],
                            ) => addEmployeeTagToSurvey(tagsToAssign)}
                          />
                        </fieldset>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <SurveyQuestionsList
                surveyId={surveyId}
                setEditSurveyQuestionItem={setEditSurveyQuestionItem}
                isEditingDisabled={isAfterStart()}
              />
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          {!editSurveyQuestionItem && (
            <SurveysEditorShortcutsSidebar
              getSurveyQuestions={getSurveyQuestions}
              isEditingDisabled={isAfterStart()}
            />
          )}
          {editSurveyQuestionItem && (
            <SurveysEditorQuestionEditSidebar
              getSurveyQuestions={getSurveyQuestions}
              editSurveyQuestionItem={editSurveyQuestionItem}
              setEditSurveyQuestionItem={setEditSurveyQuestionItem}
              isEditingDisabled={isAfterStart()}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    survey: state.surveyGeneral.data?.survey,
    tagCategories: state.tagCategories,
  };
};

const mapDispatchToProps = {
  getSurvey,
  getApplication,
  createSurvey,
  updateSurvey,
  revertSurvey,
  revertSurveyQuestions,
  addTagToSurvey,
  removeSurveyTag,
  getSurveyQuestions,
  getTagCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveysEditor);
