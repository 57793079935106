import i18n from 'src/i18n';

import { SelectModel } from 'src/shared/models';
import { defaultSelect } from 'src/shared/constants/select.constants';

export const getRetryInterval = (): SelectModel[] => [
  { value: 'PT24H', label: i18n.t('common.daily') },
  { value: 'PT168H', label: i18n.t('common.weekly') },
  { value: 'PT336H', label: i18n.t('common.biweekly') },
  { value: 'PT672H', label: i18n.t('common.monthly') },
];

export const getDeliverOffset = (): SelectModel[] => [
  { value: 'PT0S', label: i18n.t('common.immediately') },
  { value: 'PT1H', label: i18n.t('common.one_hour'), amount: 1, unit: 'hour' },
  { value: 'PT24H', label: i18n.t('common.one_day'), amount: 1, unit: 'day' },
  { value: 'PT168H', label: i18n.t('common.one_week'), amount: 1, unit: 'week' },
  { value: 'PT336H', label: i18n.t('common.two_weeks'), amount: 2, unit: 'week' },
  { value: 'PT672H', label: i18n.t('common.one_month'), amount: 1, unit: 'month' },
];

export const getInterval = (intervals: SelectModel[], interval: string): SelectModel =>
  intervals.find(({ value }) => value === interval) || { ...defaultSelect };

export const ANSWER_VALUE_MIN_LIMIT = 1;
export const ANSWER_VALUE_MAX_LIMIT = 40;
