import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Row, Label } from 'reactstrap';

import { useFormikContext, ArrayHelpers, FieldArray } from 'formik';

import { ReactSelect, TextField } from 'src/shared/components';
import { SelectModel } from 'src/shared/models';

interface AnalyticsControllerFormProps {}

export interface AnalyticsControllerModel {
  analyticsType: SelectModel;
  data: {
    source?: string;
  };
}

export enum AnalyticsControllerType {
  FEEDBACK = 'FEEDBACK',
  MENU_CLICKED = 'MENU_CLICKED',
}

const AnalyticsControllerForm: FC<AnalyticsControllerFormProps> = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<{
    analytics: AnalyticsControllerModel[];
  }>();

  const remove = (arrayHelpers: ArrayHelpers, index: number) => {
    arrayHelpers.remove(index);
  };

  const add = (arrayHelpers: ArrayHelpers) => {
    arrayHelpers.push({ analyticsType: '', data: {} });
  };

  const types = useMemo(() => {
    return Object.keys(AnalyticsControllerType).map(enumValue => {
      return {
        value: enumValue,
        label: t(`questionnaire.analyticsControllerForm.types.${enumValue.toLowerCase()}`),
      };
    });
  }, [t]);

  return (
    <FieldArray
      name="analytics"
      render={arrayHelpers => (
        <>
          <FormGroup>
            {values.analytics.length > 0 && (
              <Row>
                {Object.keys(values.analytics[0]).map(
                  name =>
                    name !== 'data' && (
                      <Col key={name}>
                        <Label>{t(`common.${name}`)}</Label>
                      </Col>
                    ),
                )}
                <Col lg={1}></Col>
              </Row>
            )}

            {values.analytics &&
              values.analytics.map((analytic, index) => (
                <Row className="mb-2" key={index}>
                  <Col>
                    <ReactSelect
                      name={`analytics[${index}].analyticsType`}
                      options={types}
                      onChanged={(type: SelectModel) => {
                        setFieldValue(`analytics[${index}].data.source`, '');
                      }}
                    />
                  </Col>
                  <Col>
                    {analytic.analyticsType.value === AnalyticsControllerType.MENU_CLICKED && (
                      <TextField name={`analytics[${index}].data.source`} />
                    )}
                  </Col>
                  <Col lg={1}>
                    <div>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => remove(arrayHelpers, index)}
                      >
                        <i className="far fa-trash-alt" />
                      </button>
                    </div>
                  </Col>
                </Row>
              ))}
            <Row className="justify-content-center mt-3">
              <button type="button" className="btn btn-primary" onClick={() => add(arrayHelpers)}>
                <span className="mr-2">{t('common.add')}</span>
                <i className="fas fa-plus" />
              </button>
            </Row>
          </FormGroup>
        </>
      )}
    />
  );
};

export default AnalyticsControllerForm;
