import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import styles from '../TalentPool.module.scss';

interface Props {
  options: any;
  handleSelectChange: Function;
}

const TalentPoolLabelFilter: React.FC<Props> = ({ options, handleSelectChange }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column">
      <span className={styles.selectLabel}>{t('common.labels')}</span>
      <Select
        isMulti
        isClearable
        placeholder={!options ? t('common.noOptions') : t('common.all')}
        noOptionsMessage={() => t('common.noOptions')}
        options={options || []}
        onChange={option => handleSelectChange(option)}
      />
    </div>
  );
};

export default TalentPoolLabelFilter;
