import React, { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardBody, Alert } from 'reactstrap';
import moment from 'moment';

import { getApplication, ApplicationModel } from 'src/redux/actions/applications.action';
import {
  getComplaints,
  exportComplaints,
  clearComplaints,
} from 'src/redux/actions/complaints.action';
import { GeneralQueryParams } from 'src/shared/models/query-params.model';
import { StateModel } from 'src/shared/models/default-state.model';

import saveFile from 'src/shared/utils/file-saver.util';
import { fileNames } from 'src/shared/settings';

import ComplaintsTable from './components/ComplaintsTable';
import ComplaintsDateFilter from './components/ComplaintsDateFilter';
import ComplaintsClear from './components/ComplaintsClear';
import CheckPermission from 'src/shared/components/CheckPermission';
import ConfirmModal from 'src/shared/components/modals/ConfirmModal';
import { Spinner, PageHeader } from 'src/shared/components';

interface Props extends RouteComponentProps<{ id: string }>, WithTranslation {
  getApplication: Function;
  application: ApplicationModel;
  getComplaints: Function;
  complaints: StateModel;
  totalComplaints: number;
  exportComplaints: Function;
  complaintsExport: StateModel;
  clearComplaints: Function;
  complaintsClear: StateModel;
}

interface State {
  queryParams: GeneralQueryParams;
  activePage: number;
  isModalOpen: boolean;
}

class Complaints extends Component<Props, State> {
  state: State = {
    queryParams: {
      from: moment.utc().startOf('day').subtract(1, 'month').toISOString(),
      to: moment.utc().endOf('day').toISOString(),
      page: 0,
      size: 20,
    },
    activePage: 1,
    isModalOpen: false,
  };

  componentDidMount = () => {
    const { application, match } = this.props;

    if (!application.id) {
      this.props.getApplication(match.params.id);
    }

    this.getComplaints();
  };

  getComplaints = () => {
    const { queryParams } = this.state;
    this.props.getComplaints(queryParams);
  };

  exportComplaints = async () => {
    const { queryParams } = this.state;

    const from = moment(queryParams.from).toISOString();
    const to = moment(queryParams.to).toISOString();

    await this.props.exportComplaints({ from, to });
    const { complaintsExport } = this.props;

    if (complaintsExport.data) {
      saveFile(complaintsExport.data, fileNames.COMPLAINTS_EXPORT, 'csv');
    }
  };

  clearComplaints = async () => {
    await this.props.clearComplaints();
    this.getComplaints();
    this.toggleModal();
  };

  handleDateChange = async (from: moment.Moment, to: moment.Moment) => {
    const { history, match } = this.props;

    if (!from || !to) return;

    this.setState(
      prevState => ({
        queryParams: {
          ...prevState.queryParams,
          from: from.utc().startOf('day').toISOString(),
          to: to.utc().endOf('day').toISOString(),
          page: 0,
        },
        activePage: 1,
      }),
      () => {
        history.push(`/application/${match.params.id}/modules/complaints?page=0`);
        this.getComplaints();
        window.scrollTo(0, 0);
      },
    );
  };

  pageChange = (index: number) => {
    const { id } = this.props.match.params;

    this.setState(
      prevState => ({
        queryParams: {
          ...prevState.queryParams,
          page: index - 1,
        },
        activePage: index,
      }),
      () => {
        const { page } = this.state.queryParams;

        this.props.history.push(`/application/${id}/modules/complaints?page=${page}`);
        this.getComplaints();
        window.scrollTo(0, 0);
      },
    );
  };

  toggleModal = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  render() {
    const { complaints, totalComplaints, complaintsClear, complaintsExport, t } = this.props;
    const { queryParams, activePage, isModalOpen } = this.state;

    const disabled =
      complaints.loading ||
      complaintsClear.loading ||
      complaintsExport.loading ||
      !complaints.data.content.length;
    const loading = complaintsClear.loading || complaintsExport.loading;

    return (
      <Fragment>
        <Alert color="primary">{t('complaints.infobox')}</Alert>
        <Row className="animated fadeIn">
          <Col>
            <PageHeader title={t('common.complaints')} />

            <Card>
              <CardHeader className="d-flex justify-content-between">
                <ComplaintsDateFilter
                  startDate={moment(queryParams.from).utc()}
                  endDate={moment(queryParams.to).utc()}
                  handleDateChange={this.handleDateChange}
                />
                <div className="d-flex align-items-center">
                  <Spinner className="mr-2" loading={loading} />

                  <CheckPermission variant="enableIf" permissions={['boe_issues_delete']}>
                    <ComplaintsClear clearComplaints={this.toggleModal} disabled={disabled} />
                  </CheckPermission>

                  {/* <CheckPermission variant="enableIf" permissions={['boe_issues_read']}>
                    <ComplaintsExport exportComplains={this.exportComplaints} disabled={disabled} />
                  </CheckPermission> */}
                </div>
              </CardHeader>
              <CardBody>
                {complaints.loaded && complaints.data.content.length > 0 && (
                  <ComplaintsTable
                    complaints={complaints.data.content}
                    totalItemsCount={totalComplaints}
                    activePage={activePage}
                    pageSize={queryParams.size}
                    pageChange={this.pageChange}
                  />
                )}

                {complaints.loading && (
                  <div className="d-flex justify-content-center">
                    <Spinner loading={complaints.loading} size="2x" />
                  </div>
                )}

                {complaints.loaded && !complaints.data.content.length && (
                  <Alert className="mb-0 text-center" color="info">
                    {t('complaints.noComplaints')}
                  </Alert>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ConfirmModal
          title={t('complaints.clearComplaints')}
          text={t('complaints.clearComplaintsConfirmMessage')}
          isOpen={isModalOpen}
          isLoading={complaintsClear.loading}
          confirm={this.clearComplaints}
          cancel={this.toggleModal}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  complaints: state.complaints,
  totalComplaints: state.complaints.data.totalElements,
  complaintsExport: state.complaintsExport,
  complaintsClear: state.complaintsClear,
});

const mapDispatchToProps = {
  getApplication,
  getComplaints,
  exportComplaints,
  clearComplaints,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(Complaints)));
