import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { Switch } from 'src/shared/components';

const PositionPrefilterCriteriasForm = () => {
  return (
    <Fragment>
      <Row form>
        <Col>
          <Switch
            name="enableKoExitPoint"
            variant="pill"
            color="info"
            label="questionnaire.enableKoExitPoint"
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default PositionPrefilterCriteriasForm;
