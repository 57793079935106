import { del, get, post } from 'src/shared/services/http.service';
import { GetLanguagesResponse, LanguageBase } from 'src/shared/models/language-settings.model';

const baseUrl = (applicationId: string) => `/applications/${applicationId}/translation/languages`;

export const getLanguageSettings = async (applicationId: string): Promise<GetLanguagesResponse> => {
  return get(baseUrl(applicationId));
};

export const createLanguageSetting = async (applicationId: string, data: LanguageBase) => {
  return post(`${baseUrl(applicationId)}`, { data });
};

export const deleteLanguageSetting = async (applicationId: string, languageSettingId: string) => {
  return del(`${baseUrl(applicationId)}/${languageSettingId}`);
};
