import { Dispatch } from 'redux';
import { ConversationFlowQueryParams, PageQueryParams } from 'src/shared/models/query-params.model';

import {
  getConversationFlowVariables as getConversationFlowVariablesService,
  createConversationFlowVariable as createConversationFlowVariableService,
  updateConversationFlowVariable as updateConversationFlowVariableService,
  deleteConversationFlowVariable as deleteConversationFlowVariableService,
  reloadConversationFlowVariables as reloadConversationFlowVariablesService,
} from 'src/redux/services/conversation-flow-variables.service';
import {
  ConversationFlowFilters,
  ConversationFlowModel,
} from 'src/shared/models/conversation-flow.model';

export const GET_CONVERSATION_FLOW_VARIABLES_REQUEST =
  'conversationflow/GET_CONVERSATION_FLOW_VARIABLES_REQUEST';
export const GET_CONVERSATION_FLOW_VARIABLES_SUCCESS =
  'conversationflow/GET_CONVERSATION_FLOW_VARIABLES_SUCCESS';
export const GET_CONVERSATION_FLOW_VARIABLES_ERROR =
  'conversationflow/GET_CONVERSATION_FLOW_VARIABLES_ERROR';

export const CREATE_CONVERSATION_FLOW_VARIABLE_REQUEST =
  'conversationflow/CREATE_CONVERSATION_FLOW_VARIABLE_REQUEST';
export const CREATE_CONVERSATION_FLOW_VARIABLE_SUCCESS =
  'conversationflow/CREATE_CONVERSATION_FLOW_VARIABLE_SUCCESS';
export const CREATE_CONVERSATION_FLOW_VARIABLE_ERROR =
  'conversationflow/CREATE_CONVERSATION_FLOW_VARIABLE_ERROR';

export const UPDATE_CONVERSATION_FLOW_VARIABLE_REQUEST =
  'conversationflow/UPDATE_CONVERSATION_FLOW_VARIABLE_REQUEST';
export const UPDATE_CONVERSATION_FLOW_VARIABLE_SUCCESS =
  'conversationflow/UPDATE_CONVERSATION_FLOW_VARIABLE_SUCCESS';
export const UPDATE_CONVERSATION_FLOW_VARIABLE_ERROR =
  'conversationflow/UPDATE_CONVERSATION_FLOW_VARIABLE_ERROR';

export const DELETE_CONVERSATION_FLOW_VARIABLE_REQUEST =
  'conversationflow/DELETE_CONVERSATION_FLOW_VARIABLE_REQUEST';
export const DELETE_CONVERSATION_FLOW_VARIABLE_SUCCESS =
  'conversationflow/DELETE_CONVERSATION_FLOW_VARIABLE_SUCCESS';
export const DELETE_CONVERSATION_FLOW_VARIABLE_ERROR =
  'conversationflow/DELETE_CONVERSATION_FLOW_VARIABLE_ERROR';

export const RELOAD_CONVERSATION_FLOW_VARIABLE_REQUEST =
  'conversationflow/RELOAD_CONVERSATION_FLOW_VARIABLE_REQUEST';
export const RELOAD_CONVERSATION_FLOW_VARIABLE_SUCCESS =
  'conversationflow/RELOAD_CONVERSATION_FLOW_VARIABLE_SUCCESS';
export const RELOAD_CONVERSATION_FLOW_VARIABLE_ERROR =
  'conversationflow/RELOAD_CONVERSATION_FLOW_VARIABLE_ERROR';

export const getConversationFlowVariables = (
  applicationId: string,
  messagingPlatform: string,
  params: PageQueryParams,
  filters: ConversationFlowFilters,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_CONVERSATION_FLOW_VARIABLES_REQUEST,
    });

    const conversationFlowParams: ConversationFlowQueryParams = {
      ...params,
      ...filters,
    };

    delete conversationFlowParams.platform;

    try {
      dispatch({
        type: GET_CONVERSATION_FLOW_VARIABLES_SUCCESS,
        payload: await getConversationFlowVariablesService(
          applicationId,
          messagingPlatform,
          conversationFlowParams,
        ),
      });
    } catch (error) {
      dispatch({
        type: GET_CONVERSATION_FLOW_VARIABLES_ERROR,
        error,
      });
    }
  };
};

export const createConversationFlowVariable = (
  applicationId: string,
  messagingPlatform: string,
  conFlow: ConversationFlowModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_CONVERSATION_FLOW_VARIABLE_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_CONVERSATION_FLOW_VARIABLE_SUCCESS,
        payload: await createConversationFlowVariableService(
          applicationId,
          messagingPlatform,
          conFlow,
        ),
      });
    } catch (error) {
      dispatch({
        type: CREATE_CONVERSATION_FLOW_VARIABLE_ERROR,
        error,
      });
    }
  };
};

export const updateConversationFlowVariable = (
  applicationId: string,
  messagingPlatform: string,
  variableId: string,
  data: ConversationFlowModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_CONVERSATION_FLOW_VARIABLE_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_CONVERSATION_FLOW_VARIABLE_SUCCESS,
        payload: await updateConversationFlowVariableService(
          applicationId,
          messagingPlatform,
          variableId,
          data,
        ),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_CONVERSATION_FLOW_VARIABLE_ERROR,
        error,
      });
    }
  };
};

export const deleteConversationFlowVariable = (
  applicationId: string,
  messagingPlatform: string,
  variableId: string,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_CONVERSATION_FLOW_VARIABLE_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_CONVERSATION_FLOW_VARIABLE_SUCCESS,
        payload: await deleteConversationFlowVariableService(
          applicationId,
          messagingPlatform,
          variableId,
        ),
      });
    } catch (error) {
      dispatch({
        type: DELETE_CONVERSATION_FLOW_VARIABLE_ERROR,
        error,
      });
    }
  };
};

export const reloadConversationFlowVariables = (
  applicationId: string,
  messagingPlatform: string,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: RELOAD_CONVERSATION_FLOW_VARIABLE_REQUEST,
    });

    try {
      dispatch({
        type: RELOAD_CONVERSATION_FLOW_VARIABLE_SUCCESS,
        payload: await reloadConversationFlowVariablesService(applicationId, messagingPlatform),
      });
    } catch (error) {
      dispatch({
        type: RELOAD_CONVERSATION_FLOW_VARIABLE_ERROR,
        error,
      });
    }
  };
};
