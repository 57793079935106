import React, { Component, Fragment } from 'react';
import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';

import placeholder from '../../../../../../../../assets/img/other/placeholder_150x150.svg';

import styles from './scss/LiveChat.module.scss';

interface Props {
  message: ConversationHistoryModel;
}

interface State {
  outputTemplate: any;
}

class GenericTemplate extends Component<Props> {
  state: State = {
    outputTemplate: [],
  };

  componentDidMount() {
    this.renderTemplate(this.props.message);
  }

  renderTemplate(message: any) {
    const outputTemplate: any = [];
    let currentTemplate = null;
    let genericElements = null;

    try {
      // eslint-disable-next-line
      currentTemplate = JSON.parse(message.messageAsText);
      // eslint-disable-next-line
      genericElements = currentTemplate.genericElements;
    } catch (error) {
      this.setState({
        outputTemplate: [],
      });
    }

    if (currentTemplate) {
      genericElements.map((el: any) => {
        return outputTemplate.push(
          <div key={el.title} className={styles.genericTemplate}>
            <p className="title">{el.title}</p>
            {el.subtitle && <p className="subtitle">{el.subTitle}</p>}

            <img
              src={el.imageUrl || placeholder}
              className={styles.templateImage}
              alt="Generic Template Cardd"
            />

            <a href={el.fileUrl}>
              <button type="button" className={styles.templateButton}>
                {el.buttonTitle}
              </button>
            </a>
          </div>,
        );
      });

      this.setState({
        outputTemplate,
      });
    }
  }

  render() {
    return <Fragment>{this.state.outputTemplate}</Fragment>;
  }
}

export default GenericTemplate;
