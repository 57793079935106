import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Formik, Form, FormikValues } from 'formik';
import isEqual from 'lodash/isEqual';

import { ModalEventType } from '../CustomFields';

import { CustomFieldModel } from 'src/shared/models/custom-fields.model';

import { customFieldConfigSchema } from 'src/shared/schemas/validation.schema';

import { getCustomFields, updateCustomField } from 'src/redux/actions/custom-fields.action';

import TextArea from 'src/shared/components/form-inputs/TextArea';
import Spinner from 'src/shared/components/Spinner';

interface Props {
  isOpen: boolean;
  selectedCustomField: CustomFieldModel | undefined;
  toggleModal: (type: ModalEventType) => void;
  getCustomFields: Function;
  updateCustomField: Function;
}

const CustomFieldConfigModal: React.FC<Props> = ({
  isOpen,
  selectedCustomField,
  toggleModal,
  getCustomFields,
  updateCustomField,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const [alertText, setAlertText] = useState<string>('');
  const [initialFormValues, setInitialValues] = useState<FormikValues>({});

  const handleUpdateCustomField = async (config: string) => {
    if (selectedCustomField) {
      const newCustomFieldValues = {
        ...selectedCustomField,
        config,
      };

      await updateCustomField(appId, selectedCustomField.id, newCustomFieldValues);
      getCustomFields(appId);
      toggleModal('config');
    }
  };

  const handleSubmit = async (config: string) => {
    setAlertText('');

    try {
      JSON.parse(config);
      await handleUpdateCustomField(config);
    } catch {
      setAlertText(t('errors.invalidJsonFormat'));
    }
  };

  const getInitialFormValues = () => {
    let value: string;

    if (selectedCustomField) {
      try {
        const { config } = selectedCustomField;
        value = JSON.stringify(JSON.parse(config), null, 2);
      } catch {
        value = '';
      }
    } else {
      value = '';
    }

    return { config: value };
  };

  useEffect(() => {
    const values = getInitialFormValues();
    setInitialValues(values);
    /* eslint-disable-next-line */
  }, [selectedCustomField]);

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{t('customFields.customFieldConfig')}</ModalHeader>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialFormValues}
        validationSchema={customFieldConfigSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (isEqual(values, initialFormValues)) {
            toggleModal('config');
            return;
          }
          await handleSubmit(values.config);
          setSubmitting(false);
        }}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <ModalBody>
              {alertText && (
                <Alert color="danger" className="text-center">
                  {alertText}
                </Alert>
              )}

              <TextArea name="config" rows="15" />
            </ModalBody>

            <ModalFooter>
              <Spinner loading={isSubmitting} />

              <Button type="submit" color="primary" disabled={!values.config || isSubmitting}>
                {t('common.submit')}
              </Button>

              <Button color="secondary" onClick={() => toggleModal('config')}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapDispatchToProps = {
  getCustomFields,
  updateCustomField,
};

export default connect(null, mapDispatchToProps)(CustomFieldConfigModal);
