import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Formik, Form, FormikProps } from 'formik';

import { StateModel } from '../../../shared/models/default-state.model';
import { CreateApplicationSettingModel } from '../../../redux/actions/application-settings.action';
import { createApplicationSettingSchema } from '../../../shared/schemas/validation.schema';

import { Switch, Select, Spinner, TextField } from 'src/shared/components';

interface Props {
  toggleCreateModal: Function;
  getApplicationSettings: Function;
  createApplicationSetting: Function;
  applicationSettings: StateModel;
  isOpen: boolean;
  isLoading: boolean;
}

const SettingCreateModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const handleSubmit = async (setting: CreateApplicationSettingModel) => {
    props.createApplicationSetting(setting);
  };

  const { isOpen, isLoading, toggleCreateModal } = props;

  const IMPORT_METHODS = [
    {
      label: t('applicationSettings.scheduled_chat_history_cleanup'),
      value: 'scheduled_chat_history_cleanup',
    },
    {
      label: t('applicationSettings.livechat_chatbot_domain'),
      value: 'livechat_chatbot_domain',
    },
    {
      label: t('applicationSettings.viber_chatbot_uri'),
      value: 'viber_chatbot_uri',
    },
    {
      label: t('applicationSettings.facebook_page_id'),
      value: 'facebook_page_id',
    },
    {
      label: t('applicationSettings.outgoing_mail_from_name'),
      value: 'outgoing_mail_from_name',
    },
    {
      label: t('applicationSettings.set_idle_user_active_for_scheduled_tasks_after_time'),
      value: 'set_idle_user_active_for_scheduled_tasks_after_time',
    },
  ];

  const INIT_VALUES: CreateApplicationSettingModel = {
    key: IMPORT_METHODS[0].value,
    value: '',
    addNewKey: false,
  };

  const onChangedNewKey = (
    checked: boolean,
    { setFieldValue }: FormikProps<CreateApplicationSettingModel>,
  ) => {
    setFieldValue('key', checked ? '' : IMPORT_METHODS[0].value);
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{t('applicationSettings.createSetting')}</ModalHeader>

      <Formik
        initialValues={{ ...INIT_VALUES }}
        validationSchema={createApplicationSettingSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);

          await handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {(formikProps: FormikProps<CreateApplicationSettingModel>) => (
          <Form>
            <ModalBody>
              <FormGroup row>
                <Col lg="3">
                  <Label>{t('common.addNewKey')}</Label>
                </Col>
                <Col>
                  <Switch
                    name="addNewKey"
                    color="primary"
                    variant="pill"
                    onChanged={(checked: boolean) => onChangedNewKey(checked, formikProps)}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col lg="3">
                  <Label>{t('common.key')}</Label>
                </Col>
                <Col>
                  {formikProps.values.addNewKey ? (
                    <TextField name="key" placeholder={t('common.key').toLowerCase()} />
                  ) : (
                    <Select name="key" options={IMPORT_METHODS} />
                  )}
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col lg="3">
                  <Label>{t('common.value')}</Label>
                </Col>

                <Col>
                  <TextField name="value" placeholder={t('common.value').toLowerCase()} />
                </Col>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Spinner loading={formikProps.isSubmitting || isLoading} className="mr-2" />

              <Button
                type="submit"
                color="success"
                disabled={formikProps.isSubmitting || isLoading}
              >
                {t('common.create')}
              </Button>
              <Button color="secondary" onClick={e => toggleCreateModal(e)}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default SettingCreateModal;
