import { StateModel } from '../../shared/models/default-state.model';

import {
  GET_SHORT_LIST_POSITIONS_REQUEST,
  GET_SHORT_LIST_POSITIONS_SUCCESS,
  GET_SHORT_LIST_POSITIONS_ERROR,
  GET_SHORT_LIST_POSITION_CRITERIAS_REQUEST,
  GET_SHORT_LIST_POSITION_CRITERIAS_SUCCESS,
  GET_SHORT_LIST_POSITION_CRITERIAS_ERROR,
  GET_SHORT_LIST_POSITION_APPLICANTS_REQUEST,
  GET_SHORT_LIST_POSITION_APPLICANTS_SUCCESS,
  GET_SHORT_LIST_POSITION_APPLICANTS_ERROR,
  CLEAR_SHORT_LIST_STATE,
} from '../actions/short-list.action';

interface TrainingDataStateModel {
  positions: StateModel;
  criterias: StateModel;
  applicants: StateModel;
}

const INITIAL_STATE = {
  positions: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  criterias: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  applicants: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
};

// eslint-disable-next-line import/prefer-default-export
export const shortListReducer = (state: TrainingDataStateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_SHORT_LIST_POSITIONS_REQUEST:
      return {
        ...state,
        positions: {
          ...state.positions,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case GET_SHORT_LIST_POSITIONS_SUCCESS:
      return {
        ...state,
        positions: {
          ...state.positions,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case GET_SHORT_LIST_POSITIONS_ERROR:
      return {
        ...state,
        positions: {
          ...state.positions,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case GET_SHORT_LIST_POSITION_CRITERIAS_REQUEST:
      return {
        ...state,
        criterias: {
          ...state.criterias,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case GET_SHORT_LIST_POSITION_CRITERIAS_SUCCESS:
      return {
        ...state,
        criterias: {
          ...state.criterias,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case GET_SHORT_LIST_POSITION_CRITERIAS_ERROR:
      return {
        ...state,
        criterias: {
          ...state.criterias,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case GET_SHORT_LIST_POSITION_APPLICANTS_REQUEST:
      return {
        ...state,
        applicants: {
          ...state.applicants,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case GET_SHORT_LIST_POSITION_APPLICANTS_SUCCESS:
      return {
        ...state,
        applicants: {
          ...state.applicants,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case GET_SHORT_LIST_POSITION_APPLICANTS_ERROR:
      return {
        ...state,
        applicants: {
          ...state.applicants,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CLEAR_SHORT_LIST_STATE:
      return INITIAL_STATE;

    default:
      return state;
  }
};
