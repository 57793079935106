import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody, Col, Button } from 'reactstrap';

import { CustomListModel } from 'src/shared/models/custom-lists.model';

import CheckPermission from 'src/shared/components/CheckPermission';

import styles from '../CustomLists.module.scss';

interface Props {
  customList: CustomListModel;
  setModalOpen: Function;
  setConfirmModalOpen: Function;
  setModalEvent: Function;
  setSelectedCustomList: Function;
}

const CustomListCard: React.FC<Props> = ({
  customList,
  setModalOpen,
  setConfirmModalOpen,
  setSelectedCustomList,
  setModalEvent,
}) => {
  const history = useHistory();
  const location = useLocation();

  const redirectTo = (customListId: number) => {
    history.push(`${location.pathname}/${customListId}`);
  };

  return (
    <Col md={4} lg={3}>
      <Card className={styles.customListCard} onClick={() => redirectTo(customList.id)}>
        <CardBody className={styles.customListCardBody}>
          {customList.name}

          <div className={styles.buttonsContainer}>
            <CheckPermission variant="enableIf" permissions={['customlist_custom-list_update']}>
              <Button
                color="secondary"
                size="sm"
                className="mr-2"
                onClick={e => {
                  e.stopPropagation();
                  setModalOpen(true);
                  setModalEvent('edit');
                  setSelectedCustomList(customList);
                }}
              >
                <i className="far fa-edit" />
              </Button>
            </CheckPermission>

            <CheckPermission variant="enableIf" permissions={['customlist_custom-list_delete']}>
              <Button
                color="danger"
                size="sm"
                onClick={e => {
                  e.stopPropagation();
                  setConfirmModalOpen(true);
                  setSelectedCustomList(customList);
                }}
              >
                <i className="far fa-trash-alt" />
              </Button>
            </CheckPermission>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CustomListCard;
