import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label, Row, Col } from 'reactstrap';

import { SelectModel } from 'src/shared/models/common.model';

import TextField from 'src/shared/components/form-inputs/TextField';
import ReactSelect from 'src/shared/components/form-inputs/ReactSelect';
import { getListsService } from 'src/redux/services/lists.service';
import { useParams } from 'react-router';
import { SQLDirection } from 'src/shared/models';
import { ListModel } from 'src/redux/reducers/lists.reducer';
import { FormikValues } from 'formik';

interface Props {
  values?: FormikValues;
  setFieldValue: Function;
}

const DocumentViewForm: React.FC<Props> = ({ values, setFieldValue }) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [folderOptions, setFolderOptions] = useState<SelectModel[]>([]);

  useEffect(() => {
    getFolders();
    /* eslint-disable-next-line */
  }, []);

  const getFolders = async () => {
    const data = await getListsService(appId, {
      sort: 'createdAt',
      direction: SQLDirection.DESC,
      page: 0,
      size: 999999,
    });
    setFolderOptions(
      data.content.map((folder: ListModel) => ({
        label: `${folder.id} ${folder.name} (${folder.slug})`,
        value: folder.slug,
      })),
    );
    if (values?.folderSlug?.value && values?.folderSlug?.label === '') {
      const usedFolder = data.content.find(
        (folder: ListModel) => folder.slug === values.folderSlug.value,
      );
      if (usedFolder) {
        console.log('SF');
        setFieldValue('folderSlug', {
          value: values.folderSlug.value,
          label: `${usedFolder.id} ${usedFolder.name} (${usedFolder.slug})`,
        });
      }
    }
  };

  return (
    <>
      <Row form>
        <Col>
          <FormGroup>
            <Label for="text">{t('common.message')}</Label>
            <TextField name="text" />
          </FormGroup>
        </Col>
      </Row>

      <Row form>
        <Col>
          <FormGroup>
            <Label for="buttonText">{t('common.buttonText')}</Label>
            <TextField name="buttonText" />
          </FormGroup>
        </Col>
      </Row>

      <Row form>
        <Col>
          <FormGroup>
            <Label for="notFoundErrorText">{t('questionnaire.notFoundErrorText')}</Label>
            <TextField name="notFoundErrorText" />
          </FormGroup>
        </Col>
      </Row>

      <Row form>
        <Col>
          <FormGroup>
            <Label for="folderSlug">{t('questionnaire.folderSlug')}</Label>
            <ReactSelect name="folderSlug" options={folderOptions} />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default DocumentViewForm;
