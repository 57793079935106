import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label, Row, Col } from 'reactstrap';
import { KeyValueList } from 'src/shared/components';

import TextArea from 'src/shared/components/form-inputs/TextArea';
import TextField from 'src/shared/components/form-inputs/TextField';

const ExportApplicationToAtsForm: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Row form>
        <Col>
          <FormGroup>
            <Label for="path">{t('common.path')}</Label>
            <TextArea name="path" />
          </FormGroup>
        </Col>
      </Row>

      <Row form>
        <Col>
          <FormGroup>
            <Label for="errorMessage">{t('common.errorMessage')}</Label>
            <TextField name="errorMessage" />
          </FormGroup>
        </Col>
      </Row>

      <Row form>
        <Col>
          <FormGroup>
            <Label for="parameters">{t('common.parameters')}</Label>
            <KeyValueList />
          </FormGroup>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ExportApplicationToAtsForm;
