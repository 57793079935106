import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';

import { StateModel } from 'src/shared/models/default-state.model';

import { getApplication, ApplicationModel } from 'src/redux/actions/applications.action';
import { getMessages, clearMessages } from 'src/redux/actions/questionnaire.action';
import { PageHeader } from 'src/shared/components';

import MessagesTableContainer from './MessagesTableContainer';
import Graph from './Graph';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  messagesState: StateModel;
  getMessages: Function;
  clearMessages: Function;
}

const Container: React.FC<Props> = ({
  application,
  getApplication,
  messagesState,
  getMessages,
  clearMessages,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [activeTab, setActiveTab] = useState('1');
  const [zoomDelay, setZoomDelay] = useState<number | undefined>(undefined);

  useEffect(() => {
    getMessages(appId);

    if (!application.id) {
      getApplication(appId);
    }

    return () => {
      clearMessages();
    };
    /* eslint-disable-next-line */
  }, []);

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleSetZoomDelay = (value: number) => {
    if (!zoomDelay) setZoomDelay(value);
  };

  return (
    <Row className="animated fadeIn mb-4">
      <Col>
        <PageHeader title={t('common.questionnaire')} />
        <Nav tabs>
          <NavItem>
            <NavLink
              className={cx({ active: activeTab === '1' })}
              onClick={() => {
                toggleTab('1');
              }}
            >
              {t('common.table')}
            </NavLink>
          </NavItem>
          {messagesState.data.length > 0 && (
            <NavItem>
              <NavLink
                className={cx({ active: activeTab === '2' })}
                onClick={() => {
                  toggleTab('2');
                  handleSetZoomDelay(500);
                }}
              >
                {t('common.graph')}
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                <MessagesTableContainer getMessages={getMessages} messagesState={messagesState} />
              </Col>
            </Row>
          </TabPane>
          {messagesState.data.length > 0 && (
            <TabPane tabId="2">
              <Row>
                <Col>
                  <Graph messages={messagesState.data} zoomDelay={zoomDelay} />
                </Col>
              </Row>
            </TabPane>
          )}
        </TabContent>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  messagesState: state.questionnaireMessages.resources,
});

const mapDispatchToProps = {
  getApplication,
  getMessages,
  clearMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
