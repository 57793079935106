import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, FormGroup, Label } from 'reactstrap';
import { Formik, Form, FormikValues } from 'formik';

import { StateModel } from '../../../shared/models/default-state.model';

import { trainingDatasetSchema } from '../../../shared/schemas/validation.schema';

import Spinner from '../../../shared/components/Spinner';
import TextField from '../../../shared/components/form-inputs/TextField';
import Select from '../../../shared/components/form-inputs/Select';
import DragAndDrop from 'src/shared/components/form-inputs/DragAndDrop';
import { Extensions } from 'src/shared/models/common.model';

import styles from '../ImportTrainingData.module.scss';

interface Props {
  datasetImport: StateModel;
  handleSubmit: Function;
}

const IMPORT_METHODS = [
  {
    label: 'Replace',
    value: 'REPLACE',
  },
];

const INIT_CHARACTERS = {
  fieldDelimiter: ',',
  keywordDelimiter: ',',
  quote: '"',
  escape: '"',
};

const INIT_VALUES = {
  updateMethod: 'REPLACE',
  ...INIT_CHARACTERS,
};

const ImportTrainingDataForm: React.FC<Props> = props => {
  const { t } = useTranslation();

  const [file, setFile] = useState<any>();
  const [fileError, setFileError] = useState<any>();

  const confirmImport = (values: FormikValues) => {
    const { fieldDelimiter, keywordDelimiter, quote, escape, updateMethod } = values;

    if (!file) {
      setFileError('errors.noDatasetSelected');
      return;
    }

    const data = {
      file,
      fieldDelimiter,
      keywordDelimiter,
      quote,
      escape,
      updateMethod,
    };

    props.handleSubmit(data);
  };

  const { datasetImport } = props;

  return (
    <Formik
      initialValues={{
        ...INIT_VALUES,
      }}
      validationSchema={trainingDatasetSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        confirmImport(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Row>
            <Col lg="6" md="12">
              <fieldset className="border p-3">
                <legend className="col-form-label w-auto">
                  <strong>{t('dataset.importDetails')}</strong>
                </legend>
                <Row form className="mb-2">
                  <Col>
                    <FormGroup>
                      <DragAndDrop
                        acceptedExt={Extensions.CSV}
                        setFile={setFile}
                        file={file}
                        setFileError={setFileError}
                        fileError={fileError}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col>
                    <FormGroup>
                      <Label for="updateMethod">{t('dataset.method')}</Label>
                      <Select name="updateMethod" options={IMPORT_METHODS} />
                    </FormGroup>
                  </Col>
                </Row>
              </fieldset>
            </Col>

            <Col lg="6" md="12">
              <fieldset className="border p-3">
                <legend className="col-form-label w-auto">
                  <strong>{t('dataset.characters')}</strong>
                </legend>
                <Row form>
                  <Col lg="6">
                    <FormGroup>
                      <Label for="fieldDelimiter">{t('dataset.fieldDelimiter')}</Label>
                      <TextField name="fieldDelimiter" />
                    </FormGroup>
                  </Col>

                  <Col lg="6">
                    <FormGroup>
                      <Label for="keywordDelimiter">{t('dataset.keywordDelimiter')}</Label>
                      <TextField name="keywordDelimiter" />
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col lg="6">
                    <FormGroup>
                      <Label for="quote">{t('dataset.quote')}</Label>
                      <TextField name="quote" />
                    </FormGroup>
                  </Col>

                  <Col lg="6">
                    <FormGroup>
                      <Label for="escape">{t('dataset.escape')}</Label>
                      <TextField name="escape" />
                    </FormGroup>
                  </Col>
                </Row>
              </fieldset>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col className="d-flex align-items-center justify-content-end">
              {datasetImport.loaded && !datasetImport.error && (
                <span className={styles.importMessage}>
                  <i className="fas fa-check-circle" />
                  {t('dataset.successfulImportMessage')}
                </span>
              )}

              <Spinner loading={datasetImport.loading} className="mr-2" />

              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting || datasetImport.loading}
              >
                {t('dataset.import')}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default ImportTrainingDataForm;
