import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import TextField from 'src/shared/components/form-inputs/TextField';
import { OptionModel, ReactSelect, Switch } from '../../../../shared/components';
import { SelectModel } from '../../../../shared/models';
import { FormikValues, useFormikContext } from 'formik';
import { LOCATIONS_PER_PAGE_MAX, LOCATIONS_PER_PAGE_MIN } from '../../../../shared/constants/questionnaire.constans';

interface Props {
  isEditing?: boolean;
}

const LocationPagerForm: React.FC<Props> = ({ isEditing }) => {
  const { t } = useTranslation();
  const { values, setFieldValue }: FormikValues = useFormikContext();

  const { showAll, showSize } = values;
  const getSelectOptions = () => {
    const options: SelectModel[] = [];

    for (let i = LOCATIONS_PER_PAGE_MIN; i <= LOCATIONS_PER_PAGE_MAX; i += 1) {
      options.push({ label: i.toString(), value: i });
    }

    return options;
  };

  const selectOptions = useMemo(() => getSelectOptions(), []);

  return (
    <Fragment>
      {isEditing && (
      <FormGroup>
        <Switch
          name="showAll"
          variant="pill"
          color="info"
          label="questionnaire.showAllLocations"
          labelPosition="after"
        />
      </FormGroup>
      )}
      {isEditing && !showAll &&(
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="nextPageButton">{t('questionnaire.nextPageButtonText')}</Label>
              <TextField name="nextPageButton" />
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <Label for="showSize">{t('questionnaire.numberOfLocationsPerPage')}</Label>
              <ReactSelect
                name="showSize"
                options={selectOptions}
                value={{ label: showSize.toString(), value: showSize }}
                onChange={(option: OptionModel) =>
                  setFieldValue('showSize', (option as SelectModel).value)
                }
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default LocationPagerForm;
