import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Label, Button, CardBody } from 'reactstrap';
import { Formik, FormikHelpers, Form } from 'formik';

import { TextField, TextArea, Spinner, Switch } from 'src/shared/components';
import { EditorBase, FormGeneralEditorModel, FormModel } from 'src/shared/models';
import { formGeneralSchema } from 'src/shared/schemas/validation.schema';

interface GeneralProps extends EditorBase {
  form?: FormModel;
  handleFormSubmit: (
    values: FormGeneralEditorModel,
    { setSubmitting }: FormikHelpers<FormGeneralEditorModel>,
  ) => void;
}

const INIT_VALUES: FormGeneralEditorModel = {
  description: '',
  title: '',
  numberOfAttempts: 0,
  id: 0,
  slug: '',
  applicationId: 0,
  emailNotificationEnabled: false,
  emailNotificationTo: '',
  emailNotificationBody: '',
  emailNotificationSubject: '',
  reportVisibleContextEntries: '',
};

const GeneralEditor: FC<GeneralProps> = ({ edit, form, handleFormSubmit }) => {
  const { t } = useTranslation();
  const initialFormValues = useMemo(() => (edit && form ? form : INIT_VALUES), [edit, form]);

  return (
    <CardBody>
      <Formik<FormGeneralEditorModel>
        initialValues={initialFormValues}
        validationSchema={formGeneralSchema()}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <fieldset className="border p-3">
                <legend className="col-form-label w-auto font-weight-bold">
                  {t('common.generalInformations')}
                </legend>

                <Row>
                  <Col lg="6">
                    <FormGroup>
                      {/* 3 és 255 között */}
                      <Label for="title">{t('common.title')}</Label>
                      <TextField name="title" />
                    </FormGroup>
                    <FormGroup>
                      <Label for="numberOfAttempts">{t('common.numberOfAttempts')}</Label>
                      <TextField name="numberOfAttempts" />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <Label for="description">{t('common.description')}</Label>
                      <TextArea name="description" rows="5" />
                    </FormGroup>
                    {values.slug && (
                      <FormGroup>
                        <Label for="slug">{t('common.slug')}</Label>
                        <TextField name="slug" disabled />
                      </FormGroup>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="emailNotificationEnabled">{t('common.emailNotification')}</Label>
                      <div className="d-flex align-items-center flex-row">
                        {t('common.disabled')}
                        <Switch
                          name="emailNotificationEnabled"
                          className="ml-2 mr-2"
                          variant="pill"
                          color="primary"
                          size="lg"
                          checked={values.emailNotificationEnabled}
                        />
                        {t('common.enabled')}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                {values.emailNotificationEnabled && (
                  <>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="emailNotificationTo">{t('common.emailNotificationTo')}</Label>
                          <TextField name="emailNotificationTo" />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label for="emailNotificationSubject">
                            {t('common.emailNotificationSubject')}
                          </Label>
                          <TextField name="emailNotificationSubject" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="emailNotificationBody">
                            {t('common.emailNotificationBody')}
                          </Label>
                          <TextArea name="emailNotificationBody" rows="5" />
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                )}

                <Row>
                  <Col lg={8}>
                    <FormGroup>
                      <Label for="reportVisibleContextEntries">
                        {t('common.reportVisibleContextEntries')}
                      </Label>
                      <TextArea name="reportVisibleContextEntries" rows="5" />
                    </FormGroup>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                      className="mt-auto"
                    >
                      {isSubmitting && <Spinner loading className="mr-1" />}{' '}
                      {edit ? t('common.save') : t('common.create')}
                    </Button>
                  </Col>
                </Row>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </CardBody>
  );
};

export default GeneralEditor;
