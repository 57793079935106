import { Dispatch } from 'redux';
import { PageQueryParams, HelpdeskInstantAnswerModel } from 'src/shared/models';

import {
  deleteHelpdeskInstantAnswer as deleteHelpdeskInstantAnswerService,
  getHelpdeskInstantAnswers as getHelpdeskInstantAnswersService,
  createHelpdeskInstantAnswer as createHelpdeskInstantAnswerService,
  getHelpdeskInstantAnswer as getHelpdeskInstantAnswerService,
  updateHelpdeskInstantAnswer as updateHelpdeskInstantAnswerService,
} from 'src/redux/services/helpdesk-instant-answers.action.service';

export const GET_HELPDESK_INSTANT_ANSWERS = 'GET_HELPDESK_INSTANT_ANSWERS';
export const GET_HELPDESK_INSTANT_ANSWERS_SUCCESS = 'GET_HELPDESK_INSTANT_ANSWERS_SUCCESS';
export const GET_HELPDESK_INSTANT_ANSWERS_ERROR = 'GET_HELPDESK_INSTANT_ANSWERS_ERROR';

export const DELETE_HELPDESK_INSTANT_ANSWER = 'DELETE_HELPDESK_INSTANT_ANSWER';
export const DELETE_HELPDESK_INSTANT_ANSWER_REQUEST = 'DELETE_HELPDESK_INSTANT_ANSWER_REQUEST';
export const DELETE_HELPDESK_INSTANT_ANSWER_SUCCESS = 'DELETE_HELPDESK_INSTANT_ANSWER_SUCCESS';
export const DELETE_HELPDESK_INSTANT_ANSWER_ERROR = 'DELETE_HELPDESK_INSTANT_ANSWER_ERROR';

export const CREATE_HELPDESK_INSTANT_ANSWER = 'helpdeskGroups/CREATE_HELPDESK_INSTANT_ANSWER';
export const CREATE_HELPDESK_INSTANT_ANSWER_SUCCESS =
  'helpdeskGroups/CREATE_HELPDESK_INSTANT_ANSWER_SUCCESS';
export const CREATE_HELPDESK_INSTANT_ANSWER_ERROR =
  'helpdeskGroups/CREATE_HELPDESK_INSTANT_ANSWER_ERROR';
export const READ_HELPDESK_INSTANT_ANSWER = 'helpdeskGroups/READ_HELPDESK_INSTANT_ANSWER';
export const READ_HELPDESK_INSTANT_ANSWER_SUCCESS =
  'helpdeskGroups/READ_HELPDESK_INSTANT_ANSWER_SUCCESS';
export const READ_HELPDESK_INSTANT_ANSWER_ERROR =
  'helpdeskGroups/READ_HELPDESK_INSTANT_ANSWER_ERROR';
export const UPDATE_HELPDESK_INSTANT_ANSWER = 'helpdeskGroups/UPDATE_HELPDESK_INSTANT_ANSWER';
export const UPDATE_HELPDESK_INSTANT_ANSWER_SUCCESS =
  'helpdeskGroups/UPDATE_HELPDESK_INSTANT_ANSWER_SUCCESS';
export const UPDATE_HELPDESK_INSTANT_ANSWER_ERROR =
  'helpdeskGroups/UPDATE_HELPDESK_INSTANT_ANSWER_ERROR';
export const REVERT_HELPDESK_INSTANT_ANSWER = 'helpdeskGroups/CLEAR_HELPDESK_INSTANT_ANSWER';

export const getHelpdeskInstantAnswers = (appId: string, queryParams: PageQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_HELPDESK_INSTANT_ANSWERS,
    });

    try {
      dispatch({
        type: GET_HELPDESK_INSTANT_ANSWERS_SUCCESS,
        payload: await getHelpdeskInstantAnswersService(appId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_HELPDESK_INSTANT_ANSWERS_ERROR,
        error,
      });
    }
  };
};

export const deleteHelpdeskInstantAnswer = (appId: string, helpdeskGroupId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_HELPDESK_INSTANT_ANSWER,
    });

    try {
      await deleteHelpdeskInstantAnswerService(appId, helpdeskGroupId.toString());
      dispatch({
        type: DELETE_HELPDESK_INSTANT_ANSWER_SUCCESS,
        payload: { helpdeskGroupId },
      });
    } catch (error) {
      dispatch({
        type: DELETE_HELPDESK_INSTANT_ANSWER_ERROR,
        error,
      });
    }
  };
};

export const createHelpdeskInstantAnswer = (
  appId: string,
  helpdeskGroupData: HelpdeskInstantAnswerModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_HELPDESK_INSTANT_ANSWER,
    });

    const response = await createHelpdeskInstantAnswerService(appId, helpdeskGroupData);

    try {
      dispatch({
        type: CREATE_HELPDESK_INSTANT_ANSWER_SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: CREATE_HELPDESK_INSTANT_ANSWER_ERROR,
        error,
      });
    }
  };
};

export const getHelpdeskInstantAnswer = (appId: string, helpdeskGroupId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: READ_HELPDESK_INSTANT_ANSWER,
    });

    try {
      dispatch({
        type: READ_HELPDESK_INSTANT_ANSWER_SUCCESS,
        payload: await getHelpdeskInstantAnswerService(appId, helpdeskGroupId),
      });
    } catch (error) {
      dispatch({
        type: READ_HELPDESK_INSTANT_ANSWER_ERROR,
        error,
      });
    }
  };
};

export const updateHelpdeskInstantAnswer = (
  appId: string,
  helpdeskGroupId: number,
  data: HelpdeskInstantAnswerModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_HELPDESK_INSTANT_ANSWER,
    });

    try {
      dispatch({
        type: UPDATE_HELPDESK_INSTANT_ANSWER_SUCCESS,
        payload: await updateHelpdeskInstantAnswerService(appId, helpdeskGroupId, data),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_HELPDESK_INSTANT_ANSWER_ERROR,
        error,
      });
    }
  };
};

export const revertHelpdeskInstantAnswer = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REVERT_HELPDESK_INSTANT_ANSWER,
    });
  };
};
