import React from 'react';
// @ts-ignore
import parse from 'parse-address';

import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';
import styles from './scss/LiveChat.module.scss';

interface Props {
  message: ConversationHistoryModel;
}

interface MapSettings {
  zoom: string;
  size: string;
  scale: string;
  markers: string;
  key: string;
  [key: string]: string;
}

const MapTemplate: React.FC<Props> = (props: Props) => {
  const { message } = props;
  const template = JSON.parse(message.messageAsText);
  const { livechatMapOptions } = template;
  const { address, zoom } = livechatMapOptions;

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

  const staticMapSettings: MapSettings = {
    zoom,
    size: '250x250',
    scale: '2',
    markers: address,
    key: apiKey,
  };

  const params = new URLSearchParams(staticMapSettings).toString();
  const parsedAddress = parse.parseLocation(address);
  const { zip, city, state, street, number, prefix, type } = parsedAddress;

  const mapUrl = `https://google.com/maps/?q=${address}`;
  const staicMapUrl = `https://maps.googleapis.com/maps/api/staticmap?${params}`;

  return (
    <div className={styles.mapTemplate}>
      <a href={mapUrl} target="_blank" rel="noopener noreferrer">
        <img src={staicMapUrl} alt="Google Maps" />
        <div className={styles.addressContainer}>
          <p>{`${number} ${prefix || ''} ${street} ${type}`}</p>
          <p>{`${city}, ${state} ${zip}`}</p>
        </div>
      </a>
    </div>
  );
};

export default MapTemplate;
