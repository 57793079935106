import { del, get, patch, post } from 'src/shared/services/http.service';
import { QueryParams } from 'src/shared/models';
import { MessageToAdminModel } from 'src/shared/models/message-to-admin.model';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';

const baseUrl = (applicationId: string) => `/applications/${applicationId}/complaint/complaints`;

export const getMessageToAdmins = async (
  applicationId: string,
  params: QueryParams,
  grouping: DynamicGroupByModel,
) => {
  return post(baseUrl(applicationId), { params, data: { filters: grouping } });
};

export const getMessageToAdmin = async (applicationId: string, messagesToAdminId: string) => {
  return get(`${baseUrl(applicationId)}/${messagesToAdminId}`);
};

export const updateMessageToAdmin = async (
  applicationId: string,
  messagesToAdminId: string,
  data: MessageToAdminModel,
) => {
  return patch(`${baseUrl(applicationId)}/${messagesToAdminId}`, { data });
};

export const deleteMessageToAdmin = async (applicationId: string, messagesToAdminId: string) => {
  return del(`${baseUrl(applicationId)}/${messagesToAdminId}`);
};

export const getComplaints = async (applicationId: string) => {
  return get(`${baseUrl(applicationId)}/types`);
};
