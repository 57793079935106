import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { List } from 'src/shared/components';
import { UserProfileSurveyQuestionAnswers } from 'src/shared/models';

interface Props {
  isOpen: boolean;
  setModalState: Function;
  title: string;
  data: UserProfileSurveyQuestionAnswers[];
}

const FreeTextAnswersModal: React.FC<Props> = ({ isOpen, setModalState, title, data }) => {
  const { t } = useTranslation();

  const getItemId = (answer: UserProfileSurveyQuestionAnswers) => {
    return moment(answer?.answeredAt).unix();
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        {t('surveys.freeTextAnswers')} - {title}
      </ModalHeader>
      <ModalBody>
        <List
          data={data.map(answer => ({ id: getItemId(answer), ...answer }))}
          totalElements={data.length}
          loaded
          fetchData={() => {}}
          hidePager
          noDataLabel={t('surveys.noFreetextAnswers')}
          fields={[
            {
              key: 'text',
              label: t('common.text'),
            },
            {
              key: 'answeredAt',
              date: true,
              label: t('surveys.answeredAt'),
            },
          ]}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => setModalState(false)}>
          {t('common.close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FreeTextAnswersModal);
