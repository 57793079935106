import {
  PageQueryParams,
  FormModel,
  FormQuestionModel,
  GetFormDetailedReportResponse,
} from 'src/shared/models';
import { post, patch, del, get } from 'src/shared/services/http.service';

const baseUrl = (applicationId: string) => `/applications/${applicationId}/form/forms`;

export const getForms = async (applicationId: string, params: PageQueryParams) => {
  return get(baseUrl(applicationId), { params });
};

export const getForm = async (applicationId: string, formId: string) => {
  return get(`${baseUrl(applicationId)}/${formId}`);
};

export const createForm = async (applicationId: string, data: FormModel) => {
  return post(baseUrl(applicationId), { data });
};

export const updateForm = async (applicationId: string, formId: number, data: FormModel) => {
  return patch(`${baseUrl(applicationId)}/${formId}`, { data });
};

export const deleteForm = async (applicationId: string, formId: number) => {
  return del(`${baseUrl(applicationId)}/${formId}`);
};

export const createQuestion = async (
  applicationId: string,
  formId: string,
  data: FormQuestionModel,
) => {
  return post(`${baseUrl(applicationId)}/${formId}/elements`, { data });
};

export const updateQuestion = async (
  applicationId: string,
  formId: string,
  questionId: string,
  data: FormQuestionModel,
) => {
  return patch(`${baseUrl(applicationId)}/${formId}/elements/${questionId}`, { data });
};

export const deleteQuestion = async (applicationId: string, formId: string, questionId: string) => {
  return del(`${baseUrl(applicationId)}/${formId}/elements/${questionId}`);
};

export const sortQuestions = async (applicationId: string, formId: string, ids: number[]) => {
  return patch(`${baseUrl(applicationId)}/${formId}/elements/sort`, { data: ids });
};

export const getDetailedFormReport = async (
  applicationId: string,
  formId: string,
  params: PageQueryParams,
): Promise<GetFormDetailedReportResponse> => {
  return get(`${baseUrl(applicationId)}/${formId}/reports/detailed`, { params });
};

export const exportDetailedReport = async (
  applicationId: string,
  formId: string,
  params: PageQueryParams,
) => {
  return get(`${baseUrl(applicationId)}/${formId}/reports/detailed/export/xlsx`, {
    params,
    requestConfig: { responseType: 'blob' },
  });
};

export const getValidationTypes = async () => {
  return get('/expression');
};
