import { get, post, patch, del } from '../../shared/services/http.service';
import { EmployeeModel, PageQueryParams, TagCategoryModel } from 'src/shared/models';

export const importEmployeeDataset = async (
  applicationId: string,
  url: string,
  importConfigurationId: string,
) => {
  return post(`/hrops/applications/${applicationId}/employee/employees/import/excel`, {
    data: { url, importConfigurationId },
  });
};

export const getTagCategory = async (applicationId: string, categoryId: string) => {
  return get(`/hrops/applications/${applicationId}/employee/categories/${categoryId}`);
};

export const getTagCategories = async (applicationId: string) => {
  return get(`/hrops/applications/${applicationId}/employee/categories`);
};

export const createTagCategory = async (applicationId: string, data: TagCategoryModel) => {
  return post(`/hrops/applications/${applicationId}/employee/categories`, { data });
};

export const updateTagCategory = async (
  applicationId: string,
  categoryId: string,
  data: TagCategoryModel,
) => {
  return patch(`/hrops/applications/${applicationId}/employee/categories/${categoryId}`, { data });
};

export const deleteTagCategory = async (applicationId: string, categoryId: string) => {
  return del(`/hrops/applications/${applicationId}/employee/categories/${categoryId}`);
};

export const getCategoryTags = async (applicationId: string, categoryId: string) => {
  return get(`/hrops/applications/${applicationId}/employee/categories/${categoryId}/tags`);
};

export const getTag = async (applicationId: string, categoryId: string, tagId: string) => {
  return get(
    `/hrops/applications/${applicationId}/employee/categories/${categoryId}/tags/${tagId}`,
  );
};

export const updateTag = async (
  applicationId: string,
  categoryId: string,
  tagId: string,
  name: string,
) => {
  return patch(
    `/hrops/applications/${applicationId}/employee/categories/${categoryId}/tags/${tagId}`,
    { data: { name } },
  );
};

export const deleteTag = async (applicationId: string, categoryId: string, tagId: string) => {
  return del(
    `/hrops/applications/${applicationId}/employee/categories/${categoryId}/tags/${tagId}`,
  );
};

export const createTag = async (applicationId: string, categoryId: string, name: string) => {
  return post(`/hrops/applications/${applicationId}/employee/categories/${categoryId}/tags`, {
    data: { name },
  });
};

export const addTagToEmployee = async (
  applicationId: string,
  employeeId: string,
  tagIds: string[],
) => {
  return post(`/hrops/applications/${applicationId}/employee/employees/${employeeId}/tags/assign`, {
    data: tagIds,
  });
};

export const removeEmployeeTag = async (
  applicationId: string,
  employeeId: string,
  tagId: string,
) => {
  return del(`/hrops/applications/${applicationId}/employee/employees/${employeeId}/tags/${tagId}`);
};

export const getEmployees = async (applicationId: string, params: object) => {
  return get(`/hrops/applications/${applicationId}/employee/employees`, { params });
};

export const getEmployee = async (applicationId: string, employeeId: string) => {
  return get(`/hrops/applications/${applicationId}/employee/employees/${employeeId}`);
};

export const createEmployee = async (applicationId: string, employeeData: EmployeeModel) => {
  return post(`/hrops/applications/${applicationId}/employee/employees`, { data: employeeData });
};

export const updateEmployee = async (
  applicationId: string,
  employeeId: string,
  employeeData: EmployeeModel,
) => {
  return patch(`/hrops/applications/${applicationId}/employee/employees/${employeeId}`, {
    data: employeeData,
  });
};

export const deleteEmployee = async (applicationId: string, employeeId: string) => {
  return del(`/hrops/applications/${applicationId}/employee/employees/${employeeId}`);
};

export const getDynamicEmployees = async (applicationId: string, params: PageQueryParams) => {
  return get(`/applications/${applicationId}/import/imports?type=IMPORT_EMPLOYEE`, { params });
};
