import React, { useEffect } from 'react';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert, Card, CardBody } from 'reactstrap';
import saveFile from 'src/shared/utils/file-saver.util';

import InterviewAttendeesHeader from './components/InterviewAttendeesHeader';
import InterviewAttendeesTable from './components/InterviewAttendeesTable';
import { getInterview } from 'src/redux/actions/interviews.action';
import { StateModel } from 'src/shared/models/default-state.model';
import Spinner from 'src/shared/components/Spinner';
import ServerError from 'src/shared/components/ServerError';
import { useTranslation } from 'react-i18next';
import { fileNames } from 'src/shared/settings';
import { exportInterviewAttendees } from 'src/redux/services/interview-attendees.service';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  interview: StateModel;
  getInterview: Function;
}

const InterviewAttendees: React.FC<Props> = ({
  application,
  getApplication,
  interview,
  getInterview,
}) => {
  const { t } = useTranslation();
  const { appId, eventId } = useParams();

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    getInterview(appId, eventId);
  }, [appId, eventId, getInterview]);

  const handleExport = async () => {
    const res = await exportInterviewAttendees(appId, eventId);
    saveFile(res, fileNames.INTERVIEWS_ATTENDEES_EXPORT, 'csv');
  };

  return (
    <div className="animated fadeIn">
      {interview.loading && (
        <div className="d-flex justify-content-center">
          <Spinner size="2x" loading={interview.loading} />
        </div>
      )}

      {interview.loaded && !interview.data && (
        <Alert className="text-center" color="info">
          {t('interviews.noInterviewsFound')}
        </Alert>
      )}

      {interview.error && <ServerError />}

      {interview.loaded && interview.data && (
        <InterviewAttendeesHeader interview={interview} handleExport={handleExport} />
      )}

      <Card>
        <CardBody>
          <InterviewAttendeesTable />
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  interview: state.interviews.interview,
});

const mapDispatchToProps = {
  getApplication,
  getInterview,
};

export default connect(mapStateToProps, mapDispatchToProps)(InterviewAttendees);
