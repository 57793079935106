import React, { Component, Fragment } from 'react';
import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';

import styles from './scss/Facebook.module.scss';
import placeholder from '../../../../../../../../assets/img/other/placeholder_150x150.svg';

interface Props {
  message: ConversationHistoryModel;
}

interface State {
  outputHtml: any;
}

class GenericTemplate extends Component<Props> {
  state: State = {
    outputHtml: [],
  };

  componentDidMount() {
    this.renderTemplate(this.props.message);
  }

  renderTemplate(msg: any) {
    const outputHtml: any = [];
    let currentTemplate = null;
    let genericElements = null;

    try {
      currentTemplate = JSON.parse(msg.messageAsText);
      // eslint-disable-next-line
      genericElements = currentTemplate.genericElements;
    } catch (error) {
      this.setState({
        outputHtml: [],
      });
    }

    if (currentTemplate) {
      genericElements.map((el: any) => {
        return outputHtml.push(
          <div className={styles.genericTemplate} key={el.title}>
            <div className={styles.header}>
              <div className={styles.texts}>
                <p>{el.title}</p>
                <p>{el.subTitle}</p>
              </div>
            </div>
            <div className={styles.content}>
              <img src={el.imageUrl || placeholder} alt="Content" />
              <div className={styles.button}>
                <a href={el.fileUrl} target="_blank" rel="noopener noreferrer">
                  <button type="button">{el.buttonTitle}</button>
                </a>
              </div>
            </div>
          </div>,
        );
      });

      this.setState({
        outputHtml,
      });
    }
  }

  render() {
    const { outputHtml } = this.state;

    return (
      <div className={styles.genericTemplatesContainer}>
        <Fragment>{outputHtml}</Fragment>
      </div>
    );
  }
}

export default GenericTemplate;
