import { StateModel } from '../../shared/models/default-state.model';
import {
  GET_COMPLAINTS_REQUEST,
  GET_COMPLAINTS_ERROR,
  GET_COMPLAINTS_SUCCESS,
  EXPORT_COMPLAINTS_REQUEST,
  EXPORT_COMPLAINTS_SUCCESS,
  EXPORT_COMPLAINTS_ERROR,
  CLEAR_COMPLAINTS_REQUEST,
  CLEAR_COMPLAINTS_SUCCESS,
  CLEAR_COMPLAINTS_ERROR,
} from '../actions/complaints.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {
    content: [],
  },
};

export const complaintsReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case GET_COMPLAINTS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case GET_COMPLAINTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_COMPLAINTS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const complaintsExportReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case EXPORT_COMPLAINTS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case EXPORT_COMPLAINTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case EXPORT_COMPLAINTS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const complaintsClearReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case CLEAR_COMPLAINTS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case CLEAR_COMPLAINTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case CLEAR_COMPLAINTS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
