import { OptionListAnswerConfiguration, TextAnswerConfiguration } from '.';
import { TranslationListItem } from './content-translations-model';

export interface TranslationBase {
  languageCode: string;
}

export interface GetEventTranslationsResponse extends Array<TranslatableEvent> {}

export interface TranslatableEvent {
  id: number;
  applicationId: number;
  title: string;
  description: string;
  questions: TranslatableQuestion[];
  dates: TranslatableDate[];
  translations: EventTranslation[];
}

export interface EventTranslation extends TranslationBase {
  eventId: number;
  title: string;
  description: string;
}

export interface TranslatableQuestion {
  id: number;
  applicationId: number;
  eventId: number;
  title: string;
  order: number;
  answerConfiguration: TextAnswerConfiguration | OptionListAnswerConfiguration; //LF: TODO
  translations: EventQuestionTranslation[];
}

export interface EventQuestionTranslation extends TranslationBase {
  eventQuestionId: number;
  text: string;
  answerConfiguration: TextAnswerConfiguration | OptionListAnswerConfiguration;
}

export interface TranslatableDate {
  id: number;
  applicationId: number;
  eventId: number;
  startDateTime: string;
  endDateTime: string;
  title: string;
  translations: any[];
}

export interface EventDateTranslation extends TranslationBase {
  eventDateId: number;
  title: string;
}

export interface CreateEventTranslationRequest {
  languageCode: string;
  description?: string;
}

export interface EditEventTranslationRequest {
  languageCode: string;
  description: string;
}

export interface ContentTranslationEventListModel extends Array<ContentTranslationEventListItem> {}

export interface ContentTranslationEventListItem extends TranslationListItem {
  eventId?: number;
  eventQuestionId?: number;
  eventQuestionAnswerId?: string;
  eventDateId?: number;
}

export enum EventTranslationLevel {
  Base,
  Question,
  Date,
  QuestionAnswer,
}
