import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Card, CardBody, Alert, Badge } from 'reactstrap';

import { TalentPoolCandidateWithAnswersModel } from '../../../shared/models/talent-pools.model';
import { QueryParams } from '../../../shared/models/query-params.model';

import { getTalentPool, TalentPoolModels } from '../../../redux/actions/talent-pools.action';
import {
  getTalentPoolCandidatesAnswers,
  markTalentPoolCandidate,
} from '../../../redux/actions/talent-pools-candidates.action';

import TalentPoolCandidatesTable from './TalentPoolCandidatesTable';

interface Props extends RouteComponentProps<{ id: string }>, WithTranslation {
  getTalentPool: Function;
  talentPool: TalentPoolModels;
  getTalentPoolCandidatesAnswers: Function;
  numberOfCandidates: number;
  candidatesWithAnswers: TalentPoolCandidateWithAnswersModel[];
  candidatesWithAnswersLoading: boolean;
  candidatesWithAnswersLoaded: boolean;
  markTalentPoolCandidate: Function;
}

class TalentPoolDetails extends Component<Props> {
  componentDidMount = () => {
    this.getTalentPool();
  };

  getTalentPool = () => {
    const { id } = this.props.match.params;
    this.props.getTalentPool(id);
  };

  getCandidatesWithAnswers = (queryParams: QueryParams) => {
    const { id } = this.props.match.params;
    this.props.getTalentPoolCandidatesAnswers(id, queryParams);
  };

  markCandidate = (talentPoolId: number, candidateId: number, marked: boolean) => {
    this.props.markTalentPoolCandidate(talentPoolId, candidateId, { marked });
  };

  render() {
    const {
      talentPool,
      numberOfCandidates,
      candidatesWithAnswers,
      candidatesWithAnswersLoaded,
      t,
    } = this.props;

    return (
      <Fragment>
        <h5 className="animated fadeIn mb-4">
          {talentPool.name}
          <Badge className="ml-2 text-uppercase" color="secondary">
            {t('talentPools.numberOfCandidates', {
              count: numberOfCandidates,
            })}
          </Badge>
        </h5>
        <Card className="animated fadeIn">
          <CardBody>
            {candidatesWithAnswersLoaded && !candidatesWithAnswers && (
              <Alert color="info">{t('talentPools.noCandidates')}</Alert>
            )}

            <TalentPoolCandidatesTable
              candidates={candidatesWithAnswers}
              totalCandidates={numberOfCandidates}
              getCandidatesWithAnswers={this.getCandidatesWithAnswers}
              markCandidate={this.markCandidate}
            />
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  talentPool: state.talentPool.data,
  talentPoolLoading: state.talentPool.loading,
  talentPoolLoaded: state.talentPool.loaded,
  numberOfCandidates: state.talentPoolCandidatesAnswers.data.totalElements,
  candidatesWithAnswers: state.talentPoolCandidatesAnswers.data.content,
  candidatesWithAnswersLoading: state.talentPoolCandidatesAnswers.loading,
  candidatesWithAnswersLoaded: state.talentPoolCandidatesAnswers.loaded,
});

const mapDispatchToProps = {
  getTalentPool,
  getTalentPoolCandidatesAnswers,
  markTalentPoolCandidate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(TalentPoolDetails)));
