import React from 'react';
import capitalize from 'lodash/capitalize';

import { PlatformModel } from 'src/shared/models';
import liveChatLogo from '../../assets/img/brand/chatboss-3.svg';

export const getActivePlatformObjects = (platforms: string[]) => {
  const activePlatforms: PlatformModel[] = [];

  platforms.sort().map((platform: string) => {
    switch (platform.toLowerCase()) {
      case 'facebook':
        return activePlatforms.push({
          label: 'Facebook Messenger',
          value: 'Facebook',
        });
      case 'hangoutschat':
        return activePlatforms.push({
          label: 'Google Hangouts',
          value: 'HangoutsChat',
        });
      case 'livechat':
        return activePlatforms.push({
          label: 'LiveChat',
          value: 'Livechat',
        });
      case 'viber':
        return activePlatforms.push({
          label: 'Viber',
          value: 'Viber',
        });
      case 'email':
        return activePlatforms.push({
          label: capitalize(`${platform.substr(0, 1)}-${platform.substr(1)}`),
          value: platform,
        });
      case 'e-mail':
        return activePlatforms.push({
          label: capitalize(platform),
          value: platform.replace('-', ''),
        });
      default:
        return null;
    }
  });

  return activePlatforms;
};

export const renderPlatformLogo = (platform: string) => {
  if (!platform) return null;

  switch (platform.toLowerCase()) {
    case 'facebook':
      return (
        <div className="platform-logo messenger">
          <i className="fab fa-facebook-messenger" />
        </div>
      );
    case 'viber':
      return (
        <div className="platform-logo viber">
          <i className="fab fa-viber" />
        </div>
      );
    case 'hangoutschat':
      return (
        <div className="platform-logo hangouts">
          <i className="fab fa-google" />
        </div>
      );
    case 'livechat':
      return <img src={liveChatLogo} className="platform-logo livechat" alt="LiveChat Logo" />;
    default:
      return null;
  }
};
