import React, { FC } from 'react';
import { Field, Formik, FormikValues } from 'formik';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { DateRange, TextField, ExportButton } from 'src/shared/components';
import { SalaryAdvanceFilters } from 'src/shared/models';
import SEARCH_TIMER from 'src/shared/constants/filter.constants';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';
import DynamicGroupBy from 'src/shared/components/dynamic-group-by/DynamicGroupBy';
import { configurationConstants } from 'src/shared/constants/configuration.constants';

interface SalaryAdvanceFilterProps {
  filterChanged: Function;
  filter: SalaryAdvanceFilters;
  export?: Function;
  isAnyData: boolean;
  handleDynamicGroupByChange: (grouping: DynamicGroupByModel) => Promise<void>;
}

const SalaryAdvanceFilter: FC<SalaryAdvanceFilterProps> = props => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const updateFilter = (values: FormikValues) => {
    props.filterChanged({ filters: values });
  };

  const debouncedCallApi = _.debounce((values: FormikValues) => updateFilter(values), SEARCH_TIMER);

  const handleFilterChange = (values: FormikValues) => {
    debouncedCallApi(values);
    return {};
  };

  return (
    <Formik
      validateOnBlur={false}
      initialValues={props.filter.filters}
      onSubmit={() => {}}
      validate={handleFilterChange}
      validateOnChange
      enableReinitialize
    >
      {({ setFieldValue, values, setValues }) => (
        <Row>
          <Col lg="4">
            <FormGroup>
              <Label>{t('common.date')}</Label>
              <Field
                startDate={values.startDate}
                endDate={values.endDate}
                component={DateRange}
                isOutsideRange={() => false}
                handleDateChange={(startDate: moment.Moment, endDate: moment.Moment) => {
                  if (!startDate || !endDate) return null;
                  const from = moment(startDate).utc().startOf('day').toISOString();
                  const to = moment(endDate).utc().endOf('day').toISOString();

                  setValues(
                    {
                      ...values,
                      startDate: from,
                      endDate: to,
                    },
                    true,
                  );
                }}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{t('common.lastName')}</Label>
              <TextField name="last_name" />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{t('common.firstName')}</Label>
              <TextField name="first_name" />
            </FormGroup>
          </Col>
          <Col lg="5">
            <FormGroup>
              <DynamicGroupBy
                appId={appId}
                isMulti
                handleDynamicGroupByChange={props.handleDynamicGroupByChange}
                configurationNameSpace={configurationConstants.hropsSalaryAdvanceFilter}
              />
            </FormGroup>
          </Col>
          {props.export !== undefined && (
            <Col className="d-flex justify-content-end">
              <FormGroup>
                <Label></Label>
                <ExportButton
                  className="ml-2"
                  disabled={!props.isAnyData}
                  onClick={() => props.export!()}
                  exportTooltipLabel={t('common.exportToExcelForPeriod')}
                  tooltipProps={{ target: 'exportButton', placement: 'left' }}
                />
              </FormGroup>
            </Col>
          )}
        </Row>
      )}
    </Formik>
  );
};

export default SalaryAdvanceFilter;
