import {
  CreateEventTranslationRequest,
  GetEventTranslationsResponse,
} from 'src/shared/models/content-translations-event.model';
import { del, get, patch, post } from 'src/shared/services/http.service';

const baseUrl = (applicationId: string) => `/applications/${applicationId}/translation/events`;

//Event methods
export const getEventTranslations = async (
  applicationId: string,
): Promise<GetEventTranslationsResponse> => {
  return get(baseUrl(applicationId));
};

export const createEventTranslation = async (
  applicationId: string,
  eventId: number,
  request: CreateEventTranslationRequest,
) => {
  return post(`${baseUrl(applicationId)}/${eventId}`, { data: request });
};

export const editEventTranslation = async (
  applicationId: string,
  eventId: number,
  languageCode: string,
  request: {},
) => {
  return patch(`${baseUrl(applicationId)}/${eventId}/${languageCode}`, { data: request });
};

export const deleteEventTranslation = async (
  applicationId: string,
  eventId: number,
  languageCode: string,
  request: {},
) => {
  return del(`${baseUrl(applicationId)}/${eventId}/${languageCode}`, { data: request });
};

//Event dates methods
export const createEventDateTranslation = async (
  applicationId: string,
  eventId: number,
  eventDateId: number,
  request: {},
) => {
  return post(`${baseUrl(applicationId)}/${eventId}/dates/${eventDateId}`, { data: request });
};

export const editEventDateTranslation = async (
  applicationId: string,
  eventId: number,
  eventDateId: number,
  languageCode: string,
  request: {},
) => {
  return patch(`${baseUrl(applicationId)}/${eventId}/dates/${eventDateId}/${languageCode}`, {
    data: request,
  });
};

export const deleteEventDateTranslation = async (
  applicationId: string,
  eventId: number,
  eventDateId: number,
  languageCode: string,
  request: {},
) => {
  return del(`${baseUrl(applicationId)}/${eventId}/dates/${eventDateId}/${languageCode}`, {
    data: request,
  });
};

//Event question methods
export const createEventQuestionTranslation = async (
  applicationId: string,
  eventId: number,
  eventQuestionId: number,
  request: {},
) => {
  return post(`${baseUrl(applicationId)}/${eventId}/questions/${eventQuestionId}`, {
    data: request,
  });
};

export const editEventQuestionTranslation = async (
  applicationId: string,
  eventId: number,
  eventQuestionId: number,
  languageCode: string,
  request: {},
) => {
  return patch(
    `${baseUrl(applicationId)}/${eventId}/questions/${eventQuestionId}/${languageCode}`,
    {
      data: request,
    },
  );
};

export const deleteEventQuestionTranslation = async (
  applicationId: string,
  eventId: number,
  eventQuestionId: number,
  languageCode: string,
  request: {},
) => {
  return del(`${baseUrl(applicationId)}/${eventId}/questions/${eventQuestionId}/${languageCode}`, {
    data: request,
  });
};
