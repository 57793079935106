import React, { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Label, FormGroup } from 'reactstrap';
import { Field, FormikValues, useFormikContext } from 'formik';

import { PositionStatus } from '../../../../../shared/models/positions.model';

import TextField from '../../../../../shared/components/form-inputs/TextField';
import TextArea from '../../../../../shared/components/form-inputs/TextArea';
import DateRangeFormik from '../../../../../shared/components/date-pickers/DateRangeFormik';
import PositionStatusDropdown from '../../../components/PositionStatusDropdown';
import { isPositionExpired, renderExpiredBadge } from '../../../../../shared/utils/positions.util';
import moment from 'moment';

interface Props {
  positionId: number | undefined;
  handleSubmit: Function;
}

const PositionBasicInformationsForm: React.FC<Props> = ({ handleSubmit, positionId }) => {
  const { t } = useTranslation();
  const { setFieldValue, values }: FormikValues = useFormikContext();

  const onNameInputBlur = () => {
    const { name, startDate, endDate } = values;
    if (!positionId && name && startDate && endDate) handleSubmit(values);
  };

  const handleStatusChange = (status: PositionStatus) => {
    setFieldValue('status', status);
  };

  return (
    <Fragment>
      <fieldset className="border p-3">
        <legend className="col-form-label w-auto font-weight-bold">
          {t('common.generalInformations')}
        </legend>

        <Row className="mb-3" form>
          <Col className="d-flex flex-row">
            <PositionStatusDropdown
              positionId={positionId!}
              status={values.status}
              disabled={!positionId}
              handleOnItemClick={handleStatusChange}
            />

            {isPositionExpired(moment(values.endDate).toISOString()) && (
              <span className="ml-2">{renderExpiredBadge()}</span>
            )}
          </Col>
        </Row>

        <Row form>
          <Col lg="6">
            <FormGroup>
              <Label for="name">{t('common.name')}</Label>
              <TextField name="name" onBlur={() => onNameInputBlur()} />
            </FormGroup>

            <FormGroup>
              <Label for="activeDates">{t('common.startAndExpirationDates')}</Label>
              <Field component={DateRangeFormik} id="activeDates" name="activeDates" />
            </FormGroup>
          </Col>

          <Col lg="6">
            <FormGroup>
              <Label for="description">{t('common.description')}</Label>
              <TextArea name="description" rows="5" />
            </FormGroup>
          </Col>
        </Row>
      </fieldset>
    </Fragment>
  );
};

export default memo(PositionBasicInformationsForm);
