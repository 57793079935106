import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  getEmployees,
  getTagCategories,
  deleteEmployee,
  addTagToEmployee,
  removeEmployeeTag,
} from 'src/redux/actions/employee.action';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import {
  StateModel,
  EmployeeModel,
  Operations,
  ListFieldModel,
  EmployeeFilters,
  QueryParams,
  PageQueryParams,
} from 'src/shared/models';
import { EmployeeTags, List } from 'src/shared/components';
import EmployeeFilter from './EmployeeFilter';
import styles from '../../Employee.module.scss';
import { Button } from 'reactstrap';
import SendMessageModal from './SendMessageModal';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  numberOfEmployees: number;
  getTagCategories: Function;
  getEmployees: Function;
  employees: StateModel;
  deleteEmployee: Function;
  deleteEmployeeState: StateModel;
  addTagToEmployee: Function;
  addTagToEmployeeState: StateModel;
  removeEmployeeTag: Function;
  removeEmployeeTagState: StateModel;
  tagCategories: StateModel;
  refresh: number;
}

const EmployeesTable: React.FC<Props> = ({
  application,
  getApplication,
  numberOfEmployees,
  getTagCategories,
  getEmployees,
  employees,
  deleteEmployee,
  deleteEmployeeState,
  addTagToEmployee,
  addTagToEmployeeState,
  removeEmployeeTag,
  removeEmployeeTagState,
  tagCategories,
  refresh,
}) => {
  const { appId } = useParams<{ appId: string; }>();
  const { t } = useTranslation();
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [isSendMessageModalOpen, setIsSendMessageModalOpen] = useState(false);
  const [selectedUserProfileId, setSelectedUserProfileId] = useState("");

  // TODO: put back create, update after funtionality defined/fixed in be
  // const [isEmployeeModalOpen, setEmployeeModalOpen] = useState<boolean>(false);
  // const [selectedEmployee, selectEmployee] = useState<EmployeeModel | undefined>(undefined);
  // const [extraEmployeeAttribues, setExtraEmployeeAttribues] = useState<EmployeeAttributeModel[]>(
  //   [],
  // );
  // const [eventType, setEventType] = useState<string | undefined>(undefined);
  const [queryParams, setQueryParams] = useState<PageQueryParams>({ page: 0, size: 20 });
  const [filter, setFilter] = useState<EmployeeFilters>({
    searchKey: '',
    searchAttributeValue: '',
  });

  useEffect(() => {
    const { loaded, error } = removeEmployeeTagState;
    if (loaded && !error) {
      refreshEmployees();
    }
    // eslint-disable-next-line
  }, [removeEmployeeTagState]);

  useEffect(() => {
    const { loaded, error } = addTagToEmployeeState;
    if (loaded && !error) {
      refreshEmployees();
    }
    // eslint-disable-next-line
  }, [addTagToEmployeeState]);

  useEffect(() => {
    const { loaded, error } = deleteEmployeeState;
    if (loaded && !error && isConfirmModalOpen) {
      toggleConfirmModal();
      refreshEmployees();
    }
    // eslint-disable-next-line
  }, [deleteEmployeeState]);

  useEffect(() => {
    if (employees.loaded) {
      refreshEmployees();
    }
    // eslint-disable-next-line
  }, [filter, refresh]);

  useEffect(() => {
    refreshEmployees();
    // eslint-disable-next-line
  }, [queryParams]);

  // TODO: put back create, update after funtionality defined/fixed in be
  // const toggleEmployeeModal = (event?: string) => {
  //   if (event) setEventType(event);
  //   setEmployeeModalOpen(!isEmployeeModalOpen);
  // };

  const toggleConfirmModal = () => {
    setConfirmModalOpen(!isConfirmModalOpen);
  };

  const fetchEmployees = (queryParams: QueryParams) => {
    setQueryParams(queryParams);
  };

  const refreshEmployees = () => {
    getEmployees(appId, { ...queryParams, ...filter });
  };

  const removeEmployee = async (employee: EmployeeModel) => {
    if (employee) {
      await deleteEmployee(appId, employee.id);
    }
  };

  const handleDateFilterChange = (filterValues: EmployeeFilters): void => {
    setFilter(filterValues);
  };

  // TODO: put back create, update after funtionality defined/fixed in be
  // const editEmployee = async (id: React.ReactText) => {
  //   const employeesCurrent: EmployeeModel[] = employees.data.content;
  //   selectEmployee(employeesCurrent.find(e => e.id === id));
  //   toggleEmployeeModal('edit');
  // };

  const renderAttribute = (employeeItem: {}, key: string) => {
    const employeeItemObject: EmployeeModel = {
      id: 0,
      key: '',
      tags: null,
      attributes: null,
      ...employeeItem,
    };
    const attribute = employeeItemObject?.attributes?.find(attrib => attrib.key === key);
    return attribute?.value || '';
  };

  const getAllAttributes = () => {
    const extraAttribues: ListFieldModel<{}>[] = [];
    employees?.data?.content?.map((employee: EmployeeModel) => {
      return employee?.attributes?.map(attribute => {
        if (!extraAttribues.find(item => item.key === attribute.key)) {
          extraAttribues.push({
            key: attribute.key,
            label: attribute.title,
            render: employeeItem => renderAttribute(employeeItem, attribute.key),
          });
        }
        return {};
      });
    });
    return extraAttribues;
  };

  const sendMessageButtonClicked = (userProfileId: string) => {
    setSelectedUserProfileId(userProfileId);
    setIsSendMessageModalOpen(true);
  };

  return (
    <Fragment>
      {/* TODO: put back create, update after funtionality defined/fixed in be
      <CheckPermission variant="displayIf" permissions={['employee_employee_create']}>
        <Row className="mb-4">
          <Col className="d-flex justify-content-end">
            <Button color="success" outline onClick={() => toggleEmployeeModal('create')}>
              {t('employee.newEmployee')}
            </Button>
          </Col>
        </Row>
      </CheckPermission> */}

      <div className={styles.employeeTable}>
        <List
          data={employees.data.content}
          totalElements={numberOfEmployees}
          loaded={employees.loaded}
          loading={employees.loading}
          error={employees.error}
          fetchData={fetchEmployees}
          defaultItemOnPage={queryParams.size}
          filterItems={() => (
            <EmployeeFilter filterChanged={handleDateFilterChange} filter={filter} />
          )}
          fields={[
            { key: 'id', label: t('common.id') },
            { key: 'key', label: t('common.key') },
            ...getAllAttributes(),
            {
              key: 'employeeTags',
              label: t('employee.tags'),
              render: (employee: EmployeeModel, index) => (
                <EmployeeTags
                  employeeTags={employee.tags || []}
                  removeTag={(tagId: string) => removeEmployeeTag(appId, employee.id, tagId)}
                  removePermission="employee_employee-tag-assign_delete"
                  createPermission="employee_employee-tag-assign_create"
                  index={index}
                  employee={employee}
                  getTagCategories={getTagCategories}
                  tagCategories={tagCategories}
                  addTagToEntity={addTagToEmployee}
                />
              ),
            },
            {
              key: 'sendMessage',
              label: t('common.sendMessage'),
              render: (employee: EmployeeModel, index) => (
                employee.userProfileId && (<Button onClick={() => {
                  sendMessageButtonClicked(employee.userProfileId!);
                }}>{t('common.sendMessage')}</Button>)
              ),
            },
            {
              key: 'operations',
              label: t('common.operations'),
              operations: [Operations.DELETE],
            },
          ]}
          noDataLabel={t('employee.noEmployeesFound')}
          deleteFunction={removeEmployee}
          deleteTitle={t('employee.deleteEmployee')}
          deleteText={t('employee.deleteEmployeeConfirmMessage')}
          deletePermission="employee_employee_delete"
        />
      </div>

      {/* TODO: put back create, update after funtionality defined/fixed in be
       <EmployeeModal
        event={eventType}
        selectedEmployee={selectedEmployee}
        isOpen={isEmployeeModalOpen}
        setModalOpen={setEmployeeModalOpen}
        extraAttribues={getAllAttributes().map(attributeItem => {
          return { key: attributeItem.key, value: attributeItem.key };
        })}
      /> */}
      <SendMessageModal
        isOpen={isSendMessageModalOpen}
        setModalOpen={setIsSendMessageModalOpen}
        userProfileId={selectedUserProfileId}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  employees: state.employees,
  numberOfEmployees: state.employees.data.totalElements,
  deleteEmployeeState: state.deleteEmployee,
  addTagToEmployeeState: state.addTagToEmployee,
  removeEmployeeTagState: state.removeEmployeeTag,
  tagCategories: state.tagCategories,
});

const mapDispatchToProps = {
  getApplication,
  getEmployees,
  getTagCategories,
  deleteEmployee,
  addTagToEmployee,
  removeEmployeeTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesTable);
