import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  FormGroup,
  Label,
} from 'reactstrap';
import { Formik, Form, FormikValues } from 'formik';

import { ListModel } from '../../../redux/reducers/lists.reducer';
import { FileModel } from '../../models/file.model';
import { folderSchema } from '../../schemas/validation.schema';

import TextArea from '../form-inputs/TextArea';
import TextField from '../form-inputs/TextField';
import File from '../form-inputs/File';
import Spinner from '../Spinner';
import Switch from 'src/shared/components/form-inputs/Switch';

interface Props {
  cancel: Function;
  confirm: Function;
  isOpen: boolean;
  isLoading: boolean;
  errors?: any;
}

interface State {
  data: ListModel;
  image: FileModel;
}

const INIT_VALUES = {
  name: '',
  slug: '',
  description: '',
  image: undefined,
};

const FolderAddModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const confirmCreate = async (values: FormikValues) => {
    const { name, slug, description, image, gallery } = values;

    await props.confirm(
      {
        name,
        slug,
        description,
        gallery,
      },
      image,
    );
  };

  const { isOpen, isLoading, cancel } = props;

  return (
    <Modal isOpen={isOpen} className="modal-lg">
      <ModalHeader>{t('foldersAndDocuments.createFolder')}</ModalHeader>
      <Formik
        initialValues={{ ...INIT_VALUES }}
        validationSchema={folderSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await confirmCreate(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <ModalBody>
              <FormGroup row>
                <Col md="3">
                  <Label for="name">
                    {t('common.name')}
                    <sup className="text-danger"> *</sup>
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <TextField
                    name="name"
                    placeholder={t('foldersAndDocuments.folderNamePlaceholder')}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md="3">
                  <Label for="name">{t('common.gallery')}</Label>
                </Col>
                <Col xs="12" md="9">
                  <Switch
                    name="gallery"
                    color="primary"
                    variant="pill"
                    label={t('common.gallery')}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md="3">
                  <Label>
                    {t('common.id')}
                    <sup className="text-danger"> *</sup>
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <TextField
                    name="slug"
                    placeholder={t('foldersAndDocuments.folderIdPlaceholder')}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md="3">
                  <Label>{t('common.description')}</Label>
                </Col>
                <Col xs="12" md="9">
                  <TextArea
                    name="description"
                    rows="5"
                    placeholder={t('foldersAndDocuments.descriptionPlaceholder')}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md="3">
                  <Label>{t('common.image')}</Label>
                </Col>
                <Col xs="12" md="9">
                  <File name="image" accept="image/*" setFieldValue={setFieldValue} />
                </Col>
              </FormGroup>
            </ModalBody>

            <ModalFooter>
              <Spinner loading={isSubmitting || isLoading} className="mr-2" />

              <Button type="submit" color="success" disabled={isSubmitting || isLoading}>
                {t('common.create')}
              </Button>
              <Button onClick={() => cancel()} color="secondary">
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default FolderAddModal;
