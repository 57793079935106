import React, { useEffect } from 'react';
import { FC } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { Row, Col, Card, CardBody, Button, FormGroup, Label } from 'reactstrap';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import moment from 'moment';

import { mergeDateWithTime } from 'src/shared/utils';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { createEvent, getEvent, updateEvent, revertEvent } from 'src/redux/actions/events.action';
import {
  EmployeeTags,
  PageHeader,
  SingleDate,
  Spinner,
  Switch,
  TextArea,
  TextField,
  TimePicker,
} from 'src/shared/components';

import { QuestionsAndAnswers, Questions, EventDates } from './components/editor';
import {
  EditorBase,
  EventModel,
  StateModel,
  EventEditorFormModel,
  EventStatus,
} from 'src/shared/models';

import { eventSchema } from 'src/shared/schemas/validation.schema';
import { getTagCategories } from 'src/redux/actions/employee.action';

import {
  removeEventTag as removeTag,
  addTagToEvent as addTag,
} from 'src/redux/actions/events.action';

interface EventsEditorProps extends EditorBase {
  getEvent: Function;
  revertEvent: Function;
  getApplication: Function;
  createEvent: Function;
  updateEvent: (appId: string, eventId: string, data: EventModel) => void;
  application: ApplicationModel;
  event: EventModel;
  getTagCategories: Function;
  tagCategories: StateModel;
  removeTag: Function;
  addTag: Function;
  addTagToState: StateModel;
  removeTagState: StateModel;
}

const INIT_VALUES: EventEditorFormModel = {
  startDate: moment().add(1, 'day'),
  startTime: moment(),
  endDate: moment().add(2, 'days'),
  endTime: moment(),
  description: '',
  title: '',
  isActive: false,
};

const EventsEditor: FC<EventsEditorProps> = ({
  edit,
  getEvent,
  revertEvent,
  getApplication,
  createEvent,
  updateEvent,
  application,
  event,
  getTagCategories,
  tagCategories,
  removeTag,
  addTag,
  addTagToState,
  removeTagState,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { appId, eventId } = useParams<{ appId: string; eventId: string }>();

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    if (!edit) {
      revertEvent();
    }
  }, [application, appId, getApplication, edit, revertEvent]);

  useEffect(() => {
    edit && getEvent(appId, eventId);
  }, [edit, appId, eventId, getEvent]);

  useEffect(() => {
    const { loaded, error } = removeTagState;
    if (loaded && !error) {
      getEvent(appId, eventId);
    }
    // eslint-disable-next-line
  }, [removeTagState, addTagToState]);

  const getInitialFormValues = () => {
    return {
      ...event,
      startDate: moment(event.openDate),
      startTime: moment(event.openDate),
      endDate: moment(event.closeDate),
      endTime: moment(event.closeDate),
      isActive: event.status === EventStatus.ACTIVE,
    };
  };

  const handleSubmit = async (
    { startDate, startTime, endDate, endTime, ...values }: EventEditorFormModel,
    { setSubmitting }: FormikHelpers<EventEditorFormModel>,
  ) => {
    setSubmitting(true);

    const data = {
      ...values,
      openDate: mergeDateWithTime(startDate, startTime),
      closeDate: mergeDateWithTime(endDate, endTime),
      status: values.isActive ? EventStatus.ACTIVE : EventStatus.DRAFT,
    };

    if (edit) {
      await updateEvent(appId, event.id.toString(), data as any);
      setSubmitting(false);
    } else {
      const { id } = await createEvent(appId, data);

      setSubmitting(false);
      redirectToEdit(id);
    }
  };

  const redirectToEdit = (eventId: number) => {
    history.replace(`/application/${appId}/modules/events/${eventId}/edit`);
  };

  const disableStartDateChange = (event: EventEditorFormModel): boolean => {
    if (event.startDate === null || event.startTime === null) return false;
    return !!(eventId && moment().isAfter(mergeDateWithTime(event.startDate, event.startTime)));
  };

  const initialFormValues = event ? getInitialFormValues() : INIT_VALUES;

  return (
    <>
      <div className="animated fadeIn">
        <PageHeader title={edit ? t('events.editEvent') : t('events.createEvent')} />
        <Row>
          <Col md={6}>
            <Card>
              <CardBody>
                <Formik<EventEditorFormModel>
                  initialValues={initialFormValues}
                  validationSchema={eventSchema()}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({ isSubmitting, values, errors }) => {
                    const isStartDateChangeDisabled = disableStartDateChange(values);

                    return (
                      <Form>
                        <fieldset className="border p-3">
                          <legend className="col-form-label w-auto font-weight-bold">
                            {t('common.generalInformations')}
                          </legend>

                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <Label for="title">{t('common.title')}</Label>
                                <TextField name="title" />
                              </FormGroup>

                              <FormGroup>
                                <Label for="startDate">{t('common.signupStartTime')}</Label>
                                <Field
                                  component={SingleDate}
                                  id="startDate"
                                  name="startDate"
                                  disabled={isStartDateChangeDisabled}
                                />
                                <Field
                                  component={TimePicker}
                                  name="startTime"
                                  disabled={isStartDateChangeDisabled}
                                />
                                {(errors.startDate || errors.startTime) && (
                                  <div className="input-error-message">
                                    {errors.startDate || errors.startTime}
                                  </div>
                                )}
                              </FormGroup>

                              <FormGroup>
                                <Label for="endDate">{t('common.signupEndTime')}</Label>
                                <Field component={SingleDate} id="endDate" name="endDate" />
                                <Field component={TimePicker} name="endTime" />
                                {(errors.endDate || errors.endTime) && (
                                  <div className="input-error-message">
                                    {errors.endDate || errors.endTime}
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <Label for="description">{t('common.description')}</Label>
                                <TextArea name="description" rows="4" />
                              </FormGroup>
                              {values.slug && (
                                <FormGroup>
                                  <Label for="slug">{t('common.slug')}</Label>
                                  <TextField name="slug" disabled />
                                </FormGroup>
                              )}
                              <FormGroup>
                                <Label for="isActive">{t('common.status')}</Label>
                                <div className="d-flex align-items-center flex-row">
                                  {t('common.draft')}
                                  <Switch
                                    name="isActive"
                                    className="ml-2 mr-2"
                                    variant="pill"
                                    color="primary"
                                    size="lg"
                                    checked={values.isActive}
                                  />
                                  {t('common.active')}
                                </div>
                              </FormGroup>
                            </Col>
                            <Col>
                              <div className="d-flex justify-content-end align-items-center mt-4">
                                {isSubmitting && (
                                  <Spinner loading={isSubmitting} className="mr-1" />
                                )}

                                <Button type="submit" color="primary" disabled={isSubmitting}>
                                  {edit ? t('common.save') : t('common.create')}
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </fieldset>
                      </Form>
                    );
                  }}
                </Formik>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <EventDates event={event} isEditingDisabled={!edit} />
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <CardBody>
                {eventId && event && (
                  <fieldset className="border p-3">
                    <legend className="col-form-label w-auto font-weight-bold">
                      {t('surveys.add_tags')}
                    </legend>

                    <EmployeeTags
                      employeeTags={event.employeeTags || []}
                      removeTag={(tagId: string) => removeTag(appId, event.id, tagId)}
                      removePermission="employee_tag-event_delete"
                      createPermission="employee_tag-event_create"
                      taggableModel={event}
                      getTagCategories={getTagCategories}
                      tagCategories={tagCategories}
                      addTagToEntity={addTag}
                    />
                  </fieldset>
                )}
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <QuestionsAndAnswers isEditingDisabled={!edit} />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Questions isEditingDisabled={!edit} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    event: state.eventGeneral.data?.event,
    tagCategories: state.tagCategories,
    removeTagState: state.removeSurveyTag,
    addTagToState: state.addTagToSurvey,
  };
};

const mapDispatchToProps = {
  getEvent,
  revertEvent,
  getApplication,
  createEvent,
  updateEvent,
  getTagCategories,
  removeTag,
  addTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsEditor);
