import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, UncontrolledTooltip } from 'reactstrap';

interface Props {
  clearComplaints: Function;
  disabled: boolean;
}

const ComplaintsClear: React.FC<Props> = ({ clearComplaints, disabled }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Button
        id="clearButton"
        color="danger"
        disabled={disabled}
        onClick={() => clearComplaints()}
      >
        {t('common.clear')}
      </Button>

      <UncontrolledTooltip
        placement="bottom"
        target="clearButton"
        trigger="hover"
      >
        {t('common.clearAllData')}
      </UncontrolledTooltip>
    </Fragment>
  );
};

export default ComplaintsClear;
