import React from 'react';
import { Row } from 'reactstrap';

import { CustomListModel } from 'src/shared/models/custom-lists.model';

import CustomListCard from './CustomListCard';

interface Props {
  customLists: CustomListModel[];
  setModalOpen: Function;
  setConfirmModalOpen: Function;
  setModalEvent: Function;
  setSelectedCustomList: Function;
}

const CustomListCards: React.FC<Props> = ({
  customLists,
  setModalOpen,
  setConfirmModalOpen,
  setModalEvent,
  setSelectedCustomList,
}) => (
  <Row>
    {customLists.map(customList => (
      <CustomListCard
        customList={customList}
        setModalOpen={setModalOpen}
        setConfirmModalOpen={setConfirmModalOpen}
        setModalEvent={setModalEvent}
        setSelectedCustomList={setSelectedCustomList}
        key={customList.id}
      />
    ))}
  </Row>
);

export default CustomListCards;
