import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import './DefaultHeader.scss';

import en from '../../assets/img/flags/en.svg';
import hu from '../../assets/img/flags/hu.svg';

interface Props {
  language: string;
  setLanguage: Function;
}

interface LanguageModel {
  id: string;
  name: string;
  icon: string;
}

const languages = [
  {
    id: 'en',
    name: 'English',
    icon: en,
  },
  {
    id: 'hu',
    name: 'Magyar',
    icon: hu,
  },
];

const getFlag = (language: string) => {
  const langObject = languages.find((el: LanguageModel) => el.id === language);

  return langObject ? langObject.icon : '';
};

const LanguageSelectorDropdown: React.FC<Props> = ({ language, setLanguage }) => {
  const { i18n } = useTranslation();

  return (
    <UncontrolledDropdown nav direction="down" style={{ margin: 0 }}>
      <DropdownToggle nav>
        <img src={getFlag(language)} className="toggle-flag" alt="Selected Language" />
      </DropdownToggle>
      <DropdownMenu right style={{ right: 17.5 }}>
        {languages.map((lang: LanguageModel) => (
          <DropdownItem
            key={lang.id}
            className="language-dropdown-item"
            onClick={() => {
              setLanguage(lang.id);
              i18n.changeLanguage(lang.id);
            }}
          >
            <img src={lang.icon} alt={lang.name} />
            <span>{lang.name}</span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default LanguageSelectorDropdown;
