import { Dispatch } from 'redux';
import { GeneralQueryParams } from '../../shared/models/query-params.model';
import {
  getPositionCriteria as getPositionCriteriaService,
  getPositionCriterias as getPositionCriteriasService,
  createPositionCriteria as createPositionCriteriaService,
  updatePositionCriteria as updatePositionCriteriaService,
  deletePositionCriteria as deletePositionCriteriaService,
} from '../services/position-criterias.service';

export const GET_POSITION_CRITERIA_REQUEST = 'position-criterias/GET_POSITION_CRITERIA_REQUEST';
export const GET_POSITION_CRITERIA_SUCCESS = 'position-criterias/GET_POSITION_CRITERIA_SUCCESS';
export const GET_POSITION_CRITERIA_ERROR = 'position-criterias/GET_POSITION_CRITERIA_ERROR';

export const GET_POSITION_CRITERIAS_REQUEST = 'position-criterias/GET_POSITION_CRITERIAS_REQUEST';
export const GET_POSITION_CRITERIAS_SUCCESS = 'position-criterias/GET_POSITION_CRITERIAS_SUCCESS';
export const GET_POSITION_CRITERIAS_ERROR = 'position-criterias/GET_POSITION_CRITERIAS_ERROR';

export const CREATE_POSITION_CRITERIA_REQUEST =
  'position-criterias/CREATE_POSITION_CRITERIA_REQUEST';
export const CREATE_POSITION_CRITERIA_SUCCESS =
  'position-criterias/CREATE_POSITION_CRITERIA_SUCCESS';
export const CREATE_POSITION_CRITERIA_ERROR = 'position-criterias/CREATE_POSITION_CRITERIA_ERROR';

export const UPDATE_POSITION_CRITERIA_REQUEST =
  'position-criterias/UPDATE_POSITION_CRITERIA_REQUEST';
export const UPDATE_POSITION_CRITERIA_SUCCESS =
  'position-criterias/UPDATE_POSITION_CRITERIA_SUCCESS';
export const UPDATE_POSITION_CRITERIA_ERROR = 'position-criterias/UPDATE_POSITION_CRITERIA_ERROR';

export const DELETE_POSITION_CRITERIA_REQUEST =
  'position-criterias/DELETE_POSITION_CRITERIA_REQUEST';
export const DELETE_POSITION_CRITERIA_SUCCESS =
  'position-criterias/DELETE_POSITION_CRITERIA_SUCCESS';
export const DELETE_POSITION_CRITERIA_ERROR = 'position-criterias/DELETE_POSITION_CRITERIA_ERROR';

export const CLEAR_POSITION = 'position-criterias/CLEAR_POSITION';
export const CLEAR_UPDATE_POSITION = 'position-criterias/CLEAR_UPDATE_POSITION';
export const CLEAR_CREATE_POSITION = 'position-criterias/CLEAR_CREATE_POSITION';

export const getPositionCriteria = (applicationId: string, criteriaId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_POSITION_CRITERIA_REQUEST,
    });

    try {
      dispatch({
        type: GET_POSITION_CRITERIA_SUCCESS,
        payload: await getPositionCriteriaService(applicationId, criteriaId),
      });
    } catch (error) {
      dispatch({
        type: GET_POSITION_CRITERIA_ERROR,
        error,
      });
    }
  };
};

export const getPositionCriterias = (applicationId: string, queryParams: GeneralQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_POSITION_CRITERIAS_REQUEST,
    });

    try {
      dispatch({
        type: GET_POSITION_CRITERIAS_SUCCESS,
        payload: await getPositionCriteriasService(applicationId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_POSITION_CRITERIAS_ERROR,
        error,
      });
    }
  };
};

export const createPositionCriteria = (applicationId: string, name: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_POSITION_CRITERIA_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_POSITION_CRITERIA_SUCCESS,
        payload: await createPositionCriteriaService(applicationId, name),
      });
    } catch (error) {
      dispatch({
        type: CREATE_POSITION_CRITERIA_ERROR,
        error,
      });
    }
  };
};

export const updatePositionCriteria = (applicationId: string, criteriaId: number, name: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_POSITION_CRITERIA_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_POSITION_CRITERIA_SUCCESS,
        payload: await updatePositionCriteriaService(applicationId, criteriaId, name),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_POSITION_CRITERIA_ERROR,
      });
    }
  };
};

export const deletePositionCriteria = (applicationId: string, criteriaId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_POSITION_CRITERIA_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_POSITION_CRITERIA_SUCCESS,
        payload: await deletePositionCriteriaService(applicationId, criteriaId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_POSITION_CRITERIA_ERROR,
        error,
      });
    }
  };
};
