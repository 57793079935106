import { get, post, patch, del } from '../../shared/services/http.service';
import { GeneralQueryParams } from '../../shared/models/query-params.model';
import { CustomListItemModel } from 'src/shared/models/custom-lists.model';

export const getCustomListItems = async (
  applicationId: string,
  customListId: number,
  params: GeneralQueryParams,
) => {
  return get(`/applications/${applicationId}/custom-lists/${customListId}/custom-list-items`, {
    params,
  });
};

export const createCustomListItem = async (
  applicationId: string,
  customListId: number,
  customListItem: CustomListItemModel,
) => {
  return post(`/applications/${applicationId}/custom-lists/${customListId}/custom-list-items`, {
    data: customListItem,
  });
};

export const updateCustomListItem = async (
  applicationId: string,
  customListId: number,
  customListItemId: number,
  customListItem: CustomListItemModel,
) => {
  return patch(
    `/applications/${applicationId}/custom-lists/${customListId}/custom-list-items/${customListItemId}`,
    { data: customListItem },
  );
};

export const deleteCustomListItem = async (
  applicationId: string,
  customListId: number,
  customListItemId: number,
) => {
  return del(
    `/applications/${applicationId}/custom-lists/${customListId}/custom-list-items/${customListItemId}`,
  );
};
