// src/containers/Login/Login.tsx

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Card, CardBody, Form, InputGroup, Input, Button } from 'reactstrap';
import { login } from '../../redux/actions/auth.action';
import MicrosoftLoginButton from './MicrosoftLoginButton';

import './Login.scss';
import logo from '../../assets/img/brand/chatboss-1.svg';

interface LoginCredentials {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const [credentials, setCredentials] = useState<LoginCredentials>({ email: '', password: '' });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCredentials(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await dispatch(login(credentials) as any);
    } catch (error) {
      console.error('Login failed', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMicrosoftLogin = async (idToken: string) => {
    setIsLoading(true);
    try {
      await dispatch(login({ idToken }) as any);
    } catch (error) {
      console.error('Microsoft login failed', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // Consider using a proper loading component
  }

  return (
    <div className="app login-container">
      <Container>
        <Row className="d-flex justify-content-center">
          <Col lg="5" md="8">
            <Col className="logo-container">
              <img className="img-fluid" alt="Chatboss Logo" src={logo} />
            </Col>
            <Card className="login-card">
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <InputGroup className="mb-3">
                    <Input
                      type="text"
                      name="email"
                      className="login-input email"
                      value={credentials.email}
                      placeholder="Email"
                      autoComplete="email"
                      onChange={handleChange}
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <Input
                      type="password"
                      placeholder="Password"
                      name="password"
                      className="login-input password"
                      value={credentials.password}
                      autoComplete="current-password"
                      onChange={handleChange}
                    />
                  </InputGroup>
                  <Row>
                    <Col>
                      <Button className="login-btn" block color="primary" type="submit">
                        Log In
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <MicrosoftLoginButton onLogin={handleMicrosoftLogin} />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;