import FileSaver from 'file-saver';

const saveFile = (data: string, fileName: string, outputType: string) => {
  let type: string = '';

  switch (outputType) {
    case 'csv':
      type = 'text/csv;charset=utf-8';
      break;
    case 'json':
      type = 'application/json';
      break;
    case 'xlsx':
      type = 'application/octet-stream';
      break;
    default:
      throw new Error(`Invalid output type: ${outputType}`);
  }

  const savingData = new Blob([data], { type });
  FileSaver.saveAs(savingData, fileName);
};

export default saveFile;
