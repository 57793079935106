import { del, get, patch, post } from '../../shared/services/http.service';
import { PageQueryParams, HelpdeskInstantAnswerModel } from 'src/shared/models';

const baseUrl = (applicationId: string) =>
  `/applications/${applicationId}/helpdesk/suggested-replies`;

export const getHelpdeskInstantAnswers = async (applicationId: string, params: PageQueryParams) => {
  return get(`${baseUrl(applicationId)}`, { params });
};

export const getHelpdeskInstantAnswer = async (applicationId: string, instantAnswersId: string) => {
  return get(`${baseUrl(applicationId)}/${instantAnswersId}`);
};

export const createHelpdeskInstantAnswer = async (
  applicationId: string,
  data: HelpdeskInstantAnswerModel,
) => {
  return post(`${baseUrl(applicationId)}`, { data });
};

export const updateHelpdeskInstantAnswer = async (
  applicationId: string,
  instantAnswersId: number,
  data: HelpdeskInstantAnswerModel,
) => {
  return patch(`${baseUrl(applicationId)}/${instantAnswersId}`, { data });
};

export const deleteHelpdeskInstantAnswer = async (
  applicationId: string,
  instantAnswersId: string,
) => {
  return del(`${baseUrl(applicationId)}/${instantAnswersId}`);
};
