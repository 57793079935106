import React, { Fragment } from 'react';
import moment from 'moment';
import i18n from 'src/i18n';
import { ListElementModel } from 'src/redux/reducers/list-elements.reducer';
import { renderDifference } from 'src/views/AuditLog/helpers/renderHelpers';

interface Props {
  updatedFolder: ListElementModel;
  savedFolder: ListElementModel;
}

const UpdatedFolder: React.FC<Props> = ({ updatedFolder, savedFolder }) => {
  return (
    <Fragment>
      <p className="font-weight-bold">
        {i18n.t('auditLog.detailsOfUpdatedDocument')}
      </p>

      <table className="table table-sm">
        <tbody>
          <tr>
            <td>
              {i18n.t('common.id')} / {i18n.t('common.name')}
            </td>
            <td>
              {updatedFolder.id} /{' '}
              {renderDifference(updatedFolder.name, savedFolder.name)}
            </td>
          </tr>
          <tr>
            <td>{i18n.t('common.created')}</td>
            <td>{moment(updatedFolder.createdAt).format('LLL')}</td>
          </tr>
          <tr>
            <td>{i18n.t('common.updated')}</td>
            <td>
              {updatedFolder.updatedAt &&
                renderDifference(
                  moment(updatedFolder.updatedAt).format('LLL'),
                  moment(savedFolder.updatedAt).format('LLL'),
                )}
            </td>
          </tr>
          <tr>
            <td>{i18n.t('foldersAndDocuments.shortDescription')}</td>
            <td>
              {renderDifference(
                updatedFolder.shortDescription,
                savedFolder.shortDescription,
              )}
            </td>
          </tr>
          <tr>
            <td>{i18n.t('common.description')}</td>
            <td>
              {renderDifference(
                updatedFolder.description,
                savedFolder.description,
              )}
            </td>
          </tr>
          <tr>
            <td>{i18n.t('foldersAndDocuments.fileAttachment')}</td>
            <td>
              {renderDifference(updatedFolder.fileUrl, savedFolder.fileUrl)}
            </td>
          </tr>
          <tr>
            <td>{i18n.t('foldersAndDocuments.imageAttachment')}</td>
            <td>
              {renderDifference(updatedFolder.imageUrl, savedFolder.imageUrl)}
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

export default UpdatedFolder;
