import React, { FC } from 'react';
import { FieldProps } from 'formik';
import { connect } from 'react-redux';

import { getTagCategories } from 'src/redux/actions/employee.action';
import { CheckPermission, EmployeeTagSelectPopover, TagCreatePill } from 'src/shared/components';
import { TagModel, StateModel } from 'src/shared/models';

import styles from './AssignTag.module.scss';

interface AssignEmployeeTagProps extends FieldProps {
  deletePermission: string;
  createPermission: string;
  getTagCategories: Function;
  tagCategories: StateModel;
}

const AssignEmployeeTag: FC<AssignEmployeeTagProps> = ({
  form,
  field,
  deletePermission,
  createPermission,
  getTagCategories,
  tagCategories,
}) => {
  const value = field.value as TagModel[];
  return (
    <div className={styles.tags}>
      {value.map((tag: TagModel) => (
        <span key={tag.id} className={styles.tag}>
          {tag.name}
          <CheckPermission variant="displayIf" permissions={[deletePermission]}>
            <button
              type="button"
              className={styles.tagDelete}
              onClick={() =>
                form.setFieldValue(
                  field.name,
                  value.filter(t => t.id !== tag.id),
                )
              }
            >
              <i className="fas fa-times-circle fa-lg" />
            </button>
          </CheckPermission>
        </span>
      ))}
      <TagCreatePill id={`Popover-0`} context="assign" createPermission={createPermission} />

      <EmployeeTagSelectPopover
        employeeTags={value}
        addTagToEntity={(tagsToAssign: Array<any>) => {
          form.setFieldValue(field.name, value.concat(tagsToAssign));
        }}
        getTagCategories={getTagCategories}
        tagCategories={tagCategories}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    tagCategories: state.tagCategories,
  };
};

const mapDispatchToProps = {
  getTagCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignEmployeeTag);
