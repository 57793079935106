import { Dispatch } from 'redux';
import { GeneralQueryParams } from '../../shared/models/query-params.model';
import { PositionFilterQuestion } from '../../shared/models/position-filter-questions.model';
import {
  getPositionFilterQuestion as getPositionFilterQuestionService,
  getPositionFilterQuestions as getPositionFilterQuestionsService,
  createPositionFilterQuestion as createPositionFilterQuestionService,
  updatePositionFilterQuestion as updatePositionFilterQuestionService,
  deletePositionFilterQuestion as deletePositionFilterQuestionService,
  updatePositionFilterQuestionsOrder as updatePositionFilterQuestionsOrderService,
} from '../services/position-filter-questions.service';

export const GET_POSITION_FILTER_QUESTION_REQUEST =
  'position-filter-questions/GET_POSITION_FILTER_QUESTION_REQUEST';
export const GET_POSITION_FILTER_QUESTION_SUCCESS =
  'position-filter-questions/GET_POSITION_FILTER_QUESTION_SUCCESS';
export const GET_POSITION_FILTER_QUESTION_ERROR =
  'position-filter-questions/GET_POSITION_FILTER_QUESTION_ERROR';

export const GET_POSITION_FILTER_QUESTIONS_REQUEST =
  'position-filter-questions/GET_POSITION_FILTER_QUESTIONS_REQUEST';
export const GET_POSITION_FILTER_QUESTIONS_SUCCESS =
  'position-filter-questions/GET_POSITION_FILTER_QUESTIONS_SUCCESS';
export const GET_POSITION_FILTER_QUESTIONS_ERROR =
  'position-filter-questions/GET_POSITION_FILTER_QUESTIONS_ERROR';

export const CREATE_POSITION_FILTER_QUESTION_REQUEST =
  'position-filter-questions/CREATE_POSITION_FILTER_QUESTION_REQUEST';
export const CREATE_POSITION_FILTER_QUESTION_SUCCESS =
  'position-filter-questions/CREATE_POSITION_FILTER_QUESTION_SUCCESS';
export const CREATE_POSITION_FILTER_QUESTION_ERROR =
  'position-filter-questions/CREATE_POSITION_FILTER_QUESTION_ERROR';

export const UPDATE_POSITION_FILTER_QUESTION_REQUEST =
  'position-filter-questions/UPDATE_POSITION_FILTER_QUESTION_REQUEST';
export const UPDATE_POSITION_FILTER_QUESTION_SUCCESS =
  'position-filter-questions/UPDATE_POSITION_FILTER_QUESTION_SUCCESS';
export const UPDATE_POSITION_FILTER_QUESTION_ERROR =
  'position-filter-questions/UPDATE_POSITION_FILTER_QUESTION_ERROR';

export const DELETE_POSITION_FILTER_QUESTION_REQUEST =
  'position-filter-questions/DELETE_POSITION_FILTER_QUESTION_REQUEST';
export const DELETE_POSITION_FILTER_QUESTION_SUCCESS =
  'position-filter-questions/DELETE_POSITION_FILTER_QUESTION_SUCCESS';
export const DELETE_POSITION_FILTER_QUESTION_ERROR =
  'position-filter-questions/DELETE_POSITION_FILTER_QUESTION_ERROR';

export const UPDATE_POSITION_FILTER_QUESTIONS_ORDER_REQUEST =
  'position-filter-questions/UPDATE_POSITION_FILTER_QUESTIONS_ORDER_REQUEST';
export const UPDATE_POSITION_FILTER_QUESTIONS_ORDER_SUCCESS =
  'position-filter-questions/UPDATE_POSITION_FILTER_QUESTIONS_ORDER_SUCCESS';
export const UPDATE_POSITION_FILTER_QUESTIONS_ORDER_ERROR =
  'position-filter-questions/UPDATE_POSITION_FILTER_QUESTIONS_ORDER_ERROR';

export const getPositionFilterQuestion = (applicationId: string, questionId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_POSITION_FILTER_QUESTION_REQUEST,
    });

    try {
      dispatch({
        type: GET_POSITION_FILTER_QUESTION_SUCCESS,
        payload: await getPositionFilterQuestionService(applicationId, questionId),
      });
    } catch (error) {
      dispatch({
        type: GET_POSITION_FILTER_QUESTION_ERROR,
        error,
      });
    }
  };
};

export const getPositionFilterQuestions = (
  applicationId: string,
  queryParams: GeneralQueryParams,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_POSITION_FILTER_QUESTIONS_REQUEST,
    });

    try {
      dispatch({
        type: GET_POSITION_FILTER_QUESTIONS_SUCCESS,
        payload: await getPositionFilterQuestionsService(applicationId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_POSITION_FILTER_QUESTIONS_ERROR,
        error,
      });
    }
  };
};

export const createPositionFilterQuestion = (
  applicationId: string,
  question: PositionFilterQuestion,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_POSITION_FILTER_QUESTION_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_POSITION_FILTER_QUESTION_SUCCESS,
        payload: await createPositionFilterQuestionService(applicationId, question),
      });
    } catch (error) {
      dispatch({
        type: CREATE_POSITION_FILTER_QUESTION_ERROR,
        error,
      });
    }
  };
};

export const updatePositionFilterQuestion = (
  applicationId: string,
  positionId: number,
  question: PositionFilterQuestion,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_POSITION_FILTER_QUESTION_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_POSITION_FILTER_QUESTION_SUCCESS,
        payload: await updatePositionFilterQuestionService(applicationId, positionId, question),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_POSITION_FILTER_QUESTION_ERROR,
        error,
      });
    }
  };
};

export const deletePositionFilterQuestion = (applicationId: string, questionId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_POSITION_FILTER_QUESTION_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_POSITION_FILTER_QUESTION_SUCCESS,
        payload: await deletePositionFilterQuestionService(applicationId, questionId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_POSITION_FILTER_QUESTION_ERROR,
        error,
      });
    }
  };
};

export const updatePositionFilterQuestionsOrder = (
  applicationId: string,
  questionIds: number[],
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_POSITION_FILTER_QUESTIONS_ORDER_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_POSITION_FILTER_QUESTIONS_ORDER_SUCCESS,
        payload: await updatePositionFilterQuestionsOrderService(applicationId, questionIds),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_POSITION_FILTER_QUESTIONS_ORDER_ERROR,
        error,
      });
    }
  };
};
