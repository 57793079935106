import React, { FC } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';

interface TimeFromNowProps {
  id: number | string;
  time: string;
}

const TimeFromNow: FC<TimeFromNowProps> = ({ id, time }) => (
  <>
    <span id={`date-${id}`}>{moment(time).fromNow()}</span>
    <UncontrolledTooltip placement="bottom" target={`date-${id}`} trigger="hover">
      {moment(time).format('LLL')}
    </UncontrolledTooltip>
  </>
);

export default TimeFromNow;
