import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormikContext } from 'formik';
import { Row, Col, FormGroup, Label } from 'reactstrap';

import { SelectModel } from 'src/shared/models/common.model';
import { ExpressionModel } from 'src/shared/models/questionnaire.model';

import { getSelectOptionObjects } from 'src/shared/utils/misc.util';
import CreateTypes from 'src/shared/models/create-types';

import {
  getExpressions as getExpressionsService,
  createExpression as createExpressionService,
} from 'src/redux/services/questionnaire.service';

import ReactSelect from 'src/shared/components/form-inputs/ReactSelect';
import CreatePopover from './components/CreatePopover';

import styles from '../../Questionnaire.module.scss';

const LogicControllerForm = () => {
  const { t } = useTranslation();
  const { values, setFieldValue }: FormikValues = useFormikContext();

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [expressionOptions, setExpressionOptions] = useState<SelectModel[]>([]);
  const [selectedExpressionOption, setSelectedExpressionOption] = useState<SelectModel>();

  const { expression } = values;

  useEffect(() => {
    getExpressions();
  }, []);

  useEffect(() => {
    if (expressionOptions.length > 0) {
      if (expression) {
        const selectedOption = expressionOptions.filter(o => o.value === expression);
        if (selectedOption.length) {
          setSelectedExpression(selectedOption[0]);
        }
      } else {
        setSelectedExpression(expressionOptions[0]);
      }
    }
    /* eslint-disable-next-line */
  }, [expressionOptions]);

  const getExpressions = async () => {
    const res = await getExpressionsService();
    const options = getSelectOptionObjects(res, 'title', 'expression');
    setExpressionOptions(options);
    setSelectedExpressionOption(options[0]);
  };

  const createExpression = async (expression: ExpressionModel) => {
    const res: ExpressionModel = await createExpressionService(expression);

    if (res) {
      const createdOption = { label: res.title, value: res.expression };
      setExpressionOptions([...expressionOptions, createdOption]);
      setSelectedExpression(createdOption);
    }

    togglePopover();
  };

  const handleExpressionCreate = (values: ExpressionModel) => {
    createExpression(values);
  };

  const setSelectedExpression = (expression: SelectModel) => {
    setFieldValue('expression', expression);
  };

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  return (
    <Fragment>
      <Row>
        <Col>
          <FormGroup>
            <Label for="attributeValidator">{t('common.expression')}</Label>
            <div className="d-flex flex-row">
              <ReactSelect
                className={styles.select}
                name="expression"
                options={expressionOptions}
                defaultValue={selectedExpressionOption}
                isClearable
              />
              <button id="createPopover" type="button" className="btn btn-ghost-info ml-1">
                <i className="fas fa-plus" />
              </button>
            </div>
          </FormGroup>
        </Col>
      </Row>

      {expression.label && expression.value && (
        <Row>
          <Col>
            <FormGroup>
              <Label for="valueOfExpression">{t('common.valueOfExpression')}</Label>
              <pre className={styles.valueOfExpression}>
                <code>{JSON.stringify(expression.value, null, 2)}</code>
              </pre>
            </FormGroup>
          </Col>
        </Row>
      )}

      <CreatePopover
        isOpen={popoverOpen}
        createType={CreateTypes.EXPRESSION}
        toggle={togglePopover}
        handleOnSubmit={handleExpressionCreate}
      />
    </Fragment>
  );
};

export default LogicControllerForm;
