import { StateModel } from '../../shared/models/default-state.model';
import {
  GET_BATCH_MESSAGES_REQUEST,
  GET_BATCH_MESSAGES_SUCCESS,
  GET_BATCH_MESSAGES_ERROR,
} from '../actions/batch-messages.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export const batchMessagesReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_BATCH_MESSAGES_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case GET_BATCH_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_BATCH_MESSAGES_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
