import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { useFormikContext } from 'formik';

import { messageTypesToExcludeRegex } from 'src/shared/constants/questionnaire.constans';

import { getSelectOptionObjects } from 'src/shared/utils/misc.util';

import { QuestionnaireMessage, QuestionnaireAnswer } from 'src/shared/models/questionnaire.model';
import { TrainingDataModel } from 'src/shared/models/training-data.model';
import { SelectGroupModel, SelectModel } from 'src/shared/models/common.model';

import { getConnectedParentConditions } from 'src/redux/services/questionnaire.service';
import { getTrainingData } from 'src/redux/services/training-data.service';

import ReactSelect from 'src/shared/components/form-inputs/ReactSelect';
import TrainingDataCreateModal from 'src/views/TrainingData/components/TrainingDataCreateModal';

import styles from '../../Questionnaire.module.scss';
import _ from 'lodash';
import { Switch } from '../../../../shared/components';

interface Props {
  messages: QuestionnaireMessage[];
  trainingData: TrainingDataModel[];
  createdTrainingData: TrainingDataModel;
  messageType: string;
  selectedMessage: QuestionnaireMessage | undefined;
}

const DefaultForm: React.FC<Props> = ({
                                        messages,
                                        trainingData,
                                        createdTrainingData,
                                        messageType,
                                        selectedMessage,
                                      }) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const { setFieldValue } = useFormikContext();

  const [connectedParentConditionIds, setConnectedParentConditionIds] = useState<number[]>([]);
  const [parentConditionOptions, setParentConditionOptions] = useState<SelectGroupModel[]>([]);
  const [nextMessageIdIfDisabledOptions, setNextMessageIdIfDisabledOptions] = useState<SelectModel[]>([]);
  const [intentOptions, setIntentOptions] = useState<SelectModel[]>([]);
  const [connectedIntents, setConnectedIntens] = useState<string[]>([]);
  const [isTrainingDataModalOpen, setTrainingDataModalOpen] = useState<boolean>(false);
  const [messageDisabled, setMessageDisabled] = useState<boolean>(false);

  useEffect(() => {
    getConnectedParentConditionIds();
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    if (trainingData.length > 0) {
      getIntents();
    }
    /* eslint-disable-next-line */
  }, [trainingData.length]);

  useEffect(() => {
    getParentConditionGroupedObjects();
    getConnectedIntentIds();
    getNextMessageObjects();
    /* eslint-disable-next-line */
  }, [connectedParentConditionIds]);


  useEffect(() => {
    if (createdTrainingData.id) {
      const intentObject = {
        label: createdTrainingData.name,
        value: createdTrainingData.id,
      };

      setFieldValue('intent', intentObject);
    }
    /* eslint-disable-next-line */
  }, [createdTrainingData]);

  useEffect(() => {
    setFieldValue('disabled', messageDisabled);
    /* eslint-disable-next-line */
  }, [messageDisabled]);

  const getConnectedParentConditionIds = async () => {
    const ids = await getConnectedParentConditions(appId);
    if (ids.length > 0) setConnectedParentConditionIds(ids);
  };

  const getIntents = async () => {
    const intents = await getTrainingData(appId);

    if (intents?.content?.length > 0) {
      const options = getSelectOptionObjects(intents.content, 'name', 'id');
      setIntentOptions(options);
    }
  };

  const getConnectedIntentIds = () => {
    if (!messages.length) return [];

    const connectedIntents: string[] = [];

    messages.forEach(message => {
      if (message.intent) {
        connectedIntents.push(message.intent);
      }
    });

    return setConnectedIntens(connectedIntents);
  };

  const getParentConditionGroupedObjects = () => {
    if (!messages.length) return [];

    const groupedObjects: SelectGroupModel[] = messages.map((message: QuestionnaireMessage) => {
      return {
        label: message.text,
        parentLabel: `${message.id}`,
        options: message.answers
          .filter(answer => !getIsParentConditionOptionDisabled(Number(answer.id)))
          .map((answer: QuestionnaireAnswer) => ({
            parentLabel: `${message.id}`,
            label:
              answer.text === 'NOT_RENDERED' || answer.text === 'FREE_TEXT_INPUT'
                ? `${t('questionnaire.freeTextQuestion')}`
                : `${answer.text}`,
            value: Number(answer.id),
          })),
      };
    });

    const filteredGroupedObjects = groupedObjects.filter(o => o.label !== selectedMessage?.text);

    return setParentConditionOptions(filteredGroupedObjects);
  };


  const getNextMessageObjects = () => {
    if (!messages.length) return [];

    const options: SelectModel[] = messages.map((message: QuestionnaireMessage) => {
      return {
        label: `[${message.id}] ${message.type} - ${message.text}`,
        value: Number(message.id),
      };
    });
    const filteredOptions = options.filter(o => o.value !== selectedMessage?.id);

    return setNextMessageIdIfDisabledOptions(filteredOptions);
  };


  const getIsParentConditionOptionDisabled = (value: number) => {
    if (!connectedParentConditionIds.length) return false;

    if (
      connectedParentConditionIds.includes(value) &&
      selectedMessage?.parentAnswers.map(a => a.id)?.includes(value)
    ) {
      return false;
    }

    return connectedParentConditionIds.includes(value);
  };

  const getIsIntentOptionDisabled = (label: string) => {
    if (!connectedIntents.length) return false;
    return connectedIntents.includes(label);
  };

  const getSelectDisplayLabel = (label: SelectModel) => {
    const optGroup = _.find(parentConditionOptions, { options: [{ value: label.value }] });

    if (optGroup) {
      return `[${label.parentLabel || optGroup.parentLabel}] ${label.label} - ${optGroup.label}`;
    }

    return `[${label.parentLabel}] ${label.label}`;
  };

  const getSelectDisplayLabelOnNextMessageId = (label: SelectModel) => {
    const optGroup = _.find(nextMessageIdIfDisabledOptions, { value: label.value });
    if (optGroup) {
      return optGroup.label;
    }
    return `${label.label}`;
  };

  return (
    <Fragment>
      {selectedMessage?.slug && (
        <FormGroup>
          <Label for="slug">{t('common.slug')}</Label>
          <Input type="text" value={selectedMessage.slug} disabled/>
        </FormGroup>
      )}

      {parentConditionOptions.length > 0 && (
        <FormGroup>
          <Label for="parentConditions">{t('questionnaire.parentConditions')}</Label>
          <ReactSelect
            name="parentConditions"
            options={parentConditionOptions}
            getOptionLabel={(label: SelectModel) => getSelectDisplayLabel(label)}
            format
            isMulti
          />
        </FormGroup>
      )}

      <Row>
        <Col>
          <FormGroup>
            <Switch
              name="disabled"
              variant="pill"
              color="info"
              label="questionnaire.messageDisabled"
              labelPosition="before"
              checked={selectedMessage?.disabled}
              onChanged={(checked: boolean) => setMessageDisabled(checked)}
            />
          </FormGroup>
        </Col>
      </Row>
      {(selectedMessage?.disabled || messageDisabled) && nextMessageIdIfDisabledOptions.length > 0 && (
        <Row>
          <Col>
            <FormGroup>
              <Label for="nextMessageIdIfDisabled">{t('questionnaire.nextMessageIdIfDisabled')}</Label>
              <ReactSelect
                name="nextMessageIdIfDisabled"
                options={nextMessageIdIfDisabledOptions}
                getOptionLabel={(label: SelectModel) => getSelectDisplayLabelOnNextMessageId(label)}
                isClearable
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      {!(selectedMessage?.type || messageType).match(messageTypesToExcludeRegex) && (
        <Fragment>
          <FormGroup>
            <Label for="intent">{t('common.intent')}</Label>
            <div className="d-flex flex-row">
              <ReactSelect
                className={styles.select}
                name="intent"
                options={intentOptions}
                isOptionDisabled={(option: SelectModel) => getIsIntentOptionDisabled(option.label)}
                isClearable
              />

              <button
                type="button"
                className="btn btn-ghost-info ml-1"
                onClick={() => setTrainingDataModalOpen(true)}
              >
                <i className="fas fa-plus"/>
              </button>
            </div>
          </FormGroup>

          <TrainingDataCreateModal
            isOpen={isTrainingDataModalOpen}
            toggleModal={() => setTrainingDataModalOpen(false)}
            isInQuestionnaire
          />
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  messages: state.questionnaireMessages.resources.data,
  trainingData: state.trainingData.resources.data.content || [],
  createdTrainingData: state.trainingData.create.data,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultForm);
