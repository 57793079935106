import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody, Alert, Button } from 'reactstrap';
import { FormikValues } from 'formik';

import {
  getPersonas,
  getPersona,
  createPersona,
  updatePersona,
  deletePersona,
} from '../../redux/actions/personas.action';
import { PersonaModel } from '../../shared/models/persona.model';
import { StateModel } from '../../shared/models/default-state.model';

import PersonasTable from './components/PersonasTable';
import PersonasModal from './components/PersonasModal';
import Spinner from '../../shared/components/Spinner';
import CheckPermission from '../../shared/components/CheckPermission';
import ConfirmModal from '../../shared/components/modals/ConfirmModal';

interface Props extends RouteComponentProps {
  getPersonas: Function;
  personas: StateModel;
  getPersona: Function;
  persona: StateModel;
  createPersona: Function;
  personaCreate: StateModel;
  updatePersona: Function;
  personaUpdate: StateModel;
  deletePersona: Function;
  personaDelete: StateModel;
}

const Personas: React.FC<Props> = ({
  getPersonas,
  personas,
  getPersona,
  persona,
  createPersona,
  personaCreate,
  updatePersona,
  personaUpdate,
  deletePersona,
  personaDelete,
}) => {
  const { t } = useTranslation();
  const [isUserModalOpen, setUserModalOpen] = useState<boolean>(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<PersonaModel | null>(null);
  const [modalEvent, setModalEvent] = useState<string>('');

  useEffect(() => {
    getPersonas();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { loaded, error } = personaCreate;
    if (loaded && !error && isUserModalOpen) {
      getPersonas();
      toggleUserModal();
    }
    // eslint-disable-next-line
  }, [personaCreate.loaded]);

  useEffect(() => {
    const { loaded, error } = personaUpdate;
    if (loaded && !error && isUserModalOpen) {
      getPersonas();
      toggleUserModal();
    }
    // eslint-disable-next-line
  }, [personaUpdate.loaded]);

  useEffect(() => {
    const { loaded, error } = personaDelete;
    if (loaded && !error && isConfirmModalOpen) {
      getPersonas();
      toggleConfirmModal();
    }
    // eslint-disable-next-line
  }, [personaDelete.loaded]);

  const toggleUserModal = (event?: string, persona?: PersonaModel) => {
    if (event) {
      setModalEvent(event);
    }

    if (persona) {
      setSelectedUser(persona);
    } else {
      setSelectedUser(null);
    }

    return setUserModalOpen(!isUserModalOpen);
  };

  const toggleConfirmModal = (persona?: PersonaModel) => {
    setConfirmModalOpen(!isConfirmModalOpen);

    if (persona) {
      setSelectedUser(persona);
    }
  };

  const handleSubmit = async (values: FormikValues) => {
    const isEditing = modalEvent === 'edit';

    if (isEditing && selectedUser) {
      const newPersonaValues = {
        name: values.name || selectedUser.name,
        avatar: values.avatar || selectedUser.avatar,
      };

      updatePersona(selectedUser.id!, newPersonaValues);
    } else {
      const newPersonaValues = {
        name: values.name,
        avatar: values.avatar,
      };

      createPersona(newPersonaValues);
    }
  };

  const handleDelete = () => {
    if (selectedUser) {
      deletePersona(selectedUser.id!);
    }
  };

  const isLoading = personaCreate.loading || personaUpdate.loading;

  return (
    <Fragment>
      <Row className="mb-4">
        <Col>
          <h5>{t('common.personas')}</h5>
        </Col>

        <Col className="d-flex justify-content-end">
          <CheckPermission variant="enableIf" permissions={['persona_create']}>
            <Button color="success" onClick={() => toggleUserModal('create')}>
              {t('personas.newPersona')}
            </Button>
          </CheckPermission>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardBody>
              {personas.loaded && personas.data.length > 0 && (
                <PersonasTable
                  personas={personas.data}
                  toggleUserModal={toggleUserModal}
                  toggleConfirmModal={toggleConfirmModal}
                />
              )}

              {personas.loading && (
                <div className="d-flex justify-content-center">
                  <Spinner loading={personas.loading} size="2x" />
                </div>
              )}

              {personas.loaded && !personas.data.length && (
                <Alert color="info" className="text-center mb-0">
                  {t('personas.noPersonasFound')}
                </Alert>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <PersonasModal
        selectedUser={selectedUser}
        personas={personas.data}
        isOpen={isUserModalOpen}
        isLoading={isLoading}
        modalEvent={modalEvent}
        toggleUserModal={toggleUserModal}
        handleSubmit={handleSubmit}
      />

      <ConfirmModal
        title={t('personas.deletePersona')}
        text={t('personas.deletePersonaConfirmMessage')}
        cancel={() => toggleConfirmModal()}
        confirm={() => handleDelete()}
        isOpen={isConfirmModalOpen}
        isLoading={personaDelete.loading}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  personas: state.personas,
  personaCreate: state.personaCreate,
  personaUpdate: state.personaUpdate,
  personaDelete: state.personaDelete,
  persona: state.persona,
});

const mapDispatchToProps = {
  getPersonas,
  createPersona,
  updatePersona,
  deletePersona,
  getPersona,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Personas));
