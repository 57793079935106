import { Dispatch } from 'redux';
import { ApplicantsQueryParams } from '../../shared/models/query-params.model';
import { getPositionApplicants as getPositionApplicantsService } from '../services/position-applicants.service';

export const GET_POSITION_APPLICANTS_REQUEST =
  'position-applicants/GET_POSITION_APPLICANTS_REQUEST';
export const GET_POSITION_APPLICANTS_SUCCESS =
  'position-applicants/GET_POSITION_APPLICANTS_SUCCESS';
export const GET_POSITION_APPLICANTS_ERROR = 'position-applicants/GET_POSITION_APPLICANTS_ERROR';

export const CLEAR_POSITION_APPLICANTS = 'position-applicants/CLEAR_POSITION_APPLICANTS';

export const getPositionApplicants = (
  applicationId: string,
  queryParams: ApplicantsQueryParams,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_POSITION_APPLICANTS_REQUEST,
    });

    try {
      dispatch({
        type: GET_POSITION_APPLICANTS_SUCCESS,
        payload: await getPositionApplicantsService(applicationId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_POSITION_APPLICANTS_ERROR,
        error,
      });
    }
  };
};

export const clearPositionApplicants = () => {
  return {
    type: CLEAR_POSITION_APPLICANTS,
  };
};
