import { StateModel } from 'src/shared/models';
import {
  GET_SIGNED_IN_EMPLOYEE_REPORT,
  GET_SIGNED_IN_EMPLOYEE_REPORT_SUCCESS,
  GET_SIGNED_IN_EMPLOYEE_REPORT_ERROR,
  GET_NOT_SIGNED_IN_EMPLOYEE_REPORT,
  GET_NOT_SIGNED_IN_EMPLOYEE_REPORT_SUCCESS,
  GET_NOT_SIGNED_IN_EMPLOYEE_REPORT_ERROR,
  GET_SIGNED_IN_EMPLOYEE_LIST,
  GET_SIGNED_IN_EMPLOYEE_LIST_SUCCESS,
  GET_SIGNED_IN_EMPLOYEE_LIST_ERROR,
} from '../actions/employee-reports.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: { signedInEmployeeReports: [], notSignedInEmployeeReports: [], signedInUsersReports: [] },
};

export const employeeReportsReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case GET_SIGNED_IN_EMPLOYEE_REPORT:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_SIGNED_IN_EMPLOYEE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          signedInEmployeeReports: payload,
          notSignedInEmployeeReports: state.data.notSignedInEmployeeReports,
          signedInUsersReports: state.data.signedInUsersReports,
        },
      };
    case GET_SIGNED_IN_EMPLOYEE_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };
    case GET_NOT_SIGNED_IN_EMPLOYEE_REPORT:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_NOT_SIGNED_IN_EMPLOYEE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          signedInEmployeeReports: state.data.signedInEmployeeReports,
          notSignedInEmployeeReports: payload,
          signedInUsersReports: state.data.signedInUsersReports,
        },
      };
    case GET_NOT_SIGNED_IN_EMPLOYEE_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };
    case GET_SIGNED_IN_EMPLOYEE_LIST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_SIGNED_IN_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          signedInEmployeeReports: state.data.signedInEmployeeReports,
          notSignedInEmployeeReports: state.data.notSignedInEmployeeReports,
          signedInUsersReports: payload,
        },
      };
    case GET_SIGNED_IN_EMPLOYEE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };
    default:
      return state;
  }
};
