import { Dispatch } from 'redux';
import {
  getAveragedIssueReplyTimeByContextDataReportService,
  getAveragedIssueReplyTimeByDashboardUserReportService,
} from '../services/issue-report.service';
import { DateRangeQueryParams } from 'src/shared/models';

export const GET_AVERAGED_ISSUE_REPLY_TIME_BY_CONTEXT_DATA_REPORT = 'employeeReport/GET_AVERAGED_ISSUE_REPLY_TIME_BY_CONTEXT_DATA_REPORT';
export const GET_AVERAGED_ISSUE_REPLY_TIME_BY_CONTEXT_DATA_REPORT_SUCCESS =
  'employeeReport/GET_AVERAGED_ISSUE_REPLY_TIME_BY_CONTEXT_DATA_REPORT_SUCCESS';
export const GET_AVERAGED_ISSUE_REPLY_TIME_BY_CONTEXT_DATA_REPORT_ERROR =
  'employeeReport/GET_AVERAGED_ISSUE_REPLY_TIME_BY_CONTEXT_DATA_REPORT_ERROR';

export const GET_AVERAGED_ISSUE_REPLY_TIME_BY_DASHBOARD_USER_REPORT = 'employeeReport/GET_AVERAGED_ISSUE_REPLY_TIME_BY_DASHBOARD_USER_REPORT';
export const GET_AVERAGED_ISSUE_REPLY_TIME_BY_DASHBOARD_USER_REPORT_SUCCESS =
  'employeeReport/GET_AVERAGED_ISSUE_REPLY_TIME_BY_DASHBOARD_USER_REPORT_SUCCESS';
export const GET_AVERAGED_ISSUE_REPLY_TIME_BY_DASHBOARD_USER_REPORT_ERROR =
  'employeeReport/GET_AVERAGED_ISSUE_REPLY_TIME_BY_DASHBOARD_USER_REPORT_ERROR';

export const getAveragedIssueReplyTimeByContextDataReport = (appId: string, params: DateRangeQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_AVERAGED_ISSUE_REPLY_TIME_BY_CONTEXT_DATA_REPORT,
    });

    try {
      const response = await getAveragedIssueReplyTimeByContextDataReportService(appId, params);
      response.forEach((v, i) => (v.id = i));

      dispatch({
        type: GET_AVERAGED_ISSUE_REPLY_TIME_BY_CONTEXT_DATA_REPORT_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_AVERAGED_ISSUE_REPLY_TIME_BY_CONTEXT_DATA_REPORT_ERROR,
        error,
      });
    }
  };
};

export const getAveragedIssueReplyTimeByDashboardUserReport = (appId: string, params: DateRangeQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_AVERAGED_ISSUE_REPLY_TIME_BY_DASHBOARD_USER_REPORT,
    });

    try {
      const response = await getAveragedIssueReplyTimeByDashboardUserReportService(appId, params);
      response.forEach((v, i) => (v.id = i));

      dispatch({
        type: GET_AVERAGED_ISSUE_REPLY_TIME_BY_DASHBOARD_USER_REPORT_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_AVERAGED_ISSUE_REPLY_TIME_BY_DASHBOARD_USER_REPORT_ERROR,
        error,
      });
    }
  };
};
