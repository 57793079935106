import { StateModel } from '../../shared/models/default-state.model';
import { PositionModel } from '../../shared/models/positions.model';
import { positionSidebarTypes } from '../../shared/constants/positions.constants';
import {
  GET_POSITION_REQUEST,
  GET_POSITION_SUCCESS,
  GET_POSITION_ERROR,
  GET_POSITIONS_REQUEST,
  GET_POSITIONS_SUCCESS,
  GET_POSITIONS_ERROR,
  CREATE_POSITION_REQUEST,
  CREATE_POSITION_SUCCESS,
  CREATE_POSITION_ERROR,
  UPDATE_POSITION_REQUEST,
  UPDATE_POSITION_SUCCESS,
  UPDATE_POSITION_ERROR,
  UPDATE_POSITION_ORDER_REQUEST,
  UPDATE_POSITION_ORDER_SUCCESS,
  UPDATE_POSITION_ORDER_ERROR,
  UPDATE_POSITION_STATUS_REQUEST,
  UPDATE_POSITION_STATUS_SUCCESS,
  UPDATE_POSITION_STATUS_ERROR,
  DELETE_POSITION_REQUEST,
  DELETE_POSITION_SUCCESS,
  DELETE_POSITION_ERROR,
  CLONE_POSITION_REQUEST,
  CLONE_POSITION_SUCCESS,
  CLONE_POSITION_ERROR,
  EXPORT_POSITIONS_REQUEST,
  EXPORT_POSITIONS_SUCCESS,
  EXPORT_POSITIONS_ERROR,
  SET_QUESTION_TO_EDIT,
  SET_POSITION_SIDEBAR_TYPE,
  CLEAR_POSITION,
  CLEAR_CREATED_POSITION,
} from '../actions/positions.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {
    position: {
      loading: false,
      loaded: false,
      error: null,
      data: {},
    },
    positions: {
      loading: false,
      loaded: false,
      error: null,
      data: {},
    },
    createPosition: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    updatePosition: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    updatePositionOrder: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    updatePositionStatus: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    deletePosition: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    clonePosition: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    exportPosition: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    questionToEdit: null,
    sidebarType: positionSidebarTypes.SHORTCUTS,
    prevSidebarType: null,
  },
};

export const positionsReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_POSITION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          position: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case GET_POSITION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          position: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case GET_POSITION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          position: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case GET_POSITIONS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          positions: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case GET_POSITIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          positions: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case GET_POSITIONS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          positions: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case CREATE_POSITION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          createPosition: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case CREATE_POSITION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          createPosition: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case CREATE_POSITION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          createPosition: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case UPDATE_POSITION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          updatePosition: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case UPDATE_POSITION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          updatePosition: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
          positions: {
            ...state.data.positions,
            data: {
              ...state.data.positions.data,
              content: state.data.positions.data.content.map((p: PositionModel) => {
                if (p.id === action.payload.id) {
                  return action.payload;
                }
                return p;
              }),
            },
          },
        },
      };

    case UPDATE_POSITION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          updatePosition: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case UPDATE_POSITION_ORDER_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          updatePositionOrder: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case UPDATE_POSITION_ORDER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          updatePositionOrder: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
          positions: {
            ...state.data.positions,
            data: {
              ...state.data.positions.data,
              content: state.data.positions.data.content.map((p: PositionModel) => {
                if (p.id === action.payload.id) {
                  return action.payload;
                }
                return p;
              }),
            },
          },
        },
      };

    case UPDATE_POSITION_ORDER_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          updatePositionOrder: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case UPDATE_POSITION_STATUS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          updatePositionStatus: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case UPDATE_POSITION_STATUS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          updatePositionStatus: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
          positions: {
            ...state.data.positions,
            data: {
              ...state.data.positions.data,
              content: state.data.positions.data.content.map((p: PositionModel) => {
                if (p.id === action.payload.id) {
                  return action.payload;
                }
                return p;
              }),
            },
          },
        },
      };

    case UPDATE_POSITION_STATUS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          updatePositionStatus: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case DELETE_POSITION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          deletePosition: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case DELETE_POSITION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          deletePosition: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case DELETE_POSITION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          deletePosition: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case CLONE_POSITION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          clonePosition: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case CLONE_POSITION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          clonePosition: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case CLONE_POSITION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          clonePosition: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case EXPORT_POSITIONS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          exportPositions: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case EXPORT_POSITIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          exportPositions: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case EXPORT_POSITIONS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          exportPositions: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case SET_QUESTION_TO_EDIT:
      return {
        ...state,
        data: {
          ...state.data,
          questionToEdit: action.payload,
        },
      };

    case SET_POSITION_SIDEBAR_TYPE:
      return {
        ...state,
        data: {
          ...state.data,
          sidebarType: action.payload,
          prevSidebarType: state.data.sidebarType,
        },
      };

    case CLEAR_POSITION:
      return {
        ...state,
        data: {
          ...state.data,
          position: {
            loading: false,
            loaded: false,
            error: null,
            data: {},
          },
        },
      };

    case CLEAR_CREATED_POSITION:
      return {
        ...state,
        data: {
          ...state.data,
          createPosition: {
            loading: false,
            loaded: false,
            error: null,
            data: {},
          },
        },
      };

    default:
      return state;
  }
};

export default positionsReducer;
