import { StateModel } from '../../shared/models/default-state.model';
import {
  GET_FEEDBACK_SUMMARY_REQUEST,
  GET_FEEDBACK_SUMMARY_SUCCESS,
  GET_FEEDBACK_SUMMARY_ERROR,
  GET_FEEDBACKS_REQUEST,
  GET_FEEDBACKS_SUCCESS,
  GET_FEEDBACKS_ERROR,
  GET_PLATFORMS_WITH_FEEDBACK_REQUEST,
  GET_PLATFORMS_WITH_FEEDBACK_SUCCESS,
  GET_PLATFORMS_WITH_FEEDBACK_ERROR,
  EXPORT_FEEDBACKS_REQUEST,
  EXPORT_FEEDBACKS_SUCCESS,
  EXPORT_FEEDBACKS_ERROR,
} from '../actions/feedbacks.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export const feedbackSummaryReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_FEEDBACK_SUMMARY_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case GET_FEEDBACK_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_FEEDBACK_SUMMARY_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const feedbacksReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_FEEDBACKS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case GET_FEEDBACKS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };

    case GET_FEEDBACKS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const platformsWithFeedbackReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_PLATFORMS_WITH_FEEDBACK_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case GET_PLATFORMS_WITH_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };

    case GET_PLATFORMS_WITH_FEEDBACK_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const exportFeedbacksReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case EXPORT_FEEDBACKS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case EXPORT_FEEDBACKS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };

    case EXPORT_FEEDBACKS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
