import React from 'react';
import { Row } from 'reactstrap';

import { TrainingDataModel } from 'src/shared/models/training-data.model';

import TrainingDataCard from './TrainingDataCard';

interface Props {
  trainingData: TrainingDataModel[];
}

const TrainingDataCards: React.FC<Props> = ({ trainingData }) => (
  <Row>
    {trainingData?.map(td => (
      <TrainingDataCard key={td.id} trainingData={td} />
    ))}
  </Row>
);

export default TrainingDataCards;
