import React from 'react';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import cx from 'classnames';
import moment from 'moment';

import { ConversationHistoryModel } from 'src/redux/actions/conversation-history.action';

import Facebook from './components/Facebook';
import Hangouts from './components/Hangouts';
import LiveChat from './components/LiveChat';
import Viber from './components/Viber';

import chatbossAvatar from '../../../../../assets/img/avatars/chatboss.png';
import userAvatar from '../../../../../assets/img/avatars/user.svg';

import styles from './Messages.module.scss';

interface Props {
  message: ConversationHistoryModel;
  createdAt: string | undefined;
}

const Message: React.FC<Props> = ({ message, createdAt }) => {
  const { t } = useTranslation();

  const messageId = `message-${message.id}`;
  const messageIsLastId = message.isLastMessage ? 'lastMessage' : '';
  const avatarSrc = message.isOutgoingMessage ? chatbossAvatar : userAvatar;

  const messageClassnames = cx(styles.messageContainer, {
    [styles.botMessage]: message.isOutgoingMessage,
    [styles.userMessage]: !message.isOutgoingMessage,
    [styles.noAvatar]: !message.showAvatar,
  });

  const selectMessagingPlatform = (
    messagingPlatform: string,
    message: ConversationHistoryModel,
  ) => {
    switch (messagingPlatform) {
      case 'Livechat':
        return <LiveChat message={message} />;
      case 'Facebook':
        return <Facebook message={message} />;
      case 'HangoutsChat':
        return <Hangouts message={message} />;
      case 'Viber':
        return <Viber message={message} />;
      default:
        throw new Error(`Invalid messaging platform: ${messagingPlatform}`);
    }
  };

  const isHighligtedMessage = (message: ConversationHistoryModel) => {
    const checkFormat = 'MMMDDYYYYLTS';
    return (
      createdAt &&
      moment(message.creationTimestamp).format(checkFormat) ===
        moment(createdAt).format(checkFormat)
    );
  };

  const higlightMessageClassIfNeeded = (message: ConversationHistoryModel) => {
    return isHighligtedMessage(message) ? ' bg-secondary p-2 currentHighlighted' : '';
  };

  return (
    <div key={message.id}>
      {message.showTimeStamp && (
        <p className={styles.messageDate}>
          {moment(message.creationTimestamp).format('MMM DD[,] YYYY [at] LT')}
        </p>
      )}

      <div
        id={messageIsLastId}
        className={messageClassnames + higlightMessageClassIfNeeded(message)}
      >
        {message.showAvatar && <img src={avatarSrc} className={styles.avatar} alt="Avatar" />}
        <div className={styles.messageContent} id={messageId}>
          {selectMessagingPlatform(message.messagingPlatform, message)}
          {message.showSender && (
            <span className={styles.sender}>{`${t('conversationHistory.sentBy')} ${
              message.sender
            }`}</span>
          )}
        </div>

        <UncontrolledTooltip placement="top" target={messageId}>
          {moment(message.creationTimestamp).format('LLL')}
        </UncontrolledTooltip>
      </div>
    </div>
  );
};

export default Message;
