import React, { FC } from 'react';
import { Label } from 'reactstrap';

interface PlusLabelProps {
  title: string;
  labelFor: string;
  id: string;
  hidePlusButton?: boolean;
}

const PlusLabel: FC<PlusLabelProps> = ({ title, labelFor, id, hidePlusButton }) => {
  return (
    <div className="d-flex align-items-center">
      <Label for={labelFor}>{title}</Label>
      {!hidePlusButton ? (
        <button id={id} type="button" className="btn btn-ghost-info ml-1">
          <i className="fas fa-plus" />
        </button>
      ) : null}
    </div>
  );
};

export default PlusLabel;
