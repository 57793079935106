import React from 'react';
import { TFunction } from 'i18next';
import { uniqueId } from 'lodash';
import {
  ContentTranslationSurveyListModel,
  SurveyTranslationLevel,
  SurveyQuestionAnswerType,
  GetSurveyTranslationsResponse,
  SurveyTranslation,
  ContentTranslationSurveyListItem,
} from 'src/shared/models/content-translations-survey.model';
import { safeFilter } from 'src/shared/utils';
import {
  createSurveyTranslation,
  editSurveyTranslation,
  createSurveyQuestionTranslation,
  editSurveyQuestionTranslation,
  createSurveyQuestionAnswerTranslation,
  editSurveyQuestionAnswerTranslation,
  deleteSurveyQuestionAnswerTranslation,
  deleteSurveyQuestionTranslation,
  deleteSurveyTranslation,
} from 'src/redux/services/content-translation-survey.service';

export const getSurveyTranslationsModel = (
  contentTranslations: GetSurveyTranslationsResponse,
  selectedLanguage: string | undefined,
  t: TFunction,
) => {
  if (!Array.isArray(contentTranslations)) return [];
  const model: ContentTranslationSurveyListModel = [];
  contentTranslations.forEach(surveyTranslation => {
    const filtered = safeFilter(
      surveyTranslation.translations.filter(t => t.languageCode === selectedLanguage),
    );

    const translatedNameField = filtered?.name;
    const translatedDescriptionField = filtered?.description;
    const translatedStartMessageField = filtered?.startMessage;

    model.push({
      id: Number.parseInt(uniqueId()),
      surveyId: surveyTranslation.id,
      displayedIdentifier: (
        <b>
          [{t('translations.surveyTranslations.survey')}] ID={surveyTranslation.id}
        </b>
      ),
      fieldName: 'name',
      originalText: surveyTranslation.name,
      translatedText: translatedNameField,
      level: SurveyTranslationLevel.Base,
    });
    model.push({
      id: Number.parseInt(uniqueId()),
      surveyId: surveyTranslation.id,
      displayedIdentifier: <hr />,
      fieldName: 'description',
      originalText: surveyTranslation.description,
      translatedText: translatedDescriptionField,
      level: SurveyTranslationLevel.Base,
    });
    model.push({
      id: Number.parseInt(uniqueId()),
      surveyId: surveyTranslation.id,
      displayedIdentifier: <hr />,
      fieldName: 'startMessage',
      originalText: surveyTranslation.startMessage,
      translatedText: translatedStartMessageField,
      level: SurveyTranslationLevel.Base,
    });

    surveyTranslation.questions.forEach(questionTranslation => {
      const filteredQuestion = safeFilter(
        questionTranslation.translations.filter(t => t.languageCode === selectedLanguage),
      );

      const translatedQuestionTextField = filteredQuestion?.text;

      model.push({
        id: Number.parseInt(uniqueId()),
        surveyId: questionTranslation.surveyId,
        surveyQuestionId: questionTranslation.id,
        displayedIdentifier: (
          <div className="d-flex align-items-center">
            <div style={{ width: 50 }}>
              <hr />
            </div>
            <div>
              <b>
                [{t('translations.surveyTranslations.questions')}] ID={questionTranslation.id}
              </b>
            </div>
          </div>
        ),
        fieldName: 'text',
        originalText: questionTranslation.text,
        translatedText: translatedQuestionTextField,
        level: SurveyTranslationLevel.Question,
      });

      questionTranslation.surveyQuestionAnswers.forEach(sqa => {
        if (sqa.type === SurveyQuestionAnswerType.OPTION) {
          const filteredAnswer = safeFilter(
            sqa.translations.filter(t => t.languageCode === selectedLanguage),
          );
          const translatedAnswerTextField = filteredAnswer?.text;

          model.push({
            id: Number.parseInt(uniqueId()),
            surveyId: surveyTranslation.id,
            surveyQuestionId: sqa.surveyQuestionId,
            surveyQuestionAnswerId: sqa.id,
            displayedIdentifier: (
              <div className="d-flex align-items-center">
                <div style={{ width: 100 }}>
                  <hr />
                </div>
                <div>
                  <b>
                    [{t('translations.surveyTranslations.surveyQuestionAnswers')}] ID={sqa.id}
                  </b>
                </div>
              </div>
            ),
            fieldName: 'text',
            originalText: sqa.text,
            translatedText: translatedAnswerTextField,
            level: SurveyTranslationLevel.QuestionAnswer,
          });
        }
      });
    });
  });
  return model;
};

export const handleSurveyEdit = async (
  item: ContentTranslationSurveyListItem,
  contentTranslationsSurvey: GetSurveyTranslationsResponse,
  newValue: string,
  selectedLanguage: string,
  appId: string,
) => {
  const isCreate =
    (!item.translatedText || item.translatedText.length === 0) && newValue.length !== 0;
  switch (item.level) {
    case SurveyTranslationLevel.Base: {
      const filtered = safeFilter(contentTranslationsSurvey.filter(t => t.id === item.surveyId));
      const filteredTranslations = filtered?.translations.filter(
        (t: SurveyTranslation) => t.languageCode === selectedLanguage,
      );
      const noFieldsAreTranslated = filteredTranslations.length === 0;
      if (isCreate && noFieldsAreTranslated) {
        await createSurveyTranslation(appId, item.surveyId!, {
          languageCode: selectedLanguage!,
          [item.fieldName]: newValue,
        });
      } else {
        await editSurveyTranslation(appId, item.surveyId!, selectedLanguage!, {
          [item.fieldName]: newValue,
        });
      }
      break;
    }
    case SurveyTranslationLevel.Question: {
      if (isCreate) {
        await createSurveyQuestionTranslation(appId, item.surveyId!, item.surveyQuestionId!, {
          surveyQuestionId: item.surveyQuestionId!,
          languageCode: selectedLanguage!,
          text: newValue,
        });
      } else {
        await editSurveyQuestionTranslation(
          appId,
          item.surveyId!,
          item.surveyQuestionId!,
          selectedLanguage!,
          { [item.fieldName]: newValue },
        );
      }
      break;
    }
    case SurveyTranslationLevel.QuestionAnswer: {
      if (isCreate) {
        await createSurveyQuestionAnswerTranslation(
          appId,
          item.surveyId!,
          item.surveyQuestionId!,
          item.surveyQuestionAnswerId!,
          { languageCode: selectedLanguage!, text: newValue },
        );
      } else {
        await editSurveyQuestionAnswerTranslation(
          appId,
          item.surveyId!,
          item.surveyQuestionId!,
          selectedLanguage!,
          item.surveyQuestionAnswerId!,
          { text: newValue },
        );
      }
      break;
    }
  }
};

export const handleSurveyDelete = async (
  item: ContentTranslationSurveyListItem,
  selectedLanguage: string,
  appId: string,
) => {
  if (!item.translatedText) return;
  switch (item.level) {
    case SurveyTranslationLevel.Base: {
      await deleteSurveyTranslation(appId, item.surveyId!, selectedLanguage!, {});
      break;
    }
    case SurveyTranslationLevel.Question: {
      await deleteSurveyQuestionTranslation(
        appId,
        item.surveyId!,
        item.surveyQuestionId!,
        selectedLanguage!,
      );
      break;
    }
    case SurveyTranslationLevel.QuestionAnswer: {
      await deleteSurveyQuestionAnswerTranslation(
        appId,
        item.surveyId!,
        item.surveyQuestionId!,
        item.surveyQuestionAnswerId!,
        selectedLanguage!,
      );
      break;
    }
  }
};
