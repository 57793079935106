import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Alert } from 'reactstrap';
import { ResponsiveLine } from '@nivo/line';

interface Props {
  chartData: any[];
  title: React.ReactElement;
}

const ApplicationEfficiencyReport: React.FC<Props> = ({ chartData, title }) => {
  const { t } = useTranslation();

  if (!chartData) {
    return (
      <Alert color="info" className="text-center mb-0">
        {t('usageStatistics.noDataForPeriod')}
      </Alert>
    );
  }

  return (
    <Row>
      <Col>
        <h6 className="mt-4">{title}</h6>

        <div style={{ height: 500 }}>
          <ResponsiveLine
            data={chartData}
            curve="monotoneX"
            margin={{ top: 40, right: 60, bottom: 110, left: 60 }}
            enablePointLabel
            // xScale={{ type: 'point' }}
            // yScale={{ type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendOffset: -40,
              legendPosition: 'middle',
            }}
            colors={{ scheme: 'nivo' }}
            pointSize={5}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={5}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel={
              function (e: any) {
                return e.y.toFixed(2);
              } as any
            }
            pointLabelYOffset={-12}
            useMesh
            enableArea
            areaOpacity={0.05}
            legends={[
              {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 100,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 100,
                itemHeight: 10,
                itemOpacity: 1,
                symbolSize: 16,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      </Col>
    </Row>
  );
};

export default ApplicationEfficiencyReport;
