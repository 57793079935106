import { post } from '../../shared/services/http.service';
import { TrainingDatasetModel } from '../../shared/models/dataset.model';

export const importTrainingDataset = async (applicationId: number, data: TrainingDatasetModel) => {
  return post(`/applications/${applicationId}/nlu/import/csv`, {
    data,
  });
};

export default importTrainingDataset;
