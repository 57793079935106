import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import moment from 'moment';
import { UserModel } from 'src/shared/models/user.model';

import CheckPermission from 'src/shared/components/CheckPermission';
import defaultAvatar from 'src/assets/img/avatars/default.png';

import styles from '../Users.module.scss';

interface Props {
  users: UserModel[];
  toggleUserModal: (event: string, user?: UserModel | undefined) => void;
  toggleConfirmModal: Function;
}

const UsersTable: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { users, toggleUserModal, toggleConfirmModal } = props;

  const renderUserDetails = (user: UserModel) => {
    return (
      <div className={styles.userDetails}>
        <img src={user.avatar || defaultAvatar} alt="User Avatar" />
        <div>
          <p>
            {user.lastName} {user.firstName}
          </p>
          <p>{user.userGroupName}</p>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <Table className="mb-0" striped hover responsive size="sm">
        <thead>
          <tr>
            <th className={styles.id}>{t('common.id')}</th>
            <th className={styles.name}>
              {t('common.name')} / {t('users.userGroup')}
            </th>
            <th className={styles.email}>{t('common.email')}</th>
            <th className={styles.lastLogin}>{t('users.lastLogin')}</th>
            <th className={styles.operations}>{t('common.operations')}</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user: UserModel) => {
            return (
              <tr key={user.id}>
                <td className={styles.id}>{user.id}</td>
                <td>{renderUserDetails(user)}</td>
                <td>{user.email}</td>
                <td>
                  {user.lastLoginAt
                    ? moment(user.lastLoginAt).format('LLL')
                    : '-'}
                </td>
                <td className={styles.operations}>
                  <CheckPermission
                    variant="enableIf"
                    permissions={['users_update']}
                  >
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => toggleUserModal('edit', user)}
                    >
                      <i className="far fa-edit" />
                    </button>
                  </CheckPermission>
                  <CheckPermission
                    variant="enableIf"
                    permissions={['users_delete']}
                  >
                    <button
                      type="button"
                      className="btn btn-danger ml-2"
                      onClick={() => toggleConfirmModal(user)}
                    >
                      <i className="far fa-trash-alt" />
                    </button>
                  </CheckPermission>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  );
};

export default UsersTable;
