import { StateModel } from '../../shared/models/default-state.model';
import {
  GET_INTENT_STATISTICS_REQUEST,
  GET_INTENT_STATISTICS_SUCCESS,
  GET_INTENT_STATISTICS_ERROR,
  EXPORT_INTENT_STATISTICS_REQUEST,
  EXPORT_INTENT_STATISTICS_SUCCESS,
  EXPORT_INTENT_STATISTICS_ERROR,
} from '../actions/intent-statistics.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export const intentStatisticsReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_INTENT_STATISTICS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case GET_INTENT_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_INTENT_STATISTICS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const exportIntentStatisticsReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case EXPORT_INTENT_STATISTICS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case EXPORT_INTENT_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case EXPORT_INTENT_STATISTICS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
