import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader } from 'reactstrap';
import { Spinner } from 'src/shared/components';

export interface FolderLoadingProps {
  isLoading: boolean;
  isUploadingFiles: boolean;
  uploadingDetails: {
    total?: number;
    current?: number;
  };
}
const FolderLoading: FC<FolderLoadingProps> = props => {
  const { t } = useTranslation();
  return props.isLoading ? (
    <Row>
      <Col>
        <Card className="animated fadeIn">
          <CardHeader>
            <div className="text-center">
              {props.isUploadingFiles && (
                <div>
                  {t('foldersAndDocuments.uploadInProgress', {
                    total: props.uploadingDetails.total,
                    current: props.uploadingDetails.current,
                  })}
                </div>
              )}
              <br />
              <Spinner loading={true} />
            </div>
          </CardHeader>
        </Card>
      </Col>
    </Row>
  ) : (
    <></>
  );
};

export default FolderLoading;
