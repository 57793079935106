import { del, get, patch, post } from '../../shared/services/http.service';

import { PageQueryParams } from 'src/shared/models';
import { FollowUpNotificationsCreateRequest } from 'src/shared/models/follow-up-notifications.model';

const baseUrl = (applicationId: string) => `/applications/${applicationId}/follow-up-notifications`;

export const getList = async (applicationId: string, params: PageQueryParams) =>
  get(baseUrl(applicationId), { params });

export const create = async (applicationId: string, data: FollowUpNotificationsCreateRequest) =>
  post(baseUrl(applicationId), { data });

export const update = async (
  applicationId: string,
  notificationId: number,
  data: FollowUpNotificationsCreateRequest,
) => patch(`${baseUrl(applicationId)}/${notificationId}`, { data });

export const deleteOne = async (applicationId: string, notificationId: number) =>
  del(`${baseUrl(applicationId)}/${notificationId}`);
