import React from 'react';

import { ExportButton } from 'src/shared/components';

import styles from '../AuditLog.module.scss';

interface Props {
  exportAuditLog: Function;
  disabled: boolean;
}

const AuditLogExport: React.FC<Props> = props => {
  const { exportAuditLog, disabled } = props;

  return (
    <ExportButton
      className={styles.exportButton}
      disabled={disabled}
      onClick={() => exportAuditLog()}
      permission="auditlogs_read"
    />
  );
};

export default AuditLogExport;
