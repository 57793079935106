import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Label, ButtonGroup, Button } from 'reactstrap';
import { Formik, Form, FormikValues } from 'formik';
import uniqueId from 'lodash/uniqueId';

import { SelectModel } from '../../../../../shared/models/common.model';
import {
  PositionCriteraModel,
  PositionQuestionModel,
} from '../../../../../shared/models/positions.model';

import { prefilterCriteriaSchema } from '../../../../../shared/schemas/validation.schema';
import { getSelectOptionObjects } from '../../../../../shared/utils/misc.util';

import { setCreatedOrEditedPositionQuestion } from '../../../../../redux/actions/position-question.action';
import {
  setPositionSidebarType,
  setQuestionToEdit,
} from '../../../../../redux/actions/positions.action';

import TextField from '../../../../../shared/components/form-inputs/TextField';
import ReactSelect from '../../../../../shared/components/form-inputs/ReactSelect';
import AnswerChoiceForm from './components/AnswerChoiceForm';
import SidebarActionButtons from './components/SidebarActionButtons';

import styles from '../../../../Questionnaire/Questionnaire.module.scss';
import { positionSidebarTypes } from '../../../../../shared/constants/positions.constants';
import addToast from '../../../../../shared/utils/notification.util';

interface Props {
  questionToEdit?: PositionQuestionModel;
  criterias: PositionCriteraModel[];
  setPositionSidebarType: Function;
  setQuestionToEdit: Function;
  setCreatedOrEditedPositionQuestion: Function;
}

const PositionQuestionCreateAndEditSidebar: React.FC<Props> = ({
  questionToEdit,
  criterias,
  setPositionSidebarType,
  setQuestionToEdit,
  setCreatedOrEditedPositionQuestion,
}) => {
  const { t } = useTranslation();

  const answerTypes = [
    { value: 'answerChoice', label: `${t('positions.answerChoice')}` },
    { value: 'freeText', label: `${t('positions.freeText')}` },
  ];

  const getInitialCategoryValue = (category: PositionCriteraModel | undefined) => {
    if (!criterias.length || !criteriaOptions.length) return [];
    const isCriteriaCategoryExists = !!criterias.find(criteria => criteria.id === category?.id);
    if (!category || !isCriteriaCategoryExists) return criteriaOptions[0];
    return { label: category.name, value: category.id };
  };

  const [answerType, setAnswerType] = useState<string>(answerTypes[0].value);
  const [answerIdsToDelete, setAnswerIdsToDelete] = useState<number[]>([]);
  const [criteriaOptions, setCriteriaOptions] = useState<SelectModel[]>([]);
  const [initialFormValues, setInitialFormValues] = useState<FormikValues>({
    category: getInitialCategoryValue(questionToEdit?.criteria),
    text: questionToEdit?.text || '',
    newanswer: '',
    newexternalId: '',
    answer: '',
    externalId: '',
    answers: [],
    answerSorting: [],
    answerOptions: questionToEdit?.questionAnswerOptions,
  });

  useEffect(() => {
    const answerType = questionToEdit?.anyAnswer ? 'freeText' : 'answerChoice';
    setAnswerType(answerType);
    /* eslint-disable-next-line */
  }, [questionToEdit]);

  useEffect(() => {
    if (criterias?.length > 0) {
      if (!criteriaOptions.length) {
        const options = getSelectOptionObjects(criterias, 'name', 'id');
        setCriteriaOptions(options);
      }
    }
    /* eslint-disable-next-line */
  }, [criterias?.length]);

  useEffect(() => {
    setInitialFormValues(prevState => ({
      ...prevState,
      category: getInitialCategoryValue(questionToEdit?.criteria),
      text: questionToEdit?.text || '',
      answer: '',
      answers: [],
      answerOptions: questionToEdit?.questionAnswerOptions,
    }));
    /* eslint-disable-next-line */
  }, [questionToEdit, criteriaOptions.length]);

  const handleSubmit = (values: FormikValues, resetForm: Function) => {
    const { category, text, answers, answerSorting } = values;

    if (answerSorting && answerSorting.length > 0 && questionToEdit) {
      questionToEdit.questionAnswerOptions = answerSorting;
    }

    if (answerType === 'answerChoice' && !answers.length) {
      if (!questionToEdit || (questionToEdit && !questionToEdit.questionAnswerOptions.length)) {
        return addToast('error', t('common.addAtleastOneAnswer'));
      }
    }

    if (questionToEdit?.id || questionToEdit?.tempId) {
      const { questionAnswerOptions } = questionToEdit;

      const filterDeletedAnswerOptions = questionAnswerOptions.filter(
        a => !answerIdsToDelete.includes(a.id!),
      );
      const addedAnswerOptions = answers.map((a: any) => ({
        text: a.name,
        externalId: a.externalId,
        value: 0,
        orderNumber: 0,
        ko: false,
      }));

      const mergedAnswerOptions = [...filterDeletedAnswerOptions, ...addedAnswerOptions];
      const orderedAnswerOptions = mergedAnswerOptions.map((a, idx) => ({
        ...a,
        orderNumber: idx,
      }));

      const questionValues = {
        ...questionToEdit,
        text,
        anyAnswer: answerType === 'freeText',
        criteriaId: category.value,
        criteria: {
          id: category.value,
          name: category.label,
        },
        questionAnswerOptions: answerType === 'freeText' ? [] : orderedAnswerOptions,
      };

      setCreatedOrEditedPositionQuestion(questionValues, 'edit');
      setQuestionToEdit(null);
      return setPositionSidebarType(positionSidebarTypes.SHORTCUTS);
    }

    const questionValues = {
      tempId: uniqueId(),
      text,
      anyAnswer: answerType === 'freeText',
      criteriaId: category.value,
      shortList: false,
      criteria: {
        id: category.value,
        name: category.label,
      },
      questionAnswerOptions: answers.map(
        (a: { name: string; answer: string; externalId: string }, idx: number) => ({
          text: a.name,
          externalId: a.externalId,
          value: 0,
          orderNumber: idx,
          ko: false,
        }),
      ),
    };

    setCreatedOrEditedPositionQuestion(questionValues, 'create');
    setQuestionToEdit(null);
    setPositionSidebarType(positionSidebarTypes.SHORTCUTS);
    return resetForm();
  };

  return (
    <Row className="animated fadeIn">
      <Col>
        <Formik
          enableReinitialize
          initialValues={initialFormValues}
          validationSchema={prefilterCriteriaSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            await handleSubmit(values, resetForm);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormGroup>
                <Label for="category">{t('common.category')}</Label>
                <div className="d-flex flex-row">
                  <ReactSelect
                    name="category"
                    className={styles.select}
                    options={criteriaOptions}
                  />
                  <button
                    type="button"
                    className="btn btn-ghost-info ml-1"
                    onClick={() => setPositionSidebarType(positionSidebarTypes.CRITERIA_CATEGORIES)}
                  >
                    <i className="far fa-edit" />
                  </button>
                </div>
              </FormGroup>

              <FormGroup>
                <Label for="text">{t('common.question')}</Label>
                <TextField name="text" />
              </FormGroup>

              <FormGroup>
                <Label for="answerType">{t('common.answerType')}</Label>
                <ButtonGroup style={{ zIndex: 0 }}>
                  {answerTypes.map((item: any) => (
                    <Button
                      key={item.value}
                      color="primary"
                      outline
                      active={item.value === answerType}
                      onClick={() => setAnswerType(item.value)}
                    >
                      {item.label}
                    </Button>
                  ))}
                </ButtonGroup>
              </FormGroup>

              {answerType === 'answerChoice' && (
                <AnswerChoiceForm
                  answerIdsToDelete={answerIdsToDelete}
                  setAnswerIdsToDelete={setAnswerIdsToDelete}
                />
              )}

              <SidebarActionButtons isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  questionToEdit: state.positions.data.questionToEdit,
  criterias: state.positionCriterias.data.positionCriterias.data?.content || [],
});

const mapDispatchToProps = {
  setPositionSidebarType,
  setQuestionToEdit,
  setCreatedOrEditedPositionQuestion,
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionQuestionCreateAndEditSidebar);
