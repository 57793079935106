import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { MetaDataModel } from 'src/shared/models/audit-log.model';

import SavedDocument from './event-details/SavedDocument';
import DeletedDocument from './event-details/DeletedDocument';
import UpdatedDocument from './event-details/UpdatedDocument';
import UploadedDocument from './event-details/UploadedDocument';
import SavedFolder from './event-details/SavedFolder';
import DeletedFolder from './event-details/DeletedFolder';
import UpdatedFolder from './event-details/UpdatedFolder';
import UpdatedUser from './event-details/UpdatedUser';
import Lists from './event-details/Lists';
import EmployeeTagTable from './event-details/EmployeeTag';
import Positions from './event-details/Positions';
import User from './event-details/User';

import styles from '../AuditLog.module.scss';
import Answers from './event-details/Answers';
import UserLoginEvent from './event-details/UserLoginEvent';

interface Props {
  isOpen: boolean;
  toggle: Function;
  metadata: MetaDataModel | undefined;
}

const renderEventDetails = (metadata?: MetaDataModel) => {
  if (metadata) {
    if (metadata.type === 'SIMPLE_TEXT_MESSAGE' || Object.keys(metadata).includes('messageId')) {
      return <Answers data={metadata} />;
    }

    if (metadata.logEventType === 'UserLoginEvent') {
      return <UserLoginEvent data={metadata} />;
    }

    if (
      Object.keys(metadata).includes('savedDocument') &&
      Object.keys(metadata).includes('updatedDocument')
    ) {
      const { updatedDocument, savedDocument } = metadata;

      if (updatedDocument && savedDocument) {
        return <UpdatedDocument updatedDocument={updatedDocument} savedDocument={savedDocument} />;
      }
    }

    if (Object.keys(metadata).includes('deletedDocument')) {
      const { deletedDocument } = metadata;

      if (deletedDocument) {
        return <DeletedDocument document={deletedDocument} />;
      }
    }

    if (Object.keys(metadata).includes('uploadedDocument')) {
      const { uploadedDocument } = metadata;

      if (uploadedDocument) {
        return <UploadedDocument document={uploadedDocument} />;
      }
    }

    if (Object.keys(metadata).includes('savedDocument')) {
      const { savedDocument } = metadata;

      if (savedDocument) {
        return <SavedDocument document={savedDocument} />;
      }
    }

    if (
      Object.keys(metadata).includes('savedFolder') &&
      Object.keys(metadata).includes('updatedFolder')
    ) {
      const { updatedFolder, savedFolder } = metadata;

      if (updatedFolder && savedFolder) {
        return <UpdatedFolder updatedFolder={updatedFolder} savedFolder={savedFolder} />;
      }
    }

    if (Object.keys(metadata).includes('deletedFolder')) {
      const { deletedFolder } = metadata;

      if (deletedFolder) {
        return <DeletedFolder folder={deletedFolder} />;
      }
    }

    if (Object.keys(metadata).includes('savedFolder')) {
      const { savedFolder } = metadata;

      if (savedFolder) {
        return <SavedFolder folder={savedFolder} />;
      }
    }

    if (
      Object.keys(metadata).includes('deletedUser') ||
      Object.keys(metadata).includes('createdUser') ||
      Object.keys(metadata).includes('currentUser') ||
      Object.keys(metadata).includes('originalUser') ||
      Object.keys(metadata).includes('newUser')
    ) {
      const { deletedUser, updatedUser, createdUser, currentUser, originalUser, newUser } = metadata;
      const user = deletedUser || updatedUser || createdUser || currentUser || originalUser || newUser;

      if (user) {
        return <User user={user} />;
      }
    }

    if (Object.keys(metadata).includes('updatedUser')) {
      const { updatedUser, savedUser } = metadata;

      if (updatedUser && savedUser) {
        return <UpdatedUser updatedUser={updatedUser} savedUser={savedUser} />;
      }
    }

    if (Object.keys(metadata).includes('oldSubscribingList') ||
      Object.keys(metadata).includes('newSubscribingList')) {
      const { oldSubscribingList, newSubscribingList } = metadata;

      if (oldSubscribingList || newSubscribingList) {
        return <Lists newSubscribingList={newSubscribingList} oldSubscribingList={oldSubscribingList} />;
      }
    }

    if (Object.keys(metadata).includes('oldEmployeeTag') ||
    Object.keys(metadata).includes('newEmployeeTag')) {
    const { oldEmployeeTag, newEmployeeTag } = metadata;

      if (oldEmployeeTag || newEmployeeTag) {
        return <EmployeeTagTable oldEmployeeTag={oldEmployeeTag} newEmployeeTag={newEmployeeTag} />;
      }

  }

  if (Object.keys(metadata).includes('positionPatchDto') ||
  Object.keys(metadata).includes('positionId')) {
  const { positionPatchDto, positionId } = metadata;

    if (positionPatchDto || positionId) {
      return <Positions positionId={positionId} positionPatchDto={positionPatchDto} />;
    }

}

  }

  return null;
};

const AuditLogDetailsModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { isOpen, toggle, metadata } = props;

  return (
    <Modal isOpen={isOpen} size="lg" className={styles.eventDetailsModal}>
      <ModalHeader>{t('auditLog.eventDetails')}</ModalHeader>
      <ModalBody>{renderEventDetails(metadata)}</ModalBody>
      <ModalFooter>
        <Button color="info" onClick={() => toggle()}>
          {t('common.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AuditLogDetailsModal;
