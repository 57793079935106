import React, { Fragment } from 'react';

import { parseFileNameWithExtension } from 'src/shared/utils/parse.util';
import { isValidUrl } from 'src/shared/utils/url-validate.util';

import styles from '../AuditLog.module.scss';

export const renderFileLink = (url: string) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {parseFileNameWithExtension(url)}
    </a>
  );
};

export const renderDifference = (prev?: string, next?: string) => {
  let prevFilename: string | null = '';
  let nextFilename: string | null = '';

  if (prev && isValidUrl(prev)) {
    prevFilename = parseFileNameWithExtension(prev);
  }

  if (next && isValidUrl(next)) {
    nextFilename = parseFileNameWithExtension(next);
  }

  const isFile = prevFilename || nextFilename;

  if (prev !== next) {
    return (
      <div className={styles.differentTexts}>
        {prev && <p>{prevFilename || prev}</p>}
        <Fragment>
          {prev && next && <div className={styles.arrow}>&rarr;</div>}
          {isFile ? (
            <a href={next} target="_blank" rel="noopener noreferrer">
              {nextFilename}
            </a>
          ) : (
            <Fragment>{next && <p>{nextFilename || next}</p>}</Fragment>
          )}
        </Fragment>
      </div>
    );
  }

  return isFile ? (
    <a href={prev} target="_blank" rel="noopener noreferrer">
      {prevFilename}
    </a>
  ) : (
    <p className={styles.sameText}>{prevFilename || prev}</p>
  );
};
