import React, { Component, Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Row, Col, Alert, Card, CardBody, CardHeader } from 'reactstrap';
import queryString from 'query-string';
import moment from 'moment';
import Select from 'react-select';
import { OptionsType } from 'react-select/src/types';

import { fileNames } from '../../shared/settings';

import { ApplicationModel, getApplication } from '../../redux/actions/applications.action';
import {
  // FeedbackModel,
  // FeedbackSummaryModel,
  getFeedbackSummary,
  getFeedbacks,
  getPlatformsWithFeedback,
  exportFeedbacks,
} from '../../redux/actions/feedbacks.action';
import { QueryParams, PlatformModel } from 'src/shared/models';
import { getActivePlatformObjects } from '../../shared/utils/platforms.util';
import { formatDatesToIsoString } from '../../shared/utils/query-parse.util';
import saveFile from '../../shared/utils/file-saver.util';

import FeedbackFilter from './components/FeedbacksFilter';
import FeedbackTable from './components/FeedbacksTable';
import FeedbackPieChart from './components/FeedbacksPieChart';
import { Spinner, ExportButton, PageHeader } from 'src/shared/components';

import './Feedbacks.scss';

interface Props extends RouteComponentProps<{ id: string }>, WithTranslation {
  application: ApplicationModel;
  getApplication: Function;
  platforms: any;
  getPlatformsWithFeedback: Function;
  feedbackSummary: any;
  getFeedbackSummary: Function;
  feedbacks: any;
  getFeedbacks: Function;
  exportedFeedbacks: any;
  exportFeedbacks: Function;
}

interface State {
  activePlatforms: OptionsType<ValModel>;
  selectedPlatform: OptionsType<ValModel>;
  queryParams: QueryParams;
  activePage: number;
}

interface ValModel {
  value: string;
  label: string;
}

const INIT_FROM_VALUE = moment().utc().subtract(7, 'days');
const INIT_TO_VALUE = moment().utc();

class Feedbacks extends Component<Props, State> {
  state: State = {
    activePlatforms: [],
    selectedPlatform: [],
    queryParams: {
      limit: 20,
      page: 0,
      size: 20,
      from: INIT_FROM_VALUE,
      to: INIT_TO_VALUE,
    },
    activePage: 1,
  };

  componentDidMount = async () => {
    const { id } = this.props.application;

    if (!id) {
      await this.props.getApplication(this.props.match.params.id);
    }

    await this.getPlatformsWithFeedback();

    if (this.state.activePlatforms.length) {
      await this.setCurrentPage();
      this.getFeedbackSummary();
      this.getFeedbacks();
    }
  };

  getPlatformsWithFeedback = async () => {
    const { id } = this.props.application;

    await this.props.getPlatformsWithFeedback(id);
    const { platforms } = this.props;

    if (!platforms.data.length) {
      return null;
    }

    const activePlatformObjects: PlatformModel[] = getActivePlatformObjects(platforms.data);

    this.setState(prevState => ({
      activePlatforms: [...prevState.activePlatforms, ...activePlatformObjects],
      selectedPlatform: [activePlatformObjects[0]],
    }));
  };

  getFeedbackSummary = () => {
    const { id } = this.props.application;
    const { selectedPlatform, queryParams } = this.state;
    const { from, to } = formatDatesToIsoString(queryParams);

    this.props.getFeedbackSummary(id, selectedPlatform[0].value, { from, to });
  };

  getFeedbacks = () => {
    const { match } = this.props;
    const { selectedPlatform, queryParams } = this.state;
    const formattedQueryParams = formatDatesToIsoString(queryParams);

    this.props.getFeedbacks(match.params.id, selectedPlatform[0].value, formattedQueryParams);
  };

  setCurrentPage = async () => {
    const queryValues = queryString.parse(this.props.location.search);

    if (queryValues.page) {
      this.setState(prevState => ({
        queryParams: {
          ...prevState.queryParams,
          page: Number(queryValues.page),
        },
        activePage: Number(queryValues.page) + 1,
      }));
    }
  };

  exportFeedbacks = async () => {
    const { id } = this.props.application;
    const { selectedPlatform, queryParams } = this.state;
    const { from, to } = formatDatesToIsoString(queryParams);

    await this.props.exportFeedbacks(id, selectedPlatform[0].value, {
      from,
      to,
    });
    const { exportedFeedbacks } = this.props;

    if (exportedFeedbacks) {
      saveFile(exportedFeedbacks, fileNames.FEEDBACKS_EXPORT, 'csv');
    }
  };

  handleSelectionChange = async (selectedPlatform: any) => {
    return this.setState({ selectedPlatform: [selectedPlatform] }, () => {
      this.getFeedbackSummary();
      this.getFeedbacks();
    });
  };

  handleDateChange = async (from: string, to: string) => {
    this.setState(prevState => ({
      queryParams: {
        ...prevState.queryParams,
        from,
        to,
      },
    }));
  };

  pageChange = (index: number) => {
    const { id } = this.props.match.params;
    // Backend oldalon a page-elés 0-tól indul
    // Frontend oldaon a 'react-js-pagination' miatt 1-től kell indulnia
    this.setState(
      prevState => ({
        queryParams: {
          ...prevState.queryParams,
          page: index - 1,
        },
        activePage: index,
      }),
      () => {
        const { page } = this.state.queryParams;
        this.props.history.push(`/application/${id}/reports-analytics/feedbacks?page=${page}`);
        this.getFeedbacks();
        window.scrollTo(0, 0);
      },
    );
  };

  render() {
    const { feedbacks, feedbackSummary, platforms, t } = this.props;
    const { activePlatforms, selectedPlatform, activePage, queryParams } = this.state;

    const isLoading = platforms.loading || feedbackSummary.loading || feedbacks.loading;
    const hasActivePlatforms = platforms.loaded && platforms.data?.length > 0;
    const hasFeedbackSummary = feedbackSummary.loaded && feedbackSummary.data?.length > 0;
    const hasFeedbacks = feedbacks.loaded && feedbacks.data?.content?.length > 0;

    return (
      <Fragment>
        <PageHeader title={t('common.feedbacks')} />

        <Card className="animated fadeIn">
          <CardHeader>
            <Row>
              <Col>
                <FeedbackFilter
                  handleDateChange={this.handleDateChange}
                  getFeedbacks={this.getFeedbacks}
                  getFeedbackSummary={this.getFeedbackSummary}
                  disabled={platforms.loaded && !platforms.data.length}
                />
              </Col>
              <Col className="d-flex justify-content-end">
                <ExportButton
                  className="ml-2"
                  disabled={
                    !hasActivePlatforms ||
                    (feedbacks.loaded && feedbacks.data.content && !feedbacks.data.content.length)
                  }
                  onClick={this.exportFeedbacks}
                  permission="feedbacks_read"
                  exportTooltipLabel={t('common.exportToCsvForPeriod')}
                  tooltipProps={{ target: 'exportButton', placement: 'left' }}
                />
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {platforms.loaded && !platforms.data.length && (
              <Alert color="info text-center mt-0 mb-0">{t('feedbacks.noFeedbacks')}</Alert>
            )}

            {hasActivePlatforms && (
              <Row>
                <Col lg="4" md="5">
                  <Select
                    name="platform-select"
                    className="mb-3"
                    value={selectedPlatform}
                    onChange={this.handleSelectionChange}
                    options={activePlatforms}
                    isLoading={platforms.loading}
                  />
                </Col>
              </Row>
            )}

            {isLoading && (
              <div className="d-flex justify-content-center">
                <Spinner loading={isLoading} size="2x" />
              </div>
            )}

            <Row>
              {hasFeedbackSummary && (
                <Col lg="4" md="5">
                  <Card>
                    <CardBody>
                      <FeedbackPieChart feedbackSummary={feedbackSummary} />
                    </CardBody>
                  </Card>
                </Col>
              )}

              {hasFeedbacks && (
                <Col>
                  <FeedbackTable
                    feedbacks={feedbacks.data}
                    queryParams={queryParams}
                    activePage={activePage}
                    pageChange={this.pageChange}
                  />
                </Col>
              )}

              {hasActivePlatforms && hasFeedbackSummary && !hasFeedbacks && !isLoading && (
                <Col>
                  <Alert color="info text-center mt-0 mb-0">
                    {t('feedbacks.noTextFeedbacksForPeriod')}
                  </Alert>
                </Col>
              )}

              {hasActivePlatforms && !hasFeedbackSummary && !hasFeedbacks && !isLoading && (
                <Col>
                  <Alert color="info text-center mt-0 mb-0">
                    {t('feedbacks.noFeedbacksForPeriod')}
                  </Alert>
                </Col>
              )}
            </Row>

            {/* {platforms.loaded &&
              platforms.data?.length > 0 &&
              feedbacks.loaded &&
              !feedbacks.data?.content?.length && (
          
              )} */}
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  feedbackSummary: state.feedbackSummary,
  feedbacks: state.feedbacks,
  platforms: state.platformsWithFeedbacks,
  exportedFeedbacks: state.exportFeedbacks.data,
});

const mapDispatchToProps = {
  getApplication,
  getFeedbackSummary,
  getFeedbacks,
  getPlatformsWithFeedback,
  exportFeedbacks,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(Feedbacks)));
