import { StateModel } from '../../shared/models/default-state.model';
import {
  GET_LABEL_REQUEST,
  GET_LABEL_SUCCESS,
  GET_LABEL_ERROR,
  GET_LABELS_REQUEST,
  GET_LABELS_SUCCESS,
  GET_LABELS_ERROR,
  CREATE_LABEL_REQUEST,
  CREATE_LABEL_SUCCESS,
  CREATE_LABEL_ERROR,
  UPDATE_LABEL_REQUEST,
  UPDATE_LABEL_SUCCESS,
  UPDATE_LABEL_ERROR,
  DELETE_LABEL_REQUEST,
  DELETE_LABEL_SUCCESS,
  DELETE_LABEL_ERROR,
  EXPORT_LABELS_REQUEST,
  EXPORT_LABELS_SUCCESS,
  EXPORT_LABELS_ERROR,
  ADD_LABEL_TO_POSITION_REQUEST,
  ADD_LABEL_TO_POSITION_SUCCESS,
  ADD_LABEL_TO_POSITION_ERROR,
  REMOVE_LABEL_FROM_POSITION_REQUEST,
  REMOVE_LABEL_FROM_POSITION_SUCCESS,
  REMOVE_LABEL_FROM_POSITION_ERROR,
  CLEAR_ADD_LABELS_TO_POSITION,
  CLEAR_CREATE_LABEL,
  CLEAR_UPDATE_LABEL,
  CLEAR_DELETE_LABEL,
} from '../actions/position-label.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {
    label: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    labels: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    createLabel: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    updateLabel: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    deleteLabel: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    exportLabel: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    addLabelsToPosition: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    removeLabelFromPosition: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
  },
};

export const positionLabelsReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_LABEL_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          label: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case GET_LABEL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          label: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case GET_LABEL_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          label: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case GET_LABELS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          labels: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case GET_LABELS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          labels: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case GET_LABELS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          labels: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case CREATE_LABEL_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          createLabel: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case CREATE_LABEL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          createLabel: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case CREATE_LABEL_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          createLabel: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case CLEAR_CREATE_LABEL:
      return {
        ...state,
        data: {
          ...state.data,
          createLabel: {
            loading: false,
            loaded: false,
            error: null,
            data: [],
          },
        },
      };

    case UPDATE_LABEL_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          updateLabel: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case UPDATE_LABEL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          updateLabel: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case UPDATE_LABEL_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          updateLabel: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case CLEAR_UPDATE_LABEL:
      return {
        ...state,
        data: {
          ...state.data,
          updateLabel: {
            loading: false,
            loaded: false,
            error: null,
            data: [],
          },
        },
      };

    case DELETE_LABEL_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          deleteLabel: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case DELETE_LABEL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          deleteLabel: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case DELETE_LABEL_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          deleteLabel: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case CLEAR_DELETE_LABEL:
      return {
        ...state,
        data: {
          ...state.data,
          deleteLabel: {
            loading: false,
            loaded: false,
            error: null,
            data: [],
          },
        },
      };

    case EXPORT_LABELS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          exportLabels: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case EXPORT_LABELS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          exportLabels: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case EXPORT_LABELS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          exportLabels: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case ADD_LABEL_TO_POSITION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          addLabelsToPosition: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case ADD_LABEL_TO_POSITION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          addLabelsToPosition: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case ADD_LABEL_TO_POSITION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          addLabelsToPosition: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case CLEAR_ADD_LABELS_TO_POSITION:
      return {
        ...state,
        data: {
          ...state.data,
          addLabelsToPosition: {
            loading: false,
            loaded: false,
            error: null,
            data: [],
          },
        },
      };

    case REMOVE_LABEL_FROM_POSITION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          removeLabelFromPosition: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case REMOVE_LABEL_FROM_POSITION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          removeLabelFromPosition: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case REMOVE_LABEL_FROM_POSITION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          removeLabelFromPosition: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    default:
      return state;
  }
};

export default positionLabelsReducer;
