import { get, post, patch, del } from '../../shared/services/http.service';
import { GeneralQueryParams } from 'src/shared/models/query-params.model';
import { InterviewModel } from 'src/shared/models/interviews.model';

export const getInterviews = async (appId: string, queryParams: GeneralQueryParams) => {
  return get(`/recruiter/applications/${appId}/interview/events`, { params: queryParams });
};

export const getInterview = async (appId: string, eventId: number) => {
  return get(`/recruiter/applications/${appId}/interview/events/${eventId}`);
};

export const createInterview = async (appId: string, interview: InterviewModel) => {
  return post(`/recruiter/applications/${appId}/interview/events`, { data: interview });
};

export const updateInterview = async (
  appId: string,
  eventId: number,
  interview: InterviewModel,
) => {
  return patch(`/recruiter/applications/${appId}/interview/events/${eventId}`, { data: interview });
};

export const deleteInterview = async (appId: string, eventId: number) => {
  return del(`/recruiter/applications/${appId}/interview/events/${eventId}`);
};
