import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { SubscribingListModel } from 'src/shared/models/subscribing-lists.model';

import CheckPermission from 'src/shared/components/CheckPermission';

import styles from '../../../SubscribingLists.module.scss';
import { EmployeeTags } from 'src/shared/components';
import { getTagCategories } from 'src/redux/actions/employee.action';
import {
  removeSubscribingListTag,
  addTagToSubscribingList,
} from 'src/redux/actions/subscribing-lists.action';
import { StateModel } from 'src/shared/models';

interface Props {
  list: SubscribingListModel;
  index: number;
  toggleEditModal: Function;
  toggleConfirmModal: Function;
  toggleMessagingModal: Function;
  toggleViberMessageModal: Function;
  showList: Function;
  getLists: Function;
  getTagCategories: Function;
  addTagToSubscribingList: Function;
  removeSubscribingListTag: Function;
  tagCategories: StateModel;
}

const ListItem: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const {
    list,
    index,
    showList,
    getLists,
    getTagCategories,
    addTagToSubscribingList,
    removeSubscribingListTag,
    toggleEditModal,
    toggleConfirmModal,
    toggleMessagingModal,
    toggleViberMessageModal,
    tagCategories,
  } = props;

  return (
    <Fragment>
      <Row className={styles.card} role="button" tabIndex={index}>
        <Col lg="2" className={styles.name} onClick={() => showList(list.id, list.stringId)}>
          <span className={styles.cardRowLabel}>{t('common.name')}</span>
          {list.name}
        </Col>

        <Col className={styles.description} onClick={() => showList(list.id, list.stringId)}>
          <span className={styles.cardRowLabel}>{t('common.description')}</span>
          <i className="fas fa-info-circle" id={`description-${list.id}`} />
        </Col>

        <Col className={styles.subscribers} onClick={() => showList(list.id, list.stringId)}>
          <span className={styles.cardRowLabel}>{t('subscribingLists.subscribers')}</span>
          {list.subscriberCount}
        </Col>

        <Col
          className={styles.date}
          id={`dateCreated-${list.id}`}
          onClick={() => showList(list.id, list.stringId)}
        >
          <span className={styles.cardRowLabel}>{t('common.created')}</span>
          {moment(list.createdAt).fromNow()}
        </Col>

        <Col lg="4" className={styles.tags} id={`tags-${list.id}`}>
            <EmployeeTags
              disabled={!list.refreshTypeAutomatic}
              employeeTags={list.employeeTags || []}
              removeTag={(tagId: string) => removeSubscribingListTag(appId, list.id, tagId)}
              removePermission="employee_employee-tag-assign_delete"
              createPermission="employee_employee-tag-assign_create"
              index={index}
              list={list}
              onChanged={getLists}
              getTagCategories={getTagCategories}
              tagCategories={tagCategories}
              addTagToEntity={addTagToSubscribingList}
            />
        </Col>

        <Col className={styles.operations} lg="2">
          <span className={styles.cardRowLabel}>{t('common.operations')}</span>
          <div className="operationButtons">
            <CheckPermission variant="enableIf" permissions={['subscribing-lists_update']}>
              <Button
                size="sm"
                color="info"
                id={`editBtn-${list.id}`}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  toggleEditModal(list);
                }}
              >
                <i className="far fa-edit" />
              </Button>
            </CheckPermission>

            <CheckPermission variant="enableIf" permissions={['subscribing-lists_delete']}>
              <Button
                size="sm"
                color="danger"
                id={`deleteBtn-${list.id}`}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  toggleConfirmModal(list);
                }}
              >
                <i className={`far fa-trash-alt ${styles.deleteButton}`} />
              </Button>
            </CheckPermission>

            <CheckPermission
              variant="enableIf"
              permissions={['subscribing-lists-outbound-messages_create']}
            >
              <Button
                size="sm"
                id={`messageBtn-${list.id}`}
                disabled={!list.hasPlatforms}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  toggleMessagingModal(list);
                }}
              >
                <i className="far fa-paper-plane" />
              </Button>
            </CheckPermission>

            <CheckPermission
              variant="enableIf"
              permissions={['subscribing-lists-outbound-messages_create']}
            >
              <Button
                size="sm"
                id={`viberBtn-${list.id}`}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  toggleViberMessageModal(list);
                }}
              >
                <i className="fab fa-viber" />
              </Button>
            </CheckPermission>
          </div>
        </Col>

        <Col className={styles.type}>
          {list.refreshTypeAutomatic && (
            <>
              <i id={`refreshTypeAutomatic-${list.id}`} className="fas fa-cog" />
              <UncontrolledTooltip
                placement="bottom"
                target={`refreshTypeAutomatic-${list.id}`}
                trigger="hover"
              >
                {t('common.refreshTypeAutomatic')}
              </UncontrolledTooltip>
            </>
          )}
        </Col>

        <UncontrolledTooltip placement="bottom" target={`description-${list.id}`} trigger="hover">
          {list.description}
        </UncontrolledTooltip>

        <UncontrolledTooltip placement="bottom" target={`dateCreated-${list.id}`} trigger="hover">
          {moment(list.createdAt).format('LLL')}
        </UncontrolledTooltip>

        <UncontrolledTooltip placement="bottom" target={`editBtn-${list.id}`} trigger="hover">
          {t('subscribingLists.editList')}
        </UncontrolledTooltip>

        <UncontrolledTooltip placement="bottom" target={`deleteBtn-${list.id}`} trigger="hover">
          {t('subscribingLists.deleteList')}
        </UncontrolledTooltip>

        <UncontrolledTooltip placement="bottom" target={`messageBtn-${list.id}`} trigger="hover">
          {t('subscribingLists.messageSubscribers')}
        </UncontrolledTooltip>

        <UncontrolledTooltip placement="bottom" target={`viberBtn-${list.id}`} trigger="hover">
          {t('subscribingLists.messageSubscribersViaViber')}
        </UncontrolledTooltip>

        <Col className={styles.chevron} onClick={() => showList(list.id, list.stringId)}>
          <i className="fas fa-chevron-right" />
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    tagCategories: state.tagCategories,
  };
};

const mapDispatchToProps = {
  removeSubscribingListTag,
  addTagToSubscribingList,
  getTagCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
