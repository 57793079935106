import React from 'react';
import { Row } from 'reactstrap';

import { QuestionnaireLabelCategory } from 'src/shared/models/questionnaire.model';

import LabelCategoryCard from './LabelCategoryCard';

interface Props {
  labelCategories: QuestionnaireLabelCategory[];
}

const LabelCategoryCards: React.FC<Props> = ({ labelCategories }) => (
  <Row>
    {labelCategories?.map(lc => (
      <LabelCategoryCard key={lc.id} category={lc} />
    ))}
  </Row>
);

export default LabelCategoryCards;
