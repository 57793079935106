import { Dispatch } from 'redux';

import { getDynamicEmployees as getDynamicEmployeesService } from 'src/redux/services/employee.service';
import { PageQueryParams, DynamicEmployeeModel } from 'src/shared/models';

export const GET_DYNAMIC_EMPLOYEES = 'GET_DYNAMIC_EMPLOYEES';
export const GET_DYNAMIC_EMPLOYEES_SUCCESS = 'GET_DYNAMIC_EMPLOYEES_SUCCESS';
export const GET_DYNAMIC_EMPLOYEES_ERROR = 'GET_DYNAMIC_EMPLOYEES_ERROR';

export const CREATE_DYNAMIC_EMPLOYEE = 'DynamicEmployees/CREATE_DYNAMIC_EMPLOYEE';
export const CREATE_DYNAMIC_EMPLOYEE_SUCCESS = 'DynamicEmployees/CREATE_DYNAMIC_EMPLOYEE_SUCCESS';
export const CREATE_DYNAMIC_EMPLOYEE_ERROR = 'DynamicEmployees/CREATE_DYNAMIC_EMPLOYEE_ERROR';

export const getDynamicEmployees = (appId: string, queryParams: PageQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_DYNAMIC_EMPLOYEES,
    });

    const dynamicEmployee = (await getDynamicEmployeesService(
      appId,
      queryParams,
    )) as DynamicEmployeeModel[];

    try {
      dispatch({
        type: GET_DYNAMIC_EMPLOYEES_SUCCESS,
        payload: {
          content: dynamicEmployee,
          pageable: false,
          totalPages: 1,
          totalElements: dynamicEmployee.length,
          numberOfElements: dynamicEmployee.length,
          first: true,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_DYNAMIC_EMPLOYEES_ERROR,
        error,
      });
    }
  };
};
