import React, { Component, Fragment } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Container } from 'reactstrap';

import { TalentPoolModels } from '../../shared/models/talent-pools.model';
import { getTalentPools } from '../../redux/actions/talent-pools.action';

import TalentPoolCardHeader from './components/TalentPoolCardHeader';
import TalentPoolCard from './components/TalentPoolCard';
import Spinner from '../../shared/components/Spinner';

import styles from './TalentPools.module.scss';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props extends RouteComponentProps, WithTranslation {
  getTalentPools: Function;
  talentPools: TalentPoolModels[];
  talentPoolsLoading: boolean;
  talentPoolsLoaded: boolean;
}

class TalentPools extends Component<Props> {
  componentDidMount() {
    this.props.getTalentPools();
  }

  showTalentPoolDetails = (talentPool: TalentPoolModels) => {
    if (talentPool.size === 0) return;

    const { match, history } = this.props;
    history.push(`${match.url}/${talentPool.id}/candidates?page=0`);
  };

  render() {
    const { talentPools, talentPoolsLoading, talentPoolsLoaded, t } = this.props;

    return (
      <Fragment>
        <Container>
          <Row>
            <Col className="animated fadeIn">
              <Row className="mb-4">
                <Col>
                  <h5>{t('common.talentPools')}</h5>
                </Col>
              </Row>

              {talentPools && talentPools.length > 0 && (
                <Fragment>
                  <TalentPoolCardHeader />
                  {talentPools.map((talentPool: TalentPoolModels, index: number) => {
                    return (
                      <TalentPoolCard
                        key={talentPool.id}
                        index={index}
                        talentPool={talentPool}
                        showTalentPoolDetails={this.showTalentPoolDetails}
                      />
                    );
                  })}
                </Fragment>
              )}

              {talentPoolsLoading && (
                <div className="d-flex justify-content-center">
                  <Spinner loading={talentPoolsLoading} size="2x" />
                </div>
              )}

              {talentPoolsLoaded && talentPools && !talentPools.length && (
                <div className={styles.noTalentPools}>
                  <span>No talent pools found.</span>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  talentPools: state.talentPools.data.content,
  talentPoolsLoading: state.talentPools.loading,
  talentPoolsLoaded: state.talentPools.loaded,
});

const mapDispatchToProps = {
  getTalentPools,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(TalentPools)));
