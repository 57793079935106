import _ from 'lodash';
import { Dispatch } from 'redux';
import { FormDetailedReport, PageQueryParams } from 'src/shared/models';
import { getDetailedFormReport } from '../services/forms.service';

export const GET_FORM_DETAILED_REPORT = 'GET_FORM_DETAILED_REPORT';
export const GET_FORM_DETAILED_REPORT_SUCCESS = 'GET_FORM_DETAILED_REPORT_SUCCESS';
export const GET_FORM_DETAILED_REPORT_ERROR = 'GET_FORM_DETAILED_REPORT_ERROR';
export const CLEAR_FORM_DETAILED_REPORT = 'CLEAR_FORM_DETAILED_REPORT';

export const getFormDetailedReport = (
  appId: string,
  formId: string,
  queryParams: PageQueryParams,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_FORM_DETAILED_REPORT,
    });

    try {
      const response = await getDetailedFormReport(appId, formId, queryParams);
      const report: FormDetailedReport = { reportRows: [], form: response.form };

      const attemptsMap: { [key: string]: number } = {};

      for (var i = 0; i < response.formReportFormFillDtos.length; i++) {
        let current = response.formReportFormFillDtos[i];
        if (attemptsMap[current.userProfileId]) {
          attemptsMap[current.userProfileId] += 1;
        } else {
          attemptsMap[current.userProfileId] = 1;
        }
      }

      for (var j = 0; j < response.formReportFormFillDtos.length; j++) {
        const index = queryParams.page * queryParams.size + j + 1;
        let firstInSeq = true;

        var groupedAnswers = _.groupBy(
          response.formReportFormFillDtos[j].formFill.answers,
          x => x.number,
        );

        for (var key in groupedAnswers) {
          response.formReportFormFillDtos[j].formFill.answers = groupedAnswers[key];

          report.reportRows.push({
            employeeData: response.formReportFormFillDtos[j].employeeData,
            userProfileId: response.formReportFormFillDtos[j].userProfileId,
            ...{
              formFill: {
                ...response.formReportFormFillDtos[j].formFill,
                ...{ answers: groupedAnswers[key] },
              },
            },
            ...{
              seqNumber: index,
              id: report.reportRows.length,
              attempts: attemptsMap[response.formReportFormFillDtos[j].userProfileId],
              firstInSeq: firstInSeq,
            },
          });
          firstInSeq = false;
        }
      }

      dispatch({
        type: GET_FORM_DETAILED_REPORT_SUCCESS,
        payload: report,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_FORM_DETAILED_REPORT_ERROR,
        error,
      });
    }
  };
};

export const revertFormDetailedReport = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_FORM_DETAILED_REPORT,
    });
  };
};
