import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup } from 'reactstrap';

import { SelectGroupModel, SelectModel } from '../../../../../shared/models/common.model';

import ReactSelect from '../../../../../shared/components/form-inputs/ReactSelect';
import { uniqueId } from 'lodash';

interface Props {
  currentLabels: SelectModel[];
  labelOptions: SelectGroupModel[];
}

const PositionLabelsForm: React.FC<Props> = ({ currentLabels, labelOptions }) => {
  const { t } = useTranslation();

  const displayLabelOptions = () => {
    if (!currentLabels || !currentLabels.length || !labelOptions.length) {
      return [];
    }

    let extendedLabels: SelectModel[] = [...currentLabels.filter(label => label.isFixed !== true)];
    extendedLabels.map(label => {
      label.group = labelOptions.find(labelOption =>
        labelOption.options.find(option => option.value === label.value),
      )?.label;
      return label;
    });
    extendedLabels.sort((a: SelectModel, b: SelectModel) => {
      return (a?.group || '') > (b?.group || '') ? 1 : 0;
    });

    let currentGroup: string = extendedLabels?.[0]?.group || '-';
    let extendedLabelsWithGroups: SelectModel[] = [
      { value: uniqueId(currentGroup), label: currentGroup, isFixed: true },
    ];

    extendedLabels.map(label => {
      if (label.group && label.group !== currentGroup) {
        currentGroup = label.group;
        extendedLabelsWithGroups.push({
          value: uniqueId(currentGroup),
          label: currentGroup,
          isFixed: true,
        });
      }
      extendedLabelsWithGroups.push(label);
      return label;
    });

    return extendedLabelsWithGroups;
  };

  const styles = {
    multiValue: (base: any, state: any) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base: any, state: any) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base: any, state: any) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
  };

  return (
    <Fragment>
      <fieldset className="border p-3">
        <legend className="col-form-label w-auto font-weight-bold">{t('common.labels')}</legend>
        <Row form>
          <Col>
            <FormGroup>
              <ReactSelect
                name="labels"
                styles={styles}
                isMulti
                isClearable
                options={labelOptions}
                value={displayLabelOptions()}
              />
            </FormGroup>
          </Col>
        </Row>
      </fieldset>
    </Fragment>
  );
};

export default PositionLabelsForm;
