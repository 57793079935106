import { StateModel } from 'src/shared/models';
import {
  GET_FORM_DETAILED_REPORT,
  GET_FORM_DETAILED_REPORT_SUCCESS,
  GET_FORM_DETAILED_REPORT_ERROR,
  CLEAR_FORM_DETAILED_REPORT,
} from '../actions/form-detailed-report.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: undefined,
};

export const formDetailedReportReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case GET_FORM_DETAILED_REPORT:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case GET_FORM_DETAILED_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload,
      };
    case GET_FORM_DETAILED_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };
    case CLEAR_FORM_DETAILED_REPORT:
      return INITIAL_STATE;

    default:
      return state;
  }
};
