import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExportButton } from 'src/shared/components';

interface Props {
  exportTopicStatistics: Function;
  exporting: boolean;
  exported: boolean;
  disabled: boolean;
}

const TopicStatisticsExport: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { exportTopicStatistics, exporting, disabled } = props;

  return (
    <ExportButton
      className="ml-2"
      disabled={disabled}
      onClick={() => exportTopicStatistics()}
      permission="analytics_read"
      exporting={exporting}
      exportTooltipLabel={t('common.exportToCsvForPeriod')}
    />
  );
};

export default TopicStatisticsExport;
