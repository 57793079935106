import React, { FC, useMemo } from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';

export interface GridProps {}

const Grid: FC<GridProps> = ({ children }) => {
  const { width } = useWindowDimensions();

  const numberOfColumnsInRow = useMemo(() => {
    //sidebar is hidden
    if (width < 990) return Math.round(width / 200) - 1;
    return Math.round(width / 200) - 2;
  }, [width]);

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${numberOfColumnsInRow}, 1fr)`,
        gridAutoRows: `200px`,
        gridGap: 10,
        padding: 10,
        gridAutoFlow: 'row dense',
      }}
    >
      {children}
    </div>
  );
};

export default Grid;
