import React, { Fragment, ReactElement } from 'react';
import { connect } from 'react-redux';

import { hasPermission } from '../services/permissions.service';
import NoPermission from '../../views/NoPermission';
import { UserModel } from '../../redux/reducers/permissions.reducer';

interface Props {
  children: ReactElement;
  variant: Variants;
  permissions: string[];
  userPermissions: string[];
  user: UserModel;
}

type Variants =
  | 'displayPageIf'
  | 'displayIf'
  | 'enableIf'
  | 'displayPageIfSuperAdmin';

// displayPageIf: Teljes oldal elrejtéséhez. Permission hiánya esetén a 403-mas oldalt jeleníti meg.
// displayIf: Komponens elrejtéséhez. Permission hiánya esetén nem jelenik meg semmi.
// enableIf: Komponens letiltásához. Permission hiánya esetén letitlja a komponenst.

const checkPermission = (props: Props) => {
  const { children, variant, permissions, userPermissions, user } = props;

  if (!userPermissions.length) return null;

  // About page permission workaround
  if (variant === 'displayPageIfSuperAdmin') {
    return user.userGroupName === 'Chatbot Admin' ? children : <NoPermission />;
  }

  const userHasPermission: boolean = hasPermission(permissions);

  switch (variant) {
    case 'displayPageIf':
      return (
        <Fragment>{userHasPermission ? children : <NoPermission />}</Fragment>
      );
    case 'displayIf':
      return <Fragment>{userHasPermission ? children : null}</Fragment>;
    case 'enableIf':
      const disabledElement = React.Children.map(children, (child: any) =>
        React.cloneElement(child, { disabled: true }),
      );
      return userHasPermission ? (
        children
      ) : (
        <div className="disabled">{disabledElement}</div>
      );
    default:
      return null;
  }
};

const CheckPermission: React.FC<Props> = props => {
  return checkPermission(props);
};

const mapStateToProps = (state: any) => ({
  userPermissions: state.permissions.data,
  user: state.user.data,
});

export default connect(mapStateToProps)(CheckPermission);
