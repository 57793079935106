import React from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';
import { TalentPoolCandidateWithAnswersModel } from '../../../shared/models/talent-pools.model';

import styles from '../TalentPools.module.scss';

interface Props {
  index: number;
  candidate: TalentPoolCandidateWithAnswersModel;
  showCandidate: Function;
  handleCandidateMark: Function;
  getSelectedPosition: Function;
}

const TalentPoolCandidatesTableRow: React.FC<Props> = (props: Props) => {
  const {
    index,
    candidate,
    showCandidate,
    handleCandidateMark,
    getSelectedPosition,
  } = props;

  return (
    <tr onClick={() => showCandidate(candidate)}>
      <td className={styles.marked}>
        <div
          className={styles.markedStar}
          role="button"
          tabIndex={index}
          onClick={(e: React.MouseEvent) => handleCandidateMark(e, candidate)}
        >
          {candidate.marked ? (
            <i className={`fas fa-star fa-lg ${styles.star}`} />
          ) : (
            <i className={`far fa-star fa-lg ${styles.star}`} />
          )}
        </div>
      </td>
      <td className={styles.name}>{candidate.name}</td>
      <td className={styles.position}>
        {getSelectedPosition(candidate.userAnswers)}
      </td>
      <td className={styles.email}>{candidate.email}</td>
      <td className={styles.phone}>{candidate.phone}</td>
      <td className={styles.cv}>
        {candidate.cv && (
          <Button
            color="success"
            size="sm"
            outline
            target="_blank"
            rel="noopener noreferrer"
            href={candidate.cv}
            download
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
            }}
          >
            <i className="fas fa-cloud-download-alt fa-lg" />
          </Button>
        )}
      </td>
      <td className={styles.date}>
        {moment(candidate.createdAt).format('LLL')}
      </td>
      {/* <td className="operations">
    <Button color="info" size="sm">
      <i className="fas fa-eye" />
    </Button>
  </td> */}
    </tr>
  );
};

export default TalentPoolCandidatesTableRow;
