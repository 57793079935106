import moment from 'moment';
import { StateModel, HandoverStateModel } from '../../shared/models/default-state.model';
import { ConversationsQueryParams } from '../../shared/models/query-params.model';

import {
  GET_CONVERSATIONS_REQUEST,
  GET_CONVERSATIONS_SUCCESS,
  GET_CONVERSATIONS_ERROR,
  SET_CONVERSATIONS_STATUS,
  SET_CONVERSATION_STATUS,
  CLEAR_CONVERSATIONS,
  GET_CONVERSATIONS_QUERY_PARAMS,
  SET_CONVERSATIONS_QUERY_PARAMS,
  SET_CURRENT_CONVERSATION,
  SET_CURRENT_USER,
  SET_CONVERSATION_PLATFORM,
  RESET_CONVERSATIONS_QUERY_PARAMS,
  SET_HANDOVER_STATUS_REQUEST,
  SET_HANDOVER_STATUS_SUCCESS,
  SET_HANDOVER_STATUS_ERROR,
  SEND_HANDOVER_MESSAGE_REQUEST,
  SEND_HANDOVER_MESSAGE_SUCCESS,
  SEND_HANDOVER_MESSAGE_ERROR,
  ConversationModel,
} from '../actions/conversations.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {
    content: [],
    currentUser: {},
    currentConversation: {},
    currentPlatform: '',
  },
};

const QUERY_PARAMS_STATE: ConversationsQueryParams = {
  from: moment()
    .utc()
    .subtract(2, 'month')
    .startOf('day')
    .toISOString(),
  to: moment()
    .utc()
    .endOf('day')
    .toISOString(),
  page: 0,
  prevPage: undefined,
  size: 20,
  limit: 20,
  waiting: false,
  platforms: '',
};

const HANDOVER_STATE: HandoverStateModel = {
  handoverStatus: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  handoverMessage: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
};

export const conversationsReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_CONVERSATIONS_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: { ...state.data, ...action.payload },
      };

    case GET_CONVERSATIONS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    case SET_CONVERSATIONS_STATUS:
      return {
        ...state,
        data: { ...state.data, content: action.payload },
      };

    case SET_CONVERSATION_STATUS:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map((conversation: ConversationModel) =>
            conversation.uuid === action.payload[0].uuid ? action.payload[0] : conversation,
          ),
        },
      };

    case SET_CURRENT_CONVERSATION:
      return {
        ...state,
        data: {
          ...state.data,
          currentConversation: action.payload,
        },
      };

    case SET_CURRENT_USER:
      return {
        ...state,
        data: {
          ...state.data,
          currentUser: action.payload,
        },
      };

    case SET_CONVERSATION_PLATFORM:
      return {
        ...state,
        data: {
          ...state.data,
          currentPlatform: action.payload,
        },
      };

    case CLEAR_CONVERSATIONS:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: null,
        data: {
          content: [],
          currentUser: {},
          currentConversation: {},
          currentPlatform: '',
        },
      };

    default:
      return state;
  }
};

export const conversationsQueryParamsReducer = (
  state: ConversationsQueryParams = QUERY_PARAMS_STATE,
  action: any,
) => {
  switch (action.type) {
    case GET_CONVERSATIONS_QUERY_PARAMS:
      return state;

    case SET_CONVERSATIONS_QUERY_PARAMS:
      return {
        ...action.payload,
      };

    case RESET_CONVERSATIONS_QUERY_PARAMS:
      return {
        ...QUERY_PARAMS_STATE,
      };

    default:
      return state;
  }
};

export const conversationsHandoverReducer = (
  state: HandoverStateModel = HANDOVER_STATE,
  action: any,
) => {
  switch (action.type) {
    case SET_HANDOVER_STATUS_REQUEST:
      return {
        ...state,
        handoverStatus: {
          ...state.handoverStatus,
          loaded: false,
          loading: true,
        },
      };
    case SEND_HANDOVER_MESSAGE_REQUEST:
      return {
        ...state,
        handoverMessage: {
          ...state.handoverMessage,
          loaded: false,
          loading: true,
        },
      };

    case SET_HANDOVER_STATUS_SUCCESS:
      return {
        ...state,
        handoverStatus: {
          ...state.handoverStatus,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };
    case SEND_HANDOVER_MESSAGE_SUCCESS:
      return {
        ...state,
        handoverMessage: {
          ...state.handoverMessage,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case SET_HANDOVER_STATUS_ERROR:
      return {
        ...state,
        handoverStatus: {
          ...state.handoverStatus,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    case SEND_HANDOVER_MESSAGE_ERROR:
      return {
        ...state,
        handoverMessage: {
          ...state.handoverMessage,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};
