import { Dispatch } from 'redux';
import { PageQueryParams } from 'src/shared/models';

import {
  deleteImportConfiguration as deleteImportConfigurationService,
  getImportConfigurations as getImportConfigurationsService,
  createImportConfiguration as createImportConfigurationService,
  getImportConfiguration as getImportConfigurationService,
  updateImportConfiguration as updateImportConfigurationService,
} from 'src/redux/services/import-configuration.service';
import { ImportConfigurationModel } from 'src/shared/models/import-configuration.model';

export const GET_IMPORT_CONFIGURATIONS = 'GET_IMPORT_CONFIGURATIONS';
export const GET_IMPORT_CONFIGURATIONS_SUCCESS = 'GET_IMPORT_CONFIGURATIONS_SUCCESS';
export const GET_IMPORT_CONFIGURATIONS_ERROR = 'GET_IMPORT_CONFIGURATIONS_ERROR';

export const DELETE_IMPORT_CONFIGURATION = 'DELETE_IMPORT_CONFIGURATION';
export const DELETE_IMPORT_CONFIGURATION_REQUEST = 'DELETE_IMPORT_CONFIGURATION_REQUEST';
export const DELETE_IMPORT_CONFIGURATION_SUCCESS = 'DELETE_IMPORT_CONFIGURATION_SUCCESS';
export const DELETE_IMPORT_CONFIGURATION_ERROR = 'DELETE_IMPORT_CONFIGURATION_ERROR';

export const CREATE_IMPORT_CONFIGURATION = 'ImportConfigurations/CREATE_IMPORT_CONFIGURATION';
export const CREATE_IMPORT_CONFIGURATION_SUCCESS =
  'ImportConfigurations/CREATE_IMPORT_CONFIGURATION_SUCCESS';
export const CREATE_IMPORT_CONFIGURATION_ERROR =
  'ImportConfigurations/CREATE_IMPORT_CONFIGURATION_ERROR';
export const READ_IMPORT_CONFIGURATION = 'ImportConfigurations/READ_IMPORT_CONFIGURATION';
export const READ_IMPORT_CONFIGURATION_SUCCESS =
  'ImportConfigurations/READ_IMPORT_CONFIGURATION_SUCCESS';
export const READ_IMPORT_CONFIGURATION_ERROR =
  'ImportConfigurations/READ_IMPORT_CONFIGURATION_ERROR';
export const UPDATE_IMPORT_CONFIGURATION = 'ImportConfigurations/UPDATE_IMPORT_CONFIGURATION';
export const UPDATE_IMPORT_CONFIGURATION_SUCCESS =
  'ImportConfigurations/UPDATE_IMPORT_CONFIGURATION_SUCCESS';
export const UPDATE_IMPORT_CONFIGURATION_ERROR =
  'ImportConfigurations/UPDATE_IMPORT_CONFIGURATION_ERROR';
export const REVERT_IMPORT_CONFIGURATION = 'ImportConfigurations/CLEAR_IMPORT_CONFIGURATION';
export const REVERT_IMPORT_CONFIGURATION_QUESTIONS =
  'ImportConfigurations/REVERT_IMPORT_CONFIGURATION_QUESTIONS';
export const REVERT_IMPORT_CONFIGURATION_QUESTION_ANSWERS =
  'ImportConfigurations/REVERT_IMPORT_CONFIGURATION_QUESTIONS';

export const getImportConfigurations = (appId: string, queryParams: PageQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_IMPORT_CONFIGURATIONS,
    });

    const importConfigurations = (await getImportConfigurationsService(
      appId,
      queryParams,
    )) as ImportConfigurationModel[];

    try {
      dispatch({
        type: GET_IMPORT_CONFIGURATIONS_SUCCESS,
        payload: {
          content: importConfigurations,
          pageable: false,
          totalPages: 1,
          totalElements: importConfigurations.length,
          numberOfElements: importConfigurations.length,
          first: true,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_IMPORT_CONFIGURATIONS_ERROR,
        error,
      });
    }
  };
};

export const deleteImportConfiguration = (appId: string, ImportConfigurationId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_IMPORT_CONFIGURATION,
    });

    try {
      await deleteImportConfigurationService(appId, ImportConfigurationId.toString());
      dispatch({
        type: DELETE_IMPORT_CONFIGURATION_SUCCESS,
        payload: { ImportConfigurationId },
      });
    } catch (error) {
      dispatch({
        type: DELETE_IMPORT_CONFIGURATION_ERROR,
        error,
      });
    }
  };
};

export const createImportConfiguration = (
  appId: string,
  ImportConfigurationData: ImportConfigurationModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_IMPORT_CONFIGURATION,
    });

    const response = await createImportConfigurationService(appId, ImportConfigurationData);

    try {
      dispatch({
        type: CREATE_IMPORT_CONFIGURATION_SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: CREATE_IMPORT_CONFIGURATION_ERROR,
        error,
      });
    }
  };
};

export const getImportConfiguration = (appId: string, ImportConfigurationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: READ_IMPORT_CONFIGURATION,
    });

    try {
      dispatch({
        type: READ_IMPORT_CONFIGURATION_SUCCESS,
        payload: await getImportConfigurationService(appId, ImportConfigurationId),
      });
    } catch (error) {
      dispatch({
        type: READ_IMPORT_CONFIGURATION_ERROR,
        error,
      });
    }
  };
};

export const updateImportConfiguration = (
  appId: string,
  ImportConfigurationId: string,
  data: ImportConfigurationModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_IMPORT_CONFIGURATION,
    });

    try {
      dispatch({
        type: UPDATE_IMPORT_CONFIGURATION_SUCCESS,
        payload: await updateImportConfigurationService(appId, ImportConfigurationId, data),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_IMPORT_CONFIGURATION_ERROR,
        error,
      });
    }
  };
};

export const revertImportConfiguration = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REVERT_IMPORT_CONFIGURATION,
    });
  };
};
