import React from 'react';
import { Table } from 'reactstrap';
import Pagination from 'react-js-pagination';
import classNames from 'classnames';

import { ListContentModel, ListRowModel, ListSortDirections } from 'src/shared/models';
import { Spinner, NoData } from 'src/shared/components';
import { ListRows } from './ListRows';

import styles from './List.module.scss';

interface ListContentProps<T> extends ListContentModel<T>, ListRowModel<T> {
  noData?: boolean;
  pageChange: Function;
  setQueryParams: Function;
  hideTableHead: boolean;
}

export const ListContent = <T extends { id: number } = any>({
  data,
  hidePager,
  fetchData,
  loaded,
  loading,
  noDataLabel,
  totalElements,
  fields,
  filterContent,
  queryParams,
  setQueryParams,
  activePage,
  noData,
  pageChange,
  hideTableHead,
  ...props
}: ListContentProps<T>) => {
  const changeSort = (key: string, sort: string | undefined) => {
    if (!sort) {
      return;
    }
    const sortString = `${key},${
      getCurrentSortDirection() === ListSortDirections.ASC
        ? ListSortDirections.DESC
        : ListSortDirections.ASC
    }`;
    setQueryParams({ ...queryParams, sort: sortString });
    fetchData({ ...queryParams, sort: sortString });
  };

  const getCurrentSortDirection = () => {
    return queryParams.sort?.split(',')[1];
  };

  const getSortIconClass = () => {
    return `fas fa-long-arrow-alt-${
      getCurrentSortDirection() === ListSortDirections.ASC ? 'up' : 'down'
    } ml-1`;
  };

  return (
    <>
      {filterContent ? <div className="mb-3">{filterContent()}</div> : null}

      {loaded && data.length > 0 && (
        <>
          <Table responsive striped hover size="sm">
            {!hideTableHead && (
              <thead>
                <tr>
                  {fields.map(({ label, key, columnStyle, operations, sort }) => (
                    <th
                      className={classNames(columnStyle && styles[columnStyle], {
                        [styles.operations]: operations,
                      })}
                      key={key}
                      onClick={() => changeSort(key, sort)}
                    >
                      {label}
                      {sort && <i className={getSortIconClass()} />}
                    </th>
                  ))}
                </tr>
              </thead>
            )}
            <ListRows
              data={data}
              fields={fields}
              queryParams={queryParams}
              fetchData={fetchData}
              activePage={activePage}
              {...props}
            />
          </Table>

          {!hidePager && (
            <Pagination
              activePage={activePage}
              itemsCountPerPage={queryParams.size}
              totalItemsCount={totalElements}
              pageRangeDisplayed={5}
              onChange={(index: number) => pageChange(index)}
            />
          )}
        </>
      )}

      {loading && (
        <div className="d-flex justify-content-center">
          <Spinner loading={loading} size="2x" />
        </div>
      )}

      {noData && <NoData label={noDataLabel} />}
    </>
  );
};

export default ListContent;
