import React from 'react';

import { ConversationModel } from '../../../../../redux/actions/conversations.action';

import SidebarContactRow from './SidebarContactRow';

import styles from '../../../ConversationHistory.module.scss';
import { ApplicationModel } from 'src/redux/actions/applications.action';

import {
  ConversationHistoryModel,
} from '../../../../../redux/actions/conversation-history.action';

interface Props {
  conversations: ConversationModel[];
  conversationsLoading: boolean;
  conversationsLoaded: boolean;
  handleConversationSelect: Function;
  application: ApplicationModel;
  conversationHistory: ConversationHistoryModel[];
}

const SidebarBody: React.FC<Props> = (props: Props) => {
  const {
    conversations,
    conversationsLoading,
    conversationsLoaded,
    handleConversationSelect,
    conversationHistory,
  } = props;

  return (
    <div id="sidebarBody" className={styles.sidebarBody}>
      {conversationsLoading && (
        <span className={styles.spinner}>
          <i className="fas fa-spinner fa-2x fa-spin" />
        </span>
      )}

      {conversations.length > 0 && !conversationsLoading && (
        <SidebarContactRow
          application={props.application}
          conversations={conversations}
          conversationHistory={conversationHistory}
          handleConversationSelect={handleConversationSelect}
        />
      )}

      {!conversations.length && conversationsLoaded && (
        <div className={styles.noConversations}>
          <span>No conversations found</span>
        </div>
      )}
    </div>
  );
};

export default SidebarBody;
