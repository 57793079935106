import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { getEvent } from 'src/redux/actions/events.action';
import {
  deleteEventQuestion,
  sortEventQuestions,
  updateEventQuestion,
} from 'src/redux/services/event.service';
import { EditableTextInput, List, TagPill } from 'src/shared/components';
import {
  Operations,
  OptionListAnswerConfiguration,
  TextAnswerConfiguration,
} from 'src/shared/models';
import { ANSWER_TYPES, EventQuestionModel } from 'src/shared/models/event.model';

interface QuestionsProps {
  eventQuestions: EventQuestionModel[];
  eventQuestionsLoading: boolean;
  eventQuestionsLoaded: boolean;
  eventQuestionsError: boolean;
  isEditingDisabled: boolean;
  getEvent: (appId: string, eventId: string) => void;
}

const Questions: FC<QuestionsProps> = ({
  eventQuestions,
  eventQuestionsLoading,
  eventQuestionsLoaded,
  eventQuestionsError,
  isEditingDisabled,
  getEvent,
}) => {
  const { t } = useTranslation();
  const { appId, eventId } = useParams<{ appId: string; eventId: string }>();

  const handleDelete = async (item: EventQuestionModel) => {
    await deleteEventQuestion(appId, eventId, item.id);
    getEvent(appId, eventId);
  };

  const handleQuestionEdit = async (item: EventQuestionModel) => {
    await updateEventQuestion(appId, eventId, item.id, item);
    getEvent(appId, eventId);
  };

  const onDragEnd = async (orderedItems: Array<any>) => {
    await sortEventQuestions(
      appId,
      eventId,
      orderedItems.map(e => e.id),
    );
    getEvent(appId, eventId);
  };

  const renderAnswer = (item: EventQuestionModel) => {
    const handleAnswerEdit = async (oldValue: string, newValue: string) => {
      if (item.answerConfiguration.type === ANSWER_TYPES.OPTIONS) {
        const answersOptions = (item.answerConfiguration as OptionListAnswerConfiguration).options;
        for (var key in answersOptions) {
          //@ts-ignore
          if (answersOptions[key] === oldValue) {
            //@ts-ignore
            answersOptions[key] = newValue;
            break;
          }
        }
        await handleQuestionEdit(item);
      } else if (item.answerConfiguration.type === ANSWER_TYPES.TEXT) {
        const textAnswer = item.answerConfiguration as TextAnswerConfiguration;
        if (!textAnswer.validationErrorMessage) {
          //In case of free text edit nothing to do
          return;
        } else {
          textAnswer.validationErrorMessage = newValue;
          await handleQuestionEdit(item);
        }
      }
    };
    const render = (text: string, id: number) => {
      return (
        <TagPill
          key={id}
          item={text}
          isDeleteable={false}
          isEditable={text !== t('events.freeText')}
          handleEdit={handleAnswerEdit}
          style={{ display: 'inline-flex' }}
        />
      );
    };
    let answers = [];

    if (item.answerConfiguration.type === ANSWER_TYPES.OPTIONS) {
      const opts = (item.answerConfiguration as OptionListAnswerConfiguration).options;
      for (var key in opts) {
        answers.push(opts[key]);
      }
    } else if (item.answerConfiguration.type === ANSWER_TYPES.TEXT) {
      const textAnswer = item.answerConfiguration as TextAnswerConfiguration;
      if (!textAnswer.validationErrorMessage) {
        answers.push(t('events.freeText'));
      } else {
        answers.push(textAnswer.validationErrorMessage);
      }
    }

    return <div>{answers.map((a, i) => render(a as string, i))}</div>;
  };

  return (
    <fieldset className="border p-3">
      <legend className="col-form-label w-auto font-weight-bold">{t('common.questions')}</legend>
      <List
        hidePager
        data={eventQuestions?.sort((q1, q2) => q1.order - q2.order)}
        totalElements={eventQuestions?.length}
        loaded={eventQuestionsLoaded}
        loading={eventQuestionsLoading}
        error={eventQuestionsError}
        fetchData={() => {}}
        noDataLabel={t('events.noEventQuestionAnswers')}
        deleteFunction={handleDelete}
        deleteTitle={t('events.deleteEventQuestionAnswer')}
        deleteText={t('events.deleteEventQuestionAnswerConfirmMessage')}
        updatePermission="event_question_update"
        deletePermission="event_question_delete"
        onDragEnd={isEditingDisabled ? undefined : onDragEnd}
        fields={[
          {
            key: 'title',
            label: t('common.text'),
            render: item => (
              <EditableTextInput
                id={item.id!}
                data={item.title}
                name="key"
                updateData={(appId: string, id: number, value: string) => {
                  item.title = value;
                  handleQuestionEdit(item);
                }}
                permission="event_question_update"
              />
            ),
          },
          {
            key: 'answers',
            label: t('common.answers'),
            render: renderAnswer,
          },
          {
            key: 'operations',
            label: t('common.operations'),
            operations: [Operations.DELETE],
          },
        ]}
      />
    </fieldset>
  );
};

const mapStateToProps = (state: any) => ({
  eventQuestions: state.eventGeneral.data?.event?.questions,
  eventQuestionsLoading: state.eventGeneral.loading,
  eventQuestionsLoaded: state.eventGeneral.loaded,
  eventQuestionsError: state.eventGeneral.error,
});

const mapDispatchToProps = {
  getEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
