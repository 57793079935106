import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import isEqual from 'lodash/isEqual';
import { toast } from 'react-toastify';
import { WithTranslation, withTranslation } from 'react-i18next';

import {
  ConversationModel,
  getConversations,
  setConversationsStatus,
  getConversationsQueryParams,
  setConversationsQueryParams,
  resetConversationsQueryParams,
  setCurrentConversation,
  setConversationPlatform,
} from '../../../../redux/actions/conversations.action';
import {
  getConversationHistory,
  clearConversationHistory,
  setCurrentUser,
  ConversationHistoryModel,
} from '../../../../redux/actions/conversation-history.action';
import { ApplicationModel } from '../../../../redux/actions/applications.action';

import {
  ConversationsQueryParams,
  QueryParams,
} from '../../../../shared/models/query-params.model';
import {
  getConversationStatus as getConversationStatusUtil,
  getCurrentConversation,
} from '../../../../shared/utils/conversations.util';

import SidebarBody from './components/SidebarBody';
import SidebarFooter from './components/SidebarFooter';

import styles from '../../ConversationHistory.module.scss';
import { ConversationHistoryFilters } from 'src/shared/models/conversation-history.model';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';

interface Props extends RouteComponentProps<{ id: string; userId: string }>, WithTranslation {
  application: ApplicationModel;
  platforms: string[];
  getConversations: (
    appId: string,
    queryParams: QueryParams,
    filters: ConversationHistoryFilters,
    grouping: DynamicGroupByModel,
    tabs: DynamicGroupByModel,
  ) => Promise<void>;
  conversations: ConversationModel[];
  conversation: ConversationModel;
  conversationsLoading: boolean;
  conversationsLoaded: boolean;
  totalPages: number;
  totalConversations: number;
  getConversationsQueryParams: Function;
  setConversationsQueryParams: Function;
  resetConversationsQueryParams: Function;
  setCurrentConversation: Function;
  setConversationPlatform: Function;
  queryParams: ConversationsQueryParams;
  getConversationHistory: Function;
  clearConversationHistory: Function;
  setCurrentUser: Function;
  setConversationsStatus: Function;
  currentUser: any;
  filter: ConversationHistoryFilters;
  grouping: DynamicGroupByModel;
  tabs: DynamicGroupByModel;
  conversationHistory: ConversationHistoryModel[];
}

class Sidebar extends Component<Props> {
  componentDidMount = async () => {
    const providedQueryParams = queryString.parse(this.props.location.search);
    const { page } = providedQueryParams;

    if (page) {
      this.setPage(Number(page));
    }
  };

  componentDidUpdate = (prev: Props) => {
    const { conversations, conversation, currentUser: user, history, t } = this.props;
    const { userId: prevUserId } = prev.match.params;
    const { userId: currUserId } = this.props.match.params;
    const { id } = this.props.match.params;

    // Beszélgetés és oldalváltás esetén lesz igaz a feltétel.
    if (prevUserId !== currUserId) {
      const conversationsWithStatus = getConversationStatusUtil(conversations);
      this.props.setConversationsStatus(conversationsWithStatus);
      const currentUser = this.getCurrentUser(conversations);

      if (!currentUser) {
        if (this.props.conversationsLoading || !this.props.conversationsLoaded) {
          return false;
        }
        const providedQueryParams = queryString.parse(this.props.location.search);
        if (Number(providedQueryParams.page) !== this.props.queryParams.page) {
          return this.getConversations(Number(providedQueryParams.page));
        }
      }

      if (currUserId && conversations.length > 0) {
        const currentConversation = getCurrentConversation(currUserId, conversations);

        if (currentConversation?.messagingPlatform && !isEqual(currentConversation, conversation)) {
          this.props.setCurrentConversation(currentConversation);
          this.props.setConversationPlatform(currentConversation.messagingPlatform);
        }

        if (!currentConversation) {
          toast.error(t('conversationHistory.conversationNotFound'));
          history.push(`/application/${id}/conversations`);

          return;
        }
      }

      if (currentUser) {
        this.props.setCurrentUser(currentUser);
      }
    }

    // Csak a betöltődést követő első alkalommal lesz igaz.
    if (conversations.length > 0 && !user.uuid && currUserId) {
      const conversationsWithStatus = getConversationStatusUtil(conversations);
      this.props.setConversationsStatus(conversationsWithStatus);
      const currentUser = this.getCurrentUser(conversations);

      const currentConversation = getCurrentConversation(currUserId, conversations);

      if (currentConversation?.messagingPlatform && !isEqual(currentConversation, conversation)) {
        this.props.setCurrentConversation(currentConversation);
        this.props.setConversationPlatform(currentConversation.messagingPlatform);
      }
      if (!currentConversation) {
        toast.error(t('conversationHistory.conversationNotFound'));
        history.push(`/application/${id}/conversations`);
        return;
      }

      if (currentUser) {
        this.props.setCurrentUser(currentUser);
      } else {
        this.props.setCurrentUser({ user: 'Unknown User', uuid: 'unknown-uuid' });
      }
    }
  };

  getConversations = async (forcePage?: number) => {
    const { queryParams } = this.props;
    const { id } = this.props.match.params;

    if (typeof forcePage !== 'undefined') {
      await this.props.getConversations(
        id,
        { ...queryParams, page: forcePage },
        this.props.filter,
        this.props.grouping,
        this.props.tabs
      );
      this.setPage(forcePage);
      return;
    }

    await this.props.getConversations(id, queryParams, this.props.filter, this.props.grouping, this.props.tabs);
    const { conversations } = this.props;

    if (conversations.length > 0) {
      const { uuid } = conversations[0];
      const { page } = queryParams;

      const conversationsWithStatus = getConversationStatusUtil(conversations);
      this.props.setConversationsStatus(conversationsWithStatus);
      this.setPage(page);
      const urlToPush = `/application/${id}/conversations/${uuid}?page=${page}`;
      this.props.history.push(urlToPush);
    }
  };

  getCurrentUser = (conversations: ConversationModel[]) => {
    const { userId } = this.props.match.params;
    // A kiválasztott user-t az URL-ben található UserId alapján keresem ki a beszélgetések listájábából,
    // mivel csak az tartalmazza a User Id-t.
    const currentUser = conversations.filter((conversation: ConversationModel) => {
      return conversation.uuid === userId;
    });

    if (currentUser && currentUser.length > 0) {
      const currentUserObject = {
        name: currentUser[0].name,
        uuid: currentUser[0].uuid,
      };

      return currentUserObject;
    }

    return null;
  };

  handleConversationSelect = (conversation: ConversationModel) => {
    const { uuid, name } = conversation;
    const { page } = this.props.queryParams;
    const { id } = this.props.application;

    this.props.history.push(`/application/${id}/conversations/${uuid}?page=${page}`);
    this.props.setCurrentUser({ name, uuid });
    // this.props.setConversationPlatform(conversation.messagingPlatform);
    // this.props.setCurrentConversation(conversation);
  };

  handlePageChange = async (offset: number) => {
    const { totalPages, queryParams } = this.props;
    const { page } = queryParams;
    const sidebarBodyDiv = document.getElementById('sidebarBody');

    if (page + offset < 0 || page + offset >= totalPages) {
      return null;
    }

    const newQueryParams = {
      ...queryParams,
      page: page + offset,
    };

    await this.props.setConversationsQueryParams(newQueryParams);
    this.getConversations();

    if (sidebarBodyDiv) {
      sidebarBodyDiv.scrollTo(0, 0);
    }
  };

  setPage = async (index: number) => {
    const { queryParams } = this.props;

    const newQueryParams = {
      ...queryParams,
      page: index,
    };

    await this.props.setConversationsQueryParams(newQueryParams);

    // this.setState(prevState => ({
    //   queryParams: {
    //     ...prevState.queryParams,
    //     page: index,
    //   },
    // }));
  };

  render() {
    const {
      application,
      conversations,
      totalPages,
      conversationsLoading,
      conversationsLoaded,
      queryParams,
      conversationHistory,
    } = this.props;

    return (
      <div className={styles.sidebarContainer}>
        {/* <SidebarHeader
          getConversations={this.getConversations}
          setQueryParams={setConversationsQueryParams}
          resetQueryParams={resetConversationsQueryParams}
          queryParams={queryParams}
          platforms={platforms}
        /> */}
        <SidebarBody
          application={application}
          conversations={conversations}
          conversationHistory={conversationHistory}
          conversationsLoading={conversationsLoading}
          conversationsLoaded={conversationsLoaded}
          handleConversationSelect={this.handleConversationSelect}
        />
        <SidebarFooter
          handlePageChange={this.handlePageChange}
          currentPage={queryParams.page + 1}
          totalPages={totalPages}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  conversations: state.conversations.data.content,
  conversation: state.conversations.data.currentConversation,
  queryParams: state.conversationsQueryParams,
  totalPages: state.conversations.data.totalPages,
  totalConversations: state.conversations.data.totalElements,
  currentUser: state.conversationHistoryCurrentUser.user,
});

const mapDispatchToProps = {
  getConversations,
  getConversationsQueryParams,
  setConversationsQueryParams,
  resetConversationsQueryParams,
  setCurrentConversation,
  setConversationPlatform,
  getConversationHistory,
  clearConversationHistory,
  setCurrentUser,
  setConversationsStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Sidebar)));
