import React, { FC } from 'react';
import { FormGroup, Label, Row, Col, FormFeedback } from 'reactstrap';

import { ArrayHelpers, FieldArray, useFormikContext } from 'formik';

import { ReactSelect, ReactCreateableSelect } from 'src/shared/components';
import { SelectModel } from 'src/shared/models';
import { FollowUpNotificationsEditorModel } from 'src/shared/models/follow-up-notifications.model';
import { useTranslation } from 'react-i18next';

interface StartsAtComposerProps {
  platforms: string[];
  startsAtValueOptions: SelectModel[];
}

const StartsAtComposer: FC<StartsAtComposerProps> = ({ platforms, startsAtValueOptions }) => {
  const { t } = useTranslation();
  const { values, errors } = useFormikContext<FollowUpNotificationsEditorModel>();

  const messagingPlatformsValueOptions: SelectModel[] = platforms.map(p => {
    return { label: p, value: p };
  });

  const remove = (arrayHelpers: ArrayHelpers, index: number) => {
    arrayHelpers.remove(index);
  };

  const add = (arrayHelpers: ArrayHelpers) => {
    arrayHelpers.push({ messagingPlatform: '', value: '' });
  };

  return (
    <FieldArray
      name="startsAt"
      render={arrayHelpers => (
        <>
          <FormGroup>
            <Label for="startsAt">{t('common.startsAt')}</Label>
            {values.startsAt.length > 0 && (
              <Row>
                {Object.keys(values.startsAt[0]).map(name => (
                  <Col key={name}>
                    <Label>{t(`common.${name}`)}</Label>
                  </Col>
                ))}
                <Col lg={1}></Col>
              </Row>
            )}
            {values.startsAt &&
              values.startsAt.map((item, index) => (
                <Row className="mb-2" key={index}>
                  <Col>
                    <ReactSelect
                      name={`startsAt[${index}].messagingPlatform`}
                      options={messagingPlatformsValueOptions}
                    />
                  </Col>
                  <Col>
                    <ReactCreateableSelect
                      name={`startsAt[${index}].value`}
                      options={startsAtValueOptions}
                    />
                  </Col>
                  <Col lg={1}>
                    <div>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => remove(arrayHelpers, index)}
                      >
                        <i className="far fa-trash-alt" />
                      </button>
                    </div>
                  </Col>
                </Row>
              ))}
            <Row className="justify-content-center mt-3">
              <button type="button" className="btn btn-primary" onClick={() => add(arrayHelpers)}>
                <span className="mr-2">{t('common.add')}</span>
                <i className="fas fa-plus" />
              </button>
            </Row>
            {errors.startsAt && (
              <FormFeedback style={{ display: 'block' }}>{errors.startsAt}</FormFeedback>
            )}
          </FormGroup>
        </>
      )}
    />
  );
};

export default StartsAtComposer;
