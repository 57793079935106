import { get, post } from '../../shared/services/http.service';
import { QueryParams } from '../../shared/models/query-params.model';
import { SalaryAdvanceFilters } from '../../shared/models';

export const getSalaryAdvancesService = async (
  appId: string,
  salaryAdvanceFilters: SalaryAdvanceFilters,
  queryParams?: QueryParams,
) => {
  return post(`/hrops/applications/${appId}/salary-advances`, {
    params: queryParams,
    data: salaryAdvanceFilters,
  });
};

export const exportSalaryAdvances = async (
  appId: string,
  salaryAdvanceFilters: SalaryAdvanceFilters,
  queryParams?: object,
) => {
  return post(`hrops/applications/${appId}/salary-advances/export/xlsx`, {
    params: queryParams,
    data: salaryAdvanceFilters,
    requestConfig: {
      responseType: 'blob',
    },
  });
};

export const getSalaryAdvancesFilters = async (appId: string) => {
  return get(`hrops/applications/${appId}/salary-advances/filter-list`);
};

export default getSalaryAdvancesService;
