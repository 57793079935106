import { Dispatch } from 'redux';
import { PageQueryParams } from 'src/shared/models';

import {
  getCriteriaImports as getCriteriaImportsService,
  createCriteriaImport as createCriteriaImportService,
} from 'src/redux/services/criteria-import.service';
import {
  CriteriaImportImportModel,
  CriteriaImportModel,
} from 'src/shared/models/criteria-import.model';

export const GET_CRITERIA_IMPORTS = 'GET_CRITERIA_IMPORTS';
export const GET_CRITERIA_IMPORTS_SUCCESS = 'GET_CRITERIA_IMPORTS_SUCCESS';
export const GET_CRITERIA_IMPORTS_ERROR = 'GET_CRITERIA_IMPORTS_ERROR';

export const CREATE_CRITERIA_IMPORT = 'CriteriaImports/CREATE_CRITERIA_IMPORT';
export const CREATE_CRITERIA_IMPORT_SUCCESS = 'CriteriaImports/CREATE_CRITERIA_IMPORT_SUCCESS';
export const CREATE_CRITERIA_IMPORT_ERROR = 'CriteriaImports/CREATE_CRITERIA_IMPORT_ERROR';

export const getCriteriaImports = (appId: string, queryParams: PageQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_CRITERIA_IMPORTS,
    });

    const CriteriaImport = (await getCriteriaImportsService(
      appId,
      queryParams,
    )) as CriteriaImportModel[];

    try {
      dispatch({
        type: GET_CRITERIA_IMPORTS_SUCCESS,
        payload: {
          content: CriteriaImport,
          pageable: false,
          totalPages: 1,
          totalElements: CriteriaImport.length,
          numberOfElements: CriteriaImport.length,
          first: true,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_CRITERIA_IMPORTS_ERROR,
        error,
      });
    }
  };
};

export const createCriteriaImport = (
  appId: string,
  CriteriaImportData: CriteriaImportImportModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_CRITERIA_IMPORT,
    });

    const response = await createCriteriaImportService(appId, CriteriaImportData);

    try {
      dispatch({
        type: CREATE_CRITERIA_IMPORT_SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: CREATE_CRITERIA_IMPORT_ERROR,
        error,
      });
    }
  };
};
