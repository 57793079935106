import React, { FC } from 'react';
import classNames from 'classnames';
import { Col } from 'reactstrap';

import styles from './SurveyHeaderTile.module.scss';

export enum TileType {
  COMPLETED = 'completed',
  SUBSCIBED = 'subscribed',
  RUNNING = 'running',
}

interface SurveyHeaderTileProps {
  label: string;
  value: number | string | React.ReactNode;
  type: TileType;
}

const SurveyHeaderTile: FC<SurveyHeaderTileProps> = ({ label, value, type }) => (
  <Col>
    <Col className={classNames('pr-0 pl-2', styles.tileContent, [styles[type]])}>
      <Col sm={12} className={classNames('p-0', styles.tileContentLabel)}>
        {label}
      </Col>
      <Col sm={12} className={classNames('p-0', styles.tileContentValue)}>
        {value}
      </Col>
    </Col>
  </Col>
);

export default SurveyHeaderTile;
