import React, { } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Col,
  Label,
} from 'reactstrap';
import { Formik, Form, FormikValues } from 'formik';

import { getApplication } from 'src/redux/actions/applications.action';

import { Spinner, TextArea } from 'src/shared/components';
import yup, { } from 'src/shared/schemas/validation.schema';
import { sendMessage } from 'src/redux/services/outbound-messages.service';

interface Props {
  isOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  userProfileId: string;
}

const SendMessageModal: React.FC<Props> = ({ isOpen, setModalOpen, userProfileId }) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string; }>();

  const onSubmit = async (values: FormikValues) => {
    try {
      await sendMessage({ message: values.message }, appId, userProfileId);
    }
    finally {
      setModalOpen(false);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        {t('common.sendMessage')}
      </ModalHeader>
      <Formik
        initialValues={{ message: '' }}
        validationSchema={yup.object().shape({
          message: yup
            .string()
            .required(t('errors.isRequired', { item: t('common.name') }))
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <FormGroup row>
                <Col sm="4">
                  <Label>{t('common.messageText')}</Label>
                </Col>
                <Col>
                  <TextArea name="message" />
                </Col>
              </FormGroup>
            </ModalBody>

            <ModalFooter>
              <Spinner loading={isSubmitting} className="mr-1" />
              <Button type="submit" disabled={isSubmitting} color="primary">
                {t('common.submit')}
              </Button>
              <Button color="secondary" onClick={() => setModalOpen(false)}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
});

const mapDispatchToProps = {
  getApplication
};

export default connect(mapStateToProps, mapDispatchToProps)(SendMessageModal);
