import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert, Table } from 'reactstrap';
import { FormikValues } from 'formik';

import { useDeepEffect } from 'src/shared/hooks';
import { StateModel } from 'src/shared/models/default-state.model';
import { ExpressionModel } from 'src/shared/models/questionnaire.model';
import {
  createExpression,
  deleteExpression,
  getExpressions,
  updateExpression,
} from 'src/redux/actions/questionnaire.action';
import { getPositions } from 'src/redux/actions/positions.action';
import ConfirmModal from 'src/shared/components/modals/ConfirmModal';
import CheckPermission from 'src/shared/components/CheckPermission';
import Spinner from 'src/shared/components/Spinner';
import ServerError from 'src/shared/components/ServerError';

import ExpressionsTableHeader from './ExpressionsTableHeader';
import ExpressionsTableBody from './ExpressionsTableBody';
import ExpressionsModal from './ExpressionsModal';

interface Props {
  expressions: StateModel;
  getExpressions: Function;
  createExpression: Function;
  updateExpression: Function;
  deleteEvent: StateModel;
  deleteExpression: Function;
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  modalEvent: string;
}

const ExpressionsTable: React.FC<Props> = ({
  expressions,
  getExpressions,
  createExpression,
  updateExpression,
  deleteEvent,
  deleteExpression,
  isModalOpen,
  setModalOpen,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams();

  const [selectedExpression, setSelectedSelectedExpression] = useState<ExpressionModel>();
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  useEffect(() => {
    getPositions(appId);
    /* eslint-disable-next-line */
  }, []);

  useDeepEffect(() => {
    getExpressions(appId);
  }, [appId]);

  useEffect(() => {
    if (!isModalOpen && selectedExpression) {
      setSelectedSelectedExpression(undefined);
    }
    /* eslint-disable-next-line */
  }, [isModalOpen]);

  useEffect(() => {
    if (!isConfirmModalOpen && selectedExpression) {
      setSelectedSelectedExpression(undefined);
    }
    /* eslint-disable-next-line */
  }, [isConfirmModalOpen]);

  const handleSubmit = async (values: FormikValues) => {
    await createExpression(values);
    await getExpressions(appId);

    setModalOpen(false);
  };

  const handleDelete = async () => {
    if (selectedExpression) {
      await deleteExpression(selectedExpression.id);
      await getExpressions(appId);

      setConfirmModalOpen(false);
    }
  };

  return (
    <Fragment>
      {expressions.loading && (
        <div className="d-flex justify-content-center">
          <Spinner size="2x" loading={expressions.loading} />
        </div>
      )}

      {expressions.loaded && !expressions.data?.length && (
        <Alert className="text-center" color="info">
          {t('expressions.noExpressionsFound')}
        </Alert>
      )}

      {expressions.error && <ServerError />}

      {expressions.loaded && expressions.data?.length > 0 && (
        <Fragment>
          <Table size="sm" hover striped responsive>
            <ExpressionsTableHeader />
            <ExpressionsTableBody
              expressions={expressions.data}
              selectExpression={setSelectedSelectedExpression}
              toggleConfirmModal={setConfirmModalOpen}
              updateExpression={updateExpression}
            />
          </Table>
        </Fragment>
      )}

      <CheckPermission variant="displayIf" permissions={['expression_create']}>
        <ExpressionsModal
          isOpen={isModalOpen}
          toggleModal={() => setModalOpen(false)}
          confirm={handleSubmit}
          selectedExpression={selectedExpression}
        />
      </CheckPermission>

      <CheckPermission variant="displayIf" permissions={['expression_delete']}>
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          title={t('expressions.deleteExpression')}
          text={t('expressions.deleteExpressionConfirm')}
          item={selectedExpression?.title}
          isLoading={deleteEvent.loading}
          confirm={handleDelete}
          cancel={() => setConfirmModalOpen(false)}
        />
      </CheckPermission>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  expressions: state.expressions.resources,
  deleteEvent: state.expressions.delete,
  positions: state.positions.data.positions.data?.content,
});

const mapDispatchToProps = {
  createExpression,
  deleteExpression,
  getExpressions,
  updateExpression,
  getPositions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpressionsTable);
