import {
  NotSignedInEmployeeReports,
  SignedInEmployeeList,
  SignedInEmployeeReports,
} from 'src/shared/models/employee-reports.model';
import { get } from 'src/shared/services/http.service';

const baseUrl = (applicationId: string) => `hrops/applications/${applicationId}/employee/analytics`;

export const getSignedInEmployeeReport = async (
  applicationId: string,
): Promise<SignedInEmployeeReports> => get(`${baseUrl(applicationId)}/signed-in-employee-report`);

export const getSignedInEmployeeList = async (
  applicationId: string,
): Promise<SignedInEmployeeList> => get(`${baseUrl(applicationId)}/signed-in-employee-list`);

export const getNotSignedInEmployeeReport = async (
  applicationId: string,
): Promise<NotSignedInEmployeeReports> =>
  get(`${baseUrl(applicationId)}/not-signed-in-employee-list`);

export const exportEmployeeReport = async (applicationId: string) =>
  get(`${baseUrl(applicationId)}/employee-report/export/xlsx`, {
    requestConfig: { responseType: 'blob' },
  });
