// src/App.tsx

import React, { Fragment, useEffect } from 'react';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from './shared/settings/msalConfig';

import { logout, login } from './redux/actions/auth.action';
import { isAuthenticated } from './redux/services/auth.service';

import './App.scss';

import Login from './containers/Login/Login';
import DefaultLayout from './containers/DefaultLayout/DefaultLayout';

interface Props {
  token: string;
  login: Function;
  logout: Function;
}

const msalInstance = new PublicClientApplication(msalConfig);

const App: React.FC<Props> = ({ token, login, logout }) => {

  useEffect(() => {
    const callbackId = msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.SSO_SILENT_SUCCESS || event.eventType === EventType.LOGIN_SUCCESS) {
        console.log(`${event.eventType} Success`);
        const result = event.payload as AuthenticationResult;
        const idToken = result.idToken;

        login({ idToken });
      }
      if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
        event.eventType === EventType.LOGIN_FAILURE) {
        console.log(`${event.eventType} Failed`);
        logout();
      }
    });

    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };
  }, [login, logout]);

  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [logout]);

  return (
    <MsalProvider instance={msalInstance}>
      <Router>
        <Fragment>
          <ToastContainer />
          <Switch>
            <Route exact path="/" component={() => <Redirect to="/login" />} />
            <Route
              exact
              path="/login"
              render={() =>
                isAuthenticated() ? <Redirect to="/applications" /> : <Login />
              }
            />
            <Route path="/" render={props => isAuthenticated() ? <DefaultLayout {...props} /> : <Redirect to="/login" />} />
          </Switch>
        </Fragment>
      </Router>
    </MsalProvider>
  );
};

const mapStateToProps = (state: any) => ({
  token: state.token.data,
});

const mapDispatchToProps = {
  login,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
