import React, { useState, ReactElement, Fragment } from 'react';
import cx from 'classnames';

import Button from 'reactstrap/lib/Button';

import styles from './EditableLabel.module.scss';

interface Props {
  text: string | number | undefined;
  type: string;
  isEditing?: boolean;
  isChildrenFormik?: boolean;
  setEditing?: Function;
  setInputVale?: Function;
  placeholder: string | number | undefined;
  canBeEmpty?: boolean;
  disabled?: boolean;
  children: ReactElement;
  handleEdit?: Function;
  handleCancel?: Function;
}

const EditableLabel: React.FC<Props> = ({
  text,
  type,
  isEditing: isEditingFromParent = undefined,
  setEditing: setEditingFromParent = undefined,
  setInputVale: setInputValeFromParent = undefined,
  placeholder,
  disabled = false,
  canBeEmpty = false,
  children,
  handleEdit,
  handleCancel,
  isChildrenFormik,
  ...props
}) => {
  const [isEditing, setEditing] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const isEditingVariable = isEditingFromParent || isEditing;
  const setEditingFunction = setEditingFromParent || setEditing;

  const labelContainerClasses = cx(styles.labelContainer, {
    [styles.disabled]: disabled,
  });

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    if (!disabled) {
      setEditingFunction(true);
    }
  };

  const handleKeyDown = (event: any, type: string) => {
    const { key } = event;
    const keys = ['Escape', 'Tab'];
    const enterKey = 'Enter';
    const escapeKey = 'Escape';
    const allKeys = [...keys, enterKey];

    if (key === escapeKey) return handleOnCancel();

    if (
      (type === 'textarea' && keys.indexOf(key) > -1) ||
      (type !== 'textarea' && allKeys.indexOf(key) > -1)
    ) {
      handleOnConfirm();
    }
  };

  const handleOnConfirm = async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.stopPropagation();
    if (!handleEdit && !canBeEmpty && isInputEmpty()) return;
    if (handleEdit && !isLoading) {
      setLoading(true);
      const valid = await handleEdit();
      setLoading(false);

      if (valid) {
        setEditingFunction(false);
      }
    }
  };

  const handleOnCancel = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.stopPropagation();
    setEditingFunction(false);
    if (setInputValeFromParent) setInputValeFromParent(text);
    if (handleCancel) handleCancel();
  };

  const isInputEmpty = () => {
    const input = document.getElementById(
      !isChildrenFormik ? children.props.id : children.props.children.props.id,
    ) as HTMLInputElement;

    if (input) {
      if (!input.value) {
        if (!input.classList.contains('is-invalid')) {
          input.classList.add('is-invalid');
        }
        return true;
      }

      if (input.classList.contains('is-invalid')) {
        input.classList.remove('is-invalid');
      }
      return false;
    }

    return true;
  };

  return (
    <Fragment>
      {isEditingVariable ? (
        <div
          onBlur={() => handleOnConfirm()}
          onKeyDown={e => handleKeyDown(e, type)}
          className={styles.childrenContainer}
          {...props}
        >
          {children}
          <div className={styles.actionButtons}>
            <Button size="sm" className={styles.actionButton} onMouseDown={e => handleOnConfirm(e)}>
              <i className="fas fa-check" />
            </Button>
            <Button size="sm" className={styles.actionButton} onMouseDown={e => handleOnCancel(e)}>
              <i className="fas fa-times" />
            </Button>
          </div>
        </div>
      ) : (
        <div className={labelContainerClasses} onClick={e => handleOnClick(e)} {...props}>
          <div className={styles.label}>{text || placeholder || '—'}</div>
        </div>
      )}
    </Fragment>
  );
};

export default EditableLabel;
