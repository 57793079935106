import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';

import { createDynamicFaq } from 'src/redux/actions/dynamic-faq.action';
import { dynamicFaqValidatorSchema } from 'src/shared/schemas/validation.schema';
import { DragAndDrop, ReactSelect, Spinner } from 'src/shared/components';
import {
  Extensions,
  ImportConfigurationModel,
  importConfigurationSelectOptionModel,
  ImportConfigurationTypes,
} from 'src/shared/models';
import { getImportConfigurations } from 'src/redux/services/import-configuration.service';
import { uploadFile } from 'src/shared/services/upload.service';
import { getSignatureForUpload, SignatureForUploadModel } from 'src/redux/actions/upload.action';

interface Props {
  isOpen: boolean;
  signature: SignatureForUploadModel;
  toggleModalState: Function;
  onSaved?: Function;
  createDynamicFaq: Function;
  getSignatureForUpload: Function;
}

const DynamicFaqModalForm: React.FC<Props> = ({
  isOpen,
  signature,
  toggleModalState,
  onSaved,
  createDynamicFaq,
  getSignatureForUpload,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [fileError, setFileError] = useState<string | undefined>(undefined);
  const [importConfiguration, setImportConfiguration] = useState<{
    url: string;
    configId: string;
  } | null>(null);
  const [importConfigOptions, setImportConfigOptions] = useState<
    importConfigurationSelectOptionModel[]
  >([]);

  const getFormInitValues = () => ({
    configId: { value: '', label: '' },
    url: '',
  });

  useEffect(() => {
    setTimeout(async () => {
      if (signature && selectedFile) {
        await uploadFile(selectedFile, signature);
        setImportConfiguration(Object.assign(importConfiguration, { url: signature.downloadUrl }));
        await createDynamicFaq(appId, importConfiguration);
        if (onSaved) {
          onSaved();
        }
        setIsImporting(false);
        toggleModalState(false);
      }
    }, 1000);
    // eslint-disable-next-line
  }, [signature]);

  useEffect(() => {
    if (isImporting) setIsImporting(false);
    if (selectedFile) setSelectedFile(undefined);
    if (fileError) setFileError(undefined);
    if (isOpen) {
      loadConfigurations();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const loadConfigurations = async () => {
    const configData: ImportConfigurationModel[] = await getImportConfigurations(appId, {
      page: 0,
      size: 0,
    });
    const config = configData.filter(e => e.type === ImportConfigurationTypes.DYNAMIC_FAQ);

    setImportConfigOptions(config.map(item => ({ label: item.title, value: item.id.toString() })));
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{t('dynamicFaq.createDynamicFaqs')}</ModalHeader>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={getFormInitValues()}
        validationSchema={dynamicFaqValidatorSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setIsImporting(true);
          setSubmitting(true);
          setImportConfiguration({ configId: values.configId.value, url: values.url });
          getSignatureForUpload(Extensions.XLSX, 'PUT');
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <ModalBody>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="configId">{t('importConfiguration.configuration')}</Label>
                    <ReactSelect name="configId" options={importConfigOptions} />
                  </FormGroup>{' '}
                </Col>
              </Row>
              <DragAndDrop
                acceptedExt={Extensions.XLSX}
                setFile={setSelectedFile}
                file={selectedFile}
                setFileError={setFileError}
                fileError={fileError}
              />
            </ModalBody>

            <ModalFooter>
              <Spinner loading={isImporting} />

              <Button
                type="submit"
                color="success"
                disabled={!selectedFile || isSubmitting || isImporting || !values.configId}
              >
                {t('common.save')}
              </Button>

              <Button color="secondary" onClick={() => toggleModalState(false)}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  signature: state.signatureForUpload.data,
});

const mapDispatchToProps = {
  createDynamicFaq,
  getSignatureForUpload,
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicFaqModalForm);
