import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';

import { generateId } from '../utils/generate-id.util';
import { ApplicationModel } from '../../redux/actions/applications.action';
import { ListModel } from '../../redux/reducers/lists.reducer';
import { ConversationModel } from '../../redux/actions/conversations.action';
import { TalentPoolModels } from '../models/talent-pools.model';
import { SubscribingListModel } from '../models/subscribing-lists.model';
import { PositionModel } from '../models/positions.model';
import { CustomListModel } from '../models/custom-lists.model';

interface Props extends RouteComponentProps, WithTranslation {
  application: ApplicationModel;
  list: ListModel;
  conversations: ConversationModel;
  currentUser: any;
  talentPool: TalentPoolModels;
  subscribingList: SubscribingListModel;
  position: PositionModel;
  customList: CustomListModel;
}

interface BreadMapItem {
  pattern: RegExp;
  view: Function;
}

const AppBreadcrumb: React.FC<Props> = props => {
  const { t } = useTranslation();

  const breadMap: BreadMapItem[] = [
    {
      pattern: /\/applications.*/,
      view: () => {
        return [`${t('common.applications')}`];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/summary.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.summary')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/conversations.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.conversations')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/conversations\/.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          <Link to={`/application/${props.application.id}/conversations`}>
            {t('common.conversations')}
          </Link>,
          props.currentUser.name
            ? `${t('common.conversationWithUser', {
                user: `${props.currentUser.name}`,
              })}`
            : null,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/faq.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.modules')}`,
          `${t('common.faqDatasetUpload')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/dynamic_faq.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.modules')}`,
          `${t('dynamicFaq.dynamicFaq')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/dynamic_conv_flow.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.modules')}`,
          `${t('dynamicConvFlow.dynamicConvFlow')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/dynamic_menu.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.modules')}`,
          `${t('common.dynamicMenuUpload')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/events.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.modules')}`,
          `${t('common.events')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/events\/create.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          <Link to={`/application/${props.application.id}/modules/events`}>
            {t('events.events')}
          </Link>,
          `${t('events.createEvent')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/events\/[0-9]+\/edit.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          <Link to={`/application/${props.application.id}/modules/events`}>
            {t('events.events')}
          </Link>,
          `${t('events.editEvent')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/events\/[0-9]+\/report.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          <Link to={`/application/${props.application.id}/modules/events`}>
            {t('events.events')}
          </Link>,
          `${t('events.reportEvent')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/subscribing-lists.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.modules')}`,
          `${t('common.subscribingLists')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/subscribing-lists\/[0-9]+.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.modules')}`,
          <Link to={`/application/${props.application.id}/modules/subscribing-lists`}>
            {t('common.subscribingLists')}
          </Link>,
          props.subscribingList.name || <i>${t('common.unknownSubscribingList')}</i>,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/batch-messages.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.modules')}`,
          `${t('common.batchMessages')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/complaints.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.modules')}`,
          `${t('common.complaints')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/follow-up-notifications.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.modules')}`,
          `${t('common.followUpNotifications')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/reports-analytics\/feedbacks.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.reportsAndAnalytics')}`,
          `${t('common.feedbacks')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/reports-analytics\/usage-statistics.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.reportsAndAnalytics')}`,
          `${t('common.usageStatistics')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/reports-analytics\/intent-statistics.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.reportsAndAnalytics')}`,
          `${t('common.intentStatistics')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/reports-analytics\/topic-statistics.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.reportsAndAnalytics')}`,
          `${t('common.topicStatistics')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/reports-analytics\/application-efficiency.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.reportsAndAnalytics')}`,
          `${t('common.applicationEfficiency')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/reports-analytics\/unrecognized-sentences.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.reportsAndAnalytics')}`,
          `${t('common.unrecognizedSentences')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/reports-analytics\/outbound-messages.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.reportsAndAnalytics')}`,
          `${t('common.outgoingMessageReport')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/reports-analytics\/employee-chatbot-usage-reports.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.reportsAndAnalytics')}`,
          `${t('common.employeeReports')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/ai-training-room\/import-training-data.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.aiTrainingRoom')}`,
          `${t('common.importTrainingData')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/ai-training-room\/training-data.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.aiTrainingRoom')}`,
          `${t('common.trainingData')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/system-management\/conversationflow.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.systemManagement')}`,
          `${t('conversationFlowVariables.conversationFlowVariables')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/system-management\/custom-fields.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.systemManagement')}`,
          `${t('common.customFields')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/system-management\/custom-lists.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.systemManagement')}`,
          `${t('common.customLists')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/system-management\/custom-lists\/[0-9]+.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.systemManagement')}`,
          <Link to={`/application/${props.application.id}/system-management/custom-lists`}>
            {t('common.customLists')}
          </Link>,
          `${props.customList.name || ''}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/system-management\/settings.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.systemManagement')}`,
          `${t('common.settings')}`,
        ];
      },
    },

    {
      pattern: /\/application\/[0-9]+\/system-management\/regex-patterns.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.systemManagement')}`,
          `${t('common.regexPatterns')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/system-management\/regex-replacer-patterns.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.systemManagement')}`,
          `${t('common.regexReplacerPatterns')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/system-management\/expressions.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.systemManagement')}`,
          `${t('common.expressions')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/system-management\/import-export.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.systemManagement')}`,
          `${t('common.importExport')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/system-management\/import-configuration.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.systemManagement')}`,
          `${t('importConfiguration.importConfiguration')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/system-management\/import-conversation-flow-variables.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.systemManagement')}`,
          `${t('common.importConversationFlowVariables')}`,
        ];
      },
    },
    {
      pattern: /\/users.*/,
      view: () => {
        return [<Link to="/applications">{t('common.applications')}</Link>, `${t('common.users')}`];
      },
    },
    {
      pattern: /\/personas.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          `${t('common.personas')}`,
        ];
      },
    },
    {
      pattern: /\/audit-log.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          `${t('common.auditLog')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/recruiter\/talent-pool.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.talentPool')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/recruiter\/questionnaire.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.questionnaire')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/recruiter\/interviews.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.interviews')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/recruiter\/criteria-categories.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.criteriaCategories')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/recruiter\/criteria-import.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('criteriaImport.criteriaImport')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/recruiter\/labels.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.labels')}`,
        ];
      },
    },
    {
      pattern: /\/talent-pools.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          `${t('common.talentPools')}`,
        ];
      },
    },
    {
      pattern: /\/talent-pools\/[0-9]+\/candidates.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          <Link to="/talent-pools">{t('common.talentPools')},</Link>,
          props.talentPool.name || `${t('common.unknownTalentPool')}`,
          `${t('common.candidates')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/documents.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>,
          `${t('common.documents')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/documents\/[0-9].*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>,
          <Link to={`/application/${props.application.id}/modules/documents`}>
            {t('common.documents')}
          </Link>,
          props.list.name,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/forms.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>,
          <Link to={`/application/${props.application.id}/modules/forms`}>
            {t('common.forms')}
          </Link>,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/forms\/create.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          <Link to={`/application/${props.application.id}/modules/forms`}>{t('forms.forms')}</Link>,
          `${t('forms.create')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/forms\/[0-9]+\/edit.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          <Link to={`/application/${props.application.id}/modules/forms`}>{t('forms.forms')}</Link>,
          `${t('forms.edit')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/modules\/forms\/[0-9]+\/reports\/detailed.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          <Link to={`/application/${props.application.id}/modules/forms`}>{t('forms.forms')}</Link>,
          `${t('forms.detailedReport')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/employee.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.employee')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/import-employee.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('employee.importEmployees')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/hrops\/salary-advance.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('salaryAdvance.salaryAdvance')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/recruiter\/positions.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.positions')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/recruiter\/positions\/new.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          <Link to={`/application/${props.application.id}/recruiter/positions`}>
            {t('common.positions')}
          </Link>,
          `${t('positions.newPosition')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/recruiter\/positions\/edit\/[0-9].*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          <Link to={`/application/${props.application.id}/recruiter/positions`}>
            {t('common.positions')}
          </Link>,
          `${t('positions.editPosition')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/surveys*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('surveys.surveys')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/helpdesk\/tickets*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.helpdesk')}`,
          `${t('common.tickets')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/helpdesk\/groups*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.helpdesk')}`,
          `${t('helpdesk.groups')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/helpdesk\/report*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.helpdesk')}`,
          `${t('helpdesk.report')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/helpdesk\/instantAnswers*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.helpdesk')}`,
          `${t('helpdesk.instantAnswers')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/surveys\/create.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          <Link to={`/application/${props.application.id}/surveys`}>{t('surveys.surveys')}</Link>,
          `${t('surveys.createSurvey')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/surveys\/[0-9]+\/report.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          <Link to={`/application/${props.application.id}/surveys`}>{t('surveys.surveys')}</Link>,
          `${t('surveys.reports')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/surveys\/[0-9]+\/edit.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          <Link to={`/application/${props.application.id}/surveys`}>{t('surveys.surveys')}</Link>,
          `${t('surveys.editSurvey')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/surveys\/[0-9]+\/subscribers.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          <Link to={`/application/${props.application.id}/surveys`}>{t('surveys.surveys')}</Link>,
          `${t('surveys.subscribers')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/recruiter\/positions\/short-list\/[0-9].*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          <Link to={`/application/${props.application.id}/recruiter/positions`}>
            {t('common.positions')}
          </Link>,
          `${t('common.shortList')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/recruiter\/positions\/applications.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.positionApplications')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/translations\/languages.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.translations')}`,
          `${t('translations.languageSettings')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/translations\/content-translations.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('common.translations')}`,
          `${t('translations.contentTranslations')}`,
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/issue\/categories.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('issue.categories')}`
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/issue\/issues.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('issue.issues')}`
        ];
      },
    },
    {
      pattern: /\/application\/[0-9]+\/issue\/reports\/average-reply-time-by-dashboard-user.*/,
      view: () => {
        return [
          <Link to="/applications">{t('common.applications')}</Link>,
          props.application.id ? (
            <Link to={`/application/${props.application.id}`}>{props.application.name}</Link>
          ) : (
            <i>{t('common.unknownApplication')}</i>
          ),
          `${t('issueReports.averagedReplyTimeReportsByDashboardUser')}`
        ];
      },
    },
    // {
    //   pattern: /\/position-filter-questions.*/,
    //   view: () => {
    //     return [
    //       <Link to="/applications">{t('common.applications')}</Link>,
    //       `${t('common.positionFilterQuestions')}`,
    //     ];
    //   },
    // },
    {
      pattern: /\/account.*/,
      view: () => {
        return [`${t('common.account')}`];
      },
    },
    {
      pattern: /\/account\/change-password.*/,
      view: () => {
        return [<Link to="/account">{t('common.account')}</Link>, `${t('common.changePassword')}`];
      },
    },
    {
      pattern: /\/about.*/,
      view: () => {
        return [`${t('common.aboutDashboard')}`];
      },
    },
    {
      pattern: /#\/404.*/,
      view: () => {
        return [`${t('common.pageNotFound')}`];
      },
    },
  ];

  const resolveActiveBread = () => {
    let breads: string[] = [];

    breadMap.forEach(bread => {
      if (bread.pattern.test(window.location.hash)) {
        breads = bread.view();
      }
    });

    return breads;
  };

  return (
    <Breadcrumb>
      {resolveActiveBread().map((crumb: string) => {
        return <BreadcrumbItem key={generateId(10)}>{crumb}</BreadcrumbItem>;
      })}
    </Breadcrumb>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  list: state.list.data,
  conversations: state.conversations.data,
  currentUser: state.conversationHistoryCurrentUser.user,
  talentPool: state.talentPool.data,
  subscribingList: state.subscribingList.data,
  position: state.positions.data.position.data,
  customList: state.customLists.resource.data,
});

export default connect(mapStateToProps)(withTranslation()(withRouter(AppBreadcrumb)));
