import { Dispatch } from 'redux';

import { PageQueryParams, TicketFilters, TicketModel } from 'src/shared/models';

import {
  deleteTicket as deleteTicketService,
  getTickets as getTicketsService,
  createTicket as createTicketService,
  getTicket as getTicketService,
  updateTicket as updateTicketService,
} from 'src/redux/services/ticket.service';

export const GET_TICKETS = 'GET_TICKETS';
export const GET_TICKETS_SUCCESS = 'GET_TICKETS_SUCCESS';
export const GET_TICKETS_ERROR = 'GET_TICKETS_ERROR';

export const DELETE_TICKET = 'DELETE_TICKET';
export const DELETE_TICKET_REQUEST = 'DELETE_TICKET_REQUEST';
export const DELETE_TICKET_SUCCESS = 'DELETE_TICKET_SUCCESS';
export const DELETE_TICKET_ERROR = 'DELETE_TICKET_ERROR';

export const CREATE_TICKET = 'tickets/CREATE_TICKET';
export const CREATE_TICKET_SUCCESS = 'tickets/CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_ERROR = 'tickets/CREATE_TICKET_ERROR';
export const READ_TICKET = 'tickets/READ_TICKET';
export const READ_TICKET_SUCCESS = 'tickets/READ_TICKET_SUCCESS';
export const READ_TICKET_ERROR = 'tickets/READ_TICKET_ERROR';
export const UPDATE_TICKET = 'tickets/UPDATE_TICKET';
export const UPDATE_TICKET_SUCCESS = 'tickets/UPDATE_TICKET_SUCCESS';
export const UPDATE_TICKET_ERROR = 'tickets/UPDATE_TICKET_ERROR';
export const READ_TICKET_QUESTIONS = 'tickets/READ_TICKET_QUESTIONS';
export const REVERT_TICKET = 'tickets/CLEAR_TICKET';
export const REVERT_TICKET_QUESTIONS = 'tickets/REVERT_TICKET_QUESTIONS';
export const REVERT_TICKET_QUESTION_ANSWERS = 'tickets/REVERT_TICKET_QUESTIONS';

export const getTickets = (appId: string, queryParams: PageQueryParams, filter: TicketFilters) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TICKETS,
    });

    try {
      dispatch({
        type: GET_TICKETS_SUCCESS,
        payload: await getTicketsService(appId, queryParams, filter),
      });
    } catch (error) {
      dispatch({
        type: GET_TICKETS_ERROR,
        error,
      });
    }
  };
};

export const deleteTicket = (appId: string, ticketId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_TICKET,
    });

    try {
      await deleteTicketService(appId, ticketId.toString());
      dispatch({
        type: DELETE_TICKET_SUCCESS,
        payload: { ticketId },
      });
    } catch (error) {
      dispatch({
        type: DELETE_TICKET_ERROR,
        error,
      });
    }
  };
};

export const createTicket = (appId: string, ticketData: TicketModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_TICKET,
    });

    const response = await createTicketService(appId, ticketData);

    try {
      dispatch({
        type: CREATE_TICKET_SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: CREATE_TICKET_ERROR,
        error,
      });
    }
  };
};

export const getTicket = (appId: string, ticketId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: READ_TICKET,
    });

    try {
      dispatch({
        type: READ_TICKET_SUCCESS,
        payload: await getTicketService(appId, ticketId),
      });
    } catch (error) {
      dispatch({
        type: READ_TICKET_ERROR,
        error,
      });
    }
  };
};

export const updateTicket = (appId: string, ticketId: string, data: TicketModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_TICKET,
    });

    try {
      dispatch({
        type: UPDATE_TICKET_SUCCESS,
        payload: await updateTicketService(appId, ticketId, data),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_TICKET_ERROR,
        error,
      });
    }
  };
};

export const revertTicket = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REVERT_TICKET,
    });
  };
};
