import {
  CreateSurveyQuestionAnswerTranslationRequest,
  CreateSurveyQuestionTranslationRequest,
  CreateSurveyTranslationRequest,
  DeleteSurveyTranslationRequest,
  EditSurveyQuestionAnswerTranslationRequest,
  EditSurveyQuestionTranslationRequest,
  EditSurveyTranslationRequest,
  GetSurveyTranslationsResponse,
} from 'src/shared/models/content-translations-survey.model';
import { del, get, patch, post } from 'src/shared/services/http.service';

const baseUrl = (applicationId: string) => `/applications/${applicationId}/translation/surveys`;

//Survey methods
export const getSurveyTranslations = async (
  applicationId: string,
): Promise<GetSurveyTranslationsResponse> => {
  return get(baseUrl(applicationId));
};

export const createSurveyTranslation = async (
  applicationId: string,
  surveyId: number,
  request: CreateSurveyTranslationRequest,
) => {
  return post(`${baseUrl(applicationId)}/${surveyId}`, { data: request });
};

export const editSurveyTranslation = async (
  applicationId: string,
  surveyId: number,
  languageCode: string,
  request: EditSurveyTranslationRequest,
) => {
  return patch(`${baseUrl(applicationId)}/${surveyId}/${languageCode}`, { data: request });
};

export const deleteSurveyTranslation = async (
  applicationId: string,
  surveyId: number,
  languageCode: string,
  request: DeleteSurveyTranslationRequest,
) => {
  return del(`${baseUrl(applicationId)}/${surveyId}/${languageCode}`, { data: request });
};

//Survey question methods
export const createSurveyQuestionTranslation = async (
  applicationId: string,
  surveyId: number,
  surveyQuestionId: number,
  request: CreateSurveyQuestionTranslationRequest,
) => {
  return post(`${baseUrl(applicationId)}/${surveyId}/questions/${surveyQuestionId}`, {
    data: request,
  });
};

export const editSurveyQuestionTranslation = async (
  applicationId: string,
  surveyId: number,
  surveyQuestionId: number,
  languageCode: string,
  request: EditSurveyQuestionTranslationRequest,
) => {
  return patch(
    `${baseUrl(applicationId)}/${surveyId}/questions/${surveyQuestionId}/${languageCode}`,
    { data: request },
  );
};

export const deleteSurveyQuestionTranslation = async (
  applicationId: string,
  surveyId: number,
  surveyQuestionId: number,
  languageCode: string,
) => {
  return del(`${baseUrl(applicationId)}/${surveyId}/questions/${surveyQuestionId}/${languageCode}`);
};

//Survey question answer methods
export const createSurveyQuestionAnswerTranslation = async (
  applicationId: string,
  surveyId: number,
  surveyQuestionId: number,
  surveyQuestionAnswerId: number,
  request: CreateSurveyQuestionAnswerTranslationRequest,
) => {
  return post(
    `${baseUrl(
      applicationId,
    )}/${surveyId}/questions/${surveyQuestionId}/answers/${surveyQuestionAnswerId}`,
    {
      data: request,
    },
  );
};

export const editSurveyQuestionAnswerTranslation = async (
  applicationId: string,
  surveyId: number,
  surveyQuestionId: number,
  languageCode: string,
  surveyQuestionAnswerId: number,
  request: EditSurveyQuestionAnswerTranslationRequest,
) => {
  return patch(
    `${baseUrl(
      applicationId,
    )}/${surveyId}/questions/${surveyQuestionId}/answers/${surveyQuestionAnswerId}/${languageCode}`,
    { data: request },
  );
};

export const deleteSurveyQuestionAnswerTranslation = async (
  applicationId: string,
  surveyId: number,
  surveyQuestionId: number,
  surveyQuestionAnswerId: number,
  languageCode: string,
) => {
  return del(
    `${baseUrl(
      applicationId,
    )}/${surveyId}/questions/${surveyQuestionId}/answers/${surveyQuestionAnswerId}/${languageCode}`,
  );
};
