import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

import styles from '../TalentPools.module.scss';

const TalentPoolCardHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Row className={styles.cardHeader}>
      <Col className={styles.name}>
        <span>{t('common.name')}</span>
      </Col>

      <Col className={styles.candidatesCount}>
        <span>{t('common.candidates')}</span>
      </Col>

      <Col className={styles.date}>
        <span>{t('common.created')}</span>
      </Col>

      <Col>{/* Plészholder */}</Col>
    </Row>
  );
};

export default TalentPoolCardHeader;
