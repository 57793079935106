import React, { useEffect, useState, FC } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import moment from 'moment';

import { BatchMessageListModel } from 'src/shared/models/subscribing-lists.model';
import { getApplication, ApplicationModel } from 'src/redux/actions/applications.action';
import { getBatchMessages } from 'src/redux/actions/batch-messages.action';
import { BatchMessagesQueryParams } from 'src/shared/models/query-params.model';
import { List } from 'src/shared/components';

import styles from './BatchMessages.module.scss';
import BatchMessageFilter from './components/BatchMessageFilter';

interface IBatchMessagesProps {
  application: ApplicationModel;
  getApplication: Function;
  batchMessages: BatchMessageListModel[];
  getBatchMessages: Function;
  batchMessagesLoading: boolean;
  batchMessagesLoaded: boolean;
  batchMessagesError: boolean;
  batchMessagesCount: number;
}

const BatchMessages: FC<IBatchMessagesProps> = ({
  application,
  getApplication,
  batchMessages,
  getBatchMessages,
  batchMessagesLoading,
  batchMessagesLoaded,
  batchMessagesError,
  batchMessagesCount,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    getBatchMessages(appId, queryParams);
    /* eslint-disable-next-line */
  }, []);

  const [queryParams, setQueryParams] = useState<BatchMessagesQueryParams>({
    from: moment().utc().subtract(7, 'days').toISOString(),
    to: moment().utc().toISOString(),
    page: 0,
    size: 20,
  });

  const fetchMessages = (queryParams: BatchMessagesQueryParams) => {
    setQueryParams(queryParams);
    getBatchMessages(appId, queryParams);
  };

  return (
    <div className="animated fadeIn">
      <List
        useCardContent
        data={batchMessages}
        totalElements={batchMessagesCount}
        loaded={batchMessagesLoaded}
        loading={batchMessagesLoading}
        error={batchMessagesError}
        fetchData={(params: BatchMessagesQueryParams) => {
          if (params.page !== queryParams.page) {
            const newParams = { ...queryParams, ...{ page: params.page } };
            setQueryParams(newParams);
            getBatchMessages(appId, newParams);
          }
        }}
        title={t('common.batchMessages')}
        fields={[
          { key: 'subscribingListName', label: t('common.subscribingList') },
          { key: 'targetMessagingPlatform', label: t('common.platform') },
          { key: 'messageSubject', label: t('common.subject') },
          { key: 'createdAt', label: t('common.created'), dateTime: true },
          {
            key: 'sent',
            label: t('common.sent'),
            render: item => (
              <div className={`${styles.badge} ${styles.successBadge}`}>{item.sent}</div>
            ),
          },
          {
            key: 'waiting',
            label: t('common.waiting'),
            render: item => (
              <div className={`${styles.badge} ${styles.waitingBadge}`}>{item.waiting}</div>
            ),
          },
          {
            key: 'failed',
            label: t('common.failed'),
            render: item => (
              <div className={`${styles.badge} ${styles.failedBadge}`}>{item.failed}</div>
            ),
          },
        ]}
        noDataLabel={t('batchMessages.noBatchMessages')}
        filterContent={() => (
          <BatchMessageFilter
            filter={queryParams}
            filterChanged={(params: BatchMessagesQueryParams) => {
              params.page = 0;
              fetchMessages(params)
            }}
          />
        )}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  batchMessages: state.batchMessages.data.content,
  batchMessagesLoading: state.batchMessages.loading,
  batchMessagesLoaded: state.batchMessages.loaded,
  batchMessagesError: state.batchMessages.error,
  batchMessagesCount: state.batchMessages.data.totalElements,
});

const mapDispatchToProps = {
  getApplication,
  getBatchMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchMessages);
