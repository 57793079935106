import React from 'react';
import { useField, FieldAttributes } from 'formik';
import { Input, FormFeedback } from 'reactstrap';

interface Props {
  refAsProp?: any;
  onChanged: (value: string) => void;
}

const TextField: React.FC<FieldAttributes<any> | Props> = ({
  refAsProp,
  id,
  disabled,
  className,
  placeholder,
  onChanged,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const { name, value, onBlur, onChange } = field;
  const { error, touched } = meta;

  // const valid = touched && !error;
  const invalid = touched && !!error;
  const errorText = typeof error === 'string' ? error : undefined;

  return (
    <div className="d-flex flex-column flex-fill">
      <Input
        type="text"
        ref={refAsProp}
        id={id}
        name={name}
        value={(value as string) || ''}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={e => {
          onChange(e);

          if (onChanged) {
            onChanged(e.target.value);
          }
        }}
        // valid={valid}
        invalid={invalid}
        disabled={disabled}
        className={className}
        {...props}
      />
      {errorText && <FormFeedback>{errorText}</FormFeedback>}
    </div>
  );
};

export default TextField;
