import React, { Fragment } from 'react';
import { ConversationHistoryModel } from 'src/redux/actions/conversation-history.action';

import TextMessage from './components/Viber/TextMessage';
import GenericTemplate from './components/Viber/GenericTemplate';
import ImageMessage from './components/Viber/ImageMessage';
import ViberMessage from './components/Viber/ViberMessage';
import ViberCustomKeyboardTemplate from './components/Viber/ViberCustomKeyboardTemplate';
import ViberCarouselTemplate from './components/Viber/ViberCarouselTemplate/ViberCarouselTemplate';
import ViberGalleryTemplate from './components/Viber/ViberGalleryTemplate';

interface Props {
  message: ConversationHistoryModel;
}

const renderMessage = (message: ConversationHistoryModel) => {
  switch (message.messageType) {
    case 'Text':
      return <TextMessage message={message} />;
    case 'GenericTemplate':
      return <GenericTemplate message={message} />;
    case 'Image':
      return <ImageMessage message={message} />;
    case 'ViberTemplate':
      return <ViberMessage message={message} />;
    case 'ViberCustomKeyboardTemplate':
      return <ViberCustomKeyboardTemplate message={message} />;
    case 'ViberGalleryTemplate':
      return <ViberGalleryTemplate message={message} />;
    case 'ViberCarouselTemplate':
      return <ViberCarouselTemplate message={message} />;
    default:
      return null;
  }
};

const Viber: React.FC<Props> = (props: Props) => {
  const { message } = props;

  return <Fragment>{renderMessage(message)}</Fragment>;
};

export default Viber;
