import React, { Component, Fragment } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Form } from 'reactstrap';
import { ApplicationSettingModel } from '../../../redux/actions/application-settings.action';

import SettingInput from './SettingInput';

interface Props {
  applicationSettings: {
    loading: boolean;
    loaded: boolean;
    error: any;
    data: ApplicationSettingModel[];
  };
  getApplicationSettings: Function;
  updateApplicationSetting: Function;
  toggleDeleteConfirmModal: Function;
}

interface State {
  data: ApplicationSettingModel[];
}

class SettingsForm extends Component<Props & RouteComponentProps, State> {
  state: State = {
    data: [],
  };

  componentWillReceiveProps(nextProps: Props) {
    const { applicationSettings } = nextProps;

    if (applicationSettings.data && applicationSettings.data.length > 0) {
      this.setState({
        data: applicationSettings.data,
      });
    }
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    this.setState(prevState => ({
      data: prevState.data.map((setting: ApplicationSettingModel) =>
        setting.key === name ? Object.assign(setting, { value }) : setting,
      ),
    }));
  };

  // Mivel mindegyik input-hoz tartozik egy Save gomb, ezért a kettő közti azonosítást
  // úgy oldottam meg, hogy az aktuális gomb id-ja megegyezik a beállítás key értékével.
  // Így ki tudom keresni a state-ből a szükséges input adatait.
  handleSubmit = async (e: Event) => {
    const target = e.target as HTMLInputElement;
    const { id } = target;

    const selectedSetting = this.findSettingByKey(id);

    if (selectedSetting) {
      const { key, value } = selectedSetting;
      this.props.updateApplicationSetting(key, value);
    }
  };

  handleDelete = async (e: Event) => {
    const target = e.target as HTMLInputElement;
    const { id } = target;

    const selectedSetting = this.findSettingByKey(id);

    if (selectedSetting) {
      const { key } = selectedSetting;
      this.props.toggleDeleteConfirmModal(key);
    }
  };

  findSettingByKey(buttonId: string) {
    return this.state.data.find((setting: ApplicationSettingModel) => {
      return setting.key === buttonId;
    });
  }

  render() {
    const { applicationSettings } = this.props;

    return (
      <Fragment>
        {applicationSettings.loaded &&
        applicationSettings.data &&
        applicationSettings.data.length ? (
          <Form>
            {applicationSettings.data.map((setting: ApplicationSettingModel) => {
              return (
                <SettingInput
                  key={setting.key}
                  setting={setting}
                  handleChange={this.handleChange}
                  handleSubmit={this.handleSubmit}
                  handleDelete={this.handleDelete}
                />
              );
            })}
          </Form>
        ) : null}
      </Fragment>
    );
  }
}

export default withRouter(SettingsForm);
