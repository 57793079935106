import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import ChangePassword from '../ChangePassword';

class AccountSettings extends Component<RouteComponentProps | any> {
  renderComponent = (selectedOption: string) => {
    switch (selectedOption) {
      case 'change-password':
        return <ChangePassword />;
      default:
        return <ChangePassword />;
    }
  };

  render() {
    const { selectedOption } = this.props;

    return this.renderComponent(selectedOption);
  }
}

const mapStateToProps = (state: any) => ({
  selectedOption: state.accountSelectedOption.data,
});

export default connect(mapStateToProps, null)(withRouter(AccountSettings));
