import { ApplicantsQueryParams, GeneralQueryParams } from 'src/shared/models/query-params.model';
import { get } from '../../shared/services/http.service';

export const getPositionApplicants = async (
  applicationId: string,
  queryParams: ApplicantsQueryParams,
) => {
  return get(`/recruiter/applications/${applicationId}/position/applicants`, {
    params: queryParams,
  });
};

export const exportPositionApplicants = async (
  applicationId: string,
  params: GeneralQueryParams,
  exportType: string,
) => {
  return get(`/recruiter/applications/${applicationId}/position/applicants/export/${exportType}`, {
    params,
    requestConfig: {
      responseType: 'blob',
    },
  });
};
