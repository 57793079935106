import React, { Component, Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import 'react-dates/initialize';
import { DateRangePicker, isInclusivelyBeforeDay, isSameDay } from 'react-dates';
import moment from 'moment';
import classNames from 'classnames';

interface Props extends RouteComponentProps, WithTranslation {
  handleDateChange: Function;
  getFeedbacks: Function;
  getFeedbackSummary: Function;
  disabled: boolean;
}
interface State {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  focusedInput: any;
  dateHasChanged: boolean;
}

const INIT_FROM_VALUE = moment().utc().subtract(7, 'days');
const INIT_TO_VALUE = moment().utc();

class FeedbacksFilter extends Component<Props, State> {
  state: State = {
    startDate: INIT_FROM_VALUE,
    endDate: INIT_TO_VALUE,
    focusedInput: null,
    dateHasChanged: false,
  };

  handlePresetClick = (startDate: moment.Moment, endDate: moment.Moment) => {
    this.setState(
      {
        dateHasChanged: true,
      },
      () => {
        this.handleDateChange(startDate, endDate);
        this.handleFocusChange(null);
      },
    );
  };

  handleDateChange = async (startDate: moment.Moment, endDate: moment.Moment) => {
    if (startDate && endDate) {
      if (!this.state.dateHasChanged) return null;

      this.setState({
        startDate,
        endDate,
        dateHasChanged: false,
      });

      await this.props.handleDateChange(startDate, endDate);
      this.props.getFeedbacks();
      this.props.getFeedbackSummary();
    }

    return null;
  };

  // Dátumválasztó bezárása
  handleFocusChange = (focusedInput: string | null) => {
    this.setState({
      focusedInput,
    });
  };

  renderDatePresets = () => {
    const { t } = this.props;

    const presets = [
      {
        text: `${t('common.today')}`,
        start: moment(),
        end: moment(),
      },
      {
        text: `${t('common.lastWeek')}`,
        start: moment().subtract(7, 'days'),
        end: moment(),
      },
      {
        text: `${t('common.lastMonth')}`,
        start: moment().subtract(1, 'month'),
        end: moment(),
      },
      {
        text: `${t('common.lastThreeMonth')}`,
        start: moment().subtract(3, 'month'),
        end: moment(),
      },
    ];

    return (
      <div className="PresetDateRangePicker_panel">
        {presets.map(({ text, start, end }) => {
          const { startDate, endDate } = this.state;

          if (startDate && endDate) {
            const isSelected = isSameDay(start, startDate) && isSameDay(end, endDate);

            return (
              <button
                key={text}
                type="button"
                className={classNames('PresetDateRangePicker_button', {
                  PresetDateRangePicker_button__selected: isSelected,
                })}
                onClick={() => this.handlePresetClick(start, end)}
              >
                {text}
              </button>
            );
          }

          return null;
        })}
      </div>
    );
  };

  render() {
    const { disabled } = this.props;

    return (
      <Fragment>
        <DateRangePicker
          startDate={this.state.startDate}
          startDateId="startDate"
          endDate={this.state.endDate}
          endDateId="endDate"
          onDatesChange={({ startDate, endDate }) =>
            this.setState({ startDate, endDate, dateHasChanged: true })
          }
          onFocusChange={focusedInput => this.handleFocusChange(focusedInput)}
          onClose={({ startDate, endDate }) => this.handleDateChange(startDate, endDate)}
          focusedInput={this.state.focusedInput}
          isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
          renderCalendarInfo={() => this.renderDatePresets()}
          displayFormat="YYYY-MM-DD"
          firstDayOfWeek={1}
          numberOfMonths={1}
          minimumNights={0}
          disabled={disabled}
        />
      </Fragment>
    );
  }
}

export default withTranslation()(withRouter(FeedbacksFilter));
