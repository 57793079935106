import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import FileSaver from 'file-saver';

import { fileNames } from 'src/shared/settings';
import { exportUnrecognizedSentences } from 'src/redux/actions/unrecognized-sentences.action';
import { ExportButton } from 'src/shared/components';

interface Props extends RouteComponentProps<{ id: string }>, WithTranslation {
  exportUnrecognizedSentences: Function;
  exportedUnrecognizedSentences: any;
  disabled: boolean;
}

class Export extends Component<Props> {
  exportUnrecognizedSentences = async () => {
    const applicationId = this.props.match.params.id;

    await this.props.exportUnrecognizedSentences(applicationId);
    const { exportedUnrecognizedSentences } = this.props;

    if (exportedUnrecognizedSentences) {
      const csvData = new Blob([exportedUnrecognizedSentences], {
        type: 'text/csv;charset=utf-8',
      });
      FileSaver.saveAs(csvData, fileNames.UNRECOGNIZED_SENTENCES_EXPORT);
    }
  };

  render() {
    const { exportedUnrecognizedSentences, disabled } = this.props;

    return (
      <ExportButton
        className="ml-2"
        disabled={disabled || exportedUnrecognizedSentences.loading}
        onClick={() => this.exportUnrecognizedSentences()}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  exportedUnrecognizedSentences: state.exportUnrecognizedSentences.data,
});

const mapDispatchToProps = {
  exportUnrecognizedSentences,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Export)));
