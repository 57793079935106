import React, { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { NumberFieldFormik, TextField } from 'src/shared/components';

interface ImageAnswerProps {}

const ImageAnswer: FC<ImageAnswerProps> = memo(() => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col>
          <FormGroup>
            <Label for="allowedFileExtensions">{t('common.allowedFileExtensions')}</Label>
            <TextField name="allowedFileExtensions" />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="invalidFileExtensionMessage">
              {t('common.invalidFileExtensionMessage')}
            </Label>
            <TextField name="invalidFileExtensionMessage" />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="allowedMaxFileSizeInBytes">{t('common.allowedMaxFileSizeInBytes')}</Label>
            <NumberFieldFormik name="allowedMaxFileSizeInBytes" max={9999999999999} />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="invalidFileSizeMessage">{t('common.invalidFileSizeMessage')}</Label>
            <TextField name="invalidFileSizeMessage" />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
});

export default ImageAnswer;
