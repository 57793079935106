import { Dispatch } from 'redux';
import { getPlatforms as getPlatformsService } from '../services/platforms.service';

export const GET_PLATFORMS_REQUEST = 'platforms/GET_PLATFORMS_REQUEST';
export const GET_PLATFORMS_SUCCESS = 'platforms/GET_PLATFORMS_SUCCESS';
export const GET_PLATFORMS_ERROR = 'platforms/GET_PLATFORMS_ERROR';
export const CLEAR_PLATFORMS = 'platforms/CLEAR_PLATFORMS';

export const getPlatforms = (applicationId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_PLATFORMS_REQUEST,
    });

    try {
      const platforms: string[] = await getPlatformsService(applicationId);

      dispatch({
        type: GET_PLATFORMS_SUCCESS,
        payload: platforms,
      });
    } catch (error) {
      dispatch({
        type: GET_PLATFORMS_ERROR,
        error,
      });
    }
  };
};

export const clearPlatforms = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_PLATFORMS,
    });
  };
};
