import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Badge } from 'reactstrap';
import moment from 'moment';

import CheckPermission from '../../../shared/components/CheckPermission';
import { ListModel } from '../../../redux/reducers/lists.reducer';

interface Props {
  list: ListModel;
  index: number;
  showElement: Function;
  openEditModal: Function;
  deleteListConfirm: Function;
}

// eslint-disable-next-line react/prefer-stateless-function
export class FoldersRow extends Component<Props | any> {
  render() {
    const { list } = this.props;

    return (
      <tr key={list.name} onClick={() => this.props.showElement(list)}>
        <td className="name">{list.name}</td>
        <td className="date-created">{moment(list.createdAt).calendar()}</td>
        <td className="documents">
          <Badge color={list.size ? 'primary' : 'secondary'}>{list.size}</Badge>
        </td>
        <td className="gallery">{list.gallery && <i className="far fa-images" />}</td>
        <td className="operations">
          <div className="d-flex justify-content-end">
            <CheckPermission variant="enableIf" permissions={['simple-lists_update']}>
              <Button
                color="info"
                className="text-dark"
                onClick={(event: React.MouseEvent) => this.props.openEditModal(event, list)}
              >
                <i className="far fa-edit" />
              </Button>
            </CheckPermission>
            <CheckPermission variant="enableIf" permissions={['simple-lists_delete']}>
              <Button
                color="danger"
                className="text-dark ml-2"
                onClick={(event: React.MouseEvent) => this.props.deleteListConfirm(event, list)}
              >
                <i className="far fa-trash-alt" />
              </Button>
            </CheckPermission>
          </div>
        </td>
      </tr>
    );
  }
}

export default withRouter(FoldersRow);
