import React, { FC } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

interface FormCardProps {
  title: string;
}

const FormCard: FC<FormCardProps> = ({ title, children }) => {
  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <h6 className="mb-1 mt-1">{title}</h6>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>{children}</Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default FormCard;
