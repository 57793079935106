import React, { Fragment } from 'react';

import { ConversationHistoryModel } from '../../../../../../redux/actions/conversation-history.action';

import ButtonTemplate from './components/Facebook/ButtonTemplate';
import GenericTemplate from './components/Facebook/GenericTemplate';
import ImageMessage from './components/Facebook/ImageMessage';
import MediaMessage from './components/Facebook/MediaMessage';
import QuickReply from './components/Facebook/QuickReply';
import SendTextWithButtons from './components/Facebook/SendTextWithButtons';
import SendFile from './components/Facebook/SendFile';
import StickerMessage from './components/Facebook/StickerMessage';
import TextMessage from './components/Facebook/TextMessage';

import { MessageTypes } from 'src/shared/constants/conversation-history.constants';

interface Props {
  message: ConversationHistoryModel;
}

const renderMessage = (message: ConversationHistoryModel) => {
  switch (message.messageType) {
    case 'Text':
      return <TextMessage message={message} />;
    case 'ButtonTemplate':
      return <ButtonTemplate message={message} />;
    case 'GenericTemplate':
      return <GenericTemplate message={message} />;
    case 'Image':
      return <ImageMessage message={message} />;
    case 'Media':
      return <MediaMessage message={message} />;
    case 'QuickReply':
      return <QuickReply message={message} />;
    case 'SendTextWithButtons':
      return <SendTextWithButtons message={message} />;
    case 'File':
      return <SendFile message={message} />;
    case 'Sticker':
      return <StickerMessage message={message} />;
    case MessageTypes.QuickReplies:
      return <QuickReply message={message} />;
    default:
      return null;
  }
};

const Facebook: React.FC<Props> = (props: Props) => {
  const { message } = props;

  return <Fragment>{renderMessage(message)}</Fragment>;
};

export default Facebook;
