import React, { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Alert, Button, Row, Col, Card, CardBody, CardHeader, Table } from 'reactstrap';
import queryString from 'query-string';
import Pagination from 'react-js-pagination';
import isEqual from 'lodash/isEqual';

import {
  getLists,
  createList,
  updateList,
  deleteList,
  clearListErrors,
} from '../../redux/actions/lists.action';
import { getListElementsService } from '../../redux/services/lists.service';
import {
  getSignatureForUpload,
  // SignatureForUploadModel,
} from '../../redux/actions/upload.action';
import {
  getSignatureForDelete,
  // SignatureForDeleteModel,
} from '../../redux/actions/delete.action';
import { uploadFile as uploadFileService } from '../../shared/services/upload.service';
import { deleteFile as deleteFileService } from '../../shared/services/delete.service';
import {
  parseFileNameWithoutExtension,
  parseFileExtension,
  isImageExtension,
} from '../../shared/utils/parse.util';
import { UserModel } from '../../redux/reducers/user.reducer';
import { ListModel } from '../../redux/reducers/lists.reducer';
import { ListQueryParams, SQLDirection } from '../../shared/models/query-params.model';
import { StateModel } from '../../shared/models/default-state.model';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';

import CheckPermission from '../../shared/components/CheckPermission';
import ConfirmModal from '../../shared/components/modals/ConfirmModal';
import FolderAddModal from '../../shared/components/modals/FolderAddModal';
import { FolderEditModal } from '../../shared/components/modals/FolderEditModal';

import { FoldersRow } from './components/FoldersRow';
import './FoldersAndDocuments.scss';
import { ListElementModel } from 'src/redux/reducers/list-elements.reducer';

interface Props extends RouteComponentProps<{ appId: string }>, WithTranslation {
  getApplication: Function;
  application: ApplicationModel;
  user: UserModel;
  list: StateModel;
  lists: StateModel;
  getLists: Function;
  createList: Function;
  updateList: Function;
  deleteList: Function;
  signatureForUpload: StateModel;
  signatureForDelete: StateModel;
  getSignatureForUpload: Function;
  getSignatureForDelete: Function;
  clearListErrors: Function;
}

interface State {
  createModalOpen: boolean;
  editModalOpen: boolean;
  allowToSetGallery: boolean;
  confirmModalOpen: boolean;
  selectedList: ListModel | undefined;
  queryParams: ListQueryParams;
  activePage: number;
  dataHasChanged: boolean;
  uploadMethod: string;
  deleteMethod: string;
}

class Folders extends Component<Props, State> {
  state: State = {
    createModalOpen: false,
    editModalOpen: false,
    allowToSetGallery: false,
    confirmModalOpen: false,
    selectedList: undefined,
    queryParams: {
      direction: SQLDirection.DESC,
      page: 0,
      size: 20,
      sort: 'createdAt',
    },
    activePage: 1,
    dataHasChanged: false,
    uploadMethod: 'PUT',
    deleteMethod: 'DELETE',
  };

  async componentDidMount() {
    if (!this.props.application.id) {
      await this.props.getApplication(this.props.match.params.appId);
    }
    await this.setCurrentPage();
    this.getLists();
  }

  async setCurrentPage() {
    const queryValues = queryString.parse(this.props.location.search);

    if (queryValues.page) {
      await this.setState({
        queryParams: {
          ...this.state.queryParams,
          page: Number(queryValues.page),
        },
        activePage: Number(queryValues.page) + 1,
      });
    }
  }

  openCreateModal() {
    this.setState({
      createModalOpen: true,
    });
  }

  closeCreateModal() {
    this.setState({
      createModalOpen: false,
    });
    this.props.clearListErrors();
  }

  async openEditModal(event: React.MouseEvent, list: ListModel) {
    event.stopPropagation();

    let allowToSetGallery = true;
    const currentItems: ListElementModel[] = await getListElementsService(
      `${this.props.application.id}`,
      list.id || 0,
      { direction: SQLDirection.ASC, sort: 'name' },
    );
    currentItems.map(item => {
      if (!isImageExtension(parseFileExtension(`${item.fileUrl}`))) {
        allowToSetGallery = false;
      }
      return true;
    });

    this.setState({
      selectedList: list,
      editModalOpen: true,
      allowToSetGallery,
      dataHasChanged: false,
    });
  }

  closeEditModal() {
    this.setState({
      selectedList: undefined,
      editModalOpen: false,
    });
    this.props.clearListErrors();
  }

  async createList(list: ListModel, file?: File) {
    const { uploadMethod } = this.state;
    let imageUrl: string | null = '';

    if (file) {
      const ext = parseFileExtension(file.name);

      if (ext) {
        await this.props.getSignatureForUpload(ext, uploadMethod);
        await uploadFileService(file, this.props.signatureForUpload.data);
        imageUrl = this.props.signatureForUpload.data.downloadUrl;
      }
    }

    await this.props.createList(this.props.match.params.appId, {
      ...list,
      imageUrl,
    });

    // Ha a létrehozás során hiba keletkezik (pl. meglévő Folder név és/vagy id esetén)
    // és a felhasználó töltött fel képet, akkor az törlésre kerül.
    if (this.props.list.error && imageUrl) {
      this.deleteFile(imageUrl);
    }

    if (!this.props.list.error) {
      await this.setState({
        activePage: 1,
      });
      this.closeCreateModal();
      this.getLists();
      this.pageChange(this.state.activePage);
    }
  }

  async updateList(list: ListModel, file?: File, deletedFileUrl?: string): Promise<any> {
    const { uploadMethod } = this.state;

    if (!this.state.selectedList) {
      return Promise.reject();
    }

    // Annak ellenőrzése, hogy az adatokban történt-e változás.
    // Name vagy Description input-ok.
    if (!isEqual(list, this.state.selectedList)) {
      await this.setState({
        dataHasChanged: true,
      });
    }

    // Kép törlése/felülírása esetén.
    if (deletedFileUrl) {
      await this.deleteFile(deletedFileUrl);
      list.imageUrl = '';

      await this.setState({
        dataHasChanged: true,
      });
    }

    // Új kép feltöltése vagy meglévő cseréje esetén.
    if (file) {
      const ext = parseFileExtension(file.name);

      if (ext) {
        await this.props.getSignatureForUpload(ext, uploadMethod);
        await uploadFileService(file, this.props.signatureForUpload.data);

        list.imageUrl = this.props.signatureForUpload.data.downloadUrl;
      }

      await this.setState({
        dataHasChanged: true,
      });
    }

    // Ha történt változás, akkor a lista frissítése az új adatokkal.
    if (this.state.dataHasChanged) {
      await this.props.updateList(this.props.match.params.appId, list.id, list);
    }

    // Ha a frissítés során hiba keletkezik (pl. meglévő Folder név esetén)
    // és a felhasználó töltött fel képet, akkor az törlésre kerül.
    if (this.props.list.error && list.imageUrl) {
      this.deleteFile(list.imageUrl);
    }

    // Sikeresen frissítés esetén a listák lekérdezese majd a Modal bezárása.
    if (!this.props.list.error) {
      this.getLists();
      this.closeEditModal();
    }

    return Promise.resolve();
  }

  async deleteFile(fileUrl: string) {
    const { deleteMethod } = this.state;
    const ext = parseFileExtension(fileUrl);
    const fileNameWithoutExtension = parseFileNameWithoutExtension(fileUrl);

    if (ext) {
      await this.props.getSignatureForDelete(ext, fileNameWithoutExtension, deleteMethod);
      deleteFileService(this.props.signatureForDelete.data);
    }
  }

  deleteListConfirm(event: React.MouseEvent, list: ListModel) {
    event.stopPropagation();

    this.setState({
      confirmModalOpen: true,
      selectedList: list,
      queryParams: {
        ...this.state.queryParams,
        page: 0,
      },
    });
  }

  closeDeleteModal() {
    this.setState({
      confirmModalOpen: false,
      selectedList: undefined,
    });
  }

  async deleteList() {
    const { selectedList, queryParams, activePage } = this.state;
    const { lists, deleteList } = this.props;

    // Lista törlése
    if (selectedList) {
      await deleteList(this.props.match.params.appId, selectedList.id);
    }

    // Törlés után meglévő oldalak számának kiszámítása
    const totalPagesAfterDelete = Math.ceil(lists.data.totalElements / queryParams.size);

    // Ha egy oldal utolsó eleme kerül törlése, akkor az activePage értéke
    // a törlés után meglévő oldalak számával kell, hogy egyenlő legyen
    if (activePage > totalPagesAfterDelete) {
      await this.setState({
        activePage: totalPagesAfterDelete,
      });
    }

    // Listák lekérdezése, Modal bezárása, szükség esetén oldalváltás
    this.getLists();
    this.closeDeleteModal();
    this.pageChange(activePage);
  }

  showElement(list: ListModel) {
    this.props.history.push(
      `/application/${this.props.match.params.appId}/modules/documents/${list.id}`,
    );
  }

  async pageChange(index: number) {
    // Backend oldalon a page-elés 0-tól indul
    // Frontend oldaon a 'react-js-pagination' miatt 1-től kell indulnia
    await this.setState({
      queryParams: {
        ...this.state.queryParams,
        page: index - 1,
      },
      activePage: index,
    });

    this.props.history.push(
      `/application/${this.props.match.params.appId}/modules/documents?page=${this.state.queryParams.page}`,
    );
    this.getLists();

    window.scrollTo(0, 0);
  }

  async getLists() {
    await this.props.getLists(this.props.match.params.appId, this.state.queryParams);
  }

  render() {
    const {
      selectedList,
      confirmModalOpen,
      createModalOpen,
      editModalOpen,
      allowToSetGallery,
      activePage,
      queryParams,
    } = this.state;
    const { list, lists, t } = this.props;

    return (
      <div>
        <Row>
          <Col>
            <Card className="animated fadeIn">
              <CardHeader>
                <Row>
                  <Col className="d-flex justify-content-end">
                    <CheckPermission variant="displayIf" permissions={['simple-lists_create']}>
                      <Button
                        className="m-0"
                        color="success"
                        onClick={this.openCreateModal.bind(this)}
                      >
                        {t('foldersAndDocuments.newFolder')}
                      </Button>
                    </CheckPermission>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {lists.loaded && lists.data.content.length ? (
                  <Fragment>
                    <Table striped hover responsive className="folders-table">
                      <thead>
                        <tr>
                          <th className="name">{t('common.name')}</th>
                          <th className="date-created">{t('common.created')}</th>
                          <th className="documents">{t('common.documents')}</th>
                          <th className="gallery">{t('common.gallery')}</th>
                          <th className="operations">{t('common.operations')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.data.content &&
                          lists.data.content.map((list: ListModel, index: number) => {
                            return (
                              <FoldersRow
                                key={list.id}
                                list={list}
                                index={index}
                                showElement={this.showElement.bind(this)}
                                openEditModal={this.openEditModal.bind(this)}
                                deleteListConfirm={this.deleteListConfirm.bind(this)}
                              />
                            );
                          })}
                      </tbody>
                    </Table>

                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={queryParams.size}
                      totalItemsCount={lists.data.totalElements}
                      pageRangeDisplayed={10}
                      onChange={this.pageChange.bind(this)}
                    />
                  </Fragment>
                ) : null}

                {lists.loaded && !lists.data.content.length ? (
                  <Alert className="text-center m-0" color="info">
                    {t('common.noImportFilesFound')}
                  </Alert>
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <CheckPermission variant="displayIf" permissions={['simple-lists_create']}>
          <FolderAddModal
            cancel={this.closeCreateModal.bind(this)}
            confirm={this.createList.bind(this)}
            isOpen={createModalOpen}
            isLoading={list.loading}
            errors={list.error}
          />
        </CheckPermission>

        <CheckPermission variant="displayIf" permissions={['simple-lists_update']}>
          <FolderEditModal
            {...this.props}
            list={selectedList}
            cancel={this.closeEditModal.bind(this)}
            confirm={this.updateList.bind(this)}
            allowToSetGallery={allowToSetGallery}
            isOpen={editModalOpen}
            isLoading={list.loading}
            errors={list.error}
          />
        </CheckPermission>

        <CheckPermission variant="displayIf" permissions={['simple-lists_delete']}>
          <ConfirmModal
            title={t('foldersAndDocuments.deleteFolder')}
            text={t('foldersAndDocuments.folderDeleteConfirmMessage')}
            item={selectedList && selectedList.name}
            isOpen={confirmModalOpen}
            cancel={this.closeDeleteModal.bind(this)}
            confirm={this.deleteList.bind(this)}
            isLoading={lists.loading}
          />
        </CheckPermission>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  user: state.user.data,
  list: state.list,
  lists: state.lists,
  listsLoading: state.lists.loading,
  signatureForUpload: state.signatureForUpload,
  signatureForDelete: state.signatureForDelete,
});

const mapDispatchToProps = {
  getApplication,
  getLists,
  createList,
  updateList,
  deleteList,
  clearListErrors,
  getSignatureForUpload,
  getSignatureForDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Folders)));
