import { StateModel } from '../../shared/models/default-state.model';
import {
  GET_SALARY_ADVANCES_REQUEST,
  GET_SALARY_ADVANCES_SUCCESS,
  GET_SALARY_ADVANCES_ERROR,
  SALARY_ADVANCES_CLEAR_ERROR,
} from '../actions/salaryAdvances.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const salaryAdvanceReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_SALARY_ADVANCES_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
        error: null,
      };

    case GET_SALARY_ADVANCES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };

    case GET_SALARY_ADVANCES_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    case SALARY_ADVANCES_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default salaryAdvanceReducer;
