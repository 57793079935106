import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Formik, Form } from 'formik';

import { Spinner, TextField } from 'src/shared/components';
import { LanguageEditorModalModel } from 'src/shared/models/language-settings.model';
import { useParams } from 'react-router';
import { issueCategorySchema } from 'src/shared/schemas/validation.schema';
import { CreateIssueCategoryRequest } from 'src/shared/models/issue-categories.model';
import { createIssueCategory, getIssueCategories } from 'src/redux/actions/issue-categories.action';

export interface IssueCategoryEditorModalProps {
  isOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  createIssueCategory: (appId: string, data: CreateIssueCategoryRequest) => Promise<void>;
  getIssueCategories: (appId: string) => Promise<void>;
}

const IssueCategoryEditorModal: FC<IssueCategoryEditorModalProps> = ({
  isOpen,
  setIsModalOpen,
  createIssueCategory,
  getIssueCategories,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const handleSubmit = async (model: LanguageEditorModalModel) => {
    await createIssueCategory(appId, model);
    await getIssueCategories(appId);
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{t('issue.create')}</ModalHeader>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{ name: ''}}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmit(values as any);
          setSubmitting(false);
          setIsModalOpen(false);
        }}
        validationSchema={issueCategorySchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="name">{t('issue.name')}</Label>
                    <TextField name="name" />
                  </FormGroup>
                </Col>
              </Row>             
            </ModalBody>
            <ModalFooter>
              <Spinner loading={isSubmitting} />

              <Button type="submit" color="success" disabled={isSubmitting}>
                {t('common.save')}
              </Button>

              <Button color="secondary" onClick={() => setIsModalOpen(false)}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = {
    createIssueCategory,
    getIssueCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(IssueCategoryEditorModal);
