import React, { Fragment, FC, useState } from 'react';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { SurveyModel, SurveyStatuses } from 'src/shared/models';

import { updateSurvey } from 'src/redux/actions/surveys.action';

interface Props {
  survey: SurveyModel;
  updateSurvey: Function;
}

const SurveyStatusButton: FC<Props> = ({ survey, updateSurvey }) => {
  const { appId } = useParams<{ appId: string }>();
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const getStatus = (survey: SurveyModel): SurveyStatuses => {
    const startTime = moment(survey.startsAt).unix();
    const endTime = moment(survey.endsAt).unix();
    const now = moment().unix();

    if (now >= startTime && (survey.endsAt === null || now <= endTime)) {
      return SurveyStatuses.running;
    }
    if (now < startTime) {
      return SurveyStatuses.scheduled;
    }
    if (survey.endsAt === null || now > endTime) {
      return SurveyStatuses.expired;
    }

    return SurveyStatuses.none;
  };

  const getStatusColor = (survey: SurveyModel) => {
    const status = getStatus(survey);

    switch (status) {
      case SurveyStatuses.running:
        return 'success';
      case SurveyStatuses.scheduled:
        return 'primary';
      case SurveyStatuses.expired:
        return 'warning text-white';
      default:
        return '';
    }
  };

  const handleSurveyStop = async () => {
    survey.endsAt = moment().subtract(10, 'second').toISOString();
    await updateSurvey(appId, survey.id.toString(), survey);
  };

  return (
    <Fragment>
      {getStatus(survey) === SurveyStatuses.running && (
        <Fragment>
          <ButtonDropdown isOpen={isDropdownOpen} toggle={() => setIsDropdownOpen(!isDropdownOpen)}>
            <DropdownToggle caret color={getStatusColor(survey)}>
              {t(`surveys.statuses.${SurveyStatuses[getStatus(survey)]}`)}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleSurveyStop()}>
                <i className="far fa-stop-circle"></i> {t('surveys.stopSurvey')}
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </Fragment>
      )}
      {getStatus(survey) !== SurveyStatuses.running && (
        <Button color={getStatusColor(survey)}>
          {t(`surveys.statuses.${SurveyStatuses[getStatus(survey)]}`)}
        </Button>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {
  updateSurvey,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyStatusButton);
