import React, { useEffect, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import {
  deleteSurvey,
  getSurveys,
  removeSurveyTag as removeTag,
  addTagToSurvey as addTag,
} from 'src/redux/actions/surveys.action';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { getTagCategories } from 'src/redux/actions/employee.action';
import { List, EmployeeTags } from 'src/shared/components';
import SurveyStatusButton from './components/SurveyStatusButton';
import { PageQueryParams, QueryParams, SurveyModel, Operations } from 'src/shared/models';
import { Button } from 'reactstrap';
import { ITaggable } from '../Events/Events';

interface ISurveysProps extends ITaggable {
  deleteSurvey: (appId: string, id: number) => void;
  getSurveys: (appId: string, queryParams: PageQueryParams) => void;
  surveys: SurveyModel[];
  numberOfSurveys: number;
  surveysLoading: boolean;
  surveysLoaded: boolean;
  surveysError: boolean;
  getApplication: Function;
  application: ApplicationModel;
}

const Surveys: FC<ISurveysProps> = ({
  deleteSurvey,
  getSurveys,
  surveys,
  numberOfSurveys,
  surveysLoading,
  surveysLoaded,
  surveysError,
  getApplication,
  application,
  removeTag,
  removeTagState,
  getTagCategories,
  tagCategories,
  addTag,
  addTagToState,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const history = useHistory();
  const [queryParamsDef, setQueryParamsDef] = useState<QueryParams>({
    page: 0,
    size: 20,
    limit: 20,
  });

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    const { loaded, error } = removeTagState;
    if (loaded && !error) {
      getSurveys(appId, queryParamsDef);
    }
    // eslint-disable-next-line
  }, [removeTagState]);

  useEffect(() => {
    const { loaded, error } = addTagToState;
    if (loaded && !error) {
      getSurveys(appId, queryParamsDef);
    }
    // eslint-disable-next-line
  }, [addTagToState]);

  const removeSurvey = async (survey?: SurveyModel) => {
    if (survey) {
      await deleteSurvey(appId, survey.id);
    }
  };

  const fetchSurveys = (queryParams: QueryParams) => {
    setQueryParamsDef(queryParams);
    getSurveys(appId, queryParams);
  };

  const redirectToSubscribers = (survey?: SurveyModel) => {
    history.push(`/application/${appId}/surveys/${survey?.id}/subscribers`);
  };

  return (
    <div className="animated fadeIn">
      <List
        useCardContent
        data={surveys}
        totalElements={numberOfSurveys}
        loaded={surveysLoaded}
        loading={surveysLoading}
        error={surveysError}
        fetchData={fetchSurveys}
        title={t('surveys.surveys')}
        createRedirectPath="create"
        fields={[
          { key: 'name', label: t('common.name') },
          {
            key: 'mandatory',
            label: t('common.mandatory'),
            render: ({ mandatory }) => (mandatory ? t('common.yes') : t('common.no')),
          },
          { key: 'startsAt', label: t('common.startsAt'), date: true },
          { key: 'endsAt', label: t('common.endsAt'), date: true },
          {
            key: 'status',
            label: t('common.status'),
            render: survey => <SurveyStatusButton survey={survey} />,
          },
          {
            key: 'numberOfSurveySubscriptions',
            label: t('common.subscriptions'),
            render: survey => (
              <Button
                className="btn-sm"
                onClick={() => {
                  redirectToSubscribers(survey);
                }}
              >
                <i className="fas fa-users" /> {survey.numberOfSurveySubscriptions}
              </Button>
            ),
          },
          { key: 'numberOfCompleted', label: t('common.completed') },
          {
            key: 'employeeTags',
            label: t('surveys.tags'),
            render: (survey, index) => (
              <EmployeeTags
                employeeTags={survey.employeeTags || []}
                removeTag={(tagId: string) => removeTag(appId, survey.id, tagId)}
                removePermission="employee_employee-tag-assign_delete"
                createPermission="employee_employee-tag-assign_create"
                index={index}
                taggableModel={survey}
                getTagCategories={getTagCategories}
                tagCategories={tagCategories}
                addTagToEntity={addTag}
              />
            ),
          },
          {
            key: 'operations',
            label: t('common.operations'),
            operations: [Operations.REPORT, Operations.UPDATE, Operations.DELETE],
          },
        ]}
        noDataLabel={t('surveys.noSurveys')}
        deleteFunction={removeSurvey}
        deleteTitle={t('surveys.deleteSurvey')}
        deleteText={t('surveys.deleteSurveyConfirmMessage')}
        updatePermission="survey_survey_update"
        deletePermission="survey_survey_delete"
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    surveys: state.surveys.data.content,
    numberOfSurveys: state.surveys.data.totalElements,
    surveysLoading: state.surveys.loading,
    surveysLoaded: state.surveys.loaded,
    surveysError: state.surveys.error,
    application: state.application.data,
    removeTagState: state.removeSurveyTag,
    tagCategories: state.tagCategories,
    addTagToState: state.addTagToSurvey,
  };
};

const mapDispatchToProps = {
  deleteSurvey,
  getSurveys,
  getApplication,
  removeTag,
  getTagCategories,
  addTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(Surveys);
