import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody, Alert } from 'reactstrap';
import Pagination from 'react-js-pagination';

import { useDeepEffect } from 'src/shared/hooks';

import { getSelectOptionObjects } from 'src/shared/utils/misc.util';

import { StateModel } from 'src/shared/models/default-state.model';
import { GeneralQueryParams } from 'src/shared/models/query-params.model';
import { CustomListItemModel, CustomListModel } from 'src/shared/models/custom-lists.model';

import { getCustomList } from 'src/redux/actions/custom-lists.action';
import {
  getCustomListItems,
  deleteCustomListItem,
} from 'src/redux/actions/custom-list-items.action';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';

import CustomListItemsHeader from './components/CustomListItemsHeader';
import CustomListItemsTable from './components/CustomListItemsTable';
import CustomListItemModal from './components/CustomListItemModal';
import ConfirmModal from 'src/shared/components/modals/ConfirmModal';
import ServerError from 'src/shared/components/ServerError';
import Spinner from 'src/shared/components/Spinner';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  customList: CustomListModel;
  getCustomList: Function;
  customListItems: StateModel;
  getCustomListItems: Function;
  deleteEvent: StateModel;
  deleteCustomListItem: Function;
}

const CustomListItems: React.FC<Props> = ({
  application,
  getApplication,
  customList,
  getCustomList,
  customListItems,
  getCustomListItems,
  deleteEvent,
  deleteCustomListItem,
}) => {
  const { t } = useTranslation();
  const { appId, customListId } = useParams();

  const [queryParams, setQueryParams] = useState<GeneralQueryParams>({ page: 0, size: 50 });
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [modalEvent, setModalEvent] = useState<string>('');
  const [selectedCustomListItem, setSelectedCustomListItem] = useState<
    CustomListItemModel | undefined
  >();

  const parentSelectOptions = getSelectOptionObjects(
    customListItems.data?.content || [],
    'name',
    'id',
  );

  useDeepEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }

    getCustomList(appId, customListId);
    getCustomListItems(appId, customListId, queryParams);
  }, [queryParams]);

  const handleDelete = async () => {
    if (selectedCustomListItem) {
      await deleteCustomListItem(appId, customListId, selectedCustomListItem.id);
      handleToggle();
    }
  };

  const handlePageChange = (index: number) => {
    setQueryParams(prevState => ({
      ...prevState,
      page: index - 1,
    }));
  };

  const handleToggle = () => {
    if (isModalOpen) setModalOpen(false);
    if (isConfirmModalOpen) setConfirmModalOpen(false);
    setSelectedCustomListItem(undefined);
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col>
          <CustomListItemsHeader
            customList={customList}
            setModalEvent={setModalEvent}
            setModalOpen={setModalOpen}
          />

          <Card>
            <CardBody>
              {customListItems.loading && (
                <div className="d-flex justify-content-center">
                  <Spinner size="2x" loading={customListItems.loading} />
                </div>
              )}

              {customListItems.loaded && !customListItems.data?.content?.length && (
                <Alert color="info" className="text-center mb-0">
                  {t('customListItems.noCustomListItemsFound')}
                </Alert>
              )}

              {customListItems.error && <ServerError />}

              {customListItems.loaded && customListItems.data?.content?.length > 0 && (
                <Fragment>
                  <CustomListItemsTable
                    customListItems={customListItems.data.content}
                    setModalOpen={setModalOpen}
                    setConfirmModalOpen={setConfirmModalOpen}
                    setModalEvent={setModalEvent}
                    setSelectedCustomListItem={setSelectedCustomListItem}
                  />

                  <Pagination
                    activePage={queryParams.page + 1}
                    itemsCountPerPage={queryParams.size}
                    totalItemsCount={customListItems.data.totalElements || 1}
                    onChange={handlePageChange}
                  />
                </Fragment>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <CustomListItemModal
        isOpen={isModalOpen}
        toggle={() => handleToggle()}
        event={modalEvent}
        customListItem={selectedCustomListItem}
        setSelectedCustomListItem={setSelectedCustomListItem}
        selectOptions={parentSelectOptions}
      />

      <ConfirmModal
        title={t('customListItems.deleteCustomListItem')}
        text={t('customListItems.deleteCustomListItemConfirm')}
        item={selectedCustomListItem?.name}
        confirm={handleDelete}
        cancel={() => handleToggle()}
        isOpen={isConfirmModalOpen}
        isLoading={deleteEvent.loading}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  customList: state.customLists.resource.data,
  customListItems: state.customListItems.resources,
  deleteEvent: state.customListItems.delete,
});

const mapDispatchToProps = {
  getApplication,
  getCustomList,
  getCustomListItems,
  deleteCustomListItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomListItems);
