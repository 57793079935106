export const isValidUrl = (fileUrl: string) => {
  if (!fileUrl) {
    return false;
  }
  const urlRegex = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/gi;
  const regex = new RegExp(urlRegex);

  return !!fileUrl.match(regex);
};

export const isValidGoogleSheetUrl = (fileUrl: string) => {
  const urlRegex = /((?:.*))(docs.google.com\/spreadsheets\/)((?:.*))/gi;
  const regex = new RegExp(urlRegex);

  return !!fileUrl.match(regex);
};
