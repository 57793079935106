import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import '../../scss/_custom.scss';

interface Props {
  pageCount: number;
  currentPage: number;
  change: Function;
}

class Paginator extends Component<Props> {
  change(index: number) {
    this.props.change(index);
  }

  prev() {
    this.change(this.props.currentPage - 1);
  }

  next() {
    this.change(this.props.currentPage + 1);
  }

  first() {
    this.change(0);
  }

  last() {
    this.change(this.props.pageCount - 1);
  }

  renderPages() {
    const pages: any[] = [];
    const first = Math.max(0, this.props.currentPage - 2);
    const last = Math.min(this.props.currentPage + 2, this.props.pageCount - 1);

    for (let i = first; i <= last; i += 1) {
      pages.push(
        <PaginationItem key={i} active={this.props.currentPage === i}>
          <PaginationLink className="pagination-link page" onClick={() => this.change(i)}>
            {i + 1}
          </PaginationLink>
        </PaginationItem>,
      );
    }

    return pages;
  }

  render() {
    const pages: any[] = this.renderPages();

    return (
      <Fragment>
        {pages.length > 1 ? (
          <Pagination>
            <PaginationItem>
              <PaginationLink className="pagination-link first" first onClick={() => this.first()}>
                First
              </PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={this.props.currentPage <= 0}>
              <PaginationLink
                className="pagination-link arrow"
                onClick={() => this.prev()}
                previous
              />
            </PaginationItem>
            {pages}
            <PaginationItem disabled={this.props.currentPage >= this.props.pageCount - 1}>
              <PaginationLink className="pagination-link arrow" onClick={() => this.next()} next />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink className="pagination-link last" last onClick={() => this.last()}>
                Last
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  permissions: state.permissions.data,
});

export default connect(mapStateToProps)(Paginator);
