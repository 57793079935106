import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Button } from 'reactstrap';
import moment from 'moment';

import { UnrecognizedSentencesModel } from '../../../redux/actions/unrecognized-sentences.action';
import { CheckPermission } from 'src/shared/components';
import { useHistory } from 'react-router';

interface Props {
  unrecognizedSentences: UnrecognizedSentencesModel[];
  appId: number;
}

const Table: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const redirectToHistory = (unrecognizedSentence?: UnrecognizedSentencesModel) => {
    history.push(
      `/application/${props.appId}/conversations/${unrecognizedSentence?.userId}?sentence=${unrecognizedSentence?.sentence}&created=${unrecognizedSentence?.createdAt}`,
    );
  };

  return (
    <div className="table-responsive">
      <table className="table unrecognized-sentences-table table-hover table-striped">
        <thead>
          <tr>
            <th className="date">{t('common.date')}</th>
            <th className="sentence">{t('common.sentence')}</th>
            <th className="entities">{t('common.entity')}</th>
            <th className="last-menu-item">{t('unrecognizedSentences.lastMenuItem')}</th>
            <th className="user-suggested">{t('unrecognizedSentences.userSuggested')}</th>
            <CheckPermission variant="displayIf" permissions={['conversations_read']}>
              <th className="conversation-link">{t('unrecognizedSentences.history')}</th>
            </CheckPermission>
          </tr>
        </thead>
        <tbody>
          {props.unrecognizedSentences &&
            props.unrecognizedSentences.map((unrecognizedSentence: UnrecognizedSentencesModel) => {
              return (
                <tr key={unrecognizedSentence.id}>
                  <td>
                    <div className="text-muted">
                      {moment(unrecognizedSentence.createdAt).format('LLL')}
                    </div>
                    {/* <span className="font-weight-bold">
                        {moment
                          .duration(moment(unrecognizedSentence.createdAt).diff(moment()))
                          .humanize(true)}
                      </span> */}
                  </td>
                  <td>{unrecognizedSentence.sentence}</td>
                  <td>
                    {unrecognizedSentence.entityInstances
                      .toString()
                      .replace('[', '')
                      .replace(']', '')
                      .split(',')
                      .map((entity: string) => {
                        return (
                          <Badge className="entity-badge" color="secondary" key={entity}>
                            {entity}
                          </Badge>
                        );
                      })}
                  </td>
                  <td>{unrecognizedSentence.lastMenuItem}</td>
                  <td className="user-suggested">
                    {unrecognizedSentence.status === 'USER_SUGGESTED' ? (
                      <i className="far fa-check-circle fa-lg" />
                    ) : null}
                  </td>
                  <CheckPermission variant="displayIf" permissions={['conversations_read']}>
                    <td className="conversation-link">
                      {unrecognizedSentence.userId && (
                        <Button
                          color="primary"
                          onClick={() => redirectToHistory(unrecognizedSentence)}
                        >
                          <i className="fas fa-comments" /> {t('unrecognizedSentences.history')}
                        </Button>
                      )}
                    </td>
                  </CheckPermission>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
