import React from 'react';
import { Button } from 'reactstrap';

import { AttributeValidatorModel } from 'src/shared/models/questionnaire.model';
import CheckPermission from 'src/shared/components/CheckPermission';
import EditableTextInput from 'src/shared/components/form-inputs/EditableTextInput';

interface Props {
  attributeValidators: AttributeValidatorModel[];
  selectAttributeValidator: (attributeValidator: AttributeValidatorModel) => void;
  toggleConfirmModal: (isOpen: boolean) => void;
  updateAttributeValidator: Function;
}

const RegexPatternsTableBody: React.FC<Props> = ({
  attributeValidators,
  selectAttributeValidator,
  toggleConfirmModal,
  updateAttributeValidator,
}) => {
  return (
    <tbody>
      {attributeValidators.map(attributeValidator => (
        <tr key={attributeValidator.id}>
          <td className="name-column">
            <EditableTextInput
              id={attributeValidator.id!}
              data={attributeValidator.title}
              updateData={(appId: string, id: number, value: string) =>
                updateAttributeValidator(id, { title: value, pattern: attributeValidator.pattern })
              }
              permission="regular-expression_update"
            />
          </td>
          <td className="name-column">
            <EditableTextInput
              id={attributeValidator.id!}
              data={attributeValidator.pattern}
              updateData={(appId: string, id: number, value: string) =>
                updateAttributeValidator(id, { title: attributeValidator.title, pattern: value })
              }
              permission="regular-expression_update"
            />
          </td>

          <td className="operations-column">
            <CheckPermission variant="enableIf" permissions={['regular-expression_delete']}>
              <Button
                size="sm"
                color="danger"
                className="ml-2"
                onClick={() => {
                  selectAttributeValidator(attributeValidator);
                  toggleConfirmModal(true);
                }}
              >
                <i className="fas fa-trash-alt" />
              </Button>
            </CheckPermission>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default RegexPatternsTableBody;
