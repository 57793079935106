import React, { Fragment } from 'react';
import moment from 'moment';
import i18n from 'src/i18n';
import { ListModel } from 'src/redux/reducers/lists.reducer';

interface Props {
  folder: ListModel;
}

const DeletedFolder: React.FC<Props> = ({ folder }) => {
  return (
    <Fragment>
      <p className="font-weight-bold">
        {i18n.t('auditLog.detailsOfDeletedFolder')}
      </p>

      <table className="table table-sm">
        <tbody>
          <tr>
            <td>
              {i18n.t('common.name')} / {i18n.t('common.slug')}
            </td>
            <td>
              {folder.name} / {folder.slug}
            </td>
          </tr>
          <tr>
            <td>{i18n.t('common.created')}</td>
            <td>{moment(folder.createdAt).format('LLL')}</td>
          </tr>
          <tr>
            <td>{i18n.t('common.updated')}</td>
            <td>
              {folder.updatedAt && moment(folder.updatedAt).format('LLL')}
            </td>
          </tr>
          <tr>
            <td>{i18n.t('common.description')}</td>
            <td>{folder.description}</td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

export default DeletedFolder;
