import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Row, Col, Card, CardHeader, CardBody, Alert } from 'reactstrap';
import queryString from 'query-string';
import moment from 'moment';

import { TopicStatisticModel } from 'src/shared/models/topic-statistics.model';
import {
  GeneralQueryParams,
  IntentStatisticsQueryParams,
} from 'src/shared/models/query-params.model';

import { getApplication, ApplicationModel } from 'src/redux/actions/applications.action';
import {
  getTopicStatistics,
  exportTopicStatistics,
} from 'src/redux/actions/topic-statistics.action';

import { filterValidQueryParams, formatDatesToIsoString } from 'src/shared/utils/query-parse.util';
import saveFile from 'src/shared/utils/file-saver.util';

import { fileNames } from 'src/shared/settings';

import DateRangeFilter from 'src/shared/components/DateRangeFilter';
import TopicStatisticsExport from './components/TopicStatisticsExport';
import TopicStatisticsTable from './components/TopicStatisticsTable';
import { Spinner, PageHeader } from 'src/shared/components';
import DynamicGroupBy from '../../shared/components/dynamic-group-by/DynamicGroupBy';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';
import { configurationConstants } from 'src/shared/constants/configuration.constants';

interface Props extends RouteComponentProps<{ id: string }>, WithTranslation {
  getApplication: Function;
  application: ApplicationModel;
  getTopicStatistics: Function;
  exportTopicStatistics: Function;
  stats: TopicStatisticModel[];
  numberOfStats: number;
  statsLoading: boolean;
  statsLoaded: boolean;
  exportedData: string;
  exporting: boolean;
  exported: boolean;
}

interface State {
  queryParams: IntentStatisticsQueryParams;
  activePage: number;
  filters: DynamicGroupByModel;
}

export class TopicStatistics extends Component<Props, State> {
  state: State = {
    queryParams: {
      filterByValue: '',
      from: moment().utc().subtract(1, 'month'),
      to: moment().utc(),
      page: 0,
      size: 20,
    },
    activePage: 1,
    filters: {},
  };

  componentDidMount = async () => {
    if (!this.props.application.id) {
      await this.props.getApplication(this.props.match.params.id);
    }

    const queryParams = {
      ...this.state.queryParams,
      from: moment(this.props.application.createdAt).utc(),
      to: moment.utc(),
    };

    // Az URL-ben megadott paraméterek érvényességének ellenerőzése.
    // Ennek oka, hogy csak a IntentStatisticsQueryParams-ban meghatározott paraméterekkel dolgozzunk.
    const providedQueryParams = queryString.parse(this.props.location.search);
    const validQueryParams: any = filterValidQueryParams(providedQueryParams, queryParams);

    this.setState({
      queryParams: validQueryParams,
    });

    // Moment objektumok formázása string-gé.
    const formattedParams = formatDatesToIsoString(validQueryParams);
    // Ha volt az URL-ben page megadva, akkor annak a beállítása.
    // Ha nem volt, akkor az alapértelmezett első oldal kerül beállításra.
    this.setCurrentPage(formattedParams.page);
    // Átirányítás a formázott paraméterekkel.
    // this.redirectWithFormattedQueryParams(formattedParams);
    // Adatok lekérdezése a formázott paraméterekkel.
    this.getTopicStatistics(formattedParams);
  };

  getTopicStatistics = (queryParams: GeneralQueryParams) => {
    const { id: appId } = this.props.match.params;

    this.props.getTopicStatistics(appId, queryParams, this.state.filters);
  };

  exportTopicStatistics = async () => {
    const { id } = this.props.match.params;
    const { queryParams } = this.state;

    const { from, to } = formatDatesToIsoString(queryParams);

    await this.props.exportTopicStatistics(id, { from, to }, this.state.filters);
    const { exportedData } = this.props;

    if (exportedData) {
      saveFile(exportedData, fileNames.TOPIC_STATISTICS_EXPORT, 'csv');
    }
  };

  // redirectWithFormattedQueryParams = (params: GeneralQueryParams) => {
  //   const stringifiedParams = queryString.stringify(params, { sort: false });
  //   this.props.history.push(`?${stringifiedParams}`);
  // };

  handleDateChange = async (startDate: moment.Moment, endDate: moment.Moment) => {
    if (startDate && endDate) {
      this.setState(
        prevState => ({
          queryParams: {
            ...prevState.queryParams,
            from: startDate,
            to: endDate,
            page: 0,
          },
          activePage: 1,
        }),
        () => {
          const { queryParams } = this.state;
          const formattedParams = formatDatesToIsoString(queryParams);
          // this.redirectWithFormattedQueryParams(formattedParams);
          this.getTopicStatistics(formattedParams);
        },
      );
    }

    return null;
  };

  handleDynamicGroupByChange = async (filter: DynamicGroupByModel) => {
    this.setState(
      prevState => ({
        ...prevState,
        filters: filter,
      }),
      () => {
        const { queryParams } = this.state;
        const formattedParams = formatDatesToIsoString(queryParams);
        this.getTopicStatistics(formattedParams);
        window.scrollTo(0, 0);
      },
    );
  };

  pageChange = (index: number) => {
    // Backend oldalon a page-elés 0-tól indul
    // Frontend oldaon a 'react-js-pagination' miatt 1-től kell indulnia
    this.setState(
      prevState => ({
        queryParams: {
          ...prevState.queryParams,
          page: index - 1,
        },
        activePage: index,
      }),
      () => {
        const { queryParams } = this.state;
        const formattedParams = formatDatesToIsoString(queryParams);
        // this.redirectWithFormattedQueryParams(formattedParams);
        this.getTopicStatistics(formattedParams);
        window.scrollTo(0, 0);
      },
    );
  };

  setCurrentPage = (page: number) => {
    this.setState({
      activePage: Number(page) + 1,
    });
  };

  render() {
    const { queryParams, activePage } = this.state;
    const { stats, numberOfStats, statsLoading, statsLoaded, exporting, exported, t } = this.props;

    const noData = statsLoaded && !stats.length;

    return (
      <div className="animated fadeIn">
        <PageHeader title={t('common.topicStatistics')} />

        <Row>
          <Col>
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col>
                    <DateRangeFilter
                      startDate={moment(queryParams.from)}
                      endDate={moment(queryParams.to)}
                      handleDateChange={this.handleDateChange}
                    />
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <DynamicGroupBy
                      appId={this.props.match.params.id}
                      isMulti
                      handleDynamicGroupByChange={this.handleDynamicGroupByChange}
                      configurationNameSpace={configurationConstants.analyticsTopicStatisticsFilter}
                    />
                  </Col>
                  <Col className="d-flex justify-content-end" lg="1">
                    <TopicStatisticsExport
                      exportTopicStatistics={this.exportTopicStatistics}
                      exporting={exporting}
                      exported={exported}
                      disabled={exporting || statsLoading || noData}
                    />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {statsLoaded && stats.length > 0 && (
                  <TopicStatisticsTable
                    topicStatistics={stats}
                    activePage={activePage}
                    size={queryParams.size}
                    totalElements={numberOfStats}
                    pageChange={this.pageChange}
                  />
                )}

                {statsLoading && (
                  <div className="d-flex justify-content-center align-items-center">
                    <Spinner loading={statsLoading} size="2x" />
                  </div>
                )}

                {noData && (
                  <Alert color="info" className="text-center m-0">
                    {t('topicStatistics.noTopicStatistics')}
                  </Alert>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  stats: state.topicStatistics.data.content,
  numberOfStats: state.topicStatistics.data.totalElements,
  statsLoading: state.topicStatistics.loading,
  statsLoaded: state.topicStatistics.loaded,
  exportedData: state.exportTopicStatistics.data,
  exporting: state.exportTopicStatistics.loading,
  exported: state.exportTopicStatistics.loaded,
});

const mapDispatchToProps = {
  getApplication,
  getTopicStatistics,
  exportTopicStatistics,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(TopicStatistics)));
