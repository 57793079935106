import React, { Component, Fragment } from 'react';
import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';
import TextMessage from './TextMessage';

import styles from './scss/LiveChat.module.scss';

interface Props {
  message: ConversationHistoryModel;
}

interface State {
  text: string;
  outputTemplate: any;
}

class ButtonTemplate extends Component<Props> {
  state: State = {
    text: '',
    outputTemplate: null,
  };

  componentDidMount() {
    // Replacing \n characters with space
    // JSON.parse() would fail otherwise
    const cleanedMessage = this.props.message.messageAsText.replace(/\n/g, ' ');
    this.renderTemplate(cleanedMessage);
  }

  renderTemplate = (message: any) => {
    const outputButtons = [];
    const outputTemplate = [];
    let currentTemplate = null;
    let text = '';
    let buttons = [];

    try {
      currentTemplate = JSON.parse(message);
      /* eslint-disable prefer-destructuring */
      text = currentTemplate.text;
      buttons = currentTemplate.buttons;

      for (let i = 0; i < buttons.length; i += 1) {
        outputButtons.push(
          <button key={i} type="button" disabled>
            {buttons[i]}
          </button>,
        );
      }

      outputTemplate.push(outputButtons);
    } catch (error) {
      text = message;
    }

    this.setState({ text, outputTemplate });
  };

  render() {
    const { message } = this.props;
    const { text, outputTemplate } = this.state;

    return (
      <Fragment>
        <TextMessage message={message} buttonTemplateText={text} />
        <div className={styles.buttonTemplate}>{outputTemplate}</div>
      </Fragment>
    );
  }
}

export default ButtonTemplate;
