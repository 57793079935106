import React, { useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';
import Pagination from 'react-js-pagination';

import { useDeepEffect } from 'src/shared/hooks';

import { ViewMode, VIEW_MODE_KEY } from 'src/shared/constants/training-data.constants';
import { fileNames } from 'src/shared/settings';

import saveFile from 'src/shared/utils/file-saver.util';

import { StateModel } from 'src/shared/models/default-state.model';
import { GeneralQueryParams } from 'src/shared/models/query-params.model';

import { getApplication, ApplicationModel } from 'src/redux/actions/applications.action';
import {
  getTrainingData,
  exportTrainingData,
  setTrainingDataViewMode,
} from 'src/redux/actions/training-data.action';

import TrainingDataHeader from './components/TrainingDataHeader';
import TrainingDataCards from './components/TrainingDataCards';
import TrainingDataCreateModal from './components/TrainingDataCreateModal';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  trainingData: StateModel;
  getTrainingData: Function;
  totalElements: number;
  exportEvent: StateModel;
  exportTrainingData: Function;
  viewMode: ViewMode;
  setTrainingDataViewMode: Function;
}

const TrainingData: React.FC<Props> = ({
  application,
  getApplication,
  trainingData,
  getTrainingData,
  totalElements,
  exportEvent,
  exportTrainingData,
  viewMode,
  setTrainingDataViewMode,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const [queryParams, setQueryParams] = useState<GeneralQueryParams>({ page: 0, size: 50 });
  const [isCreateModalOpen, setCreateModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }

    setStoredViewMode();
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    const { loaded, error, data } = exportEvent;

    if (loaded && !error && data.length > 0) {
      saveFile(exportEvent.data, fileNames.TRAINING_DATA_EXPORT, 'csv');

      exportEvent.loaded = false;
      exportEvent.data = null;
    }
    /* eslint-disable-next-line */
  }, [exportEvent.data]);

  useDeepEffect(() => {
    getTrainingData(appId, queryParams);
  }, [queryParams]);

  const handleExport = async () => {
    await exportTrainingData(appId);
  };

  const setStoredViewMode = () => {
    const storedViewMode = localStorage.getItem(VIEW_MODE_KEY);

    if (storedViewMode && viewMode !== storedViewMode) {
      setTrainingDataViewMode(storedViewMode);
    }
  };

  const handlePageChange = (selectedPage: number) => {
    setQueryParams(prevState => ({ ...prevState, page: selectedPage - 1 }));
  };

  return (
    <Fragment>
      <div className="animated fadeIn">
        <TrainingDataHeader
          setCreateModalOpen={setCreateModalOpen}
          viewMode={viewMode}
          setTrainingDataViewMode={setTrainingDataViewMode}
          handleExport={handleExport}
          isButtonDisabled={!trainingData?.data?.content?.length || exportEvent.loading}
        />

        {trainingData.loaded && !trainingData?.data?.content?.length && (
          <Alert color="info" className="text-center">
            {t('trainingData.noTrainingDataFound')}
          </Alert>
        )}

        {trainingData.loaded && trainingData?.data.content.length > 0 && (
          <Fragment>
            <TrainingDataCards trainingData={trainingData?.data?.content} />
            <Pagination
              totalItemsCount={totalElements}
              itemsCountPerPage={queryParams.size}
              activePage={queryParams.page + 1}
              onChange={handlePageChange}
            />
          </Fragment>
        )}
      </div>

      <TrainingDataCreateModal
        isOpen={isCreateModalOpen}
        toggleModal={() => setCreateModalOpen(false)}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  trainingData: state.trainingData.resources,
  totalElements: state.trainingData.resources.data.totalElements,
  exportEvent: state.trainingData.export,
  viewMode: state.trainingData.viewMode,
});

const mapDispatchToProps = {
  getApplication,
  getTrainingData,
  exportTrainingData,
  setTrainingDataViewMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainingData);
