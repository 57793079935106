import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label } from 'reactstrap';

import { TextField } from 'src/shared/components';

interface OptionsAnswerProps {}

const OptionsAnswer: FC<OptionsAnswerProps> = () => {
  const { t } = useTranslation();
  return (
    <>
      <Col lg="6">
        <FormGroup>
          <Label for="text">{t('common.text')}</Label>
          <TextField name="answer.text" />
        </FormGroup>
      </Col>
      <Col lg="6">
        <FormGroup>
          <Label for="value">{t('common.value')}</Label>
          <TextField name="answer.value" />
        </FormGroup>
      </Col>
    </>
  );
};

export default OptionsAnswer;
