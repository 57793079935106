import React from 'react';
import classNames from 'classnames';

import { ApplicationModel } from 'src/redux/actions/applications.action';
import { TagCategoryModel } from 'src/shared/models';

import styles from './Tags.module.scss';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  index: number;
  isActive: boolean;
  tagCategory: TagCategoryModel;
  selectTagCategory: React.Dispatch<React.SetStateAction<TagCategoryModel | undefined>>;
  toggleModal: Function;
  toggleConfirmModal: () => void;
  handleOnClick: Function;
}

const TagCategoryCard: React.FC<Props> = ({
  application,
  getApplication,
  index,
  isActive,
  tagCategory,
  selectTagCategory,
  toggleModal,
  toggleConfirmModal,
  handleOnClick,
}) => {
  const cardClasses = classNames([styles.tagCategoryCard], { [styles.activeCard]: isActive });

  return (
    <div
      className={cardClasses}
      role="button"
      tabIndex={index}
      onClick={() => handleOnClick(tagCategory)}
    >
      <span className={styles.categoryName}>{tagCategory.name}</span>
      <div>
        <span className={styles.operations}>
          <button
            type="button"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              selectTagCategory(tagCategory);
              toggleModal('edit');
            }}
          >
            <i className="far fa-edit" />
          </button>

          <button
            type="button"
            onClick={(e: any) => {
              e.stopPropagation();
              selectTagCategory(tagCategory);
              toggleConfirmModal();
            }}
          >
            <i className="far fa-trash-alt" />
          </button>
        </span>
        <span className={styles.tagsCount}>{tagCategory.tags ? tagCategory.tags.length : 0}</span>
      </div>
    </div>
  );
};

export default TagCategoryCard;
