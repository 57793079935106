import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

import { PageHeader } from 'src/shared/components';

interface Props {
  setModalOpen: (isOpen: boolean) => void;
  setModalEvent: (event: string) => void;
}

const InterviewsHeader: React.FC<Props> = ({ setModalOpen, setModalEvent }) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      title={t('common.interviews')}
      rightComponent={
        <Button
          color="primary"
          onClick={() => {
            setModalOpen(true);
            setModalEvent('create');
          }}
        >
          {t('common.create')}
        </Button>
      }
    />
  );
};

export default InterviewsHeader;
