import React, { Component, Fragment } from 'react';
import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';

import placeholder from 'src/assets/img/other/image_placeholder.jpg';

import styles from './scss/Viber.module.scss';

interface Props {
  message: ConversationHistoryModel;
}

interface State {
  outputTemplate: any;
}

class GenericTemplate extends Component<Props> {
  state: State = {
    outputTemplate: [],
  };

  componentDidMount() {
    this.renderTemplate(this.props.message);
  }

  renderTemplate(message: any) {
    const outputTemplate: any = [];
    let currentTemplate = null;
    let genericElements = null;

    try {
      // eslint-disable-next-line
      currentTemplate = JSON.parse(message.messageAsText.replace(/\n\s*\n/g, ''));
      // eslint-disable-next-line
      genericElements = currentTemplate.viberGenericElements || currentTemplate.genericElements;
    } catch (error) {
      this.setState({
        outputTemplate: [],
      });
    }

    if (currentTemplate) {
      genericElements.map((el: any) => {
        return outputTemplate.push(
          <div key={el.title} className={styles.genericTemplate}>
            {el.imageUrl !== 'null' && (
              <img
                src={el.imageUrl || placeholder}
                className={styles.templateImage}
                alt="Generic Template Cardd"
              />
            )}

            <div className={styles.templateTitle}>
              <p>{el.title}</p>
            </div>

            {el.subTitle && (
              <div className={styles.templateSubTitle}>
                <p>{el.subTitle}</p>
              </div>
            )}

            {el.buttonTitle && (
              <a href={el.fileUrl || '#'} target="_blank" rel="noopener noreferrer">
                <button type="button" className={styles.templateButton}>
                  {el.buttonTitle}
                </button>
              </a>
            )}

            {!el.buttonTitle &&
              el.buttons.map((button: any) => {
                return (
                  <a key={button.title} href={button.url} target="_blank" rel="noopener noreferrer">
                    <button
                      type="button"
                      className={styles.templateButton}
                      disabled={button.url === 'null'}
                    >
                      {button.title}
                    </button>
                  </a>
                );
              })}
          </div>,
        );
      });

      this.setState({
        outputTemplate,
      });
    }
  }

  render() {
    return <Fragment>{this.state.outputTemplate}</Fragment>;
  }
}

export default GenericTemplate;
