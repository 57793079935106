import React, { Fragment, useState } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { positionSidebarTypes } from '../../../shared/constants/positions.constants';
import {
  PositionQuestionCreateAndEditSidebar,
  PositionShortcutsSidebar,
} from './components/sidebars';
import SidebarHeader from './components/sidebars/components/SidebarHeader';
import PositionCriteriasSidebar from './components/sidebars/PositionCriteriasSidebar';
import PositionUrls from '../components/PositionUrls';
import PositionUrlsHeader from './components/sidebars/components/PositionUrlsHeader';

const sidebarToRender = {
  [positionSidebarTypes.SHORTCUTS]: <PositionShortcutsSidebar />,
  [positionSidebarTypes.CREATE_PREFILTER_CRITERIA]: <PositionQuestionCreateAndEditSidebar />,
  [positionSidebarTypes.EDIT_PREFILTER_CRITERIA]: <PositionQuestionCreateAndEditSidebar />,
  [positionSidebarTypes.CRITERIA_CATEGORIES]: <PositionCriteriasSidebar />,
};

interface Props {
  sidebarType: string;
  positionSlug: string | undefined;
  platforms: string[];
  facebookPageId: string;
}

const PositionSidebarContainer: React.FC<Props> = ({
  sidebarType,
  positionSlug,
  platforms,
  facebookPageId,
}) => {
  const { t } = useTranslation();
  const [positionUrlsVisible, setPositionUrlsVisible] = useState<boolean>(true);

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <SidebarHeader />
        </CardHeader>
        <CardBody>{sidebarToRender[sidebarType]}</CardBody>
      </Card>

      <Card>
        <CardHeader>
          <PositionUrlsHeader
            positionUrlsVisible={positionUrlsVisible}
            setPositionUrlsVisible={setPositionUrlsVisible}
          />
        </CardHeader>

        <CardBody style={{ display: positionUrlsVisible ? 'block' : 'none' }}>
          {positionSlug && platforms.length > 0 ? (
            <PositionUrls
              positionSlug={positionSlug}
              platforms={platforms}
              facebookPageId={facebookPageId}
            />
          ) : (
            <p className="text-center text-muted m-0">{t('positions.noPlatformUrlsFound')}</p>
          )}
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default PositionSidebarContainer;
