import { StateModel, SurveyQuestionsModel } from 'src/shared/models';
import {
  READ_SURVEY_QUESTIONS,
  READ_SURVEY_QUESTIONS_SUCCESS,
  READ_SURVEY_QUESTIONS_ERROR,
  REVERT_SURVEY_QUESTIONS,
} from '../actions/surveys.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [] as SurveyQuestionsModel[],
};

export const surveyQuestionsReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case READ_SURVEY_QUESTIONS:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case READ_SURVEY_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload,
      };
    case READ_SURVEY_QUESTIONS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };
    case REVERT_SURVEY_QUESTIONS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
