import { Dispatch } from 'redux';
import { getSignatureForUploadService } from '../../shared/services/upload.service';

export const GET_SIGNATURE_REQUEST = 'upload/GET_SIGNATURE_REQUEST';
export const GET_SIGNATURE_SUCCESS = 'upload/GET_SIGNATURE_SUCCESS';
export const GET_SIGNATURE_ERROR = 'upload/GET_SIGNATURE_ERROR';

export interface SignatureForUploadModel {
  storageType: string;
  signedUrl: string;
  downloadUrl: string;
}

export const getSignatureForUpload = (extension: string, method: string = 'PUT') => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_SIGNATURE_REQUEST,
    });

    const signature = await getSignatureForUploadService(extension, method);

    try {
      dispatch({
        type: GET_SIGNATURE_SUCCESS,
        payload: signature,
      });

      return signature;
    } catch (err) {
      dispatch({
        type: GET_SIGNATURE_ERROR,
        err,
      });
    }
  };
};
