import React, { useEffect, FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Button } from 'reactstrap';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { DynamicConvFlowModel, PageQueryParams, QueryParams } from 'src/shared/models';
import { List } from 'src/shared/components';

import { getDynamicConvFlows } from 'src/redux/actions/dynamic-conv-flow.action';
import DynamicConvFlowModalForm from './forms/DynamicConvFlowModalForm';
import { getSignedUrlAndOpenFile } from 'src/shared/services/upload.service';
import { ImportTypes } from 'src/redux/services/dynamic-conv-flow.service';

interface Props {
  type: ImportTypes;
  getDynamicConvFlows: (appId: string, queryParams: PageQueryParams, type: ImportTypes) => void;
  getApplication: Function;
  application: ApplicationModel;
  dynamicConvFlows: DynamicConvFlowModel[];
  numberOfDynamicConvFlows: number;
  dynamicConvFlowsLoading: boolean;
  dynamicConvFlowsLoaded: boolean;
  dynamicConvFlowsError: boolean;
}

const DynamicConvFlow: FC<Props> = ({
  type,
  getDynamicConvFlows,
  getApplication,
  application,
  dynamicConvFlows,
  numberOfDynamicConvFlows,
  dynamicConvFlowsLoading,
  dynamicConvFlowsLoaded,
  dynamicConvFlowsError,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [queryParamsDef, setQueryParamsDef] = useState<QueryParams>({
    page: 0,
    size: 20,
    limit: 20,
  });

  const typeSpecifics = useMemo(() => {
    if (type === ImportTypes.IMPORT_CONVFLOW_VARIABLES) {
      return {
        title: t('convFlow.convFlow'),
        noDataFound: t('convFlow.noConvFlows'),
        modalHeader: t('convFlow.createConvFlows'),
      };
    } else {
      return {
        title: t('dynamicConvFlow.dynamicConvFlow'),
        noDataFound: t('dynamicConvFlow.noDynamicConvFlows'),
        modalHeader: t('dynamicConvFlow.createDynamicConvFlows'),
      };
    }
    /* eslint-disable-next-line */
  }, [type, t]);

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  const fetchDynamicConvFlows = (queryParams: QueryParams) => {
    setQueryParamsDef(queryParams);
    getDynamicConvFlows(appId, queryParams, type);
  };

  const refreshDynamicConvFlows = () => {
    getDynamicConvFlows(appId, queryParamsDef, type);
  };

  return (
    <div className="animated fadeIn">
      <List
        useCardContent
        data={dynamicConvFlows}
        totalElements={numberOfDynamicConvFlows}
        loaded={dynamicConvFlowsLoaded}
        loading={dynamicConvFlowsLoading}
        error={dynamicConvFlowsError}
        fetchData={fetchDynamicConvFlows}
        title={typeSpecifics?.title}
        createPermission="import_create"
        createModal={(isModalOpen, setIsModalOpen) => (
          <DynamicConvFlowModalForm
            type={type}
            isOpen={isModalOpen}
            toggleModalState={setIsModalOpen}
            onSaved={refreshDynamicConvFlows}
            header={typeSpecifics?.modalHeader}
          />
        )}
        fields={[
          { key: 'id', label: t('common.id') },
          { key: 'url', label: t('common.url'), render: item => item.uploadedDocument.url },
          { key: 'updatedAt', label: t('common.updated'), dateTime: true },
          {
            key: 'title',
            label: t('importConfiguration.configuration'),
            render: item => item.configuration.title,
          },
          { key: 'status', label: t('common.status') },
          {
            key: 'download',
            label: t('common.download'),
            render: item => (
              <>
                <Button
                  className="btn-sm float-right"
                  onClick={() => {
                    getSignedUrlAndOpenFile(item.uploadedDocument.url);
                  }}
                >
                  <i className="file-icon far fa-file-alt" />
                </Button>
              </>
            ),
          },
        ]}
        noDataLabel={typeSpecifics?.noDataFound}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    dynamicConvFlows: state.dynamicConvFlow.data.content,
    numberOfDynamicConvFlows: state.dynamicConvFlow.data.totalElements,
    dynamicConvFlowsLoading: state.dynamicConvFlow.loading,
    dynamicConvFlowsLoaded: state.dynamicConvFlow.loaded,
    dynamicConvFlowsError: state.dynamicConvFlow.error,
  };
};

const mapDispatchToProps = {
  getApplication,
  getDynamicConvFlows,
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicConvFlow);
