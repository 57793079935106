import React, { Fragment, useMemo } from 'react';
import { Card, CardHeader, CardBody, Button, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { Formik, Form, FormikValues } from 'formik';

import {
  SelectModel,
  SurveyQuestionAnswerOptionModel,
  SurveyQuestionModel,
  Operations,
  SurveyAnswerTypes,
} from 'src/shared/models';
import { EditableTextInput, List, ReactSelect, TextField } from 'src/shared/components';
import { usePreviousProps } from 'src/shared/hooks';
import {
  ANSWER_VALUE_MAX_LIMIT,
  ANSWER_VALUE_MIN_LIMIT,
} from 'src/shared/constants/surveys.constants';
import {
  createSurveyQuestionAnswer,
  deleteSurveyQuestionAnswer,
  sortSurveyQuestionAnswers,
  updateSurveyQuestionAnswer,
} from 'src/redux/services/survey.service';
import { getSurveyQuestionAnswers } from 'src/redux/actions/surveys.action';
import EditableSelectInput from 'src/shared/components/form-inputs/EditableSelectInput';
import { surveyQuestionAnswerCreateSchema } from 'src/shared/schemas/validation.schema';

interface Props {
  getSurveyQuestionAnswers: (appId: string, surveyId: string, surveyQuestionId: string) => void;
  getSurveyQuestions: (appId: string, surveyId: string) => void;
  editSurveyQuestionItem: SurveyQuestionModel;
  setEditSurveyQuestionItem: Function;
  surveyQuestionAnswers: SurveyQuestionAnswerOptionModel[];
  numberOfQuestionAnswers: number;
  surveyQuestionAnswersLoaded: boolean;
  surveyQuestionAnswersLoading: boolean;
  surveyQuestionAnswersError: boolean;
  isEditingDisabled: boolean;
}

const SurveysEditorQuestionEditSidebar: React.FC<Props> = ({
  getSurveyQuestionAnswers,
  getSurveyQuestions,
  editSurveyQuestionItem,
  setEditSurveyQuestionItem,
  surveyQuestionAnswers,
  numberOfQuestionAnswers,
  surveyQuestionAnswersLoaded,
  surveyQuestionAnswersLoading,
  surveyQuestionAnswersError,
  isEditingDisabled,
}) => {
  const { appId, surveyId } = useParams<{ appId: string; surveyId: string }>();
  const { t } = useTranslation();
  const prevProps = usePreviousProps({ editSurveyQuestionItem });
  const answerTypeOptions: SelectModel[] = Object.keys(SurveyAnswerTypes).map(answerTypeKey => ({
    label: t(`surveys.${answerTypeKey}`),
    value: answerTypeKey,
  }));
  const updateList =
    !!editSurveyQuestionItem?.id &&
    !!prevProps.editSurveyQuestionItem &&
    editSurveyQuestionItem?.id !== prevProps.editSurveyQuestionItem?.id;

  const getInitAnswerTpe = () => {
    if (!surveyQuestionAnswers || !surveyQuestionAnswers.length) {
      return {
        value: '',
        label: '',
      };
    }

    return {
      value: surveyQuestionAnswers[0].type,
      label: t(`surveys.${surveyQuestionAnswers[0].type}`),
    };
  };

  const getInitValues = () => {
    return {
      type: getInitAnswerTpe(),
      text: '',
      value: { value: 0 },
    };
  };

  const getValueOptions = () => {
    const options: SelectModel[] = [];

    for (let i = ANSWER_VALUE_MIN_LIMIT; i <= ANSWER_VALUE_MAX_LIMIT; i += 1) {
      options.push({ value: i, label: i.toString() });
    }

    return options;
  };
  const valueOptions = useMemo(() => getValueOptions(), []);

  const fetchQuestionAnswers = () => {
    if (!surveyId) {
      return;
    }
    getSurveyQuestionAnswers(appId, surveyId.toString(), editSurveyQuestionItem.id.toString());
  };

  const removeQuestionAnswer = async (answer?: SurveyQuestionAnswerOptionModel) => {
    if (answer && surveyId) {
      await deleteSurveyQuestionAnswer(
        appId,
        surveyId.toString(),
        editSurveyQuestionItem.id.toString(),
        answer.id.toString(),
      );
      fetchQuestionAnswers();
      getSurveyQuestions(appId, surveyId.toString());
    }
  };

  const updateAnswerText = async (surveyQuestionAnswer: SurveyQuestionAnswerOptionModel) => {
    if (!surveyId || !surveyQuestionAnswer.id) {
      return;
    }
    await updateSurveyQuestionAnswer(
      appId,
      surveyId.toString(),
      editSurveyQuestionItem.id.toString(),
      surveyQuestionAnswer.id.toString(),
      surveyQuestionAnswer,
    );
    fetchQuestionAnswers();
    getSurveyQuestions(appId, surveyId.toString());
  };

  const onQuestionAnswerDragEnd = async (orderedItems: Array<any>) => {
    if (!surveyId) {
      return;
    }
    await sortSurveyQuestionAnswers(
      appId,
      surveyId.toString(),
      editSurveyQuestionItem.id.toString(),
      orderedItems.map(e => e.id),
    );
    fetchQuestionAnswers();
    getSurveyQuestions(appId, surveyId.toString());
  };

  const isAddAnswerDisabled = () => {
    return !!(
      surveyQuestionAnswers &&
      surveyQuestionAnswers.find(
        answer => answer.type === SurveyAnswerTypes.SURVEY_QUESTION_ANSWER_TYPE_TEXT,
      )
    );
  };

  const isSelectAnswerTypeDisabled = () => {
    return !!(surveyQuestionAnswers && surveyQuestionAnswers.length);
  };

  const onSubmit = async (values: FormikValues, setSubmitting: Function, resetForm: Function) => {
    await createSurveyQuestionAnswer(appId, surveyId, editSurveyQuestionItem.id.toString(), {
      text:
        values.type.value === SurveyAnswerTypes.SURVEY_QUESTION_ANSWER_TYPE_TEXT
          ? t(`surveys.SURVEY_QUESTION_ANSWER_TYPE_TEXT`)
          : values.text,
      type: values.type.value,
      value:
        values.type.value === SurveyAnswerTypes.SURVEY_QUESTION_ANSWER_TYPE_TEXT
          ? null
          : values.value.value,
      orderNumber: editSurveyQuestionItem.surveyQuestionAnswers.length,
    });

    setSubmitting(false);
    fetchQuestionAnswers();
    getSurveyQuestions(appId, surveyId.toString());
    resetForm();
  };

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={getInitValues()}
        validationSchema={surveyQuestionAnswerCreateSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          onSubmit(values, setSubmitting, resetForm);
        }}
      >
        {({ isSubmitting, values }) => (
          <Card className="animated fadeIn">
            <CardHeader>
              {t('surveys.answersForQuestion')} {editSurveyQuestionItem.text}
              <Button
                color="ghost-secondary"
                className="float-right"
                onClick={() => {
                  setEditSurveyQuestionItem(false);
                }}
              >
                <i className="fas fa-times" />
              </Button>
            </CardHeader>
            <CardHeader>
              <Form>
                <FormGroup>
                  <Label for="type">{t('common.answerType')}</Label>
                  <ReactSelect
                    name="type"
                    options={answerTypeOptions}
                    isDisabled={isSelectAnswerTypeDisabled()}
                  />
                </FormGroup>
              </Form>
            </CardHeader>
            <CardBody>
              <Form>
                <FormGroup>
                  <Label for="name">{t('common.text')}</Label>
                  {values.type.value !== SurveyAnswerTypes.SURVEY_QUESTION_ANSWER_TYPE_TEXT && (
                    <TextField name="text" />
                  )}
                  {values.type.value === SurveyAnswerTypes.SURVEY_QUESTION_ANSWER_TYPE_TEXT && (
                    <TextField
                      name="text"
                      value={t(`surveys.SURVEY_QUESTION_ANSWER_TYPE_TEXT`)}
                      disabled
                    />
                  )}
                </FormGroup>

                {values.type.value !== SurveyAnswerTypes.SURVEY_QUESTION_ANSWER_TYPE_TEXT && (
                  <FormGroup>
                    <Label for="name">{t('common.value')}</Label>
                    <ReactSelect name="value" options={valueOptions} />
                  </FormGroup>
                )}

                <div className="d-flex justify-content-end mt-4">
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting || isAddAnswerDisabled() || isEditingDisabled}
                  >
                    {t('common.add')}
                  </Button>
                </div>
              </Form>

              <br />

              <List
                data={surveyQuestionAnswers}
                hidePager
                updateList={updateList}
                totalElements={numberOfQuestionAnswers}
                loaded={surveyQuestionAnswersLoaded}
                loading={surveyQuestionAnswersLoading}
                error={surveyQuestionAnswersError}
                fetchData={fetchQuestionAnswers}
                noDataLabel={t('surveys.noQuestions')}
                deleteFunction={removeQuestionAnswer}
                deleteTitle={t('surveys.deleteQuestion')}
                deleteText={t('surveys.deleteSurveyQuestionConfirmMessage')}
                onDragEnd={isEditingDisabled ? undefined : onQuestionAnswerDragEnd}
                updatePermission="survey_survey_update"
                deletePermission="survey_survey_delete"
                fields={[
                  {
                    key: 'text',
                    label: t('common.text'),
                    render: surveyQuestionAnswer =>
                      isEditingDisabled ? (
                        surveyQuestionAnswer.text || ''
                      ) : (
                        <EditableTextInput
                          text={surveyQuestionAnswer.text || ''}
                          name="text"
                          data={surveyQuestionAnswer.text || ''}
                          canBeEmpty
                          updateData={(appId: string, id: number, value: string) => {
                            surveyQuestionAnswer.text = value;
                            updateAnswerText(surveyQuestionAnswer);
                          }}
                        />
                      ),
                  },
                  {
                    key: 'value',
                    label: t('common.value'),
                    render: surveyQuestionAnswer =>
                      isEditingDisabled ? (
                        surveyQuestionAnswer.value || ''
                      ) : (
                        <Fragment>
                          {surveyQuestionAnswer.type !==
                            SurveyAnswerTypes.SURVEY_QUESTION_ANSWER_TYPE_TEXT && (
                            <EditableSelectInput
                              name="value"
                              data={surveyQuestionAnswer.value || ''}
                              options={valueOptions}
                              canBeEmpty
                              updateData={(appId: string, id: number, value: string) => {
                                surveyQuestionAnswer.value = value;
                                updateAnswerText(surveyQuestionAnswer);
                              }}
                            />
                          )}
                        </Fragment>
                      ),
                  },
                  {
                    key: 'operations',
                    label: t('common.operations'),
                    operations: isEditingDisabled ? [] : [Operations.DELETE],
                  },
                ]}
              />
            </CardBody>
          </Card>
        )}
      </Formik>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  surveyQuestionAnswers: state.surveyQuestionAnswers.data.content,
  numberOfQuestionAnswers: state.surveyQuestionAnswers.data.totalElements,
  surveyQuestionAnswersLoading: state.surveyQuestionAnswers.loading,
  surveyQuestionAnswersLoaded: state.surveyQuestionAnswers.loaded,
  surveyQuestionAnswersError: state.surveyQuestionAnswers.error,
});

const mapDispatchToProps = {
  getSurveyQuestionAnswers,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveysEditorQuestionEditSidebar);
