import React, { Fragment } from 'react';

import { ConversationHistoryModel } from '../../../../../../redux/actions/conversation-history.action';

import TextMessage from './components/Hangouts/TextMessage';
import ImageMessage from './components/Hangouts/ImageMessage';
import ButtonTemplate from './components/Hangouts/ButtonTemplate';
import GenericTemplate from './components/Hangouts/GenericTemplate';
import SimplifiedButton from './components/Hangouts/SimplifiedButton';

interface Props {
  message: ConversationHistoryModel;
}

const renderMessage = (message: ConversationHistoryModel) => {
  switch (message.messageType) {
    case 'Text':
      return <TextMessage message={message} />;
    case 'ButtonTemplate':
      return <ButtonTemplate message={message} />;
    case 'GenericTemplate':
      return <GenericTemplate message={message} />;
    case 'Image':
      return <ImageMessage message={message} />;
    case 'SimplifiedButton':
      return <SimplifiedButton message={message} />;
    default:
      return null;
  }
};

const Hangouts: React.FC<Props> = (props: Props) => {
  const { message } = props;

  return <Fragment>{renderMessage(message)}</Fragment>;
};

export default Hangouts;
