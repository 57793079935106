import { Dispatch } from 'redux';
import { ApplicantsQueryParams } from '../../shared/models/query-params.model';

import {
  getShortListPositions as getShortListPositionsService,
  getShortListPositionCriterias as getShortListPositionCriteriasService,
  getShortListPositionApplicants as getShortListPositionApplicantsService,
} from '../services/short-list.service';

export const GET_SHORT_LIST_POSITIONS_REQUEST = 'short-list/GET_SHORT_LIST_POSITIONS_REQUEST';
export const GET_SHORT_LIST_POSITIONS_SUCCESS = 'short-list/GET_SHORT_LIST_POSITIONS_SUCCESS';
export const GET_SHORT_LIST_POSITIONS_ERROR = 'short-list/GET_SHORT_LIST_POSITIONS_ERROR';

export const GET_SHORT_LIST_POSITION_CRITERIAS_REQUEST =
  'short-list/GET_SHORT_LIST_POSITION_CRITERIAS_REQUEST';
export const GET_SHORT_LIST_POSITION_CRITERIAS_SUCCESS =
  'short-list/GET_SHORT_LIST_POSITION_CRITERIAS_SUCCESS';
export const GET_SHORT_LIST_POSITION_CRITERIAS_ERROR =
  'short-list/GET_SHORT_LIST_POSITION_CRITERIAS_ERROR';

export const GET_SHORT_LIST_POSITION_APPLICANTS_REQUEST =
  'short-list/GET_SHORT_LIST_POSITION_APPLICANTS_REQUEST';
export const GET_SHORT_LIST_POSITION_APPLICANTS_SUCCESS =
  'short-list/GET_SHORT_LIST_POSITION_APPLICANTS_SUCCESS';
export const GET_SHORT_LIST_POSITION_APPLICANTS_ERROR =
  'short-list/GET_SHORT_LIST_POSITION_APPLICANTS_ERROR';

export const CLEAR_SHORT_LIST_STATE = 'short-list/CLEAR_SHORT_LIST_STATE';

export const getShortListPositions = (applicationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_SHORT_LIST_POSITIONS_REQUEST,
    });

    try {
      dispatch({
        type: GET_SHORT_LIST_POSITIONS_SUCCESS,
        payload: await getShortListPositionsService(applicationId),
      });
    } catch (error) {
      dispatch({
        type: GET_SHORT_LIST_POSITIONS_ERROR,
        error,
      });
    }
  };
};

export const getShortListPositionCriterias = (applicationId: string, positionId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_SHORT_LIST_POSITION_CRITERIAS_REQUEST,
    });

    try {
      dispatch({
        type: GET_SHORT_LIST_POSITION_CRITERIAS_SUCCESS,
        payload: await getShortListPositionCriteriasService(applicationId, positionId),
      });
    } catch (error) {
      dispatch({
        type: GET_SHORT_LIST_POSITION_CRITERIAS_ERROR,
        error,
      });
    }
  };
};

export const getShortListPositionApplicants = (
  applicationId: string,
  positionId: number,
  queryParams: ApplicantsQueryParams,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_SHORT_LIST_POSITION_APPLICANTS_REQUEST,
    });

    try {
      dispatch({
        type: GET_SHORT_LIST_POSITION_APPLICANTS_SUCCESS,
        payload: await getShortListPositionApplicantsService(
          applicationId,
          positionId,
          queryParams,
        ),
      });
    } catch (error) {
      dispatch({
        type: GET_SHORT_LIST_POSITION_APPLICANTS_ERROR,
        error,
      });
    }
  };
};

export const clearShortListState = () => {
  return {
    type: CLEAR_SHORT_LIST_STATE,
  };
};
