import { Dispatch } from 'redux';
import { QueryParams } from '../../shared/models/query-params.model';
import {
  getFeedbackSummary as getFeedbackSummaryService,
  getFeedbacks as getFeedbacksService,
  getPlatformsWithFeedback as getPlatformsWithFeedbackService,
  exportFeedbacks as exportFeedbacksService,
} from '../services/feedbacks.service';

export const GET_FEEDBACK_SUMMARY_REQUEST = 'feedbacks/GET_FEEDBACK_SUMMARY_REQUEST';
export const GET_FEEDBACK_SUMMARY_SUCCESS = 'feedbacks/GET_FEEDBACK_SUMMARY_SUCCESS';
export const GET_FEEDBACK_SUMMARY_ERROR = 'feedbacks/GET_FEEDBACK_SUMMARY_ERROR';

export const GET_FEEDBACKS_REQUEST = 'feedbacks/GET_FEEDBACKS_REQUEST';
export const GET_FEEDBACKS_SUCCESS = 'feedbacks/GET_FEEDBACKS_SUCCESS';
export const GET_FEEDBACKS_ERROR = 'feedbacks/GET_FEEDBACKS_ERROR';

export const GET_PLATFORMS_WITH_FEEDBACK_REQUEST = 'feedbacks/GET_PLATFORMS_WITH_FEEDBACK_REQUEST';
export const GET_PLATFORMS_WITH_FEEDBACK_SUCCESS = 'feedbacks/GET_PLATFORMS_WITH_FEEDBACK_SUCCESS';
export const GET_PLATFORMS_WITH_FEEDBACK_ERROR = 'feedbacks/GET_PLATFORMS_WITH_FEEDBACK_ERROR';

export const EXPORT_FEEDBACKS_REQUEST = 'feedbacks/EXPORT_FEEDBACKS_REQUEST';
export const EXPORT_FEEDBACKS_SUCCESS = 'feedbacks/EXPORT_FEEDBACKS_SUCCESS';
export const EXPORT_FEEDBACKS_ERROR = 'feedbacks/EXPORT_FEEDBACKS_ERROR';

export interface FeedbackSummaryModel {
  id: string | number;
  value: number;
  label: string;
  [key: string]: string | number;
}

export interface FeedbackModel {
  id: number;
  createdAt: string;
  feedbackMessage: string | null;
}

export const getFeedbackSummary = (
  applicationId: number,
  platform: string,
  queryParams: object,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_FEEDBACK_SUMMARY_REQUEST,
    });

    try {
      const feedbackSummary: FeedbackSummaryModel[] = await getFeedbackSummaryService(
        applicationId,
        platform,
        queryParams,
      );

      dispatch({
        type: GET_FEEDBACK_SUMMARY_SUCCESS,
        payload: feedbackSummary,
      });
    } catch (error) {
      dispatch({
        type: GET_FEEDBACK_SUMMARY_ERROR,
        error,
      });
      throw error;
    }
  };
};

export const getFeedbacks = (applicationId: number, platform: string, queryParams: QueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_FEEDBACKS_REQUEST,
    });

    try {
      const feedbacks: FeedbackModel[] = await getFeedbacksService(
        applicationId,
        platform,
        queryParams,
      );

      dispatch({
        type: GET_FEEDBACKS_SUCCESS,
        payload: feedbacks,
      });
    } catch (error) {
      dispatch({
        type: GET_FEEDBACKS_ERROR,
        error,
      });
      throw error;
    }
  };
};

export const getPlatformsWithFeedback = (applicationId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_PLATFORMS_WITH_FEEDBACK_REQUEST,
    });

    try {
      const platforms: Array<string> = await getPlatformsWithFeedbackService(applicationId);

      dispatch({
        type: GET_PLATFORMS_WITH_FEEDBACK_SUCCESS,
        payload: platforms,
      });
    } catch (error) {
      dispatch({
        type: GET_PLATFORMS_WITH_FEEDBACK_ERROR,
        error,
      });
      throw error;
    }
  };
};

export const exportFeedbacks = (applicationId: number, platform: string, queryParams: object) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EXPORT_FEEDBACKS_REQUEST,
    });

    try {
      const feedbacks: FeedbackModel[] = await exportFeedbacksService(
        applicationId,
        platform,
        queryParams,
      );

      dispatch({
        type: EXPORT_FEEDBACKS_SUCCESS,
        payload: feedbacks,
      });
    } catch (error) {
      dispatch({
        type: EXPORT_FEEDBACKS_ERROR,
        error,
      });
      throw error;
    }
  };
};
