import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader } from 'src/shared/components';
import NewPositionButton from './NewPositionButton';

const PositionsHeader = () => {
  const { t } = useTranslation();

  return <PageHeader title={t('common.positions')} rightComponent={<NewPositionButton />} />;
};

export default PositionsHeader;
