import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';
import { Formik, Form } from 'formik';

import { labelCategorySchema } from 'src/shared/schemas/validation.schema';

import TextField from 'src/shared/components/form-inputs/TextField';
import Spinner from 'src/shared/components/Spinner';

interface Props {
  isOpen: boolean;
  toggleModal: Function;
  createCategory: Function;
}

const LabelCategoryModal: React.FC<Props> = ({ isOpen, toggleModal, createCategory }) => {
  const { t } = useTranslation();

  const handleSubmit = async (name: string) => {
    await createCategory(name);
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{t('labels.createLabelCategory')}</ModalHeader>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{ name: '' }}
        validationSchema={labelCategorySchema}
        onSubmit={async ({ name }, { setSubmitting }) => {
          await handleSubmit(name);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="name">{t('common.name')}</Label>
                    <TextField name="name" />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>

            <ModalFooter>
              <Spinner loading={isSubmitting} />

              <Button type="submit" color="primary" disabled={isSubmitting}>
                {t('common.submit')}
              </Button>

              <Button color="secondary" onClick={() => toggleModal()}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default LabelCategoryModal;
