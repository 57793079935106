import React, { Fragment } from 'react';
import i18n from 'src/i18n';
import { ListModel } from 'src/redux/reducers/lists.reducer';

interface Props {
  newSubscribingList: ListModel | undefined;
  oldSubscribingList: ListModel | undefined;
}

const Lists: React.FC<Props> = ({ oldSubscribingList, newSubscribingList }) => {
  return (
    <Fragment>
      {oldSubscribingList && (
        <>
          <p className="font-weight-bold">
            {i18n.t('auditLog.oldSubscribingList')}
          </p>
          <table className="table table-sm">
            <tbody>
              <tr>
                <td>{i18n.t('common.id')}</td>
                <td>{oldSubscribingList.id}</td>
              </tr>
              <tr>
                <td>{i18n.t('common.name')}</td>
                <td>{oldSubscribingList.name}</td>
              </tr>
              <tr>
                <td>{i18n.t('common.createdAt')}</td>
                <td>{oldSubscribingList.createdAt}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      {newSubscribingList && (
        <>
          <p className="font-weight-bold">
            {i18n.t('auditLog.newSubscribingList')}
          </p>
          <table className="table table-sm">
            <tbody>
              <tr>
                <td>{i18n.t('common.id')}</td>
                <td>{newSubscribingList.id}</td>
              </tr>
              <tr>
                <td>{i18n.t('common.name')}</td>
                <td>{newSubscribingList.name}</td>
              </tr>
              <tr>
                <td>{i18n.t('common.createdAt')}</td>
                <td>{newSubscribingList.createdAt}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </Fragment>
  );
};

export default Lists;
