import { Dispatch } from 'redux';

import { GeneralQueryParams } from 'src/shared/models/query-params.model';

import {
    getInterviewAttendees as getInterviewAttendeesService
} from '../services/interview-attendees.service';

export const GET_INTERVIEW_ATTENDEES_REQUEST = 'interviews/GET_INTERVIEW_ATTENDEES_REQUEST';
export const GET_INTERVIEW_ATTENDEES_SUCCESS = 'interviews/GET_INTERVIEW_ATTENDEES_SUCCESS';
export const GET_INTERVIEW_ATTENDEES_ERROR = 'interviews/GET_INTERVIEW_ATTENDEES_ERROR';

export const getInterviewAttendees = (applicationId: string, eventId: string, queryParams: GeneralQueryParams) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: GET_INTERVIEW_ATTENDEES_REQUEST,
        });

        try {
            dispatch({
                type: GET_INTERVIEW_ATTENDEES_SUCCESS,
                payload: await getInterviewAttendeesService(applicationId, eventId, queryParams),
            });
        } catch (error) {
            dispatch({
                type: GET_INTERVIEW_ATTENDEES_ERROR,
                error,
            });
        }
    };
};