import { Dispatch } from 'redux';
import {
  getSubscribingLists as getSubscribingListsService,
  createSubscribingList as createSubscribingListService,
  updateSubscribingList as updateSubscribingListService,
  deleteSubscribingList as deleteSubscribingListService,
  getSubscribingList as getSubscribingListService,
  getSubscribers as getSubscribersService,
  deleteSubscriber as deleteSubscriberService,
  exportSubscribers as exportSubscribersService,
  getTargetMessagingPlatforms as getTargetMessagingPlatformsService,
  sendMessageToSubscribers as sendMessageToSubscribersService,
  addTagToSubscribingList as addTagToSubscribingListService,
  removeSubscribingListTag as removeSubscribingListTagService,
} from '../services/subscribing-lists.service';
import {
  SubscribingListModel,
  EmailMessageModel,
} from '../../shared/models/subscribing-lists.model';
import { QueryParams, GeneralQueryParams } from '../../shared/models/query-params.model';

export const GET_SUBSCRIBING_LISTS_REQUEST = 'subscribing-lists/GET_SUBSCRIBING_LISTS_REQUEST';
export const GET_SUBSCRIBING_LISTS_SUCCESS = 'subscribing-lists/GET_SUBSCRIBING_LISTS_SUCCESS';
export const GET_SUBSCRIBING_LISTS_ERROR = 'subscribing-lists/GET_SUBSCRIBING_LISTS_ERROR';

export const CREATE_SUBSCRIBING_LIST_REQUEST = 'subscribing-lists/CREATE_SUBSCRIBING_LIST_REQUEST';
export const CREATE_SUBSCRIBING_LIST_SUCCESS = 'subscribing-lists/CREATE_SUBSCRIBING_LIST_SUCCESS';
export const CREATE_SUBSCRIBING_LIST_ERROR = 'subscribing-lists/CREATE_SUBSCRIBING_LIST_ERROR';

export const UPDATE_SUBSCRIBING_LIST_REQUEST = 'subscribing-lists/UPDATE_SUBSCRIBING_LIST_REQUEST';
export const UPDATE_SUBSCRIBING_LIST_SUCCESS = 'subscribing-lists/UPDATE_SUBSCRIBING_LIST_SUCCESS';
export const UPDATE_SUBSCRIBING_LIST_ERROR = 'subscribing-lists/UPDATE_SUBSCRIBING_LIST_ERROR';
export const UPDATE_SUBSCRIBING_LIST_CLEAR_ERROR =
  'subscribing-lists/UPDATE_SUBSCRIBING_LIST_CLEAR_ERROR';

export const DELETE_SUBSCRIBING_LIST_REQUEST = 'subscribing-lists/DELETE_SUBSCRIBING_LIST_REQUEST';
export const DELETE_SUBSCRIBING_LIST_SUCCESS = 'subscribing-lists/DELETE_SUBSCRIBING_LIST_SUCCESS';
export const DELETE_SUBSCRIBING_LIST_ERROR = 'subscribing-lists/DELETE_SUBSCRIBING_LIST_ERROR';

export const GET_SUBSCRIBING_LIST_REQUEST = 'subscribing-lists/GET_SUBSCRIBING_LIST_REQUEST';
export const GET_SUBSCRIBING_LIST_SUCCESS = 'subscribing-lists/GET_SUBSCRIBING_LIST_SUCCESS';
export const GET_SUBSCRIBING_LIST_ERROR = 'subscribing-lists/GET_SUBSCRIBING_LIST_ERROR';

export const GET_SUBSCRIBERS_REQUEST = 'subscribing-lists/GET_SUBSCRIBERS_REQUEST';
export const GET_SUBSCRIBERS_SUCCESS = 'subscribing-lists/GET_SUBSCRIBERS_SUCCESS';
export const GET_SUBSCRIBERS_ERROR = 'subscribing-lists/GET_SUBSCRIBERS_ERROR';

export const DELETE_SUBSCRIBER_REQUEST = 'subscribing-lists/DELETE_SUBSCRIBER_REQUEST';
export const DELETE_SUBSCRIBER_SUCCESS = 'subscribing-lists/DELETE_SUBSCRIBER_SUCCESS';
export const DELETE_SUBSCRIBER_ERROR = 'subscribing-lists/DELETE_SUBSCRIBER_ERROR';

export const EXPORT_SUBSCRIBERS_REQUEST = 'subscribing-lists/EXPORT_SUBSCRIBERS_REQUEST';
export const EXPORT_SUBSCRIBERS_SUCCESS = 'subscribing-lists/EXPORT_SUBSCRIBERS_SUCCESS';
export const EXPORT_SUBSCRIBERS_ERROR = 'subscribing-lists/EXPORT_SUBSCRIBERS_ERROR';

export const GET_TARGET_MESSAGING_PLATFORMS_REQUEST =
  'subscribing-lists/GET_TARGET_MESSAGING_PLATFORMS_REQUEST';
export const GET_TARGET_MESSAGING_PLATFORMS_SUCCESS =
  'subscribing-lists/GET_TARGET_MESSAGING_PLATFORMS_SUCCESS';
export const GET_TARGET_MESSAGING_PLATFORMS_ERROR =
  'subscribing-lists/GET_TARGET_MESSAGING_PLATFORMS_ERROR';

export const SEND_MESSAGE_TO_SUBSCRIBERS_REQUEST =
  'subscribing-lists/SEND_MESSAGE_TO_SUBSCRIBERS_REQUEST';
export const SEND_MESSAGE_TO_SUBSCRIBERS_SUCCESS =
  'subscribing-lists/SEND_MESSAGE_TO_SUBSCRIBERS_SUCCESS';
export const SEND_MESSAGE_TO_SUBSCRIBERS_ERROR =
  'subscribing-lists/SEND_MESSAGE_TO_SUBSCRIBERS_ERROR';

export const ADD_TAG_TO_SUBSCRIBER_REQUEST = 'subscribing-lists/ADD_TAG_TO_SUBSCRIBER_REQUEST';
export const ADD_TAG_TO_SUBSCRIBER_REQUEST_SUCCESS =
  'subscribing-lists/ADD_TAG_TO_SUBSCRIBER_REQUEST_SUCCESS';
export const ADD_TAG_TO_SUBSCRIBER_REQUEST_ERROR =
  'subscribing-lists/ADD_TAG_TO_SUBSCRIBER_REQUEST_ERROR';

export const REMOVE_SUBSCRIBER_TAG_REQUEST = 'subscribing-lists/REMOVE_SUBSCRIBER_TAG_REQUEST';
export const REMOVE_SUBSCRIBER_TAG_REQUEST_SUCCESS =
  'subscribing-lists/REMOVE_SUBSCRIBER_TAG_REQUEST_SUCCESS';
export const REMOVE_SUBSCRIBER_TAG_REQUEST_ERROR =
  'subscribing-lists/REMOVE_SUBSCRIBER_TAG_REQUEST_ERROR';

export const getSubscribingLists = (applicationId: number, queryParams: GeneralQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_SUBSCRIBING_LISTS_REQUEST,
    });

    try {
      const payload = await getSubscribingListsService(applicationId, queryParams);

      dispatch({
        type: GET_SUBSCRIBING_LISTS_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: GET_SUBSCRIBING_LISTS_ERROR,
        error,
      });
    }
  };
};

export const getSubscribingList = (applicationId: number, listId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_SUBSCRIBING_LIST_REQUEST,
    });

    try {
      const payload = await getSubscribingListService(applicationId, listId);

      dispatch({
        type: GET_SUBSCRIBING_LIST_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: GET_SUBSCRIBING_LIST_ERROR,
        error,
      });
    }
  };
};

export const createSubscribingList = (applicationId: number, list: SubscribingListModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_SUBSCRIBING_LIST_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_SUBSCRIBING_LIST_SUCCESS,
        payload: await createSubscribingListService(applicationId, list),
      });
    } catch (error) {
      dispatch({
        type: CREATE_SUBSCRIBING_LIST_ERROR,
        error,
      });
    }
  };
};

export const updateSubscribingList = (
  applicationId: number,
  listId: number,
  list: SubscribingListModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_SUBSCRIBING_LIST_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_SUBSCRIBING_LIST_SUCCESS,
        payload: await updateSubscribingListService(applicationId, listId, list),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_SUBSCRIBING_LIST_ERROR,
        error,
      });
    }
  };
};

export const clearUpdateSubscribingListError = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_SUBSCRIBING_LIST_CLEAR_ERROR,
    });
  };
};

export const deleteSubscribingList = (applicationId: number, listId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_SUBSCRIBING_LIST_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_SUBSCRIBING_LIST_SUCCESS,
        payload: await deleteSubscribingListService(applicationId, listId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_SUBSCRIBING_LIST_ERROR,
        error,
      });
    }
  };
};

export const getSubscribers = (applicationId: number, listSlug: string, params: QueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_SUBSCRIBERS_REQUEST,
    });

    try {
      const payload = await getSubscribersService(applicationId, listSlug, params);

      dispatch({
        type: GET_SUBSCRIBERS_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: GET_SUBSCRIBERS_ERROR,
        error,
      });
    }
  };
};

export const addTagToSubscribingList = (
  applicationId: number,
  listId: number,
  tagIds: string[],
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ADD_TAG_TO_SUBSCRIBER_REQUEST,
    });

    try {
      dispatch({
        type: ADD_TAG_TO_SUBSCRIBER_REQUEST_SUCCESS,
        payload: await addTagToSubscribingListService(applicationId, listId, tagIds),
      });
    } catch (error) {
      dispatch({
        type: ADD_TAG_TO_SUBSCRIBER_REQUEST_ERROR,
        error,
      });
    }
  };
};

export const removeSubscribingListTag = (applicationId: number, listId: number, tagId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REMOVE_SUBSCRIBER_TAG_REQUEST,
    });

    try {
      dispatch({
        type: REMOVE_SUBSCRIBER_TAG_REQUEST_SUCCESS,
        payload: await removeSubscribingListTagService(applicationId, listId, tagId),
      });
    } catch (error) {
      dispatch({
        type: REMOVE_SUBSCRIBER_TAG_REQUEST_ERROR,
        error,
      });
    }
  };
};

export const deleteSubscriber = (
  applicationId: number,
  listSlug: string,
  subscriberSlug: string,
  platform: string,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_SUBSCRIBER_REQUEST,
    });

    try {
      await deleteSubscriberService(applicationId, listSlug, subscriberSlug, platform);

      dispatch({
        type: DELETE_SUBSCRIBER_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: DELETE_SUBSCRIBER_ERROR,
        error,
      });
    }
  };
};

export const exportSubscribers = (applicationId: number, listSlug: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EXPORT_SUBSCRIBERS_REQUEST,
    });

    try {
      const payload = await exportSubscribersService(applicationId, listSlug);

      dispatch({
        type: EXPORT_SUBSCRIBERS_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: EXPORT_SUBSCRIBERS_ERROR,
        error,
      });
    }
  };
};

export const getTargetMessagingPlatforms = (applicationId: number, listId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TARGET_MESSAGING_PLATFORMS_REQUEST,
    });

    try {
      const payload = await getTargetMessagingPlatformsService(applicationId, listId);

      dispatch({
        type: GET_TARGET_MESSAGING_PLATFORMS_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: GET_TARGET_MESSAGING_PLATFORMS_ERROR,
        error,
      });
    }
  };
};

export const sendMessageToSubscribers = (
  applicationId: number,
  listId: number,
  platform: string,
  data: EmailMessageModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SEND_MESSAGE_TO_SUBSCRIBERS_REQUEST,
    });

    try {
      const payload = await sendMessageToSubscribersService(applicationId, listId, platform, data);

      dispatch({
        type: SEND_MESSAGE_TO_SUBSCRIBERS_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: SEND_MESSAGE_TO_SUBSCRIBERS_ERROR,
        error,
      });
    }
  };
};
