import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { InputGroup, Input, InputGroupAddon, Button } from 'reactstrap';
import { connect } from 'react-redux';

import { createSurveyQuestion, getSurveyQuestions } from 'src/redux/services/survey.service';
import { SurveyQuestionAnswerEditorModel } from 'src/shared/models';

interface Props {
  afterCreate?: Function;
  isEditingDisabled: boolean;
}

const CreateSurveyQuestionForm: React.FC<Props> = ({ afterCreate, isEditingDisabled }) => {
  const { t } = useTranslation();
  const [newQuestionValue, setNewQuestionValue] = useState<string>('');
  const { appId, surveyId } = useParams<{ appId: string; surveyId: string }>();

  const createQuestion = async (newQuestionValue: string) => {
    if (!surveyId) {
      return;
    }

    const questions: SurveyQuestionAnswerEditorModel[] = await getSurveyQuestions(appId, surveyId);

    await createSurveyQuestion(appId, surveyId, {
      text: newQuestionValue,
      orderNumber: questions?.length || 0,
    });
    setNewQuestionValue('');
    if (afterCreate) {
      afterCreate();
    }
  };

  return (
    <InputGroup>
      <Input
        value={newQuestionValue}
        disabled={!surveyId}
        placeholder={t('surveys.createSurveyQuestionPlaceholder')}
        onChange={e => setNewQuestionValue(e.target.value)}
      />
      <InputGroupAddon addonType="append">
        <Button
          className="m-0"
          disabled={!surveyId || !newQuestionValue || isEditingDisabled}
          onClick={() => {
            createQuestion(newQuestionValue);
          }}
        >
          {t('surveys.createQuestion')}
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSurveyQuestionForm);
