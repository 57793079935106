import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';

import { getApplication, ApplicationModel } from 'src/redux/actions/applications.action';

import ExpressionsHeader from './components/ExpressionsHeader';
import ExpressionsTable from './components/ExpressionsTable';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
}

const Expressions: React.FC<Props> = ({ application, getApplication }) => {
  const { appId } = useParams();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [modalEvent, setModalEvent] = useState<string>('');

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  return (
    <div className="animated fadeIn">
      <ExpressionsHeader setModalOpen={setModalOpen} setModalEvent={setModalEvent} />

      <Card>
        <CardBody>
          <ExpressionsTable
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            modalEvent={modalEvent}
          />
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
});

const mapDispatchToProps = {
  getApplication,
};

export default connect(mapStateToProps, mapDispatchToProps)(Expressions);
