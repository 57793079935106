import React, { Component, Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { FeedbackSummaryModel } from 'src/redux/actions/feedbacks.action';
import { PieChart } from 'src/shared/components';

interface Props extends RouteComponentProps, WithTranslation {
  feedbackSummary: any;
}
interface State {
  feedbacks: FeedbackSummaryModel[];
  labels: string[];
  numberOfFeedbacks: number;
}

class FeedbacksPieChart extends Component<Props, State> {
  state: State = {
    feedbacks: [],
    labels: [],
    numberOfFeedbacks: 0,
  };

  async componentDidMount() {
    if (this.props.feedbackSummary.data) {
      await this.setData(this.props.feedbackSummary.data);
      this.sortData(this.state.feedbacks);
    }
  }

  setData(feedbackSummary: FeedbackSummaryModel[]) {
    const feedbackObjects: FeedbackSummaryModel[] = [];
    const labels: string[] = [];
    let feedbacksCount: number = 0;

    feedbackSummary.map((feedback: FeedbackSummaryModel) => {
      const feedbackObject = {
        id: feedback.label,
        label: feedback.label,
        value: feedback.value,
        key: feedback.key,
      };

      if (!labels.includes(feedback.label)) {
        labels.push(feedback.label);
      }

      feedbacksCount += feedback.value;

      return feedbackObjects.push(feedbackObject);
    });

    this.setState({
      feedbacks: feedbackObjects,
      labels,
      numberOfFeedbacks: feedbacksCount,
    });
  }

  sortData(feedbacks: FeedbackSummaryModel[]) {
    let sortedFeedbacks: FeedbackSummaryModel[] = [];

    if (feedbacks) {
      sortedFeedbacks = feedbacks.sort((a, b) => (a.value < b.value ? 1 : -1));
    }

    this.setState({
      feedbacks: sortedFeedbacks,
    });
  }

  render() {
    const { t } = this.props;
    const { feedbacks, numberOfFeedbacks } = this.state;

    return (
      <Fragment>
        {feedbacks.length ? (
          <ul className="bordered-list">
            {feedbacks.map((feedback: FeedbackSummaryModel) => (
              <li key={feedback.id}>
                {feedback.id}
                <span className="float-right">
                  {feedback.value} ({Math.round((feedback.value / numberOfFeedbacks) * 100)}%)
                </span>
              </li>
            ))}

            <li className="total-feedbacks">
              {t('feedbacks.totalFeedbacks')}
              <span className="float-right mr-1">{numberOfFeedbacks}</span>
            </li>
          </ul>
        ) : null}

        <div className="chart-container">
          <PieChart
            data={feedbacks}
            margin={{ top: 20 }}
            innerRadius={0.5}
            enableRadialLabels={false}
            colors={{ scheme: 'set2' }}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.25]] }}
            animate
            motionStiffness={90}
            motionDamping={15}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
              {
                id: 'squares',
                type: 'patternSquares',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true,
              },
            ]}
            fill={[
              {
                match: {
                  id: this.state.labels[0],
                },
                id: 'dots',
              },
              {
                match: {
                  id: this.state.labels[1],
                },
                id: 'lines',
              },
            ]}
          />
        </div>
      </Fragment>
    );
  }
}

export default withTranslation()(withRouter(FeedbacksPieChart));
