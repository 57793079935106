import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';
import { Formik, Form } from 'formik';

import { CustomListModel } from 'src/shared/models/custom-lists.model';
import { customListSchema } from 'src/shared/schemas/validation.schema';

import { createCustomList, updateCustomList } from 'src/redux/actions/custom-lists.action';

import TextField from 'src/shared/components/form-inputs/TextField';
import Spinner from 'src/shared/components/Spinner';

interface Props {
  toggle: Function;
  isOpen: boolean;
  event: string;
  customList: CustomListModel | undefined;
  setSelectedCustomList: Function;
  createCustomList: Function;
  updateCustomList: Function;
}

const CustomListModal: React.FC<Props> = ({
  toggle,
  isOpen,
  event,
  customList,
  setSelectedCustomList,
  createCustomList,
  updateCustomList,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const title = event === 'create' ? 'customLists.createCustomList' : 'customLists.editCustomList';
  const submitButtonText = event === 'create' ? 'common.create' : 'common.edit';

  const handleSubmit = async (name: string) => {
    if (customList) {
      await updateCustomList(appId, customList.id, { name });
    } else {
      await createCustomList(appId, { name });
    }

    handleToggle();
  };

  const handleToggle = () => {
    setSelectedCustomList(undefined);
    toggle();
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{t(title)}</ModalHeader>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{ name: customList?.name || '' }}
        validationSchema={customListSchema}
        onSubmit={async ({ name }, { setSubmitting }) => {
          await handleSubmit(name);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="name">{t('common.name')}</Label>
                    <TextField name="name" />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>

            <ModalFooter>
              <Spinner loading={isSubmitting} />

              <Button type="submit" color="success" disabled={isSubmitting}>
                {t(submitButtonText)}
              </Button>

              <Button color="secondary" onClick={() => handleToggle()}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapDispatchToProps = {
  createCustomList,
  updateCustomList,
};

export default connect(null, mapDispatchToProps)(CustomListModal);
