import moment from 'moment';

import { EmployeeModel, SelectModel } from 'src/shared/models';
import { TagModel } from 'src/shared/models/employee.model';
import { TaggableModel } from './event.model';

export interface BaseSurveyModel {
  name: string;
  description: string;
  retries: number;
  mandatory: boolean;
  slug?: string;
}

export interface SurveyModel extends BaseSurveyModel, TaggableModel {
  applicationId: number;
  numberOfCompleted: number;
  numberOfSurveyQuestion: number;
  numberOfSurveySubscriptions: number;
  startMessage: string;
  startsAt: string;
  endsAt: string;
  retryInterval: string;
  deliverOffset: string;
  notification: boolean;
}

export interface SurveyEditorModel extends BaseSurveyModel {
  startsAt: string;
  endsAt: string;
  retryInterval: string;
  deliverOffset: string;
  employeeTags: TagModel[] | null;
}

export interface SurveyEditorFormModel extends BaseSurveyModel {
  startDate: moment.Moment;
  startTime: moment.Moment;
  endDate: moment.Moment;
  endTime: moment.Moment;
  startMessage: String;
  employeeTags: TagModel[];
  retryInterval: SelectModel;
  deliverOffset: SelectModel;
  notification: boolean;
}

export interface SurveyQuestionEditorModel {
  id?: number | string;
  text: string;
  minRequiredToAnswered?: number;
  maxRequiredToAnswered?: number;
  orderNumber?: number;
}

export interface SurveyQuestionModel extends SurveyQuestionEditorModel {
  id: number;
  slug: string;
  surveyId: number;
  applicationId: number;
  surveyQuestionAnswers: Array<SurveyQuestionAnswerModel>;
  surveyQuestionAnswersText: string[];
  numberOfSurveyQuestionAnswers: number;
  survey: SurveyModel;
}

export interface SurveyQuestionAnswerEditorModel {
  id?: number;
  text: string;
  value: number;
  type: string;
  orderNumber: number;
}

export enum SurveyStatuses {
  running,
  scheduled,
  expired,
  none,
}

export const SurveyAnswerTypes = {
  SURVEY_QUESTION_ANSWER_TYPE_TEXT: 'SURVEY_QUESTION_ANSWER_TYPE_TEXT',
  SURVEY_QUESTION_ANSWER_TYPE_OPTION: 'SURVEY_QUESTION_ANSWER_TYPE_OPTION',
};

export interface UserProfileSurveyQuestionAnswers {
  answeredAt: string;
  text: string;
}

export interface SurveyQuestionAnswerModel extends SurveyQuestionAnswerEditorModel {
  id: number;
  slug: string;
  surveyQuestionId: number;
  surveyQuestion: Omit<SurveyQuestionModel, 'survey'>;
  numberOfSubscriberAnswered: number;
  userProfileSurveyQuestionAnswers: UserProfileSurveyQuestionAnswers[];
  originalIndex: number;
}

export interface SurveyQuestionAnswerOptionModel {
  id: number;
  externalId?: string | null;
  answer?: string;
  text?: string;
  value?: string;
  type: string;
  name?: number;
  orderNumbe?: number;
}

export interface SurveyQuestionsModel {
  id: number;
  text: string;
  slug: string;
  minRequiredToAnswered: number;
  maxRequiredToAnswered: number;
  orderNumber: number;
  surveyId: number;
  applicationId: number;
  numberOfAnswers: number;
  surveyQuestionAnswers: SurveyQuestionAnswerModel[];
  surveyQuestionAnswersText: string[];
  numberOfSurveyQuestionAnswers: number;
  survey: SurveyModel;
}

export interface SurveyStartMessageJson {
  text: string;
  type: string;
  buttons: Array<{ text: string; value: string }>;
}

export interface SurveySubscriberCustomModel {
  [key: string]: {
    [key: string]: number;
  };
}

export interface SurveySubscriberModel {
  id: number;
  userId: string;
  filledAt?: string;
  lastTriedAt?: string;
  employee?: EmployeeModel;
}
