// src/containers/DefaultLayout/DefaultLayout.tsx

/* eslint-disable */
import React, { Component, useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
  useParams,
  useLocation,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/hu';

import {
  getApplication,
  getApplications,
  ApplicationModel,
} from '../../redux/actions/applications.action';
import { getUserProfile, getPermissions } from '../../redux/actions/user.action';
import { setLanguage } from '../../redux/actions/language.action';

import AppBreadcrumb from '../../shared/components/AppBreadcrumb';

import {
  AppAside,
  // AppBreadcrumb,
  // AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
  // @ts-ignore
} from '@coreui/react';
// routes config
import routes from '../../routes';
import { isAuthenticated } from '../../redux/services/auth.service';

import DefaultHeader from './DefaultHeader';
// import DefaultAside from './DefaultAside';
import DefaultNav from './DefaultNav';
import DefaultNavFooter from './DefaultNavFooter';
import { UserModel } from '../../redux/reducers/user.reducer';
// import DefaultFooter from './DefaultFooter';

/* eslint-enable */

interface Props extends RouteComponentProps {
  getApplication: Function;
  getApplications: Function;
  application: ApplicationModel;
  applications: ApplicationModel[];
  language: string;
  getUserProfile: Function;
  user: UserModel;
  getPermissions: Function;
  permissions: any;
  setLanguage: Function;
}

const DefaultLayout: React.FC<Props | any> = props => {
  useEffect(() => {
    const lang = localStorage.getItem('i18nextLng') || 'en';
    moment.locale(lang);

    props.setLanguage(lang);
    props.getUserProfile();
    props.getPermissions();
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    moment.locale(props.language);
  }, [props.language]);

  // const loading = () => (
  //   <div className="animated fadeIn pt-1 text-center">
  //     <div className="sk-spinner sk-spinner-pulse" />
  //   </div>
  // );

  const onLanguageChange = () => {
    window.location.reload();
  };

  return (
    <div className="app">
      <AppHeader fixed>
        {/* <Suspense fallback={this.loading()}> */}
        <DefaultHeader onSetLanguage={onLanguageChange} />
        {/* </Suspense> */}
      </AppHeader>
      <div className="app-body">
        <AppSidebar fixed display="lg">
          {/* <AppSidebarHeader />
            <AppSidebarForm /> */}
          {/* <AppSidebarNav navConfig={navigation} {...this.props} /> */}
          <DefaultNav {...props} />
          {/* <AppSidebarFooter /> */}
          <DefaultNavFooter userGroup={props.user.userGroupName} />
          <AppSidebarMinimizer />
        </AppSidebar>
        <main className="main">
          <AppBreadcrumb />
          {/* <AppBreadcrumb appRoutes={routes} /> */}
          <Container fluid>
            {/* <Suspense fallback={this.loading()}> */}
            <Switch>
              {isAuthenticated() ? (
                routes.map(route => {
                  return (
                    route.component && (
                      <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        render={(props: any) => <route.component {...props} />}
                      />
                    )
                  );
                })
              ) : (
                <Redirect to="/login" />
              )}
              <Redirect to="/404" />
            </Switch>
            {/* </Suspense> */}
          </Container>
        </main>
        {/* <AppAside fixed>
            <Suspense fallback={this.loading()}>
              <DefaultAside />
            </Suspense>
          </AppAside> */}
      </div>
      {/* <AppFooter>
          <Suspense fallback={this.loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter> */}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    applications: state.applications.data,
    user: state.user.data,
    permissions: state.permissions.data,
    language: state.language.data,
  };
};

const mapDispatchToProps = {
  getApplication,
  getApplications,
  getUserProfile,
  getPermissions,
  setLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DefaultLayout));
