import React, { Fragment } from 'react';
import { Badge } from 'reactstrap';
import cx from 'classnames';
import Highlighter from 'react-highlight-words';
import { ConversationHistoryModel } from 'src/redux/actions/conversation-history.action';

import ViberStyles from './scss/Viber.module.scss';
import commonStyles from '../../../Messages.module.scss';

interface Props {
  message: ConversationHistoryModel;
  buttonTemplateText?: string;
  file?: FileModel;
}

interface FileModel {
  name: string;
  url: string;
}

const renderMessage = (
  message: ConversationHistoryModel,
  buttonTemplateText?: string,
  file?: FileModel,
) => {
  if (buttonTemplateText) return buttonTemplateText;

  if (file) {
    return (
      <Fragment>
        <i className="fas fa-cloud-download-alt mr-2" />
        <a href={file.url} target="_blank" rel="noopener noreferrer">
          {file.name}
        </a>
      </Fragment>
    );
  }

  if (message.entityTexts && message.entityTexts.length > 0) {
    return (
      <Highlighter
        highlightClassName={commonStyles.entityHighlight}
        searchWords={message.entityTexts}
        autoEscape
        textToHighlight={message.messageAsText}
      />
    );
  }

  return message.messageAsText;
};

const renderBadge = (message: ConversationHistoryModel) => {
  if (!message.intent) return null;

  return (
    <div className={commonStyles.intentBadgeContainer}>
      <Badge
        className={commonStyles.intentBadge}
        color={message.intent === 'none' ? 'danger' : 'success'}
      >
        #{message.intent}
      </Badge>
    </div>
  );
};

const TextMessage: React.FC<Props> = (props: Props) => {
  const { message, buttonTemplateText, file } = props;

  const textMessageClasses = cx(
    { [ViberStyles.bot]: message.isOutgoingMessage },
    { [ViberStyles.user]: !message.isOutgoingMessage },
    { [ViberStyles.start]: message.isStartMessage },
    { [ViberStyles.middle]: message.isMiddleMessage },
    { [ViberStyles.end]: message.isEndMessage },
  );

  return (
    <div className={textMessageClasses}>
      {renderMessage(message, buttonTemplateText, file)}
      {renderBadge(message)}
    </div>
  );
};

export default TextMessage;
