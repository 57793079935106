import React, { FC, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { connect } from 'react-redux';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { EditableTextInput, List } from 'src/shared/components';
import { Operations } from 'src/shared/models';
import { GetLanguagesResponse } from 'src/shared/models/language-settings.model';
import { getLanguageSettings } from 'src/redux/actions/language-settings.action';
import ContentTranslationFilters, { ModuleTypes } from './components/ContentTranslationFilters';
import {
  clearTranslations,
  getEventTranslations,
  getSurveyTranslations,
} from 'src/redux/actions/content-translations.action';
import {
  ContentTranslationSurveyListItem,
  GetSurveyTranslationsResponse,
} from 'src/shared/models/content-translations-survey.model';
import {
  getSurveyTranslationsModel,
  handleSurveyDelete,
  handleSurveyEdit,
} from './content-translations-survey.utils';
import {
  ContentTranslationEventListItem,
  GetEventTranslationsResponse,
} from 'src/shared/models/content-translations-event.model';
import {
  getEventTranslationsModel,
  handleEventDelete,
  handleEventEdit,
} from './content-translations-event.utils';
import {
  TranslationListItem,
  TranslationListModel,
} from 'src/shared/models/content-translations-model';

interface ContentTranslationsProps {
  application: ApplicationModel;
  languageSettings: GetLanguagesResponse;
  contentTranslationsSurvey: GetSurveyTranslationsResponse;
  contentTranslationsEvent: GetEventTranslationsResponse;

  getApplication: Function;
  getLanguageSettings: (appId: string) => Promise<void>;
  getSurveyTranslations: (appId: string) => Promise<void>;
  getEventTranslations: (appId: string) => Promise<void>;
  clearTranslations: () => void;
}

const ContentTranslations: FC<ContentTranslationsProps> = ({
  application,
  languageSettings,
  contentTranslationsSurvey,
  contentTranslationsEvent,
  getApplication,
  getLanguageSettings,
  getSurveyTranslations,
  getEventTranslations,
  clearTranslations,
}) => {
  const { appId } = useParams<{ appId: string }>();
  const { t } = useTranslation();

  const [scrollYPosition, setScrollYPosition] = useState<number>(0);
  const [selectedModule, setSelectedModule] = useState<ModuleTypes | undefined>();
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>();

  const listModel: TranslationListModel = useMemo(() => {
    if (selectedModule === ModuleTypes.SURVEYS) {
      return getSurveyTranslationsModel(contentTranslationsSurvey, selectedLanguage, t);
    } else return getEventTranslationsModel(contentTranslationsEvent, selectedLanguage, t);
  }, [contentTranslationsSurvey, contentTranslationsEvent, selectedLanguage, selectedModule, t]);

  useEffect(() => {
    clearTranslations();
    if (!application.id) {
      getApplication(appId);
    }
    getLanguageSettings(appId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedModule === undefined || !selectedLanguage) {
      return;
    }
    switch (selectedModule) {
      case ModuleTypes.SURVEYS: {
        getSurveyTranslations(appId);
        break;
      }
      case ModuleTypes.EVENTS: {
        getEventTranslations(appId);
        break;
      }
    }
  }, [selectedModule, selectedLanguage, appId, getSurveyTranslations, getEventTranslations]);

  useEffect(() => {
    window.scrollTo(0, scrollYPosition);
    // eslint-disable-next-line
  }, [contentTranslationsSurvey, contentTranslationsEvent]);

  const handleDelete = async (item: TranslationListItem) => {
    setScrollYPosition(() => window.scrollY);

    switch (selectedModule) {
      case ModuleTypes.SURVEYS: {
        await handleSurveyDelete(
          item as ContentTranslationSurveyListItem,
          selectedLanguage!,
          appId,
        );
        await getSurveyTranslations(appId);
        break;
      }
      case ModuleTypes.EVENTS: {
        await handleEventDelete(item as ContentTranslationEventListItem, selectedLanguage!, appId);
        await getEventTranslations(appId);
        break;
      }
    }
  };

  const handleEdit = async (item: TranslationListItem, newValue: string) => {
    setScrollYPosition(() => window.scrollY);

    switch (selectedModule) {
      case ModuleTypes.SURVEYS: {
        await handleSurveyEdit(
          item as ContentTranslationSurveyListItem,
          contentTranslationsSurvey,
          newValue,
          selectedLanguage!,
          appId,
        );
        await getSurveyTranslations(appId);
        break;
      }
      case ModuleTypes.EVENTS: {
        await handleEventEdit(
          item as ContentTranslationEventListItem,
          contentTranslationsEvent,
          newValue,
          selectedLanguage!,
          appId,
        );
        await getEventTranslations(appId);
        break;
      }
    }
  };

  const getDynamicColumnCssProperties = (item: TranslationListItem) => {
    if (!item.translatedText) return {};
    return { backgroundColor: '#bee3bc' };
  };

  return (
    <List
      useCardContent
      hidePager
      data={listModel}
      totalElements={0}
      loaded={true}
      loading={false}
      error={false}
      fetchData={() => {}}
      noDataLabel={t('translations.noTranslationsData')}
      deleteFunction={handleDelete}
      deleteTitle={t('common.warning')}
      deleteText={t('translations.deleteTranslation')}
      updatePermission="translation_survey_update"
      deletePermission="translation_survey_delete"
      filterItems={() => (
        <ContentTranslationFilters
          languageSettings={languageSettings}
          setSelectedModule={setSelectedModule}
          setSelectedLanguage={setSelectedLanguage}
        />
      )}
      fields={[
        {
          columnCssStyle: { width: '35%' },
          key: 'identifier',
          label: t('translations.surveyTranslations.identifier'),
          render: item => item.displayedIdentifier,
        },
        {
          columnCssStyle: { width: '10%' },
          key: 'fieldName',
          label: t('translations.surveyTranslations.fieldName'),
          render: item => <>[{item.fieldName}]</>,
        },
        {
          columnCssStyle: { width: '25%' },
          key: 'text',
          label: t('translations.surveyTranslations.originalText'),
          render: item => <>{item.originalText}</>,
        },
        {
          columnCssStyle: { width: '25%' },
          dynamicColumnCssProperties: getDynamicColumnCssProperties,
          key: 'translatedText',
          label: t('translations.surveyTranslations.translatedText'),
          render: item => (
            <div className="d-flex align-items-center">
              <div className="mr-2">
                <img
                  alt={selectedLanguage!}
                  src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedLanguage?.toUpperCase()}.svg`}
                  style={{ width: 30 }}
                />
              </div>

              <EditableTextInput
                name="title"
                canBeEmpty={false}
                data={item.translatedText}
                updateData={(appId: string, id: number, value: string) => {
                  if (value.length !== 0) {
                    handleEdit(item, value);
                  }
                }}
                textArea={item.originalText?.length / 60 > 1}
                rows={Math.round(item.originalText?.length / 60) || 1}
                style={{ width: '100%' }}
              />
            </div>
          ),
        },
        {
          columnCssStyle: { width: '5%' },
          dynamicColumnCssProperties: getDynamicColumnCssProperties,
          key: 'operations',
          label: '',
          operations: [Operations.DELETE],
        },
      ]}
      tableBodyProps={{ style: { minHeight: '500px' } }}
    />
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    languageSettings: state.languageSettings.data,
    contentTranslationsSurvey: state.contentTranslationsSurvey.data,
    contentTranslationsEvent: state.contentTranslationsEvent.data,
  };
};

const mapDispatchToProps = {
  getApplication,
  getLanguageSettings,
  getSurveyTranslations,
  getEventTranslations,
  clearTranslations,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentTranslations);
