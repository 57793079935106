import { StateModel } from 'src/shared/models';
import {
  GET_FORMS,
  GET_FORMS_SUCCESS,
  GET_FORMS_ERROR,
  DELETE_FORM,
  DELETE_FORM_SUCCESS,
  DELETE_FORM_ERROR,
  GET_VALIDATION_TYPES_SUCCESS,
} from '../actions/forms.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const formsReducer = (state: StateModel = INITIAL_STATE, { type, payload, error }: any) => {
  switch (type) {
    case GET_FORMS:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_FORMS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload,
      };
    case GET_FORMS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };
    case DELETE_FORM:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case DELETE_FORM_SUCCESS:
      const content = state.data.content.filter(({ id }: any) => id !== payload.id);
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          content,
        },
      };
    case DELETE_FORM_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };
    case GET_VALIDATION_TYPES_SUCCESS:
      return {
        ...state,
        validationTypes: payload,
      };

    default:
      return state;
  }
};
