import React, { Fragment, FC } from 'react';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap';
import { EditableTextInput, List } from 'src/shared/components';
import { Operations } from 'src/shared/models';

import {
  deleteSurveyQuestion,
  sortSurveyQuestions,
  updateSurveyQuestion,
} from 'src/redux/services/survey.service';

import { getSurveyQuestions } from 'src/redux/actions/surveys.action';

import {
  SurveyQuestionAnswerModel,
  SurveyQuestionEditorModel,
  SurveyQuestionModel,
} from 'src/shared/models';
import CreateSurveyQuestionForm from '../forms/CreateSurveyQuestionForm';

interface Props {
  surveyId?: string | number;
  getSurveyQuestions: (appId: string, surveyId: string) => void;
  surveyQuestions: SurveyQuestionModel[];
  numberOfQuestions: number;
  surveyQuestionsLoaded: boolean;
  surveyQuestionsLoading: boolean;
  surveyQuestionsError: boolean;
  isEditingDisabled: boolean;
  setEditSurveyQuestionItem: Function;
}

const SurveyQuestionsList: FC<Props> = ({
  surveyId,
  getSurveyQuestions,
  surveyQuestions,
  numberOfQuestions,
  surveyQuestionsLoaded,
  surveyQuestionsLoading,
  surveyQuestionsError,
  isEditingDisabled,
  setEditSurveyQuestionItem,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const fetchQuestions = () => {
    if (!surveyId) {
      return;
    }
    getSurveyQuestions(appId, surveyId.toString());
  };

  const removeQuestion = async (question?: SurveyQuestionModel) => {
    if (question && surveyId) {
      await deleteSurveyQuestion(appId, surveyId.toString(), question.id.toString());
    }
  };

  const editQuestion = async (id: string | number) => {
    setEditSurveyQuestionItem(surveyQuestions.find(e => e.id === id));
  };

  const updateQuestionText = async (surveyQuestion: SurveyQuestionEditorModel, value: string) => {
    if (!surveyId || !surveyQuestion.id) {
      return;
    }
    await updateSurveyQuestion(appId, surveyId.toString(), surveyQuestion.id.toString(), {
      text: value,
      orderNumber: surveyQuestion.orderNumber,
    });
    fetchQuestions();
  };

  const onQuestionDragEnd = async (orderedItems: Array<any>) => {
    if (!surveyId) {
      return;
    }
    await sortSurveyQuestions(
      appId,
      surveyId.toString(),
      orderedItems.map(e => e.id),
    );
    fetchQuestions();
  };

  return (
    <Fragment>
      <fieldset className="border p-3">
        <legend className="col-form-label w-auto font-weight-bold">{t('surveys.questions')}</legend>

        <Row form>
          <Col md="4" />
          <Col md="8">
            <Row className="mb-3">
              <Col className="d-flex justify-content-end">
                <CreateSurveyQuestionForm
                  afterCreate={fetchQuestions}
                  isEditingDisabled={isEditingDisabled}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <List
          data={surveyQuestions}
          hidePager
          totalElements={numberOfQuestions}
          loaded={surveyQuestionsLoaded}
          loading={surveyQuestionsLoading}
          error={surveyQuestionsError}
          fetchData={fetchQuestions}
          noDataLabel={t('surveys.noQuestions')}
          editFunction={editQuestion}
          deleteFunction={removeQuestion}
          deleteTitle={t('surveys.deleteQuestion')}
          deleteText={t('surveys.deleteSurveyQuestionConfirmMessage')}
          updatePermission="survey_survey_update"
          deletePermission="survey_survey_delete"
          onDragEnd={isEditingDisabled ? undefined : onQuestionDragEnd}
          fields={[
            {
              key: 'text',
              label: t('common.text'),
              render: surveyQuestion => (
                <EditableTextInput
                  text={surveyQuestion.text || ''}
                  name="text"
                  data={surveyQuestion.text || ''}
                  canBeEmpty
                  updateData={(appId: string, id: number, value: string) => {
                    updateQuestionText(surveyQuestion, value);
                  }}
                />
              ),
            },
            {
              key: 'surveyQuestionAnswers',
              label: t('surveys.answers'),
              render: surveyQuestion => (
                <div>
                  {surveyQuestion.surveyQuestionAnswers
                    .sort((a, b) => a.orderNumber - b.orderNumber)
                    .map((answer: SurveyQuestionAnswerModel) => (
                      <Button key={answer.id} color="secondary" className="mb-2 mr-2 text-left">
                        {answer.text}
                      </Button>
                    ))}
                </div>
              ),
            },
            {
              key: 'operations',
              label: t('common.operations'),
              operations: isEditingDisabled
                ? [Operations.UPDATE]
                : [Operations.UPDATE, Operations.DELETE],
            },
          ]}
        />
      </fieldset>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  surveyQuestions: state.surveyQuestions.data.content,
  numberOfQuestions: state.surveyQuestions.data.totalElements,
  surveyQuestionsLoading: state.surveyQuestions.loading,
  surveyQuestionsLoaded: state.surveyQuestions.loaded,
  surveyQuestionsError: state.surveyQuestions.error,
});

const mapDispatchToProps = {
  getSurveyQuestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyQuestionsList);
