import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';

import { ConversationHistoryModel } from '../../../../../redux/actions/conversation-history.action';

import Message from './Message';

import styles from './Messages.module.scss';

interface Props {
  conversationHistory: ConversationHistoryModel[];
  handleScroll: Function;
  createdAt: string | undefined;
}

const Messages: React.FC<Props> = ({ conversationHistory: messages, handleScroll, createdAt }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const redirectToMostRecent = () => {
    history.push(history.location.pathname.replace(`/${createdAt}`, ''));
  };

  return (
    <div
      id="conversationContainer"
      className={styles.conversationContainer}
      onScroll={(e: React.UIEvent<HTMLDivElement>) => handleScroll(e)}
    >
      {messages.map((message: ConversationHistoryModel) => (
        <Message message={message} key={message.id} createdAt={createdAt} />
      ))}

      {createdAt && (
        <div className="text-center p-3">
          <Button onClick={redirectToMostRecent} color="primary">
            {t('conversationHistory.mostRecentMessages')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Messages;
