import React from 'react';
import { useTranslation } from 'react-i18next';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import CheckPermission from '../../shared/components/CheckPermission';

const CommonElementsDropdown = () => {
  const { t } = useTranslation();

  interface CommonElementModel {
    name: string;
    url: string;
    permissions: string[];
  }

  const commonElements: CommonElementModel[] = [
    {
      name: t('common.auditLog'),
      url: 'audit-log',
      permissions: ['auditlogs_read'],
    },
    {
      name: t('common.users'),
      url: 'users',
      permissions: ['users_read'],
    },
    {
      name: t('common.personas'),
      url: 'personas',
      permissions: ['personas_read'],
    },
  ];

  return (
    <UncontrolledDropdown direction="down" className="mr-2">
      <DropdownToggle nav className="p-2">
        <i className="fas fa-angle-down fa-lg" />
      </DropdownToggle>
      <DropdownMenu right>
        {commonElements.map((el: CommonElementModel) => (
          <CheckPermission key={el.name} variant="displayIf" permissions={el.permissions}>
            <DropdownItem tag="a" href={`#/${el.url}`}>
              {el.name}
            </DropdownItem>
          </CheckPermission>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default CommonElementsDropdown;
