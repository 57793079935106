import { StateModel } from 'src/shared/models';
import {
  GET_SURVEYS,
  GET_SURVEYS_ERROR,
  GET_SURVEYS_SUCCESS,
  DELETE_SURVEY,
  DELETE_SURVEY_SUCCESS,
  DELETE_SURVEY_ERROR,
  ADD_TAG_TO_SURVEY_REQUEST,
  ADD_TAG_TO_SURVEY_SUCCESS,
  ADD_TAG_TO_SURVEY_ERROR,
  REMOVE_SURVEY_TAG_REQUEST,
  REMOVE_SURVEY_TAG_SUCCESS,
  REMOVE_SURVEY_TAG_ERROR,
} from '../actions/surveys.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const surveysReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case GET_SURVEYS:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_SURVEYS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload,
      };
    case GET_SURVEYS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };
    case DELETE_SURVEY:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case DELETE_SURVEY_SUCCESS:
      const content = state.data.content.filter(({ id }: any) => id !== payload.surveyId);
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          content,
        },
      };
    case DELETE_SURVEY_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };

    default:
      return state;
  }
};

export const addTagToSurveyReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ADD_TAG_TO_SURVEY_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case ADD_TAG_TO_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case ADD_TAG_TO_SURVEY_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const removeSurveyTagReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case REMOVE_SURVEY_TAG_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case REMOVE_SURVEY_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case REMOVE_SURVEY_TAG_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
