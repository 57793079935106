import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Label, Input, Form, FormGroup, Alert } from 'reactstrap';
import ReactTooltip from 'react-tooltip';

import addToast from '../../../shared/utils/notification.util';

import { FaqDatasetUploadModel } from '../../../redux/actions/faq-dataset.action';
import { PlatformModel, Extensions } from 'src/shared/models';
import DragAndDrop from 'src/shared/components/form-inputs/DragAndDrop';
import Spinner from '../../../shared/components/Spinner';

import '../FAQ.scss';

interface Props {
  confirm: Function;
  faqDataset: any;
  activePlatforms: PlatformModel[];
}

const FAQUpload: React.FC<Props> = ({ faqDataset, activePlatforms, confirm }) => {
  const { t } = useTranslation();
  const { id: appId } = useParams();

  const [formData, setFormData] = useState<FaqDatasetUploadModel>({
    url: '',
    fieldDelimiter: ',',
    keywordDelimiter: ',',
    quote: '"',
    escape: '"',
    updateMethod: 'REPLACE',
  });
  const [platform, setPlatform] = useState<string>('');
  const [file, setFile] = useState<File>();
  const [fileError, setFileError] = useState<string>();

  useEffect(() => {
    if (activePlatforms.length > 0) {
      setPlatform(activePlatforms[0].value);
    }
  }, [activePlatforms]);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePlatformChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    setPlatform(value);
  };

  const handleUpdateMethodChange = (event: any) => {
    setFormData({ ...formData, updateMethod: event.target.value });
  };

  const handleUpload = () => {
    if (!file) {
      return addToast('error', `${t('errors.noDatasetSelected')}`);
    }

    confirm(
      appId,
      {
        ...formData,
      },
      platform,
      file,
    );
  };

  return (
    <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
      <Alert color="primary">{t('dataset.faqInfo')}</Alert>
      <Row>
        <Col lg="4">
          <fieldset className="border p-3" style={{ minHeight: 240 }}>
            <legend className="col-form-label w-auto">{t('dataset.dataset')}</legend>

            <FormGroup>
              <DragAndDrop
                acceptedExt={Extensions.CSV}
                setFile={setFile}
                file={file}
                setFileError={setFileError}
                fileError={fileError}
              />
            </FormGroup>
          </fieldset>
        </Col>

        <Col lg="4">
          <fieldset className="border p-3">
            <legend className="col-form-label w-auto">{t('dataset.characters')}</legend>

            <Row>
              <Col lg="6" md="6">
                <FormGroup>
                  <Label for="fieldDelimiter">
                    <div className="info_box">
                      {t('dataset.fieldDelimiter')}
                      <Button className="info_btn" data-tip data-for="registerTip2">
                        i
                      </Button>

                      <ReactTooltip id="registerTip2" place="top" effect="solid">
                        {t('faqDocuments.Field')}
                      </ReactTooltip>
                    </div>
                  </Label>

                  <Input
                    name="fieldDelimiter"
                    type="text"
                    value={formData.fieldDelimiter}
                    onChange={handleChange}
                    disabled={faqDataset.loading}
                  />
                </FormGroup>
              </Col>
              <Col lg="6" md="6">
                <FormGroup>
                  <Label for="keywordDelimiter">
                    <div className="info_box">
                      {t('dataset.keywordDelimiter')}
                      <Button className="info_btn" data-tip data-for="registerTip3">
                        i
                      </Button>

                      <ReactTooltip id="registerTip3" place="right" effect="solid">
                        {t('faqDocuments.Keyword')}
                      </ReactTooltip>
                    </div>
                  </Label>

                  <Input
                    name="keywordDelimiter"
                    type="text"
                    value={formData.keywordDelimiter}
                    onChange={handleChange}
                    disabled={faqDataset.loading}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="6" md="6">
                <FormGroup>
                  <Label for="Quote">
                    <div className="info_box">
                      {t('dataset.quote')}
                      <Button className="info_btn" data-tip data-for="registerTip4">
                        i
                      </Button>

                      <ReactTooltip id="registerTip4" place="top" effect="solid">
                        {t('faqDocuments.Quote')}
                      </ReactTooltip>
                    </div>
                  </Label>

                  <Input
                    name="quote"
                    type="text"
                    value={formData.quote}
                    onChange={handleChange}
                    disabled={faqDataset.loading}
                  />
                </FormGroup>
              </Col>
              <Col lg="6" md="6">
                <FormGroup>
                  <Label for="EscapeCharacter">
                    <div className="info_box">
                      {t('dataset.escape')}
                      <Button className="info_btn" data-tip data-for="registerTip5">
                        i
                      </Button>

                      <ReactTooltip id="registerTip5" place="top" effect="solid">
                        {t('faqDocuments.Escape')}
                      </ReactTooltip>
                    </div>
                  </Label>

                  <Input
                    name="escape"
                    type="text"
                    value={formData.escape}
                    onChange={handleChange}
                    disabled={faqDataset.loading}
                  />
                </FormGroup>
              </Col>
            </Row>
          </fieldset>
        </Col>

        <Col lg="4">
          <fieldset className="border p-3">
            <legend className="col-form-label w-auto">{t('common.settings')}</legend>

            <Col>
              <FormGroup>
                <Label for="platform">{t('common.platform')}</Label>
                <Input
                  type="select"
                  name="platform"
                  id="platform"
                  value={platform}
                  onChange={handlePlatformChange}
                >
                  {activePlatforms.length &&
                    activePlatforms.map((platform: PlatformModel) => {
                      return (
                        <option key={platform.value} value={platform.value}>
                          {platform.label}
                        </option>
                      );
                    })}
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="updateMethod">{t('dataset.method')}</Label>
                <Input
                  type="select"
                  name="updateMethod"
                  id="updateMethod"
                  value={formData.updateMethod}
                  onChange={handleUpdateMethodChange}
                >
                  <option value="REPLACE">{t('dataset.replace')}</option>
                </Input>
              </FormGroup>
            </Col>
          </fieldset>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end align-items-center mt-3">
          <Spinner loading={faqDataset.loading} className="mr-2" />
          <Button
            color="primary"
            disabled={faqDataset.loading || !!fileError}
            onClick={handleUpload}
          >
            {t('common.upload')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = (state: any) => ({
  faqDataset: state.faqDataset,
});

export default connect(mapStateToProps, {})(FAQUpload);
