import React, { FC } from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { SelectModel } from 'src/shared/models/common.model';
import ReactSelect, { ReactSelectProps } from 'src/shared/components/form-inputs/ReactSelect';

interface ConfigurationSelectProps extends ReactSelectProps {
  name: string;
  configuratonOptions: SelectModel[];
}

const ConfigurationSelect: FC<ConfigurationSelectProps> = ({
  name,
  configuratonOptions,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Col md="12" lg="4">
      <FormGroup>
        <Label for={`configuration.${name}`}>{t(`importExport.${name}`)}</Label>
        <ReactSelect name={`configuration.${name}`} options={configuratonOptions} {...props} />
      </FormGroup>
    </Col>
  );
};

export default ConfigurationSelect;
