import { StateModel } from '../../shared/models/default-state.model';

import {
  GET_SUBSCRIBING_LISTS_REQUEST,
  GET_SUBSCRIBING_LISTS_SUCCESS,
  GET_SUBSCRIBING_LISTS_ERROR,
  CREATE_SUBSCRIBING_LIST_REQUEST,
  CREATE_SUBSCRIBING_LIST_SUCCESS,
  CREATE_SUBSCRIBING_LIST_ERROR,
  UPDATE_SUBSCRIBING_LIST_REQUEST,
  UPDATE_SUBSCRIBING_LIST_SUCCESS,
  UPDATE_SUBSCRIBING_LIST_ERROR,
  UPDATE_SUBSCRIBING_LIST_CLEAR_ERROR,
  DELETE_SUBSCRIBING_LIST_REQUEST,
  DELETE_SUBSCRIBING_LIST_SUCCESS,
  DELETE_SUBSCRIBING_LIST_ERROR,
  GET_SUBSCRIBING_LIST_REQUEST,
  GET_SUBSCRIBING_LIST_SUCCESS,
  GET_SUBSCRIBING_LIST_ERROR,
  GET_SUBSCRIBERS_REQUEST,
  GET_SUBSCRIBERS_SUCCESS,
  GET_SUBSCRIBERS_ERROR,
  DELETE_SUBSCRIBER_REQUEST,
  DELETE_SUBSCRIBER_SUCCESS,
  DELETE_SUBSCRIBER_ERROR,
  EXPORT_SUBSCRIBERS_REQUEST,
  EXPORT_SUBSCRIBERS_SUCCESS,
  EXPORT_SUBSCRIBERS_ERROR,
  GET_TARGET_MESSAGING_PLATFORMS_REQUEST,
  GET_TARGET_MESSAGING_PLATFORMS_SUCCESS,
  GET_TARGET_MESSAGING_PLATFORMS_ERROR,
  SEND_MESSAGE_TO_SUBSCRIBERS_REQUEST,
  SEND_MESSAGE_TO_SUBSCRIBERS_SUCCESS,
  SEND_MESSAGE_TO_SUBSCRIBERS_ERROR,
  ADD_TAG_TO_SUBSCRIBER_REQUEST,
  ADD_TAG_TO_SUBSCRIBER_REQUEST_SUCCESS,
  ADD_TAG_TO_SUBSCRIBER_REQUEST_ERROR,
  REMOVE_SUBSCRIBER_TAG_REQUEST,
  REMOVE_SUBSCRIBER_TAG_REQUEST_SUCCESS,
  REMOVE_SUBSCRIBER_TAG_REQUEST_ERROR,
} from '../actions/subscribing-lists.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export const subscribingListsReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_SUBSCRIBING_LISTS_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_SUBSCRIBING_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_SUBSCRIBING_LISTS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const subscribingListReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_SUBSCRIBING_LIST_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_SUBSCRIBING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_SUBSCRIBING_LIST_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const subscribingListCreateReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CREATE_SUBSCRIBING_LIST_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case CREATE_SUBSCRIBING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        data: action.payload,
      };

    case CREATE_SUBSCRIBING_LIST_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const subscribingListUpdateReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case UPDATE_SUBSCRIBING_LIST_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case UPDATE_SUBSCRIBING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case UPDATE_SUBSCRIBING_LIST_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    case UPDATE_SUBSCRIBING_LIST_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const subscribingListDeleteReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DELETE_SUBSCRIBING_LIST_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case DELETE_SUBSCRIBING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case DELETE_SUBSCRIBING_LIST_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const subscribersReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_SUBSCRIBERS_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_SUBSCRIBERS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const subscriberDeleteReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DELETE_SUBSCRIBER_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case DELETE_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };

    case DELETE_SUBSCRIBER_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const exportSubscribersReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case EXPORT_SUBSCRIBERS_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case EXPORT_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case EXPORT_SUBSCRIBERS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const targetMessagingPlatformsReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_TARGET_MESSAGING_PLATFORMS_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_TARGET_MESSAGING_PLATFORMS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_TARGET_MESSAGING_PLATFORMS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const sendMessageToSubscribersReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SEND_MESSAGE_TO_SUBSCRIBERS_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case SEND_MESSAGE_TO_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case SEND_MESSAGE_TO_SUBSCRIBERS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const addTagToSubscribersReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ADD_TAG_TO_SUBSCRIBER_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case ADD_TAG_TO_SUBSCRIBER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case ADD_TAG_TO_SUBSCRIBER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const removeSubscribersTagReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case REMOVE_SUBSCRIBER_TAG_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case REMOVE_SUBSCRIBER_TAG_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case REMOVE_SUBSCRIBER_TAG_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
