// src/shared/services/http.service.ts

import axios, { AxiosRequestConfig } from 'axios';
import qs from 'query-string';
import i18n from '../../i18n';

import { HTTPMethods } from '../models/http-methods.model';

import { logout } from '../../redux/actions/auth.action';
import { setToken, getToken } from '../../redux/services/token.service';
import addToast from '../utils/notification.util';

import store from '../../redux';
import { settings } from '../settings';

export interface HTTPRequestModel {
  payload?: any;
  data?: any;
  params?: any;
  headers?: any;
  directRequest?: boolean;
  requestConfig?: AxiosRequestConfig;
}

async function request(
  method: HTTPMethods = HTTPMethods.GET,
  path: string,
  config: HTTPRequestModel = {},
) {
  const { headers, data, requestConfig } = config;
  let { params } = config;

  const token = getToken();

  const {
    REACT_APP_AUTH_API_BASE_URL,
    REACT_APP_AUTH_API_BASE_PATH,
    REACT_APP_DATA_API_BASE_URL,
    REACT_APP_DATA_API_BASE_PATH,
  } = settings;

  const baseURL = /^\/(login|user|user-profile|permissions|change-password)/.test(path)
    ? `${REACT_APP_AUTH_API_BASE_URL}/${REACT_APP_AUTH_API_BASE_PATH}`
    : `${REACT_APP_DATA_API_BASE_URL}/${REACT_APP_DATA_API_BASE_PATH}`;

  // IE11 'cached-data' probléma megoldására.
  // Így a GET kérések eredményeit nem a cache-ből kapja.
  if (method === HTTPMethods.GET) {
    params = { ...params, time: Date.now() };
  }

  let requestOptions: AxiosRequestConfig = {
    ...requestConfig,
    baseURL,
    url: path,
    method,
    params,
    data,
    paramsSerializer: (params: any) => {
      return qs.stringify(params);
    },
    headers: token ? {
      Authorization: `Bearer ${token}`,
      'X-User-Object': localStorage.getItem('userObject'),
      ...headers,
    } : {
      ...headers,
    }
  };

  if (/^http.+/.test(path)) {
    requestOptions = {
      ...requestOptions,
      headers: {
        ...headers,
      },
      baseURL: '',
    };
  }

  return axios
    .request(requestOptions)
    .then((response: any) => {
      if (response.data.token) {
        setToken(response.data.token);
      }

      if (response && response.data && response.data.message) {
        addToast('success', response.data.message);
      }

      return response.data;
    })
    .catch((err: any) => {
      const { response, message } = err;
      const { dispatch } = store;
      // message !== 'Network Error' - FAQ Feltöltés 504 miatt...
      if (!response && message !== 'Network Error') {
        // Possible 401
        dispatch(logout());
      }

      if (response && response.status === 401) {
        dispatch(logout());
        window.location.hash = '#/login';
        return;
      }

      if (response?.data?.message) {
        const nameOriginal = `${response?.data?.data?.name}`;
        const name = i18n.t(`${`common.${response?.data?.data?.name}`}`);
        const entity = i18n.t(`${`common.${response?.data?.data?.entity}`}`);
        const errorType = i18n.t(`${`common.${response?.data?.data?.errorType}`}`);
        const dataMessage = i18n.t(`${`common.${response?.data?.data?.message}`}`);

        const key = i18n.t(`${`common.${response?.data?.data?.properties?.key}`}`);
        const message = i18n.t(`${`common.${response?.data?.data?.properties?.message}`}`);

        addToast(
          'error',
          i18n.t(`${`errors.${response.data.message}`}`, {
            ...response.data?.data,
            ...response.data?.data?.properties,
            name,
            nameOriginal,
            entity,
            key,
            message,
            dataMessage,
            errorType,
          }),
        );
      }

      throw err;
    });
}

export const get = (path: string, config?: HTTPRequestModel) => {
  return request(HTTPMethods.GET, path, config);
};

export const post = (path: string, config?: HTTPRequestModel) => {
  return request(HTTPMethods.POST, path, config);
};

export const put = (path: string, config?: HTTPRequestModel) => {
  return request(HTTPMethods.PUT, path, config);
};

export const del = (path: string, config?: HTTPRequestModel) => {
  return request(HTTPMethods.DELETE, path, config);
};

export const patch = (path: string, config?: HTTPRequestModel) => {
  return request(HTTPMethods.PATCH, path, config);
};
