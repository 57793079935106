import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';
import { Formik, Form, FormikValues } from 'formik';

import { SelectModel } from 'src/shared/models/common.model';
import { CustomListItemModel } from 'src/shared/models/custom-lists.model';

import { customListSchema } from 'src/shared/schemas/validation.schema';

import {
  createCustomListItem,
  updateCustomListItem,
} from 'src/redux/actions/custom-list-items.action';

import TextField from 'src/shared/components/form-inputs/TextField';
import ReactSelect from 'src/shared/components/form-inputs/ReactSelect';
import Spinner from 'src/shared/components/Spinner';

interface Props {
  toggle: Function;
  isOpen: boolean;
  event: string;
  customListItem: CustomListItemModel | undefined;
  setSelectedCustomListItem: Function;
  selectOptions: SelectModel[];
  createCustomListItem: Function;
  updateCustomListItem: Function;
}

const CustomListModal: React.FC<Props> = ({
  toggle,
  isOpen,
  event,
  customListItem,
  setSelectedCustomListItem,
  selectOptions,
  createCustomListItem,
  updateCustomListItem,
}) => {
  const { t } = useTranslation();
  const { appId, customListId } = useParams();

  const title =
    event === 'create'
      ? 'customListItems.createCustomListItem'
      : 'customListItems.editCustomListItem';
  const submitButtonText = event === 'create' ? 'common.create' : 'common.edit';

  const handleSubmit = async (values: FormikValues) => {
    const { name, externalId, parent } = values;

    const newCustomListItemValues = {
      name,
      externalId: !externalId?.length ? null : externalId || null,
      parentId: Array.isArray(parent) ? null : parent?.value ? parent.value : null,
    };

    if (customListItem) {
      await updateCustomListItem(appId, customListId, customListItem.id, newCustomListItemValues);
    } else {
      await createCustomListItem(appId, customListId, newCustomListItemValues);
    }

    handleToggle();
  };

  const handleToggle = () => {
    setSelectedCustomListItem(undefined);
    toggle();
  };

  const getInitialFormValues = () => {
    return {
      name: customListItem?.name || '',
      externalId: customListItem?.externalId || null,
      parent:
        customListItem?.parent?.name && customListItem?.parent?.id
          ? { label: customListItem.parent.name, value: customListItem.parent.id }
          : null,
    };
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{t(title)}</ModalHeader>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={getInitialFormValues()}
        validationSchema={customListSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="name">{t('common.name')}</Label>
                    <TextField name="name" />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="externalId">{t('common.externalId')}</Label>
                    <TextField name="externalId" />
                  </FormGroup>
                </Col>
              </Row>

              {selectOptions.length > 1 && (
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label for="parent">{t('common.parent')}</Label>
                      <ReactSelect
                        name="parent"
                        options={selectOptions.filter(o => o.value !== customListItem?.id)}
                        defaultValue={selectOptions.find(
                          o => o.value === customListItem?.parent?.id,
                        )}
                        isClearable
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </ModalBody>

            <ModalFooter>
              <Spinner loading={isSubmitting} />

              <Button type="submit" color="success" disabled={isSubmitting}>
                {t(submitButtonText)}
              </Button>

              <Button color="secondary" onClick={() => handleToggle()}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapDispatchToProps = {
  createCustomListItem,
  updateCustomListItem,
};

export default connect(null, mapDispatchToProps)(CustomListModal);
