import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Card, CardBody, Alert } from 'reactstrap';
import { withTranslation, WithTranslation } from 'react-i18next';

import { getApplication, ApplicationModel } from 'src/redux/actions/applications.action';
import { getSignatureForUpload, SignatureForUploadModel } from 'src/redux/actions/upload.action';
import { uploadFaqDataset, FaqDatasetUploadModel } from 'src/redux/actions/faq-dataset.action';
import { getPlatforms } from 'src/redux/actions/platforms.action';

import { uploadFile } from 'src/shared/services/upload.service';
import { getActivePlatformObjects } from 'src/shared/utils/platforms.util';
import { parseFileExtension } from 'src/shared/utils/parse.util';
import { UploadedDocumentType, PlatformModel } from 'src/shared/models';
import { UploadedDocuments, PageHeader } from 'src/shared/components';

import FAQUpload from './components/FAQUpload';

interface Props extends RouteComponentProps<{ id: string; listId: string }>, WithTranslation {
  getApplication: Function;
  application: ApplicationModel;
  faqDataset: any;
  uploadFaqDataset: Function;
  signatureForUpload: SignatureForUploadModel;
  getSignatureForUpload: Function;
  getPlatforms: Function;
  platforms: string[];
}

interface State {
  activePlatforms: PlatformModel[];
  uploadMethod: string;
  showMessage: boolean;
  updateList: boolean;
}

class FAQ extends Component<Props, State> {
  state: State = {
    activePlatforms: [],
    uploadMethod: 'PUT',
    showMessage: false,
    updateList: false,
  };

  componentDidMount = async () => {
    const { id } = this.props.match.params;

    if (!this.props.application.id) {
      await this.props.getApplication(id);
    }

    await this.props.getPlatforms(this.props.application.id);

    const activePlatformObjects: PlatformModel[] = getActivePlatformObjects(this.props.platforms);
    this.setActivePlatforms(activePlatformObjects);
  };

  setActivePlatforms(activePlatformObjects: PlatformModel[]) {
    this.setState(prevState => ({
      activePlatforms: [...prevState.activePlatforms, ...activePlatformObjects],
    }));
  }

  uploadFaqDataset = async (
    applicationId: number,
    dataset: FaqDatasetUploadModel,
    platform: any,
    file?: File,
  ): Promise<any> => {
    const payload: any = {
      ...dataset,
    };

    if (file) {
      const fileExtension = parseFileExtension(file.name);

      if (fileExtension) {
        await this.props.getSignatureForUpload(fileExtension, this.state.uploadMethod);
        await uploadFile(file, this.props.signatureForUpload);
        payload.url = this.props.signatureForUpload.downloadUrl;
      }
    }

    if (payload.url) {
      await this.props.uploadFaqDataset(applicationId, platform, payload);

      this.setState(
        {
          showMessage: true,
          updateList: true,
        },
        () => {
          this.setState({ updateList: false });
        },
      );
    }

    // if (!this.props.faqDataset.error) {
    //   toast.success('Your FAQ dataset has been successfully uploaded');
    // }
  };

  render() {
    const { t } = this.props;
    const { activePlatforms, showMessage, updateList } = this.state;

    return (
      <Fragment>
        <div className="animated fadeIn">
          <PageHeader title={t('common.faqDatasetUpload')} />

          <Card>
            <CardBody className="faq-upload">
              {showMessage && <Alert color="success">{t('dataset.successfulUploadMessage')}</Alert>}

              <FAQUpload confirm={this.uploadFaqDataset} activePlatforms={activePlatforms} />
            </CardBody>
          </Card>

          <UploadedDocuments
            uploadedDocumentType={UploadedDocumentType.FAQ}
            updateList={updateList}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  platforms: state.platforms.data,
  signatureForUpload: state.signatureForUpload.data,
  faqDataset: state.faqDataset.data,
});

const mapDispatchToProps = {
  getApplication,
  uploadFaqDataset,
  getSignatureForUpload,
  getPlatforms,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(FAQ)));
