import { post } from '../../shared/services/http.service';
import { IntentStatisticsQueryParams } from 'src/shared/models/query-params.model';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';

export const getIntentStatistics = async (
  applicationId: number,
  params: IntentStatisticsQueryParams,
  filters: DynamicGroupByModel,
) => {
  if (typeof params.filterByValue === 'string' && !params.filterByValue) {
    // eslint-disable-next-line no-param-reassign
    delete params.filterByValue;
  }
  return post(`/applications/${applicationId}/analytics/intent-popularity`, {
    params,
    data: {
      filters: filters,
    },
  });
};

export const exportIntentStatistics = async (
  applicationId: number,
  params: IntentStatisticsQueryParams,
  filters: DynamicGroupByModel,
) => {
  if (typeof params.filterByValue === 'string' && !params.filterByValue) {
    // eslint-disable-next-line no-param-reassign
    delete params.filterByValue;
  }
  return post(`/applications/${applicationId}/analytics/intent-popularity/export/csv`, {
    params,
    data: {
      filters: filters,
    },
  });
};
