import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CheckPermission from '../../../shared/components/CheckPermission';

const NewPositionButton = () => {
  const history = useHistory();
  const { appId } = useParams<{ appId: string }>();
  const { t } = useTranslation();

  return (
    <CheckPermission variant="enableIf" permissions={['position_position_create']}>
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => history.push(`/application/${appId}/recruiter/positions/new`)}
      >
        {t('positions.newPosition')}
      </button>
    </CheckPermission>
  );
};

export default NewPositionButton;
