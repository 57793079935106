import React, { Fragment } from 'react';
import moment from 'moment';
import i18n from 'src/i18n';
import { ListElementModel } from 'src/redux/reducers/list-elements.reducer';
import { renderDifference } from 'src/views/AuditLog/helpers/renderHelpers';

interface Props {
  updatedDocument: ListElementModel;
  savedDocument: ListElementModel;
}

const UpdatedDocument: React.FC<Props> = ({
  updatedDocument,
  savedDocument,
}) => {
  return (
    <Fragment>
      <p className="font-weight-bold">
        {i18n.t('auditLog.detailsOfUpdatedDocument')}
      </p>

      <table className="table table-sm">
        <tbody>
          <tr>
            <td>
              {i18n.t('common.id')} / {i18n.t('common.name')}
            </td>
            <td>
              {updatedDocument.id} /{' '}
              {renderDifference(updatedDocument.name, savedDocument.name)}
            </td>
          </tr>
          <tr>
            <td>{i18n.t('common.created')}</td>
            <td>{moment(updatedDocument.createdAt).format('LLL')}</td>
          </tr>
          <tr>
            <td>{i18n.t('common.updated')}</td>
            <td>
              {updatedDocument.updatedAt &&
                renderDifference(
                  moment(updatedDocument.updatedAt).format('LLL'),
                  moment(savedDocument.updatedAt).format('LLL'),
                )}
            </td>
          </tr>
          <tr>
            <td>{i18n.t('foldersAndDocuments.shortDescription')}</td>
            <td>
              {renderDifference(
                updatedDocument.shortDescription,
                savedDocument.shortDescription,
              )}
            </td>
          </tr>
          <tr>
            <td>{i18n.t('common.description')}</td>
            <td>
              {renderDifference(
                updatedDocument.description,
                savedDocument.description,
              )}
            </td>
          </tr>
          <tr>
            <td>{i18n.t('foldersAndDocuments.fileAttachment')}</td>
            <td>
              {renderDifference(updatedDocument.fileUrl, savedDocument.fileUrl)}
            </td>
          </tr>
          <tr>
            <td>{i18n.t('foldersAndDocuments.imageAttachment')}</td>
            <td>
              {renderDifference(
                updatedDocument.imageUrl,
                savedDocument.imageUrl,
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

export default UpdatedDocument;
