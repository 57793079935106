export enum ImportConfigurationTypes {
  EMPLOYEE = 'EMPLOYEE',
  DYNAMIC_FAQ = 'DYNAMIC_FAQ',
  DYNAMIC_MENU = 'DYNAMIC_MENU',
  DYNAMIC_CONVFLOW = 'DYNAMIC_CONVFLOW',
  SHORTLIST = 'SHORTLIST',
  CONVFLOW_VARIABLES = 'CONVFLOW_VARIABLES',
}

export interface importConfigurationSelectOptionModel {
  label: string;
  value: string;
}

export const ImportConfigurationTypesForSelect: importConfigurationSelectOptionModel[] = [
  { label: ImportConfigurationTypes.EMPLOYEE, value: ImportConfigurationTypes.EMPLOYEE },
  { label: ImportConfigurationTypes.DYNAMIC_FAQ, value: ImportConfigurationTypes.DYNAMIC_FAQ },
  { label: ImportConfigurationTypes.DYNAMIC_MENU, value: ImportConfigurationTypes.DYNAMIC_MENU },
  {
    label: ImportConfigurationTypes.DYNAMIC_CONVFLOW,
    value: ImportConfigurationTypes.DYNAMIC_CONVFLOW,
  },
  {
    label: ImportConfigurationTypes.SHORTLIST,
    value: ImportConfigurationTypes.SHORTLIST,
  },
  {
    label: ImportConfigurationTypes.CONVFLOW_VARIABLES,
    value: ImportConfigurationTypes.CONVFLOW_VARIABLES,
  },
];

export interface BaseImportConfigurationModel {
  title: string;
  type: ImportConfigurationTypes;
  configuration: string;
}

export interface ImportConfigurationModel extends BaseImportConfigurationModel {
  id: number;
}
