import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Card, CardBody } from 'reactstrap';

import {
  ChangelogModel,
  AddedFeaturesModel,
  RemovedFeaturesModel,
} from '../../shared/models/changelog.model';

import styles from './About.module.scss';
import changelog from './data/changelog.json';



const renderAddedFeatures = (features: AddedFeaturesModel[]) => {



  return features.map((feature: AddedFeaturesModel) => {

    
    return (
        <div className={styles.categoryContainer} key={feature.name}>
          <div className={styles.badgeContainer}>
            <div className={styles.newBadge}>New</div>
          </div>
          <div className={styles.contentContainer}>
            <p className={styles.name}>{feature.name}</p>
            <p className={styles.description}>{feature.description}</p>
          </div>
        </div>
    );
  });
};

const renderRemovedFeatures = (features: RemovedFeaturesModel[]) => {
  return features.map((feature: RemovedFeaturesModel) => {
    return (
      <div className={styles.categoryContainer} key={feature.name}>
        <div className={styles.badgeContainer}>
          <div className={styles.removedBadge}>Removed</div>
        </div>
        <div className={styles.contentContainer}>
          <p className={styles.name}>{feature.name}</p>
          <p className={styles.description}>{feature.reason}</p>
        </div>
      </div>
    );
  });
};

const renderFixedBugs = (bugs: string[]) => {
  return bugs.map((bug: string) => {
    return (
      <div className={styles.categoryContainer} key={bug}>
        <div className={styles.badgeContainer}>
          <div className={styles.fixedBadge}>Fixed</div>
        </div>
        <div className={styles.contentContainer}>
          <p className={styles.name}>{bug}</p>
        </div>
      </div>
    );
  });
};

const About = () => {
  const { t } = useTranslation();
  
  return (
    <Container className="animated fadeIn">
      <h5 className="mb-4">{t('common.aboutDashboard')}</h5>
      <Card>
        <CardBody>
          <div className={styles.changelogContainer}>
            <h1>Changelog</h1>
            {changelog.map((rls: ChangelogModel) => {
              return (
                <div className={styles.changelog} key={rls.version}>
                  <h2 className={styles.version}>{`Version ${rls.version}`}</h2>
                  <div className={styles.changelogContent}>
                    {renderAddedFeatures(rls.addedFeatures)}
                    {renderRemovedFeatures(rls.removedFeatures)}
                    {renderFixedBugs(rls.fixedBugs)}
                  </div>
                </div>
              );
            })}
          </div>
        </CardBody>
      </Card>
    </Container>
  );
};

export default About;
