import React, { Component, Fragment } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown } from 'reactstrap';

import {
  // AppAsideToggler,
  AppNavbarBrand,
  AppSidebarToggler,
  // @ts-ignore
} from '@coreui/react';

import {
  getApplication,
  getApplications,
  ApplicationModel,
} from '../../redux/actions/applications.action';
import { UserModel } from '../../redux/reducers/user.reducer';
import { logout } from '../../redux/actions/auth.action';
import { setLanguage } from '../../redux/actions/language.action';

import CommonElementsDropdown from './CommonElementsDropdown';
import LanguageSelectorDropdown from './LanguageSelectorDropdown';

import './DefaultHeader.scss';

import logoFull from '../../assets/img/brand/chatboss-2.svg';
import logoMinimized from '../../assets/img/brand/chatboss-3.svg';
import avatar from '../../assets/img/avatars/default.png';
import moment from 'moment';

interface Props extends RouteComponentProps, WithTranslation {
  application: ApplicationModel;
  applications: ApplicationModel[];
  user: UserModel;
  language: string;
  getApplication: Function;
  getApplications: Function;
  logout: Function;
  setLanguage: Function;
  onSetLanguage: Function;
}

class DefaultHeader extends Component<Props> {
  async componentDidMount() {
    const { application, applications } = this.props;
    const { pathname } = this.props.location;
    const date = moment().subtract(7, 'd').utc().startOf('day').toISOString();

    if (!application.id) {
      await this.props.getApplications(date);
    }

    if (applications.length === 1 && pathname === '/applications') {
      this.showApplication(applications[0]);
    }
  }

  onLogoutClick = () => {
    this.props.logout();
  };

  onApplicationClick(application: ApplicationModel) {
    this.showApplication(application);
  }

  async showApplication(application: ApplicationModel) {
    const { id } = application;

    await this.props.getApplication(id);
    this.props.history.push(`/application/${id}/summary`);
  }

  navigateTo(to: string) {
    this.props.history.push(`/${to}`);
  }

  render() {
    const { user, application, applications, language, setLanguage, onSetLanguage, t } = this.props;

    const setLanguageWrapper = (language: string) => {
      onSetLanguage();
      return setLanguage(language);
    };

    return (
      <Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          className="d-none d-lg-flex"
          full={{
            src: logoFull,
            width: '90%',
            height: 'auto',
            alt: 'Chatboss Logo',
          }}
          minimized={{
            src: logoMinimized,
            width: '80%',
            height: '80%',
            alt: 'Chatboss Logo Minimized',
          }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="mr-auto" navbar>
          <UncontrolledDropdown className="application-select" nav direction="down">
            <DropdownToggle className="application-select-toggle minimzed">
              <i className="fas fa-layer-group" />
              <i className="fas fa-chevron-down float-right" />
            </DropdownToggle>
            <DropdownToggle className="application-select-toggle">
              {application.name || `${t('common.selectAnApp')}`}
              <i className="fas fa-chevron-down float-right" />
            </DropdownToggle>
            <DropdownMenu className="application-dropdown-menu">
              {applications.map((app: ApplicationModel) => {
                return (
                  <DropdownItem
                    key={app.id}
                    className="application-dropdown-item"
                    onClick={() => this.onApplicationClick(app)}
                  >
                    {app.name}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        <Nav className="ml-auto" navbar>
          <CommonElementsDropdown />
          <LanguageSelectorDropdown language={language} setLanguage={setLanguageWrapper} />

          <UncontrolledDropdown style={{ marginRight: 20 }} nav direction="down">
            <DropdownToggle nav>
              <img
                src={user.avatar || avatar}
                className="img-avatar border border-secondary"
                alt="User Avatar"
              />
            </DropdownToggle>
            <DropdownMenu right style={{ right: 17.5 }}>
              <DropdownItem header>
                <span className="h6 font-weight-bold">{`${user.firstName} ${user.lastName}`}</span>
                <span className="d-block">{user.userGroupName}</span>
              </DropdownItem>
              <DropdownItem onClick={() => this.navigateTo('account')}>
                <i className="fas fa-user-cog" />
                {t('common.account')}
              </DropdownItem>
              <DropdownItem onClick={this.onLogoutClick}>
                <i className="fas fa-sign-out-alt" />
                {t('common.logout')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* <AppAsideToggler display="md" defaultOpen /> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  applications: state.applications.data,
  user: state.user.data,
  language: state.language.data,
});

const mapDispatchToProps = {
  getApplication,
  getApplications,
  logout,
  setLanguage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(DefaultHeader)));
