import React, { useEffect, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Button } from 'reactstrap';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { DynamicEmployeeModel, PageQueryParams, QueryParams } from 'src/shared/models';
import { List } from 'src/shared/components';

import { getDynamicEmployees } from 'src/redux/actions/dynamic-employee.action';
import EmployeeImportModal from './forms/EmployeeImportModal';
import { getSignedUrlAndOpenFile } from 'src/shared/services/upload.service';

interface Props {
  getDynamicEmployees: (appId: string, queryParams: PageQueryParams) => void;
  getApplication: Function;
  application: ApplicationModel;
  dynamicEmployees: DynamicEmployeeModel[];
  numberOfDynamicEmployees: number;
  dynamicEmployeesLoading: boolean;
  dynamicEmployeesLoaded: boolean;
  dynamicEmployeesError: boolean;
}

const EmplyeeImport: FC<Props> = ({
  getDynamicEmployees,
  getApplication,
  application,
  dynamicEmployees,
  numberOfDynamicEmployees,
  dynamicEmployeesLoading,
  dynamicEmployeesLoaded,
  dynamicEmployeesError,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [queryParamsDef, setQueryParamsDef] = useState<QueryParams>({
    page: 0,
    size: 20,
    limit: 20,
  });

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  const fetchDynamicEmployees = (queryParams: QueryParams) => {
    setQueryParamsDef(queryParams);
    getDynamicEmployees(appId, queryParams);
  };

  const refreshDynamicEmployees = () => {
    getDynamicEmployees(appId, queryParamsDef);
  };

  return (
    <div className="animated fadeIn">
      <List
        useCardContent
        data={dynamicEmployees}
        totalElements={numberOfDynamicEmployees}
        loaded={dynamicEmployeesLoaded}
        loading={dynamicEmployeesLoading}
        error={dynamicEmployeesError}
        fetchData={fetchDynamicEmployees}
        title={t('employee.importEmployees')}
        createPermission="import_create"
        createModal={(isModalOpen, setIsModalOpen) => (
          <EmployeeImportModal
            isOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            onSaved={refreshDynamicEmployees}
          />
        )}
        fields={[
          { key: 'id', label: t('common.id') },
          { key: 'url', label: t('common.url'), render: item => item.uploadedDocument.url },
          { key: 'updatedAt', label: t('common.updated'), dateTime: true },
          {
            key: 'configuration',
            label: t('importConfiguration.configuration'),
            render: item => item.configuration.title,
          },
          { key: 'status', label: t('common.status') },
          {
            key: 'download',
            label: t('common.download'),
            render: item => (
              <>
                <Button
                  className="btn-sm float-right"
                  onClick={() => {
                    getSignedUrlAndOpenFile(item.uploadedDocument.url);
                  }}
                >
                  <i className="file-icon far fa-file-alt" />
                </Button>
              </>
            ),
          },
        ]}
        noDataLabel={t('employee.noDynamicEmployees')}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    dynamicEmployees: state.dynamicEmployee.data.content,
    numberOfDynamicEmployees: state.dynamicEmployee.data.totalElements,
    dynamicEmployeesLoading: state.dynamicEmployee.loading,
    dynamicEmployeesLoaded: state.dynamicEmployee.loaded,
    dynamicEmployeesError: state.dynamicEmployee.error,
  };
};

const mapDispatchToProps = {
  getApplication,
  getDynamicEmployees,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmplyeeImport);
