export interface UploadedDocumentModel {
  content: UploadedDocumentContentModel[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export interface UploadedDocumentContentModel {
  id: number;
  applicationId: number;
  type: UploadedDocumentType;
  url: string;
  time: string;
}

export enum UploadedDocumentType {
  FAQ = 'FAQ',
  TRAINING_DATA = 'TRAINING_DATA',
  DYNAMIC_CONFLOW = 'DYNAMIC_CONFLOW',
}
