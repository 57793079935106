import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { ApplicationModel } from 'src/redux/actions/applications.action';
import { getSignedInEmployeeReport } from 'src/redux/actions/employee-reports.action';
import { SignedInEmployeeReport } from 'src/shared/models/employee-reports.model';
import { getListFieldsByConfiguration } from 'src/shared/utils/configuration.util';
import { configurationConstants } from 'src/shared/constants/configuration.constants';
import { List } from 'src/shared/components';

interface Props {
  signedInEmployeeReports: SignedInEmployeeReport[];
  application: ApplicationModel;
  getSignedInEmployeeReport: (appId: string) => void;
}
const SignedInEmployeeReports: FC<Props> = ({
  signedInEmployeeReports,
  application,
  getSignedInEmployeeReport,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  useEffect(() => {
    getSignedInEmployeeReport(appId);
  }, [appId, getSignedInEmployeeReport]);

  const configuredFields = useMemo(() => {
    return getListFieldsByConfiguration(
      application,
      configurationConstants.hropsEmployeeSignedInReportList,
      t,
    );
  }, [application, t]);

  return (
    <div className="animated fadeIn">
      <List
        hidePager
        data={signedInEmployeeReports}
        totalElements={signedInEmployeeReports.length}
        loaded={configuredFields !== undefined}
        loading={configuredFields === undefined}
        noDataLabel={t('employeeReports.noData')}
        fetchData={() => {}}
        fields={configuredFields}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    signedInEmployeeReports: state.employeeReports.data.signedInEmployeeReports,
  };
};

const mapDispatchToProps = {
  getSignedInEmployeeReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignedInEmployeeReports);
