import { StateModel } from '../../shared/models/default-state.model';
import {
  GET_LIST_ELEMENTS_REQUEST,
  GET_LIST_ELEMENTS_SUCCESS,
  GET_LIST_ELEMENTS_ERROR,
  UPDATE_LIST_ELEMENTS_ORDER_REQUEST,
  UPDATE_LIST_ELEMENTS_ORDER_SUCCESS,
  UPDATE_LIST_ELEMENTS_ORDER_ERROR,
} from '../actions/lists.action';
import { ListModel } from './lists.reducer';
import { PaginationAPIModel } from '../../shared/models/pagination.model';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export interface ListElementModel {
  createdAt?: string;
  description?: string;
  shortDescription?: string;
  id?: number;
  name?: string;
  updatedAt?: string;
  simpleList?: ListModel;
  fileUrl?: string;
  imageUrl?: string;
  thumbnail?: string;
  priority?: number;
  slug?: string;
}

export type ImageListElementModel = Omit<ListElementModel, 'id'> & { id: string };

export interface ListElementAPIModel extends PaginationAPIModel {
  content: ListElementModel[];
}

export const listElementsReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_LIST_ELEMENTS_REQUEST:
    case UPDATE_LIST_ELEMENTS_ORDER_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
        error: null,
      };

    case GET_LIST_ELEMENTS_SUCCESS:
    case UPDATE_LIST_ELEMENTS_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };

    case GET_LIST_ELEMENTS_ERROR:
    case UPDATE_LIST_ELEMENTS_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default listElementsReducer;
