import { Dispatch } from 'redux';
import { ApplicantsQueryParams } from '../../shared/models/query-params.model';
import {
  getTalentPoolApplicants as getTalentPoolApplicantsService,
  getTalentPoolApplicant as getTalentPoolApplicantService,
} from '../services/talent-pool.service';

export const GET_TALENT_POOL_APPLICANTS_REQUEST = 'talent-pools/GET_TALENT_POOL_APPLICANTS_REQUEST';
export const GET_TALENT_POOL_APPLICANTS_SUCCESS = 'talent-pools/GET_TALENT_POOL_APPLICANTS_SUCCESS';
export const GET_TALENT_POOL_APPLICANTS_ERROR = 'talent-pools/GET_TALENT_POOL_APPLICANTS_ERROR';

export const GET_TALENT_POOL_APPLICANT_REQUEST = 'talent-pools/GET_TALENT_POOL_APPLICANT_REQUEST';
export const GET_TALENT_POOL_APPLICANT_SUCCESS = 'talent-pools/GET_TALENT_POOL_APPLICANT_SUCCESS';
export const GET_TALENT_POOL_APPLICANT_ERROR = 'talent-pools/GET_TALENT_POOL_APPLICANT_ERROR';

export const CLEAR_TALENT_POOL_APPLICANTS = 'talent-pools/CLEAR_TALENT_POOL_APPLICANTS';

export const getTalentPoolApplicants = (
  applicationId: string,
  queryParams: ApplicantsQueryParams,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TALENT_POOL_APPLICANTS_REQUEST,
    });

    try {
      dispatch({
        type: GET_TALENT_POOL_APPLICANTS_SUCCESS,
        payload: await getTalentPoolApplicantsService(applicationId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_TALENT_POOL_APPLICANTS_ERROR,
        error,
      });
    }
  };
};

export const getTalentPoolApplicant = (applicationId: string, applicantId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TALENT_POOL_APPLICANT_REQUEST,
    });

    try {
      dispatch({
        type: GET_TALENT_POOL_APPLICANT_SUCCESS,
        payload: await getTalentPoolApplicantService(applicationId, applicantId),
      });
    } catch (error) {
      dispatch({
        type: GET_TALENT_POOL_APPLICANT_ERROR,
        error,
      });
    }
  };
};

export const clearTalentPoolApplicants = () => {
  return {
    type: CLEAR_TALENT_POOL_APPLICANTS,
  };
};
