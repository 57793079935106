import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert, Table } from 'reactstrap';
import { FormikValues } from 'formik';

import { useDeepEffect } from 'src/shared/hooks';
import { StateModel } from 'src/shared/models/default-state.model';
import { AttributeValidatorModel } from 'src/shared/models/questionnaire.model';
import {
  createAttributeValidator,
  deleteAttributeValidator,
  getAttributeValidators,
  updateAttributeValidator,
} from 'src/redux/actions/questionnaire.action';
import { getPositions } from 'src/redux/actions/positions.action';
import ConfirmModal from 'src/shared/components/modals/ConfirmModal';
import CheckPermission from 'src/shared/components/CheckPermission';
import Spinner from 'src/shared/components/Spinner';
import ServerError from 'src/shared/components/ServerError';

import RegexPatternsTableHeader from './RegexPatternsTableHeader';
import RegexPatternsTableBody from './RegexPatternsTableBody';
import RegexPatternsModal from './RegexPatternsModal';

interface Props {
  attributeValidators: StateModel;
  getAttributeValidators: Function;
  createAttributeValidator: Function;
  updateAttributeValidator: Function;
  deleteEvent: StateModel;
  deleteAttributeValidator: Function;
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  modalEvent: string;
}

const RegexPatternsTable: React.FC<Props> = ({
  attributeValidators,
  getAttributeValidators,
  createAttributeValidator,
  updateAttributeValidator,
  deleteEvent,
  deleteAttributeValidator,
  isModalOpen,
  setModalOpen,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams();

  const [selectedAttributeValidator, setSelectedAttributeValidator] = useState<
    AttributeValidatorModel
  >();
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  useEffect(() => {
    getPositions(appId);
    /* eslint-disable-next-line */
  }, []);

  useDeepEffect(() => {
    getAttributeValidators(appId);
  }, [appId]);

  useEffect(() => {
    if (!isModalOpen && selectedAttributeValidator) {
      setSelectedAttributeValidator(undefined);
    }
    /* eslint-disable-next-line */
  }, [isModalOpen]);

  useEffect(() => {
    if (!isConfirmModalOpen && selectedAttributeValidator) {
      setSelectedAttributeValidator(undefined);
    }
    /* eslint-disable-next-line */
  }, [isConfirmModalOpen]);

  const handleSubmit = async (values: FormikValues) => {
    await createAttributeValidator(values);
    await getAttributeValidators(appId);

    setModalOpen(false);
  };

  const handleDelete = async () => {
    if (selectedAttributeValidator) {
      await deleteAttributeValidator(selectedAttributeValidator.id);
      await getAttributeValidators(appId);

      setConfirmModalOpen(false);
    }
  };

  return (
    <Fragment>
      {attributeValidators.loading && (
        <div className="d-flex justify-content-center">
          <Spinner size="2x" loading={attributeValidators.loading} />
        </div>
      )}

      {attributeValidators.loaded && !attributeValidators.data?.length && (
        <Alert className="text-center" color="info">
          {t('regexPatterns.noRegexPatternsFound')}
        </Alert>
      )}

      {attributeValidators.error && <ServerError />}

      {attributeValidators.loaded && attributeValidators.data?.length > 0 && (
        <Fragment>
          <Table size="sm" hover striped responsive>
            <RegexPatternsTableHeader />
            <RegexPatternsTableBody
              attributeValidators={attributeValidators.data}
              selectAttributeValidator={setSelectedAttributeValidator}
              toggleConfirmModal={setConfirmModalOpen}
              updateAttributeValidator={updateAttributeValidator}
            />
          </Table>
        </Fragment>
      )}

      <CheckPermission variant="displayIf" permissions={['regular-expression_update']}>
        <RegexPatternsModal
          isOpen={isModalOpen}
          toggleModal={() => setModalOpen(false)}
          confirm={handleSubmit}
          selectedAttributeValidator={selectedAttributeValidator}
        />
      </CheckPermission>

      <CheckPermission variant="displayIf" permissions={['regular-expression_delete']}>
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          title={t('regexPatterns.deleteRegexPattern')}
          text={t('regexPatterns.deleteRegexPatternConfirm')}
          item={selectedAttributeValidator?.title}
          isLoading={deleteEvent.loading}
          confirm={handleDelete}
          cancel={() => setConfirmModalOpen(false)}
        />
      </CheckPermission>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  attributeValidators: state.attributeValidators.resources,
  deleteEvent: state.attributeValidators.delete,
  positions: state.positions.data.positions.data?.content,
});

const mapDispatchToProps = {
  createAttributeValidator,
  deleteAttributeValidator,
  getAttributeValidators,
  updateAttributeValidator,
  getPositions,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegexPatternsTable);
