import { get } from '../../shared/services/http.service';
import { UploadedDocumentType } from '../../shared/models/uploaded-document.model';
import { QueryParams } from '../../shared/models/query-params.model';

const getUploadedDocuments = async (
  applicationId: number,
  type: UploadedDocumentType,
  params: QueryParams,
) => {
  return get(`/applications/${applicationId}/dashboard/uploaded-documents`, {
    params: { ...params, type },
  });
};

export default getUploadedDocuments;
