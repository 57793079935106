import React, { Fragment, useState } from 'react';
import { Card, CardHeader, CardBody, ListGroup, ListGroupItem, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CreateSurveyQuestionForm from '../../forms/CreateSurveyQuestionForm';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

interface Props {
  getSurveyQuestions: (appId: string, surveyId: string) => void;
  isEditingDisabled: boolean;
}

const SurveysEditorShortcutsSidebar: React.FC<Props> = ({
  getSurveyQuestions,
  isEditingDisabled,
}) => {
  const { appId, surveyId } = useParams<{ appId: string; surveyId: string }>();
  const { t } = useTranslation();
  const [createSurveyQuestionMode, setCreateSurveyQuestionMode] = useState<boolean>(false);
  const afterCreate = () => {
    if (!surveyId) {
      return;
    }
    setCreateSurveyQuestionMode(false);
    toast.success(t('surveys.createQuestionSuccessToast'));
    getSurveyQuestions(appId, surveyId.toString());
  };

  return (
    <Fragment>
      {!createSurveyQuestionMode && (
        <Card className="animated fadeIn">
          <CardHeader>{t('surveys.createQuestion')}</CardHeader>
          <CardBody>
            <ListGroup>
              <ListGroupItem
                key="addSurveyQuestion"
                disabled={!surveyId || isEditingDisabled}
                onClick={() => setCreateSurveyQuestionMode(true)}
              >
                {t('surveys.createQuestion')}
              </ListGroupItem>
            </ListGroup>
          </CardBody>
        </Card>
      )}

      {createSurveyQuestionMode && (
        <Card className="animated fadeIn">
          <CardHeader>
            {t('surveys.createQuestion')}
            <Button
              className="btn-ghost-secondary float-right"
              onClick={() => {
                setCreateSurveyQuestionMode(false);
              }}
            >
              <i className="fas fa-times" />
            </Button>
          </CardHeader>
          <CardBody>
            <CreateSurveyQuestionForm
              afterCreate={afterCreate}
              isEditingDisabled={isEditingDisabled}
            />

            <div className="mt-3 text-right">
              <Button
                color="primary"
                onClick={() => {
                  setCreateSurveyQuestionMode(false);
                }}
              >
                {t('common.back')}
              </Button>
            </div>
          </CardBody>
        </Card>
      )}
    </Fragment>
  );
};

export default SurveysEditorShortcutsSidebar;
