import React, { Component, Fragment } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Row, Col, Button, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import isEqual from 'lodash/isEqual';
// @ts-ignore
import { AppSwitch } from '@coreui/react';
import { SwitchModel } from 'src/shared/models/switch.model';

import { Spinner, ExportButton } from 'src/shared/components';

interface Props extends RouteComponentProps, WithTranslation {
  export: Function;
  isExporting: boolean;
  disabledExports: any;
}

interface State {
  modalIsOpen: boolean;
  showAlert: boolean;
  switches: SwitchModel[];
}

class Export extends Component<Props, State> {
  state: State = {
    modalIsOpen: false,
    showAlert: false,
    switches: [
      {
        id: 'dailyCountOfConversationsAndMessages',
        name: `${this.props.t('usageStatistics.dailyCountOfConversationsAndMessages')}`,
        disabled: false,
        checked: true,
      },
      {
        id: 'hourlyDistributionOfAllMessagesPercent',
        name: `${this.props.t('usageStatistics.hourlyDistributionOfAllMessagesPercent')}`,
        disabled: false,
        checked: true,
      },
      {
        id: 'hourlyDistributionOfAllMessagesAvgCount',
        name: `${this.props.t('usageStatistics.hourlyDistributionOfAllMessagesAvgCount')}`,
        disabled: false,
        checked: true,
      },
    ],
  };

  componentWillReceiveProps = (nextProps: any) => {
    if (!isEqual(this.props.disabledExports, nextProps.disabledExports)) {
      this.setDisabledExports(nextProps.disabledExports);
    }
  };

  setDisabledExports = (disabledExports: any) => {
    this.setState(prevState => ({
      switches: prevState.switches.map((obj, index) => {
        return Object.values(disabledExports)[index]
          ? Object.assign(obj, {
              disabled: true,
              checked: false,
            })
          : Object.assign(obj, {
              disabled: false,
              checked: true,
            });
      }),
    }));
  };

  export = () => {
    const checkedSwitches: SwitchModel[] = [];

    this.state.switches.map((el: SwitchModel) => {
      if (el.checked) {
        return checkedSwitches.push(el);
      }

      return null;
    });

    if (!checkedSwitches.length) {
      return this.setState({
        showAlert: true,
      });
    }

    this.props.export(checkedSwitches);
    this.toggleModal();

    return null;
  };

  handleSwitchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;

    this.setState(prevState => ({
      switches: prevState.switches.map((obj: SwitchModel) =>
        obj.id === id ? Object.assign(obj, { checked }) : obj,
      ),
    }));
  };

  toggleModal = () => {
    this.setState(prevState => ({
      modalIsOpen: !prevState.modalIsOpen,
      showAlert: false,
    }));
  };

  render() {
    const { showAlert, modalIsOpen, switches } = this.state;
    const { isExporting, disabledExports, t } = this.props;

    return (
      <Fragment>
        <ExportButton
          color="primary"
          className="ml-2"
          disabled={Object.keys(disabledExports).every(k => disabledExports[k])}
          onClick={() => this.toggleModal()}
        />

        <Modal isOpen={modalIsOpen} toggle={() => this.toggleModal()}>
          <ModalHeader>Export Data</ModalHeader>
          <ModalBody>
            {showAlert ? (
              <Alert color="danger" className="text-center">
                {t('usageStatistics.noStatisticSelectedForExport')}
              </Alert>
            ) : null}

            {switches.map((el: SwitchModel, index: number) => {
              return (
                <Row key={el.id} className="export-switch-container">
                  <Col className="export-switch">
                    <AppSwitch
                      name={String(index)}
                      id={el.id}
                      className="mr-2"
                      variant="pill"
                      color="primary"
                      size="lg"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.handleSwitchChange(e)
                      }
                      checked={el.checked}
                      disabled={el.disabled}
                    />
                    <span>{el.name}</span>
                  </Col>
                </Row>
              );
            })}
          </ModalBody>
          <ModalFooter>
            <Spinner loading={isExporting} className="mr-1" />
            <Button
              color="secondary"
              disabled={isExporting || Object.keys(disabledExports).every(k => disabledExports[k])}
              // disabled={isLoading || disabledExports.every((k: any) => k.disabled)}
              onClick={() => this.export()}
            >
              {t('common.export')}
            </Button>
            <Button color="secondary" onClick={() => this.toggleModal()}>
              {t('common.cancel')}
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default withTranslation()(withRouter(Export));
