import React, { Component } from 'react';
import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';

import styles from './scss/Hangouts.module.scss';

interface Props {
  message: ConversationHistoryModel;
}

interface State {
  outputTemplate: any;
}

class GenericTemplate extends Component<Props> {
  state: State = {
    outputTemplate: null,
  };

  componentDidMount() {
    // Replacing \n characters with space
    // JSON.parse() would fail otherwise
    const cleanedMessage = this.props.message.messageAsText.replace(/\n/g, ' ');
    this.renderTemplate(cleanedMessage);
  }

  renderButtons = (buttons: any[]) => {
    for (let i = 0; i < buttons.length; i++) {
      return (
        <button type="button" disabled>
          {buttons[i].title}
        </button>
      );
    }
  };

  async renderTemplate(msg: any) {
    const outputTemplate: any[] = [];

    try {
      const currentTemplate = JSON.parse(msg).hangoutsChatGenericElements;

      for (let i = 0; i < currentTemplate.length; i++) {
        outputTemplate.push(
          <div className={styles.genericTemplate}>
            <div className={styles.header}>
              <img src={currentTemplate[i].headerImage} alt="Header" />
              <div className={styles.texts}>
                <p>{currentTemplate[i].title}</p>
                <p>{currentTemplate[i].subTitle}</p>
              </div>
            </div>
            <div className={styles.content}>
              <img src={currentTemplate[i].imageUrl} className={styles.bodyImage} alt="Body" />
              <div className={styles.buttons}>{this.renderButtons(currentTemplate[i].buttons)}</div>
            </div>
          </div>,
        );
      }

      this.setState({ outputTemplate });
    } catch (error) {
      this.setState({ outputTemplate: msg });
    }
  }

  render() {
    return <div className={styles.genericTemplatesContainer}>{this.state.outputTemplate}</div>;
  }
}

export default GenericTemplate;
