import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert, Table } from 'reactstrap';
import { FormikValues } from 'formik';
import Pagination from 'react-js-pagination';

import { useDeepEffect } from 'src/shared/hooks';

import { StateModel } from 'src/shared/models/default-state.model';
import { GeneralQueryParams } from 'src/shared/models/query-params.model';
import { InterviewModel } from 'src/shared/models/interviews.model';
import { PositionModel } from 'src/shared/models/positions.model';
import { SelectModel } from 'src/shared/models/common.model';

import {
  createInterview,
  deleteInterview,
  getInterviews,
  updateInterview,
} from 'src/redux/actions/interviews.action';
import { getPositions } from 'src/redux/actions/positions.action';

import InterviewsTableHeader from './InterviewsTableHeader';
import InterviewsTableBody from './InterviewsTableBody';
import InterviewsModal from './InterviewsModal';
import ConfirmModal from 'src/shared/components/modals/ConfirmModal';
import CheckPermission from 'src/shared/components/CheckPermission';
import Spinner from 'src/shared/components/Spinner';
import ServerError from 'src/shared/components/ServerError';
import { mergeDateWithTime } from 'src/shared/utils/merge-date-time';

import styles from '../Interviews.module.scss';

interface Props {
  interviews: StateModel;
  getInterviews: Function;
  updateInterview: Function;
  createInterview: Function;
  deleteEvent: StateModel;
  deleteInterview: Function;
  positions: PositionModel[];
  getPositions: Function;
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  setModalEvent: (event: string) => void;
  modalEvent: string;
}

const InterviewsTable: React.FC<Props> = ({
  interviews,
  getInterviews,
  updateInterview,
  createInterview,
  deleteEvent,
  deleteInterview,
  positions,
  getPositions,
  isModalOpen,
  setModalOpen,
  setModalEvent,
  modalEvent,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams();

  const [queryParams, setQueryParams] = useState<GeneralQueryParams>({ page: 0, size: 20 });
  const [selectedInterview, setSelectedInterview] = useState<InterviewModel>();
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  useEffect(() => {
    getPositions(appId);
    /* eslint-disable-next-line */
  }, []);

  useDeepEffect(() => {
    getInterviews(appId, queryParams);
  }, [queryParams]);

  useEffect(() => {
    if (!isModalOpen && selectedInterview) {
      setSelectedInterview(undefined);
    }
    /* eslint-disable-next-line */
  }, [isModalOpen]);

  useEffect(() => {
    if (!isConfirmModalOpen && selectedInterview) {
      setSelectedInterview(undefined);
    }
    /* eslint-disable-next-line */
  }, [isConfirmModalOpen]);

  const handlePageChange = (index: number) => {
    setQueryParams(prevState => ({
      ...prevState,
      page: index - 1,
    }));
  };

  const handleSubmit = async (values: FormikValues) => {
    const {
      name,
      description,
      positions,
      limit,
      startDate,
      startTime,
      endDate,
      endTime,
      recurrenceFrequency,
      recurrenceUntilDate,
      recurrenceUntilTime,
      applicationDeadlineDate,
      applicationDeadlineTime,
    } = values;

    const interviewValues = {
      name,
      description,
      positionIds: positions.map((position: SelectModel) => position.value),
      limit: limit.value,
      startTime: mergeDateWithTime(startDate, startTime),
      applicationDeadline: mergeDateWithTime(applicationDeadlineDate, applicationDeadlineTime),
      endTime: mergeDateWithTime(endDate, endTime),
    };

    if (modalEvent === 'create') {
      const interviewValuesForCreate = {
        ...interviewValues,
        recurrence: {
          frequency: recurrenceFrequency.value,
          until: mergeDateWithTime(recurrenceUntilDate, recurrenceUntilTime),
        },
      };

      await createInterview(appId, interviewValuesForCreate);
    }

    if (modalEvent === 'edit' && selectedInterview) {
      await updateInterview(appId, selectedInterview.id, interviewValues);
    }

    await getInterviews(appId, queryParams);

    setModalOpen(false);
  };

  const handleDelete = async () => {
    if (selectedInterview) {
      await deleteInterview(appId, selectedInterview.id);
      await getInterviews(appId, queryParams);

      setConfirmModalOpen(false);
    }
  };

  return (
    <Fragment>
      {interviews.loading && (
        <div className="d-flex justify-content-center">
          <Spinner size="2x" loading={interviews.loading} />
        </div>
      )}

      {interviews.loaded && !interviews.data?.length && (
        <Alert className="text-center" color="info">
          {t('interviews.noInterviewsFound')}
        </Alert>
      )}

      {interviews.error && <ServerError />}

      {interviews.loaded && interviews.data?.length > 0 && (
        <Fragment>
          <Table size="sm" hover striped responsive className={styles.interviewsTable}>
            <InterviewsTableHeader />
            <InterviewsTableBody
              interviews={interviews.data}
              selectInterview={setSelectedInterview}
              setModalEvent={setModalEvent}
              toggleModal={setModalOpen}
              toggleConfirmModal={setConfirmModalOpen}
            />
          </Table>

          <Pagination
            totalItemsCount={interviews.data.totalElements || 0}
            activePage={queryParams.page + 1}
            itemsCountPerPage={queryParams.size}
            onChange={handlePageChange}
          />
        </Fragment>
      )}

      <CheckPermission variant="displayIf" permissions={['interview_event_update']}>
        <InterviewsModal
          isOpen={isModalOpen}
          toggleModal={() => setModalOpen(false)}
          modalEvent={modalEvent}
          confirm={handleSubmit}
          selectedInterview={selectedInterview}
          positions={positions}
        />
      </CheckPermission>

      <CheckPermission variant="displayIf" permissions={['interview_event_delete']}>
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          title={t('interviews.deleteInterview')}
          text={t('interviews.deleteInterviewConfirm')}
          item={selectedInterview?.name}
          isLoading={deleteEvent.loading}
          confirm={handleDelete}
          cancel={() => setConfirmModalOpen(false)}
        />
      </CheckPermission>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  interviews: state.interviews.resources,
  deleteEvent: state.interviews.delete,
  positions: state.positions.data.positions.data?.content,
});

const mapDispatchToProps = {
  getInterviews,
  updateInterview,
  createInterview,
  deleteInterview,
  getPositions,
};

export default connect(mapStateToProps, mapDispatchToProps)(InterviewsTable);
