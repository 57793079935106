import React from 'react';
import { CardHeader, Badge } from 'reactstrap';

import { QuestionnaireLabelCategory } from 'src/shared/models/questionnaire.model';

import styles from '../Labels.module.scss';

interface Props {
  category: QuestionnaireLabelCategory;
  chevronIcon: string;
  setBodyVisible: Function;
}

const LabelCategoryCardHeader: React.FC<Props> = ({ category, chevronIcon, setBodyVisible }) => (
  <CardHeader>
    <div className={styles.cardHeader}>
      <div>
        {/* <div className="text-muted">#{category.id}</div> */}
        <div className="font-weight-bold">{category.text}</div>
      </div>

      <div className={styles.rightContent}>
        <Badge>{category.labels?.length || 0}</Badge>
        <button
          type="button"
          className="btn btn-ghost-secondary ml-2"
          onClick={() => setBodyVisible((prevState: boolean) => !prevState)}
        >
          <i className={chevronIcon} />
        </button>
      </div>
    </div>
  </CardHeader>
);

export default LabelCategoryCardHeader;
