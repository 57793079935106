import React, { Fragment, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Table, Badge, Button } from 'reactstrap';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { FormikValues, useFormikContext } from 'formik';
import cx from 'classnames';
import findIndex from 'lodash/findIndex';
import uniqueId from 'lodash/uniqueId';

import {
  PositionQuestionAnswerOptionModel,
  PositionQuestionModel,
  SelectModel,
} from 'src/shared/models';
import { getItemStyle, swapItemsInArray } from 'src/shared/utils/misc.util';
import { positionSidebarTypes, shortListValues } from 'src/shared/constants/positions.constants';
import { setQuestionToEdit, setPositionSidebarType } from 'src/redux/actions/positions.action';
import { Switch, ReactSelect, OptionModel } from 'src/shared/components';

import styles from '../../../Positions.module.scss';

interface Props {
  positionId: number | undefined;
  questions: PositionQuestionModel[];
  setQuestionToEdit: Function;
  setPositionSidebarType: Function;
  createdOrEditedPositionQuestion: {
    data: PositionQuestionModel;
    method: string;
  };
}

const PositionPrefilterCriteriasForm: React.FC<Props> = ({
  positionId,
  questions,
  setQuestionToEdit,
  setPositionSidebarType,
  createdOrEditedPositionQuestion,
}) => {
  const { t } = useTranslation();
  const { values, setFieldValue }: FormikValues = useFormikContext();

  const getSelectOptions = () => {
    const options: SelectModel[] = [];

    for (let i = shortListValues.MIN; i <= shortListValues.MAX; i += 1) {
      options.push({ label: i.toString(), value: i });
    }

    return options;
  };

  const selectOptions = useMemo(() => getSelectOptions(), []);

  const handlePositionQuestionsChange = (question: PositionQuestionModel, method: string) => {
    const tempQuestions = values.questions;

    if (method === 'create') {
      tempQuestions.push({ ...question, orderNumber: tempQuestions.length });
    }

    if (method === 'edit') {
      const index = findIndex(
        tempQuestions,
        (item: PositionQuestionModel) => item.id === question.id,
      );

      if (index > -1) {
        tempQuestions.splice(index, 1, question);
      }
    }

    setFieldValue('questions', tempQuestions);
  };

  useEffect(() => {
    const { data, method } = createdOrEditedPositionQuestion;
    handlePositionQuestionsChange(data, method);
    /* eslint-disable-next-line */
  }, [createdOrEditedPositionQuestion]);

  const renderQuestionAnswerOptions = (questionIndex: number, question: PositionQuestionModel) => {
    const { questionAnswerOptions, shortList } = question;

    if (!questionAnswerOptions?.length) return null;
    const containerClasses = cx('d-flex flex-wrap', { 'flex-column': shortList });
    const sortedAnswerOptions = questionAnswerOptions.sort((a, b) => a.orderNumber - b.orderNumber);

    const items = sortedAnswerOptions.map((option: PositionQuestionAnswerOptionModel) => (
      <Badge
        key={uniqueId(option.id?.toString())}
        color="secondary"
        className={styles.possibleAnswer}
      >
        {option.text}
      </Badge>
    ));

    return <div className={containerClasses}>{items}</div>;
  };

  const renderQuestionAnswerOptionValueAndKoInputs = (
    questionIndex: number,
    question: PositionQuestionModel,
  ) => {
    const { questionAnswerOptions } = question;

    if (!questionAnswerOptions?.length) return null;
    const sortedAnswerOptions = questionAnswerOptions.sort((a, b) => a.orderNumber - b.orderNumber);

    return sortedAnswerOptions.map((option: PositionQuestionAnswerOptionModel, idx) => {
      const value = questions[questionIndex].questionAnswerOptions[idx].value;
      const checkedValue = typeof value !== 'undefined' ? value : '';

      return (
        <div key={option.id} className="d-flex flex-row align-items-center">
          <ReactSelect
            name="positionShowSize"
            options={selectOptions}
            className={styles.select}
            value={
              {
                label: checkedValue,
                value: checkedValue,
              } as OptionModel
            }
            onChange={(option: OptionModel) =>
              setFieldValue(
                `questions[${questionIndex}].questionAnswerOptions[${idx}].value`,
                (option as SelectModel).value,
              )
            }
          />
          <Switch
            key={option.id}
            name={`questions[${questionIndex}].questionAnswerOptions[${idx}].ko`}
            variant="pill"
            color="primary"
          />
        </div>
      );
    });
  };

  const handleQuestionEdit = (question: PositionQuestionModel) => {
    setQuestionToEdit(question);
    setPositionSidebarType(positionSidebarTypes.EDIT_PREFILTER_CRITERIA);
  };

  const handleQuestionDelete = (questionId: number) => {
    const filteredQuestions = questions.filter(question => question.id !== questionId);
    setFieldValue('questions', filteredQuestions);
  };

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    const swappedQuestions = swapItemsInArray(questions, source.index, destination.index);

    const orderedTempQuestions = swappedQuestions.map((item, idx) => ({
      ...item,
      orderNumber: idx,
    }));
    setFieldValue('questions', orderedTempQuestions);
  };

  const renderQuestionsTable = () => {
    if (!questions.length)
      return (
        <div className="text-center text-muted">{t('positions.noPrefilterCriteriasFound')}</div>
      );

    const tableRowItems = questions
      .sort((a, b) => a.orderNumber - b.orderNumber)
      .map((question, idx) => {
        const rowId = uniqueId('question');

        return (
          <Draggable draggableId={rowId} key={rowId} index={idx}>
            {(provided, snapshot) => (
              <tr
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
              >
                <td className={styles.shortList}>
                  {!question.anyAnswer && (
                    <div className="d-flex justify-content-center">
                      <Switch name={`questions[${idx}].shortList`} variant="pill" color="primary" />
                    </div>
                  )}
                </td>
                <td className={styles.category}>{question.criteria.name}</td>
                <td className={styles.question}>{question.text}</td>
                <td className={styles.possibleAnswers}>
                  <div className={styles.possibleAnswersContainer}>
                    {question.anyAnswer && `${t('positions.freeText')}`}
                    {renderQuestionAnswerOptions(idx, question)}
                  </div>
                </td>
                <td className={styles.valueAndKoInputs}>
                  {question.shortList && renderQuestionAnswerOptionValueAndKoInputs(idx, question)}
                </td>
                <td className={styles.operations}>
                  <Button color="secondary" size="sm" onClick={() => handleQuestionEdit(question)}>
                    <i className="far fa-edit" />
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => handleQuestionDelete(question.id!)}
                  >
                    <i className="far fa-trash-alt" />
                  </Button>
                </td>
              </tr>
            )}
          </Draggable>
        );
      });

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="questions">
          {provided => (
            <Table className={styles.prefilterCriteriaTable} size="sm" striped hover>
              <thead>
                <tr>
                  <th className={styles.shortList}>{t('common.shortList')}</th>
                  <th className={styles.category}>{t('common.category')}</th>
                  <th className={styles.question}>{t('common.question')}</th>
                  <th className={styles.possibleAnswers}>{t('common.possibleAnswers')}</th>
                  <th className={styles.valueAndKoInputs}>
                    {t('common.value')} / {t('KO')}
                  </th>
                  <th className={styles.operations}> </th>
                </tr>
              </thead>
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {tableRowItems}
                {provided.placeholder}
              </tbody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  return (
    <Fragment>
      <fieldset className="border p-3">
        <legend className="col-form-label w-auto font-weight-bold">{`${t(
          'common.prefilterCriterias',
        )} & ${t('common.shortListDefinition')}`}</legend>
        <Row form>
          <Col>
            <Row className="mb-3">
              <Col className="d-flex justify-content-end">
                <Button
                  disabled={!positionId}
                  onClick={() => {
                    setPositionSidebarType(positionSidebarTypes.CREATE_PREFILTER_CRITERIA);
                    setQuestionToEdit(null);
                  }}
                >
                  {t('positions.createPrefilterCriteria')}
                </Button>
              </Col>
            </Row>
            <FormGroup>{renderQuestionsTable()}</FormGroup>
          </Col>
        </Row>
      </fieldset>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  createdOrEditedPositionQuestion: state.positionQuestions.data.createdOrEditedPositionQuestion,
});

const mapDispatchToProps = {
  setQuestionToEdit,
  setPositionSidebarType,
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionPrefilterCriteriasForm);
