import { Dispatch } from 'redux';
import { PageQueryParams } from 'src/shared/models';

import {
  getDynamicConvFlows as getDynamicConvFlowsService,
  createDynamicConvFlow as createDynamicConvFlowService,
  ImportTypes,
} from 'src/redux/services/dynamic-conv-flow.service';
import {
  DynamicConvFlowImportModel,
  DynamicConvFlowModel,
} from 'src/shared/models/dynamic-conv-flow.model';

export const GET_DYNAMIC_CONV_FLOWS = 'GET_DYNAMIC_CONV_FLOWS';
export const GET_DYNAMIC_CONV_FLOWS_SUCCESS = 'GET_DYNAMIC_CONV_FLOWS_SUCCESS';
export const GET_DYNAMIC_CONV_FLOWS_ERROR = 'GET_DYNAMIC_CONV_FLOWS_ERROR';

export const CREATE_DYNAMIC_CONV_FLOW = 'DynamicConvFlows/CREATE_DYNAMIC_CONV_FLOW';
export const CREATE_DYNAMIC_CONV_FLOW_SUCCESS = 'DynamicConvFlows/CREATE_DYNAMIC_CONV_FLOW_SUCCESS';
export const CREATE_DYNAMIC_CONV_FLOW_ERROR = 'DynamicConvFlows/CREATE_DYNAMIC_CONV_FLOW_ERROR';

export const getDynamicConvFlows = (
  appId: string,
  queryParams: PageQueryParams,
  type: ImportTypes,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_DYNAMIC_CONV_FLOWS,
    });

    const dynamicConvFlow = (await getDynamicConvFlowsService(
      appId,
      queryParams,
      type,
    )) as DynamicConvFlowModel[];

    try {
      dispatch({
        type: GET_DYNAMIC_CONV_FLOWS_SUCCESS,
        payload: {
          content: dynamicConvFlow,
          pageable: false,
          totalPages: 1,
          totalElements: dynamicConvFlow.length,
          numberOfElements: dynamicConvFlow.length,
          first: true,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_DYNAMIC_CONV_FLOWS_ERROR,
        error,
      });
    }
  };
};

export const createDynamicConvFlow = (
  appId: string,
  DynamicConvFlowData: DynamicConvFlowImportModel,
  type: ImportTypes,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_DYNAMIC_CONV_FLOW,
    });

    const response = await createDynamicConvFlowService(appId, DynamicConvFlowData, type);

    try {
      dispatch({
        type: CREATE_DYNAMIC_CONV_FLOW_SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: CREATE_DYNAMIC_CONV_FLOW_ERROR,
        error,
      });
    }
  };
};
