import React, { Fragment } from 'react';
import i18n from 'src/i18n';
import { EmployeeTag } from 'src/shared/models/audit-log.model';

interface Props {
  oldEmployeeTag: EmployeeTag | undefined;
  newEmployeeTag: EmployeeTag | undefined;
}

const EmployeeTagTable: React.FC<Props> = ({ oldEmployeeTag, newEmployeeTag }) => {
  return (
    <Fragment>
      {oldEmployeeTag && (
        <>
          <p className="font-weight-bold">
            {i18n.t('auditLog.oldEmployeeTag')}
          </p>
          <table className="table table-sm">
            <tbody>
              <tr>
                <td>{i18n.t('common.id')}</td>
                <td>{oldEmployeeTag.id}</td>
              </tr>
              <tr>
                <td>{i18n.t('common.name')}</td>
                <td>{oldEmployeeTag.name}</td>
              </tr>
              <tr>
                <td>{i18n.t('common.categoryId')}</td>
                <td>{oldEmployeeTag.categoryId}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      {newEmployeeTag && (
        <>
          <p className="font-weight-bold">
            {i18n.t('auditLog.newEmployeeTag')}
          </p>
          <table className="table table-sm">
            <tbody>
              <tr>
                <td>{i18n.t('common.id')}</td>
                <td>{newEmployeeTag.id}</td>
              </tr>
              <tr>
                <td>{i18n.t('common.name')}</td>
                <td>{newEmployeeTag.name}</td>
              </tr>
              <tr>
                <td>{i18n.t('common.categoryId')}</td>
                <td>{newEmployeeTag.categoryId}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </Fragment>
  );
};

export default EmployeeTagTable;
