import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Alert } from 'reactstrap';
import Pagination from 'react-js-pagination';

import { useDeepEffect } from 'src/shared/hooks';

import { StateModel } from 'src/shared/models/default-state.model';
import { GeneralQueryParams } from 'src/shared/models/query-params.model';
import { CustomListModel } from 'src/shared/models/custom-lists.model';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { getCustomLists, deleteCustomList } from 'src/redux/actions/custom-lists.action';

import CustomListsHeader from './components/CustomListsHeader';
import CustomListCards from './components/CustomListCards';
import ConfirmModal from 'src/shared/components/modals/ConfirmModal';
import CustomListModal from './components/CustomListModal';
import ServerError from 'src/shared/components/ServerError';
import Spinner from 'src/shared/components/Spinner';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  customLists: StateModel;
  getCustomLists: Function;
  deleteEvent: StateModel;
  deleteCustomList: Function;
}

const CustomLists: React.FC<Props> = ({
  application,
  getApplication,
  customLists,
  getCustomLists,
  deleteEvent,
  deleteCustomList,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const [queryParams, setQueryParams] = useState<GeneralQueryParams>({ page: 0, size: 50 });
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [modalEvent, setModalEvent] = useState<string>('');
  const [selectedCustomList, setSelectedCustomList] = useState<CustomListModel | undefined>();

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    // eslint-disable-next-line
  }, []);

  useDeepEffect(() => {
    getCustomLists(appId, queryParams);
  }, [queryParams]);

  const handlePageChange = (index: number) => {
    setQueryParams(prevState => ({
      ...prevState,
      page: index - 1,
    }));
  };

  const handleDelete = async () => {
    if (selectedCustomList) {
      await deleteCustomList(appId, selectedCustomList.id);
      handleToggle();
    }
  };

  const handleToggle = () => {
    setSelectedCustomList(undefined);
    setConfirmModalOpen(false);
  };

  return (
    <div className="animated fadeIn">
      <CustomListsHeader setModalOpen={setModalOpen} setModalEvent={setModalEvent} />
      <Row>
        <Col>
          {customLists.loading && (
            <div className="d-flex justify-content-center">
              <Spinner size="2x" loading={customLists.loading} />
            </div>
          )}

          {customLists.loaded && !customLists.data?.content?.length && (
            <Alert color="info" className="text-center">
              {t('customLists.noCustomListsFound')}
            </Alert>
          )}

          {customLists.error && <ServerError />}

          {customLists.loaded && customLists.data?.content?.length > 0 && (
            <Fragment>
              <CustomListCards
                customLists={customLists.data.content}
                setModalOpen={setModalOpen}
                setConfirmModalOpen={setConfirmModalOpen}
                setModalEvent={setModalEvent}
                setSelectedCustomList={setSelectedCustomList}
              />

              <Pagination
                activePage={queryParams.page + 1}
                totalItemsCount={customLists.data.totalElements || 1}
                itemsCountPerPage={queryParams.size}
                onChange={handlePageChange}
              />

              <ConfirmModal
                title={t('customLists.deleteCustomList')}
                text={t('customLists.deleteCustomListConfirm')}
                item={selectedCustomList?.name}
                isOpen={isConfirmModalOpen}
                isLoading={deleteEvent.loading}
                confirm={handleDelete}
                cancel={() => handleToggle()}
              />
            </Fragment>
          )}
        </Col>
      </Row>

      <CustomListModal
        toggle={() => setModalOpen(false)}
        isOpen={isModalOpen}
        event={modalEvent}
        customList={selectedCustomList}
        setSelectedCustomList={setSelectedCustomList}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  customLists: state.customLists.resources,
  deleteEvent: state.customLists.delete,
});

const mapDispatchToProps = {
  getApplication,
  getCustomLists,
  deleteCustomList,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomLists);
