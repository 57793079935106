import React, { useEffect, useMemo, useState } from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { uniqueId } from 'lodash';
import moment from 'moment';
import { Card, CardBody, Col, Row } from 'reactstrap';

import saveFile from 'src/shared/utils/file-saver.util';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { getEvent } from 'src/redux/actions/events.action';
import {
  getEventReport,
  getEventReports,
  revertEventReport,
} from 'src/redux/actions/event-report.action';

import { exportReport } from 'src/redux/services/event.service';
import { default as DateRangeFilter } from '../UsageStatistics/components/Filter';
import { ExportButton, List } from 'src/shared/components';
import {
  ListFieldModel,
  OptionListAnswerConfiguration,
  QueryParams,
  TextAnswerConfiguration,
} from 'src/shared/models';
import {
  ANSWER_TYPES,
  EventModel,
  EventReport,
  EventReportQuestion,
  EventReportSubscriber,
} from 'src/shared/models/event.model';
import settings, { fileNames } from 'src/shared/settings';

import { getListFieldsByConfiguration } from 'src/shared/utils/configuration.util';
import { configurationConstants } from 'src/shared/constants/configuration.constants';

import './EventReports.scss';

interface EventsReportProps {
  application: ApplicationModel;
  event: EventModel;
  reports: EventReport[];
  reportsLoading: boolean;
  reportsLoaded: boolean;
  reportsError: boolean;
  getApplication: Function;
  getEvent: Function;
  getEventReport: Function;
  getEventReports: Function;
  revertEventReport: Function;
}

const EventsReport: FC<EventsReportProps> = ({
  application,
  event,
  reports,
  reportsLoading,
  reportsLoaded,
  reportsError,
  getApplication,
  getEvent,
  getEventReport,
  getEventReports,
  revertEventReport,
}) => {
  const { t } = useTranslation();
  const { appId, eventId } = useParams<{ appId: string; eventId: string }>();
  /* eslint-disable-next-line */
  const [queryParams, setQueryParams] = useState<QueryParams>({
    from: moment().utc().subtract(7, 'days').toISOString(),
    to: moment().utc().toISOString(),
    page: 0,
    size: 20,
    limit: 20,
  });

  /*a config elemein kívül a kérdésekre adott válaszok továbbra is megjelenítendőek
   (ha nincs az aktuális configban core.events.report.list, akkor csak azok)*/
  const configuredFields = getListFieldsByConfiguration(
    application,
    configurationConstants.coreEventsReportList,
    t,
  );

  useEffect(() => {
    revertEventReport();
    getEvent(appId, eventId);

    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    if (event) {
      fetchItems();
    }

    /* eslint-disable-next-line */
  }, [event, queryParams]);

  const getEventDateTitle = (eventDateTitle: string) =>
    eventDateTitle ? `${eventDateTitle} - ` : ' ';

  const title = useMemo(() => {
    let title = t('events.reports.title', {
      title: reports.length === 0 ? event?.title.trim() : reports[0].title,
    });

    if (reports.length === 1) {
      title = `${title} - ${getEventDateTitle(reports[0].eventDateTitle)}${moment(
        reports[0].startDateTime,
      ).format(settings.DATE_TIME_FORMAT)}`;
    }
    return title;
  }, [reports, event, t]);

  const fetchItems = async () => {
    if (event) {
      if (event.dates.length === 1) {
        getEventReport(appId, eventId, queryParams);
      } else {
        getEventReports(appId, eventId, queryParams);
      }
    }
  };

  const handleTableFetch = (newQueryParams: QueryParams) => {
    if (queryParams.page !== newQueryParams.page) {
      setQueryParams(prevState => ({ ...prevState, ...newQueryParams }));
    }
  };

  const handleDateChange = async (from: string, to: string) => {
    setQueryParams(
      Object.assign(queryParams, {
        from: moment(from).toISOString(),
        to: moment(to).toISOString(),
      }),
    );
    fetchItems();
  };

  const getFields = (report: EventReport): ListFieldModel<EventReportSubscriber>[] => {
    const fields = [];

    const returnEmptyIfFails = (func: () => any) => {
      try {
        return func();
      } catch {
        return '';
      }
    };

    fields.push({
      key: uniqueId(),
      label: '',
      render: (item: EventReportSubscriber) => {
        return returnEmptyIfFails(() => item.seqNumber);
      },
    });

    const numberOfFixColumns = 2;
    const numberOfAllColumns = numberOfFixColumns + report.questions.length;

    //answers
    const customFields = report.questions.map((question: EventReportQuestion) => {
      const getTextBasedOnAnswerConfiguration = (
        type: ANSWER_TYPES,
        item: EventReportSubscriber,
        configuration: TextAnswerConfiguration | OptionListAnswerConfiguration,
      ) => {
        const selected = item.answers.filter(a => a.eventQuestionId === question.id);
        if (selected.length === 0) return '';
        if (type === ANSWER_TYPES.TEXT) {
          return selected[0].answer;
        } else if (type === ANSWER_TYPES.OPTIONS) {
          const currentConfiguration = configuration as OptionListAnswerConfiguration;
          return currentConfiguration.options[selected[0].answer as any];
        }
        return '';
      };

      const field: ListFieldModel<any> = {
        key: uniqueId(),
        label: question.title,
        columnCssStyle: { width: `${100 / numberOfAllColumns}%` },
        render: (item: EventReportSubscriber) => {
          if (item) {
            return getTextBasedOnAnswerConfiguration(
              question.answerConfiguration.type,
              item,
              question.answerConfiguration,
            );
          }
          return '';
        },
      };
      return field;
    });

    return [...fields, ...configuredFields, ...customFields];
  };

  const fields = useMemo(() => {
    return reports && reports.map(getFields);
    /* eslint-disable-next-line */
  }, [reports, configuredFields]);

  const exportReports = async () => {
    const response = await exportReport(appId, eventId);

    if (response) {
      saveFile(response, fileNames.EVENT_REPORT_EXPORT_EXCEL, 'xlsx');
    }
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col>
          <h4>{title}</h4>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col className="d-flex justify-content-start">
          <DateRangeFilter handleDateChange={handleDateChange} getAllData={fetchItems} />
        </Col>
        <Col className="d-flex justify-content-end">
          <ExportButton
            className="ml-2"
            disabled={reports.length === 0}
            onClick={exportReports}
            permission="event_reports_read"
            tooltipProps={{ target: 'exportButton', placement: 'left' }}
            exportTooltipLabel={t('common.exportToExcel')}
          />
        </Col>
      </Row>
      <Card>
        <CardBody>
          {event && reports.length > 0 ? (
            reports.map((report, index) => {
              let tableProps = {};
              if (event.dates.length === 1) {
                tableProps = {
                  data: (report.subscribers as any).content,
                  totalElements: (report.subscribers as any).totalElements,
                  fetchData: handleTableFetch,
                };
              } else {
                tableProps = {
                  data: report.subscribers as any,
                  totalElements: report.subscribers.length,
                  fetchData: () => {},
                  hidePager: true,
                };
              }
              return (
                <List
                  key={report.id}
                  pageHeaderProps={{
                    rightComponent: (
                      <Row>
                        <Col className="subscribed">
                          <p>{t('events.reports.subscribed')}</p> {report.subscriptionsCount}
                        </Col>
                        <Col className="remaining">
                          <p>{t('events.reports.remaining')}</p>
                          {report.limit === -1 ? (
                            <>{t('events.reports.unlimited')}</>
                          ) : (
                            report.limit - report.subscriptionsCount
                          )}
                        </Col>
                      </Row>
                    ),
                  }}
                  loaded={reportsLoaded}
                  loading={reportsLoading}
                  error={reportsError}
                  fields={fields[index]}
                  title={`${getEventDateTitle(report.eventDateTitle)}${moment(
                    report.startDateTime,
                  ).format(settings.DATE_TIME_FORMAT)}`}
                  noDataLabel={t('events.noReports')}
                  hideTableHead={index !== 0}
                  {...(tableProps as any)}
                />
              );
            })
          ) : (
            <div className="text-center m-0 alert alert-info fade show">
              {t('events.noReports')}
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    event: state.eventGeneral.data?.event,
    reports: state.eventReport.data,
    reportsLoading: state.eventReport.loading,
    reportsLoaded: state.eventReport.loaded,
    reportsError: state.eventReport.error,
  };
};

const mapDispatchToProps = {
  getApplication,
  getEvent,
  getEventReport,
  revertEventReport,
  getEventReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsReport);
