import React, { FC, Fragment } from 'react';
import { Button } from 'reactstrap';

import { TagModel } from 'src/shared/models';
import {
  CheckPermission,
  TagCreatePill,
  TagSelectPopover,
  TagSelectPopoverProps,
} from 'src/shared/components';

import styles from './Tags.module.scss';

interface EmployeeTagsProps extends TagSelectPopoverProps {
  employeeTags: TagModel[];
  removeTag?: (tagId: string) => void;
  removePermission?: string;
  createPermission: string;
  disabled?: boolean;
}

const EmployeeTags: FC<EmployeeTagsProps> = ({
  employeeTags,
  removeTag,
  removePermission,
  disabled,
  index,
  onChanged,
  ...props
}) => {
  const removeOneTag = (id: string) => {
    if (removeTag) {
      removeTag(id);
      if (onChanged) {
        onChanged();
      }
    }
  };

  return (
    <div className={styles.employeeTags}>
      {employeeTags
        ?.sort((a: TagModel, b: TagModel) => ((a?.name || '') > (b?.name || '') ? 1 : -1))
        ?.sort((a: TagModel, b: TagModel) =>
          (a?.employeeTagCategories?.name || '') > (b?.employeeTagCategories?.name || '') ? 1 : -1,
        )
        .map((tag: TagModel, index: number, tags: TagModel[]) => (
          <Fragment key={tag.id}>
            {tag.employeeTagCategories?.name &&
              (!index ||
                tags[index - 1].employeeTagCategories?.name !==
                  tag.employeeTagCategories?.name) && (
                <span className={styles.employeeTagCategory}>
                  {tag.employeeTagCategories?.name}:
                </span>
              )}
            <span className={styles.employeeTag}>
              {tag.name}
              {!disabled && removeTag ? (
                <CheckPermission
                  variant="displayIf"
                  permissions={removePermission ? [removePermission] : []}
                >
                  <Button
                    className={[styles.employeTagRemove, 'bg-transparent'].join(' ')}
                    onClick={() => removeOneTag(tag.id)}
                  >
                    <i className="fas fa-lg fa-times-circle text-primary" />
                  </Button>
                </CheckPermission>
              ) : null}
            </span>
          </Fragment>
        ))}

      {!disabled ? (
        <>
          <TagCreatePill
            id={`Popover-${index}`}
            context="assign"
            createPermission={props.createPermission}
          />
          <TagSelectPopover index={index} onChanged={onChanged} {...props} />
        </>
      ) : null}
    </div>
  );
};

export default EmployeeTags;
