import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import {
  deleteLanguageSetting,
  getLanguageSettings,
} from 'src/redux/actions/language-settings.action';
import { List } from 'src/shared/components';
import { Operations } from 'src/shared/models';
import { GetLanguagesResponse, Language } from 'src/shared/models/language-settings.model';
import LanguageEditorModal from './components/LanguageEditorModal';

interface LanguageSettingsProps {
  application: ApplicationModel;
  languageSettings: GetLanguagesResponse;

  getApplication: Function;
  getLanguageSettings: (appId: string) => Promise<void>;
  deleteLanguageSetting: (appId: string, languageSettingCode: string) => Promise<void>;
}

const LanguageSettings: FC<LanguageSettingsProps> = ({
  application,
  languageSettings,
  getApplication,
  getLanguageSettings,
  deleteLanguageSetting,
}) => {
  const { appId } = useParams<{ appId: string }>();
  const { t } = useTranslation();

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    getLanguageSettings(appId);
    // eslint-disable-next-line
  }, []);

  const deleteLanguage = async (item: Language) => {
    await deleteLanguageSetting(appId, item.languageCode);
    await getLanguageSettings(appId);
  };
  return (
    <div className="animated fadeIn">
      <List
        useCardContent
        hidePager
        data={languageSettings}
        totalElements={languageSettings.length}
        loaded={true}
        loading={false}
        error={false}
        fetchData={() => {}}
        title={t('translations.languageSettings')}
        fields={[
          { label: t('translations.id'), key: 'id' },
          { label: t('translations.languageCode'), key: 'languageCode' },
          { label: t('translations.name'), key: 'name' },
          {
            key: 'operations',
            label: t('common.operations'),
            operations: [Operations.DELETE],
          },
        ]}
        noDataLabel={t('translations.noData')}
        deleteFunction={deleteLanguage}
        deleteTitle={t('common.warning')}
        deleteText={t('translations.deleteLanguage')}
        deletePermission="translation_language_delete"
        createModal={(isModalOpen, toggleModal) => (
          <LanguageEditorModal isOpen={isModalOpen} setIsModalOpen={toggleModal} />
        )}
        createPermission="translation_language_create"
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    languageSettings: state.languageSettings.data,
  };
};

const mapDispatchToProps = {
  getApplication,
  getLanguageSettings,
  deleteLanguageSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSettings);
