import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, CardBody, Form, Row, FormGroup, Input, Button, Alert } from 'reactstrap';
import uniqueId from 'lodash/uniqueId';

import { QuestionnaireLabel } from 'src/shared/models/questionnaire.model';

import hasPermission from 'src/shared/services/permissions.service';

import TagPill from 'src/shared/components/TagPill';
import CheckPermission from 'src/shared/components/CheckPermission';

import styles from '../Labels.module.scss';

interface Props {
  labels: QuestionnaireLabel[];
  labelToAdd: string;
  setLabelToAdd: Function;
  handleLabelAdd: Function;
  handleLabelUpdate: Function;
  handleLabelDelete: Function;
  alertText: string;
}

const LabelCategoryCardBody: React.FC<Props> = ({
  labels,
  labelToAdd,
  setLabelToAdd,
  handleLabelAdd,
  handleLabelUpdate,
  handleLabelDelete,
  alertText,
}) => {
  const { t } = useTranslation();

  const handleOnKeyDown = (keyCode: number) => {
    if (keyCode === 13) {
      handleLabelAdd();
    }
  };

  return (
    <CardBody>
      {alertText && (
        <Alert color="danger" className="text-center">
          {alertText}
        </Alert>
      )}

      <CheckPermission variant="displayIf" permissions={['position_label_label_create']}>
        <Form className="mb-3" onSubmit={e => e.preventDefault()}>
          <Row form>
            <Col lg={4}>
              <FormGroup className="d-flex">
                <Input
                  type="text"
                  name="label"
                  id="label"
                  placeholder={t('labels.addLabel')}
                  value={labelToAdd}
                  onKeyDown={e => handleOnKeyDown(e.keyCode)}
                  onChange={e => setLabelToAdd(e.target.value)}
                />
                <Button className="ml-2" disabled={!labelToAdd} onClick={() => handleLabelAdd()}>
                  {t('common.add')}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CheckPermission>

      <div className={styles.tagPillContainer}>
        {labels.map(label => (
          <TagPill
            key={uniqueId('label-')}
            item={label.text}
            isDeleteConfirmRequired={false}
            isDeleteable={hasPermission(['position_label_label_update'])}
            isEditable={hasPermission(['position_label_label_delete'])}
            handleEdit={handleLabelUpdate}
            handleDelete={handleLabelDelete}
          />
        ))}
      </div>
    </CardBody>
  );
};

export default LabelCategoryCardBody;
