import React, { useEffect, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Button } from 'reactstrap';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { CriteriaImportModel, PageQueryParams, QueryParams } from 'src/shared/models';
import { List } from 'src/shared/components';

import { getCriteriaImports } from 'src/redux/actions/criteria-import.action';
import CriteriaImportModalForm from './forms/CriteriaImportModalForm';
import { getSignedUrlAndOpenFile } from 'src/shared/services/upload.service';

interface Props {
  getCriteriaImports: (appId: string, queryParams: PageQueryParams) => void;
  getApplication: Function;
  application: ApplicationModel;
  criteriaImports: CriteriaImportModel[];
  numberOfCriteriaImports: number;
  criteriaImportsLoading: boolean;
  criteriaImportsLoaded: boolean;
  criteriaImportsError: boolean;
}

const CriteriaImport: FC<Props> = ({
  getCriteriaImports,
  getApplication,
  application,
  criteriaImports,
  numberOfCriteriaImports,
  criteriaImportsLoading,
  criteriaImportsLoaded,
  criteriaImportsError,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [queryParamsDef, setQueryParamsDef] = useState<QueryParams>({
    page: 0,
    size: 20,
    limit: 20,
  });

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  const fetchCriteriaImports = (queryParams: QueryParams) => {
    setQueryParamsDef(queryParams);
    getCriteriaImports(appId, queryParams);
  };

  const refreshCriteriaImports = () => {
    getCriteriaImports(appId, queryParamsDef);
  };

  return (
    <div className="animated fadeIn">
      <List
        useCardContent
        data={criteriaImports}
        totalElements={numberOfCriteriaImports}
        loaded={criteriaImportsLoaded}
        loading={criteriaImportsLoading}
        error={criteriaImportsError}
        fetchData={fetchCriteriaImports}
        title={t('criteriaImport.criteriaImport')}
        createPermission="import_create"
        createModal={(isModalOpen, setIsModalOpen) => (
          <CriteriaImportModalForm
            isOpen={isModalOpen}
            toggleModalState={setIsModalOpen}
            onSaved={refreshCriteriaImports}
          />
        )}
        fields={[
          { key: 'id', label: t('common.id') },
          { key: 'url', label: t('common.url'), render: item => item.uploadedDocument.url },
          { key: 'updatedAt', label: t('common.updated'), dateTime: true },
          {
            key: 'title',
            label: t('importConfiguration.configuration'),
            render: item => item.configuration.title,
          },
          { key: 'status', label: t('common.status') },
          {
            key: 'download',
            label: t('common.download'),
            render: item => (
              <>
                <Button
                  className="btn-sm float-right"
                  onClick={() => {
                    getSignedUrlAndOpenFile(item.uploadedDocument.url);
                  }}
                >
                  <i className="file-icon far fa-file-alt" />
                </Button>
              </>
            ),
          },
        ]}
        noDataLabel={t('criteriaImport.noCriteriaImports')}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    criteriaImports: state.criteriaImport.data.content,
    numberOfCriteriaImports: state.criteriaImport.data.totalElements,
    criteriaImportsLoading: state.criteriaImport.loading,
    criteriaImportsLoaded: state.criteriaImport.loaded,
    criteriaImportsError: state.criteriaImport.error,
  };
};

const mapDispatchToProps = {
  getApplication,
  getCriteriaImports,
};

export default connect(mapStateToProps, mapDispatchToProps)(CriteriaImport);
