import React, { useEffect, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import {
  ImportConfigurationModel,
  Operations,
  PageQueryParams,
  QueryParams,
} from 'src/shared/models';
import { List } from 'src/shared/components';

import {
  deleteImportConfiguration,
  getImportConfigurations,
} from 'src/redux/actions/import-configuration.action';
import ImportConfigurationModalForm from './forms/ImportConfigurationModalForm';

interface Props {
  deleteImportConfiguration: (appId: string, id: number) => void;
  getImportConfigurations: (appId: string, queryParams: PageQueryParams) => void;
  getApplication: Function;
  application: ApplicationModel;
  importConfigurations: ImportConfigurationModel[];
  numberOfImportConfigurations: number;
  importConfigurationsLoading: boolean;
  importConfigurationsLoaded: boolean;
  importConfigurationsError: boolean;
}

const ImportConfiguration: FC<Props> = ({
  deleteImportConfiguration,
  getImportConfigurations,
  getApplication,
  application,
  importConfigurations,
  numberOfImportConfigurations,
  importConfigurationsLoading,
  importConfigurationsLoaded,
  importConfigurationsError,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [editDocument, setEditDocument] = useState<ImportConfigurationModel>();
  const [queryParamsDef, setQueryParamsDef] = useState<QueryParams>({
    page: 0,
    size: 20,
    limit: 20,
  });

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  const removeImportConfiguration = async (importConfiguration?: ImportConfigurationModel) => {
    if (importConfiguration) {
      await deleteImportConfiguration(appId, importConfiguration.id);
    }
  };

  const editImportConfiguration = async (id: React.ReactText) => {
    setEditDocument(importConfigurations.find(e => e.id === id));
    setEditModalOpen(true);
  };

  const fetchImportConfigurations = (queryParams: QueryParams) => {
    setQueryParamsDef(queryParams);
    getImportConfigurations(appId, queryParams);
  };

  const refreshImportConfigurations = () => {
    getImportConfigurations(appId, queryParamsDef);
  };

  return (
    <div className="animated fadeIn">
      <List
        useCardContent
        data={importConfigurations}
        totalElements={numberOfImportConfigurations}
        loaded={importConfigurationsLoaded}
        loading={importConfigurationsLoading}
        error={importConfigurationsError}
        fetchData={fetchImportConfigurations}
        title={t('importConfiguration.importConfiguration')}
        createPermission="import_create"
        createModal={(isModalOpen, setIsModalOpen) => (
          <ImportConfigurationModalForm
            isOpen={isModalOpen}
            toggleModalState={setIsModalOpen}
            onSaved={refreshImportConfigurations}
          ></ImportConfigurationModalForm>
        )}
        fields={[
          { key: 'title', label: t('common.title') },
          { key: 'type', label: t('common.type') },
          {
            key: 'operations',
            label: t('common.operations'),
            operations: [Operations.UPDATE, Operations.DELETE],
          },
        ]}
        noDataLabel={t('importConfiguration.noImportConfigurations')}
        deleteFunction={removeImportConfiguration}
        editFunction={editImportConfiguration}
        deleteTitle={t('importConfiguration.deleteConfiguration')}
        deleteText={t('importConfiguration.deleteConfigurationConfirmMessage')}
        updatePermission="import_update"
        deletePermission="import_delete"
      />
      <ImportConfigurationModalForm
        editDocument={editDocument}
        isOpen={isEditModalOpen}
        toggleModalState={setEditModalOpen}
        onSaved={refreshImportConfigurations}
      ></ImportConfigurationModalForm>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    importConfigurations: state.importConfigurations.data.content,
    numberOfImportConfigurations: state.importConfigurations.data.totalElements,
    importConfigurationsLoading: state.importConfigurations.loading,
    importConfigurationsLoaded: state.importConfigurations.loaded,
    importConfigurationsError: state.importConfigurations.error,
  };
};

const mapDispatchToProps = {
  getApplication,
  getImportConfigurations,
  deleteImportConfiguration,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportConfiguration);
