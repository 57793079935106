import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';
import { Formik, Form, FormikValues } from 'formik';

import { CustomFieldModel } from 'src/shared/models/custom-fields.model';

import { customFieldsSchema } from 'src/shared/schemas/validation.schema';

import {
  getCustomFields,
  createCustomField,
  updateCustomField,
} from 'src/redux/actions/custom-fields.action';

import TextField from 'src/shared/components/form-inputs/TextField';
import Spinner from 'src/shared/components/Spinner';
import { ModalEventType } from '../CustomFields';

const defaultConfig = [
  {
    positionList: {
      visible: true,
    },
  },
  {
    talentPoolList: {
      visible: true,
    },
  },
];

interface Props {
  isOpen: boolean;
  modalEvent: ModalEventType;
  selectedCustomField: CustomFieldModel | undefined;
  toggleModal: Function;
  getCustomFields: Function;
  createCustomField: Function;
  updateCustomField: Function;
}

const CustomFieldModal: React.FC<Props> = ({
  selectedCustomField,
  isOpen,
  modalEvent,
  toggleModal,
  getCustomFields,
  createCustomField,
  updateCustomField,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const isEditing = modalEvent === 'edit';
  const modalTitle = isEditing ? 'customFields.editCustomField' : 'customFields.createCustomField';
  const submitButtonText = isEditing ? 'common.save' : 'common.create';

  const getFormInitValues = () => ({
    key: selectedCustomField?.key || '',
    title: selectedCustomField?.title || '',
    namespace: selectedCustomField?.namespace || '',
    config: JSON.stringify(selectedCustomField?.config || defaultConfig),
  });

  const handleSubmit = async (values: FormikValues) => {
    if (isEditing && selectedCustomField) {
      await updateCustomField(appId, selectedCustomField.id, values);
    } else {
      await createCustomField(appId, values);
    }

    getCustomFields(appId);
    toggleModal();
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{t(modalTitle)}</ModalHeader>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={getFormInitValues()}
        validationSchema={customFieldsSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="key">{t('common.key')}</Label>
                    <TextField name="key" />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="title">{t('common.title')}</Label>
                    <TextField name="title" />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="namespace">{t('common.namespace')}</Label>
                    <TextField name="namespace" />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>

            <ModalFooter>
              <Spinner loading={isSubmitting} />

              <Button type="submit" color="success" disabled={isSubmitting}>
                {t(submitButtonText)}
              </Button>

              <Button color="secondary" onClick={() => toggleModal()}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapDispatchToProps = {
  getCustomFields,
  createCustomField,
  updateCustomField,
};

export default connect(null, mapDispatchToProps)(CustomFieldModal);
