export const baseStyle = {
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 140,
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#bdbdbd',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#a4a4a4',
  outline: 'none',
  transition: 'border .25s ease-in-out',
  cursor: 'pointer',
};

export const activeStyle = {
  borderColor: '#2196f3',
};

export const acceptStyle = {
  borderColor: '#00e676',
};

export const rejectStyle = {
  borderColor: '#ff1744',
};
