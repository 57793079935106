import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';

import { messageType as messageTypes } from 'src/shared/constants/questionnaire.constans';

import TextField from 'src/shared/components/form-inputs/TextField';

const labelTexts = {
  [messageTypes.SIMPLE_TEXT_MESSAGE]: 'common.message',
  [messageTypes.SHOW_POSITION_MESSAGE]: 'common.message',
  [messageTypes.DOCUMENT_UPLOAD_CONTROLLER]: 'common.message',
  [messageTypes.LOCATION_PART_OF_COUNTRY]: 'questionnaire.chooseCountryPartsText',
  [messageTypes.LOCATION_COUNTY]: 'questionnaire.chooseCountyText',
  [messageTypes.LOCATION_CITY]: 'questionnaire.chooseCityText',
  [messageTypes.IMAGE_MESSAGE]: 'questionnaire.imageMessageText',
  [messageTypes.START_CONTROLLER]: 'questionnaire.flagStartMessage',
  [messageTypes.SHOW_INTERVIEW_EVENTS_MESSAGE]: 'common.message',
};

interface Props {
  name?: string;
  messageType: string;
}

const SimpleMessageForm: React.FC<Props> = ({ name, messageType }) => {
  const { t } = useTranslation();

  return (
    <FormGroup>
      <Label for={name || 'text'}>{t(labelTexts[messageType])}</Label>
      <TextField name={name || 'text'} />
    </FormGroup>
  );
};

export default SimpleMessageForm;
