import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Badge, Nav, NavItem, NavLink as RsNavLink } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';

import { ApplicationModel } from '../../redux/actions/applications.action';
import { UserModel } from '../../redux/reducers/user.reducer';
import { hasPermission } from '../../shared/services/permissions.service';

import 'react-perfect-scrollbar/dist/css/styles.css';

interface Props extends WithTranslation {
  application: ApplicationModel;
  user: UserModel;
}

/* eslint-disable */
class DefaultNav extends Component<Props | any> {
  hideMobile() {
    if (document.body.classList.contains('sidebar-show')) {
      document.body.classList.toggle('sidebar-show');
    }
  }

  // nav list
  navList = (items: any) => {
    return items.map((item: any, index: any) => this.navType(item, index));
  };

  // nav type
  navType = (item: any, idx: any) =>
    item.title
      ? this.navTitle(item, idx)
      : item.divider
      ? this.navDivider(item, idx)
      : item.label
      ? this.navLabel(item, idx)
      : item.children
      ? this.navDropdown(item, idx)
      : this.navItem(item, idx);

  // nav list section title
  navTitle = (title: any, key: any) => {
    const classes = classNames('nav-title', title.class);

    return (
      <li key={key} className={classes}>
        {this.navWrapper(title)}
      </li>
    );
  };

  // simple wrapper for nav-title item
  navWrapper = (item: any) => {
    return item.wrapper && item.wrapper.element
      ? React.createElement(item.wrapper.element, item.wrapper.attributes, item.name)
      : item.name;
  };

  // nav list divider
  navDivider = (divider: any, key: any) => {
    const classes = classNames('divider', divider.class);

    return <li key={key} className={classes} />;
  };

  // nav label with nav link
  navLabel = (item: any, key: any) => {
    const classes = {
      item: classNames('hidden-cn', item.class),
      link: classNames('nav-label', item.class ? item.class : ''),
      icon: classNames(
        'nav-icon',
        !item.icon ? 'far fa-dot-circle' : item.icon,
        item.label.variant ? `text-${item.label.variant}` : '',
        item.label.class ? item.label.class : '',
      ),
    };

    return this.navLink(item, key, classes);
  };

  // nav dropdown
  navDropdown = (item: any, key: any) => {
    const classIcon = classNames('nav-icon', item.icon);
    const attributes = JSON.parse(JSON.stringify(item.attributes || {}));
    const classes = classNames('nav-link', 'nav-dropdown-toggle', item.class, attributes.class);
    delete attributes.class;

    if (!item.children.length) {
      return null;
    }

    return (
      <li key={key} className={this.activeRoute(item.url, this.props)}>
        <a className={classes} href="#/" onClick={this.handleClick.bind(this)} {...attributes}>
          <i className={classIcon} />
          {item.name}
          {this.navBadge(item.badge)}
        </a>
        <ul className="nav-dropdown-items">{this.navList(item.children)}</ul>
      </li>
    );
  };

  // nav item with nav link
  navItem = (item: any, key: any) => {
    const classes = {
      item: classNames(item.class),
      link: classNames('nav-link', item.variant ? `nav-link-${item.variant}` : ''),
      icon: classNames('nav-icon', item.icon),
    };

    return this.navLink(item, key, classes);
  };

  // nav link
  navLink = (item: any, key: any, classes: any) => {
    const url = item.url || '';
    const itemIcon = React.createElement('i', { className: classes.icon });
    const itemBadge = this.navBadge(item.badge);
    const attributes = item.attributes || {};

    return (
      <NavItem key={key} className={classes.item}>
        {attributes.disabled ? (
          <RsNavLink href="#/" className={classes.link} {...attributes}>
            {itemIcon}
            {item.name}
            {itemBadge}
          </RsNavLink>
        ) : null}
        {this.isExternal(url) ? (
          <RsNavLink href={url} className={classes.link} active {...attributes}>
            {itemIcon}
            {item.name}
            {itemBadge}
          </RsNavLink>
        ) : (
          <NavLink
            to={url}
            className={classes.link}
            activeClassName="active"
            onClick={this.hideMobile}
            {...attributes}
          >
            {itemIcon}
            {item.name}
            {itemBadge}
          </NavLink>
        )}
      </NavItem>
    );
  };

  // badge addon to NavItem
  navBadge = (badge: any) => {
    if (badge) {
      const classes = classNames(badge.class);

      return (
        <Badge className={classes} color={badge.variant}>
          {badge.text}
        </Badge>
      );
    }

    return null;
  };

  isExternal = (url: any) => {
    const link = url ? url.substring(0, 4) : '';

    return link === 'http';
  };

  activeRoute(routeName: any, props: any) {
    return props.location.pathname.indexOf(routeName) > -1
      ? 'nav-item nav-dropdown open'
      : 'nav-item nav-dropdown';
  }

  handleClick(e: React.MouseEvent) {
    e.preventDefault();

    const el = e.target as HTMLElement;

    if (el && el.parentElement) {
      el.parentElement.classList.toggle('open');
    }
  }

  filter(navItems: any[]) {
    return navItems.filter(item => {
      if (item.children) {
        item.children = this.filter(item.children);
      }

      return (
        (!item.permission || (item.permission && hasPermission(item.permission))) &&
        (item.url || (!item.url && item.children && item.children.length))
      );
    });
  }

  render() {
    const { t } = this.props;
    const { overallConversationsCount, id } = this.props.application;
    let navItems: any[] = [];

    if (id) {
      navItems = [
        {
          name: `${t('common.summary')}`,
          url: `/application/${id}/summary`,
          icon: 'far fa-dot-circle',
          permission: ['application_read'],
        },
        {
          name: `${t('common.conversations')}`,
          url: `/application/${id}/conversations`,
          icon: 'fas fa-comments',
          permission: ['conversations_read'],
          badge: overallConversationsCount
            ? {
                variant: 'info',
                text: overallConversationsCount,
              }
            : null,
        },
        // {
        //   name: 'Talent Pools',
        //   url: '/talent-pools',
        //   icon: 'fas fa-users',
        //   permission: ['talentpool_read'],
        // },
        {
          name: `${t('common.modules')}`,
          url: `/application/${id}/modules`,
          icon: 'fas fa-box-open',
          children: [
            {
              name: `${t('common.faqDatasetUpload')}`,
              url: `/application/${id}/modules/faq`,
              icon: '',
              permission: ['faq_update'],
            },
            {
              name: `${t('dynamicFaq.dynamicFaq')}`,
              url: `/application/${id}/modules/dynamic_faq/imports`,
              icon: '',
              permission: ['dynamicfaq_faq_import_create'],
            },
            {
              name: `${t('common.dynamicMenuUpload')}`,
              url: `/application/${id}/modules/dynamic_menu`,
              icon: '',
              permission: ['dynamic_menu_create'],
            },
            {
              name: `${t('dynamicConvFlow.dynamicConvFlow')}`,
              url: `/application/${id}/modules/dynamic_conv_flow/imports`,
              icon: '',
              permission: ['dynamic-convflow_create'],
            },
            {
              name: `${t('common.events')}`,
              url: `/application/${id}/modules/events`,
              icon: '',
              permission: ['event_events_read'],
            },
            {
              name: `${t('common.subscribingLists')}`,
              url: `/application/${id}/modules/subscribing-lists`,
              icon: '',
              permission: ['subscribing-lists_read'],
            },
            {
              name: `${t('common.batchMessages')}`,
              url: `/application/${id}/modules/batch-messages`,
              icon: '',
              permission: ['outbound-messages_read'],
            },
            {
              name: `${t('common.complaints')}`,
              url: `/application/${id}/modules/complaints`,
              icon: '',
              permission: ['boe_issues_read'],
            },
            {
              name: `${t('messageToAdmin.messageToAdmin')}`,
              url: `/application/${id}/modules/messagesToAdmin`,
              icon: '',
              permission: ['complaint_complaints_read'],
            },
            {
              name: `${t('common.documents')}`,
              url: `/application/${id}/modules/documents`,
              icon: '',
              permission: ['simple-lists_read'],
            },
            {
              name: `${t('common.followUpNotifications')}`,
              url: `/application/${id}/modules/follow-up-notifications/`,
              icon: '',
              permission: ['follow_up_notifications_read'],
            },
            {
              name: `${t('common.forms')}`,
              url: `/application/${id}/modules/forms`,
              icon: '',
              permission: ['form_forms_read'],
            },
          ],
        },
        {
          name: `${t('common.helpdesk')}`,
          url: `/application/${id}/helpdesk`,
          icon: 'far fa-life-ring',
          children: [
            {
              name: `${t('helpdesk.groups')}`,
              url: `/application/${id}/helpdesk/groups`,
              icon: '',
              permission: ['helpdesk_groups_read'],
            },
            {
              name: `${t('helpdesk.instantAnswers')}`,
              url: `/application/${id}/helpdesk/instantAnswers`,
              icon: '',
              permission: ['helpdesk_suggested-replies_read'],
            },
            {
              name: `${t('common.tickets')}`,
              url: `/application/${id}/helpdesk/tickets`,
              icon: '',
              permission: ['helpdesk_tickets_read'],
            },
            {
              name: `${t('helpdesk.report')}`,
              url: `/application/${id}/helpdesk/report`,
              icon: '',
              permission: ['helpdesk_tickets_read'],
            },
          ],
        },
        {
          name: `${t('common.aiTrainingRoom')}`,
          url: `/application/${id}/ai-training-room`,
          icon: 'fas fa-robot',
          children: [
            {
              name: `${t('common.importTrainingData')}`,
              url: `/application/${id}/ai-training-room/import-training-data`,
              icon: ' ',
              permission: ['trainingdata-import_create'],
            },
            {
              name: `${t('common.trainingData')}`,
              url: `/application/${id}/ai-training-room/training-data`,
              icon: ' ',
              permission: ['training-data_read'],
            },
          ],
        },
        {
          name: `${t('common.reportsAndAnalytics')}`,
          url: `/application/${id}/reports-analytics`,
          icon: 'fas fa-file-contract',
          children: [
            {
              name: `${t('common.feedbacks')}`,
              url: `/application/${id}/reports-analytics/feedbacks`,
              icon: '',
              permission: ['feedbacks_read'],
            },
            {
              name: `${t('common.usageStatistics')}`,
              url: `/application/${id}/reports-analytics/usage-statistics`,
              icon: '',
              permission: ['analytics_read'],
            },
            {
              name: `${t('common.intentStatistics')}`,
              url: `/application/${id}/reports-analytics/intent-statistics`,
              icon: '',
              permission: ['analytics_read'],
            },
            {
              name: `${t('common.topicStatistics')}`,
              url: `/application/${id}/reports-analytics/topic-statistics`,
              icon: '',
              permission: ['analytics_read'],
            },
            {
              name: `${t('common.applicationEfficiency')}`,
              url: `/application/${id}/reports-analytics/application-efficiency`,
              icon: '',
              permission: ['recruiter_analytics_read'],
            },
            {
              name: `${t('common.unrecognizedSentences')}`,
              url: `/application/${id}/reports-analytics/unrecognized-sentences`,
              icon: ' ',
              permission: ['recognitionlog_read'],
            },
            {
              name: `${t('common.outgoingMessageReport')}`,
              url: `/application/${id}/reports-analytics/outbound-messages`,
              icon: '',
              permission: ['outbound-messages_read'],
            },
            {
              name: `${t('common.employeeReports')}`,
              url: `/application/${id}/reports-analytics/employee-chatbot-usage-reports`,
              icon: '',
              permission: ['employee_employee_report_read'],
            },
          ],
        },
        {
          name: `${t('common.recruiter')}`,
          url: `/application/${id}/recruiter`,
          icon: 'fas fa-users',
          children: [
            {
              name: `${t('common.positions')}`,
              url: `/application/${id}/recruiter/positions`,
              icon: '',
              permission: ['position_positions_read'],
            },
            {
              name: `${t('common.positionApplications')}`,
              url: `/application/${id}/recruiter/positions/applications`,
              icon: '',
              permission: ['position_applicants_read'],
            },
            {
              name: `${t('common.talentPool')}`,
              url: `/application/${id}/recruiter/talent-pool`,
              icon: '',
              permission: ['position_talentpool_applicants_read'],
            },
            {
              name: `${t('common.questionnaire')}`,
              url: `/application/${id}/recruiter/questionnaire`,
              icon: '',
              permission: ['position_questionnaire_messages_read'],
            },
            {
              name: `${t('common.interviews')}`,
              url: `/application/${id}/recruiter/interviews`,
              icon: '',
              permission: ['interview_events_read'],
            },
            {
              name: `${t('common.criteriaCategories')}`,
              url: `/application/${id}/recruiter/criteria-categories`,
              icon: '',
              permission: ['position_criterias_read'],
            },
            {
              name: `${t('criteriaImport.criteriaImport')}`,
              url: `/application/${id}/recruiter/criteria-import`,
              icon: '',
              permission: ['position_criterias_read'],
            },
            {
              name: `${t('common.labels')}`,
              url: `/application/${id}/recruiter/labels`,
              icon: '',
              permission: ['position_label_categories_read'],
            },
          ],
        },
        {
          name: `${t('common.hrops')}`,
          url: `/application/${id}/hrops`,
          icon: 'fas fa-user-friends',
          children: [
            {
              name: `${t('common.employees')}`,
              url: `/application/${id}/employee/employees/`,
              icon: '',
              permission: ['employee_tags_read'],
            },
            {
              name: `${t('employee.importEmployees')}`,
              url: `/application/${id}/import-employee`,
              icon: '',
              permission: ['employee_employees_create'],
            },
            {
              name: `${t('salaryAdvance.salaryAdvance')}`,
              url: `/application/${id}/hrops/salary-advance`,
              icon: '',
              permission: ['salary_advances_create'],
            },
          ],
        },
        {
          name: `${t('surveys.surveys')}`,
          url: `/application/${id}/surveys`,
          icon: 'fas fa-tasks',
          children: [
            {
              name: `${t('surveys.surveyAdministration')}`,
              url: `/application/${id}/surveys`,
              icon: '',
              permission: ['survey_surveys_read'],
            },
          ],
        },
        {
          name: `${t('common.systemManagement')}`,
          url: `/application/${id}/system-management`,
          icon: 'fas fa-wrench',
          permission: ['system-management-menu_read'],
          children: [
            {
              name: `${t('conversationFlowVariables.conversationFlowVariables')}`,
              url: `/application/${id}/system-management/conversationflow`,
              icon: '',
              permission: ['conversationflow_variables_read'],
            },
            {
              name: `${t('common.customFields')}`,
              url: `/application/${id}/system-management/custom-fields`,
              icon: '',
              permission: ['customlist_custom-lists_read'],
            },
            {
              name: `${t('common.customLists')}`,
              url: `/application/${id}/system-management/custom-lists`,
              icon: '',
              permission: ['application_custom-fields_read'],
            },
            {
              name: `${t('common.settings')}`,
              url: `/application/${id}/system-management/settings`,
              icon: '',
              permission: ['application-settings_read'],
            },
            {
              name: `${t('common.regexPatterns')}`,
              url: `/application/${id}/system-management/regex-patterns`,
              icon: '',
              permission: ['regular-expressions_read'],
            },
            {
              name: `${t('common.regexReplacerPatterns')}`,
              url: `/application/${id}/system-management/regex-replacer-patterns`,
              icon: '',
              permission: ['regular-expression-replacers_read'],
            },
            {
              name: `${t('common.expressions')}`,
              url: `/application/${id}/system-management/expressions`,
              icon: '',
              permission: ['expressions_read'],
            },
            {
              name: `${t('common.importExport')}`,
              url: `/application/${id}/system-management/import-export`,
              icon: '',
              permission: ['migration_export_read'],
            },
            {
              name: `${t('importConfiguration.importConfiguration')}`,
              url: `/application/${id}/system-management/import-configuration`,
              icon: '',
              permission: ['import_read'],
            },
            {
              name: `${t('common.importConversationFlowVariables')}`,
              url: `/application/${id}/system-management/import-conversation-flow-variables`,
              icon: '',
              permission: ['system-management-menu_read'],
            },
          ],
        },
        {
          name: `${t('common.translations')}`,
          url: `/application/${id}/translations`,
          icon: 'fas fa-book-open',
          children: [
            {
              name: `${t('translations.languageSettings')}`,
              url: `/application/${id}/translations/languages`,
              icon: '',
              permission: ['translation_languages_read'],
            },
            {
              name: `${t('translations.contentTranslations')}`,
              url: `/application/${id}/translations/content-translations`,
              icon: '',
              permission: ['translation_surveys_read'],
            },
          ],
        },
        {
          name: `${t('issue.issues')}`,
          url: `/application/${id}/issue/issues`,
          icon: 'fas fa-envelope',
          children: [
            {
              name: `${t('issue.categories')}`,
              url: `/application/${id}/issue/categories`,
              icon: '',
              permission: ['issue_categories_read'],
            },
            {
              name: `${t('issue.issues')}`,
              url: `/application/${id}/issue/issues`,
              icon: '',
              permission: ['issue_issues_create'],
            },
            // {
            //   name: `${t('issueReports.averagedReplyTimeReportsByContextData')}`,
            //   url: `/application/${id}/issue/issues/reports/average-reply-time-by-context-data`,
            //   icon: '',
            //   permission: ['issue_issue_report_read'],
            // },
            {
              name: `${t('issueReports.averagedReplyTimeReportsByDashboardUser')}`,
              url: `/application/${id}/issue/reports/average-reply-time-by-dashboard-user`,
              icon: '',
              permission: ['issue_issue_report_read'],
            },
          ],
        }
      ];
    }

    navItems = this.filter(navItems);

    return (
      <PerfectScrollbar className="sidebar-nav">
        {/* <SidebarHeader {...this.props.user} />
        <SidebarForm /> */}
        <Nav>{this.navList(navItems)}</Nav>
        {/* <SidebarFooter />
        <SidebarMinimizer /> */}
      </PerfectScrollbar>
    );
  }
}

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  user: state.user.data,
});

export default connect(mapStateToProps)(withTranslation()(DefaultNav));
