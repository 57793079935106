import React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckPermission } from 'src/shared/components';
import styles from './Tags.module.scss';

interface Props {
  id?: string;
  context: string;
  toggleModal?: Function;
  createPermission: string;
}

const TagPill: React.FC<Props> = ({ id, context, toggleModal, createPermission }) => {
  const { t } = useTranslation();
  const tagText = context === 'new' ? t('employee.newTag') : t('employee.assignTag');

  return (
    <CheckPermission variant="enableIf" permissions={createPermission ? [createPermission] : []}>
      <button
        type="button"
        id={id}
        className={styles.tagCreatePill}
        onClick={() => (toggleModal ? toggleModal('create') : null)}
      >
        <i className="fas fa-plus mr-1" />
        {tagText}
      </button>
    </CheckPermission>
  );
};

export default TagPill;
