import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';
import moment from 'moment';
import Select from 'react-select';
import { OptionsType, ValueType } from 'react-select/src/types';
import { FormikValues } from 'formik';

import {
  getTargetMessagingPlatforms,
  sendMessageToSubscribers,
} from 'src/redux/actions/subscribing-lists.action';

import { StateModel } from 'src/shared/models/default-state.model';
import { SubscribingListModel } from 'src/shared/models/subscribing-lists.model';

import { getActivePlatformObjects } from 'src/shared/utils/platforms.util';
import addToast from 'src/shared/utils/notification.util';

import Email from './platforms/Email/Email';
// import FacebookMessenger from './platforms/FacebookMessenger/FacebookMessenger';
// import FacebookMessengerPreview from './platforms/FacebookMessenger/FacebookMessengerPreview';

interface Props extends RouteComponentProps<{ appId: string }>, WithTranslation {
  list: SubscribingListModel | undefined;
  lists: SubscribingListModel[];
  isOpen: boolean;
  toggleModal: Function;
  getTargetMessagingPlatforms: Function;
  sendMessageToSubscribers: Function;
  messagingPlatforms: StateModel;
  messageSending: StateModel;
}

interface State {
  listOptions: OptionsType<ValModel>;
  selectedList: OptionsType<ValModel>;
  platformOptions: OptionsType<ValModel>;
  selectedPlatform: OptionsType<ValModel>;
}

interface ValModel {
  value: string;
  label: string;
}

class SendMessageModal extends Component<Props> {
  state: State = {
    listOptions: [],
    selectedList: [],
    platformOptions: [],
    selectedPlatform: [],
  };

  componentDidUpdate = async (prev: Props) => {
    const { isOpen, list, lists } = this.props;
    const { listOptions } = this.state;

    if (isOpen) {
      if (!listOptions.length) {
        await this.createListOptions(lists);
      }

      if (list && prev.list !== list) {
        this.setSelectedListOption();
        this.setPlatformOptions();
      }
    }
  };

  createListOptions = async (lists: SubscribingListModel[]) => {
    const listOptions: ValModel[] = [];

    lists.map((list: SubscribingListModel) => {
      if (list.subscriberCount > 0) {
        const label = list.name;
        const value = list.id ? list.id.toString() : '';

        if (label && value) {
          return listOptions.push({ label, value });
        }
      }

      return null;
    });

    if (!listOptions.length) return;

    this.setState({
      listOptions,
    });
  };

  setSelectedListOption = () => {
    const { list } = this.props;
    const { listOptions } = this.state;

    if (list && listOptions.length > 0) {
      const selectedList = listOptions.find(l => l.value === list.id.toString());

      this.setState({
        selectedList: [selectedList],
      });
    }
  };

  setPlatformOptions = async () => {
    const { appId } = this.props.match.params;
    const { selectedList } = this.state;

    if (selectedList.length > 0) {
      await this.props.getTargetMessagingPlatforms(appId, selectedList[0].value);
      const { messagingPlatforms } = this.props;

      if (messagingPlatforms.data.length > 0) {
        const platformObjects = getActivePlatformObjects(messagingPlatforms.data);

        this.setState({
          platformOptions: platformObjects,
          selectedPlatform: [platformObjects[0]],
        });
      } else {
        this.setState({
          platformOptions: [],
          selectedPlatform: [],
        });
      }
    }
  };

  handleSelectionChange = async (option: ValueType<ValModel>, type: string) => {
    this.setState({ [type]: [option] }, () => this.setPlatformOptions());
  };

  getFormComponentToRender = () => {
    const { selectedPlatform } = this.state;
    const { toggleModal, messageSending, t } = this.props;

    if (!selectedPlatform.length) {
      return (
        <Alert className="text-center m-0" color="info">
          {t('common.noPlatforms')}
        </Alert>
      );
    }

    const props = {
      sendMessage: this.sendMessage,
      toggleModal,
      messageSending,
    };

    switch (selectedPlatform[0].value) {
      case 'email':
        return <Email {...props} />;
      // case 'Facebook':
      //   return <FacebookMessenger {...props} />;
      default:
        return null;
    }
  };

  getPreviewComponentToRender = () => {
    const { selectedPlatform } = this.state;

    if (selectedPlatform.length > 0) {
      switch (selectedPlatform[0].value) {
        // case 'Facebook':
        //   return <FacebookMessengerPreview />;
        default:
          return null;
      }
    }
  };

  sendMessage = async (values: FormikValues) => {
    const { t } = this.props;
    const { appId } = this.props.match.params;
    const { selectedList, selectedPlatform } = this.state;

    const listId = selectedList[0].value;
    const platform = selectedPlatform[0].value;

    const year = moment(values.sendAtDate).year();
    const month = moment(values.sendAtDate).month();
    const day = moment(values.sendAtDate).date();

    const hour = moment(values.sendAtTime).hour();
    const minute = moment(values.sendAtTime).minute();

    const startAt = new Date(year, month, day, hour, minute).toISOString();

    const payload = {
      startAt,
      replyTo: values.replyTo,
      subject: values.subject,
      body: values.message,
    };

    await this.props.sendMessageToSubscribers(appId, listId, platform, payload);
    const { error } = this.props.messageSending;

    if (!error) {
      addToast('success', `${t('subscribingLists.messageScheduledMessage')}`);
      this.props.toggleModal();
    }
  };

  render() {
    const { isOpen, toggleModal, messagingPlatforms, t } = this.props;
    const { selectedPlatform, platformOptions, selectedList, listOptions } = this.state;

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>{t('subscribingLists.messageSubscribers')}</ModalHeader>
        <ModalBody>
          <Row form>
            <Col>
              <FormGroup>
                <Label for="subscribingList">{t('common.subscribingList')}</Label>
                <Select
                  name="listSelect"
                  className="mb-3"
                  value={selectedList}
                  onChange={(option: ValueType<ValModel>) =>
                    this.handleSelectionChange(option, 'selectedList')
                  }
                  options={listOptions}
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label for="platform">{t('common.platform')}</Label>
                <Select
                  name="platformSelect"
                  className="mb-3"
                  value={selectedPlatform}
                  onChange={(option: ValueType<ValModel>) =>
                    this.handleSelectionChange(option, 'selectedPlatform')
                  }
                  options={platformOptions}
                  isDisabled={!messagingPlatforms.data.length}
                  isLoading={messagingPlatforms.loading}
                />
              </FormGroup>
            </Col>
          </Row>
          {this.getFormComponentToRender()}
          {this.getPreviewComponentToRender()}
        </ModalBody>

        {(1 || !messagingPlatforms.data.length) && (
          <ModalFooter>
            <Button color="secondary" onClick={() => toggleModal()}>
              {t('common.cancel')}
            </Button>
          </ModalFooter>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state: any) => ({
  messagingPlatforms: state.targetMessagingPlatforms,
  messageSending: state.sendMessageToSubscribers,
});

const mapDispatchToProps = {
  getTargetMessagingPlatforms,
  sendMessageToSubscribers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(SendMessageModal)));
