import { Dispatch } from 'redux';
import { FormModel, PageQueryParams } from 'src/shared/models';

import {
  getForms as getFormsService,
  getForm as getFormService,
  createForm as createFormService,
  updateForm as updateFormService,
  deleteForm as deleteFormService,
  getValidationTypes as getValidationTypesService,
} from '../services/forms.service';

export const GET_FORMS = 'GET_FORMS';
export const GET_FORMS_SUCCESS = 'GET_FORMS_SUCCESS';
export const GET_FORMS_ERROR = 'GET_FORMS_ERROR';

export const DELETE_FORM = 'DELETE_FORM';
export const DELETE_FORM_REQUEST = 'DELETE_FORM_REQUEST';
export const DELETE_FORM_SUCCESS = 'DELETE_FORM_SUCCESS';
export const DELETE_FORM_ERROR = 'DELETE_FORM_ERROR';

export const CREATE_FORM = 'FORMS/CREATE_FORM';
export const CREATE_FORM_SUCCESS = 'FORMS/CREATE_FORM_SUCCESS';
export const CREATE_FORM_ERROR = 'FORMS/CREATE_FORM_ERROR';

export const READ_FORM = 'FORMS/READ_FORM';
export const READ_FORM_SUCCESS = 'FORMS/READ_FORM_SUCCESS';
export const READ_FORM_ERROR = 'FORMS/READ_FORM_ERROR';

export const UPDATE_FORM = 'FORMS/UPDATE_FORM';
export const UPDATE_FORM_SUCCESS = 'FORMS/UPDATE_FORM_SUCCESS';
export const UPDATE_FORM_ERROR = 'FORMS/UPDATE_FORM_ERROR';

export const REVERT_FORM = 'FORMS/CLEAR_FORM';

export const GET_VALIDATION_TYPES_REQUEST = 'FORMS/GET_VALIDATION_TYPES_REQUEST';
export const GET_VALIDATION_TYPES_SUCCESS = 'FORMS/GET_VALIDATION_TYPES_SUCCESS';
export const GET_VALIDATION_TYPES_ERROR = 'FORMS/GET_VALIDATION_TYPES_ERROR';

export const getForms = (appId: string, queryParams: PageQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_FORMS,
    });

    try {
      dispatch({
        type: GET_FORMS_SUCCESS,
        payload: await getFormsService(appId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_FORMS_ERROR,
        error,
      });
    }
  };
};

export const getForm = (appId: string, formId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: READ_FORM,
    });

    try {
      const response = await getFormService(appId, formId);
      dispatch({
        type: READ_FORM_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: READ_FORM_ERROR,
        error,
      });
    }
  };
};

export const deleteForm = (appId: string, formId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_FORM,
    });

    try {
      await deleteFormService(appId, formId);
      dispatch({
        type: DELETE_FORM_SUCCESS,
        payload: { formId },
      });
    } catch (error) {
      dispatch({
        type: DELETE_FORM_ERROR,
        error,
      });
    }
  };
};

export const createForm = (appId: string, data: FormModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_FORM,
    });

    const response = await createFormService(appId, data);

    try {
      dispatch({
        type: CREATE_FORM_SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: CREATE_FORM_ERROR,
        error,
      });
    }
  };
};

export const updateForm = (appId: string, formId: number, data: FormModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_FORM,
    });

    try {
      dispatch({
        type: UPDATE_FORM_SUCCESS,
        payload: await updateFormService(appId, formId, data),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_FORM_ERROR,
        error,
      });
    }
  };
};

export const revertForm = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REVERT_FORM,
    });
  };
};

export const getValidationTypes = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_VALIDATION_TYPES_REQUEST,
    });

    try {
      dispatch({
        type: GET_VALIDATION_TYPES_SUCCESS,
        payload: await getValidationTypesService(),
      });
    } catch (error) {
      dispatch({
        type: GET_VALIDATION_TYPES_ERROR,
        error,
      });
    }
  };
};
