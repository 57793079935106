import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ListGroup, ListGroupItem } from 'reactstrap';

import { positionSidebarTypes } from '../../../../../shared/constants/positions.constants';
import { PositionModel } from '../../../../../shared/models/positions.model';
import { setPositionSidebarType } from '../../../../../redux/actions/positions.action';

import styles from '../../../Positions.module.scss';

type PositionSidebarType = keyof typeof positionSidebarTypes;

interface Shortcut {
  name: PositionSidebarType;
  text: string;
  disabled?: boolean;
}

interface Props {
  position: PositionModel;
  setPositionSidebarType: Function;
}

const PositionShortcutsSidebar: React.FC<Props> = ({ position, setPositionSidebarType }) => {
  const { t } = useTranslation();

  const shortCuts: Shortcut[] = [
    {
      name: positionSidebarTypes.CREATE_PREFILTER_CRITERIA as PositionSidebarType,
      text: 'positions.createPrefilterCriteria',
      disabled: !position?.id,
    },
    {
      name: positionSidebarTypes.CRITERIA_CATEGORIES as PositionSidebarType,
      text: 'positions.prefilterCriteriaCategories',
    },
  ];

  const handleOnClick = (shortcut: Shortcut) => {
    const { disabled, name } = shortcut;
    if (!disabled) setPositionSidebarType(name);
  };

  return (
    <ListGroup>
      {shortCuts.map(sc => (
        <ListGroupItem
          key={sc.name}
          className={styles.shortcutListItem}
          disabled={sc.disabled}
          onClick={() => handleOnClick(sc)}
        >
          {t(sc.text)}
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};

const mapStateToProps = (state: any) => ({
  position: state.positions.data.position.data,
  sidebarType: state.positions.data.sidebarType,
});

const mapDispatchToProps = {
  setPositionSidebarType,
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionShortcutsSidebar);
