import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { Formik, Form, FormikValues } from 'formik';

import { readFileAsDataURL } from '../../../redux/services/filereader.service';
import { ListElementModel } from '../../../redux/reducers/list-elements.reducer';
import { FileModel } from '../../models/file.model';
import { isValidUrl } from '../../utils/url-validate.util';
import { documentSchema } from '../../schemas/validation.schema';

import TextField from '../form-inputs/TextField';
// import File from '../form-inputs/File';
import Spinner from '../Spinner';
import { getSignedUrlForDownloadService } from 'src/shared/services/upload.service';

interface Props extends WithTranslation {
  element: ListElementModel | undefined;
  cancel: Function;
  confirm: Function;
  isOpen: boolean;
  isLoading: boolean;
  errors?: any;
  isGalleryEdit?: boolean;
}

interface State {
  data: ListElementModel;
  document: FileModel;
  image: FileModel;
  deletedFileTypes: string[];
  deletedFileUrls: string[];
}

export class DocumentEditModal extends Component<Props, State> {
  state: State = {
    data: {
      name: '',
      shortDescription: '',
      description: '',
    },
    document: {
      file: undefined,
      source: undefined,
    },
    image: {
      file: undefined,
      source: undefined,
    },
    deletedFileTypes: [],
    deletedFileUrls: [],
  };

  componentDidUpdate = async (prevProps: Props) => {
    const { element: prevElement } = prevProps;
    const { element: currElement, isOpen } = this.props;

    if (!prevElement && currElement && isOpen) {
      this.setState({
        data: {
          slug: currElement.slug,
          name: currElement.name,
          shortDescription: currElement.shortDescription,
          description: currElement.description,
        },
        document: {
          file: null,
          source: currElement.fileUrl
            ? await getSignedUrlForDownloadService(currElement.fileUrl)
            : null,
        },
        image: {
          file: null,
          source: currElement.imageUrl
            ? await getSignedUrlForDownloadService(currElement.imageUrl)
            : null,
        },
        deletedFileTypes: [],
        deletedFileUrls: [],
      });
    }
  };

  confirmEdit = async (values: FormikValues) => {
    const { confirm, element } = this.props;
    const { name, shortDescription, description, image, document } = values;
    const { deletedFileUrls, deletedFileTypes } = this.state;

    await confirm(
      {
        ...element,
        name,
        shortDescription,
        description,
      },
      image,
      document,
      deletedFileUrls,
      deletedFileTypes,
    );
  };

  fileChange = async (
    event: React.FormEvent<HTMLInputElement>,
    name: string,
    deletedFileUrl: string,
  ) => {
    const { files } = event.currentTarget;

    if (files) {
      const fileSource = await readFileAsDataURL(files[0]);
      this.setState(prevState => ({
        ...prevState,
        [name]: {
          file: files[0],
          source: fileSource,
        },
      }));
    }

    // Ha fájl és/vagy képcsere történt
    if (deletedFileUrl && isValidUrl(deletedFileUrl)) {
      this.setState(prevState => ({
        deletedFileUrls: [...prevState.deletedFileUrls, deletedFileUrl],
        deletedFileTypes: [...prevState.deletedFileTypes, name],
      }));
    }
  };

  removeFile = (name: string, deletedFileUrl: any) => {
    (document.getElementById(name) as HTMLInputElement).value = '';

    this.setState(prevState => ({
      ...prevState,
      [name]: {
        file: null,
        source: null,
      },
    }));

    if (deletedFileUrl && isValidUrl(deletedFileUrl)) {
      this.setState(prevState => ({
        deletedFileUrls: [...prevState.deletedFileUrls, deletedFileUrl],
        deletedFileTypes: [...prevState.deletedFileTypes, name],
      }));
    }
  };

  render() {
    const { isOpen, cancel, t, isGalleryEdit } = this.props;
    const { data, document, image } = this.state;
    return (
      <Modal isOpen={isOpen} className="modal-lg">
        <ModalHeader>
          {isGalleryEdit
            ? t('foldersAndDocuments.editPhoto')
            : t('foldersAndDocuments.editDocument')}
        </ModalHeader>
        <Formik
          initialValues={{
            ...data,
            document: undefined,
            image: undefined,
            deletedFileUrls: [],
          }}
          validationSchema={documentSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await this.confirmEdit(values);
            setSubmitting(false);
          }}
          enableReinitialize
        >
          {({ errors, isSubmitting, setFieldValue }) => (
            <Form>
              <ModalBody>
                <FormGroup row>
                  <Col md="3">
                    <Label for="name">
                      {t('common.name')}
                      <sup className="text-danger"> *</sup>
                    </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <TextField
                      name="name"
                      placeholder={t('foldersAndDocuments.documentNamePlaceholder')}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col md="3">
                    <Label>{t('foldersAndDocuments.shortDescription')}</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <TextField
                      name="shortDescription"
                      placeholder={t('foldersAndDocuments.shortDescriptionPlaceholder')}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col md="3">
                    <Label>{t('common.description')}</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <TextField
                      name="description"
                      placeholder={t('foldersAndDocuments.descriptionPlaceholder')}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col md="3">
                    <Label>{t('common.slug')}</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <TextField name="slug" disabled />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col md="3">
                    <Label>
                      {isGalleryEdit ? t('common.originalPhoto') : t('common.document')}
                    </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <div className="d-flex w-100">
                      <div>
                        <Input
                          id="document"
                          type="file"
                          name="document"
                          onChange={async e => {
                            await this.fileChange(e, 'document', document.source);
                            setFieldValue('document', this.state.document.file);
                          }}
                        />
                      </div>
                      <div className="justify-content-end d-flex" style={{ flex: 1 }}>
                        {this.state.document.source && (
                          <div>
                            {/^http(s?):\/\/.+$/.test(document.source) && (
                              <Button
                                color="success mr-1"
                                className="text-dark"
                                size="md"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={document.source}
                              >
                                <i className="fas fa-cloud-download-alt" />
                              </Button>
                            )}

                            <Button
                              className="text-dark"
                              color="danger"
                              size="md"
                              onClick={() => {
                                this.removeFile('document', document.source);
                                setFieldValue('document', undefined);
                              }}
                            >
                              <i className="far fa-trash-alt" />
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </FormGroup>
                {/* Document Image Attachment Input */}
                <FormGroup row>
                  <Col md="3">
                    <Label>{isGalleryEdit ? t('common.thumbnail') : t('common.image')}</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <div className="d-flex w-100">
                      <div>
                        <Input
                          id="image"
                          type="file"
                          name="image"
                          accept="image/*"
                          onChange={async e => {
                            await this.fileChange(e, 'image', image.source);
                            setFieldValue('image', this.state.image.file);
                          }}
                        />
                      </div>
                      <div className="justify-content-end d-flex" style={{ flex: 1 }}>
                        {image.source && (
                          <div>
                            {/^http(s?):\/\/.+$/.test(image.source) && (
                              <Button
                                color="success mr-1"
                                className="text-dark"
                                size="md"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={image.source}
                              >
                                <i className="fas fa-cloud-download-alt" />
                              </Button>
                            )}

                            <Button
                              className="text-dark"
                              color="danger"
                              size="md"
                              onClick={() => {
                                this.removeFile('image', image.source);
                                setFieldValue('image', undefined);
                              }}
                            >
                              <i className="far fa-trash-alt" />
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                    {image.source && !errors.image && (
                      <img
                        className="img-thumbnail mt-2"
                        width="300"
                        src={image.source}
                        alt="Document Attachment"
                      />
                    )}
                  </Col>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Spinner loading={isSubmitting} className="mr-2" />

                <Button type="submit" color="success" disabled={isSubmitting}>
                  {t('common.update')}
                </Button>
                <Button onClick={() => cancel()} color="secondary">
                  {t('common.cancel')}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default withTranslation()(DocumentEditModal);
