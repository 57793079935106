import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'reactstrap';

import { copyToClipboard } from '../utils/misc.util';

type Placement =
  | 'auto-start'
  | 'auto'
  | 'auto-end'
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start';

interface Props {
  id: string;
  className?: string;
  valueToCopy: string;
  size?: string;
  tooltipPlacement?: Placement;
  tooltipTrigger?: string;
}

const CopyToClipboardButton: React.FC<Props> = ({
  id,
  className,
  valueToCopy,
  size = 'sm',
  tooltipPlacement = 'right',
  tooltipTrigger = 'hover',
}) => {
  const { t } = useTranslation();
  const [isLinkCopied, setLinkCopied] = useState<boolean>(false);
  const [isTooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const toggleTooltip = () => setTooltipOpen(!isTooltipOpen);

  const handleCopy = () => {
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 2000);
    copyToClipboard(valueToCopy);
  };

  return (
    <Fragment>
      <Button id={id} className={className} size={size} onClick={() => handleCopy()}>
        <i className="far fa-copy" />
      </Button>
      <Tooltip
        placement={tooltipPlacement}
        isOpen={isTooltipOpen}
        target={id}
        toggle={toggleTooltip}
        trigger={tooltipTrigger}
      >
        {isLinkCopied ? t('common.copiedToClipboard') : t('common.copyToClipboard')}
      </Tooltip>
    </Fragment>
  );
};

export default CopyToClipboardButton;
