import { StateModel } from '../../shared/models/default-state.model';
import {
  UPLOAD_FAQ_DATASET_REQUEST,
  UPLOAD_FAQ_DATASET_SUCCESS,
  UPLOAD_FAQ_DATASET_ERROR,
} from '../actions/faq-dataset.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export const faqDatasetReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case UPLOAD_FAQ_DATASET_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case UPLOAD_FAQ_DATASET_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };

    case UPLOAD_FAQ_DATASET_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default faqDatasetReducer;
