import { Dispatch } from 'redux';
import {
  PositionQuestionModel,
  PositionQuestionAnswerOptionModel,
} from 'src/shared/models/positions.model';
import { GeneralQueryParams } from '../../shared/models/query-params.model';
import {
  getPositionQuestion as getPositionQuestionService,
  getPositionQuestions as getPositionQuestionsService,
  createPositionQuestion as createPositionQuestionService,
  updatePositionQuestion as updatePositionQuestionService,
  deletePositionQuestion as deletePositionQuestionService,
  getPositionQuestionAnswerOption as getPositionQuestionAnswerOptionService,
  getPositionQuestionAnswerOptions as getPositionQuestionAnswerOptionsService,
  createPositionQuestionAnswerOption as createPositionQuestionAnswerOptionService,
  createPositionQuestionAnswerOptions as createPositionQuestionAnswerOptionsService,
  updatePositionQuestionAnswerOption as updatePositionQuestionAnswerOptionService,
  updatePositionQuestionAnswerOptionValues as updatePositionQuestionAnswerOptionValuesService,
  deletePositionQuestionAnswerOption as deletePositionQuestionAnswerOptionService,
} from '../services/position-questions.service';

export const GET_POSITION_QUESTION_REQUEST = 'position-questions/GET_POSITION_QUESTION_REQUEST';
export const GET_POSITION_QUESTION_SUCCESS = 'position-questions/GET_POSITION_QUESTION_SUCCESS';
export const GET_POSITION_QUESTION_ERROR = 'position-questions/GET_POSITION_QUESTION_ERROR';

export const GET_POSITION_QUESTIONS_REQUEST = 'position-questions/GET_POSITION_QUESTIONS_REQUEST';
export const GET_POSITION_QUESTIONS_SUCCESS = 'position-questions/GET_POSITION_QUESTIONS_SUCCESS';
export const GET_POSITION_QUESTIONS_ERROR = 'position-questions/GET_POSITION_QUESTIONS_ERROR';

export const CREATE_POSITION_QUESTION_REQUEST =
  'position-questions/CREATE_POSITION_QUESTION_REQUEST';
export const CREATE_POSITION_QUESTION_SUCCESS =
  'position-questions/CREATE_POSITION_QUESTION_SUCCESS';
export const CREATE_POSITION_QUESTION_ERROR = 'position-questions/CREATE_POSITION_QUESTION_ERROR';

export const UPDATE_POSITION_QUESTION_REQUEST =
  'position-questions/UPDATE_POSITION_QUESTION_REQUEST';
export const UPDATE_POSITION_QUESTION_SUCCESS =
  'position-questions/UPDATE_POSITION_QUESTION_SUCCESS';
export const UPDATE_POSITION_QUESTION_ERROR = 'position-questions/UPDATE_POSITION_QUESTION_ERROR';

export const DELETE_POSITION_QUESTION_REQUEST =
  'position-questions/DELETE_POSITION_QUESTION_REQUEST';
export const DELETE_POSITION_QUESTION_SUCCESS =
  'position-questions/DELETE_POSITION_QUESTION_SUCCESS';
export const DELETE_POSITION_QUESTION_ERROR = 'position-questions/DELETE_POSITION_QUESTION_ERROR';

export const GET_POSITION_QUESTION_ANSWER_OPTION_REQUEST =
  'position-questions/GET_POSITION_QUESTION_ANSWER_OPTION_REQUEST';
export const GET_POSITION_QUESTION_ANSWER_OPTION_SUCCESS =
  'position-questions/GET_POSITION_QUESTIO_ANSWER_OPTIONN_SUCCESS';
export const GET_POSITION_QUESTION_ANSWER_OPTION_ERROR =
  'position-questions/GET_POSITION_QUESTION_ANSWER_OPTION_ERROR';

export const GET_POSITION_QUESTION_ANSWER_OPTIONS_REQUEST =
  'position-questions/GET_POSITION_QUESTION_ANSWER_OPTIONS_REQUEST';
export const GET_POSITION_QUESTION_ANSWER_OPTIONS_SUCCESS =
  'position-questions/GET_POSITION_QUESTION_ANSWER_OPTIONS_SUCCESS';
export const GET_POSITION_QUESTION_ANSWER_OPTIONS_ERROR =
  'position-questions/GET_POSITION_QUESTION_ANSWER_OPTIONS_ERROR';

export const CREATE_POSITION_QUESTION_ANSWER_OPTION_REQUEST =
  'position-questions/CREATE_POSITION_QUESTION_ANSWER_OPTION_REQUEST';
export const CREATE_POSITION_QUESTION_ANSWER_OPTION_SUCCESS =
  'position-questions/CREATE_POSITION_QUESTION_ANSWER_OPTION_SUCCESS';
export const CREATE_POSITION_QUESTION_ANSWER_OPTION_ERROR =
  'position-questions/CREATE_POSITION_QUESTION_ANSWER_OPTION_ERROR';

export const UPDATE_POSITION_QUESTION_ANSWER_OPTION_REQUEST =
  'position-questions/UPDATE_POSITION_QUESTION_ANSWER_OPTION_REQUEST';
export const UPDATE_POSITION_QUESTION_ANSWER_OPTION_SUCCESS =
  'position-questions/UPDATE_POSITION_QUESTION_ANSWER_OPTION_SUCCESS';
export const UPDATE_POSITION_QUESTION_ANSWER_OPTION_ERROR =
  'position-questions/UPDATE_POSITION_QUESTION_ANSWER_OPTION_ERROR';

export const UPDATE_POSITION_QUESTION_ANSWER_OPTION_VALUES_REQUEST =
  'position-questions/UPDATE_POSITION_QUESTION_ANSWER_OPTION_VALUES_REQUEST';
export const UPDATE_POSITION_QUESTION_ANSWER_OPTION_VALUES_SUCCESS =
  'position-questions/UPDATE_POSITION_QUESTION_ANSWER_OPTION_VALUES_SUCCESS';
export const UPDATE_POSITION_QUESTION_ANSWER_OPTION_VALUES_ERROR =
  'position-questions/UPDATE_POSITION_QUESTION_ANSWER_OPTION_VALUES_ERROR';

export const DELETE_POSITION_QUESTION_ANSWER_OPTION_REQUEST =
  'position-questions/DELETE_POSITION_QUESTION_ANSWER_OPTION_REQUEST';
export const DELETE_POSITION_QUESTION_ANSWER_OPTION_SUCCESS =
  'position-questions/DELETE_POSITION_QUESTION_ANSWER_OPTION_SUCCESS';
export const DELETE_POSITION_QUESTION_ANSWER_OPTION_ERROR =
  'position-questions/DELETE_POSITION_QUESTION_ANSWER_OPTION_ERROR';

export const SET_CREATED_OR_EDITED_POSITION_QUESTION =
  'positions-questions/SET_CREATED_OR_EDITED_POSITION_QUESTION';

export const CLEAR_POSITION_QUESTION = 'position-questions/CLEAR_POSITION_QUESTION';

export const getPositionQuestion = (
  applicationId: string,
  positionId: number,
  questionId: number,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_POSITION_QUESTION_REQUEST,
    });

    try {
      dispatch({
        type: GET_POSITION_QUESTION_SUCCESS,
        payload: await getPositionQuestionService(applicationId, positionId, questionId),
      });
    } catch (error) {
      dispatch({
        type: GET_POSITION_QUESTION_ERROR,
        error,
      });
    }
  };
};

export const clearPositionQuestion = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_POSITION_QUESTION,
    });
  };
};

export const getPositionQuestions = (
  applicationId: string,
  positionId: number,
  queryParams: GeneralQueryParams,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_POSITION_QUESTIONS_REQUEST,
    });

    try {
      dispatch({
        type: GET_POSITION_QUESTIONS_SUCCESS,
        payload: await getPositionQuestionsService(applicationId, positionId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_POSITION_QUESTIONS_ERROR,
        error,
      });
    }
  };
};

export const createPositionQuestion = (
  applicationId: string,
  positionId: number,
  question: PositionQuestionModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_POSITION_QUESTION_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_POSITION_QUESTION_SUCCESS,
        payload: await createPositionQuestionService(applicationId, positionId, question),
      });
    } catch (error) {
      dispatch({
        type: CREATE_POSITION_QUESTION_ERROR,
        error,
      });
    }
  };
};

export const updatePositionQuestion = (
  applicationId: string,
  positionId: number,
  questionId: number,
  question: PositionQuestionModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_POSITION_QUESTION_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_POSITION_QUESTION_SUCCESS,
        payload: await updatePositionQuestionService(
          applicationId,
          positionId,
          questionId,
          question,
        ),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_POSITION_QUESTION_ERROR,
      });
    }
  };
};

export const deletePositionQuestion = (
  applicationId: string,
  positionId: number,
  questionId: number,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_POSITION_QUESTION_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_POSITION_QUESTION_SUCCESS,
        payload: await deletePositionQuestionService(applicationId, positionId, questionId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_POSITION_QUESTION_ERROR,
        error,
      });
    }
  };
};

export const getPositionQuestionAnswerOption = (
  applicationId: string,
  positionId: number,
  questionId: number,
  answerOptionId: number,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_POSITION_QUESTION_ANSWER_OPTION_REQUEST,
    });

    try {
      dispatch({
        type: GET_POSITION_QUESTION_ANSWER_OPTION_SUCCESS,
        payload: await getPositionQuestionAnswerOptionService(
          applicationId,
          positionId,
          questionId,
          answerOptionId,
        ),
      });
    } catch (error) {
      dispatch({
        type: GET_POSITION_QUESTION_ANSWER_OPTION_ERROR,
        error,
      });
    }
  };
};

export const getPositionQuestionAnswerOptions = (
  applicationId: string,
  positionId: number,
  questionId: number,
  queryParams: GeneralQueryParams,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_POSITION_QUESTION_ANSWER_OPTIONS_REQUEST,
    });

    try {
      dispatch({
        type: GET_POSITION_QUESTION_ANSWER_OPTIONS_SUCCESS,
        payload: await getPositionQuestionAnswerOptionsService(
          applicationId,
          positionId,
          questionId,
          queryParams,
        ),
      });
    } catch (error) {
      dispatch({
        type: GET_POSITION_QUESTION_ANSWER_OPTIONS_ERROR,
        error,
      });
    }
  };
};

export const createPositionQuestionAnswerOption = (
  applicationId: string,
  positionId: number,
  questionId: number,
  answer: string,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_POSITION_QUESTION_ANSWER_OPTION_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_POSITION_QUESTION_ANSWER_OPTION_SUCCESS,
        payload: await createPositionQuestionAnswerOptionService(
          applicationId,
          positionId,
          questionId,
          answer,
        ),
      });
    } catch (error) {
      dispatch({
        type: CREATE_POSITION_QUESTION_ANSWER_OPTION_ERROR,
        error,
      });
    }
  };
};

export const createPositionQuestionAnswerOptions = (
  applicationId: string,
  positionId: number,
  questionId: number,
  answers: string[],
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_POSITION_QUESTION_ANSWER_OPTION_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_POSITION_QUESTION_ANSWER_OPTION_SUCCESS,
        payload: await createPositionQuestionAnswerOptionsService(
          applicationId,
          positionId,
          questionId,
          answers,
        ),
      });
    } catch (error) {
      dispatch({
        type: CREATE_POSITION_QUESTION_ANSWER_OPTION_ERROR,
        error,
      });
    }
  };
};

export const updatePositionQuestionAnswerOption = (
  applicationId: string,
  positionId: number,
  questionId: number,
  answerOptionId: number,
  answer: PositionQuestionAnswerOptionModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_POSITION_QUESTION_ANSWER_OPTION_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_POSITION_QUESTION_ANSWER_OPTION_SUCCESS,
        payload: await updatePositionQuestionAnswerOptionService(
          applicationId,
          positionId,
          questionId,
          answerOptionId,
          answer,
        ),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_POSITION_QUESTION_ANSWER_OPTION_ERROR,
      });
    }
  };
};

export const updatePositionQuestionAnswerOptionValues = (
  applicationId: string,
  positionId: number,
  questionId: number,
  values: object,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_POSITION_QUESTION_ANSWER_OPTION_VALUES_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_POSITION_QUESTION_ANSWER_OPTION_VALUES_SUCCESS,
        payload: await updatePositionQuestionAnswerOptionValuesService(
          applicationId,
          positionId,
          questionId,
          values,
        ),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_POSITION_QUESTION_ANSWER_OPTION_VALUES_ERROR,
      });
    }
  };
};

export const deletePositionQuestionAnswerOption = (
  applicationId: string,
  positionId: number,
  questionId: number,
  answerOptionId: number,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_POSITION_QUESTION_ANSWER_OPTION_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_POSITION_QUESTION_ANSWER_OPTION_SUCCESS,
        payload: await deletePositionQuestionAnswerOptionService(
          applicationId,
          positionId,
          questionId,
          answerOptionId,
        ),
      });
    } catch (error) {
      dispatch({
        type: DELETE_POSITION_QUESTION_ANSWER_OPTION_ERROR,
        error,
      });
    }
  };
};

export const setCreatedOrEditedPositionQuestion = (
  question: PositionQuestionModel,
  method: string,
) => {
  return {
    type: SET_CREATED_OR_EDITED_POSITION_QUESTION,
    payload: question,
    method,
  };
};
