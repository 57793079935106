import { StateModel } from 'src/shared/models';
import {
  GET_ISSUES,
  GET_ISSUES_SUCCESS,
  GET_ISSUES_ERROR,
} from '../actions/issues.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const issuesReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case GET_ISSUES:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_ISSUES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload,
      };
    case GET_ISSUES_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };   

    default:
      return state;
  }
};
