import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

import { StateModel } from 'src/shared/models/default-state.model';
import { ApplicantsQueryParams } from 'src/shared/models/query-params.model';

import { getCustomFieldKeys } from 'src/shared/utils/misc.util';

import { getApplication, ApplicationModel } from 'src/redux/actions/applications.action';
import { getCustomFields, clearCustomFields } from 'src/redux/actions/custom-fields.action';
import {
  getShortListPositionApplicants,
  getShortListPositionCriterias,
  clearShortListState,
} from 'src/redux/actions/short-list.action';

import { getPosition } from 'src/redux/actions/positions.action';

import ShortListHeader from './components/ShortListHeader';
import ShortListTable from './components/ShortListTable';
import { useDeepEffect } from 'src/shared/hooks';
import { CustomFieldModel } from 'src/shared/models/custom-fields.model';
import { PositionModel } from 'src/shared/models/positions.model';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  customFields: CustomFieldModel[];
  getCustomFields: Function;
  customFieldsState: StateModel;
  getShortListPositionApplicants: Function;
  getShortListPositionCriterias: Function;
  clearShortListState: Function;
  position: PositionModel;
  getPosition: Function;
}

const ShortList: React.FC<Props> = ({
  application,
  getApplication,
  customFields,
  getCustomFields,
  customFieldsState,
  getShortListPositionApplicants,
  getShortListPositionCriterias,
  clearShortListState,
  position,
  getPosition,
}) => {
  const { appId, positionId } = useParams();

  const [canGetCustomFieldKeys, setCanGetCustomFieldKeys] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<ApplicantsQueryParams>({
    page: 0,
    size: 20,
    attributes: '',
  });

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }

    getCustomFields(appId);
    setCanGetCustomFieldKeys(true);

    return () => {
      clearCustomFields();
      clearShortListState();
    };
    /* eslint-disable-next-line */
  }, []);

  useDeepEffect(() => {
    if (queryParams.attributes) {
      getShortListPositionCriterias(appId, positionId);
      getShortListPositionApplicants(appId, positionId, queryParams);
    }

    /* eslint-disable-next-line */
  }, [queryParams]);

  useEffect(() => {
    if (canGetCustomFieldKeys && customFields?.length > 0) {
      const customFieldKeys = getCustomFieldKeys(customFields, 'shortlistList');

      const newQueryParams = {
        ...queryParams,
        attributes: customFieldKeys.join(','),
      };

      setQueryParams(newQueryParams);
    }
    /* eslint-disable-next-line */
  }, [customFields]);

  useEffect(() => {
    getPosition(appId, positionId);
  }, [positionId, appId, getPosition]);

  return (
    <div className="animated fadeIn">
      <ShortListHeader position={position} />
      <Card>
        <CardBody>
          <ShortListTable queryParams={queryParams} setQueryParams={setQueryParams} />
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  customFields: state.customFields.data.customFields.data,
  customFieldsState: state.customFields.data.customFields,
  position: state.positions.data.position.data,
});

const mapDispatchToProps = {
  getApplication,
  getCustomFields,
  clearCustomFields,
  getShortListPositionApplicants,
  getShortListPositionCriterias,
  clearShortListState,
  getPosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortList);
