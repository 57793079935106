import React, { FC } from 'react';
import { Formik, FormikValues } from 'formik';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { TextField } from 'src/shared/components';
import { EmployeeFilters } from 'src/shared/models';
import SEARCH_TIMER from 'src/shared/constants/filter.constants';

interface ConversationFlowFilterProps {
  filterChanged: Function;
  filter: EmployeeFilters;
}

const EmployeeFilter: FC<ConversationFlowFilterProps> = ({ filterChanged }) => {
  const { t } = useTranslation();

  const updateFilter = (values: FormikValues) => {
    filterChanged(values);
  };

  const debouncedCallApi = _.debounce((values: FormikValues) => updateFilter(values), SEARCH_TIMER);

  const handleFilterChange = (values: FormikValues) => {
    debouncedCallApi(values);
    return {};
  };

  return (
    <Formik
      validateOnBlur={false}
      initialValues={{
        searchKey: '',
        searchAttributeValue: '',
      }}
      onSubmit={() => {}}
      validate={handleFilterChange}
      validateOnChange
      enableReinitialize
    >
      {() => (
        <Row>
          <Col lg="3">
            <FormGroup>
              <Label for="name">{t('common.key')}</Label>
              <TextField name="searchKey" />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label for="name">{t('common.name')}</Label>
              <TextField name="searchAttributeValue" />
            </FormGroup>
          </Col>
        </Row>
      )}
    </Formik>
  );
};

export default EmployeeFilter;
