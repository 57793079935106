import React, { useState, Fragment } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { useLocation, useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { TimeFromNow, ConfirmModal, CheckPermission, DateTime } from '..';
import { Operations, ListModel, ListRowModel } from 'src/shared/models';

import styles from './List.module.scss';
import { uniqueId } from 'lodash';

interface ListProps<T> extends ListModel<T>, ListRowModel<T> {
  item: T;
  index: number;
}

export const ListFields = <T extends { id: number } = any>({
  fields,
  item,
  index,
  queryParams,
  fetchData,
  deleteFunction,
  editFunction,
  updateRedirectPath,
  reportRedirectPath,
  updatePermission,
  reportPermission,
  deletePermission,
  deleteTitle,
  deleteText,
  deleting,
  activePage,
  deleteConfirmKey,
}: ListProps<T>) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const deleteItem = async () => {
    if (deleteFunction) {
      await deleteFunction(selectedItem);
    }
    fetchData(queryParams);
    toggleConfirmModal();
  };

  const toggleConfirmModal = (item?: any) => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
    setSelectedItem(item);
  };

  const redirectToUpdate = async (id: string | number) => {
    if (editFunction) {
      editFunction(id);
      return;
    }
    history.push(`${pathname}/${id}/${updateRedirectPath || 'edit'}`);
  };

  const redirectToReport = (id: string | number) => {
    history.push(`${pathname}/${id}/${reportRedirectPath || 'report'}`);
  };

  const getValueByKey = (item: any, key: string) => {
    return key
      .split('.')
      .reduce((previousValue, currentValue) => previousValue?.[currentValue], item);
  };

  return (
    <Fragment>
      {fields.map(
        ({
          key,
          render,
          dateTime,
          date,
          columnStyle,
          operations,
          ellipsis,
          columnCssStyle,
          dynamicColumnCssProperties,
        }) => {
          const dynamicCssProps = dynamicColumnCssProperties
            ? dynamicColumnCssProperties(item)
            : {};

          return (
            <td
              key={key}
              className={classNames(styles.defaulColumn, columnStyle && styles[columnStyle], {
                [styles.operations]: operations,
              })}
              id={operations && `deleteBtn-${item.id}`}
              style={{ ...columnCssStyle, ...dynamicCssProps }}
            >
              {render ? (
                render(item, index, activePage)
              ) : operations ? (
                operations.map(operationType =>
                  operationType === Operations.DELETE ? (
                    <CheckPermission
                      key={operationType}
                      variant="displayIf"
                      permissions={deletePermission ? [deletePermission] : []}
                    >
                      <Button
                        color="danger"
                        className="ml-1 btn-sm"
                        onClick={() => toggleConfirmModal(item)}
                      >
                        <i className={`far fa-trash-alt ${styles.deleteButton}`} />
                      </Button>
                    </CheckPermission>
                  ) : operationType === Operations.UPDATE ? (
                    <CheckPermission
                      key={operationType}
                      variant="displayIf"
                      permissions={updatePermission ? [updatePermission] : []}
                    >
                      <Button className="ml-1 btn-sm" onClick={() => redirectToUpdate(item.id)}>
                        <i className="fas fa-edit" />
                      </Button>
                    </CheckPermission>
                  ) : operationType === Operations.REPORT ? (
                    <CheckPermission
                      key={operationType}
                      variant="displayIf"
                      permissions={reportPermission ? [reportPermission] : []}
                    >
                      <Button className="ml-1 btn-sm" onClick={() => redirectToReport(item.id)}>
                        <i className="fas fa-chart-bar" />
                      </Button>
                    </CheckPermission>
                  ) : null,
                )
              ) : dateTime && key ? (
                <TimeFromNow id={item.id || uniqueId()} time={getValueByKey(item, key)} />
              ) : date && key ? (
                <DateTime dateTime={getValueByKey(item, key)} />
              ) : ellipsis && key ? (
                <>
                  <span id={`ellipsis-${index}`}>
                    {getValueByKey(item, key).substr(0, ellipsis)}
                    {getValueByKey(item, key).length > ellipsis ? '...' : ''}
                  </span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`ellipsis-${index}`}
                    trigger="hover"
                  >
                    {getValueByKey(item, key)}
                  </UncontrolledTooltip>
                </>
              ) : (
                key && getValueByKey(item, key)
              )}
            </td>
          );
        },
      )}

      <ConfirmModal
        title={deleteTitle || ''}
        text={deleteText || ''}
        cancel={toggleConfirmModal}
        confirm={deleteItem}
        isOpen={isConfirmModalOpen}
        item={deleteConfirmKey && selectedItem ? selectedItem[deleteConfirmKey] : ''}
        isLoading={Boolean(deleting)}
      />
    </Fragment>
  );
};

export default ListFields;
