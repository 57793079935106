import React from 'react';
import { CardHeader } from 'reactstrap';

import { TrainingDataModel } from 'src/shared/models/training-data.model';
import EditableTextInput from 'src/shared/components/form-inputs/EditableTextInput';
import CheckPermission from 'src/shared/components/CheckPermission';

import styles from '../TrainingData.module.scss';

interface Props {
  trainingData: TrainingDataModel;
  updateTrainingData: Function;
  chevronIcon: string;
  setBodyVisible: Function;
  setConfirmModalOpen: Function;
  setSelectedTrainingData: Function;
}

const TrainingDataCardHeader: React.FC<Props> = ({
  trainingData,
  updateTrainingData,
  chevronIcon,
  setBodyVisible,
  setConfirmModalOpen,
  setSelectedTrainingData,
}) => {
  return (
    <CardHeader>
      <div className={styles.cardHeader}>
        <div className={styles.textContainer}>
          <div className="text-muted">#{trainingData.id}</div>
          <div className="font-weight-bold">
            <EditableTextInput
              id={trainingData.id}
              data={trainingData.name}
              updateData={(appId: string, id: number, value: string) =>
                updateTrainingData(appId, id, {
                  ...trainingData,
                  name: value,
                })
              }
              permission="training-data_update"
            />
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <CheckPermission variant="displayIf" permissions={['training-data_delete']}>
            <button
              type="button"
              className="btn btn-ghost-danger mx-2"
              onClick={e => {
                e.stopPropagation();
                setSelectedTrainingData(trainingData);
                setConfirmModalOpen(true);
              }}
            >
              <i className="fas fa-trash-alt" />
            </button>
          </CheckPermission>

          <button
            type="button"
            className="btn btn-ghost-secondary"
            onClick={() => setBodyVisible((prevState: boolean) => !prevState)}
          >
            <i className={chevronIcon} />
          </button>
        </div>
      </div>
    </CardHeader>
  );
};

export default TrainingDataCardHeader;
