import { StateModel } from 'src/shared/models';
import {
  DELETE_LANGUAGE_SETTINGS,
  DELETE_LANGUAGE_SETTINGS_ERROR,
  DELETE_LANGUAGE_SETTINGS_SUCCESS,
  GET_LANGUAGE_SETTINGS,
  GET_LANGUAGE_SETTINGS_ERROR,
  GET_LANGUAGE_SETTINGS_SUCCESS,
} from '../actions/language-settings.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const languageSettingsReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case GET_LANGUAGE_SETTINGS:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_LANGUAGE_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload,
      };
    case GET_LANGUAGE_SETTINGS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };
    case DELETE_LANGUAGE_SETTINGS:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case DELETE_LANGUAGE_SETTINGS_SUCCESS:
      //TODO review this
      const content = state.data.content.filter(
        ({ id }: any) => id !== payload.importConfigurationId,
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          content,
        },
      };
    case DELETE_LANGUAGE_SETTINGS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };

    default:
      return state;
  }
};
