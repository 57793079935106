import i18n from '../../i18n';
import { SelectModel } from '../models/common.model';

export const positionStatus = {
  DRAFT: 'DRAFT',
  OPENED: 'OPENED',
  CLOSED: 'CLOSED',
};

export const positionStatusToDisplay = {
  [positionStatus.DRAFT]: i18n.t('common.draft'),
  [positionStatus.OPENED]: i18n.t('common.open'),
  [positionStatus.CLOSED]: i18n.t('common.closed'),
};

export const positionStatusColor = {
  [positionStatus.DRAFT]: 'secondary',
  [positionStatus.OPENED]: 'success',
  [positionStatus.CLOSED]: 'danger',
};

export const positionSidebarTypes = {
  SHORTCUTS: 'Shortcuts',
  CREATE_PREFILTER_CRITERIA: 'Create Prefilter Criteria',
  EDIT_PREFILTER_CRITERIA: 'Edit Prefilter Criteria',
  CRITERIA_CATEGORIES: 'Crtieria Categories',
};

export const positionSidebarHeaderTitles = {
  [positionSidebarTypes.SHORTCUTS]: 'common.shortcuts',
  [positionSidebarTypes.CREATE_PREFILTER_CRITERIA]: 'positions.createPrefilterCriteria',
  [positionSidebarTypes.EDIT_PREFILTER_CRITERIA]: 'positions.editPrefilterCriteria',
  [positionSidebarTypes.CRITERIA_CATEGORIES]: 'positions.prefilterCriteriaCategories',
};

export const shortListValues = {
  MIN: 0,
  MAX: 10,
};

export const withoutPositionOption: SelectModel = {
  label: i18n.t('common.withoutPosition'),
  value: -1,
};
