import React from 'react';
import { useField, FieldAttributes } from 'formik';
import { Input, FormFeedback } from 'reactstrap';

const NumberFieldFormik: React.FC<FieldAttributes<any>> = ({
  ref,
  id,
  min = 0,
  max = 10,
  disabled,
  className,
  placeholder,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const { name, value, onBlur, onChange } = field;
  const { error, touched } = meta;

  // const valid = touched && !error;
  const invalid = touched && !!error;
  const errorText = error || undefined;

  return (
    <div className="d-flex flex-column flex-fill">
      <Input
        type="number"
        ref={ref}
        id={id}
        name={name}
        min={min}
        max={max}
        value={value as string}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        // valid={valid}
        invalid={invalid}
        disabled={disabled}
        className={className}
        {...props}
      />
      {errorText && <FormFeedback>{errorText}</FormFeedback>}
    </div>
  );
};

export default NumberFieldFormik;
