import { StateModel } from 'src/shared/models';
import {
  GET_DYNAMIC_MENUS,
  GET_DYNAMIC_MENUS_ERROR,
  GET_DYNAMIC_MENUS_SUCCESS,
} from '../actions/dynamic-menu.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const dynamicMenuReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case GET_DYNAMIC_MENUS:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_DYNAMIC_MENUS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload.content,
      };
    case GET_DYNAMIC_MENUS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };

    default:
      return state;
  }
};

export default dynamicMenuReducer;
