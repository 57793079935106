import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Badge, Table, UncontrolledTooltip } from 'reactstrap';
import { Formik, Form } from 'formik';
import cx from 'classnames';
import uniqueId from 'lodash/uniqueId';

import { StateModel } from 'src/shared/models/default-state.model';
import {
  ExpressionModel,
  QuestionnaireAnswer,
  QuestionnaireMessage,
} from 'src/shared/models/questionnaire.model';
import {
  messageActionTypeToDisplay,
  messageType,
  messageTypeIcon,
  messageTypesToExcludeRegex,
} from 'src/shared/constants/questionnaire.constans';

import {
  updateMessageText,
  updateAnswerText,
  updateMessageCheckpoint,
  deleteMessage,
  getExpressions,
} from 'src/redux/actions/questionnaire.action';
import { getTrainingData } from 'src/redux/actions/training-data.action';

import hasPermission from 'src/shared/services/permissions.service';

import TagPill from 'src/shared/components/TagPill';
import ConfirmModal from 'src/shared/components/modals/ConfirmModal';
import CheckPermission from 'src/shared/components/CheckPermission';
import EditableTextInput from 'src/shared/components/form-inputs/EditableTextInput';

import styles from '../Questionnaire.module.scss';

interface Props {
  getMessages: Function;
  messages: QuestionnaireMessage[];
  updateMessageText: Function;
  updateAnswerText: Function;
  updateMessageCheckpoint: Function;
  deleteMessage: Function;
  deleteMessageState: StateModel;
  setModalOpen: Function;
  setModalEvent: Function;
  selectedMessage: QuestionnaireMessage | undefined;
  setSelectedMessage: Function;
  setMessageType: Function;
  setScrollYPosition: Function;
  scrollYPosition: number;
  getExpressions: Function;
  expressions: ExpressionModel[];
  getTrainingData: Function;

  setConnectionsModalOpen: (isOpen: boolean) => void;
}

const MessagesTable: React.FC<Props> = ({
  getMessages,
  messages,
  updateMessageText,
  updateAnswerText,
  updateMessageCheckpoint,
  deleteMessage,
  deleteMessageState,
  setModalOpen,
  setModalEvent,
  selectedMessage,
  setSelectedMessage,
  setMessageType,
  setScrollYPosition,
  scrollYPosition,
  getExpressions,
  expressions,
  getTrainingData,
  setConnectionsModalOpen,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  useEffect(() => {
    getExpressions();
    getTrainingData(appId);
    /* eslint-disable-next-line */
  }, [getExpressions, getTrainingData]);

  const handleMessageDelete = async (messageId: number) => {
    await deleteMessage(appId, messageId);
    await getMessages(appId);
    setSelectedMessage(null);
    window.scrollTo(0, scrollYPosition);
  };

  const handleCheckpointToggle = async (messageId: number, isChecked: boolean) => {
    await updateMessageCheckpoint(appId, messageId, !isChecked);
  };

  const getMessageText = (message: QuestionnaireMessage) => {
    const { text, type } = message;

    let expressionText = '';

    if (type === messageType.LOGIC_CONTROLLER && expressions.length > 0) {
      expressionText = expressions.find(expression => expression.expression === text)?.title || '';
    }

    return expressionText || text;
  };

  const handlePossibleAnswerEdit = (answer: QuestionnaireAnswer, newText: string) => {
    const { id, messageId, text } = answer;

    if (appId && id && messageId && newText) {
      if (text === newText) return;

      updateAnswerText(appId, messageId, id, newText);
    }
  };

  const renderLabels = (messageId: number, answers: QuestionnaireAnswer[]) => {
    const answersToHide = [
      'FREE_TEXT_INPUT',
      'DEFAULT',
      'TRUE',
      'FALSE',
      'NOT_RENDERED',
      'NO_INTERVIEW_EVENT_FOUND',
      'ACTIVE_INTERVIEW_EVENT_EXISTS',
      'DYNAMIC EVENT',
      'NO_POSITION_FOUND',
      ' ENABLED_KO_EXITPOINT',
      'ONE_LABEL_AVAILABLE',
      'ONE_CITY_AVAILABLE',
    ];
    const answerTypesToHide = [
      'DEFAULT',
      'NO_INTERVIEW_EVENT_FOUND',
      'ACTIVE_INTERVIEW_EVENT_EXISTS',
      'DYNAMIC EVENT',
      'NO_POSITION_FOUND',
      ' ENABLED_KO_EXITPOINT',
      'ONE_LABEL_AVAILABLE',
      'ONE_CITY_AVAILABLE',
      'INVISIBLE_ANSWER',
    ];

    const isEditable = hasPermission(['position_questionnaire_answer_text_update']);

    return answers
      .sort((a, b) => a.order - b.order)
      .map((answer: QuestionnaireAnswer) => {
        if (
          answersToHide.includes(answer.text) ||
          answerTypesToHide.includes(answer.answerActionType)
        )
          return null;

        const tempAnswer = {
          ...answer,
          messageId,
        };

        return (
          <TagPill
            key={tempAnswer.text}
            item={tempAnswer}
            isDeleteable={false}
            isEditable={isEditable}
            handleEdit={handlePossibleAnswerEdit}
          />
        );
      });
  };

  const renderMessageTypeIcon = (type: string) => {
    const id = uniqueId('message-type-icon-');

    return (
      <Fragment>
        <i id={id} className={messageTypeIcon[type]} />
        <UncontrolledTooltip target={id} placement="top">
          {messageActionTypeToDisplay[type]}
        </UncontrolledTooltip>
      </Fragment>
    );
  };

  const renderParentConditions = (parentConditions: QuestionnaireAnswer[]) => {
    const id = uniqueId('parent-conditions-');
    const hasParentConditions = !!parentConditions?.length;
    let icon: string = '';
    let tooltipContent: JSX.Element | undefined;

    if (hasParentConditions) {
      tooltipContent = (
        <Fragment>
          {parentConditions.map(el => (
            <Fragment key={el.id}>
              <span>
                {el.messageId}: {el.text}
              </span>
              <br />
            </Fragment>
          ))}
        </Fragment>
      );
    } else {
      icon = 'fas fa-exclamation-triangle';
      tooltipContent = <span>{t('questionnaire.noParentConditionsFound')}</span>;
    }

    return (
      <Fragment>
        {hasParentConditions && <Badge id={id}>{parentConditions.length}</Badge>}
        {!hasParentConditions && <i id={id} className={icon} />}
        <UncontrolledTooltip target={id} placement="top">
          {tooltipContent}
        </UncontrolledTooltip>
      </Fragment>
    );
  };

  const renderCheckpointToggler = (messageId: number, isChecked: boolean) => {
    const iconClasses = cx('fas fa-thumbtack', {
      [styles.checked]: isChecked,
      [styles.unchecked]: !isChecked,
    });

    return (
      <div className={styles.checkpointIconContainer}>
        <CheckPermission
          variant="enableIf"
          permissions={['position_questionnaire_message_checkpoint_update']}
        >
          <button
            type="button"
            className="btn btn-ghost-secondary"
            onClick={() => handleCheckpointToggle(messageId, isChecked)}
          >
            <i className={iconClasses} />
          </button>
        </CheckPermission>
      </div>
    );
  };

  const handleConnectionsModalOpenClick = (message: QuestionnaireMessage) => {
    setConnectionsModalOpen(true);
    setSelectedMessage(message);
    setScrollYPosition(window.scrollY);
  };

  return (
    <Fragment>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{}}
        onSubmit={() => {}}
      >
        {() => (
          <Form>
            <Table className={styles.questionnaireTable} striped hover size="sm">
              <thead>
                <tr>
                  <th className={styles.id}>{t('common.id')}</th>
                  <th className={styles.message}>{t('common.message')}</th>
                  <th className={styles.possibleAnswers}>{t('common.possibleAnswers')}</th>
                  <th className={styles.connections}>{t('common.connections')}</th>
                  <th className={styles.parentConditions}>{t('questionnaire.parentConditions')}</th>
                  <th className={styles.type}>{t('common.type')}</th>
                  <th className={styles.checkpoint}>{t('common.checkpoint')}</th>
                  <th className={styles.operations}>{t('common.operations')}</th>
                </tr>
              </thead>

              <tbody>
                {messages
                  ?.sort((a: QuestionnaireMessage, b: QuestionnaireMessage) => {
                    return (a.id || 0) - (b.id || 0);
                  })
                  .map((message: QuestionnaireMessage, idx) => (
                    <tr id={`messagesTableRow-${idx}`} key={uniqueId(`${message.id}`)}>
                      <td>{message.id}</td>
                      <td className={styles.message}>
                        <EditableTextInput
                          id={message.id!}
                          data={getMessageText(message)}
                          updateData={updateMessageText}
                          permission="position_questionnaire_message_text_update"
                        />
                      </td>
                      <td className={styles.possibleAnswers}>
                        <div className={styles.tagPillContainer}>
                          {message.type !== messageType.SIMPLE_TEXT_MESSAGE &&
                            renderLabels(message.id!, message.answers)}
                        </div>
                      </td>
                      <td className={styles.connections}>
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm"
                          onClick={() => handleConnectionsModalOpenClick(message)}
                        >
                          <i className="fa fa-crosshairs" />
                        </button>
                      </td>
                      <td className={styles.parentConditions}>
                        {renderParentConditions(message.parentAnswers)}
                      </td>
                      <td className={styles.type}>{renderMessageTypeIcon(message.type)}</td>
                      <td className={styles.checkpoint}>
                        {!message.type.match(messageTypesToExcludeRegex) &&
                          renderCheckpointToggler(message.id!, message.checkpoint)}
                      </td>
                      <td className={styles.operations}>
                        <CheckPermission
                          variant="displayIf"
                          permissions={['position_questionnaire_message_update']}
                        >
                          <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={() => {
                              setModalOpen(true);
                              setModalEvent('edit');
                              setSelectedMessage(message);
                              setMessageType(message.type);
                              setScrollYPosition(window.scrollY);
                            }}
                          >
                            <i className="far fa-edit" />
                          </button>
                        </CheckPermission>

                        <CheckPermission
                          variant="enableIf"
                          permissions={['position_questionnaire_message_delete']}
                        >
                          <button
                            type="button"
                            className="btn btn-danger btn-sm ml-2"
                            onClick={() => {
                              setConfirmModalOpen(true);
                              setSelectedMessage(message);
                              setScrollYPosition(window.scrollY);
                            }}
                          >
                            <i className="far fa-trash-alt" />
                          </button>
                        </CheckPermission>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Form>
        )}
      </Formik>

      <ConfirmModal
        title={t('questionnaire.deleteMessage')}
        text={t('questionnaire.deleteMessageConfirmMessage')}
        isLoading={deleteMessageState.loading}
        isOpen={isConfirmModalOpen}
        cancel={() => {
          setConfirmModalOpen(false);
          setSelectedMessage(null);
        }}
        confirm={() => selectedMessage?.id && handleMessageDelete(selectedMessage.id)}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  createMessageState: state.questionnaireMessages.create,
  updateMessageState: state.questionnaireMessages.update,
  deleteMessageState: state.questionnaireMessages.delete,
  expressions: state.expressions.resources.data,
});

const mapDispatchToProps = {
  updateMessageText,
  updateAnswerText,
  updateMessageCheckpoint,
  deleteMessage,
  getExpressions,
  getTrainingData,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesTable);
