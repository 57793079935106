import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { getPlatforms } from 'src/redux/actions/platforms.action';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import {
  getConversations,
  getConversationsQueryParams,
  resetConversationsQueryParams,
  ConversationModel,
  setConversationsQueryParams,
} from 'src/redux/actions/conversations.action';
import {
  getConversationHistory,
  clearConversationHistory,
  setCurrentUser,
  ConversationHistoryModel,
} from 'src/redux/actions/conversation-history.action';
import { NoData, PageHeader } from 'src/shared/components';
import { ConversationsQueryParams, QueryParams } from 'src/shared/models';

import Sidebar from './components/Sidebar/Sidebar';
import History from './components/History/History';

import styles from './ConversationHistory.module.scss';
import ConversationHistoryFilter from './components/Filter/ConversationHistoryFilter';
import { ConversationHistoryFilters } from 'src/shared/models/conversation-history.model';
import moment from 'moment';
import _, { isArray } from 'lodash';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';
import i18n from 'src/i18n';
import DynamicTabs from 'src/shared/components/dynamic-tabs/DynamicTabs';
import { configurationConstants } from 'src/shared/constants/configuration.constants';

interface Props extends RouteComponentProps<{ id: string; userId: string; createdAt: string }> {
  getApplication: Function;
  application: ApplicationModel;
  getPlatforms: Function;
  platforms: string[];
  resetConversationsQueryParams: Function;
  getConversationsQueryParams: Function;
  queryParams: ConversationsQueryParams;
  getConversations: (
    appId: string,
    queryParams: QueryParams,
    filters: ConversationHistoryFilters,
    grouping: DynamicGroupByModel,
    tabs: DynamicGroupByModel,
  ) => Promise<void>;
  conversations: ConversationModel[];
  totalConversations: number;
  conversationsLoading: boolean;
  conversationsLoaded: boolean;
  getConversationHistory: Function;
  conversationHistory: ConversationHistoryModel[];
  totalPagesOfHistory: number;
  sizeOfHistoryBatch: number;
  numberOfHistory: number;
  clearConversationHistory: Function;
  setCurrentUser: Function;
}

interface State {
  filter: ConversationHistoryFilters;
  grouping: DynamicGroupByModel;
  tabs: DynamicGroupByModel;
}

class ConversationHistory extends Component<Props, State> {
  state: State = {
    filter: {
      filters: {
        startDate: moment().subtract(14, 'days').startOf('day').utc().toISOString(),
        endDate: moment().endOf('day').utc().toISOString(),
        selectedPlatforms: [],
        waiting: false,
      },
    },
    grouping: {},
    tabs: {},
  };

  componentDidMount = async () => {
    const { conversations } = this.props;
    const { id: appId, userId } = this.props.match.params;

    if (!this.props.application.id) {
      this.props.getApplication(appId);
    }

    if (!this.props.platforms.length) {
      this.props.getPlatforms(appId);
    }

    if (!userId || !conversations.length) {
      await this.props.getConversationsQueryParams();
      if (!_.isEmpty(this.state.tabs)) {
        const { queryParams } = this.props;
        this.props.getConversations(
          appId,
          queryParams as any,
          this.state.filter,
          this.state.grouping,
          this.state.tabs,
        );
      }
    }
  };

  componentDidUpdate = () => {
    const { id: appId, userId } = this.props.match.params;
    const { conversations } = this.props;

    if (!conversations || !conversations.length) {
      return null;
    }

    if (`${conversations[0].applicationId}` !== appId) {
      window.location.hash = `#/application/${appId}/conversations`;
      return null;
    }
    // Ha az URL-ben nem található userId, akkor a beszégetések listájából az első kerül kiválasztásra.
    if (!userId) {
      const { uuid } = conversations[0];
      if (!uuid) {
        return null;
      }
      this.props.history.push(`/application/${appId}/conversations/${uuid}?page=0`);
    }
  };

  componentWillUnmount = () => {
    const { userId } = this.props.match.params;
    // Az átirányítás előtt is lefut ez a ciklus, viszont akkor még nem szabad
    // törölni az adatoakt a state-ből.
    // A userId megléte azt jelenti, hogy már megtörtént az átirányítás.
    if (userId) {
      this.props.clearConversationHistory();
      this.props.resetConversationsQueryParams();
      this.props.setCurrentUser({ name: '', uuid: '' });
    }
  };

  getConversationHistory = async (queryParams: ConversationsQueryParams) => {
    const { userId, createdAt } = this.props.match.params;
    if (createdAt) {
      setConversationsQueryParams({ ...queryParams, to: createdAt });
    }
    await this.props.getConversationHistory(userId, {
      ...queryParams,
      to: createdAt || queryParams.to,
    });
  };

  handleFilterChange = (filterValues: ConversationHistoryFilters): void => {
    const { id: appId } = this.props.match.params;
    const { queryParams } = this.props;

    setConversationsQueryParams({ ...this.props.queryParams, page: 0 });

    for (var key in filterValues.filters) {
      let current = (filterValues.filters as any)[key];
      if (isArray(current) && current.length === 0) {
        (filterValues.filters as any)[key] = undefined;
      }
    }

    this.setState(prevState => ({
      filter: filterValues,
      grouping: prevState.grouping,
      tabs: prevState.tabs,
    }));
    this.props.getConversations(
      appId,
      queryParams as any,
      filterValues,
      this.state.grouping,
      this.state.tabs,
    );
  };

  handleDynamicGroupByChange = async (grouping: DynamicGroupByModel) => {
    const { id: appId } = this.props.match.params;
    const { queryParams } = this.props;

    this.setState(prevState => ({
      filter: prevState.filter,
      grouping: grouping,
      tabs: prevState.tabs,
    }));
    this.props.getConversations(
      appId,
      queryParams as any,
      this.state.filter,
      grouping,
      this.state.tabs,
    );
  };

  handleDynamicTabChange = async (tabs: DynamicGroupByModel) => {
    const { id: appId } = this.props.match.params;
    const { queryParams } = this.props;

    this.setState(prevState => ({ filter: prevState.filter, grouping: {}, tabs: tabs }));
    this.props.getConversations(appId, queryParams as any, this.state.filter, {}, tabs);
  };

  render() {
    const {
      platforms,
      conversationsLoading,
      conversationsLoaded,
      conversationHistory,
      totalPagesOfHistory,
      sizeOfHistoryBatch,
      numberOfHistory,
    } = this.props;
    const { userId } = this.props.match.params;
    return (
      <div className="animated fadeIn">
        <PageHeader title={<Trans i18nKey="common.conversations" />} />
        {this.props.application.dashboardConfiguration !== undefined && (
          <DynamicTabs
            configurationNameSpace={configurationConstants.coreConservationsFilter}
            application={this.props.application}
            handleDynamicGroupByChange={this.handleDynamicTabChange}
          >
            <ConversationHistoryFilter
              platforms={platforms}
              filter={this.state.filter}
              filterChanged={this.handleFilterChange}
              handleDynamicGroupByChange={this.handleDynamicGroupByChange}
            />

            <div className={styles.mainContainer}>
              {userId ? (
                <Fragment>
                  <Sidebar
                    platforms={platforms}
                    conversationHistory={conversationHistory}
                    conversationsLoading={conversationsLoading}
                    conversationsLoaded={conversationsLoaded}
                    filter={this.state.filter}
                    grouping={this.state.grouping}
                    tabs={this.state.tabs}
                  />
                  <History
                    getConversationHistory={this.getConversationHistory}
                    conversationHistory={conversationHistory}
                    totalPagesOfHistory={totalPagesOfHistory}
                    sizeOfHistoryBatch={sizeOfHistoryBatch}
                    numberOfHistory={numberOfHistory}
                  />
                </Fragment>
              ) : (
                <div className={styles.noConversationHistoryFound}>
                  <NoData label={i18n.t('common.noConversations')} />
                </div>
              )}
            </div>
          </DynamicTabs>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  platforms: state.platforms.data,
  queryParams: state.conversationsQueryParams,
  conversations: state.conversations.data.content,
  conversationsLoading: state.conversations.loading,
  conversationsLoaded: state.conversations.loaded,
  totalConversations: state.conversations.data.totalElements,
  conversationHistory: state.conversationHistory.data.content,
  totalPagesOfHistory: state.conversationHistory.data.totalPages,
  sizeOfHistoryBatch: state.conversationHistory.data.numberOfElements,
  numberOfHistory: state.conversationHistory.data.totalElements,
});

const mapDispatchToProps = {
  getApplication,
  getPlatforms,
  resetConversationsQueryParams,
  getConversations,
  getConversationHistory,
  getConversationsQueryParams,
  clearConversationHistory,
  setCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConversationHistory));
