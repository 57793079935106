import React, { useState } from 'react';
import { useFormikContext, FieldAttributes, useField } from 'formik';
// @ts-ignore
import { useTranslation } from 'react-i18next';
import { GithubPicker } from 'react-color';
import { TextField } from '..';
import { Label } from 'reactstrap';

interface Props {
  id?: string;
  label: string;
  className: string;
  disabled?: boolean;
  onChanged?: (chekced: boolean) => void;
}

const colorPickerColors = [
  '#B80000',
  '#DB3E00',
  '#FCCB00',
  '#008B02',
  '#006B76',
  '#1273DE',
  '#004DCF',
  '#5300EB',
  '#EB9694',
  '#FAD0C3',
  '#FEF3BD',
  '#C1E1C5',
  '#BEDADC',
  '#C4DEF6',
  '#BED3F3',
  '#D4C4FB',
  '#FFFFFF',
  '#000000',
];

const Switch: React.FC<Props | FieldAttributes<any>> = ({
  id,
  checked,
  label,
  disabled,
  className,
  onChanged,
  ...props
}) => {
  const { t } = useTranslation();
  const [field] = useField<{}>(props);
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const { name, value } = field;
  const { setFieldValue } = useFormikContext();

  const getColor = (): string => {
    return value?.toString() || '';
  };

  return (
    <>
      {label && <Label for={name}>{t(label)}</Label>}
      {showColorPicker && (
        <GithubPicker
          className="colorpicker"
          color={getColor()}
          colors={colorPickerColors}
          onChange={color => {
            setShowColorPicker(false);
            setFieldValue(name, color.hex);
            if (onChanged) {
              onChanged(color.hex);
            }
          }}
        />
      )}
      <TextField
        name={name}
        readOnly
        onClick={() => setShowColorPicker(!showColorPicker)}
        disabled={disabled}
      />
    </>
  );
};

export default Switch;
