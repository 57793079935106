import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Button } from 'reactstrap';
import isEqual from 'lodash/isEqual';
import { ApplicantsQueryParams } from 'src/shared/models/query-params.model';
import { useTranslation } from 'react-i18next';

import styles from '../TalentPool.module.scss';
import { SelectModel } from 'src/shared/models/common.model';

interface Props {
  setQueryParams: Function;
}

const TalentPoolSorter: React.FC<Props> = ({ setQueryParams }) => {
  const { t } = useTranslation();

  const FALLBACK_SORT_TYPE = 'id';

  const SORT_TYPES = [
    { label: t('talentPool.questionnaireFilledAt'), value: 'userProfile.surveyFilledAt' },
  ];

  const [sortType, setSortType] = useState<SelectModel>(SORT_TYPES[0]);
  const [sortDirection, setSortDirection] = useState<string>('DESC');

  const selectStyles = {
    container: (base: any) => ({
      ...base,
      flex: 1,
    }),
  };

  useEffect(() => {
    setQueryParams((prevState: ApplicantsQueryParams) => ({
      ...prevState,
      page: 0,
      sort: `${sortType.value},${FALLBACK_SORT_TYPE},${sortDirection}`,
    }));
    /* eslint-disable-next-line */
  }, [sortDirection, sortType]);

  const handleSortTypeChange = (option: any) => {
    if (isEqual(sortType, option)) return;

    setSortType(option);
  };

  const handleSortDirectionChange = () => {
    if (sortDirection === 'ASC') {
      setSortDirection('DESC');
    } else {
      setSortDirection('ASC');
    }
  };

  return (
    <div className="d-flex flex-column">
      <span className={styles.selectLabel}>{t('common.sortBy')}</span>
      <div className="d-flex">
        <Select
          styles={selectStyles}
          options={SORT_TYPES}
          defaultValue={SORT_TYPES[0]}
          onChange={option => handleSortTypeChange(option)}
        />
        <Button className="ml-3" onClick={() => handleSortDirectionChange()}>
          {sortDirection === 'DESC' ? (
            <i className="fas fa-long-arrow-alt-down" />
          ) : (
            <i className="fas fa-long-arrow-alt-up" />
          )}
        </Button>
      </div>
    </div>
  );
};

export default TalentPoolSorter;
