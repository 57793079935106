import moment from 'moment';
// TODO(miki): .env -bol szedni az env variablekat
// const env = require("../../env.json");
const dateNow = moment(Date.now()).format('YYYYMMDD_HH_mm_ss');

const { NODE_ENV } = process.env;
const development = {};
const production = {};

export const settings = {
  REACT_APP_AUTH_API_BASE_URL: '',
  REACT_APP_AUTH_API_BASE_PATH: '',
  REACT_APP_DATA_API_BASE_URL: '',
  REACT_APP_DATA_API_BASE_PATH: '',
  REACT_APP_AUTH_JAVA_ENABLED: '',
  REACT_APP_MSAL_CLIENT_ID: '',
  REACT_APP_MSAL_AUTHORITY: '',
  REACT_APP_MSAL_REDIRECT_URL: '',
  ...process.env,
  API_TOKEN: 'API_TOKEN',
  DEFAULT_TRENDS_DAY_LIMIT: 6,
  DEFAULT_LIST_LIMIT: 20,
  DEFAULT_LIST_OFFSET: 0,
  DEFAULT_CONVERSATION_LAST_ACTION_DURATION_AMOUNT: 1,
  DEFAULT_CONVERSATION_LAST_ACTION_DURATION_UNIT: 'h',
  DEFAULT_CONVERSATION_RECENT_AMOUNT: 6,
  DEFAULT_CONVERSATION_RECENT_UNIT: 'd',
  CONVERSATION_STATUS_INACTIVE: 0,
  CONVERSATION_STATUS_ACTIVE: 1,
  CONVERSATION_STATUS_WAITING: 2,
  CONVERSATION_STATUS_LABELS: ['Inactive', 'Active', 'Waiting'],
  DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm',
  DEFAULT_TIMEZONE: 'Europe/Budapest',
  SURVEY_STATUS_COLORS: {
    running: 'success',
    scheduled: 'primary',
    expired: 'warning',
  },
  ...(NODE_ENV ? development : production),
};

export const fileNames = {
  AUDIT_LOG_EXPORT: `audit_log_export_${dateNow}.csv`,
  SURVEY_REPORT_EXPORT: `survey_report_export_${dateNow}.xlsx`,
  FEEDBACKS_EXPORT: `feedbacks_export_${dateNow}.csv`,
  SALARY_ADVANCES_EXPORT: `salary_advances_export_${dateNow}.xlsx`,
  UNRECOGNIZED_SENTENCES_EXPORT: `unrecognized_sentences_export_${dateNow}.csv`,
  USAGE_STATISTICS_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES: `daily_count_of_conversations_and_messages_export_${dateNow}.csv`,
  USAGE_STATISTICS_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT: `hourly_distribution_all_messages_percent_export_${dateNow}.csv`,
  USAGE_STATISTICS_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_COUNT: `hourly_distribution_all_messages_count_export_${dateNow}.csv`,
  INTENT_STATISTICS_EXPORT: `intent_statistics_export_${dateNow}.csv`,
  TOPIC_STATISTICS_EXPORT: `topic_statistics_export_${dateNow}.csv`,
  COMPLAINTS_EXPORT: `complaints_export_${dateNow}.csv`,
  APPLICATION_EFFICIENCY_REPORT_EXPORT: `application_efficiency_report_export_${dateNow}.csv`,
  TRAINING_DATA_EXPORT: `training_data_export_${dateNow}.csv`,
  TALENT_POOL_APPLICANTS_EXPORT_CSV: `talent_pool_applicants_export_${dateNow}.csv`,
  TALENT_POOL_APPLICANTS_EXPORT_EXCEL: `talent_pool_applicants_export_${dateNow}.xlsx`,
  INTERVIEWS_ATTENDEES_EXPORT: `interviews_attendees_export_${dateNow}.csv`,
  POSITION_APPLICANTS_EXPORT_CSV: `position_applicants_export_${dateNow}.csv`,
  POSITION_APPLICANTS_EXPORT_EXCEL: `position_applicants_export_${dateNow}.xlsx`,
  RECURTIER_DATA_EXPORT: `recruiter_data_export_${dateNow}.json`,
  EVENT_REPORT_EXPORT_EXCEL: `event_report_export_${dateNow}.xlsx`,
  CONVERSION_FLOW_VARIABLES_EXPORT_EXCEL: `conversion_flow_variables_export_${dateNow}.xlsx`,
  FORM_DETAILED_REPORT_EXPORT_EXCEL: `form_detailed_report_export_${dateNow}.xlsx`,
  EMPLOYEE_CHATBOT_USAGE_REPORT_EXCEL: `employee_chatbot_usage_report_export_${dateNow}.xlsx`,
  ISSUE_AVERAGE_REPLY_TIME_REPORT_EXCEL: `issue_average_reply_time_report_export_${dateNow}.xlsx`,
};

export default settings;
