/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-classes-per-file */
import { put, post } from './http.service';
import { readFileAsArrayBuffer } from '../../redux/services/filereader.service';
import { SignatureForUploadModel } from '../../redux/actions/upload.action';

interface uploadService {
  upload: Function;
}

export const getSignedUrlForDownloadService = async (filenameString: string) => {
  const splitted = filenameString.split('.');
  const data = await post(`/signatures`, {
    data: {
      method: 'GET',
      filename: splitted[0],
      extension: splitted[1],
    },
  });
  return data.signedUrl;
};

export const getSignedUrlAndOpenFile = async (src: string) => {
  const url = await getSignedUrlForDownloadService(src);
  window.open(url, '_blank');
}

export const getSignatureForUploadService = async (extension: string, method: string) => {
  return post('/signatures', {
    data: {
      extension,
      method,
    },
  });
};

export class AWSUpload implements uploadService {
  upload = async (signedUrl: string, file: File) => {
    return put(signedUrl, {
      data: await readFileAsArrayBuffer(file),
      headers: {
        'Content-Type': file.type,
      },
    });
  };
}

export class AzureUpload implements uploadService {
  upload = async (signedUrl: string, file: File) => {
    return put(signedUrl, {
      data: await readFileAsArrayBuffer(file),
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'Content-Type': file.type,
      },
    });
  };
}

export function uploadFile(file: File, signature: SignatureForUploadModel) {
  if (signature.storageType === 'aws-s3') {
    return new AWSUpload().upload(signature.signedUrl, file);
  }
  if (signature.storageType === 'azure-blobstorage') {
    return new AzureUpload().upload(signature.signedUrl, file);
  }

  return Promise.reject();
}

function readFileAsync(file:File) {
  return new Promise((resolve, reject) => {
   const reader = new FileReader();
   reader.onload = () => {
    resolve(reader.result);
   };
   reader.onerror = reject;
   reader.readAsDataURL(file);
  })
 }

 function loadImgAsync(imgSrc:string) {
  return new Promise((resolve, reject) => {
   let img = new Image();
   img.onload = () => {
    resolve(img);
   };
   img.onerror = reject;
   img.src = imgSrc;
  })
 }

 function imgToBlobAsync(img:any, canvas:any) {
  return new Promise((resolve) => {
   const ctxMain = canvas.getContext('2d');
   ctxMain.drawImage(img, 0, 0, canvas.width, canvas.height);
   ctxMain.canvas.toBlob(async (blob:any) => {
    resolve(blob)
   }, 'image/*');
  })
 }

 function createCanvas(img:any, maxSize:number) {
  const canvas = document.createElement('canvas');
  if (img.width > img.height) {
   const widthMain = maxSize;
   const scaleFactorMain = widthMain / img.width;
   canvas.width = widthMain;
   canvas.height = img.height * scaleFactorMain;
  } else {
   const heightMain = maxSize;
   const scaleFactorMain = heightMain / img.height;
   canvas.width = img.width * scaleFactorMain;
   canvas.height = heightMain;
  }
  return canvas
 }

export async function generateThumbnail(file: File | undefined) {
  if (typeof file === 'undefined') {
    return false;
  }
  const filename = file.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
  const fileExtension = file.name.split('.').pop();
  const thumbnailImgName = `${filename  }-thumbnail.${  fileExtension}`;

  const imgSrc:any = await readFileAsync(file);
  const img = await loadImgAsync(imgSrc)
  const canvasThumb = createCanvas(img, 200)
  const imgBlobThumb:any = await imgToBlobAsync(img, canvasThumb)

  return new File([imgBlobThumb], thumbnailImgName, {
      type: 'image/*',
     lastModified: Date.now()
  });


}
