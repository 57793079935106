import { Dispatch } from 'redux';

import { GeneralQueryParams } from 'src/shared/models/query-params.model';
import { CustomListModel } from 'src/shared/models/custom-lists.model';

import {
  getCustomLists as getCustomListsService,
  getCustomList as getCustomListService,
  createCustomList as createCustomListService,
  updateCustomList as updateCustomListService,
  deleteCustomList as deleteCustomListService,
} from '../services/custom-lists.service';

export const GET_CUSTOM_LISTS_REQUEST = 'custom-lists/GET_CUSTOM_LISTS_REQUEST';
export const GET_CUSTOM_LISTS_SUCCESS = 'custom-lists/GET_CUSTOM_LISTS_SUCCESS';
export const GET_CUSTOM_LISTS_ERROR = 'custom-lists/GET_CUSTOM_LISTS_ERROR';

export const GET_CUSTOM_LIST_REQUEST = 'custom-lists/GET_CUSTOM_LIST_REQUEST';
export const GET_CUSTOM_LIST_SUCCESS = 'custom-lists/GET_CUSTOM_LIST_SUCCESS';
export const GET_CUSTOM_LIST_ERROR = 'custom-lists/GET_CUSTOM_LIST_ERROR';

export const CREATE_CUSTOM_LIST_REQUEST = 'custom-lists/CREATE_CUSTOM_LIST_REQUEST';
export const CREATE_CUSTOM_LIST_SUCCESS = 'custom-lists/CREATE_CUSTOM_LIST_SUCCESS';
export const CREATE_CUSTOM_LIST_ERROR = 'custom-lists/CREATE_CUSTOM_LIST_ERROR';

export const UPDATE_CUSTOM_LIST_REQUEST = 'custom-lists/UPDATE_CUSTOM_LIST_REQUEST';
export const UPDATE_CUSTOM_LIST_SUCCESS = 'custom-lists/UPDATE_CUSTOM_LIST_SUCCESS';
export const UPDATE_CUSTOM_LIST_ERROR = 'custom-lists/UPDATE_CUSTOM_LIST_ERROR';

export const DELETE_CUSTOM_LIST_REQUEST = 'custom-lists/DELETE_CUSTOM_LIST_REQUEST';
export const DELETE_CUSTOM_LIST_SUCCESS = 'custom-lists/DELETE_CUSTOM_LIST_SUCCESS';
export const DELETE_CUSTOM_LIST_ERROR = 'custom-lists/DELETE_CUSTOM_LIST_ERROR';

export const getCustomLists = (applicationId: string, queryParams: GeneralQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_CUSTOM_LISTS_REQUEST,
    });

    try {
      dispatch({
        type: GET_CUSTOM_LISTS_SUCCESS,
        payload: await getCustomListsService(applicationId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_CUSTOM_LISTS_ERROR,
        error,
      });
    }
  };
};

export const getCustomList = (applicationId: string, customListId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_CUSTOM_LIST_REQUEST,
    });

    try {
      dispatch({
        type: GET_CUSTOM_LIST_SUCCESS,
        payload: await getCustomListService(applicationId, customListId),
      });
    } catch (error) {
      dispatch({
        type: GET_CUSTOM_LIST_ERROR,
        error,
      });
    }
  };
};

export const createCustomList = (applicationId: string, customList: CustomListModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_CUSTOM_LIST_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_CUSTOM_LIST_SUCCESS,
        payload: await createCustomListService(applicationId, customList),
      });
    } catch (error) {
      dispatch({
        type: CREATE_CUSTOM_LIST_ERROR,
        error,
      });
    }
  };
};

export const updateCustomList = (
  applicationId: string,
  customListId: number,
  CustomList: CustomListModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_CUSTOM_LIST_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_CUSTOM_LIST_SUCCESS,
        payload: await updateCustomListService(applicationId, customListId, CustomList),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_CUSTOM_LIST_ERROR,
        error,
      });
    }
  };
};

export const deleteCustomList = (applicationId: string, customListId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_CUSTOM_LIST_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_CUSTOM_LIST_SUCCESS,
        payload: await deleteCustomListService(applicationId, customListId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_CUSTOM_LIST_ERROR,
        error,
      });
    }
  };
};
