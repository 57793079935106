import React from 'react';
import { Button, FormGroup, Label } from 'reactstrap';

import { uniqueId } from 'lodash';

import { ApplicationModel } from 'src/redux/actions/applications.action';
import { CheckPermission, DateTime, ReactSelect } from '../components';
import {
  ColumnConfiguration,
  ColumnConfigurationType,
  ConfiguredColumn,
  DashboardConfiguration,
  DateTimeColumn,
  OrderType,
} from '../models/configuration.model';
import { getSignedUrlAndOpenFile } from '../services/upload.service';
import { renderPlatformLogo } from './platforms.util';
import { TFunction } from 'i18next';
import { get } from '../services/http.service';

const DATE_FORMAT_REGEX = /^[0-9]{4}-((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01])|(0[469]|11)-(0[1-9]|[12][0-9]|30)|(02)-(0[1-9]|[12][0-9]))T(0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])\.[0-9]{3}Z$/;

export const getConfiguration = (application: ApplicationModel, configNameSpace: string) => {
  if (application && application.dashboardConfiguration) {
    const configItems = JSON.parse(application.dashboardConfiguration) as DashboardConfiguration;
    const currentConfigItem = configItems.find(config => config.namespace === configNameSpace);
    return currentConfigItem;
  }
};

const getColumnsFromConfiguration = (application: ApplicationModel, configNameSpace: string) => {
  const currentConfigItem = getConfiguration(application, configNameSpace);
  if (!currentConfigItem || currentConfigItem.configuration.columns.length === 0) {
    return [];
  }

  const columns = currentConfigItem?.configuration?.columns;
  if (!columns) {
    return [];
  }
  return columns;
};

export const getFormGroupByConfiguration = async (
  application: ApplicationModel,
  configNameSpace: string,
) => {
  try {
    var columns = getColumnsFromConfiguration(application, configNameSpace);
    if (columns.length === 1) {
      const col = columns[0];
      if (col.endpoint) {
        const url = col.endpoint.replace('{applicationId}', application.id.toString());
        const items = await get(url);
        // eslint-disable-next-line no-eval
        const options = items.map(eval(col.evalScript));
        return (
          <FormGroup>
            <Label for="dynamicMenuItems">{col.title}</Label>
            <ReactSelect name="dynamicMenuItems" isMulti options={options} />
          </FormGroup>
        );
      }
    }
  } catch {
    return <FormGroup />;
  }
};

export const getListFieldsByConfiguration = (
  application: ApplicationModel,
  configNameSpace: string,
  t: TFunction,
) => {
  var columns = getColumnsFromConfiguration(application, configNameSpace);
  return columns.map(col => getColumn(col, application.id, t));
};

export const getOrdersByConfiguration = (
  application: ApplicationModel,
  configNameSpace: string): { [key: string]: OrderType; }[] => {
  const currentConfigItem = getConfiguration(application, configNameSpace);

  if (!currentConfigItem?.configuration || !currentConfigItem.configuration.order) {
    return [];
  }
  return currentConfigItem.configuration.order;
};

const getColumn = (col: ColumnConfiguration, appId: number, t: TFunction): ConfiguredColumn => {
  return {
    key: uniqueId(),
    label: col.title as string,
    //evalScript needs param with name element!
    render: (element: any, index?: number, activePage?: number) => {
      // eslint-disable-next-line no-eval   
      const value = eval(col.evalScript);

      if (DATE_FORMAT_REGEX.test(value) || col.type === ColumnConfigurationType.DateTime) {
        const format = (col as DateTimeColumn).dateFormat;
        return <DateTime dateTime={value} format={format} />;
      }

      switch (col.type) {
        case ColumnConfigurationType.None:
        case ColumnConfigurationType.Text:
          return value;

        case ColumnConfigurationType.MessagingPlatformIcon:
          return renderPlatformLogo(value);

        case ColumnConfigurationType.S3File:
          if (!value) return '';

          return (
            <div className="text-center">
              <Button
                className="btn-sm"
                onClick={() => {
                  getSignedUrlAndOpenFile(value);
                }}
              >
                <i className="file-icon far fa-file-alt" />
              </Button>
            </div>
          );
        case ColumnConfigurationType.ChatHistoryButton:
          const { userId, createdAt } = value;
          return (
            <CheckPermission variant="displayIf" permissions={['conversations_read']}>
              <div className="conversation-link">
                {userId && (
                  <Button
                    color="primary"
                    onClick={() => {
                      window.location.href = `/#/application/${appId}/conversations/${userId}?created=${createdAt}`;
                    }}
                  >
                    <i className="fas fa-comments" /> {t('unrecognizedSentences.history')}
                  </Button>
                )}
              </div>
            </CheckPermission>
          );
        case ColumnConfigurationType.Labels:
          return (value as []).map(item => (
            <Button key={item} className="btn btn-secondary mr-1 mb-1">
              {item}
            </Button>
          ));
      }
    },
  };
};
