import { Dispatch } from 'redux';
import { PageQueryParams } from 'src/shared/models';
import { EventDateModel, EventModel } from 'src/shared/models/event.model';

import {
  getEvents as getEventsService,
  getEvent as getEventService,
  createEvent as createEventService,
  updateEvent as updateEventService,
  deleteEvent as deleteEventService,
  createEventDate as createEventDateService,
  addTagToEvent as addTagToEventService,
  removeEventTag as removeEventTagService,
} from '../services/event.service';

export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';

export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR';

export const CREATE_EVENT_DATE = 'EVENTS/CREATE_EVENT_DATE';
export const CREATE_EVENT_DATE_SUCCESS = 'EVENTS/CREATE_EVENT_DATE_SUCCESS';
export const CREATE_EVENT_DATE_ERROR = 'EVENTS/CREATE_EVENT_DATE_ERROR';

export const CREATE_EVENT = 'EVENTS/CREATE_EVENT';
export const CREATE_EVENT_SUCCESS = 'EVENTS/CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_ERROR = 'EVENTS/CREATE_EVENT_ERROR';

export const READ_EVENT = 'EVENTS/READ_EVENT';
export const READ_EVENT_SUCCESS = 'EVENTS/READ_EVENT_SUCCESS';
export const READ_EVENT_ERROR = 'EVENTS/READ_EVENT_ERROR';

export const UPDATE_EVENT = 'EVENTS/UPDATE_EVENT';
export const UPDATE_EVENT_SUCCESS = 'EVENTS/UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_ERROR = 'EVENTS/UPDATE_EVENT_ERROR';

export const ADD_TAG_TO_EVENT_REQUEST = 'employee/ADD_TAG_TO_SURVEY_REQUEST';
export const ADD_TAG_TO_EVENT_SUCCESS = 'employee/ADD_TAG_TO_SURVEY_SUCCESS';
export const ADD_TAG_TO_EVENT_ERROR = 'employee/ADD_TAG_TO_SURVEY_ERROR';

export const REMOVE_EVENT_TAG_REQUEST = 'employee/REMOVE_SURVEY_TAG_REQUEST';
export const REMOVE_EVENT_TAG_SUCCESS = 'employee/REMOVE_SURVEY_TAG_SUCCESS';
export const REMOVE_EVENT_TAG_ERROR = 'employee/REMOVE_SURVEY_TAG_ERROR';

export const REVERT_EVENT = 'EVENTS/CLEAR_EVENT';

export const getEvents = (appId: string, queryParams: PageQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_EVENTS,
    });

    try {
      dispatch({
        type: GET_EVENTS_SUCCESS,
        payload: await getEventsService(appId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_EVENTS_ERROR,
        error,
      });
    }
  };
};

export const getEvent = (appId: string, eventId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: READ_EVENT,
    });

    try {
      const response = await getEventService(appId, eventId);
      dispatch({
        type: READ_EVENT_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: READ_EVENT_ERROR,
        error,
      });
    }
  };
};

export const deleteEvent = (appId: string, eventId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_EVENT,
    });

    try {
      await deleteEventService(appId, eventId.toString());
      dispatch({
        type: DELETE_EVENT_SUCCESS,
        payload: { eventId },
      });
    } catch (error) {
      dispatch({
        type: DELETE_EVENT_ERROR,
        error,
      });
    }
  };
};

export const createEvent = (appId: string, eventData: EventModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_EVENT,
    });

    const response = await createEventService(appId, eventData);

    try {
      dispatch({
        type: CREATE_EVENT_SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: CREATE_EVENT_ERROR,
        error,
      });
    }
  };
};

export const updateEvent = (appId: string, eventId: string, data: EventModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EVENT,
    });

    try {
      dispatch({
        type: UPDATE_EVENT_SUCCESS,
        payload: await updateEventService(appId, eventId, data),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_EVENT_ERROR,
        error,
      });
    }
  };
};

export const createEventDate = (appId: string, eventId: string, eventDateData: EventDateModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_EVENT_DATE,
    });

    const response = await createEventDateService(appId, eventId, eventDateData);
    try {
      dispatch({
        type: CREATE_EVENT_DATE_SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: CREATE_EVENT_DATE_ERROR,
        error,
      });
    }
  };
};

export const addTagToEvent = (applicationId: string, eventId: string, tagIds: string[]) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ADD_TAG_TO_EVENT_REQUEST,
    });

    try {
      dispatch({
        type: ADD_TAG_TO_EVENT_SUCCESS,
        payload: await addTagToEventService(applicationId, eventId, tagIds),
      });
    } catch (error) {
      dispatch({
        type: ADD_TAG_TO_EVENT_ERROR,
        error,
      });
    }
  };
};

export const removeEventTag = (applicationId: string, eventId: string, tagId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REMOVE_EVENT_TAG_REQUEST,
    });

    try {
      dispatch({
        type: REMOVE_EVENT_TAG_SUCCESS,
        payload: await removeEventTagService(applicationId, eventId, tagId),
      });
    } catch (error) {
      dispatch({
        type: REMOVE_EVENT_TAG_ERROR,
        error,
      });
    }
  };
};

export const revertEvent = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REVERT_EVENT,
    });
  };
};
