import React, { FC } from 'react';

interface ViberLinkProps {
  href: string;
  text: string;
}

const ViberLink: FC<ViberLinkProps> = ({ href, text }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    <div dangerouslySetInnerHTML={{ __html: text }} />
  </a>
);

export default ViberLink;
