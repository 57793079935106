import React, { FC, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Row, Col, Alert, Card, CardHeader, CardBody } from 'reactstrap';
import moment from 'moment';
import queryString from 'query-string';

import { fileNames } from 'src/shared/settings';

import { IntentStatisticsQueryParams } from 'src/shared/models/query-params.model';
import { IntentStatisticModel } from 'src/shared/models/intent-statistics.model';

import { getApplication, ApplicationModel } from 'src/redux/actions/applications.action';
import {
  getIntentStatistics,
  exportIntentStatistics,
} from 'src/redux/actions/intent-statistics.action';

import { filterValidQueryParams, formatDatesToIsoString } from 'src/shared/utils/query-parse.util';
import saveFile from 'src/shared/utils/file-saver.util';

import IntentStatisticsFilter from './components/IntentStatisticsFilter';
import IntentStatisticsExport from './components/IntentStatisticsExport';
import IntentStatisticsTable from './components/IntentStatisticsTable';
import { Spinner, PageHeader } from 'src/shared/components';
import DynamicGroupBy from '../../shared/components/dynamic-group-by/DynamicGroupBy';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';
import { configurationConstants } from 'src/shared/constants/configuration.constants';

interface IntentStatisticsProps extends RouteComponentProps<{ id: string }>, WithTranslation {
  getApplication: Function;
  application: ApplicationModel;
  getIntentStatistics: Function;
  intentStatistics: IntentStatisticModel[];
  numberOfStatistics: number;
  intentStatisticsLoading: boolean;
  intentStatisticsLoaded: boolean;
  exportIntentStatistics: Function;
  exportedData: string;
  exporting: boolean;
  exported: boolean;
}

interface IntentStatisticsState {
  queryParams: IntentStatisticsQueryParams;
  activePage: number;
}

const IntentStatistics: FC<IntentStatisticsProps> = props => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [filters, setFilters] = useState<DynamicGroupByModel>({});

  const [state, setState] = useState<IntentStatisticsState>({
    queryParams: {
      from: moment(props.application.createdAt).utc(),
      to: moment().utc(),
      page: 0,
      size: 20,
      noneCount: false,
    },
    activePage: 1,
  });

  useEffect(() => {
    if (!props.application.id) {
      props.getApplication(props.match.params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!props.application.id) {
      return;
    }

    const queryParams = {
      ...state.queryParams,
      from: moment(props.application.createdAt).utc(),
      to: moment().utc(),
    };

    // Az URL-ben megadott paraméterek érvényességének ellenerőzése.
    // Ennek oka, hogy csak a IntentStatisticsQueryParams-ban meghatározott paraméterekkel dolgozzunk.
    const providedQueryParams = queryString.parse(props.location.search);
    const validQueryParams: any = filterValidQueryParams(providedQueryParams, queryParams);

    setState({
      ...state,
      queryParams: validQueryParams,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.application]);

  useEffect(() => {
    if (!props.application.id) {
      return;
    }
    // Moment objektumok formázása string-gé.
    const formattedParams = formatDatesToIsoString(state.queryParams);
    // Ha volt az URL-ben page megadva, akkor annak a beállítása.
    // Ha nem volt, akkor az alapértelmezett első oldal kerül beállításra.
    setCurrentPage(formattedParams.page);
    // Átirányítás a formázott paraméterekkel.
    // this.redirectWithFormattedQueryParams(formattedParams);
    // Adatok lekérdezése a formázott paraméterekkel.
    getIntentStatistics(formattedParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.queryParams, filters]);

  const getIntentStatistics = (queryParams: IntentStatisticsQueryParams) => {
    props.getIntentStatistics(id, queryParams, filters);
  };

  const exportIntentStatistics = async () => {
    const { queryParams } = state;
    const { from, to } = formatDatesToIsoString(queryParams);

    const exportedData = await props.exportIntentStatistics(id, { from, to }, filters);

    if (exportedData) {
      saveFile(exportedData, fileNames.INTENT_STATISTICS_EXPORT, 'csv');
    }
  };

  const handleDateChange = async (startDate: moment.Moment, endDate: moment.Moment) => {
    if (startDate && endDate) {
      setState(prevState => ({
        queryParams: {
          ...prevState.queryParams,
          from: startDate,
          to: endDate,
          page: 0,
        },
        activePage: 1,
      }));
    }

    return null;
  };

  const handleDynamicGroupByChange = async (values: DynamicGroupByModel) => {
    setFilters(values);
  };

  const pageChange = (index: number) => {
    // Backend oldalon a page-elés 0-tól indul
    // Frontend oldaon a 'react-js-pagination' miatt 1-től kell indulnia
    setState(prevState => ({
      queryParams: {
        ...prevState.queryParams,
        page: index - 1,
      },
      activePage: index,
    }));
    // this.redirectWithFormattedQueryParams(formattedParams);
    window.scrollTo(0, 0);
  };

  const setCurrentPage = (page: number) => {
    setState({
      ...state,
      activePage: Number(page) + 1,
    });
  };

  const {
    intentStatistics,
    intentStatisticsLoading,
    intentStatisticsLoaded,
    numberOfStatistics,
    exporting,
    exported,
    t,
  } = props;
  const { queryParams, activePage } = state;

  const showTable = !intentStatisticsLoading && intentStatistics && intentStatistics.length > 0;
  const noData = intentStatisticsLoaded && !intentStatistics.length;

  return (
    <div className="animated fadeIn">
      <PageHeader title={t('common.intentStatistics')} />
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col>
                  <IntentStatisticsFilter
                    startDate={moment(queryParams.from)}
                    endDate={moment(queryParams.to)}
                    handleDateChange={handleDateChange}
                  />
                </Col>
                <Col className="d-flex justify-content-end">
                  <DynamicGroupBy
                    appId={id}
                    isMulti
                    handleDynamicGroupByChange={handleDynamicGroupByChange}
                    configurationNameSpace={configurationConstants.analyticsIntentStatisticsFilter}
                  />
                </Col>
                <Col className="d-flex justify-content-end" lg="1">
                  <IntentStatisticsExport
                    exportIntentStatistics={exportIntentStatistics}
                    exporting={exporting}
                    exported={exported}
                    disabled={exporting || intentStatisticsLoading || noData}
                  />
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {showTable && (
                <IntentStatisticsTable
                  intentStatistics={intentStatistics}
                  activePage={activePage}
                  size={queryParams.size}
                  totalElements={numberOfStatistics}
                  pageChange={pageChange}
                />
              )}
              {intentStatisticsLoading && (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner loading={intentStatisticsLoading} size="2x" />
                </div>
              )}
              {noData && (
                <Alert color="info" className="text-center m-0">
                  {t('intentStatistics.noIntentStatistics')}
                </Alert>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  intentStatistics: state.intentStatistics.data.content,
  numberOfStatistics: state.intentStatistics.data.totalElements,
  intentStatisticsLoading: state.intentStatistics.loading,
  intentStatisticsLoaded: state.intentStatistics.loaded,
  exportedData: state.exportIntentStatistics.data,
  exporting: state.exportIntentStatistics.loading,
  exported: state.exportIntentStatistics.loaded,
});

const mapDispatchToProps = {
  getApplication,
  getIntentStatistics,
  exportIntentStatistics,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(IntentStatistics)));
