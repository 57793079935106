import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { getCustomFieldKeys } from 'src/shared/utils/misc.util';

import { getApplication, ApplicationModel } from 'src/redux/actions/applications.action';
import {
  getPositionApplicants,
  clearPositionApplicants,
} from 'src/redux/actions/position-applicants.action';
import { getCustomFields, clearCustomFields } from 'src/redux/actions/custom-fields.action';

import { exportPositionApplicants } from 'src/redux/services/position-applicants.service';
import { getPositions } from 'src/redux/actions/positions.action';

import { ApplicantsQueryParams } from 'src/shared/models/query-params.model';
import { SelectModel } from 'src/shared/models/common.model';
import { CustomFieldModel } from 'src/shared/models/custom-fields.model';

import { useDeepEffect } from 'src/shared/hooks';

import PositionApplicantsHeader from './components/PositionApplicantsHeader';
import PositionApplicantsFilters from './components/PositionApplicantsFilters';
import PositionApplicantsTable from './components/PositionApplicantsTable';
import saveFile from 'src/shared/utils/file-saver.util';
import { fileNames } from 'src/shared/settings';
import { exportType } from 'src/shared/constants/export.constants';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  getPositionApplicants: Function;
  getCustomFields: Function;
  customFields: CustomFieldModel[];
  clearCustomFields: Function;
  clearPositionApplicants: Function;
  getPositions: Function;
}

const PositionApplicants: React.FC<Props> = ({
  application,
  getApplication,
  getPositionApplicants,
  getCustomFields,
  customFields,
  clearCustomFields,
  clearPositionApplicants,
  getPositions,
}) => {
  const { appId } = useParams<{ appId: string }>();

  const [canGetCustomFieldKeys, setCanGetCustomFieldKeys] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<ApplicantsQueryParams>({
    page: 0,
    size: 50,
    positions: '',
    sort: 'appliedAt,DESC',
    attributes: '',
  });

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }

    getCustomFields(appId);

    setCanGetCustomFieldKeys(true);

    return () => {
      clearCustomFields();
      clearPositionApplicants();
    };
    /* eslint-disable-next-line */
  }, []);

  useDeepEffect(() => {
    if (queryParams.attributes) {
      getPositionApplicants(appId, queryParams);
    }
    /* eslint-disable-next-line */
  }, [queryParams]);

  useEffect(() => {
    if (canGetCustomFieldKeys && customFields?.length > 0) {
      const customFieldKeys = getCustomFieldKeys(customFields, 'positionList');

      const newQueryParams = {
        ...queryParams,
        attributes: customFieldKeys.join(','),
      };

      setQueryParams(newQueryParams);
    }
    /* eslint-disable-next-line */
  }, [customFields]);

  const handlePositionSelect = (positions: SelectModel[]) => {
    const positionIds = positions?.map(position => position.value) || [];

    setQueryParams((prevState: ApplicantsQueryParams) => ({
      ...prevState,
      positions: positionIds.length ? positionIds.join(',') : '',
    }));
  };

  const handleExport = async (id: string) => {
    const params = { ...queryParams };
    delete params.page;
    delete params.size;

    if (id === exportType.EXCEL || id === exportType.CSV) {
      const res = await exportPositionApplicants(appId, params, id);
      if (id === exportType.CSV) {
        saveFile(res, fileNames.POSITION_APPLICANTS_EXPORT_CSV, exportType.CSV);
      } else if (id === exportType.EXCEL) {
        saveFile(res, fileNames.POSITION_APPLICANTS_EXPORT_EXCEL, exportType.EXCEL);
      }
    }
  };

  return (
    <Row className="animated fadeIn">
      <Col>
        <PositionApplicantsHeader />
        <PositionApplicantsFilters
          handlePositionSelect={handlePositionSelect}
          handleExport={handleExport}
          setQueryParams={setQueryParams}
        />
        <PositionApplicantsTable
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          application={application}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  positions: state.positions.data.positions,
  customFields: state.customFields.data.customFields.data,
});

const mapDispatchToProps = {
  getApplication,
  getPositionApplicants,
  getCustomFields,
  clearCustomFields,
  clearPositionApplicants,
  getPositions,
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionApplicants);
