import { post, get } from 'src/shared/services/http.service';
import { TrainingDatasetModel } from 'src/shared/models/dataset.model';
import { GeneralQueryParams } from 'src/shared/models/query-params.model';

export const importRecruiterDataset = async (applicationId: number, data: TrainingDatasetModel) => {
  return post(`/recruiter/applications/${applicationId}/migration/import`, {
    data,
  });
};

export const exportRecruiterDataset = async (applicationId: string, params: GeneralQueryParams) => {
  return get(`/recruiter/applications/${applicationId}/migration/export`, { params });
};

export default importRecruiterDataset;
