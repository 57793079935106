import React from 'react';
import { Row, Col } from 'reactstrap';

const NoPermission: React.FC = () => {
  return (
    <Row className="justify-content-center">
      <Col lg="6" md="8">
        <div className="clearfix">
          <h1 className="float-left display-3 mr-4">403</h1>
          <h4 className="pt-3">Access Denied</h4>
          <p className="text-muted float-left">
            The URL is valid but you don&apos;t have permission to view this page.
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default NoPermission;
