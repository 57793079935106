import React, { Component } from 'react';

import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';
import { generateId } from '../../../../../../../../shared/utils/generate-id.util';

import styles from './scss/Hangouts.module.scss';

interface Props {
  message: ConversationHistoryModel;
}

interface State {
  outputTemplate: any;
}

class ButtonTemplate extends Component<Props> {
  state: State = {
    outputTemplate: null,
  };

  componentDidMount() {
    const { message } = this.props;
    // Replacing \n characters with space
    // JSON.parse() would fail otherwise
    const cleanedMessage = message.messageAsText.replace(/\n/g, ' ');
    this.renderTemplate(cleanedMessage);
  }

  async renderTemplate(msg: any) {
    const outputTitle = [];
    const outputButtons = [];
    const outputTemplate = [];
    let currentTemplate = null;
    let text = '';
    let buttons = null;

    try {
      currentTemplate = JSON.parse(msg);
      // eslint-disable-next-line
      text = currentTemplate.text;
      buttons = currentTemplate.hangoutsChatButtons;

      outputTitle.push(
        <div className={styles.row} key={text}>
          <span>{text}</span>
        </div>,
      );

      for (let i = 0; i < buttons.length; i += 1) {
        outputButtons.push(
          <div className={styles.row}>
            <button type="button" key={i} disabled>
              {buttons[i].title}
            </button>
          </div>,
        );
      }

      outputTemplate.push(outputTitle);
      outputTemplate.push(outputButtons);

      this.setState({ outputTemplate });
    } catch (error) {
      this.setState({ outputTemplate: msg });
    }
  }

  render() {
    const { outputTemplate } = this.state;

    return (
      <div className={styles.buttonTemplate} key={generateId(5)}>
        {outputTemplate}
      </div>
    );
  }
}

export default ButtonTemplate;
