import React, { useState } from 'react';
import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { getSignedUrlForDownloadService } from 'src/shared/services/upload.service';

import styles from './Thumbnail.module.scss';

export interface ThumbnailProps {
  id: string;
  imageUrl: string;
  caption: React.ReactElement;
  buttons: React.ReactElement;
}

export interface ThumbnailPropsWithRef extends ThumbnailProps {
  ref: any;
  style: any;
  faded: boolean;
}

const Thumbnail = React.forwardRef<HTMLInputElement, ThumbnailPropsWithRef>(
  ({ id, imageUrl, faded, style, caption, buttons, ...props }, ref) => {
    const inlineStyles = {
      opacity: faded ? '0.2' : '1',
      transformOrigin: '0 0',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      ...style,
    };
    const [thumbnail, setThumbnail] = useState(undefined);

    const fetch = async (imageUrl: string) => {
      const response = await getSignedUrlForDownloadService(imageUrl);
      setThumbnail(response);
    };

    useEffect(() => {
      if (!thumbnail && imageUrl) {
        fetch(imageUrl);
      }
      /* eslint-disable-next-line */
    }, [imageUrl]);

    return (
      <div
        className={styles.container}
        data-tip
        data-for={id}
        style={inlineStyles}
        {...props}
        ref={ref}
      >
        <div>
          <img className={styles.image} src={thumbnail} alt={thumbnail} />
        </div>
        <hr />
        <div>{caption}</div>
        {!faded && (
          <ReactTooltip id={id} place="bottom" effect="solid" offset={{ bottom: -40 }} clickable>
            <div>{buttons}</div>
          </ReactTooltip>
        )}
      </div>
    );
  },
);

export default Thumbnail;
