import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { ApplicationModel, getApplication } from "src/redux/actions/applications.action";
import { getIssueCategories, updateIssueCategory } from "src/redux/actions/issue-categories.action";
import { List } from "src/shared/components";
import { CreateIssueCategoryRequest, GetIssueCategoriesResponse } from "src/shared/models/issue-categories.model";
import IssueCategoryEditorModal from "./components/IssueCategoryEditorModal";

interface IssueCategoriesProps {
    application: ApplicationModel;
    issueCategories: GetIssueCategoriesResponse;
    loaded: boolean,
    loading: boolean,
    error: boolean,

    getApplication: Function;
    getIssueCategories: (appid: string) => void;
    updateIssueCategory: (appid: string, data: CreateIssueCategoryRequest) => void;
}

const IssueCategories: FC<IssueCategoriesProps> = ({ application, issueCategories, loading, loaded, error, getApplication, getIssueCategories, updateIssueCategory }) => {
    const { appId } = useParams<{ appId: string; }>();
    const { t } = useTranslation();

    useEffect(() => {
        if (!application.id) {
            getApplication(appId);
        }
        getIssueCategories(appId);
        // eslint-disable-next-line
    }, []);

    return <div className="animated fadeIn">
        <List
            useCardContent
            hidePager
            data={issueCategories}
            totalElements={issueCategories.length}
            loaded={loaded}
            loading={loading}
            error={error}
            fetchData={() => { }}
            title={t('issue.categories')}
            fields={[
                { label: t('issue.id'), key: 'id' },
                { label: t('issue.name'), key: 'name' },
                // {
                //     key: 'name',
                //     label: t('issue.name'),
                //     columnStyle: 'inline-edit',
                //     render: item => (
                //         <EditableTextInput
                //             id={item.id!}
                //             data={item.name}
                //             name="name"
                //             updateData={(appId: string, id: number, value: string) => {
                //                 console.log(value);
                //                 updateIssueCategory(appId, { name: value });
                //             }}
                //             permission="issue_category_create"
                //             validationSchema={issueCategorySchema()}
                //         />
                //     ),
                // },
                { label: t('issue.slug'), key: 'slug' }
            ]}
            noDataLabel={t('common.noData')}
            createModal={(isModalOpen, toggleModal) => (
                <IssueCategoryEditorModal isOpen={isModalOpen} setIsModalOpen={toggleModal} />
            )}
            createPermission="issue_category_create"
            // updatePermission="issue_category_create"
        />
    </div>;
};


const mapStateToProps = (state: any) => {
    return {
        application: state.application.data,
        issueCategories: state.issueCategories.data,
        loading: state.issueCategories.loading,
        loaded: state.issueCategories.loaded,
        error: state.issueCategories.error,
    };
};

const mapDispatchToProps = {
    getApplication,
    getIssueCategories,
    updateIssueCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(IssueCategories);

