import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert } from 'reactstrap';

import { PersonaModel } from 'src/shared/models/persona.model';
import { getPersonas, setPersona } from 'src/redux/actions/personas.action';

import defaultAvatar from 'src/assets/img/avatars/default.png';
import styles from 'src/views/ConversationHistory/ConversationHistory.module.scss';

import { useTranslation } from 'react-i18next';

interface Props {
  getPersonas: Function;
  personas: PersonaModel[];
  setPersona: Function;
  selectedPersona: PersonaModel;
}

const PersonaSelector: React.FC<Props> = ({
  getPersonas,
  personas,
  setPersona,
  selectedPersona,
}) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    getPersonas();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (personas.length > 0) {
      let personaValues = {};
      const localStoragePersona = localStorage.getItem('selectedPersona');

      if (localStoragePersona) {
        personaValues = JSON.parse(localStoragePersona);
      } else {
        const { id, name } = personas[0];

        personaValues = {
          id,
          name,
        };
      }

      setPersona(personaValues);
    }

    // eslint-disable-next-line
  }, [personas]);

  const handleSelectPersona = (persona: PersonaModel) => {
    const { id, name, avatar } = persona;
    setPersona({ id, name, avatar });
  };

  return (
    <ButtonDropdown isOpen={isDropdownOpen} toggle={() => setIsDropdownOpen(!isDropdownOpen)}>
      <DropdownToggle className={styles.personaSelectorButton}>
        <img src={selectedPersona.avatar || defaultAvatar} alt="Selected Persona" />
      </DropdownToggle>
      <DropdownMenu>
        {personas.length > 0 ? (
          personas.map((persona: PersonaModel) => (
            <DropdownItem
              key={persona.id!}
              className={styles.personaDropdownItem}
              onClick={() => handleSelectPersona(persona)}
            >
              <div className={styles.personaDetails}>
                <img src={persona.avatar} alt="Persona Avatar" />
                <p>{persona.name}</p>
              </div>
            </DropdownItem>
          ))
        ) : (
          <Alert className={styles.user_box}>
            {t('personas.doYouHavepersona')}{' '}
            <a href="#/personas">{t('personas.doYouHavepersonaLinkText')}</a>
          </Alert>
        )}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

const mapStateToProps = (state: any) => ({
  personas: state.personas.data,
  selectedPersona: state.selectedPersona,
});

const mapDispatchToProps = {
  getPersonas,
  setPersona,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonaSelector);
