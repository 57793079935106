import React, { FC } from 'react';

import styles from './CompletedRatio.module.scss';

interface CompletedRatioProps {
  completed: number;
  total: number;
}

const CompletedRatio: FC<CompletedRatioProps> = ({ completed, total }) => {
  return (
    <div>
      <span>{completed}</span>
      <span className={styles.ratioText}>{`(${
        completed && total ? Math.round((completed / total) * 10000) / 100 : 0
      }%)`}</span>
    </div>
  );
};

export default CompletedRatio;
