import React, { Component, Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Table } from 'reactstrap';
import queryString from 'query-string';
import Pagination from 'react-js-pagination';

import {
  TalentPoolCandidateAnswerModel,
  TalentPoolCandidateWithAnswersModel,
} from '../../../shared/models/talent-pools.model';
import { QueryParams } from '../../../shared/models/query-params.model';

import TalentPoolCandidatesTableRow from './TalentPoolCandidatesTableRow';
import TalentPoolCandidateModal from './TalentPoolCandidateModal';

import styles from '../TalentPools.module.scss';

interface Props extends RouteComponentProps, WithTranslation {
  candidates: TalentPoolCandidateWithAnswersModel[];
  totalCandidates: number;
  getCandidatesWithAnswers: Function;
  markCandidate: Function;
}

interface State {
  selectedCandidate: TalentPoolCandidateWithAnswersModel | null;
  selectedCandidatePosition: string | null;
  isModalOpen: boolean;
  activePage: number;
  queryParams: QueryParams;
}

class TalentPoolCandidatesTable extends Component<Props, State> {
  state: State = {
    selectedCandidate: null,
    selectedCandidatePosition: '',
    isModalOpen: false,
    activePage: 1,
    queryParams: {
      limit: 20,
      page: 0,
      size: 20,
    },
  };

  componentDidMount = () => {
    const { queryParams } = this.state;
    const queryValues = queryString.parse(this.props.location.search);

    const page = queryValues.page ? Number(queryValues.page) + 1 : queryParams.page;

    this.pageChange(page);
  };

  handleCandidateMark = async (
    event: React.MouseEvent,
    candidate: TalentPoolCandidateWithAnswersModel,
  ) => {
    event.stopPropagation();
    const { queryParams } = this.state;
    const { id, talentPoolId } = candidate;
    const marked = !candidate.marked;

    await this.props.markCandidate(talentPoolId, id, marked);
    this.props.getCandidatesWithAnswers(queryParams);
  };

  showCandidate = (candidate: TalentPoolCandidateWithAnswersModel) => {
    const position = this.getSelectedPosition(candidate.userAnswers);

    this.setState(
      {
        selectedCandidate: candidate,
        selectedCandidatePosition: position,
      },
      () => this.toggleModal(),
    );
  };

  getSelectedPosition = (userAnswers: TalentPoolCandidateAnswerModel[]) => {
    if (!userAnswers || !userAnswers.length) {
      return null;
    }

    const userAnswerWithPosition = userAnswers.filter(userAnswer => {
      return userAnswer.showInTableView;
    });

    if (userAnswerWithPosition.length > 0) {
      return userAnswerWithPosition[0].answer;
    }

    return null;
  };

  pageChange = async (index: number) => {
    // Backend oldalon a page-elés 0-tól indul
    // Frontend oldaon a 'react-js-pagination' miatt 1-től kell indulnia
    this.setState(
      prevState => ({
        queryParams: {
          ...prevState.queryParams,
          page: index - 1,
        },
        activePage: index,
      }),
      () => {
        this.props.getCandidatesWithAnswers(this.state.queryParams);
        this.props.history.push(`?page=${this.state.queryParams.page}`);
        window.scrollTo(0, 0);
      },
    );
  };

  toggleModal = () => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  render() {
    const { candidates, totalCandidates, t } = this.props;
    const {
      selectedCandidate,
      selectedCandidatePosition,
      isModalOpen,
      activePage,
      queryParams,
    } = this.state;

    return (
      <Fragment>
        <Table responsive striped hover className={styles.talentPoolTable}>
          <thead>
            <tr>
              <th> </th>
              <th className="name">{t('common.name')}</th>
              <th className="position">{t('talentPools.position')}</th>
              <th className="email">{t('common.email')}</th>
              <th className="phone">{t('common.phone')}</th>
              <th className="cv">{t('talentPools.cv')}</th>
              <th className="date-submitted">{t('talentPools.dateSubmitted')}</th>
            </tr>
          </thead>
          <tbody>
            {candidates &&
              candidates.map((candidate: TalentPoolCandidateWithAnswersModel, index: number) => {
                return (
                  <TalentPoolCandidatesTableRow
                    key={candidate.id}
                    index={index}
                    candidate={candidate}
                    showCandidate={this.showCandidate}
                    handleCandidateMark={this.handleCandidateMark}
                    getSelectedPosition={this.getSelectedPosition}
                  />
                );
              })}
          </tbody>
        </Table>

        <Pagination
          activePage={activePage}
          itemsCountPerPage={queryParams.limit}
          totalItemsCount={totalCandidates}
          pageRangeDisplayed={10}
          onChange={this.pageChange}
        />

        <TalentPoolCandidateModal
          selectedCandidate={selectedCandidate}
          position={selectedCandidatePosition}
          isOpen={isModalOpen}
          toggleModal={this.toggleModal}
        />
      </Fragment>
    );
  }
}

export default withTranslation()(withRouter(TalentPoolCandidatesTable));
