import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';

import { ArrayHelpers, FieldArray, useFormikContext } from 'formik';

import { ReactCreateableSelect, TextField } from 'src/shared/components';
import { SelectModel } from 'src/shared/models';
import { FollowUpNotificationsEditorModel } from 'src/shared/models/follow-up-notifications.model';

interface MessageComposerProps {
  dynamicConvFlowMenuItemsSlug: SelectModel[];
}

const MessageComposer: FC<MessageComposerProps> = ({ dynamicConvFlowMenuItemsSlug }) => {
  const { t } = useTranslation();
  const { values, errors } = useFormikContext<FollowUpNotificationsEditorModel>();

  const removeButton = (arrayHelpers: ArrayHelpers, index: number) => {
    arrayHelpers.remove(index);
  };

  const addButton = (arrayHelpers: ArrayHelpers) => {
    arrayHelpers.push({ text: '', value: '' });
  };

  return (
    <>
      <FormGroup>
        <Label for="message.text">{t('common.message')}</Label>
        <TextField name="message.text" />
      </FormGroup>
      <FieldArray
        name="message.buttons"
        render={arrayHelpers => {
          return (
            <>
              <FormGroup>
                <Label for="buttons">{t('common.buttons')}</Label>
                {values.message.buttons.length > 0 && (
                  <Row>
                    {Object.keys(values.message.buttons[0]).map(name => (
                      <Col key={name}>
                        <Label>{t(`common.${name}`)}</Label>
                      </Col>
                    ))}
                    <Col lg={1}></Col>
                  </Row>
                )}
                {values.message.buttons.map((item, index) => (
                  <Row className="mb-2" key={index}>
                    <Col>
                      <TextField name={`message.buttons[${index}].text`} />
                    </Col>
                    <Col>
                      <ReactCreateableSelect
                        name={`message.buttons[${index}].value`}
                        options={dynamicConvFlowMenuItemsSlug}
                      />
                    </Col>
                    <Col lg={1}>
                      <div>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => removeButton(arrayHelpers, index)}
                        >
                          <i className="far fa-trash-alt" />
                        </button>
                      </div>
                    </Col>
                  </Row>
                ))}
                <Row className="justify-content-center mt-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => addButton(arrayHelpers)}
                  >
                    <span className="mr-2">{t('common.add')}</span>
                    <i className="fas fa-plus" />
                  </button>
                </Row>
                {errors.message?.buttons && (
                  <FormFeedback style={{ display: 'block' }}>{errors.message.buttons}</FormFeedback>
                )}
              </FormGroup>
            </>
          );
        }}
      />
    </>
  );
};

export default MessageComposer;
