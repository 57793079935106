import moment from 'moment';
import { ConversationModel } from '../../redux/actions/conversations.action';
import { settings } from '../settings';

export const conversationStatusHelper = (conversation: ConversationModel) => {
  if (conversation.handoverStatus) {
    return settings.CONVERSATION_STATUS_WAITING;
  }

  return moment(conversation.creationTimestamp).isAfter(
    moment().subtract(
      settings.DEFAULT_CONVERSATION_LAST_ACTION_DURATION_AMOUNT as any,
      settings.DEFAULT_CONVERSATION_LAST_ACTION_DURATION_UNIT,
    ),
  )
    ? settings.CONVERSATION_STATUS_ACTIVE
    : settings.CONVERSATION_STATUS_INACTIVE;
};

export const getConversationStatus = (conversations: ConversationModel[]) => {
  const conversationsWithStatus: object[] = [];

  if (conversations.length > 0) {
    conversations.map((conversation: ConversationModel) => {
      return conversationsWithStatus.push({
        ...conversation,
        status: conversationStatusHelper(conversation),
      });
    });

    return conversationsWithStatus;
  }
};

export const getCurrentConversation = (userId: string, conversations: ConversationModel[]) => {
  const currentConversation = conversations.filter(
    (conversation: ConversationModel) => conversation.uuid === userId,
  );

  return currentConversation[0] || null;
};
