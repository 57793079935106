import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Popover,
  PopoverHeader,
  PopoverBody,
  FormGroup,
  Label,
  Button,
} from 'reactstrap';
import { Formik, Form } from 'formik';
import { ObjectSchema } from 'yup';

import {
  attributeValidatorSchema,
  regexReplacerSchema,
  expressionSchema,
} from 'src/shared/schemas/validation.schema';
import CreateTypes from 'src/shared/models/create-types';

import TextField from 'src/shared/components/form-inputs/TextField';

interface CreateType {
  [key: string]: {
    initValues: object;
    schema: ObjectSchema;
  };
}

interface Props {
  id?: string;
  isOpen: boolean;
  createType: CreateTypes;
  toggle: Function;
  handleOnSubmit: Function;
}

const CreatePopover: React.FC<Props> = ({ id, isOpen, createType, toggle, handleOnSubmit }) => {
  const { t } = useTranslation();

  const title =
    createType === CreateTypes.EXPRESSION
      ? 'createExpression'
      : createType === CreateTypes.REGEX_REPLACER
      ? 'createRegexReplacer'
      : 'createAttributeValidator';
  const secondTextFieldLabel = createType === 'expression' ? 'expression' : 'regexPattern';
  const secondTextFieldName = createType === 'expression' ? 'expression' : 'pattern';

  const createTypes = {
    attributeValidator: {
      initValues: {
        title: '',
        pattern: '',
      },
      schema: attributeValidatorSchema,
    },
    regexReplacer: {
      initValues: {
        title: '',
        pattern: '',
        replaceTo: '',
      },
      schema: regexReplacerSchema,
    },
    expression: {
      initValues: {
        title: '',
        expression: '',
      },
      schema: expressionSchema,
    },
  };

  return (
    <Popover
      placement="top-end"
      isOpen={isOpen}
      target={id || 'createPopover'}
      toggle={() => toggle()}
    >
      <PopoverHeader>{t(`questionnaire.${title}`)}</PopoverHeader>
      <PopoverBody>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={(createTypes as CreateType)[createType].initValues}
          validationSchema={(createTypes as CreateType)[createType].schema}
          onSubmit={async (values, { setSubmitting }) => {
            await handleOnSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="title">{t('common.title')}</Label>
                    <TextField name="title" />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label for={secondTextFieldName}>{t(`common.${secondTextFieldLabel}`)}</Label>
                    <TextField name={secondTextFieldName} />
                  </FormGroup>
                </Col>
              </Row>

              {createType === CreateTypes.REGEX_REPLACER ? (
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label for="replaceTo">{t(`common.replaceTo`)}</Label>
                      <TextField name="replaceTo" />
                    </FormGroup>
                  </Col>
                </Row>
              ) : null}

              <div className="d-flex">
                <Button className="flex-fill w-100 mr-2" type="submit" color="success">
                  {t('common.create')}
                </Button>
                <Button
                  className="flex-fill"
                  color="secondary"
                  disabled={isSubmitting}
                  onClick={() => toggle()}
                >
                  {t('common.cancel')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </PopoverBody>
    </Popover>
  );
};

export default CreatePopover;
