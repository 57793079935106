import { Dispatch } from 'redux';
import { TrainingDatasetModel } from '../../shared/models/dataset.model';
import { importTrainingDataset as importTrainingDatasetService } from '../services/dataset.service';

export const IMPORT_TRAINING_DATASET_REQUEST = 'dataset/IMPORT_TRAINING_DATASET_REQUEST';
export const IMPORT_TRAINING_DATASET_SUCCESS = 'dataset/IMPORT_TRAINING_DATASET_SUCCESS';
export const IMPORT_TRAINING_DATASET_ERROR = 'dataset/IMPORT_TRAINING_DATASET_ERROR';
export const TRAINING_DATASET_CLEAR_ERROR = 'dataset/TRAINING_DATASET_CLEAR_ERROR';

export const importTrainingDataset = (applicationId: number, data: TrainingDatasetModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: IMPORT_TRAINING_DATASET_REQUEST,
    });

    try {
      dispatch({
        type: IMPORT_TRAINING_DATASET_SUCCESS,
        payload: await importTrainingDatasetService(applicationId, data),
      });
    } catch (error) {
      dispatch({
        type: IMPORT_TRAINING_DATASET_ERROR,
        error,
      });
    }
  };
};

export const clearDatasetErrors = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: TRAINING_DATASET_CLEAR_ERROR,
    });
  };
};
