import { StateModel } from '../../shared/models/default-state.model';

import {
  GET_AUDIT_LOG_REQUEST,
  GET_AUDIT_LOG_SUCCESS,
  GET_AUDIT_LOG_ERROR,
  EXPORT_AUDIT_LOG_REQUEST,
  EXPORT_AUDIT_LOG_SUCCESS,
  EXPORT_AUDIT_LOG_ERROR,
} from '../actions/audit-log.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {
    content: [],
  },
};

export const auditLogReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_AUDIT_LOG_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_AUDIT_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_AUDIT_LOG_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const auditLogExportReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case EXPORT_AUDIT_LOG_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case EXPORT_AUDIT_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case EXPORT_AUDIT_LOG_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
