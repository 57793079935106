import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert, Table } from 'reactstrap';
import { FormikValues } from 'formik';

import { useDeepEffect } from 'src/shared/hooks';
import { StateModel } from 'src/shared/models/default-state.model';
import { RegexReplacerModel } from 'src/shared/models/questionnaire.model';
import {
  createRegexReplacer,
  deleteRegexReplacer,
  getRegexReplacers,
  updateRegexReplacer,
} from 'src/redux/actions/questionnaire.action';
import { getPositions } from 'src/redux/actions/positions.action';
import ConfirmModal from 'src/shared/components/modals/ConfirmModal';
import CheckPermission from 'src/shared/components/CheckPermission';
import Spinner from 'src/shared/components/Spinner';
import ServerError from 'src/shared/components/ServerError';

import RegexReplacePatternsTableHeader from './RegexReplacePatternsTableHeader';
import RegexReplacePatternsTableBody from './RegexReplacePatternsTableBody';
import RegexReplacePatternsModal from './RegexReplacePatternsModal';

interface Props {
  regexReplacers: StateModel;
  getRegexReplacers: Function;
  createRegexReplacer: Function;
  updateRegexReplacer: Function;
  deleteEvent: StateModel;
  deleteRegexReplacer: Function;
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  modalEvent: string;
}

const RegexReplacePatternsTable: React.FC<Props> = ({
  regexReplacers,
  getRegexReplacers,
  createRegexReplacer,
  updateRegexReplacer,
  deleteEvent,
  deleteRegexReplacer,
  isModalOpen,
  setModalOpen,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams();

  const [selectedRegexReplacer, setSelectedRegexReplacer] = useState<
    RegexReplacerModel
  >();
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  useEffect(() => {
    getPositions(appId);
    /* eslint-disable-next-line */
  }, []);

  useDeepEffect(() => {
    getRegexReplacers(appId);
  }, [appId]);

  useEffect(() => {
    if (!isModalOpen && selectedRegexReplacer) {
      setSelectedRegexReplacer(undefined);
    }
    /* eslint-disable-next-line */
  }, [isModalOpen]);

  useEffect(() => {
    if (!isConfirmModalOpen && selectedRegexReplacer) {
      setSelectedRegexReplacer(undefined);
    }
    /* eslint-disable-next-line */
  }, [isConfirmModalOpen]);

  const handleSubmit = async (values: FormikValues) => {
    await createRegexReplacer(values);
    await getRegexReplacers(appId);

    setModalOpen(false);
  };

  const handleDelete = async () => {
    if (selectedRegexReplacer) {
      await deleteRegexReplacer(selectedRegexReplacer.id);
      await getRegexReplacers(appId);

      setConfirmModalOpen(false);
    }
  };

  return (
    <Fragment>
      {regexReplacers.loading && (
        <div className="d-flex justify-content-center">
          <Spinner size="2x" loading={regexReplacers.loading} />
        </div>
      )}

      {regexReplacers.loaded && !regexReplacers.data?.length && (
        <Alert className="text-center" color="info">
          {t('regexReplacerPatterns.noRegexReplacerPatternsFound')}
        </Alert>
      )}

      {regexReplacers.error && <ServerError />}

      {regexReplacers.loaded && regexReplacers.data?.length > 0 && (
        <Fragment>
          <Table size="sm" hover striped responsive>
            <RegexReplacePatternsTableHeader />
            <RegexReplacePatternsTableBody
              attributeReplacers={regexReplacers.data}
              selectAttributeReplacer={setSelectedRegexReplacer}
              toggleConfirmModal={setConfirmModalOpen}
              updateAttributeReplacer={updateRegexReplacer}
            />
          </Table>
        </Fragment>
      )}

      <CheckPermission variant="displayIf" permissions={['regular-expression-replacer_update']}>
        <RegexReplacePatternsModal
          isOpen={isModalOpen}
          toggleModal={() => setModalOpen(false)}
          confirm={handleSubmit}
          selectedRegexReplacer={selectedRegexReplacer}
        />
      </CheckPermission>

      <CheckPermission variant="displayIf" permissions={['regular-expression-replacer_delete']}>
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          title={t('regexReplacerPatterns.deleteRegexReplacerPattern')}
          text={t('regexReplacerPatterns.deleteRegexReplacerPatternConfirm')}
          item={selectedRegexReplacer?.title}
          isLoading={deleteEvent.loading}
          confirm={handleDelete}
          cancel={() => setConfirmModalOpen(false)}
        />
      </CheckPermission>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  regexReplacers: state.regexReplacers.resources,
  deleteEvent: state.regexReplacers.delete,
  positions: state.positions.data.positions.data?.content,
});

const mapDispatchToProps = {
  createRegexReplacer: createRegexReplacer,
  deleteRegexReplacer: deleteRegexReplacer,
  getRegexReplacers: getRegexReplacers,
  updateRegexReplacer: updateRegexReplacer,
  getPositions,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegexReplacePatternsTable);
