import { StateModel } from 'src/shared/models';
import {
  CREATE_SURVEY,
  CREATE_SURVEY_SUCCESS,
  CREATE_SURVEY_ERROR,
  READ_SURVEY,
  READ_SURVEY_SUCCESS,
  READ_SURVEY_ERROR,
  UPDATE_SURVEY,
  UPDATE_SURVEY_SUCCESS,
  UPDATE_SURVEY_ERROR,
  DELETE_SURVEY_REQUEST,
  DELETE_SURVEY_SUCCESS,
  DELETE_SURVEY_ERROR,
  REVERT_SURVEY,
} from '../actions/surveys.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const surveyGeneralReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case CREATE_SURVEY:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case CREATE_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          survey: payload,
        },
      };
    case CREATE_SURVEY_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };
    case READ_SURVEY:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case READ_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          survey: payload,
        },
      };
    case READ_SURVEY_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };
    case UPDATE_SURVEY:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case UPDATE_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          survey: payload,
        },
      };
    case UPDATE_SURVEY_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };
    case REVERT_SURVEY:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const deleteSurveyReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DELETE_SURVEY_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case DELETE_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case DELETE_SURVEY_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
