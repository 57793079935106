import { get } from '../../shared/services/http.service';
import {
  AuditLogQueryParams,
  AuditLogExportQueryParams,
} from '../../shared/models/query-params.model';

export const getAuditLog = async (params: AuditLogQueryParams) => {


  if(params.type === null){
      delete params.type;
  }

  if(params.userId === null){
    delete params.userId;
  }


  return get('/audit-logs', {
    params,
  });
};

export const exportAuditLog = async (params: AuditLogExportQueryParams) => {
  return get('/audit-logs/export/csv', {
    params,
  });
};
