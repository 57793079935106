import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Select from 'react-select';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { Formik, Field, FormikValues } from 'formik';
import _ from 'lodash';
import moment from 'moment';

import { DateRange } from 'src/shared/components';
import DynamicGroupBy from 'src/shared/components/dynamic-group-by/DynamicGroupBy';
import { configurationConstants } from 'src/shared/constants/configuration.constants';
import SEARCH_TIMER from 'src/shared/constants/filter.constants';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';
import { IssueFilters, IssuesQueryParams } from 'src/shared/models/issues.model';

interface IIssuesFiltersProps {
  filter: IssueFilters;
  issueCategoryDropdownItems: { label: string; value: string }[];
  filterChanged: (filters: IssuesQueryParams) => void;
  handleDynamicGroupByChange: (grouping: DynamicGroupByModel) => Promise<void>;
}

const IssuesFilters: FC<IIssuesFiltersProps> = props => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const updateFilter = (values: FormikValues) => {
    props.filterChanged(values as IssuesQueryParams);
  };

  const debouncedCallApi = _.debounce((values: FormikValues) => updateFilter(values), SEARCH_TIMER);

  const handleFilterChange = (values: FormikValues) => {
    debouncedCallApi(values);
    return {};
  };

  return (
    <>
      <Formik
        validateOnBlur={false}
        initialValues={props.filter.filters}
        onSubmit={() => {}}
        validate={handleFilterChange}
        validateOnChange
        enableReinitialize
      >
        {({ setFieldValue, values, setValues }) => (
          <Row>
            <Col lg="3">
              <FormGroup>
                <Label>{t('common.date')}</Label>
                <Field
                  startDate={values.from}
                  endDate={values.to}
                  component={DateRange}
                  isOutsideRange={() => false}
                  handleDateChange={(startDate: moment.Moment, endDate: moment.Moment) => {
                    if (!startDate || !endDate) return null;
                    const from = moment(startDate).utc().startOf('day').toISOString();
                    const to = moment(endDate).utc().endOf('day').toISOString();

                    setValues(
                      {
                        ...values,
                        from,
                        to,
                      },
                      true,
                    );
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t('common.status')}</Label>
                <Select
                  placeholder={t('common.status')}
                  value={values.statuses}
                  options={[
                    { label: 'Opened', value: 'OPENED' },
                    { label: 'Pending', value: 'PENDING' },
                    { label: 'Closed', value: 'CLOSED' },
                  ]}
                  isMulti
                  //@ts-ignore
                  onChange={(e: any) => {
                    setValues({ ...values, statuses: e }, true);
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{t('common.category')}</Label>
                <Select
                  placeholder={t('common.category')}
                  value={values.categorySlugs}
                  options={props.issueCategoryDropdownItems}
                  isMulti
                  //@ts-ignore
                  onChange={(e: any) => {
                    setValues({ ...values, categorySlugs: e }, true);
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="5">
              <FormGroup>
                <DynamicGroupBy
                  appId={appId}
                  isMulti
                  handleDynamicGroupByChange={props.handleDynamicGroupByChange}
                  configurationNameSpace={configurationConstants.coreIssueFilter}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
      </Formik>
    </>
  );
};

export default IssuesFilters;
