import i18n from 'src/i18n';

export const exportType = {
  EXCEL: 'xlsx',
  CSV: 'csv',
};

export const exportMessageToDisplay = {
  [exportType.EXCEL]: i18n.t('export.excel'),
  [exportType.CSV]: i18n.t('export.csv'),
};

export const exportIcon = {
  [exportType.EXCEL]: 'fas fa-file-excel',
  [exportType.CSV]: 'fas fa-file-csv',
};
