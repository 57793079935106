import React, { useEffect, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { Col, FormGroup, Row } from 'reactstrap';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { MessageToAdminModel, QueryParams } from 'src/shared/models';
import { List } from 'src/shared/components';

import { getMessagesToAdmins } from 'src/redux/actions/message-to-admin.action';
import { default as DateRangeFilter } from '../UsageStatistics/components/Filter';
import { formatDatesToIsoString } from 'src/shared/utils';
import { configurationConstants } from 'src/shared/constants/configuration.constants';
import { getListFieldsByConfiguration } from 'src/shared/utils/configuration.util';
import DynamicGroupBy from '../../shared/components/dynamic-group-by/DynamicGroupBy';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';

interface Props {
  getMessagesToAdmins: (
    appId: string,
    queryParams: QueryParams,
    grouping: DynamicGroupByModel,
  ) => void;
  getApplication: Function;
  application: ApplicationModel;
  messagesToAdmins: MessageToAdminModel[];
  numberOfMessageToAdmins: number;
  messagesToAdminsLoading: boolean;
  messagesToAdminsLoaded: boolean;
  messagesToAdminsError: boolean;
}

const MessageToAdmin: FC<Props> = ({
  getMessagesToAdmins,
  getApplication,
  application,
  messagesToAdmins,
  numberOfMessageToAdmins,
  messagesToAdminsLoading,
  messagesToAdminsLoaded,
  messagesToAdminsError,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [queryParamsDef, setQueryParamsDef] = useState<QueryParams>({
    from: moment().utc().subtract(7, 'days').toISOString(),
    to: moment().utc().toISOString(),
    page: 0,
    size: 20,
    limit: 20,
  });
  const [grouping, setGrouping] = useState<DynamicGroupByModel>({});

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refreshMessageToAdmins();
    // eslint-disable-next-line
  }, [grouping]);

  const updateQueryParams = (queryParams: QueryParams) => {
    if (!queryParams.from || !!queryParams.to) {
      return;
    }
    const { from, to } = formatDatesToIsoString(queryParams);
    queryParamsDef.from = from;
    queryParamsDef.to = to;
    setQueryParamsDef(queryParamsDef);
  };

  const refreshMessageToAdmins = () => {
    getMessagesToAdmins(appId, queryParamsDef, grouping);
  };

  const handleDateChange = async (from: string, to: string) => {
    setQueryParamsDef(
      Object.assign(queryParamsDef, {
        from: moment(from).toISOString(),
        to: moment(to).toISOString(),
      }),
    );
    refreshMessageToAdmins();
  };

  const fields = getListFieldsByConfiguration(
    application,
    configurationConstants.coreComplaintsList,
    t,
  );

  const handleDynamicGroupByChange = async (grouping: DynamicGroupByModel) => {
    setGrouping(grouping);
  };

  return (
    <div className="animated fadeIn">
      {fields ? (
        <List
          useCardContent
          data={messagesToAdmins}
          totalElements={numberOfMessageToAdmins}
          loaded={messagesToAdminsLoaded}
          loading={messagesToAdminsLoading}
          error={messagesToAdminsError}
          fetchData={updateQueryParams}
          title={t('messageToAdmin.messageToAdmin')}
          filterItems={() => (
            <Row className="align-items-center">
              <Col>
                <FormGroup>
                  <DateRangeFilter
                    handleDateChange={handleDateChange}
                    getAllData={refreshMessageToAdmins}
                  />
                </FormGroup>
              </Col>
              <Col>
                <DynamicGroupBy
                  appId={appId}
                  isMulti
                  handleDynamicGroupByChange={handleDynamicGroupByChange}
                  configurationNameSpace={configurationConstants.coreMessageToAdminFilter}
                />
              </Col>
            </Row>
          )}
          fields={fields}
          noDataLabel={t('messageToAdmin.noMessageToAdmins')}
          tableBodyProps={{ style: { minHeight: '500px' } }}
        />
      ) : (
        <p className="text-center">{t('common.configurationError')}</p>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    messagesToAdmins: state.messageToAdmin.data.content,
    numberOfMessageToAdmins: state.messageToAdmin.data.totalElements,
    messagesToAdminsLoading: state.messageToAdmin.loading,
    messagesToAdminsLoaded: state.messageToAdmin.loaded,
    messagesToAdminsError: state.messageToAdmin.error,
  };
};

const mapDispatchToProps = {
  getApplication,
  getMessagesToAdmins,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageToAdmin);
