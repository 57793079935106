import { del, get, patch, post } from '../../shared/services/http.service';
import { DynamicMenuModel, PageQueryParams } from 'src/shared/models';
import { HelpdeskGroupModel } from 'src/shared/models/helpdeskGroup.model';

const baseUrl = (applicationId: string) => `/applications/${applicationId}/helpdesk`;

export const getHelpdeskGroups = async (applicationId: string, params: PageQueryParams) => {
  return get(`${baseUrl(applicationId)}/groups`, { params });
};

export const getHelpdeskGroup = async (applicationId: string, helpdeskGroupId: string) => {
  return get(`${baseUrl(applicationId)}/groups/${helpdeskGroupId}`);
};

export const createHelpdeskGroup = async (
  applicationId: string,
  data: HelpdeskGroupModel,
  url: string,
) => {
  const createData = await post(`${baseUrl(applicationId)}/groups`, { data });
  if (data.dynamicMenuItemIds?.length) {
    const currentUrl = url
      .replace('{applicationId}', applicationId)
      .replace('{groupId}', createData.id);

    await post(currentUrl, {
      data: data.dynamicMenuItemIds,
    }).catch(() => {});
  }
  return createData;
};

export const updateHelpdeskGroup = async (
  applicationId: string,
  helpdeskGroupId: string,
  data: HelpdeskGroupModel,
  savingUrl: string,
  deletingUrl: string,
) => {
  const currentIds = data.dynamicMenuItems?.map((menu: DynamicMenuModel) => menu.id);
  const toAddIds = data.dynamicMenuItemIds?.filter(
    dynamicMenuItemId => currentIds?.indexOf(dynamicMenuItemId) === -1,
  );
  const toRemoveIds = currentIds?.filter(
    currentId => data?.dynamicMenuItemIds?.indexOf(currentId) === -1,
  );

  if (toAddIds?.length) {
    const currentUrl = savingUrl
      .replace('{applicationId}', applicationId)
      .replace('{groupId}', helpdeskGroupId);

    await post(currentUrl, {
      data: toAddIds,
    });
  }
  const removeQuery = toRemoveIds?.map(removeId => {
    const currentUrl = deletingUrl
      .replace('{applicationId}', applicationId)
      .replace('{groupId}', helpdeskGroupId)
      .replace('{dynamicMenuItemId}', removeId.toString())
      .replace('{dynamicCFMenuItemId}', removeId.toString());

    return del(currentUrl);
  });
  if (removeQuery) {
    await Promise.all(removeQuery);
  }

  return patch(`${baseUrl(applicationId)}/groups/${helpdeskGroupId}`, { data });
};

export const deleteHelpdeskGroup = async (applicationId: string, helpdeskGroupId: string) => {
  return del(`${baseUrl(applicationId)}/groups/${helpdeskGroupId}`);
};

export const addTagToHelpdeskGroup = async (
  applicationId: string,
  helpdeskGroupId: string,
  tagIds: string[],
) => {
  const query = tagIds.map(tagId =>
    post(
      `/hrops/applications/${applicationId}/tag/helpdesk-groups/${helpdeskGroupId}/tags/${tagId}`,
    ),
  );
  return Promise.all(query);
};

export const removeHelpdeskGroupTag = async (
  applicationId: string,
  helpdeskGroupId: string,
  tagId: string,
) => {
  return del(
    `/hrops/applications/${applicationId}/tag/helpdesk-groups/${helpdeskGroupId}/tags/${tagId}`,
  );
};

export const getMembersForGroup = async (applicationId: string, helpdeskGroupId: number) => {
  return get(`/applications/${applicationId}/helpdesk/groups/${helpdeskGroupId}/users`);
};
