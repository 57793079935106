import { StateModel } from 'src/shared/models';
import {
  GET_TICKETS,
  GET_TICKETS_ERROR,
  GET_TICKETS_SUCCESS,
  DELETE_TICKET,
  DELETE_TICKET_SUCCESS,
  DELETE_TICKET_ERROR,
} from '../actions/tickets.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const ticketsReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case GET_TICKETS:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload,
      };
    case GET_TICKETS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };
    case DELETE_TICKET:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case DELETE_TICKET_SUCCESS:
      const content = state.data.content.filter(({ id }: any) => id !== payload.ticketId);
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          content,
        },
      };
    case DELETE_TICKET_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };

    default:
      return state;
  }
};

export default ticketsReducer;
