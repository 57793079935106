import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import {
  answerActionTypeToDisplay,
  answerActionObjects, answerActionType,
} from 'src/shared/constants/questionnaire.constans';
import { SelectModel } from 'src/shared/models/common.model';

import styles from '../../../Questionnaire.module.scss';
import { FormikValues, useFormikContext } from 'formik';
import { QuestionnaireAnswerActionType, QuestionnaireMessageType } from '../../../../../shared/models';

interface Props {
  index: number;
  answer: any;
  handleReplace: (index: number, value: any) => void;
  messageType: string;
}

const AnswerActionsDropdown: React.FC<Props> = ({ index, answer, handleReplace, messageType }) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const { values }: FormikValues = useFormikContext();

  const handleActionTypeChange = (actionType: string) => {
    if (answer.answerActionType === actionType) return;

    const defaultAnswerValues = {
      ...answer,
      answerActionType: actionType,
    };

    if (actionType === QuestionnaireAnswerActionType.NONE) {
      delete defaultAnswerValues.answerActionRootData;
      handleReplace(index, defaultAnswerValues);
      return;
    }

    let modifiedAnswerValues = {};

    if (actionType === QuestionnaireAnswerActionType.LABEL_APPLICANT) {
      modifiedAnswerValues = {
        ...defaultAnswerValues,
        answerActionRootData: {
          type: 'label-applicant',
          labelId: null,
        },
      };
    }

    if (actionType === QuestionnaireAnswerActionType.ATTRIBUTE_APPLICANT) {
      modifiedAnswerValues = {
        ...defaultAnswerValues,
        answerActionRootData: {
          type: 'attribute-applicant',
          attributeKey: '',
          attributeRequired: false,
          attributeMultiplicable: false,
        },
      };
    }

    if (actionType === QuestionnaireAnswerActionType.ATTRIBUTE_APPLICANT_POSITION) {
      modifiedAnswerValues = {
        ...defaultAnswerValues,
        text: '',
        answerActionRootData: {
          type: 'position-attribute-applicant',
          attributeKey: '',
        },
      };
    }

    if (actionType === QuestionnaireAnswerActionType.INVISIBLE_ANSWER) {
      delete defaultAnswerValues.answerActionRootData;
      handleReplace(index, defaultAnswerValues);
      return;
    }

    if (actionType === QuestionnaireAnswerActionType.ONE_CITY_AVAILABLE) {
      delete defaultAnswerValues.answerActionRootData;
      handleReplace(index, defaultAnswerValues);
      return;
    }

    if (actionType === QuestionnaireAnswerActionType.NO_POSITION_FOUND) {
      delete defaultAnswerValues.answerActionRootData;
      handleReplace(index, defaultAnswerValues);
      return;
    }

    if (actionType === QuestionnaireAnswerActionType.ONE_LABEL_AVAILABLE) {
      delete defaultAnswerValues.answerActionRootData;
      handleReplace(index, defaultAnswerValues);
      return;
    }

    if (actionType === QuestionnaireAnswerActionType.NO_INTERVIEW_EVENT_FOUND) {
      delete defaultAnswerValues.answerActionRootData;
      handleReplace(index, defaultAnswerValues);
      return;
    }

    if (actionType === QuestionnaireAnswerActionType.ACTIVE_INTERVIEW_EVENT_EXISTS) {
      delete defaultAnswerValues.answerActionRootData;
      handleReplace(index, defaultAnswerValues);
      return;
    }
    if (actionType === QuestionnaireAnswerActionType.VALIDATION_FAILED) {
      delete defaultAnswerValues.answerActionRootData;
      handleReplace(index, defaultAnswerValues);
      return;
    }

    handleReplace(index, modifiedAnswerValues);
  };

  const filteredAnswerActionObjects = answerActionObjects.filter(function(item){
    if (messageType === QuestionnaireMessageType.CLOSED_ENDED_ANSWERS) {
      return item.value !== answerActionType.ONE_CITY_AVAILABLE && item.value !== answerActionType.NO_POSITION_FOUND && item.value !== answerActionType.ONE_LABEL_AVAILABLE && item.value !== answerActionType.ACTIVE_INTERVIEW_EVENT_EXISTS && item.value !== answerActionType.NO_INTERVIEW_EVENT_FOUND;
    } else if (messageType === QuestionnaireMessageType.OPEN_ENDED_ANSWERS) {
      switch (values.saveLocation?.value) {
        case 'attribute':
          return item.value === answerActionType.ATTRIBUTE_APPLICANT || item.value === answerActionType.INVISIBLE_ANSWER || item.value === answerActionType.VALIDATION_FAILED;

        case 'positionAttribute':
          return item.value === answerActionType.ATTRIBUTE_APPLICANT_POSITION || item.value === answerActionType.INVISIBLE_ANSWER || item.value === answerActionType.VALIDATION_FAILED;

        case 'none':
          return item.value === answerActionType.NONE || item.value === answerActionType.INVISIBLE_ANSWER || item.value === answerActionType.VALIDATION_FAILED;

        default:
          return false;
      }
    } else if (messageType === QuestionnaireMessageType.DOCUMENT_UPLOAD_CONTROLLER) {
      switch (values.saveLocation?.value) {
        case 'attribute':
          return item.value === answerActionType.ATTRIBUTE_APPLICANT || item.value === answerActionType.INVISIBLE_ANSWER;

        case 'positionAttribute':
          return item.value === answerActionType.ATTRIBUTE_APPLICANT_POSITION || item.value === answerActionType.INVISIBLE_ANSWER;

        case 'none':
          return item.value === answerActionType.NONE || item.value === answerActionType.INVISIBLE_ANSWER;

        default:
          return false;
      }
    } else if (messageType === QuestionnaireMessageType.LOCATION_CITY || messageType === QuestionnaireMessageType.LOCATION_COUNTY || messageType === QuestionnaireMessageType.LOCATION_PART_OF_COUNTRY) {
      return item.value !== answerActionType.ONE_LABEL_AVAILABLE && item.value !== answerActionType.ACTIVE_INTERVIEW_EVENT_EXISTS && item.value !== answerActionType.NO_INTERVIEW_EVENT_FOUND;
    } else if (messageType === QuestionnaireMessageType.SHOW_POSITION_MESSAGE) {
      return item.value !== answerActionType.ONE_CITY_AVAILABLE && item.value !== answerActionType.ONE_LABEL_AVAILABLE && item.value !== answerActionType.ACTIVE_INTERVIEW_EVENT_EXISTS && item.value !== answerActionType.NO_INTERVIEW_EVENT_FOUND;
    } else if (messageType === QuestionnaireMessageType.DYNAMIC_LABEL_MESSAGE) {
      return item.value !== answerActionType.ONE_CITY_AVAILABLE && item.value !== answerActionType.ACTIVE_INTERVIEW_EVENT_EXISTS && item.value !== answerActionType.NO_INTERVIEW_EVENT_FOUND;
    } else if (messageType === QuestionnaireMessageType.SHOW_INTERVIEW_EVENTS_MESSAGE) {
      return item.value !== answerActionType.ONE_CITY_AVAILABLE && item.value !== answerActionType.ONE_LABEL_AVAILABLE && item.value !== answerActionType.NO_POSITION_FOUND;
    } else return true;
    })

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle className={styles.actionTypeButton} caret>
        {answerActionTypeToDisplay[answer.answerActionType]}
      </DropdownToggle>
      <DropdownMenu>
        {filteredAnswerActionObjects.map((action: SelectModel) => (
          <DropdownItem
            key={action.value}
            onClick={() => handleActionTypeChange(action.value.toString())}
          >
            {action.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default AnswerActionsDropdown;
