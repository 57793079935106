import { Dispatch } from 'redux';
import {
  getCustomFields as getCustomFieldsService,
  createCustomField as createCustomFieldervice,
  updateCustomField as updateCustomFieldService,
  deleteCustomField as deleteCustomFieldService,
} from '../services/custom-fields.service';

import { CustomFieldsQueryParams } from 'src/shared/models/query-params.model';
import { CustomFieldModel } from 'src/shared/models/custom-fields.model';

export const GET_CUSTOM_FIELDS_REQUEST = 'custom-fields/GET_CUSTOM_FIELDS_REQUEST';
export const GET_CUSTOM_FIELDS_SUCCESS = 'custom-fields/GET_CUSTOM_FIELDS_SUCCESS';
export const GET_CUSTOM_FIELDS_ERROR = 'custom-fields/GET_CUSTOM_FIELDS_ERROR';

export const CREATE_CUSTOM_FIELD_REQUEST = 'custom-fields/CREATE_CUSTOM_FIELD_REQUEST';
export const CREATE_CUSTOM_FIELD_SUCCESS = 'custom-fields/CREATE_CUSTOM_FIELD_SUCCESS';
export const CREATE_CUSTOM_FIELD_ERROR = 'custom-fields/CREATE_CUSTOM_FIELD_ERROR';

export const UPDATE_CUSTOM_FIELD_REQUEST = 'custom-fields/UPDATE_CUSTOM_FIELD_REQUEST';
export const UPDATE_CUSTOM_FIELD_SUCCESS = 'custom-fields/UPDATE_CUSTOM_FIELD_SUCCESS';
export const UPDATE_CUSTOM_FIELD_ERROR = 'custom-fields/UPDATE_CUSTOM_FIELD_ERROR';

export const DELETE_CUSTOM_FIELD_REQUEST = 'custom-fields/DELETE_CUSTOM_FIELD_REQUEST';
export const DELETE_CUSTOM_FIELD_SUCCESS = 'custom-fields/DELETE_CUSTOM_FIELD_SUCCESS';
export const DELETE_CUSTOM_FIELD_ERROR = 'custom-fields/DELETE_CUSTOM_FIELD_ERROR';

export const CLEAR_CUSTOM_FIELDS = 'custom-fields/CLEAR_CUSTOM_FIELDS';

export const getCustomFields = (applicationId: string, queryParams: CustomFieldsQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_CUSTOM_FIELDS_REQUEST,
    });

    try {
      dispatch({
        type: GET_CUSTOM_FIELDS_SUCCESS,
        payload: await getCustomFieldsService(applicationId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_CUSTOM_FIELDS_ERROR,
        error,
      });
    }
  };
};

export const createCustomField = (applicationId: string, customField: CustomFieldModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_CUSTOM_FIELD_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_CUSTOM_FIELD_SUCCESS,
        payload: await createCustomFieldervice(applicationId, customField),
      });
    } catch (error) {
      dispatch({
        type: CREATE_CUSTOM_FIELD_ERROR,
        error,
      });
    }
  };
};

export const updateCustomField = (
  applicationId: string,
  customFieldId: string,
  customField: CustomFieldModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_CUSTOM_FIELD_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_CUSTOM_FIELD_SUCCESS,
        payload: await updateCustomFieldService(applicationId, customFieldId, customField),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_CUSTOM_FIELD_ERROR,
        error,
      });
    }
  };
};

export const deleteCustomField = (applicationId: string, customFieldId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_CUSTOM_FIELD_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_CUSTOM_FIELD_SUCCESS,
        payload: await deleteCustomFieldService(applicationId, customFieldId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_CUSTOM_FIELD_ERROR,
        error,
      });
    }
  };
};

export const clearCustomFields = () => {
  return {
    type: CLEAR_CUSTOM_FIELDS,
  };
};
