import React from 'react';
import { QueryParams } from 'src/shared/models';

export enum Operations {
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
  REPORT = 'REPORT',
}

export enum ListSortDirections {
  ASC = 'asc',
  DESC = 'desc',
}

export interface ListFieldModel<T> {
  label?: string;
  key: string;
  render?: (item: T, index: number, activePage: number) => React.ReactNode;
  dateTime?: boolean;
  date?: boolean;
  columnStyle?: string;
  columnCssStyle?: React.CSSProperties;
  operations?: Operations[];
  sort?: ListSortDirections;
  ellipsis?: number;
  dynamicColumnCssProperties?: (item: T) => React.CSSProperties;
}

export const defaultQueryParams = {
  page: 0,
  size: 20,
  limit: 20,
};

export interface ListModel<T> {
  deleteConfirmKey?: string;
  deleting?: boolean;
  updateRedirectPath?: string;
  reportRedirectPath?: string;
  deletePermission?: string;
  updatePermission?: string;
  reportPermission?: string;
  deleteTitle?: string;
  deleteText?: string;
  highlightFirstRow?: boolean;
  updateList?: boolean;
  defaultItemOnPage?: number;
  fetchData: Function;
  customRowClassName?: Function;
  deleteFunction?: (item: T) => Promise<void>;
  editFunction?: (id: string | number) => Promise<void>;
  onDragEnd?: (orderedItems: Array<any>) => void;
}

export interface ListRowModel<T> {
  fields: ListFieldModel<T>[];
  queryParams: QueryParams;
  activePage: number;
}

export interface ListRowsModel<T> {
  data: T[];
  fields: ListFieldModel<T>[];
}

export interface ListContentModel<T> extends ListModel<T>, ListRowsModel<T> {
  title?: string;
  hidePager?: boolean;
  totalElements: number;
  loaded?: boolean;
  loading?: boolean;
  error?: boolean;
  noDataLabel: string;
  createModal?: (
    isModalOpen: boolean,
    setIsModalOpen: (saved?: boolean) => void,
  ) => React.ReactNode;
  filterItems?: () => React.ReactNode;
  filterContent?: () => React.ReactNode;
}
