import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import capitalize from 'lodash/capitalize';
import { generateId } from '../../../shared/utils/generate-id.util';
import {
  TalentPoolCandidateWithAnswersModel,
  TalentPoolCandidateAnswerModel,
} from '../../../shared/models/talent-pools.model';

import styles from '../TalentPools.module.scss';

interface Props extends WithTranslation {
  selectedCandidate: TalentPoolCandidateWithAnswersModel | null;
  position: string | null;
  isOpen: boolean;
  toggleModal: Function;
}

interface State {
  candidate: TalentPoolCandidateWithAnswersModel;
}

class TalentPoolCandidateModal extends Component<Props, State> {
  state: State = {
    candidate: {
      id: '',
      name: '',
      email: '',
      phone: '',
      cv: '',
      marked: false,
      talentPoolId: 0,
      createdAt: '',
      updatedAt: null,
      userAnswers: [],
    },
  };

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.selectedCandidate) {
      this.setState({
        candidate: nextProps.selectedCandidate,
      });
    }
  }

  renderCVDownload = (downloadUrl: string) => {
    const { t } = this.props;

    if (!downloadUrl) {
      return <span className="text-muted">{t('talentPools.noCV')}</span>;
    }

    return (
      <Button
        outline
        color="dark"
        size="sm"
        target="_blank"
        rel="noopener noreferrer"
        href={downloadUrl}
        download
      >
        <i className="fas fa-cloud-download-alt fa-lg mr-2" />
        {t('common.download')}
      </Button>
    );
  };

  renderQuestionsAndAnswers = (userAnswers: TalentPoolCandidateAnswerModel[]) => {
    const { t } = this.props;

    if (!userAnswers || !userAnswers.length) {
      return (
        <Row>
          <Col className="d-flex justify-content-center mt-2">
            <span className="text-muted">{t('talentPools.noQuestionsAndAnswers')}</span>
          </Col>
        </Row>
      );
    }

    return userAnswers.map((userAnswer: TalentPoolCandidateAnswerModel) => {
      const answerAsJSON = this.answerToJSON(userAnswer.answer);

      return (
        <Row key={userAnswer.id} className={styles.informationRow}>
          <Col className={styles.informationCol}>{capitalize(userAnswer.shortName)}</Col>
          <Col className={styles.informationCol}>
            <strong>
              {answerAsJSON instanceof Object
                ? this.renderIfAnswerIsObject(answerAsJSON)
                : userAnswer.answer || '—'}
            </strong>
          </Col>
        </Row>
      );
    });
  };

  answerToJSON = (answer: string) => {
    if (!Number.isNaN(parseInt(answer, 10))) return null;

    try {
      const result = JSON.parse(answer);
      return result;
    } catch (error) {
      return null;
    }
  };

  renderIfAnswerIsObject = (answerAsJSON: any) => {
    const { selectedOptions } = answerAsJSON;

    if (selectedOptions && !selectedOptions.length) {
      return <strong>—</strong>;
    }

    return (
      selectedOptions &&
      selectedOptions.map((el: any) => {
        return (
          <span className={styles.multipleAnswer} key={generateId(10)}>
            {`${el.name} (${el.value})`}
          </span>
        );
      })
    );
  };

  render() {
    const { position, isOpen, toggleModal, t } = this.props;
    const { candidate } = this.state;

    return (
      <Modal
        className={styles.candidateModal}
        size="lg"
        isOpen={isOpen}
        toggle={() => toggleModal()}
      >
        <ModalHeader>{t('talentPools.detailsOfCandidate')}</ModalHeader>
        <ModalBody>
          <div className={styles.basicInformations}>
            <p className={styles.title}>{t('talentPools.basicInformations')}</p>
            <Row className={styles.informationRow}>
              <Col className={styles.informationCol}>{t('common.name')}</Col>
              <Col className={styles.informationCol}>
                <strong>{candidate.name || '—'}</strong>
              </Col>
            </Row>

            <Row className={styles.informationRow}>
              <Col className={styles.informationCol}>{t('talentPools.position')}</Col>
              <Col className={styles.informationCol}>
                <strong>{position || '—'}</strong>
              </Col>
            </Row>

            <Row className={styles.informationRow}>
              <Col className={styles.informationCol}>{t('common.email')}</Col>
              <Col className={styles.informationCol}>
                <strong>{candidate.email || '—'}</strong>
              </Col>
            </Row>

            <Row className={styles.informationRow}>
              <Col className={styles.informationCol}>{t('common.phone')}</Col>
              <Col className={styles.informationCol}>
                <strong>{candidate.phone || '—'}</strong>
              </Col>
            </Row>

            <Row className={styles.informationRow}>
              <Col className={styles.informationCol}>{t('talentPools.cv')}</Col>
              <Col className={styles.informationCol}>{this.renderCVDownload(candidate.cv)}</Col>
            </Row>
          </div>
          <div className={styles.questionsAnswers}>
            <p className={styles.title}>Questions &amp; Answers</p>
            {this.renderQuestionsAndAnswers(candidate.userAnswers)}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggleModal()}>
            {t('common.close')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withTranslation()(TalentPoolCandidateModal);
