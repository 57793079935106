import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { Formik, Form, FormikValues } from 'formik';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';

import {
  ImportConfigurationTypesForSelect,
  ImportConfigurationTypes,
  ImportConfigurationModel,
} from 'src/shared/models/import-configuration.model';
import {
  createImportConfiguration,
  updateImportConfiguration,
} from 'src/redux/actions/import-configuration.action';
import { importConfigurationValidatorSchema } from 'src/shared/schemas/validation.schema';
import { Select, Spinner, TextArea, TextField } from 'src/shared/components';

interface Props {
  isOpen: boolean;
  editDocument?: ImportConfigurationModel;
  toggleModalState: Function;
  onSaved?: Function;
  createImportConfiguration: Function;
  updateImportConfiguration: Function;
}

const ImportConfigurationModalForm: React.FC<Props> = ({
  isOpen,
  editDocument,
  toggleModalState,
  onSaved,
  createImportConfiguration,
  updateImportConfiguration,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const getFormInitValues = () => ({
    title: editDocument ? editDocument.title : '',
    type: editDocument ? editDocument.type : ImportConfigurationTypes.DYNAMIC_FAQ,
    configuration:
      editDocument && editDocument.configuration
        ? JSON.stringify(editDocument.configuration, null, 2)
        : '',
  });

  const handleSubmit = async ({ ...values }: FormikValues) => {
    if (editDocument) {
      await updateImportConfiguration(appId, editDocument.id, values);
    } else {
      await createImportConfiguration(appId, values);
    }
    if (onSaved) {
      onSaved();
    }
    toggleModalState(false);
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        {editDocument ? editDocument.title : t('importConfiguration.createConfiguration')}
      </ModalHeader>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={getFormInitValues()}
        validationSchema={importConfigurationValidatorSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <ModalBody>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="title">{t('common.title')}</Label>
                    <TextField name="title" />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="type">{t('common.type')}</Label>
                    <Select name="type" options={ImportConfigurationTypesForSelect} />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="configuration">{t('importConfiguration.configuration')}</Label>
                    <TextArea rows="10" name="configuration" />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>

            <ModalFooter>
              <Spinner loading={isSubmitting} />

              <Button type="submit" color="success" disabled={isSubmitting}>
                {t('common.save')}
              </Button>

              <Button color="secondary" onClick={() => toggleModalState(false)}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {
  createImportConfiguration,
  updateImportConfiguration,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportConfigurationModalForm);
