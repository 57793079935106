import { TranslationListItem } from './content-translations-model';

export interface GetSurveyTranslationsResponse extends Array<TranslatableSurvey> {}

export interface TranslatableSurvey {
  id: number;
  applicationId: number;
  name: string;
  description: string;
  startMessage: string;
  questions: TranslatableQuestion[];
  translations: SurveyTranslation[];
}

export interface SurveyTranslation {
  languageCode: string;
  surveyId: number;
  name: string;
  description: string;
  startMessage: string;
}

export interface TranslatableQuestion {
  id: number;
  surveyId: number;
  applicationId: number;
  text: string;
  surveyQuestionAnswers: TranslatableQuestionAnswer[];
  translations: QuestionTranslation[];
}

export interface QuestionTranslation {
  languageCode: string;
  surveyQuestionId: number;
  text: string;
}

export interface TranslatableQuestionAnswer {
  id: number;
  surveyQuestionId: number;
  text: string;
  type: SurveyQuestionAnswerType;
  value: number;
  translations: QuestionAnswerTranslation[];
}

export interface QuestionAnswerTranslation {
  languageCode: string;
  surveyQuestionAnswerId: number;
  text: string;
}

export enum SurveyQuestionAnswerType {
  TEXT = 'SURVEY_QUESTION_ANSWER_TYPE_TEXT',
  OPTION = 'SURVEY_QUESTION_ANSWER_TYPE_OPTION',
}

export interface ContentTranslationSurveyListModel
  extends Array<ContentTranslationSurveyListItem> {}

export interface ContentTranslationSurveyListItem extends TranslationListItem {
  surveyId?: number;
  surveyQuestionId?: number;
  surveyQuestionAnswerId?: number;
}

export enum SurveyTranslationLevel {
  Base,
  Question,
  QuestionAnswer,
}

export interface SurveyTranslationRequestBase {
  name?: string;
  description?: string;
  startMessage?: string;
}

export interface CreateSurveyTranslationRequest extends SurveyTranslationRequestBase {
  languageCode: string;
}

export interface EditSurveyTranslationRequest extends SurveyTranslationRequestBase {}
export interface DeleteSurveyTranslationRequest extends SurveyTranslationRequestBase {}

export interface CreateSurveyQuestionTranslationRequest {
  languageCode: string;
  surveyQuestionId: number;
  text: string;
}

export interface EditSurveyQuestionTranslationRequest {
  text?: string;
}

export interface CreateSurveyQuestionAnswerTranslationRequest {
  languageCode: string;
  text: string;
}

export interface EditSurveyQuestionAnswerTranslationRequest {
  text?: string;
}
