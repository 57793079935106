import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { Alert } from 'reactstrap';

import { StateModel } from 'src/shared/models/default-state.model';
import { UserModel } from 'src/shared/models/user.model';
import { PersonaModel } from 'src/shared/models/persona.model';

import { sendHandoverMessage, ConversationModel } from 'src/redux/actions/conversations.action';
import { getConversationHistory, addMessage } from 'src/redux/actions/conversation-history.action';
import { generateId } from 'src/shared/utils/generate-id.util';

import PersonaSelector from './PersonaSelector';

import styles from '../../../ConversationHistory.module.scss';

interface Props {
  conversation: ConversationModel;
  getConversationHistory: Function;
  user: UserModel;
  platform: string;
  sendHandoverMessage: Function;
  showContent: boolean;
  loadedMessages: number;
  totalMessages: number;
  handoverState: StateModel;
  addMessage: Function;
  selectedPersona: PersonaModel;
}

const HistoryFooter: React.FC<Props> = ({
  conversation,
  user,
  platform,
  sendHandoverMessage,
  getConversationHistory,
  showContent,
  loadedMessages,
  totalMessages,
  handoverState,
  addMessage,
  selectedPersona,
}) => {
  const { t } = useTranslation();
  const { userId }: any = useParams();
  const [message, setMessage] = useState<string>('');
  const [isEmojiPickerOpen, setEmojiPickerOpen] = useState<boolean>(false);
  const handoverMessageInput = useRef<HTMLInputElement>(null);
  const emojiPicker = useRef<HTMLSpanElement>(null);
  const isHandoverAvailable = !conversation.timeWindowExpired;

  const handleClick = (e: any) => {
    if (emojiPicker.current) {
      if (!emojiPicker.current.contains(e.target)) {
        setEmojiPickerOpen(false);
      }
    }
  };

  const handleOnChange = (text: string) => {
    setMessage(text);
  };

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!message.length) return;

    if (userId && user && selectedPersona) {
      const handoverMessage = {
        message,
        personaId: selectedPersona.id,
      };

      const historyMessage = {
        id: generateId(10),
        messagingPlatform: platform,
        creationTimestamp: new Date().toISOString(),
        messageAsText: message,
        messageAsRaw: message,
        isOutgoingMessage: true,
        sender: `${user.firstName} ${user.lastName}`,
      };
      addMessage(historyMessage);
      sendHandoverMessage(userId, handoverMessage);

      setMessage('');

      if (handoverMessageInput.current) {
        handoverMessageInput.current.value = '';
        handoverMessageInput.current.focus();
      }
    }
  };

  const handleEmojiSelect = (emoji: any) => {
    setMessage(message + emoji.native);
  };

  // useEffect(() => {
  //  getPersonas();
  // }, []);

  // useEffect(() => {
  //   const { loaded, error } = handoverState;

  //   if (loaded && !error && handoverMessageInput.current) {
  //     const prevLocation = window.location.href;
  //     setMessage('');
  //     handoverMessageInput.current.value = '';
  //     handoverMessageInput.current.focus();

  //     const getHistory = (prevLocation: string) => {
  //       // Csak akkor kérjük le a beszélgetést ha nem történt felhasználó vagy oldal váltás.
  //       if (prevLocation === window.location.href) {
  //         getConversationHistory(userId, { page: 0, limit: 20, size: 20 });
  //       }
  //     };

  //     setTimeout(() => {
  //       getHistory(prevLocation);
  //     }, 3000);
  //   }
  //   // eslint-disable-next-line
  // }, [handoverState]);

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const inputPlaceholder = conversation.handoverStatus
    ? t('conversationHistory.selectUser')
    : t('conversationHistory.muteChatbotToType');

  return (
    <div className={styles.historyFooter}>
      {showContent && !isHandoverAvailable && (
        <span>{t('conversationHistory.messagesLoaded', { loadedMessages, totalMessages })}</span>
      )}
      {isHandoverAvailable && (
        <Fragment>
          <form className={styles.handoverMessageForm}>
            <PersonaSelector />
            {selectedPersona.id ? (
              <input
                ref={handoverMessageInput}
                className={styles.handoverMessageInput}
                type="text"
                id="handoverMessageInput"
                disabled={!conversation.handoverStatus || !selectedPersona}
                placeholder={inputPlaceholder}
                value={message}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleOnChange(e.currentTarget.value)
                }
              />
            ) : (
              <Alert className={styles.alert} variant="filled" severity="info">
                {t('conversationHistory.selectUser')}
              </Alert>
            )}

            <button
              type="button"
              className={styles.emojiPickerToggler}
              disabled={!conversation.handoverStatus}
              onClick={() => setEmojiPickerOpen(!isEmojiPickerOpen)}
            >
              <i className="far fa-smile fa-lg" />
            </button>

            <button
              type="submit"
              className={styles.sendMessageButton}
              disabled={
                !conversation.handoverStatus ||
                !message.length ||
                !selectedPersona ||
                handoverState.loading
              }
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleOnClick(e)}
            >
              <i className="far fa-paper-plane" />
            </button>
          </form>

          {isEmojiPickerOpen && (
            <span ref={emojiPicker} className={styles.emojiPicker}>
              <Picker
                native
                emojiSize={20}
                perLine={10}
                sheetSize={16}
                showPreview={false}
                showSkinTones={false}
                onClick={emoji => handleEmojiSelect(emoji)}
              />
            </span>
          )}
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  conversation: state.conversations.data.currentConversation,
  handoverState: state.conversationsHandover.handoverMessage,
  queryParams: state.conversationsQueryParams,
  platform: state.conversations.data.currentPlatform,
  user: state.user.data,
  selectedPersona: state.selectedPersona,
});

const mapDispatchToProps = {
  addMessage,
  sendHandoverMessage,
  getConversationHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryFooter);
