import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody, Alert } from 'reactstrap';

import { CustomFieldModel } from 'src/shared/models/custom-fields.model';

import { StateModel } from 'src/shared/models/default-state.model';
import { ApplicantsQueryParams } from 'src/shared/models/query-params.model';

import {
  getTalentPoolApplicants,
  clearTalentPoolApplicants,
} from 'src/redux/actions/talent-pool.action';
import { getApplication, ApplicationModel } from 'src/redux/actions/applications.action';
import { getCustomFields, clearCustomFields } from 'src/redux/actions/custom-fields.action';

import { exportTalentPoolApplicants } from 'src/redux/services/talent-pool.service';

import { useDeepEffect } from 'src/shared/hooks';

import Spinner from 'src/shared/components/Spinner';
import TalentPoolHeader from './components/TalentPoolHeader';
import TalentPoolFilters from './components/TalentPoolFilters';
import TalentPoolTable from './components/TalentPoolTable';
import { getCustomFieldKeys } from 'src/shared/utils/misc.util';
import saveFile from 'src/shared/utils/file-saver.util';
import { fileNames } from 'src/shared/settings';
import { exportType } from 'src/shared/constants/export.constants';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  applicantsState: StateModel;
  getTalentPoolApplicants: Function;
  customFields: CustomFieldModel[];
  getCustomFields: Function;
  customFieldsState: StateModel;
  clearCustomFields: Function;
  clearTalentPoolApplicants: Function;
}

const TalentPool: React.FC<Props> = ({
  application,
  getApplication,
  applicantsState,
  getTalentPoolApplicants,
  customFields,
  getCustomFields,
  customFieldsState,
  clearCustomFields,
  clearTalentPoolApplicants,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const [canGetCustomFieldKeys, setCanGetCustomFieldKeys] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<ApplicantsQueryParams>({
    page: 0,
    size: 50,
    labels: '',
    positions: '',
    sort: 'userProfile.surveyFilledAt,id,DESC',
    attributes: '',
  });

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }

    getCustomFields(appId);

    setCanGetCustomFieldKeys(true);

    return () => {
      clearCustomFields();
      clearTalentPoolApplicants();
    };
    /* eslint-disable-next-line */
  }, []);

  useDeepEffect(() => {
    if (queryParams.attributes) {
      getTalentPoolApplicants(appId, queryParams);
    }
    /* eslint-disable-next-line */
  }, [queryParams]);

  useEffect(() => {
    if (canGetCustomFieldKeys && customFields?.length > 0) {
      const customFieldKeys = getCustomFieldKeys(customFields, 'talentPoolList');

      const newQueryParams = {
        ...queryParams,
        attributes: customFieldKeys.join(','),
      };

      setQueryParams(newQueryParams);
    }
    /* eslint-disable-next-line */
  }, [customFields]);

  const handleExport = async (id: string) => {
    const params = { ...queryParams };
    delete params.page;
    delete params.size;

    if (id === exportType.EXCEL || id === exportType.CSV) {
      const res = await exportTalentPoolApplicants(appId, params, id);
      if (id === exportType.CSV) {
        saveFile(res, fileNames.TALENT_POOL_APPLICANTS_EXPORT_CSV, exportType.CSV);
      } else if (id === exportType.EXCEL) {
        saveFile(res, fileNames.TALENT_POOL_APPLICANTS_EXPORT_EXCEL, exportType.EXCEL);
      }
    }
  };

  const noDataFound =
    (customFieldsState.loaded && !customFields.length) ||
    (applicantsState.loaded && !applicantsState.data.content?.length);

  const pageChange = (index: number) => {
    const newQueryParams = {
      ...queryParams,
      page: index - 1,
    };

    setQueryParams(newQueryParams);
  };

  return (
    <Row className="animated fadeIn">
      <Col>
        <TalentPoolHeader handleExport={handleExport} isExportDisabled={noDataFound} />
        <TalentPoolFilters setQueryParams={setQueryParams} />

        <Card>
          <CardBody>
            {customFieldsState.loading ||
              (applicantsState.loading && (
                <div className="d-flex justify-content-center">
                  <Spinner loading={applicantsState.loading} size="2x" />
                </div>
              ))}

            {noDataFound && (
              <Alert color="info" className="text-center mb-0">
                {t('talentPool.noApplicantsFound')}
              </Alert>
            )}

            {applicantsState.loaded && applicantsState.data.content?.length > 0 && (
              <TalentPoolTable
                application={application}
                applicants={applicantsState.data.content}
                totalApplicants={applicantsState.data.totalElements}
                itemsPerPage={queryParams.size}
                activePage={queryParams.page + 1}
                customFields={customFields}
                pageChange={pageChange}
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  applicantsState: state.talentPoolApplicants,
  customFields: state.customFields.data.customFields.data,
  customFieldsState: state.customFields.data.customFields,
});

const mapDispatchToProps = {
  getApplication,
  getTalentPoolApplicants,
  getCustomFields,
  clearCustomFields,
  clearTalentPoolApplicants,
};

export default connect(mapStateToProps, mapDispatchToProps)(TalentPool);
