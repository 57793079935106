import React from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';

import { InterviewModel } from 'src/shared/models';
import { CheckPermission, DateTime, TagPill } from 'src/shared/components';

import styles from '../Interviews.module.scss';
import { useHistory, useLocation } from 'react-router-dom';

interface Props {
  interviews: InterviewModel[];
  selectInterview: (interview: InterviewModel) => void;
  setModalEvent: (event: string) => void;
  toggleModal: (isOpen: boolean) => void;
  toggleConfirmModal: (isOpen: boolean) => void;
}

const InterviewsTableBody: React.FC<Props> = ({
  interviews,
  selectInterview,
  setModalEvent,
  toggleModal,
  toggleConfirmModal,
}) => {
  const history = useHistory();
  const location = useLocation();

  const redirectTo = (path: string, eventId: number) => {
    history.push(`${location.pathname}/${eventId}/${path}`);
  };

  return (
    <tbody>
      {interviews.map(interview => (
        <tr key={interview.id}>
          <td className={styles.name}>{interview.name}</td>
          <td className={styles.date}>{moment(interview.startTime).format('YYYY-MM-DD')}</td>
          <td className={styles.from}>{moment(interview.startTime).format('HH:mm')}</td>
          <td className={styles.to}>{moment(interview.endTime).format('HH:mm')}</td>
          <td className={styles.deadline}>
            <DateTime dateTime={interview.applicationDeadline} />
          </td>

          <td className={styles.limit}>{interview.limit}</td>
          <td className={styles.positions}>
            {interview.positions?.length && (
              <div className={styles.positionTagPills}>
                {interview.positions.map(position => (
                  <TagPill
                    item={position.name}
                    isEditable={false}
                    isDeleteable={false}
                    key={position.name}
                  />
                ))}
              </div>
            )}
          </td>
          <td>{interview.applicantsCount}</td>
          <td>
            <Button
              size="sm"
              color="secondary"
              onClick={() => redirectTo('attendees', interview.id!)}
            >
              <i className="fas fa-list" />
            </Button>
          </td>
          <td className={styles.operations}>
            <CheckPermission variant="enableIf" permissions={['interview_event_update']}>
              <Button
                size="sm"
                color="secondary"
                onClick={() => {
                  setModalEvent('edit');
                  selectInterview(interview);
                  toggleModal(true);
                }}
              >
                <i className="fas fa-edit" />
              </Button>
            </CheckPermission>

            <CheckPermission variant="enableIf" permissions={['interview_event_delete']}>
              <Button
                size="sm"
                color="danger"
                className="ml-2"
                onClick={() => {
                  selectInterview(interview);
                  toggleConfirmModal(true);
                }}
              >
                <i className="fas fa-trash-alt" />
              </Button>
            </CheckPermission>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default InterviewsTableBody;
