const randomNumber = (length: number) => {
  return Math.floor(Math.random() * length);
};

export const generateId = (length: number) => {
  const possibleCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let id = '';

  for (let i = 0; i < length; i += 1) {
    id += possibleCharacters.charAt(randomNumber(possibleCharacters.length));
  }

  return id;
};
