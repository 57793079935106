import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

interface Props {
  isEditing: boolean;
  positionName?: string;
}

const PositionCreateAndEditHeader: React.FC<Props> = ({ isEditing, positionName }) => {
  const { t } = useTranslation();

  const headerText = isEditing ? 'positions.editPosition' : 'positions.newPosition';

  return (
    <Row className="mb-4">
      <Col>
        <h5>
          {t(headerText)} {positionName && <span>— {positionName}</span>}
        </h5>
      </Col>
    </Row>
  );
};

export default PositionCreateAndEditHeader;
