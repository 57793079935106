import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button } from 'reactstrap';

const ServerError = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Alert color="danger" className="text-center mb-0">
        {t('errors.somethingWentWrong')}
        <Button size="sm" className="ml-3" onClick={() => window.location.reload()}>
          {t('common.retry')}
        </Button>
      </Alert>
    </div>
  );
};

export default ServerError;
