import { Dispatch } from 'redux';
import { PageQueryParams } from 'src/shared/models';

import {
  deleteHelpdeskGroup as deleteHelpdeskGroupService,
  getHelpdeskGroups as getHelpdeskGroupsService,
  createHelpdeskGroup as createHelpdeskGroupService,
  getHelpdeskGroup as getHelpdeskGroupService,
  updateHelpdeskGroup as updateHelpdeskGroupService,
  addTagToHelpdeskGroup as addTagToHelpdeskGroupService,
  removeHelpdeskGroupTag as removeHelpdeskGroupTagService,
} from 'src/redux/services/helpdeskGroup.service';
import { HelpdeskGroupModel } from 'src/shared/models/helpdeskGroup.model';

export const GET_HELPDESK_GROUPS = 'GET_HELPDESK_GROUPS';
export const GET_HELPDESK_GROUPS_SUCCESS = 'GET_HELPDESK_GROUPS_SUCCESS';
export const GET_HELPDESK_GROUPS_ERROR = 'GET_HELPDESK_GROUPS_ERROR';

export const DELETE_HELPDESK_GROUP = 'DELETE_HELPDESK_GROUP';
export const DELETE_HELPDESK_GROUP_REQUEST = 'DELETE_HELPDESK_GROUP_REQUEST';
export const DELETE_HELPDESK_GROUP_SUCCESS = 'DELETE_HELPDESK_GROUP_SUCCESS';
export const DELETE_HELPDESK_GROUP_ERROR = 'DELETE_HELPDESK_GROUP_ERROR';

export const CREATE_HELPDESK_GROUP = 'helpdeskGroups/CREATE_HELPDESK_GROUP';
export const CREATE_HELPDESK_GROUP_SUCCESS = 'helpdeskGroups/CREATE_HELPDESK_GROUP_SUCCESS';
export const CREATE_HELPDESK_GROUP_ERROR = 'helpdeskGroups/CREATE_HELPDESK_GROUP_ERROR';
export const READ_HELPDESK_GROUP = 'helpdeskGroups/READ_HELPDESK_GROUP';
export const READ_HELPDESK_GROUP_SUCCESS = 'helpdeskGroups/READ_HELPDESK_GROUP_SUCCESS';
export const READ_HELPDESK_GROUP_ERROR = 'helpdeskGroups/READ_HELPDESK_GROUP_ERROR';
export const UPDATE_HELPDESK_GROUP = 'helpdeskGroups/UPDATE_HELPDESK_GROUP';
export const UPDATE_HELPDESK_GROUP_SUCCESS = 'helpdeskGroups/UPDATE_HELPDESK_GROUP_SUCCESS';
export const UPDATE_HELPDESK_GROUP_ERROR = 'helpdeskGroups/UPDATE_HELPDESK_GROUP_ERROR';
export const REVERT_HELPDESK_GROUP = 'helpdeskGroups/CLEAR_HELPDESK_GROUP';

export const GET_TAG_CATEGORIES_REQUEST = 'employee/GET_TAG_CATEGORIES_REQUEST';
export const GET_TAG_CATEGORIES_SUCCESS = 'employee/GET_TAG_CATEGORIES_SUCCESS';
export const GET_TAG_CATEGORIES_ERROR = 'employee/GET_TAG_CATEGORIES_ERROR';

export const ADD_TAG_TO_HELPDESK_GROUP_REQUEST = 'employee/ADD_TAG_TO_HELPDESK_GROUP_REQUEST';
export const ADD_TAG_TO_HELPDESK_GROUP_SUCCESS = 'employee/ADD_TAG_TO_HELPDESK_GROUP_SUCCESS';
export const ADD_TAG_TO_HELPDESK_GROUP_ERROR = 'employee/ADD_TAG_TO_HELPDESK_GROUP_ERROR';

export const REMOVE_HELPDESK_GROUP_TAG_REQUEST = 'employee/REMOVE_HELPDESK_GROUP_TAG_REQUEST';
export const REMOVE_HELPDESK_GROUP_TAG_SUCCESS = 'employee/REMOVE_HELPDESK_GROUP_TAG_SUCCESS';
export const REMOVE_HELPDESK_GROUP_TAG_ERROR = 'employee/REMOVE_HELPDESK_GROUP_TAG_ERROR';

export const GET_CATEGORY_TAGS_REQUEST = 'employee/GET_CATEGORY_TAGS_REQUEST';
export const GET_CATEGORY_TAGS_SUCCESS = 'employee/GET_CATEGORY_TAGS_SUCCESS';
export const GET_CATEGORY_TAGS_ERROR = 'employee/GET_CATEGORY_TAGS_ERROR';

export const DELETE_TAG_CATEGORY_REQUEST = 'employee/DELETE_TAG_CATEGORY_REQUEST';
export const DELETE_TAG_CATEGORY_SUCCESS = 'employee/DELETE_TAG_CATEGORY_SUCCESS';
export const DELETE_TAG_CATEGORY_ERROR = 'employee/DELETE_TAG_CATEGORY_ERROR';

export const getHelpdeskGroups = (appId: string, queryParams: PageQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_HELPDESK_GROUPS,
    });

    try {
      dispatch({
        type: GET_HELPDESK_GROUPS_SUCCESS,
        payload: await getHelpdeskGroupsService(appId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_HELPDESK_GROUPS_ERROR,
        error,
      });
    }
  };
};

export const deleteHelpdeskGroup = (appId: string, helpdeskGroupId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_HELPDESK_GROUP,
    });

    try {
      await deleteHelpdeskGroupService(appId, helpdeskGroupId.toString());
      dispatch({
        type: DELETE_HELPDESK_GROUP_SUCCESS,
        payload: { helpdeskGroupId },
      });
    } catch (error) {
      dispatch({
        type: DELETE_HELPDESK_GROUP_ERROR,
        error,
      });
    }
  };
};

export const createHelpdeskGroup = (
  appId: string,
  helpdeskGroupData: HelpdeskGroupModel,
  url: string,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_HELPDESK_GROUP,
    });

    const response = await createHelpdeskGroupService(appId, helpdeskGroupData, url);

    try {
      dispatch({
        type: CREATE_HELPDESK_GROUP_SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: CREATE_HELPDESK_GROUP_ERROR,
        error,
      });
    }
  };
};

export const getHelpdeskGroup = (appId: string, helpdeskGroupId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: READ_HELPDESK_GROUP,
    });

    try {
      dispatch({
        type: READ_HELPDESK_GROUP_SUCCESS,
        payload: await getHelpdeskGroupService(appId, helpdeskGroupId),
      });
    } catch (error) {
      dispatch({
        type: READ_HELPDESK_GROUP_ERROR,
        error,
      });
    }
  };
};

export const updateHelpdeskGroup = (
  appId: string,
  helpdeskGroupId: string,
  data: HelpdeskGroupModel,
  savingUrl: string,
  deletingUrl: string,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_HELPDESK_GROUP,
    });

    try {
      dispatch({
        type: UPDATE_HELPDESK_GROUP_SUCCESS,
        payload: await updateHelpdeskGroupService(
          appId,
          helpdeskGroupId,
          data,
          savingUrl,
          deletingUrl,
        ),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_HELPDESK_GROUP_ERROR,
        error,
      });
    }
  };
};

export const revertHelpdeskGroup = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REVERT_HELPDESK_GROUP,
    });
  };
};

export const addTagToHelpdeskGroup = (
  applicationId: string,
  helpdeskGroupId: string,
  tagIds: string[],
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ADD_TAG_TO_HELPDESK_GROUP_REQUEST,
    });

    try {
      dispatch({
        type: ADD_TAG_TO_HELPDESK_GROUP_SUCCESS,
        payload: await addTagToHelpdeskGroupService(applicationId, helpdeskGroupId, tagIds),
      });
    } catch (error) {
      dispatch({
        type: ADD_TAG_TO_HELPDESK_GROUP_ERROR,
        error,
      });
    }
  };
};

export const removeHelpdeskGroupTag = (
  applicationId: string,
  employeeId: string,
  tagId: string,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REMOVE_HELPDESK_GROUP_TAG_REQUEST,
    });

    try {
      dispatch({
        type: REMOVE_HELPDESK_GROUP_TAG_SUCCESS,
        payload: await removeHelpdeskGroupTagService(applicationId, employeeId, tagId),
      });
    } catch (error) {
      dispatch({
        type: REMOVE_HELPDESK_GROUP_TAG_ERROR,
        error,
      });
    }
  };
};
