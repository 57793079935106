import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import { PersonaModel } from 'src/shared/models/persona.model';

import CheckPermission from 'src/shared/components/CheckPermission';
import defaultAvatar from 'src/assets/img/avatars/default.png';

import styles from '../Personas.module.scss';

interface Props {
  personas: PersonaModel[];
  toggleUserModal: (event: string, personas?: PersonaModel | undefined) => void;
  toggleConfirmModal: Function;
}

const PersonasTable: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { personas, toggleUserModal, toggleConfirmModal } = props;

  return (
    <Table className="mb-0" striped hover size="sm">
      <thead>
        <tr>
          <th>{t('common.name')}</th>
          <th className="text-right">{t('common.operations')}</th>
        </tr>
      </thead>
      <tbody>
        {personas.map((persona: PersonaModel) => (
          <tr key={persona.id!}>
            <td>
              <div className={styles.userDetails}>
                <img src={persona.avatar || defaultAvatar} alt="Persona Avatar" />
                <p>{persona.name}</p>
              </div>
            </td>
            <td className={styles.operations}>
              <CheckPermission variant="enableIf" permissions={['persona_update']}>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => toggleUserModal('edit', persona)}
                >
                  <i className="far fa-edit" />
                </button>
              </CheckPermission>
              <CheckPermission variant="enableIf" permissions={['persona_delete']}>
                <button
                  type="button"
                  className="btn btn-danger ml-2"
                  onClick={() => toggleConfirmModal(persona)}
                >
                  <i className="far fa-trash-alt" />
                </button>
              </CheckPermission>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default PersonasTable;
