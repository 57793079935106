import { del, get, patch, post } from 'src/shared/services/http.service';
import { PageQueryParams, TicketFilters, TicketModel } from 'src/shared/models';

const baseUrl = (applicationId: string) => `/applications/${applicationId}/helpdesk/tickets`;

export const getTickets = async (
  applicationId: string,
  params: PageQueryParams,
  filter: TicketFilters,
) => {
  const appliedFilters = Object.entries(filter)
    .filter(filterItem => !!filterItem?.[1])
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  return get(baseUrl(applicationId), { params: { ...params, ...appliedFilters } });
};

export const getTicketReport = async (applicationId: string, filter: TicketFilters) => {
  const appliedFilters = Object.entries(filter)
    .filter(filterItem => !!filterItem?.[1])
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  return get(`/applications/${applicationId}/analytics/helpdesk/tickets`, {
    params: { ...appliedFilters },
  });
};

export const getTicket = async (applicationId: string, ticketId: string) => {
  return get(`${baseUrl(applicationId)}/${ticketId}`);
};

export const getGroups = async (applicationId: string) => {
  return get(`${baseUrl(applicationId)}/groups`);
};

export const createTicket = async (applicationId: string, data: TicketModel) => {
  return post(baseUrl(applicationId), { data });
};

export const updateTicket = async (applicationId: string, ticketId: string, data: TicketModel) => {
  return patch(`${baseUrl(applicationId)}/${ticketId}`, { data });
};

export const deleteTicket = async (applicationId: string, ticketId: string) => {
  return del(`${baseUrl(applicationId)}/${ticketId}`);
};
