import { del, get, patch, post } from '../../shared/services/http.service';
import { PageQueryParams } from 'src/shared/models';
import { ImportConfigurationModel } from 'src/shared/models/import-configuration.model';

const baseUrl = (applicationId: string) => `/applications/${applicationId}/import/configuration`;

export const getImportConfigurations = async (applicationId: string, params: PageQueryParams) => {
  return get(baseUrl(applicationId), { params });
};

export const getImportConfiguration = async (
  applicationId: string,
  importConfigurationId: string,
) => {
  return get(`${baseUrl(applicationId)}/${importConfigurationId}`);
};

export const createImportConfiguration = async (
  applicationId: string,
  data: ImportConfigurationModel,
) => {
  return post(baseUrl(applicationId), { data });
};

export const updateImportConfiguration = async (
  applicationId: string,
  importConfigurationId: string,
  data: ImportConfigurationModel,
) => {
  return patch(`${baseUrl(applicationId)}/${importConfigurationId}`, { data });
};

export const deleteImportConfiguration = async (
  applicationId: string,
  importConfigurationId: string,
) => {
  return del(`${baseUrl(applicationId)}/${importConfigurationId}`);
};
