import { post, patch } from '../../shared/services/http.service';
import { ChangePasswordModel } from '../reducers/account.reducer';

const AUTH_JAVA_ENABLED = process.env.REACT_APP_AUTH_JAVA_ENABLED !== 'false';

export const changePassword = async (payload: ChangePasswordModel) => {
  if (AUTH_JAVA_ENABLED) {
    return post('/users/user-profile/change-password', { data: payload });
  }
  else {
    return patch('/change-password', { data: payload });
  }
};

export default changePassword;
