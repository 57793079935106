import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form, FieldAttributes } from 'formik';
import { FormGroup } from 'reactstrap';

import hasPermission from 'src/shared/services/permissions.service';
import { EditableLabel } from 'src/shared/components';
import { SelectModel } from 'src/shared/models';
import ReactSelect from './ReactSelect';

interface Props extends FieldAttributes<any> {
  id?: number;
  data: string | number;
  canBeEmpty?: boolean;
  updateData?: Function;
  permission?: string;
  options: SelectModel[];
}

const EditableSelectInput: React.FC<Props> = ({
  id,
  data,
  canBeEmpty,
  updateData,
  permission,
  options,
}) => {
  const { appId } = useParams<{ appId: string }>();
  const [value, setValue] = useState(data);

  const handleEdit = async () => {
    if (data === value) {
      return;
    }

    if (updateData) {
      await updateData(appId, id, value);
    }
  };

  const handleCancel = () => {
    setValue(data);
  };

  const initialFormValues = {
    value: { value: data, label: data },
  };

  return (
    <EditableLabel
      type="text"
      text={data}
      placeholder={data}
      canBeEmpty={canBeEmpty}
      disabled={permission ? !hasPermission([permission]) : false}
      handleEdit={handleEdit}
      handleCancel={handleCancel}
    >
      <Formik enableReinitialize initialValues={initialFormValues} onSubmit={() => {}}>
        <Form>
          <FormGroup>
            <ReactSelect
              options={options}
              name="value"
              onChanged={(option: SelectModel) => {
                setValue(option.value);
              }}
              autoFocus
            />
          </FormGroup>
        </Form>
      </Formik>
    </EditableLabel>
  );
};

export default EditableSelectInput;
