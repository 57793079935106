import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { ReactSelect, TextField } from 'src/shared/components';
import { connect, useDispatch } from 'react-redux';
import { getValidationTypes } from 'src/redux/actions/forms.action';
import { Expression } from 'src/shared/models/expression.model';

interface TextAnswerProps {
  disabled: boolean;
  getValidationTypes: Function;
  attributeValidators: Expression[];
}

const TextAnswer: FC<TextAnswerProps> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    props.getValidationTypes();
    //eslint-disable-next-line
  }, [dispatch]);

  const validationTypes = useMemo(() => {
    if (!props.attributeValidators) return [];
    else
      return props.attributeValidators.map((validator: Expression) => ({
        id: validator.id,
        value: validator.expression,
        label: validator.title,
      }));
  }, [props.attributeValidators]);

  return (
    <Row>
      <Col lg="6">
        <FormGroup>
          <Label for="validationPattern">{t('common.validationType')}</Label>
          <ReactSelect
            name="validationPattern"
            options={validationTypes}
            isDisabled={props.disabled}
            isClearable
          />
        </FormGroup>
      </Col>
      <Col lg="6">
        <FormGroup>
          <Label for="validationErrorMessage">{t('common.validationErrorMessage')}</Label>
          <TextField name="validationErrorMessage" disabled={props.disabled} />
        </FormGroup>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  attributeValidators: state.forms.validationTypes,
});

const mapDispatchToProps = {
  getValidationTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(TextAnswer);
