import React, { Fragment } from 'react';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Pagination from 'react-js-pagination';

import { ComplaintModel } from '../../../shared/models/complaints.model';

import styles from '../Complaints.module.scss';

interface Props {
  complaints: ComplaintModel[];
  totalItemsCount: number;
  activePage: number;
  pageSize: number;
  pageChange: Function;
}

const ComplaintsTable: React.FC<Props> = ({
  complaints,
  totalItemsCount,
  activePage,
  pageSize,
  pageChange,
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Table striped hover responsive size="sm">
        <thead>
          <tr>
            <th className={styles.date}>{t('common.date')}</th>
            <th className={styles.pollSite}>{t('complaints.pollSite')}</th>
            <th className={styles.message}>{t('common.message')}</th>
          </tr>
        </thead>

        <tbody>
          {complaints.map((complaint: ComplaintModel) => {
            return (
              <tr key={complaint.id}>
                <td>{moment(complaint.createdAt).format('LLL')}</td>
                <td>{complaint.pollSite}</td>
                <td>{complaint.message}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Pagination
        activePage={activePage}
        itemsCountPerPage={pageSize}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={5}
        onChange={(index: number) => pageChange(index)}
      />
    </Fragment>
  );
};

export default ComplaintsTable;
