import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Row, Col, Nav, NavItem, TabContent, TabPane, NavLink } from 'reactstrap';
import cx from 'classnames';
import { ExportButton, PageHeader } from 'src/shared/components';
import SignedInEmployeeReports from './components/SignedInEmployeeReports';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { connect } from 'react-redux';
import { NotSignedInEmployeeReports, SignedInUsersReports } from './components';
import { exportEmployeeReport } from 'src/redux/services/employee-report.service';
import saveFile from 'src/shared/utils/file-saver.util';
import { fileNames } from 'src/shared/settings';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
}
const Container: FC<Props> = ({ application, getApplication }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('1');
  const [exportLoading, setExportLoading] = useState(false);
  const { appId } = useParams<{ appId: string }>();

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const exportReports = async () => {
    const response = await exportEmployeeReport(appId);

    if (response) {
      saveFile(response, fileNames.EMPLOYEE_CHATBOT_USAGE_REPORT_EXCEL, 'xlsx');
    }

    setExportLoading(false);
  };

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  return (
    <Row className="animated fadeIn mb-4">
      <Col>
        <Row>
          <Col>
            <PageHeader title={t('common.employeeReports')} />
          </Col>
          <Col className="d-flex justify-content-end">
            <ExportButton
              exporting={exportLoading}
              className="ml-2"
              disabled={false}
              onClick={() => {
                setExportLoading(true);
                exportReports();
              }}
              permission="employee_employee_report_read"
              tooltipProps={{ target: 'exportButton', placement: 'left' }}
              exportTooltipLabel={t('common.exportToExcel')}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={cx({ active: activeTab === '1' })}
                  onClick={() => {
                    toggleTab('1');
                  }}
                >
                  {t('common.signedInEmployeeList')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={cx({ active: activeTab === '2' })}
                  onClick={() => {
                    toggleTab('2');
                  }}
                >
                  {t('common.notSignedInEmployeeList')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={cx({ active: activeTab === '3' })}
                  onClick={() => {
                    toggleTab('3');
                  }}
                >
                  {t('common.signedInUsersList')}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col>
                    <SignedInEmployeeReports application={application} />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col>
                    <NotSignedInEmployeeReports application={application} />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col>
                    <SignedInUsersReports application={application} />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
});

const mapDispatchToProps = {
  getApplication,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
