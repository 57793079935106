import { Dispatch } from 'redux';

import { GeneralQueryParams } from 'src/shared/models/query-params.model';
import { CustomListItemModel } from 'src/shared/models/custom-lists.model';

import {
  getCustomListItems as getCustomListItemsService,
  createCustomListItem as createCustomListItemService,
  updateCustomListItem as updateCustomListItemService,
  deleteCustomListItem as deleteCustomListItemService,
} from '../services/custom-list-items.service';

export const GET_CUSTOM_LIST_ITEM_ITEMS_REQUEST = 'custom-list-items/GET_CUSTOM_LIST_ITEM_REQUEST';
export const GET_CUSTOM_LIST_ITEM_ITEMS_SUCCESS = 'custom-list-items/GET_CUSTOM_LIST_ITEM_SUCCESS';
export const GET_CUSTOM_LIST_ITEM_ITEMS_ERROR =
  'custom-list-items/GET_CUSTOM_LIST_ITEM_ITEMS_ERROR';

export const CREATE_CUSTOM_LIST_ITEM_REQUEST = 'custom-list-items/CREATE_CUSTOM_LIST_ITEM_REQUEST';
export const CREATE_CUSTOM_LIST_ITEM_SUCCESS = 'custom-list-items/CREATE_CUSTOM_LIST_ITEM_SUCCESS';
export const CREATE_CUSTOM_LIST_ITEM_ERROR = 'custom-list-items/CREATE_CUSTOM_LIST_ITEM_ERROR';

export const UPDATE_CUSTOM_LIST_ITEM_REQUEST = 'custom-list-items/UPDATE_CUSTOM_LIST_ITEM_REQUEST';
export const UPDATE_CUSTOM_LIST_ITEM_SUCCESS = 'custom-list-items/UPDATE_CUSTOM_LIST_ITEM_SUCCESS';
export const UPDATE_CUSTOM_LIST_ITEM_ERROR = 'custom-list-items/UPDATE_CUSTOM_LIST_ITEM_ERROR';

export const DELETE_CUSTOM_LIST_ITEM_REQUEST = 'custom-list-items/DELETE_CUSTOM_LIST_ITEM_REQUEST';
export const DELETE_CUSTOM_LIST_ITEM_SUCCESS = 'custom-list-items/DELETE_CUSTOM_LIST_ITEM_SUCCESS';
export const DELETE_CUSTOM_LIST_ITEM_ERROR = 'custom-list-items/DELETE_CUSTOM_LIST_ITEM_ERROR';

export const getCustomListItems = (
  applicationId: string,
  customListId: number,
  queryParams: GeneralQueryParams,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_CUSTOM_LIST_ITEM_ITEMS_REQUEST,
    });

    try {
      dispatch({
        type: GET_CUSTOM_LIST_ITEM_ITEMS_SUCCESS,
        payload: await getCustomListItemsService(applicationId, customListId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_CUSTOM_LIST_ITEM_ITEMS_ERROR,
        error,
      });
    }
  };
};

export const createCustomListItem = (
  applicationId: string,
  customListId: number,
  customListItem: CustomListItemModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_CUSTOM_LIST_ITEM_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_CUSTOM_LIST_ITEM_SUCCESS,
        payload: await createCustomListItemService(applicationId, customListId, customListItem),
      });
    } catch (error) {
      dispatch({
        type: CREATE_CUSTOM_LIST_ITEM_ERROR,
        error,
      });
    }
  };
};

export const updateCustomListItem = (
  applicationId: string,
  customListId: number,
  customListItemId: number,
  customListItem: CustomListItemModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_CUSTOM_LIST_ITEM_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_CUSTOM_LIST_ITEM_SUCCESS,
        payload: await updateCustomListItemService(
          applicationId,
          customListId,
          customListItemId,
          customListItem,
        ),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_CUSTOM_LIST_ITEM_ERROR,
        error,
      });
    }
  };
};

export const deleteCustomListItem = (
  applicationId: string,
  customListId: number,
  customListItemId: number,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_CUSTOM_LIST_ITEM_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_CUSTOM_LIST_ITEM_SUCCESS,
        payload: await deleteCustomListItemService(applicationId, customListId, customListItemId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_CUSTOM_LIST_ITEM_ERROR,
        error,
      });
    }
  };
};
