import { StateModel } from 'src/shared/models';
import {
  CREATE_FORM,
  CREATE_FORM_SUCCESS,
  CREATE_FORM_ERROR,
  READ_FORM,
  READ_FORM_SUCCESS,
  READ_FORM_ERROR,
  UPDATE_FORM,
  UPDATE_FORM_SUCCESS,
  UPDATE_FORM_ERROR,
  REVERT_FORM,
} from '../actions/forms.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const formGeneralReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case CREATE_FORM:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case CREATE_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          FORM: payload,
        },
      };
    case CREATE_FORM_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };
    case READ_FORM:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case READ_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          form: payload,
        },
      };
    case READ_FORM_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };
    case UPDATE_FORM:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case UPDATE_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          form: payload,
        },
      };
    case UPDATE_FORM_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };
    case REVERT_FORM:
      return INITIAL_STATE;
    default:
      return state;
  }
};
