import { StateModel } from '../../shared/models/default-state.model';

import {
  GET_UPLOADED_DOCUMENTS_REQUEST,
  GET_UPLOADED_DOCUMENTS_SUCCESS,
  GET_UPLOADED_DOCUMENTS_ERROR,
} from '../actions/uploaded-documents.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

const uploadedDocumentsReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_UPLOADED_DOCUMENTS_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_UPLOADED_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_UPLOADED_DOCUMENTS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default uploadedDocumentsReducer;
