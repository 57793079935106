import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  positionUrlsVisible: boolean;
  setPositionUrlsVisible: Function;
}

const PositionUrlsHeader: React.FC<Props> = ({ positionUrlsVisible, setPositionUrlsVisible }) => {
  const { t } = useTranslation();

  const icon = positionUrlsVisible ? 'fas fa-angle-up' : 'fas fa-angle-down';

  return (
    <div className="d-flex justify-content-between align-items-baseline">
      <h6 className="m-0"> {t('positions.platformUrls')}</h6>

      <button
        type="button"
        className="btn btn-ghost-secondary"
        onClick={() => setPositionUrlsVisible((prevState: boolean) => !prevState)}
      >
        <i className={icon} />
      </button>
    </div>
  );
};

export default PositionUrlsHeader;
