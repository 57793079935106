import { Dispatch } from 'redux';

import { GeneralQueryParams } from 'src/shared/models/query-params.model';
import { TrainingDataModel } from 'src/shared/models/training-data.model';

import {
  getTrainingData as getTrainingDataService,
  createTrainingData as createTrainingDataService,
  updateTrainingData as updateTrainingDataService,
  deleteTrainingData as deleteTrainingDataService,
  exportTrainingData as exportTrainingDataService,
} from '../services/training-data.service';

export const GET_TRAINING_DATA_REQUEST = 'training-data/GET_TRAINING_DATA_REQUEST';
export const GET_TRAINING_DATA_SUCCESS = 'training-data/GET_TRAINING_DATA_SUCCESS';
export const GET_TRAINING_DATA_ERROR = 'training-data/GET_TRAINING_DATA_ERROR';

export const CREATE_TRAINING_DATA_REQUEST = 'training-data/CREATE_TRAINING_DATA_REQUEST';
export const CREATE_TRAINING_DATA_SUCCESS = 'training-data/CREATE_TRAINING_DATA_SUCCESS';
export const CREATE_TRAINING_DATA_ERROR = 'training-data/CREATE_TRAINING_DATA_ERROR';

export const UPDATE_TRAINING_DATA_REQUEST = 'training-data/UPDATE_TRAINING_DATA_REQUEST';
export const UPDATE_TRAINING_DATA_SUCCESS = 'training-data/UPDATE_TRAINING_DATA_SUCCESS';
export const UPDATE_TRAINING_DATA_ERROR = 'training-data/UPDATE_TRAINING_DATA_ERROR';

export const DELETE_TRAINING_DATA_REQUEST = 'training-data/DELETE_TRAINING_DATA_REQUEST';
export const DELETE_TRAINING_DATA_SUCCESS = 'training-data/DELETE_TRAINING_DATA_SUCCESS';
export const DELETE_TRAINING_DATA_ERROR = 'training-data/DELETE_TRAINING_DATA_ERROR';

export const EXPORT_TRAINING_DATA_REQUEST = 'training-data/EXPORT_TRAINING_DATA_REQUEST';
export const EXPORT_TRAINING_DATA_SUCCESS = 'training-data/EXPORT_TRAINING_DATA_SUCCESS';
export const EXPORT_TRAINING_DATA_ERROR = 'training-data/EXPORT_TRAINING_DATA_ERROR';

export const SET_TRAINING_DATA_VIEW_MODE = 'training-data/SET_TRAINING_DATA_VIEW_MODE';

export const getTrainingData = (applicationId: string, queryParams: GeneralQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TRAINING_DATA_REQUEST,
    });

    try {
      dispatch({
        type: GET_TRAINING_DATA_SUCCESS,
        payload: await getTrainingDataService(applicationId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_TRAINING_DATA_ERROR,
        error,
      });
    }
  };
};

export const createTrainingData = (applicationId: string, trainingData: TrainingDataModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_TRAINING_DATA_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_TRAINING_DATA_SUCCESS,
        payload: await createTrainingDataService(applicationId, trainingData),
      });
    } catch (error) {
      dispatch({
        type: CREATE_TRAINING_DATA_ERROR,
        error,
      });
    }
  };
};

export const updateTrainingData = (
  applicationId: string,
  intentId: number,
  trainingData: TrainingDataModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_TRAINING_DATA_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_TRAINING_DATA_SUCCESS,
        payload: await updateTrainingDataService(applicationId, intentId, trainingData),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_TRAINING_DATA_ERROR,
        error,
      });
    }
  };
};

export const deleteTrainingData = (applicationId: string, intentId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_TRAINING_DATA_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_TRAINING_DATA_SUCCESS,
        payload: await deleteTrainingDataService(applicationId, intentId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_TRAINING_DATA_ERROR,
        error,
      });
    }
  };
};

export const exportTrainingData = (applicationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EXPORT_TRAINING_DATA_REQUEST,
    });

    try {
      dispatch({
        type: EXPORT_TRAINING_DATA_SUCCESS,
        payload: await exportTrainingDataService(applicationId),
      });
    } catch (error) {
      dispatch({
        type: EXPORT_TRAINING_DATA_ERROR,
        error,
      });
    }
  };
};

export const setTrainingDataViewMode = (mode: string) => {
  return {
    type: SET_TRAINING_DATA_VIEW_MODE,
    payload: mode,
  };
};
