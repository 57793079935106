import _, { Dictionary } from 'lodash';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ApplicationModel } from 'src/redux/actions/applications.action';
import { getNotSignedInEmployeeReport } from 'src/redux/actions/employee-reports.action';
import { List } from 'src/shared/components';
import { configurationConstants } from 'src/shared/constants/configuration.constants';
import { NotSignedInEmployeeReport } from 'src/shared/models/employee-reports.model';
import { getListFieldsByConfiguration } from 'src/shared/utils/configuration.util';

interface Props {
  notSignedInEmployeeReports: NotSignedInEmployeeReport[];
  application: ApplicationModel;
  getNotSignedInEmployeeReport: (appId: string) => void;
}

const NotSignedInEmployeeReports: FC<Props> = ({
  notSignedInEmployeeReports,
  application,
  getNotSignedInEmployeeReport,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [grouped, setGrouped] = useState<Dictionary<NotSignedInEmployeeReport[]>>();

  useEffect(() => {
    getNotSignedInEmployeeReport(appId);
  }, [appId, getNotSignedInEmployeeReport]);

  useEffect(() => {
    setGrouped(_.groupBy(notSignedInEmployeeReports, 'groupByName'));
  }, [notSignedInEmployeeReports]);

  const configuredFields = useMemo(() => {
    return getListFieldsByConfiguration(
      application,
      configurationConstants.hropsEmployeeNotSignedInListList,
      t,
    );
  }, [application, t]);

  return (
    <div className="animated fadeIn">
      {grouped &&
        Object.entries(grouped).map(([key, value]) => (
          <div key={key}>
            <h2>{key}</h2>
            <List
              hidePager
              data={value}
              totalElements={value.length}
              loaded={configuredFields !== undefined}
              loading={configuredFields === undefined}
              noDataLabel={t('employeeReports.noData')}
              fetchData={() => {}}
              fields={configuredFields}
            />
          </div>
        ))}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    notSignedInEmployeeReports: state.employeeReports.data.notSignedInEmployeeReports,
  };
};

const mapDispatchToProps = {
  getNotSignedInEmployeeReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotSignedInEmployeeReports);
