import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';
import 'react-dates/initialize';
import { DateRangePicker, isInclusivelyBeforeDay, isSameDay } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

interface Props {
  startDate: moment.Moment | string;
  endDate: moment.Moment | string;
  handleDateChange: Function;
  isOutsideRange?: (day: moment.Moment) => boolean;
}

const DateRange: React.FC<Props> = props => {
  const { t } = useTranslation();
  const {
    startDate,
    endDate,
    handleDateChange,
    isOutsideRange = day => !isInclusivelyBeforeDay(day, moment()),
  } = props;

  const [start, setStartDate] = useState<moment.Moment | null>(moment(startDate));
  const [end, setEndDate] = useState<moment.Moment | null>(moment(endDate));
  const [focused, setFocused] = useState<'startDate' | 'endDate' | null>(null);

  const handlePresetClick = (startDate: moment.Moment, endDate: moment.Moment) => {
    setStartDate(startDate);
    setEndDate(endDate);
    handleDateChange(startDate, endDate);
    setFocused(null);
  };

  const renderDatePresets = () => {
    const presets = [
      {
        text: `${t('common.today')}`,
        startDate: moment().utc(),
        endDate: moment().utc(),
      },
      {
        text: `${t('common.lastWeek')}`,
        startDate: moment().utc().subtract(7, 'days'),
        endDate: moment().utc(),
      },
      {
        text: `${t('common.lastMonth')}`,
        startDate: moment().utc().subtract(1, 'month'),
        endDate: moment().utc(),
      },
      {
        text: `${t('common.lastThreeMonth')}`,
        startDate: moment().utc().subtract(3, 'month'),
        endDate: moment().utc(),
      },
    ];

    return (
      <div className="PresetDateRangePicker_panel">
        {presets.map(({ text, startDate, endDate }) => {
          if (start && end) {
            const isSelected = isSameDay(start, startDate) && isSameDay(end, endDate);

            return (
              <button
                key={text}
                type="button"
                className={classNames('PresetDateRangePicker_button', {
                  PresetDateRangePicker_button__selected: isSelected,
                })}
                onClick={() => handlePresetClick(startDate, endDate)}
              >
                {text}
              </button>
            );
          }

          return null;
        })}
      </div>
    );
  };

  return (
    <DateRangePicker
      startDate={start}
      startDateId="startDate"
      endDate={end}
      endDateId="endDate"
      onDatesChange={({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
        handleDateChange(startDate, endDate);
      }}
      focusedInput={focused}
      onFocusChange={focusedInput => setFocused(focusedInput)}
      renderCalendarInfo={() => renderDatePresets()}
      isOutsideRange={isOutsideRange}
      firstDayOfWeek={1}
      numberOfMonths={1}
      minimumNights={0}
    />
  );
};

export default DateRange;
