import { StateModel } from 'src/shared/models';
import {
  CREATE_EVENT,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_ERROR,
  READ_EVENT,
  READ_EVENT_SUCCESS,
  READ_EVENT_ERROR,
  UPDATE_EVENT,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_ERROR,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,
  REVERT_EVENT,
} from '../actions/events.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const eventGeneralReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case CREATE_EVENT:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case CREATE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          event: payload,
        },
      };
    case CREATE_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };
    case READ_EVENT:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case READ_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          event: payload,
        },
      };
    case READ_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };
    case UPDATE_EVENT:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          event: payload,
        },
      };
    case UPDATE_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };
    case REVERT_EVENT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const deleteEventReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DELETE_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case DELETE_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
