import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { Formik, Form, FormikValues } from 'formik';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';

import { createHelpdeskGroup, updateHelpdeskGroup } from 'src/redux/actions/helpdesk-groups.action';
import { helpdeskGroupValidatorSchema } from 'src/shared/schemas/validation.schema';
import { Spinner, Switch, TextField } from 'src/shared/components';
import { DynamicMenuModel, HelpdeskGroupModel, SelectModel } from 'src/shared/models';
import { ApplicationModel } from 'src/redux/actions/applications.action';
import { getConfiguration, getFormGroupByConfiguration } from 'src/shared/utils/configuration.util';
import { configurationConstants } from 'src/shared/constants/configuration.constants';

interface Props {
  application: ApplicationModel;
  isOpen: boolean;
  editDocument?: HelpdeskGroupModel;
  toggleModalState: Function;
  onSaved?: Function;
  createHelpdeskGroup: Function;
  updateHelpdeskGroup: Function;
}

const HelpdeskGroupnModalForm: React.FC<Props> = ({
  application,
  isOpen,
  editDocument,
  toggleModalState,
  onSaved,
  createHelpdeskGroup,
  updateHelpdeskGroup,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [configuredFormGroup, setConfiguredFormGroup] = useState<JSX.Element>();

  const selectedMenuItems = useMemo(() => {
    if (
      editDocument?.dynamicConvFlowMenuItems &&
      editDocument.dynamicConvFlowMenuItems.length !== 0
    ) {
      return editDocument.dynamicConvFlowMenuItems;
    } else if (editDocument?.dynamicMenuItems && editDocument.dynamicMenuItems.length !== 0) {
      return editDocument.dynamicMenuItems;
    } else {
      return [];
    }
  }, [editDocument]);

  const getFormInitValues = () => ({
    name: editDocument?.name || '',
    isDefault: editDocument?.isDefault || false,
    dynamicMenuItems: selectedMenuItems.map((menu: DynamicMenuModel) => ({
      value: menu.id,
      label: menu.title,
    })),
  });

  const configuration = useMemo(
    () => getConfiguration(application, configurationConstants.helpdeskGroupsForm),
    [application],
  );

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    getFormGroupByConfiguration(application, configurationConstants.helpdeskGroupsForm).then(r => {
      setConfiguredFormGroup(r);
    });

    /* eslint-disable-next-line */
  }, [isOpen]);

  const handleSubmit = async ({ ...values }: FormikValues) => {
    const updateValues = {
      name: values.name,
      isDefault: values.isDefault,
      dynamicMenuItems: selectedMenuItems,
      dynamicMenuItemIds: values.dynamicMenuItems.map((dynamicMenuItem: SelectModel) => {
        return dynamicMenuItem?.value;
      }),
    };

    const configuredColumn = configuration?.configuration.columns[0];

    if (editDocument) {
      await updateHelpdeskGroup(
        appId,
        editDocument.id,
        updateValues,
        configuredColumn?.endpointForSaving,
        configuredColumn?.endpointForDeleting,
      );
    } else {
      await createHelpdeskGroup(appId, updateValues, configuredColumn?.endpointForSaving);
    }
    if (onSaved) {
      onSaved();
    }
    toggleModalState(false);
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{editDocument ? editDocument.name : t('helpdesk.groups')}</ModalHeader>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={getFormInitValues()}
        validationSchema={helpdeskGroupValidatorSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="name">{t('common.name')}</Label>
                    <TextField name="name" />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>{configuredFormGroup}</Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="isDefault">{t('helpdesk.default')}</Label>
                    <Switch name="isDefault" variant="pill" color="primary" size="lg" />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>

            <ModalFooter>
              <Spinner loading={isSubmitting} />

              <Button type="submit" color="success" disabled={isSubmitting}>
                {t('common.save')}
              </Button>

              <Button color="secondary" onClick={() => toggleModalState(false)}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
});

const mapDispatchToProps = {
  createHelpdeskGroup,
  updateHelpdeskGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpdeskGroupnModalForm);
