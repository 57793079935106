import React, { FC } from 'react';
import { Formik, FormikValues } from 'formik';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';

import { TextField } from 'src/shared/components';
import { SubscribingListFilterModel } from 'src/shared/models';
import SEARCH_TIMER from 'src/shared/constants/filter.constants';

interface ListFilterProps {
    filterChanged: Function;
    filter: SubscribingListFilterModel;
}

const ListFilter: FC<ListFilterProps> = ({ filterChanged }) => {
    const { t } = useTranslation();
    
    const updateFilter = (values: FormikValues) => {
        filterChanged(values);
    };

    const debouncedCallApi = _.debounce((values: FormikValues) => updateFilter(values), SEARCH_TIMER);

    const handleFilterChange = (values: FormikValues) => {
      debouncedCallApi(values);
      return {};
    };
  
    return (
        <Formik
            validateOnBlur={false}
            initialValues={{
                name: '',
            }}
            onSubmit={() => { }}
            validate={handleFilterChange}
            validateOnChange
            enableReinitialize
        >
            {() => (
                <Row>
                    <Col lg="3">
                        <FormGroup>
                        <Label for="name">{t('common.name')}</Label>
                            <TextField name="name" />
                        </FormGroup>
                    </Col>
                </Row>
            )}
        </Formik>
    );
};

export default ListFilter;