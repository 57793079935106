import { get } from '../../shared/services/http.service';
import { UsageStatisticsQueryParams } from '../../shared/models/query-params.model';

export const getDailyApplicationProcessEfficiency = async (
  appId: string,
  queryParams: UsageStatisticsQueryParams,
) => {
  return get(`/applications/${appId}/analytics/daily-application-process-efficiency`, {
    params: queryParams,
  });
};

export const exportDailyApplicationProcessEfficiency = async (
  appId: string,
  queryParams: UsageStatisticsQueryParams,
) => {
  return get(`/applications/${appId}/analytics/daily-application-process-efficiency`, {
    params: queryParams,
  });
};
