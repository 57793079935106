import React, { FC } from 'react';
import moment, { Moment } from 'moment-timezone';

import { settings } from 'src/shared/settings';

interface DateTimeProps {
  dateTime: string | Moment;
  format?: string;
  timeZone?: string;
}

const DateTime: FC<DateTimeProps> = ({ dateTime, format, timeZone }) => (
  <span>{moment(dateTime).tz(timeZone || settings.DEFAULT_TIMEZONE).format(format || settings.DATE_TIME_FORMAT)}</span>
);

export default DateTime;
