import { get, del } from '../../shared/services/http.service';
import { GeneralQueryParams } from '../../shared/models/query-params.model';

export const getComplaints = async (params: GeneralQueryParams) => {
  return get(`/boe/issues`, {
    params,
  });
};

export const exportComplaints = async (params: GeneralQueryParams) => {
  return get(`/boe/issues/export/csv`, {
    params,
  });
};

export const clearComplaints = async () => {
  return del(`/boe/issues/clear-all`);
};
