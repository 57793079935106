import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, UncontrolledTooltip, UncontrolledTooltipProps } from 'reactstrap';

import { CheckPermission, Spinner } from 'src/shared/components';

interface ExportButtonProps extends ButtonProps {
  permission?: string;
  exportLabel?: string;
  exportTooltipLabel?: string;
  tooltipProps?: UncontrolledTooltipProps;
  exporting?: boolean;
}

const ExportButton: FC<ExportButtonProps> = ({
  onClick,
  permission,
  exportLabel,
  exportTooltipLabel,
  tooltipProps,
  exporting,

  ...props
}) => {
  const { t } = useTranslation();

  return (
    <CheckPermission variant="displayIf" permissions={permission ? [permission] : []}>
      <>
        <Spinner loading={exporting || false} className="mr-2" />

        <Button id="exportButton" color="secondary" onClick={onClick} {...props}>
          {exportLabel || t('common.export')}
        </Button>

        <UncontrolledTooltip placement="bottom" target="exportButton" {...tooltipProps}>
          {exportTooltipLabel || t('common.exportToCsv')}
        </UncontrolledTooltip>
      </>
    </CheckPermission>
  );
};

export default ExportButton;
