import { StateModel } from 'src/shared/models';
import {
  GET_EVENTS,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_ERROR,
  DELETE_EVENT,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,
} from '../actions/events.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const eventsReducer = (state: StateModel = INITIAL_STATE, { type, payload, error }: any) => {
  switch (type) {
    case GET_EVENTS:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload,
      };
    case GET_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };
    case DELETE_EVENT:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case DELETE_EVENT_SUCCESS:
      const content = state.data.content.filter(({ id }: any) => id !== payload.surveyId);
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          content,
        },
      };
    case DELETE_EVENT_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };

    default:
      return state;
  }
};
