import React, { FC, useMemo } from 'react';

import { Formik, Form } from 'formik';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ContentSelectModel, SelectModel } from 'src/shared/models';
import { GetLanguagesResponse } from 'src/shared/models/language-settings.model';
import { ReactSelect } from 'src/shared/components';

interface ContentTranslationFiltersProps {
  languageSettings: GetLanguagesResponse;
  setSelectedModule: (item: ModuleTypes) => void;
  setSelectedLanguage: (item: string) => void;
}

interface ContentTranslationFilterModel {
  selectedModule: SelectModel;
  selectedLanguageCode: SelectModel;
}

export enum ModuleTypes {
  EVENTS = 'EVENTS',
  SURVEYS = 'SURVEYS',
}
const ContentTranslationFilters: FC<ContentTranslationFiltersProps> = ({
  languageSettings,
  setSelectedModule,
  setSelectedLanguage,
}) => {
  const { t } = useTranslation();

  const validateFilter = (values: ContentTranslationFilterModel) => {
    if (values.selectedLanguageCode.value && values.selectedModule.value) {
      setSelectedLanguage(values.selectedLanguageCode.value);
      setSelectedModule(values.selectedModule.value);
    }
  };

  const modules: SelectModel[] = [
    { label: t('translations.filter.events'), value: ModuleTypes.EVENTS },
    { label: t('translations.filter.surveys'), value: ModuleTypes.SURVEYS },
  ];

  const languageSelect: ContentSelectModel[] = useMemo(() => {
    if (!Array.isArray(languageSettings)) {
      return [];
    }

    return languageSettings?.map(setting => {
      return {
        value: setting.languageCode.toLowerCase(),
        label: (
          <div className="d-flex align-items-center">
            <img
              alt={setting.languageCode}
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${setting.languageCode.toUpperCase()}.svg`}
              style={{ width: 30 }}
              className="mr-1"
            />
            {setting.name.toUpperCase()}
          </div>
        ),
      };
    });
  }, [languageSettings]);

  return (
    <>
      <Formik
        validateOnChange
        initialValues={{
          selectedModule: { label: '', value: undefined },
          selectedLanguageCode: { label: '', value: undefined },
        }}
        onSubmit={() => {}}
        validate={validateFilter}
      >
        <Form>
          <Row className="flex-row-reverse">
            <Col xs={3}>
              <FormGroup>
                <Label for="selectedLanguageCode">
                  {t('translations.filter.selectedLanguage')}
                </Label>
                <ReactSelect name="selectedLanguageCode" options={languageSelect as any} />
              </FormGroup>
            </Col>
            <Col xs={3}>
              <FormGroup>
                <Label for="selectedModule">{t('translations.filter.translatableModule')}</Label>
                <ReactSelect name="selectedModule" options={modules} />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Formik>
    </>
  );
};

export default ContentTranslationFilters;
