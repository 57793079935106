import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader } from 'src/shared/components';
import ButtonDropdown, { ButtonDropDownItem } from 'src/shared/components/ButtonDropdown';
import {
  exportType,
  exportMessageToDisplay,
  exportIcon,
} from 'src/shared/constants/export.constants';

interface Props {
  handleExport: (id: string) => void;
  isExportDisabled: boolean;
}

const TalentPoolHeader: React.FC<Props> = ({ handleExport, isExportDisabled }) => {
  const { t } = useTranslation();

  const exportDropdownItems: ButtonDropDownItem[] = [
    {
      id: exportType.CSV,
      text: exportMessageToDisplay[exportType.CSV],
      icon: exportIcon[exportType.CSV],
    },
    {
      id: exportType.EXCEL,
      text: exportMessageToDisplay[exportType.EXCEL],
      icon: exportIcon[exportType.EXCEL],
    },
  ];

  return (
    <PageHeader
      title={t('common.talentPool')}
      rightComponent={
        <ButtonDropdown
          items={exportDropdownItems}
          disabled={isExportDisabled}
          titleKey="common.export"
          onClick={handleExport}
          permission="position_talentpool_applicants_read"
        />
      }
    />
  );
};

export default TalentPoolHeader;
