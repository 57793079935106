import React, { Fragment, useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { UserModel } from 'src/shared/models/user.model';
import { userCreateSchema, userEditSchema } from 'src/shared/schemas/validation.schema';

import TextField from 'src/shared/components/form-inputs/TextField';
import PasswordField from 'src/shared/components/form-inputs/PasswordField';
import Select from 'src/shared/components/form-inputs/Select';
import Spinner from 'src/shared/components/Spinner';

interface Props {
  handleSubmit: Function;
  selectedUser: UserModel | null;
  userGroups: any[];
  isOpen: boolean;
  isLoading: boolean;
  modalEvent: string;
  toggleUserModal: Function;
}

const UserModal: React.FC<Props> = props => {
  const {
    userGroups,
    selectedUser,
    isOpen,
    isLoading,
    modalEvent,
    toggleUserModal,
    handleSubmit,
  } = props;

  const { t } = useTranslation();
  const [groupOptions, setGroupOptions] = useState<any[]>([]);
  const isEditing = modalEvent === 'edit';
  const schema = isEditing ? userEditSchema : userCreateSchema;
  const modalTitle = isEditing ? 'users.editUser' : 'users.createUser';
  const submitButtonText = isEditing ? 'common.save' : 'common.create';

  const getUserGroupObjects = () => {
    const results: any[] = [];

    if (userGroups.length > 0) {
      userGroups.map((el: any) => {
        return results.push({ value: el.id, label: el.name });
      });
    }

    return results;
  };

  useEffect(() => {
    if (!groupOptions.length) {
      setGroupOptions(getUserGroupObjects());
    }
    // eslint-disable-next-line
  }, [userGroups]);

  const INIT_VALUES = {
    firstName: selectedUser?.firstName || '',
    lastName: selectedUser?.lastName || '',
    email: selectedUser?.email || '',
    password: '',
    passwordConfirmation: '',
    avatar: selectedUser?.avatar || '',
    userGroupId: selectedUser?.userGroupId || (groupOptions.length && groupOptions[0].value),
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen}>
        <ModalHeader>{t(modalTitle)}</ModalHeader>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            ...INIT_VALUES,
          }}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalBody>
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label for="firstName">{t('common.firstName')}</Label>
                      <TextField name="firstName" />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label for="lastName">{t('common.lastName')}</Label>
                      <TextField name="lastName" />
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  {!isEditing && (
                    <Col>
                      <FormGroup>
                        <Label for="email">{t('common.email')}</Label>
                        <TextField name="email" disabled={isEditing} autoComplete="nope" />
                      </FormGroup>
                    </Col>
                  )}
                  <Col>
                    <FormGroup>
                      <Label for="avatar">{t('common.avatar')} URL</Label>
                      <TextField name="avatar" />
                    </FormGroup>
                  </Col>
                </Row>

                {!isEditing && (
                  <Row form>
                    <Col>
                      <FormGroup>
                        <Label for="password">{t('common.password')}</Label>
                        <PasswordField name="password" />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="passwordConfirmation">{t('common.passwordConfirmation')}</Label>
                        <PasswordField name="passwordConfirmation" />
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                <FormGroup>
                  <Label for="userGroupId">{t('users.userGroup')}</Label>
                  <Select name="userGroupId" options={groupOptions} />
                </FormGroup>
              </ModalBody>

              <ModalFooter>
                <Spinner loading={isLoading} />
                <Button type="submit" color="success">
                  {t(submitButtonText)}
                </Button>
                <Button
                  color="secondary"
                  disabled={isSubmitting || isLoading}
                  onClick={() => toggleUserModal()}
                >
                  {t('common.cancel')}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </Fragment>
  );
};

export default UserModal;
