import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody } from 'reactstrap';
import { Formik, Form, FormikValues } from 'formik';
import moment from 'moment';

import { PositionModel, PositionQuestionModel } from '../../../../shared/models/positions.model';
import { SelectGroupModel, SelectModel } from '../../../../shared/models/common.model';
import { QuestionnaireLabel } from '../../../../shared/models/questionnaire.model';

import { positionSchema } from '../../../../shared/schemas/validation.schema';

import {
  getGroupedSelectOptionObjects,
  getSelectOptionObjects,
} from '../../../../shared/utils/misc.util';

import {
  getPosition,
  createPosition as createPositionAction,
  updatePosition as updatePositionAction,
} from '../../../../redux/actions/positions.action';

import {
  PositionBasicInformationsForm,
  PositionLabelsForm,
  PositionPrefilterCriteriasForm,
} from './forms';
import { positionStatus } from '../../../../shared/constants/positions.constants';

interface Props {
  position: PositionModel | undefined;
  getPosition: Function;
  labels: QuestionnaireLabel[];
  createdPosition: PositionModel;
  createPositionAction: Function;
  updatePositionAction: Function;
}

const PositionCreateAndEditFormContainer: React.FC<Props> = ({
  position,
  getPosition,
  labels,
  createdPosition,
  createPositionAction,
  updatePositionAction,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const history = useHistory();

  const [labelSelectOptions, setLabelSelectOptions] = useState<SelectGroupModel[]>([]);

  useEffect(() => {
    getLabelSelectOptions();
    /* eslint-disable-next-line */
  }, [labels.length]);

  useEffect(() => {
    if (appId && createdPosition?.id) {
      getPosition(appId, createdPosition.id);
    }
    /* eslint-disable-next-line */
  }, [createdPosition]);

  const getLabelSelectOptions = () => {
    if (!labels.length) return null;

    const labelSelectOptions = getGroupedSelectOptionObjects(
      labels,
      'text',
      'labels',
      'text',
      'id',
    );

    return setLabelSelectOptions(labelSelectOptions);
  };

  const getInitialLabelValues = (labels: QuestionnaireLabel[] | undefined) => {
    if (!labels) return [];
    return getSelectOptionObjects(labels, 'text', 'id');
  };

  const initialFormValues = {
    name: position?.name || '',
    status: positionStatus.DRAFT,
    description: position?.description || '',
    startDate: moment(position?.startsAt) || moment(),
    endDate: position?.expiresAt ? moment(position?.expiresAt) : moment().add(1, 'month'),
    labels: getInitialLabelValues(position?.labels),
    questions: position?.questions || [],
  };

  const createPosition = (values: FormikValues) => {
    const { name, startDate, endDate } = values;

    const positionValues = {
      name,
      startsAt: startDate,
      expiresAt: endDate,
    };

    createPositionAction(appId, positionValues);
  };

  const updatePosition = (values: FormikValues) => {
    if (!position?.id) return;

    const { status, name, description, startDate, endDate, labels, questions } = values;
    const { id: positionId, externalId, slug } = position;

    const positionValues = {
      status,
      name,
      description,
      externalId,
      slug,
      startsAt: startDate,
      expiresAt: endDate,
      labelIds: labels.map((label: SelectModel) => label.value),
      questions: questions.map((q: PositionQuestionModel) => ({ ...q, criteriaId: q.criteria.id })),
    };

    updatePositionAction(appId, positionId, positionValues);
  };

  const handleSubmit = async (values: FormikValues) => {
    if (values.labels.length) {
      values.labels = values.labels.filter((label: SelectModel) => label.isFixed !== true);
    }

    if (!position?.id) {
      return createPosition(values);
    }

    await updatePosition(values);
    return history.push(`/application/${appId}/recruiter/positions`);
  };

  return (
    <Card>
      <CardBody>
        <Formik
          enableReinitialize
          initialValues={initialFormValues}
          validationSchema={positionSchema}
          onSubmit={async (values, { setSubmitting }) => {
            await handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <PositionBasicInformationsForm
                positionId={position?.id}
                handleSubmit={handleSubmit}
              />
              <PositionLabelsForm currentLabels={values.labels} labelOptions={labelSelectOptions} />
              <PositionPrefilterCriteriasForm
                positionId={position?.id}
                questions={values.questions}
              />

              <div className="d-flex justify-content-end mt-4">
                <Button type="submit" color="primary" disabled={isSubmitting || !position?.id}>
                  {t('common.submit')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({
  createdPosition: state.positions.data.createPosition.data,
});

const mapDispatchToProps = {
  getPosition,
  createPositionAction,
  updatePositionAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionCreateAndEditFormContainer);
