import React from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { CheckPermission, PageHeader } from 'src/shared/components';

interface Props {
  toggleModal: Function;
}

const CustomFieldsHeader: React.FC<Props> = ({ toggleModal }) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      title={t('common.customFields')}
      rightComponent={
        <CheckPermission variant="enableIf" permissions={['application_custom-field_create']}>
          <Button color="success" onClick={() => toggleModal('create')}>
            {t('customFields.newCustomField')}
          </Button>
        </CheckPermission>
      }
    />
  );
};

export default CustomFieldsHeader;
