import { Dispatch } from 'redux';
import { PageQueryParams } from 'src/shared/models';

import {
  getDynamicFaqs as getDynamicFaqsService,
  createDynamicFaq as createDynamicFaqService,
} from 'src/redux/services/dynamic-faq.service';
import { DynamicFaqImportModel, DynamicFaqModel } from 'src/shared/models/dynamic-faq.model';

export const GET_DYNAMIC_FAQS = 'GET_DYNAMIC_FAQS';
export const GET_DYNAMIC_FAQS_SUCCESS = 'GET_DYNAMIC_FAQS_SUCCESS';
export const GET_DYNAMIC_FAQS_ERROR = 'GET_DYNAMIC_FAQS_ERROR';

export const CREATE_DYNAMIC_FAQ = 'DynamicFaqs/CREATE_DYNAMIC_FAQ';
export const CREATE_DYNAMIC_FAQ_SUCCESS = 'DynamicFaqs/CREATE_DYNAMIC_FAQ_SUCCESS';
export const CREATE_DYNAMIC_FAQ_ERROR = 'DynamicFaqs/CREATE_DYNAMIC_FAQ_ERROR';

export const getDynamicFaqs = (appId: string, queryParams: PageQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_DYNAMIC_FAQS,
    });

    const dynamicFaq = (await getDynamicFaqsService(appId, queryParams)) as DynamicFaqModel[];

    try {
      dispatch({
        type: GET_DYNAMIC_FAQS_SUCCESS,
        payload: {
          content: dynamicFaq,
          pageable: false,
          totalPages: 1,
          totalElements: dynamicFaq.length,
          numberOfElements: dynamicFaq.length,
          first: true,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_DYNAMIC_FAQS_ERROR,
        error,
      });
    }
  };
};

export const createDynamicFaq = (appId: string, DynamicFaqData: DynamicFaqImportModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_DYNAMIC_FAQ,
    });

    const response = await createDynamicFaqService(appId, DynamicFaqData);

    try {
      dispatch({
        type: CREATE_DYNAMIC_FAQ_SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: CREATE_DYNAMIC_FAQ_ERROR,
        error,
      });
    }
  };
};
