import { StateModel } from '../../shared/models/default-state.model';

import {
  // GET_MESSAGE_REQUEST,
  // GET_MESSAGE_SUCCESS,
  // GET_MESSAGE_ERROR,
  GET_MESSAGES_REQUEST,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_ERROR,
  CREATE_MESSAGE_REQUEST,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_ERROR,
  UPDATE_MESSAGE_REQUEST,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_ERROR,
  UPDATE_MESSAGE_TEXT_REQUEST,
  UPDATE_MESSAGE_TEXT_SUCCESS,
  UPDATE_MESSAGE_TEXT_ERROR,
  UPDATE_ANSWER_TEXT_REQUEST,
  UPDATE_ANSWER_TEXT_SUCCESS,
  UPDATE_ANSWER_TEXT_ERROR,
  UPDATE_MESSAGE_CHECKPOINT_REQUEST,
  UPDATE_MESSAGE_CHECKPOINT_SUCCESS,
  UPDATE_MESSAGE_CHECKPOINT_ERROR,
  DELETE_MESSAGE_REQUEST,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_ERROR,
  GET_LABEL_CATEGORIES_REQUEST,
  GET_LABEL_CATEGORIES_SUCCESS,
  GET_LABEL_CATEGORIES_ERROR,
  CREATE_LABEL_CATEGORY_REQUEST,
  CREATE_LABEL_CATEGORY_SUCCESS,
  CREATE_LABEL_CATEGORY_ERROR,
  GET_LABELS_REQUEST,
  GET_LABELS_SUCCESS,
  GET_LABELS_ERROR,
  CREATE_LABEL_REQUEST,
  CREATE_LABEL_SUCCESS,
  CREATE_LABEL_ERROR,
  UPDATE_LABEL_REQUEST,
  UPDATE_LABEL_SUCCESS,
  UPDATE_LABEL_ERROR,
  DELETE_LABEL_REQUEST,
  DELETE_LABEL_SUCCESS,
  DELETE_LABEL_ERROR,
  CLEAR_MESSAGES,
  GET_ATTRIBUTE_VALIDATORS_REQUEST,
  GET_ATTRIBUTE_VALIDATORS_SUCCESS,
  GET_ATTRIBUTE_VALIDATORS_ERROR,
  CREATE_ATTRIBUTE_VALIDATOR_REQUEST,
  CREATE_ATTRIBUTE_VALIDATOR_SUCCESS,
  CREATE_ATTRIBUTE_VALIDATOR_ERROR,
  UPDATE_ATTRIBUTE_VALIDATOR_REQUEST,
  UPDATE_ATTRIBUTE_VALIDATOR_SUCCESS,
  UPDATE_ATTRIBUTE_VALIDATOR_ERROR,
  DELETE_ATTRIBUTE_VALIDATOR_REQUEST,
  DELETE_ATTRIBUTE_VALIDATOR_SUCCESS,
  DELETE_ATTRIBUTE_VALIDATOR_ERROR,
  GET_ATTRIBUTE_REPLACERS_REQUEST,
  GET_ATTRIBUTE_REPLACERS_SUCCESS,
  GET_ATTRIBUTE_REPLACERS_ERROR,
  CREATE_ATTRIBUTE_REPLACER_REQUEST,
  CREATE_ATTRIBUTE_REPLACER_SUCCESS,
  CREATE_ATTRIBUTE_REPLACER_ERROR,
  UPDATE_ATTRIBUTE_REPLACER_REQUEST,
  UPDATE_ATTRIBUTE_REPLACER_SUCCESS,
  UPDATE_ATTRIBUTE_REPLACER_ERROR,
  DELETE_ATTRIBUTE_REPLACER_REQUEST,
  DELETE_ATTRIBUTE_REPLACER_SUCCESS,
  DELETE_ATTRIBUTE_REPLACER_ERROR,
  GET_EXPRESSIONS_REQUEST,
  GET_EXPRESSIONS_SUCCESS,
  GET_EXPRESSIONS_ERROR,
  CREATE_EXPRESSION_REQUEST,
  CREATE_EXPRESSION_SUCCESS,
  CREATE_EXPRESSION_ERROR,
  UPDATE_EXPRESSION_REQUEST,
  UPDATE_EXPRESSION_SUCCESS,
  UPDATE_EXPRESSION_ERROR,
  DELETE_EXPRESSION_REQUEST,
  DELETE_EXPRESSION_SUCCESS,
  DELETE_EXPRESSION_ERROR,
  SET_LABEL_CATEGORIES_VIEW_MODE,
} from '../actions/questionnaire.action';

import { VIEW_MODE_KEY } from 'src/shared/constants/questionnaire.constans';
import { updateListItem } from 'src/shared/utils/reducer.util';

interface QuestionnaireStateModel {
  resources: StateModel;
  create: StateModel;
  update: StateModel;
  updateMessageText: StateModel;
  updateAnswerText: StateModel;
  updateCheckpoint: StateModel;
  delete: StateModel;
  viewMode: string;
}

const INITIAL_STATE = {
  resources: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  create: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  update: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  updateMessageText: {
    loading: false,
    loaded: false,
    error: null,
    data: {},
  },
  updateAnswerText: {
    loading: false,
    loaded: false,
    error: null,
    data: {},
  },
  updateCheckpoint: {
    loading: false,
    loaded: false,
    error: null,
    data: {},
  },
  delete: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  viewMode: 'normal',
};

interface QuestionnaireLabelsStateModel {
  resources: StateModel;
  create: StateModel;
  update: StateModel;
  delete: StateModel;
}

const LABELS_INITIAL_STATE = {
  resources: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  create: {
    loading: false,
    loaded: false,
    error: null,
    data: {},
  },
  update: {
    loading: false,
    loaded: false,
    error: null,
    data: {},
  },
  delete: {
    loading: false,
    loaded: false,
    error: null,
    data: {},
  },
};

export const messagesReducer = (state: QuestionnaireStateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CLEAR_MESSAGES:
      return {
        ...state,
        resources: {
          ...state.resources,
          data: [],
        },
      };

    case GET_MESSAGES_REQUEST:
      return {
        ...state,
        resources: {
          ...state.resources,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case GET_MESSAGES_ERROR:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CREATE_MESSAGE_REQUEST:
      return {
        ...state,
        create: {
          ...state.create,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case CREATE_MESSAGE_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case CREATE_MESSAGE_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case UPDATE_MESSAGE_REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case UPDATE_MESSAGE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case UPDATE_MESSAGE_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case UPDATE_MESSAGE_TEXT_REQUEST:
      return {
        ...state,
        updateMessageText: {
          ...state.updateMessageText,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case UPDATE_MESSAGE_TEXT_SUCCESS:
      return {
        ...state,
        updateMessageText: {
          ...state.updateMessageText,
          loading: false,
          loaded: true,
          data: action.payload,
        },
        ...updateListItem(state.resources, action.payload),
      };

    case UPDATE_MESSAGE_TEXT_ERROR:
      return {
        ...state,
        updateMessageText: {
          ...state.updateMessageText,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case UPDATE_ANSWER_TEXT_REQUEST:
      return {
        ...state,
        updateAnswerText: {
          ...state.updateAnswerText,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case UPDATE_ANSWER_TEXT_SUCCESS:
      return {
        ...state,
        updateAnswerText: {
          ...state.updateAnswerText,
          loading: false,
          loaded: true,
          data: action.payload,
        },
        ...updateListItem(state.resources, action.payload),
      };

    case UPDATE_ANSWER_TEXT_ERROR:
      return {
        ...state,
        updateAnswerText: {
          ...state.updateAnswerText,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case UPDATE_MESSAGE_CHECKPOINT_REQUEST:
      return {
        ...state,
        updateCheckpoint: {
          ...state.updateCheckpoint,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case UPDATE_MESSAGE_CHECKPOINT_SUCCESS:
      return {
        ...state,
        updateCheckpoint: {
          ...state.updateCheckpoint,
          loading: false,
          loaded: true,
          data: action.payload,
        },
        ...updateListItem(state.resources, action.payload),
      };

    case UPDATE_MESSAGE_CHECKPOINT_ERROR:
      return {
        ...state,
        updateCheckpoint: {
          ...state.updateCheckpoint,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case DELETE_MESSAGE_REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case DELETE_MESSAGE_ERROR:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export const labelCategoriesReducer = (
  state: QuestionnaireStateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case GET_LABEL_CATEGORIES_REQUEST:
      return {
        ...state,
        resources: {
          ...state.resources,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case GET_LABEL_CATEGORIES_SUCCESS:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case GET_LABEL_CATEGORIES_ERROR:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CREATE_LABEL_CATEGORY_REQUEST:
      return {
        ...state,
        create: {
          ...state.create,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case CREATE_LABEL_CATEGORY_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case CREATE_LABEL_CATEGORY_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case SET_LABEL_CATEGORIES_VIEW_MODE:
      localStorage.setItem(VIEW_MODE_KEY, action.payload);
      return {
        ...state,
        viewMode: action.payload,
      };

    default:
      return state;
  }
};

export const labelsReducer = (
  state: QuestionnaireLabelsStateModel = LABELS_INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case GET_LABELS_REQUEST:
      return {
        ...state,
        resources: {
          ...state.resources,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case GET_LABELS_SUCCESS:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case GET_LABELS_ERROR:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CREATE_LABEL_REQUEST:
      return {
        ...state,
        create: {
          ...state.create,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case CREATE_LABEL_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case CREATE_LABEL_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case UPDATE_LABEL_REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case UPDATE_LABEL_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case UPDATE_LABEL_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case DELETE_LABEL_REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case DELETE_LABEL_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case DELETE_LABEL_ERROR:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export const attributeValidatorsReducer = (
  state: QuestionnaireStateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case GET_ATTRIBUTE_VALIDATORS_REQUEST:
      return {
        ...state,
        resources: {
          ...state.resources,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case GET_ATTRIBUTE_VALIDATORS_SUCCESS:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case GET_ATTRIBUTE_VALIDATORS_ERROR:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CREATE_ATTRIBUTE_VALIDATOR_REQUEST:
      return {
        ...state,
        create: {
          ...state.create,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case CREATE_ATTRIBUTE_VALIDATOR_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case CREATE_ATTRIBUTE_VALIDATOR_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case UPDATE_ATTRIBUTE_VALIDATOR_REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case UPDATE_ATTRIBUTE_VALIDATOR_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          loaded: true,
          data: action.payload,
        },
        ...updateListItem(state.resources, action.payload),
      };

    case UPDATE_ATTRIBUTE_VALIDATOR_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case DELETE_ATTRIBUTE_VALIDATOR_REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case DELETE_ATTRIBUTE_VALIDATOR_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case DELETE_ATTRIBUTE_VALIDATOR_ERROR:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export const regexReplacersReducer = (
  state: QuestionnaireStateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case GET_ATTRIBUTE_REPLACERS_REQUEST:
      return {
        ...state,
        resources: {
          ...state.resources,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case GET_ATTRIBUTE_REPLACERS_SUCCESS:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case GET_ATTRIBUTE_REPLACERS_ERROR:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CREATE_ATTRIBUTE_REPLACER_REQUEST:
      return {
        ...state,
        create: {
          ...state.create,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case CREATE_ATTRIBUTE_REPLACER_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case CREATE_ATTRIBUTE_REPLACER_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case UPDATE_ATTRIBUTE_REPLACER_REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case UPDATE_ATTRIBUTE_REPLACER_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          loaded: true,
          data: action.payload,
        },
        ...updateListItem(state.resources, action.payload),
      };

    case UPDATE_ATTRIBUTE_REPLACER_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case DELETE_ATTRIBUTE_REPLACER_REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case DELETE_ATTRIBUTE_REPLACER_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case DELETE_ATTRIBUTE_REPLACER_ERROR:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export const expressionsReducer = (state: QuestionnaireStateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_EXPRESSIONS_REQUEST:
      return {
        ...state,
        resources: {
          ...state.resources,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case GET_EXPRESSIONS_SUCCESS:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case GET_EXPRESSIONS_ERROR:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CREATE_EXPRESSION_REQUEST:
      return {
        ...state,
        create: {
          ...state.create,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case CREATE_EXPRESSION_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case CREATE_EXPRESSION_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case UPDATE_EXPRESSION_REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case UPDATE_EXPRESSION_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          loaded: true,
          data: action.payload,
        },
        ...updateListItem(state.resources, action.payload),
      };

    case UPDATE_EXPRESSION_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case DELETE_EXPRESSION_REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case DELETE_EXPRESSION_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case DELETE_EXPRESSION_ERROR:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};
