import { get } from '../../shared/services/http.service';
import { GeneralQueryParams } from '../../shared/models/query-params.model';

export const getInterviewAttendees = async (
  appId: string,
  eventId: string,
  queryParams: GeneralQueryParams,
) => {
  return get(`/recruiter/applications/${appId}/interview/events/${eventId}/attendees`, {
    params: queryParams,
  });
};

export const exportInterviewAttendees = async (applicationId: string, eventId: string) => {
  return get(
    `/recruiter/applications/${applicationId}/interview/events/${eventId}/attendees/export/csv`,
    {},
  );
};
