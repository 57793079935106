import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Card, CardHeader, CardBody, Badge } from 'reactstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Operations } from 'src/shared/models';

import {
  SubscribingListModel,
  SubscriberModel,
} from '../../../../shared/models/subscribing-lists.model';
import { QueryParams } from '../../../../shared/models/query-params.model';

import { getApplication, ApplicationModel } from '../../../../redux/actions/applications.action';
import {
  getSubscribingList,
  getSubscribers,
  exportSubscribers,
  deleteSubscriber,
} from '../../../../redux/actions/subscribing-lists.action';

import saveFileAs from '../../../../shared/utils/file-saver.util';

import SubscribersExport from './components/SubscribersExport';
import { List, PageHeader } from 'src/shared/components';
import { ConfiguredColumn } from 'src/shared/models/configuration.model';
import { getListFieldsByConfiguration } from 'src/shared/utils/configuration.util';
import { configurationConstants } from 'src/shared/constants/configuration.constants';

interface Props
  extends RouteComponentProps<{ id: string; listId: string; stringId: string }>,
    WithTranslation {
  getApplication: Function;
  application: ApplicationModel;
  getSubscribingList: Function;
  list: SubscribingListModel;
  getSubscribers: Function;
  deleteSubscriber: Function;
  subscribers: SubscriberModel[];
  numberOfsubscribers: number;
  subscribersLoading: boolean;
  subscribersLoaded: boolean;
  subscribersError: boolean;
  subscriberDeleting: boolean;
  exportSubscribers: Function;
  exportedData: string;
  exporting: boolean;
  exported: boolean;
}

interface State {
  isConfirmModalOpen: boolean;
  selectedSubscriber: SubscriberModel | undefined;
  configuredFields: ConfiguredColumn[];
  loading: boolean;
}

class Subscribers extends Component<Props, State> {
  state: State = {
    configuredFields: [],
    isConfirmModalOpen: false,
    selectedSubscriber: undefined,
    loading: true,
  };

  componentDidMount = async () => {
    const { id } = this.props.match.params;

    if (!this.props.application.id) {
      this.props.getApplication(id);
    } else {
      this.setConfiguredColumns();
    }

    await this.getSubscribingList();
  };

  componentDidUpdate(prev: Props) {
    if (prev.application !== this.props.application) {
      this.setConfiguredColumns();
    }
  }

  setConfiguredColumns = async () => {
    this.setState(prevState => ({ ...prevState, loading: true }));

    const configuredFields = getListFieldsByConfiguration(
      this.props.application,
      configurationConstants.coreSubscribingListSubscriberList,
      this.props.t,
    );

    this.setState(prevState => ({
      ...prevState,
      configuredFields: configuredFields,
      loading: false,
    }));
  };

  getSubscribingList = async () => {
    const { id: appId, listId } = this.props.match.params;

    await this.props.getSubscribingList(appId, listId);
  };

  getSubscribers = (queryParams: QueryParams) => {
    const { id: appId } = this.props.match.params;

    this.props.getSubscribers(appId, this.props.match.params.stringId, queryParams);
  };

  deleteSubscriber = async (subscriber?: SubscriberModel) => {
    if (subscriber) {
      const { id: appId } = this.props.match.params;
      const { stringId: listId } = this.props.list;
      const { uuid, targetMessagingPlatform: platform } = subscriber;

      await this.props.deleteSubscriber(appId, listId, uuid, platform);
    }
  };

  exportSubscribers = async () => {
    const { id: appId } = this.props.match.params;
    const { stringId: listId } = this.props.list;

    await this.props.exportSubscribers(appId, listId);
    const { exportedData } = this.props;

    if (exportedData) {
      saveFileAs(exportedData, `subscribing_list_${listId}.xlsx`, 'xlsx');
    }
  };

  render() {
    const {
      list,
      subscribers,
      numberOfsubscribers,
      subscribersLoading,
      subscribersLoaded,
      exporting,
      subscriberDeleting,
      subscribersError,
      t,
    } = this.props;

    const noData = subscribersLoaded && !subscribers.length;
    const { configuredFields, loading } = this.state;
    return (
      <Fragment>
        <div className="animated fadeIn">
          <PageHeader
            title={list.name || 'Unknown Subscribing List'}
            subTitle={
              numberOfsubscribers > 0 && (
                <Badge className="ml-2 text-uppercase" color="secondary">
                  {t('subscribingLists.subscribersCount', { count: numberOfsubscribers })}
                </Badge>
              )
            }
          />

          <Card>
            <CardHeader>
              <SubscribersExport
                exportSubscribers={this.exportSubscribers}
                exporting={exporting}
                disabled={exporting || subscribersLoading || noData}
              />
            </CardHeader>

            <CardBody>
              {list.stringId ? (
                <List
                  data={subscribers}
                  totalElements={numberOfsubscribers}
                  loaded={subscribersLoaded && !loading}
                  loading={subscribersLoading || loading}
                  deleting={subscriberDeleting}
                  error={subscribersError}
                  fetchData={this.getSubscribers}
                  fields={[
                    ...configuredFields,
                    {
                      key: 'operations',
                      label: t('common.operations'),
                      operations: [Operations.DELETE],
                    },
                  ]}
                  deleteFunction={this.deleteSubscriber}
                  noDataLabel={t('subscribingLists.noSubscribers')}
                  deleteTitle={t('subscribingLists.deleteSubscriber')}
                  deleteText={t('subscribingLists.subscriberDeleteConfirm')}
                />
              ) : null}
            </CardBody>
          </Card>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  list: state.subscribingList.data,
  subscribers: state.subscribers.data.content,
  numberOfsubscribers: state.subscribers.data.totalElements,
  subscribersLoading: state.subscribers.loading,
  subscribersLoaded: state.subscribers.loaded,
  subscribersError: state.subscribers.error,
  exportedData: state.exportSubscribers.data,
  exporting: state.exportSubscribers.loading,
  exported: state.exportSubscribers.loaded,
  subscriberDeleting: state.subscriberDelete.loading,
});

const mapDispatchToProps = {
  getApplication,
  getSubscribingList,
  getSubscribers,
  deleteSubscriber,
  exportSubscribers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(Subscribers)));
