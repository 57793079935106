import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ViewMode } from 'src/shared/constants/training-data.constants';

import { CheckPermission, PageHeader } from 'src/shared/components';

interface Props {
  setLabelsViewMode: Function;
  viewMode: ViewMode;
  setModalOpen: Function;
}

const LabelsHeader: React.FC<Props> = ({ setLabelsViewMode, viewMode, setModalOpen }) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      title={t('common.labels')}
      rightComponent={
        <>
          <ButtonGroup className="mr-2">
            <Button
              color="secondary"
              outline
              active={viewMode === ViewMode.Row}
              disabled={false}
              onClick={() => setLabelsViewMode(ViewMode.Row)}
            >
              <i className="fas fa-align-justify" />
            </Button>

            <Button
              color="secondary"
              outline
              active={viewMode === ViewMode.Normal}
              disabled={false}
              onClick={() => setLabelsViewMode(ViewMode.Normal)}
            >
              <i className="fas fa-th-large" />
            </Button>

            <Button
              color="secondary"
              outline
              active={viewMode === ViewMode.Compact}
              onClick={() => setLabelsViewMode(ViewMode.Compact)}
            >
              <i className="fas fa-th" />
            </Button>
          </ButtonGroup>

          <CheckPermission variant="displayIf" permissions={['position_label_category_create']}>
            <Button color="primary" onClick={() => setModalOpen(true)}>
              {t('common.create')}
            </Button>
          </CheckPermission>
        </>
      }
    />
  );
};

export default LabelsHeader;
