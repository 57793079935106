import { get } from '../../shared/services/http.service';
import { ApplicantsQueryParams, GeneralQueryParams } from '../../shared/models/query-params.model';

export const getTalentPoolApplicants = async (
  applicationId: string,
  queryParams: ApplicantsQueryParams,
) => {
  return get(`/recruiter/applications/${applicationId}/talent-pool/applicants`, {
    params: queryParams,
  });
};

export const getTalentPoolApplicant = async (applicationId: string, applicantId: number) => {
  return get(`/recruiter/applications/${applicationId}/talent-pool/applicants/${applicantId}`);
};

export const exportTalentPoolApplicants = async (
  applicationId: string,
  params: GeneralQueryParams,
  exportType: string,
) => {
  return get(
    `/recruiter/applications/${applicationId}/talent-pool/applicants/export/${exportType}`,
    {
      params,
      requestConfig: {
        responseType: 'blob',
      },
    },
  );
};
