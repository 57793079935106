import React from 'react';
import {useTranslation} from 'react-i18next';

import styles from '../Interviews.module.scss';

const InterviewsTableHeader = () => {
    const {t} = useTranslation();

    return (
        <thead>
        <tr>
            <th className={styles.name}>{t('common.name')}</th>
            <th className={styles.date}>{t('common.date')}</th>
            <th className={styles.from}>{t('common.from')}</th>
            <th className={styles.to}>{t('common.to')}</th>
            <th className={styles.deadline}>{t('common.applicationDeadline')}</th>
            <th className={styles.limit}>{t('common.limit')}</th>
            <th className={styles.positions}>{t('common.positions')}</th>
            <th className={styles.nofattendees}>{t('common.number-of-attendees')}</th>
            <th className={styles.attendees}>{t('common.attendees')}</th>
            <th className={styles.operations}>{t('common.operations')}</th>
        </tr>
        </thead>
    );
};

export default InterviewsTableHeader;
