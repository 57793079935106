import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from 'reactstrap';

import { ApplicationEfficiencyReportType } from 'src/shared/models';

interface Props {
  chartType: string;
  setChartType: (type: ApplicationEfficiencyReportType) => void;
}

const ChartTypeButtons: React.FC<Props> = ({ chartType, setChartType }) => {
  const { t } = useTranslation();

  return (
    <ButtonGroup>
      <Button
        color="primary"
        outline
        onClick={() => setChartType('percentage')}
        active={chartType === 'percentage'}
      >
        {t('common.percentage')}
      </Button>
      <Button
        color="primary"
        outline
        onClick={() => setChartType('mean')}
        active={chartType === 'mean'}
      >
        {t('common.mean')}
      </Button>
    </ButtonGroup>
  );
};

export default ChartTypeButtons;
