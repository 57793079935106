import { FormikHelpers } from 'formik';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { createForm, getForm, revertForm, updateForm } from 'src/redux/actions/forms.action';

import { PageHeader } from 'src/shared/components';
import { EditorBase, FormGeneralEditorModel, FormModel } from 'src/shared/models';
import GeneralEditor from './components/editor/GeneralEditor';
import Questions from './components/editor/Questions';
import QuestionsAndAnswers from './components/editor/QuestionsAndAnswers';

interface FormsEditorProps extends EditorBase {
  application: ApplicationModel;
  form: FormModel;

  getForm: (appId: string, formId: string) => void;
  revertForm: () => void;
  createForm: (appId: string, data: FormModel) => Promise<any>;
  updateForm: (appId: string, formId: number, data: FormModel) => void;
  getApplication: Function;
}

const FormsEditor: FC<FormsEditorProps> = ({
  edit,
  application,
  form,
  getForm,
  revertForm,
  getApplication,
  createForm,
  updateForm,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { appId, formId } = useParams<{ appId: string; formId: string }>();

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    if (!edit) {
      revertForm();
    }
  }, [application, appId, getApplication, edit, revertForm]);

  useEffect(() => {
    edit && getForm(appId, formId as any);
  }, [edit, appId, formId, getForm]);

  const redirectToEdit = (formId: number) => {
    history.replace(`/application/${appId}/modules/forms/${formId}/edit`);
  };

  const handleGeneralFormSubmit = async (
    values: FormGeneralEditorModel,
    { setSubmitting }: FormikHelpers<FormGeneralEditorModel>,
  ) => {
    setSubmitting(true);

    const data = {
      ...values,
    };

    if (edit) {
      await updateForm(appId, form.id, data as any);
      setSubmitting(false);
    } else {
      const { id } = await createForm(appId, data as any);

      setSubmitting(false);
      redirectToEdit(id);
    }
  };

  return (
    <>
      <div className="animated fadeIn">
        <PageHeader title={edit ? t('forms.edit') : t('forms.create')} />
        <Row>
          <Col md={6}>
            <Card>
              <GeneralEditor edit={edit} form={form} handleFormSubmit={handleGeneralFormSubmit} />
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <CardBody>
                <QuestionsAndAnswers isEditingDisabled={!edit} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Questions isEditingDisabled={!edit} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    form: state.form.data?.form,
  };
};

const mapDispatchToProps = {
  getForm,
  revertForm,
  getApplication,
  createForm,
  updateForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormsEditor);
