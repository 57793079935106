import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Row, Label } from 'reactstrap';

import { useFormikContext, ArrayHelpers, FieldArray } from 'formik';

import { ReactSelect, TextField } from 'src/shared/components';
import { QuestionnaireLabelCategory, SelectGroupModel, SelectModel } from 'src/shared/models';
import { getGroupedSelectOptionObjects, getSelectOptionObjects } from 'src/shared/utils';
import { getLabelCategories } from 'src/redux/actions/questionnaire.action';
import { connect } from 'react-redux';
import {
  getDefaultLabelCategoryValue,
  getDefaultLabelValue,
} from 'src/shared/utils/questionnare.util';
import { useParams } from 'react-router-dom';
import LabelCreateModal from './components/LabelCreateModal';

interface ActionControllerFormProps {
  getLabelCategories: Function;
  labelCategories: QuestionnaireLabelCategory[];
}

export interface ActionControllerModel {
  actionType: SelectModel;
  method: SelectModel;
  data: {
    attributeKey?: string;
    attributeValue?: string;
    labelCategorySlug?: string;
    labelSlug?: string;
    label?: SelectModel;
    labelCategory?: SelectModel;
  };
}

export enum ActionControllerType {
  APPLICANT_SURVEY_FILLED_AT = 'APPLICANT_SURVEY_FILLED_AT',
  APPLICANT_ATTRIBUTE = 'APPLICANT_ATTRIBUTE',
  APPLICANT_LABEL_CATEGORY = 'APPLICANT_LABEL_CATEGORY',
  APPLICANT_POSITION_ATTRIBUTE = 'APPLICANT_POSITION_ATTRIBUTE',
  APPLICANT_LABEL = 'APPLICANT_LABEL',
}

export enum MethodType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

const ActionControllerForm: FC<ActionControllerFormProps> = ({
  labelCategories,
  getLabelCategories,
}) => {
  const { t } = useTranslation();
  const [labelOptions, setLabelOptions] = useState<SelectGroupModel[]>([]);
  const [labelCategoryOptions, setLabelCategoryOptions] = useState<SelectModel[]>([]);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [actionIndex, setActionIndex] = useState<number | undefined>(undefined);
  const { appId } = useParams<{ appId: string }>();

  const { values, setFieldValue } = useFormikContext<{
    actions: ActionControllerModel[];
  }>();

  useEffect(() => {
    if (labelCategories.length > 0) {
      const labelOptions = getLabelOptions();
      setLabelOptions(labelOptions);

      const labelCategoriesOptions = getLabelCategoryOptions();
      setLabelCategoryOptions(labelCategoriesOptions);
    }
    /* eslint-disable-next-line */
  }, [labelCategories]);

  useEffect(() => {
    getLabelCategories(appId);
    /* eslint-disable-next-line */
  }, []);

  const getLabelOptions = () => {
    return getGroupedSelectOptionObjects(labelCategories, 'text', 'labels', 'text', 'slug');
  };

  const getLabelCategoryOptions = () => {
    return getSelectOptionObjects(labelCategories, 'text', 'slug');
  };

  const remove = (arrayHelpers: ArrayHelpers, index: number) => {
    arrayHelpers.remove(index);
  };

  const add = (arrayHelpers: ArrayHelpers) => {
    arrayHelpers.push({
      analyticsType: '',
      method: '',
      data: { attributeKey: '', attributeValue: '', labelCategorySlug: '', labelSlug: '' },
    });
  };

  const actionTypes = useMemo(() => {
    return Object.keys(ActionControllerType).map(enumValue => {
      return {
        value: enumValue,
        label: t(`questionnaire.actionControllerForm.actionTypes.${enumValue.toLowerCase()}`),
      };
    });
  }, [t]);

  const getMethods = (actionType: ActionControllerType) => {
    if (!actionType) return;
    let methods = [];
    switch (actionType) {
      case ActionControllerType.APPLICANT_SURVEY_FILLED_AT:
      case ActionControllerType.APPLICANT_ATTRIBUTE:
      case ActionControllerType.APPLICANT_POSITION_ATTRIBUTE:
        methods = [MethodType.CREATE, MethodType.UPDATE, MethodType.DELETE];
        break;
      case ActionControllerType.APPLICANT_LABEL_CATEGORY:
      case ActionControllerType.APPLICANT_LABEL:
        methods = [MethodType.CREATE, MethodType.DELETE];
        break;
    }

    return methods.map(method => {
      return {
        value: method,
        label: t(`common.${method.toLowerCase()}`),
      };
    });
  };

  return (
    <FieldArray
      name="actions"
      render={arrayHelpers => (
        <>
          <FormGroup>
            {values.actions.length > 0 && (
              <Row>
                <Col>
                  <Label>{t('common.type')}</Label>
                </Col>
                <Col>
                  <Label>{t('common.subType')}</Label>
                </Col>
                <Col>
                  <Label>{t('common.key')}</Label>
                </Col>
                <Col>
                  <Label>{t('common.value')}</Label>
                </Col>
                <Col lg="2">
                  <Label>{t('common.label')}</Label>
                </Col>
                <Col lg="2">
                  <Label>{t('common.labelCategory')}</Label>
                </Col>
                <Col lg={1}></Col>
              </Row>
            )}

            {values.actions &&
              values.actions.map((action, index) => (
                <Row className="mb-2 mr-1 pr-1" key={index} noGutters>
                  <Col>
                    <ReactSelect
                      name={`actions[${index}].actionType`}
                      options={actionTypes}
                      onChanged={(actionType: SelectModel) => {
                        setFieldValue(`actions[${index}].method`, '');
                        setFieldValue(`actions[${index}].data.attributeKey`, '');
                        setFieldValue(`actions[${index}].data.attributeValue`, '');
                        setFieldValue(`actions[${index}].data.labelCategorySlug`, '');
                        setFieldValue(`actions[${index}].data.labelSlug`, '');
                      }}
                    />
                  </Col>
                  <Col>
                    <ReactSelect
                      name={`actions[${index}].method`}
                      options={getMethods(action.actionType?.value)}
                    />
                  </Col>
                  <Col>
                    {(action.actionType?.value === ActionControllerType.APPLICANT_ATTRIBUTE ||
                      action.actionType?.value ===
                        ActionControllerType.APPLICANT_POSITION_ATTRIBUTE) &&
                      action.method.value && (
                        <TextField name={`actions[${index}].data.attributeKey`} />
                      )}
                  </Col>
                  <Col>
                    {(action.actionType?.value === ActionControllerType.APPLICANT_ATTRIBUTE ||
                      action.actionType?.value ===
                        ActionControllerType.APPLICANT_POSITION_ATTRIBUTE) &&
                      (action.method.value === MethodType.CREATE ||
                        action.method.value === MethodType.UPDATE) && (
                        <TextField name={`actions[${index}].data.attributeValue`} />
                      )}
                  </Col>
                  <Col lg="2">
                    {action.actionType?.value === ActionControllerType.APPLICANT_LABEL &&
                      action.method.value && (
                        <div className="d-flex">
                          <ReactSelect
                            name={`actions[${index}].data.label`}
                            options={labelOptions}
                            defaultValue={getDefaultLabelValue(
                              labelCategories,
                              action.data.labelSlug,
                            )}
                            displayParentLabel
                          />
                          <button
                            type="button"
                            className="btn btn-ghost-primary"
                            onClick={() => {
                              setModalOpen(true);
                              setActionIndex(index);
                            }}
                          >
                            <i className="fas fa-plus" />
                          </button>
                        </div>
                      )}
                  </Col>
                  <Col lg="2">
                    {action.actionType?.value === ActionControllerType.APPLICANT_LABEL_CATEGORY &&
                      action.method.value && (
                        <Row>
                          <Col>
                            <div className="d-flex">
                              <ReactSelect
                                name={`actions[${index}].data.labelCategory`}
                                options={labelCategoryOptions}
                                defaultValue={getDefaultLabelCategoryValue(
                                  labelCategories,
                                  action.data.labelCategorySlug,
                                )}
                              />
                              <button
                                type="button"
                                className="btn btn-ghost-primary"
                                onClick={() => {
                                  setModalOpen(true);
                                  setActionIndex(index);
                                }}
                              >
                                <i className="fas fa-plus" />
                              </button>
                            </div>
                          </Col>
                        </Row>
                      )}
                  </Col>
                  <Col lg={1}>
                    <div>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => remove(arrayHelpers, index)}
                      >
                        <i className="far fa-trash-alt" />
                      </button>
                    </div>
                  </Col>
                </Row>
              ))}
            <Row className="justify-content-center mt-3">
              <button type="button" className="btn btn-primary" onClick={() => add(arrayHelpers)}>
                <span className="mr-2">{t('common.add')}</span>
                <i className="fas fa-plus" />
              </button>
            </Row>
          </FormGroup>

          <LabelCreateModal
            isOpen={isModalOpen}
            toggle={setModalOpen}
            labelCategoryOptions={labelCategoryOptions}
            answerIndex={actionIndex}
            getLabelCategories={getLabelCategories}
          />
        </>
      )}
    />
  );
};

const mapStateToProps = (state: any) => ({
  labelCategories: state.questionnaireLabelCategories.resources.data,
});

const mapDispatchToProps = {
  getLabelCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionControllerForm);
