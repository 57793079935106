import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { uniqueId } from 'lodash';
import { FieldAttributes, Formik } from 'formik';

import hasPermission from 'src/shared/services/permissions.service';
import Yup from 'src/shared/schemas/validation.schema';
import { EditableLabel, TextField } from 'src/shared/components';

interface Props extends FieldAttributes<any> {
  id?: number;
  data: string;
  canBeEmpty?: boolean;
  updateData?: Function;
  permission?: string;
  formik?: boolean;
  validationSchema?: Yup.ObjectSchema;
  textArea?: boolean;
  rows?: number;
}

const EditableTextInput: React.FC<Props> = ({
  id,
  data,
  canBeEmpty,
  updateData,
  permission,
  formik,
  name,
  validationSchema,
  textArea,
  rows,
  ...props
}) => {
  let formRef: any = null;
  const { appId } = useParams<{ appId: string }>();
  const [value, setValue] = useState(data);
  const fieldName = name || 'key';

  const handleEdit = async () => {
    formRef.handleSubmit();
    const isValid = Object.keys(formRef.errors).length === 0;

    if (data !== value && updateData && isValid) {
      await updateData(appId, id, value);
    }

    return isValid;
  };

  const handleCancel = () => {
    setValue(data);
  };

  const inputType = useMemo(() => {
    if (textArea) return 'textarea';
    return 'text';
  }, [textArea]);

  return (
    <EditableLabel
      type="text"
      text={data}
      placeholder={data}
      canBeEmpty={canBeEmpty}
      disabled={permission ? !hasPermission([permission]) : false}
      handleEdit={handleEdit}
      handleCancel={handleCancel}
      isChildrenFormik={!formik}
      {...props}
    >
      {formik ? (
        <TextField id={uniqueId('editable-text-input-')} name={fieldName} autoFocus />
      ) : (
        <Formik
          innerRef={ref => (formRef = ref)}
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={{ [fieldName]: value }}
          validationSchema={validationSchema}
          onSubmit={() => {}}
          enableReinitialize
        >
          <TextField
            name={fieldName}
            type={inputType}
            id={uniqueId('editable-text-input-')}
            onChanged={(value: string) => setValue(value)}
            rows={rows}
            autoFocus
          />
        </Formik>
      )}
    </EditableLabel>
  );
};

export default EditableTextInput;
