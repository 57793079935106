import React, { useEffect, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Button } from 'reactstrap';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { DynamicMenuModel, PageQueryParams, QueryParams } from 'src/shared/models';
import { List } from 'src/shared/components';

import { getDynamicMenus } from 'src/redux/actions/dynamic-menu.action';
import DynamicMenuModalForm from './forms/DynamicMenuModalForm';
import { getSignedUrlAndOpenFile } from 'src/shared/services/upload.service';
// import DynamicMenuModalForm from './forms/DynamicMenuModalForm';

interface Props {
  getDynamicMenus: (appId: string, queryParams: PageQueryParams) => void;
  getApplication: Function;
  application: ApplicationModel;
  dynamicMenus: DynamicMenuModel[];
  numberOfDynamicMenus: number;
  dynamicMenusLoading: boolean;
  dynamicMenusLoaded: boolean;
  dynamicMenusError: boolean;
}

const DynamicMenu: FC<Props> = ({
  getDynamicMenus,
  getApplication,
  application,
  dynamicMenus,
  numberOfDynamicMenus,
  dynamicMenusLoading,
  dynamicMenusLoaded,
  dynamicMenusError,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [queryParamsDef, setQueryParamsDef] = useState<QueryParams>({
    page: 0,
    size: 20,
    limit: 20,
  });

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  const fetchDynamicMenus = (queryParams: QueryParams) => {
    setQueryParamsDef(queryParams);
    getDynamicMenus(appId, queryParams);
  };

  const refreshDynamicMenus = () => {
    getDynamicMenus(appId, queryParamsDef);
  };

  return (
    <div className="animated fadeIn">
      <List
        useCardContent
        data={dynamicMenus}
        totalElements={numberOfDynamicMenus}
        loaded={dynamicMenusLoaded}
        loading={dynamicMenusLoading}
        error={dynamicMenusError}
        fetchData={fetchDynamicMenus}
        title={t('common.dynamicMenuUpload')}
        createPermission="import_create"
        createModal={(isModalOpen, setIsModalOpen) => (
          <DynamicMenuModalForm
            isOpen={isModalOpen}
            toggleModalState={setIsModalOpen}
            onSaved={refreshDynamicMenus}
          />
        )}
        fields={[
          { key: 'id', label: t('common.id') },
          { key: 'url', label: t('common.url'), render: item => item.uploadedDocument.url },
          { key: 'updatedAt', label: t('common.updated'), dateTime: true },
          {
            key: 'configuration',
            label: t('dynamic_menu.configuration'),
            render: item => item.configuration.title,
          },
          { key: 'status', label: t('common.status') },
          {
            key: 'download',
            label: t('common.download'),
            render: item => (
              <>
                <Button
                  className="btn-sm float-right"
                  onClick={() => {
                    getSignedUrlAndOpenFile(item.uploadedDocument.url);
                  }}
                >
                  <i className="file-icon far fa-file-alt" />
                </Button>
              </>
            ),
          },
        ]}
        noDataLabel={t('dynamic_menu.noDynamicMenus')}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    dynamicMenus: state.dynamicMenu.data.content,
    numberOfDynamicMenus: state.dynamicMenu.data.totalElements,
    dynamicMenusLoading: state.dynamicMenu.loading,
    dynamicMenusLoaded: state.dynamicMenu.loaded,
    dynamicMenusError: state.dynamicMenu.error,
  };
};

const mapDispatchToProps = {
  getApplication,
  getDynamicMenus,
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicMenu);
