import { StateModel } from '../../shared/models/default-state.model';

import {
  GET_PLATFORMS_REQUEST,
  GET_PLATFORMS_SUCCESS,
  GET_PLATFORMS_ERROR,
  CLEAR_PLATFORMS,
} from '../actions/platforms.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export const platformsReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_PLATFORMS_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_PLATFORMS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_PLATFORMS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    case CLEAR_PLATFORMS:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: null,
        data: [],
      };

    default:
      return state;
  }
};

export default platformsReducer;
