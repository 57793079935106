import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { getEmployees, getTagCategories } from 'src/redux/actions/employee.action';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { CheckPermission, Tags, PageHeader } from 'src/shared/components';

import EmployeesTable from './components/Employees/EmployeesTable';

import styles from './Employee.module.scss';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  getEmployees: Function;
  getTagCategories: Function;
}

const Employee: React.FC<Props> = ({
  application,
  getApplication,
  getEmployees,
  getTagCategories,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { appId, tab } = useParams<{ appId?: string; tab?: string }>();
  const [activeTab, setActiveTab] = useState<string>(tab || 'tags');
  const [refreshEmployeeTable, setRefreshEmployeeTable] = useState<number>(0);

  const getData = (appId?: string, tab?: string) => {
    switch (tab) {
      case 'tags':
        return getTagCategories(appId);
      case 'employees':
        return setRefreshEmployeeTable(refreshEmployeeTable + 1);
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  const toggleTab = (tab: string) => {
    if (activeTab !== tab && appId) {
      setActiveTab(tab);
      history.push(`/application/${appId}/employee/${tab}`);
      getData(appId, tab);
    }
  };

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
      history.push(`/application/${appId}/employee/${tab}`);
      getData(appId, tab);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="animated fadeIn">
      <PageHeader title={t('common.employees')} />

      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'tags' })}
            onClick={() => {
              toggleTab('tags');
            }}
          >
            {t('employee.tags')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'employees' })}
            onClick={() => {
              toggleTab('employees');
            }}
          >
            {t('employee.employees')}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab} className={styles.tabContent}>
        <TabPane tabId="tags" className={styles.tabPane}>
          <Tags />
        </TabPane>
        <TabPane tabId="employees" className={styles.tabPane}>
          <Row>
            <Col>
              <CheckPermission variant="displayIf" permissions={['employee_employees_read']}>
                <EmployeesTable refresh={refreshEmployeeTable} />
              </CheckPermission>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  tagCategories: state.tagCategories,
  employees: state.employees,
});

const mapDispatchToProps = {
  getApplication,
  getTagCategories,
  getEmployees,
};

export default connect(mapStateToProps, mapDispatchToProps)(Employee);
