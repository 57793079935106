import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormikValues } from 'formik';
import { Row, Col, Card, CardBody, Alert } from 'reactstrap';

import {
  defaultDefaultAnswer,
  defaultExportApplicationToAtsAnswer,
  defaultInterviewEventAnswer,
  defaultLogicControllerAnswer,
  defaultOpenEndedQuestionAnswerAttribute,
  defaultOpenEndedQuestionAnswerPositionAttribute,
  defaultPositionPrefilterCriteriasEnabledKoExitPointAnswer,
  defaultPositionPrefilterCriteriasMessageAnswer,
  defaultSimpleMessageAnswer,
  defaultStartAnswer,
  messageType as messageTypes,
  MessageTypeEnum,
} from 'src/shared/constants/questionnaire.constans';

import { SelectModel } from 'src/shared/models/common.model';
import { StateModel } from 'src/shared/models/default-state.model';
import {
  QuestionnaireAnswer,
  QuestionnaireAnswerActionType,
  QuestionnaireMessage,
  QuestionnaireMessageMetadataValidatorType,
} from 'src/shared/models/questionnaire.model';

import {
  createMessage,
  getLabelCategories,
  updateMessage,
} from 'src/redux/actions/questionnaire.action';
import { createMessageGroups } from 'src/redux/services/questionnaire.service';

import Spinner from 'src/shared/components/Spinner';
import CheckPermission from 'src/shared/components/CheckPermission';
import MessageCreateDropdown from './components/MessageCreateDropdown';
import MessagesTable from './components/MessagesTable';
import MessageModal from './components/MessageModal';
import { ContextControllerContextAction } from './components/forms/ContextControllerForm';
import { AnalyticsControllerModel } from './components/forms/AnalyticsControllerForm';
import { ActionControllerModel } from './components/forms/ActionControllerForm';
import ConnectionsModal from './components/ConnectionsModal';

interface RegexReplacersModel {
  value: SelectModel;
}

interface Props {
  getLabelCategories: Function;
  getMessages: Function;
  messagesState: StateModel;
  createMessage: Function;
  updateMessage: Function;
}

const MessagesTableContainer: React.FC<Props> = ({
  getLabelCategories,
  getMessages,
  messagesState,
  createMessage,
  updateMessage,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isConnectionsModalOpen, setConnectionsModalOpen] = useState<boolean>(false);
  const [modalEvent, setModalEvent] = useState<string>('');
  const [messageType, setMessageType] = useState<string>('');
  const [alertText, setAlertText] = useState<string>('');
  const [scrollYPosition, setScrollYPosition] = useState<number>(0);
  const [selectedMessage, setSelectedMessage] = useState<QuestionnaireMessage | undefined>(
    undefined,
  );

  const getMessageType = (type: string) => selectedMessage?.type === type || messageType === type;

  const isSimpleMessage = getMessageType(messageTypes.SIMPLE_TEXT_MESSAGE);
  const isOpenEnded = getMessageType(messageTypes.OPEN_ENDED_ANSWERS);
  const isClosedEnded = getMessageType(messageTypes.CLOSED_ENDED_ANSWERS);
  const isLogicController = getMessageType(messageTypes.LOGIC_CONTROLLER);
  const isLocationPartOfCountry =
    getMessageType(messageTypes.LOCATION_PART_OF_COUNTRY) ||
    getMessageType(messageTypes.LOCATION_MESSAGE);
  const isLocationCounty =
    getMessageType(messageTypes.LOCATION_COUNTY) || getMessageType(messageTypes.LOCATION_MESSAGE);
  const isLocationCity =
    getMessageType(messageTypes.LOCATION_CITY) || getMessageType(messageTypes.LOCATION_MESSAGE);
  const isLocationMessage = isLocationPartOfCountry && isLocationCounty && isLocationCity;
  const isShowPositionMessage = getMessageType(messageTypes.SHOW_POSITION_MESSAGE);
  const isPositionApplyController = getMessageType(messageTypes.POSITION_APPLY_CONTROLLER);
  const isPositionPrefilterCriteriasMessage = getMessageType(
    messageTypes.POSITION_PREFILTER_CRITERIAS_MESSAGE,
  );
  const isExternalServiceCall = getMessageType(messageTypes.EXPORT_APPLICATION_TO_ATS_CONTROLLER);
  const isDocumentUploader = getMessageType(messageTypes.DOCUMENT_UPLOAD_CONTROLLER);
  const isDocumentView = getMessageType(messageTypes.DOCUMENT_VIEWER_MESSAGE);
  const isStartMessage = getMessageType(messageTypes.START_CONTROLLER);
  const isAnalyticsController = getMessageType(messageTypes.ANALYTICS_CONTROLLER);
  const isActionController = getMessageType(messageTypes.ACTION_CONTROLLER);
  const isContextController = getMessageType(messageTypes.CONTEXT_CONTROLLER);
  const isDynamicLabelMessage = getMessageType(messageTypes.DYNAMIC_LABEL_MESSAGE);
  const isInterviewEventMessage = getMessageType(messageTypes.SHOW_INTERVIEW_EVENTS_MESSAGE);
  const isImageMessage = getMessageType(messageTypes.IMAGE_MESSAGE);
  const isAtsGatewayController = getMessageType(messageTypes.ATS_GATEWAY_CONTROLLER);

  const messageTypeEnum = (selectedMessage?.type || messageType) as MessageTypeEnum;

  useEffect(() => {
    getLabelCategories(appId);
    /* eslint-disable-next-line */
  }, []);

  const handleOnMessageCreateClick = (messageType: string) => {
    setMessageType(messageType);
    setModalEvent('create');
    setSelectedMessage(undefined);
    setModalOpen(true);
  };

  const handleOnSubmit = async (values: FormikValues) => {
    const {
      intent,
      text,
      expression,
      parentConditions,
      answers,
      chooseCity,
      chooseCounty,
      choosePartOfCountry,
      disabled,
      nextMessageIdIfDisabled,
    } = values;
    let messageValues: any = {};

    if (alertText) setAlertText('');

    if (isClosedEnded && !answers.length) {
      setAlertText(t('common.addAtleastOneAnswer'));
      return;
    }

    if (hasEmptyInput('[id^="answer-"]') || hasEmptyInput('[id^="attribute-"]')) return;

    if (isLocationMessage) {
      const { locationMessage } = values;

      messageValues = [
        {
          text: locationMessage.chooseCountryPart,
          metadata: {
            type: messageTypes.LOCATION_PART_OF_COUNTRY,
            showSize: locationMessage.showSize,
            showAll: locationMessage.showAll,
            nextPageButton: locationMessage.nextPageButton,
          },
          type: messageTypes.LOCATION_PART_OF_COUNTRY,
          rootAnswerIds: getRootAnswerIds(parentConditions),
          intent: intent?.label ? intent.label : null,
          answers: [
            {
              ...defaultDefaultAnswer,
              answerActionRootData: {
                ...defaultDefaultAnswer.answerActionRootData,
              },
            },
            {
              text: 'NO_POSITION_FOUND',
              answerActionType: 'NO_POSITION_FOUND',
            },
          ],
        },
        {
          text: locationMessage.chooseCounty,
          type: messageTypes.LOCATION_COUNTY,
          metadata: {
            type: messageTypes.LOCATION_COUNTY,
            showSize: locationMessage.showSize,
            showAll: locationMessage.showAll,
            nextPageButton: locationMessage.nextPageButton,
          },
          rootAnswerIds: null,
          answers: [
            {
              ...defaultDefaultAnswer,
              answerActionRootData: {
                ...defaultDefaultAnswer.answerActionRootData,
              },
            },
            {
              text: 'NO_POSITION_FOUND',
              answerActionType: 'NO_POSITION_FOUND',
            },
          ],
        },
        {
          text: locationMessage.chooseCity,
          type: messageTypes.LOCATION_CITY,
          metadata: {
            type: messageTypes.LOCATION_CITY,
            cityChooseAllText: locationMessage.cityChooseAllText,
            cityChooseNoneText: locationMessage.cityChooseNoneText,
            showSize: locationMessage.showSize,
            showAll: locationMessage.showAll,
            nextPageButton: locationMessage.nextPageButton,
          },
          rootAnswerIds: null,
          answers: [
            {
              ...defaultDefaultAnswer,
              answerActionRootData: {
                ...defaultDefaultAnswer.answerActionRootData,
              },
            },
            {
              text: 'ONE_CITY_AVAILABLE',
              answerActionType: 'ONE_CITY_AVAILABLE',
            },
            {
              text: 'NO_POSITION_FOUND',
              answerActionType: 'NO_POSITION_FOUND',
            },
          ],
        },
      ];
    } else if (isDocumentView) {
      messageValues = {
        text: values.text,
        type: messageTypes.DOCUMENT_VIEWER_MESSAGE,
        rootAnswerIds: getRootAnswerIds(parentConditions),
        answers: [
          {
            text: values.buttonText,
            answerActionType: 'DEFAULT',
            answerActionRootData: {
              type: 'none',
            },
          },
        ],
        metadata: {
          type: messageTypes.DOCUMENT_VIEWER_MESSAGE,
          folderSlug: values.folderSlug.value,
          notFoundErrorText: values.notFoundErrorText,
        },
      };
    } else {
      let messageMetadata;
      if (isOpenEnded) {
        messageMetadata = {
          type: messageTypes.OPEN_ENDED_ANSWERS,
          validatorProperties:
            {
              regularExpression: values.regularExpression?.value
                ? values.regularExpression?.value
                : null,
              isoRegionCode: values.isoRegionCode ? values.isoRegionCode : null,
            } || null,
          regexReplacers:
            values.regexReplacers.map(({ value }: RegexReplacersModel) => ({
              pattern: value.value,
              replaceTo: value.additional,
            })) || null,
          validatorType:
            values.validatorType.value || QuestionnaireMessageMetadataValidatorType.NONE,
          saveLocation: values.saveLocation?.value ? values.saveLocation.value : 'attribute',
          faultTolerant: values?.faultTolerant || false,
        };
      } else if (isClosedEnded) {
        messageMetadata = {
          type: messageTypes.CLOSED_ENDED_ANSWERS,
        };
      } else if (isSimpleMessage) {
        messageMetadata = {
          type: messageTypes.SIMPLE_TEXT_MESSAGE,
        };
      } else if (isLogicController) {
        messageMetadata = {
          type: messageTypes.LOGIC_CONTROLLER,
        };
      } else if (isAtsGatewayController) {
        messageMetadata = {
          type: messageTypes.ATS_GATEWAY_CONTROLLER,
        };
      } else if (isLocationPartOfCountry) {
        messageMetadata = {
          type: messageTypes.LOCATION_PART_OF_COUNTRY,
          showAll: values.showAll,
          nextPageButton: !values.showAll ? values.nextPageButton : null,
          showSize: !values.showAll ? values.showSize : null,
        };
      } else if (isLocationCounty) {
        messageMetadata = {
          type: messageTypes.LOCATION_COUNTY,
          showAll: values.showAll,
          nextPageButton: !values.showAll ? values.nextPageButton : null,
          showSize: !values.showAll ? values.showSize : null,
        };
      } else if (isLocationCity) {
        messageMetadata = {
          type: messageTypes.LOCATION_CITY,
          cityChooseAllText: values.cityChooseAllText,
          cityChooseNoneText: values.cityChooseNoneText,
          showAll: values.showAll,
          nextPageButton: !values.showAll ? values.nextPageButton : null,
          showSize: !values.showAll ? values.showSize : null,
        };
      } else if (isShowPositionMessage) {
        messageMetadata = {
          type: messageTypes.SHOW_POSITION_MESSAGE,
          showAll: values.showAll,
          nextPageButton: !values.showAll ? values.nextPageButton : null,
          showSize: !values.showAll ? values.showSize : null,
          showNextButton: !values.showAll ? values.showNextButton : null,
          carouselView: values.carouselView,
          carouselButtonText: values.carouselView ? values.carouselButtonText : null,
          carouselDefaultImageUrl: values.carouselView ? values.carouselDefaultImageUrl : null,
        };
      } else if (isPositionApplyController) {
        messageMetadata = {
          type: messageTypes.POSITION_APPLY_CONTROLLER,
        };
      } else if (isPositionPrefilterCriteriasMessage) {
        messageMetadata = {
          type: messageTypes.POSITION_APPLY_CONTROLLER,
          enableKoExitPoint: values.enableKoExitPoint,
        };
      } else if (isExternalServiceCall) {
        const { path, errorMessage, parameters } = values;
        const convertedParams: { [key: string]: string } = {};
        if (Array.isArray(parameters)) {
          parameters.map(item => (convertedParams[item.key] = item.value));
        }

        messageMetadata = {
          type: messageTypes.EXPORT_APPLICATION_TO_ATS_CONTROLLER,
          path,
          errorMessage,
          parameters: convertedParams !== {} ? convertedParams : parameters,
        };
      } else if (isDocumentUploader) {
        const {
          hint,
          webviewUrl,
          saveLocation,
          maxFileSizeInBytes,
          allowedFileMimeTypes,
          cantUploadErrorText,
          tooLargeFileSizeErrorText,
          notAllowedFileMimeTypeErrorText,
        } = values;

        messageMetadata = {
          type: messageTypes.DOCUMENT_UPLOAD_CONTROLLER,
          saveLocation: saveLocation?.value ? saveLocation.value : null,
          webviewUrl: webviewUrl,
          hint: hint,
          maxFileSizeInBytes: maxFileSizeInBytes,
          allowedFileMimeTypes: allowedFileMimeTypes.map((m: SelectModel) => m.value),
          cantUploadErrorText: cantUploadErrorText,
          tooLargeFileSizeErrorText: tooLargeFileSizeErrorText,
          notAllowedFileMimeTypeErrorText: notAllowedFileMimeTypeErrorText,
        };
      } else if (isDocumentView) {
        messageMetadata = {
          type: messageTypes.DOCUMENT_VIEWER_MESSAGE,
          folderSlug: values.folderSlug.value,
          notFoundErrorText: values.notFoundErrorText,
        };
      } else if (isStartMessage) {
        messageMetadata = {
          type: messageTypes.START_CONTROLLER,
          mainRoute: values.mainRoute,
        };
      } else if (isAnalyticsController) {
        const analytics = values.analytics.map((analytic: AnalyticsControllerModel) => {
          return { ...analytic, analyticsType: analytic.analyticsType.value };
        });

        messageMetadata = {
          type: messageTypes.ANALYTICS_CONTROLLER,
          analytics: analytics,
        };
      } else if (isActionController) {
        const actions = values.actions.map((action: ActionControllerModel) => {
          return {
            ...action,
            actionType: action.actionType.value,
            method: action.method.value,
            data: {
              attributeKey: action.data.attributeKey,
              attributeValue: action.data.attributeValue,
              labelCategorySlug: action.data.labelCategory?.value || action.data.labelCategorySlug,
              labelSlug: action.data.label?.value || action.data.labelSlug,
            },
          };
        });

        messageMetadata = {
          type: messageTypes.ACTION_CONTROLLER,
          actions: actions,
        };
      } else if (isContextController) {
        const contextActions = values.contextActions.map(
          (action: ContextControllerContextAction) => {
            return { ...action, method: action.method.value };
          },
        );
        messageMetadata = {
          type: messageTypes.CONTEXT_CONTROLLER,
          contextActions,
        };
      } else if (isDynamicLabelMessage) {
        messageMetadata = {
          type: messageTypes.DYNAMIC_LABEL_MESSAGE,
          labelCategorySlug: values.labelCategorySlug.value,
          perPageLabelSlugs: values.perPageLabelSlugs.map((slug: SelectModel) => slug.value),
          showAll: values.showAll,
          nextPageButton: !values.showAll ? values.nextPageButton : null,
          showSize: !values.showAll ? values.showSize : null,
        };
      } else if (isInterviewEventMessage) {
        messageMetadata = {
          type: messageTypes.SHOW_INTERVIEW_EVENTS_MESSAGE,
          showAll: values.showAll,
          nextPageButton: !values.showAll ? values.nextPageButton : null,
          showSize: !values.showAll ? values.showSize : null,
          showNextButton: !values.showAll ? values.showNextButton : null,
        };
      } else if (isImageMessage) {
        messageMetadata = {
          type: messageTypes.IMAGE_MESSAGE,
        };
      }

      messageValues = {
        rootAnswerIds: getRootAnswerIds(parentConditions),
        intent: intent?.label ? intent.label : null,
        text:
          text ||
          chooseCity ||
          chooseCounty ||
          choosePartOfCountry ||
          expression?.value ||
          'External service call',
        type: messageType,
        answers: getAnswers(values),
        metadata: messageMetadata,
        disabled: disabled,
        nextMessageIdIfDisabled: nextMessageIdIfDisabled?.value
          ? nextMessageIdIfDisabled.value
          : null,
      };
    }
    if (
      !Array.isArray(messageValues) &&
      !messageValues.answers.length &&
      !isDocumentView &&
      !isContextController &&
      !isAnalyticsController &&
      !isActionController
    )
      return;

    if (modalEvent === 'create') {
      if (isLocationMessage) {
        createMessageGroups(appId, messageValues, 'location');
      } else {
        await createMessage(appId, messageValues);
      }
    }

    if (modalEvent === 'edit' && selectedMessage) {
      await updateMessage(appId, selectedMessage.id, messageValues);
    }

    setModalOpen(false);
    await getMessages(appId);
    window.scrollTo(0, scrollYPosition);
  };

  const hasEmptyInput = (query: string) => {
    let hasEmpty = false;
    const attributeInputs = document.querySelectorAll(query);

    attributeInputs.forEach((el: any) => {
      if (!el.value) {
        hasEmpty = true;

        if (!el.classList.contains('is-invalid')) {
          el.classList.add('is-invalid');
        }
      }

      if (el.value && el.classList.contains('is-invalid')) {
        el.classList.remove('is-invalid');
      }
    });

    return hasEmpty;
  };

  const getRootAnswerIds = (parentConditions: SelectModel[]) => {
    return parentConditions?.map(option => option.value) || [];
  };

  const getAnswers = (values: FormikValues) => {
    if (isSimpleMessage || isImageMessage || isPositionApplyController) {
      return [{ ...defaultSimpleMessageAnswer, id: selectedMessage?.answers[0].id || null }];
    }
    if (isLogicController) {
      return selectedMessage?.answers || defaultLogicControllerAnswer;
    }

    if (isLocationPartOfCountry || isLocationCounty) {
      if (selectedMessage) {
        let hasError = false;
        const { answers } = values;

        const answer = answers
          .filter(function (item: { answerActionType: string }) {
            return item.answerActionType !== 'DEFAULT';
          })
          .map((answer: QuestionnaireAnswer) => {
            const { answerActionType, answerActionRootData } = answer;

            const answerObject = {
              ...answer,
              id: answer.id || null,
              text: answer.editedText || answer.text,
              intent: answer.editedIntent || answer.intent,
            };

            if (answerActionType === QuestionnaireAnswerActionType.LABEL_APPLICANT) {
              if (!answerActionRootData?.labelSlug && !answerActionRootData?.label) {
                setAlertText(t('common.selectALabel'));
                hasError = true;
              }

              const labelSlug =
                answerActionRootData?.label?.value || answerActionRootData?.labelSlug;
              delete answerActionRootData?.label;

              answerObject.answerActionRootData = {
                ...answerActionRootData,
                type: 'label-applicant',
                labelSlug,
              };
            }

            if (answerActionType === QuestionnaireAnswerActionType.NONE) {
              answerObject.answerActionRootData = {
                type: 'none',
              };
            }

            if (answerActionType === QuestionnaireAnswerActionType.INVISIBLE_ANSWER) {
              answerObject.answerActionRootData = {
                type: 'none',
              };
            }

            return answerObject;
          });
        answer.push({
          ...selectedMessage.answers.filter(answer => answer.answerActionType === 'DEFAULT')[0],
          answerActionRootData: {
            ...selectedMessage.answers.filter(answer => answer.answerActionType === 'DEFAULT')[0]
              .answerActionRootData,
          },
        });
        return hasError ? [] : answer;
      }
      return [
        {
          ...defaultDefaultAnswer,
          answerActionRootData: {
            ...defaultDefaultAnswer.answerActionRootData,
          },
        },
      ];
    }

    if (isPositionPrefilterCriteriasMessage) {
      return [
        {
          ...{
            ...defaultPositionPrefilterCriteriasMessageAnswer,
          },
          id:
            selectedMessage?.answers.filter(answer => answer.answerActionType === 'DEFAULT')[0]
              .id || null,
        },
        {
          ...{
            ...defaultPositionPrefilterCriteriasEnabledKoExitPointAnswer,
          },
          id:
            selectedMessage?.answers.filter(
              answer => answer.answerActionType === 'ENABLED_KO_EXITPOINT',
            )[0].id || null,
        },
      ];
    }

    if (isLocationCity) {
      if (selectedMessage) {
        let hasError = false;
        const { answers } = values;

        const answer = answers
          .filter(function (item: { answerActionType: string }) {
            return item.answerActionType !== 'DEFAULT';
          })
          .map((answer: QuestionnaireAnswer) => {
            const { answerActionType, answerActionRootData } = answer;

            const answerObject = {
              ...answer,
              id: answer.id || null,
              text: answer.editedText || answer.text,
              intent: answer.editedIntent || answer.intent,
            };

            if (answerActionType === QuestionnaireAnswerActionType.LABEL_APPLICANT) {
              if (!answerActionRootData?.labelSlug && !answerActionRootData?.label) {
                setAlertText(t('common.selectALabel'));
                hasError = true;
              }

              const labelSlug =
                answerActionRootData?.label?.value || answerActionRootData?.labelSlug;
              delete answerActionRootData?.label;

              answerObject.answerActionRootData = {
                ...answerActionRootData,
                type: 'label-applicant',
                labelSlug,
              };
            }

            if (answerActionType === QuestionnaireAnswerActionType.NONE) {
              answerObject.answerActionRootData = {
                type: 'none',
              };
            }

            if (answerActionType === QuestionnaireAnswerActionType.INVISIBLE_ANSWER) {
              answerObject.answerActionRootData = {
                type: 'none',
              };
            }

            return answerObject;
          });
        answer.push({
          ...selectedMessage.answers.filter(answer => answer.answerActionType === 'DEFAULT')[0],
          answerActionRootData: {
            ...selectedMessage.answers.filter(answer => answer.answerActionType === 'DEFAULT')[0]
              .answerActionRootData,
          },
        });
        return hasError ? [] : answer;
      }
      return [
        {
          ...defaultDefaultAnswer,
          answerActionRootData: {
            ...defaultDefaultAnswer.answerActionRootData,
          },
        },
        {
          text: values.onlyOneCityAvailableText,
          answerActionType: 'ONE_CITY_AVAILABLE',
        },
        {
          text: 'NO_POSITION_FOUND',
          answerActionType: 'NO_POSITION_FOUND',
        },
      ];
    }

    if (isOpenEnded) {
      let hasError = false;
      const { answers } = values;

      const answer = answers.map((answer: QuestionnaireAnswer) => {
        const { answerActionType, answerActionRootData } = answer;

        const answerObject = {
          ...answer,
          id: answer.id || null,
          text: answer.editedText || answer.text,
          intent: answer.editedIntent || answer.intent,
        };

        if (answerActionType === QuestionnaireAnswerActionType.LABEL_APPLICANT) {
          if (!answerActionRootData?.labelSlug && !answerActionRootData?.label) {
            setAlertText(t('common.selectALabel'));
            hasError = true;
          }

          const labelSlug = answerActionRootData?.label?.value || answerActionRootData?.labelSlug;
          delete answerActionRootData?.label;

          answerObject.answerActionRootData = {
            ...answerActionRootData,
            type: 'label-applicant',
            labelSlug,
          };
        }

        if (answerActionType === QuestionnaireAnswerActionType.NONE) {
          answerObject.answerActionRootData = {
            type: 'none',
          };
        }

        if (answerActionType === QuestionnaireAnswerActionType.INVISIBLE_ANSWER) {
          answerObject.answerActionRootData = {
            type: 'none',
          };
        }

        if (answerActionType === QuestionnaireAnswerActionType.ATTRIBUTE_APPLICANT) {
          answerObject.answerActionRootData = {
            ...defaultOpenEndedQuestionAnswerAttribute.answerActionRootData,
            attributeKey: answerActionRootData?.attributeKey,
            attributeRequired: answerActionRootData?.attributeRequired,
            attributeMultiplicable: answerActionRootData?.attributeMultiplicable,
          };
        }

        if (answerActionType === QuestionnaireAnswerActionType.ATTRIBUTE_APPLICANT_POSITION) {
          answerObject.answerActionRootData = {
            ...defaultOpenEndedQuestionAnswerPositionAttribute.answerActionRootData,
            attributeKey: answerActionRootData?.attributeKey,
          };
        }

        return answerObject;
      });
      return hasError ? [] : answer;
    }

    if (isClosedEnded) {
      let hasError = false;
      const { answers } = values;

      const results = answers.map((answer: QuestionnaireAnswer) => {
        const { answerActionType, answerActionRootData } = answer;

        const answerObject = {
          ...answer,
          id: answer.id || null,
          text: answer.editedText || answer.text,
          intent: answer.editedIntent || answer.intent,
        };

        if (answerActionType === QuestionnaireAnswerActionType.LABEL_APPLICANT) {
          if (!answerActionRootData?.labelSlug && !answerActionRootData?.label) {
            setAlertText(t('common.selectALabel'));
            hasError = true;
          }

          const labelSlug = answerActionRootData?.label?.value || answerActionRootData?.labelSlug;
          delete answerActionRootData?.label;

          answerObject.answerActionRootData = {
            ...answerActionRootData,
            type: 'label-applicant',
            labelSlug,
          };
        }

        if (answerActionType === QuestionnaireAnswerActionType.NONE) {
          answerObject.answerActionRootData = {
            type: 'none',
          };
        }

        if (answerActionType === QuestionnaireAnswerActionType.INVISIBLE_ANSWER) {
          answerObject.answerActionRootData = {
            type: 'none',
          };
        }

        return answerObject;
      });

      return hasError ? [] : results;
    }

    if (isShowPositionMessage) {
      if (selectedMessage) {
        let hasError = false;
        const { answers } = values;

        const answer = answers
          .filter(function (item: { answerActionType: string }) {
            return item.answerActionType !== 'DEFAULT';
          })
          .map((answer: QuestionnaireAnswer) => {
            const { answerActionType, answerActionRootData } = answer;

            const answerObject = {
              ...answer,
              id: answer.id || null,
              text: answer.editedText || answer.text,
              intent: answer.editedIntent || answer.intent,
            };

            if (answerActionType === QuestionnaireAnswerActionType.LABEL_APPLICANT) {
              if (!answerActionRootData?.labelSlug && !answerActionRootData?.label) {
                setAlertText(t('common.selectALabel'));
                hasError = true;
              }

              const labelSlug =
                answerActionRootData?.label?.value || answerActionRootData?.labelSlug;
              delete answerActionRootData?.label;

              answerObject.answerActionRootData = {
                ...answerActionRootData,
                type: 'label-applicant',
                labelSlug,
              };
            }

            if (answerActionType === QuestionnaireAnswerActionType.NONE) {
              answerObject.answerActionRootData = {
                type: 'none',
              };
            }

            if (answerActionType === QuestionnaireAnswerActionType.INVISIBLE_ANSWER) {
              answerObject.answerActionRootData = {
                type: 'none',
              };
            }

            return answerObject;
          });
        answer.push({
          ...selectedMessage.answers.filter(answer => answer.answerActionType === 'DEFAULT')[0],
          answerActionRootData: {
            ...selectedMessage.answers.filter(answer => answer.answerActionType === 'DEFAULT')[0]
              .answerActionRootData,
          },
        });

        return hasError ? [] : answer;
      }
      const { nextPageButton, showAll, showSize } = values;

      const showPositionMessageAnswer = {
        ...defaultDefaultAnswer,
        answerActionRootData: {
          ...defaultDefaultAnswer.answerActionRootData,
          showAll: showAll,
          nextPageButton: !showAll ? nextPageButton : null,
          showSize: !showAll ? showSize : null,
        },
      };

      return [showPositionMessageAnswer];
    }

    if (isExternalServiceCall) {
      const exportApplicationToAtsAnswer = {
        ...defaultExportApplicationToAtsAnswer,
        id:
          selectedMessage?.answers.filter(answer => answer.answerActionType === 'DEFAULT')[0]?.id ||
          null,
        answerActionRootData: {
          ...defaultExportApplicationToAtsAnswer.answerActionRootData,
        },
      };

      return [exportApplicationToAtsAnswer];
    }

    if (isDocumentUploader) {
      let hasError = false;
      const { answers } = values;

      const answer = answers.map((answer: QuestionnaireAnswer) => {
        const { answerActionType, answerActionRootData } = answer;

        const answerObject = {
          ...answer,
          id: answer.id || null,
          text: answer.editedText || answer.text,
          intent: answer.editedIntent || answer.intent,
        };

        if (answerActionType === QuestionnaireAnswerActionType.NONE) {
          answerObject.answerActionRootData = {
            type: 'none',
          };
        }

        if (answerActionType === QuestionnaireAnswerActionType.INVISIBLE_ANSWER) {
          answerObject.answerActionRootData = {
            type: 'none',
          };
        }

        if (
          answerActionType === QuestionnaireAnswerActionType.ATTRIBUTE_APPLICANT ||
          answerActionType === QuestionnaireAnswerActionType.ATTRIBUTE_APPLICANT_POSITION
        ) {
          answerObject.answerActionRootData = {
            ...defaultOpenEndedQuestionAnswerAttribute.answerActionRootData,
            attributeKey: answerActionRootData?.attributeKey,
            attributeRequired: answerActionRootData?.attributeRequired,
            attributeMultiplicable: answerActionRootData?.attributeMultiplicable,
          };
        }

        return answerObject;
      });
      return hasError ? [] : answer;
    }

    if (isStartMessage) {
      return [
        {
          ...defaultStartAnswer,
          id:
            selectedMessage?.answers.filter(answer => answer.answerActionType === 'START_ANSWER')[0]
              .id || null,
          text: 'START',
          answerActionRootData: {
            ...defaultStartAnswer.answerActionRootData,
          },
        },
      ];
    }

    if (isAtsGatewayController) {
      return [
        {
          ...defaultDefaultAnswer,
          id:
            selectedMessage?.answers.filter(answer => answer.answerActionType === 'DEFAULT')[0]
              .id || null,
          answerActionRootData: {
            ...defaultStartAnswer.answerActionRootData,
          },
        },
      ];
    }

    if (isDynamicLabelMessage) {
      if (selectedMessage) {
        let hasError = false;
        const { answers } = values;

        const answer = answers
          .filter(function (item: { answerActionType: string }) {
            return item.answerActionType !== 'DEFAULT';
          })
          .map((answer: QuestionnaireAnswer) => {
            const { answerActionType, answerActionRootData } = answer;

            const answerObject = {
              ...answer,
              id: answer.id || null,
              text: answer.editedText || answer.text,
              intent: answer.editedIntent || answer.intent,
            };

            if (answerActionType === QuestionnaireAnswerActionType.LABEL_APPLICANT) {
              if (!answerActionRootData?.labelSlug && !answerActionRootData?.label) {
                setAlertText(t('common.selectALabel'));
                hasError = true;
              }

              const labelSlug =
                answerActionRootData?.label?.value || answerActionRootData?.labelSlug;
              delete answerActionRootData?.label;

              answerObject.answerActionRootData = {
                ...answerActionRootData,
                type: 'label-applicant',
                labelSlug,
              };
            }

            if (answerActionType === QuestionnaireAnswerActionType.NONE) {
              answerObject.answerActionRootData = {
                type: 'none',
              };
            }

            if (answerActionType === QuestionnaireAnswerActionType.INVISIBLE_ANSWER) {
              answerObject.answerActionRootData = {
                type: 'none',
              };
            }

            return answerObject;
          });
        answer.push({
          ...selectedMessage.answers.filter(answer => answer.answerActionType === 'DEFAULT')[0],
          answerActionRootData: {
            ...selectedMessage.answers.filter(answer => answer.answerActionType === 'DEFAULT')[0]
              .answerActionRootData,
          },
        });
        return hasError ? [] : answer;
      }

      const dynamicLabelAnswer = {
        ...defaultDefaultAnswer,
        answerActionRootData: {
          ...defaultDefaultAnswer.answerActionRootData,
        },
      };

      return [dynamicLabelAnswer];
    }

    if (isInterviewEventMessage) {
      if (selectedMessage) {
        let hasError = false;
        const { answers } = values;

        const answer = answers
          .filter(function (item: { answerActionType: string }) {
            return item.answerActionType !== 'INTERVIEW_EVENTS_ANSWER';
          })
          .map((answer: QuestionnaireAnswer) => {
            const { answerActionType, answerActionRootData } = answer;

            const answerObject = {
              ...answer,
              id: answer.id || null,
              text: answer.editedText || answer.text,
              intent: answer.editedIntent || answer.intent,
            };

            if (answerActionType === QuestionnaireAnswerActionType.LABEL_APPLICANT) {
              if (!answerActionRootData?.labelSlug && !answerActionRootData?.label) {
                setAlertText(t('common.selectALabel'));
                hasError = true;
              }

              const labelSlug =
                answerActionRootData?.label?.value || answerActionRootData?.labelSlug;
              delete answerActionRootData?.label;

              answerObject.answerActionRootData = {
                ...answerActionRootData,
                type: 'label-applicant',
                labelSlug,
              };
            }

            if (answerActionType === QuestionnaireAnswerActionType.NONE) {
              answerObject.answerActionRootData = {
                type: 'none',
              };
            }

            if (answerActionType === QuestionnaireAnswerActionType.INVISIBLE_ANSWER) {
              answerObject.answerActionRootData = {
                type: 'none',
              };
            }

            return answerObject;
          });
        answer.push({
          ...selectedMessage.answers.filter(answer => answer.answerActionType === 'DEFAULT')[0],
          answerActionRootData: {
            ...selectedMessage.answers.filter(answer => answer.answerActionType === 'DEFAULT')[0]
              .answerActionRootData,
          },
        });
        return hasError ? [] : answer;
      }

      const interviewEventAnswers = {
        ...defaultInterviewEventAnswer,
        answerActionRootData: {
          ...defaultInterviewEventAnswer.answerActionRootData,
          showAll: values.showAll,
          nextPageButton: !values.showAll ? values.nextPageButton : null,
          showSize: !values.showAll ? values.showSize : null,
          showNextButton: !values.showAll ? values.showNextButton : null,
        },
      };

      return [interviewEventAnswers];
    }

    if (isContextController) {
      return [
        {
          ...defaultDefaultAnswer,
          id:
            selectedMessage?.answers.filter(answer => answer.answerActionType === 'DEFAULT')[0]
              .id || null,
          answerActionRootData: {
            ...defaultStartAnswer.answerActionRootData,
          },
        },
      ];
    }

    if (isAnalyticsController) {
      return [
        {
          ...defaultDefaultAnswer,
          id:
            selectedMessage?.answers.filter(answer => answer.answerActionType === 'DEFAULT')[0]
              .id || null,
          answerActionRootData: {
            ...defaultStartAnswer.answerActionRootData,
          },
        },
      ];
    }

    if (isActionController) {
      return [
        {
          ...defaultDefaultAnswer,
          id:
            selectedMessage?.answers.filter(answer => answer.answerActionType === 'DEFAULT')[0]
              .id || null,
          answerActionRootData: {
            ...defaultStartAnswer.answerActionRootData,
          },
        },
      ];
    }

    return [];
  };

  return (
    <Fragment>
      <Row>
        <Col>
          <CheckPermission
            variant="displayIf"
            permissions={['position_questionnaire_message_create']}
          >
            <Row className="mb-3">
              <Col className="d-flex justify-content-end">
                <MessageCreateDropdown onClick={handleOnMessageCreateClick} />
              </Col>
            </Row>
          </CheckPermission>

          <Card className="mb-0">
            <CardBody>
              {messagesState.loaded && !messagesState.data.length && (
                <Alert color="info" className="text-center mb-0">
                  {t('questionnaire.noMessagesFound')}
                </Alert>
              )}

              {messagesState.loading && (
                <div className="d-flex justify-content-center">
                  <Spinner loading={messagesState.loading} size="2x" />
                </div>
              )}

              {messagesState.loaded && messagesState.data.length > 0 && (
                <MessagesTable
                  getMessages={getMessages}
                  messages={messagesState.data}
                  setModalOpen={setModalOpen}
                  setModalEvent={setModalEvent}
                  selectedMessage={selectedMessage}
                  setSelectedMessage={setSelectedMessage}
                  setMessageType={setMessageType}
                  setScrollYPosition={setScrollYPosition}
                  scrollYPosition={scrollYPosition}
                  setConnectionsModalOpen={setConnectionsModalOpen}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <ConnectionsModal
        isOpen={isConnectionsModalOpen}
        selectedMessage={selectedMessage}
        messages={messagesState.data}
        toggle={setConnectionsModalOpen}
        setSelectedMessage={setSelectedMessage}
      />

      <MessageModal
        isOpen={isModalOpen}
        toggle={setModalOpen}
        isSimpleMessage={isSimpleMessage}
        isOpenEnded={isOpenEnded}
        isClosedEnded={isClosedEnded}
        isLogicController={isLogicController}
        isLocationPartOfCountry={isLocationPartOfCountry}
        isLocationCounty={isLocationCounty}
        isLocationCity={isLocationCity}
        isLocationMessage={isLocationMessage}
        isShowPositionMessage={isShowPositionMessage}
        isPositionApplyController={isPositionApplyController}
        isPositionPrefilterCriteriasMessage={isPositionPrefilterCriteriasMessage}
        isExternalServiceCall={isExternalServiceCall}
        isDocumentUploader={isDocumentUploader}
        isDocumentView={isDocumentView}
        isStartMessage={isStartMessage}
        isAnalyticsController={isAnalyticsController}
        isActionController={isActionController}
        isContextController={isContextController}
        isDynamicLabelMessage={isDynamicLabelMessage}
        isInterviewEventMessage={isInterviewEventMessage}
        isImageMessage={isImageMessage}
        isAtsGatewayController={isAtsGatewayController}
        modalEvent={modalEvent}
        alertText={alertText}
        setAlertText={setAlertText}
        handleOnSubmit={handleOnSubmit}
        selectedMessage={selectedMessage}
        messageType={messageType}
        messageTypeEnum={messageTypeEnum}
      />
    </Fragment>
  );
};

const mapDispatchToProps = {
  getLabelCategories,
  createMessage,
  updateMessage,
};

export default connect(null, mapDispatchToProps)(MessagesTableContainer);
