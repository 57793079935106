import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';

import { StateModel } from 'src/shared/models/default-state.model';

import { parseFileExtension } from 'src/shared/utils/parse.util';

import { getApplication, ApplicationModel } from 'src/redux/actions/applications.action';
import { importTrainingDataset } from 'src/redux/actions/dataset.action';
import { getSignatureForUpload, SignatureForUploadModel } from 'src/redux/actions/upload.action';

import { uploadFile } from 'src/shared/services/upload.service';

import ImportTrainingDataForm from './components/ImportTrainingDataForm';
import { UploadedDocuments, PageHeader } from 'src/shared/components';
import { UploadedDocumentType } from 'src/shared/models';

interface Props extends RouteComponentProps<{ id: string }>, WithTranslation {
  application: ApplicationModel;
  getApplication: Function;
  datasetImport: StateModel;
  importTrainingDataset: Function;
  signature: SignatureForUploadModel;
  getSignatureForUpload: Function;
}

const UPLOAD_METHOD = 'PUT';

interface State {
  updateList: boolean;
}

export class ImportTrainingData extends Component<Props> {
  state: State = {
    updateList: false,
  };

  componentDidMount = () => {
    const { id } = this.props.application;

    if (!id) {
      this.props.getApplication(this.props.match.params.id);
    }
  };

  handleSubmit = async (payload: any) => {
    const { file } = payload;

    const fileExtension = parseFileExtension(file.name);

    if (fileExtension) {
      await this.props.getSignatureForUpload(fileExtension, UPLOAD_METHOD);
      await uploadFile(file, this.props.signature);
      payload.url = this.props.signature.downloadUrl;
    }

    delete payload.file;

    if (payload.url) {
      const { id } = this.props.application;
      await this.props.importTrainingDataset(id, payload);

      this.setState(
        {
          updateList: true,
        },
        () => {
          this.setState({ updateList: false });
        },
      );
    }
  };

  render() {
    const { datasetImport, t } = this.props;
    const { updateList } = this.state;

    return (
      <div className="animated fadeIn">
        <PageHeader title={t('common.importTrainingData')} />

        <Card>
          <CardBody>
            <ImportTrainingDataForm
              datasetImport={datasetImport}
              handleSubmit={this.handleSubmit}
            />
          </CardBody>
        </Card>
        <UploadedDocuments
          uploadedDocumentType={UploadedDocumentType.TRAINING_DATA}
          updateList={updateList}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  datasetImport: state.trainingDataset,
  signature: state.signatureForUpload.data,
});

const mapDispatchToProps = {
  getApplication,
  importTrainingDataset,
  getSignatureForUpload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(ImportTrainingData)));
