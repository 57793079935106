import React, { Component, Fragment } from 'react';
import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';

import styles from './scss/Hangouts.module.scss';

interface Props {
  message: ConversationHistoryModel;
}

interface State {
  outputTemplate: any;
}

class SimplifiedButton extends Component<Props> {
  state: State = {
    outputTemplate: null,
  };

  componentDidMount() {
    // Replacing \n characters with space
    // JSON.parse() would fail otherwise
    const cleanedMessage = this.props.message.messageAsText.replace(/\n/g, ' ');
    this.renderTemplate(cleanedMessage);
  }

  renderTitle = (text: string) => {
    return (
      <div className={styles.title} key={text}>
        {text}
      </div>
    );
  };

  renderButtons = (buttons: any[]) => {
    const outputButtons: any[] = [];

    for (let i = 0; i < buttons.length; i++) {
      outputButtons.push(
        <button type="button" key={i} disabled>
          {buttons[i]}
        </button>,
      );
    }

    return outputButtons;
  };

  renderTemplate = (msg: any) => {
    const outputTemplate: any[] = [];

    try {
      const currentTemplate = JSON.parse(msg);
      const { text } = currentTemplate;
      const { buttons } = currentTemplate;

      outputTemplate.push(
        <Fragment>
          {this.renderTitle(text)}
          <div className={styles.buttons}>{this.renderButtons(buttons)}</div>
        </Fragment>,
      );

      this.setState({ outputTemplate });
    } catch (error) {
      this.setState({ outputTemplate: msg });
    }
  };

  render() {
    return <div className={styles.simplifiedButtonTemplate}>{this.state.outputTemplate}</div>;
  }
}

export default SimplifiedButton;
