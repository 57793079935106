import { get, patch, post, del } from '../../shared/services/http.service';
import { UserModel, UserResponseModel } from '../../shared/models/user.model';

const AUTH_JAVA_ENABLED = process.env.REACT_APP_AUTH_JAVA_ENABLED !== 'false';

export const getUserProfile = async () => {
  if (AUTH_JAVA_ENABLED) {
    return get('/users/user-profile').then((response: UserResponseModel) => {
      return {
        id: response.id,
        email: response.email,
        firstName: response.firstName,
        lastName: response.lastName,
        tenantId: response.tenant.id,
        lastLoginAt: response.loggedInAt,
        userGroupId: response.userGroup.id,
        userGroup: response.userGroup.name,
        userGroupName: response.userGroup.name,
        avatar: response.avatar,
      };
    });
  } else {
    return get('/user-profile');
  }
};

export const getPermissions = async () => {
  if (AUTH_JAVA_ENABLED) {
    return get('/users/user-profile').then((response: UserResponseModel) => {
      return response.userGroup.permissions.map((permission) => permission.name);
    });
  } else {
    return get('/permissions');
  }
};

export const getTenantUsers = async () => {
  if (AUTH_JAVA_ENABLED) {
    return get('/users').then((response: UserResponseModel[]) => {
      return response.map((user) => ({
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        tenantId: user.tenant.id,
        lastLoginAt: user.loggedInAt,
        userGroupId: user.userGroup.id,
        userGroup: user.userGroup.name,
        userGroupName: user.userGroup.name,
        avatar: user.avatar,
      }));
    });
  } else {
    return get('/user');
  }
};

export const getTenantUser = async (id: number) => {
  if (AUTH_JAVA_ENABLED) {
    return get(`/users/${id}`).then((response: UserResponseModel) => {
      return {
        id: response.id,
        email: response.email,
        firstName: response.firstName,
        lastName: response.lastName,
        tenantId: response.tenant.id,
        lastLoginAt: response.loggedInAt,
        userGroupId: response.userGroup.id,
        userGroup: response.userGroup.name,
        userGroupName: response.userGroup.name,
        avatar: response.avatar,
      };
    });
  } else {
    return get(`/user/${id}`);
  }
};

export const createTenantUser = async (user: UserModel) => {
  if (AUTH_JAVA_ENABLED) {
    return post('/users', { data: user }).then((response: UserResponseModel) => {
      return {
        id: response.id,
        email: response.email,
        firstName: response.firstName,
        lastName: response.lastName,
        tenantId: response.tenant.id,
        lastLoginAt: response.loggedInAt,
        userGroupId: response.userGroup.id,
        userGroup: response.userGroup.name,
        userGroupName: response.userGroup.name,
        avatar: response.avatar,
      };
    });
  } else {
    return post('/user', { data: user });
  }
};

export const upateTenantUser = async (id: number, user: UserModel) => {
  if (AUTH_JAVA_ENABLED) {
    return patch(`/users/${id}`, { data: user }).then((response: UserResponseModel) => {
      return {
        id: response.id,
        email: response.email,
        firstName: response.firstName,
        lastName: response.lastName,
        tenantId: response.tenant.id,
        lastLoginAt: response.loggedInAt,
        userGroupId: response.userGroup.id,
        userGroup: response.userGroup.name,
        userGroupName: response.userGroup.name,
        avatar: response.avatar,
      };
    });
  } else {
    return patch(`/user/${id}`, { data: user });
  }
};

export const deleteTenantUser = async (id: number) => {
  if (AUTH_JAVA_ENABLED) {
    return del(`/users/${id}`);
  } else {
    return del(`/user/${id}`);
  }
};

export const getUserGroups = async () => {
  if (AUTH_JAVA_ENABLED) {
    return get('/users/user-groups/flat');
  } else {
    return get('/user-profile/user-groups/deep/flat');
  }
};
