import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import capitalize from 'lodash/capitalize';

import {
  PositionModel,
  PositionQuestionAnswerOptionModel,
  PositionQuestionModel,
} from 'src/shared/models/positions.model';
import {
  QuestionnaireLabel,
  QuestionnaireLabelCategory,
} from '../../../shared/models/questionnaire.model';

import {
  isPositionExpired,
  renderExpiredBadge,
  renderPositionStatusBadge,
} from '../../../shared/utils/positions.util';

import TagPill from '../../../shared/components/TagPill';
import PositionUrls from './PositionUrls';
import CopyToClipboardButton from '../../../shared/components/CopyToClipboardButton';

import styles from '../Positions.module.scss';
import { uniqueId } from 'lodash';

interface Props {
  position: PositionModel | undefined;
  isOpen: boolean;
  platforms: string[];
  facebookPageId: string;
  labelCategories: QuestionnaireLabelCategory[];
  cancel: () => void;
}

const PositionDetailsModal: React.FC<Props> = ({
  position,
  isOpen,
  platforms,
  facebookPageId,
  labelCategories,
  cancel,
}) => {
  const { t } = useTranslation();

  const renderPositionDetails = (position: PositionModel) => (
    <Row className="p-3 bg-light">
      <Col className="px-0">
        <div className="d-flex justify-content-between align-items-baseline">
          <div className={styles.positionNameContainer}>
            <div className="d-flex flex-column">
              <div className="d-flex mb-2">
                <span>{renderPositionStatusBadge(position.status)}</span>
                {isPositionExpired(position.expiresAt) && (
                  <span className="ml-2">{renderExpiredBadge()}</span>
                )}
              </div>
              <h3>{position.name}</h3>
            </div>

            <ReactMarkdown
              source={position.description}
              renderers={{ paragraph: props => <p className="mb-0">{props.children}</p> }}
            />
          </div>

          <div className={styles.positionDetailsContainer}>
            <h6>{`${moment(position.startsAt).format('LL')} - ${moment(position.expiresAt).format(
              'LL',
            )}`}</h6>
            {position.slug && (
              <div className="d-flex align-items-center">
                <p className="text-muted mb-0 mr-2">{position.slug}</p>
                <CopyToClipboardButton
                  id={position.slug}
                  valueToCopy={position.slug}
                  tooltipPlacement="top"
                />
              </div>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );

  const renderPositionUrls = (positionSlug: string | undefined) => {
    if (!positionSlug || !platforms.length || !facebookPageId) return null;

    return (
      <Row className="mt-3">
        <Col>
          <h6>{t('positions.platformUrls')}</h6>
          <PositionUrls
            positionSlug={positionSlug}
            platforms={platforms}
            facebookPageId={facebookPageId}
          />
        </Col>
      </Row>
    );
  };

  const renderPositionLabels = (positionlabels: QuestionnaireLabel[] | undefined) => {
    const groupedLabelsByCategoryId = groupBy(positionlabels, 'categoryId');

    const items = Object.entries(groupedLabelsByCategoryId).map(el => {
      const labelCategory = labelCategories.find(c => c.id?.toString() === el[0]);

      const labelTagPills = el[1].map(label => (
        <TagPill key={label.id} item={label.text} isEditable={false} isDeleteable={false} />
      ));

      return (
        <div key={uniqueId('positionLabels')} className="d-flex flex-column shadow-sm p-2 m-2">
          <p className="mb-0">{capitalize(labelCategory?.text)}</p>
          <div className="d-flex flex-row">{labelTagPills}</div>
        </div>
      );
    });

    return (
      <div className="mt-4">
        <h6>{t('common.labels')}</h6>
        {items.length > 0 ? (
          <div className="d-flex flex-wrap">{items}</div>
        ) : (
          <p className="mb-0 text-muted">{t('positions.noLabelsFound')}</p>
        )}
      </div>
    );
  };

  const renderQuestionAnswerOptions = (answerOptions: PositionQuestionAnswerOptionModel[]) => {
    if (!answerOptions.length) return null;

    const sortedAnswerOptions = answerOptions.sort((a, b) => a.orderNumber - b.orderNumber);

    const results = sortedAnswerOptions.map((option: PositionQuestionAnswerOptionModel) => (
      <div key={option.id} className="d-flex flex-row">
        <Badge className={styles.possibleAnswerBadge} color="primary">
          {option.text}
        </Badge>

        <Badge className={styles.possibleAnswerBadge} color={option.value ? 'info' : 'secondary'}>
          {option.value ? `${t('common.value')}: ${option.value}` : t('common.noValue')}
        </Badge>

        <Badge className={styles.possibleAnswerBadge} color={option.ko ? 'danger' : 'secondary'}>
          {option.ko ? 'KO' : t('common.notKo')}
        </Badge>
      </div>
    ));

    return <div className="d-flex flex-column">{results}</div>;
  };

  const renderPrefilterCriteriasTable = (questions: PositionQuestionModel[]) => {
    return (
      <Table className={styles.positionDetailsTable} size="sm" hover striped>
        <thead>
          <tr>
            <th className={styles.shortList}>{t('common.shortList')}</th>
            <th className={styles.category}>{t('common.category')}</th>
            <th className={styles.question}>{t('common.question')}</th>
            <th className={styles.possibleAnswers}>{t('common.possibleAnswers')}</th>
          </tr>
        </thead>

        <tbody>
          {questions.map(question => (
            <tr key={question.id}>
              <td className={styles.shortList}>
                {question.shortList ? (
                  <i className="far fa-check-circle" />
                ) : (
                  <i className="far fa-times-circle" />
                )}
              </td>
              <td>{question.criteria.name}</td>
              <td>{question.text}</td>
              <td>
                {question.anyAnswer
                  ? `${t('positions.freeText')}`
                  : renderQuestionAnswerOptions(question.questionAnswerOptions)}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const renderPrefilterCriterias = (questions: PositionQuestionModel[] | undefined) => {
    return (
      <div className="mt-4">
        <h6>
          {t('common.prefilterCriterias')} & {t('common.shortListDefinition')}
        </h6>
        {questions && questions.length > 0 ? (
          renderPrefilterCriteriasTable(questions)
        ) : (
          <p className="mb-0 text-muted">{t('positions.noPrefilterCriteriasFound')}</p>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      {position && (
        <Modal isOpen={isOpen} size="lg" toggle={() => cancel()}>
          <ModalHeader>{t('positions.positionDetails')}</ModalHeader>
          <ModalBody className="pt-0">
            {renderPositionDetails(position)}
            {renderPositionUrls(position.slug)}
            {renderPositionLabels(position.labels)}
            {renderPrefilterCriterias(position.questions)}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => cancel()}>
              {t('common.close')}
            </Button>{' '}
          </ModalFooter>
        </Modal>
      )}
    </Fragment>
  );
};

export default PositionDetailsModal;
