import React, { FC } from 'react';
import uniqueId from 'lodash/uniqueId';

import { ViberMessageModel } from 'src/shared/models';
import { ConversationHistoryModel } from 'src/redux/actions/conversation-history.action';
import { ViberButton, ViberLink, ViberButtonContainer } from './components';

interface ViberMessageProps {
  message: ConversationHistoryModel;
}

const ViberMessage: FC<ViberMessageProps> = ({ message }) => {
  const text: string = message.messageAsText;
  const viberMessage: ViberMessageModel = JSON.parse(text);

  return viberMessage.buttonTemplateButtons.length > 1 && viberMessage ? (
    <ViberButtonContainer text={viberMessage.text}>
      {viberMessage.buttonTemplateButtons.map(el =>
        el.url ? (
          <ViberLink href={el.url} text={el.text} key={uniqueId('link-')} />
        ) : (
          <ViberButton value={el.value} key={uniqueId('button-')} className="w-100">
            {el.text}
          </ViberButton>
        ),
      )}
    </ViberButtonContainer>
  ) : null;
};

export default ViberMessage;
