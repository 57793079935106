import React, { Fragment } from 'react';
import moment from 'moment';
import i18n from 'src/i18n';
import { ListElementModel } from 'src/redux/reducers/list-elements.reducer';
import { renderFileLink } from 'src/views/AuditLog/helpers/renderHelpers';

interface Props {
  document: ListElementModel;
}

const SavedDocument: React.FC<Props> = ({ document }) => {
  return (
    <Fragment>
      <p className="font-weight-bold">
        {i18n.t('auditLog.detailsOfCreatedDocument')}
      </p>

      <table className="table table-sm">
        <tbody>
          <tr>
            <td>{i18n.t('common.name')}</td>
            <td>{document.name}</td>
          </tr>
          <tr>
            <td>{i18n.t('common.created')}</td>
            <td>{moment(document.createdAt).format('LLL')}</td>
          </tr>
          <tr>
            <td>{i18n.t('foldersAndDocuments.shortDescription')}</td>
            <td>{document.shortDescription}</td>
          </tr>
          <tr>
            <td>{i18n.t('common.description')}</td>
            <td>{document.description}</td>
           
           
          </tr>
          <tr>
            <td>{i18n.t('foldersAndDocuments.fileAttachment')}</td>
            <td>{document.fileUrl && renderFileLink(document.fileUrl)}</td>
          </tr>
          <tr>
            <td>{i18n.t('foldersAndDocuments.imageAttachment')}</td>
            <td>{document.imageUrl && renderFileLink(document.imageUrl)}</td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

export default SavedDocument;
