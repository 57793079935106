import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  getTagCategories,
  getCategoryTags,
  deleteTagCategory,
} from 'src/redux/actions/employee.action';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { StateModel, TagCategoryModel } from 'src/shared/models';
import { ConfirmModal, Spinner, TagCategoryCard, TagCategoryModal } from 'src/shared/components';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  getTagCategories: Function;
  tagCategories: StateModel;
  getCategoryTags: Function;
  tags: StateModel;
  deleteTagCategory: Function;
  deleteState: StateModel;
  selectedTagCategory: TagCategoryModel | undefined;
  selectTagCategory: React.Dispatch<React.SetStateAction<TagCategoryModel | undefined>>;
}

const TagCategories: React.FC<Props> = ({
  application,
  getApplication,
  getTagCategories,
  tagCategories,
  getCategoryTags,
  tags,
  deleteTagCategory,
  deleteState,
  selectedTagCategory,
  selectTagCategory,
}) => {
  const { appId, tab } = useParams();
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [eventType, setEventType] = useState<string | undefined>(undefined);

  useEffect(() => {
    const { loaded, data } = tagCategories;
    if (loaded && data.length > 0 && !selectedTagCategory) {
      selectFirstCategory(appId);
    }
    // eslint-disable-next-line
  }, [tagCategories]);

  useEffect(() => {
    const { loaded, error } = deleteState;
    if (loaded && !error) {
      selectTagCategory(undefined);
      toggleConfirmModal();
      getTagCategories(appId);
    }
    // eslint-disable-next-line
  }, [deleteState]);

  const selectFirstCategory = (appId: number) => {
    if (tab !== 'tags') return;
    const { data } = tagCategories;

    selectTagCategory(data[0]);
    getCategoryTags(appId, data[0].id);
  };

  const confirmDelete = () => {
    if (selectedTagCategory) {
      deleteTagCategory(appId, selectedTagCategory.id);
    }
  };

  const toggleModal = (event?: string) => {
    if (event) {
      setEventType(event);
    }
    setModalOpen(!isModalOpen);
  };

  const toggleConfirmModal = () => {
    setConfirmModalOpen(!isConfirmModalOpen);
  };

  const handleOnCardClick = (tagCategory: TagCategoryModel) => {
    selectTagCategory(tagCategory);
    getCategoryTags(appId, tagCategory.id);
  };

  return (
    <Fragment>
      <Row className="mb-4">
        <Col className="d-flex justify-content-between align-items-center">
          <h6 className="m-0">{t('common.categories')}</h6>
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => toggleModal('create')}
          >
            {t('employee.newCategory')}
          </button>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex justify-content-center">
          <Spinner loading={tagCategories.loading} />
        </Col>
      </Row>

      {tagCategories.loaded &&
        tagCategories.data.map((tagCategory: TagCategoryModel, index: number) => {
          return (
            <TagCategoryCard
              application={application}
              getApplication={getApplication}
              key={tagCategory.id}
              index={index}
              isActive={selectedTagCategory?.id === tagCategory.id}
              tagCategory={tagCategory}
              selectTagCategory={selectTagCategory}
              toggleModal={toggleModal}
              toggleConfirmModal={toggleConfirmModal}
              handleOnClick={() => handleOnCardClick(tagCategory)}
            />
          );
        })}

      <TagCategoryModal
        getTagCategories={getTagCategories}
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        event={eventType}
        selectTagCategory={selectTagCategory}
        selectedTagCategory={selectedTagCategory}
      />

      <ConfirmModal
        title={t('employee.deleteCategory')}
        text={t('employee.deleteCategoryConfirmMessage')}
        item={selectedTagCategory && selectedTagCategory.name}
        cancel={toggleConfirmModal}
        confirm={confirmDelete}
        isLoading={deleteState.loading}
        isOpen={isConfirmModalOpen}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  tagCategories: state.tagCategories,
  tags: state.categoryTags,
  createState: state.createTagCategory,
  deleteState: state.deleteTagCategory,
});

const mapDispatchToProps = {
  getApplication,
  getTagCategories,
  getCategoryTags,
  deleteTagCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(TagCategories);
