import { EmployeeModel } from './employee.model';

export interface TicketMetaDataModel {
  answererUserId?: string;
  answer?: string;
  feedback?: string;
  type?: string;
  helpdeskGroupName?: string;
  helpdeskGroupId?: string;
  cause?: string;
  questionerEmployee?: EmployeeModel;
  answererEmployee?: EmployeeModel;
}

export interface TicketStatusModel {
  id: number;
  ticketId: number;
  status: string;
  metadata?: TicketMetaDataModel | null;
  feedback: string;
}

export interface TicketTimelineModel {
  id: number;
  ticketId: number;
  status: string;
  updatedAt: string;
  metadata?: TicketMetaDataModel | null;
}

export interface BaseTicketModel {
  questioner_user_id?: number;
  questionerEmployee: EmployeeModel;
  answererEmployee: EmployeeModel;
  message: string;
  key: string;
  metadata: Object;
  helpdeskGroup: string;
  ticketTimeline?: Array<TicketTimelineModel>;
  currentStatus?: TicketStatusModel;
}

export interface TicketModel extends BaseTicketModel {
  id: number;
  applicationId: number;
  createdAt: string;
}

export enum TicketFeedbackTypeModel {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
}

export enum TicketStatusTypeModel {
  ALL = 'ALL',
  DONE = 'DONE',
  WAITING = 'WAITING',
  ANSWERED = 'ANSWERED',
  DELIVERED_ANSWER = 'DELIVERED_ANSWER',
  CHANGED_ANSWERER = 'CHANGED_ANSWERER',
  MISSING_QUESTIONER = 'MISSING_QUESTIONER',
  MISSING_ANSWERER = 'MISSING_ANSWERER',
  CREATED = 'CREATED',
}

export interface TicketFilters {
  answererEmployeeKey?: string;
  questionerEmployeeKey?: string;
  status?: string;
  groups?: string;
  from?: string;
  to?: string;
}

export interface HelpdeskReportModel {
  'tickets total': number;
  'tickets delivered to answerer': number;
  'tickets delivered but not answered by answerer': number;
  'tickets answered and delivered to questioner': number;
  'tickets feedbacked as positive': number;
  'tickets feedbacked as negative': number;
}

export interface HelpdeskReportGraphModel {
  delivered: number;
  answered: number;
  average: number;
  deliveredNum: number;
  answeredNum: number;
  total: number;
}
