import { Dispatch } from 'redux';

import {
    getIssueCategories as getIssueCategoriesService,
    postIssueCategory as postIssueCategoryService,
    patchIssueCategory as patchIssueCategoryService
} from 'src/redux/services/issue-categories.service';
import { CreateIssueCategoryRequest } from 'src/shared/models/issue-categories.model';

export const GET_ISSUE_CATEGORIES = 'issue/GET_ISSUE_CATEGORIES';
export const GET_ISSUE_CATEGORIES_SUCCESS = 'issue/GET_ISSUE_CATEGORIES_SUCCESS';
export const GET_ISSUE_CATEGORIES_ERROR = 'issue/GET_ISSUE_CATEGORIES_ERROR';

export const CREATE_ISSUE_CATEGORIES = 'issue/CREATE_ISSUE_CATEGORIES';
export const CREATE_ISSUE_CATEGORIES_SUCCESS = 'issue/CREATE_ISSUE_CATEGORIES_SUCCESS';
export const CREATE_ISSUE_CATEGORIES_ERROR = 'issue/CREATE_ISSUE_CATEGORIES_ERROR';

export const REVERT_LANGUAGE_SETTINGS = 'language-settings/CLEAR_LANGUAGE_SETTINGS';

export const getIssueCategories = (appId: string) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: GET_ISSUE_CATEGORIES,
        });

        let languageSettings = await getIssueCategoriesService(appId);
        try {
            dispatch({
                type: GET_ISSUE_CATEGORIES_SUCCESS,
                payload: languageSettings,
            });
        } catch (error) {
            dispatch({
                type: GET_ISSUE_CATEGORIES_ERROR,
                error,
            });
        }
    };
};

export const createIssueCategory = (appId: string, data: CreateIssueCategoryRequest) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: CREATE_ISSUE_CATEGORIES,
        });

        try {
            dispatch({
                type: CREATE_ISSUE_CATEGORIES_SUCCESS,
                payload: await postIssueCategoryService(appId, data),
            });
        } catch (error) {
            dispatch({
                type: CREATE_ISSUE_CATEGORIES_ERROR,
                error,
            });
        }
    };
};

export const updateIssueCategory = (appId: string, data: CreateIssueCategoryRequest) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: CREATE_ISSUE_CATEGORIES,
        });

        try {
            dispatch({
                type: CREATE_ISSUE_CATEGORIES_SUCCESS,
                payload: await patchIssueCategoryService(appId, data),
            });
            console.log(updateIssueCategory);
        } catch (error) {
            dispatch({
                type: CREATE_ISSUE_CATEGORIES_ERROR,
                error,
            });
            console.log(error);
        }
    };
};

export const revertLanguageSettings = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: REVERT_LANGUAGE_SETTINGS,
        });
    };
};
