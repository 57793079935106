import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ListGroup, ListGroupItem } from 'reactstrap';

import { platform } from 'src/shared/constants/platforms.constants';
import { generateId } from 'src/shared/utils/generate-id.util';
import { ApplicationSettingModel } from 'src/redux/actions/application-settings.action';
import { getApplicationSettings } from 'src/redux/services/application-settings.service';

import messengerLogo from 'src/assets/img/platforms/messenger.svg';
import chatbossLogo from 'src/assets/img/brand/chatboss-3.svg';
import viberLogo from 'src/assets/img/platforms/viber.svg';

import CopyToClipboardButton from 'src/shared/components/CopyToClipboardButton';
import GoToSettingsButton from 'src/shared/components/GoToSettingsButton';

import styles from '../Positions.module.scss';

interface Props {
  positionSlug: string;
  platforms: string[];
  facebookPageId: string;
}

const PositionUrls: React.FC<Props> = ({ positionSlug, platforms, facebookPageId }) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [appSettings, setAppSettings] = useState<ApplicationSettingModel[]>([]);

  useEffect(() => {
    if (!platforms.length) return;

    const getAppSettings = async () => {
      const allAppSettings: ApplicationSettingModel[] = [];

      await Promise.all(
        platforms.map(async () => {
          const settings: ApplicationSettingModel[] = await getApplicationSettings(appId);
          if (settings.length > 0) {
            allAppSettings.push(...settings);
          }
        }),
      );

      setAppSettings(allAppSettings);
    };

    getAppSettings();
  }, [platforms, appId]);

  const getSettingValueByKey = (settingKey: string) => {
    if (!appSettings.length) return undefined;
    return appSettings.filter(s => s.key === settingKey)[0]?.value || undefined;
  };

  const getFacebookUrl = () => {
    const facebookPageIdFromSettings = getSettingValueByKey('faecbook_page_id');
    const hasFacebookId = facebookPageIdFromSettings || facebookPageId;

    return {
      logo: messengerLogo,
      url:
        hasFacebookId &&
        `https://m.me/${facebookPageIdFromSettings || facebookPageId}?ref=${positionSlug}`,
      error: !hasFacebookId && t('errors.missingSetting', { setting: 'faecbook_page_id' }),
    };
  };

  const getLiveChatUrl = () => {
    const liveChatDomain = getSettingValueByKey('livechat_chatbot_domain');

    return {
      logo: chatbossLogo,
      url: liveChatDomain && `${liveChatDomain}?ref=${positionSlug}`,
      error: !liveChatDomain && t('errors.missingSetting', { setting: 'livechat_chatbot_domain' }),
    };
  };

  const getViberUrl = () => {
    const viberUri = getSettingValueByKey('viber_chatbot_uri');

    return {
      logo: viberLogo,
      url: viberUri && `viber://pa?chatURI=${viberUri}?text=${positionSlug}`,
      error: !viberUri && t('errors.missingSetting', { setting: 'viber_chatbot_uri' }),
    };
  };

  const getPositionUrls = () => {
    if (!platforms.length || !appSettings.length) return [];

    const positionUrlDetails: any[] = [];

    platforms.forEach(p => {
      switch (p) {
        case platform.Facebook:
          return positionUrlDetails.push(getFacebookUrl());
        case platform.LiveChat:
          return positionUrlDetails.push(getLiveChatUrl());
        case platform.Viber:
          return positionUrlDetails.push(getViberUrl());
        default:
          return null;
      }
    });

    return positionUrlDetails;
  };

  const renderPositionUrls = () => {
    return getPositionUrls().map(el => {
      const id = generateId(5);

      return (
        <ListGroupItem className={styles.positionUrlCard} key={id}>
          <img src={el.logo} alt="Platform logo" />
          {el.url && (
            <div className={styles.urlContent}>
              <a href={el.url} target="_blank" rel="noopener noreferrer">
                {el.url}
              </a>
              <CopyToClipboardButton id={id} valueToCopy={el.url} tooltipPlacement="top" />
            </div>
          )}

          {el.error && (
            <div className={styles.urlContent}>
              <span className="text-danger">{el.error}</span>
              <GoToSettingsButton id={id} appId={appId} />
            </div>
          )}
        </ListGroupItem>
      );
    });
  };

  return <Fragment>{positionSlug && <ListGroup>{renderPositionUrls()}</ListGroup>}</Fragment>;
};

export default PositionUrls;
