import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormikContext, FieldArray, ArrayHelpers } from 'formik';
import uniqueId from 'lodash/uniqueId';
import { Col, FormGroup } from 'reactstrap';

import ReactSelect from 'src/shared/components/form-inputs/ReactSelect';
import { SelectModel } from 'src/shared/models/common.model';
import PlusLabel from './PlusLabel';

import styles from '../../../Questionnaire.module.scss';

interface RegexReplacerProps {
  showCreateRegexReplacerPopover: boolean;
  replacerOptions: SelectModel[];
}

const RegexReplacer: FC<RegexReplacerProps> = ({
  showCreateRegexReplacerPopover,
  replacerOptions,
}) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<{
    regexReplacers: SelectModel[];
  }>();

  const addReplacer = (arrayHelpers: ArrayHelpers) => {
    arrayHelpers.push({ label: '', value: '', id: uniqueId('regexReplacer-') });
  };

  const removeReplacer = (arrayHelpers: ArrayHelpers, index: number) => {
    arrayHelpers.remove(index);
  };

  return (
    <FieldArray
      name="regexReplacers"
      render={arrayHelpers => (
        <>
          <Col lg={6}>
            <FormGroup>
              <PlusLabel
                id="createRegexReplacerPopover"
                labelFor="regexReplacer.value"
                title={t('questionnaire.regexReplacer')}
                hidePlusButton={!showCreateRegexReplacerPopover}
              />

              {values.regexReplacers &&
                values.regexReplacers.map((item, index) => (
                  <div className="d-flex flex-row mb-3" key={item.id}>
                    <ReactSelect
                      className={styles.select}
                      name={`regexReplacers[${index}].value`}
                      options={replacerOptions}
                      isClearable
                    />

                    <div>
                      <button
                        type="button"
                        className={classNames('btn btn-danger ml-1', styles.removeBtn)}
                        onClick={() => removeReplacer(arrayHelpers, index)}
                      >
                        <i className="far fa-trash-alt" />
                      </button>
                    </div>
                  </div>
                ))}

              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary ml-1"
                  onClick={() => addReplacer(arrayHelpers)}
                >
                  <span className="mr-2">{t('questionnaire.addNewRegexReplacer')}</span>
                  <i className="fas fa-plus" />
                </button>
              </div>
            </FormGroup>
          </Col>
        </>
      )}
    />
  );
};

export default RegexReplacer;
