import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Alert } from 'reactstrap';
import { ResponsiveLine } from '@nivo/line';

import Spinner from '../../../../shared/components/Spinner';

interface Props {
  data: any;
  conversationSum: number;
  messageSum: number;
}

const DailyCountOfConversationsAndMessages: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { conversationSum, messageSum } = props;
  const { loading, loaded, data } = props.data;

  const mapData = (data: any) => {
    return data.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
  };

  return (
    <Row>
      <Col>
        <h5>{t('usageStatistics.conversationsAndMessages')}</h5>

        {loading && (
          <div className="d-flex justify-content-center">
            <Spinner loading={loading} />
          </div>
        )}

        {loaded && !conversationSum && !messageSum ? (
          <Alert color="light" className="text-center mb-0">
            {t('usageStatistics.noDataForPeriod')}
          </Alert>
        ) : null}

        {loaded && conversationSum > 0 && messageSum > 0 ? (
          <div className="chart-container">
            <ResponsiveLine
              data={mapData(data)}
              curve="monotoneX"
              margin={{ top: 40, right: 210, bottom: 70, left: 60 }}
              enablePointLabel
              axisBottom={{
                orient: 'bottom',
                tickSize: 0,
                tickPadding: 10,
                tickRotation: -45,
              }}
              axisLeft={{
                orient: 'left',
                tickSize: 0,
                tickPadding: 10,
                tickRotation: 0,
                legend: 'Count',
                legendOffset: -45,
                legendPosition: 'middle',
              }}
              colors={[
                'rgba(31, 119, 180, 1)',
                'rgba(31, 119, 180, 0.5)',
                'rgba(255, 127, 14, 1)',
                'rgba(255, 127, 14, 0.5)',
                'rgba(44, 160, 44, 1)',
                'rgba(44, 160, 44, 0.5)',
                'rgba(214, 39, 40, 1)',
                'rgba(214, 39, 40, 0.5)',
                'rgba(148, 103, 189, 1)',
                'rgba(148, 103, 189, 0.5)',
                'rgba(140, 86, 75, 1)',
                'rgba(140, 86, 75, 0.5)',
                'rgba(227, 119, 194, 1)',
                'rgba(227, 119, 194, 0.5)',
                'rgba(127, 127, 127, 1)',
                'rgba(127, 127, 127, 0.5)',
                'rgba(188, 189, 34, 1)',
                'rgba(188, 189, 34, 0.5)',
                'rgba(23, 190, 207, 1)',
                'rgba(23, 190, 207, 0.5)',
                'rgba(27, 158, 119, 1)',
                'rgba(27, 158, 119, 0.5)',
                'rgba(217, 95, 2, 1)',
                'rgba(217, 95, 2, 0.5)',
              ]}
              pointSize={5}
              pointColor={{ theme: 'background' }}
              pointBorderWidth={5}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabel="y"
              pointLabelYOffset={-12}
              useMesh
              legends={[
                {
                  anchor: 'top-right',
                  direction: 'column',
                  justify: false,
                  translateX: 220,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: 'left-to-right',
                  itemWidth: 200,
                  itemHeight: 24,
                  itemOpacity: 0.75,
                  symbolSize: 11,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        ) : null}
      </Col>
    </Row>
  );
};

export default DailyCountOfConversationsAndMessages;
