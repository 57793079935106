import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';

import { HelpdeskGroupUserModel } from 'src/shared/models';
import { getMembersForGroup } from 'src/redux/services/helpdeskGroup.service';

interface Props {
  isOpen: boolean;
  id: number;
  toggleModalState: Function;
}

const HelpdeskGroupMembers: React.FC<Props> = ({ isOpen, id, toggleModalState }) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [users, setUsers] = useState<HelpdeskGroupUserModel[]>([]);

  useEffect(() => {
    if (id > 0) {
      getMembersForHelpdeskGroup();
    }
    /* eslint-disable-next-line */
  }, [id]);

  const getMembersForHelpdeskGroup = async () => {
    const members = await getMembersForGroup(appId, id);
    setUsers(members.content);
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{t('helpdesk.members')}</ModalHeader>
      <ModalBody>
        <Table responsive striped hover size="sm">
          <tbody>
            {users.map(item => (
              <tr key={item.userId}>
                <td>{item.employee.attributes?.map(attribute => attribute.value).join(', ')}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => toggleModalState(false)}>
          {t('common.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HelpdeskGroupMembers);
