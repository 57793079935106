// src/redux/actions/auth.action.ts

import { Dispatch } from 'redux';
import { clearStore } from './store.action';
import { login as loginService, LoginCredentials, idToken, IdTokenAuthCredentials, AuthCredentials } from '../services/auth.service';
import { removeToken, setToken } from '../services/token.service';

export const AUTH_LOGIN_REQUEST = 'auth/AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'auth/AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'auth/AUTH_LOGIN_ERROR';
export const AUTH_ID_TOKEN_REQUEST = 'auth/AUTH_ID_TOKEN_REQUEST';
export const AUTH_ID_TOKEN_SUCCESS = 'auth/AUTH_ID_TOKEN_SUCCESS';
export const AUTH_ID_TOKEN_ERROR = 'auth/AUTH_ID_TOKEN_ERROR';
export const AUTH_LOGOUT = 'auth/LOGOUT';

export const login = (credentials: AuthCredentials) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AUTH_LOGIN_REQUEST,
    });

    try {
      const response = ('email' in credentials) 
      ? await loginService(credentials as LoginCredentials) 
      : await idToken(credentials as IdTokenAuthCredentials);

      dispatch({
        type: AUTH_LOGIN_SUCCESS,
        payload: response,
      });

      setToken(response.token);

      return response;
    } catch (error) {
      dispatch({
        type: AUTH_LOGIN_ERROR,
        error,
      });
    }
  };
};

export const logout = (): any => {
  return (dispatch: Dispatch) => {
    removeToken();
    dispatch(clearStore());
    localStorage.clear();

    dispatch({
      type: AUTH_LOGOUT,
    });

    window.location.hash = '#/login';
  };
};
