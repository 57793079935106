import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { ResponsiveLine } from '@nivo/line';

interface Props {
  bodyHeader: any;
  bodySubHeader: any;
  chartData: any;
  tickValue: any;
}

const ApplicationTrendsCard: React.FC<Props> = (props: Props) => {
  const bodyHeader = props.bodyHeader;
  const { bodySubHeader, chartData, tickValue } = props;
  const theme = {
    background: 'transparent',
    axis: {
      fontSize: '14px',
      tickColor: '#999',
      ticks: {
        line: {
          stroke: '#fff',
        },
        text: {
          fill: '#fff',
        },
      },
      legend: {
        text: {
          fill: '#fff',
        },
      },
    },
  };


    return (
      <Card
            className="text-white bg-info">
        <CardBody className="pb-0">
          <h4 className="mb-0">{bodyHeader}</h4>
          <small className="text-uppercase font-weight-bold">{bodySubHeader}</small>
        </CardBody>
        <div className="chart-wrapper text-dark px-3">
          {chartData && chartData[0].data ? (
            <ResponsiveLine
              key={chartData}
              data={chartData}
              curve="basis"
              margin={{ top: 10, right: 30, bottom: 30, left: 35 }}
              axisTop={null}
              axisRight={null}
              axisBottom={{ legend: '', legendOffset: 12, tickValues: 5, tickPadding: 10 }}
              axisLeft={{
                legend: `${bodySubHeader.split(' ').slice(1, 2)}`,
                legendPosition: 'middle',
                legendOffset: 10,
                tickValues: tickValue,
                orient: 'left',
              }}
              theme={theme}
              colors="#d5e8f2"
              enableArea
              enableSlices="x"
              enablePoints={false}
              enableGridX={false}
              enableGridY={false}
              pointSize={8}
              pointBorderWidth={1}
              pointLabelYOffset={22}
              useMesh={true}
              enableCrosshair={false}
            />
          ) : null}
        </div>
      </Card>
    );
};

export default ApplicationTrendsCard;
