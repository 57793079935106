// MicrosoftLoginButton.tsx
import React from 'react';
import { useMsal } from "@azure/msal-react";
import { Button } from 'reactstrap';

interface Props {
  onLogin: (token: string) => void;
}

const MicrosoftLoginButton: React.FC<Props> = ({ onLogin }) => {
  const { instance } = useMsal();

  const handleMicrosoftLogin = () => {
    instance.loginPopup().then((response) => {
      onLogin(response.idToken);
    }).catch(e => {
      console.error(e);
    });
  }

  return (
    <Button 
      className="microsoft-login-btn" 
      block 
      color="secondary" 
      onClick={handleMicrosoftLogin}
    >
      Log In with Microsoft
    </Button>
  );
}

export default MicrosoftLoginButton;