import { Dispatch } from 'redux';
import {
  getComplaints as getComplaintsService,
  exportComplaints as exportComplaintsService,
  clearComplaints as clearComplaintsService,
} from '../services/complaints.service';
import { GeneralQueryParams } from '../../shared/models/query-params.model';

export const GET_COMPLAINTS_REQUEST = 'complaints/GET_COMPLAINTS_REQUEST';
export const GET_COMPLAINTS_SUCCESS = 'complaints/GET_COMPLAINTS_SUCCESS';
export const GET_COMPLAINTS_ERROR = 'complaints/GET_COMPLAINTS_ERROR';

export const EXPORT_COMPLAINTS_REQUEST = 'complaints/EXPORT_COMPLAINTS_REQUEST';
export const EXPORT_COMPLAINTS_SUCCESS = 'complaints/EXPORT_COMPLAINTS_SUCCESS';
export const EXPORT_COMPLAINTS_ERROR = 'complaints/EXPORT_COMPLAINTS_ERROR';

export const CLEAR_COMPLAINTS_REQUEST = 'complaints/CLEAR_COMPLAINTS_REQUEST';
export const CLEAR_COMPLAINTS_SUCCESS = 'complaints/CLEAR_COMPLAINTS_SUCCESS';
export const CLEAR_COMPLAINTS_ERROR = 'complaints/CLEAR_COMPLAINTS_ERROR';

export const getComplaints = (params: GeneralQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_COMPLAINTS_REQUEST,
    });

    const payload = await getComplaintsService(params);

    try {
      dispatch({
        type: GET_COMPLAINTS_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: GET_COMPLAINTS_ERROR,
        error,
      });
    }
  };
};

export const exportComplaints = (params: GeneralQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EXPORT_COMPLAINTS_REQUEST,
    });

    const payload = await exportComplaintsService(params);

    try {
      dispatch({
        type: EXPORT_COMPLAINTS_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: EXPORT_COMPLAINTS_ERROR,
        error,
      });
    }
  };
};

export const clearComplaints = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_COMPLAINTS_REQUEST,
    });

    const payload = await clearComplaintsService();

    try {
      dispatch({
        type: CLEAR_COMPLAINTS_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: CLEAR_COMPLAINTS_ERROR,
        error,
      });
    }
  };
};
