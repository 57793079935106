import React, { useEffect, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Button } from 'reactstrap';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { EmployeeTags, List } from 'src/shared/components';
import { HelpdeskGroupModel, Operations, QueryParams, StateModel } from 'src/shared/models';
import {
  deleteHelpdeskGroup,
  getHelpdeskGroups,
  removeHelpdeskGroupTag,
  addTagToHelpdeskGroup,
} from 'src/redux/actions/helpdesk-groups.action';
import { removeHelpdeskGroupTag as removeHelpdeskGroupTagService } from 'src/redux/services/helpdeskGroup.service';
import { getTagCategories } from 'src/redux/actions/employee.action';
import HelpdeskGroupModalForm from './forms/HelpdeskGroupModalForm';
import { uniqueId } from 'lodash';
import HelpdeskGroupMembers from './forms/HelpdeskGroupMembers';
import { configurationConstants } from 'src/shared/constants/configuration.constants';
import { getListFieldsByConfiguration } from 'src/shared/utils/configuration.util';

interface IHelpdeskGroupsProps {
  application: ApplicationModel;
  getApplication: Function;
  getHelpdeskGroups: Function;
  getTagCategories: Function;
  addTagToHelpdeskGroup: Function;
  removeHelpdeskGroupTag: Function;
  deleteHelpdeskGroup: Function;
  helpdeskGroups: HelpdeskGroupModel[];
  numberOfHelpdeskGroups: number;
  helpdeskGroupsLoading: boolean;
  helpdeskGroupsLoaded: boolean;
  helpdeskGroupsError: boolean;
  tagCategories: StateModel;
  removeTagState: StateModel;
  addTagState: StateModel;
}

const HelpdeskGroups: FC<IHelpdeskGroupsProps> = ({
  application,
  getApplication,
  getHelpdeskGroups,
  getTagCategories,
  addTagToHelpdeskGroup,
  removeHelpdeskGroupTag,
  deleteHelpdeskGroup,
  helpdeskGroups,
  numberOfHelpdeskGroups,
  helpdeskGroupsLoading,
  helpdeskGroupsLoaded,
  helpdeskGroupsError,
  tagCategories,
  removeTagState,
  addTagState,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [isMembersModalOpen, setMembersModalOpen] = useState<boolean>(false);
  const [membersModalMemberId, setMembersModalMemberId] = useState<number>(0);
  const [editDocument, setEditDocument] = useState<HelpdeskGroupModel>();
  const [queryParamsDef, setQueryParamsDef] = useState<QueryParams>({
    page: 0,
    size: 20,
    limit: 20,
  });

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    const { loaded, error } = removeTagState;
    if (loaded && !error) {
      refreshHelpdeskGroups();
    }

    /* eslint-disable-next-line */
  }, [removeTagState]);

  useEffect(() => {
    const { loaded, error } = addTagState;
    if (loaded && !error) {
      refreshHelpdeskGroups();
    }

    /* eslint-disable-next-line */
  }, [addTagState]);

  const configuredFields = getListFieldsByConfiguration(
    application,
    configurationConstants.helpdeskGroupsList,
    t,
  );

  const refreshHelpdeskGroups = () => {
    getHelpdeskGroups(appId, queryParamsDef);
  };

  const fetchHelpdeskGroups = (queryParams: QueryParams) => {
    setQueryParamsDef(queryParams);
    getHelpdeskGroups(appId, queryParams);
  };

  const removeHelpdeskGroup = async (group?: HelpdeskGroupModel) => {
    if (!group) {
      return;
    }
    await deleteHelpdeskGroup(appId, group.id);
  };

  const removeExistingTagAndAssignNew = async (
    applicationId: string,
    helpdeskGroupId: string,
    tagIds: string[],
  ) => {
    const existingGroup = helpdeskGroups.find(group => `${group.id}` === `${helpdeskGroupId}`);
    if (existingGroup?.employeeTags?.length) {
      await removeHelpdeskGroupTagService(
        applicationId,
        helpdeskGroupId,
        existingGroup.employeeTags[0].id,
      );
    }
    addTagToHelpdeskGroup(applicationId, helpdeskGroupId, tagIds);
  };

  const editHelpdeskGroup = async (id: React.ReactText) => {
    setEditDocument(helpdeskGroups.find(e => e.id === id));
    setEditModalOpen(true);
  };

  const showMembers = (id: number) => {
    setMembersModalMemberId(id);
    setMembersModalOpen(!isMembersModalOpen);
  };

  return (
    <div className="animated fadeIn">
      <List
        useCardContent
        data={helpdeskGroups}
        totalElements={numberOfHelpdeskGroups}
        loaded={helpdeskGroupsLoaded}
        loading={helpdeskGroupsLoading}
        error={helpdeskGroupsError}
        fetchData={fetchHelpdeskGroups}
        title={t('helpdesk.groups')}
        defaultItemOnPage={20}
        editFunction={editHelpdeskGroup}
        createPermission="helpdesk_group_create"
        createModal={(isModalOpen, setIsModalOpen) => (
          <HelpdeskGroupModalForm
            isOpen={isModalOpen}
            toggleModalState={setIsModalOpen}
            onSaved={refreshHelpdeskGroups}
          />
        )}
        fields={[
          { key: 'id', label: t('common.id') },
          { key: 'name', label: t('common.name') },
          {
            key: 'isDefault',
            label: t('helpdesk.default'),
            render: ({ isDefault }) => (isDefault ? t('common.yes') : t('common.no')),
          },
          {
            key: 'employeeTags',
            label: t('surveys.tags'),
            render: (group, index) => (
              <EmployeeTags
                employeeTags={group.employeeTags || []}
                removeTag={(tagId: string) => removeHelpdeskGroupTag(appId, `${group?.id}`, tagId)}
                removePermission="employee_employee-tag-assign_delete"
                createPermission="employee_employee-tag-assign_create"
                index={index}
                singleSelect
                helpdeskGroup={group}
                getTagCategories={getTagCategories}
                tagCategories={tagCategories}
                addTagToEntity={removeExistingTagAndAssignNew}
              />
            ),
          },
          ...configuredFields,
          {
            key: 'members',
            label: t('helpdesk.members'),
            render: ({ id }) => (
              <Button
                key={uniqueId(`${id}`)}
                className="btn btn-secondary btn-sm"
                onClick={() => showMembers(id)}
              >
                <i className="fas fa-list" />
              </Button>
            ),
          },
          {
            key: 'operations',
            label: t('common.operations'),
            operations: [Operations.UPDATE, Operations.DELETE],
          },
        ]}
        noDataLabel={t('helpdesk.noGroups')}
        deleteFunction={removeHelpdeskGroup}
        deleteTitle={t('helpdesk.deleteGroup')}
        deleteText={t('helpdesk.deleteGroupConfirmMessage')}
        updatePermission="helpdesk_group_update"
        deletePermission="helpdesk_group_delete"
      />
      <HelpdeskGroupModalForm
        editDocument={editDocument}
        isOpen={isEditModalOpen}
        toggleModalState={setEditModalOpen}
        onSaved={refreshHelpdeskGroups}
      />
      <HelpdeskGroupMembers
        toggleModalState={setMembersModalOpen}
        isOpen={isMembersModalOpen}
        id={membersModalMemberId}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    helpdeskGroups: state.helpdeskGroups.data.content,
    numberOfHelpdeskGroups: state.helpdeskGroups.data.totalElements,
    helpdeskGroupsLoading: state.helpdeskGroups.loading,
    helpdeskGroupsLoaded: state.helpdeskGroups.loaded,
    helpdeskGroupsError: state.helpdeskGroups.error,
    tagCategories: state.tagCategories,
    removeTagState: state.removeHelpdeskGroupTag,
    addTagState: state.addTagToHelpdeskGroup,
  };
};

const mapDispatchToProps = {
  getApplication,
  getHelpdeskGroups,
  getTagCategories,
  addTagToHelpdeskGroup,
  removeHelpdeskGroupTag,
  deleteHelpdeskGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpdeskGroups);
