import React from 'react';
import { Button } from 'reactstrap';

import { ExpressionModel } from 'src/shared/models/questionnaire.model';
import CheckPermission from 'src/shared/components/CheckPermission';
import EditableTextInput from 'src/shared/components/form-inputs/EditableTextInput';

interface Props {
  expressions: ExpressionModel[];
  selectExpression: (expression: ExpressionModel) => void;
  toggleConfirmModal: (isOpen: boolean) => void;
  updateExpression: Function;
}

const ExpressionsTableBody: React.FC<Props> = ({
  expressions,
  selectExpression,
  toggleConfirmModal,
  updateExpression,
}) => {
  return (
    <tbody>
      {expressions.map(expression => (
        <tr key={expression.id}>
          <td className="name-column">
            <EditableTextInput
              id={expression.id!}
              data={expression.title}
              updateData={(appId: string, id: number, value: string) =>
                updateExpression(id, {
                  title: value,
                  expression: expression.expression,
                })
              }
              permission="expression_update"
            />
          </td>
          <td className="name-column">
            <EditableTextInput
              id={expression.id!}
              data={expression.expression}
              updateData={(appId: string, id: number, value: string) =>
                updateExpression(id, {
                  title: expression.title,
                  expression: value,
                })
              }
              permission="expression_update"
            />
          </td>

          <td className="operations-column">
            <CheckPermission variant="enableIf" permissions={['expression_delete']}>
              <Button
                size="sm"
                color="danger"
                className="ml-2"
                onClick={() => {
                  selectExpression(expression);
                  toggleConfirmModal(true);
                }}
              >
                <i className="fas fa-trash-alt" />
              </Button>
            </CheckPermission>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default ExpressionsTableBody;
