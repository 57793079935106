import React, { FC, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

import {
  baseStyle,
  activeStyle,
  acceptStyle,
  rejectStyle,
} from 'src/shared/constants/dropzone.constants';
import { parseFileExtension } from 'src/shared/utils/parse.util';
import { renderSelectedFile } from 'src/shared/utils/misc.util';
import { Extensions } from 'src/shared/models/common.model';

export interface DragAndDropProps {
  setFile: Function;
  setFileError: Function;
  fileError: any;
  file: any;
  acceptedExt: Extensions;
}

const DragAndDrop: FC<DragAndDropProps> = ({
  setFile,
  setFileError,
  file,
  fileError,
  acceptedExt,
}) => {
  const [fileExt, setFileExt] = useState<string>('');
  const { t } = useTranslation();

  const getErrorMessage = () => {
    switch (acceptedExt) {
      case Extensions.CSV: {
        return 'errors.mustBeCsv';
      }
      case Extensions.JSON: {
        return 'errors.mustBeJSON';
      }
      case Extensions.XLSX: {
        return 'errors.mustBeXlsx';
      }
      default: {
        return 'errors.mustBeCsv';
      }
    }
  };

  const onDrop = useCallback(acceptedFiles => {
    if (!acceptedFiles.length) return;
    setFile(undefined);
    setFileError(undefined);

    const ext = parseFileExtension(acceptedFiles[0].name);
    setFileExt(ext);

    if (ext && ext !== acceptedExt) {
      setFileError(t(getErrorMessage()));
      return;
    }

    setFile(acceptedFiles[0]);
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line
    [isDragActive, isDragReject],
  );

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {!fileError && !file && (
        <div>
          <p className="m-0">
            {isDragActive
              ? t('common.dragAndDropActiveDragInstruction')
              : t('common.dragAndDropInstruction')}
          </p>
        </div>
      )}

      {fileError && <p className="text-danger mb-0">{t(fileError)}</p>}
      {file && renderSelectedFile(file, fileExt)}
    </div>
  );
};

export default DragAndDrop;
