import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Button } from 'reactstrap';

import { CustomFieldModel } from 'src/shared/models/custom-fields.model';

import CheckPermission from 'src/shared/components/CheckPermission';

import styles from '../CustomFields.module.scss';

interface Props {
  customFields: CustomFieldModel[];
  toggleModal: Function;
}

const CustomFieldsTable: React.FC<Props> = ({ customFields, toggleModal }) => {
  const { t } = useTranslation();

  return (
    <Table className={styles.customFieldsTable} size="sm" striped hover responsive>
      <thead>
        <tr>
          <th className={styles.key}>{t('common.key')}</th>
          <th className={styles.title}>{t('common.title')}</th>
          <th className={styles.namespace}>{t('common.namespace')}</th>
          <th className={styles.operations}>{t('common.operations')}</th>
        </tr>
      </thead>
      <tbody>
        {customFields.map(customField => (
          <tr key={customField.id}>
            <td className={styles.key}>{customField.key}</td>
            <td className={styles.title}>{customField.title}</td>
            <td className={styles.namespace}>{customField.namespace}</td>
            <td className={styles.operations}>
              <CheckPermission variant="enableIf" permissions={['application_custom-field_update']}>
                <Button type="button" size="sm" onClick={() => toggleModal('config', customField)}>
                  <i className="fas fa-cog" />
                </Button>
              </CheckPermission>

              <CheckPermission variant="enableIf" permissions={['application_custom-field_update']}>
                <Button
                  type="button"
                  size="sm"
                  className="ml-2"
                  onClick={() => toggleModal('edit', customField)}
                >
                  <i className="far fa-edit" />
                </Button>
              </CheckPermission>

              <CheckPermission variant="enableIf" permissions={['application_custom-field_delete']}>
                <Button
                  type="button"
                  size="sm"
                  color="danger"
                  className="ml-2"
                  onClick={() => toggleModal('confirm', customField)}
                >
                  <i className="far fa-trash-alt" />
                </Button>
              </CheckPermission>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CustomFieldsTable;
