import { Dispatch } from 'redux';

import { PageQueryParams } from 'src/shared/models';
import { FollowUpNotificationsCreateRequest } from 'src/shared/models/follow-up-notifications.model';

import {
  getList as getFollowUpNotificationsService,
  create as createFollowUpNotificationService,
  update as updateFollowUpNotificationService,
  deleteOne as deleteFollowUpNotificationService,
} from '../services/follow-up-notifications.service';

export const GET_FOLLOW_UP_NOTIFICATIONS = 'GET_FOLLOW_UP_NOTIFICATIONS';
export const GET_FOLLOW_UP_NOTIFICATIONS_SUCCESS = 'GET_FOLLOW_UP_NOTIFICATIONS_SUCCESS';
export const GET_FOLLOW_UP_NOTIFICATIONS_ERROR = 'GET_FOLLOW_UP_NOTIFICATIONS_ERROR';

export const DELETE_FOLLOW_UP_NOTIFICATION = 'DELETE_FOLLOW_UP_NOTIFICATION';
export const DELETE_FOLLOW_UP_NOTIFICATION_REQUEST = 'DELETE_FOLLOW_UP_NOTIFICATION_REQUEST';
export const DELETE_FOLLOW_UP_NOTIFICATION_SUCCESS = 'DELETE_FOLLOW_UP_NOTIFICATION_SUCCESS';
export const DELETE_FOLLOW_UP_NOTIFICATION_ERROR = 'DELETE_FOLLOW_UP_NOTIFICATION_ERROR';

export const CREATE_FOLLOW_UP_NOTIFICATION =
  'FOLLOW_UP_NOTIFICATIONS/CREATE_FOLLOW_UP_NOTIFICATION';
export const CREATE_FOLLOW_UP_NOTIFICATION_SUCCESS =
  'FOLLOW_UP_NOTIFICATIONS/CREATE_FOLLOW_UP_NOTIFICATION_SUCCESS';
export const CREATE_FOLLOW_UP_NOTIFICATION_ERROR =
  'FOLLOW_UP_NOTIFICATIONS/CREATE_FOLLOW_UP_NOTIFICATION_ERROR';

export const UPDATE_FOLLOW_UP_NOTIFICATION =
  'FOLLOW_UP_NOTIFICATIONS/UPDATE_FOLLOW_UP_NOTIFICATION';
export const UPDATE_FOLLOW_UP_NOTIFICATION_SUCCESS =
  'FOLLOW_UP_NOTIFICATIONS/UPDATE_FOLLOW_UP_NOTIFICATION_SUCCESS';
export const UPDATE_FOLLOW_UP_NOTIFICATION_ERROR =
  'FOLLOW_UP_NOTIFICATIONS/UPDATE_FOLLOW_UP_NOTIFICATION_ERROR';

export const REVERT_FOLLOW_UP_NOTIFICATION = 'FOLLOW_UP_NOTIFICATIONS/CLEAR_FOLLOW_UP_NOTIFICATION';

export const getFollowUpNotifications = (appId: string, queryParams: PageQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_FOLLOW_UP_NOTIFICATIONS,
    });

    try {
      dispatch({
        type: GET_FOLLOW_UP_NOTIFICATIONS_SUCCESS,
        payload: await getFollowUpNotificationsService(appId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_FOLLOW_UP_NOTIFICATIONS_ERROR,
        error,
      });
    }
  };
};

export const deleteFollowUpNotification = (appId: string, followUpNotificationId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_FOLLOW_UP_NOTIFICATION,
    });

    try {
      await deleteFollowUpNotificationService(appId, followUpNotificationId);
      dispatch({
        type: DELETE_FOLLOW_UP_NOTIFICATION_SUCCESS,
        payload: { followUpNotificationId },
      });
    } catch (error) {
      dispatch({
        type: DELETE_FOLLOW_UP_NOTIFICATION_ERROR,
        error,
      });
    }
  };
};

export const createFollowUpNotification = (
  appId: string,
  followUpNotification: FollowUpNotificationsCreateRequest,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_FOLLOW_UP_NOTIFICATION,
    });

    const response = await createFollowUpNotificationService(appId, followUpNotification);

    try {
      dispatch({
        type: CREATE_FOLLOW_UP_NOTIFICATION_SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch({
        type: CREATE_FOLLOW_UP_NOTIFICATION_ERROR,
        error,
      });
    }
  };
};

export const updateFollowUpNotification = (
  appId: string,
  followUpNotificationId: number,
  data: FollowUpNotificationsCreateRequest,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_FOLLOW_UP_NOTIFICATION,
    });

    try {
      dispatch({
        type: UPDATE_FOLLOW_UP_NOTIFICATION_SUCCESS,
        payload: await updateFollowUpNotificationService(appId, followUpNotificationId, data),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_FOLLOW_UP_NOTIFICATION_ERROR,
        error,
      });
    }
  };
};

export const revertFOLLOW_UP_NOTIFICATION = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REVERT_FOLLOW_UP_NOTIFICATION,
    });
  };
};
