import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Col,
  Label,
} from 'reactstrap';
import { Formik, Form } from 'formik';

import {
  createTagCategory,
  updateTagCategory,
  getCategoryTags,
} from 'src/redux/actions/employee.action';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { StateModel, TagCategoryModel } from 'src/shared/models';
import { TextField, Spinner } from 'src/shared/components';
import { tagCategorySchema } from 'src/shared/schemas/validation.schema';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  isOpen: boolean;
  toggleModal: Function;
  event: string | undefined;
  getTagCategories: Function;
  getCategoryTags: Function;
  createTagCategory: Function;
  create: StateModel;
  updateTagCategory: Function;
  update: StateModel;
  selectTagCategory: Function;
  selectedTagCategory: TagCategoryModel | undefined;
}

const TagCategoryModal: React.FC<Props> = ({
  application,
  getApplication,
  isOpen,
  toggleModal,
  event,
  getTagCategories,
  getCategoryTags,
  createTagCategory,
  create,
  updateTagCategory,
  update,
  selectTagCategory,
  selectedTagCategory,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams();

  const isEditing = event === 'edit';

  useEffect(() => {
    const { loaded, error } = create;
    if (loaded && !error) {
      const { data: newCategory } = create;
      getTagCategories(appId);
      selectTagCategory(newCategory);
      getCategoryTags(appId, newCategory.id);
      toggleModal('create');
    }
    // eslint-disable-next-line
  }, [create]);

  useEffect(() => {
    const { loaded, error } = update;
    if (loaded && !error) {
      const { data: updatedCategory } = update;
      getTagCategories(appId);
      selectTagCategory(updatedCategory);
      getCategoryTags(appId, updatedCategory.id);
      getTagCategories(appId);
      toggleModal('update');
    }
    // eslint-disable-next-line
  }, [update]);

  const onSubmit = async (values: TagCategoryModel) => {
    if (isEditing && selectedTagCategory) {
      updateTagCategory(appId, selectedTagCategory.id, values);
    } else {
      createTagCategory(appId, values);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={() => toggleModal(event)}>
      <ModalHeader>
        {isEditing ? t('employee.editCategory') : t('employee.createCategory')}
      </ModalHeader>

      <Formik
        initialValues={
          {
            name: isEditing ? selectedTagCategory?.name : '',
            slug: isEditing ? selectedTagCategory?.slug : '',
          } as TagCategoryModel
        }
        validationSchema={tagCategorySchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          if (values) {
            await onSubmit(values);
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <FormGroup row>
                <Col sm="4">
                  <Label>{t('common.name')}</Label>
                </Col>
                <Col>
                  <TextField name="name" placeholder={t('employee.nameOfTagCategory')} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm="4">
                  <Label>{t('employee.slug')}</Label>
                </Col>
                <Col>
                  <TextField name="slug" placeholder={t('employee.slug')} disabled={isEditing} />
                </Col>
              </FormGroup>
            </ModalBody>

            <ModalFooter>
              <Spinner loading={create.loading || update.loading} className="mr-1" />
              <Button type="submit" disabled={isSubmitting} color="primary">
                {isEditing ? t('common.save') : t('common.create')}
              </Button>
              <Button color="secondary" onClick={() => toggleModal(event)}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  create: state.createTagCategory,
  update: state.updateTagCategory,
});

const mapDispatchToProps = {
  getApplication,

  getCategoryTags,
  createTagCategory,
  updateTagCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(TagCategoryModal);
