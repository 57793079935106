import * as yup from 'yup';
import moment from 'moment';
import i18n from '../../i18n';
import { shortListValues } from '../constants/positions.constants';
import {
  LOCATIONS_PER_PAGE_MAX,
  LOCATIONS_PER_PAGE_MIN,
  POSITIONS_PER_PAGE_MAX,
  POSITIONS_PER_PAGE_MIN,
} from '../constants/questionnaire.constans';
import { SurveyAnswerTypes } from '../models';
import { ContextControllerMethod } from 'src/views/Questionnaire/components/forms/ContextControllerForm';
import { AnalyticsControllerType } from 'src/views/Questionnaire/components/forms/AnalyticsControllerForm';

export const varchar31 = 31;
export const varchar63 = 63;
export const varchar255 = 255;
export const varchar1024 = 1024;
export const varchar1023 = 1023;

export default yup;

export const folderSchema = yup.object().shape({
  name: yup
    .string()
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 }))
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.name') })),
  slug: yup
    .string()
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 }))
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.id') }))
    .matches(/^[a-z0-9_/-]+$/, i18n.t('errors.invalidSlug')),
  description: yup
    .string()
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  file: yup.mixed(),
});

export const documentSchema = yup.object().shape({
  name: yup
    .string()
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 }))
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.name') })),
  shortDescription: yup
    .string()
    .max(varchar1023, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar1023 })),
  description: yup.string(),
  file: yup.mixed(),
  image: yup.mixed(),
});

export const changePasswordSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.currentPassword') })),
  newPassword: yup
    .string()
    .min(8, i18n.t('errors.passwordMinLength', { length: 8 }))
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.password') })),
  newPasswordConfirmation: yup
    .string()
    .required(
      i18n.t('errors.isRequired', {
        item: i18n.t('common.passwordConfirmation'),
      }),
    )
    .oneOf([yup.ref('newPassword')], i18n.t('errors.passwordsDontMatch')),
});

export const createApplicationSettingSchema = yup.object().shape({
  key: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.key') }))
    .matches(/^[0-9a-z_]+$/, { message: i18n.t('errors.invalidFormat') })
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  value: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.value') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  addNewKey: yup.boolean(),
});

export const createConversationFlowVariableSchema = (valueNotRequired?: boolean) =>
  yup.object().shape({
    key: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('common.key') }))
      .matches(/^[0-9a-zA-Z-_.]*$/, i18n.t('errors.itMustEnglishABCAndSpecialCharacters'))
      .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
    value: valueNotRequired
      ? yup.string().nullable()
      : yup
          .string()
          .required(i18n.t('errors.isRequired', { item: i18n.t('common.value') }))
          .max(
            varchar255,
            i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 }),
          ),
  });

export const trainingDatasetSchema = yup.object().shape({
  fieldDelimiter: yup
    .string()
    .max(1, i18n.t('errors.isAllowed', { item: i18n.t('common.onlyOneCharacter') }))
    .required(i18n.t('errors.isRequired', { item: i18n.t('dataset.fieldDelimiter') })),
  keywordDelimiter: yup
    .string()
    .max(1, i18n.t('errors.isAllowed', { item: i18n.t('common.onlyOneCharacter') }))
    .required(i18n.t('errors.isRequired', { item: i18n.t('dataset.keywordDelimiter') })),
  quote: yup
    .string()
    .max(1, i18n.t('errors.isAllowed', { item: i18n.t('common.onlyOneCharacter') }))
    .required(i18n.t('errors.isRequired', { item: i18n.t('dataset.quote') })),
  escape: yup
    .string()
    .max(1, i18n.t('errors.isAllowed', { item: i18n.t('common.onlyOneCharacter') }))
    .required(i18n.t('errors.isRequired', { item: i18n.t('dataset.escape') })),
});

export const subscribtionListSchema = yup.object().shape({
  name: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.name') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  stringId: yup
    .string()
    .max(varchar63, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar63 }))
    .matches(/^[0-9a-zA-Z-]+$/, {
      message: i18n.t('errors.allowedCharacters', {
        chars: 'a-z, A-Z, 0-9, -',
      }),
    })
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.id') })),
  description: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.description') })),
});

export const emailMessageSchema = yup.object().shape({
  replyTo: yup
    .string()
    .email(i18n.t('errors.invalidEmail'))
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.replyTo') })),
  subject: yup.string().required(i18n.t('errors.isRequired', { item: i18n.t('common.subject') })),
  message: yup.string().required(i18n.t('errors.isRequired', { item: i18n.t('common.message') })),
  sendAtDate: yup
    .string()
    .test('sendAtDate', i18n.t('errors.selectValidDate'), (value: moment.Moment) => {
      return moment(value).isSameOrAfter(moment(), 'day');
    }),
  sendAtTime: yup
    .string()
    .test('sendAtTime', i18n.t('errors.selectValidTime'), (value: moment.Moment) => {
      return moment(value).isSameOrAfter(moment(), 'seconds');
    }),
});

export const viberMessageSchema = yup.object().shape({
  name: yup.string().required(i18n.t('errors.isRequired', { item: i18n.t('common.name') })),
  messgingPlatform: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.messgingPlatform') })),
  message: yup.string().required(i18n.t('errors.isRequired', { item: i18n.t('common.message') })),
  employeeTags: yup.array(),
  sendDate: yup
    .string()
    .test('sendDate', i18n.t('errors.selectValidDate'), (value: moment.Moment) => {
      return moment(value).isSameOrAfter(moment(), 'day');
    }),
  sendTime: yup.string(),
});

export const userCreateSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 }))
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.firstName') })),
  lastName: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.lastName') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  email: yup
    .string()
    .email(i18n.t('errors.invalidEmail'))
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.email') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  password: yup
    .string()
    .min(8, i18n.t('errors.passwordMinLength', { length: 8 }))
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, {
      message: i18n.t('errors.mustContainCharacters', {
        item: i18n.t('common.password'),
        chars: i18n.t('common.requiredPasswordChars'),
      }),
    })
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.password') })),
  passwordConfirmation: yup
    .string()
    .required(
      i18n.t('errors.isRequired', {
        item: i18n.t('common.passwordConfirmation'),
      }),
    )
    .oneOf([yup.ref('password')], i18n.t('errors.passwordsDontMatch')),
  avatar: yup
    .string()
    .url(i18n.t('errors.mustBeAValidUrl'))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  userGroupId: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('users.userGroup') })),
});

export const personaCreateAndEditSchema = yup.object().shape({
  name: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.name') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  avatar: yup
    .string()
    .url(i18n.t('errors.mustBeAValidUrl'))
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.avatar') })),
});

export const userEditSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 }))
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.firstName') })),
  lastName: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.lastName') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  avatar: yup
    .string()
    .url(i18n.t('errors.mustBeAValidUrl'))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  userGroupId: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('users.userGroup') })),
});

export const tagCategorySchema = yup.object().shape({
  name: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.name') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  slug: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('employee.slug') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
});

export const tagSchema = yup.object().shape({
  name: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.name') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
});

export const employeeCreateSchema = yup.object().shape({
  key: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.key') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  secret: yup.string().required(),
});

export const surveyQuestionAnswerCreateSchema = yup.object().shape({
  text: yup.string().when('type.value', {
    is: SurveyAnswerTypes.SURVEY_QUESTION_ANSWER_TYPE_OPTION,
    then: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('common.text') }))
      .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  }),
  type: yup.object().shape({
    label: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('common.answerType') })),
    value: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('common.answerType') })),
  }),
});

export const employeeEditSchema = yup.object().shape({
  key: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.key') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
});

export const positionSchema = yup.object().shape({
  name: yup
    .string()
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 }))
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.name') })),
  description: yup
    .string()
    .max(varchar1024, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar1024 })),
  startDate: yup
    .string()
    .nullable()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.startDate') })),
  endDate: yup
    .string()
    .nullable()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.endDate') })),
  labels: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  ),
  questions: yup.array().of(
    yup.object().shape({
      text: yup.string().required(),
      anyAnswer: yup.bool().required(),
      shortList: yup.bool().required(),
      orderNumber: yup.number().required(),
      questionAnswerOptions: yup.array().of(
        yup.object().shape({
          text: yup.string().required(),
          value: yup
            .number()
            .required(i18n.t('errors.itMustBeNumber'))
            .min(
              shortListValues.MIN,
              i18n.t('errors.itMustBeGreaterThanOrEqual', { number: shortListValues.MIN }),
            )
            .max(
              shortListValues.MAX,
              i18n.t('errors.itMustBeLessThanOrEqual', { number: shortListValues.MAX }),
            ),
          ko: yup.bool().required(),
        }),
      ),
      criteria: yup.object().shape({
        id: yup.number().required(),
        name: yup
          .string()
          .required()
          .max(
            varchar255,
            i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 }),
          ),
      }),
    }),
  ),
});

export const positionCloningSchema = yup.object().shape({
  name: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.name') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  description: yup
    .string()
    .max(varchar1024, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar1024 }))
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.description') })),
  startDate: yup
    .string()
    .nullable()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.startDate') })),
  endDate: yup
    .string()
    .nullable()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.endDate') })),
  cloneLabels: yup.bool(),
  clonePrefilterCriterias: yup.bool(),
  cloneShortListDefiniton: yup.bool(),
  cloneApplicants: yup.bool(),
});

export const prefilterCriteriaSchema = yup.object().shape({
  category: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  text: yup.string().required(i18n.t('errors.isRequired', { item: i18n.t('common.question') })),
});

export const prefilterCriteriaCategorySchema = yup.object().shape({
  name: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.prefilterCriteriaCategory') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
});

export const positionFilterQuestionSchema = yup.object().shape({
  subject: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.subject') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  question: yup.string().required(i18n.t('errors.isRequired', { item: i18n.t('common.question') })),
  // label: yup.string(),
});

// Questionnaire Simple Message & Open Ended Question
export const defaultMessageSchema = yup.object().shape({
  parentConditions: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  ),
  disabled: yup.bool(),
  nextMessageIdIfDisabled: yup.mixed(),
});

// Questionnaire Simple Message & Open Ended Question
export const simpleMessageSchema = yup.object().shape({
  parentConditions: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  ),
  disabled: yup.bool(),
  nextMessageIdIfDisabled: yup.mixed(),
  intent: yup.mixed(),
  text: yup.string().required(i18n.t('errors.isRequired', { item: i18n.t('common.message') })),
});

export const parentConditionsWithIntentAndTextSchema = yup.object().shape({
  parentConditions: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  ),
  disabled: yup.bool(),
  nextMessageIdIfDisabled: yup.mixed(),
  intent: yup.mixed(),
  text: yup.string().required(i18n.t('errors.isRequired', { item: i18n.t('common.message') })),
});

// Questionnaire Simple Message & Open Ended Question
export const startMessageSchema = yup.object().shape({
  parentConditions: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  ),
  intent: yup.mixed(),
  mainRoute: yup.bool(),
});

export const dynamicLabelMessageSchema = yup.object().shape({
  parentConditions: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  ),
  intent: yup.mixed(),
  text: yup.string().required(i18n.t('errors.isRequired', { item: i18n.t('common.message') })),
  labelCategorySlug: yup.string().required(),
  perPageLabelSlugs: yup.array(),
  showAll: yup.bool().required(),
  nextPageButton: yup
    .string()
    .when('showAll', { is: false, then: yup.string().required(i18n.t('common.required')) }),
  showSize: yup.number().when('showAll', {
    is: false,
    then: yup
      .number()
      .min(
        LOCATIONS_PER_PAGE_MIN,
        i18n.t('errors.itMustBeGreaterThanOrEqual', { number: LOCATIONS_PER_PAGE_MIN }),
      )
      .max(
        LOCATIONS_PER_PAGE_MAX,
        i18n.t('errors.itMustBeLessThanOrEqual', { number: LOCATIONS_PER_PAGE_MAX }),
      )
      .required(i18n.t('common.required')),
  }),
});

export const interviewEventMessageSchema = yup.object().shape({
  parentConditions: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  ),
  intent: yup.mixed(),
  text: yup.string().required(i18n.t('errors.isRequired', { item: i18n.t('common.message') })),
  showNextButton: yup
    .bool()
    .when('showAll', { is: false, then: yup.bool().required(i18n.t('common.required')) }),
  showAll: yup.bool().required(),
  nextPageButton: yup
    .string()
    .when('showAll', { is: false, then: yup.string().required(i18n.t('common.required')) }),
  showSize: yup.number().when('showAll', {
    is: false,
    then: yup
      .number()
      .min(
        LOCATIONS_PER_PAGE_MIN,
        i18n.t('errors.itMustBeGreaterThanOrEqual', { number: LOCATIONS_PER_PAGE_MIN }),
      )
      .max(
        LOCATIONS_PER_PAGE_MAX,
        i18n.t('errors.itMustBeLessThanOrEqual', { number: LOCATIONS_PER_PAGE_MAX }),
      )
      .required(i18n.t('common.required')),
  }),
});

// Questionnaire Logic Controller Message
export const logicContollerMessageSchema = yup.object().shape({
  expression: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.expression') })),
  parentConditions: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  ),
});

// Questionnaire Open Ended Question
export const openEndedQuestionSchema = yup.object().shape({
  regexReplacers: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup
          .string()
          .required(i18n.t('errors.isRequired', { item: i18n.t('questionnaire.regexReplacer') })),
      }),
    )
    .nullable(),
  saveLocation: yup.mixed(),
  validatorType: yup.mixed(),
  faultTolerant: yup.bool(),
});

// Questionnaire Closed Ended Question
export const closedEndedQuestionSchema = yup.object().shape({
  answer: yup
    .string()
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  answers: yup.array(),
});

// Questionnaire Location Message
export const locationMessageSchema = yup.object().shape({
  parentConditions: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  ),
  locationMessage: yup.object().shape({
    chooseCountryPart: yup.string().required(i18n.t('common.required')),
    chooseCounty: yup.string().required(i18n.t('common.required')),
    chooseCity: yup.string().required(i18n.t('common.required')),
    cityChooseAllText: yup.string().required(i18n.t('common.required')),
    cityChooseNoneText: yup.string().required(i18n.t('common.required')),
    showAll: yup.bool().required(),
    nextPageButton: yup
      .string()
      .when('showAll', { is: false, then: yup.string().required(i18n.t('common.required')) }),
    showSize: yup.number().when('showAll', {
      is: false,
      then: yup
        .number()
        .min(
          LOCATIONS_PER_PAGE_MIN,
          i18n.t('errors.itMustBeGreaterThanOrEqual', { number: LOCATIONS_PER_PAGE_MIN }),
        )
        .max(
          LOCATIONS_PER_PAGE_MAX,
          i18n.t('errors.itMustBeLessThanOrEqual', { number: LOCATIONS_PER_PAGE_MAX }),
        )
        .required(i18n.t('common.required')),
    }),
  }),
});

// Questionnaire Location City Message
export const locationCityMessageSchema = yup.object().shape({
  parentConditions: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  ),
  chooseCity: yup.string().required(i18n.t('common.required')),
  cityChooseAllText: yup.string().required(i18n.t('common.required')),
  cityChooseNoneText: yup.string().required(i18n.t('common.required')),
  showAll: yup.bool().required(),
  nextPageButton: yup
    .string()
    .when('showAll', { is: false, then: yup.string().required(i18n.t('common.required')) }),
  showSize: yup.number().when('showAll', {
    is: false,
    then: yup
      .number()
      .min(
        LOCATIONS_PER_PAGE_MIN,
        i18n.t('errors.itMustBeGreaterThanOrEqual', { number: LOCATIONS_PER_PAGE_MIN }),
      )
      .max(
        LOCATIONS_PER_PAGE_MAX,
        i18n.t('errors.itMustBeLessThanOrEqual', { number: LOCATIONS_PER_PAGE_MAX }),
      )
      .required(i18n.t('common.required')),
  }),
});

// Questionnaire Location County and Part of country Message
export const locationCountyMessageSchema = yup.object().shape({
  parentConditions: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  ),
  chooseCounty: yup.string().required(i18n.t('common.required')),
  showAll: yup.bool().required(),
  nextPageButton: yup
    .string()
    .when('showAll', { is: false, then: yup.string().required(i18n.t('common.required')) }),
  showSize: yup.number().when('showAll', {
    is: false,
    then: yup
      .number()
      .min(
        LOCATIONS_PER_PAGE_MIN,
        i18n.t('errors.itMustBeGreaterThanOrEqual', { number: LOCATIONS_PER_PAGE_MIN }),
      )
      .max(
        LOCATIONS_PER_PAGE_MAX,
        i18n.t('errors.itMustBeLessThanOrEqual', { number: LOCATIONS_PER_PAGE_MAX }),
      )
      .required(i18n.t('common.required')),
  }),
});

// Questionnaire Location County and Part of country Message
export const locationPartOfCountryMessageSchema = yup.object().shape({
  parentConditions: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  ),
  choosePartOfCountry: yup.string().required(i18n.t('common.required')),
  showAll: yup.bool().required(),
  nextPageButton: yup
    .string()
    .when('showAll', { is: false, then: yup.string().required(i18n.t('common.required')) }),
  showSize: yup.number().when('showAll', {
    is: false,
    then: yup
      .number()
      .min(
        LOCATIONS_PER_PAGE_MIN,
        i18n.t('errors.itMustBeGreaterThanOrEqual', { number: LOCATIONS_PER_PAGE_MIN }),
      )
      .max(
        LOCATIONS_PER_PAGE_MAX,
        i18n.t('errors.itMustBeLessThanOrEqual', { number: LOCATIONS_PER_PAGE_MAX }),
      )
      .required(i18n.t('common.required')),
  }),
});

// Questionnaire Show Positions Message
export const showPositionMessageSchema = yup.object().shape({
  showAll: yup.bool().required(),
  nextPageButton: yup
    .string()
    .when('showAll', { is: false, then: yup.string().required(i18n.t('common.required')) }),
  showSize: yup.number().when('showAll', {
    is: false,
    then: yup
      .number()
      .min(
        POSITIONS_PER_PAGE_MIN,
        i18n.t('errors.itMustBeGreaterThanOrEqual', { number: POSITIONS_PER_PAGE_MIN }),
      )
      .max(
        POSITIONS_PER_PAGE_MAX,
        i18n.t('errors.itMustBeLessThanOrEqual', { number: POSITIONS_PER_PAGE_MAX }),
      )
      .required(i18n.t('common.required')),
  }),
  carouselView: yup.bool().required(),
  carouselButtonText: yup
    .string()
    .when('carouselView', { is: true, then: yup.string().required(i18n.t('common.required')) }),
  carouselDefaultImageUrl: yup
    .string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(ht|f)tp(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?$/,
      i18n.t('errors.mustBeAValidUrl'),
    )
    .when('carouselView', { is: true, then: yup.string().required(i18n.t('common.required')) }),
});

export const imageMessageSchema = yup.object().shape({
  parentConditions: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  ),
  text: yup
    .string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(ht|f)tp(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?$/,
      i18n.t('errors.mustBeAValidUrl'),
    )
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.path') })),
});

// Questionnaire Export Application To ATS Message
export const exportApplicationToAtsMessageSchema = yup.object().shape({
  parentConditions: yup.array().of(
    yup.object().shape({
      label: yup.string().required(),
      value: yup.string().required(),
    }),
  ),
  path: yup
    .string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(ht|f)tp(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?$/,
      i18n.t('errors.mustBeAValidUrl'),
    )
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.path') })),
  errorMessage: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.errorMessage') })),
});

// Questionnaire Document Uploader Message
export const documentUploaderMessageSchema = yup.object().shape({
  hint: yup.string().required(i18n.t('errors.isRequired', { item: i18n.t('common.hint') })),
  webviewUrl: yup
    .string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(ht|f)tp(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?$/,
      i18n.t('errors.mustBeAValidUrl'),
    )
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.webViewUrl') })),
  saveLocation: yup.mixed(),
  maxFileSizeInBytes: yup
    .number()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.maxFileSizeInBytes') })),
  allowedFileMimeTypes: yup.array().min(1, i18n.t('common.selectAtLeastOneFileMimeType')),
  cantUploadErrorText: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.cantUploadErrorText') })),
  tooLargeFileSizeErrorText: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.tooLargeFileSizeErrorText') })),
  notAllowedFileMimeTypeErrorText: yup
    .string()
    .required(
      i18n.t('errors.isRequired', { item: i18n.t('common.notAllowedFileMimeTypeErrorText') }),
    ),
});

export const documentViewMessageSchema = yup.object().shape({
  buttonText: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.buttonText') })),
  text: yup.string().required(i18n.t('errors.isRequired', { item: i18n.t('common.message') })),
  notFoundErrorText: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('questionnaire.notFoundErrorText') })),
  folderSlug: yup.object().shape({
    label: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('questionnaire.folderSlug') })),
    value: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('questionnaire.folderSlug') })),
  }),
});

export const newLabelSchema = yup.object().shape({
  createNewLabelCategory: yup.boolean().required(),
  existingLabelCategory: yup
    .object()
    .shape({})
    .when('createNewLabelCategory', {
      is: false,
      then: yup
        .object()
        .shape({
          label: yup.string(),
          value: yup.string(),
        })
        .required(),
    }),
  newLabelCategory: yup.string().when('createNewLabelCategory', {
    is: true,
    then: yup
      .string()
      .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 }))
      .required(i18n.t('errors.isRequired', { item: i18n.t('common.labelCategory') })),
  }),
  label: yup
    .string()
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 }))
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.label') })),
});

export const customFieldsSchema = yup.object().shape({
  key: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.key') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  title: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.title') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  namespace: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.namespace') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
});

export const customFieldConfigSchema = yup.object().shape({
  config: yup.string().required(i18n.t('errors.isRequired', { item: i18n.t('common.config') })),
});

export const attributeValidatorSchema = yup.object().shape({
  title: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.title') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  pattern: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.regexPattern') })),
});

export const regexReplacerSchema = yup.object().shape({
  title: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.title') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  pattern: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.regexPattern') })),
  replaceTo: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.replaceTo') })),
});

export const expressionSchema = yup.object().shape({
  title: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.title') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  expression: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.expression') })),
});

export const trainingDataSchema = yup.object().shape({
  name: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.name') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
});

export const customListSchema = yup.object().shape({
  name: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.name') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  externalId: yup
    .string()
    .nullable()
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
});

export const labelCategorySchema = yup.object().shape({
  name: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.name') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
});

export const interviewSchema = yup.object().shape({
  name: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.name') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  description: yup.string(),
  positions: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .min(1, i18n.t('interviews.selectAtLeastOnePosition')),
  limit: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  startDate: yup
    .string()
    .test('startDate', i18n.t('errors.selectValidDate'), (value: moment.Moment) => {
      return moment(value).isSameOrAfter(moment(), 'day');
    }),
  startTime: yup.string(),
  // .test('startTime', i18n.t('errors.selectValidTime'), (value: moment.Moment) => {
  //   return moment(value).isSameOrAfter(moment(), 'seconds');
  // }),
  endDate: yup
    .string()
    .test('endDate', i18n.t('errors.selectValidDate'), (value: moment.Moment) => {
      return moment(value).isSameOrAfter(moment(), 'day');
    }),
  endTime: yup.string(),
  // .test('endTime', i18n.t('errors.selectValidTime'), (value: moment.Moment) => {
  //   return moment(value).isSameOrAfter(moment(), 'seconds');
  // }),
  applicationDeadlineDate: yup
    .string()
    .required(i18n.t('errors.selectValidDate', { item: i18n.t('common.applicationDeadline') })),
  applicationDeadlineTime: yup.string(),
  // .test('startTime', i18n.t('errors.selectValidTime'), (value: moment.Moment) => {
  //   return moment(value).isSameOrAfter(moment(), 'seconds');
  // }),
  recurrenceFrequency: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  recurrenceUntilDate: yup
    .string()
    .test('recurrenceUntilDate', i18n.t('errors.selectValidDate'), (value: moment.Moment) => {
      return moment(value).isSameOrAfter(moment(), 'day');
    }),
  recurrenceUntilTime: yup.string(),
  // .test('recurrenceUntilTime', i18n.t('errors.selectValidTime'), (value: moment.Moment) => {
  //   return moment(value).isSameOrAfter(moment(), 'seconds');
  // }),
});

export const importSchema = yup.object().shape({
  configuration: yup.object().shape({
    labelCategories: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('importExport.labelCategories') }))
      .nullable(),
    criterias: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('importExport.criterias') }))
      .nullable(),
    positions: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('importExport.positions') }))
      .nullable(),
    interviews: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('importExport.interviews') }))
      .nullable(),
    messages: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('importExport.messages') }))
      .nullable(),
  }),
  data: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('importExport.jsonData') })),
});

export const importConfigurationValidatorSchema = yup.object().shape({
  title: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.title') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
  configuration: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('importConfiguration.jsonData') }))
    .test('configuration', i18n.t('importConfiguration.jsonData'), value => {
      try {
        JSON.parse(value);
      } catch (error) {
        return false;
      }
      return true;
    }),
});

export const helpdeskGroupValidatorSchema = yup.object().shape({
  name: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.name') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
});

export const helpdeskInstantAnswerValidatorSchema = yup.object().shape({
  title: yup
    .string()
    .required(i18n.t('errors.isRequired', { item: i18n.t('common.name') }))
    .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 })),
});

export const dynamicFaqValidatorSchema = yup.object().shape({
  configId: yup.object().shape({
    value: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('importExport.messages') })),
  }),
});

export const criteriaImportValidatorSchema = yup.object().shape({
  configId: yup.object().shape({
    value: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('importExport.messages') })),
  }),
});

export const dynamicConvFlowValidatorSchema = yup.object().shape({
  configId: yup.object().shape({
    value: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('importExport.messages') })),
  }),
});

export const dynamicMenuValidatorSchema = yup.object().shape({
  configId: yup.object().shape({
    value: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('importExport.messages') })),
  }),
});

export const surveySchema = (edit?: boolean) =>
  yup.object().shape({
    name: yup
      .string()
      .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 }))
      .required(i18n.t('errors.isRequired', { item: i18n.t('common.name') })),
    mandatory: yup.boolean(),
    description: yup.string(),
    startDate: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('common.startDate') })),
    startTime: yup.string(),
    endDate: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('common.startDate') })),
    endTime: yup.string(),
    retries: yup.number().required(i18n.t('errors.itMustBeNumber')).nullable(),
  });

export const eventSchema = () =>
  yup.object().shape({
    title: yup
      .string()
      .min(
        shortListValues.MIN,
        i18n.t('errors.itMustBeGreaterThanOrEqual', { number: shortListValues.MIN }),
      )
      .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 }))
      .required(i18n.t('errors.isRequired', { item: i18n.t('common.title') })),
    description: yup.string(),
    startDate: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('common.startDate') })),
    startTime: yup.string(),
    endDate: yup.string().required(i18n.t('errors.isRequired', { item: i18n.t('common.endDate') })),
    endTime: yup.string(),
  });

export const eventQuestionsAnswersSchema = () =>
  yup.object().shape({
    text: yup
      .string()
      .min(
        shortListValues.MIN,
        i18n.t('errors.itMustBeGreaterThanOrEqual', { number: shortListValues.MIN }),
      )
      .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 }))
      .required(i18n.t('errors.isRequired', { item: i18n.t('common.title') })),
  });

export const questionsAnswerSchema = () =>
  yup.object().shape({answer:
    yup.object().shape({
    text: yup
      .string()
      .min(
        shortListValues.MIN,
        i18n.t('errors.itMustBeGreaterThanOrEqual', { number: shortListValues.MIN }),
      )
      .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 }))
      .required(i18n.t('errors.isRequired', { item: i18n.t('common.text') })),
    value: yup
      .string()
      .min(
        shortListValues.MIN,
        i18n.t('errors.itMustBeGreaterThanOrEqual', { number: shortListValues.MIN }),
      )
      .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 }))
      .required(i18n.t('errors.isRequired', { item: i18n.t('common.value') })),
  })
  });

export const questionnaireContextControllerSchema = () =>
  yup.object().shape({
    contextActions: yup.array().of(
      yup.object().shape({
        method: yup.object().shape({
          label: yup.string().required(),
          value: yup.string().required(),
        }),
        key: yup.string().when('method.value', {
          is: value =>
            value === ContextControllerMethod.CONTEXT_SET ||
            value === ContextControllerMethod.CONTEXT_REMOVE,
          then: yup.string().required(i18n.t('errors.isRequired', { item: i18n.t('common.key') })),
          otherwise: yup.string().notRequired().nullable().default(null),
        }),
        value: yup.string().when('method.value', {
          is: value => value === ContextControllerMethod.CONTEXT_SET,
          then: yup
            .string()
            .required(i18n.t('errors.isRequired', { item: i18n.t('common.value') })),
          otherwise: yup.string().notRequired().nullable().default(null),
        }),
        function: yup.string().when('method.value', {
          is: value => value === ContextControllerMethod.SERVICE,
          then: yup
            .string()
            .required(i18n.t('errors.isRequired', { item: i18n.t('common.function') })),
          otherwise: yup.string().notRequired().nullable().default(null),
        }),
      }),
    ),
  });

export const questionnaireAnalyticsControllerSchema = () =>
  yup.object().shape({
    analytics: yup.array().of(
      yup.object().shape({
        analyticsType: yup.object().shape({
          label: yup.string().required(),
          value: yup.string().required(),
        }),
        data: yup.object().when('analyticsType.value', {
          is: AnalyticsControllerType.MENU_CLICKED,
          then: yup.object().shape({
            source: yup
              .string()
              .required(i18n.t('errors.isRequired', { item: i18n.t('common.source') })),
          }),
          otherwise: yup.string().notRequired().nullable().default(null),
        }),
      }),
    ),
  });

export const followUpNotificationsEditorSchema = () =>
  yup.object().shape({
    name: yup.string().required(i18n.t('errors.isRequired', { item: i18n.t('common.name') })),
    message: yup.object().shape({
      text: yup.string().required(i18n.t('errors.isRequired', { item: i18n.t('common.message') })),
    }),
    startsAt: yup.array().min(1, i18n.t('followUpNotifications.selectAtLeastOne')),
    triggerDynamicConversationFlowMenuItems: yup
      .array()
      .min(1, i18n.t('followUpNotifications.selectAtLeastOne')),
  });

export const formGeneralSchema = () =>
  yup.object().shape({
    title: yup
      .string()
      .min(3, i18n.t('errors.itMustBeGreaterThanOrEqual', { number: 3 }))
      .max(varchar255, i18n.t('errors.itMustBeLessThanOrEqualCharacters', { number: varchar255 }))
      .required(i18n.t('errors.isRequired', { item: i18n.t('common.title') })),
    emailNotificationEnabled: yup.boolean(),
    emailNotificationSubject: yup.mixed().when('emailNotificationEnabled', {
      is: true,
      then: yup
        .string()
        .required(i18n.t('errors.isRequired', { item: i18n.t('common.emailNotificationSubject') })),
      otherwise: yup.string().nullable(),
    }),
    emailNotificationBody: yup
      .string()
      .nullable()
      .when('emailNotificationEnabled', {
        is: true,
        then: yup
          .string()
          .required(i18n.t('errors.isRequired', { item: i18n.t('common.emailNotificationBody') })),
        otherwise: yup.string().nullable(),
      }),
    emailNotificationTo: yup
      .string()
      .nullable()
      .when('emailNotificationEnabled', {
        is: true,
        then: yup
          .string()
          .required(i18n.t('errors.isRequired', { item: i18n.t('common.emailNotificationTo') })),
      }),
    reportVisibleContextEntries: yup
      .string()
      .matches(/^[0-9a-zA-Z_-]+:[\p{L}0-9 _-]+(?:,[0-9a-zA-Z_-]+:[\p{L}0-9 _-]+)*$/gmu, {
        message: i18n.t('errors.isAllowed', {
          item: 'key1:value1,key2:value2,...',
        }),
      }),
  });

export const languageEditorSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('common.name') }))
      .min(3, i18n.t('errors.itMustBeGreaterThanOrEqual', { number: 3 }))
      .max(63, i18n.t('errors.itMustBeLessThanOrEqual', { number: 63 })),
    languageCode: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('translations.languageCode') }))
      .min(2, i18n.t('errors.itMustBeGreaterThanOrEqual', { number: 2 }))
      .max(2, i18n.t('errors.itMustBeLessThanOrEqual', { number: 2 })),
  });

export const issueCategorySchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .required(i18n.t('errors.isRequired', { item: i18n.t('common.name') }))
  });
