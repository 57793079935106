import React from 'react';
import { isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment';

import DateRange from '../../../shared/components/date-pickers/DateRange';

interface Props {
  startDate: moment.Moment;
  endDate: moment.Moment;
  handleDateChange: Function;
}

const ComplaintsDateFilter: React.FC<Props> = ({
  startDate,
  endDate,
  handleDateChange,
}) => {
  return (
    <DateRange
      startDate={startDate}
      endDate={endDate}
      handleDateChange={handleDateChange}
      isOutsideRange={(day: moment.Moment) =>
        !isInclusivelyBeforeDay(day, moment())
      }
    />
  );
};

export default ComplaintsDateFilter;
