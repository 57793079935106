import React from 'react';

import styles from '../../../ConversationHistory.module.scss';

interface Props {
  handlePageChange: Function;
  currentPage: number;
  totalPages: number;
}

const SidebarFooter: React.FC<Props> = (props: Props) => {
  const { currentPage, totalPages, handlePageChange } = props;

  return (
    <div className={styles.sidebarFooter}>
      <button
        type="button"
        className={styles.navButton}
        disabled={currentPage === 1}
        onClick={() => handlePageChange(-1)}
      >
        <i className="fas fa-chevron-left" />
      </button>
      <span className={styles.pages}>
        {currentPage} / {totalPages || 1}
      </span>
      <button
        type="button"
        className={styles.navButton}
        disabled={currentPage === totalPages || totalPages === 0}
        onClick={() => handlePageChange(1)}
      >
        <i className="fas fa-chevron-right" />
      </button>
    </div>
  );
};

export default SidebarFooter;
