import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import { TextField, ReactSelect } from 'src/shared/components';
import { SelectModel } from 'src/shared/models';

interface OptionsAnswerProps {
  jumpToSlugOptions: SelectModel[];
}

const OptionsAnswer: FC<OptionsAnswerProps> = ({ jumpToSlugOptions }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label for="text">{t('common.text')}</Label>
            <TextField name="answer.text" />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label for="value">{t('common.value')}</Label>
            <TextField name="answer.value" />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label for="jumpToSlug">{t('Kérdésre ugrás')}</Label>
            <ReactSelect name="answer.jumpToSlug" options={jumpToSlugOptions} />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default OptionsAnswer;
