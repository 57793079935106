import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { deleteForm, getForms, revertForm } from 'src/redux/actions/forms.action';

import { List } from 'src/shared/components';

import { FormModel, Operations, PageQueryParams, QueryParams } from 'src/shared/models';

interface IFormsProps {
  forms: FormModel[];
  numberOfForms: number;
  formsLoading: boolean;
  formsLoaded: boolean;
  formsError: boolean;
  application: ApplicationModel;

  getForms: (appId: string, queryParams: PageQueryParams) => void;
  getApplication: Function;
  deleteForm: (appId: string, formId: number) => void;
  revertForm: () => void;
}

const Forms: FC<IFormsProps> = ({
  forms,
  numberOfForms,
  formsLoading,
  formsLoaded,
  formsError,
  application,

  getForms,
  getApplication,
  deleteForm,
  revertForm,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  /* eslint-disable-next-line */
  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: 0,
    size: 20,
  });

  useEffect(() => {
    revertForm();
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  const fetchForms = (queryParams: QueryParams) => {
    setQueryParams(queryParams);
    getForms(appId, queryParams);
  };

  const removeForm = async (form: FormModel) => {
    await deleteForm(appId, form.id);
  };

  return (
    <div className="animated fadeIn">
      <List
        useCardContent
        data={forms}
        totalElements={numberOfForms}
        loaded={formsLoaded}
        loading={formsLoading}
        error={formsError}
        fetchData={fetchForms}
        title={t('forms.forms')}
        createRedirectPath="create"
        fields={[
          { key: 'title', label: t('common.title') },
          { key: 'description', label: t('common.description'), columnStyle: 'long' },
          { key: 'numberOfAttempts', label: t('common.numberOfAttempts') },
          {
            key: 'operations',
            label: t('common.operations'),
            operations: [Operations.REPORT, Operations.UPDATE, Operations.DELETE],
          },
        ]}
        noDataLabel={t('forms.noData')}
        deleteFunction={removeForm}
        deleteTitle={t('forms.delete')}
        deleteText={t('forms.deleteConfirmMessage')}
        updatePermission="form_form_update"
        deletePermission="form_form_delete"
        reportRedirectPath="reports/detailed"
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    forms: state.forms.data.content,
    numberOfForms: state.forms.data.totalElements,
    formsLoading: state.forms.loading,
    formsLoaded: state.forms.loaded,
    formsError: state.forms.error,
    application: state.application.data,
  };
};

const mapDispatchToProps = {
  getForms,
  getApplication,
  deleteForm,
  revertForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(Forms);
