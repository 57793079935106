// import { addAlert } from './alerts';
import { Dispatch } from 'redux';
import { QueryParams, ConversationsQueryParams } from '../../shared/models/query-params.model';
import {
  HandoverMessageModel,
  ConversationUserModel,
} from '../../shared/models/conversations.model';
import {
  getConversations as getConversationsService,
  setHandoverStatus as setHandoverStatusService,
  sendHandoverMessage as sendHandoverMessageService,
} from '../services/conversations.service';
import { EmployeeModel } from 'src/shared/models';
import { ConversationHistoryFilters } from 'src/shared/models/conversation-history.model';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';
import { getNewFilters } from 'src/shared/utils';

export const GET_CONVERSATIONS_REQUEST = 'conversations/GET_CONVERSATIONS_REQUEST';
export const GET_CONVERSATIONS_SUCCESS = 'conversations/GET_CONVERSATIONS_SUCCESS';
export const GET_CONVERSATIONS_ERROR = 'conversations/GET_CONVERSATIONS_ERROR';

export const SET_CONVERSATION_STATUS = 'conversations/SET_CONVERSATION_STATUS';
export const SET_CONVERSATIONS_STATUS = 'conversations/SET_CONVERSATIONS_STATUS';
export const SET_CONVERSATION_PLATFORM = 'conversations/SET_CONVERSATION_PLATFORM';
export const SET_CURRENT_CONVERSATION = 'conversations/SET_CURRENT_CONVERSATION';
export const SET_CURRENT_USER = 'conversations/SET_CURRENT_USER';

export const CLEAR_CONVERSATIONS = 'conversations/CLEAR_CONVERSATIONS';

export const GET_CONVERSATIONS_QUERY_PARAMS = 'conversations/GET_CONVERSATIONS_QUERY_PARAMS';
export const SET_CONVERSATIONS_QUERY_PARAMS = 'conversations/SET_CONVERSATIONS_QUERY_PARAMS';
export const RESET_CONVERSATIONS_QUERY_PARAMS = 'conversations/RESET_CONVERSATIONS_QUERY_PARAMS';

export const SET_HANDOVER_STATUS_REQUEST = 'conversations/SET_HANDOVER_STATUS_REQUEST';
export const SET_HANDOVER_STATUS_SUCCESS = 'conversations/SET_HANDOVER_STATUS_SUCCESS';
export const SET_HANDOVER_STATUS_ERROR = 'conversations/SET_HANDOVER_STATUS_ERROR';

export const SEND_HANDOVER_MESSAGE_REQUEST = 'conversations/SEND_HANDOVER_MESSAGE_REQUEST';
export const SEND_HANDOVER_MESSAGE_SUCCESS = 'conversations/SEND_HANDOVER_MESSAGE_SUCCESS';
export const SEND_HANDOVER_MESSAGE_ERROR = 'conversations/SEND_HANDOVER_MESSAGE_ERROR';

export interface ConversationModel {
  uuid: string;
  applicationId: number;
  handoverStatus: boolean;
  timeWindowExpired: boolean;
  creationTimestamp: string;
  messageAsRaw: string;
  messageAsText: string;
  messagingPlatform: string;
  name: string;
  isOutgoingMessage: boolean;
  status: number;
  employee?: EmployeeModel;
  lastChatbotTimestamp?: string;
}

export const getConversations = (
  appId: string,
  queryParams: QueryParams,
  filters: ConversationHistoryFilters,
  grouping: DynamicGroupByModel,
  tabs: DynamicGroupByModel
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_CONVERSATIONS_REQUEST,
    });

    if (!queryParams.platforms) {
      /* eslint-disable-next-line */
      delete queryParams.platforms;
    }

    if (!queryParams.waiting) {
      /* eslint-disable-next-line */
      delete queryParams.waiting;
    }
    
    const conversations = await getConversationsService(
      appId,
      { filters: { ...getNewFilters(filters), ...grouping, ...tabs } },
      {
        ...queryParams,
        from: filters.filters.startDate,
        to: filters.filters.endDate,
        waiting: filters.filters.waiting,
        platforms: (filters.filters.selectedPlatforms || []).map(sp => sp.value).join(','),
      },
    );

    try {
      dispatch({
        type: GET_CONVERSATIONS_SUCCESS,
        payload: conversations,
      });
    } catch (error) {
      dispatch({
        type: GET_CONVERSATIONS_ERROR,
        error,
      });
    }
  };
};

export const getConversationsQueryParams = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: GET_CONVERSATIONS_QUERY_PARAMS,
    });
  };
};

export const setConversationsQueryParams = (queryParams: ConversationsQueryParams) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_CONVERSATIONS_QUERY_PARAMS,
      payload: queryParams,
    });
  };
};

export const resetConversationsQueryParams = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: RESET_CONVERSATIONS_QUERY_PARAMS,
    });
  };
};

export const clearConversations = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_CONVERSATIONS,
    });
  };
};

export const setConversationsStatus = (conversations: ConversationModel[]) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_CONVERSATIONS_STATUS,
      payload: conversations,
    });
  };
};

export const setConversationStatus = (conversation: ConversationModel) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_CONVERSATION_STATUS,
      payload: conversation,
    });
  };
};

export const setCurrentConversation = (conversation: ConversationModel) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_CURRENT_CONVERSATION,
      payload: conversation,
    });
  };
};

export const setConversationPlatform = (platform: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_CONVERSATION_PLATFORM,
      payload: platform,
    });
  };
};

export const setCurrentUser = (user: ConversationUserModel) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_CURRENT_USER,
      payload: user,
    });
  };
};

export const setHandoverStatus = (userId: string, handover: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_HANDOVER_STATUS_REQUEST,
    });

    try {
      dispatch({
        type: SET_HANDOVER_STATUS_SUCCESS,
        payload: await setHandoverStatusService(userId, handover),
      });
    } catch (error) {
      dispatch({
        type: SET_HANDOVER_STATUS_ERROR,
        error,
      });
    }
  };
};

export const sendHandoverMessage = (userId: string, message: HandoverMessageModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SEND_HANDOVER_MESSAGE_REQUEST,
    });

    try {
      dispatch({
        type: SEND_HANDOVER_MESSAGE_SUCCESS,
        payload: await sendHandoverMessageService(userId, message),
      });
    } catch (error) {
      dispatch({
        type: SEND_HANDOVER_MESSAGE_ERROR,
        error,
      });
    }
  };
};
