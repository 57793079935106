import React, { FC, Fragment, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import moment from 'moment';

import { SwitchModel } from 'src/shared/models/switch.model';
import { UsageStatisticsQueryParams } from 'src/shared/models/query-params.model';

import { fileNames } from 'src/shared/settings';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import {
  getDailyCountOfConversationsAndMessages,
  exportDailyCountOfConversationsAndMessages,
  getHourlyDistributionOfAllMessagesPercent,
  exportHourlyDistributionOfAllMessagesPercent,
  getHourlyDistributionOfAllMessagesAvgCount,
  exportHourlyDistributionOfAllMessagesAvgCount,
} from 'src/redux/actions/usage-statistics.action';

import { formatDatesToIsoString } from 'src/shared/utils/query-parse.util';
import saveFile from 'src/shared/utils/file-saver.util';
import { getUsersTimezoneOffsetToUtc } from 'src/shared/utils/time.util';

import Filter from './components/Filter';
import Export from './components/Export';
import DailyCountOfConversationsAndMessages from './components/charts/DailyCountOfConversationsAndMessages';
import HourlyDistributionOfAllMessages from './components/charts/HourlyDistributionOfAllMessages';
import { Spinner, PageHeader } from 'src/shared/components';
import DynamicGroupBy from '../../shared/components/dynamic-group-by/DynamicGroupBy';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';

import 'react-dates/lib/css/_datepicker.css';
import './UsageStatistics.scss';
import { configurationConstants } from 'src/shared/constants/configuration.constants';

interface Props extends RouteComponentProps<{ id: string }>, WithTranslation {
  getApplication: Function;
  application: ApplicationModel;

  dailyCountOfConversationsAndMessages: any;
  dailyCountOfConversationsAndMessagesExport: any;
  getDailyCountOfConversationsAndMessages: Function;
  exportDailyCountOfConversationsAndMessages: (
    applicationId: number,
    params: UsageStatisticsQueryParams,
    grouping: DynamicGroupByModel,
  ) => void;

  hourlyDistributionOfAllMessagesPercent: any;
  hourlyDistributionOfAllMessagesPercentExport: any;
  getHourlyDistributionOfAllMessagesPercent: Function;
  exportHourlyDistributionOfAllMessagesPercent: (
    applicationId: number,
    params: UsageStatisticsQueryParams,
    grouping: DynamicGroupByModel,
  ) => void;

  hourlyDistributionOfAllMessagesAvgCount: any;
  hourlyDistributionOfAllMessagesAvgCountExport: any;
  getHourlyDistributionOfAllMessagesAvgCount: Function;
  exportHourlyDistributionOfAllMessagesAvgCount: (
    applicationId: number,
    params: UsageStatisticsQueryParams,
    grouping: DynamicGroupByModel,
  ) => void;
}

interface State {
  conversationSum: number;
  messageSum: number;
  distributionSum: number;
  disabledExports: any;
  queryParams: UsageStatisticsQueryParams;
}

const INIT_FROM_VALUE = moment().utc().subtract(7, 'days');
const INIT_TO_VALUE = moment().utc();

const UsageStatistics: FC<Props> = props => {
  const dispatch = useDispatch();
  const { appId } = useParams<{ appId: string }>();

  const [state, setState] = useState<State>({
    conversationSum: 0,
    messageSum: 0,
    distributionSum: 0,
    disabledExports: {},
    queryParams: {
      from: INIT_FROM_VALUE,
      to: INIT_TO_VALUE,
      timezoneOffset: getUsersTimezoneOffsetToUtc(),
    },
  });

  const [grouping, setGrouping] = useState<DynamicGroupByModel>();

  useEffect(() => {
    if (!props.application.id) {
      props.getApplication(appId);
    }
    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, grouping]);

  useEffect(() => {
    const { data: distributionData } = hourlyDistributionOfAllMessagesAvgCount;
    const { data: conversationsAndMessagesData } = dailyCountOfConversationsAndMessages;
    if (conversationsAndMessagesData.length > 0 && distributionData.length > 0) {
      const calc = {
        ...getSum(conversationsAndMessagesData[0].data, 'conversationSum', 'y'),
        ...getSum(conversationsAndMessagesData[1].data, 'messageSum', 'y'),
        ...getSum(distributionData, 'distributionSum', 'distribution'),
      };

      setState({
        ...state,
        ...calc,
        ...getDisabledExports(calc),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hourlyDistributionOfAllMessagesAvgCount, props.dailyCountOfConversationsAndMessages]);

  const getSum = (data: object[], sumType: string, valueAttribute: string) => {
    const sum = data.reduce((prev: number, curr: any) => {
      return prev + curr[valueAttribute];
    }, 0);

    return { [sumType]: sum };
  };

  const getSumForDistribution = () => {
    let data: any = [];

    const { hourlyDistributionOfAllMessagesAvgCount } = props;

    // eslint-disable-next-line no-return-assign
    hourlyDistributionOfAllMessagesAvgCount.data.map(
      (items: any) => (data = [...data, ...items.data]),
    );

    const sum = data.reduce((prev: number, curr: any) => {
      return prev + curr.distribution;
    }, 0);

    return sum;
  };

  const getDisabledExports = (calc: { [key: string]: number }) => {
    const { conversationSum, messageSum, distributionSum } = calc;

    const disabledExports = {
      dailyCountOfConversationsAndMessages: conversationSum === 0 && messageSum === 0,
      hourlyDistributionOfAllMessagesPercent: distributionSum === 0,
      hourlyDistributionOfAllMessagesAvgCount: distributionSum === 0,
    };

    return { disabledExports: disabledExports };
  };

  const getAllData = async () => {
    const { queryParams } = state;
    const formattedQueryParams = formatDatesToIsoString(queryParams);

    props.getDailyCountOfConversationsAndMessages(appId, formattedQueryParams, grouping);
    props.getHourlyDistributionOfAllMessagesPercent(appId, formattedQueryParams, grouping);
    props.getHourlyDistributionOfAllMessagesAvgCount(appId, formattedQueryParams, grouping);
  };

  const handleDateChange = async (from: string, to: string) => {
    setState(prevState => ({
      ...state,
      queryParams: {
        ...prevState.queryParams,
        from,
        to,
      },
    }));
  };

  const handleDynamicGroupByChange = async (grouping: DynamicGroupByModel) => {
    setGrouping(grouping);
  };

  const exportData = (switches: SwitchModel[]) => {
    const exportBase = async (
      exportFunc: (
        applicationId: number,
        params: UsageStatisticsQueryParams,
        grouping: DynamicGroupByModel,
      ) => void,
    ) => {
      const { id } = props.application;
      const { queryParams } = state;
      const formattedQueryParams = formatDatesToIsoString(queryParams);
      await exportFunc(id, formattedQueryParams, grouping || {});
    };

    switches.map((el: SwitchModel) => {
      switch (el.id) {
        case 'dailyCountOfConversationsAndMessages':
          return exportBase(props.exportDailyCountOfConversationsAndMessages);
        // exportDailyCountOfConversationsAndMessages();
        case 'hourlyDistributionOfAllMessagesPercent':
          return exportBase(props.exportHourlyDistributionOfAllMessagesPercent);
        case 'hourlyDistributionOfAllMessagesAvgCount':
          return exportBase(props.exportHourlyDistributionOfAllMessagesAvgCount);
        default:
          return null;
      }
    });
  };

  useEffect(() => {
    if (props.dailyCountOfConversationsAndMessagesExport.data.length === 0) {
      return;
    }
    saveFile(
      props.dailyCountOfConversationsAndMessagesExport.data,
      fileNames.USAGE_STATISTICS_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES,
      'csv',
    );
  }, [props.dailyCountOfConversationsAndMessagesExport.data]);

  useEffect(() => {
    if (props.hourlyDistributionOfAllMessagesPercentExport.data.length === 0) {
      return;
    }
    saveFile(
      props.hourlyDistributionOfAllMessagesPercentExport.data,
      fileNames.USAGE_STATISTICS_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT,
      'csv',
    );
  }, [props.hourlyDistributionOfAllMessagesPercentExport.data]);

  useEffect(() => {
    if (props.hourlyDistributionOfAllMessagesAvgCountExport.data.length === 0) {
      return;
    }
    saveFile(
      props.hourlyDistributionOfAllMessagesAvgCountExport.data,
      fileNames.USAGE_STATISTICS_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_COUNT,
      'csv',
    );
  }, [props.hourlyDistributionOfAllMessagesAvgCountExport.data]);

  const {
    dailyCountOfConversationsAndMessages,
    dailyCountOfConversationsAndMessagesExport,
    hourlyDistributionOfAllMessagesPercent,
    hourlyDistributionOfAllMessagesPercentExport,
    hourlyDistributionOfAllMessagesAvgCount,
    hourlyDistributionOfAllMessagesAvgCountExport,
    t,
  } = props;

  const { conversationSum, messageSum, disabledExports } = state;

  const isExporting =
    dailyCountOfConversationsAndMessagesExport.loading ||
    hourlyDistributionOfAllMessagesPercentExport.loading ||
    hourlyDistributionOfAllMessagesAvgCountExport.loading;

  return (
    <Fragment>
      <PageHeader title={t('common.usageStatistics')} />

      <Card className="animated fadeIn mt-0">
        <CardHeader>
          <Row className="align-items-center">
            <Col>
              <Filter handleDateChange={handleDateChange} getAllData={getAllData} />
            </Col>
            <Col className="d-flex justify-content-end">
              <DynamicGroupBy
                appId={appId}
                isMulti
                handleDynamicGroupByChange={handleDynamicGroupByChange}
                configurationNameSpace={configurationConstants.analyticsIntentStatisticsFilter}
              />
            </Col>
            <Col className="d-flex justify-content-end" lg="1">
              <Spinner loading={isExporting} />
              <Export
                export={exportData}
                isExporting={isExporting}
                disabledExports={disabledExports}
              />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <DailyCountOfConversationsAndMessages
            conversationSum={conversationSum}
            messageSum={messageSum}
            data={dailyCountOfConversationsAndMessages}
          />
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <HourlyDistributionOfAllMessages
            distributionSum={getSumForDistribution()}
            dataPercent={hourlyDistributionOfAllMessagesPercent}
            dataCount={hourlyDistributionOfAllMessagesAvgCount}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  dailyCountOfConversationsAndMessages: state.dailyCountOfConversationsAndMessages,
  dailyCountOfConversationsAndMessagesExport: state.exportDailyCountOfConversationsAndMessages,
  hourlyDistributionOfAllMessagesPercent: state.hourlyDistributionOfAllMessagesPercent,
  hourlyDistributionOfAllMessagesPercentExport: state.exportHourlyDistributionOfAllMessagesPercent,
  hourlyDistributionOfAllMessagesAvgCount: state.hourlyDistributionOfAllMessagesAvgCount,
  hourlyDistributionOfAllMessagesAvgCountExport:
    state.exportHourlyDistributionOfAllMessagesAvgCount,
});

const mapDispatchToProps = {
  getApplication,
  getDailyCountOfConversationsAndMessages,
  exportDailyCountOfConversationsAndMessages,
  getHourlyDistributionOfAllMessagesPercent,
  exportHourlyDistributionOfAllMessagesPercent,
  getHourlyDistributionOfAllMessagesAvgCount,
  exportHourlyDistributionOfAllMessagesAvgCount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(UsageStatistics)));
