import { Dispatch } from 'redux';
import { QueryParams } from 'src/shared/models';

import {
  deleteMessageToAdmin as deleteMessageToAdminService,
  getMessageToAdmins as getMessageToAdminsService,
  getMessageToAdmin as getMessageToAdminService,
  updateMessageToAdmin as updateMessageToAdminService,
} from 'src/redux/services/message-to-admin.service';
import { MessageToAdminModel } from 'src/shared/models/message-to-admin.model';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';

export const GET_MESSAGE_TO_ADMIN = 'GET_MESSAGE_TO_ADMIN';
export const GET_MESSAGE_TO_ADMIN_SUCCESS = 'GET_MESSAGE_TO_ADMIN_SUCCESS';
export const GET_MESSAGE_TO_ADMIN_ERROR = 'GET_MESSAGE_TO_ADMIN_ERROR';

export const DELETE_MESSAGE_TO_ADMIN = 'DELETE_MESSAGE_TO_ADMIN';
export const DELETE_MESSAGE_TO_ADMIN_REQUEST = 'DELETE_MESSAGE_TO_ADMIN_REQUEST';
export const DELETE_MESSAGE_TO_ADMIN_SUCCESS = 'DELETE_MESSAGE_TO_ADMIN_SUCCESS';
export const DELETE_MESSAGE_TO_ADMIN_ERROR = 'DELETE_MESSAGE_TO_ADMIN_ERROR';

export const READ_MESSAGE_TO_ADMIN = 'MessageToAdmins/READ_MESSAGE_TO_ADMIN';
export const READ_MESSAGE_TO_ADMIN_SUCCESS = 'MessageToAdmins/READ_MESSAGE_TO_ADMIN_SUCCESS';
export const READ_MESSAGE_TO_ADMIN_ERROR = 'MessageToAdmins/READ_MESSAGE_TO_ADMIN_ERROR';
export const UPDATE_MESSAGE_TO_ADMIN = 'MessageToAdmins/UPDATE_MESSAGE_TO_ADMIN';
export const UPDATE_MESSAGE_TO_ADMIN_SUCCESS = 'MessageToAdmins/UPDATE_MESSAGE_TO_ADMIN_SUCCESS';
export const UPDATE_MESSAGE_TO_ADMIN_ERROR = 'MessageToAdmins/UPDATE_MESSAGE_TO_ADMIN_ERROR';
export const REVERT_MESSAGE_TO_ADMIN = 'MessageToAdmins/CLEAR_MESSAGE_TO_ADMIN';
export const REVERT_MESSAGE_TO_ADMIN_QUESTIONS =
  'MessageToAdmins/REVERT_MESSAGE_TO_ADMIN_QUESTIONS';
export const REVERT_MESSAGE_TO_ADMIN_QUESTION_ANSWERS =
  'MessageToAdmins/REVERT_MESSAGE_TO_ADMIN_QUESTIONS';

export const getMessagesToAdmins = (
  appId: string,
  queryParams: QueryParams,
  grouping: DynamicGroupByModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_MESSAGE_TO_ADMIN,
    });

    const importConfigurations = (await getMessageToAdminsService(
      appId,
      queryParams,
      grouping,
    )) as MessageToAdminModel[];

    try {
      dispatch({
        type: GET_MESSAGE_TO_ADMIN_SUCCESS,
        payload: importConfigurations,
      });
    } catch (error) {
      dispatch({
        type: GET_MESSAGE_TO_ADMIN_ERROR,
        error,
      });
    }
  };
};

export const deleteMessageToAdmin = (appId: string, MessageToAdminId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_MESSAGE_TO_ADMIN,
    });

    try {
      await deleteMessageToAdminService(appId, MessageToAdminId.toString());
      dispatch({
        type: DELETE_MESSAGE_TO_ADMIN_SUCCESS,
        payload: { MessageToAdminId },
      });
    } catch (error) {
      dispatch({
        type: DELETE_MESSAGE_TO_ADMIN_ERROR,
        error,
      });
    }
  };
};

export const getMessageToAdmin = (appId: string, messageToAdminId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: READ_MESSAGE_TO_ADMIN,
    });

    try {
      dispatch({
        type: READ_MESSAGE_TO_ADMIN_SUCCESS,
        payload: await getMessageToAdminService(appId, messageToAdminId),
      });
    } catch (error) {
      dispatch({
        type: READ_MESSAGE_TO_ADMIN_ERROR,
        error,
      });
    }
  };
};

export const updateMessageToAdmin = (
  appId: string,
  MessageToAdminId: string,
  data: MessageToAdminModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_MESSAGE_TO_ADMIN,
    });

    try {
      dispatch({
        type: UPDATE_MESSAGE_TO_ADMIN_SUCCESS,
        payload: await updateMessageToAdminService(appId, MessageToAdminId, data),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_MESSAGE_TO_ADMIN_ERROR,
        error,
      });
    }
  };
};

export const revertMessageToAdmin = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REVERT_MESSAGE_TO_ADMIN,
    });
  };
};
