import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Card } from 'reactstrap';

import { ViewMode } from 'src/shared/constants/training-data.constants';

import { StateModel } from 'src/shared/models/default-state.model';
import { TrainingDataModel } from 'src/shared/models/training-data.model';

import { updateTrainingData, deleteTrainingData } from 'src/redux/actions/training-data.action';

import TrainingDataCardHeader from './TrainingDataCardHeader';
import TrainingDataCardBody from './TrainingDataCardBody';
import ConfirmModal from 'src/shared/components/modals/ConfirmModal';

interface Props {
  trainingData: TrainingDataModel;
  updateTrainingData: Function;
  deleteTrainingData: Function;
  deleteEvent: StateModel;
  viewMode: ViewMode;
}

const TrainingDataCard: React.FC<Props> = ({
  trainingData,
  updateTrainingData,
  deleteTrainingData,
  deleteEvent,
  viewMode,
}) => {
  const { appId } = useParams<{ appId: string }>();
  const { t } = useTranslation();

  const [isBodyVisible, setBodyVisible] = useState<boolean>(false);
  const [sentenceToAdd, setSentenceToAdd] = useState<string>('');
  const [alertText, setAlertText] = useState<string>('');
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [selectedTrainingData, setSelectedTrainingData] = useState<TrainingDataModel | undefined>(
    undefined,
  );

  const { id, sentences } = trainingData;

  const colSize =
    isBodyVisible || viewMode === ViewMode.Row
      ? 12
      : viewMode === ViewMode.Normal
      ? 4
      : viewMode === ViewMode.Compact
      ? 3
      : 3;
  const chevronIcon = isBodyVisible ? 'fas fa-chevron-up' : 'fas fa-chevron-down';

  const handleSentenceAdd = async () => {
    const { id: intentId, sentences } = trainingData;

    setAlertText('');

    if (!sentenceToAdd) return;

    if (sentences.includes(sentenceToAdd)) {
      setAlertText(t('trainingData.sentenceAlreadyExists'));
      return;
    }

    const newTrainingDataValues = {
      ...trainingData,
      sentences: [...sentences, sentenceToAdd],
    };

    await updateTrainingData(appId, intentId, newTrainingDataValues);
    setSentenceToAdd('');
  };

  const handleSentenceUpdate = async (currentSentence: string, newSentence?: string) => {
    const { id: intentId, sentences } = trainingData;

    if (currentSentence === newSentence) return;

    const index = sentences.indexOf(currentSentence);

    if (index > -1) {
      const tempSentences = sentences;

      if (newSentence) {
        tempSentences.splice(index, 1, newSentence);
      } else {
        tempSentences.splice(index, 1);
      }

      const newTrainingDataValues = {
        ...trainingData,
        sentences: [...tempSentences],
      };

      await updateTrainingData(appId, intentId, newTrainingDataValues);
    }
  };

  const handleTrainingDataDelete = async () => {
    if (selectedTrainingData) {
      await deleteTrainingData(appId, selectedTrainingData.id);
      setConfirmModalOpen(false);
    }
  };

  return (
    <Fragment>
      <Col lg={colSize} key={id}>
        <Card>
          <TrainingDataCardHeader
            trainingData={trainingData}
            updateTrainingData={updateTrainingData}
            chevronIcon={chevronIcon}
            setBodyVisible={setBodyVisible}
            setConfirmModalOpen={setConfirmModalOpen}
            setSelectedTrainingData={setSelectedTrainingData}
          />

          {isBodyVisible && (
            <TrainingDataCardBody
              sentences={sentences}
              sentenceToAdd={sentenceToAdd}
              setSentenceToAdd={setSentenceToAdd}
              handleSentenceAdd={handleSentenceAdd}
              handleSentenceUpdate={handleSentenceUpdate}
              alertText={alertText}
            />
          )}
        </Card>
      </Col>

      <ConfirmModal
        title={t('trainingData.deleteTrainingData')}
        text={t('trainingData.deleteTrainingDataConfirm')}
        item={selectedTrainingData?.name}
        isOpen={isConfirmModalOpen}
        confirm={handleTrainingDataDelete}
        cancel={() => setConfirmModalOpen(false)}
        isLoading={deleteEvent.loading}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  deleteEvent: state.trainingData.delete,
  viewMode: state.trainingData.viewMode,
});

const mapDispatchToProps = {
  updateTrainingData,
  deleteTrainingData,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainingDataCard);
