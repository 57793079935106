import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { Formik, Form, FormikValues } from 'formik';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';

import { createHelpdeskInstantAnswer } from 'src/redux/actions/helpdesk-instant-answers.action';
import { helpdeskInstantAnswerValidatorSchema } from 'src/shared/schemas/validation.schema';
import { Spinner, TextArea, TextField } from 'src/shared/components';

interface Props {
  isOpen: boolean;
  toggleModalState: Function;
  onSaved?: Function;
  createHelpdeskInstantAnswer: Function;
}

const HelpdeskInstantAnswerModalForm: React.FC<Props> = ({
  isOpen,
  toggleModalState,
  onSaved,
  createHelpdeskInstantAnswer,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const getFormInitValues = () => ({
    title: '',
    message: '',
  });

  const handleSubmit = async ({ ...values }: FormikValues) => {
    await createHelpdeskInstantAnswer(appId, values);
    if (onSaved) {
      onSaved();
    }
    toggleModalState(false);
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{t('helpdesk.instantAnswers')}</ModalHeader>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={getFormInitValues()}
        validationSchema={helpdeskInstantAnswerValidatorSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="title">{t('common.title')}</Label>
                    <TextField name="title" />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="message">{t('common.message')}</Label>
                    <TextArea name="message" />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>

            <ModalFooter>
              <Spinner loading={isSubmitting} />

              <Button type="submit" color="success" disabled={isSubmitting}>
                {t('common.save')}
              </Button>

              <Button color="secondary" onClick={() => toggleModalState(false)}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createHelpdeskInstantAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpdeskInstantAnswerModalForm);
