import React, { Component, Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardHeader, Alert } from 'reactstrap';
import queryString from 'query-string';
import Pagination from 'react-js-pagination';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import {
  getUnrecognizedSentences,
  exportUnrecognizedSentences,
  resetUnrecognizedSentences,
} from 'src/redux/actions/unrecognized-sentences.action';

import { QueryParams } from 'src/shared/models/query-params.model';

import Table from './components/Table';
import Filter from './components/Filter';
import Export from './components/Export';
import Reset from './components/Reset';
import { Spinner, PageHeader } from 'src/shared/components';

import './UnrecognizedSentences.scss';

interface Props extends RouteComponentProps<{ id: string }>, WithTranslation {
  getApplication: Function;
  application: ApplicationModel;
  getUnrecognizedSentences: Function;
  unrecognizedSentences: any;
  exportUnrecognizedSentences: Function;
  exportState: any;
  resetUnrecognizedSentences: Function;
  resetState: any;
}

interface State {
  queryParams: QueryParams;
  activePage: number;
  pageBeforeSwitch: number;
  switchChecked: boolean;
}

class UnrecognizedSentences extends Component<Props, State> {
  state: State = {
    queryParams: {
      size: 20,
      page: 0,
      limit: 20,
      status: undefined,
    },
    activePage: 1,
    pageBeforeSwitch: 1,
    switchChecked: false,
  };

  async componentDidMount() {
    if (!this.props.application.id) {
      await this.props.getApplication(this.props.match.params.id);
    }

    await this.setCurrentPage();
    this.getUnrecognizedSentences();
  }

  getUnrecognizedSentences() {
    const { id } = this.props.application;
    const { queryParams } = this.state;

    this.props.getUnrecognizedSentences(id, queryParams);
  }

  async setCurrentPage() {
    const queryValues = queryString.parse(this.props.location.search);

    if (queryValues.page) {
      await this.setState(prevState => ({
        queryParams: {
          ...prevState.queryParams,
          page: Number(queryValues.page),
        },
        activePage: Number(queryValues.page) + 1,
      }));
    }
  }

  handleSwitchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const queryValues = queryString.parse(this.props.location.search);
    await this.setState({ switchChecked: checked });

    const status = checked ? 'USER_SUGGESTED' : undefined;
    const pageBeforeSwitch = queryValues.page ? Number(queryValues.page) : 0;

    if (checked) {
      await this.setState(prevState => ({
        queryParams: {
          ...prevState.queryParams,
          status,
        },
        pageBeforeSwitch,
      }));

      this.pageChange(1);
    }

    if (!checked) {
      await this.setState(prevState => ({
        queryParams: {
          ...prevState.queryParams,
          status,
        },
      }));

      this.pageChange(this.state.pageBeforeSwitch + 1);
    }
  };

  pageChange = async (index: number) => {
    await this.setState(prevState => ({
      queryParams: {
        ...prevState.queryParams,
        page: index - 1,
      },
      activePage: index,
    }));

    const { queryParams } = this.state;

    this.props.history.push(`${window.location.hash}/?page=${queryParams.page}`);

    this.getUnrecognizedSentences();
    window.scrollTo(0, 0);
  };

  renderAlert = (loaded: boolean, totalElements: number, switchChecked: boolean) => {
    const { t } = this.props;

    if (loaded && !totalElements && !switchChecked) {
      return (
        <Alert color="info text-center mt-0 mb-0">
          {t('unrecognizedSentences.noUnrecognizedSentencesFound')}
        </Alert>
      );
    }

    if (loaded && !totalElements && switchChecked) {
      return (
        <Alert color="info text-center mt-0 mb-0">
          {t('unrecognizedSentences.noUserSuggestionsFound')}
        </Alert>
      );
    }

    return null;
  };

  render() {
    const { data: sentences, loading, loaded } = this.props.unrecognizedSentences;
    const { exportState, resetState, t } = this.props;
    const { activePage, switchChecked, queryParams } = this.state;

    return (
      <div className="animated fadeIn">
        <PageHeader title={t('common.unrecognizedSentences')} />

        <Card>
          <CardHeader>
            <Row>
              <Col className="d-flex align-items-center justify-content-start">
                <Filter
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.handleSwitchChange(e);
                  }}
                  switchChecked={switchChecked}
                  disabled={loaded && !sentences.content.length && !switchChecked}
                />
              </Col>
              <Col className="d-flex align-items-center justify-content-end">
                <Spinner loading={exportState.loading || resetState.loading} />

                <Reset
                  getUnrecognizedSentences={() => this.getUnrecognizedSentences()}
                  disabled={
                    resetState.loading || (loaded && !sentences.content.length && !switchChecked)
                  }
                />
                <Export
                  disabled={
                    exportState.loading || (loaded && !sentences.content.length && !switchChecked)
                  }
                />
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {loading && (
              <div className="d-flex justify-content-center">
                <Spinner loading={loading} size="2x" />
              </div>
            )}

            {loaded && sentences.content && sentences.content.length ? (
              <Fragment>
                <Table
                  unrecognizedSentences={sentences.content}
                  appId={this.props.application.id}
                />

                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={queryParams.limit}
                  totalItemsCount={sentences.totalElements}
                  pageRangeDisplayed={5}
                  onChange={this.pageChange}
                />
              </Fragment>
            ) : null}

            {this.renderAlert(loaded, sentences.content && sentences.content.length, switchChecked)}
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  unrecognizedSentences: state.getUnrecognizedSentences,
  exportState: state.exportUnrecognizedSentences,
  resetState: state.resetUnrecognizedSentences,
});

const mapDispatchToProps = {
  getApplication,
  getUnrecognizedSentences,
  exportUnrecognizedSentences,
  resetUnrecognizedSentences,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(UnrecognizedSentences)));
