import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';
import { Formik, Form, FormikValues } from 'formik';

import { createConversationFlowVariable } from 'src/redux/actions/conversation-flow-variables.action';
import { createConversationFlowVariableSchema } from 'src/shared/schemas/validation.schema';
import { TextField, Spinner, ReactSelect } from 'src/shared/components';

interface Props {
  isOpen: boolean;
  platforms: string[];
  toggleModal: (saved?: boolean) => void;
  createConversationFlowVariable: Function;
  messagingPlatform: string;
}

const CustomFieldModal: React.FC<Props> = ({
  isOpen,
  toggleModal,
  createConversationFlowVariable,
  messagingPlatform,
  platforms,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  const modalTitle = 'conversationFlowVariables.createConversationFlowVariables';
  const submitButtonText = 'common.save';

  const getFormInitValues = () => ({
    key: '',
    value: '',
    platform: { label: messagingPlatform, value: messagingPlatform },
  });

  const handleSubmit = async ({ platform, ...values }: FormikValues) => {
    await createConversationFlowVariable(appId, platform.value, values);
    toggleModal(true);
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{t(modalTitle)}</ModalHeader>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={getFormInitValues()}
        validationSchema={createConversationFlowVariableSchema()}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="key">{t('common.platform')}</Label>
                    <ReactSelect
                      name="platform"
                      options={platforms.map(platform => ({ label: platform, value: platform }))}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="key">{t('common.name')}</Label>
                    <TextField name="key" />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="value">{t('common.value')}</Label>
                    <TextField name="value" />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>

            <ModalFooter>
              <Spinner loading={isSubmitting} />

              <Button type="submit" color="success" disabled={isSubmitting}>
                {t(submitButtonText)}
              </Button>

              <Button color="secondary" onClick={() => toggleModal()}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapDispatchToProps = {
  createConversationFlowVariable,
};

export default connect(null, mapDispatchToProps)(CustomFieldModal);
