import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExportButton } from 'src/shared/components';

interface Props {
  exportIntentStatistics: Function;
  exporting: boolean;
  exported: boolean;
  disabled: boolean;
}

const IntentStatisticsExport: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { exportIntentStatistics, exporting, disabled } = props;

  return (
    <ExportButton
      className="ml-2"
      disabled={disabled}
      onClick={() => exportIntentStatistics()}
      exportTooltipLabel={t('common.exportToCsvForPeriod')}
      exporting={exporting}
    />
  );
};

export default IntentStatisticsExport;
