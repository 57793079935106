import React, { useMemo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { FormikValues, useFormikContext } from 'formik';

import {
  POSITIONS_PER_PAGE_MAX,
  POSITIONS_PER_PAGE_MIN,
} from 'src/shared/constants/questionnaire.constans';

import { SelectModel } from 'src/shared/models';
import { Switch, TextField, ReactSelect, OptionModel } from 'src/shared/components';

const ShowPositionMessageForm = () => {
  const { t } = useTranslation();
  const { values, setFieldValue }: FormikValues = useFormikContext();

  const { showAll, showSize, carouselView } = values;

  const getSelectOptions = () => {
    const options: SelectModel[] = [];

    for (let i = POSITIONS_PER_PAGE_MIN; i <= POSITIONS_PER_PAGE_MAX; i += 1) {
      options.push({ label: i.toString(), value: i });
    }

    return options;
  };

  const selectOptions = useMemo(() => getSelectOptions(), []);

  return (
    <Fragment>
      <FormGroup>
        <Switch
          name="showAll"
          variant="pill"
          color="info"
          label="questionnaire.showAllPositions"
          labelPosition="after"
        />
      </FormGroup>

      {!showAll && (
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="nextPageButton">{t('questionnaire.nextPageButtonText')}</Label>
              <TextField name="nextPageButton" />
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <Label for="showSize">{t('questionnaire.numberOfPositionsPerPage')}</Label>
              <ReactSelect
                name="showSize"
                options={selectOptions}
                value={{ label: showSize.toString(), value: showSize }}
                onChange={(option: OptionModel) =>
                  setFieldValue('showSize', (option as SelectModel).value)
                }
              />
            </FormGroup>
          </Col>
        </Row>
      )}

      <FormGroup>
        <Switch
          name="carouselView"
          variant="pill"
          color="info"
          label="questionnaire.carouselView"
          labelPosition="after"
        />
      </FormGroup>


      {carouselView && (
        <Row form>
          <Col md={4}>
            <FormGroup>
              <Label for="carouselButtonText">{t('questionnaire.carouselButtonText')}</Label>
              <TextField name="carouselButtonText"/>
            </FormGroup>
          </Col>

          <Col md={8}>
            <FormGroup>
              <Label for="carouselDefaultImageUrl">{t('questionnaire.carouselDefaultImageUrl')}</Label>
              <TextField name="carouselDefaultImageUrl"/>
            </FormGroup>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default ShowPositionMessageForm;
