import { Dispatch } from 'redux';
import { PageQueryParams } from 'src/shared/models';
import { getReport, getReports } from '../services/event.service';

export const GET_EVENT_REPORT = 'GET_EVENT_REPORT';
export const GET_EVENT_REPORT_SUCCESS = 'GET_EVENT_REPORT_SUCCESS';
export const GET_EVENT_REPORT_ERROR = 'GET_EVENT_REPORT_ERROR';
export const CLEAR_EVENT_REPORT = 'CLEAR_EVENT_REPORT';

export const getEventReport = (appId: string, eventId: string, queryParams: PageQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_EVENT_REPORT,
    });

    try {
      const response = await getReport(appId, eventId, queryParams);
      const report = response;

      for (var j = 0; j < (report.subscribers as any).content.length; j++) {
        (report.subscribers as any).content[j] = {
          ...(report.subscribers as any).content[j],
          ...{ seqNumber: queryParams.page * queryParams.size + j + 1 },
        };
      }

      dispatch({
        type: GET_EVENT_REPORT_SUCCESS,
        payload: [report],
      });
    } catch (error) {
      dispatch({
        type: GET_EVENT_REPORT_ERROR,
        error,
      });
    }
  };
};

export const getEventReports = (appId: string, eventId: string, queryParams: PageQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_EVENT_REPORT,
    });

    try {
      const response = await getReports(appId, eventId, queryParams);

      for (var i = 0; i < response.length; i++) {
        const report = response[i];
        for (var j = 0; j < report.subscribers.length; j++) {
          report.subscribers[j] = {
            ...report.subscribers[j],
            ...{ seqNumber: j + 1 },
          };
        }
      }

      dispatch({
        type: GET_EVENT_REPORT_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_EVENT_REPORT_ERROR,
        error,
      });
    }
  };
};

export const revertEventReport = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_EVENT_REPORT,
    });
  };
};
