import { Dispatch } from 'redux';

import {
  getSalaryAdvancesService,
  exportSalaryAdvances as exportSalaryAdvancesService,
} from '../services/salaryAdvances.service';
import { QueryParams } from '../../shared/models/query-params.model';
import { SalaryAdvanceFilters } from '../../shared/models';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';
import { getNewFilters } from 'src/shared/utils';

export const GET_SALARY_ADVANCES_REQUEST = 'salaryAdvances/GET_SALARY_ADVANCES_REQUEST';
export const GET_SALARY_ADVANCES_SUCCESS = 'salaryAdvances/GET_SALARY_ADVANCES_SUCCESS';
export const GET_SALARY_ADVANCES_ERROR = 'salaryAdvances/GET_SALARY_ADVANCES_ERROR';
export const SALARY_ADVANCES_CLEAR_ERROR = 'salaryAdvances/SALARY_ADVANCES_CLEAR_ERROR';

export const getSalaryAdvances = (
  appId: string,
  salaryAdvanceFilters: SalaryAdvanceFilters,
  queryParams: QueryParams,
  grouping: DynamicGroupByModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_SALARY_ADVANCES_REQUEST,
    });

    const salaryAdvances = await getSalaryAdvancesService(
      appId,
      { filters: { ...getNewFilters(salaryAdvanceFilters), ...grouping } },
      {
        ...queryParams,
        from: salaryAdvanceFilters.filters.startDate,
        to: salaryAdvanceFilters.filters.endDate,
      },
    );

    try {
      dispatch({
        type: GET_SALARY_ADVANCES_SUCCESS,
        payload: salaryAdvances,
      });
    } catch (error) {
      dispatch({
        type: GET_SALARY_ADVANCES_ERROR,
        error,
      });
    }
  };
};

export const exportSalaryAdvances = (
  appId: string,
  salaryAdvanceFilters: SalaryAdvanceFilters,
  grouping: DynamicGroupByModel,
  queryParams?: object,
) => {
  return async () => {
    const salaryAdvances = await exportSalaryAdvancesService(
      appId,
      { filters: { ...getNewFilters(salaryAdvanceFilters), ...grouping } },
      queryParams,
    );
    return salaryAdvances;
  };
};
