import React, { useEffect, useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { StateModel } from 'src/shared/models/default-state.model';

import { ViewMode } from 'src/shared/constants/training-data.constants';
import { VIEW_MODE_KEY } from 'src/shared/constants/questionnaire.constans';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import {
  getLabelCategories,
  createLabelCategory,
  setLabelsViewMode,
} from 'src/redux/actions/questionnaire.action';

import LabelsHeader from './components/LabelsHeader';
import LabelCategoryCards from './components/LabelCategoryCards';
import LabelCategoryModal from './components/LabelCategoryModal';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  labelCategories: StateModel;
  getLabelCategories: Function;
  createLabelCategory: Function;
  setLabelsViewMode: Function;
  viewMode: ViewMode;
}

const Labels: React.FC<Props> = ({
  application,
  getApplication,
  labelCategories,
  getLabelCategories,
  createLabelCategory,
  setLabelsViewMode,
  viewMode,
}) => {
  const { appId } = useParams();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }

    setStoredViewMode();
    getLabelCategories(appId);
    /* eslint-disable-next-line */
  }, []);

  const setStoredViewMode = () => {
    const storedViewMode = localStorage.getItem(VIEW_MODE_KEY);

    if (storedViewMode && viewMode !== storedViewMode) {
      setLabelsViewMode(storedViewMode);
    }
  };

  const createCategory = async (name: string) => {
    await createLabelCategory(appId, name);
    getLabelCategories(appId);
    setModalOpen(false);
  };

  return (
    <Fragment>
      <div className="animated fadeIn">
        <LabelsHeader
          setLabelsViewMode={setLabelsViewMode}
          viewMode={viewMode}
          setModalOpen={setModalOpen}
        />
        <LabelCategoryCards labelCategories={labelCategories.data} />
      </div>

      <LabelCategoryModal
        isOpen={isModalOpen}
        toggleModal={() => setModalOpen(false)}
        createCategory={createCategory}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  labelCategories: state.questionnaireLabelCategories.resources,
  viewMode: state.questionnaireLabelCategories.viewMode,
});

const mapDispatchToProps = {
  getApplication,
  getLabelCategories,
  createLabelCategory,
  setLabelsViewMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(Labels);
