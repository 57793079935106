import React, { FunctionComponent, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';

import { ApplicationSettingModel } from '../../../redux/actions/application-settings.action';

import CheckPermission from '../../../shared/components/CheckPermission';

import styles from '../ApplicationSettings.module.scss';

interface Props {
  setting: ApplicationSettingModel;
  handleChange: Function;
  handleSubmit: Function;
  handleDelete: Function;
}

const SettingInput: FunctionComponent<Props> = ({
  setting,
  handleChange,
  handleSubmit,
  handleDelete,
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <InputGroup className={styles['input-group']}>
        <Label className="font-weight-bold" for={setting.key}>
          {setting.key}
        </Label>
        <Input
          type="text"
          id={setting.key}
          name={setting.key}
          defaultValue={setting.value}
          onChange={e => {
            handleChange(e);
          }}
        />

        <InputGroupAddon addonType="append">
          <CheckPermission variant="enableIf" permissions={['application-settings_update']}>
            <Button
              id={setting.key}
              name={setting.key}
              className={styles['btn-save']}
              color="primary"
              onClick={e => handleSubmit(e)}
            >
              {t('common.save')}
            </Button>
          </CheckPermission>

          <CheckPermission variant="displayIf" permissions={['application-settings_delete']}>
            <Button
              id={setting.key}
              name={setting.key}
              className={styles['btn-delete']}
              color="danger"
              onClick={e => handleDelete(e)}
            >
              {t('common.delete')}
            </Button>
          </CheckPermission>
        </InputGroupAddon>
      </InputGroup>
    </Fragment>
  );
};

export default SettingInput;
