import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Form, Formik, FormikValues } from 'formik';

import {
  defaultLocationMessageFormInitValues,
  messageActionTypeToDisplay,
  MessageTypeEnum,
} from 'src/shared/constants/questionnaire.constans';
import { getSelectOptionObjects } from 'src/shared/utils/misc.util';

import { SelectModel } from 'src/shared/models/common.model';
import {
  AttributeValidatorModel,
  QuestionnaireMessage,
  QuestionnaireMessageMetadataValidatorType,
  RegexReplacerModel,
} from 'src/shared/models/questionnaire.model';
import { TrainingDataModel } from 'src/shared/models/training-data.model';

import {
  closedEndedQuestionSchema,
  defaultMessageSchema,
  documentUploaderMessageSchema,
  documentViewMessageSchema,
  dynamicLabelMessageSchema,
  exportApplicationToAtsMessageSchema,
  imageMessageSchema,
  interviewEventMessageSchema,
  locationCityMessageSchema,
  locationCountyMessageSchema,
  locationMessageSchema,
  locationPartOfCountryMessageSchema,
  logicContollerMessageSchema,
  parentConditionsWithIntentAndTextSchema,
  openEndedQuestionSchema,
  showPositionMessageSchema,
  simpleMessageSchema,
  startMessageSchema,
  questionnaireContextControllerSchema,
  questionnaireAnalyticsControllerSchema,
} from 'src/shared/schemas/validation.schema';

import Spinner from 'src/shared/components/Spinner';
import { Switch } from '../../../shared/components';
import { TextField } from 'src/shared/components';
import {
  DefaultForm,
  PositionPrefilterCriteriasForm,
  LocationMessageForm,
  SimpleMessageForm,
  ExtraAnswersForm,
  LocationCityButtonTextsForm,
  LogicControllerForm,
  OpenEndedQuestionForm,
  ShowPositionMessageForm,
  DocumentUploaderForm,
  DynamicLabelMessageForm,
  LabelPagerForm,
  InterviewEventPagerForm,
  DocumentViewForm,
  LocationPagerForm,
  ExternalServiceCallForm,
  ContextControllerForm,
  AnalyticsControllerForm,
} from './forms';
import { ContextControllerContextAction } from './forms/ContextControllerForm';
import { KeyValuePair } from 'src/shared/models';
import { AnalyticsControllerModel } from './forms/AnalyticsControllerForm';
import ActionControllerForm, { ActionControllerModel } from './forms/ActionControllerForm';

interface Props {
  messages: QuestionnaireMessage[];
  isOpen: boolean;
  toggle: Function;
  modalEvent: string;
  isSimpleMessage: boolean;
  isOpenEnded: boolean;
  isClosedEnded: boolean;
  isLogicController: boolean;
  isLocationPartOfCountry: boolean;
  isLocationCounty: boolean;
  isLocationCity: boolean;
  isLocationMessage: boolean;
  isShowPositionMessage: boolean;
  isPositionApplyController: boolean;
  isPositionPrefilterCriteriasMessage: boolean;
  isExternalServiceCall: boolean;
  isDocumentUploader: boolean;
  isDocumentView: boolean;
  isStartMessage: boolean;
  isAnalyticsController: boolean;
  isActionController: boolean;
  isContextController: boolean;
  isDynamicLabelMessage: boolean;
  isInterviewEventMessage: boolean;
  isImageMessage: boolean;
  isAtsGatewayController: boolean;
  alertText: string;
  setAlertText: React.Dispatch<React.SetStateAction<string>>;
  selectedMessage: QuestionnaireMessage | undefined;
  messageType: string;
  handleOnSubmit: Function;
  attributeValidators: AttributeValidatorModel[];
  regexReplacers: RegexReplacerModel[];
  trainingData: TrainingDataModel[];
  messageTypeEnum: MessageTypeEnum;
}

const MessageModal: React.FC<Props> = ({
  messages,
  isOpen,
  toggle,
  modalEvent,
  isSimpleMessage,
  isOpenEnded,
  isClosedEnded,
  isLogicController,
  isLocationPartOfCountry,
  isLocationCounty,
  isLocationCity,
  isLocationMessage,
  isShowPositionMessage,
  isPositionApplyController,
  isPositionPrefilterCriteriasMessage,
  isExternalServiceCall,
  isDocumentUploader,
  isDocumentView,
  isStartMessage,
  isAnalyticsController,
  isActionController,
  isContextController,
  isDynamicLabelMessage,
  isInterviewEventMessage,
  isImageMessage,
  isAtsGatewayController,
  alertText,
  setAlertText,
  selectedMessage,
  messageType,
  handleOnSubmit,
  attributeValidators,
  regexReplacers,
  trainingData,
  messageTypeEnum,
}) => {
  const { t } = useTranslation();
  const [attributeValidatorOptions, setAttributeValidatorOptions] = useState<SelectModel[]>([]);
  const [regexReplacerOptions, setRegexReplacerOptions] = useState<SelectModel[]>([]);

  const isEditing = modalEvent === 'edit';

  useEffect(() => {
    if (alertText) setAlertText('');
    /* eslint-disable-next-line */
  }, [isOpen]);

  useEffect(() => {
    setAttributeValidatorOptions(getAttributeOptions(attributeValidators));
    /* eslint-disable-next-line */
  }, [attributeValidators.length]);

  useEffect(() => {
    setRegexReplacerOptions(getAttributeOptions(regexReplacers, 'replaceTo'));
    /* eslint-disable-next-line */
  }, [regexReplacers.length]);

  const allowedFileMimeTypes = [
    'application/pdf',
    'text/richtext',
    'application/rtf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/jpeg',
  ];

  const selectableMimeTypes = useMemo(
    () =>
      allowedFileMimeTypes.map(allowedFileMimeTypes => ({
        label: allowedFileMimeTypes,
        value: allowedFileMimeTypes,
      })),
    [allowedFileMimeTypes],
  );

  const getAttributeOptions = (
    items: AttributeValidatorModel[] | RegexReplacerModel[],
    additionalAttr?: string,
  ) => {
    if (!items.length) return [];

    return getSelectOptionObjects(items, 'title', 'pattern', additionalAttr);
  };

  const getConnectedParentConditions = () => {
    if (!selectedMessage) return [];

    const { parentAnswers } = selectedMessage;
    return getSelectOptionObjects(parentAnswers, 'text', 'id');
  };

  const getMessageDisabled = () => {
    if (!selectedMessage) return false;

    const { disabled } = selectedMessage;
    return disabled;
  };

  const getNextMessageIdIfDisabled = () => {
    if (!selectedMessage) return false;

    const { nextMessageIdIfDisabled } = selectedMessage;

    const nextMessageIfDisabled = messages.find(
      data => data.id === selectedMessage.nextMessageIdIfDisabled,
    );

    return {
      label: `[${nextMessageIdIfDisabled}] ${nextMessageIfDisabled?.type} - ${nextMessageIfDisabled?.text}`,
      value: nextMessageIdIfDisabled,
    };
  };

  const getConnectedIntent = () => {
    if (!selectedMessage || !trainingData.length) return [];

    const intent = trainingData.find(data => data.name === selectedMessage.intent);

    if (intent) {
      return {
        value: intent?.id || '',
        label: intent?.name || null,
      };
    }

    return [];
  };

  const getInitValueOfRegexReplacer = () => {
    if (!selectedMessage || !regexReplacerOptions.length) return [];

    const regexReplacers = selectedMessage.metadata?.regexReplacers;

    const initValue: SelectModel[] = [];
    regexReplacers?.forEach(option => {
      const item = regexReplacerOptions.find(({ value }) => value === option.pattern);

      if (item) {
        initValue.push({ ...item, value: item });
      }
    });

    return initValue || [];
  };

  const getFormInitValues = () => {
    const defaultFormInitValues = {
      parentConditions: getConnectedParentConditions(),
      intent: getConnectedIntent(),
      disabled: getMessageDisabled(),
      nextMessageIdIfDisabled: getNextMessageIdIfDisabled(),
    };

    const defaultFormInitValuesWithoutIntent = {
      parentConditions: getConnectedParentConditions(),
      disabled: getMessageDisabled(),
      nextMessageIdIfDisabled: getNextMessageIdIfDisabled(),
    };

    switch (messageTypeEnum) {
      case MessageTypeEnum.LOCATION_MESSAGE: {
        return {
          ...defaultFormInitValues,
          ...defaultLocationMessageFormInitValues,
          showAll: selectedMessage?.metadata?.showAll ?? true,
          nextPageButton: selectedMessage?.metadata?.nextPageButton || t('common.nextPage'),
          showSize: selectedMessage?.metadata?.showSize || 1,
        };
      }
      case MessageTypeEnum.SIMPLE_TEXT_MESSAGE: {
        return { ...defaultFormInitValues, text: selectedMessage?.text || '' };
      }
      case MessageTypeEnum.OPEN_ENDED_ANSWERS: {
        return {
          ...defaultFormInitValues,
          text: selectedMessage?.text || '',
          validatorProperties: {
            regularExpression:
              selectedMessage?.metadata?.validatorProperties?.regularExpression || null,
            isoRegionCode: selectedMessage?.metadata?.validatorProperties?.isoRegionCode || null,
          },
          regexReplacers: getInitValueOfRegexReplacer(),
          saveLocation: selectedMessage?.metadata?.saveLocation
            ? {
                label: selectedMessage?.metadata?.saveLocation,
                value: selectedMessage?.metadata?.saveLocation,
              }
            : {
                label: 'attribute',
                value: 'attribute',
              },
          validatorType: selectedMessage?.metadata?.validatorType
            ? {
                label: selectedMessage?.metadata?.validatorType,
                value: selectedMessage?.metadata?.validatorType,
              }
            : {
                label: QuestionnaireMessageMetadataValidatorType.NONE,
                value: QuestionnaireMessageMetadataValidatorType.NONE,
              },
          faultTolerant: selectedMessage?.metadata?.faultTolerant || false,
          answers:
            selectedMessage?.answers.map(answer => ({
              ...answer,
              editedText: answer.text,
              editedIntent: answer.intent,
            })) || [],
        };
      }
      case MessageTypeEnum.CLOSED_ENDED_ANSWERS: {
        return {
          ...defaultFormInitValues,
          text: selectedMessage?.text || '',
          answer: '',
          answers:
            selectedMessage?.answers.map(answer => ({
              ...answer,
              editedText: answer.text,
              editedIntent: answer.intent,
            })) || [],
        };
      }
      case MessageTypeEnum.LOGIC_CONTROLLER: {
        return { ...defaultFormInitValues, expression: selectedMessage?.text || '' };
      }
      case MessageTypeEnum.LOCATION_CITY: {
        return {
          ...defaultFormInitValues,
          chooseCity: selectedMessage?.text || '',
          cityChooseAllText: selectedMessage?.metadata?.cityChooseAllText,
          cityChooseNoneText: selectedMessage?.metadata?.cityChooseNoneText,
          showAll: selectedMessage?.metadata?.showAll ?? true,
          nextPageButton: selectedMessage?.metadata?.nextPageButton || t('common.nextPage'),
          showSize: selectedMessage?.metadata?.showSize || 1,
          answers:
            selectedMessage?.answers.map(answer => ({
              ...answer,
              editedText: answer.text,
              editedIntent: answer.intent,
            })) || [],
        };
      }
      case MessageTypeEnum.LOCATION_COUNTY: {
        return {
          ...defaultFormInitValues,
          chooseCounty: selectedMessage?.text || '',
          showAll: selectedMessage?.metadata?.showAll ?? true,
          nextPageButton: selectedMessage?.metadata?.nextPageButton || t('common.nextPage'),
          showSize: selectedMessage?.metadata?.showSize || 1,
          answers:
            selectedMessage?.answers.map(answer => ({
              ...answer,
              editedText: answer.text,
              editedIntent: answer.intent,
            })) || [],
        };
      }
      case MessageTypeEnum.LOCATION_PART_OF_COUNTRY: {
        return {
          ...defaultFormInitValues,
          choosePartOfCountry: selectedMessage?.text || '',
          showAll: selectedMessage?.metadata?.showAll ?? true,
          nextPageButton: selectedMessage?.metadata?.nextPageButton || t('common.nextPage'),
          showSize: selectedMessage?.metadata?.showSize || 1,
          answers:
            selectedMessage?.answers.map(answer => ({
              ...answer,
              editedText: answer.text,
              editedIntent: answer.intent,
            })) || [],
        };
      }
      case MessageTypeEnum.SHOW_POSITION_MESSAGE: {
        return {
          ...defaultFormInitValues,
          text: selectedMessage?.text || '',
          showAll: selectedMessage?.metadata?.showAll ?? true,
          nextPageButton: selectedMessage?.metadata?.nextPageButton || t('common.nextPage'),
          showSize: selectedMessage?.metadata?.showSize || 1,
          answers:
            selectedMessage?.answers.map(answer => ({
              ...answer,
              editedText: answer.text,
              editedIntent: answer.intent,
            })) || [],
          carouselView: selectedMessage?.metadata?.carouselView || false,
          carouselButtonText: selectedMessage?.metadata?.carouselButtonText,
          carouselDefaultImageUrl: selectedMessage?.metadata?.carouselDefaultImageUrl,
        };
      }
      case MessageTypeEnum.POSITION_APPLY_CONTROLLER: {
        return {
          ...defaultFormInitValues,
          text: t('questionnaire.applyToSelectedPosition'),
        };
      }
      case MessageTypeEnum.ATS_GATEWAY_CONTROLLER: {
        return {
          ...defaultFormInitValues,
          text: t('questionnaire.atsGatewayController'),
        };
      }
      case MessageTypeEnum.POSITION_PREFILTER_CRITERIAS_MESSAGE: {
        return {
          ...defaultFormInitValues,
          text: t('questionnaire.prefilterCriteriasToSelectedPosition'),
          enableKoExitPoint: selectedMessage?.metadata?.enableKoExitPoint || false,
        };
      }
      case MessageTypeEnum.EXPORT_APPLICATION_TO_ATS_CONTROLLER: {
        const parameters: KeyValuePair<string>[] = [];
        const data = selectedMessage?.metadata?.parameters;
        if (data) {
          Object.keys(data).map(key => parameters.push({ key: key, value: (data as any)[key] }));
        }

        return {
          ...defaultFormInitValues,
          path: selectedMessage?.metadata?.path || '',
          errorMessage: selectedMessage?.metadata?.errorMessage || '',
          parameters: parameters,
        };
      }
      case MessageTypeEnum.DOCUMENT_UPLOAD_CONTROLLER: {
        return {
          ...defaultFormInitValues,
          text: selectedMessage?.text || '',
          hint: selectedMessage?.metadata?.hint || '',
          webviewUrl: selectedMessage?.metadata?.webviewUrl,
          saveLocation: selectedMessage?.metadata?.saveLocation
            ? {
                label: selectedMessage?.metadata?.saveLocation,
                value: selectedMessage?.metadata?.saveLocation,
              }
            : {
                label: 'attribute',
                value: 'attribute',
              },
          maxFileSizeInBytes: selectedMessage?.metadata?.maxFileSizeInBytes,
          allowedFileMimeTypes: selectedMessage?.metadata?.allowedFileMimeTypes?.map(type => {
            return { label: type, value: type };
          }) || [selectableMimeTypes[0]],
          cantUploadErrorText: selectedMessage?.metadata?.cantUploadErrorText,
          tooLargeFileSizeErrorText: selectedMessage?.metadata?.tooLargeFileSizeErrorText,
          notAllowedFileMimeTypeErrorText:
            selectedMessage?.metadata?.notAllowedFileMimeTypeErrorText,
          answers:
            selectedMessage?.answers.map(answer => ({
              ...answer,
              editedText: answer.text,
              editedIntent: answer.intent,
            })) || [],
        };
      }
      case MessageTypeEnum.DOCUMENT_VIEWER_MESSAGE: {
        return {
          ...defaultFormInitValuesWithoutIntent,
          text: selectedMessage?.text || '',
          buttonText: selectedMessage?.answers[0]?.text,
          notFoundErrorText: selectedMessage?.metadata?.notFoundErrorText,
          folderSlug: {
            value: selectedMessage?.metadata?.folderSlug,
            label: '',
          } || { value: '' },
        };
      }
      case MessageTypeEnum.IMAGE_MESSAGE: {
        return {
          ...defaultFormInitValuesWithoutIntent,
          text: selectedMessage?.text || '',
        };
      }
      case MessageTypeEnum.START_CONTROLLER: {
        return {
          ...defaultFormInitValuesWithoutIntent,
          text: selectedMessage?.text || '',
          mainRoute: selectedMessage?.metadata?.mainRoute,
        };
      }
      case MessageTypeEnum.DYNAMIC_LABEL_MESSAGE: {
        return {
          ...defaultFormInitValuesWithoutIntent,
          text: selectedMessage?.text || '',
          showAll: selectedMessage?.metadata?.showAll ?? true,
          nextPageButton: selectedMessage?.metadata?.nextPageButton || t('common.nextPage'),
          showSize: selectedMessage?.metadata?.showSize || 1,
          answers:
            selectedMessage?.answers.map(answer => ({
              ...answer,
              editedText: answer.text,
              editedIntent: answer.intent,
            })) || [],
          labelCategorySlug: {
            value: selectedMessage?.metadata?.labelCategorySlug,
            label: '',
          } || { value: '' },
          perPageLabelSlugs: selectedMessage?.metadata?.perPageLabelSlugs || [],
        };
      }
      case MessageTypeEnum.SHOW_INTERVIEW_EVENTS_MESSAGE: {
        return {
          ...defaultFormInitValuesWithoutIntent,
          text: selectedMessage?.text || '',
          showAll: selectedMessage?.metadata?.showAll ?? true,
          nextPageButton: selectedMessage?.metadata?.nextPageButton || t('common.nextPage'),
          showSize: selectedMessage?.metadata?.showSize || 1,
          showNextButton: selectedMessage?.metadata?.showNextButton || false,
          answers:
            selectedMessage?.answers.map(answer => ({
              ...answer,
              editedText: answer.text,
              editedIntent: answer.intent,
            })) || [],
        };
      }
      case MessageTypeEnum.CONTEXT_CONTROLLER: {
        const actions = selectedMessage?.metadata?.contextActions;
        const mappedActions: ContextControllerContextAction[] = [];
        if (actions) {
          actions.map(item => {
            return mappedActions.push({
              ...item,
              method: {
                value: item.method,
                label: t(
                  `questionnaire.contextControllerForm.actions.${item.method.toLowerCase()}`,
                ),
              },
            });
          });
        }
        return {
          ...defaultFormInitValues,
          contextActions: mappedActions,
        };
      }
      case MessageTypeEnum.ANALYTICS_CONTROLLER: {
        const analytics = selectedMessage?.metadata?.analytics;
        const mappedAnalytics: AnalyticsControllerModel[] = [];
        if (analytics) {
          analytics.map(item => {
            return mappedAnalytics.push({
              ...item,
              analyticsType: {
                value: item.analyticsType,
                label: t(
                  `questionnaire.analyticsControllerForm.types.${item.analyticsType.toLowerCase()}`,
                ),
              },
            });
          });
        }

        return {
          ...defaultFormInitValues,
          analytics: mappedAnalytics,
        };
      }
      case MessageTypeEnum.ACTION_CONTROLLER: {
        const actions = selectedMessage?.metadata?.actions;
        const mappedActions: ActionControllerModel[] = [];
        if (actions) {
          actions.map(action => {
            return mappedActions.push({
              ...action,
              actionType: {
                value: action.actionType,
                label: t(
                  `questionnaire.actionControllerForm.actionTypes.${action.actionType.toLowerCase()}`,
                ),
              },
              method: {
                value: action.method,
                label: t(`common.${action.method.toLowerCase()}`),
              },
            });
          });
        }

        return {
          ...defaultFormInitValues,
          actions: mappedActions,
        };
      }
    }

    return {};
  };

  const getValidationSchema = () => {
    if (isLocationMessage) {
      return locationMessageSchema;
    }

    if (isSimpleMessage || isPositionApplyController || isPositionPrefilterCriteriasMessage) {
      return simpleMessageSchema;
    }

    if (isLocationPartOfCountry) {
      return locationPartOfCountryMessageSchema;
    }

    if (isLocationCounty) {
      return locationCountyMessageSchema;
    }

    if (isLocationCity) {
      return locationCityMessageSchema;
    }

    if (isOpenEnded) {
      return parentConditionsWithIntentAndTextSchema.concat(openEndedQuestionSchema);
    }

    if (isClosedEnded) {
      return simpleMessageSchema.concat(closedEndedQuestionSchema);
    }

    if (isLogicController) {
      return logicContollerMessageSchema;
    }

    if (isShowPositionMessage) {
      return simpleMessageSchema.concat(showPositionMessageSchema);
    }

    if (isExternalServiceCall) {
      return exportApplicationToAtsMessageSchema;
    }

    if (isDocumentUploader) {
      return simpleMessageSchema.concat(documentUploaderMessageSchema);
    }

    if (isDocumentView) {
      return simpleMessageSchema.concat(documentViewMessageSchema);
    }

    if (isImageMessage) {
      return imageMessageSchema;
    }

    if (isStartMessage) {
      return startMessageSchema;
    }

    if (isActionController) {
    }

    if (isAnalyticsController) {
      return questionnaireAnalyticsControllerSchema;
    }

    if (isContextController) {
      return questionnaireContextControllerSchema;
    }

    if (isAtsGatewayController) {
      return defaultMessageSchema;
    }

    if (isDynamicLabelMessage) {
      return dynamicLabelMessageSchema;
    }

    if (isInterviewEventMessage) {
      return interviewEventMessageSchema;
    }

    return defaultMessageSchema;
  };

  return (
    <Modal size="xl" isOpen={isOpen} toggle={() => toggle(false)}>
      <ModalHeader toggle={() => toggle(false)}>
        {isEditing ? t('common.edit') : t('common.create')}
        {' — '}
        {`${messageActionTypeToDisplay[selectedMessage?.type! || messageType]}`}
      </ModalHeader>
      <Formik
        initialValues={getFormInitValues()}
        validationSchema={getValidationSchema()}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          await handleOnSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue, isSubmitting }: FormikValues) => (
          <Form>
            <ModalBody>
              {alertText && (
                <Alert color="danger" className="text-center">
                  {alertText}
                </Alert>
              )}

              <DefaultForm selectedMessage={selectedMessage} messageType={messageType} />

              {isPositionPrefilterCriteriasMessage && <PositionPrefilterCriteriasForm />}

              {isLocationMessage && <LocationMessageForm />}

              {isSimpleMessage && !isLocationMessage && (
                <SimpleMessageForm messageType={messageType} />
              )}

              {isLocationCounty && !isLocationMessage && (
                <Fragment>
                  <SimpleMessageForm messageType={messageType} name="chooseCounty" />
                  <LocationPagerForm isEditing />
                  <ExtraAnswersForm
                    values={values}
                    setAlertText={setAlertText}
                    messageType={messageType}
                  />
                </Fragment>
              )}

              {isLocationPartOfCountry && !isLocationMessage && (
                <Fragment>
                  <SimpleMessageForm messageType={messageType} name="choosePartOfCountry" />
                  <LocationPagerForm isEditing />
                  <ExtraAnswersForm
                    values={values}
                    setAlertText={setAlertText}
                    messageType={messageType}
                  />
                </Fragment>
              )}

              {isLocationCity && !isLocationMessage && (
                <Fragment>
                  <SimpleMessageForm messageType={messageType} name="chooseCity" />
                  <LocationCityButtonTextsForm isEditing />
                  <LocationPagerForm isEditing />
                  <ExtraAnswersForm
                    values={values}
                    setAlertText={setAlertText}
                    messageType={messageType}
                  />
                </Fragment>
              )}

              {isLogicController && <LogicControllerForm />}

              {isClosedEnded && (
                <Fragment>
                  <FormGroup>
                    <Label for="text">{t('common.text')}</Label>
                    <TextField name="text" />
                  </FormGroup>
                  <ExtraAnswersForm
                    values={values}
                    setAlertText={setAlertText}
                    messageType={messageType}
                  />
                </Fragment>
              )}

              {isOpenEnded && (
                <Fragment>
                  <OpenEndedQuestionForm
                    options={attributeValidatorOptions}
                    replacerOptions={regexReplacerOptions}
                  />
                  <ExtraAnswersForm
                    values={values}
                    setAlertText={setAlertText}
                    messageType={messageType}
                  />
                </Fragment>
              )}

              {isShowPositionMessage && (
                <Fragment>
                  <SimpleMessageForm messageType={messageType} />
                  <ShowPositionMessageForm />
                  <ExtraAnswersForm
                    values={values}
                    setAlertText={setAlertText}
                    messageType={messageType}
                  />
                </Fragment>
              )}

              {isExternalServiceCall && <ExternalServiceCallForm />}

              {isDocumentUploader && (
                <Fragment>
                  <SimpleMessageForm messageType={messageType} />
                  <DocumentUploaderForm allowedFileMimeTypesOptions={selectableMimeTypes} />
                  <ExtraAnswersForm
                    values={values}
                    setAlertText={setAlertText}
                    messageType={messageType}
                  />
                </Fragment>
              )}

              {isDocumentView && <DocumentViewForm values={values} setFieldValue={setFieldValue} />}

              {isStartMessage && (
                <Fragment>
                  <SimpleMessageForm messageType={messageType} />
                  <FormGroup>
                    <Switch
                      name="mainRoute"
                      variant="pill"
                      color="info"
                      label="questionnaire.isMainRoute"
                      labelPosition="after"
                    />
                  </FormGroup>
                </Fragment>
              )}

              {isImageMessage && (
                <Fragment>
                  <SimpleMessageForm messageType={messageType} />
                </Fragment>
              )}

              {isDynamicLabelMessage && (
                <Fragment>
                  <DynamicLabelMessageForm values={values} setFieldValue={setFieldValue} />
                  <LabelPagerForm isEditing />
                  <ExtraAnswersForm
                    values={values}
                    setAlertText={setAlertText}
                    messageType={messageType}
                  />
                </Fragment>
              )}

              {isInterviewEventMessage && (
                <Fragment>
                  <SimpleMessageForm messageType={messageType} />
                  <InterviewEventPagerForm isEditing />
                  <ExtraAnswersForm
                    values={values}
                    setAlertText={setAlertText}
                    messageType={messageType}
                  />
                </Fragment>
              )}

              {isContextController && (
                <Fragment>
                  <ContextControllerForm />
                </Fragment>
              )}

              {isAnalyticsController && (
                <Fragment>
                  <AnalyticsControllerForm />
                </Fragment>
              )}

              {isActionController && (
                <Fragment>
                  <ActionControllerForm />
                </Fragment>
              )}
            </ModalBody>

            <ModalFooter>
              {isSubmitting && <Spinner loading={isSubmitting} className="mr-1" />}

              <Button type="submit" color="primary" disabled={isSubmitting}>
                {isEditing ? t('common.save') : t('common.create')}
              </Button>

              <Button color="secondary" onClick={() => toggle(false)}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  messages: state.questionnaireMessages.resources.data,
  attributeValidators: state.attributeValidators.resources.data,
  regexReplacers: state.regexReplacers.resources.data,
  trainingData: state.trainingData.resources.data.content || [],
});

export default connect(mapStateToProps, {})(MessageModal);
