import React, { Component, Fragment } from 'react';
import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';

import placeholder from '../../../../../../../../assets/img/other/placeholder_150x150.svg';

import styles from './scss/LiveChat.module.scss';
import CarouselTemplate from '../Common/Carousel/CarouselTemplate';

interface Props {
  message: ConversationHistoryModel;
}

interface State {
  outputButtons: any;
  outputTemplate: any;
  isCarousel: boolean;
}

class GenericTemplate2 extends Component<Props> {
  state: State = {
    outputButtons: [],
    outputTemplate: [],
    isCarousel: false,
  };

  componentDidMount() {
    this.renderTemplate(this.props.message);
  }

  renderTemplate(message: any) {
    const outputTemplate: any = [];
    const outputButtons: any = [];
    let currentTemplate = null;
    let genericElements = null;
    let buttons = null;

    try {
      currentTemplate = JSON.parse(message.messageAsText);
      // eslint-disable-next-line
      genericElements = currentTemplate.livechatGenericElements;
      // eslint-disable-next-line
      buttons = genericElements[0].buttons;

      for (let i = 0; i < buttons.length; i += 1) {
        outputButtons.push(
          <a href={buttons[i].url} key={i} target="_blank" rel="noopener noreferrer">
            <button type="button" className={styles.templateButton}>
              {buttons[i].title}
            </button>
          </a>,
        );
      }

      this.setState({
        outputButtons,
      });
    } catch (error) {
      this.setState({
        outputButtons: [],
        outputTemplate: [],
      });
    }

    if (currentTemplate) {
      if (genericElements.length > 1) {
        this.setState({ isCarousel: true });
        outputTemplate.push(<CarouselTemplate elements={genericElements} key={1} />);
      } else {
        genericElements.map((el: any) => {
          return outputTemplate.push(
            <Fragment key={el.title}>
              <p>{el.title}</p>
              <img
                src={el.imageUrl !== 'null' ? el.imageUrl : placeholder}
                className={styles.templateImage}
                alt="Generic Template Card"
              />
            </Fragment>,
          );
        });
      }

      this.setState({
        outputTemplate,
      });
    }
  }

  render() {
    const { outputTemplate, outputButtons, isCarousel } = this.state;
    return (
      <div className={styles.genericTemplate} style={{ display: isCarousel ? 'block' : 'flex' }}>
        {outputTemplate}
        {!isCarousel && <div className={styles.genericTeplateButtons}>{outputButtons}</div>}
      </div>
    );
  }
}

export default GenericTemplate2;
