import { StateModel } from '../../shared/models/default-state.model';
import {
  GET_POSITION_FILTER_QUESTION_REQUEST,
  GET_POSITION_FILTER_QUESTION_SUCCESS,
  GET_POSITION_FILTER_QUESTION_ERROR,
  GET_POSITION_FILTER_QUESTIONS_REQUEST,
  GET_POSITION_FILTER_QUESTIONS_SUCCESS,
  GET_POSITION_FILTER_QUESTIONS_ERROR,
  CREATE_POSITION_FILTER_QUESTION_REQUEST,
  CREATE_POSITION_FILTER_QUESTION_SUCCESS,
  CREATE_POSITION_FILTER_QUESTION_ERROR,
  UPDATE_POSITION_FILTER_QUESTION_REQUEST,
  UPDATE_POSITION_FILTER_QUESTION_SUCCESS,
  UPDATE_POSITION_FILTER_QUESTION_ERROR,
  DELETE_POSITION_FILTER_QUESTION_REQUEST,
  DELETE_POSITION_FILTER_QUESTION_SUCCESS,
  DELETE_POSITION_FILTER_QUESTION_ERROR,
  UPDATE_POSITION_FILTER_QUESTIONS_ORDER_REQUEST,
  UPDATE_POSITION_FILTER_QUESTIONS_ORDER_SUCCESS,
  UPDATE_POSITION_FILTER_QUESTIONS_ORDER_ERROR,
} from '../actions/position-filter-questions.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {
    question: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    questions: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    createQuestion: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    updateQuestion: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    deleteQuestion: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    questionsOrder: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
  },
};

export const positionFilterQuestionsReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_POSITION_FILTER_QUESTION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          question: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case GET_POSITION_FILTER_QUESTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          question: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case GET_POSITION_FILTER_QUESTION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          question: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case GET_POSITION_FILTER_QUESTIONS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          questions: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case GET_POSITION_FILTER_QUESTIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          questions: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case GET_POSITION_FILTER_QUESTIONS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          questions: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case CREATE_POSITION_FILTER_QUESTION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          createQuestion: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case CREATE_POSITION_FILTER_QUESTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          createQuestion: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case CREATE_POSITION_FILTER_QUESTION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          createQuestion: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case UPDATE_POSITION_FILTER_QUESTION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          updateQuestion: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case UPDATE_POSITION_FILTER_QUESTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          updateQuestion: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case UPDATE_POSITION_FILTER_QUESTION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          updateQuestion: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case DELETE_POSITION_FILTER_QUESTION_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          deleteQuestion: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case DELETE_POSITION_FILTER_QUESTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          deleteQuestion: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case DELETE_POSITION_FILTER_QUESTION_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          deleteQuestion: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case UPDATE_POSITION_FILTER_QUESTIONS_ORDER_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          questionsOrder: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case UPDATE_POSITION_FILTER_QUESTIONS_ORDER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          questionsOrder: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case UPDATE_POSITION_FILTER_QUESTIONS_ORDER_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          questionsOrder: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    default:
      return state;
  }
};

export default positionFilterQuestionsReducer;
