import { StateModel } from '../../shared/models/default-state.model';

import {
  GET_SIGNATURE_REQUEST,
  GET_SIGNATURE_SUCCESS,
  GET_SIGNATURE_ERROR,
} from '../actions/upload.action';

import {
  GET_SIGNATURE_FOR_DELETE_REQUEST,
  GET_SIGNATURE_FOR_DELETE_SUCCESS,
  GET_SIGNATURE_FOR_DELETE_ERROR,
} from '../actions/delete.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const signatureForUploadReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_SIGNATURE_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_SIGNATURE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_SIGNATURE_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const signatureForDeleteReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_SIGNATURE_FOR_DELETE_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_SIGNATURE_FOR_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_SIGNATURE_FOR_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
