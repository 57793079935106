import { StateModel } from 'src/shared/models';
import {
  GET_DYNAMIC_EMPLOYEES,
  GET_DYNAMIC_EMPLOYEES_ERROR,
  GET_DYNAMIC_EMPLOYEES_SUCCESS,
} from '../actions/dynamic-employee.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const dynamicEmployeeReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case GET_DYNAMIC_EMPLOYEES:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_DYNAMIC_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload.content,
      };
    case GET_DYNAMIC_EMPLOYEES_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };

    default:
      return state;
  }
};

export default dynamicEmployeeReducer;
