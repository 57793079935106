import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Pagination from 'react-js-pagination';
import { Row, Col, Card, CardBody, Table, Alert } from 'reactstrap';

import { StateModel } from '../../../shared/models/default-state.model';
import { PositionApplicantModel } from 'src/shared/models/position-applicants.model';
import { ApplicantsQueryParams } from '../../../shared/models/query-params.model';

import ServerError from 'src/shared/components/ServerError';
import Spinner from 'src/shared/components/Spinner';

import styles from '../PositionApplicants.module.scss';
import { uniqueId } from 'lodash';
import { getListFieldsByConfiguration } from 'src/shared/utils/configuration.util';
import { configurationConstants } from 'src/shared/constants/configuration.constants';
import { ApplicationModel } from 'src/redux/actions/applications.action';

interface Props {
  applicantsState: StateModel;
  customFields: StateModel;
  queryParams: ApplicantsQueryParams;
  setQueryParams: Function;
  application: ApplicationModel;
}

const PositionApplicantsTable: React.FC<Props> = ({
  applicantsState,
  customFields,
  queryParams,
  setQueryParams,
  application,
}) => {
  const { t } = useTranslation();
  const configuredFields = getListFieldsByConfiguration(
    application,
    configurationConstants.recruiterPositionApplicationsList,
    t,
  );

  const pageChange = (index: number) => {
    const newQueryParams = {
      ...queryParams,
      page: index - 1,
    };

    setQueryParams(newQueryParams);
  };

  const noDataFound =
    (applicantsState.loaded && !applicantsState.data?.content?.length) || !queryParams.attributes;

  const renderData = (element: PositionApplicantModel) => {
    return (
      <tr key={uniqueId(`${element.id}`)}>
        {configuredFields.map(cf => (
          <td key={cf.key}>{cf.render(element)}</td>
        ))}
      </tr>
    );
  };

  const renderHeader = () => {
    return (
      <tr>
        {configuredFields.map(cf => (
          <th key={cf.key}>{cf.label}</th>
        ))}
      </tr>
    );
  };

  return (
    <Fragment>
      {configuredFields.length > 0 ? (
        <Row>
          <Col>
            <Card>
              <CardBody>
                {applicantsState.error && <ServerError />}

                {customFields.loading ||
                  (applicantsState.loading && (
                    <div className="d-flex justify-content-center">
                      <Spinner loading={applicantsState.loading} size="2x" />
                    </div>
                  ))}

                {noDataFound && (
                  <Alert color="info" className="text-center mb-0">
                    {t('positions.noPositionApplicationsFound')}
                  </Alert>
                )}

                {applicantsState.loaded && applicantsState.data?.content?.length > 0 && (
                  <Fragment>
                    <Table className={styles.applicantsTable} striped hover size="sm">
                      <thead>{renderHeader()}</thead>

                      <tbody>{applicantsState.data?.content?.map(renderData)}</tbody>
                    </Table>

                    <Pagination
                      activePage={queryParams.page + 1}
                      itemsCountPerPage={queryParams.size}
                      totalItemsCount={applicantsState.data?.totalElements}
                      pageRangeDisplayed={5}
                      onChange={(index: number) => pageChange(index)}
                    />
                  </Fragment>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <p className="text-center">{t('common.configurationError')}</p>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  applicantsState: state.positionApplicants,
  customFields: state.customFields.data.customFields,
});

export default connect(mapStateToProps, {})(PositionApplicantsTable);
