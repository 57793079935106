import { StateModel } from '../../shared/models/default-state.model';
import { CustomListItemModel } from 'src/shared/models/custom-lists.model';

import {
  GET_CUSTOM_LIST_ITEM_ITEMS_REQUEST,
  GET_CUSTOM_LIST_ITEM_ITEMS_SUCCESS,
  GET_CUSTOM_LIST_ITEM_ITEMS_ERROR,
  CREATE_CUSTOM_LIST_ITEM_REQUEST,
  CREATE_CUSTOM_LIST_ITEM_SUCCESS,
  CREATE_CUSTOM_LIST_ITEM_ERROR,
  UPDATE_CUSTOM_LIST_ITEM_REQUEST,
  UPDATE_CUSTOM_LIST_ITEM_SUCCESS,
  UPDATE_CUSTOM_LIST_ITEM_ERROR,
  DELETE_CUSTOM_LIST_ITEM_REQUEST,
  DELETE_CUSTOM_LIST_ITEM_SUCCESS,
  DELETE_CUSTOM_LIST_ITEM_ERROR,
} from '../actions/custom-list-items.action';

interface CustomListItemsStateModel {
  resources: StateModel;
  create: StateModel;
  update: StateModel;
  delete: StateModel;
}

const INITIAL_STATE = {
  resources: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  create: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  update: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  delete: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
};

// eslint-disable-next-line import/prefer-default-export
export const customListItemsReducer = (
  state: CustomListItemsStateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case GET_CUSTOM_LIST_ITEM_ITEMS_REQUEST:
      return {
        ...state,
        resources: {
          ...state.resources,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case GET_CUSTOM_LIST_ITEM_ITEMS_SUCCESS:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case GET_CUSTOM_LIST_ITEM_ITEMS_ERROR:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CREATE_CUSTOM_LIST_ITEM_REQUEST:
      return {
        ...state,
        create: {
          ...state.create,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case CREATE_CUSTOM_LIST_ITEM_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: true,
          data: action.payload,
        },
        resources: {
          ...state.resources,
          data: {
            ...state.resources.data,
            content: [...state.resources.data.content, action.payload],
          },
        },
      };

    case CREATE_CUSTOM_LIST_ITEM_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case UPDATE_CUSTOM_LIST_ITEM_REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case UPDATE_CUSTOM_LIST_ITEM_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          loaded: true,
          data: action.payload,
        },
        resources: {
          ...state.resources,
          data: {
            ...state.resources.data,
            content: state.resources.data.content.map((cli: CustomListItemModel) =>
              cli.id === action.payload.id ? action.payload : cli,
            ),
          },
        },
      };

    case UPDATE_CUSTOM_LIST_ITEM_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case DELETE_CUSTOM_LIST_ITEM_REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case DELETE_CUSTOM_LIST_ITEM_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          loaded: true,
          data: action.payload,
        },
        resources: {
          ...state.resources,
          data: {
            ...state.resources.data,
            content: state.resources.data.content.filter(
              (td: CustomListItemModel) => td.id !== action.payload.id,
            ),
          },
        },
      };

    case DELETE_CUSTOM_LIST_ITEM_ERROR:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};
