import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from 'reactstrap';

import { ViewMode } from 'src/shared/constants/training-data.constants';

import addToast from 'src/shared/utils/notification.util';

import { train } from 'src/redux/services/training-data.service';
import { ExportButton, CheckPermission, Spinner, PageHeader } from 'src/shared/components';

interface Props {
  setCreateModalOpen: Function;
  viewMode: ViewMode;
  setTrainingDataViewMode: Function;
  handleExport: Function;
  isButtonDisabled: boolean;
}

const TrainingDataHeader: React.FC<Props> = ({
  setCreateModalOpen,
  viewMode,
  setTrainingDataViewMode,
  handleExport,
  isButtonDisabled,
}) => {
  const { appId } = useParams<{ appId: string }>();
  const { t } = useTranslation();

  const [isTraining, setIsTraining] = useState<boolean>(false);

  const handleTrain = async () => {
    try {
      setIsTraining(true);
      await train(appId);
      addToast('success', t('trainingData.trainingSuccessfullyStarted'));
    } catch {
      addToast('warning', t('trainingData.trainingFailed'));
    } finally {
      setIsTraining(false);
    }
  };

  return (
    <PageHeader
      title={t('common.trainingData')}
      rightComponent={
        <>
          <ButtonGroup className="mr-2">
            <Button
              color="secondary"
              outline
              active={viewMode === ViewMode.Row}
              disabled={isButtonDisabled}
              onClick={() => setTrainingDataViewMode(ViewMode.Row)}
            >
              <i className="fas fa-align-justify" />
            </Button>

            <Button
              color="secondary"
              outline
              active={viewMode === ViewMode.Normal}
              disabled={isButtonDisabled}
              onClick={() => setTrainingDataViewMode(ViewMode.Normal)}
            >
              <i className="fas fa-th-large" />
            </Button>

            <Button
              color="secondary"
              outline
              active={viewMode === ViewMode.Compact}
              onClick={() => setTrainingDataViewMode(ViewMode.Compact)}
            >
              <i className="fas fa-th" />
            </Button>
          </ButtonGroup>

          <CheckPermission variant="displayIf" permissions={['nlp-train_create']}>
            <Button
              color="secondary"
              className="mr-2"
              disabled={isButtonDisabled || isTraining}
              onClick={() => handleTrain()}
            >
              <Spinner size="sm" className="mr-1" loading={isTraining} />
              {t('common.train')}
            </Button>
          </CheckPermission>

          <ExportButton
            className="mr-2"
            disabled={isButtonDisabled}
            onClick={() => handleExport()}
            permission="training-data_read"
          />

          <CheckPermission variant="displayIf" permissions={['training-data_create']}>
            <Button color="primary" onClick={() => setCreateModalOpen(true)}>
              {t('common.create')}
            </Button>
          </CheckPermission>
        </>
      }
    />
  );
};

export default TrainingDataHeader;
