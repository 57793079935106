import React from 'react';
import { useTranslation } from 'react-i18next';

const RegexReplacePatternsTableHeader = () => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        <th className="name-column">{t('common.title')}</th>
        <th className="name-column">{t('common.regexPattern')}</th>
        <th className="name-column">{t('common.replaceTo')}</th>
        <th className="operations-column">{t('common.operations')}</th>
      </tr>
    </thead>
  );
};

export default RegexReplacePatternsTableHeader;
