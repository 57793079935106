export function readFileAsArrayBuffer(file: File) {
  return new Promise((resolve: any, reject: any) => {
    const fr = new FileReader();

    fr.onload = () => {
      resolve(fr.result);
    };

    fr.onerror = () => {
      reject(fr.error);
    };

    fr.readAsArrayBuffer(file);
  }).catch(err => err);
}

export function readFileAsDataURL(file: File) {
  return new Promise((resolve: any, reject: any) => {
    const fr = new FileReader();

    fr.onload = () => {
      resolve(fr.result);
    };

    fr.onerror = () => {
      reject(fr.error);
    };

    fr.readAsDataURL(file);
  }).catch(err => err);
}
