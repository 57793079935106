import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import { Row, Col, Card, CardBody, Table, Alert, Button } from 'reactstrap';

import { PositionsQueryParams } from '../../../shared/models/query-params.model';
import { StateModel } from '../../../shared/models/default-state.model';
import { PositionModel } from '../../../shared/models/positions.model';
import { QuestionnaireLabelCategory } from '../../../shared/models/questionnaire.model';
import { ShortListPositionModel } from 'src/shared/models/short-list.model';

import { isPositionExpired, renderExpiredBadge } from '../../../shared/utils/positions.util';
import { sortWithNullIgnore } from '../../../shared/utils/misc.util';
import { hasPermission } from '../../../shared/services/permissions.service';

import { ApplicationModel } from 'src/redux/actions/applications.action';
import {
  getPositions,
  updatePositionOrder,
  deletePosition,
  clonePosition,
} from '../../../redux/actions/positions.action';

import Spinner from 'src/shared/components/Spinner';
import CheckPermission from '../../../shared/components/CheckPermission';
import ServerError from '../../../shared/components/ServerError';
import ConfirmModal from '../../../shared/components/modals/ConfirmModal';
import PositionCloningModal from './PositionCloningModal';
import PositionDetailsModal from './PositionDetailsModal';
import EditableNumberInput from '../../../shared/components/form-inputs/EditableNumberInput';
import PositionStatusDropdown from './PositionStatusDropdown';

import styles from '../Positions.module.scss';

interface Props {
  application: ApplicationModel;
  platforms: string[];
  positionsState: StateModel;
  getPositions: Function;
  updatePositionOrder: Function;
  deletePosition: Function;
  deletePositionState: StateModel;
  clonePosition: Function;
  labelCategories: QuestionnaireLabelCategory[];
  queryParams: PositionsQueryParams;
  setQueryParams: Function;
  shortListPositions: ShortListPositionModel[];
}

const PositionsTable: React.FC<Props> = ({
  application,
  platforms,
  positionsState,
  getPositions,
  updatePositionOrder,
  deletePosition,
  deletePositionState,
  clonePosition,
  labelCategories,
  queryParams,
  setQueryParams,
  shortListPositions,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const history = useHistory();
  const { pathname } = useLocation();

  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [isCloningModalOpen, setCloningModalOpen] = useState<boolean>(false);
  const [isDetailsModalOpen, setDetailsModalOpen] = useState<boolean>(false);
  const [selectedPosition, setSelectedPosition] = useState<PositionModel | undefined>(undefined);

  const handleConfirm = () => {
    if (selectedPosition) {
      deletePosition(appId, selectedPosition.id);
    }
  };

  const handleOrderNumberEdit = (value: number | undefined, positionId: number) => {
    updatePositionOrder(appId, positionId, value || null);
  };

  const pageChange = (index: number) => {
    setQueryParams((prevState: PositionsQueryParams) => ({
      ...prevState,
      page: index - 1,
    }));
  };

  const redirectTo = (path: string, positionId: number) => {
    history.push(`${pathname}/${path}/${positionId}`);
  };

  useEffect(() => {
    const { loaded, error } = deletePositionState;

    if (loaded && !error) {
      getPositions(appId, queryParams);
      setConfirmModalOpen(false);
    }
    /* eslint-disable-next-line */
  }, [deletePositionState]);

  return (
    <Fragment>
      <Row>
        <Col>
          <Card>
            <CardBody>
              {positionsState.error && <ServerError />}

              {positionsState.loaded && !positionsState.data?.content?.length && (
                <Alert color="info" className="text-center mb-0">
                  {t('positions.noPositionsFound')}
                </Alert>
              )}

              {positionsState.loading && (
                <div className="d-flex justify-content-center">
                  <Spinner loading={positionsState.loading} size="2x" />
                </div>
              )}

              {positionsState.data?.content?.length > 0 && (
                <Fragment>
                  <Table className={styles.positionsTable} striped hover size="sm">
                    <thead>
                      <tr>
                        <th className={styles.orderNumber}>#</th>
                        <th className={styles.name}>{t('common.name')}</th>
                        <th className={styles.externalId}>{t('common.externalId')}</th>
                        <th className={styles.startsAt}>{t('common.startsAt')}</th>
                        <th className={styles.expiresAt}>{t('common.expiresAt')}</th>
                        <th className={styles.statusBadge}>{t('common.status')}</th>
                        <th className={styles.operations}>{t('common.operations')}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {(positionsState.data?.content as PositionModel[])
                        .sort((a, b) => sortWithNullIgnore(a.order, b.order, true))
                        .map(position => {
                          return (
                            <tr key={position.id}>
                              <td className={styles.orderNumber}>
                                <EditableNumberInput
                                  value={position.order || undefined}
                                  disabled={!hasPermission(['position_status_update'])}
                                  handleEdit={(value: number | undefined) =>
                                    handleOrderNumberEdit(value, position.id!)
                                  }
                                />
                              </td>
                              <td className={styles.name}>{position.name}</td>
                              <td className={styles.externalId}>{position.externalId}</td>
                              <td className={styles.startsAt}>
                                {moment(position.startsAt).format('LL')}
                              </td>
                              <td className={styles.expiresAt}>
                                {moment(position.expiresAt).format('LL')}
                              </td>
                              <td className={styles.statusBadge}>
                                <div className="d-flex flex-row">
                                  <PositionStatusDropdown
                                    positionId={position.id!}
                                    status={position.status}
                                  />
                                  {isPositionExpired(position.expiresAt) && (
                                    <div className="ml-2">{renderExpiredBadge()}</div>
                                  )}
                                </div>
                              </td>
                              <td className={styles.operations}>
                                {shortListPositions?.find(slp => slp.id === position.id) && (
                                  <CheckPermission
                                    variant="displayIf"
                                    permissions={['shortlist_positions_read']}
                                  >
                                    <Button
                                      size="sm"
                                      onClick={() => redirectTo('short-list', position.id!)}
                                    >
                                      <i className="fas fa-list" />
                                    </Button>
                                  </CheckPermission>
                                )}

                                <Button
                                  className="ml-2"
                                  size="sm"
                                  onClick={() => {
                                    setDetailsModalOpen(true);
                                    setSelectedPosition(position);
                                  }}
                                >
                                  <i className="far fa-eye" />
                                </Button>

                                <CheckPermission
                                  variant="enableIf"
                                  permissions={['position_position_create']}
                                >
                                  <Button
                                    className="ml-2"
                                    size="sm"
                                    onClick={() => {
                                      setCloningModalOpen(true);
                                      setSelectedPosition(position);
                                    }}
                                  >
                                    <i className="far fa-clone" />
                                  </Button>
                                </CheckPermission>
                                <CheckPermission
                                  variant="enableIf"
                                  permissions={['position_position_update']}
                                >
                                  <Button
                                    className="ml-2"
                                    size="sm"
                                    onClick={() => redirectTo('edit', position.id!)}
                                  >
                                    <i className="far fa-edit" />
                                  </Button>
                                </CheckPermission>

                                <CheckPermission
                                  variant="enableIf"
                                  permissions={['position_position_delete']}
                                >
                                  <Button
                                    className="ml-2"
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                      setConfirmModalOpen(true);
                                      setSelectedPosition(position);
                                    }}
                                  >
                                    <i className="far fa-trash-alt" />
                                  </Button>
                                </CheckPermission>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>

                  <Pagination
                    activePage={queryParams.page + 1}
                    itemsCountPerPage={queryParams.size}
                    totalItemsCount={positionsState.data?.totalElements}
                    pageRangeDisplayed={5}
                    onChange={(index: number) => pageChange(index)}
                  />
                </Fragment>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <PositionDetailsModal
        position={selectedPosition}
        isOpen={isDetailsModalOpen}
        platforms={platforms}
        facebookPageId={application.facebookPageId}
        labelCategories={labelCategories}
        cancel={() => {
          setDetailsModalOpen(false);
          setSelectedPosition(undefined);
        }}
      />

      <PositionCloningModal
        isOpen={isCloningModalOpen}
        selectedPosition={selectedPosition}
        getPositions={getPositions}
        clonePosition={clonePosition}
        cancel={() => {
          setCloningModalOpen(false);
          setSelectedPosition(undefined);
        }}
      />

      <ConfirmModal
        title={t('positions.deletePosition')}
        text={t('positions.deletePositionConfirmMessage')}
        item={selectedPosition?.name}
        isOpen={isConfirmModalOpen}
        isLoading={deletePositionState.loading}
        confirm={handleConfirm}
        cancel={() => {
          setConfirmModalOpen(false);
          setSelectedPosition(undefined);
        }}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  platforms: state.platforms.data,
  positionsState: state.positions.data.positions,
  shortListPositions: state.shortList.positions.data,
  deletePositionState: state.positions.data.deletePosition,
  labelCategories: state.questionnaireLabelCategories.resources.data,
});

const mapDispatchToProps = {
  getPositions,
  updatePositionOrder,
  deletePosition,
  clonePosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionsTable);
