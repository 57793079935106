import { useRef, useEffect } from 'react';

const usePreviousProps = <T,>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value || ({} as T);
  });
  return ref.current || ({} as T);
};

export default usePreviousProps;
