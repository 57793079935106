import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Col, FormGroup, Label } from 'reactstrap';
import { Formik, Field, FormikValues } from 'formik';

import _ from 'lodash';

import moment from 'moment';

import { DateRange, TextField } from 'src/shared/components';
import SEARCH_TIMER from 'src/shared/constants/filter.constants';
import { BatchMessageFilterModel, BatchMessagesQueryParams } from 'src/shared/models';

interface BatchMessageFilterProps {
  filter: BatchMessageFilterModel;
  filterChanged: (filters: BatchMessagesQueryParams) => void;
}

export const BatchMessageFilter: FC<BatchMessageFilterProps> = props => {
  const { t } = useTranslation();

  const updateFilter = (values: FormikValues) => {
    props.filterChanged(values as BatchMessagesQueryParams);
  };

  const debouncedCallApi = _.debounce((values: FormikValues) => updateFilter(values), SEARCH_TIMER);

  const handleFilterChange = (values: FormikValues) => {
    debouncedCallApi(values);
    return {};
  };

  return (
    <>
      <Formik
        validateOnBlur={false}
        initialValues={props.filter}
        onSubmit={() => {}}
        validate={handleFilterChange}
        validateOnChange
        enableReinitialize
      >
        {({ setFieldValue, values, setValues }) => (
          <Row>
            <Col lg="3">
              <FormGroup>
                <Label>{t('common.date')}</Label>
                <Field
                  startDate={values.from}
                  endDate={values.to}
                  component={DateRange}
                  isOutsideRange={() => false}
                  handleDateChange={(startDate: moment.Moment, endDate: moment.Moment) => {
                    if (!startDate || !endDate) return null;
                    const from = moment(startDate).utc().startOf('day').toISOString();
                    const to = moment(endDate).utc().endOf('day').toISOString();

                    setValues(
                      {
                        ...values,
                        from: from,
                        to: to,
                      },
                      true,
                    );
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <Label>{t('common.name')}</Label>
                <TextField name="name" />
              </FormGroup>
            </Col>
          </Row>
        )}
      </Formik>
    </>
  );
};

export default BatchMessageFilter;
