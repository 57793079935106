import { post, put } from 'src/shared/services/http.service';
import { GetIssuesResponse, IssuesQueryParams, SendIssueReplyRequest } from 'src/shared/models/issues.model';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';
import _ from 'lodash';

const baseUrl = (applicationId: string) => `/applications/${applicationId}/issue/issues`;

export const getIssues = async (applicationId: string, params: IssuesQueryParams, grouping: DynamicGroupByModel): Promise<GetIssuesResponse> => {
    const copiedParams = _.cloneDeep(params);
    if (copiedParams.statuses) {
        copiedParams.statuses = _.map(copiedParams.statuses, s => s.value).join(",") as any;
    }
    if (copiedParams.categorySlugs) {
        copiedParams.categorySlugs = _.map(copiedParams.categorySlugs, s => s.value).join(",") as any;
    }

    //@ts-ignore
    Object.keys(copiedParams).forEach((k) => (copiedParams[k] == null || copiedParams[k].length === 0) && delete copiedParams[k]);

    return post(baseUrl(applicationId), { params: copiedParams, data: { filters: grouping } });
};

export const updateIssueStatus = async (applicationId: string, issueId: number, status: string) => {
    return put(`${baseUrl(applicationId)}/${issueId}/status`, { data: { status: status } });
};

export const sendIssueReply = (applicationId: string, issueId: number, messageId: number, data: SendIssueReplyRequest) => {
    return post(`${baseUrl(applicationId)}/${issueId}/messages/${messageId}/replies`, { data });
};
