import React from 'react';
import { useField, useFormikContext, FieldAttributes } from 'formik';
// @ts-ignore
import { AppSwitch } from '@coreui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  id?: string;
  checked?: boolean;
  label: string;
  labelPosition?: string;
  variant: string;
  color: string;
  className: string;
  disabled?: boolean;
  onChanged?: (chekced: boolean) => void;
}

const Switch: React.FC<Props | FieldAttributes<any>> = ({
  id,
  checked,
  label,
  labelPosition = 'after',
  variant,
  color,
  disabled,
  className,
  onChanged,
  ...props
}) => {
  const { t } = useTranslation();
  const [field] = useField<{}>(props);
  const { name, value } = field;
  const { setFieldValue } = useFormikContext();

  return (
    <div className="d-flex align-items-center flex-row">
      {label && labelPosition === 'before' && <span className="mr-2">{t(`${label}`)}</span>}
      <AppSwitch
        id={id}
        className={className}
        variant={variant}
        color={color}
        checked={checked || value}
        disabled={disabled}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const checked = e.currentTarget.checked;
          setFieldValue(name, checked);

          if (onChanged) {
            onChanged(checked);
          }
        }}
      />

      {label && labelPosition === 'after' && <span className="ml-2">{t(`${label}`)}</span>}
    </div>
  );
};

export default Switch;
