import React, { Component, Fragment } from 'react';
import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';
import TextMessage from './TextMessage';

import styles from './scss/Facebook.module.scss';

interface Props {
  message: ConversationHistoryModel;
}

interface State {
  text: string;
  buttons: Array<String>;
}

class SendTextWithButtons extends Component<Props> {
  state: State = {
    text: '',
    buttons: [],
  };

  componentDidMount() {
    const cleanedMessage = this.props.message.messageAsText.replace(/\n/g, ' ');
    this.renderTemplate(cleanedMessage);
  }

  renderTemplate(msg: string) {
    const outputButtons = [];
    let currentMessage = null;

    try {
      currentMessage = JSON.parse(msg);
      const { buttons } = currentMessage;

      for (let i = 0; i < buttons.length; i += 1) {
        outputButtons.push(
          <button type="button" className={styles.quickReply} key={buttons[i]} disabled>
            {buttons[i]}
          </button>,
        );
      }

      this.setState({
        text: currentMessage.text,
        buttons: outputButtons,
      });
    } catch (error) {
      this.setState({
        text: msg,
      });
    }
  }

  render() {
    const { message } = this.props;
    const { text, buttons } = this.state;

    return (
      <Fragment>
        <TextMessage message={message} buttonTemplateText={text} />
        <div className={styles.quickReplies}>{buttons}</div>
      </Fragment>
    );
  }
}

export default SendTextWithButtons;
