import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Popover, PopoverBody } from 'reactstrap';
import Select from 'react-select';
import { useParams } from 'react-router-dom';

import {
  StateModel,
  TagCategoryModel,
  TagModel,
  EmployeeModel,
  SubscribingListModel,
  HelpdeskGroupModel,
  TaggableModel,
} from 'src/shared/models';

import styles from './Tags.module.scss';

export interface TagSelectPopoverProps {
  index?: number;
  employee?: EmployeeModel;
  taggableModel?: TaggableModel;
  helpdeskGroup?: HelpdeskGroupModel;
  list?: SubscribingListModel;
  onChanged?: Function;
  tagCategories?: StateModel;
  getTagCategories?: Function;
  addTagToEntity?: Function;
  singleSelect?: boolean;
}

const onChangeTimer = 300;

const TagSelectPopover: React.FC<TagSelectPopoverProps> = ({
  index,
  employee,
  taggableModel,
  helpdeskGroup,
  list,
  onChanged,
  tagCategories,
  getTagCategories,
  addTagToEntity,
  singleSelect,
}) => {
  const { appId }: any = useParams();
  const { t } = useTranslation();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [tagsToAssign, setTagsToAssign] = useState<string[]>([]);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const formatGroupLabel = (data: any) => (
    <div className={styles.selectGroup}>
      <span>{data.label}</span>
      <span className={styles.selectGroupBadge}>{data.options.length}</span>
    </div>
  );

  const getOptions = async () => {
    const options: any = [];

    if (tagCategories) {
      tagCategories.data.map((tagCategory: TagCategoryModel) => {
        const groupObjects: any = [];
        const optionObject = {
          label: '',
          options: [],
        };

        tagCategory.tags?.map((tag: TagModel) => {
          let alreadyHas = null;
          if (typeof employee !== 'undefined') {
            alreadyHas = employee?.tags?.find(employeeTag => employeeTag.id === tag.id);
          }
          if (typeof taggableModel !== 'undefined') {
            alreadyHas = taggableModel?.employeeTags?.find(
              employeeTag => employeeTag.id === tag.id,
            );
          }
          if (typeof helpdeskGroup !== 'undefined') {
            alreadyHas = helpdeskGroup?.employeeTags?.find(
              employeeTag => employeeTag.id === tag.id,
            );
          }
          if (!alreadyHas) {
            return groupObjects.push({ label: tag.name, value: tag.id });
          }

          return null;
        });

        optionObject.label = tagCategory.name;
        optionObject.options = groupObjects;

        return options.push(optionObject);
      });

      setOptions(options);
    }
  };

  const handleButtonClick = async () => {
    if (!tagsToAssign.length) return;

    const tagIds: string[] = [];

    tagsToAssign.map((tag: any) => {
      return tagIds.push(tag.value);
    });

    if (onChanged) {
      setTimeout(onChanged, onChangeTimer);
    }

    if (addTagToEntity) {
      addTagToEntity(
        appId,
        employee
          ? employee.id
          : taggableModel
          ? taggableModel.id
          : list
          ? list.id
          : helpdeskGroup
          ? helpdeskGroup.id
          : null,
        tagIds,
        tagsToAssign,
      );
    }
    toggle();
  };

  useEffect(() => {
    if (tagCategories) {
      const { loaded, error } = tagCategories;
      if (loaded && !error) {
        getOptions();
      }
    }

    // eslint-disable-next-line
  }, [tagCategories]);

  useEffect(() => {
    if (popoverOpen && getTagCategories) {
      getTagCategories(appId);
    }
    // eslint-disable-next-line
  }, [popoverOpen]);

  return (
    <Popover
      style={{ width: 274 }}
      placement="top"
      isOpen={popoverOpen}
      target={`Popover-${index}`}
      toggle={toggle}
    >
      <PopoverBody style={{ width: 274 }}>
        <Select
          isMulti={!singleSelect}
          placeholder={t('common.select')}
          options={options}
          formatGroupLabel={formatGroupLabel}
          onChange={options => setTagsToAssign(singleSelect ? [options] : options || [])}
        />
        <Row className="mt-2">
          <Col className="pr-1">
            <Button color="secondary" className="w-100" onClick={() => toggle()}>
              {t('common.close')}
            </Button>
          </Col>
          <Col className="pl-1">
            <Button
              color="primary"
              className="w-100"
              disabled={!tagsToAssign.length}
              onClick={() => handleButtonClick()}
            >
              {t('common.save')}
            </Button>
          </Col>
        </Row>
      </PopoverBody>
    </Popover>
  );
};

export default TagSelectPopover;
