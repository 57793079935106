import { get, patch, post } from 'src/shared/services/http.service';
import { CreateIssueCategoryRequest, GetIssueCategoriesResponse } from 'src/shared/models/issue-categories.model';

const baseUrl = (applicationId: string) => `/applications/${applicationId}/issue/categories`;

export const getIssueCategories = async (applicationId: string): Promise<GetIssueCategoriesResponse> => {
    return get(baseUrl(applicationId));
};

export const postIssueCategory = async (applicationId: string, data: CreateIssueCategoryRequest): Promise<GetIssueCategoriesResponse> => {
    return post(baseUrl(applicationId), { data });
};

export const patchIssueCategory = async (applicationId: string, data: CreateIssueCategoryRequest): Promise<GetIssueCategoriesResponse> => {
    return patch(baseUrl(applicationId), { data });
};


