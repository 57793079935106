import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Draggable } from 'react-beautiful-dnd';
import { Button } from 'reactstrap';

import { ListElementModel } from '../../../redux/reducers/list-elements.reducer';
import { hasPermission } from '../../../shared/services/permissions.service';
import CheckPermission from '../../../shared/components/CheckPermission';
import { getSignedUrlAndOpenFile } from 'src/shared/services/upload.service';

interface Props {
  item: ListElementModel;
  index: number;
  openEditModal: Function;
  deleteListConfirm: Function;
}

export class DocumentsRow extends Component<Props | any> {
  getItemStyle = (isDragging: any, draggableStyle: any) => ({
    background: isDragging ? '#d4ebf2 ' : '',
    border: isDragging ? '2px solid #99d0e0 ' : '',
    display: isDragging ? 'table' : '',
    ...draggableStyle,
  });

  render() {
    return (
      <Draggable
        draggableId={this.props.item.id.toString()}
        index={this.props.index}
        isDragDisabled={!hasPermission(['simple-elements_update'])}
      >
        {(provided, snapshot) => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            <td className="name">
              {this.props.item.thumbnail && (
                <div
                  className="d-inline-block mr-2"
                  style={{
                    width: '150px',
                    height: '100px',
                    background: `url('${this.props.item.thumbnail}')`,
                    backgroundSize: 'cover',
                  }}
                />
              )}
              <div className="d-inline-block align-top">{this.props.item.name}</div>
            </td>
            <td className="date-created">{moment(this.props.item.createdAt).calendar()}</td>

            {!this.props.item.imageUrl && !this.props.item.fileUrl ? (
              <td>
                <span>No attachments</span>
              </td>
            ) : null}

            {this.props.item.imageUrl || this.props.item.fileUrl ? (
              <td className="attachments">
                {this.props.item.fileUrl ? (
                  <Button
                    className="text-dark mr-1"
                    color="success"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => getSignedUrlAndOpenFile(this.props.item.fileUrl)}
                    download
                  >
                    <i className="file-icon far fa-file-alt" />
                  </Button>
                ) : null}
              </td>
            ) : null}
            <td className="slug">
              <div className="d-inline-block align-top">{this.props.item.slug}</div>
            </td>

            <td className="operations">
              <div className="d-flex justify-content-end">
                <CheckPermission variant="enableIf" permissions={['simple-elements_update']}>
                  <Button
                    color="info"
                    className="text-dark"
                    onClick={() => this.props.openEditModal(this.props.item)}
                  >
                    <i className="far fa-edit" />
                  </Button>
                </CheckPermission>

                <CheckPermission variant="enableIf" permissions={['simple-elements_delete']}>
                  <Button
                    color="danger"
                    className="text-dark ml-2"
                    onClick={() => this.props.deleteListConfirm(this.props.item)}
                  >
                    <i className="far fa-trash-alt" />
                  </Button>
                </CheckPermission>
              </div>
            </td>
          </tr>
        )}
      </Draggable>
    );
  }
}
export default withRouter(DocumentsRow);
