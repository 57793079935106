import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap';

import { PersonaModel } from 'src/shared/models/persona.model';
import { personaCreateAndEditSchema } from 'src/shared/schemas/validation.schema';

import TextField from 'src/shared/components/form-inputs/TextField';
import Spinner from 'src/shared/components/Spinner';

interface Props {
  personas: any[];
  selectedUser: PersonaModel | null;
  handleSubmit: Function;
  isOpen: boolean;
  isLoading: boolean;
  modalEvent: string;
  toggleUserModal: Function;
}

const PersonasModal: React.FC<Props> = ({
  personas,
  selectedUser,
  isOpen,
  isLoading,
  modalEvent,
  toggleUserModal,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const [personaOptions, setPersonaOptions] = useState<any[]>([]);
  const isEditing = modalEvent === 'edit';
  const modalTitle = isEditing ? 'personas.editPersona' : 'personas.createPersona';
  const submitButtonText = isEditing ? 'common.save' : 'common.create';

  const INIT_VALUES = {
    name: selectedUser?.name || '',
    avatar: selectedUser?.avatar || '',
  };

  const getPersonaObjects = () => {
    const personaObjects: any[] = [];

    if (personas.length > 0) {
      personas.map((persona: PersonaModel) =>
        personaObjects.push({ value: persona.id, label: persona.name }),
      );
    }

    return personaObjects;
  };

  useEffect(() => {
    if (!personaOptions.length) {
      setPersonaOptions(getPersonaObjects());
    }
    // eslint-disable-next-line
  }, [personas]);

  return (
    <Fragment>
      <Modal isOpen={isOpen}>
        <ModalHeader>{t(modalTitle)}</ModalHeader>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            ...INIT_VALUES,
          }}
          validationSchema={personaCreateAndEditSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalBody>
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label for="name">{t('common.name')}</Label>
                      <TextField name="name" />
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col>
                    <FormGroup>
                      <Label for="avatar">{t('common.avatar')} URL</Label>
                      <TextField name="avatar" />
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>

              <ModalFooter>
                <Spinner loading={isLoading} />
                <Button type="submit" color="success">
                  {t(submitButtonText)}
                </Button>
                <Button
                  color="secondary"
                  disabled={isSubmitting || isLoading}
                  onClick={() => toggleUserModal()}
                >
                  {t('common.cancel')}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </Fragment>
  );
};

export default PersonasModal;
