import React, { useEffect, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { isArray } from 'lodash';

import { getSalaryAdvances, exportSalaryAdvances } from 'src/redux/actions/salaryAdvances.action';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';

import {
  PageQueryParams,
  QueryParams,
  SalaryAdvanceFilters,
  SalaryAdvanceModel,
} from 'src/shared/models';
import { List } from 'src/shared/components';
import { getCustomFields } from 'src/redux/actions/custom-fields.action';
import SalaryAdvanceFilter from './components/SalaryAdvanceFilter';
import { formatDatesToIsoString } from 'src/shared/utils';
import { fileNames } from 'src/shared/settings';
import saveFile from 'src/shared/utils/file-saver.util';
import { getListFieldsByConfiguration } from 'src/shared/utils/configuration.util';
import { configurationConstants } from 'src/shared/constants/configuration.constants';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';

interface ISalaryAdvanceProps {
  getSalaryAdvances: (
    appId: string,
    salaryAdvanceFilters: SalaryAdvanceFilters,
    queryParams: PageQueryParams,
    grouping: DynamicGroupByModel,
  ) => void;
  salaryAdvances: SalaryAdvanceModel[];
  numberOfSalaryAdvance: number;
  salaryAdvancesLoading: boolean;
  salaryAdvancesLoaded: boolean;
  salaryAdvancesError: boolean;
  getCustomFields: Function;
  getApplication: Function;
  application: ApplicationModel;
  exportSalaryAdvances: Function;
}

const SalaryAdvance: FC<ISalaryAdvanceProps> = ({
  getSalaryAdvances,
  salaryAdvances,
  numberOfSalaryAdvance,
  salaryAdvancesLoading,
  salaryAdvancesLoaded,
  salaryAdvancesError,
  getApplication,
  application,
  exportSalaryAdvances,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const initFrom = moment().subtract(7, 'days').startOf('day').utc().toISOString();
  const initTo = moment().endOf('day').utc().toISOString();
  const [grouping, setGrouping] = useState<DynamicGroupByModel>({});

  const [filter, setFilter] = useState<SalaryAdvanceFilters>({
    filters: {
      first_name: '',
      last_name: '',
      startDate: initFrom,
      endDate: initTo,
    },
  });
  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: 0,
    size: 20,
    limit: 20,
  });

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    getSalaryAdvances(appId, filter, queryParams, grouping);
    // eslint-disable-next-line
  }, [filter, grouping]);

  const fields = getListFieldsByConfiguration(
    application,
    configurationConstants.hropsSalaryAdvanceList,
    t,
  );

  const handleFilterChange = (filterValues: SalaryAdvanceFilters): void => {
    setQueryParams({
      ...queryParams,
      page: 0,
    });

    for (var key in filterValues.filters) {
      let current = (filterValues.filters as any)[key];
      if (isArray(current) && current.length === 0) {
        (filterValues.filters as any)[key] = undefined;
      }
    }
    setFilter(filterValues);
  };

  const handleDynamicGroupByChange = async (grouping: DynamicGroupByModel) => {
    setGrouping(grouping);
  };

  const fetchSalaryAdvance = (queryParams: QueryParams) => {
    const { from, to } = formatDatesToIsoString(queryParams);
    if (from || to) {
      queryParams.from = from;
      queryParams.to = to;
    }
    setQueryParams(queryParams);
    getSalaryAdvances(appId, filter, queryParams, grouping);
  };

  const exportList = async () => {
    var exported = await exportSalaryAdvances(appId, filter, grouping, {
      from: filter.filters.startDate,
      to: filter.filters.endDate,
    });

    if (exported) {
      saveFile(exported, fileNames.SALARY_ADVANCES_EXPORT, 'xlsx');
    }
  };

  return (
    <div className="animated fadeIn">
      <List
        useCardContent
        data={salaryAdvances}
        totalElements={numberOfSalaryAdvance}
        loaded={salaryAdvancesLoaded}
        loading={salaryAdvancesLoading}
        error={salaryAdvancesError}
        fetchData={fetchSalaryAdvance}
        title={t('salaryAdvance.salaryAdvance')}
        defaultItemOnPage={queryParams.size}
        filterItems={() => (
          <SalaryAdvanceFilter
            isAnyData={salaryAdvances?.length !== 0}
            filterChanged={handleFilterChange}
            filter={filter}
            export={exportList}
            handleDynamicGroupByChange={handleDynamicGroupByChange}
          />
        )}
        fields={fields}
        noDataLabel={t('salaryAdvance.noSalaryAdvance')}
        tableBodyProps={{ className: 'overflow-auto', style: { minHeight: '500px' } }}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    salaryAdvances: state.salaryAdvance.data.content,
    numberOfSalaryAdvance: state.salaryAdvance.data.totalElements,
    salaryAdvancesLoading: state.salaryAdvance.loading,
    salaryAdvancesLoaded: state.salaryAdvance.loaded,
    salaryAdvancesError: state.salaryAdvance.error,
    application: state.application.data,
  };
};

const mapDispatchToProps = {
  getSalaryAdvances,
  getCustomFields,
  getApplication,
  exportSalaryAdvances,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalaryAdvance);
