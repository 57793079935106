import { Dispatch } from 'redux';
import {
  getDailyCountOfConversationsAndMessages as getDailyCountOfConversationsAndMessagesService,
  exportDailyCountOfConversationsAndMessages as exportDailyCountOfConversationsAndMessagesService,
  getHourlyDistributionOfAllMessagesPercent as getHourlyDistributionOfAllMessagesPercentService,
  exportHourlyDistributionOfAllMessagesPercent as exportHourlyDistributionOfAllMessagesPercentService,
  getHourlyDistributionOfAllMessagesAvgCount as getHourlyDistributionOfAllMessagesAvgCountService,
  exportHourlyDistributionOfAllMessagesAvgCount as exportHourlyDistributionOfAllMessagesAvgCountService,
} from '../services/usage-statistics.service';
import { UsageStatisticsQueryParams } from '../../shared/models/query-params.model';
import { DynamicGroupByModel } from 'src/shared/models/dynamic-group-by.model';

export const GET_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_REQUEST =
  'usage-statistics/GET_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_REQUEST';
export const GET_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_SUCCESS =
  'usage-statistics/GET_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_SUCCESS';
export const GET_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_ERROR =
  'usage-statistics/GET_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_ERROR';

export const GET_ANALITICS_GROUP_BY_LIST_REQUEST =
  'usage-statistics/GET_ANALITICS_GROUP_BY_LIST_REQUEST';
export const GET_ANALITICS_GROUP_BY_LIST_SUCCESS =
  'usage-statistics/GET_ANALITICS_GROUP_BY_LIST_SUCCESS';
export const GET_ANALITICS_GROUP_BY_LIST_ERROR =
  'usage-statistics/GET_ANALITICS_GROUP_BY_LIST_ERROR';

export const EXPORT_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_REQUEST =
  'usage-statistics/EXPORT_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_REQUEST';
export const EXPORT_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_SUCCESS =
  'usage-statistics/EXPORT_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_SUCCESS';
export const EXPORT_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_ERROR =
  'usage-statistics/EXPORT_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_ERROR';

export const GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_REQUEST =
  'usage-statistics/GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_REQUEST';
export const GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_SUCCESS =
  'usage-statistics/GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_SUCCESS';
export const GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_ERROR =
  'usage-statistics/GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_ERROR';

export const EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_REQUEST =
  'usage-statistics/EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_REQUEST';
export const EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_SUCCESS =
  'usage-statistics/EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_SUCCESS';
export const EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_ERROR =
  'usage-statistics/EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_ERROR';

export const GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_REQUEST =
  'usage-statistics/GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_REQUEST';
export const GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_SUCCESS =
  'usage-statistics/GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_SUCCESS';
export const GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_ERROR =
  'usage-statistics/GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_ERROR';

export const EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_REQUEST =
  'usage-statistics/EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_REQUEST';
export const EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_SUCCESS =
  'usage-statistics/EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_SUCCESS';
export const EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_ERROR =
  'usage-statistics/EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_ERROR';

export const getDailyCountOfConversationsAndMessages = (
  applicationId: number,
  params: UsageStatisticsQueryParams,
  grouping: DynamicGroupByModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_REQUEST,
    });

    try {
      const sessionsAndMessages = await getDailyCountOfConversationsAndMessagesService(
        applicationId,
        params,
        grouping,
      );

      dispatch({
        type: GET_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_SUCCESS,
        payload: sessionsAndMessages,
      });
    } catch (error) {
      dispatch({
        type: GET_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_ERROR,
        error,
      });
      throw error;
    }
  };
};

export const exportDailyCountOfConversationsAndMessages = (
  applicationId: number,
  params: UsageStatisticsQueryParams,
  grouping: DynamicGroupByModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EXPORT_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_REQUEST,
    });

    try {
      const payload = await exportDailyCountOfConversationsAndMessagesService(
        applicationId,
        params,
        grouping,
      );

      dispatch({
        type: EXPORT_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: EXPORT_DAILY_COUNT_OF_CONVERSATIONS_AND_MESSAGES_ERROR,
        error,
      });

      throw error;
    }
  };
};

export const getHourlyDistributionOfAllMessagesPercent = (
  applicationId: number,
  params: UsageStatisticsQueryParams,
  grouping: DynamicGroupByModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_REQUEST,
    });

    try {
      const hourlyDistributionPercent = await getHourlyDistributionOfAllMessagesPercentService(
        applicationId,
        params,
        grouping,
      );

      dispatch({
        type: GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_SUCCESS,
        payload: hourlyDistributionPercent,
      });
    } catch (error) {
      dispatch({
        type: GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_ERROR,
        error,
      });
      throw error;
    }
  };
};

export const exportHourlyDistributionOfAllMessagesPercent = (
  applicationId: number,
  params: UsageStatisticsQueryParams,
  grouping: DynamicGroupByModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_REQUEST,
    });

    try {
      const payload = await exportHourlyDistributionOfAllMessagesPercentService(
        applicationId,
        params,
        grouping,
      );

      dispatch({
        type: EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_PERCENT_ERROR,
        error,
      });

      throw error;
    }
  };
};

export const getHourlyDistributionOfAllMessagesAvgCount = (
  applicationId: number,
  params: UsageStatisticsQueryParams,
  grouping: DynamicGroupByModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_REQUEST,
    });

    try {
      const hourlyDistributionAvgCount = await getHourlyDistributionOfAllMessagesAvgCountService(
        applicationId,
        params,
        grouping,
      );

      dispatch({
        type: GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_SUCCESS,
        payload: hourlyDistributionAvgCount,
      });
    } catch (error) {
      dispatch({
        type: GET_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_ERROR,
        error,
      });
      throw error;
    }
  };
};

export const exportHourlyDistributionOfAllMessagesAvgCount = (
  applicationId: number,
  params: UsageStatisticsQueryParams,
  grouping: DynamicGroupByModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_REQUEST,
    });

    try {
      const payload = await exportHourlyDistributionOfAllMessagesAvgCountService(
        applicationId,
        params,
        grouping,
      );

      dispatch({
        type: EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: EXPORT_HOURLY_DISTRIBUTION_OF_ALL_MESSAGES_AVG_COUNT_ERROR,
        error,
      });
      throw error;
    }
  };
};
