import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup, Label } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import { StateModel } from 'src/shared/models/default-state.model';

import { emailMessageSchema } from 'src/shared/schemas/validation.schema';
import { getDisabledHours, getDisabledMinutes } from 'src/shared/utils/time.util';

import TextField from 'src/shared/components/form-inputs/TextField';
import TextArea from 'src/shared/components/form-inputs/TextArea';
import SingleDate from 'src/shared/components/date-pickers/SingleDate';
import Spinner from 'src/shared/components/Spinner';

import styles from '../../SendMessageModal.module.scss';

interface Props {
  sendMessage: Function;
  toggleModal: Function;
  messageSending: StateModel;
}

const INIT_VALUES = {
  replyTo: '',
  subject: '',
  message: '',
  sendAtDate: moment(),
  sendAtTime: moment().add(1, 'minute'),
};

const Email: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { sendMessage, toggleModal, messageSending } = props;

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        ...INIT_VALUES,
      }}
      validationSchema={emailMessageSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await sendMessage(values);
        setSubmitting(false);
      }}
    >
      {({ values, errors, isSubmitting, setFieldValue }) => (
        <Form>
          <FormGroup>
            <Label for="replyTo">{t('common.replyTo')}</Label>
            <TextField name="replyTo" />
          </FormGroup>

          <FormGroup>
            <Label for="subject">{t('common.subject')}</Label>
            <TextField name="subject" />
          </FormGroup>

          <FormGroup>
            <Label for="message">{t('common.message')}</Label>
            <TextArea name="message" rows="5" />
          </FormGroup>

          <FormGroup>
            <Label for="sendAtDate">{t('common.sendAt')}</Label>
            <Field component={SingleDate} id="sendAtDate" name="sendAtDate" />
            <TimePicker
              id="sendAtTime"
              name="sendAtTime"
              className={styles.timePicker}
              popupClassName={styles.timePickerPopup}
              allowEmpty={false}
              defaultValue={values.sendAtTime}
              placement="topLeft"
              onChange={(time: moment.Moment) => {
                setFieldValue('sendAtTime', time);
              }}
              showSecond={false}
              disabledHours={() => getDisabledHours()}
              disabledMinutes={(h: number) => getDisabledMinutes(h)}
            />
            {(errors.sendAtDate || errors.sendAtTime) && (
              <div className="input-error-message">{errors.sendAtDate || errors.sendAtTime}</div>
            )}
          </FormGroup>

          <div className={styles.modalButtons}>
            <Spinner loading={isSubmitting || messageSending.loading} className="mr-2" />
            <Button type="submit" color="primary" disabled={isSubmitting || messageSending.loading}>
              {t('common.send')}
            </Button>
            <Button color="secondary" onClick={() => toggleModal()}>
              {t('common.cancel')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Email;
