import { Dispatch } from 'redux';
import { QueryParams } from '../../shared/models/query-params.model';
import { getConversationHistory as getConversationHistoryService } from '../services/conversation-history.service';

export const GET_CONVERSATION_HISTORY_REQUEST =
  'conversation-history/GET_CONVERSATION_HISTORY_REQUEST';
export const GET_CONVERSATION_HISTORY_SUCCESS =
  'conversation-history/GET_CONVERSATION_HISTORY_SUCCESS';
export const GET_CONVERSATION_HISTORY_ERROR = 'conversation-history/GET_CONVERSATION_HISTORY_ERROR';

export const GET_NEXT_BATCH_OF_CONVERSATION_HISTORY_REQUEST =
  'conversation-history/GET_NEXT_BATCH_OF_CONVERSATION_HISTORY_REQUEST';
export const GET_NEXT_BATCH_OF_CONVERSATION_HISTORY_SUCCESS =
  'conversation-history/GET_NEXT_BATCH_OF_CONVERSATION_HISTORY_SUCCESS';
export const GET_NEXT_BATCH_OF_CONVERSATION_HISTORY_ERROR =
  'conversation-history/GET_NEXT_BATCH_OF_CONVERSATION_HISTORY_ERROR';

export const CLEAR_CONVERSATION_HISTORY = 'conversation-history/CLEAR_CONVERSATION_HISTORY';

export const SET_CONVERSATION_HISTORY_CURRENT_USER =
  'conversation-history/SET_CONVERSATION_HISTORY_CURRENT_USER';

export const UPDATE_CONVERSATION_HISTORY = 'conversation-history/UPDATE_CONVERSATION_HISTORY';
export const ADD_MESSAGE = 'conversation-history/ADD_MESSAGE';

export interface ConversationHistoryModel {
  id: number;
  uuid: string;
  sessionId: string;
  applicationId: number;
  isOutgoingMessage: boolean;
  messagingPlatform: string;
  creationTimestamp: string;
  intent: string;
  intentBadge: string[];
  entity: string;
  entityTexts: string[];
  currentNodeName: string;
  messageAsText: any;
  messageAsRaw: string;
  messageType: string;
  isStartMessage: boolean;
  isLastMessage: boolean;
  isMiddleMessage: boolean;
  isEndMessage: boolean;
  isSingleMessage: boolean;
  isPrevBySameAuthor: boolean;
  isNextBySameAuthor: boolean;
  isNextBySamePersona: boolean;
  showTimeStamp: boolean;
  showAvatar: boolean;
  sender: string;
  showSender: boolean;
}

export const getConversationHistory = (userId: string, queryParams: QueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_CONVERSATION_HISTORY_REQUEST,
    });

    const payload = await getConversationHistoryService(userId, queryParams);

    try {
      dispatch({
        type: GET_CONVERSATION_HISTORY_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: GET_CONVERSATION_HISTORY_ERROR,
        error,
      });
    }
  };
};

export const getNextBatchOfConversationHistory = (userId: string, queryParams: QueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_NEXT_BATCH_OF_CONVERSATION_HISTORY_REQUEST,
    });

    const payload = await getConversationHistoryService(userId, queryParams);

    try {
      dispatch({
        type: GET_NEXT_BATCH_OF_CONVERSATION_HISTORY_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: GET_NEXT_BATCH_OF_CONVERSATION_HISTORY_ERROR,
        error,
      });
    }
  };
};

export const clearConversationHistory = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_CONVERSATION_HISTORY,
    });
  };
};

export const updateConversationHistory = (messages: ConversationHistoryModel[]) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_CONVERSATION_HISTORY,
      payload: messages,
    });
  };
};

export const setCurrentUser = (currentUser: object) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_CONVERSATION_HISTORY_CURRENT_USER,
      payload: currentUser,
    });
  };
};

export const addMessage = (message: ConversationHistoryModel) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ADD_MESSAGE,
      payload: message,
    });
  };
};
