import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import uniqueId from 'lodash/uniqueId';
import { FieldArray, FormikValues, useFormikContext } from 'formik';
import { InputGroup, Label, InputGroupAddon, Button, Table } from 'reactstrap';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import {
  QuestionnaireLabelCategory,
  QuestionnaireAnswer,
  QuestionnaireLabel, QuestionnaireAnswerActionType,
} from 'src/shared/models/questionnaire.model';
import { SelectGroupModel, SelectModel } from 'src/shared/models/common.model';

import { getGroupedSelectOptionObjects, getSelectOptionObjects } from 'src/shared/utils/misc.util';

import { getLabelCategories } from 'src/redux/actions/questionnaire.action';

import TextField from 'src/shared/components/form-inputs/TextField';
import EditableTextInput from 'src/shared/components/form-inputs/EditableTextInput';
import ReactSelect from 'src/shared/components/form-inputs/ReactSelect';
import AnswerActionsDropdown from './components/AnswerActionsDropdown';
import LabelCreateModal from './components/LabelCreateModal';
import AttributeSettingsModal from './components/AttributeSettingsModal';

import styles from '../../Questionnaire.module.scss';
import {
  answerActionType,
} from '../../../../shared/constants/questionnaire.constans';

interface Props {
  values: FormikValues;
  setAlertText: React.Dispatch<React.SetStateAction<string>>;
  getLabelCategories: Function;
  labelCategories: QuestionnaireLabelCategory[];
  messageType: string;
}

const ExtraAnswersForm: React.FC<Props> = ({
  values,
  setAlertText,
  getLabelCategories,
  labelCategories,
  messageType
}) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [answerIndex, setAnswerIndex] = useState<number | undefined>(undefined);
  const [labelOptions, setLabelOptions] = useState<SelectGroupModel[]>([]);
  const [labelCategoryOptions, setLabelCategoryOptions] = useState<SelectModel[]>([]);
  const [isAttributeSettingsModalOpen, setAttributeSettingsModalOpen] = useState<boolean>(false);
  const [requiredName, setRequiredName] = useState<string>('');
  const [multiplicableName, setMultiplicableName] = useState<string>('');
  const { answers, answer } = values;
  const filteredAnswers = answers.filter(function(item: { answerActionType: string; }) {
    if (item.answerActionType === 'DEFAULT') {
      return false;
    }
    return item.answerActionType !== 'INTERVIEW_EVENTS_ANSWER';

  })
  useEffect(() => {
    if (labelCategories.length > 0) {
      const labelOptions = getLabelOptions();
      setLabelOptions(labelOptions);

      const labelCategories = getLabelCategoryOptions();
      setLabelCategoryOptions(labelCategories);
    }
    /* eslint-disable-next-line */
  }, [labelCategories]);

  const getLabelOptions = () => {
    return getGroupedSelectOptionObjects(labelCategories, 'text', 'labels', 'text', 'slug');
  };

  const getLabelCategoryOptions = () => {
    return getSelectOptionObjects(labelCategories, 'text', 'id');
  };

  const getDefaultLabelValue = (labelSlug: string | undefined) => {
    if (!labelCategories.length || !labelSlug) return undefined;

    let selectedLabel: QuestionnaireLabel | undefined;

    labelCategories.forEach((category: QuestionnaireLabelCategory) => {
      category.labels?.forEach(label => {
        if (label.slug === labelSlug) {
          selectedLabel = { ...label, parentLabel: category.text };
        }
      });
    });

    if (selectedLabel) {
      const selectedLabelOption: SelectModel = {
        label: selectedLabel.text,
        value: selectedLabel.slug,
        parentLabel: selectedLabel.parentLabel || undefined,
      };

      return selectedLabelOption;
    }

    return undefined;
  };

  const renderAction = (answer: QuestionnaireAnswer, index: number) => {
    const { answerActionType } = answer;

    if (answerActionType === QuestionnaireAnswerActionType.LABEL_APPLICANT) {
      return (
        <div className="d-flex flex-row">
          <ReactSelect
            className={styles.select}
            name={`answers[${index}].answerActionRootData.label`}
            options={labelOptions}
            displayParentLabel
            defaultValue={getDefaultLabelValue(answer?.answerActionRootData?.labelSlug)}
          />

          <button
            type="button"
            className="btn btn-ghost-primary ml-1"
            onClick={() => {
              setModalOpen(true);
              setAnswerIndex(index);
            }}
          >
            <i className="fas fa-plus" />
          </button>
        </div>
      );
    }

    if (
      answerActionType === QuestionnaireAnswerActionType.ATTRIBUTE_APPLICANT ||
      answerActionType === QuestionnaireAnswerActionType.ATTRIBUTE_APPLICANT_POSITION
    ) {
      return (
        <div className="d-flex flex-row">

          <EditableTextInput
            data={answer?.answerActionRootData?.attributeKey || ''}
            text={answer?.answerActionRootData?.attributeKey}
            name="editedAttributeKey"
            updateData={(
              appId: string,
              id: number,
              value: string,
            ) => {
              answers[index].answerActionRootData.attributeKey = value;
              setFieldValue('answers', answers);
            }}
          />

          {answerActionType === 'ATTRIBUTE_APPLICANT' && (
            <button
              type="button"
              style={{ height: 35 }}
              className="btn btn-ghost-info ml-1"
              onClick={() => toggleAttributeSettingsModal(index)}
            >
              <i className="fas fa-cog"/>
            </button>
          )}
        </div>
      );
    }

    return null;
  };

  const handleAnswerAdd = (
    answer: string,
    answers: any,
    push: (obj: any) => void,
  ) => {
    setAlertText('');

    if (!answer) {
      return;
    }

    const isAnswerAlreadyExists = answers.filter((a: { text: string; }) => a.text === answer).length;

    if (isAnswerAlreadyExists) {
      setAlertText(t('common.answerAlreadyExists'));
      return;
    }

    if (messageType === 'OPEN_ENDED_QUESTION') {
      push({
        text: 'Invisible answer',
        editedText: 'Invisible answer',
        answerActionType: answerActionType.INVISIBLE_ANSWER,
      });
    } else {
      push({ text: answer, editedText: answer, answerActionType: QuestionnaireAnswerActionType.NONE });
    }
    const addAnswerInput = document.getElementById('addAnswerInput') as HTMLInputElement;

    if (addAnswerInput) {
      addAnswerInput.value = '';
      addAnswerInput.focus();
      setFieldValue('answer', '');
    }
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    background: isDragging ? '#d4ebf2 ' : '',
    border: isDragging ? '2px solid #99d0e0 ' : '',
    display: isDragging ? 'table' : '',
    ...draggableStyle,
  });

  const reOrderElements = (sourceIndex: number, destinationIndex: number) => {
    const tempAddedLabels: any[] = Array.from(answers);
    const [removed] = tempAddedLabels.splice(sourceIndex, 1);

    tempAddedLabels.splice(destinationIndex, 0, removed);

    return tempAddedLabels;
  };

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) {
      return null;
    }

    const reOrderedanswers = reOrderElements(source.index, destination.index);
    const orderedanswers = reOrderedanswers.map((answer, idx) => ({
      ...answer,
      order: idx,
    }));

    return setFieldValue('answers', orderedanswers);
  };

  const toggleAttributeSettingsModal = (index: number) => {
    setRequiredName(`answers[${index}].answerActionRootData.attributeRequired`);
    setMultiplicableName(`answers[${index}].answerActionRootData.attributeMultiplicable`);
    setAttributeSettingsModalOpen(!isAttributeSettingsModalOpen);
  };


  return (
    <Fragment>
      <FieldArray
        name="answers"
        render={arrayHelpers => (
          <Fragment>
            <InputGroup>
              <Label for="answer">{t('common.otherAnswers')}</Label>
              <TextField name="answer" id="addAnswerInput"/>
              <InputGroupAddon addonType="append">
                <Button
                  color="secondary"
                  style={{ zIndex: 0 }}
                  onClick={() => handleAnswerAdd(answer, answers, arrayHelpers.push)}
                >
                  {t('common.add')}
                </Button>
              </InputGroupAddon>
            </InputGroup>

            <Fragment>
              {filteredAnswers.length > 0 && (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="answers">
                    {provided => (
                      <Table className={styles.answersTable} hover striped size="sm">
                        <thead>
                          <tr>
                            <th className={styles.id}>{t('common.id')}</th>
                            <th className={styles.answer}>{t('common.answer')}</th>
                            <th className={styles.intent}>{t('common.intent')}</th>
                            <th className={styles.actionType}>{t('questionnaire.actionType')}</th>
                            <th className={styles.action}>{t('common.key')}</th>
                            <th className={styles.delete}></th>
                          </tr>
                        </thead>

                        <tbody ref={provided.innerRef} {...provided.droppableProps}>
                          {(filteredAnswers as QuestionnaireAnswer[])
                            .sort((a, b) => a.order - b.order)
                            .map((answer, index) => {
                              return (
                                <Draggable
                                  draggableId={`${answer?.id}`}
                                  index={index}
                                  key={uniqueId(`${answer.id}`)}
                                >
                                  {(provided, snapshot) => (
                                    <tr
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style,
                                      )}
                                    >
                                      <td className={styles.id}>
                                        {answer.id}
                                      </td>
                                      <td className={styles.answer}>
                                        <EditableTextInput
                                          data={answer.text || ''}
                                          text={answer.text}
                                          name="editedText"
                                          updateData={(
                                            appId: string,
                                            id: number,
                                            value: string,
                                          ) => {
                                            answers[index].editedText = value;
                                            answers[index].text = value;
                                            setFieldValue('answers', answers);
                                          }}
                                        />
                                      </td>
                                      <td className={styles.intent}>
                                        <EditableTextInput
                                          data={answer.editedIntent || ''}
                                          text={answer.editedIntent}
                                          name="editedIntent"
                                          canBeEmpty
                                          updateData={(
                                            appId: string,
                                            id: number,
                                            value: string,
                                          ) => {
                                            answers[index].editedIntent = value;
                                            setFieldValue('answers', answers);
                                          }}
                                        />
                                      </td>
                                      <td className={styles.actionType}>
                                        <AnswerActionsDropdown
                                          index={index}
                                          answer={answer}
                                          handleReplace={arrayHelpers.replace}
                                          messageType={messageType}
                                        />
                                      </td>
                                      <td className={styles.action}>
                                        {renderAction(answer, index)}
                                      </td>
                                      <td className={styles.delete}>
                                        <Button
                                          color="danger"
                                          onClick={() => arrayHelpers.remove(index)}
                                        >
                                          <i className="far fa-trash-alt" />
                                        </Button>
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              );
                            })}
                          {provided.placeholder}
                        </tbody>
                      </Table>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </Fragment>
          </Fragment>
        )}
      />

      <LabelCreateModal
        isOpen={isModalOpen}
        toggle={setModalOpen}
        labelCategoryOptions={labelCategoryOptions}
        answerIndex={answerIndex}
        getLabelCategories={getLabelCategories}
      />

      <AttributeSettingsModal
        isOpen={isAttributeSettingsModalOpen}
        toggle={toggleAttributeSettingsModal}
        requiredName={requiredName}
        multiplicableName={multiplicableName}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  labelCategories: state.questionnaireLabelCategories.resources.data,
});

const mapDispatchToProps = {
  getLabelCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraAnswersForm);
