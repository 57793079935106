import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Button } from 'reactstrap';

import {
  getConversationFlowVariables,
  updateConversationFlowVariable,
  deleteConversationFlowVariable,
  reloadConversationFlowVariables,
} from 'src/redux/actions/conversation-flow-variables.action';
import { getPlatforms } from 'src/redux/actions/platforms.action';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import {
  List,
  EditableTextInput,
  PageHeader,
  NoData,
  CheckPermission,
  ExportButton,
} from 'src/shared/components';
import { usePreviousProps } from 'src/shared/hooks';
import {
  PageQueryParams,
  Operations,
  ConversationFlowModel,
  ConversationFlowFilters,
} from 'src/shared/models';
import { createConversationFlowVariableSchema } from 'src/shared/schemas/validation.schema';
import addToast from 'src/shared/utils/notification.util';

import ConversationFlowModal from './ConversationFlowModal';
import ConversationFlowFilter from './ConversationFlowFilter';
import { exportConversionFlowVariables } from 'src/redux/services/conversation-flow-variables.service';
import { fileNames } from 'src/shared/settings';
import saveFile from 'src/shared/utils/file-saver.util';

interface conFlowProps {
  getConversationFlowVariables: Function;
  reloadConversationFlowVariables: Function;
  conversationFlowVariables: ConversationFlowModel[];
  updateConversationFlowVariable: Function;
  deleteConversationFlowVariable: Function;
  getApplication: Function;
  application: ApplicationModel;
  numberOfConversationFlowVariables: number;
  conversationFlowVariablesLoaded: boolean;
  conversationFlowVariablesLoading: boolean;
  conversationFlowVariablesError: boolean;
  getPlatforms: Function;
  platforms: string[];
}

const ConversationFlow: FC<conFlowProps> = ({
  getConversationFlowVariables,
  reloadConversationFlowVariables,
  conversationFlowVariables,
  updateConversationFlowVariable,
  deleteConversationFlowVariable,
  getApplication,
  application,
  numberOfConversationFlowVariables,
  conversationFlowVariablesLoaded,
  conversationFlowVariablesLoading,
  conversationFlowVariablesError,
  getPlatforms,
  platforms,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [activePlatform, setActivePlatform] = useState<string | null>(null);
  const [filters, setFilters] = useState<ConversationFlowFilters>();
  const prevProps = usePreviousProps({ appId, activePlatform: activePlatform, platforms, filters });

  const updateList =
    (!!prevProps.activePlatform && activePlatform !== prevProps.activePlatform) ||
    filters?.searchKey !== prevProps.filters?.searchKey ||
    filters?.searchValue !== prevProps.filters?.searchValue;

  useEffect(() => {
    if (!application.id && appId !== prevProps.appId) {
      getApplication(appId);
    }
  }, [application.id, appId, getApplication, prevProps.appId]);

  useEffect(() => {
    if (!prevProps.platforms) {
      getPlatforms(appId);
    }
  }, [getPlatforms, appId, prevProps.platforms, platforms]);

  useEffect(() => {
    if (platforms && platforms.length > 0) {
      setActivePlatform(platforms[0]);
    }
  }, [platforms]);

  const reload = async () => {
    await reloadConversationFlowVariables(appId, activePlatform);
    addToast('success', `${t('conversationFlowVariables.reloadSuccess')}`);
  };

  const handleFilterChanged = async (values: ConversationFlowFilters) => {
    setFilters(values);
  };

  return activePlatform ? (
    <div className="animated fadeIn">
      <List
        useCardContent
        title={t('conversationFlowVariables.conversationFlowVariables')}
        fetchData={(params: PageQueryParams) => {
          return getConversationFlowVariables(appId, activePlatform, params, filters);
        }}
        pageHeaderProps={{
          rightComponent: (
            <>
              <ExportButton
                exportTooltipLabel={t('common.exportToExcel')}
                disabled={conversationFlowVariables?.length === 0}
                color="primary"
                onClick={async () => {
                  const exported = await exportConversionFlowVariables(appId);
                  if (exported) {
                    saveFile(exported, fileNames.CONVERSION_FLOW_VARIABLES_EXPORT_EXCEL, 'xlsx');
                  }
                }}
              />
              <CheckPermission
                variant="displayIf"
                permissions={['conversationflow_variable_create']}
              >
                <Button color="primary" onClick={reload} className="ml-2">
                  {t('common.reload')}
                </Button>
              </CheckPermission>
            </>
          ),
        }}
        totalElements={numberOfConversationFlowVariables}
        loaded={conversationFlowVariablesLoaded}
        loading={conversationFlowVariablesLoading}
        error={conversationFlowVariablesError}
        updateList={updateList}
        data={conversationFlowVariables}
        fields={[
          {
            key: 'key',
            label: t('common.name'),
            columnStyle: 'inline-edit',
            render: item => (
              <EditableTextInput
                id={item.id!}
                data={item.key}
                name="key"
                updateData={(appId: string, id: number, value: string) =>
                  updateConversationFlowVariable(appId, activePlatform, id, {
                    key: value,
                    value: item.value,
                  })
                }
                permission="conversationflow_variable_update"
                validationSchema={createConversationFlowVariableSchema(true)}
              />
            ),
          },
          {
            key: 'value',
            columnStyle: 'inline-edit',
            label: t('common.value'),
            render: item => (
              <EditableTextInput
                id={item.id!}
                data={item.value}
                updateData={(appId: string, id: number, value: string) =>
                  updateConversationFlowVariable(appId, activePlatform, id, {
                    key: item.key,
                    value: value,
                  })
                }
                permission="conversationflow_variable_update"
              />
            ),
          },
          {
            key: 'operations',
            label: t('common.operations'),
            operations: [Operations.DELETE],
          },
        ]}
        noDataLabel={t('conversationFlowVariables.noConversationFlowVariables')}
        deleteFunction={item => deleteConversationFlowVariable(appId, activePlatform, item.id)}
        deleteTitle={t('conversationFlowVariables.deleteConversationFlowVariables')}
        deleteText={t('conversationFlowVariables.deleteConversationFlowVariablesConfirmMessage')}
        deleteConfirmKey="key"
        updatePermission="conversationflow_variable_update"
        deletePermission="conversationflow_variable_delete"
        createPermission="conversationflow_variable_create"
        createModal={(isModalOpen, closeModal) => (
          <ConversationFlowModal
            isOpen={isModalOpen}
            toggleModal={closeModal}
            messagingPlatform={activePlatform}
            platforms={platforms}
          />
        )}
        filterContent={() => (
          <ConversationFlowFilter
            platforms={platforms}
            filterChanged={handleFilterChanged}
            setActivePlatform={setActivePlatform}
          />
        )}
      />
    </div>
  ) : (
    <>
      <PageHeader title={t('conversationFlowVariables.conversationFlowVariables')} />
      <NoData label={t('common.noPlatforms')} />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    platforms: state.platforms.data,
    conversationFlowVariables: state.conversationFlowVariables.resources.data.content,
    numberOfConversationFlowVariables: state.conversationFlowVariables.resources.data.totalElements,
    conversationFlowVariablesLoaded: state.conversationFlowVariables.resources.loaded,
    conversationFlowVariablesLoading: state.conversationFlowVariables.resources.loading,
    conversationFlowVariablesError: state.conversationFlowVariables.resources.error,
  };
};

const mapDispatchToProps = {
  deleteConversationFlowVariable,
  updateConversationFlowVariable,
  getConversationFlowVariables,
  reloadConversationFlowVariables,
  getApplication,
  getPlatforms,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConversationFlow);
