import { Dispatch } from 'redux';
import { changePassword as changePasswordService } from '../services/account.service';
import { ChangePasswordModel } from '../reducers/account.reducer';

export const ACCOUNT_SET_SELECTED_OPTION_REQUEST = 'account/ACCOUNT_SET_SELECTED_OPTION_REQUEST';
export const ACCOUNT_SET_SELECTED_OPTION_SUCCESS = 'account/ACCOUNT_SET_SELECTED_OPTION_SUCCESS';
export const ACCOUNT_SET_SELECTED_OPTION_ERROR = 'account/ACCOUNT_SET_SELECTED_OPTION_ERROR';

export const ACCOUNT_CHANGE_PASSWORD_REQUEST = 'account/AUTH_LOGIN_REQUEST';
export const ACCOUNT_CHANGE_PASSWORD_SUCCESS = 'account/AUTH_LOGIN_SUCCESS';
export const ACCOUNT_CHANGE_PASSWORD_ERROR = 'account/AUTH_LOGIN_ERROR';

export const setSelectedOption = (option: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ACCOUNT_SET_SELECTED_OPTION_REQUEST,
    });

    try {
      dispatch({
        type: ACCOUNT_SET_SELECTED_OPTION_SUCCESS,
        payload: option,
      });
    } catch (error) {
      dispatch({
        type: ACCOUNT_SET_SELECTED_OPTION_ERROR,
        error,
      });
    }
  };
};

export const changePassword = (payload: ChangePasswordModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ACCOUNT_CHANGE_PASSWORD_REQUEST,
    });

    try {
      dispatch({
        type: ACCOUNT_CHANGE_PASSWORD_SUCCESS,
        payload: await changePasswordService(payload),
      });
    } catch (error) {
      dispatch({
        type: ACCOUNT_CHANGE_PASSWORD_ERROR,
        error,
      });
    }
  };
};
