import { post, del } from './http.service';
import { SignatureForDeleteModel } from '../../redux/actions/delete.action';

interface deleteService {
  delete: Function;
}

export const getSignatureForDeleteService = async (
  extension: string,
  fileNameWithoutExtension: string,
  method: string,
) => {
  const payload = {
    extension,
    filename: fileNameWithoutExtension,
    method,
  };
  return post('/signatures', { data: payload });
};

export class AWSDelete implements deleteService {
  delete = async (signedUrl: string) => {
    return del(signedUrl);
  };
}

export class AzureDelete implements deleteService {
  delete = async (signedUrl: string) => {
    return del(signedUrl);
  };
}

export function deleteFile(signature: SignatureForDeleteModel) {
  if (signature.storageType === 'aws-s3') {
    return new AWSDelete().delete(signature.signedUrl);
  }
  if (signature.storageType === 'azure-blobstorage') {
    return new AzureDelete().delete(signature.signedUrl);
  }

  return Promise.reject();
}
