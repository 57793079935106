import { Dispatch } from 'redux';
import { PageQueryParams } from 'src/shared/models';

import {
  deleteSurvey as deleteSurveyService,
  getSurveys as getSurveysService,
  getSurveySubscribers as getSurveySubscribersService,
  createSurvey as createSurveyService,
  getSurvey as getSurveyService,
  updateSurvey as updateSurveyService,
  getTagCategories as getTagCategoriesService,
  addTagToSurvey as addTagToSurveyService,
  removeSurveyTag as removeSurveyTagService,
  getCategoryTags as getCategoryTagsService,
  deleteTagCategory as deleteTagCategoryService,
  getSurveyQuestions as getSurveyQuestionsService,
  getSurveyQuestionAnswers as getSurveyQuestionAnswersService,
} from 'src/redux/services/survey.service';
import {
  SurveyModel,
  SurveyQuestionAnswerModel,
  SurveyQuestionsModel,
} from 'src/shared/models/survey.model';

export const GET_SURVEYS = 'GET_SURVEYS';
export const GET_SURVEYS_SUCCESS = 'GET_SURVEYS_SUCCESS';
export const GET_SURVEYS_ERROR = 'GET_SURVEYS_ERROR';

export const GET_SURVEY_SUBSCRIBERS = 'GET_SURVEYS';
export const GET_SURVEY_SUBSCRIBERS_SUCCESS = 'GET_SURVEYS_SUCCESS';
export const GET_SURVEY_SUBSCRIBERS_ERROR = 'GET_SURVEYS_ERROR';

export const DELETE_SURVEY = 'DELETE_SURVEY';
export const DELETE_SURVEY_REQUEST = 'DELETE_SURVEY_REQUEST';
export const DELETE_SURVEY_SUCCESS = 'DELETE_SURVEY_SUCCESS';
export const DELETE_SURVEY_ERROR = 'DELETE_SURVEY_ERROR';

export const CREATE_SURVEY = 'surveys/CREATE_SURVEY';
export const CREATE_SURVEY_SUCCESS = 'surveys/CREATE_SURVEY_SUCCESS';
export const CREATE_SURVEY_ERROR = 'surveys/CREATE_SURVEY_ERROR';
export const READ_SURVEY = 'surveys/READ_SURVEY';
export const READ_SURVEY_SUCCESS = 'surveys/READ_SURVEY_SUCCESS';
export const READ_SURVEY_ERROR = 'surveys/READ_SURVEY_ERROR';
export const UPDATE_SURVEY = 'surveys/UPDATE_SURVEY';
export const UPDATE_SURVEY_SUCCESS = 'surveys/UPDATE_SURVEY_SUCCESS';
export const UPDATE_SURVEY_ERROR = 'surveys/UPDATE_SURVEY_ERROR';
export const READ_SURVEY_QUESTIONS = 'surveys/READ_SURVEY_QUESTIONS';
export const READ_SURVEY_QUESTIONS_SUCCESS = 'surveys/READ_SURVEY_QUESTIONS_SUCCESS';
export const READ_SURVEY_QUESTIONS_ERROR = 'surveys/READ_SURVEY_QUESTIONS_ERROR';
export const READ_SURVEY_QUESTION_ANSWERS = 'surveys/READ_SURVEY_QUESTION_ANSWERS';
export const READ_SURVEY_QUESTION_ANSWERS_SUCCESS = 'surveys/READ_SURVEY_QUESTION_ANSWERS_SUCCESS';
export const READ_SURVEY_QUESTION_ANSWERS_ERROR = 'surveys/READ_SURVEY_QUESTION_ANSWERS_ERROR';
export const REVERT_SURVEY = 'surveys/CLEAR_SURVEY';
export const REVERT_SURVEY_QUESTIONS = 'surveys/REVERT_SURVEY_QUESTIONS';
export const REVERT_SURVEY_QUESTION_ANSWERS = 'surveys/REVERT_SURVEY_QUESTIONS';

export const GET_TAG_CATEGORIES_REQUEST = 'employee/GET_TAG_CATEGORIES_REQUEST';
export const GET_TAG_CATEGORIES_SUCCESS = 'employee/GET_TAG_CATEGORIES_SUCCESS';
export const GET_TAG_CATEGORIES_ERROR = 'employee/GET_TAG_CATEGORIES_ERROR';

export const ADD_TAG_TO_SURVEY_REQUEST = 'employee/ADD_TAG_TO_SURVEY_REQUEST';
export const ADD_TAG_TO_SURVEY_SUCCESS = 'employee/ADD_TAG_TO_SURVEY_SUCCESS';
export const ADD_TAG_TO_SURVEY_ERROR = 'employee/ADD_TAG_TO_SURVEY_ERROR';

export const REMOVE_SURVEY_TAG_REQUEST = 'employee/REMOVE_SURVEY_TAG_REQUEST';
export const REMOVE_SURVEY_TAG_SUCCESS = 'employee/REMOVE_SURVEY_TAG_SUCCESS';
export const REMOVE_SURVEY_TAG_ERROR = 'employee/REMOVE_SURVEY_TAG_ERROR';

export const GET_CATEGORY_TAGS_REQUEST = 'employee/GET_CATEGORY_TAGS_REQUEST';
export const GET_CATEGORY_TAGS_SUCCESS = 'employee/GET_CATEGORY_TAGS_SUCCESS';
export const GET_CATEGORY_TAGS_ERROR = 'employee/GET_CATEGORY_TAGS_ERROR';

export const DELETE_TAG_CATEGORY_REQUEST = 'employee/DELETE_TAG_CATEGORY_REQUEST';
export const DELETE_TAG_CATEGORY_SUCCESS = 'employee/DELETE_TAG_CATEGORY_SUCCESS';
export const DELETE_TAG_CATEGORY_ERROR = 'employee/DELETE_TAG_CATEGORY_ERROR';

export const getSurveys = (appId: string, queryParams: PageQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_SURVEYS,
    });

    try {
      dispatch({
        type: GET_SURVEYS_SUCCESS,
        payload: await getSurveysService(appId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_SURVEYS_ERROR,
        error,
      });
    }
  };
};

export const getSurveySubscribers = (
  appId: string,
  surveyId: string,
  queryParams: PageQueryParams,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_SURVEY_SUBSCRIBERS,
    });

    try {
      dispatch({
        type: GET_SURVEY_SUBSCRIBERS_SUCCESS,
        payload: await getSurveySubscribersService(appId, surveyId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_SURVEY_SUBSCRIBERS_ERROR,
        error,
      });
    }
  };
};

export const deleteSurvey = (appId: string, surveyId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_SURVEY,
    });

    try {
      await deleteSurveyService(appId, surveyId.toString());
      dispatch({
        type: DELETE_SURVEY_SUCCESS,
        payload: { surveyId },
      });
    } catch (error) {
      dispatch({
        type: DELETE_SURVEY_ERROR,
        error,
      });
    }
  };
};

export const createSurvey = (appId: string, surveyData: SurveyModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_SURVEY,
    });

    const response = await createSurveyService(appId, surveyData);

    try {
      dispatch({
        type: CREATE_SURVEY_SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: CREATE_SURVEY_ERROR,
        error,
      });
    }
  };
};

export const getSurvey = (appId: string, surveyId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: READ_SURVEY,
    });

    try {
      dispatch({
        type: READ_SURVEY_SUCCESS,
        payload: await getSurveyService(appId, surveyId),
      });
    } catch (error) {
      dispatch({
        type: READ_SURVEY_ERROR,
        error,
      });
    }
  };
};

export const getSurveyQuestions = (appId: string, surveyId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: READ_SURVEY_QUESTIONS,
    });

    let surveyQuestions = (await getSurveyQuestionsService(
      appId,
      surveyId,
    )) as SurveyQuestionsModel[];

    if (surveyQuestions) {
      surveyQuestions = surveyQuestions.map(question => {
        return {
          ...question,
          surveyQuestionAnswers: question.surveyQuestionAnswers
            .map((answer, index) => ({
              ...answer,
              originalIndex: index,
            }))
            .sort((a, b) =>
              a.numberOfSubscriberAnswered >= b.numberOfSubscriberAnswered ? -1 : 1,
            ),
          numberOfAnswers: question.surveyQuestionAnswers
            ? question.surveyQuestionAnswers.reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.numberOfSubscriberAnswered,
                0,
              )
            : 0,
        };
      });
    }

    try {
      dispatch({
        type: READ_SURVEY_QUESTIONS_SUCCESS,
        payload: {
          content: surveyQuestions,
          pageable: false,
          totalPages: 1,
          totalElements: surveyQuestions.length,
          numberOfElements: surveyQuestions.length,
          first: true,
        },
      });
    } catch (error) {
      dispatch({
        type: READ_SURVEY_QUESTIONS_ERROR,
        error,
      });
    }
  };
};

export const getSurveyQuestionAnswers = (
  appId: string,
  surveyId: string,
  surveyQuestionId: string,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: READ_SURVEY_QUESTION_ANSWERS,
    });

    const surveyQuestionsAnswers = (await getSurveyQuestionAnswersService(
      appId,
      surveyId,
      surveyQuestionId,
    )) as SurveyQuestionAnswerModel[];

    try {
      dispatch({
        type: READ_SURVEY_QUESTION_ANSWERS_SUCCESS,
        payload: {
          content: surveyQuestionsAnswers,
          pageable: false,
          totalPages: 1,
          totalElements: surveyQuestionsAnswers.length,
          numberOfElements: surveyQuestionsAnswers.length,
          first: true,
        },
      });
    } catch (error) {
      dispatch({
        type: READ_SURVEY_QUESTION_ANSWERS_ERROR,
        error,
      });
    }
  };
};

export const updateSurvey = (appId: string, surveyId: string, data: SurveyModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_SURVEY,
    });

    try {
      dispatch({
        type: UPDATE_SURVEY_SUCCESS,
        payload: await updateSurveyService(appId, surveyId, data),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_SURVEY_ERROR,
        error,
      });
    }
  };
};

export const revertSurvey = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REVERT_SURVEY,
    });
  };
};

export const getTagCategories = (applicationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TAG_CATEGORIES_REQUEST,
    });

    try {
      dispatch({
        type: GET_TAG_CATEGORIES_SUCCESS,
        payload: await getTagCategoriesService(applicationId),
      });
    } catch (error) {
      dispatch({
        type: GET_TAG_CATEGORIES_ERROR,
        error,
      });
    }
  };
};

export const addTagToSurvey = (applicationId: string, employeeId: string, tagIds: string[]) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ADD_TAG_TO_SURVEY_REQUEST,
    });

    try {
      dispatch({
        type: ADD_TAG_TO_SURVEY_SUCCESS,
        payload: await addTagToSurveyService(applicationId, employeeId, tagIds),
      });
    } catch (error) {
      dispatch({
        type: ADD_TAG_TO_SURVEY_ERROR,
        error,
      });
    }
  };
};

export const removeSurveyTag = (applicationId: string, employeeId: string, tagId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REMOVE_SURVEY_TAG_REQUEST,
    });

    try {
      dispatch({
        type: REMOVE_SURVEY_TAG_SUCCESS,
        payload: await removeSurveyTagService(applicationId, employeeId, tagId),
      });
    } catch (error) {
      dispatch({
        type: REMOVE_SURVEY_TAG_ERROR,
        error,
      });
    }
  };
};

export const getCategoryTags = (applicationId: string, categoryId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_CATEGORY_TAGS_REQUEST,
    });

    try {
      dispatch({
        type: GET_CATEGORY_TAGS_SUCCESS,
        payload: await getCategoryTagsService(applicationId, categoryId),
      });
    } catch (error) {
      dispatch({
        type: GET_CATEGORY_TAGS_ERROR,
        error,
      });
    }
  };
};

export const deleteTagCategory = (applicationId: string, categoryId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_TAG_CATEGORY_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_TAG_CATEGORY_SUCCESS,
        payload: await deleteTagCategoryService(applicationId, categoryId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_TAG_CATEGORY_ERROR,
        error,
      });
    }
  };
};

export const revertSurveyQuestions = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REVERT_SURVEY_QUESTIONS,
    });
  };
};
