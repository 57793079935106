import { del, get, patch, post } from '../../shared/services/http.service';
import { PageQueryParams } from 'src/shared/models';
import {
  SurveyModel,
  SurveyQuestionAnswerEditorModel,
  SurveyQuestionAnswerOptionModel,
  SurveyQuestionEditorModel,
} from 'src/shared/models/survey.model';

const baseUrl = (applicationId: string) => `/applications/${applicationId}/survey/surveys`;

export const getSurveys = async (applicationId: string, params: PageQueryParams) => {
  return get(baseUrl(applicationId), { params });
};

export const getSurveySubscribers = async (
  applicationId: string,
  surveyId: string,
  params: PageQueryParams,
) => {
  return get(`${baseUrl(applicationId)}/${surveyId}/subscriptions`, { params });
};

export const getSurvey = async (applicationId: string, surveyId: string) => {
  return get(`${baseUrl(applicationId)}/${surveyId}`);
};

export const createSurvey = async (applicationId: string, data: SurveyModel) => {
  return post(baseUrl(applicationId), { data });
};

export const updateSurvey = async (applicationId: string, surveyId: string, data: SurveyModel) => {
  return patch(`${baseUrl(applicationId)}/${surveyId}`, { data });
};

export const deleteSurvey = async (applicationId: string, surveyId: string) => {
  return del(`${baseUrl(applicationId)}/${surveyId}`);
};

export const getSurveyQuestions = async (applicationId: string, surveyId: string) => {
  return get(`${baseUrl(applicationId)}/${surveyId}/questions`);
};

export const getSurveyQuestion = async (
  applicationId: string,
  surveyId: string,
  surveyQuestionId: string,
) => {
  return get(`${baseUrl(applicationId)}/${surveyId}/questions/${surveyQuestionId}`);
};

export const createSurveyQuestion = async (
  applicationId: string,
  surveyId: string | number,
  data: SurveyQuestionEditorModel,
) => {
  return post(`applications/${applicationId}/survey/surveys/${surveyId}/questions`, { data });
};

export const updateSurveyQuestion = async (
  applicationId: string,
  surveyId: string,
  surveyQuestionId: string,
  data: SurveyQuestionEditorModel,
) => {
  return patch(
    `applications/${applicationId}/survey/surveys/${surveyId}/questions/${surveyQuestionId}`,
    { data },
  );
};

export const sortSurveyQuestions = async (
  applicationId: string,
  surveyId: string,
  data: string[],
) => {
  return post(`${baseUrl(applicationId)}/${surveyId}/questions/sort`, { data });
};

export const deleteSurveyQuestion = async (
  applicationId: string,
  surveyId: string,
  surveyQuestionId: string,
) => {
  return del(`${baseUrl(applicationId)}/${surveyId}/questions/${surveyQuestionId}`);
};

export const getSurveyQuestionAnswers = async (
  applicationId: string,
  surveyId: string,
  surveyQuestionId: string,
) => {
  return get(`${baseUrl(applicationId)}/${surveyId}/questions/${surveyQuestionId}/answers`);
};

export const getSurveyQuestionAnswer = async (
  applicationId: string,
  surveyId: string,
  surveyQuestionId: string,
  surveyAnswerId: string,
) => {
  return get(
    `${baseUrl(applicationId)}/${surveyId}/questions/${surveyQuestionId}/answers/${surveyAnswerId}`,
  );
};

export const createSurveyQuestionAnswer = async (
  applicationId: string,
  surveyId: string,
  surveyQuestionId: string,
  data: SurveyQuestionAnswerEditorModel,
) => {
  return post(`${baseUrl(applicationId)}/${surveyId}/questions/${surveyQuestionId}/answers`, {
    data,
  });
};

export const updateSurveyQuestionAnswer = async (
  applicationId: string,
  surveyId: string,
  surveyQuestionId: string,
  surveyAnswerId: string,
  data: SurveyQuestionAnswerOptionModel,
) => {
  return patch(
    `${baseUrl(applicationId)}/${surveyId}/questions/${surveyQuestionId}/answers/${surveyAnswerId}`,
    { data },
  );
};

export const sortSurveyQuestionAnswers = async (
  applicationId: string,
  surveyId: string,
  surveyQuestionId: string,
  data: string[],
) => {
  return post(`${baseUrl(applicationId)}/${surveyId}/questions/${surveyQuestionId}/answers/sort`, {
    data,
  });
};

export const deleteSurveyQuestionAnswer = async (
  applicationId: string,
  surveyId: string,
  surveyQuestionId: string,
  surveyAnswerId: string,
) => {
  return del(
    `${baseUrl(applicationId)}/${surveyId}/questions/${surveyQuestionId}/answers/${surveyAnswerId}`,
  );
};

export const getTagCategories = async (applicationId: string) => {
  return get(`/hrops/applications/${applicationId}/survey/categories`);
};

export const addTagToSurvey = async (applicationId: string, surveyId: string, tagIds: string[]) => {
  let query: any = tagIds.map(tagId =>
    post(`/hrops/applications/${applicationId}/tag/surveys/${surveyId}/tags/${tagId}`),
  );
  return Promise.all(query);
  // TODO: replace after batch api works
  //return post(`/hrops/applications/${applicationId}/survey/surveys/${surveyId}/tags/assign`, { data: tagIds });
};

export const removeSurveyTag = async (applicationId: string, surveyId: string, tagId: string) => {
  return del(`/hrops/applications/${applicationId}/tag/surveys/${surveyId}/tags/${tagId}`);
};

export const getCategoryTags = async (applicationId: string, categoryId: string) => {
  return get(`/hrops/applications/${applicationId}/survey/categories/${categoryId}/tags`);
};

export const deleteTagCategory = async (applicationId: string, categoryId: string) => {
  return del(`/hrops/applications/${applicationId}/survey/categories/${categoryId}`);
};

export const exportReport = async (applicationId: string, surveyId: string) => {
  return get(`/applications/${applicationId}/survey/surveys/${surveyId}/reports/export/xlsx`, {
    requestConfig: {
      responseType: 'blob',
    },
  });
};
