import { StateModel } from '../../shared/models/default-state.model';

import {
  GET_CONVERSATION_HISTORY_REQUEST,
  GET_CONVERSATION_HISTORY_SUCCESS,
  GET_CONVERSATION_HISTORY_ERROR,
  GET_NEXT_BATCH_OF_CONVERSATION_HISTORY_REQUEST,
  GET_NEXT_BATCH_OF_CONVERSATION_HISTORY_SUCCESS,
  GET_NEXT_BATCH_OF_CONVERSATION_HISTORY_ERROR,
  CLEAR_CONVERSATION_HISTORY,
  UPDATE_CONVERSATION_HISTORY,
  SET_CONVERSATION_HISTORY_CURRENT_USER,
  ADD_MESSAGE,
} from '../actions/conversation-history.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {
    content: [],
  },
};

const CURRENT_USER_STATE: object = {
  user: {
    user: '',
    uuid: '',
  },
};

export const conversationHistoryReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_CONVERSATION_HISTORY_REQUEST:
    case GET_NEXT_BATCH_OF_CONVERSATION_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case GET_CONVERSATION_HISTORY_SUCCESS:
      const payload = {
        ...action.payload,
        content: action.payload.content.reverse(),
      };

      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload,
      };

    case GET_NEXT_BATCH_OF_CONVERSATION_HISTORY_SUCCESS:
      const oldContent = state.data.content;
      const newContent = action.payload.content.reverse();
      const mergedContent = newContent.concat(oldContent);

      return {
        ...state,
        loading: false,
        loaded: true,
        data: { ...action.payload, content: mergedContent },
        error: null,
      };

    case GET_CONVERSATION_HISTORY_ERROR:
    case GET_NEXT_BATCH_OF_CONVERSATION_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    case ADD_MESSAGE:
      return {
        ...state,
        data: {
          ...state.data,
          content: [...state.data.content, action.payload],
        },
      };

    case CLEAR_CONVERSATION_HISTORY:
      return {
        ...state,
        data: {
          ...state.data,
          content: [],
        },
      };
    case UPDATE_CONVERSATION_HISTORY:
      return {
        ...state,
        data: { ...state.data, content: [...action.payload] },
      };

    default:
      return state;
  }
};

export const currentUserReducer = (state: object = CURRENT_USER_STATE, action: any) => {
  switch (action.type) {
    case SET_CONVERSATION_HISTORY_CURRENT_USER:
      return {
        user: action.payload,
      };

    default:
      return state;
  }
};
