import React, { FC } from 'react';

import { Carousel } from 'react-responsive-carousel';

import './styles.scss';
import 'react-responsive-carousel/lib/styles/carousel.css';

export interface CarouselTemplateProps {
  elements: { title: string; imageUrl: string; buttons: { url: string[] } }[];
}

const CarouselTemplate: FC<CarouselTemplateProps> = props => {
  const statusFormatter = (currentItem: number, total: number) => {
    return `${currentItem}/${total}`;
  };

  const arrowStyles: React.CSSProperties = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 30,
    height: 30,
    cursor: 'pointer',
    borderRadius: 20,
    color: 'darkgray',
    border: 0,
    boxShadow: '0px 3px 6px #00000029',
    opacity: 0.8,
  };

  const renderArrowPrev = (clickHandler: () => void, hasPrev: boolean, label: string) => {
    return (
      hasPrev && (
        <button
          type="button"
          onClick={clickHandler}
          title={label}
          style={{ ...arrowStyles, left: 0 }}
        >
          {'<'}
        </button>
      )
    );
  };

  const renderArrowNext = (clickHandler: () => void, hasNext: boolean, label: string) => {
    return (
      hasNext && (
        <button
          type="button"
          onClick={clickHandler}
          title={label}
          style={{ ...arrowStyles, right: 0 }}
        >
          {'>'}
        </button>
      )
    );
  };
  return (
    <div className="carousel-snippet-container">
      <div className="carousel-snippet">
        <Carousel
          showThumbs={false}
          showIndicators={false}
          statusFormatter={statusFormatter}
          renderArrowPrev={renderArrowPrev}
          renderArrowNext={renderArrowNext}
        >
          {props.elements.map((element, index) => (
            <div key={index} className="element">
              <div className="carousel-snippet-image">
                <img src={element.imageUrl} alt="Generic Template" />
              </div>
              <span className="generic-title">{element.title}</span>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default CarouselTemplate;
