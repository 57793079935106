import { StateModel } from '../../shared/models/default-state.model';
import {
  GET_POSITION_CRITERIA_REQUEST,
  GET_POSITION_CRITERIA_SUCCESS,
  GET_POSITION_CRITERIA_ERROR,
  GET_POSITION_CRITERIAS_REQUEST,
  GET_POSITION_CRITERIAS_SUCCESS,
  GET_POSITION_CRITERIAS_ERROR,
  CREATE_POSITION_CRITERIA_REQUEST,
  CREATE_POSITION_CRITERIA_SUCCESS,
  CREATE_POSITION_CRITERIA_ERROR,
  UPDATE_POSITION_CRITERIA_REQUEST,
  UPDATE_POSITION_CRITERIA_SUCCESS,
  UPDATE_POSITION_CRITERIA_ERROR,
  DELETE_POSITION_CRITERIA_REQUEST,
  DELETE_POSITION_CRITERIA_SUCCESS,
  DELETE_POSITION_CRITERIA_ERROR,
} from '../actions/position-criterias.action';
import { PositionCriteraModel } from '../../shared/models/positions.model';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {
    positionCriteria: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    positionCriterias: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    createPositionCriteria: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    updatePositionCriteria: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
    deletePositionCriteria: {
      loading: false,
      loaded: false,
      error: null,
      data: [],
    },
  },
};

export const positionCriteriasReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_POSITION_CRITERIA_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          positionCriteria: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case GET_POSITION_CRITERIA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          positionCriteria: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case GET_POSITION_CRITERIA_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          positionCriteria: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case GET_POSITION_CRITERIAS_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          positionCriterias: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case GET_POSITION_CRITERIAS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          positionCriterias: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
        },
      };

    case GET_POSITION_CRITERIAS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          positionCriterias: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case CREATE_POSITION_CRITERIA_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          createPositionCriteria: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case CREATE_POSITION_CRITERIA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          createPositionCriteria: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
          positionCriterias: {
            ...state.data.positionCriterias,
            data: {
              ...state.data.positionCriterias.data,
              content: [...state.data.positionCriterias.data.content, action.payload],
            },
          },
        },
      };

    case CREATE_POSITION_CRITERIA_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          createPositionCriteria: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case UPDATE_POSITION_CRITERIA_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          updatePositionCriteria: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case UPDATE_POSITION_CRITERIA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          updatePositionCriteria: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
          positionCriterias: {
            ...state.data.positionCriterias,
            data: {
              ...state.data.positionCriterias.data,
              content: state.data.positionCriterias.data.content.map((el: PositionCriteraModel) => {
                if (el.id === action.payload.id) {
                  return action.payload;
                }
                return el;
              }),
            },
          },
        },
      };

    case UPDATE_POSITION_CRITERIA_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          updatePositionCriteria: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    case DELETE_POSITION_CRITERIA_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          deletePositionCriteria: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };

    case DELETE_POSITION_CRITERIA_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          deletePositionCriteria: {
            loading: false,
            loaded: true,
            data: action.payload,
          },
          positionCriterias: {
            ...state.data.positionCriterias,
            data: {
              ...state.data.positionCriterias.data,
              content: state.data.positionCriterias.data.content.filter(
                (el: PositionCriteraModel) => el.id !== action.payload.criteriaId,
              ),
            },
          },
        },
      };

    case DELETE_POSITION_CRITERIA_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          deletePositionCriteria: {
            loading: false,
            loaded: false,
            error: action.error,
          },
        },
      };

    default:
      return state;
  }
};

export default positionCriteriasReducer;
