import { post } from '../../shared/services/http.service';
import { FaqDatasetUploadModel } from '../actions/faq-dataset.action';

export const uploadFaqDataset = async (
  applicationId: number,
  platform: string,
  payload: FaqDatasetUploadModel,
) => {
  return post(`/applications/${applicationId}/platform/${platform}/conversation-flow/import/csv`, {
    data: payload,
  });
};

export default uploadFaqDataset;
