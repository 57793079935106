import { get, post } from '../../shared/services/http.service';
import { QueryParams } from '../../shared/models/query-params.model';

export const getUnrecognizedSentences = async (applicationId: number, queryParams: QueryParams) => {
  return get(`/applications/${applicationId}/analytics/unrecognized`, {
    params: queryParams,
  });
};

export const exportUnrecognizedSentences = async (applicationId: number) => {
  return get(`/applications/${applicationId}/analytics/unrecognized/export/csv`);
};

export const resetUnrecognizedSentences = async (applicationId: number) => {
  return post(`/applications/${applicationId}/analytics/unrecognized/reset`);
};
