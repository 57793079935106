import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import Pagination from 'react-js-pagination';

import { TopicStatisticModel } from '../../../shared/models/topic-statistics.model';

import styles from '../TopicStatistics.module.scss';

interface Props {
  topicStatistics: TopicStatisticModel[];
  activePage: number;
  size: number;
  totalElements: number;
  pageChange: Function;
}

const TopicStatisticsTable: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const {
    topicStatistics,
    activePage,
    size,
    totalElements,
    pageChange,
  } = props;

  return (
    <Fragment>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th className={styles.topicName}>{t('topicStatistics.topic')}</th>
            <th className={styles.clickCount}>
              {t('topicStatistics.clickCount')}
            </th>
          </tr>
        </thead>
        <tbody>
          {topicStatistics.map((statistic: TopicStatisticModel) => {
            return (
              <tr key={statistic.topicName}>
                <td className={styles.topicName}>{statistic.topicName}</td>
                <td className={styles.clickCount}>{statistic.clickCount}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Pagination
        activePage={activePage}
        itemsCountPerPage={size}
        totalItemsCount={totalElements}
        pageRangeDisplayed={5}
        onChange={(index: number) => pageChange(index)}
      />
    </Fragment>
  );
};

export default TopicStatisticsTable;
