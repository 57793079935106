import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { FormikValues, useFormikContext } from 'formik';

import SimpleMessageForm from './SimpleMessageForm';
import LocationCityButtonTextsForm from './LocationCityButtonTextsForm';
import { OptionModel, ReactSelect, Switch, TextField } from '../../../../shared/components';
import { SelectModel } from '../../../../shared/models';

import {
  messageType as messageTypes,
  LOCATIONS_PER_PAGE_MIN,
  LOCATIONS_PER_PAGE_MAX
} from 'src/shared/constants/questionnaire.constans';
const LocationMessageForm = () => {
  const { t } = useTranslation();
  const { values, setFieldValue }: FormikValues = useFormikContext();

  const { locationMessage } = values;

  const getSelectOptions = () => {
    const options: SelectModel[] = [];

    for (let i = LOCATIONS_PER_PAGE_MIN; i <= LOCATIONS_PER_PAGE_MAX; i += 1) {
      options.push({ label: i.toString(), value: i });
    }

    return options;
  };

  const selectOptions = useMemo(() => getSelectOptions(), []);

  return (
    <Fragment>
      <Row form>
        <Col>
          <h6>{t('common.messages')}</h6>
          <SimpleMessageForm
            name="locationMessage.chooseCountryPart"
            messageType={messageTypes.LOCATION_PART_OF_COUNTRY}
          />
          <SimpleMessageForm
            name="locationMessage.chooseCounty"
            messageType={messageTypes.LOCATION_COUNTY}
          />
          <SimpleMessageForm
            name="locationMessage.chooseCity"
            messageType={messageTypes.LOCATION_CITY}
          />
        </Col>

        <Col>
          <LocationCityButtonTextsForm />
        </Col>
      </Row>


      <FormGroup>
        <Switch
          name="locationMessage.showAll"
          variant="pill"
          color="info"
          label="questionnaire.showAllLocations"
          labelPosition="after"
        />
      </FormGroup>

      {!locationMessage.showAll && (
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="nextPageButton">{t('questionnaire.nextPageButtonText')}</Label>
              <TextField name="locationMessage.nextPageButton" />
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <Label for="showSize">{t('questionnaire.numberOfLocationsPerPage')}</Label>
              <ReactSelect
                name="locationMessage.showSize"
                options={selectOptions}
                value={{ label: locationMessage.showSize.toString(), value: locationMessage.showSize }}
                onChange={(option: OptionModel) =>
                  setFieldValue('locationMessage.showSize', (option as SelectModel).value)
                }
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default LocationMessageForm;
