import moment from 'moment';

export const getUsersTimezoneOffsetToUtc = () => {
  const timezoneOffset = new Date().getTimezoneOffset() / 60;
  return timezoneOffset === 0 ? 0 : timezoneOffset * -1;
};

export const getDisabledHours = () => {
  const disabledHours = [];
  for (let i = 0; i < moment().hour(); i++) {
    disabledHours.push(i);
  }
  return disabledHours;
};

export const getDisabledMinutes = (h: number) => {
  if (h !== moment().hour()) return [];

  const disabledMinutes = [];
  for (
    let i = 0;
    i <
    moment()
      .add(1, 'minute')
      .minutes();
    i++
  ) {
    disabledMinutes.push(i);
  }
  return disabledMinutes;
};
