import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, Alert } from 'reactstrap';
import moment from 'moment';

import { fileNames } from '../../shared/settings';

import { AuditLogQueryParams } from '../../shared/models/query-params.model';
import { AuditLogModel, MetaDataModel } from '../../shared/models/audit-log.model';
import { UserModel } from '../../shared/models/user.model';

import { ApplicationModel, getApplication } from '../../redux/actions/applications.action';
import { getTenantUser, getTenantUsers } from '../../redux/actions/user.action';
import { getAuditLog, exportAuditLog } from '../../redux/actions/audit-log.action';

import { formatDatesToIsoString } from '../../shared/utils/query-parse.util';
import saveFile from '../../shared/utils/file-saver.util';

import Spinner from '../../shared/components/Spinner';
import AuditLogTable from './components/AuditLogTable';
import AuditLogFilters from './components/AuditLogFilters';
import AuditLogExport from './components/AuditLogExport';
import AuditLogDetailsModal from './components/AuditLogDetailsModal';

import events from './data/events.json';

interface Props extends RouteComponentProps<{ id: string }>, WithTranslation {
  getApplication: Function;
  application: ApplicationModel;
  getAuditLog: Function;
  auditLogs: AuditLogModel[];
  auditLogsLoading: boolean;
  auditLogsLoaded: boolean;
  numberOfLogs: number;
  exportAuditLog: Function;
  exportedLogs: any;
  getTenantUsers: Function;
  tenantUsers: UserModel[];
  getTenantUser: Function;
  revealedUser: UserModel;
}

interface State {
  queryParams: AuditLogQueryParams;
  activePage: number;
  revealedUsers: UserModel[];
  isModalOpen: boolean;
  metadata: MetaDataModel | undefined;
}

const INIT_FROM_VALUE = moment().utc().subtract(1, 'month');
const INIT_TO_VALUE = moment().utc();

class AuditLog extends Component<Props, State> {
  state: State = {
    queryParams: {
      from: INIT_FROM_VALUE,
      to: INIT_TO_VALUE,
      type: undefined,
      userId: undefined,
      page: 0,
      size: 20,
    },
    activePage: 1,
    revealedUsers: [],
    isModalOpen: false,
    metadata: {},
  };

  componentDidMount = () => {
    this.getAuditLog();
    this.getTenantUsers();
  };

  getAuditLog = () => {
    const { queryParams } = this.state;
    const formattedQueryParams = formatDatesToIsoString(queryParams);

    this.props.getAuditLog(formattedQueryParams);
  };

  exportAuditLog = async () => {
    const { queryParams } = this.state;
    const formattedQueryParams = formatDatesToIsoString(queryParams);

    delete formattedQueryParams.page;
    delete formattedQueryParams.size;

    await this.props.exportAuditLog(formattedQueryParams);
    const { exportedLogs } = this.props;

    if (exportedLogs) {
      saveFile(exportedLogs, fileNames.AUDIT_LOG_EXPORT, 'csv');
    }
  };

  getTenantUser = async (userId: number) => {
    await this.props.getTenantUser(userId);
  };

  getTenantUsers = () => {
    this.props.getTenantUsers();
  };

  handleDateChange = async (startDate: moment.Moment, endDate: moment.Moment) => {
    if (startDate && endDate) {
      this.setState(
        prevState => ({
          queryParams: {
            ...prevState.queryParams,
            from: startDate,
            to: endDate,
            page: 0,
          },
          activePage: 1,
        }),
        () => {
          // this.redirectWithQueryParams(params);
          this.getAuditLog();
        },
      );
    }

    return null;
  };

  handleEventTypeChange = (selectedOption: any) => {
    const type = selectedOption ? selectedOption.value : null;
    if (type === null) {
      this.setState(
        prevState => ({
          queryParams: {
            ...prevState.queryParams,
            page: 0,
          },
          activePage: 1,
        }),
        () => {
          // this.redirectWithQueryParams(params);
          this.getAuditLog();
        },
      );
    }
    this.setState(
      prevState => ({
        queryParams: {
          ...prevState.queryParams,
          type,
          page: 0,
        },
        activePage: 1,
      }),
      () => {
        // this.redirectWithQueryParams(params);
        this.getAuditLog();
      },
    );
  };

  handleTenantUserChange = (selectedUser: any) => {
    const userId = selectedUser ? selectedUser.value : null;

    this.setState(
      prevState => ({
        queryParams: {
          ...prevState.queryParams,
          userId,
          page: 0,
        },
        activePage: 1,
      }),
      () => {
        // this.redirectWithQueryParams(params);
        this.getAuditLog();
      },
    );
  };

  revealUser = async (userId: number) => {
    await this.getTenantUser(userId);

    const { revealedUser } = this.props;
    const { revealedUsers } = this.state;

    if (revealedUser && !revealedUsers.includes(revealedUser)) {
      this.setState(prevState => ({
        revealedUsers: [...prevState.revealedUsers, revealedUser],
      }));
    }
  };

  pageChange = (index: number) => {
    // Backend oldalon a page-elés 0-tól indul
    // Frontend oldaon a 'react-js-pagination' miatt 1-től kell indulnia
    this.setState(
      prevState => ({
        queryParams: {
          ...prevState.queryParams,
          page: index - 1,
        },
        activePage: index,
      }),
      () => {
        // this.redirectWithQueryParams(params);
        this.getAuditLog();
        window.scrollTo(0, 0);
      },
    );
  };

  setCurrentPage = (page: number) => {
    this.setState({
      activePage: Number(page) + 1,
    });
  };

  toggleModal = (metadata?: MetaDataModel) => {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      metadata,
    }));
  };

  // redirectWithQueryParams = (params: AuditLogQueryParams) => {
  //   const stringifiedParams = queryString.stringify(params, { sort: false });
  //   this.props.history.push(`?${stringifiedParams}`);
  // };

  render() {
    const {
      auditLogs,
      numberOfLogs,
      auditLogsLoading,
      auditLogsLoaded,
      tenantUsers,
      t,
    } = this.props;
    const { queryParams, activePage, revealedUsers, isModalOpen, metadata } = this.state;

    return (
      <Fragment>
        <h5 className="mb-4">{t('common.auditLog')}</h5>

        <Row className="animated fadeIn">
          <Col>
            <Card>
              <CardHeader>
                <Row>
                  <Col>
                    <AuditLogFilters
                      events={events}
                      startDate={moment(queryParams.from)}
                      endDate={moment(queryParams.to)}
                      tenantUsers={tenantUsers}
                      selectedEvent={queryParams.type ? queryParams.type : undefined}
                      selectedUser={queryParams.userId ? queryParams.userId : undefined}
                      handleDateChange={this.handleDateChange}
                      handleEventTypeChange={this.handleEventTypeChange}
                      handleTenantUserChange={this.handleTenantUserChange}
                    />
                    <AuditLogExport
                      exportAuditLog={this.exportAuditLog}
                      disabled={!auditLogs.length}
                    />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {!auditLogsLoading && auditLogs.length > 0 && (
                  <AuditLogTable
                    events={events}
                    activePage={activePage}
                    size={queryParams.size}
                    totalElements={numberOfLogs}
                    pageChange={this.pageChange}
                    auditLogs={auditLogs}
                    revealUser={this.revealUser}
                    revealedUsers={revealedUsers}
                    toggleModal={this.toggleModal}
                  />
                )}

                {auditLogsLoading && (
                  <div className="d-flex justify-content-center">
                    <Spinner loading={auditLogsLoading} size="2x" />
                  </div>
                )}

                {auditLogsLoaded && !auditLogs.length && (
                  <Alert className="mb-0 text-center" color="info">
                    {t('auditLog.noLogs')}
                  </Alert>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <AuditLogDetailsModal isOpen={isModalOpen} toggle={this.toggleModal} metadata={metadata} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  auditLogs: state.auditLog.data.content,
  auditLogsLoading: state.auditLog.loading,
  auditLogsLoaded: state.auditLog.loaded,
  numberOfLogs: state.auditLog.data.totalElements,
  exportedLogs: state.auditLogExport.data,
  revealedUser: state.tenantUser.data,
  tenantUsers: state.tenantUsers.data,
  tenantUsersLoading: state.tenantUsers.loading,
  tenantUsersLoaded: state.tenantUsers.loaded,
});

const mapDispatchToProps = {
  getApplication,
  getAuditLog,
  exportAuditLog,
  getTenantUser,
  getTenantUsers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(AuditLog)));
