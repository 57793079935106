import { Dispatch } from 'redux';
import {
  getListsService,
  createListService,
  updateListService,
  getListService,
  deleteListService,
  getListElementsService,
  createListElementService,
  deleteListElementService,
  updateListElementService,
  updateListElementsOrderService,
  getListElementService,
} from '../services/lists.service';
import { QueryParams, ElementsQueryParams } from '../../shared/models/query-params.model';
import { ListModel, ListUpdateModel } from '../reducers/lists.reducer';

export const GET_LISTS_REQUEST = 'lists/GET_LISTS_REQUEST';
export const GET_LISTS_SUCCESS = 'lists/GET_LISTS_SUCCESS';
export const GET_LISTS_ERROR = 'lists/GET_LISTS_ERROR';
export const CREATE_LIST_REQUEST = 'lists/CREATE_LIST_REQUEST';
export const CREATE_LIST_SUCCESS = 'lists/CREATE_LIST_SUCCESS';
export const CREATE_LIST_ERROR = 'lists/CREATE_LIST_ERROR';
export const GET_LIST_REQUEST = 'lists/GET_LIST_REQUEST';
export const GET_LIST_SUCCESS = 'lists/GET_LIST_SUCCESS';
export const GET_LIST_ERROR = 'lists/GET_LIST_ERROR';
export const DELETE_LIST_REQUEST = 'lists/DELETE_LIST_REQUEST';
export const DELETE_LIST_SUCCESS = 'lists/DELETE_LIST_SUCCESS';
export const DELETE_LIST_ERROR = 'lists/DELETE_LIST_ERROR';
export const UPDATE_LIST_REQUEST = 'lists/UPDATE_LIST_REQUEST';
export const UPDATE_LIST_SUCCESS = 'lists/UPDATE_LIST_SUCCESS';
export const UPDATE_LIST_ERROR = 'lists/UPDATE_LIST_ERROR';
export const LIST_CLEAR_ERROR = 'lists/LIST_CLEAR_ERROR';

export const GET_LIST_ELEMENTS_REQUEST = 'lists/elements/GET_LIST_ELEMENTS_REQUEST';
export const GET_LIST_ELEMENTS_SUCCESS = 'lists/elements/GET_LIST_ELEMENTS_SUCCESS';
export const GET_LIST_ELEMENTS_ERROR = 'lists/elements/GET_LIST_ELEMENTS_ERROR';
export const CREATE_LIST_ELEMENT_REQUEST = 'lists/elements/CREATE_LIST_ELEMENT_REQUEST';
export const CREATE_LIST_ELEMENT_SUCCESS = 'lists/elements/CREATE_LIST_ELEMENT_SUCCESS';
export const CREATE_LIST_ELEMENT_ERROR = 'lists/elements/CREATE_LIST_ELEMENT_ERROR';
export const GET_LIST_ELEMENT_REQUEST = 'lists/elements/GET_LIST_ELEMENT_REQUEST';
export const GET_LIST_ELEMENT_SUCCESS = 'lists/elements/GET_LIST_ELEMENT_SUCCESS';
export const GET_LIST_ELEMENT_ERROR = 'lists/elements/GET_LIST_ELEMENT_ERROR';
export const DELETE_LIST_ELEMENT_REQUEST = 'lists/elements/DELETE_LIST_ELEMENT_REQUEST';
export const DELETE_LIST_ELEMENT_SUCCESS = 'lists/elements/DELETE_LIST_ELEMENT_SUCCESS';
export const DELETE_LIST_ELEMENT_ERROR = 'lists/elements/DELETE_LIST_ELEMENT_ERROR';
export const UPDATE_LIST_ELEMENT_REQUEST = 'lists/elements/UPDATE_LIST_ELEMENT_REQUEST';
export const UPDATE_LIST_ELEMENT_SUCCESS = 'lists/elements/UPDATE_LIST_ELEMENT_SUCCESS';
export const UPDATE_LIST_ELEMENT_ERROR = 'lists/elements/UPDATE_LIST_ELEMENT_ERROR';
export const UPDATE_LIST_ELEMENTS_ORDER_REQUEST =
  'lists/elements/UPDATE_LIST_ELEMENTS_ORDER_REQUEST';
export const UPDATE_LIST_ELEMENTS_ORDER_SUCCESS =
  'lists/elements/UPDATE_LIST_ELEMENTS_ORDER_SUCCESS';
export const UPDATE_LIST_ELEMENTS_ORDER_ERROR = 'lists/elements/UPDATE_LIST_ELEMENTS_ORDER_ERROR';
export const LIST_ELEMENT_CLEAR_ERROR = 'lists/elements/LIST_ELEMENT_CLEAR_ERROR';

export const getLists = (appId: string, queryParams?: QueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_LISTS_REQUEST,
    });

    const lists = await getListsService(appId, queryParams);

    try {
      dispatch({
        type: GET_LISTS_SUCCESS,
        payload: lists,
      });
    } catch (error) {
      dispatch({
        type: GET_LISTS_ERROR,
        error,
      });
    }
  };
};

export const createList = (appId: string, list: ListModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_LIST_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_LIST_SUCCESS,
        payload: await createListService(appId, list),
      });
    } catch (error) {
      dispatch({
        type: CREATE_LIST_ERROR,
        error,
      });
    }
  };
};

export const getList = (appId: string, listId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_LIST_REQUEST,
    });

    const lists = await getListService(appId, listId);

    try {
      dispatch({
        type: GET_LIST_SUCCESS,
        payload: lists,
      });
    } catch (error) {
      dispatch({
        type: GET_LIST_ERROR,
        error,
      });
    }
  };
};

export const deleteList = (appId: string, listId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_LIST_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_LIST_SUCCESS,
        payload: await deleteListService(appId, listId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_LIST_ERROR,
        error,
      });
    }
  };
};

export const updateList = (appId: string, listId: number, list: ListUpdateModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_LIST_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_LIST_SUCCESS,
        payload: await updateListService(appId, listId, list),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_LIST_ERROR,
        error,
      });
    }
  };
};

export const getListElements = (
  appId: string,
  listId: number,
  queryParams: ElementsQueryParams,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_LIST_ELEMENTS_REQUEST,
    });

    const lists = await getListElementsService(appId, listId, queryParams);

    try {
      dispatch({
        type: GET_LIST_ELEMENTS_SUCCESS,
        payload: lists,
      });
    } catch (error) {
      dispatch({
        type: GET_LIST_ELEMENTS_ERROR,
        error,
      });
    }
  };
};

export const createListElement = (appId: string, listId: number, element: ListModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_LIST_ELEMENT_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_LIST_ELEMENT_SUCCESS,
        payload: await createListElementService(appId, listId, element),
      });
    } catch (error) {
      dispatch({
        type: CREATE_LIST_ELEMENT_ERROR,
        error,
      });
    }
  };
};

export const getListElement = (appId: string, listId: number, listElementId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_LIST_ELEMENT_REQUEST,
    });

    const lists = await getListElementService(appId, listId, listElementId);

    try {
      dispatch({
        type: GET_LIST_ELEMENT_SUCCESS,
        payload: lists,
      });
    } catch (error) {
      dispatch({
        type: GET_LIST_ELEMENT_ERROR,
        error,
      });
    }
  };
};

export const deleteListElement = (appId: string, listId: number, listElementId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_LIST_ELEMENT_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_LIST_ELEMENT_SUCCESS,
        payload: await deleteListElementService(appId, listId, listElementId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_LIST_ELEMENT_ERROR,
        error,
      });
    }
  };
};

export const updateListElement = (
  appId: string,
  listId: number,
  listElementId: number,
  list: ListUpdateModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_LIST_ELEMENT_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_LIST_ELEMENT_SUCCESS,
        payload: await updateListElementService(appId, listId, listElementId, list),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_LIST_ELEMENT_ERROR,
        error,
      });
    }
  };
};

export const updateListElementsOrder = (
  appId: string,
  listId: number,
  listElementsOrder: Array<Number>,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_LIST_ELEMENTS_ORDER_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_LIST_ELEMENTS_ORDER_SUCCESS,
        payload: await updateListElementsOrderService(appId, listId, listElementsOrder),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_LIST_ELEMENTS_ORDER_ERROR,
        error,
      });
    }
  };
};

export const clearListErrors = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LIST_CLEAR_ERROR,
    });
  };
};

export const clearListElementErrors = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LIST_ELEMENT_CLEAR_ERROR,
    });
  };
};
