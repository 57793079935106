export interface SelectModel {
  label: string;
  value: string | number | any;
  id?: string;
  additional?: string;
  group?: string;
  amount?: number;
  isFixed?: boolean;
  parentLabel?: string;
  unit?: 'hour' | 'day' | 'week' | 'month';
}

export interface ContentSelectModel extends Omit<SelectModel, 'label'> {
  label: JSX.Element;
}

export interface SelectGroupModel {
  label: string;
  parentLabel?: string;
  options: SelectModel[];
}

export enum Extensions {
  CSV = 'csv',
  JSON = 'json',
  XLSX = 'xlsx',
}
