import React, { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import uniqueId from 'lodash/uniqueId';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { HTMLProps } from 'react-redux/node_modules/@types/react';

import EditableLabel from './form-inputs/EditableLabel';
import ConfirmModal from './modals/ConfirmModal';

import styles from './TagPill.module.scss';

interface Props extends HTMLProps<HTMLDivElement> {
  id?: string;
  item: any;
  isEditable?: boolean;
  isEditableInModal?: boolean;
  isDeleteable?: boolean;
  idIsVisible?: boolean;
  disableSubmit?: boolean;
  isDeleteConfirmRequired?: boolean;
  handleDelete?: Function;
  handleEdit?: Function;
  modalEditorContent?: React.ReactElement;
  onEditorModalSubmit?: Function;
}

const TagPill: React.FC<Props> = ({
  id,
  item,
  isEditable,
  isEditableInModal,
  isDeleteable,
  idIsVisible,
  isDeleteConfirmRequired,
  handleDelete,
  handleEdit,
  modalEditorContent,
  onEditorModalSubmit,
  disableSubmit,
  ...props
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputVale] = useState<string>();
  const [inputExternalId, setInputExternalId] = useState<string>();
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [isEditorModalOpen, setIsEditorModalOpen] = useState<boolean>(false);

  const tagText = item?.name || item?.text || item?.value || (typeof item === 'string' ? item : '') || ' - ';
  const tagId = item.externalId || '';
  const hasOperation = isEditable || isDeleteable || isEditableInModal;
  const inputId = uniqueId('tagPillInput-');

  useEffect(() => {
    setInputVale(tagText);
  }, [tagText]);

  useEffect(() => {
    setInputExternalId(tagId);
  }, [tagId]);

  const handleOnChange = (value: string) => {
    setInputVale(value);
  };

  const handleEditorModalOnSubmit = async () => {
    if (onEditorModalSubmit) {
      const isInvalid = await onEditorModalSubmit();
      if (isInvalid === true) {
        return;
      }
    }
    setIsEditorModalOpen(false);
  };

  const handleEditorModalOnCancel = () => {
    setIsEditorModalOpen(false);
  };

  return (
    <Fragment>
      <div id={id} className={styles.tagPillContainer} {...props}>
        <div className={styles.tagPill}>
          {(isEditable || isEditableInModal) && isEditing ? (
            <EditableLabel
              type="text"
              isEditing={isEditing}
              setEditing={setEditing}
              setInputVale={setInputVale}
              text={tagText}
              placeholder={tagText}
              handleEdit={() => {
                if (handleEdit) handleEdit(item, inputValue);
              }}
            >
              <Input
                type="text"
                id={inputId}
                name={inputId}
                value={inputValue}
                className={styles.tagPillInput}
                autoFocus
                onChange={(e: any) => handleOnChange(e.target.value)}
              />
            </EditableLabel>
          ) : (
            <span className={styles.span}>
              {idIsVisible ? `${inputExternalId} - ` : ''} {tagText}
            </span>
          )}
        </div>

        {hasOperation && (
          <div className={styles.tagPillOperations}>
            {!isEditing && (
              <Fragment>
                {isEditable && (
                  <button
                    type="button"
                    className={styles.tagPillButton}
                    onClick={() => setEditing(true)}
                  >
                    <i className="far fa-edit" />
                  </button>
                )}

                {isDeleteable && (
                  <button
                    type="button"
                    className={styles.tagPillButton}
                    onClick={() => {
                      if (isDeleteConfirmRequired) return setConfirmModalOpen(true);
                      return handleDelete && handleDelete(item);
                    }}
                  >
                    <i className="far fa-trash-alt" />
                  </button>
                )}
                {isEditableInModal && (
                  <button
                    type="button"
                    className={styles.tagPillButton}
                    onClick={() => {
                      setIsEditorModalOpen(true);
                    }}
                  >
                    <i className="fas fa-columns"></i>
                  </button>
                )}
              </Fragment>
            )}
          </div>
        )}
      </div>

      <ConfirmModal
        title={t('common.delete')}
        text={t('common.deleteConfirmMessage')}
        item={tagText}
        cancel={() => setConfirmModalOpen(false)}
        confirm={() => handleDelete && handleDelete(item)}
        isOpen={isConfirmModalOpen}
        isLoading={false}
      />

      {isEditableInModal && (
        <Modal isOpen={isEditorModalOpen} className="modal-lg">
          <ModalHeader>Editor</ModalHeader>
          <ModalBody>{modalEditorContent}</ModalBody>
          <ModalFooter>
            <Button onClick={handleEditorModalOnSubmit} type="submit" color="success">
              {t('common.edit')}
            </Button>
            <Button onClick={handleEditorModalOnCancel} color="secondary">
              {t('common.cancel')}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Fragment>
  );
};

TagPill.defaultProps = {
  isEditable: true,
  isDeleteable: true,
  isDeleteConfirmRequired: true,
  idIsVisible: false,
};

export default TagPill;
