import React, { Fragment } from 'react';
import i18n from 'src/i18n';
import {  UserModel } from 'src/shared/models/user.model';

interface Props {
  user: UserModel;
}

const User: React.FC<Props> = ({ user }) => {
  return (
    <Fragment>
      <p className="font-weight-bold">{i18n.t('users.detailsOfUser')}</p>

      <table className="table table-sm">
        <tbody>
          <tr>
            <td>
              {i18n.t('common.id')} / {i18n.t('common.name')}
            </td>
            <td>
              {user.id} / {user.firstName} {user.lastName}
            </td>
          </tr>
          <tr>
            <td>{i18n.t('common.email')}</td>
            <td>{user.email}</td>
          </tr>
          <tr>
            <td>{i18n.t('users.userGroup')}</td>
            <td>{(typeof user.userGroup === 'object' && user.userGroup.name ? user.userGroup.name : user.userGroupName )}</td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

export default User;
