import React, { useEffect, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { ResponsiveBar } from '@nivo/bar';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { OutgoingMessagesReportModel, OutgoingMessagesReportFilters } from 'src/shared/models';
import { PageHeader } from 'src/shared/components';
import OutgoingMessagesReportFilter from './OutgoingMessagesReportFilter';
import getOutgoingMessagesReport from 'src/redux/services/report.service';

import './OutgoingMessagesReport.scss';

interface IOutgoingMessagesReportProps {
  application: ApplicationModel;
  getApplication: Function;
}

const OutgoingMessagesReport: FC<IOutgoingMessagesReportProps> = ({
  application,
  getApplication,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [filter, setFilter] = useState<OutgoingMessagesReportFilters>({
    from: '',
    to: '',
  });
  const [data, setData] = useState<OutgoingMessagesReportModel | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    fetchReport();
    /* eslint-disable-next-line */
  }, [filter]);

  const fetchReport = async () => {
    const chartData: OutgoingMessagesReportModel = await getOutgoingMessagesReport(appId, filter);

    setData(chartData);

    setIsLoaded(true);
  };

  const handleDateFilterChange = (filterValues: OutgoingMessagesReportFilters): void => {
    setFilter(filterValues);
  };

  const generateData = () => {
    if (!isLoaded || !data) {
      return [];
    }

    return [
      {
        title: t('outgoingMNessages.messagesBoradcast'),
        messagesBoradcastTotal: data.broadcastMessagesAfterWindowClosedCount,
      },
      {
        title: t('outgoingMNessages.messagesBoradcastTotal'),
        messagesBoradcastTotal: data.broadcastMessagesTotalCount,
      },
      {
        title: t('outgoingMNessages.messagesSurvey'),
        messagesSurveyTotal: data.surveyMessagesAfterWindowClosedCount,
      },
      {
        title: t('outgoingMNessages.messagesSurveyTotal'),
        messagesSurveyTotal: data.surveyMessagesTotalCount,
      },
      {
        title: t('outgoingMNessages.messagesHelpdesk'),
        messagesHelpdeskTotal: data.helpdeskMessagesAfterWindowClosedCount,
      },
      {
        title: t('outgoingMNessages.messagesHelpdeskTotal'),
        messagesHelpdeskTotal: data.helpdeskMessagesTotalCount,
      },
      {
        title: t('outgoingMNessages.messagesAdmin'),
        messagesAdminTotal: data.adminMessageWindowClosedCount,
      },
      {
        title: t('outgoingMNessages.messagesAdminTotal'),
        messagesAdminTotal: data.adminMessageTotalCount,
      },
      {
        title: t('outgoingMNessages.messages'),
        messagesTotal: data.totalAfterWindowClosedCount,
      },
      {
        title: t('outgoingMNessages.messagesTotal'),
        messagesTotal: data.totalCount,
      },
    ];
  };

  return (
    <div className="animated fadeIn">
      <PageHeader title={t('common.outgoingMessageReport')} />
      <Card className="animated fadeIn mt-0">
        <CardHeader>
          <OutgoingMessagesReportFilter filterChanged={handleDateFilterChange} filter={filter} />
        </CardHeader>
        <CardBody>
          <div className="chart-container">
            {isLoaded && (
              <ResponsiveBar
                data={generateData()}
                keys={[
                  'value',
                  'messagesBoradcastTotal',
                  'messagesSurveyTotal',
                  'messagesHelpdeskTotal',
                  'messagesAdminTotal',
                  'messagesTotal',
                ]}
                indexBy="title"
                margin={{ top: 40, right: 60, bottom: 110, left: 60 }}
                padding={0.3}
                colors={{ scheme: 'nivo' }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: `${t('outgoingMNessages.number')}`,
                  legendPosition: 'middle',
                  legendOffset: -50,
                }}
                axisBottom={{ tickRotation: 20 }}
                labelFormat={v => `${v}`}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                tooltip={({ indexValue }) => <span>{indexValue}</span>}
                animate
                motionStiffness={90}
                motionDamping={15}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
  };
};

const mapDispatchToProps = {
  getApplication,
};

export default connect(mapStateToProps, mapDispatchToProps)(OutgoingMessagesReport);
