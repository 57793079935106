// src/shared/settings/msalConfig.tsx

import { Configuration } from "@azure/msal-browser";

import { settings } from '../settings'

const { REACT_APP_MSAL_CLIENT_ID, REACT_APP_MSAL_AUTHORITY, REACT_APP_MSAL_REDIRECT_URL } = settings;

export const msalConfig: Configuration = {
  auth: {
    clientId: REACT_APP_MSAL_CLIENT_ID || '',
    authority: REACT_APP_MSAL_AUTHORITY,
    redirectUri: REACT_APP_MSAL_REDIRECT_URL, 
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};