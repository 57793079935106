import React, { Fragment, FC } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { Button } from 'reactstrap';
import { SurveyStatuses } from 'src/shared/models';

interface StatusButtonProps {
  data: StatusModel;
}

interface StatusModel {
  startsAt: Moment;
  endsAt: Moment;
  isDraft: boolean;
}

const StatusButton: FC<StatusButtonProps> = ({ data }) => {
  const { t } = useTranslation();

  const getStatus = (data: StatusModel): SurveyStatuses => {
    const startTime = moment(data.startsAt).unix();
    const endTime = moment(data.endsAt).unix();
    const now = moment().unix();

    if (data.isDraft) {
      return SurveyStatuses.none;
    }
    if (now >= startTime && (data.endsAt === null || now <= endTime)) {
      return SurveyStatuses.running;
    }
    if (now < startTime) {
      return SurveyStatuses.scheduled;
    }
    if (data.endsAt === null || now > endTime) {
      return SurveyStatuses.expired;
    }

    return SurveyStatuses.none;
  };

  const getStatusColor = (data: StatusModel) => {
    const status = getStatus(data);

    switch (status) {
      case SurveyStatuses.running:
        return 'success';
      case SurveyStatuses.scheduled:
        return 'primary';
      case SurveyStatuses.expired:
        return 'warning text-white';
      case SurveyStatuses.none:
        return 'secondary';
      default:
        return '';
    }
  };

  return (
    <Fragment>
      <Button color={getStatusColor(data)}>
        {t(`events.statuses.${SurveyStatuses[getStatus(data)]}`)}
      </Button>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StatusButton);
