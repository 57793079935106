import { StateModel } from 'src/shared/models';
import {
  GET_CRITERIA_IMPORTS,
  GET_CRITERIA_IMPORTS_ERROR,
  GET_CRITERIA_IMPORTS_SUCCESS,
} from '../actions/criteria-import.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const criteriaImportReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case GET_CRITERIA_IMPORTS:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_CRITERIA_IMPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload.content,
      };
    case GET_CRITERIA_IMPORTS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };

    default:
      return state;
  }
};

export default criteriaImportReducer;
