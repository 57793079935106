import React from 'react';
import { Button } from 'reactstrap';

import { RegexReplacerModel } from 'src/shared/models/questionnaire.model';
import CheckPermission from 'src/shared/components/CheckPermission';
import EditableTextInput from 'src/shared/components/form-inputs/EditableTextInput';

interface Props {
  attributeReplacers: RegexReplacerModel[];
  selectAttributeReplacer: (attributeReplacers: RegexReplacerModel) => void;
  toggleConfirmModal: (isOpen: boolean) => void;
  updateAttributeReplacer: Function;
}

const RegexReplacePatternsTableBody: React.FC<Props> = ({
  attributeReplacers,
  selectAttributeReplacer,
  toggleConfirmModal,
  updateAttributeReplacer,
}) => {
  return (
    <tbody>
      {attributeReplacers.map(attributeReplacer => (
        <tr key={attributeReplacer.id}>
          <td className="name-column">
            <EditableTextInput
              id={attributeReplacer.id!}
              data={attributeReplacer.title}
              updateData={(appId: string, id: number, value: string) =>
                updateAttributeReplacer(id, {
                  title: value,
                  pattern: attributeReplacer.pattern,
                  replaceTo: attributeReplacer.replaceTo,
                })
              }
              permission="regular-expression-replacer_update"
            />
          </td>
          <td className="name-column">
            <EditableTextInput
              id={attributeReplacer.id!}
              data={attributeReplacer.pattern}
              updateData={(appId: string, id: number, value: string) =>
                updateAttributeReplacer(id, {
                  title: attributeReplacer.title,
                  pattern: value,
                  replaceTo: attributeReplacer.replaceTo,
                })
              }
              permission="regular-expression-replacer_update"
            />
          </td>
          <td className="name-column">
            <EditableTextInput
              id={attributeReplacer.id!}
              data={attributeReplacer.replaceTo}
              updateData={(appId: string, id: number, value: string) =>
                updateAttributeReplacer(id, {
                  title: attributeReplacer.title,
                  pattern: attributeReplacer.pattern,
                  replaceTo: value,
                })
              }
              permission="regular-expression-replacer_update"
            />
          </td>

          <td className="operations-column">
            <CheckPermission
              variant="enableIf"
              permissions={['regular-expression-replacer_delete']}
            >
              <Button
                size="sm"
                color="danger"
                className="ml-2"
                onClick={() => {
                  selectAttributeReplacer(attributeReplacer);
                  toggleConfirmModal(true);
                }}
              >
                <i className="fas fa-trash-alt" />
              </Button>
            </CheckPermission>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default RegexReplacePatternsTableBody;
