import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label, Row, Col } from 'reactstrap';

import { SelectModel } from 'src/shared/models/common.model';

import TextArea from 'src/shared/components/form-inputs/TextArea';
import TextField from 'src/shared/components/form-inputs/TextField';
import ReactSelect from 'src/shared/components/form-inputs/ReactSelect';
import { NumberFieldFormik } from '../../../../shared/components';

interface Props {
  allowedFileMimeTypesOptions: SelectModel[];
}
const saveLocationOptions: SelectModel[] = [
  {
    label: 'attribute',
    value: 'attribute',
  },
  {
    label: 'positionAttribute',
    value: 'positionAttribute',
  },
  {
    label: 'none',
    value: 'none',
  },
];

const DocumentUploaderForm: React.FC<Props> = ({ allowedFileMimeTypesOptions }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Row form>
        <Col>
          <FormGroup>
            <Label for="hint">{t('common.hint')}</Label>
            <TextField name="hint" />
          </FormGroup>
        </Col>
      </Row>

      <Row form>
        <Col>
          <FormGroup>
            <Label for="webviewUrl">{t('common.webViewUrl')}</Label>
            <TextArea name="webviewUrl" />
          </FormGroup>
        </Col>
      </Row>

      <Row form>
        <Col>
          <FormGroup>
            <Label for="saveLocation">{t('common.saveLocation')}</Label>
            <ReactSelect name="saveLocation" options={saveLocationOptions}/>
          </FormGroup>
        </Col>
      </Row>

      <Row form>
        <Col>
          <FormGroup>
            <Label for="maxFileSizeInBytes">{t('common.maxFileSizeInBytes')}</Label>
            <NumberFieldFormik name="maxFileSizeInBytes" min={0} max={50000000} />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Label for="allowedFileMimeTypes">{t('common.allowedFileMimeTypes')}</Label>
            <ReactSelect
              name="allowedFileMimeTypes"
              isMulti
              options={allowedFileMimeTypesOptions}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Label for="cantUploadErrorText">{t('common.cantUploadErrorText')}</Label>
            <TextField name="cantUploadErrorText" />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Label for="tooLargeFileSizeErrorText">{t('common.tooLargeFileSizeErrorText')}</Label>
            <TextField name="tooLargeFileSizeErrorText" />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Label for="notAllowedFileMimeTypeErrorText">
              {t('common.notAllowedFileMimeTypeErrorText')}
            </Label>
            <TextField name="notAllowedFileMimeTypeErrorText" />
          </FormGroup>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DocumentUploaderForm;
