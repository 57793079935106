import React from 'react';
import { Badge } from 'reactstrap';
import moment from 'moment';

import { PositionStatus } from '../models/positions.model';
import { positionStatusColor, positionStatusToDisplay } from '../constants/positions.constants';

import i18n from '../../i18n';

import styles from '../../views/Positions/Positions.module.scss';

export const isPositionExpired = (expiresAt: string) =>
  moment(new Date()).isAfter(expiresAt, 'minute');

export const renderExpiredBadge = () => (
  <Badge className={styles.positionStatusBadge} color="warning">
    {i18n.t('common.expired')}
  </Badge>
);

export const renderPositionStatusBadge = (status: PositionStatus) => (
  <Badge className={styles.positionStatusBadge} color={positionStatusColor[status]}>
    {positionStatusToDisplay[status]}
  </Badge>
);
