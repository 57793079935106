import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';

import { Form, Formik, FormikHelpers, FormikProps } from 'formik';

import { createEventQuestion } from 'src/redux/services/event.service';
import { List, ReactSelect, TextField } from 'src/shared/components';
import { Operations, SelectModel } from 'src/shared/models';
import { ANSWER_TYPES } from 'src/shared/models/event.model';
import { getEvent } from 'src/redux/actions/events.action';
import { eventQuestionsAnswersSchema } from 'src/shared/schemas/validation.schema';

import TextAnswer from './QuestionsAndAnswers/TextAnswer';
import OptionsAnswer from './QuestionsAndAnswers/OptionsAnswer';

interface QuestionsAndAnswersProps {
  isEditingDisabled: boolean;
  getEvent: (appId: string, eventId: string) => void;
}

interface AnswerFormItem {
  id: number;
  text: string;
  value: string;
}

const answerConfigurations: SelectModel[] = [
  { value: ANSWER_TYPES.TEXT, label: 'Text' },
  { value: ANSWER_TYPES.OPTIONS, label: 'Options' },
];

const QuestionsAndAnswers: FC<QuestionsAndAnswersProps> = ({ getEvent, isEditingDisabled }) => {
  let formRef: FormikProps<any> | null = null;
  const { t } = useTranslation();
  const [isAnswerTypeSelectorDisabled, setIsAnswerTypeSelectorDisabled] = useState(false);
  const [answers, setAnswers] = useState<AnswerFormItem[]>([]);
  const { appId, eventId } = useParams<{ appId: string; eventId: string }>();

  useEffect(() => {
    if (answers.length === 0) {
      setIsAnswerTypeSelectorDisabled(false);
    } else {
      setIsAnswerTypeSelectorDisabled(true);
    }
  }, [answers]);

  const onAnswerAdd = async (values: any, formikHelpers: FormikHelpers<any>) => {
    if (values.type.value === ANSWER_TYPES.TEXT) {
      //If free text answer is added.
      if (!values.validationRegex || values.validationRegex.length === 0) {
        setAnswers([
          ...answers,
          ...[{ id: answers.length, text: '--', value: t('events.freeText') }],
        ]);
      } else {
        if (!values.validationErrorMessage) return;
        setAnswers([
          ...answers,
          ...[
            {
              id: answers.length,
              text: values.validationRegex.label,
              value: values.validationErrorMessage,
            },
          ],
        ]);
      }
    } else if (values.type.value === ANSWER_TYPES.OPTIONS) {
      setAnswers([
        ...answers,
        ...[{ id: answers.length, text: values.answer.text, value: values.answer.value }],
      ]);
      formikHelpers.setFieldValue('answer.text', '');
      formikHelpers.setFieldValue('answer.value', '');
    }
  };

  const onQuestionAdd = async (values: any) => {
    const data = {
      title: values.text,
      answerConfiguration: {},
    };

    if (values.type.value === ANSWER_TYPES.OPTIONS) {
      data.answerConfiguration = {
        type: ANSWER_TYPES.OPTIONS,
        options: answers.reduce((o, key) => ({ ...o, [key.value]: key.text }), {}),
      };
    } else if (values.type.value === ANSWER_TYPES.TEXT) {
      data.answerConfiguration = {
        type: ANSWER_TYPES.TEXT,
        validationRegex: values.validationRegex.value,
        validationErrorMessage: values.validationErrorMessage,
      };
    }

    await createEventQuestion(appId, eventId, data as any);
    formRef?.resetForm();
    setAnswers([]);
    getEvent(appId, eventId);
  };

  const fetch = () => {
    //No need to fetch here.
  };

  const deleteAnswer = async (item: AnswerFormItem) => {
    setAnswers(answers.filter(a => a.id !== item.id));
  };

  const editAnswer = async (id: string | number) => {};

  return (
    <fieldset className="border p-3">
      <legend className="col-form-label w-auto font-weight-bold">
        {t('events.addNewQuestion')}
      </legend>
      <Formik<any>
        initialValues={{ type: answerConfigurations[0], text: '', validationRegex: '' }}
        onSubmit={onAnswerAdd}
        validationSchema={eventQuestionsAnswersSchema()}
        innerRef={i => (formRef = i)}
        enableReinitialize
      >
        {({ values }) => {
          const isAnswerInputsDisabled =
            (values.type.value === ANSWER_TYPES.TEXT && answers.length === 1) || isEditingDisabled;

          return (
            <Form>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label for="text">{t('common.text')}</Label>
                    <TextField name="text" disabled={isEditingDisabled} />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  {values.slug && (
                    <FormGroup>
                      <Label for="slug">{t('common.slug')}</Label>
                      <TextField name="slug" disabled />
                    </FormGroup>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <fieldset className="border p-3">
                    <legend className="col-form-label w-auto font-weight-bold">
                      {t('common.answers')}
                    </legend>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label for="type">{t('common.type')}</Label>
                          <ReactSelect
                            name="type"
                            options={answerConfigurations}
                            isDisabled={isAnswerTypeSelectorDisabled || isEditingDisabled}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {values?.type?.value && values.type.value === ANSWER_TYPES.TEXT ? (
                        <TextAnswer disabled={isAnswerInputsDisabled} />
                      ) : (
                        <OptionsAnswer />
                      )}
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          {!isAnswerInputsDisabled && (
                            <div className="d-flex justify-content-end align-items-end">
                              <Button type="submit" color="primary">
                                {t('common.addAnswer')}
                              </Button>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    {answers.length > 0 && (
                      <Row>
                        <Col>
                          <List
                            hidePager
                            data={answers}
                            totalElements={answers.length}
                            loaded={true}
                            loading={false}
                            error={false}
                            fetchData={fetch}
                            noDataLabel={t('events.noEventQuestionAnswers')}
                            editFunction={editAnswer}
                            deleteFunction={deleteAnswer}
                            deleteTitle={t('events.deleteEventQuestionAnswer')}
                            deleteText={t('events.deleteEventQuestionAnswerConfirmMessage')}
                            updatePermission="event_question_update"
                            deletePermission="event_question_delete"
                            fields={[
                              {
                                key: 'text',
                                label: t('common.text'),
                                render: item => item.text,
                              },
                              {
                                key: 'value',
                                label: t('common.value'),
                                render: item => item.value,
                              },
                              {
                                key: 'operations',
                                label: t('common.operations'),
                                operations: [Operations.DELETE],
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                    )}
                  </fieldset>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    {answers.length > 0 && (
                      <div className="d-flex justify-content-end align-items-end mt-4">
                        <Button
                          color="primary"
                          onClick={() => onQuestionAdd(values)}
                          disabled={isEditingDisabled}
                        >
                          {t('common.addQuestion')}
                        </Button>
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </fieldset>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {
  getEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsAndAnswers);
