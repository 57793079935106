import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Card } from 'reactstrap';

import { ViewMode } from 'src/shared/constants/training-data.constants';

import { QuestionnaireLabelCategory } from 'src/shared/models/questionnaire.model';

import {
  getLabelCategories,
  createLabel,
  updateLabel,
  deleteLabel,
} from 'src/redux/actions/questionnaire.action';

import LabelCategoryCardHeader from './LabelCategoryCardHeader';
import LabelCategoryCardBody from './LabelCategoryCardBody';

interface Props {
  category: QuestionnaireLabelCategory;
  viewMode: ViewMode;
  getLabelCategories: Function;
  createLabel: Function;
  updateLabel: Function;
  deleteLabel: Function;
}

const TrainingDataCard: React.FC<Props> = ({
  category,
  viewMode,
  getLabelCategories,
  createLabel,
  updateLabel,
  deleteLabel,
}) => {
  const { appId } = useParams<{ appId: string }>();
  const { t } = useTranslation();

  const [isBodyVisible, setBodyVisible] = useState<boolean>(false);
  const [labelToAdd, setLabelToAdd] = useState<string>('');
  const [alertText, setAlertText] = useState<string>('');

  const chevronIcon = isBodyVisible ? 'fas fa-chevron-up' : 'fas fa-chevron-down';
  const colSize =
    isBodyVisible || viewMode === ViewMode.Row
      ? 12
      : viewMode === ViewMode.Normal
      ? 4
      : viewMode === ViewMode.Compact
      ? 3
      : 3;

  const handleLabelAdd = async () => {
    const { id: categoryId, labels } = category;
    setAlertText('');

    if (!labelToAdd) return;

    const isLabelExists = !!labels?.find(label => label.text === labelToAdd);

    if (isLabelExists) {
      setAlertText(t('labels.labelAlreadyExists'));
      return;
    }

    await createLabel(appId, categoryId, labelToAdd);
    getLabelCategories(appId);
    setLabelToAdd('');
  };

  const handleLabelUpdate = async (currentText: string, newText?: string) => {
    const { id: categoryId, labels } = category;

    if (currentText === newText) return;

    const label = labels?.find(label => label.text === currentText);

    if (categoryId && label?.id && newText) {
      const newLabelValues = {
        ...label,
        text: newText,
      };

      await updateLabel(appId, categoryId, label.id, newLabelValues);
      getLabelCategories(appId);
    }
  };

  const handleLabelDelete = async (labelText: string) => {
    const { id: categoryId, labels } = category;

    const label = labels?.find(label => label.text === labelText);

    if (categoryId && label?.id) {
      await deleteLabel(appId, categoryId, label.id);
      getLabelCategories(appId);
    }
  };

  return (
    <Col lg={colSize} key={category.id}>
      <Card>
        <LabelCategoryCardHeader
          category={category}
          chevronIcon={chevronIcon}
          setBodyVisible={setBodyVisible}
        />

        {isBodyVisible && (
          <LabelCategoryCardBody
            labels={category.labels || []}
            labelToAdd={labelToAdd}
            setLabelToAdd={setLabelToAdd}
            handleLabelAdd={handleLabelAdd}
            handleLabelUpdate={handleLabelUpdate}
            handleLabelDelete={handleLabelDelete}
            alertText={alertText}
          />
        )}
      </Card>
    </Col>
  );
};

const mapStateToProps = (state: any) => ({
  viewMode: state.questionnaireLabelCategories.viewMode,
});

const mapDispatchToProps = {
  getLabelCategories,
  createLabel,
  updateLabel,
  deleteLabel,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainingDataCard);
