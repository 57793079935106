export const configurationConstants = {
  recruiterPositionApplicationsList: 'recruiter.position-applications.list',
  recruiterTalentPoolList: 'recruiter.talent-pool.list',

  coreComplaintsList: 'core.complaints.list',
  coreSubscribingListSubscriberList: 'core.subscribing-list.subscriber.list',
  coreSubscribingListListFormManual: 'core.subscribing-list.list.form.manual',
  coreMessageToAdminFilter: 'core.message-to-admin.filter',
  coreEventsReportList: 'core.events.report.list',
  coreFormsReportsDetailedList: 'core.forms.reports.detailed.list',
  coreConservationsFilter: 'core.conversations.filter',
  coreIssueList: 'core.issue.list',
  coreIssueFilter: 'core.issue.filter',
  
  coreIssueReportsAveragedIssueReplyTimeReportFilter: 'core.issue.averaged-issue-reply-report.filter',
  coreIssueReportsAveragedIssueReplyTimeByContextDataReportList: 'core.issue.averaged-issue-reply-by-context-data-report.list',
  coreIssueReportsAveragedIssueReplyTimeByDashboardUserReportList: 'core.issue.averaged-issue-reply-by-dashboard-user-report.list',

  hropsSalaryAdvanceList: 'hrops.salary-advance.list',
  hropsSalaryAdvanceFilter: 'hrops.salary-advance.filter',
  hropsEmployeeSignedInReportList: 'hrops.employee.signed-in-report.list',
  hropsEmployeeNotSignedInListList: 'hrops.employee.not-signed-in-list.list',
  hropsEmployeeSignedInListList: 'hrops.employee.signed-in-list.list',

  helpdeskGroupsForm: 'helpdesk.groups.form',
  helpdeskGroupsList: 'helpdesk.groups.list',

  analyticsUsageStatisticsFilter: 'analytics.usage-statistics.filter',
  analyticsIntentStatisticsFilter: 'analytics.intent-statistics.filter',
  analyticsTopicStatisticsFilter: 'analytics.topic-statistics.filter',
};
