import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import uniqueId from 'lodash/uniqueId';

import EditableLabel from './EditableLabel';

interface Props {
  value: number | undefined;
  disabled?: boolean;
  min?: number;
  max?: number;
  handleEdit: (value: number | undefined) => void;
}

const EditableNumberInput: React.FC<Props> = ({
  value: initialValue,
  disabled = false,
  min,
  max,
  handleEdit: propsHandleEdit,
}) => {
  const [isEditing, setEditing] = useState<boolean>(false);
  const [value, setValue] = useState<number | undefined>(initialValue);

  const inputId = uniqueId('editableNumberInput-');

  useEffect(() => {
    const input = document.getElementById(inputId) as HTMLInputElement;

    if (isEditing && input) input.focus();
  }, [isEditing, inputId]);

  const handleEdit = () => {
    if (value === initialValue) return;
    propsHandleEdit(value);
  };

  const handleCancel = () => {
    setValue(initialValue);
  };

  return (
    <EditableLabel
      type="number"
      isEditing={isEditing}
      setEditing={setEditing}
      text={value}
      placeholder={value}
      disabled={disabled}
      canBeEmpty
      handleEdit={handleEdit}
      handleCancel={handleCancel}
    >
      <Input
        id={inputId}
        type="number"
        value={value}
        min={min}
        max={max}
        onChange={e => setValue(Number(e.target.value) || undefined)}
      />
    </EditableLabel>
  );
};

EditableNumberInput.defaultProps = {
  min: 1,
  max: 99,
};

export default EditableNumberInput;
