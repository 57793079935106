import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'reactstrap';

type Placement =
  | 'auto-start'
  | 'auto'
  | 'auto-end'
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start';

interface Props {
  id: string;
  className?: string;
  appId: string;
  size?: string;
  tooltipPlacement?: Placement;
  tooltipTrigger?: string;
}

const CopyToClipboardButton: React.FC<Props> = ({
  id,
  className,
  appId,
  size = 'sm',
  tooltipPlacement = 'right',
  tooltipTrigger = 'hover',
}) => {
  const { t } = useTranslation();
  const [isTooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const toggleTooltip = () => setTooltipOpen(!isTooltipOpen);

  return (
    <a
      href={`/#/application/${appId}/system-management/settings`}
      target="_blank"
      rel="noopener noreferrer"
      className="m-0"
    >
      <Button id={id} className={className} size={size}>
        <i className="fas fa-external-link-alt" />
      </Button>
      <Tooltip
        placement={tooltipPlacement}
        isOpen={isTooltipOpen}
        target={id}
        toggle={toggleTooltip}
        trigger={tooltipTrigger}
      >
        {t('common.openSettings')}
      </Tooltip>
    </a>
  );
};

export default CopyToClipboardButton;
