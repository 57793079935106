import React, { Fragment } from 'react';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { TalentPoolModels } from '../../../shared/models/talent-pools.model';

import styles from '../TalentPools.module.scss';

interface Props {
  index: number;
  talentPool: TalentPoolModels;
  showTalentPoolDetails: Function;
}

const TalentPoolCard: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { index, talentPool, showTalentPoolDetails } = props;

  const rowClasses = cx(styles.card, {
    [styles.disabled]: talentPool.size === 0,
  });

  return (
    <Fragment>
      <Row
        className={rowClasses}
        role="button"
        tabIndex={index}
        onClick={() => showTalentPoolDetails(talentPool)}
      >
        <Col className={styles.name}>
          <span className={styles.cardRowLabel}>{t('common.name')}</span>
          {talentPool.name}
        </Col>

        <Col className={styles.candidatesCount}>
          <span className={styles.cardRowLabel}>{t('common.candidates')}</span>
          {talentPool.size}
        </Col>

        <Col className={styles.date} id={`dateCreated-${talentPool.id}`}>
          <span className={styles.cardRowLabel}>{t('common.created')}</span>
          {moment(talentPool.createdAt).fromNow()}
        </Col>

        <Col className={styles.chevron}>
          <i className="fas fa-chevron-right" />
        </Col>
      </Row>

      <UncontrolledTooltip
        placement="bottom"
        target={`dateCreated-${talentPool.id}`}
        trigger="hover"
      >
        {moment(talentPool.createdAt).format('LLL')}
      </UncontrolledTooltip>
    </Fragment>
  );
};

export default TalentPoolCard;
