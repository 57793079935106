import { Dispatch } from 'redux';
import { getSignatureForDeleteService } from '../../shared/services/delete.service';

export const GET_SIGNATURE_FOR_DELETE_REQUEST = 'upload/GET_SIGNATURE_FOR_DELETE_REQUEST';
export const GET_SIGNATURE_FOR_DELETE_SUCCESS = 'upload/GET_SIGNATURE_FOR_DELETE_SUCCESS';
export const GET_SIGNATURE_FOR_DELETE_ERROR = 'upload/GET_SIGNATURE_FOR_DELETE_ERROR';

export interface SignatureForDeleteModel {
  storageType: string;
  signedUrl: string;
  downloadUrl: string;
}

export const getSignatureForDelete = (
  extension: string,
  fileNameWithoutExtension: string,
  method: string,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_SIGNATURE_FOR_DELETE_REQUEST,
    });

    try {
      dispatch({
        type: GET_SIGNATURE_FOR_DELETE_SUCCESS,
        payload: await getSignatureForDeleteService(extension, fileNameWithoutExtension, method),
      });
    } catch (err) {
      dispatch({
        type: GET_SIGNATURE_FOR_DELETE_ERROR,
        err,
      });
    }
  };
};
