import moment from 'moment';
import { QuestionModel, TagModel } from '.';

export interface TaggableModel {
  employeeTags: TagModel[] | null;
  id: number;
}

export interface EventModel extends TaggableModel {
  applicationId: number;
  title: string;
  slug: string;
  openDate: string;
  closeDate: string;
  status: EventStatus;
  description: string;
  dates: EventDateModel[];
  questions: EventQuestionModel[];
}

export interface EventDateModel {
  id: number;
  eventId: number;
  applicationId: number;
  startDateTime: moment.Moment;
  endDateTime: moment.Moment;
  limit: number | null; //null: nincs létszámkorlát
  title: string;
}

export interface EventQuestionModel extends QuestionModel {
  eventId: number;
}

export enum EventStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  EXPIRED = 'EXPIRED',
}

export interface BaseEventModel {
  title: string;
  description: string;
  slug?: string;
  isActive: boolean;
}

export interface EventEditorFormModel extends BaseEventModel {
  startDate: moment.Moment;
  startTime: moment.Moment;
  endDate: moment.Moment;
  endTime: moment.Moment;
}

export enum ANSWER_TYPES {
  TEXT = 'text',
  OPTIONS = 'optionList',
  IMAGE = 'image',
}

export interface EventReport {
  id: number;
  eventDateId: number;
  eventDateTitle: string;
  eventId: number;
  applicationId: number;
  startDateTime: moment.Moment;
  endDateTime: moment.Moment;
  limit: number;
  subscriptionsCount: number;
  title: string;
  slug: string;
  description: string;
  status: EventStatus;
  questions: EventReportQuestion[];
  subscribers: EventReportSubscriber[];
}

export interface EventReportQuestion {
  id: number;
  applicationId: number;
  title: string;
  slug: string;
  order: number;
  answerConfiguration: any;
}

export interface EventReportSubscriber {
  seqNumber: number;
  id: number;
  answers: EventReportAnswer[];
  employee: any;
  subscribedAt: moment.Moment;
  userProfileId: string;
}

export interface EventReportAnswer {
  answer: string;
  eventQuestionId: number;
}

export interface EventReportEmployee {
  attributes: any[];
  id: string;
  key: string;
  tags: any[];
  userProfileId: string;
}

export interface GetReportResponse extends EventReport {}

export interface GetReportsResponse extends Array<EventReport> {}
