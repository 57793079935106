import React, { useState, Fragment } from 'react';
import { FormFeedback } from 'reactstrap';
import { DateRangePicker } from 'react-dates';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

interface Props {
  form: {
    setFieldValue: any;
    setFieldTouched: any;
    values: any;
    errors: any;
  };
  isOutsideRange?: (day: any) => boolean;
}

const DateRange: React.FC<Props> = ({
  form: { setFieldValue, values, errors },
  isOutsideRange,
}: Props) => {
  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <Fragment>
      <DateRangePicker
        startDate={values.startDate}
        startDateId="Start"
        endDate={values.endDate}
        endDateId="End"
        onDatesChange={({ startDate, endDate }) => {
          setFieldValue('startDate', startDate);
          setFieldValue('endDate', endDate);
        }}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)}
        isOutsideRange={isOutsideRange}
      />
      {errors.startDate ||
        (errors.endDate && (
          <FormFeedback style={{ display: 'block' }}>
            {errors.startDate || errors.endDate}
          </FormFeedback>
        ))}
    </Fragment>
  );
};

export default DateRange;
