import React, { Component, Fragment } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Row, Col, Alert, Button, ButtonGroup } from 'reactstrap';
import { ResponsiveBar } from '@nivo/bar';

import Spinner from '../../../../shared/components/Spinner';

interface Props extends WithTranslation {
  distributionSum: number | undefined;
  dataCount: any;
  dataPercent: any;
}

interface State {
  selectedChartProps: ChartProps;
}

interface ChartProps {
  type: string;
  data: object[];
  legend: string;
  maxValue: number | 'auto' | undefined;
}

class HourlyDistributionOfAllMessages extends Component<Props, State> {
  state: State = {
    selectedChartProps: {
      type: 'percentage',
      data: this.props.dataPercent.data,
      legend: `${this.props.t('common.distribution')} (%)`,
      maxValue: 'auto',
    },
  };

  setChartProps(selectedChartType: string) {
    let chartProps: ChartProps = {
      type: '',
      data: [],
      legend: '',
      maxValue: 0,
    };

    switch (selectedChartType) {
      case 'percentage':
        chartProps = {
          type: 'percentage',
          data: this.props.dataPercent.data,
          legend: `${this.props.t('common.distribution')} (%)`,
          maxValue: 'auto',
        };
        return this.setState({ selectedChartProps: chartProps });
      case 'count':
        chartProps = {
          type: 'count',
          data: this.props.dataCount.data,
          legend: `${this.props.t('common.distribution')}`,
          maxValue: 'auto',
        };
        return this.setState({ selectedChartProps: chartProps });
      default:
        return null;
    }
  }

  setData() {
    const { selectedChartProps } = this.state;

    switch (selectedChartProps.type) {
      case 'percentage':
        return this.props.dataPercent.data[0]?.data.map((item: { hour: string }) => {
          const data: { [key: string]: string } = { hour: item.hour };
          this.props.dataPercent.data.map(
            (percentData: { id: string; data: Array<{ hour: string; distribution: string }> }) => {
              const id = percentData.id || ' ';
              data[id] =
                percentData.data?.find(
                  (itemData: { hour: string; distribution: string }) => itemData.hour === item.hour,
                )?.distribution || '0';
              return '';
            },
          );
          return data;
        });
      case 'count':
        return this.props.dataCount.data[0].data.map((item: { hour: string }) => {
          const data: { [key: string]: string } = { hour: item.hour };
          this.props.dataCount.data.map(
            (countData: { id: string; data: Array<{ hour: string; distribution: string }> }) => {
              const id = countData.id || ' ';
              data[id] =
                countData.data?.find(
                  (itemData: { hour: string; distribution: string }) => itemData.hour === item.hour,
                )?.distribution || '0';
              return '';
            },
          );
          return data;
        });
      default:
        return null;
    }
  }

  setKeys() {
    return this.props.dataPercent.data.map((item: { id: string }) => item.id || ' ');
  }

  formatTooltip(indexValue: string | number, value: number) {
    const { selectedChartProps } = this.state;

    switch (selectedChartProps.type) {
      case 'percentage':
        return (
          <span>
            {indexValue}h - {Number(indexValue) + 1}h: <strong>{value} %</strong>
          </span>
        );
      case 'count':
        return (
          <span>
            {indexValue}h - {Number(indexValue) + 1}h: <strong>{value}</strong>
          </span>
        );
      default:
        return <span>{value}</span>;
    }
  }

  render() {
    const { selectedChartProps } = this.state;
    const { distributionSum, t } = this.props;
    const { loading: dataPercentLoading, loaded: dataPercentLoaded } = this.props.dataPercent;
    const { loading: dataCountLoading, loaded: dataCountLoaded } = this.props.dataCount;

    return (
      <Row>
        <Col>
          <h5 className="mt-3">{t('usageStatistics.hourlyDistributionOfAllMessages')}</h5>

          {(dataPercentLoading || dataCountLoading) && (
            <div className="d-flex justify-content-center">
              <Spinner loading={dataPercentLoading || dataCountLoading} />
            </div>
          )}

          {dataPercentLoaded && dataCountLoaded && !distributionSum ? (
            <Alert color="light" className="text-center mb-0">
              {t('usageStatistics.noDataForPeriod')}
            </Alert>
          ) : null}

          {dataPercentLoaded && dataCountLoaded && distributionSum ? (
            <Fragment>
              <div className="chart-type-btn-container">
                <ButtonGroup>
                  <Button
                    color="primary"
                    outline
                    className="chart-type-btn percentage"
                    onClick={() => this.setChartProps('percentage')}
                    active={selectedChartProps.type === 'percentage'}
                  >
                    {t('common.percentage')}
                  </Button>
                  <Button
                    color="primary"
                    outline
                    className="chart-type-btn count"
                    onClick={() => this.setChartProps('count')}
                    active={selectedChartProps.type === 'count'}
                  >
                    {t('common.count')}
                  </Button>
                </ButtonGroup>
              </div>

              <div className="chart-container">
                <ResponsiveBar
                  groupMode="grouped"
                  data={this.setData()}
                  keys={this.setKeys()}
                  indexBy="hour"
                  maxValue={selectedChartProps.maxValue}
                  margin={{ top: 40, right: 60, bottom: 110, left: 60 }}
                  padding={0.3}
                  colors={{ scheme: 'nivo' }}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Hours',
                    legendPosition: 'middle',
                    legendOffset: 32,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: this.state.selectedChartProps.legend,
                    legendPosition: 'middle',
                    legendOffset: -50,
                  }}
                  labelFormat={selectedChartProps.type === 'percentage' ? v => `${v} %` : undefined}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                  tooltip={({ indexValue, value }) => this.formatTooltip(indexValue, value)}
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 70,
                      itemsSpacing: 2,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              </div>
            </Fragment>
          ) : null}
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(HourlyDistributionOfAllMessages);
