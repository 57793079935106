import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { QueryParams, SurveyModel, SurveySubscriberModel } from 'src/shared/models';
import { getSurvey, getSurveySubscribers } from 'src/redux/actions/surveys.action';
import { DateTime, List } from 'src/shared/components';

interface SurveySubscribersProps {
  getApplication: Function;
  application: ApplicationModel;
  survey: SurveyModel;
  getSurvey: Function;
  getSurveySubscribers: Function;
  surveySubscribers: SurveySubscriberModel[];
  numberOfSurveySubscribers: number;
  surveySubscribersLoaded: boolean;
  surveySubscribersLoading: boolean;
  surveySubscribersError: boolean;
}

const SurveySubscribers: FC<SurveySubscribersProps> = ({
  getApplication,
  application,
  survey,
  getSurvey,
  getSurveySubscribers,
  surveySubscribers,
  numberOfSurveySubscribers,
  surveySubscribersLoaded,
  surveySubscribersLoading,
  surveySubscribersError,
}) => {
  const { t } = useTranslation();
  const { appId, surveyId } = useParams<{ appId: string; surveyId: string }>();

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    getSurvey(appId, surveyId);
    /* eslint-disable-next-line */
  }, []);

  const fetchSurveySubscribers = (queryParams: QueryParams) => {
    getSurveySubscribers(appId, surveyId, queryParams);
  };

  return (
    <div className="animated fadeIn">
      <List
        useCardContent
        pageHeaderProps={{ title: `${t('surveys.subscribers')} - ${survey?.name}` }}
        data={surveySubscribers}
        totalElements={numberOfSurveySubscribers}
        loaded={surveySubscribersLoaded}
        loading={surveySubscribersLoading}
        error={surveySubscribersError}
        fetchData={fetchSurveySubscribers}
        title={t('surveys.surveys')}
        defaultItemOnPage={50}
        fields={[
          {
            key: 'uuid',
            label: t('common.id'),
            columnStyle: 'uuid',
            render: item => (item.employee ? item.employee.key : item.userId),
          },
          {
            key: 'filledAt',
            label: t('surveys.filledAt'),
            render: item => (item.filledAt ? <DateTime dateTime={item.filledAt} /> : '-'),
          },
          {
            key: 'lastTriedAt',
            label: t('surveys.lastTriedAt'),
            render: item => (item.lastTriedAt ? <DateTime dateTime={item.lastTriedAt} /> : '-'),
          },
          { key: 'retries', label: t('surveys.retries') },
        ]}
        noDataLabel={t('surveys.noSurveySubscribers')}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    survey: state.surveyGeneral.data?.survey,
    surveySubscribers: state.surveySubscribers.data?.content,
    numberOfSurveySubscribers: state.surveySubscribers.data.totalElements,
    surveySubscribersLoading: state.surveySubscribers.loading,
    surveySubscribersLoaded: state.surveySubscribers.loaded,
    surveySubscribersError: state.surveySubscribers.error,
  };
};

const mapDispatchToProps = {
  getSurvey,
  getSurveySubscribers,
  getApplication,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveySubscribers);
