import React, { FC } from 'react';

import styles from '../scss/Viber.module.scss';

interface ViberButtonContainerProps {
  text: string;
}

const ViberButtonContainer: FC<ViberButtonContainerProps> = ({ text, children }) => {
  return (
    <div className={styles.viberButtonTemplate}>
      <div className={styles.viberBox}>
        <p>{text}</p>
      </div>
      <div className={styles.viberButtonTemplateButtons}>{children}</div>
    </div>
  );
};

export default ViberButtonContainer;
