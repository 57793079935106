import moment from 'moment';

export const mergeDateWithTime = (date: moment.Moment, time: moment.Moment) => {
  const year = moment(date).year();
  const month = moment(date).month();
  const day = moment(date).date();

  const hour = moment(time).hour();
  const minute = moment(time).minute();

  const mergedDate = new Date(year, month, day, hour, minute).toISOString();

  return mergedDate;
};
