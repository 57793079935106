import React, { Fragment } from 'react';
import moment from 'moment';
import i18n from 'src/i18n';
import {UploadedDocumentContentModel} from 'src/shared/models/uploaded-document.model';
import { renderFileLink } from 'src/views/AuditLog/helpers/renderHelpers';

interface Props {
  document: UploadedDocumentContentModel;
}

const UploadedDocument: React.FC<Props> = ({ document }) => {
  return (
    <Fragment>
      <p className="font-weight-bold">
        {i18n.t('auditLog.detailsOfCreatedDocument')}
      </p>

      <table className="table table-sm">
        <tbody>
        <tr>
            <td>{i18n.t('common.id')}</td>
            <td>{document.id}</td>
          </tr>
          <tr>
            <td>{i18n.t('common.name')}</td>
            <td>{document.type}</td>
          </tr>
          <tr>
            <td>{i18n.t('foldersAndDocuments.fileName')}</td>
            <td>{document.url}</td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

export default UploadedDocument;
