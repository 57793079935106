import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';
import TextMessage from './TextMessage';

import styles from './scss/LiveChat.module.scss';

interface Props {
  message: ConversationHistoryModel;
}

interface State {
  text: string;
  outputTemplate: any[];
}

class MultiList extends Component<Props, State> {
  state: State = {
    text: '',
    outputTemplate: [],
  };

  componentDidMount() {
    const { messageAsText } = this.props.message;
    this.renderTemplate(messageAsText);
  }

  renderTemplate(msg: any) {
    try {
      const outputTemplate: any[] = [];
      const message = JSON.parse(msg);
      const { text, livechatMultiListTemplateOptions: mainOptions } = message;

      mainOptions.map((option: any) => {
        const mainOptionAClass = classNames({
          [styles.disabledOption]: option.url === 'null',
        });

        outputTemplate.push(
          <a key={option.value} href={option.url} className={mainOptionAClass}>
            <button type="button" className={styles.mainOption}>
              {option.text}
            </button>
          </a>,
        );

        if (option.subOptions && option.subOptions.length) {
          option.subOptions.map((subOption: any, index: number) => {
            const subOptionClass = classNames({
              [styles.subOption]: true,
              [styles.lastOption]: option.subOptions.length - 1 === index,
            });

            const subOptionAClass = classNames({
              [styles.disabledOption]: subOption.url === 'null',
            });

            return outputTemplate.push(
              <a
                key={`${option.value}-${subOption.value}`}
                href={subOption.url}
                className={subOptionAClass}
              >
                <button type="button" className={subOptionClass}>
                  {subOption.text}
                  {!subOptionAClass ? <i className="fas fa-external-link-alt ml-2" /> : null}
                </button>
              </a>,
            );
          });
        }

        return null;
      });

      this.setState({
        text,
        outputTemplate,
      });
    } catch (error) {
      this.setState({
        text: error,
        outputTemplate: [],
      });
    }
  }

  render() {
    const { message } = this.props;
    const { text, outputTemplate } = this.state;

    return (
      <Fragment>
        <TextMessage message={message} buttonTemplateText={text} />
        <div className={styles.multiListOptions}>{outputTemplate}</div>
      </Fragment>
    );
  }
}

export default MultiList;
