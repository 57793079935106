import { StateModel } from 'src/shared/models';
import {
  GET_CONVERSATION_FLOW_VARIABLES_REQUEST,
  GET_CONVERSATION_FLOW_VARIABLES_SUCCESS,
  GET_CONVERSATION_FLOW_VARIABLES_ERROR,
  CREATE_CONVERSATION_FLOW_VARIABLE_REQUEST,
  CREATE_CONVERSATION_FLOW_VARIABLE_SUCCESS,
  CREATE_CONVERSATION_FLOW_VARIABLE_ERROR,
  UPDATE_CONVERSATION_FLOW_VARIABLE_REQUEST,
  UPDATE_CONVERSATION_FLOW_VARIABLE_SUCCESS,
  UPDATE_CONVERSATION_FLOW_VARIABLE_ERROR,
  DELETE_CONVERSATION_FLOW_VARIABLE_REQUEST,
  DELETE_CONVERSATION_FLOW_VARIABLE_SUCCESS,
  DELETE_CONVERSATION_FLOW_VARIABLE_ERROR,
  RELOAD_CONVERSATION_FLOW_VARIABLE_REQUEST,
  RELOAD_CONVERSATION_FLOW_VARIABLE_SUCCESS,
  RELOAD_CONVERSATION_FLOW_VARIABLE_ERROR,
} from '../actions/conversation-flow-variables.action';
import { updatePaginatedListItem } from 'src/shared/utils/reducer.util';

interface ConflowVariablesStateModel {
  resources: StateModel;
  createConversationFlowVariables: StateModel;
  updateConversationFlowVariables: StateModel;
  deleteConversationFlowVariables: StateModel;
  reloadConversationFlowVariables: StateModel;
}

const INITIAL_STATE: ConflowVariablesStateModel = {
  resources: {
    loading: false,
    loaded: false,
    error: null,
    data: { content: [] },
  },
  createConversationFlowVariables: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  updateConversationFlowVariables: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  deleteConversationFlowVariables: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  reloadConversationFlowVariables: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
};

export const conversationFlowVariableReducer = (
  state: ConflowVariablesStateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case GET_CONVERSATION_FLOW_VARIABLES_REQUEST:
      return {
        ...state,
        resources: {
          loading: true,
          loaded: false,
          error: null,
          data: { content: [] },
        },
      };

    case GET_CONVERSATION_FLOW_VARIABLES_SUCCESS:
      return {
        ...state,
        resources: {
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case GET_CONVERSATION_FLOW_VARIABLES_ERROR:
      return {
        ...state,
        resources: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CREATE_CONVERSATION_FLOW_VARIABLE_REQUEST:
      return {
        ...state,
        createConversationFlowVariables: {
          loading: true,
          loaded: false,
          error: null,
        },
      };

    case CREATE_CONVERSATION_FLOW_VARIABLE_SUCCESS:
      return {
        ...state,
        createConversationFlowVariables: {
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case CREATE_CONVERSATION_FLOW_VARIABLE_ERROR:
      return {
        ...state,
        createConversationFlowVariables: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case UPDATE_CONVERSATION_FLOW_VARIABLE_REQUEST:
      return {
        ...state,
        updateConversationFlowVariables: {
          loading: true,
          loaded: false,
          error: null,
        },
      };

    case UPDATE_CONVERSATION_FLOW_VARIABLE_SUCCESS:
      return {
        ...state,
        updateConversationFlowVariables: {
          loading: false,
          loaded: true,
          data: action.payload,
        },
        ...updatePaginatedListItem(state.resources, action.payload),
      };

    case UPDATE_CONVERSATION_FLOW_VARIABLE_ERROR:
      return {
        ...state,
        updateConversationFlowVariables: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case DELETE_CONVERSATION_FLOW_VARIABLE_REQUEST:
      return {
        ...state,
        deleteConversationFlowVariables: {
          loading: true,
          loaded: false,
          error: null,
        },
      };

    case DELETE_CONVERSATION_FLOW_VARIABLE_SUCCESS:
      return {
        ...state,
        deleteConversationFlowVariables: {
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case DELETE_CONVERSATION_FLOW_VARIABLE_ERROR:
      return {
        ...state,
        deleteConversationFlowVariables: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case RELOAD_CONVERSATION_FLOW_VARIABLE_REQUEST:
      return {
        ...state,
        reloadConversationFlowVariables: {
          loading: true,
          loaded: false,
          error: null,
        },
      };

    case RELOAD_CONVERSATION_FLOW_VARIABLE_SUCCESS:
      return {
        ...state,
        reloadConversationFlowVariables: {
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case RELOAD_CONVERSATION_FLOW_VARIABLE_ERROR:
      return {
        ...state,
        reloadConversationFlowVariables: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default conversationFlowVariableReducer;
