import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { uniqueId } from 'lodash';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import {
  getFormDetailedReport,
  revertFormDetailedReport,
} from 'src/redux/actions/form-detailed-report.action';
import { getForm } from 'src/redux/actions/forms.action';
import { default as DateRangeFilter } from '../UsageStatistics/components/Filter';
import { ExportButton, List } from 'src/shared/components';
import {
  ANSWER_TYPES,
  FormDetailedReport,
  FormModel,
  FormQuestionModel,
  FormReportUserDto,
  ListFieldModel,
  PageQueryParams,
  QueryParams,
  QuestionOptionListAnswerConfiguration,
  TextAnswerConfiguration,
} from 'src/shared/models';

import { configurationConstants } from 'src/shared/constants/configuration.constants';
import { getListFieldsByConfiguration } from 'src/shared/utils/configuration.util';
import { exportDetailedReport } from 'src/redux/services/forms.service';
import saveFile from 'src/shared/utils/file-saver.util';
import { fileNames } from 'src/shared/settings';
import { getSignedUrlAndOpenFile } from 'src/shared/services/upload.service';

interface IFormsReportProps {
  form: FormModel;
  report: FormDetailedReport;
  reportLoading: boolean;
  reportLoaded: boolean;
  reportError: boolean;
  application: ApplicationModel;

  getApplication: Function;
  getForm: (appId: string, formId: string) => Promise<void>;
  getFormDetailedReport: (
    appId: string,
    formId: string,
    queryParams: PageQueryParams,
  ) => Promise<void>;
  revertFormDetailedReport: () => void;
}

const FormsReport: FC<IFormsReportProps> = ({
  form,
  report,
  reportLoading,
  reportLoaded,
  reportError,
  application,

  getApplication,
  getForm,
  getFormDetailedReport,
  revertFormDetailedReport,
}) => {
  const { t } = useTranslation();
  const { appId, formId } = useParams<{ appId: string; formId: string }>();
  /* eslint-disable-next-line */
  const [queryParams, setQueryParams] = useState<QueryParams>({
    from: moment().utc().subtract(7, 'days').startOf('day').toISOString(),
    to: moment().utc().endOf('day').toISOString(),
    page: 0,
    size: 100,
    limit: 20,
  });

  useEffect(() => {
    revertFormDetailedReport();
    getForm(appId, formId);

    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    if (form) {
      fetchItems();
    }

    /* eslint-disable-next-line */
  }, [form, queryParams]);

  const fetchItems = async () => {
    return getFormDetailedReport(appId, formId, queryParams);
  };

  const handleDateChange = async (from: string, to: string) => {
    setQueryParams(
      Object.assign(queryParams, {
        from: moment(from).startOf('day').toISOString(),
        to: moment(to).endOf('day').toISOString(),
      }),
    );
  };

  const configuredFields = getListFieldsByConfiguration(
    application,
    configurationConstants.coreFormsReportsDetailedList,
    t,
  );

  const getFields = (reportRow: FormDetailedReport): ListFieldModel<any>[] => {
    const fields = [];
    const returnEmptyIfFails = (func: () => any) => {
      try {
        return func();
      } catch {
        return '';
      }
    };

    fields.push({
      key: uniqueId(),
      label: '',
      render: (item: FormReportUserDto) => {
        return returnEmptyIfFails(() => item.seqNumber);
      },
    });

    fields.push({
      key: uniqueId(),
      label: 'Attempts',
      render: (item: FormReportUserDto) => {
        return returnEmptyIfFails(() => item.attempts);
      },
    });

    const numberOfFixColumns = 2;

    const numberOfAllColumns = numberOfFixColumns + reportRow.form.elements.length;

    //answers
    const customFields = reportRow.form.elements.map((question: FormQuestionModel) => {
      const getFieldBasedOnAnswerConfiguration = (
        type: ANSWER_TYPES,
        item: FormReportUserDto,
        configuration: TextAnswerConfiguration | QuestionOptionListAnswerConfiguration,
      ) => {
        const selected = item.formFill.answers.filter(a => a.formElementId === question.id);
        if (selected.length === 0) return '';

        switch (type) {
          case ANSWER_TYPES.TEXT: {
            return selected[0].answer;
          }
          case ANSWER_TYPES.OPTIONS: {
            return selected[0].answer;
            // const currentConfiguration = configuration as QuestionOptionListAnswerConfiguration;
            // return currentConfiguration.options[selected[0].answer as any]?.value;
          }
          case ANSWER_TYPES.IMAGE: {
            if (selected[0].answer !== null) {
              return (
                <Button
                  className="btn-sm"
                  onClick={() => {
                    getSignedUrlAndOpenFile(selected[0].answer);
                  }}
                >
                  <i className="file-icon far fa-file-alt" />
                </Button>
              );
            }
            break;
          }
          default: {
            return '';
          }
        }
      };

      const field: ListFieldModel<any> = {
        key: uniqueId(),
        label: question.text,
        columnCssStyle: { width: `${100 / numberOfAllColumns}%` },
        render: (item: FormReportUserDto) => {
          if (item) {
            return getFieldBasedOnAnswerConfiguration(
              question.answerConfiguration.type,
              item,
              question.answerConfiguration,
            );
          }
          return '';
        },
      };
      return field;
    });

    return [...fields, ...configuredFields, ...customFields];
  };

  const exportReport = async () => {
    const response = await exportDetailedReport(appId, formId, queryParams);

    if (response) {
      saveFile(response, fileNames.FORM_DETAILED_REPORT_EXPORT_EXCEL, 'xlsx');
    }
  };

  const fields = useMemo(() => {
    return report && getFields(report);
    /* eslint-disable-next-line */
  }, [report, configuredFields]);

  return (
    <div className="animated fadeIn">
      <Row>
        <Col>
          <h4>{t('forms.detailedReport')}</h4>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col className="d-flex justify-content-start">
          <DateRangeFilter handleDateChange={handleDateChange} getAllData={fetchItems} />
        </Col>
        <Col className="d-flex justify-content-end">
          <ExportButton
            className="ml-2"
            disabled={report === undefined || report.reportRows.length === 0}
            onClick={exportReport}
            permission="form_form_reports_detailed_export_read"
            tooltipProps={{ target: 'exportButton', placement: 'left' }}
            exportTooltipLabel={t('common.exportToExcel')}
          />
        </Col>
      </Row>
      <Card>
        <CardBody>
          {report ? (
            <List
              key={`${uniqueId()}-${report.form.id}`}
              data={report.reportRows}
              fetchData={() => {}}
              loaded={reportLoaded}
              loading={reportLoading}
              hidePager
              totalElements={999999}
              error={reportError}
              fields={fields}
              noDataLabel={t('events.noReports')}
            />
          ) : (
            <>{t('events.noReports')}</>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    form: state.form.data?.form,
    report: state.formDetailedReport.data,
    reportLoading: state.formDetailedReport.loading,
    reportLoaded: state.formDetailedReport.loaded,
    reportError: state.formDetailedReport.error,
    application: state.application.data,
  };
};

const mapDispatchToProps = {
  getApplication,
  getForm,
  getFormDetailedReport,
  revertFormDetailedReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormsReport);
