import React from 'react';
import { Row, Col } from 'reactstrap';
import {PositionModel} from "src/shared/models/positions.model";

interface Props {
    position: PositionModel
}

const ShortListHeader: React.FC<Props> = ({ position }) => {

  return (
    <Row className="mb-4">
      <Col>
        <h4>{position?.name}</h4>
      </Col>
    </Row>
  );
};

export default ShortListHeader;
