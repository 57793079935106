import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Alert, Button, Row, Col, CardBody, CardHeader, Card } from 'reactstrap';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import isEqual from 'lodash/isEqual';

import { StateModel } from 'src/shared/models/default-state.model';
import {
  getList,
  getListElements,
  createListElement,
  updateListElement,
  updateListElementsOrder,
  deleteListElement,
  clearListElementErrors,
} from '../../redux/actions/lists.action';
import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { UserModel } from '../../redux/reducers/user.reducer';
import { ListModel } from '../../redux/reducers/lists.reducer';
import { Spinner } from 'src/shared/components';

import { ListElementModel } from '../../redux/reducers/list-elements.reducer';

import { SQLDirection, ElementsQueryParams } from '../../shared/models/query-params.model';
import { getSignatureForUpload } from '../../redux/actions/upload.action';
import { getSignatureForDelete } from '../../redux/actions/delete.action';
import {
  generateThumbnail,
  getSignedUrlForDownloadService,
  uploadFile,
} from '../../shared/services/upload.service';
import { deleteFile as deleteFileService } from '../../shared/services/delete.service';
import { parseFileNameWithoutExtension, parseFileExtension } from '../../shared/utils/parse.util';

import CheckPermission from '../../shared/components/CheckPermission';
import ConfirmModal from '../../shared/components/modals/ConfirmModal';
import { DocumentsRow } from './components/DocumentsRow';
import { DocumentAddModal } from '../../shared/components/modals/DocumentAddModal';
import { DocumentEditModal } from '../../shared/components/modals/DocumentEditModal';

import './FoldersAndDocuments.scss';
import ImageGallery from './components/ImageGallery';
import FolderLoading from './components/FolderLoading';

interface Props extends RouteComponentProps<{ id: string; appId: string }>, WithTranslation {
  getApplication: Function;
  application: ApplicationModel;
  user: UserModel;
  list: ListModel;
  listElement: StateModel;
  listElements: StateModel;
  getListElements: Function;
  createListElement: Function;
  updateListElement: Function;
  updateListElementsOrder: Function;
  deleteListElement: Function;
  signatureForUpload: StateModel;
  getSignatureForUpload: Function;
  signatureForDelete: StateModel;
  getSignatureForDelete: Function;
  clearListElementErrors: Function;
  getList: Function;
}

interface State {
  createModalOpen: boolean;
  editModalOpen: boolean;
  isGalleryEdit: boolean;
  confirmModalOpen: boolean;
  selectedElement: ListElementModel | undefined;
  uploadingFiles: boolean;
  queryParams: ElementsQueryParams;
  dataHasChanged: boolean;
  uploadMethod: string;
  deleteMethod: string;
  elements: any[];
  loading: boolean;
  uploadingDetails: {
    total?: number;
    current?: number;
  };
}

class Documents extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  state: State = {
    createModalOpen: false,
    editModalOpen: false,
    isGalleryEdit: false,
    confirmModalOpen: false,
    selectedElement: undefined,
    uploadingFiles: false,
    queryParams: {
      direction: SQLDirection.ASC,
      sort: 'priority',
    },
    dataHasChanged: false,
    uploadMethod: 'PUT',
    deleteMethod: 'DELETE',
    elements: [],
    loading: false,
    uploadingDetails: {
      total: 0,
      current: 0,
    },
  };

  async componentDidMount() {
    if (!this.props.application.id) {
      await this.props.getApplication(this.props.match.params.appId);
    }
    this.props.getList(this.props.match.params.appId, this.props.match.params.id);
    this.getListElements();
  }

  async getListElements() {
    await this.props.getListElements(
      this.props.match.params.appId,
      this.props.match.params.id,
      this.state.queryParams,
    );

    if (!this.props.list) {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.props.listElements.data) {
        if (item.imageUrl && !item.thumbnail) {
          // eslint-disable-next-line no-await-in-loop
          item.thumbnail = await getSignedUrlForDownloadService(item.imageUrl);
        }
      }
    }

    this.setState({
      elements: this.props.listElements.data,
    });
  }

  openCreateModal() {
    this.setState({
      createModalOpen: true,
    });
  }

  closeCreateModal() {
    this.setState({
      createModalOpen: false,
    });

    this.props.clearListElementErrors();
  }

  openEditModal(element: any, isGalleryEdit: boolean) {
    this.setState({
      selectedElement: element,
      editModalOpen: true,
      dataHasChanged: false,
      isGalleryEdit: isGalleryEdit,
    });
  }

  closeEditModal() {
    this.setState({
      selectedElement: undefined,
      editModalOpen: false,
    });

    this.props.clearListElementErrors();
  }

  deleteListConfirm(element: any) {
    this.setState({
      confirmModalOpen: true,
      selectedElement: element,
    });
  }

  closeDeleteModal() {
    const deleteButton = document.getElementById('deleteDocumentButton');
    if (deleteButton) {
      deleteButton.blur();
    }

    this.setState({
      confirmModalOpen: false,
      selectedElement: undefined,
    });
  }

  async deleteListElement() {
    if (this.state.selectedElement) {
      //Delete from S3
      await this.deleteFiles([
        this.state.selectedElement.fileUrl || '',
        this.state.selectedElement.imageUrl || '',
      ]);
      //Delete from list
      await this.props.deleteListElement(
        this.props.match.params.appId,
        this.props.list.id,
        this.state.selectedElement.id,
      );

      this.setState({
        elements: this.state.elements.filter(e => e.id !== this.state.selectedElement?.id),
      });
    }

    this.closeDeleteModal();
    const orderedElementsIds = this.getOrderedElementsIds(this.state.elements);
    await this.props.updateListElementsOrder(
      this.props.match.params.appId,
      this.props.match.params.id,
      orderedElementsIds,
    );
  }

  async createListElement(element: ListElementModel, image?: File, document?: File) {
    const createData: any = {
      ...element,
      imageUrl: '',
      fileUrl: '',
    };

    // Kép feltöltése esetén
    if (image) {
      const ext = parseFileExtension(image.name);

      if (ext) {
        await this.props.getSignatureForUpload(ext, this.state.uploadMethod);
        await uploadFile(image, this.props.signatureForUpload.data);
        createData.imageUrl = this.props.signatureForUpload.data.downloadUrl;
      }
    }

    // Dokumentum feltöltése esetén
    if (document) {
      const ext = parseFileExtension(document.name);

      if (ext) {
        await this.props.getSignatureForUpload(ext, this.state.uploadMethod);
        await uploadFile(document, this.props.signatureForUpload.data);
        createData.fileUrl = this.props.signatureForUpload.data.downloadUrl;
      }
    }

    await this.props.createListElement(
      this.props.match.params.appId,
      this.props.list.id,
      createData,
    );

    if (this.props.listElement.error && (createData.imageUrl || createData.fileUrl)) {
      this.deleteFiles([createData.imageUrl, createData.fileUrl]);
    }

    if (!this.props.listElement.error) {
      if (!this.props.list.gallery) {
        await this.getListElements();
      }
      const orderedElementsIds = this.getOrderedElementsIds(this.state.elements);
      await this.props.updateListElementsOrder(
        this.props.match.params.appId,
        this.props.match.params.id,
        orderedElementsIds,
      );
      if (!this.props.list.gallery) {
        this.getListElements();
      }
      this.closeCreateModal();
    }
  }

  async updateListElement(
    element: ListElementModel,
    image?: File,
    document?: File,
    deletedFileUrls?: string[],
    deletedFileTypes?: string[],
  ): Promise<any> {
    if (!this.state.selectedElement) {
      return Promise.reject();
    }
    // Annak ellenőrzése, hogy az adatokban történt-e változás
    // Name, Description, Short Description input-ok
    if (!isEqual(element, this.state.selectedElement)) {
      await this.setState({
        dataHasChanged: true,
      });
    }

    // Kép és/vagy dokumentum törlése/felülírása esetén
    if (deletedFileUrls) {
      await this.deleteFiles(deletedFileUrls);

      if (deletedFileTypes) {
        if (deletedFileTypes.includes('image')) {
          element.imageUrl = '';
        }

        if (deletedFileTypes.includes('document')) {
          element.fileUrl = '';
        }
      }

      await this.setState({
        dataHasChanged: true,
      });
    }

    // Új kép feltöltése vagy meglévő cseréje esetén
    if (image) {
      const ext = parseFileExtension(image.name);

      if (ext) {
        await this.props.getSignatureForUpload(ext, this.state.uploadMethod);
        await uploadFile(image, this.props.signatureForUpload.data);

        element.imageUrl = this.props.signatureForUpload.data.downloadUrl;
      }

      await this.setState({
        dataHasChanged: true,
      });
    }

    // Új dokumentum feltöltése vagy meglévő cseréje esetén
    if (document) {
      const ext = parseFileExtension(document.name);

      if (ext) {
        await this.props.getSignatureForUpload(ext, this.state.uploadMethod);
        await uploadFile(document, this.props.signatureForUpload.data);

        element.fileUrl = this.props.signatureForUpload.data.downloadUrl;
      }

      await this.setState({
        dataHasChanged: true,
      });
    }

    // Ha történt változás, akkor a lista elem frissítése az új adatokkal
    if (this.state.dataHasChanged) {
      await this.props.updateListElement(
        this.props.match.params.appId,
        this.props.list.id,
        element.id,
        element,
      );
    }

    // Sikeresen frissítés esetén a lista elemek lekérdezese majd a Modal bezárása
    if (!this.props.listElement.error) {
      this.getListElements();
      this.closeEditModal();
    }

    return Promise.resolve();
  }

  async deleteFiles(fileUrls: string[]) {
    const { deleteMethod } = this.state;

    fileUrls.map(async (fileUrl: string) => {
      const ext = parseFileExtension(fileUrl);
      const fileNameWithoutExtension = parseFileNameWithoutExtension(fileUrl);

      if (ext) {
        await this.props.getSignatureForDelete(ext, fileNameWithoutExtension, deleteMethod);
        deleteFileService(this.props.signatureForDelete.data);
      }
    });
  }

  reOrderElements = (list: object[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  };

  async onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    if (result.source.index === result.destination.index) {
      return;
    }

    const elements = this.reOrderElements(
      this.state.elements,
      result.source.index,
      result.destination.index,
    );

    await this.setState({
      elements,
    });

    const orderedElementsIds = this.getOrderedElementsIds(this.state.elements);

    await this.updateSortingAndReload(orderedElementsIds);
  }

  async updateSortingAndReload(orderedElementsIds: Number[]) {
    this.setState({ loading: true });
    await this.props.updateListElementsOrder(
      this.props.match.params.appId,
      this.props.match.params.id,
      orderedElementsIds,
    );
    await this.getListElements();
    this.setState({ loading: false });
  }

  getOrderedElementsIds(elements: Array<Object>) {
    const elementsIds: Array<Number> = [];

    elements.map((item: any, index: number) => {
      return elementsIds.push(item.id);
    });

    return elementsIds;
  }

  // eslint-disable-next-line class-methods-use-this
  multipleUpload() {
    document.getElementById('fileUploads')?.click();
  }

  async multipleUploadStart(event: React.ChangeEvent<HTMLInputElement>) {
    const total = event.target.files?.length || 0;
    this.setState({
      uploadingFiles: true,
      uploadingDetails: { total: total, current: 1 },
    });

    const { files } = event.target;
    const length = files?.length || 0;

    const maxPriority = this.state.elements.reduce(
      (prevValue, currentValue) =>
        currentValue.priority > prevValue ? currentValue.priority : prevValue,
      -1,
    );

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      const file = files?.item(i);
      if (file === null) {
        // eslint-disable-next-line no-continue
        continue;
      }
      // eslint-disable-next-line no-await-in-loop
      const thumbnail = await generateThumbnail(file);
      if (!thumbnail) {
        return;
      }

      // eslint-disable-next-line no-await-in-loop
      await this.createListElement(
        {
          name: file?.name,
          shortDescription: '',
          description: '',
          priority: maxPriority + (1 + i),
        },
        thumbnail,
        file,
      );
      this.setState({ uploadingDetails: { current: i + 1, total: total } });
    }

    this.setState({ uploadingFiles: false });
    this.getListElements();

    const input = document.getElementById('fileUploads');
    if (input) {
      input.nodeValue = null;
    }
  }

  getItemStyle = (isDragging: any, draggableStyle: any) => ({
    background: isDragging ? '#d4ebf2 ' : '',
    border: isDragging ? '2px solid #99d0e0 ' : '',
    display: isDragging ? 'table' : '',

    ...draggableStyle,
  });

  getHeader = (list: ListModel, t: Function, buttonTitle: string) => {
    return (
      <Row>
        <Col className="d-flex justify-content-end">
          <CheckPermission variant="displayIf" permissions={['simple-elements_create']}>
            <>
              <Button
                className=""
                color="success"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={
                  list.gallery ? this.multipleUpload.bind(this) : this.openCreateModal.bind(this)
                }
              >
                {buttonTitle}
                <Spinner loading={this.state.uploadingFiles || false} className="ml-2" />
              </Button>
              <input
                className="d-none"
                type="file"
                id="fileUploads"
                accept="image/*"
                onChange={this.multipleUploadStart.bind(this)}
                multiple
              />
            </>
          </CheckPermission>
        </Col>
      </Row>
    );
  };

  render() {
    const { listElements, t, list } = this.props;
    const { elements, selectedElement, loading, uploadingFiles, uploadingDetails } = this.state;
    const isLoading = this.props.listElements.loading || loading || uploadingFiles;
    return (
      <div>
        {isLoading ? (
          <FolderLoading
            isLoading={isLoading}
            isUploadingFiles={uploadingFiles}
            uploadingDetails={uploadingDetails}
          />
        ) : list.gallery ? (
          <ImageGallery
            items={elements}
            editFunc={this.openEditModal.bind(this)}
            deleteFunc={this.deleteListConfirm.bind(this)}
            galleryHeader={this.getHeader(list, t, t('foldersAndDocuments.newPhoto'))}
            updateFunc={this.updateSortingAndReload.bind(this)}
          />
        ) : (
          <Row>
            <Col>
              <Card className="animated fadeIn">
                <CardHeader>
                  {this.getHeader(list, t, t('foldersAndDocuments.newDocument'))}
                </CardHeader>
                <CardBody>
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div className="table-responsive">
                          <table className="table documents-table table-hover table-striped">
                            <thead>
                              <tr>
                                <th className="name">{t('common.name')}</th>
                                <th className="date-created">{t('common.created')}</th>
                                <th className="attachments">
                                  {t('foldersAndDocuments.attachments')}
                                </th>
                                <th className="slug">{t('common.slug')}</th>
                                <th className="operations">{t('common.operations')}</th>
                              </tr>
                            </thead>
                            <tbody {...provided.droppableProps} ref={provided.innerRef}>
                              {elements &&
                                elements.map((item: any, index: number) => (
                                  <DocumentsRow
                                    key={item.id}
                                    index={index}
                                    item={item}
                                    openEditModal={this.openEditModal.bind(this)}
                                    deleteListConfirm={this.deleteListConfirm.bind(this)}
                                  />
                                ))}
                              {provided.placeholder}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  {listElements.loaded && !listElements.data.length ? (
                    <Alert className="text-center" color="info">
                      {t('common.noImportFilesFound')}
                    </Alert>
                  ) : null}

                  <Button outline color="primary" onClick={() => this.props.history.go(-1)}>
                    <i className="fas fa-chevron-left mr-1" />
                    {t('common.back')}
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        <CheckPermission variant="displayIf" permissions={['simple-elements_create']}>
          <DocumentAddModal
            cancel={this.closeCreateModal.bind(this)}
            confirm={this.createListElement.bind(this)}
            isOpen={this.state.createModalOpen}
            isLoading={this.props.listElement.loading}
            errors={this.props.listElement.error}
          />
        </CheckPermission>

        <CheckPermission variant="displayIf" permissions={['simple-elements_update']}>
          <DocumentEditModal
            {...this.props}
            element={selectedElement}
            cancel={this.closeEditModal.bind(this)}
            confirm={this.updateListElement.bind(this)}
            isOpen={this.state.editModalOpen}
            isLoading={this.props.listElement.loading}
            errors={this.props.listElement.error}
            isGalleryEdit={this.state.isGalleryEdit}
          />
        </CheckPermission>

        <CheckPermission variant="displayIf" permissions={['simple-elements_delete']}>
          <ConfirmModal
            title={t('foldersAndDocuments.deleteDocument')}
            text={t('foldersAndDocuments.documentDeleteConfirmMessage')}
            item={selectedElement && selectedElement.name}
            cancel={this.closeDeleteModal.bind(this)}
            confirm={this.deleteListElement.bind(this)}
            isOpen={this.state.confirmModalOpen}
            isLoading={this.props.listElements.loading}
          />
        </CheckPermission>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  user: state.user.data,
  list: state.list.data,
  listElement: state.listElement,
  listElements: state.listElements,
  signatureForUpload: state.signatureForUpload,
  signatureForDelete: state.signatureForDelete,
});

const mapDispatchToProps = {
  getApplication,
  getList,
  getListElements,
  createListElement,
  updateListElement,
  updateListElementsOrder,
  deleteListElement,
  clearListElementErrors,
  getSignatureForUpload,
  getSignatureForDelete,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(Documents)));
