import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Card, CardBody } from 'reactstrap';
import { withTranslation, WithTranslation } from 'react-i18next';

import { getUploadedDocuments } from '../../redux/actions/uploaded-documents.action';
import { QueryParams, UploadedDocumentType, UploadedDocumentContentModel } from '../models';
import { CheckPermission, List } from '.';
import { getSignedUrlAndOpenFile } from '../services/upload.service';

interface Props extends RouteComponentProps<{ id: string; listId: string }>, WithTranslation {
  getUploadedDocuments: Function;
  uploadedDocuments: UploadedDocumentContentModel[];
  numberOfUploadedDocuments: number;
  uploadedDocumentsLoading: boolean;
  uploadedDocumentsLoaded: boolean;
  uploadedDocumentsError: boolean;
  uploadedDocumentType: UploadedDocumentType;
  updateList: boolean;
}

class UploadedDocuments extends Component<Props> {
  getUploadedDocuments = (queryParams: QueryParams) => {
    const { id: appId } = this.props.match.params;
    this.props.getUploadedDocuments(appId, this.props.uploadedDocumentType, queryParams);
  };

  render() {
    const {
      uploadedDocuments,
      uploadedDocumentsLoaded,
      uploadedDocumentsLoading,
      numberOfUploadedDocuments,
      uploadedDocumentsError,
      updateList,
      t,
    } = this.props;

    return (
      <CheckPermission variant="displayIf" permissions={['dashboard_uploaded_documents_read']}>
        <Card>
          <CardBody>
            <List
              data={uploadedDocuments}
              totalElements={numberOfUploadedDocuments}
              loaded={uploadedDocumentsLoaded}
              loading={uploadedDocumentsLoading}
              error={uploadedDocumentsError}
              updateList={updateList}
              fetchData={this.getUploadedDocuments}
              highlightFirstRow
              fields={[
                { key: 'id', label: t('common.id') },
                {
                  key: 'url',
                  label: t('common.url'),
                  render: ({ url }) => (
                    <Button outline style={{border: 'none'}} className="text-primary" onClick={() => {getSignedUrlAndOpenFile(url)}}>
                      {url}
                    </Button>
                  ),
                },
                { key: 'time', label: t('common.createdAt'), dateTime: true },
                {
                  key: 'actuality',
                  label: t('common.actuality'),
                  render: (item, index, activePage) =>
                    index === 0 && activePage === 1 ? t('common.active') : t('common.deprecated'),
                },
              ]}
              noDataLabel={t('common.noImportFilesFound')}
            />
          </CardBody>
        </Card>
      </CheckPermission>
    );
  }
}

const mapStateToProps = (state: any) => ({
  uploadedDocuments: state.uploadedDocuments.data.content,
  numberOfUploadedDocuments: state.uploadedDocuments.data.totalElements,
  uploadedDocumentsLoading: state.uploadedDocuments.loading,
  uploadedDocumentsLoaded: state.uploadedDocuments.loaded,
  uploadedDocumentsError: state.uploadedDocuments.error,
});

const mapDispatchToProps = {
  getUploadedDocuments,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(UploadedDocuments)));
