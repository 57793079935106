import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';

import { getApplication, ApplicationModel } from 'src/redux/actions/applications.action';

import InterviewsHeader from './components/InterviewsHeader';
import InterviewsTable from './components/InterviewsTable';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
}

const Interviews: React.FC<Props> = ({ application, getApplication }) => {
  const { appId } = useParams();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [modalEvent, setModalEvent] = useState<string>('');

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  return (
    <div className="animated fadeIn">
      <InterviewsHeader setModalOpen={setModalOpen} setModalEvent={setModalEvent} />

      <Card>
        <CardBody>
          <InterviewsTable
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            modalEvent={modalEvent}
            setModalEvent={setModalEvent}
          />
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
});

const mapDispatchToProps = {
  getApplication,
};

export default connect(mapStateToProps, mapDispatchToProps)(Interviews);
