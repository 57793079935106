import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';

import { getApplication, ApplicationModel } from '../../../redux/actions/applications.action';

import '../Application.scss';

interface Props extends RouteComponentProps, WithTranslation {
  getApplication: Function;
  application: ApplicationModel;
  index: number;
}

const Application: React.FC<Props> = ({ getApplication, application, index, history }) => {
  const { t } = useTranslation();

  const showSummary = async (applicationId: number) => {
    await getApplication(applicationId);
    history.push(`/application/${applicationId}/summary`);
  };

  return (
    <div className="col-lg-4 col-md-6 col-sm-12">
      <div
        className="app-widget"
        role="button"
        tabIndex={index}
        onClick={() => showSummary(application.id)}
      >
        <div className="header">
          <p>{application.name}</p>
        </div>
        <div className="stats">
          <div className="col-5 p-0">
            <p className="stat-count">{application.overallConversationsCount || 0}</p>
            <p className="stat-text">{t('common.conversations')}</p>
          </div>
          <div className="col-5 p-0">
            <p className="stat-count">{application.overallUsersCount || 0}</p>
            <p className="stat-text">{t('common.visitors')}</p>
          </div>
          <div className="col-5 p-0">
            <p className="stat-count">{application.handoveredCount || 0}</p>
            <p className="stat-text">{t('common.waiting')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  getApplication,
};

export default connect(null, mapDispatchToProps)(withTranslation()(withRouter(Application)));
