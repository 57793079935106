import React, { useEffect, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { ResponsiveBar } from '@nivo/bar';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { getTicketReport } from 'src/redux/services/ticket.service';
import { HelpdeskReportGraphModel, HelpdeskReportModel, TicketFilters } from 'src/shared/models';
import { PageHeader } from 'src/shared/components';
import HelpdeskFilter from './HelpdeskFilter';

import './HelpdeskReport.scss';

interface IHelpdeskReportProps {
  application: ApplicationModel;
  getApplication: Function;
}

const HelpdeskReport: FC<IHelpdeskReportProps> = ({ application, getApplication }) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [filter, setFilter] = useState<TicketFilters>({
    answererEmployeeKey: '',
    questionerEmployeeKey: '',
    from: '',
    to: '',
  });
  const [data, setData] = useState<HelpdeskReportGraphModel>({
    delivered: 0,
    answered: 0,
    average: 0,
    deliveredNum: 0,
    answeredNum: 0,
    total: 0,
  });
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    fetchReport();
    /* eslint-disable-next-line */
  }, [filter]);

  const fetchReport = async () => {
    const chartData: HelpdeskReportModel = await getTicketReport(appId, filter);

    const reportData = {
      delivered: chartData['tickets delivered to answerer'] / chartData['tickets total'],
      answered:
        chartData['tickets answered and delivered to questioner'] /
        (chartData['tickets delivered to answerer'] -
          chartData['tickets delivered but not answered by answerer']),
    };

    const average = (reportData.delivered + reportData.answered) / 2;

    setData({
      ...reportData,
      average,
      deliveredNum: chartData['tickets delivered to answerer'],
      answeredNum: chartData['tickets answered and delivered to questioner'],
      total: chartData['tickets total'],
    });

    setIsLoaded(true);
  };

  const handleDateFilterChange = (filterValues: TicketFilters): void => {
    setFilter(filterValues);
  };

  const generateData = () => {
    if (!isLoaded) {
      return [];
    }

    return [
      {
        title: `${t('helpdesk.delivered')} (${data.deliveredNum})`,
        percent: Math.round(1000 * data.delivered) / 10,
        value: 100 * data.delivered,
      },
      {
        title: `${t('helpdesk.answered')} (${data.answeredNum})`,
        percent: Math.round(1000 * data.answered) / 10,
        value: data.answered,
      },
      {
        title: `${t('helpdesk.average')}`,
        percent: Math.round(1000 * data.average) / 10,
        value: data.average,
      },
    ];
  };

  return (
    <div className="animated fadeIn">
      <PageHeader title={t('helpdesk.report')} />
      <Card className="animated fadeIn mt-0">
        <CardHeader>
          <HelpdeskFilter filterChanged={handleDateFilterChange} filter={filter} />
        </CardHeader>
        <CardBody>
          <div className="chart-container">
            {isLoaded && (
              <ResponsiveBar
                data={generateData()}
                keys={['percent']}
                indexBy="title"
                maxValue={100}
                margin={{ top: 40, right: 60, bottom: 110, left: 60 }}
                padding={0.3}
                colors={{ scheme: 'nivo' }}
                fill={[
                  {
                    match: {
                      id: 'percent',
                    },
                    id: 'lines',
                  },
                ]}
                defs={[
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: `${t('common.percentage')} (%)`,
                  legendPosition: 'middle',
                  legendOffset: -50,
                }}
                labelFormat={v => `${v} %`}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                tooltip={({ indexValue }) => <span>{indexValue}</span>}
                legends={[
                  {
                    dataFrom: 'keys',
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 70,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                animate
                motionStiffness={90}
                motionDamping={15}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
  };
};

const mapDispatchToProps = {
  getApplication,
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpdeskReport);
