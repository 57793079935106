import { Dispatch } from 'redux';
import { EmployeeModel, TagCategoryModel } from 'src/shared/models';
import {
  importEmployeeDataset as importEmployeeDatasetService,
  getTagCategories as getTagCategoriesService,
  createTagCategory as createTagCategoryService,
  updateTagCategory as updateTagCategoryService,
  deleteTagCategory as deleteTagCategoryService,
  getCategoryTags as getCategoryTagsService,
  createTag as createTagService,
  updateTag as updateTagService,
  deleteTag as deleteTagService,
  getEmployees as getEmployeesService,
  getEmployee as getEmployeeService,
  createEmployee as createEmployeeService,
  updateEmployee as updateEmployeeService,
  deleteEmployee as deleteEmployeeService,
  addTagToEmployee as addTagToEmployeeService,
  removeEmployeeTag as removeEmployeeTagService,
} from '../services/employee.service';

export const IMPORT_EMPLOYEE_DATASET_REQUEST = 'employee/IMPORT_EMPLOYEE_DATASET_REQUEST';
export const IMPORT_EMPLOYEE_DATASET_SUCCESS = 'employee/IMPORT_EMPLOYEE_DATASET_SUCCESS';
export const IMPORT_EMPLOYEE_DATASET_ERROR = 'employee/IMPORT_EMPLOYEE_DATASET_ERROR';

export const GET_TAG_CATEGORIES_REQUEST = 'employee/GET_TAG_CATEGORIES_REQUEST';
export const GET_TAG_CATEGORIES_SUCCESS = 'employee/GET_TAG_CATEGORIES_SUCCESS';
export const GET_TAG_CATEGORIES_ERROR = 'employee/GET_TAG_CATEGORIES_ERROR';

export const CREATE_TAG_CATEGORY_REQUEST = 'employee/CREATE_TAG_CATEGORY_REQUEST';
export const CREATE_TAG_CATEGORY_SUCCESS = 'employee/CREATE_TAG_CATEGORY_SUCCESS';
export const CREATE_TAG_CATEGORY_ERROR = 'employee/CREATE_TAG_CATEGORY_ERROR';

export const UPDATE_TAG_CATEGORY_REQUEST = 'employee/UPDATE_TAG_CATEGORY_REQUEST';
export const UPDATE_TAG_CATEGORY_SUCCESS = 'employee/UPDATE_TAG_CATEGORY_SUCCESS';
export const UPDATE_TAG_CATEGORY_ERROR = 'employee/UPDATE_TAG_CATEGORY_ERROR';

export const DELETE_TAG_CATEGORY_REQUEST = 'employee/DELETE_TAG_CATEGORY_REQUEST';
export const DELETE_TAG_CATEGORY_SUCCESS = 'employee/DELETE_TAG_CATEGORY_SUCCESS';
export const DELETE_TAG_CATEGORY_ERROR = 'employee/DELETE_TAG_CATEGORY_ERROR';

export const GET_CATEGORY_TAGS_REQUEST = 'employee/GET_CATEGORY_TAGS_REQUEST';
export const GET_CATEGORY_TAGS_SUCCESS = 'employee/GET_CATEGORY_TAGS_SUCCESS';
export const GET_CATEGORY_TAGS_ERROR = 'employee/GET_CATEGORY_TAGS_ERROR';

export const CREATE_TAG_REQUEST = 'employee/CREATE_TAG_REQUEST';
export const CREATE_TAG_SUCCESS = 'employee/CREATE_TAG_SUCCESS';
export const CREATE_TAG_ERROR = 'employee/CREATE_TAG_ERROR';

export const UPDATE_TAG_REQUEST = 'employee/UPDATE_TAG_REQUEST';
export const UPDATE_TAG_SUCCESS = 'employee/UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_ERROR = 'employee/UPDATE_TAG_ERROR';

export const DELETE_TAG_REQUEST = 'employee/DELETE_TAG_REQUEST';
export const DELETE_TAG_SUCCESS = 'employee/DELETE_TAG_SUCCESS';
export const DELETE_TAG_ERROR = 'employee/DELETE_TAG_ERROR';

export const GET_EMPLOYEE_REQUEST = 'employee/GET_EMPLOYEE_REQUEST';
export const GET_EMPLOYEE_SUCCESS = 'employee/GET_EMPLOYEE_SUCCESS';
export const GET_EMPLOYEE_ERROR = 'employee/GET_EMPLOYEE_ERROR';

export const GET_EMPLOYEES_REQUEST = 'employee/GET_EMPLOYEES_REQUEST';
export const GET_EMPLOYEES_SUCCESS = 'employee/GET_EMPLOYEES_SUCCESS';
export const GET_EMPLOYEES_ERROR = 'employee/GET_EMPLOYEES_ERROR';

export const CREATE_EMPLOYEE_REQUEST = 'employee/CREATE_EMPLOYEE_REQUEST';
export const CREATE_EMPLOYEE_SUCCESS = 'employee/CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_ERROR = 'employee/CREATE_EMPLOYEE_ERROR';

export const UPDATE_EMPLOYEE_REQUEST = 'employee/UPDATE_EMPLOYEE_REQUEST';
export const UPDATE_EMPLOYEE_SUCCESS = 'employee/UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_ERROR = 'employee/UPDATE_EMPLOYEE_ERROR';

export const DELETE_EMPLOYEE_REQUEST = 'employee/DELETE_EMPLOYEE_REQUEST';
export const DELETE_EMPLOYEE_SUCCESS = 'employee/DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_ERROR = 'employee/DELETE_EMPLOYEE_ERROR';

export const ADD_TAG_TO_EMPLOYEE_REQUEST = 'employee/ADD_TAG_TO_EMPLOYEE_REQUEST';
export const ADD_TAG_TO_EMPLOYEE_SUCCESS = 'employee/ADD_TAG_TO_EMPLOYEE_SUCCESS';
export const ADD_TAG_TO_EMPLOYEE_ERROR = 'employee/ADD_TAG_TO_EMPLOYEE_ERROR';

export const REMOVE_EMPLOYEE_TAG_REQUEST = 'employee/REMOVE_EMPLOYEE_TAG_REQUEST';
export const REMOVE_EMPLOYEE_TAG_SUCCESS = 'employee/REMOVE_EMPLOYEE_TAG_SUCCESS';
export const REMOVE_EMPLOYEE_TAG_ERROR = 'employee/REMOVE_EMPLOYEE_TAG_ERROR';

export const importEmployeeDataset = (
  applicationId: string,
  url: string,
  importConfigurationId: string,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: IMPORT_EMPLOYEE_DATASET_REQUEST,
    });

    try {
      dispatch({
        type: IMPORT_EMPLOYEE_DATASET_SUCCESS,
        payload: await importEmployeeDatasetService(applicationId, url, importConfigurationId),
      });
    } catch (error) {
      dispatch({
        type: IMPORT_EMPLOYEE_DATASET_ERROR,
        error,
      });
    }
  };
};

export const getTagCategories = (applicationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TAG_CATEGORIES_REQUEST,
    });

    try {
      dispatch({
        type: GET_TAG_CATEGORIES_SUCCESS,
        payload: await getTagCategoriesService(applicationId),
      });
    } catch (error) {
      dispatch({
        type: GET_TAG_CATEGORIES_ERROR,
        error,
      });
    }
  };
};

export const createTagCategory = (applicationId: string, data: TagCategoryModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_TAG_CATEGORY_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_TAG_CATEGORY_SUCCESS,
        payload: await createTagCategoryService(applicationId, data),
      });
    } catch (error) {
      dispatch({
        type: CREATE_TAG_CATEGORY_ERROR,
        error,
      });
    }
  };
};

export const updateTagCategory = (
  applicationId: string,
  categoryId: string,
  data: TagCategoryModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_TAG_CATEGORY_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_TAG_CATEGORY_SUCCESS,
        payload: await updateTagCategoryService(applicationId, categoryId, data),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_TAG_CATEGORY_ERROR,
        error,
      });
    }
  };
};

export const deleteTagCategory = (applicationId: string, categoryId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_TAG_CATEGORY_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_TAG_CATEGORY_SUCCESS,
        payload: await deleteTagCategoryService(applicationId, categoryId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_TAG_CATEGORY_ERROR,
        error,
      });
    }
  };
};

export const getCategoryTags = (applicationId: string, categoryId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_CATEGORY_TAGS_REQUEST,
    });

    try {
      dispatch({
        type: GET_CATEGORY_TAGS_SUCCESS,
        payload: await getCategoryTagsService(applicationId, categoryId),
      });
    } catch (error) {
      dispatch({
        type: GET_CATEGORY_TAGS_ERROR,
        error,
      });
    }
  };
};

export const createTag = (applicationId: string, categoryId: string, name: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_TAG_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_TAG_SUCCESS,
        payload: await createTagService(applicationId, categoryId, name),
      });
    } catch (error) {
      dispatch({
        type: CREATE_TAG_ERROR,
        error,
      });
    }
  };
};

export const updateTag = (
  applicationId: string,
  categoryId: string,
  tagId: string,
  name: string,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_TAG_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_TAG_SUCCESS,
        payload: await updateTagService(applicationId, categoryId, tagId, name),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_TAG_ERROR,
        error,
      });
    }
  };
};

export const deleteTag = (applicationId: string, categoryId: string, tagId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_TAG_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_TAG_SUCCESS,
        payload: await deleteTagService(applicationId, categoryId, tagId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_TAG_ERROR,
        error,
      });
    }
  };
};

export const getEmployees = (applicationId: string, queryParams: object) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_EMPLOYEES_REQUEST,
    });

    try {
      dispatch({
        type: GET_EMPLOYEES_SUCCESS,
        payload: await getEmployeesService(applicationId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_EMPLOYEES_ERROR,
        error,
      });
    }
  };
};

export const getEmployee = (applicationId: string, employeeId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_EMPLOYEE_REQUEST,
    });

    try {
      dispatch({
        type: GET_EMPLOYEE_SUCCESS,
        payload: await getEmployeeService(applicationId, employeeId),
      });
    } catch (error) {
      dispatch({
        type: GET_EMPLOYEE_ERROR,
        error,
      });
    }
  };
};

export const createEmployee = (applicationId: string, employeeData: EmployeeModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_EMPLOYEE_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_EMPLOYEE_SUCCESS,
        payload: await createEmployeeService(applicationId, employeeData),
      });
    } catch (error) {
      dispatch({
        type: CREATE_EMPLOYEE_ERROR,
        error,
      });
    }
  };
};

export const updateEmployee = (
  applicationId: string,
  employeeId: string,
  employeeData: EmployeeModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EMPLOYEE_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_EMPLOYEE_SUCCESS,
        payload: await updateEmployeeService(applicationId, employeeId, employeeData),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_EMPLOYEE_ERROR,
        error,
      });
    }
  };
};

export const deleteEmployee = (applicationId: string, employeeId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_EMPLOYEE_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_EMPLOYEE_SUCCESS,
        payload: await deleteEmployeeService(applicationId, employeeId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_EMPLOYEE_ERROR,
        error,
      });
    }
  };
};

export const addTagToEmployee = (applicationId: string, employeeId: string, tagIds: string[]) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ADD_TAG_TO_EMPLOYEE_REQUEST,
    });

    try {
      dispatch({
        type: ADD_TAG_TO_EMPLOYEE_SUCCESS,
        payload: await addTagToEmployeeService(applicationId, employeeId, tagIds),
      });
    } catch (error) {
      dispatch({
        type: ADD_TAG_TO_EMPLOYEE_ERROR,
        error,
      });
    }
  };
};

export const removeEmployeeTag = (applicationId: string, employeeId: string, tagId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REMOVE_EMPLOYEE_TAG_REQUEST,
    });

    try {
      dispatch({
        type: REMOVE_EMPLOYEE_TAG_SUCCESS,
        payload: await removeEmployeeTagService(applicationId, employeeId, tagId),
      });
    } catch (error) {
      dispatch({
        type: REMOVE_EMPLOYEE_TAG_ERROR,
        error,
      });
    }
  };
};
