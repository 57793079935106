import { get, post, patch, del } from '../../shared/services/http.service';
import { GeneralQueryParams } from '../../shared/models/query-params.model';

export const getPositionCriteria = async (applicationId: string, criteriaId: number) => {
  return get(`/recruiter/applications/${applicationId}/shortlist/criterias/${criteriaId}`);
};

export const getPositionCriterias = async (
  applicationId: string,
  queryParams: GeneralQueryParams,
) => {
  return get(`/recruiter/applications/${applicationId}/shortlist/criterias`, {
    params: queryParams,
  });
};

export const createPositionCriteria = async (applicationId: string, name: string) => {
  return post(`/recruiter/applications/${applicationId}/shortlist/criterias`, { data: { name } });
};

export const updatePositionCriteria = async (
  applicationId: string,
  criteriaId: number,
  name: string,
) => {
  return patch(`/recruiter/applications/${applicationId}/shortlist/criterias/${criteriaId}`, {
    data: { name },
  });
};

export const deletePositionCriteria = async (applicationId: string, criteriaId: number) => {
  return del(`/recruiter/applications/${applicationId}/shortlist/criterias/${criteriaId}`);
};
