import { get, post } from '../../shared/services/http.service';
import { PageQueryParams } from 'src/shared/models';
import { CriteriaImportImportModel } from 'src/shared/models/criteria-import.model';

const baseUrl = (applicationId: string) => `/applications/${applicationId}/import`;

export const getCriteriaImports = async (applicationId: string, params: PageQueryParams) => {
  return get(`${baseUrl(applicationId)}/imports?type=IMPORT_SHORTLIST`, { params });
};

export const createCriteriaImport = async (
  applicationId: string,
  data: CriteriaImportImportModel,
) => {
  if (typeof data.configId === 'object') {
    // eslint-disable-next-line no-param-reassign
    data.configId = parseInt(data.configId.value, 0);
  }
  return post(`/recruiter/applications/${applicationId}/shortlist/criterias/import/xlsx`, { data });
};
