import React, { Fragment, useState } from 'react';
import { Row, Col } from 'reactstrap';

import { TagCategoryModel } from 'src/shared/models';
import { CheckPermission, TagCategoryDetails, TagCategories } from 'src/shared/components';

const Tags = () => {
  const [selectedTagCategory, selectTagCategory] = useState<TagCategoryModel | undefined>(
    undefined,
  );

  return (
    <Fragment>
      <Row>
        <Col sm="6">
          <CheckPermission variant="displayIf" permissions={['employee_categories_read']}>
            <TagCategories
              selectedTagCategory={selectedTagCategory}
              selectTagCategory={selectTagCategory}
            />
          </CheckPermission>
        </Col>

        <Col sm="6">
          <CheckPermission variant="displayIf" permissions={['employee_tags_read']}>
            <TagCategoryDetails selectedTagCategory={selectedTagCategory} />
          </CheckPermission>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Tags;
