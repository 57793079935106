import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, FormGroup, Label } from 'reactstrap';
import { FormikValues, Formik, Form, FormikHelpers } from 'formik';

import { exportRecruiterDataset } from 'src/redux/services/recruiter-import.service';
import { uploadFile as uploadFileService } from '../../shared/services/upload.service';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { importRecruiterDataset } from 'src/redux/actions/recruiter-import.action';
import { getSignatureForUpload } from 'src/redux/actions/upload.action';

import saveFile from 'src/shared/utils/file-saver.util';
import { getSelectOptionObjects } from 'src/shared/utils/misc.util';
import addToast from 'src/shared/utils/notification.util';
import { parseFileExtension } from 'src/shared/utils';

import FormCard from './components/FormCard';
import ConfigurationSelect from './components/ConfigurationSelect';
import File from 'src/shared/components/form-inputs/File';
import { CheckPermission, ExportButton, PageHeader } from 'src/shared/components';

import { importSchema } from 'src/shared/schemas/validation.schema';
import { fileNames } from 'src/shared/settings';

interface ImportModel {
  configuration: {
    labelCategories: ImportOptions | null;
    criterias: ImportOptions | null;
    positions: ImportOptions | null;
    interviews: ImportOptions | null;
    messages: ImportOptions | null;
  };
  data?: File;
}

enum ImportOptions {
  SKIP = 'SKIP',
  REPLACE = 'REPLACE',
  MERGE = 'MERGE',
}

interface Props {
  getApplication: Function;
  application: ApplicationModel;
  importRecruiterDataset: Function;
  getSignatureForUpload: Function;
}

const ImportExport: FC<Props> = ({
  application,
  getApplication,
  importRecruiterDataset,
  getSignatureForUpload,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [isExportLoading, setIsExportLoading] = useState<boolean>();

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  const handleExport = async () => {
    setIsExportLoading(true);
    const res = await exportRecruiterDataset(appId.toString(), {
      size: 30,
      page: 1,
    });

    saveFile(JSON.stringify(res), fileNames.RECURTIER_DATA_EXPORT, 'json');
    setIsExportLoading(false);
  };

  const handleImport = async (values: FormikValues, props: FormikHelpers<ImportModel>) => {
    const { id } = application;
    try {
      const file = values.data as File;
      const ext = parseFileExtension(file.name);
      if (ext) {
        const response = await getSignatureForUpload(ext, 'PUT');
        await uploadFileService(file, response);

        await importRecruiterDataset(id, {
          configuration: {
            labelCategories: values.configuration.labelCategories.value,
            criterias: values.configuration.criterias.value,
            positions: values.configuration.positions.value,
            interviews: values.configuration.interviews.value,
            messages: values.configuration.messages.value,
          },
          filename: response.downloadUrl,
        });
        addToast('success', `${t('importExport.importSucceed')}`);
      }
    } finally {
      props.setSubmitting(false);
    }
  };

  const configuratonOptions = getSelectOptionObjects(
    [
      { value: ImportOptions.SKIP, label: t('importExport.skip') },
      { value: ImportOptions.REPLACE, label: t('importExport.replace') },
      { value: ImportOptions.MERGE, label: t('importExport.merge') },
    ],
    'label',
    'value',
  );

  return (
    <Row className="animated fadeIn">
      <Col>
        <PageHeader title={t('common.importExport')} />

        <FormCard title={t('importExport.export')}>
          <ExportButton
            onClick={handleExport}
            disabled={isExportLoading}
            exportTooltipLabel={t('common.exportToJson')}
          />
        </FormCard>

        <CheckPermission variant="displayIf" permissions={['migration_import_create']}>
          <FormCard title={t('importExport.import')}>
            <Formik
              initialValues={
                {
                  configuration: {
                    labelCategories: null,
                    criterias: null,
                    positions: null,
                    interviews: null,
                    messages: null,
                  },
                } as ImportModel
              }
              validationSchema={importSchema}
              onSubmit={handleImport}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form>
                  <Row>
                    <ConfigurationSelect
                      name="labelCategories"
                      configuratonOptions={configuratonOptions}
                    />
                    <ConfigurationSelect
                      name="criterias"
                      configuratonOptions={configuratonOptions}
                    />
                    <ConfigurationSelect
                      name="positions"
                      configuratonOptions={configuratonOptions}
                    />
                    <ConfigurationSelect
                      name="interviews"
                      configuratonOptions={configuratonOptions}
                    />
                    <ConfigurationSelect
                      name="messages"
                      configuratonOptions={configuratonOptions}
                    />

                    <Col md="12">
                      <FormGroup>
                        <Label for="data">{t('importExport.jsonData')}</Label>
                        <File name="data" setFieldValue={setFieldValue} />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Button type="submit" disabled={isSubmitting}>
                    {t('dataset.import')}
                  </Button>
                </Form>
              )}
            </Formik>
          </FormCard>
        </CheckPermission>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
});

const mapDispatchToProps = {
  getApplication,
  importRecruiterDataset,
  getSignatureForUpload,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportExport);
