import { Dispatch } from 'redux';

import { getSurveyTranslations as getSurveyTranslationsService } from 'src/redux/services/content-translation-survey.service';
import { getEventTranslations as getEventTranslationsService } from 'src/redux/services/content-translation-event.service';

export const GET_SURVEY_TRANSLATIONS_REQUEST = 'translations/GET_SURVEY_TRANSLATIONS_REQUEST';
export const GET_SURVEY_TRANSLATIONS_SUCCESS = 'translations/GET_SURVEY_TRANSLATIONS_SUCCESS';
export const GET_SURVEY_TRANSLATIONS_ERROR = 'translations/GET_SURVEY_TRANSLATIONS_ERROR';

export const GET_EVENT_TRANSLATIONS_REQUEST = 'translations/GET_EVENT_TRANSLATIONS_REQUEST';
export const GET_EVENT_TRANSLATIONS_SUCCESS = 'translations/GET_EVENT_TRANSLATIONS_SUCCESS';
export const GET_EVENT_TRANSLATIONS_ERROR = 'translations/GET_EVENT_TRANSLATIONS_ERROR';

export const CLEAR_TRANSLATIONS = 'translations/CLEAR_TRANSLATIONS';

export const getSurveyTranslations = (appId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_SURVEY_TRANSLATIONS_REQUEST,
    });

    try {
      dispatch({
        type: GET_SURVEY_TRANSLATIONS_SUCCESS,
        payload: await getSurveyTranslationsService(appId),
      });
    } catch (error) {
      dispatch({
        type: GET_SURVEY_TRANSLATIONS_ERROR,
        error,
      });
    }
  };
};

export const getEventTranslations = (appId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_EVENT_TRANSLATIONS_REQUEST,
    });
    try {
      dispatch({
        type: GET_EVENT_TRANSLATIONS_SUCCESS,
        payload: await getEventTranslationsService(appId),
      });
    } catch (error) {
      dispatch({
        type: GET_EVENT_TRANSLATIONS_ERROR,
        error,
      });
    }
  };
};

export const clearTranslations = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_TRANSLATIONS,
    });
  };
};
