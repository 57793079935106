import { del, get, patch, post } from '../../shared/services/http.service';

import { PageQueryParams } from 'src/shared/models';
import {
  EventDateModel,
  EventModel,
  EventQuestionModel,
  GetReportResponse,
  GetReportsResponse,
} from 'src/shared/models/event.model';

const baseUrl = (applicationId: string) => `/applications/${applicationId}/event/events`;

export const getEvents = async (applicationId: string, params: PageQueryParams) => {
  return get(baseUrl(applicationId), { params });
};

export const getEvent = async (applicationId: string, eventId: string) => {
  return get(`${baseUrl(applicationId)}/${eventId}`);
};

export const createEvent = async (applicationId: string, data: EventModel) => {
  return post(baseUrl(applicationId), { data });
};

export const updateEvent = async (applicationId: string, eventId: string, data: EventModel) => {
  return patch(`${baseUrl(applicationId)}/${eventId}`, { data });
};

export const deleteEvent = async (applicationId: string, eventId: string) => {
  return del(`${baseUrl(applicationId)}/${eventId}`);
};

//Event dates CRUD START
export const createEventDate = async (
  applicationId: string,
  eventId: string,
  data: EventDateModel,
) => {
  return post(`${baseUrl(applicationId)}/${eventId}/dates`, { data });
};

export const updateEventDate = async (
  applicationId: string,
  eventId: number,
  dateId: number,
  data: EventDateModel,
) => {
  return patch(`${baseUrl(applicationId)}/${eventId}/dates/${dateId}`, { data });
};

export const deleteEventDate = async (applicationId: string, eventId: number, dateId: number) => {
  return del(`${baseUrl(applicationId)}/${eventId}/dates/${dateId}`);
};
//Event dates CRUD END

//Event QuestionsAndAnswers START
export const createEventQuestion = async (
  applicationId: string,
  eventId: string,
  data: EventQuestionModel,
) => {
  return post(`${baseUrl(applicationId)}/${eventId}/questions`, { data });
};

export const updateEventQuestion = async (
  applicationId: string,
  eventId: string,
  questionId: number,
  data: EventQuestionModel,
) => {
  return patch(`${baseUrl(applicationId)}/${eventId}/questions/${questionId}`, { data });
};

export const deleteEventQuestion = async (
  applicationId: string,
  eventId: string,
  questionId: number,
) => {
  return del(`${baseUrl(applicationId)}/${eventId}/questions/${questionId}`);
};

export const sortEventQuestions = async (
  applicationId: string,
  eventId: string,
  data: number[],
) => {
  return patch(`${baseUrl(applicationId)}/${eventId}/questions/sort`, { data });
};
//Event QuestionsAndAnswers END

//Event reports START
export const getReport = async (
  applicationId: string,
  eventId: string,
  params: PageQueryParams,
): Promise<GetReportResponse> => {
  return get(`${baseUrl(applicationId)}/${eventId}/report`, { params });
};

export const getReports = async (
  applicationId: string,
  eventId: string,
  params: PageQueryParams,
): Promise<GetReportsResponse> => {
  return get(`${baseUrl(applicationId)}/${eventId}/reports`, { params });
};

export const exportReport = async (applicationId: string, eventId: string) => {
  return get(`${baseUrl(applicationId)}/${eventId}/reports/export/xlsx`, {
    requestConfig: { responseType: 'blob' },
  });
};
//Event reports END
//Event tagging START
export const addTagToEvent = async (applicationId: string, eventId: string, tagIds: string[]) => {
  let query: any = tagIds.map(tagId =>
    post(`/hrops/applications/${applicationId}/tag/events/${eventId}/tags/${tagId}`),
  );
  return Promise.all(query);
  // TODO: replace after batch api works
  //return post(`/hrops/applications/${applicationId}/survey/surveys/${surveyId}/tags/assign`, { data: tagIds });
};

export const removeEventTag = async (applicationId: string, eventId: string, tagId: string) => {
  return del(`/hrops/applications/${applicationId}/tag/events/${eventId}/tags/${tagId}`);
};
//Event tagging END
