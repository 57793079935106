import { Dispatch } from 'redux';
import {
  getApplications as getApplicationsService,
  getApplication as getApplicationService,
} from '../services/applications.service';

export const GET_APPLICATIONS_REQUEST = 'applications/GET_APPLICATIONS_REQUEST';
export const GET_APPLICATIONS_SUCCESS = 'applications/GET_APPLICATIONS_SUCCESS';
export const GET_APPLICATIONS_ERROR = 'applications/GET_APPLICATIONS_ERROR';
export const GET_APPLICATION_REQUEST = 'application/GET_APPLICATION_REQUEST';
export const GET_APPLICATION_SUCCESS = 'application/GET_APPLICATION_SUCCESS';
export const GET_APPLICATION_ERROR = 'application/GET_APPLICATION_ERROR';
export const CLEAR_APPLICATION = 'application/CLEAR_APPLICATION';

export interface ApplicationModel {
  id: number;
  name: string;
  description: string;
  tenantId: number;
  createdAt: string;
  deletedAt: string | null;
  updatedAt: string | null;
  facebookPageId: string;
  facebookConversationsTrend: object;
  facebookVisitorsTrend: object;
  hangoutsChatConversationsTrend: object;
  hangoutsChatVisitorsTrend: object;
  livechatConversationsTrend: object;
  livechatChatVisitorsTrend: object;
  viberConversationsTrend: object;
  viberChatVisitorsTrend: object;
  handoveredCount: number;
  overallConversationsTrend: object;
  overallVisitorsTrend: object;
  overallConversationsCount: number;
  overallUsersCount: number;
  dashboardConfiguration: string;
}

export const getApplications = (lastLoginAt: Date = new Date()) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_APPLICATIONS_REQUEST,
    });

    const applications: ApplicationModel[] = await getApplicationsService(lastLoginAt);

    dispatch({
      type: GET_APPLICATIONS_SUCCESS,
      payload: applications,
    });
  };
};

export const getApplication = (applicationId: number, lastLoginAt?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_APPLICATION_REQUEST,
    });

    try {
      const application = await getApplicationService(applicationId, lastLoginAt);

      dispatch({
        type: GET_APPLICATION_SUCCESS,
        payload: application,
      });
    } catch (error) {
      dispatch({
        type: GET_APPLICATION_ERROR,
        error,
      });

      // dispatch(
      //   addAlert({
      //     type: 'danger',
      //     message: 'Getting application has been failed.',
      //   })
      // );

      throw error;
    }
  };
};

export const clearApplication = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_APPLICATION,
    });
  };
};
