import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Row,
} from 'reactstrap';
import { Formik, Form, FormikValues, Field } from 'formik';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import isEqual from 'lodash/isEqual';

import { getSelectOptionObjects } from 'src/shared/utils/misc.util';

import { SelectModel } from 'src/shared/models/common.model';
import { InterviewModel } from 'src/shared/models/interviews.model';
import { PositionModel } from 'src/shared/models/positions.model';

import { interviewSchema } from 'src/shared/schemas/validation.schema';

import TextField from 'src/shared/components/form-inputs/TextField';
import TextArea from 'src/shared/components/form-inputs/TextArea';
import ReactSelect from 'src/shared/components/form-inputs/ReactSelect';
import SingleDate from 'src/shared/components/date-pickers/SingleDate';
import Spinner from 'src/shared/components/Spinner';

import styles from '../Interviews.module.scss';

const MIN_LIMIT = 1;
const MAX_LIMIT = 40;

interface Props {
  isOpen: boolean;
  toggleModal: () => void;
  confirm: (values: FormikValues) => Promise<void>;
  modalEvent: string;
  selectedInterview: InterviewModel | undefined;
  positions: PositionModel[];
}

const InterviewsModal: React.FC<Props> = ({
  isOpen,
  toggleModal,
  confirm,
  modalEvent,
  selectedInterview,
  positions,
}) => {
  const { t } = useTranslation();

  const recurrenceFrequencies: SelectModel[] = [
    {
      label: t('common.daily'),
      value: 'DAILY',
    },
    {
      label: t('common.weekly'),
      value: 'WEEKLY',
    },
    {
      label: t('common.biweekly'),
      value: 'BIWEEKLY',
    },
    {
      label: t('common.monthly'),
      value: 'MONTHLY',
    },
  ];

  const isEditing = modalEvent === 'edit';
  const title = isEditing ? 'interviews.editInterview' : 'interviews.createInterview';
  const submitButtonText = isEditing ? 'common.edit' : 'common.create';

  const getLimitOptions = () => {
    const options: SelectModel[] = [];

    for (let i = MIN_LIMIT; i <= MAX_LIMIT; i += 1) {
      options.push({ value: i, label: i.toString() });
    }

    return options;
  };

  const limitOptions = useMemo(() => getLimitOptions(), []);
  const positionOptions = useMemo(() => getSelectOptionObjects(positions || [], 'name', 'id'), [
    positions,
  ]);

  const getInitialLimitValue = () => {
    if (!limitOptions?.length) return undefined;

    if (selectedInterview?.limit) {
      return limitOptions.find(o => o.value === selectedInterview.limit);
    }

    return limitOptions[limitOptions.length - 1];
  };

  const getInitialFormValues = () => ({
    name: selectedInterview?.name || '',
    description: selectedInterview?.description || '',
    limit: getInitialLimitValue(),
    positions: selectedInterview?.positions?.length
      ? getSelectOptionObjects(selectedInterview?.positions, 'name', 'id')
      : [],
    startDate: selectedInterview?.startTime ? moment(selectedInterview?.startTime) : moment(),
    startTime: selectedInterview?.startTime
      ? moment(selectedInterview?.startTime)
      : moment().add(1, 'hour').startOf('hour'),
    endDate: selectedInterview?.endTime ? moment(selectedInterview?.endTime) : moment(),
    endTime: selectedInterview?.endTime
      ? moment(selectedInterview?.endTime)
      : moment().add(2, 'hour').startOf('hour'),
    recurrenceFrequency: recurrenceFrequencies[1],
    recurrenceUntilDate: moment().add(1, 'month'),
    recurrenceUntilTime: moment().add(1, 'month').add(1, 'hour').startOf('hour'),
    applicationDeadlineDate: selectedInterview?.applicationDeadline
      ? moment(selectedInterview?.applicationDeadline)
      : moment().add(-1, 'day'),
    applicationDeadlineTime: selectedInterview?.applicationDeadline
      ? moment(selectedInterview?.applicationDeadline)
      : moment().add(1, 'hour').startOf('hour'),
  });

  const initialFormValues = getInitialFormValues();

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>{t(title)}</ModalHeader>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialFormValues}
        validationSchema={interviewSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (isEqual(values, initialFormValues)) {
            toggleModal();
            return;
          }
          await confirm(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, isSubmitting, setFieldValue }) => (
          <Form>
            <ModalBody>
              <FormGroup>
                <Label for="name">{t('common.name')}</Label>
                <TextField name="name" />
              </FormGroup>

              <FormGroup>
                <Label for="description">{t('common.description')}</Label>
                <TextArea name="description" rows="5" />
              </FormGroup>

              <FormGroup>
                <Label for="positions">{t('common.positions')}</Label>
                <ReactSelect name="positions" isMulti isClearable options={positionOptions} />
                {errors.positions && <div className="input-error-message">{errors.positions}</div>}
              </FormGroup>

              <FormGroup>
                <Label for="limit">{t('common.limit')}</Label>
                <ReactSelect name="limit" options={limitOptions} />
              </FormGroup>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="startDate">{t('common.startTime')}</Label>
                    <Field component={SingleDate} id="startDate" name="startDate" />
                    <TimePicker
                      id="startTime"
                      name="startTime"
                      className={styles.timePicker}
                      popupClassName={styles.timePickerPopup}
                      allowEmpty={false}
                      defaultValue={values.startTime}
                      placement="topLeft"
                      showSecond={false}
                      onChange={(time: moment.Moment) => {
                        setFieldValue('startTime', time);
                      }}
                    />
                    {(errors.startDate || errors.startTime) && (
                      <div className="input-error-message">
                        {errors.startDate || errors.startTime}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col>
                  <FormGroup>
                    <Label for="endDate">{t('common.endTime')}</Label>
                    <Field component={SingleDate} id="endDate" name="endDate" />
                    <TimePicker
                      id="endTime"
                      name="endTime"
                      className={styles.timePicker}
                      popupClassName={styles.timePickerPopup}
                      allowEmpty={false}
                      defaultValue={values.endTime}
                      placement="topLeft"
                      showSecond={false}
                      onChange={(time: moment.Moment) => {
                        setFieldValue('endTime', time);
                      }}
                    />
                    {(errors.endDate || errors.endTime) && (
                      <div className="input-error-message">{errors.endDate || errors.endTime}</div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="applicationDeadlineDate">{t('common.applicationDeadline')}</Label>
                    <Field
                      component={SingleDate}
                      id="applicationDeadlineDate"
                      name="applicationDeadlineDate"
                    />
                    <TimePicker
                      id="applicationDeadlineTime"
                      name="applicationDeadlineTime"
                      className={styles.timePicker}
                      popupClassName={styles.timePickerPopup}
                      allowEmpty={false}
                      defaultValue={values.applicationDeadlineTime}
                      placement="topLeft"
                      showSecond={false}
                      onChange={(time: moment.Moment) => {
                        setFieldValue('applicationDeadlineTime', time);
                      }}
                    />
                    {(errors.applicationDeadlineDate || errors.applicationDeadlineTime) && (
                      <div className="input-error-message">
                        {errors.applicationDeadlineDate || errors.applicationDeadlineTime}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              {!isEditing && (
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label for="recurrenceFrequency">{t('common.recurrenceFrequency')}</Label>
                      <ReactSelect name="recurrenceFrequency" options={recurrenceFrequencies} />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label for="recurrenceUntilDate">{t('common.recurrenceUntil')}</Label>
                      <Field
                        component={SingleDate}
                        id="recurrenceUntilDate"
                        name="recurrenceUntilDate"
                      />
                      <TimePicker
                        id="recurrenceUntilTime"
                        name="recurrenceUntilTime"
                        className={styles.timePicker}
                        popupClassName={styles.timePickerPopup}
                        allowEmpty={false}
                        defaultValue={values.recurrenceUntilTime}
                        placement="topLeft"
                        showSecond={false}
                        onChange={(time: moment.Moment) => {
                          setFieldValue('recurrenceUntilTime', time);
                        }}
                      />
                      {(errors.recurrenceUntilDate || errors.recurrenceUntilTime) && (
                        <div className="input-error-message">
                          {errors.recurrenceUntilDate || errors.recurrenceUntilTime}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </ModalBody>

            <ModalFooter>
              <Spinner loading={isSubmitting} />

              <Button type="submit" color="primary" disabled={isSubmitting}>
                {t(submitButtonText)}
              </Button>

              <Button color="secondary" onClick={toggleModal}>
                {t('common.cancel')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default InterviewsModal;
