import React, { useEffect, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { EditableTextInput, List } from 'src/shared/components';
import { HelpdeskInstantAnswerModel, Operations, QueryParams } from 'src/shared/models';
import {
  deleteHelpdeskInstantAnswer,
  getHelpdeskInstantAnswers,
} from 'src/redux/actions/helpdesk-instant-answers.action';
import { updateHelpdeskInstantAnswer } from 'src/redux/services/helpdesk-instant-answers.action.service';
import HelpdeskInstantAnswerModalForm from './forms/HelpdeskInstantAnswerModalForm';

interface IHelpdeskInstantAnswersProps {
  application: ApplicationModel;
  getApplication: Function;
  getHelpdeskInstantAnswers: Function;
  deleteHelpdeskInstantAnswer: Function;
  helpdeskInstantAnswers: HelpdeskInstantAnswerModel[];
  numberOfHelpdeskInstantAnswers: number;
  helpdeskInstantAnswersLoading: boolean;
  helpdeskInstantAnswersLoaded: boolean;
  helpdeskInstantAnswersError: boolean;
}

const HelpdeskInstantAnswers: FC<IHelpdeskInstantAnswersProps> = ({
  application,
  getApplication,
  getHelpdeskInstantAnswers,
  deleteHelpdeskInstantAnswer,
  helpdeskInstantAnswers,
  numberOfHelpdeskInstantAnswers,
  helpdeskInstantAnswersLoading,
  helpdeskInstantAnswersLoaded,
  helpdeskInstantAnswersError,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [queryParamsDef, setQueryParamsDef] = useState<QueryParams>({
    page: 0,
    size: 20,
    limit: 20,
  });

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }
    /* eslint-disable-next-line */
  }, []);

  const refreshHelpdeskInstantAnswers = () => {
    getHelpdeskInstantAnswers(appId, queryParamsDef);
  };

  const fetchHelpdeskInstantAnswers = (queryParams: QueryParams) => {
    setQueryParamsDef(queryParams);
    getHelpdeskInstantAnswers(appId, queryParams);
  };

  const removeHelpdeskInstantAnswer = async (group?: HelpdeskInstantAnswerModel) => {
    if (!group) {
      return;
    }
    await deleteHelpdeskInstantAnswer(appId, group.id);
  };

  const updateAnswer = async (answer: HelpdeskInstantAnswerModel) => {
    await updateHelpdeskInstantAnswer(appId, answer.id, answer);
    refreshHelpdeskInstantAnswers();
  };

  return (
    <div className="animated fadeIn">
      <List
        useCardContent
        data={helpdeskInstantAnswers}
        totalElements={numberOfHelpdeskInstantAnswers}
        loaded={helpdeskInstantAnswersLoaded}
        loading={helpdeskInstantAnswersLoading}
        error={helpdeskInstantAnswersError}
        fetchData={fetchHelpdeskInstantAnswers}
        title={t('helpdesk.instantAnswers')}
        defaultItemOnPage={20}
        createPermission="helpdesk_group_create"
        createModal={(isModalOpen, setIsModalOpen) => (
          <HelpdeskInstantAnswerModalForm
            isOpen={isModalOpen}
            toggleModalState={setIsModalOpen}
            onSaved={refreshHelpdeskInstantAnswers}
          />
        )}
        fields={[
          { key: 'id', label: t('common.id') },
          {
            key: 'title',
            label: t('common.title'),
            render: answer => (
              <EditableTextInput
                text={answer.title || ''}
                name="text"
                data={answer.title || ''}
                canBeEmpty
                updateData={(appId: string, id: number, value: string) => {
                  updateAnswer({ ...answer, title: value });
                }}
              />
            ),
          },
          {
            key: 'message',
            label: t('common.message'),
            render: answer => (
              <EditableTextInput
                text={answer.message || ''}
                name="text"
                data={answer.message || ''}
                canBeEmpty
                updateData={(appId: string, id: number, value: string) => {
                  updateAnswer({ ...answer, message: value });
                }}
              />
            ),
          },
          {
            key: 'operations',
            label: t('common.operations'),
            operations: [Operations.DELETE],
          },
        ]}
        noDataLabel={t('helpdesk.noInstantMessages')}
        deleteFunction={removeHelpdeskInstantAnswer}
        deleteTitle={t('helpdesk.deleteInstantMessageTitle')}
        deleteText={t('helpdesk.deleteInstantMessageText')}
        deletePermission="helpdesk_group_delete"
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    application: state.application.data,
    helpdeskInstantAnswers: state.helpdeskInstantAnswers.data.content,
    numberOfHelpdeskInstantAnswers: state.helpdeskInstantAnswers.data.totalElements,
    helpdeskInstantAnswersLoading: state.helpdeskInstantAnswers.loading,
    helpdeskInstantAnswersLoaded: state.helpdeskInstantAnswers.loaded,
    helpdeskInstantAnswersError: state.helpdeskInstantAnswers.error,
  };
};

const mapDispatchToProps = {
  getApplication,
  getHelpdeskInstantAnswers,
  deleteHelpdeskInstantAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpdeskInstantAnswers);
