import { get } from 'src/shared/services/http.service';
import { OutgoingMessagesReportFilters } from 'src/shared/models';

export const getOutgoingMessagesReport = async (
  applicationId: string,
  filter: OutgoingMessagesReportFilters,
) => {
  // const appliedFilters = Object.entries(filter)
  //   .filter(filterItem => !!filterItem?.[1])
  //   .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  console.log('filter', filter);

  return get(`/applications/${applicationId}/analytics/outbound-messages-count`, {
    params: { ...filter },
  });
};

export default getOutgoingMessagesReport;
