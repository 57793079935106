import { Dispatch } from 'redux';
import { PositionsQueryParams } from '../../shared/models/query-params.model';
import {
  PositionModel,
  PositionCloneModel,
  PositionQuestionModel,
  PositionStatus,
} from '../../shared/models/positions.model';
import {
  getPosition as getPositionService,
  getPositions as getPositionsService,
  createPosition as createPositionService,
  updatePosition as updatePositionService,
  updatePositionStatus as updatePositionStatusService,
  deletePosition as deletePositionService,
  clonePosition as clonePositionService,
  exportPositions as exportPositionsService,
  updatePositionOrder as updatePositionOrderService,
} from '../services/positions.service';

export const GET_POSITION_REQUEST = 'positions/GET_POSITION_REQUEST';
export const GET_POSITION_SUCCESS = 'positions/GET_POSITION_SUCCESS';
export const GET_POSITION_ERROR = 'positions/GET_POSITION_ERROR';

export const GET_POSITIONS_REQUEST = 'positions/GET_POSITIONS_REQUEST';
export const GET_POSITIONS_SUCCESS = 'positions/GET_POSITIONS_SUCCESS';
export const GET_POSITIONS_ERROR = 'positions/GET_POSITIONS_ERROR';

export const CREATE_POSITION_REQUEST = 'positions/CREATE_POSITION_REQUEST';
export const CREATE_POSITION_SUCCESS = 'positions/CREATE_POSITION_SUCCESS';
export const CREATE_POSITION_ERROR = 'positions/CREATE_POSITION_ERROR';

export const UPDATE_POSITION_REQUEST = 'positions/UPDATE_POSITION_REQUEST';
export const UPDATE_POSITION_SUCCESS = 'positions/UPDATE_POSITION_SUCCESS';
export const UPDATE_POSITION_ERROR = 'positions/UPDATE_POSITION_ERROR';

export const UPDATE_POSITION_STATUS_REQUEST = 'positions/UPDATE_POSITION_STATUS_REQUEST';
export const UPDATE_POSITION_STATUS_SUCCESS = 'positions/UPDATE_POSITION_STATUS_SUCCESS';
export const UPDATE_POSITION_STATUS_ERROR = 'positions/UPDATE_POSITION_STATUS_ERROR';

export const UPDATE_POSITION_ORDER_REQUEST = 'positions/UPDATE_POSITION_ORDER_REQUEST';
export const UPDATE_POSITION_ORDER_SUCCESS = 'positions/UPDATE_POSITION_ORDER_SUCCESS';
export const UPDATE_POSITION_ORDER_ERROR = 'positions/UPDATE_POSITION_ORDER_ERROR';

export const DELETE_POSITION_REQUEST = 'positions/DELETE_POSITION_REQUEST';
export const DELETE_POSITION_SUCCESS = 'positions/DELETE_POSITION_SUCCESS';
export const DELETE_POSITION_ERROR = 'positions/DELETE_POSITION_ERROR';

export const CLONE_POSITION_REQUEST = 'positions/CLONE_POSITION_REQUEST';
export const CLONE_POSITION_SUCCESS = 'positions/CLONE_POSITION_SUCCESS';
export const CLONE_POSITION_ERROR = 'positions/CLONE_POSITION_ERROR';

export const EXPORT_POSITIONS_REQUEST = 'positions/EXPORT_POSITIONS_REQUEST';
export const EXPORT_POSITIONS_SUCCESS = 'positions/EXPORT_POSITIONS_SUCCESS';
export const EXPORT_POSITIONS_ERROR = 'positions/EXPORT_POSITIONS_ERROR';

export const SET_QUESTION_TO_EDIT = 'positions/SET_QUESTION_TO_EDIT';
export const SET_POSITION_SIDEBAR_TYPE = 'positions/SET_POSITION_SIDEBAR_TYPE';

export const CLEAR_POSITION = 'positions/CLEAR_POSITION';
export const CLEAR_CREATED_POSITION = 'positions/CLEAR_CREATED_POSITION';

export const getPosition = (applicationId: string, positionId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_POSITION_REQUEST,
    });

    try {
      dispatch({
        type: GET_POSITION_SUCCESS,
        payload: await getPositionService(applicationId, positionId),
      });
    } catch (error) {
      dispatch({
        type: GET_POSITION_ERROR,
        error,
      });
    }
  };
};

export const getPositions = (applicationId: string, queryParams?: PositionsQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_POSITIONS_REQUEST,
    });

    try {
      dispatch({
        type: GET_POSITIONS_SUCCESS,
        payload: await getPositionsService(applicationId, queryParams),
      });
    } catch (error) {
      dispatch({
        type: GET_POSITIONS_ERROR,
        error,
      });
    }
  };
};

export const createPosition = (applicationId: string, position: PositionModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_POSITION_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_POSITION_SUCCESS,
        payload: await createPositionService(applicationId, position),
      });
    } catch (error) {
      dispatch({
        type: CREATE_POSITION_ERROR,
        error,
      });
    }
  };
};

export const updatePosition = (
  applicationId: string,
  positionId: number,
  position: PositionModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_POSITION_REQUEST,
    });
    try {
      dispatch({
        type: UPDATE_POSITION_SUCCESS,
        payload: await updatePositionService(applicationId, positionId, position),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_POSITION_ERROR,
        error,
      });
    }
  };
};

export const updatePositionOrder = (applicationId: string, positionId: number, order: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_POSITION_ORDER_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_POSITION_ORDER_SUCCESS,
        payload: await updatePositionOrderService(applicationId, positionId, order),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_POSITION_ORDER_ERROR,
        error,
      });
    }
  };
};

export const updatePositionStatus = (
  applicationId: string,
  positionId: number,
  status: PositionStatus,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_POSITION_STATUS_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_POSITION_STATUS_SUCCESS,
        payload: await updatePositionStatusService(applicationId, positionId, status),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_POSITION_STATUS_ERROR,
        error,
      });
    }
  };
};

export const deletePosition = (applicationId: string, positionId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_POSITION_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_POSITION_SUCCESS,
        payload: await deletePositionService(applicationId, positionId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_POSITION_ERROR,
        error,
      });
    }
  };
};

export const clonePosition = (
  applicationId: string,
  positionId: number,
  position: PositionCloneModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CLONE_POSITION_REQUEST,
    });

    try {
      dispatch({
        type: CLONE_POSITION_SUCCESS,
        payload: await clonePositionService(applicationId, positionId, position),
      });
    } catch (error) {
      dispatch({
        type: CLONE_POSITION_ERROR,
        error,
      });
    }
  };
};

export const exportPositions = (applicationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EXPORT_POSITIONS_REQUEST,
    });

    try {
      dispatch({
        type: EXPORT_POSITIONS_SUCCESS,
        payload: await exportPositionsService(applicationId),
      });
    } catch (error) {
      dispatch({
        type: EXPORT_POSITIONS_ERROR,
        error,
      });
    }
  };
};

export const setQuestionToEdit = (question: PositionQuestionModel) => {
  return {
    type: SET_QUESTION_TO_EDIT,
    payload: question,
  };
};

export const setPositionSidebarType = (type: string) => {
  return {
    type: SET_POSITION_SIDEBAR_TYPE,
    payload: type,
  };
};

export const clearPosition = () => {
  return {
    type: CLEAR_POSITION,
  };
};

export const clearCreatedPosition = () => {
  return {
    type: CLEAR_CREATED_POSITION,
  };
};
