import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { ExportButton } from 'src/shared/components';
import { StateModel } from '../../../shared/models/default-state.model';

interface Props {
  interview: StateModel;
  handleExport: Function;
}

const InterviewAttendeesHeader: React.FC<Props> = ({ interview, handleExport }) => {
  const { t } = useTranslation();

  return (
    <Row className="mb-4">
      <Col>
        <h4>
          {t('common.interview-attendees')} - {interview?.data.name}
        </h4>
      </Col>
      <Col className="col-2 text-right">
        <ExportButton
          onClick={() => {
            handleExport();
          }}
          permission="position_talentpool_applicants_read"
        />
      </Col>
    </Row>
  );
};

export default InterviewAttendeesHeader;
