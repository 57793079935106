import { StateModel } from '../../shared/models/default-state.model';
import {
  GET_UNRECOGNIZED_SENTENCES_REQUEST,
  GET_UNRECOGNIZED_SENTENCES_SUCCESS,
  GET_UNRECOGNIZED_SENTENCES_ERROR,
  EXPORT_UNRECOGNIZED_SENTENCES_REQUEST,
  EXPORT_UNRECOGNIZED_SENTENCES_SUCCESS,
  EXPORT_UNRECOGNIZED_SENTENCES_ERROR,
  RESET_UNRECOGNIZED_SENTENCES_REQUEST,
  RESET_UNRECOGNIZED_SENTENCES_SUCCESS,
  RESET_UNRECOGNIZED_SENTENCES_ERROR,
} from '../actions/unrecognized-sentences.action';
import { PaginationAPIModel } from '../../shared/models/pagination.model';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export interface UnrecognizedSentencesModel {
  createdAt: string;
  sentence: string;
  entityInstances?: object;
}

export interface ListAPIModel extends PaginationAPIModel {
  content: UnrecognizedSentencesModel[];
}

export const getUnrecognizedSentencesReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_UNRECOGNIZED_SENTENCES_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case GET_UNRECOGNIZED_SENTENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };

    case GET_UNRECOGNIZED_SENTENCES_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const exportUnrecognizedSentencesReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case EXPORT_UNRECOGNIZED_SENTENCES_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case EXPORT_UNRECOGNIZED_SENTENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };

    case EXPORT_UNRECOGNIZED_SENTENCES_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const resetUnrecognizedSentencesReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case RESET_UNRECOGNIZED_SENTENCES_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case RESET_UNRECOGNIZED_SENTENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
        error: null,
      };

    case RESET_UNRECOGNIZED_SENTENCES_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
