import { get, post, patch, del } from '../../shared/services/http.service';
import {
  QuestionnaireMessage,
  QuestionnaireLabel,
  QuestionnaireLabelCategory,
  AttributeValidatorModel,
  RegexReplacerModel,
  ExpressionModel,
} from '../../shared/models/questionnaire.model';

export const getMessages = async (applicationId: string) => {
  return get(`/recruiter/applications/${applicationId}/questionnaire/messages`, {});
};

export const getMessage = async (applicationId: string, messageId: number) => {
  return get(`/recruiter/applications/${applicationId}/questionnaire/messages/${messageId}`);
};

export const createMessage = async (applicationId: string, message: QuestionnaireMessage) => {
  return post(`/recruiter/applications/${applicationId}/questionnaire/messages`, {
    data: message,
  });
};

export const createMessageGroups = async (
  applicationId: string,
  message: QuestionnaireMessage,
  type: string,
) => {
  return post(`/recruiter/applications/${applicationId}/questionnaire/message-groups`, {
    data: message,
    params: { type },
  });
};

export const updateMessage = async (
  applicationId: string,
  messageId: number,
  message: QuestionnaireMessage,
) => {
  // fix empty answerActionRootData
  message?.answers?.map(answer => {
    if (
      answer.answerActionRootData &&
      answer.answerActionRootData !== null &&
      !Object.keys(answer.answerActionRootData).length
    ) {
      // eslint-disable-next-line no-param-reassign
      answer.answerActionRootData = null;
    }
  });
  return patch(`/recruiter/applications/${applicationId}/questionnaire/messages/${messageId}`, {
    data: message,
  });
};

export const updateMessageCheckpoint = async (
  applicationId: string,
  messageId: number,
  isChecked: boolean,
) => {
  return patch(
    `/recruiter/applications/${applicationId}/questionnaire/messages/${messageId}/checkpoint`,
    { data: { checkpoint: isChecked } },
  );
};

export const updateMessageText = async (applicationId: string, messageId: number, text: string) => {
  return patch(
    `/recruiter/applications/${applicationId}/questionnaire/messages/${messageId}/text`,
    { data: { text } },
  );
};

export const updateAnswerText = async (
  applicationId: string,
  messageId: number,
  answerId: number,
  text: string,
) => {
  return patch(
    `/recruiter/applications/${applicationId}/questionnaire/messages/${messageId}/answers/${answerId}/text`,
    { data: { text } },
  );
};

export const deleteMessage = async (applicationId: string, messageId: number) => {
  return del(`/recruiter/applications/${applicationId}/questionnaire/messages/${messageId}`);
};

export const getLabelCategories = async (
  applicationId: string,
): Promise<QuestionnaireLabelCategory[]> => {
  return get(`/recruiter/applications/${applicationId}/label/categories`);
};

export const createLabelCategory = async (
  applicationId: string,
  labelCategory: QuestionnaireLabelCategory,
) => {
  return post(`/recruiter/applications/${applicationId}/label/categories`, {
    data: { text: labelCategory },
  });
};

export const getLabels = async (applicationId: string, labelCategoryId: number) => {
  return get(`/recruiter/applications/${applicationId}/label/categories/${labelCategoryId}/labels`);
};

export const createLabel = async (
  applicationId: string,
  labelCategoryId: number,
  label: QuestionnaireLabel,
) => {
  return post(
    `/recruiter/applications/${applicationId}/label/categories/${labelCategoryId}/labels`,
    {
      data: { text: label, order: 0 },
    },
  );
};

export const updateLabel = async (
  applicationId: string,
  labelCategoryId: number,
  labelId: number,
  label: QuestionnaireLabel,
) => {
  return patch(
    `/recruiter/applications/${applicationId}/label/categories/${labelCategoryId}/labels/${labelId}`,
    {
      data: label,
    },
  );
};

export const deleteLabel = async (
  applicationId: string,
  labelCategoryId: number,
  labelId: number,
) => {
  return del(
    `/recruiter/applications/${applicationId}/label/categories/${labelCategoryId}/labels/${labelId}`,
  );
};

export const getConnectedParentConditions = async (applicationId: string) => {
  return get(`/recruiter/applications/${applicationId}/questionnaire/answers/connected`);
};

export const getAttributeValidators = async () => {
  return get('/regex');
};

export const createAttributeValidator = async (attributeValidator: AttributeValidatorModel) => {
  return post('/regex', { data: attributeValidator });
};

export const updateAttributeValidator = async (
  regexId: number,
  attributeValidator: AttributeValidatorModel,
) => {
  return patch(`/regex/${regexId}`, { data: attributeValidator });
};

export const deleteAttributeValidator = async (regexId: number) => {
  return del(`/regex/${regexId}`);
};

export const getRegexReplacers = async () => {
  return get('/regex/replacers');
};

export const createRegexReplacer = async (attributeValidator: RegexReplacerModel) => {
  return post('/regex/replacers', { data: attributeValidator });
};

export const updateRegexReplacer = async (
  replacersId: number,
  attributeValidator: AttributeValidatorModel,
) => {
  return patch(`/regex/replacers/${replacersId}`, { data: attributeValidator });
};

export const deleteRegexReplacer = async (replacersId: number) => {
  return del(`/regex/replacers/${replacersId}`);
};

export const getExpressions = async () => {
  return get('/expression');
};

export const createExpression = async (expression: ExpressionModel) => {
  return post('/expression', { data: expression });
};

export const updateExpression = async (expressionId: number, expression: ExpressionModel) => {
  return patch(`/expression/${expressionId}`, { data: expression });
};

export const deleteExpression = async (expressionId: number) => {
  return del(`/expression/${expressionId}`);
};
