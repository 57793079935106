import { TagModel } from 'src/shared/models';

export const updateTags = async (
  originalTags: TagModel[] | null,
  newTags: TagModel[],
  addTag: (toAddIds: string[]) => any,
  deleteTag: (tag: string) => any,
) => {
  let toRemoveIds: string[] = [];
  let toAddIds: string[] = [];

  if (originalTags) {
    originalTags.map(tag => {
      const exitsts = newTags.find(empTags => empTags.id === tag.id);
      if (!exitsts) {
        toRemoveIds.push(tag.id);
      }
      return null;
    });

    newTags.map(tag => {
      const exitsts = originalTags.find(origTags => origTags.id === tag.id);
      if (!exitsts) {
        toAddIds.push(tag.id);
      }
      return null;
    });

    if (toAddIds.length) {
      await addTag(toAddIds);
    }

    await Promise.all(toRemoveIds.map(tag => deleteTag(tag)));

    return Promise.resolve();
  }
};
