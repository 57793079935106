import { get } from '../../shared/services/http.service';
import { ApplicantsQueryParams } from '../../shared/models/query-params.model';

export const getShortListPositions = async (applicationId: string) => {
  return get(`/recruiter/applications/${applicationId}/shortlist/positions`);
};

export const getShortListPositionCriterias = async (applicationId: string, positionId: number) => {
  return get(
    `/recruiter/applications/${applicationId}/shortlist/positions/${positionId}/criterias`,
  );
};

export const getShortListPositionApplicants = async (
  applicationId: string,
  positionId: number,
  queryParams: ApplicantsQueryParams,
) => {
  return get(
    `/recruiter/applications/${applicationId}/shortlist/positions/${positionId}/applicants`,
    {
      params: queryParams,
    },
  );
};
