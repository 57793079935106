import React, { Fragment } from 'react';
import moment from 'moment';
import i18n from 'src/i18n';
import { ListElementModel } from 'src/redux/reducers/list-elements.reducer';

interface Props {
  document: ListElementModel;
}

const DeletedDocument: React.FC<Props> = ({ document }) => {
  return (
    <Fragment>
      <p className="font-weight-bold">
        {i18n.t('auditLog.detailsOfDeletedDocument')}
      </p>

      <table className="table table-sm">
        <tbody>
          <tr>
            <td>
              {i18n.t('common.id')} / {i18n.t('common.name')}
            </td>
            <td>
              {document.id} / {document.name}
            </td>
          </tr>
          <tr>
            <td>{i18n.t('common.created')}</td>
            <td>{moment(document.createdAt).format('LLL')}</td>
          </tr>
          <tr>
            <td>{i18n.t('common.updated')}</td>
            <td>
              {document.updatedAt && moment(document.updatedAt).format('LLL')}
            </td>
          </tr>
          <tr>
            <td>{i18n.t('foldersAndDocuments.shortDescription')}</td>
            <td>{document.shortDescription}</td>
          </tr>
          <tr>
            <td>{i18n.t('common.description')}</td>
            <td>{document.description}</td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

export default DeletedDocument;
