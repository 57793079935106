import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import isEqual from 'lodash/isEqual';
import Select from 'react-select';

import { ApplicantsQueryParams } from 'src/shared/models/query-params.model';
import { SelectModel } from '../models/common.model';

interface Props {
  setQueryParams: Function;
  sortTypes: SelectModel[];
}

const Sorter: React.FC<Props> = ({ setQueryParams, sortTypes }) => {
  const { t } = useTranslation();

  const [sortType, setSortType] = useState<SelectModel>(sortTypes[0]);
  const [sortDirection, setSortDirection] = useState<string>('DESC');

  const selectStyles = {
    container: (base: any) => ({
      ...base,
      flex: 1,
    }),
  };

  useEffect(() => {
    setQueryParams((prevState: ApplicantsQueryParams) => ({
      ...prevState,
      page: 0,
      sort: `${sortType.value},${sortDirection}`,
    }));
    /* eslint-disable-next-line */
  }, [sortDirection, sortType]);

  const handleSortTypeChange = (option: any) => {
    if (isEqual(sortType, option)) return;

    setSortType(option);
  };

  const handleSortDirectionChange = () => {
    const nextSortDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC';
    setSortDirection(nextSortDirection);
  };

  return (
    <div className="d-flex flex-column">
      <span className="mb-2">{t('common.sortBy')}</span>
      <div className="d-flex">
        <Select
          styles={selectStyles}
          options={sortTypes}
          defaultValue={sortTypes[0]}
          onChange={option => handleSortTypeChange(option)}
        />
        <Button className="ml-3" onClick={() => handleSortDirectionChange()}>
          {sortDirection === 'DESC' ? (
            <i className="fas fa-long-arrow-alt-down" />
          ) : (
            <i className="fas fa-long-arrow-alt-up" />
          )}
        </Button>
      </div>
    </div>
  );
};

export default Sorter;
