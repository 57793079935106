import React, { Fragment } from 'react';

import { ConversationHistoryModel } from '../../../../../../redux/actions/conversation-history.action';

import TextMessage from './components/LiveChat/TextMessage';
import ButtonTemplate from './components/LiveChat/ButtonTemplate';
import GenericTemplate from './components/LiveChat/GenericTemplate';
import GenericTemplate2 from './components/LiveChat/GenericTemplate2';
import MultiList from './components/LiveChat/MultiList';
import MultiListResponse from './components/LiveChat/MultiListResponse';
import QuickReply from './components/LiveChat/QuickReply';
import SendFile from './components/LiveChat/SendFile';
import SendTextWithButtons from './components/LiveChat/SendTextWithButtons';
import MapTemplate from './components/LiveChat/MapTemplate';
import { MessageTypes } from 'src/shared/constants/conversation-history.constants';

interface Props {
  message: ConversationHistoryModel;
}

const renderMessage = (message: ConversationHistoryModel) => {
  switch (message.messageType) {
    case 'Text':
      return <TextMessage message={message} />;
    case 'ButtonTemplate':
      return <ButtonTemplate message={message} />;
    case 'SendTextWithButtons':
      return <SendTextWithButtons message={message} />;
    case 'GenericTemplate':
      return <GenericTemplate message={message} />;
    case 'GenericTemplate2':
      return <GenericTemplate2 message={message} />;
    case 'MultiList':
      return <MultiList message={message} />;
    case 'MultiListResponse':
      return <MultiListResponse message={message} />;
    case 'QuickReply':
      return <QuickReply message={message} />;
    case 'File':
      return <SendFile message={message} />;
    case 'MapTemplate':
      return <MapTemplate message={message} />;
    case MessageTypes.QuickReplies:
      return <QuickReply message={message} />;
    default:
      break;
  }
};

const LiveChat: React.FC<Props> = (props: Props) => {
  const { message } = props;

  return <Fragment>{renderMessage(message)}</Fragment>;
};

export default LiveChat;
