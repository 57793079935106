import React from 'react';
import { TFunction } from 'i18next';
import { uniqueId } from 'lodash';
import {
  ContentTranslationEventListItem,
  ContentTranslationEventListModel,
  EventTranslation,
  EventTranslationLevel,
  GetEventTranslationsResponse,
} from 'src/shared/models/content-translations-event.model';
import { safeFilter } from 'src/shared/utils';
import {
  ANSWER_TYPES,
  OptionListAnswerConfiguration,
  TextAnswerConfiguration,
} from 'src/shared/models';
import {
  createEventDateTranslation,
  createEventQuestionTranslation,
  createEventTranslation,
  deleteEventDateTranslation,
  deleteEventQuestionTranslation,
  deleteEventTranslation,
  editEventDateTranslation,
  editEventQuestionTranslation,
  editEventTranslation,
} from 'src/redux/services/content-translation-event.service';

export const getEventTranslationsModel = (
  contentTranslations: GetEventTranslationsResponse,
  selectedLanguage: string | undefined,
  t: TFunction,
): ContentTranslationEventListModel => {
  if (!Array.isArray(contentTranslations)) return [];
  const model: ContentTranslationEventListModel = [];

  contentTranslations.forEach(eventTranslation => {
    const filtered = safeFilter(
      eventTranslation.translations.filter(t => t.languageCode === selectedLanguage),
    ) as EventTranslation;

    const translatedTitleField = filtered?.title;
    const translatedDescriptionField = filtered?.description;

    model.push({
      id: Number.parseInt(uniqueId()),
      eventId: eventTranslation.id,
      displayedIdentifier: (
        <b>
          [{t('Events')}] ID={eventTranslation.id}
        </b>
      ),
      fieldName: 'title',
      originalText: eventTranslation.title,
      translatedText: translatedTitleField,
      level: EventTranslationLevel.Base,
    });
    model.push({
      id: Number.parseInt(uniqueId()),
      eventId: eventTranslation.id,
      displayedIdentifier: <hr />,
      fieldName: 'description',
      originalText: eventTranslation.description,
      translatedText: translatedDescriptionField,
      level: EventTranslationLevel.Base,
    });

    eventTranslation.dates.forEach(dateTranslation => {
      const filteredDate = safeFilter(
        dateTranslation.translations.filter(t => t.languageCode === selectedLanguage),
      );

      const translatedTitleField = filteredDate?.title;
      model.push({
        id: Number.parseInt(uniqueId()),
        eventId: eventTranslation.id,
        eventDateId: dateTranslation.id,
        displayedIdentifier: (
          <div className="d-flex align-items-center">
            <div style={{ width: 50 }}>
              <hr />
            </div>
            <div>
              <b>
                [{t('Dates')}] ID={dateTranslation.id} From={dateTranslation.startDateTime}, To=
                {dateTranslation.endDateTime}
              </b>
            </div>
          </div>
        ),
        fieldName: 'title',
        originalText: dateTranslation.title,
        translatedText: translatedTitleField,
        level: EventTranslationLevel.Date,
      });
    });

    eventTranslation.questions.forEach(questionTranslation => {
      const filteredQuestion = safeFilter(
        questionTranslation.translations.filter(t => t.languageCode === selectedLanguage),
      );

      const translatedTitle = filteredQuestion?.text;
      model.push({
        id: Number.parseInt(uniqueId()),
        eventId: eventTranslation.id,
        eventQuestionId: questionTranslation.id!,
        displayedIdentifier: (
          <div className="d-flex align-items-center">
            <div style={{ width: 50 }}>
              <hr />
            </div>
            <div>
              <b>
                [{t('Questions')}] ID={questionTranslation.id}
              </b>
            </div>
          </div>
        ),
        fieldName: 'text',
        originalText: questionTranslation.title,
        translatedText: translatedTitle,
        level: EventTranslationLevel.Question,
      });

      if (questionTranslation.answerConfiguration.type === ANSWER_TYPES.OPTIONS) {
        const optionList = questionTranslation.answerConfiguration as OptionListAnswerConfiguration;
        const translatedAnswerConfiguration = filteredQuestion?.answerConfiguration as OptionListAnswerConfiguration;

        for (const property in optionList.options) {
          model.push({
            id: Number.parseInt(uniqueId()),
            eventId: eventTranslation.id,
            eventQuestionId: questionTranslation.id!,
            eventQuestionAnswerId: property,
            displayedIdentifier: (
              <div className="d-flex align-items-center">
                <div style={{ width: 100 }}>
                  <hr />
                </div>
                <div>
                  <b>
                    [{t('Answers')}] ID={property}
                  </b>
                </div>
              </div>
            ),
            fieldName: property,
            originalText: optionList.options[property] as any,
            translatedText: translatedAnswerConfiguration?.options[property] as any,
            level: EventTranslationLevel.QuestionAnswer,
          });
        }
      } else if (questionTranslation.answerConfiguration.type === ANSWER_TYPES.TEXT) {
        const answerConfiguration = questionTranslation.answerConfiguration as TextAnswerConfiguration;
        const translatedAnswerConfiguration = filteredQuestion?.answerConfiguration as TextAnswerConfiguration;

        model.push({
          id: Number.parseInt(uniqueId()),
          eventId: eventTranslation.id,
          eventQuestionId: questionTranslation.id!,
          displayedIdentifier: (
            <div className="d-flex align-items-center">
              <div style={{ width: 100 }}>
                <hr />
              </div>
              <div>
                <b>
                  [{t('AnswerConfiguration')}] ID={answerConfiguration.type}
                </b>
              </div>
            </div>
          ),
          fieldName: 'validationErrorMessage',
          originalText: answerConfiguration.validationErrorMessage,
          translatedText: translatedAnswerConfiguration?.validationErrorMessage,
          level: EventTranslationLevel.QuestionAnswer,
        });
      }
    });
  });
  return model;
};

export const handleEventEdit = async (
  item: ContentTranslationEventListItem,
  contentTranslationsEvent: GetEventTranslationsResponse,
  newValue: string,
  selectedLanguage: string,
  appId: string,
) => {
  const isCreate =
    (!item.translatedText || item.translatedText.length === 0) && newValue.length !== 0;
  switch (item.level) {
    case EventTranslationLevel.Base: {
      const filtered = safeFilter(contentTranslationsEvent.filter(t => t.id === item.eventId));
      const filteredTranslations = filtered?.translations.filter(
        (t: EventTranslation) => t.languageCode === selectedLanguage,
      );
      const noFieldsAreTranslated = filteredTranslations.length === 0;
      if (isCreate && noFieldsAreTranslated) {
        await createEventTranslation(appId, item.eventId!, {
          languageCode: selectedLanguage!,
          [item.fieldName]: newValue,
        });
      } else {
        await editEventTranslation(appId, item.eventId!, selectedLanguage!, {
          [item.fieldName]: newValue,
        });
      }
      break;
    }
    case EventTranslationLevel.Date: {
      if (isCreate) {
        await createEventDateTranslation(appId, item.eventId!, item.eventDateId!, {
          languageCode: selectedLanguage!,
          [item.fieldName]: newValue,
        });
      } else {
        await editEventDateTranslation(appId, item.eventId!, item.eventDateId!, selectedLanguage!, {
          [item.fieldName]: newValue,
        });
      }
      break;
    }
    case EventTranslationLevel.Question:
    case EventTranslationLevel.QuestionAnswer: {
      let translation = undefined;
      try {
        translation = contentTranslationsEvent
          .filter(e => e.id === item.eventId)[0]
          .questions.filter(q => q.id === item.eventQuestionId)[0]
          .translations.filter(t => t.languageCode === selectedLanguage!)[0];
      } catch {
        translation = {};
      }

      let request = (translation as any) || {};
      if (item.level === EventTranslationLevel.QuestionAnswer) {
        if (item.fieldName === 'validationErrorMessage') {
          request = {
            languageCode: selectedLanguage!,
            answerConfiguration: {
              type: 'text',
              [item.fieldName]: newValue,
            },
          };
        } else {
          if (translation !== undefined) {
            if (translation.answerConfiguration) {
              request.answerConfiguration.options[item.fieldName] = newValue;
            } else {
              request.answerConfiguration = {
                type: 'optionList',
                options: {
                  [item.fieldName]: newValue,
                },
              };
            }
          } else {
            request = {
              languageCode: selectedLanguage!,
              answerConfiguration: {
                type: 'optionList',
                options: {
                  [item.fieldName]: newValue,
                },
              },
            };
          }
        }
      } else if (item.level === EventTranslationLevel.Question) {
        request.languageCode = selectedLanguage!;
        request[item.fieldName] = newValue;
      }

      if (isCreate && translation === undefined) {
        await createEventQuestionTranslation(appId, item.eventId!, item.eventQuestionId!, request);
      } else {
        await editEventQuestionTranslation(
          appId,
          item.eventId!,
          item.eventQuestionId!,
          selectedLanguage!,
          request,
        );
      }
      break;
    }
  }
};

export const handleEventDelete = async (
  item: ContentTranslationEventListItem,
  selectedLanguage: string,
  appId: string,
) => {
  if (!item.translatedText) return;
  switch (item.level) {
    case EventTranslationLevel.Base: {
      await deleteEventTranslation(appId, item.eventId!, selectedLanguage!, {});
      break;
    }
    case EventTranslationLevel.Date: {
      await deleteEventDateTranslation(
        appId,
        item.eventId!,
        item.eventDateId!,
        selectedLanguage!,
        {},
      );
      break;
    }
    case EventTranslationLevel.Question:
    case EventTranslationLevel.QuestionAnswer: {
      await deleteEventQuestionTranslation(
        appId,
        item.eventId!,
        item.eventQuestionId!,
        selectedLanguage!,
        {},
      );
    }
  }
};
