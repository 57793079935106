import React, { Component, Fragment } from 'react';
import { ConversationHistoryModel } from '../../../../../../../../redux/actions/conversation-history.action';
import TextMessage from './TextMessage';

import styles from './scss/LiveChat.module.scss';

interface Props {
  message: ConversationHistoryModel;
}

interface State {
  text: string;
  buttons: any;
}

class SendTextWithButtons extends Component<Props, State> {
  state: State = {
    text: '',
    buttons: [],
  };

  componentDidMount() {
    const { message } = this.props;
    const cleanedMessage = message.messageAsText.replace(/\n/g, ' ');

    try {
      const currentMessage = JSON.parse(cleanedMessage);
      this.getText(currentMessage);
      this.getButtons(currentMessage);
    } catch (error) {
      throw new Error('Failed to parse JSON');
    }
  }

  getText = (message: any) => {
    const { text } = message;

    this.setState({
      text,
    });
  };

  getButtons = (message: any) => {
    const { buttons } = message;
    const outputButtons = [];

    for (let i = 0; i < buttons.length; i++) {
      outputButtons.push(
        <button key={i} type="button" className={styles.quickReply} disabled>
          {buttons[i]}
        </button>,
      );
    }

    this.setState({
      buttons: outputButtons,
    });
  };

  render() {
    const { message } = this.props;
    const { text, buttons } = this.state;

    return (
      <Fragment>
        <TextMessage message={message} buttonTemplateText={text} />
        <div className={styles.quickReplies}>{buttons}</div>
      </Fragment>
    );
  }
}

export default SendTextWithButtons;
