import { Dispatch } from 'redux';

import {
  QuestionnaireMessage,
  QuestionnaireLabel,
  QuestionnaireLabelCategory,
  AttributeValidatorModel,
  RegexReplacerModel,
  ExpressionModel,
} from '../../shared/models/questionnaire.model';
import {
  getMessage as getMessageService,
  getMessages as getMessagesService,
  createMessage as createMessageService,
  updateMessage as updateMessageService,
  updateMessageText as updateMessageTextService,
  updateAnswerText as updateAnswerTextService,
  updateMessageCheckpoint as updateMessageCheckpointService,
  deleteMessage as deleteMessageService,
  getLabels as getLabelsService,
  createLabel as createLabelService,
  updateLabel as updateLabelService,
  deleteLabel as deleteLabelService,
  createLabelCategory as createLabelCategoryService,
  getLabelCategories as getLabelCategoriesService,
  getAttributeValidators as getAttributeValidatorsService,
  createAttributeValidator as createAttributeValidatorService,
  updateAttributeValidator as updateAttributeValidatorService,
  deleteAttributeValidator as deleteAttributeValidatorService,
  getRegexReplacers as getRegexReplacersService,
  createRegexReplacer as createRegexReplacerService,
  updateRegexReplacer as updateRegexReplacerService,
  deleteRegexReplacer as deleteRegexReplacerService,
  getExpressions as getExpressionsService,
  createExpression as createExpressionService,
  updateExpression as updateExpressionService,
  deleteExpression as deleteExpressionService,
} from '../services/questionnaire.service';

export const GET_MESSAGE_REQUEST = 'questionnaire/GET_MESSAGE_REQUEST';
export const GET_MESSAGE_SUCCESS = 'questionnaire/GET_MESSAGE_SUCCESS';
export const GET_MESSAGE_ERROR = 'questionnaire/GET_MESSAGE_ERROR';

export const GET_MESSAGES_REQUEST = 'questionnaire/GET_MESSAGES_REQUEST';
export const GET_MESSAGES_SUCCESS = 'questionnaire/GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_ERROR = 'questionnaire/GET_MESSAGES_ERROR';

export const CREATE_MESSAGE_REQUEST = 'questionnaire/CREATE_MESSAGE_REQUEST';
export const CREATE_MESSAGE_SUCCESS = 'questionnaire/CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_ERROR = 'questionnaire/CREATE_MESSAGE_ERROR';

export const UPDATE_MESSAGE_REQUEST = 'questionnaire/UPDATE_MESSAGE_REQUEST';
export const UPDATE_MESSAGE_SUCCESS = 'questionnaire/UPDATE_MESSAGE_SUCCESS';
export const UPDATE_MESSAGE_ERROR = 'questionnaire/UPDATE_MESSAGE_ERROR';

export const UPDATE_MESSAGE_TEXT_REQUEST = 'questionnaire/UPDATE_MESSAGE_TEXT_REQUEST';
export const UPDATE_MESSAGE_TEXT_SUCCESS = 'questionnaire/UPDATE_MESSAGE_TEXT_SUCCESS';
export const UPDATE_MESSAGE_TEXT_ERROR = 'questionnaire/UPDATE_MESSAGE_TEXT_ERROR';

export const UPDATE_ANSWER_TEXT_REQUEST = 'questionnaire/UPDATE_ANSWER_TEXT_REQUEST';
export const UPDATE_ANSWER_TEXT_SUCCESS = 'questionnaire/UPDATE_ANSWER_TEXT_SUCCESS';
export const UPDATE_ANSWER_TEXT_ERROR = 'questionnaire/UPDATE_ANSWER_TEXT_ERROR';

export const UPDATE_MESSAGE_CHECKPOINT_REQUEST = 'questionnaire/UPDATE_MESSAGE_CHECKPOINT_REQUEST';
export const UPDATE_MESSAGE_CHECKPOINT_SUCCESS = 'questionnaire/UPDATE_MESSAGE_CHECKPOINT_SUCCESS';
export const UPDATE_MESSAGE_CHECKPOINT_ERROR = 'questionnaire/UPDATE_MESSAGE_CHECKPOINT_ERROR';

export const DELETE_MESSAGE_REQUEST = 'questionnaire/DELETE_MESSAGE_REQUEST';
export const DELETE_MESSAGE_SUCCESS = 'questionnaire/DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_ERROR = 'questionnaire/DELETE_MESSAGE_ERROR';

export const GET_LABELS_REQUEST = 'questionnaire/GET_LABELS_REQUEST';
export const GET_LABELS_SUCCESS = 'questionnaire/GET_LABELS_SUCCESS';
export const GET_LABELS_ERROR = 'questionnaire/GET_LABELS_ERROR';

export const CREATE_LABEL_REQUEST = 'questionnaire/CREATE_LABEL_REQUEST';
export const CREATE_LABEL_SUCCESS = 'questionnaire/CREATE_LABEL_SUCCESS';
export const CREATE_LABEL_ERROR = 'questionnaire/CREATE_LABEL_ERROR';

export const UPDATE_LABEL_REQUEST = 'questionnaire/UPDATE_LABEL_REQUEST';
export const UPDATE_LABEL_SUCCESS = 'questionnaire/UPDATE_LABEL_SUCCESS';
export const UPDATE_LABEL_ERROR = 'questionnaire/UPDATE_LABEL_ERROR';

export const DELETE_LABEL_REQUEST = 'questionnaire/DELETE_LABEL_REQUEST';
export const DELETE_LABEL_SUCCESS = 'questionnaire/DELETE_LABEL_SUCCESS';
export const DELETE_LABEL_ERROR = 'questionnaire/DELETE_LABEL_ERROR';

export const GET_LABEL_CATEGORIES_REQUEST = 'questionnaire/GET_LABEL_CATEGORIES_REQUEST';
export const GET_LABEL_CATEGORIES_SUCCESS = 'questionnaire/GET_LABEL_CATEGORIES_SUCCESS';
export const GET_LABEL_CATEGORIES_ERROR = 'questionnaire/GET_LABEL_CATEGORIES_ERROR';

export const CREATE_LABEL_CATEGORY_REQUEST = 'questionnaire/CREATE_LABEL_CATEGORY_REQUEST';
export const CREATE_LABEL_CATEGORY_SUCCESS = 'questionnaire/CREATE_LABEL_CATEGORY_SUCCESS';
export const CREATE_LABEL_CATEGORY_ERROR = 'questionnaire/CREATE_LABEL_CATEGORY_ERROR';

export const GET_CONNECTED_PARENT_CONDITIONS_REQUEST =
  'questionnaire/GET_CONNECTED_PARENT_CONDITIONS_REQUEST';
export const GET_CONNECTED_PARENT_CONDITIONS_SUCCESS =
  'questionnaire/GET_CONNECTED_PARENT_CONDITIONS_SUCCESS';
export const GET_CONNECTED_PARENT_CONDITIONS_ERROR =
  'questionnaire/GET_CONNECTED_PARENT_CONDITIONS_ERROR';

export const CLEAR_MESSAGES = 'questionnaire/CLEAR_MESSAGES';

export const GET_ATTRIBUTE_VALIDATORS_REQUEST = 'questionnaire/GET_ATTRIBUTE_VALIDATORS_REQUEST';
export const GET_ATTRIBUTE_VALIDATORS_SUCCESS = 'questionnaire/GET_ATTRIBUTE_VALIDATORS_SUCCESS';
export const GET_ATTRIBUTE_VALIDATORS_ERROR = 'questionnaire/GET_ATTRIBUTE_VALIDATORS_ERROR';

export const CREATE_ATTRIBUTE_VALIDATOR_REQUEST =
  'questionnaire/CREATE_ATTRIBUTE_VALIDATOR_REQUEST';
export const CREATE_ATTRIBUTE_VALIDATOR_SUCCESS =
  'questionnaire/CREATE_ATTRIBUTE_VALIDATOR_SUCCESS';
export const CREATE_ATTRIBUTE_VALIDATOR_ERROR = 'questionnaire/CREATE_ATTRIBUTE_VALIDATOR_ERROR';

export const UPDATE_ATTRIBUTE_VALIDATOR_REQUEST =
  'questionnaire/UPDATE_ATTRIBUTE_VALIDATOR_REQUEST';
export const UPDATE_ATTRIBUTE_VALIDATOR_SUCCESS =
  'questionnaire/UPDATE_ATTRIBUTE_VALIDATOR_SUCCESS';
export const UPDATE_ATTRIBUTE_VALIDATOR_ERROR = 'questionnaire/UPDATE_ATTRIBUTE_VALIDATOR_ERROR';

export const DELETE_ATTRIBUTE_VALIDATOR_REQUEST =
  'questionnaire/DELETE_ATTRIBUTE_VALIDATOR_REQUEST';
export const DELETE_ATTRIBUTE_VALIDATOR_SUCCESS =
  'questionnaire/DELETE_ATTRIBUTE_VALIDATOR_SUCCESS';
export const DELETE_ATTRIBUTE_VALIDATOR_ERROR = 'questionnaire/DELETE_ATTRIBUTE_VALIDATOR_ERROR';

export const GET_ATTRIBUTE_REPLACERS_REQUEST = 'questionnaire/GET_ATTRIBUTE_REPLACERS_REQUEST';
export const GET_ATTRIBUTE_REPLACERS_SUCCESS = 'questionnaire/GET_ATTRIBUTE_REPLACERS_SUCCESS';
export const GET_ATTRIBUTE_REPLACERS_ERROR = 'questionnaire/GET_ATTRIBUTE_REPLACERS_ERROR';

export const CREATE_ATTRIBUTE_REPLACER_REQUEST = 'questionnaire/CREATE_ATTRIBUTE_REPLACER_REQUEST';
export const CREATE_ATTRIBUTE_REPLACER_SUCCESS = 'questionnaire/CREATE_ATTRIBUTE_REPLACER_SUCCESS';
export const CREATE_ATTRIBUTE_REPLACER_ERROR = 'questionnaire/CREATE_ATTRIBUTE_REPLACER_ERROR';

export const UPDATE_ATTRIBUTE_REPLACER_REQUEST = 'questionnaire/UPDATE_ATTRIBUTE_REPLACER_REQUEST';
export const UPDATE_ATTRIBUTE_REPLACER_SUCCESS = 'questionnaire/UPDATE_ATTRIBUTE_REPLACER_SUCCESS';
export const UPDATE_ATTRIBUTE_REPLACER_ERROR = 'questionnaire/UPDATE_ATTRIBUTE_REPLACER_ERROR';

export const DELETE_ATTRIBUTE_REPLACER_REQUEST = 'questionnaire/DELETE_ATTRIBUTE_REPLACER_REQUEST';
export const DELETE_ATTRIBUTE_REPLACER_SUCCESS = 'questionnaire/DELETE_ATTRIBUTE_REPLACER_SUCCESS';
export const DELETE_ATTRIBUTE_REPLACER_ERROR = 'questionnaire/DELETE_ATTRIBUTE_REPLACER_ERROR';

export const GET_EXPRESSIONS_REQUEST = 'questionnaire/GET_EXPRESSIONS_REQUEST';
export const GET_EXPRESSIONS_SUCCESS = 'questionnaire/GET_EXPRESSIONS_SUCCESS';
export const GET_EXPRESSIONS_ERROR = 'questionnaire/GET_EXPRESSIONS_ERROR';

export const CREATE_EXPRESSION_REQUEST = 'questionnaire/CREATE_EXPRESSION_REQUEST';
export const CREATE_EXPRESSION_SUCCESS = 'questionnaire/CREATE_EXPRESSION_SUCCESS';
export const CREATE_EXPRESSION_ERROR = 'questionnaire/CREATE_EXPRESSION_ERROR';

export const UPDATE_EXPRESSION_REQUEST = 'questionnaire/UPDATE_EXPRESSION_REQUEST';
export const UPDATE_EXPRESSION_SUCCESS = 'questionnaire/UPDATE_EXPRESSION_SUCCESS';
export const UPDATE_EXPRESSION_ERROR = 'questionnaire/UPDATE_EXPRESSION_ERROR';

export const DELETE_EXPRESSION_REQUEST = 'questionnaire/DELETE_EXPRESSION_REQUEST';
export const DELETE_EXPRESSION_SUCCESS = 'questionnaire/DELETE_EXPRESSION_SUCCESS';
export const DELETE_EXPRESSION_ERROR = 'questionnaire/DELETE_EXPRESSION_ERROR';

export const SET_LABEL_CATEGORIES_VIEW_MODE = 'questionnaire/SET_LABEL_CATEGORIES_VIEW_MODE';

export const getMessage = (applicationId: string, messageId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_MESSAGE_REQUEST,
    });

    try {
      dispatch({
        type: GET_MESSAGE_SUCCESS,
        payload: await getMessageService(applicationId, messageId),
      });
    } catch (error) {
      dispatch({
        type: GET_MESSAGE_ERROR,
        error,
      });
    }
  };
};

export const getMessages = (applicationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_MESSAGES_REQUEST,
    });

    try {
      dispatch({
        type: GET_MESSAGES_SUCCESS,
        payload: await getMessagesService(applicationId),
      });
    } catch (error) {
      dispatch({
        type: GET_MESSAGES_ERROR,
        error,
      });
    }
  };
};

export const createMessage = (applicationId: string, message: QuestionnaireMessage) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_MESSAGE_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_MESSAGE_SUCCESS,
        payload: await createMessageService(applicationId, message),
      });
    } catch (error) {
      dispatch({
        type: CREATE_MESSAGE_ERROR,
        error,
      });
    }
  };
};

export const updateMessage = (
  applicationId: string,
  messageId: number,
  message: QuestionnaireMessage,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_MESSAGE_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_MESSAGE_SUCCESS,
        payload: await updateMessageService(applicationId, messageId, message),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_MESSAGE_ERROR,
        error,
      });
    }
  };
};

export const updateMessageText = (applicationId: string, messageId: number, text: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_MESSAGE_TEXT_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_MESSAGE_TEXT_SUCCESS,
        payload: await updateMessageTextService(applicationId, messageId, text),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_MESSAGE_TEXT_ERROR,
        error,
      });
    }
  };
};

export const updateAnswerText = (
  applicationId: string,
  messageId: number,
  answerId: number,
  text: string,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ANSWER_TEXT_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_ANSWER_TEXT_SUCCESS,
        payload: await updateAnswerTextService(applicationId, messageId, answerId, text),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_ANSWER_TEXT_ERROR,
        error,
      });
    }
  };
};

export const updateMessageCheckpoint = (
  applicationId: string,
  messageId: number,
  isChecked: boolean,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_MESSAGE_CHECKPOINT_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_MESSAGE_CHECKPOINT_SUCCESS,
        payload: await updateMessageCheckpointService(applicationId, messageId, isChecked),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_MESSAGE_CHECKPOINT_ERROR,
        error,
      });
    }
  };
};

export const deleteMessage = (applicationId: string, messageId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_MESSAGE_REQUEST,
    });

    try {
      await deleteMessageService(applicationId, messageId);

      dispatch({
        type: DELETE_MESSAGE_SUCCESS,
        payload: messageId,
      });
    } catch (error) {
      dispatch({
        type: DELETE_MESSAGE_ERROR,
        error,
      });
    }
  };
};

export const getLabels = (applicationId: string, labelCategoryId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_LABELS_REQUEST,
    });

    try {
      dispatch({
        type: GET_LABELS_SUCCESS,
        payload: await getLabelsService(applicationId, labelCategoryId),
      });
    } catch (error) {
      dispatch({
        type: GET_LABELS_ERROR,
        error,
      });
    }
  };
};

export const createLabel = (
  applicationId: string,
  labelCategoryId: number,
  label: QuestionnaireLabel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_LABEL_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_LABEL_SUCCESS,
        payload: await createLabelService(applicationId, labelCategoryId, label),
      });
    } catch (error) {
      dispatch({
        type: CREATE_LABEL_ERROR,
        error,
      });
    }
  };
};

export const updateLabel = (
  applicationId: string,
  labelCategoryId: number,
  labelId: number,
  label: QuestionnaireLabel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_LABEL_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_LABEL_SUCCESS,
        payload: await updateLabelService(applicationId, labelCategoryId, labelId, label),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_LABEL_ERROR,
        error,
      });
    }
  };
};

export const deleteLabel = (applicationId: string, labelCategoryId: number, labelId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_LABEL_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_LABEL_SUCCESS,
        payload: await deleteLabelService(applicationId, labelCategoryId, labelId),
      });
    } catch (error) {
      dispatch({
        type: DELETE_LABEL_ERROR,
        error,
      });
    }
  };
};

export const getLabelCategories = (applicationId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_LABEL_CATEGORIES_REQUEST,
    });

    try {
      dispatch({
        type: GET_LABEL_CATEGORIES_SUCCESS,
        payload: await getLabelCategoriesService(applicationId),
      });
    } catch (error) {
      dispatch({
        type: GET_LABEL_CATEGORIES_ERROR,
        error,
      });
    }
  };
};

export const createLabelCategory = (
  applicationId: string,
  labelCategory: QuestionnaireLabelCategory,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_LABEL_CATEGORY_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_LABEL_CATEGORY_SUCCESS,
        payload: await createLabelCategoryService(applicationId, labelCategory),
      });
    } catch (error) {
      dispatch({
        type: CREATE_LABEL_CATEGORY_ERROR,
        error,
      });
    }
  };
};

export const clearMessages = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CLEAR_MESSAGES,
    });
  };
};

export const getAttributeValidators = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_ATTRIBUTE_VALIDATORS_REQUEST,
    });

    try {
      dispatch({
        type: GET_ATTRIBUTE_VALIDATORS_SUCCESS,
        payload: await getAttributeValidatorsService(),
      });
    } catch (error) {
      dispatch({
        type: GET_ATTRIBUTE_VALIDATORS_ERROR,
        error,
      });
    }
  };
};

export const createAttributeValidator = (attributeValidator: AttributeValidatorModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_ATTRIBUTE_VALIDATOR_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_ATTRIBUTE_VALIDATOR_SUCCESS,
        payload: await createAttributeValidatorService(attributeValidator),
      });
    } catch (error) {
      dispatch({
        type: CREATE_ATTRIBUTE_VALIDATOR_ERROR,
        error,
      });
    }
  };
};

export const updateAttributeValidator = (
  id: number,
  attributeValidator: AttributeValidatorModel,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ATTRIBUTE_VALIDATOR_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_ATTRIBUTE_VALIDATOR_SUCCESS,
        payload: await updateAttributeValidatorService(id, attributeValidator),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_ATTRIBUTE_VALIDATOR_ERROR,
        error,
      });
    }
  };
};

export const deleteAttributeValidator = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_ATTRIBUTE_VALIDATOR_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_ATTRIBUTE_VALIDATOR_SUCCESS,
        payload: await deleteAttributeValidatorService(id),
      });
    } catch (error) {
      dispatch({
        type: DELETE_ATTRIBUTE_VALIDATOR_ERROR,
        error,
      });
    }
  };
};

export const getRegexReplacers = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_ATTRIBUTE_REPLACERS_REQUEST,
    });

    try {
      dispatch({
        type: GET_ATTRIBUTE_REPLACERS_SUCCESS,
        payload: await getRegexReplacersService(),
      });
    } catch (error) {
      dispatch({
        type: GET_ATTRIBUTE_REPLACERS_ERROR,
        error,
      });
    }
  };
};

export const createRegexReplacer = (attributeValidator: RegexReplacerModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_ATTRIBUTE_REPLACER_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_ATTRIBUTE_REPLACER_SUCCESS,
        payload: await createRegexReplacerService(attributeValidator),
      });
    } catch (error) {
      dispatch({
        type: CREATE_ATTRIBUTE_REPLACER_ERROR,
        error,
      });
    }
  };
};

export const updateRegexReplacer = (id: number, regexReplacer: RegexReplacerModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ATTRIBUTE_REPLACER_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_ATTRIBUTE_REPLACER_SUCCESS,
        payload: await updateRegexReplacerService(id, regexReplacer),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_ATTRIBUTE_REPLACER_ERROR,
        error,
      });
    }
  };
};

export const deleteRegexReplacer = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_ATTRIBUTE_REPLACER_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_ATTRIBUTE_REPLACER_SUCCESS,
        payload: await deleteRegexReplacerService(id),
      });
    } catch (error) {
      dispatch({
        type: DELETE_ATTRIBUTE_REPLACER_ERROR,
        error,
      });
    }
  };
};

export const getExpressions = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_EXPRESSIONS_REQUEST,
    });

    try {
      dispatch({
        type: GET_EXPRESSIONS_SUCCESS,
        payload: await getExpressionsService(),
      });
    } catch (error) {
      dispatch({
        type: GET_EXPRESSIONS_ERROR,
        error,
      });
    }
  };
};

export const createExpression = (expression: ExpressionModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_EXPRESSION_REQUEST,
    });

    try {
      dispatch({
        type: CREATE_EXPRESSION_SUCCESS,
        payload: await createExpressionService(expression),
      });
    } catch (error) {
      dispatch({
        type: CREATE_EXPRESSION_ERROR,
        error,
      });
    }
  };
};

export const updateExpression = (id: number, expression: ExpressionModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EXPRESSION_REQUEST,
    });

    try {
      dispatch({
        type: UPDATE_EXPRESSION_SUCCESS,
        payload: await updateExpressionService(id, expression),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_EXPRESSION_ERROR,
        error,
      });
    }
  };
};

export const deleteExpression = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_EXPRESSION_REQUEST,
    });

    try {
      dispatch({
        type: DELETE_EXPRESSION_SUCCESS,
        payload: await deleteExpressionService(id),
      });
    } catch (error) {
      dispatch({
        type: DELETE_EXPRESSION_ERROR,
        error,
      });
    }
  };
};

export const setLabelsViewMode = (mode: string) => {
  return {
    type: SET_LABEL_CATEGORIES_VIEW_MODE,
    payload: mode,
  };
};
