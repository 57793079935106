import { SelectModel } from './common.model';

export interface QuestionnaireMessageMetadata {
  attributeKey?: string;
  validatorType?: QuestionnaireMessageMetadataValidatorType;
  validatorProperties?: ValidatorProperties;
  regexReplacers?: RegexReplacerSaveModel[];
  nextPageButton?: string;
  showAll?: boolean;
  showSize?: number;
  showNextButton?: boolean;
  cityChooseAllText?: string;
  cityChooseNoneText?: string;
  enableKoExitPoint?: boolean;
  path?: string;
  errorMessage?: string;
  hint?: string;
  key?: string;
  webviewUrl?: string;
  saveLocation?: string;
  type?: string;
  notFoundErrorText?: string;
  folderSlug?: string;
  labelCategorySlug?: string;
  parameters?: { [key: string]: string }[] | any[];
  maxFileSizeInBytes?: number;
  allowedFileMimeTypes?: string[];
  cantUploadErrorText?: string;
  tooLargeFileSizeErrorText?: string;
  notAllowedFileMimeTypeErrorText?: string;
  mainRoute?: boolean;
  faultTolerant?: boolean;
  carouselView?: boolean;
  carouselButtonText?: string;
  carouselDefaultImageUrl?: string;
  contextActions: { method: string; key?: string; value?: string; function?: string }[];
  analytics: { analyticsType: string; data: { source?: string } }[];
  actions: {
    method: string;
    actionType: string;
    data: {
      attributeKey?: string;
      attributeValue?: string;
      labelCategorySlug?: string;
      labelSlug?: string;
    };
  }[];
  perPageLabelSlugs: string[];
}

export enum QuestionnaireMessageMetadataValidatorType {
  NONE = 'NONE',
  REGEX_VALIDATOR = 'REGEX_VALIDATOR',
  LOCATION_VALIDATOR = 'LOCATION_VALIDATOR',
}

export interface QuestionnaireMessage {
  metadata: QuestionnaireMessageMetadata;
  id?: number;
  text: string;
  type: QuestionnaireMessageType;
  intent: string | null;
  checkpoint: boolean;
  slug: string | null;
  answers: QuestionnaireAnswer[];
  parentAnswers: QuestionnaireAnswer[];
  disabled: boolean;
  nextMessageIdIfDisabled: number | null;
}

export enum QuestionnaireMessageType {
  CLOSED_ENDED_ANSWERS = 'CLOSED_ENDED_ANSWERS',
  OPEN_ENDED_ANSWERS = 'OPEN_ENDED_ANSWERS',
  SIMPLE_TEXT_MESSAGE = 'SIMPLE_TEXT_MESSAGE',
  LOCATION_PART_OF_COUNTRY = 'LOCATION_PART_OF_COUNTRY',
  LOCATION_COUNTY = 'LOCATION_COUNTY',
  LOCATION_CITY = 'LOCATION_CITY',
  START_CONTROLLER = 'START_CONTROLLER',
  LOGIC_CONTROLLER = 'LOGIC_CONTROLLER',
  SHOW_POSITION_MESSAGE = 'SHOW_POSITION_MESSAGE',
  POSITION_PREFILTER_CRITERIAS_MESSAGE = 'POSITION_PREFILTER_CRITERIAS_MESSAGE',
  POSITION_APPLY_CONTROLLER = 'POSITION_APPLY_CONTROLLER',
  DOCUMENT_UPLOAD_CONTROLLER = 'DOCUMENT_UPLOAD_CONTROLLER',
  EXPORT_APPLICATION_TO_ATS_CONTROLLER = 'EXPORT_APPLICATION_TO_ATS_CONTROLLER',
  ACTION_CONTROLLER = 'ACTION_CONTROLLER',
  ANALYTICS_CONTROLLER = 'ANALYTICS_CONTROLLER',
  IMAGE_MESSAGE = 'IMAGE_MESSAGE',
  SHOW_INTERVIEW_EVENTS_MESSAGE = 'SHOW_INTERVIEW_EVENTS_MESSAGE',
  CONTEXT_CONTROLLER = 'CONTEXT_CONTROLLER',
  DOCUMENT_VIEWER_MESSAGE = 'DOCUMENT_VIEWER_MESSAGE',
  DYNAMIC_LABEL_MESSAGE = 'DYNAMIC_LABEL_MESSAGE',
  ATS_GATEWAY_CONTROLLER = 'ATS_GATEWAY_CONTROLLER',
}

export enum QuestionnaireAnswerActionType {
  NONE = 'NONE',
  LABEL_APPLICANT = 'LABEL_APPLICANT',
  ATTRIBUTE_APPLICANT = 'ATTRIBUTE_APPLICANT',
  ATTRIBUTE_APPLICANT_POSITION = 'ATTRIBUTE_APPLICANT_POSITION',
  INVISIBLE_ANSWER = 'INVISIBLE_ANSWER',
  ONE_CITY_AVAILABLE = 'ONE_CITY_AVAILABLE',
  NO_POSITION_FOUND = 'NO_POSITION_FOUND',
  ONE_LABEL_AVAILABLE = 'ONE_LABEL_AVAILABLE',
  ACTIVE_INTERVIEW_EVENT_EXISTS = 'ACTIVE_INTERVIEW_EVENT_EXISTS',
  NO_INTERVIEW_EVENT_FOUND = 'NO_INTERVIEW_EVENT_FOUND',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  DEFAULT = 'DEFAULT',
  DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD',
  START_ANSWER = 'START_ANSWER',
  ENABLED_KO_EXITPOINT = 'ENABLED_KO_EXITPOINT',
}

export interface QuestionnaireAnswer {
  id?: number;
  messageId?: number;
  text: string;
  editedText?: string;
  intent: string;
  editedIntent?: string;
  order: number;
  nextMessageId?: number | null;
  answerActionType: QuestionnaireAnswerActionType;
  answerActionRootData?: null | {
    label?: SelectModel;
    labelSlug?: string;
    attributeKey?: string;
    attributeRequired?: boolean;
    attributeMultiplicable?: boolean;
    type?: string;
  };
}

export interface QuestionnaireLabelCategory {
  id?: number;
  text: string;
  slug?: string;
  labels?: QuestionnaireLabel[];
}

export interface QuestionnaireLabel {
  id?: number;
  slug: string;
  text: string;
  order: number;
  parentLabel?: string;
  categoryId?: number;
}

export interface AttributeValidatorModel {
  id?: number;
  title: string;
  pattern: string;
}

export interface RegexReplacerSaveModel {
  pattern: string;
  replaceTo: string;
}

export interface ValidatorProperties {
  regularExpression?: string;
  isoRegionCode?: string;
}

export interface RegexReplacerModel extends RegexReplacerSaveModel {
  id?: number;
  title: string;
}

export interface ExpressionModel {
  id?: number;
  title: string;
  expression: string;
}
