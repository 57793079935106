import { Dispatch } from 'redux';

import {
  getDynamicMenusWithParams as getDynamicMenusService,
  uploadDynamicDataset as createDynamicMenuService,
} from 'src/redux/services/dynamic-dataset.service';
import { PageQueryParams, DynamicMenuModel, DynamicDatasetModel } from 'src/shared/models';

export const GET_DYNAMIC_MENUS = 'GET_DYNAMIC_MENUS';
export const GET_DYNAMIC_MENUS_SUCCESS = 'GET_DYNAMIC_MENUS_SUCCESS';
export const GET_DYNAMIC_MENUS_ERROR = 'GET_DYNAMIC_MENUS_ERROR';

export const CREATE_DYNAMIC_FAQMENU = 'DynamicMenus/CREATE_DYNAMIC_FAQMENU';
export const CREATE_DYNAMIC_FAQMENU_SUCCESS = 'DynamicMenus/CREATE_DYNAMIC_FAQMENU_SUCCESS';
export const CREATE_DYNAMIC_FAQMENU_ERROR = 'DynamicMenus/CREATE_DYNAMIC_FAQMENU_ERROR';

export const getDynamicMenus = (appId: string, queryParams: PageQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_DYNAMIC_MENUS,
    });

    const dynamicFaq = (await getDynamicMenusService(appId, queryParams)) as DynamicMenuModel[];

    try {
      dispatch({
        type: GET_DYNAMIC_MENUS_SUCCESS,
        payload: {
          content: dynamicFaq,
          pageable: false,
          totalPages: 1,
          totalElements: dynamicFaq.length,
          numberOfElements: dynamicFaq.length,
          first: true,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_DYNAMIC_MENUS_ERROR,
        error,
      });
    }
  };
};

export const createDynamicMenu = (appId: string, DynamicMenuData: DynamicDatasetModel) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CREATE_DYNAMIC_FAQMENU,
    });

    const response = await createDynamicMenuService(appId, DynamicMenuData);

    try {
      dispatch({
        type: CREATE_DYNAMIC_FAQMENU_SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: CREATE_DYNAMIC_FAQMENU_ERROR,
        error,
      });
    }
  };
};
