import React from 'react';
import classNames from 'classnames';
import { ResponsiveLine } from '@nivo/line';
import { useTranslation } from 'react-i18next';

const ApplicationSocialCard: React.FC<any> = (props: any) => {
  const { t } = useTranslation();
  const { children, className, variant, icon, conversations, visitors, chartData } = props;

  if (!variant || ['messenger', 'hangouts', 'livechat', 'viber'].indexOf(variant) < 0) {
    return null;
  }

  const status = conversations ? 'active' : 'inactive';
  const back = classNames(`bg-${variant}`, status);

  const classCard = 'brand-card';
  const classCardHeader = classNames(`${classCard}-header`, back);
  const classCardBody = classNames(`${classCard}-body`);
  const classes = classNames(classCard, className);
  const theme = {
    background: 'transparent',
    axis: {
      fontSize: '14px',
      tickColor: '#999',
      ticks: {
        line: {
          stroke: '#fff',
        },
        text: {
          fill: '#fff',
        },
      },
      legend: {
        text: {
          fill: '#fff',
        },
      },
    },
  };
  return (
    <div className={classes}>
      <div className={classCardHeader}>
        <i className={icon} />
        {children}
        <div className="chart-wrapper social-card">
          {chartData && visitors && conversations ? (
            <ResponsiveLine
              data={chartData}
              curve="basis"
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              axisTop={null}
              axisRight={null}
              axisBottom={null}
              axisLeft={null}
              colors={{ scheme: 'blues' }}
              enableSlices="x"
              enableArea
              theme={theme}
              enablePoints={false}
              enableGridX={false}
              enableGridY={false}
              pointSize={8}
              pointBorderWidth={1}
              pointLabelYOffset={-12}
              useMesh
              enableCrosshair={false}
            />
          ) : null}
        </div>
      </div>
      <div className={classCardBody}>
        <div>
          <div className="text-value">{conversations}</div>
          <div className="text-uppercase text-muted small">{t('applicationSummary.conversations')}</div>
        </div>
        <div>
          <div className="text-value">{visitors}</div>
          <div className="text-uppercase text-muted small">{t('applicationSummary.visitors')}</div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationSocialCard;
