import React from 'react';
import ButtonDropdown from 'src/shared/components/ButtonDropdown';

import {
  messageType,
  messageActionTypeToDisplay,
  messageTypeIcon,
} from 'src/shared/constants/questionnaire.constans';

interface Props {
  onClick: (id: string) => void;
}

const MessageCreateDropdown: React.FC<Props> = ({ onClick }) => {
  const dropdownItems = [
    {
      id: messageType.CLOSED_ENDED_ANSWERS,
      text: messageActionTypeToDisplay.CLOSED_ENDED_ANSWERS,
      icon: messageTypeIcon.CLOSED_ENDED_ANSWERS,
    },
    {
      id: messageType.OPEN_ENDED_ANSWERS,
      text: messageActionTypeToDisplay.OPEN_ENDED_ANSWERS,
      icon: messageTypeIcon.OPEN_ENDED_ANSWERS,
    },
    {
      id: messageType.SIMPLE_TEXT_MESSAGE,
      text: messageActionTypeToDisplay.SIMPLE_TEXT_MESSAGE,
      icon: messageTypeIcon.SIMPLE_TEXT_MESSAGE,
    },
    {
      id: messageType.LOGIC_CONTROLLER,
      text: messageActionTypeToDisplay.LOGIC_CONTROLLER,
      icon: messageTypeIcon.LOGIC_CONTROLLER,
    },
    {
      id: messageType.LOCATION_MESSAGE,
      text: messageActionTypeToDisplay.LOCATION_MESSAGE,
      icon: messageTypeIcon.LOCATION_MESSAGE,
    },
    {
      id: messageType.SHOW_POSITION_MESSAGE,
      text: messageActionTypeToDisplay.SHOW_POSITION_MESSAGE,
      icon: messageTypeIcon.SHOW_POSITION_MESSAGE,
    },
    {
      id: messageType.POSITION_APPLY_CONTROLLER,
      text: messageActionTypeToDisplay.POSITION_APPLY_CONTROLLER,
      icon: messageTypeIcon.POSITION_APPLY_CONTROLLER,
    },
    {
      id: messageType.POSITION_PREFILTER_CRITERIAS_MESSAGE,
      text: messageActionTypeToDisplay.POSITION_PREFILTER_CRITERIAS_MESSAGE,
      icon: messageTypeIcon.POSITION_PREFILTER_CRITERIAS_MESSAGE,
    },
    {
      id: messageType.EXPORT_APPLICATION_TO_ATS_CONTROLLER,
      text: messageActionTypeToDisplay.EXPORT_APPLICATION_TO_ATS_CONTROLLER,
      icon: messageTypeIcon.EXPORT_APPLICATION_TO_ATS_CONTROLLER,
    },
    {
      id: messageType.DOCUMENT_UPLOAD_CONTROLLER,
      text: messageActionTypeToDisplay.DOCUMENT_UPLOAD_CONTROLLER,
      icon: messageTypeIcon.DOCUMENT_UPLOAD_CONTROLLER,
    },
    {
      id: messageType.DOCUMENT_VIEWER_MESSAGE,
      text: messageActionTypeToDisplay.DOCUMENT_VIEWER_MESSAGE,
      icon: messageTypeIcon.DOCUMENT_VIEWER_MESSAGE,
    },
    {
      id: messageType.START_CONTROLLER,
      text: messageActionTypeToDisplay.START_CONTROLLER,
      icon: messageTypeIcon.START_CONTROLLER,
    },
    {
      id: messageType.IMAGE_MESSAGE,
      text: messageActionTypeToDisplay.IMAGE_MESSAGE,
      icon: messageTypeIcon.IMAGE_MESSAGE,
    },
    {
      id: messageType.ACTION_CONTROLLER,
      text: messageActionTypeToDisplay.ACTION_CONTROLLER,
      icon: messageTypeIcon.ACTION_CONTROLLER,
    },
    {
      id: messageType.ANALYTICS_CONTROLLER,
      text: messageActionTypeToDisplay.ANALYTICS_CONTROLLER,
      icon: messageTypeIcon.ANALYTICS_CONTROLLER,
    },
    {
      id: messageType.CONTEXT_CONTROLLER,
      text: messageActionTypeToDisplay.CONTEXT_CONTROLLER,
      icon: messageTypeIcon.CONTEXT_CONTROLLER,
    },
    {
      id: messageType.DYNAMIC_LABEL_MESSAGE,
      text: messageActionTypeToDisplay.DYNAMIC_LABEL_MESSAGE,
      icon: messageTypeIcon.DYNAMIC_LABEL_MESSAGE,
    },
    {
      id: messageType.SHOW_INTERVIEW_EVENTS_MESSAGE,
      text: messageActionTypeToDisplay.SHOW_INTERVIEW_EVENTS_MESSAGE,
      icon: messageTypeIcon.SHOW_INTERVIEW_EVENTS_MESSAGE,
    },
    {
      id: messageType.ATS_GATEWAY_CONTROLLER,
      text: messageActionTypeToDisplay.ATS_GATEWAY_CONTROLLER,
      icon: messageTypeIcon.ATS_GATEWAY_CONTROLLER,
    },

  ];

  return <ButtonDropdown onClick={onClick} items={dropdownItems} titleKey="common.create" />;
};

export default MessageCreateDropdown;
