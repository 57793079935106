import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
// @ts-ignore
import { AppSwitch } from '@coreui/react';

import { getForm } from 'src/redux/actions/forms.action';
import { deleteQuestion, sortQuestions, updateQuestion } from 'src/redux/services/forms.service';
import { EditableTextInput, List, TagPill } from 'src/shared/components';
import {
  FormQuestionModel,
  Operations,
  QuestionOptionListAnswerConfiguration,
  SelectModel,
  TextAnswerConfiguration,
} from 'src/shared/models';
import { ANSWER_TYPES } from 'src/shared/models/event.model';
import { JumpToEndSignal } from './QuestionsAndAnswers';
import OptionsAnswer from './QuestionsAndAnswers/OptionsAnswer';
import { Formik } from 'formik';
import { questionsAnswerSchema } from 'src/shared/schemas/validation.schema';

interface QuestionsProps {
  formQuestions: FormQuestionModel[];
  formQuestionsLoading: boolean;
  formQuestionsLoaded: boolean;
  formQuestionsError: boolean;
  isEditingDisabled: boolean;
  getForm: (appId: string, formId: string) => void;
}

const Questions: FC<QuestionsProps> = ({
  formQuestions,
  formQuestionsLoading,
  formQuestionsLoaded,
  formQuestionsError,
  isEditingDisabled,
  getForm,
}) => {
  const { t } = useTranslation();
  const { appId, formId } = useParams<{ appId: string; formId: string }>();

  const jumpToSlugOptions: SelectModel[] = useMemo(() => {
    let model: SelectModel[] = [{ label: t('common.noJump'), value: null }];
    formQuestions.forEach(question => {
      model.push({ label: question.text, value: question.slug });
    });
    model.push({ label: t('common.jumpToEnd'), value: '##END##' });
    return model;
  }, [formQuestions, t]);

  const handleDelete = async (item: FormQuestionModel) => {
    await deleteQuestion(appId, formId, item.id.toString());
    getForm(appId, formId);
  };

  const handleQuestionEdit = async (item: FormQuestionModel) => {
    await updateQuestion(appId, formId, item.id.toString(), item);
    getForm(appId, formId);
  };

  const onDragEnd = async (orderedItems: Array<any>) => {
    await sortQuestions(
      appId,
      formId,
      orderedItems.map(e => e.id),
    );
    getForm(appId, formId);
  };

  const renderAnswer = (item: FormQuestionModel) => {
    const handleAnswerEdit = async (oldValue: any, newValue: string) => {
      if (newValue.length === 0) {
        return;
      }

      if (item.answerConfiguration.type === ANSWER_TYPES.TEXT) {
        const textAnswer = item.answerConfiguration as TextAnswerConfiguration;
        if (!textAnswer.validationErrorMessage) {
          //In case of free text edit nothing to do
          return;
        } else {
          textAnswer.validationErrorMessage = newValue;
          await handleQuestionEdit(item);
        }
      }
    };
    const renderTagPill = (text: string, id: number) => {
      return (
        <TagPill
          key={id}
          item={text}
          isDeleteable={false}
          isEditable={text !== t('forms.freeText')}
          handleEdit={handleAnswerEdit}
          style={{ display: 'inline-flex' }}
        />
      );
    };
    const renderOptionsEditor = (current: { answer: any }, id: number) => {
      let formRef: any = null;

      const getLabel = (value: string) => {
        if (value === null) {
          return t('common.noJump');
        } if (value === JumpToEndSignal) {
          return t('common.jumpToEnd');
        }
          try {
            return jumpToSlugOptions.filter(jo => jo.value === value)[0].label;
          } catch (e) {
            console.error(e);
            return '';
          }

      };

      const onEditorModalSubmit = async () => {
        const {values} = formRef;
        const isValid = await questionsAnswerSchema().isValid(values);

        if (!isValid) {
          console.log('RRRR',);
          return true;
        }

        // eslint-disable-next-line no-param-reassign
        (item.answerConfiguration as QuestionOptionListAnswerConfiguration).options[id] = {
          ...values.answer,
          ...{ jumpToSlug: values.answer.jumpToSlug.value },
        };
        handleQuestionEdit(item);
      };

      if (!current.answer) return <></>;

      // eslint-disable-next-line no-param-reassign
      current.answer = {
        ...current.answer,
        ...{
          jumpToSlug: {
            value: current.answer.jumpToSlug,
            label: getLabel(current.answer.jumpToSlug),
          },
        },
      };

      return (
        <TagPill
          key={id}
          item={current.answer}
          isDeleteable={false}
          isEditable={false}
          isEditableInModal
          modalEditorContent={
            // eslint-disable-next-line no-return-assign
            <Formik innerRef={ref => (formRef = ref)} initialValues={current} onSubmit={() => {}} validationSchema={questionsAnswerSchema()} validateOnChange validateOnBlur>
              <OptionsAnswer jumpToSlugOptions={jumpToSlugOptions} />
            </Formik>
          }
          handleEdit={handleAnswerEdit}
          style={{ display: 'inline-flex' }}
          onEditorModalSubmit={onEditorModalSubmit}
        />
      );
    };

    let answers = [];

    if (item.answerConfiguration.type === ANSWER_TYPES.OPTIONS) {
      const opts = (item.answerConfiguration as QuestionOptionListAnswerConfiguration).options;
      for (var key in opts) {
        answers.push(opts[key]);
      }
      return <div>{answers.map((a, i) => renderOptionsEditor({ answer: a }, i))}</div>;
    } else if (item.answerConfiguration.type === ANSWER_TYPES.TEXT) {
      const textAnswer = item.answerConfiguration as TextAnswerConfiguration;
      if (!textAnswer.validationErrorMessage) {
        answers.push(t('forms.freeText'));
      } else {
        answers.push(textAnswer.validationErrorMessage);
      }
      return <div>{answers.map((a, i) => renderTagPill(a as string, i))}</div>;
    }
  };

  return (
    <fieldset className="border p-3">
      <legend className="col-form-label w-auto font-weight-bold">{t('common.questions')}</legend>
      <List
        hidePager
        data={formQuestions?.sort((q1, q2) => q1.order - q2.order)}
        totalElements={formQuestions?.length}
        loaded={formQuestionsLoaded}
        loading={formQuestionsLoading}
        error={formQuestionsError}
        fetchData={() => {}}
        noDataLabel={t('forms.noQuestionAnswers')}
        deleteFunction={handleDelete}
        deleteTitle={t('forms.deleteQuestionAnswer')}
        deleteText={t('forms.deleteQuestionAnswerConfirmMessage')}
        updatePermission="form_element_update"
        deletePermission="form_element_delete"
        onDragEnd={isEditingDisabled ? undefined : onDragEnd}
        fields={[
          {
            key: 'text',
            label: t('common.text'),
            render: item => (
              <div style={{ overflowWrap: 'anywhere' }}>
                <EditableTextInput
                  id={item.id!}
                  data={item.text}
                  name="key"
                  updateData={(appId: string, id: number, value: string) => {
                    item.text = value;
                    handleQuestionEdit(item);
                  }}
                  permission="form_element_update"
                />
              </div>
            ),
          },
          {
            key: 'answers',
            label: t('common.answers'),
            render: renderAnswer,
          },
          {
            key: 'required',
            label: t('common.required'),
            render: item => {
              return (
                <AppSwitch
                  //@ts-ignore
                  checked={item.answerConfiguration.required}
                  name="required"
                  className="ml-2 mr-2"
                  variant="pill"
                  color="primary"
                  size="lg"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const checked = e.currentTarget.checked;
                    //@ts-ignore
                    item.answerConfiguration.required = checked;
                    handleQuestionEdit(item);
                  }}
                />
              );
            },
          },
          {
            key: 'skipButtonText',
            label: t('common.skipButtonText'),
            render: item => (
              <div style={{ overflowWrap: 'anywhere' }}>
                <EditableTextInput
                  id={item.id!}
                  //@ts-ignore
                  data={item.answerConfiguration.skipButtonText}
                  name="key"
                  updateData={(appId: string, id: number, value: string | null) => {
                    if (value?.length === 0) {
                      value = null;
                    }
                    //@ts-ignore
                    item.answerConfiguration.skipButtonText = value;
                    handleQuestionEdit(item);
                  }}
                  permission="form_element_update"
                />
              </div>
            ),
          },
          {
            key: 'allowedFileExtensions',
            label: t('common.allowedFileExtensions'),
            render: item => (
              <div style={{ overflowWrap: 'anywhere' }}>
                <EditableTextInput
                  id={item.id!}
                  //@ts-ignore
                  data={item.answerConfiguration.allowedFileExtensions}
                  name="key"
                  updateData={(appId: string, id: number, value: string | null) => {
                    if (value?.length === 0) {
                      value = null;
                    }
                    //@ts-ignore
                    item.answerConfiguration.allowedFileExtensions = value;
                    handleQuestionEdit(item);
                  }}
                  permission="form_element_update"
                />
              </div>
            ),
          },
          {
            key: 'invalidFileExtensionMessage',
            label: t('common.invalidFileExtensionMessage'),
            render: item => (
              <div style={{ overflowWrap: 'anywhere' }}>
                <EditableTextInput
                  id={item.id!}
                  //@ts-ignore
                  data={item.answerConfiguration.invalidFileExtensionMessage}
                  name="key"
                  updateData={(appId: string, id: number, value: string | null) => {
                    if (value?.length === 0) {
                      value = null;
                    }
                    //@ts-ignore
                    item.answerConfiguration.invalidFileExtensionMessage = value;
                    handleQuestionEdit(item);
                  }}
                  permission="form_element_update"
                />
              </div>
            ),
          },
          {
            key: 'allowedMaxFileSizeInBytes',
            label: t('common.allowedMaxFileSizeInBytes'),
            render: item => (
              <div style={{ overflowWrap: 'anywhere' }}>
                <EditableTextInput
                  id={item.id!}
                  //@ts-ignore
                  data={item.answerConfiguration.allowedMaxFileSizeInBytes}
                  name="key"
                  updateData={(appId: string, id: number, value: string | null) => {
                    if (value?.length === 0) {
                      value = null;
                    }
                    //@ts-ignore
                    item.answerConfiguration.allowedMaxFileSizeInBytes = value;
                    handleQuestionEdit(item);
                  }}
                  permission="form_element_update"
                />
              </div>
            ),
          },
          {
            key: 'invalidFileSizeMessage',
            label: t('common.invalidFileSizeMessage'),
            render: item => (
              <div style={{ overflowWrap: 'anywhere' }}>
                <EditableTextInput
                  id={item.id!}
                  //@ts-ignore
                  data={item.answerConfiguration.invalidFileSizeMessage}
                  name="key"
                  updateData={(appId: string, id: number, value: string | null) => {
                    if (value?.length === 0) {
                      value = null;
                    }
                    //@ts-ignore
                    item.answerConfiguration.invalidFileSizeMessage = value;
                    handleQuestionEdit(item);
                  }}
                  permission="form_element_update"
                />
              </div>
            ),
          },

          {
            key: 'operations',
            label: t('common.operations'),
            operations: [Operations.DELETE],
          },
        ]}
      />
    </fieldset>
  );
};

const mapStateToProps = (state: any) => ({
  formQuestions: state.form.data?.form?.elements || [],
  formQuestionsLoading: state.form.loading,
  formQuestionsLoaded: state.form.loaded,
  formQuestionsError: state.form.error,
});

const mapDispatchToProps = {
  getForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
