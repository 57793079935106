import React, { Fragment } from 'react';
import { UncontrolledTooltip, Button } from 'reactstrap';
import moment from 'moment';

import i18n from '../../../i18n';

import {
  AuditLogModel,
  AuditLogEventModel,
  MetaDataModel,
} from '../../../shared/models/audit-log.model';
import { UserModel } from '../../../shared/models/user.model';

import CheckPermission from '../../../shared/components/CheckPermission';
import styles from '../AuditLog.module.scss';

const METADATA_VALUES = [
  'updatedDocument',
  'savedDocument',
  'deletedDocument',
  'uploadedDocument',
  'updatedFolder',
  'savedFolder',
  'deletedDocument',
  'updatedUser',
  'createdUser',
  'deletedUser',
  'currentUser',
  'originalUser',
  'newUser',
  'answers',
  'email',
  'oldSubscribingList',
  'newSubscribingList',
  'oldEmployeeTag',
  'newEmployeeTag',
  'positionPatchDto',
  'positionId',
  'messageId',
  'rootAnswerIds'
];

interface Props {
  events: AuditLogEventModel[];
  auditLogs: AuditLogModel[];
  revealUser: Function;
  revealedUsers: UserModel[];
  toggleModal: Function;
}

const getRevealedUser = (revealedUsers: UserModel[], currentUserId: number) => {
  const user = revealedUsers.find(revealedUser => revealedUser.id === currentUserId);
  return user ? `${user.firstName} ${user.lastName}` : null;
};

const renderRevealButton = (revealUser: Function, userId: number, buttonId: string) => {
  return (
    <Fragment>
      <CheckPermission variant="enableIf" permissions={['user_read']}>
        <button
          type="button"
          id={buttonId}
          className={`btn btn-ghost-secondary ${styles.reveal}`}
          onClick={() => revealUser(userId)}
        >
          <i className="far fa-eye-slash" />
        </button>
      </CheckPermission>

      <UncontrolledTooltip target={buttonId}>{i18n.t('auditLog.revealUser')}</UncontrolledTooltip>
    </Fragment>
  );
};

const renderEventTexts = (eventType: string, events: AuditLogEventModel[]) => {
  const event: AuditLogEventModel[] = events.filter(e => e.value === eventType);

  if (event.length > 0) {
    return (
      <Fragment>
        <p>{eventType}</p>
        <p>{event[0].description}</p>
      </Fragment>
    );
  }

  return null;
};

const showViewDetailsButton = (metadata: MetaDataModel) => {
  if (metadata) {
    return METADATA_VALUES.some(item => {
      return item in metadata;
    });
  }

  return null;
};

const renderTime = (time: string) => {
  return (
    <Fragment>
      <p>{moment(time).format('MMMM D, YYYY')}</p>
      <p>{moment(time).format('LT')}</p>
    </Fragment>
  );
};

const AuditLogTableRow: React.FC<Props> = props => {
  const { events, auditLogs, revealUser, revealedUsers, toggleModal } = props;

  return (
    <Fragment>
      {auditLogs.map((log: AuditLogModel, idx: number) => {
        const revealButtonId = `rb-${idx}`;
        const appName = (log.metadata && log.metadata.appName) || '';
        const appId = log.metadata && log.metadata.appId ? `/ #${log.metadata.appId}` : '';

        return (
          <tr key={log.id}>
            <td className={styles.logId}>{log.id}</td>
            <td className={styles.time}>{renderTime(log.time)}</td>
            <td className={styles.application}>{`${appName} ${appId}`}</td>
            <td className={styles.user}>
              {getRevealedUser(revealedUsers, log.userId) ||
                renderRevealButton(revealUser, log.userId, revealButtonId)}
            </td>
            <td className={styles.eventType}>{renderEventTexts(log.type, events)}</td>
            <td className={styles.viewDetails}>
              {showViewDetailsButton(log.metadata) ? (
                <Button
                  color="primary"
                  onClick={() =>
                    toggleModal({ logEventType: log.type, createdAt: log.time, ...log.metadata })
                  }
                >
                  Details
                </Button>
              ) : (
                'NO DATA'
              )}
            </td>
          </tr>
        );
      })}
    </Fragment>
  );
};

export default AuditLogTableRow;
