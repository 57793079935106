import { StateModel, SurveyQuestionAnswerModel } from 'src/shared/models';
import {
  READ_SURVEY_QUESTION_ANSWERS,
  READ_SURVEY_QUESTION_ANSWERS_SUCCESS,
  READ_SURVEY_QUESTION_ANSWERS_ERROR,
  REVERT_SURVEY_QUESTION_ANSWERS,
} from '../actions/surveys.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [] as SurveyQuestionAnswerModel[],
};

export const surveyQuestionAnswersReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case READ_SURVEY_QUESTION_ANSWERS:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case READ_SURVEY_QUESTION_ANSWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload,
      };
    case READ_SURVEY_QUESTION_ANSWERS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error,
      };
    case REVERT_SURVEY_QUESTION_ANSWERS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
