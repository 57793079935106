import React, { Fragment } from 'react';
import i18n from 'src/i18n';
import { Position } from 'src/shared/models/audit-log.model';

interface Props {
  positionId: string | undefined;
  positionPatchDto: Position | undefined;
}

const Positions: React.FC<Props> = ({ positionId, positionPatchDto }) => {
  return (
    <Fragment>
      <table className="table table-sm">
        <tbody>
          {positionId && (
            <tr>
              <td>{i18n.t('common.id')}</td>
              <td>{positionId}</td>
            </tr>
          )}
          {positionPatchDto && (
            <>
              <tr>
                <td>{i18n.t('common.name')}</td>
                <td>{positionPatchDto.name}</td>
              </tr>
              <tr>
                <td>{i18n.t('common.startsAt')}</td>
                <td>{positionPatchDto.startsAt}</td>
              </tr> 
              <tr>
                <td>{i18n.t('common.expiresAt')}</td>
                <td>{positionPatchDto.expiresAt}</td>
              </tr>
              <tr>
                <td>{i18n.t('common.externalId')}</td>
                <td>{positionPatchDto.externalId}</td>
              </tr>
              <tr>
                <td>{i18n.t('common.status')}</td>
                <td>{positionPatchDto.status}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </Fragment>
  );
};

export default Positions;
