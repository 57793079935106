import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Table,
} from 'reactstrap';
import moment from 'moment';
import capitalize from 'lodash/capitalize';

import { PositionModel } from 'src/shared/models/positions.model';
import {
  TalentPoolApplicantModel,
  ApplicantLabelModel,
  ApplicantAttributeModel,
} from 'src/shared/models/talent-pool.model';
import { renderPlatformLogo } from 'src/shared/utils/platforms.util';

import { getTalentPoolApplicant } from 'src/redux/actions/talent-pool.action';

import TagPill from 'src/shared/components/TagPill';

interface Props {
  isOpen: boolean;
  closeModal: Function;
  selectedApplicantId: number | undefined;
  applicant: TalentPoolApplicantModel;
  getTalentPoolApplicant: Function;
  getAttributeValueByKey: (attributes: ApplicantAttributeModel[], key: string) => string;
}

const TalentPoolApplicantModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  selectedApplicantId,
  applicant,
  getTalentPoolApplicant,
  getAttributeValueByKey,
}) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();

  useEffect(() => {
    if (applicant?.id !== selectedApplicantId) {
      getTalentPoolApplicant(appId, selectedApplicantId);
    }
  }, [appId, applicant, getTalentPoolApplicant, selectedApplicantId]);

  const renderGeneralInformation = (applicant: TalentPoolApplicantModel) => (
    <Row className="p-3 mb-3 bg-light">
      <Col>
        <h3 className="d-flex align-items-center">
          {getAttributeValueByKey(applicant.attributes, 'first_name')}{' '}
          {getAttributeValueByKey(applicant.attributes, 'last_name')}{' '}
        </h3>

        <p className="mb-1"> {getAttributeValueByKey(applicant.attributes, 'email')}</p>
        <p className="mb-1">{getAttributeValueByKey(applicant.attributes, 'phone')}</p>
      </Col>
      <Col className="text-right">
        <div className="mb-2">{renderPlatformLogo(applicant?.userProfile?.messagingPlatform)}</div>
        <p>
          {t('talentPool.privacyStatementAcceptedAt')}
          <span className="d-block">
            {applicant?.userProfile?.privacyStatementAcceptedAt
              ? moment(applicant?.userProfile?.privacyStatementAcceptedAt).format('LL')
              : '—'}
          </span>
        </p>

        <p className="m-0">
          {t('talentPool.questionnaireFilledAt')}
          <span className="d-block">
            {applicant?.userProfile?.surveyFilledAt
              ? moment(applicant?.userProfile?.surveyFilledAt).format('LL')
              : '—'}
          </span>
        </p>
      </Col>
    </Row>
  );

  const renderPositions = (positions: PositionModel[]) => {
    const items = positions.map(position => (
      <ListGroupItem
        className="d-flex justify-content-between align-items-center"
        style={{ cursor: 'auto' }}
        key={position.id}
      >
        <span>{position.name}</span>
        <span>
          <p className="m-0 text-muted">{t('common.appliedAt')}</p>
          {moment(position.appliedAt).format('LLL')}
        </span>
      </ListGroupItem>
    ));

    return (
      <Fragment>
        <h6 className="mb-3">{t('common.positions')}</h6>
        {items.length ? (
          <Row>
            <Col>
              <ListGroup>{items}</ListGroup>
            </Col>
          </Row>
        ) : (
          <p className="text-center text-muted">{t('positions.noPositionsFound')}</p>
        )}
      </Fragment>
    );
  };

  const renderPositionFilterQuestions = (labels: ApplicantLabelModel[]) => {
    const items = labels.map((label: ApplicantLabelModel) => (
      <Row key={label.id}>
        <Col className="d-flex flex-wrap">
          <p className="mb-0">{capitalize(label.category.text)}</p>
        </Col>
        <Col className="d-flex flex-wrap">
          <TagPill item={label.text} isEditable={false} isDeleteable={false} />
        </Col>
      </Row>
    ));

    return (
      <Fragment>
        <h6 className="mb-3">{t('common.positionFilterQuestions')}</h6>
        {items.length ? (
          items
        ) : (
          <p className="text-center text-muted">{t('positions.noPrefilterCriteriasFound')}</p>
        )}
      </Fragment>
    );
  };

  const renderAllAttributes = (applicant: TalentPoolApplicantModel) => {
    return (
      applicant.attributes.length && (
        <>
          <h6 className="mb-3">{t('talentPool.applicantAttributes')}</h6>
          <Table className="mb-4" striped hover size="sm">
            <thead>
              <tr>
                <th>{t('common.key')}</th>
                <th>{t('common.value')}</th>
                <th>{t('common.createdAt')}</th>
              </tr>
            </thead>
            <tbody>
              {applicant.attributes.map((attribute: ApplicantAttributeModel) => (
                <tr key={attribute.id}>
                  <td>{attribute.key}</td>
                  <td>{attribute.value}</td>
                  <td>{attribute.createdAt}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )
    );
  };

  const renderApplicantDetails = (applicant: TalentPoolApplicantModel) => (
    <Fragment>
      {renderGeneralInformation(applicant)}
      {renderAllAttributes(applicant)}
      <Row>
        <Col className="border-right">{renderPositions(applicant.positions)}</Col>
        <Col>{renderPositionFilterQuestions(applicant.labels)}</Col>
      </Row>
    </Fragment>
  );

  return (
    <Fragment>
      {applicant && (
        <Modal isOpen={isOpen} size="lg" toggle={() => closeModal(false)}>
          <ModalHeader>{t('talentPool.applicantDetails')}</ModalHeader>
          <ModalBody className="pt-0">
            {applicant.id && renderApplicantDetails(applicant)}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => closeModal(false)}>
              {t('common.close')}
            </Button>{' '}
          </ModalFooter>
        </Modal>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  applicant: state.talentPoolApplicant.data,
});

const mapDispatchToProps = {
  getTalentPoolApplicant,
};

export default connect(mapStateToProps, mapDispatchToProps)(TalentPoolApplicantModal);
