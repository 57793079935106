import { StateModel } from '../../shared/models/default-state.model';
import { TrainingDataModel } from 'src/shared/models/training-data.model';

import {
  GET_TRAINING_DATA_REQUEST,
  GET_TRAINING_DATA_SUCCESS,
  GET_TRAINING_DATA_ERROR,
  CREATE_TRAINING_DATA_REQUEST,
  CREATE_TRAINING_DATA_SUCCESS,
  CREATE_TRAINING_DATA_ERROR,
  UPDATE_TRAINING_DATA_REQUEST,
  UPDATE_TRAINING_DATA_SUCCESS,
  UPDATE_TRAINING_DATA_ERROR,
  DELETE_TRAINING_DATA_REQUEST,
  DELETE_TRAINING_DATA_SUCCESS,
  DELETE_TRAINING_DATA_ERROR,
  EXPORT_TRAINING_DATA_REQUEST,
  EXPORT_TRAINING_DATA_SUCCESS,
  EXPORT_TRAINING_DATA_ERROR,
  SET_TRAINING_DATA_VIEW_MODE,
} from '../actions/training-data.action';
import { VIEW_MODE_KEY } from 'src/shared/constants/training-data.constants';

interface TrainingDataStateModel {
  resources: StateModel;
  create: StateModel;
  update: StateModel;
  delete: StateModel;
  export: StateModel;
  viewMode: string;
}

const INITIAL_STATE = {
  resources: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  create: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  update: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  delete: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  export: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  viewMode: 'normal',
};

// eslint-disable-next-line import/prefer-default-export
export const trainingDataReducer = (state: TrainingDataStateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_TRAINING_DATA_REQUEST:
      return {
        ...state,
        resources: {
          ...state.resources,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case GET_TRAINING_DATA_SUCCESS:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case GET_TRAINING_DATA_ERROR:
      return {
        ...state,
        resources: {
          ...state.resources,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case CREATE_TRAINING_DATA_REQUEST:
      return {
        ...state,
        create: {
          ...state.create,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case CREATE_TRAINING_DATA_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: true,
          data: action.payload,
        },
        resources: {
          ...state.resources,
          data: {
            ...state.resources.data,
            content: [...state.resources.data.content, action.payload],
          },
        },
      };

    case CREATE_TRAINING_DATA_ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case UPDATE_TRAINING_DATA_REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case UPDATE_TRAINING_DATA_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          loaded: true,
          data: action.payload,
        },
        resources: {
          ...state.resources,
          data: {
            ...state.resources.data,
            content: state.resources.data.content.map((td: TrainingDataModel) =>
              td.id === action.payload.id ? action.payload : td,
            ),
          },
        },
      };

    case UPDATE_TRAINING_DATA_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case DELETE_TRAINING_DATA_REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case DELETE_TRAINING_DATA_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          loaded: true,
          data: action.payload,
        },
        resources: {
          ...state.resources,
          data: {
            ...state.resources.data,
            content: state.resources.data.content.filter(
              (td: TrainingDataModel) => td.id !== action.payload.id,
            ),
          },
        },
      };

    case DELETE_TRAINING_DATA_ERROR:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case EXPORT_TRAINING_DATA_REQUEST:
      return {
        ...state,
        export: {
          ...state.export,
          error: null,
          loading: true,
          loaded: false,
        },
      };

    case EXPORT_TRAINING_DATA_SUCCESS:
      return {
        ...state,
        export: {
          ...state.export,
          loading: false,
          loaded: true,
          data: action.payload,
        },
      };

    case EXPORT_TRAINING_DATA_ERROR:
      return {
        ...state,
        export: {
          ...state.export,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case SET_TRAINING_DATA_VIEW_MODE:
      localStorage.setItem(VIEW_MODE_KEY, action.payload);
      return {
        ...state,
        viewMode: action.payload,
      };

    default:
      return state;
  }
};
