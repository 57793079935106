import { get, patch, post, del } from '../../shared/services/http.service';
import { PersonaModel } from '../../shared/models/persona.model';


export const getPersonas = async () => {
  return get('/personas');
};

export const getPersona = async (id: number) => {
  return get(`/personas/${id}`);
};

export const createPersona = async (persona: PersonaModel) => {
  return post('/personas', { data: persona });
};

export const updatePersona = async (id: number, persona: PersonaModel) => {
  return patch(`/personas/${id}`, { data: persona });
};

export const deletePersona = async (id: number) => {
  return del(`/personas/${id}`);
};
