import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label, Row, Col } from 'reactstrap';

import { SelectModel } from 'src/shared/models/common.model';

import TextField from 'src/shared/components/form-inputs/TextField';
import ReactSelect from 'src/shared/components/form-inputs/ReactSelect';
import { useParams } from 'react-router';
import { QuestionnaireLabel, QuestionnaireLabelCategory } from 'src/shared/models';
import { FormikValues } from 'formik';
import { getLabelCategories } from 'src/redux/services/questionnaire.service';

interface Props {
  values?: FormikValues;
  setFieldValue: Function;
}

const DynamicLabelMessageForm: React.FC<Props> = ({ values, setFieldValue }) => {
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const [labelCategoriesOptions, setLabelCategories] = useState<SelectModel[]>([]);
  const [labelCategoryLabels, setLabelCategoryLabels] = useState<{
    [key: string]: SelectModel[];
  }>({});
  const [perPageLabelsOptions, setPerPageLabelsOptions] = useState<SelectModel[]>([]);

  useEffect(() => {
    getLabelCategory();
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    if (values?.labelCategorySlug?.value) {
      setPerPageLabelsOptions(labelCategoryLabels[values.labelCategorySlug.value]);
    }
    /* eslint-disable-next-line */
  }, [labelCategoryLabels]);

  const getLabelCategory = async () => {
    const data = await getLabelCategories(appId);
    setLabelCategories(
      data.map((labelCategory: QuestionnaireLabelCategory) => ({
        label: `${labelCategory.id} ${labelCategory.text} (${labelCategory.slug})`,
        value: labelCategory.slug,
      })),
    );

    const labelCategoryLabels = Object.assign(
      {},
      ...data.map(labelCategory => ({
        [labelCategory.slug!]: labelCategory.labels?.map(
          (questionnaireLabel: QuestionnaireLabel) => ({
            label: questionnaireLabel.text,
            value: questionnaireLabel.slug,
          }),
        ),
      })),
    );

    setLabelCategoryLabels(labelCategoryLabels);
    if (values?.perPageLabelSlugs && values?.labelCategorySlug?.value) {
      const slugs = values.perPageLabelSlugs.map((slug: string) => {
        return {
          value: slug,
          label:
            (labelCategoryLabels[values?.labelCategorySlug?.value] as SelectModel[]).filter(
              (model: SelectModel) => model.value === slug,
            )[0]?.label || '',
        };
      });
      setFieldValue('perPageLabelSlugs', slugs);
    }

    if (values?.labelCategorySlug?.value && values?.labelCategorySlug?.label === '') {
      const userLabelCategory = data.find(
        (labelCategory: QuestionnaireLabelCategory) =>
          labelCategory.slug === values.labelCategorySlug.value,
      );
      if (userLabelCategory) {
        setFieldValue('labelCategorySlug', {
          value: values.labelCategorySlug.value,
          label: `${userLabelCategory.id} ${userLabelCategory.text} (${userLabelCategory.slug})`,
        });
      }
    }
  };

  const onLabelCategorySlugChanged = (selected: SelectModel) => {
    setPerPageLabelsOptions(labelCategoryLabels[selected.value]);
    setFieldValue('perPageLabelSlugs', []);
  };
  return (
    <>
      <Row form>
        <Col>
          <FormGroup>
            <Label for="text">{t('common.message')}</Label>
            <TextField name="text" />
          </FormGroup>
        </Col>
      </Row>

      <Row form>
        <Col>
          <FormGroup>
            <Label for="labelCategorySlug">{t('questionnaire.labelCategorySlug')}</Label>
            <ReactSelect
              name="labelCategorySlug"
              options={labelCategoriesOptions}
              onChanged={onLabelCategorySlugChanged}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row form>
        <Col>
          <FormGroup>
            <Label for="perPageLabelSlugs">{t('questionnaire.perPageLabels')}</Label>
            <ReactSelect name="perPageLabelSlugs" options={perPageLabelsOptions} format isMulti />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default DynamicLabelMessageForm;
