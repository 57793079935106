import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { useLocation, useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { CheckPermission } from '..';

export interface PageHeaderProps {
  title?: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  rightComponent?: React.ReactNode;
  showCreateButton?: boolean;
  createPermission?: string;
  createRedirectPath?: string;
  createLabel?: string;
  onCreateButtonClick?: (open: boolean) => void;
}

export const PageHeader: FC<PageHeaderProps> = ({
  title,
  subTitle,
  createPermission,
  createRedirectPath,
  createLabel,
  onCreateButtonClick,
  showCreateButton,
  rightComponent,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();

  const redirectToCreate = () => {
    history.push(`${pathname}/${createRedirectPath || 'create'}`);
  };

  return (
    <div className="mb-4 d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <h4>{title}</h4>
        {subTitle}
      </div>

      <div className="d-flex align-items-center">
        {rightComponent ? <div className="d-flex align-items-center">{rightComponent}</div> : null}
        {showCreateButton || createPermission ? (
          <div
            className={classNames({
              'ml-2': showCreateButton || createPermission,
            })}
          >
            <CheckPermission
              variant="displayIf"
              permissions={createPermission ? [createPermission] : []}
            >
              <Button
                color="primary"
                onClick={onCreateButtonClick ? () => onCreateButtonClick(true) : redirectToCreate}
              >
                {createLabel || t('common.create')}
              </Button>
            </CheckPermission>
          </div>
        ) : null}
      </div>
    </div>
  );
};
