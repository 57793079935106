import { StateModel } from '../../shared/models/default-state.model';

import {
  GET_TALENT_POOL_CANDIDATES_REQUEST,
  GET_TALENT_POOL_CANDIDATES_SUCCESS,
  GET_TALENT_POOL_CANDIDATES_ERROR,
  GET_TALENT_POOL_CANDIDATE_REQUEST,
  GET_TALENT_POOL_CANDIDATE_SUCCESS,
  GET_TALENT_POOL_CANDIDATE_ERROR,
  GET_TALENT_POOL_CANDIDATES_ANSWERS_REQUEST,
  GET_TALENT_POOL_CANDIDATES_ANSWERS_SUCCESS,
  GET_TALENT_POOL_CANDIDATES_ANSWERS_ERROR,
  GET_TALENT_POOL_CANDIDATE_ANSWERS_REQUEST,
  GET_TALENT_POOL_CANDIDATE_ANSWERS_SUCCESS,
  GET_TALENT_POOL_CANDIDATE_ANSWERS_ERROR,
  UPDATE_TALENT_POOL_CANDIDATE_REQUEST,
  UPDATE_TALENT_POOL_CANDIDATE_SUCCESS,
  UPDATE_TALENT_POOL_CANDIDATE_ERROR,
  DELETE_TALENT_POOL_CANDIDATE_REQUEST,
  DELETE_TALENT_POOL_CANDIDATE_SUCCESS,
  DELETE_TALENT_POOL_CANDIDATE_ERROR,
  MARK_TALENT_POOL_CANDIDATE_REQUEST,
  MARK_TALENT_POOL_CANDIDATE_SUCCESS,
  MARK_TALENT_POOL_CANDIDATE_ERROR,
} from '../actions/talent-pools-candidates.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export const talentPoolCandidatesReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_TALENT_POOL_CANDIDATES_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_TALENT_POOL_CANDIDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_TALENT_POOL_CANDIDATES_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const talentPoolCandidatesAnswersReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case GET_TALENT_POOL_CANDIDATES_ANSWERS_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_TALENT_POOL_CANDIDATES_ANSWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_TALENT_POOL_CANDIDATES_ANSWERS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const talentPoolCandidateReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_TALENT_POOL_CANDIDATE_REQUEST:
    case UPDATE_TALENT_POOL_CANDIDATE_REQUEST:
    case DELETE_TALENT_POOL_CANDIDATE_REQUEST:
    case MARK_TALENT_POOL_CANDIDATE_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_TALENT_POOL_CANDIDATE_SUCCESS:
    case UPDATE_TALENT_POOL_CANDIDATE_SUCCESS:
    case DELETE_TALENT_POOL_CANDIDATE_SUCCESS:
    case MARK_TALENT_POOL_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_TALENT_POOL_CANDIDATE_ERROR:
    case UPDATE_TALENT_POOL_CANDIDATE_ERROR:
    case DELETE_TALENT_POOL_CANDIDATE_ERROR:
    case MARK_TALENT_POOL_CANDIDATE_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const talentPoolCandidateAnswersReducer = (
  state: StateModel = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case GET_TALENT_POOL_CANDIDATE_ANSWERS_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true,
      };

    case GET_TALENT_POOL_CANDIDATE_ANSWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_TALENT_POOL_CANDIDATE_ANSWERS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
