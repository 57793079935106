import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { StateModel } from 'src/shared/models/default-state.model';
import { resetUnrecognizedSentences } from '../../../redux/actions/unrecognized-sentences.action';
import ConfirmModal from '../../../shared/components/modals/ConfirmModal';

interface Props extends RouteComponentProps<{ id: string }>, WithTranslation {
  resetUnrecognizedSentences: Function;
  getUnrecognizedSentences: Function;
  resetState: StateModel;
  disabled: boolean;
}

interface State {
  confirmModalOpen: boolean;
}

class Reset extends Component<Props, State> {
  state: State = {
    confirmModalOpen: false,
  };

  resetUnrecognizedSentences = async () => {
    const applicationId = this.props.match.params.id;

    await this.props.resetUnrecognizedSentences(applicationId);
    this.props.getUnrecognizedSentences();
  };

  resetDataConfirm = (event: React.MouseEvent) => {
    event.stopPropagation();

    this.setState({
      confirmModalOpen: true,
    });
  };

  confirm = () => {
    this.resetUnrecognizedSentences();
    this.closeConfirmModal();
  };

  openConfirmModal = () => {
    this.setState({
      confirmModalOpen: true,
    });
  };

  closeConfirmModal = () => {
    this.setState({
      confirmModalOpen: false,
    });
  };

  render() {
    const { resetState, disabled, t } = this.props;

    return (
      <Fragment>
        <Button
          id="resetButton"
          className="ml-2"
          color="danger"
          disabled={disabled || resetState.loading}
          onClick={(event: React.MouseEvent) => this.resetDataConfirm(event)}
        >
          {t('common.reset')}
        </Button>

        <UncontrolledTooltip trigger="hover" placement="bottom" target="resetButton">
          {t('common.resetAllData')}
        </UncontrolledTooltip>

        <ConfirmModal
          title={t('common.resetData')}
          text={t('unrecognizedSentences.unrecognizedSentencesResetConfirmation')}
          isOpen={this.state.confirmModalOpen}
          cancel={() => this.closeConfirmModal()}
          confirm={() => this.confirm()}
          isLoading={resetState.loading}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  unrecognizedSentences: state.getUnrecognizedSentences,
  resetState: state.resetUnrecognizedSentences,
});

const mapDispatchToProps = {
  resetUnrecognizedSentences,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Reset)));
