import { del, get, patch, post } from '../../shared/services/http.service';
import { ConversationFlowQueryParams } from 'src/shared/models';
import { ConversationFlowModel } from 'src/shared/models/conversation-flow.model';

export const exportConversionFlowVariables = async (applicationId: string) => {
  return post(`/applications/${applicationId}/conversationflow/variables/export/xlsx`, {
    requestConfig: { responseType: 'blob' },
  });
};

export const getConversationFlowVariables = async (
  applicationId: string,
  messagingPlatform: string,
  params: ConversationFlowQueryParams,
) => {
  return get(
    `/applications/${applicationId}/conversationflow/messaging-platform/${messagingPlatform}/variables`,
    { params },
  );
};

export const createConversationFlowVariable = async (
  applicationId: string,
  messagingPlatform: string,
  data: ConversationFlowModel,
) => {
  return post(
    `/applications/${applicationId}/conversationflow/messaging-platform/${messagingPlatform}/variables`,
    { data },
  );
};

export const updateConversationFlowVariable = async (
  applicationId: string,
  messagingPlatform: string,
  variableId: string,
  data: ConversationFlowModel,
) => {
  return patch(
    `/applications/${applicationId}/conversationflow/messaging-platform/${messagingPlatform}/variables/${variableId}`,
    { data },
  );
};

export const deleteConversationFlowVariable = async (
  applicationId: string,
  messagingPlatform: string,
  variableId: string,
) => {
  return del(
    `/applications/${applicationId}/conversationflow/messaging-platform/${messagingPlatform}/variables/${variableId}`,
  );
};

export const reloadConversationFlowVariables = async (
  applicationId: string,
  messagingPlatform: string,
) => {
  return post(
    `applications/${applicationId}/conversationflow/messaging-platform/${messagingPlatform}/reload`,
  );
};
