import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';

import { EmployeeModel, TicketModel, TicketFeedbackTypeModel } from 'src/shared/models';
import moment from 'moment';

interface Props {
  isOpen: boolean;
  editDocument?: TicketModel | null;
  toggleModalState: Function;
}

const TicketStatusHistoryModal: React.FC<Props> = ({ isOpen, editDocument, toggleModalState }) => {
  const { t } = useTranslation();

  const getEmployeeName = (prefix: string, employee: EmployeeModel | undefined) => {
    if (!employee) {
      return '';
    }
    return `${prefix ? `${prefix}: ` : ''}${employee?.attributes
      ?.map(attribute => attribute.value)
      .join(' ')}, ${t('common.id')}: ${employee?.key}`;
  };

  return (
    <Modal isOpen={isOpen} size="xl">
      <ModalHeader>{t('tickets.statusHistory')}</ModalHeader>
      <ModalBody>
        <Table responsive striped hover size="sm">
          <thead>
            <tr>
              <th>{t('common.id')}</th>
              <th>{t('tickets.statusCreated')}</th>
              <th>{t('common.status')}</th>
              <th>{t('tickets.metadata')}</th>
            </tr>
          </thead>
          <tbody>
            {editDocument &&
              editDocument?.ticketTimeline?.map(item => (
                <tr key={item?.id}>
                  <td>{item.id}</td>
                  <td>{moment(item.updatedAt).format('LLL')}</td>
                  <td>{item.status}</td>
                  <td>
                    {item?.metadata?.type === 'created' &&
                      getEmployeeName(
                        t('tickets.employeeName'),
                        item?.metadata?.questionerEmployee,
                      )}

                    {item?.metadata?.type === 'changed-group' &&
                      `${item.metadata?.helpdeskGroupName} ${
                        item.metadata?.helpdeskGroupId
                          ? `, ${t('common.id')}: ${item.metadata?.helpdeskGroupId}`
                          : ''
                      }`}
                    {item?.metadata?.type &&
                      [
                        'delivered',
                        'changed-answerer',
                        'refused',
                        'delivered-answered',
                        'answered',
                      ].indexOf(item.metadata.type) !== -1 &&
                      getEmployeeName(t('tickets.answererName'), item?.metadata?.answererEmployee)}
                    {item?.metadata?.type === 'waiting' && `${item.metadata.cause}`}
                    {item?.metadata?.type === 'done' && (
                      <>
                        {getEmployeeName(
                          t('tickets.answererName'),
                          item?.metadata?.answererEmployee,
                        )}
                        {item?.metadata?.feedback && (
                          <>
                            , {t('tickets.feedback')}:&nbsp;
                            <span
                              className={`text-center ${
                                item.metadata.feedback === TicketFeedbackTypeModel.POSITIVE
                                  ? 'text-success'
                                  : 'text-danger'
                              }`}
                            >
                              <i
                                className={`fas ${
                                  item.metadata.feedback === TicketFeedbackTypeModel.POSITIVE
                                    ? 'fa-plus'
                                    : 'fa-minus'
                                }`}
                              />
                            </span>
                          </>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" onClick={() => toggleModalState(false)}>
          {t('common.close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TicketStatusHistoryModal);
