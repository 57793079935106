import React, { FC } from 'react';

interface SortIndexProps {
  index: number;
}

const SortIndex: FC<SortIndexProps> = ({ index }) => {
  return <span className="mr-2">#{index + 1}</span>;
};

export default SortIndex;
