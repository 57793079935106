import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useField, FieldAttributes } from 'formik';
import { Input, FormFeedback } from 'reactstrap';
import { FileModel } from '../../models/file.model';
import { readFileAsDataURL } from '../../../redux/services/filereader.service';

interface Props {
  setFieldValue: Function;
  fileUrl?: string;
  showThumbnail?: boolean;
  showDownload?: boolean;
  showDelete?: boolean;
  accept?: string;
}

const File: React.FC<FieldAttributes<Props>> = ({
  setFieldValue,
  fileUrl,
  showThumbnail,
  showDownload,
  showDelete,
  accept,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const [field, meta] = useField<any>(props);
  const [file, setFile] = useState<FileModel | undefined>();
  const { name, onBlur } = field;
  const { error, touched } = meta;

  const valid = touched && !error;
  const invalid = touched && !!error;
  const errorText = error || undefined;

  const handleChange = async (e: React.FormEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;

    if (files) {
      const file = files[0];
      const source = await readFileAsDataURL(files[0]);
      setFieldValue(name, file, true);
      setFile({ file, source });
    }
  };

  const handleFileClear = () => {
    setFieldValue(name, undefined);
    setFile(undefined);
  };

  const isImage = (file: FileModel | undefined) => {
    if (file) {
      const { type } = file.file;
      return String(type).startsWith('image/');
    }
    return false;
  };

  return (
    <Fragment>
      <div className="file-input-container">
        <button type="button" className="choose-button">
          {t('common.chooseFile')}
        </button>
        {file && <span className="choosen-file">{file.file.name}</span>}
        <Input
          type="file"
          name={name}
          accept={accept}
          onBlur={onBlur}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            handleChange(e);
          }}
          valid={valid}
          invalid={invalid}
          className={className}
        />
        <FormFeedback>{errorText}</FormFeedback>
      </div>

      {file && (
        <button
          type="button"
          className="clear-button"
          onClick={() => handleFileClear()}
        >
          {t('common.clear')}
        </button>
      )}

      {isImage(file) && (
        <img
          className="img-thumbnail mt-2"
          width="300"
          src={file && file.source}
          alt="File"
        />
      )}
    </Fragment>
  );
};

export default File;
