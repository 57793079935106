interface UpdateItemModel {
  id: number;
}

interface PaginationUpdateItemModel {
  content: UpdateItemModel[];
}

const updateItem = (data: UpdateItemModel[], payload: UpdateItemModel) =>
  data.map((p: UpdateItemModel) => (p.id === payload.id ? payload : p));

export const updateListItem = <T extends { data: UpdateItemModel[] }>(
  resources: T,
  payload: UpdateItemModel,
) => ({
  resources: {
    ...resources,
    data: updateItem(resources.data, payload),
  },
});

export const updatePaginatedListItem = <T extends { data: PaginationUpdateItemModel }>(
  resources: T,
  payload: UpdateItemModel,
) => ({
  resources: {
    ...resources,
    data: { ...resources.data, content: updateItem(resources.data.content, payload) },
  },
});
