import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody, Alert, Button } from 'reactstrap';
import { FormikValues } from 'formik';

import {
  getTenantUsers,
  getUserGroups,
  createTenantUser,
  updateTenantUser,
  deleteTenantUser,
} from '../../redux/actions/user.action';
import { UserModel } from '../../shared/models/user.model';
import { StateModel } from '../../shared/models/default-state.model';

import UsersTable from './components/UsersTable';
import UserModal from './components/UserModal';
import Spinner from '../../shared/components/Spinner';
import CheckPermission from '../../shared/components/CheckPermission';
import ConfirmModal from '../../shared/components/modals/ConfirmModal';

interface Props extends RouteComponentProps {
  users: StateModel;
  userCreate: StateModel;
  userUpdate: StateModel;
  userDelete: StateModel;
  userGroups: StateModel;
  getTenantUsers: Function;
  getUserGroups: Function;
  createTenantUser: Function;
  updateTenantUser: Function;
  deleteTenantUser: Function;
}

const Users: React.FC<Props> = props => {
  const { t } = useTranslation();
  const {
    users,
    getTenantUsers,
    userCreate,
    createTenantUser,
    userUpdate,
    updateTenantUser,
    userDelete,
    deleteTenantUser,
    userGroups,
    getUserGroups,
  } = props;
  const [isUserModalOpen, setUserModalOpen] = useState<boolean>(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<UserModel | null>(null);
  const [modalEvent, setModalEvent] = useState<string>('');

  /* eslint-disable */
  useEffect(() => {
    getTenantUsers();
    getUserGroups();
  }, []);

  useEffect(() => {
    const { loaded, error } = userCreate;
    if (loaded && !error && isUserModalOpen) {
      getTenantUsers();
      toggleUserModal();
    }
  }, [userCreate.loaded]);

  useEffect(() => {
    const { loaded, error } = userUpdate;
    if (loaded && !error && isUserModalOpen) {
      getTenantUsers();
      toggleUserModal();
    }
  }, [userUpdate.loaded]);

  useEffect(() => {
    const { loaded, error } = userDelete;
    if (loaded && !error && isConfirmModalOpen) {
      getTenantUsers();
      toggleConfirmModal();
    }
  }, [userDelete.loaded]);

  const toggleUserModal = (event?: string, user?: UserModel) => {
    if (event) {
      setModalEvent(event);
    }

    if (user) {
      setSelectedUser(user);
    } else {
      setSelectedUser(null);
    }

    return setUserModalOpen(!isUserModalOpen);
  };

  const toggleConfirmModal = (user?: UserModel) => {
    setConfirmModalOpen(!isConfirmModalOpen);

    if (user) {
      setSelectedUser(user);
    }
  };

  const handleSubmit = async (values: FormikValues) => {
    const isEditing = modalEvent === 'edit';

    if (!isEditing) {
      await createTenantUser({
        ...values,
        avatar: values.avatar || null,
        userGroupId: Number(values.userGroupId),
      });
    } else if (isEditing && selectedUser) {
      const newValues = {
        firstName: values.firstName,
        lastName: values.lastName,
        avatar: values.avatar || null,
        userGroupId: Number(values.userGroupId),
      };

      await updateTenantUser(selectedUser.id, newValues);
    }
  };

  const handleDelete = async () => {
    if (selectedUser) {
      await deleteTenantUser(selectedUser.id);
    }
  };

  const isLoading = userCreate.loading || userUpdate.loading;

  return (
    <Fragment>
      <Row className="mb-4">
        <Col>
          <h5>{t('common.users')}</h5>
        </Col>

        <Col className="d-flex justify-content-end">
          {/* PERMISSION KELL IDE */}
          <CheckPermission variant="enableIf" permissions={['users_create']}>
            <Button color="success" onClick={() => toggleUserModal('create')}>
              {t('users.newUser')}
            </Button>
          </CheckPermission>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardBody>
              {users.loaded && users.data.length > 0 && (
                <UsersTable
                  users={users.data}
                  toggleUserModal={toggleUserModal}
                  toggleConfirmModal={toggleConfirmModal}
                />
              )}

              {users.loading && (
                <div className="d-flex justify-content-center">
                  <Spinner loading={users.loading} size="2x" />
                </div>
              )}

              {users.loaded && !users.data.length && <Alert>{t('users.noUsersFound')}</Alert>}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <UserModal
        selectedUser={selectedUser}
        userGroups={userGroups.data}
        isOpen={isUserModalOpen}
        isLoading={isLoading}
        modalEvent={modalEvent}
        toggleUserModal={toggleUserModal}
        handleSubmit={handleSubmit}
      />

      <ConfirmModal
        title={t('users.deleteUser')}
        text={t('users.deleteUserConfirmMessage')}
        cancel={() => toggleConfirmModal()}
        confirm={() => handleDelete()}
        isOpen={isConfirmModalOpen}
        isLoading={userDelete.loading}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  users: state.tenantUsers,
  userCreate: state.tenantUserCreate,
  userUpdate: state.tenantUserUpdate,
  userDelete: state.tenantUserDelete,
  userGroups: state.tenantUserGroups,
});

const mapDispatchToProps = {
  getTenantUsers,
  createTenantUser,
  updateTenantUser,
  deleteTenantUser,
  getUserGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Users));
