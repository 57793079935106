import React, { FC, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';

import { Form, Formik, FormikHelpers, FormikProps } from 'formik';

import { List, ReactSelect, Switch, TextField } from 'src/shared/components';
import { FormQuestionModel, SelectModel } from 'src/shared/models';
import { ANSWER_TYPES } from 'src/shared/models/event.model';
import { eventQuestionsAnswersSchema } from 'src/shared/schemas/validation.schema';

import TextAnswer from './QuestionsAndAnswers/TextAnswer';
import OptionsAnswer from './QuestionsAndAnswers/OptionsAnswer';
import { createQuestion } from 'src/redux/services/forms.service';
import { getForm } from 'src/redux/actions/forms.action';
import ImageAnswer from './QuestionsAndAnswers/ImageAnswer';
import { getFields } from './questionAndAnswersUtils';

export const JumpToEndSignal = '##END##';

interface QuestionsAndAnswersProps {
  isEditingDisabled: boolean;
  formQuestions: FormQuestionModel[];
  getForm: (appId: string, formId: string) => void;
}

export interface OptionAnswerFormItem extends TextOrOptionAnswerFormItem {
  jumpToSlug: SelectModel;
}

export interface TextOrOptionAnswerFormItem extends AnswerFormItem {
  text: string;
  value: string;
}

export interface ImageAnswerFormItem extends AnswerFormItem {
  allowedFileExtensions: string;
  invalidFileExtensionMessage: string;
  allowedMaxFileSizeInBytes: number;
  invalidFileSizeMessage: string;
}

export interface AnswerFormItem {
  id: number;
}

const answerConfigurations: SelectModel[] = [
  { value: ANSWER_TYPES.TEXT, label: 'Text' },
  { value: ANSWER_TYPES.OPTIONS, label: 'Options' },
  { value: ANSWER_TYPES.IMAGE, label: 'Image' },
];

const QuestionsAndAnswers: FC<QuestionsAndAnswersProps> = ({
  isEditingDisabled,
  formQuestions,
  getForm,
}) => {
  let formRef: FormikProps<any> | null = null;
  const { t } = useTranslation();
  const [isAnswerTypeSelectorDisabled, setIsAnswerTypeSelectorDisabled] = useState(false);
  const [answers, setAnswers] = useState<AnswerFormItem[]>([]);
  const { appId, formId } = useParams<{ appId: string; formId: string }>();

  const jumpToSlugOptions: SelectModel[] = useMemo(() => {
    let model: SelectModel[] = [{ label: t('common.noJump'), value: null }];
    formQuestions.forEach(question => {
      model.push({ label: question.text, value: question.slug });
    });
    model.push({ label: t('common.jumpToEnd'), value: JumpToEndSignal });
    return model;
  }, [formQuestions, t]);

  useEffect(() => {
    if (answers.length === 0) {
      setIsAnswerTypeSelectorDisabled(false);
    } else {
      setIsAnswerTypeSelectorDisabled(true);
    }
  }, [answers]);

  const onAnswerAdd = async (values: any, formikHelpers: FormikHelpers<any>) => {
    var base = {
      required: values.required,
      skipButtonText: values.skipButtonText,
    };

    switch (values.type.value) {
      case ANSWER_TYPES.TEXT: {
        //If free text answer is added.
        if (!values.validationPattern || values.validationPattern.length === 0) {
          setAnswers([
            ...answers,
            ...[
              {
                ...base,
                id: answers.length,
                text: '--',
                value: t('forms.freeText'),
              } as TextOrOptionAnswerFormItem,
            ],
          ]);
        } else {
          if (!values.validationErrorMessage) {
            formikHelpers.setFieldError(
              'validationErrorMessage',
              t('errors.isRequired', { item: t('common.validationErrorMessage') }),
            );
            return;
          }
          setAnswers([
            ...answers,
            ...[
              {
                ...base,
                id: answers.length,
                text: values.validationPattern.label,
                value: values.validationErrorMessage,
              } as TextOrOptionAnswerFormItem,
            ],
          ]);
        }
        break;
      }
      case ANSWER_TYPES.OPTIONS: {
        var isValid = true;
        if (values.answer.text.length === 0) {
          isValid = false;
          formikHelpers.setFieldError(
            'answer.text',
            t('errors.isRequired', { item: t('common.text') }),
          );
        }
        if (values.answer.value.length === 0) {
          isValid = false;
          formikHelpers.setFieldError(
            'answer.value',
            t('errors.isRequired', { item: t('common.value') }),
          );
        }

        if (isValid) {
          setAnswers([
            ...answers,
            ...[
              {
                ...base,
                id: answers.length,
                text: values.answer.text,
                value: values.answer.value,
                jumpToSlug: values.answer.jumpToSlug,
              } as OptionAnswerFormItem,
            ],
          ]);
          formikHelpers.setFieldValue('answer.text', '');
          formikHelpers.setFieldValue('answer.value', '');
        }
        break;
      }
      case ANSWER_TYPES.IMAGE: {
        setAnswers([
          ...answers,
          ...[
            {
              ...base,
              id: answers.length,
              allowedFileExtensions: values.allowedFileExtensions,
              invalidFileExtensionMessage: values.invalidFileExtensionMessage,
              allowedMaxFileSizeInBytes: values.allowedMaxFileSizeInBytes,
              invalidFileSizeMessage: values.invalidFileSizeMessage,
            } as ImageAnswerFormItem,
          ],
        ]);
        break;
      }
    }
  };

  const onQuestionAdd = async (values: any) => {
    const data = {
      text: values.text,
      answerConfiguration: {},
      order: formQuestions.length,
    };

    switch (values.type.value) {
      case ANSWER_TYPES.OPTIONS: {
        data.answerConfiguration = {
          required: values.required,
          skipButtonText: values.skipButtonText,
          type: ANSWER_TYPES.OPTIONS,
          options: (answers as OptionAnswerFormItem[]).map(a => {
            return { text: a.text, value: a.value, jumpToSlug: a.jumpToSlug.value };
          }),
        };
        break;
      }
      case ANSWER_TYPES.TEXT: {
        data.answerConfiguration = {
          required: values.required,
          skipButtonText: values.skipButtonText,
          type: ANSWER_TYPES.TEXT,
          validationPattern: values.validationPattern.value,
          validationErrorMessage: values.validationErrorMessage,
        };
        break;
      }
      case ANSWER_TYPES.IMAGE: {
        data.answerConfiguration = {
          required: values.required,
          skipButtonText: values.skipButtonText,
          type: ANSWER_TYPES.IMAGE,
          allowedFileExtensions: values.allowedFileExtensions,
          invalidFileExtensionMessage: values.invalidFileExtensionMessage,
          allowedMaxFileSizeInBytes: values.allowedMaxFileSizeInBytes,
          invalidFileSizeMessage: values.invalidFileSizeMessage,
        };
        break;
      }
    }

    await createQuestion(appId, formId, data as any);
    formRef?.resetForm();
    setAnswers([]);
    getForm(appId, formId);
  };

  const fetch = () => {
    //No need to fetch here.
  };

  const deleteAnswer = async (item: AnswerFormItem) => {
    setAnswers(answers.filter(a => a.id !== item.id));
  };

  const editAnswer = async (id: string | number) => {};

  return (
    <fieldset className="border p-3">
      <legend className="col-form-label w-auto font-weight-bold">
        {t('forms.addNewQuestion')}
      </legend>
      <Formik<any>
        initialValues={{
          type: answerConfigurations[0],
          text: '',
          validationPattern: '',
          validationErrorMessage: '',
          answer: { text: '', value: '', jumpToSlug: '' },
          required: false,
          skipButtonText: null,
        }}
        onSubmit={onAnswerAdd}
        validationSchema={eventQuestionsAnswersSchema()}
        innerRef={i => (formRef = i)}
        enableReinitialize
      >
        {({ values }) => {
          const isAnswerInputsDisabled =
            ((values.type.value === ANSWER_TYPES.TEXT ||
              values.type.value === ANSWER_TYPES.IMAGE) &&
              answers.length === 1) ||
            isEditingDisabled;

          const isSkipButtonTextHidden = values.required;
          if (isSkipButtonTextHidden) {
            values.skipButtonText = null;
          }

          return (
            <Form>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label for="text">{t('common.text')}</Label>
                    <TextField name="text" disabled={isEditingDisabled} />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  {values.slug && (
                    <FormGroup>
                      <Label for="slug">{t('common.slug')}</Label>
                      <TextField name="slug" disabled />
                    </FormGroup>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <fieldset className="border p-3">
                    <legend className="col-form-label w-auto font-weight-bold">
                      {t('common.answers')}
                    </legend>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label for="type">{t('common.type')}</Label>
                          <ReactSelect
                            name="type"
                            options={answerConfigurations}
                            isDisabled={isAnswerTypeSelectorDisabled || isEditingDisabled}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="required">{t('common.required')}</Label>
                          <div className="d-flex align-items-center flex-row">
                            {t('common.false')}
                            <Switch
                              name="required"
                              className="ml-2 mr-2"
                              variant="pill"
                              color="primary"
                              size="lg"
                              checked={values.required}
                              disabled={isAnswerInputsDisabled}
                            />
                            {t('common.true')}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        {!isSkipButtonTextHidden && (
                          <FormGroup>
                            <Label for="skipButtonText">{t('common.skipButtonText')}</Label>
                            <TextField name="skipButtonText" disabled={isAnswerInputsDisabled} />
                          </FormGroup>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {values?.type?.value && values.type.value === ANSWER_TYPES.TEXT ? (
                          <TextAnswer disabled={isAnswerInputsDisabled} />
                        ) : values.type.value === ANSWER_TYPES.OPTIONS ? (
                          <OptionsAnswer jumpToSlugOptions={jumpToSlugOptions} />
                        ) : (
                          <ImageAnswer />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          {!isAnswerInputsDisabled && (
                            <div className="d-flex justify-content-end align-items-end">
                              <Button type="submit" color="primary">
                                {t('common.addAnswer')}
                              </Button>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    {answers.length > 0 && (
                      <Row>
                        <Col>
                          <List
                            hidePager
                            //@ts-ignore
                            data={answers}
                            totalElements={answers.length}
                            loaded={true}
                            loading={false}
                            error={false}
                            fetchData={fetch}
                            noDataLabel={t('forms.noQuestionAnswers')}
                            editFunction={editAnswer}
                            deleteFunction={deleteAnswer}
                            deleteTitle={t('forms.deleteQuestionAnswer')}
                            deleteText={t('forms.deleteQuestionAnswerConfirmMessage')}
                            updatePermission="form_element_update"
                            deletePermission="form_element_delete"
                            //@ts-ignore
                            fields={getFields(values, t)}
                          />
                        </Col>
                      </Row>
                    )}
                  </fieldset>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    {answers.length > 0 && (
                      <div className="d-flex justify-content-end align-items-end mt-4">
                        <Button
                          color="primary"
                          onClick={() => onQuestionAdd(values)}
                          disabled={isEditingDisabled}
                        >
                          {t('common.addQuestion')}
                        </Button>
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </fieldset>
  );
};

const mapStateToProps = (state: any) => ({
  formQuestions: state.form.data?.form?.elements || [],
});

const mapDispatchToProps = {
  getForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsAndAnswers);
