import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import moment from 'moment';

import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';

import { ImageListElementModel, ListElementModel } from 'src/redux/reducers/list-elements.reducer';
import { CheckPermission } from 'src/shared/components';
import Grid from 'src/shared/components/Grid';
import SortableThumbnail from 'src/shared/components/image/SortableThumbnail';
import settings from 'src/shared/settings';

interface ImageGalleryProps {
  items: ListElementModel[];
  editFunc: (element: ImageListElementModel | ListElementModel, isGalleryEdit: boolean) => void;
  deleteFunc: (element: ImageListElementModel | ListElementModel) => void;
  galleryHeader: React.ReactElement;
  updateFunc: (orderedElementsIds: Number[]) => void;
}

const ImageGallery: FC<ImageGalleryProps> = ({
  items,
  editFunc,
  deleteFunc,
  galleryHeader,
  updateFunc,
}) => {
  const [photos, setPhotos] = useState<ImageListElementModel[]>([]);

  useEffect(() => {
    items.forEach(item => {
      (item.id as any) = item.id?.toString();
    });
    setPhotos(items as any);
  }, [items]);

  const sensors = useSensors(
    useSensor(KeyboardSensor),
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  const onDragEnd = (event: DragEndEvent) => {
    updateFunc(event.active.data.current?.sortable.items as Number[]);
  };

  const onDragOver = (event: DragOverEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setPhotos(p => {
        const oldIndex = p.findIndex(i => i.id?.toString() === active.id);
        const newIndex = p.findIndex(i => i.id?.toString() === over?.id);

        return arrayMove(p, oldIndex, newIndex);
      });
    }
  };

  return (
    <Row>
      <Col>
        <Card className="animated fadeIn">
          <CardHeader>{galleryHeader}</CardHeader>
          <CardBody>
            <div className="d-flex">
              <DndContext onDragEnd={onDragEnd} onDragOver={onDragOver} sensors={sensors}>
                <SortableContext items={photos}>
                  <Grid>
                    {photos.map((photo, index) => {
                      return (
                        <SortableThumbnail
                          id={photo.id.toString()}
                          key={index}
                          imageUrl={photo.imageUrl!}
                          caption={
                            <>
                              <b>{photo.name}</b>
                              <br />
                              {moment(photo.createdAt).format(settings.DATE_TIME_FORMAT)}
                            </>
                          }
                          buttons={
                            <div>
                              <CheckPermission
                                variant="enableIf"
                                permissions={['simple-elements_update']}
                              >
                                <Button
                                  color="info"
                                  className="text-dark"
                                  onClick={() => editFunc(photo, true)}
                                >
                                  <i className="far fa-edit" />
                                </Button>
                              </CheckPermission>
                              <CheckPermission
                                variant="enableIf"
                                permissions={['simple-elements_delete']}
                              >
                                <Button
                                  color="danger"
                                  className="text-dark ml-2"
                                  onClick={() => deleteFunc(photo)}
                                >
                                  <i className="far fa-trash-alt" />
                                </Button>
                              </CheckPermission>
                            </div>
                          }
                        />
                      );
                    })}
                  </Grid>
                </SortableContext>
              </DndContext>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ImageGallery;
