import { StateModel } from 'src/shared/models';
import {
  GET_ISSUE_CATEGORIES,
  GET_ISSUE_CATEGORIES_SUCCESS,
  GET_ISSUE_CATEGORIES_ERROR,
} from '../actions/issue-categories.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const issueCategoriesReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case GET_ISSUE_CATEGORIES:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_ISSUE_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload,
      };
    case GET_ISSUE_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };   

    default:
      return state;
  }
};
