import { StateModel } from '../../shared/models/default-state.model';
import {
  IMPORT_EMPLOYEE_DATASET_REQUEST,
  IMPORT_EMPLOYEE_DATASET_SUCCESS,
  IMPORT_EMPLOYEE_DATASET_ERROR,
  GET_TAG_CATEGORIES_REQUEST,
  GET_TAG_CATEGORIES_SUCCESS,
  GET_TAG_CATEGORIES_ERROR,
  CREATE_TAG_CATEGORY_REQUEST,
  CREATE_TAG_CATEGORY_SUCCESS,
  CREATE_TAG_CATEGORY_ERROR,
  UPDATE_TAG_CATEGORY_REQUEST,
  UPDATE_TAG_CATEGORY_SUCCESS,
  UPDATE_TAG_CATEGORY_ERROR,
  DELETE_TAG_CATEGORY_REQUEST,
  DELETE_TAG_CATEGORY_SUCCESS,
  DELETE_TAG_CATEGORY_ERROR,
  GET_CATEGORY_TAGS_REQUEST,
  GET_CATEGORY_TAGS_SUCCESS,
  GET_CATEGORY_TAGS_ERROR,
  CREATE_TAG_REQUEST,
  CREATE_TAG_ERROR,
  CREATE_TAG_SUCCESS,
  UPDATE_TAG_REQUEST,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_ERROR,
  DELETE_TAG_REQUEST,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_ERROR,
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_ERROR,
  GET_EMPLOYEE_REQUEST,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_ERROR,
  CREATE_EMPLOYEE_REQUEST,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE_ERROR,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_ERROR,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_ERROR,
  ADD_TAG_TO_EMPLOYEE_REQUEST,
  ADD_TAG_TO_EMPLOYEE_SUCCESS,
  ADD_TAG_TO_EMPLOYEE_ERROR,
  REMOVE_EMPLOYEE_TAG_REQUEST,
  REMOVE_EMPLOYEE_TAG_SUCCESS,
  REMOVE_EMPLOYEE_TAG_ERROR,
} from '../actions/employee.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: [],
};

export const employeeDatasetReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case IMPORT_EMPLOYEE_DATASET_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case IMPORT_EMPLOYEE_DATASET_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case IMPORT_EMPLOYEE_DATASET_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const tagCategoriesReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_TAG_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case GET_TAG_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_TAG_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const createTagCategoryReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CREATE_TAG_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case CREATE_TAG_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case CREATE_TAG_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const updateTagCategoryReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case UPDATE_TAG_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case UPDATE_TAG_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case UPDATE_TAG_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const deleteTagCategoryReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DELETE_TAG_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case DELETE_TAG_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case DELETE_TAG_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const categoryTagsReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_CATEGORY_TAGS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case GET_CATEGORY_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_CATEGORY_TAGS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const createTagReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CREATE_TAG_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case CREATE_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case CREATE_TAG_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const updateTagReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case UPDATE_TAG_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case UPDATE_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case UPDATE_TAG_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const deleteTagReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DELETE_TAG_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case DELETE_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case DELETE_TAG_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const employeesReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_EMPLOYEES_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const employeeReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case GET_EMPLOYEE_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const createEmployeeReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CREATE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case CREATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case CREATE_EMPLOYEE_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const updateEmployeeReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case UPDATE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case UPDATE_EMPLOYEE_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const deleteEmployeeReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DELETE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case DELETE_EMPLOYEE_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const addTagToEmployeeReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ADD_TAG_TO_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case ADD_TAG_TO_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case ADD_TAG_TO_EMPLOYEE_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const removeEmployeeTagReducer = (state: StateModel = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case REMOVE_EMPLOYEE_TAG_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case REMOVE_EMPLOYEE_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload,
      };

    case REMOVE_EMPLOYEE_TAG_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
};
