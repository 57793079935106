import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';

import TextField from 'src/shared/components/form-inputs/TextField';

interface Props {
  isEditing?: boolean;
}

const LocationCityButtonTextsForm: React.FC<Props> = ({ isEditing }) => {
  const { t } = useTranslation();

  const namePrefix = isEditing ? '' : 'locationMessage.';

  return (
    <Fragment>
      <h6>{t('common.buttons')}</h6>
      <FormGroup>
        <Label for="cityChooseAllText">{t('questionnaire.cityChooseAllText')}</Label>
        <TextField name={`${namePrefix}cityChooseAllText`} />
      </FormGroup>

      <FormGroup>
        <Label for="cityChooseNoneText">{t('questionnaire.cityChooseNoneText')}</Label>
        <TextField name={`${namePrefix}cityChooseNoneText`} />
      </FormGroup>
    </Fragment>
  );
};

export default LocationCityButtonTextsForm;
