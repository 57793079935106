import { get, post, patch, del } from '../../shared/services/http.service';
import { GeneralQueryParams } from '../../shared/models/query-params.model';
import { TrainingDataModel } from 'src/shared/models/training-data.model';

export const getTrainingData = async (applicationId: string, params?: GeneralQueryParams) => {
  return get(`/applications/${applicationId}/training-data`, { params });
};

export const createTrainingData = async (
  applicationId: string,
  trainingData: TrainingDataModel,
) => {
  return post(`/applications/${applicationId}/training-data`, { data: trainingData });
};

export const updateTrainingData = async (
  applicationId: string,
  intentId: number,
  trainingData: TrainingDataModel,
) => {
  return patch(`/applications/${applicationId}/training-data/${intentId}`, { data: trainingData });
};

export const deleteTrainingData = async (applicationId: string, intentId: number) => {
  return del(`/applications/${applicationId}/training-data/${intentId}`);
};

export const exportTrainingData = async (applicationId: string) => {
  return get(`/applications/${applicationId}/training-data/export/csv`);
};

export const train = async (applicationId: string) => {
  return post(`/applications/${applicationId}/train`);
};
