import React, { useEffect, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table, Alert } from 'reactstrap';

import { StateModel } from 'src/shared/models/default-state.model';
import { CustomFieldModel } from 'src/shared/models/custom-fields.model';
import {
  ShortListApplicantModel,
  ShortListApplicantAnswerModel,
  ShortListApplicantAnswerCriteriaModel,
} from 'src/shared/models/short-list.model';

import {
  renderCustomFieldCellsForHeader,
  renderCustomFieldCellsForBody,
} from 'src/shared/utils/misc.util';

import ServerError from 'src/shared/components/ServerError';
import Spinner from 'src/shared/components/Spinner';

import styles from '../ShortList.module.scss';
import Pagination from 'react-js-pagination';
import { ApplicantsQueryParams } from 'src/shared/models/query-params.model';

interface Props {
  applicants: StateModel;
  criterias: StateModel;
  customFields: CustomFieldModel[];
  queryParams: ApplicantsQueryParams;
  setQueryParams: Function;
}

const ShortListTable: React.FC<Props> = ({
  applicants,
  criterias,
  customFields,
  queryParams,
  setQueryParams,
}) => {
  const { t } = useTranslation();

  const [criteriaIds, setCriteriaIds] = useState<number[]>([]);

  useEffect(() => {
    const { loaded, data } = criterias;

    if (loaded && data.length > 0) {
      const result = data.map((c: ShortListApplicantAnswerCriteriaModel) => c.id);
      setCriteriaIds(result);
    }
  }, [criterias]);

  const renderAnswerCellsForHeader = () => {
    if (criterias.data?.length > 0) {
      return criterias.data.map((criteria: ShortListApplicantAnswerCriteriaModel) => (
        <th key={criteria.id}>{criteria.name}</th>
      ));
    }

    return null;
  };

  const isAnswerKo = (answer: ShortListApplicantAnswerModel) => answer.ko && answer.value === 0;

  const renderAnswerCellsForBody = (answers: ShortListApplicantAnswerModel[]) => {
    if (criteriaIds.length > 0) {
      return criteriaIds.map((criteriaId, index) => {
        const answer = answers.find(({ criteria }) => criteria.id === criteriaId);

        return answer ? (
          <td key={answer.text}>
            <div className={styles.answerContainer}>
              <div className={styles.answerText}>
                {answer.text}
                {isAnswerKo(answer) && <i className="fas fa-times-circle ml-2" />}
              </div>
              <div className={styles.answerValue}>
                {answer.value} {t('common.point').toLowerCase()}
              </div>
            </div>
          </td>
        ) : index >= answers.length ? (
          <td key={criteriaId} className="text-muted">
            N/A
          </td>
        ) : null;
      });
    }
  };

  const getTableRowClassName = (answers: ShortListApplicantAnswerModel[]) => {
    const isKoRow = answers.find(answer => answer.ko && answer.value === 0);
    return isKoRow ? styles.koRow : '';
  };

  const handlePageChange = (index: number) => {
    setQueryParams((prevState: ApplicantsQueryParams) => ({
      ...prevState,
      page: index - 1,
    }));
  };

  return (
    <Fragment>
      {applicants.error && <ServerError />}

      {applicants.loading && (
        <div className="d-flex justify-content-center">
          <Spinner size="2x" loading={applicants.loading} />
        </div>
      )}

      {applicants.loaded && !applicants.data?.content?.length && (
        <Alert color="info" className="text-center mb-0">
          {t('positions.noShortListApplicantsFound')}
        </Alert>
      )}

      {applicants.loaded && applicants.data?.content?.length > 0 && (
        <Fragment>
          <Table striped hover responsive>
            <thead>
              <tr>
                {renderCustomFieldCellsForHeader(customFields)}
                {renderAnswerCellsForHeader()}
                <th className={styles.totalPoints}>{t('common.total')}</th>
              </tr>
            </thead>
            <tbody>
              {applicants.data.content.map((applicant: ShortListApplicantModel) => (
                <tr className={getTableRowClassName(applicant.answers)} key={applicant.id}>
                  {renderCustomFieldCellsForBody(customFields, applicant.attributes)}
                  {renderAnswerCellsForBody(applicant.answers)}
                  <td className={styles.totalPoints}>{applicant.summary}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Pagination
            activePage={queryParams.page + 1}
            totalItemsCount={applicants.data.totalElements}
            itemsCountPerPage={applicants.data.size}
            onChange={handlePageChange}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  applicants: state.shortList.applicants,
  criterias: state.shortList.criterias,
  customFields: state.customFields.data.customFields.data,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShortListTable);
