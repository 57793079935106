import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label } from 'reactstrap';
import { ReactSelect, TextField } from 'src/shared/components';
import { connect, useDispatch } from 'react-redux';
import { getAttributeValidators } from 'src/redux/actions/questionnaire.action';
import { StateModel } from 'src/shared/models';

interface TextAnswerProps {
  disabled: boolean;
  getAttributeValidators: Function;
  attributeValidators: StateModel;
}

const TextAnswer: FC<TextAnswerProps> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    props.getAttributeValidators();
    //eslint-disable-next-line
  }, [dispatch]);

  const validationTypes = useMemo(() => {
    return props.attributeValidators.data.map((validator: any) => ({
      id: validator.id,
      value: validator.pattern,
      label: validator.title,
    }));
  }, [props.attributeValidators]);

  return (
    <>
      <Col lg="6">
        <FormGroup>
          <Label for="validationRegex">{t('common.validationType')}</Label>
          <ReactSelect
            name="validationRegex"
            options={validationTypes}
            isDisabled={props.disabled}
            isClearable
          />
        </FormGroup>
      </Col>
      <Col lg="6">
        <FormGroup>
          <Label for="validationErrorMessage">{t('common.validationErrorMessage')}</Label>
          <TextField name="validationErrorMessage" disabled={props.disabled} />
        </FormGroup>
      </Col>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  attributeValidators: state.attributeValidators.resources,
});

const mapDispatchToProps = {
  getAttributeValidators,
};

export default connect(mapStateToProps, mapDispatchToProps)(TextAnswer);
