import { StateModel } from 'src/shared/models';
import {
  GET_FOLLOW_UP_NOTIFICATIONS,
  GET_FOLLOW_UP_NOTIFICATIONS_SUCCESS,
  GET_FOLLOW_UP_NOTIFICATIONS_ERROR,
} from '../actions/follow-up-notifications.action';

const INITIAL_STATE: StateModel = {
  loading: false,
  loaded: false,
  error: null,
  data: {},
};

export const followUpNotificationsReducer = (
  state: StateModel = INITIAL_STATE,
  { type, payload, error }: any,
) => {
  switch (type) {
    case GET_FOLLOW_UP_NOTIFICATIONS:
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    case GET_FOLLOW_UP_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: payload,
      };
    case GET_FOLLOW_UP_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };

    default:
      return state;
  }
};
