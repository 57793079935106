import { DateRangeQueryParams } from 'src/shared/models';
import { AveragedIssueReplyTimeReports } from 'src/shared/models/issue-reports.model';
import { get } from 'src/shared/services/http.service';

const baseUrl = (applicationId: string) => `applications/${applicationId}/issue/issues/reports`;

export const getAveragedIssueReplyTimeByContextDataReportService = async (
  applicationId: string,
  params: DateRangeQueryParams
): Promise<AveragedIssueReplyTimeReports> => get(`${baseUrl(applicationId)}/reply-time-report-by-context-data`, { params });

export const getAveragedIssueReplyTimeByDashboardUserReportService = async (
  applicationId: string,
  params: DateRangeQueryParams
): Promise<AveragedIssueReplyTimeReports> => {
  const response: Map<string, number> = await get(`${baseUrl(applicationId)}/reply-time-report-by-replier`, { params });
  const result: AveragedIssueReplyTimeReports = Object.entries(response).map(([key, value]) => ({ id: 0, groupByName: key, average: value }));

  return result;
};

export const exportAveragedIssueReplyTimeReport = async (applicationId: string, params: DateRangeQueryParams) =>
  get(`${baseUrl(applicationId)}/reply-time-reports/export/xlsx`, {
    params,
    requestConfig: { responseType: 'blob' },
  });
