import { Dispatch } from 'redux';
import { BatchMessagesQueryParams } from 'src/shared/models';
import { getBatchMessages as getBatchMessagesService } from '../services/batch-messages.service';

export const GET_BATCH_MESSAGES_REQUEST = 'batch-messages/GET_BATCH_MESSAGES_REQUEST';
export const GET_BATCH_MESSAGES_SUCCESS = 'batch-messages/GET_BATCH_MESSAGES_SUCCESS';
export const GET_BATCH_MESSAGES_ERROR = 'batch-messages/GET_BATCH_MESSAGES_ERROR';

export const getBatchMessages = (applicationId: number, queryParams: BatchMessagesQueryParams) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_BATCH_MESSAGES_REQUEST,
    });

    try {
      const payload = await getBatchMessagesService(applicationId, queryParams);

      dispatch({
        type: GET_BATCH_MESSAGES_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: GET_BATCH_MESSAGES_ERROR,
        error,
      });

      throw error;
    }
  };
};
