import React, { Fragment } from 'react';
import { useField, FieldAttributes } from 'formik';
import { Input, FormFeedback, InputProps } from 'reactstrap';

const TextArea: React.FC<FieldAttributes<any> & InputProps> = ({
  rows,
  className,
  placeholder,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);
  const { name, value, onBlur, onChange } = field;
  const { error, touched } = meta;

  const invalid = touched && !!error;
  const errorText = error || undefined;

  return (
    <Fragment>
      <Input
        type="textarea"
        name={name}
        value={(value || '') as string}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        invalid={invalid}
        rows={rows}
        className={className}
        {...props}
      />
      {errorText && <FormFeedback>{errorText}</FormFeedback>}
    </Fragment>
  );
};

export default TextArea;
