import React, { Fragment, useEffect, useRef, useState } from 'react';
import { GraphView } from 'react-digraph';

import {
  QuestionnaireAnswer,
  QuestionnaireAnswerActionType,
  QuestionnaireMessage,
  QuestionnaireMessageType,
} from 'src/shared/models/questionnaire.model';

const ImageUrlRegex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/g;

const GraphConfig = {
  NodeTypes: {
    question: {
      shapeId: '#question',
      shape: (
        <symbol viewBox="0 0 300 150" id="question" width="300" height="150" fill="#e4e6eb">
          <rect width="300" height="150" rx="15" />
        </symbol>
      ),
    },
    answer: {
      shapeId: '#answer',
      shape: (
        <symbol
          viewBox="0 0 150 100"
          id="answer"
          width="150"
          height="100"
          fill="white"
          strokeWidth="1"
        >
          <rect width="150" height="100" rx="15" />
        </symbol>
      ),
    },
  },
  NodeSubtypes: {},
  EdgeTypes: {
    messageToQuestionEdge: {
      // required to show empty edges
      shapeId: '#messageToQuestionEdge',
      shape: (
        <symbol viewBox="0 0 50 50" key="0" id="messageToQuestionEdge" fill="white">
          <circle cx="25" cy="25" r="8" fill="currentColor" />
        </symbol>
      ),
    },
    messageToQuestionEdgeIfDisabled: {
      shapeId: '#messageToQuestionEdge',
      shape: (
        <symbol viewBox="0 0 50 50" key="0" id="messageToQuestionEdge" fill="grey">
          <circle cx="25" cy="25" r="8" fill="currentColor" />
        </symbol>
      ),
    },
    answerToQuestionEdege: {
      // required to show empty edges
      shapeId: '#answerToQuestionEdege',
      shape: <symbol viewBox="0 0 10 10" id="answerToQuestionEdege"></symbol>,
    },
    messageToAnswerEdge: {
      // required to show empty edges
      shapeId: '#questionToAnswerEdege',
      shape: <symbol viewBox="0 0 50 50" id="questionToAnswerEdege"></symbol>,
    },
  },
};

interface Props {
  messages: QuestionnaireMessage[];
  zoomDelay: number | undefined;
}

interface NodeModel {
  id: string | number;
  title: string;
  type: string;
  x?: number;
  y?: number;
}

interface EdgeModel {
  handleText?: string;
  source: number | string;
  target: number | string;
  type: string;
}

const NODE_KEY = 'id';
const EXCLUDED_MESSAGE_TYPES: string | string[] = [
  'LOGIC_CONTROLLER',
  'ANALYTICS_CONTROLLER',
  'ACTION_CONTROLLER',
  'CONTEXT_CONTROLLER',
  'POSITION_APPLY_CONTROLLER',
];

const Graph: React.FC<Props> = ({ messages, zoomDelay }) => {
  const graphRef = useRef(null);
  const [fNodes, setNodes] = useState<NodeModel[]>([]);
  const [fEdges, setEdges] = useState<EdgeModel[]>([]);

  const { NodeTypes, NodeSubtypes, EdgeTypes } = GraphConfig;

  useEffect(() => {
    if (messages.length > 0) {
      generateGraphData();
    }
    /* eslint-disable-next-line */
  }, [messages]);

  const renderNodeText = (data: NodeModel) => {
    const x = data.type === 'answer' ? -75 : -150;
    const y = data.type === 'answer' ? -50 : -75;
    var width = data.type === 'answer' ? 150 : 300;
    var height = data.type === 'answer' ? 100 : 150;

    return (
      <foreignObject
        x={x}
        y={y}
        width={width}
        height={height}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <div
          style={{
            background: 'transparent',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            verticalAlign: 'top',
            padding: 10,
          }}
        >
          {data.title.match(ImageUrlRegex) ? (
            <img src={data.title} alt="Graph" style={{ maxHeight: '100%', maxWidth: '100%' }} />
          ) : (
            <p
              style={{
                fontSize: 20,
                margin: 0,
                textAlign: 'center',
              }}
            >
              {data.title}
            </p>
          )}
        </div>
      </foreignObject>
    );
  };

  function getNextMessageIdAndAnswerIds(
    messageWithAnswers: QuestionnaireMessage,
  ): { answerId: number; nextMessageId: number }[] {
    switch (messageWithAnswers.type) {
      case QuestionnaireMessageType.START_CONTROLLER:
      case QuestionnaireMessageType.DOCUMENT_UPLOAD_CONTROLLER:
      case QuestionnaireMessageType.EXPORT_APPLICATION_TO_ATS_CONTROLLER:
      case QuestionnaireMessageType.SHOW_INTERVIEW_EVENTS_MESSAGE:
      case QuestionnaireMessageType.IMAGE_MESSAGE:
      case QuestionnaireMessageType.DOCUMENT_VIEWER_MESSAGE:
      case QuestionnaireMessageType.SIMPLE_TEXT_MESSAGE:
      case QuestionnaireMessageType.ACTION_CONTROLLER:
      case QuestionnaireMessageType.ANALYTICS_CONTROLLER:
      case QuestionnaireMessageType.CONTEXT_CONTROLLER:
      case QuestionnaireMessageType.POSITION_APPLY_CONTROLLER:
        return [
          {
            answerId: messageWithAnswers.answers[0]?.id!,
            nextMessageId: messageWithAnswers.answers[0]?.nextMessageId!,
          },
        ];
      case QuestionnaireMessageType.DYNAMIC_LABEL_MESSAGE:
      case QuestionnaireMessageType.LOCATION_CITY:
      case QuestionnaireMessageType.LOCATION_COUNTY:
      case QuestionnaireMessageType.LOCATION_PART_OF_COUNTRY:
      case QuestionnaireMessageType.POSITION_PREFILTER_CRITERIAS_MESSAGE:
      case QuestionnaireMessageType.SHOW_POSITION_MESSAGE:
      case QuestionnaireMessageType.ATS_GATEWAY_CONTROLLER:
        return [
          {
            answerId: messageWithAnswers.answers.filter(
              i => i.answerActionType === QuestionnaireAnswerActionType.DEFAULT,
            )[0].id!,
            nextMessageId: messageWithAnswers.answers.filter(
              i => i.answerActionType === QuestionnaireAnswerActionType.DEFAULT,
            )[0].nextMessageId!,
          },
        ];
      case QuestionnaireMessageType.CLOSED_ENDED_ANSWERS:
        return messageWithAnswers.answers
          .filter(
            i =>
              i.answerActionType === QuestionnaireAnswerActionType.ATTRIBUTE_APPLICANT ||
              i.answerActionType === QuestionnaireAnswerActionType.ATTRIBUTE_APPLICANT_POSITION ||
              i.answerActionType === QuestionnaireAnswerActionType.LABEL_APPLICANT ||
              i.answerActionType === QuestionnaireAnswerActionType.NONE,
          )
          .map((i: QuestionnaireAnswer) => ({
            answerId: i.id!,
            nextMessageId: i.nextMessageId!,
          }));
      case QuestionnaireMessageType.OPEN_ENDED_ANSWERS:
        return messageWithAnswers.answers
          .filter(
            i =>
              i.answerActionType === QuestionnaireAnswerActionType.ATTRIBUTE_APPLICANT ||
              i.answerActionType === QuestionnaireAnswerActionType.ATTRIBUTE_APPLICANT_POSITION ||
              i.answerActionType === QuestionnaireAnswerActionType.LABEL_APPLICANT ||
              i.answerActionType === QuestionnaireAnswerActionType.NONE ||
              i.answerActionType === QuestionnaireAnswerActionType.VALIDATION_FAILED,
          )
          .map((i: QuestionnaireAnswer) => ({
            answerId: i.id!,
            nextMessageId: i.nextMessageId!,
          }));
      case QuestionnaireMessageType.LOGIC_CONTROLLER:
        return messageWithAnswers.answers.map((i: QuestionnaireAnswer) => ({
          answerId: i.id!,
          nextMessageId: i.nextMessageId!,
        }));
    }
  }

  // @ts-ignore
  function flatDeep(arr: any[], d = 1) {
    return d > 0
      ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), [])
      : arr.slice();
  }

  function nextEdgeMessageIds(
    messageWithAnswers: QuestionnaireMessage,
  ): { answerId: number; nextMessageIds: any[] }[] {
    const answersWithNextMessageIds:
      | { answerId: number; nextMessageId: number }[]
      | null = getNextMessageIdAndAnswerIds(messageWithAnswers);

    var answersWithNextMessages:
      | { answerId: number; nextMessage: QuestionnaireMessage | null }[]
      | null = answersWithNextMessageIds.map(answerWithNextMessageId => {
      const { answerId, nextMessageId } = answerWithNextMessageId;
      const nextMessage = messages.find((item: QuestionnaireMessage) => nextMessageId === item.id!);

      return { answerId, nextMessage: nextMessage ? nextMessage : null };
    });

    return answersWithNextMessages.map(item => {
      const { answerId, nextMessage } = item;
      if (nextMessage === null) {
        return { answerId: answerId, nextMessageIds: [null] };
      } else if (!EXCLUDED_MESSAGE_TYPES.includes(nextMessage.type)) {
        return { answerId: answerId, nextMessageIds: [nextMessage.id!] };
      } else {
        return {
          answerId: answerId,
          nextMessageIds: flatDeep(
            nextEdgeMessageIds(nextMessage).map(item => item.nextMessageIds),
          ),
        };
      }
    });
  }

  const generateGraphData = () => {
    const tNodes: NodeModel[] = [];
    const tEdges: EdgeModel[] = [];

    messages.forEach((message: QuestionnaireMessage) => {
      if (!EXCLUDED_MESSAGE_TYPES.includes(message.type)) {
        const questionNode: NodeModel = {
          id: message.id!,
          title: message.text,
          type: 'question',
          x: 0,
          y: 0,
        };
        tNodes.push(questionNode);

        const nextMessageIds = nextEdgeMessageIds(message);
        if (nextMessageIds !== null && nextMessageIds.length === 1) {
          const targets: any[] = flatDeep(nextMessageIds.map(item => item.nextMessageIds));
          targets.forEach((nextMessageId: number) => {
            const messageToMessageEdge: EdgeModel = {
              source: message.id!,
              target: nextMessageId!,
              type: 'messageToQuestionEdge',
            };
            const messageToMessageEdgeIfDisabled: EdgeModel = {
              source: message.id!,
              target: message.nextMessageIdIfDisabled!,
              type: 'messageToQuestionEdgeIfDisabled',
            };
            tEdges.push(messageToMessageEdge);
            tEdges.push(messageToMessageEdgeIfDisabled);
          });
        }
        if (nextMessageIds !== null && nextMessageIds.length > 1) {
          message.answers
            .filter(x => x.answerActionType !== 'INVISIBLE_ANSWER')
            .forEach((answer: QuestionnaireAnswer) => {
              const answerNode: NodeModel = {
                id: answer.id!,
                title: answer.text,
                type: 'answer',
                x: 0,
                y: 0,
              };
              tNodes.push(answerNode);

              const messageToAnswerEdge: EdgeModel = {
                source: message.id!,
                target: answer.id!,
                type: 'messageToAnswerEdge',
              };
              tEdges.push(messageToAnswerEdge);

              const targets = nextMessageIds.find(item => item.answerId === answer.id!)!
                .nextMessageIds;
              if (targets !== null) {
                targets.forEach((nextMessageId: number) => {
                  const answerToQuestionEdege: EdgeModel = {
                    source: answer.id!,
                    target: nextMessageId!,
                    type: 'answerToQuestionEdege',
                  };
                  tEdges.push(answerToQuestionEdege);
                });
              }
            });
        }
      }
    });
    setNodes(tNodes);
    setEdges(tEdges);
  };

  const graphConfig = {
    align: 'UR',
    rankdir: 'TB',
    nodesep: 100,
    edgesep: 1,
    ranksep: 1,
  };

  return (
    <Fragment>
      {zoomDelay && (
        <div id="graph" style={{ height: 'calc(100vh - 317px)' }}>
          <GraphView
            ref={graphRef}
            nodeKey={NODE_KEY}
            nodes={fNodes}
            edges={fEdges}
            selected={undefined}
            nodeTypes={NodeTypes}
            nodeSubtypes={NodeSubtypes}
            edgeTypes={EdgeTypes}
            layoutEngineType="HorizontalTree"
            // @ts-ignore
            graphConfig={graphConfig}
            maxTitleChars={100}
            renderNodeText={(data: NodeModel) => renderNodeText(data)}
            zoomDelay={zoomDelay}
            onCreateNode={() => null}
            onCreateEdge={() => null}
            onSwapEdge={() => null}
            minZoom={0.1}
            maxZoom={0.5}
            showGraphControls={true}
          />
        </div>
      )}
    </Fragment>
  );
};

export default Graph;
