import { StateModel } from '../../shared/models/default-state.model';

import {
    GET_INTERVIEW_ATTENDEES_REQUEST,
    GET_INTERVIEW_ATTENDEES_SUCCESS,
    GET_INTERVIEW_ATTENDEES_ERROR,
} from '../actions/interview-attendees.action';

interface InterviewAttendeesStateModel {
    resources: StateModel;
    create: StateModel;
    update: StateModel;
    delete: StateModel;
}

const INITIAL_STATE = {
    resources: {
        loading: false,
        loaded: false,
        error: null,
        data: [],
    },
    create: {
        loading: false,
        loaded: false,
        error: null,
        data: [],
    },
    update: {
        loading: false,
        loaded: false,
        error: null,
        data: [],
    },
    delete: {
        loading: false,
        loaded: false,
        error: null,
        data: [],
    },
};

// eslint-disable-next-line import/prefer-default-export
export const interviewAttendeesReducer = (state: InterviewAttendeesStateModel = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case GET_INTERVIEW_ATTENDEES_REQUEST:
            return {
                ...state,
                resources: {
                    ...state.resources,
                    error: null,
                    loading: true,
                    loaded: false,
                },
            };

        case GET_INTERVIEW_ATTENDEES_SUCCESS:
            return {
                ...state,
                resources: {
                    ...state.resources,
                    loading: false,
                    loaded: true,
                    data: action.payload,
                },
            };

        case GET_INTERVIEW_ATTENDEES_ERROR:
            return {
                ...state,
                resources: {
                    ...state.resources,
                    loading: false,
                    loaded: false,
                    error: action.error,
                },
            };

        default:
            return state;
    }
};
