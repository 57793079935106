import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ApplicationModel, getApplication } from 'src/redux/actions/applications.action';
import { getPositionCriterias } from 'src/redux/actions/position-criterias.action';
import { PageHeader } from 'src/shared/components';
import PositionCriteriasSidebar from '../Positions/PositionCreateAndEdit/components/sidebars/PositionCriteriasSidebar';

interface Props {
  application: ApplicationModel;
  getApplication: Function;
  getPositionCriterias: Function;
}

const CriteriaCategories: React.FC<Props> = ({
  application,
  getApplication,
  getPositionCriterias,
}) => {
  const { appId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (!application.id) {
      getApplication(appId);
    }

    getPositionCriterias(appId);
    /* eslint-disable-next-line */
  }, []);

  return (
    <div className="animated fadeIn">
      <PageHeader title={t('common.criteriaCategories')} />

      <Card>
        <CardBody>
          <PositionCriteriasSidebar isCriteriaCategories />
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  application: state.application.data,
  platforms: state.platforms.data,
  position: state.positions.data.position.data,
  labels: state.questionnaireLabelCategories.resources.data,
  sidebarType: state.positions.data.sidebarType,
});

const mapDispatchToProps = {
  getApplication,
  getPositionCriterias,
};

export default connect(mapStateToProps, mapDispatchToProps)(CriteriaCategories);
