import { post, get } from 'src/shared/services/http.service';
import { DynamicDatasetModel, PageQueryParams } from 'src/shared/models';

export const uploadDynamicDataset = async (
  applicationId: number | string,
  payload: DynamicDatasetModel,
) => {
  return post(`/applications/${applicationId}/dynamicmenu/menuitems/import/xlsx`, {
    data: payload,
  });
};

export const getDynamicMenusWithParams = async (applicationId: string, params: PageQueryParams) => {
  return get(`/applications/${applicationId}/import/imports?type=IMPORT_DYNAMIC_MENU`, { params });
};

export const getDynamicMenus = async (applicationId: string) => {
  return get(`/applications/${applicationId}/dynamicmenu/menuitems`);
};
export default uploadDynamicDataset;
