import React, { FC } from 'react';
import { Alert } from 'reactstrap';

interface NoDataProps {
  label: string;
}

const NoData: FC<NoDataProps> = ({ label }) => (
  <Alert color="info" className="text-center m-0">
    {label}
  </Alert>
);

export default NoData;
