import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import moment from 'moment';

import HistoryHeader from './components/HistoryHeader';
import Messages from './components/Messages';
import HistoryFooter from './components/HistoryFooter';

import { EntityModel } from '../../../../shared/models/conversations.model';
import { QueryParams } from '../../../../shared/models/query-params.model';
import { MessageTypes } from 'src/shared/constants/conversation-history.constants';
import { ConversationModel } from '../../../../redux/actions/conversations.action';
import {
  updateConversationHistory,
  getNextBatchOfConversationHistory,
  ConversationHistoryModel,
} from '../../../../redux/actions/conversation-history.action';

import { isValidUrl } from '../../../../shared/utils/url-validate.util';

import styles from '../../ConversationHistory.module.scss';

const loadingTimeout = 1000;

interface Props extends RouteComponentProps<{ userId: string; createdAt: string }> {
  getConversationHistory: Function;
  getNextBatchOfConversationHistory: Function;
  updateConversationHistory: Function;
  platform: string;
  conversations: ConversationModel[];
  conversationHistory: ConversationHistoryModel[];
  totalPagesOfHistory: number;
  sizeOfHistoryBatch: number;
  numberOfHistory: number;
  currentUser: any;
  conversation: ConversationModel;
}

interface State {
  queryParams: QueryParams;
  messages: ConversationHistoryModel[];
  loadedMessages: number;
  totalMessages: number;
}

class History extends Component<Props, State> {
  state: State = {
    queryParams: {
      page: 0,
      limit: 20,
      size: 20,
    },
    messages: [],
    loadedMessages: 0,
    totalMessages: 0,
  };

  componentDidMount = async () => {
    const { queryParams } = this.state;

    const { userId, createdAt } = this.props.match.params;

    if (userId) {
      await this.props.getConversationHistory(queryParams);
      const { conversationHistory, sizeOfHistoryBatch, numberOfHistory } = this.props;

      this.setState(
        {
          loadedMessages: sizeOfHistoryBatch,
          totalMessages: numberOfHistory,
        },
        async () => {
          await this.categorizeMessages(conversationHistory);
          await this.getEntityTexts(conversationHistory);
          await this.setMessagesDetails(conversationHistory);

          const { messages } = this.state;
          await this.props.updateConversationHistory(messages);

          if (createdAt) {
            this.scrollToHighlightedMessage();
            setTimeout(this.scrollToHighlightedMessage, loadingTimeout);
          } else {
            this.scrollToLastMessage();
            setTimeout(this.scrollToLastMessage, loadingTimeout);
          }
        },
      );
    }
  };

  componentDidUpdate = async (prev: Props) => {
    const { userId: prevUserId } = prev.match.params;
    const { userId: currUserId } = this.props.match.params;
    const { conversationHistory: prevHistory } = prev;
    const { conversationHistory: currHistory } = this.props;

    // Igaz feltétel esetén itt történik annak az esetnek a kezelése,
    // amikor egy másik beszélgetés lett kiválasztva.
    if (prevUserId !== currUserId) {
      this.setState(
        prevState => ({
          queryParams: {
            ...prevState.queryParams,
            page: 0,
          },
          loadedMessages: 0,
          totalMessages: 0,
        }),
        async () => {
          const { queryParams } = this.state;
          await this.props.getConversationHistory(queryParams);
          this.scrollToBottom();
        },
      );
    }
    // Ez a feltétel abban az esetben lesz igaz, ha másik beszélgetés lett kiválasztva vagy
    // új üzenetek kerültek letöltésre és hozzáadva a state-hez (felfelé görgetés hatására).
    if (prevHistory && prevHistory.length && currHistory && currHistory.length) {
      const areBatchMessagesAdded = prevHistory[0].id !== currHistory[0].id;
      const isSingleMessageAdded = currHistory.length - prevHistory.length === 1;

      if (areBatchMessagesAdded || isSingleMessageAdded) {
        const { sizeOfHistoryBatch, numberOfHistory } = this.props;

        this.setState(
          prevState => ({
            loadedMessages: areBatchMessagesAdded
              ? prevState.loadedMessages + sizeOfHistoryBatch
              : prevState.loadedMessages + 1,
            totalMessages: areBatchMessagesAdded ? numberOfHistory : prevState.totalMessages + 1,
          }),
          async () => {
            await this.categorizeMessages(currHistory);
            await this.getEntityTexts(currHistory);
            await this.setMessagesDetails(currHistory);

            const { messages } = this.state;
            await this.props.updateConversationHistory(messages);

            if (areBatchMessagesAdded) {
              this.scrollToLastMessage();
            } else {
              this.scrollToBottom();
            }
          },
        );
      }
    }
  };

  categorizeMessages = async (messages: ConversationHistoryModel[]) => {
    const messagesWithCategories: ConversationHistoryModel[] = [];
    const { sizeOfHistoryBatch } = this.props;

    if (messages && messages.length > 0) {
      messages.map((message: ConversationHistoryModel, idx: number) => {
        const { messageAsRaw, messageAsText } = message;

        message.isLastMessage = idx === sizeOfHistoryBatch - 1;

        if (messageAsRaw.startsWith('[Image:')) {
          message.messageType = 'Image';
        } else if (message.intent === 'mediaMessage') {
          message.messageType = 'Media';
        } else if (message.intent === 'stickerMessage') {
          message.messageType = 'Sticker';
        } else if (messageAsRaw.startsWith('[File:') || isValidUrl(messageAsRaw)) {
          message.messageType = 'File';
        } else if (messageAsRaw.includes('HangoutsChatSimplifiedButton')) {
          message.messageType = 'SimplifiedButton';
        } else if (messageAsText.includes('sendTextWithButtons')) {
          message.messageType = 'SendTextWithButtons';
        } else if (messageAsRaw.includes('Button template')) {
          message.messageType = 'ButtonTemplate';
        } else if (
          messageAsRaw === '[Generic template]' ||
          messageAsRaw === '[Viber generic template]'
        ) {
          message.messageType = 'GenericTemplate';
        } else if (messageAsRaw === '[Livechat generic template]') {
          message.messageType = 'GenericTemplate2';
        } else if (messageAsRaw === '[MultiList]') {
          message.messageType = 'MultiList';
        } else if (messageAsRaw.includes('MultiListResponse')) {
          message.messageType = 'MultiListResponse';
        } else if (messageAsRaw.includes('TextWithQuickReplies')) {
          message.messageType = 'QuickReply';
        } else if (messageAsRaw === '[SendTextWithButtons]') {
          message.messageType = 'SendTextWithButtons';
        } else if (messageAsRaw === '[Livechat map template]') {
          message.messageType = 'MapTemplate';
        } else if (messageAsRaw === '[Viber button template]') {
          message.messageType = 'ViberTemplate';
        } else if (messageAsRaw === '[Viber custom keyboard template]') {
          message.messageType = 'ViberCustomKeyboardTemplate';
        } else if (messageAsRaw === '[Viber carousel template]') {
          message.messageType = 'ViberCarouselTemplate';
        } else if (messageAsRaw === '[Gallery template]') {
          message.messageType = 'ViberGalleryTemplate';
        } else if (messageAsRaw === '[QuickReplies]') {
          message.messageType = MessageTypes.QuickReplies;
        } else {
          message.messageType = 'Text';
        }

        return messagesWithCategories.push(message);
      });

      this.setState({
        messages: messagesWithCategories,
      });
    }
  };

  getEntityTexts = async (messages: ConversationHistoryModel[]) => {
    const messagesWithEntityTexts: ConversationHistoryModel[] = [];

    if (messages && messages.length > 0) {
      messages.map((message: ConversationHistoryModel) => {
        const { entity } = message;

        message.entityTexts = [];

        if (entity && entity.length > 2) {
          const cleanedEntity = entity.replace('\\', '\\\\'); // Because of ¯\_(ツ)_/¯ and other similiar things...
          const currentEntity = JSON.parse(cleanedEntity);
          const entityTexts: string[] = [];

          currentEntity.map((currEntity: EntityModel) => {
            const startPosition = currEntity.start;
            const endPosition = currEntity.end + 1;

            const entityText = message.messageAsText.substring(startPosition, endPosition);

            return entityTexts.push(entityText);
          });

          message.entityTexts = [...entityTexts];
        }

        return messagesWithEntityTexts.push(message);
      });

      this.setState({
        messages: messagesWithEntityTexts,
      });
    }
  };

  setMessagesDetails = async (messages: ConversationHistoryModel[]) => {
    const messagesWithDetails: ConversationHistoryModel[] = [];

    messages.map((message: ConversationHistoryModel, index: number) => {
      const previousMessage = messages[index - 1];
      const currentMessage = messages[index];
      const nextMessage = messages[index + 1];
      const currentMoment = moment(currentMessage.creationTimestamp);

      message.isPrevBySameAuthor = false;
      message.isNextBySameAuthor = false;
      message.isNextBySamePersona = false;

      message.isStartMessage = true;
      message.isEndMessage = true;
      message.isSingleMessage = false;

      message.showTimeStamp = true;
      message.showAvatar = true;
      message.showSender = !!message.sender;

      if (previousMessage) {
        const previousMoment = moment(previousMessage.creationTimestamp);
        const previousDuration = moment.duration(currentMoment.diff(previousMoment));

        message.isPrevBySameAuthor =
          previousMessage.isOutgoingMessage === currentMessage.isOutgoingMessage;

        if (message.isPrevBySameAuthor && previousDuration.as('minutes') < 5) {
          message.isStartMessage = false;
        }

        if (previousDuration.as('minutes') < 5) {
          message.showTimeStamp = false;
        }
      }

      if (nextMessage) {
        const nextMoment = moment(nextMessage.creationTimestamp);
        const nextDuration = moment.duration(nextMoment.diff(currentMoment));
        message.isNextBySameAuthor =
          nextMessage.isOutgoingMessage === currentMessage.isOutgoingMessage;
        message.isNextBySamePersona = nextMessage.sender === currentMessage.sender;

        if (message.isPrevBySameAuthor) {
          message.showAvatar = false;
        }

        if (message.isNextBySamePersona) {
          message.showSender = false;
        }

        if (message.isNextBySameAuthor && nextDuration.as('minutes') < 5) {
          message.isEndMessage = false;
        }
      }

      if (message.isStartMessage && message.isEndMessage) {
        message.isStartMessage = false;
        message.isEndMessage = false;
        message.isSingleMessage = true;
      }

      if (!message.isStartMessage && !message.isEndMessage && !message.isSingleMessage) {
        message.isMiddleMessage = true;
      }

      return messagesWithDetails.push(message);
    });

    this.setState({
      messages: messagesWithDetails,
    });
  };

  getNextBatchOfConversationHistory = () => {
    const { totalPagesOfHistory } = this.props;
    const { page } = this.state.queryParams;

    if (totalPagesOfHistory - 1 === page) return;

    this.setState(
      prevState => ({
        queryParams: {
          ...prevState.queryParams,
          page: prevState.queryParams.page + 1,
        },
      }),
      () => {
        const { queryParams } = this.state;
        const { userId } = this.props.match.params;

        this.props.getNextBatchOfConversationHistory(userId, queryParams);
      },
    );
  };

  handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop } = e.currentTarget;

    if (scrollTop === 0) {
      this.getNextBatchOfConversationHistory();
    }
  };

  scrollToHighlightedMessage = () => {
    const currentHighlightedMessageDiv = document.querySelector('.currentHighlighted');
    if (currentHighlightedMessageDiv) {
      currentHighlightedMessageDiv.scrollIntoView();
    } else {
      this.scrollToBottom();
    }
  };

  scrollToLastMessage = () => {
    const lastMessageDiv = document.getElementById('lastMessage');
    if (lastMessageDiv) {
      lastMessageDiv.scrollIntoView();
    }
  };

  scrollToBottom = () => {
    const conversationContainer = document.getElementById('conversationContainer');
    if (conversationContainer) {
      conversationContainer.scrollTop = conversationContainer.scrollHeight;
    }
  };

  render() {
    const { conversations, /* conversation, */ conversationHistory, currentUser } = this.props;
    const { loadedMessages, totalMessages } = this.state;
    const { createdAt } = this.props.match.params;

    const showContent = !!(conversationHistory.length && conversations.length);

    return (
      <div className={styles.historyContainer}>
        <HistoryHeader
          showContent={showContent}
          // showHandoverSwitch={!conversation.timeWindowExpired}
          showHandoverSwitch
          currentUser={currentUser}
        />

        {showContent && (
          <Messages
            conversationHistory={conversationHistory}
            handleScroll={this.handleScroll}
            createdAt={createdAt}
          />
        )}

        {!showContent && <div className={styles.noMessages}>No messages found</div>}

        <HistoryFooter
          showContent={showContent}
          loadedMessages={loadedMessages}
          totalMessages={totalMessages}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  conversation: state.conversations.data.currentConversation,
  currentUser: state.conversationHistoryCurrentUser.user,
  conversations: state.conversations.data.content,
  platform: state.conversations.data.currentPlatform,
});

const mapDispatchToProps = {
  updateConversationHistory,
  getNextBatchOfConversationHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(History));
