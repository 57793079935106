import i18n from '../../i18n';
import { SelectModel } from '../models/common.model';
import { QuestionnaireAnswerActionType } from '../models';
export const VIEW_MODE_KEY = 'labelCategoriesViewMode';

export const messageTypesToExcludeRegex = /CONTROLLER|PREFILTER_CRITERIAS/g;

// For Show Positions Message
export const POSITIONS_PER_PAGE_MIN = 1;
export const POSITIONS_PER_PAGE_MAX = 11;

// For Location Message
export const LOCATIONS_PER_PAGE_MIN = 1;
export const LOCATIONS_PER_PAGE_MAX = 11;

export const messageType = {
  CLOSED_ENDED_ANSWERS: 'CLOSED_ENDED_ANSWERS',
  OPEN_ENDED_ANSWERS: 'OPEN_ENDED_ANSWERS',
  SIMPLE_TEXT_MESSAGE: 'SIMPLE_TEXT_MESSAGE',
  LOGIC_CONTROLLER: 'LOGIC_CONTROLLER',
  LOCATION_MESSAGE: 'LOCATION_MESSAGE',
  LOCATION_PART_OF_COUNTRY: 'LOCATION_PART_OF_COUNTRY',
  LOCATION_COUNTY: 'LOCATION_COUNTY',
  LOCATION_CITY: 'LOCATION_CITY',
  SHOW_POSITION_MESSAGE: 'SHOW_POSITION_MESSAGE',
  POSITION_APPLY_CONTROLLER: 'POSITION_APPLY_CONTROLLER',
  POSITION_PREFILTER_CRITERIAS_MESSAGE: 'POSITION_PREFILTER_CRITERIAS_MESSAGE',
  EXPORT_APPLICATION_TO_ATS_CONTROLLER: 'EXPORT_APPLICATION_TO_ATS_CONTROLLER',
  DOCUMENT_UPLOAD_CONTROLLER: 'DOCUMENT_UPLOAD_CONTROLLER',
  DOCUMENT_VIEWER_MESSAGE: 'DOCUMENT_VIEWER_MESSAGE',
  START_CONTROLLER: 'START_CONTROLLER',
  ACTION_CONTROLLER: 'ACTION_CONTROLLER',
  ANALYTICS_CONTROLLER: 'ANALYTICS_CONTROLLER',
  CONTEXT_CONTROLLER: 'CONTEXT_CONTROLLER',
  DYNAMIC_LABEL_MESSAGE: 'DYNAMIC_LABEL_MESSAGE',
  IMAGE_MESSAGE: 'IMAGE_MESSAGE',
  SHOW_INTERVIEW_EVENTS_MESSAGE: 'SHOW_INTERVIEW_EVENTS_MESSAGE',
  ATS_GATEWAY_CONTROLLER: 'ATS_GATEWAY_CONTROLLER',
};

export enum MessageTypeEnum {
  CLOSED_ENDED_ANSWERS = 'CLOSED_ENDED_ANSWERS',
  OPEN_ENDED_ANSWERS = 'OPEN_ENDED_ANSWERS',
  SIMPLE_TEXT_MESSAGE = 'SIMPLE_TEXT_MESSAGE',
  LOGIC_CONTROLLER = 'LOGIC_CONTROLLER',
  LOCATION_MESSAGE = 'LOCATION_MESSAGE',
  LOCATION_PART_OF_COUNTRY = 'LOCATION_PART_OF_COUNTRY',
  LOCATION_COUNTY = 'LOCATION_COUNTY',
  LOCATION_CITY = 'LOCATION_CITY',
  SHOW_POSITION_MESSAGE = 'SHOW_POSITION_MESSAGE',
  POSITION_APPLY_CONTROLLER = 'POSITION_APPLY_CONTROLLER',
  POSITION_PREFILTER_CRITERIAS_MESSAGE = 'POSITION_PREFILTER_CRITERIAS_MESSAGE',
  EXPORT_APPLICATION_TO_ATS_CONTROLLER = 'EXPORT_APPLICATION_TO_ATS_CONTROLLER',
  DOCUMENT_UPLOAD_CONTROLLER = 'DOCUMENT_UPLOAD_CONTROLLER',
  DOCUMENT_VIEWER_MESSAGE = 'DOCUMENT_VIEWER_MESSAGE',
  START_CONTROLLER = 'START_CONTROLLER',
  ACTION_CONTROLLER = 'ACTION_CONTROLLER',
  ANALYTICS_CONTROLLER = 'ANALYTICS_CONTROLLER',
  CONTEXT_CONTROLLER = 'CONTEXT_CONTROLLER',
  DYNAMIC_LABEL_MESSAGE = 'DYNAMIC_LABEL_MESSAGE',
  IMAGE_MESSAGE = 'IMAGE_MESSAGE',
  SHOW_INTERVIEW_EVENTS_MESSAGE = 'SHOW_INTERVIEW_EVENTS_MESSAGE',
  ATS_GATEWAY_CONTROLLER = 'ATS_GATEWAY_CONTROLLER',
}

export const messageActionTypeToDisplay = {
  [messageType.CLOSED_ENDED_ANSWERS]: i18n.t('questionnaire.closedEndedQuestion'),
  [messageType.OPEN_ENDED_ANSWERS]: i18n.t('questionnaire.openEndedQuestion'),
  [messageType.SIMPLE_TEXT_MESSAGE]: i18n.t('questionnaire.simpleTextMessage'),
  [messageType.LOGIC_CONTROLLER]: i18n.t('questionnaire.logicController'),
  [messageType.LOCATION_MESSAGE]: i18n.t('questionnaire.locationMessage'),
  [messageType.LOCATION_PART_OF_COUNTRY]: i18n.t('questionnaire.locationPartOfCountry'),
  [messageType.LOCATION_COUNTY]: i18n.t('questionnaire.locationCounty'),
  [messageType.LOCATION_CITY]: i18n.t('questionnaire.locationCity'),
  [messageType.SHOW_POSITION_MESSAGE]: i18n.t('questionnaire.dynamicPositionChooser'),
  [messageType.POSITION_APPLY_CONTROLLER]: i18n.t('questionnaire.positionApplyController'),
  [messageType.POSITION_PREFILTER_CRITERIAS_MESSAGE]: i18n.t(
    'questionnaire.positionPrefilterCriteriasMessage',
  ),
  [messageType.EXPORT_APPLICATION_TO_ATS_CONTROLLER]: i18n.t(
    'questionnaire.exportApplicationToAts',
  ),
  [messageType.DOCUMENT_UPLOAD_CONTROLLER]: i18n.t('questionnaire.documentUploader'),
  [messageType.DOCUMENT_VIEWER_MESSAGE]: i18n.t('questionnaire.documentView'),
  [messageType.START_CONTROLLER]: i18n.t('questionnaire.startMessage'),
  [messageType.ACTION_CONTROLLER]: i18n.t('questionnaire.actionController'),
  [messageType.ANALYTICS_CONTROLLER]: i18n.t('questionnaire.analyticsController'),
  [messageType.CONTEXT_CONTROLLER]: i18n.t('questionnaire.contextController'),
  [messageType.DYNAMIC_LABEL_MESSAGE]: i18n.t('questionnaire.dynamicLabelMessage'),
  [messageType.IMAGE_MESSAGE]: i18n.t('questionnaire.imageMessage'),
  [messageType.SHOW_INTERVIEW_EVENTS_MESSAGE]: i18n.t('questionnaire.showInterviewEventMessage'),
  [messageType.ATS_GATEWAY_CONTROLLER]: i18n.t('questionnaire.atsGatewayController'),
};

export const messageTypeIcon = {
  [messageType.CLOSED_ENDED_ANSWERS]: 'fas fa-question',
  [messageType.OPEN_ENDED_ANSWERS]: 'fas fa-comment-dots',
  [messageType.SIMPLE_TEXT_MESSAGE]: 'fas fa-paper-plane',
  [messageType.LOGIC_CONTROLLER]: 'fas fa-random',
  [messageType.LOCATION_MESSAGE]: 'fas fa-map-marked-alt',
  [messageType.LOCATION_PART_OF_COUNTRY]: 'fas fa-map-marked-alt',
  [messageType.LOCATION_COUNTY]: 'fas fa-map-marked-alt',
  [messageType.LOCATION_CITY]: 'fas fa-map-marked-alt',
  [messageType.SHOW_POSITION_MESSAGE]: 'fas fa-list',
  [messageType.POSITION_APPLY_CONTROLLER]: 'fas fa-user-plus',
  [messageType.POSITION_PREFILTER_CRITERIAS_MESSAGE]: 'fas fa-sort-numeric-down',
  [messageType.EXPORT_APPLICATION_TO_ATS_CONTROLLER]: 'fas fa-file-export',
  [messageType.DOCUMENT_UPLOAD_CONTROLLER]: 'fas fa-cloud-upload-alt',
  [messageType.DOCUMENT_VIEWER_MESSAGE]: 'fas fa-file-alt',
  [messageType.START_CONTROLLER]: 'fas fa-play-circle',
  [messageType.ACTION_CONTROLLER]: 'fas fa-random',
  [messageType.ANALYTICS_CONTROLLER]: 'fas fa-random',
  [messageType.CONTEXT_CONTROLLER]: 'fas fa-random',
  [messageType.DYNAMIC_LABEL_MESSAGE]: 'fas fa-tags',
  [messageType.IMAGE_MESSAGE]: 'fas fa-image',
  [messageType.SHOW_INTERVIEW_EVENTS_MESSAGE]: 'fas fa-handshake',
  [messageType.ATS_GATEWAY_CONTROLLER]: 'fas fa-exchange-alt',
};

export const answerActionType = {
  NONE: 'NONE',
  LABEL_APPLICANT: 'LABEL_APPLICANT',
  ATTRIBUTE_APPLICANT: 'ATTRIBUTE_APPLICANT',
  ATTRIBUTE_APPLICANT_POSITION: 'ATTRIBUTE_APPLICANT_POSITION',
  INVISIBLE_ANSWER: 'INVISIBLE_ANSWER',
  ONE_CITY_AVAILABLE: 'ONE_CITY_AVAILABLE',
  NO_POSITION_FOUND: 'NO_POSITION_FOUND',
  ONE_LABEL_AVAILABLE: 'ONE_LABEL_AVAILABLE',
  ACTIVE_INTERVIEW_EVENT_EXISTS: 'ACTIVE_INTERVIEW_EVENT_EXISTS',
  NO_INTERVIEW_EVENT_FOUND: 'NO_INTERVIEW_EVENT_FOUND',
  VALIDATION_FAILED: 'VALIDATION_FAILED',
};

export const answerActionTypeToDisplay = {
  [answerActionType.NONE]: i18n.t('common.none'),
  [answerActionType.LABEL_APPLICANT]: i18n.t('questionnaire.addLabel'),
  [answerActionType.ATTRIBUTE_APPLICANT]: i18n.t('questionnaire.addAttribute'),
  [answerActionType.ATTRIBUTE_APPLICANT_POSITION]: i18n.t('questionnaire.addPositionAttribute'),
  [answerActionType.INVISIBLE_ANSWER]: i18n.t('questionnaire.addInvisibleAnswer'),
  [answerActionType.ONE_CITY_AVAILABLE]: i18n.t('questionnaire.addOneCityAvailable'),
  [answerActionType.NO_POSITION_FOUND]: i18n.t('questionnaire.addNoPositionFound'),
  [answerActionType.ONE_LABEL_AVAILABLE]: i18n.t('questionnaire.addOneLabelAvailable'),
  [answerActionType.ACTIVE_INTERVIEW_EVENT_EXISTS]: i18n.t(
    'questionnaire.addActiveInterviewEventExist',
  ),
  [answerActionType.NO_INTERVIEW_EVENT_FOUND]: i18n.t('questionnaire.addNoInterviewEventFound'),
  [answerActionType.VALIDATION_FAILED]: i18n.t('questionnaire.validationFailed'),
};

export const answerActionObjects: SelectModel[] = [
  { label: i18n.t('common.none'), value: 'NONE' },
  { label: i18n.t('questionnaire.addLabel'), value: 'LABEL_APPLICANT' },
  { label: i18n.t('questionnaire.addAttribute'), value: 'ATTRIBUTE_APPLICANT' },
  { label: i18n.t('questionnaire.addPositionAttribute'), value: 'ATTRIBUTE_APPLICANT_POSITION' },
  { label: i18n.t('questionnaire.addInvisibleAnswer'), value: 'INVISIBLE_ANSWER' },
  { label: i18n.t('questionnaire.addOneCityAvailable'), value: 'ONE_CITY_AVAILABLE' },
  { label: i18n.t('questionnaire.addNoPositionFound'), value: 'NO_POSITION_FOUND' },
  { label: i18n.t('questionnaire.addOneLabelAvailable'), value: 'ONE_LABEL_AVAILABLE' },
  {
    label: i18n.t('questionnaire.addActiveInterviewEventExist'),
    value: 'ACTIVE_INTERVIEW_EVENT_EXISTS',
  },
  { label: i18n.t('questionnaire.addNoInterviewEventFound'), value: 'NO_INTERVIEW_EVENT_FOUND' },
  { label: i18n.t('questionnaire.validationFailed'), value: 'VALIDATION_FAILED' },
];

export const defaultSimpleMessageAnswer = {
  text: 'NOT_RENDERED',
  answerActionType: QuestionnaireAnswerActionType.NONE,
};

export const defaultDefaultAnswer = {
  text: 'DEFAULT',
  answerActionType: 'DEFAULT',
  answerActionRootData: {
    type: 'none',
  },
};

export const defaultInterviewEventAnswer = {
  text: 'DEFAULT',
  answerActionType: 'DEFAULT',
  answerActionRootData: {
    type: 'none',
  },
};

export const defaultPositionPrefilterCriteriasMessageAnswer = {
  text: 'DEFAULT',
  answerActionType: 'DEFAULT',
  answerActionRootData: {
    type: 'none',
  },
};

export const defaultPositionPrefilterCriteriasEnabledKoExitPointAnswer = {
  text: 'ENABLED_KO_EXITPOINT',
  answerActionType: 'ENABLED_KO_EXITPOINT',
  answerActionRootData: {
    type: 'none',
  },
};

export const defaultOpenEndedQuestionAnswerAttribute = {
  text: 'FREE_TEXT_INPUT',
  answerActionType: QuestionnaireAnswerActionType.ATTRIBUTE_APPLICANT,
  answerActionRootData: {
    type: 'attribute-applicant',
    attributeRequired: true,
    attributeMultiplicable: false,
  },
};

export const defaultOpenEndedQuestionAnswerPositionAttribute = {
  text: 'FREE_TEXT_INPUT',
  answerActionType: QuestionnaireAnswerActionType.ATTRIBUTE_APPLICANT_POSITION,
  answerActionRootData: {
    type: 'position-attribute-applicant',
    attributeKey: '',
  },
};

export const defaultOpenEndedQuestionAnswerNone = {
  text: 'FREE_TEXT_INPUT',
  answerActionType: QuestionnaireAnswerActionType.NONE,
  answerActionRootData: {
    type: 'none',
  },
};

export const defaultLogicControllerAnswer = [
  {
    text: 'TRUE',
    answerActionType: QuestionnaireAnswerActionType.NONE,
    answerActionRootData: null,
  },
  {
    text: 'FALSE',
    answerActionType: QuestionnaireAnswerActionType.NONE,
    answerActionRootData: null,
  },
];

export const defaultLocationMessageFormInitValues = {
  locationMessage: {
    chooseCountryPart: '',
    chooseCounty: '',
    chooseCity: '',
    cityChooseAllText: '',
    cityChooseNoneText: '',
    showAll: true,
    showSize: 8,
    nextPageButton: '',
  },
};

export const defaultExportApplicationToAtsAnswer = {
  text: 'NOT_RENDERED',
  answerActionType: 'DEFAULT',
  answerActionRootData: {
    type: 'none',
  },
};

export const defaultStartAnswer = {
  text: 'START',
  answerActionType: 'DEFAULT',
  answerActionRootData: {
    type: 'none',
  },
};
