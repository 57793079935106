import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap';

import { positionSidebarTypes } from '../../../../../../shared/constants/positions.constants';

import { setPositionSidebarType } from '../../../../../../redux/actions/positions.action';

interface Props {
  isSubmitting?: boolean;
  showSubmit?: boolean;
  showBack?: boolean;
  sidebarType: string;
  prevSidebarType: string;
  setPositionSidebarType: Function;
}

const SidebarActionButtons: React.FC<Props> = ({
  isSubmitting,
  showSubmit = true,
  showBack = false,
  sidebarType,
  prevSidebarType,
  setPositionSidebarType,
}) => {
  const { t } = useTranslation();

  const getSubmitButtonText = () => {
    switch (sidebarType) {
      case positionSidebarTypes.CREATE_PREFILTER_CRITERIA:
        return 'common.create';
      case positionSidebarTypes.EDIT_PREFILTER_CRITERIA:
        return 'common.save';
      default:
        return 'common.submit';
    }
  };

  const handleBack = () => {
    setPositionSidebarType(prevSidebarType);
  };

  return (
    <Row className="mt-4">
      <Col className="d-flex justify-content-end">
        {showSubmit && (
          <Button type="submit" color="primary" className="ml-2" disabled={isSubmitting}>
            {t(getSubmitButtonText())}
          </Button>
        )}

        {showBack && (
          <Button color="primary" className="ml-2" disabled={isSubmitting} onClick={handleBack}>
            {t('common.back')}
          </Button>
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  sidebarType: state.positions.data.sidebarType,
  prevSidebarType: state.positions.data.prevSidebarType,
});

const mapDispatchToProps = {
  setPositionSidebarType,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarActionButtons);
