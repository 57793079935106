import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import Pagination from 'react-js-pagination';
import { uniqueId } from 'lodash';

import { TalentPoolApplicantModel } from 'src/shared/models/talent-pool.model';
import { CustomFieldModel } from 'src/shared/models/custom-fields.model';

import { getAttributeValueByKey } from 'src/shared/utils/misc.util';

import TalentPoolApplicantModal from './TalentPoolApplicantModal';

import styles from '../TalentPool.module.scss';
import { configurationConstants } from 'src/shared/constants/configuration.constants';
import { getListFieldsByConfiguration } from 'src/shared/utils/configuration.util';
import { ApplicationModel } from 'src/redux/actions/applications.action';

interface Props {
  application: ApplicationModel;
  applicants: TalentPoolApplicantModel[];
  totalApplicants: number;
  itemsPerPage: number;
  activePage: number;
  customFields: CustomFieldModel[];
  pageChange: (index: number) => void;
}

const TalentPoolTable: React.FC<Props> = ({
  application,
  applicants,
  totalApplicants,
  itemsPerPage,
  activePage,
  pageChange,
}) => {
  const { t } = useTranslation();
  const [selectedApplicantId, setSelectedApplicantId] = useState<number | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const configuredFields = getListFieldsByConfiguration(
    application,
    configurationConstants.recruiterTalentPoolList,
    t,
  );

  const handleApplicantSelect = (applicant: TalentPoolApplicantModel) => {
    setSelectedApplicantId(applicant.id);
    setIsModalOpen(true);
  };

  const renderData = (element: { id: number }) => {
    return (
      <tr key={uniqueId(`${element.id}`)} onClick={() => handleApplicantSelect(element as any)}>
        {configuredFields.map(cf => (
          <td key={cf.key}>{cf.render(element)}</td>
        ))}
      </tr>
    );
  };

  const renderHeader = () => {
    return (
      <tr>
        {configuredFields.map(cf => (
          <th key={cf.key}>{cf.label}</th>
        ))}
      </tr>
    );
  };

  return (
    <Fragment>
      {configuredFields.length > 0 ? (
        <Fragment>
          <Table className={styles.talentPoolTable} striped hover size="sm">
            <thead>{renderHeader()}</thead>
            <tbody>{applicants.map(renderData)}</tbody>
          </Table>
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={totalApplicants}
            pageRangeDisplayed={5}
            onChange={(index: number) => pageChange(index)}
          />
        </Fragment>
      ) : (
        <p className="text-center">{t('common.configurationError')}</p>
      )}
      <TalentPoolApplicantModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        selectedApplicantId={selectedApplicantId}
        getAttributeValueByKey={getAttributeValueByKey}
      />
    </Fragment>
  );
};

export default TalentPoolTable;
