import React from 'react';
import { withRouter } from 'react-router-dom';

import AccountSideBar from './components/AccountSideBar';
import AccountSettings from './components/AccountSettings';

import './Account.scss';

const Account: React.FC = () => {
  return (
    <div className="container">
      <div className="row animated fadeIn">
        <div className="col-md-5 col-xl-4">
          <AccountSideBar />
        </div>
        <div className="col-md-7 col-xl-8">
          <AccountSettings />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Account);
