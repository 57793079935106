import { get, post, del, patch } from '../../shared/services/http.service';
import {
  QueryParams,
  ElementsQueryParams,
  ListQueryParams,
} from '../../shared/models/query-params.model';
import { ListModel } from '../reducers/lists.reducer';
import { ListElementModel } from '../reducers/list-elements.reducer';

export const getListsService = async (
  appId: string,
  queryParams?: QueryParams | ListQueryParams,
) => {
  return get(`/applications/${appId}/simplelist/simple-lists`, {
    params: queryParams,
  });
};

export const createListService = async (appId: string, list: ListModel) => {
  return post(`/applications/${appId}/simplelist/simple-lists`, {
    data: list,
  });
};

export const getListService = async (appId: string, listId: number) => {
  return get(`/applications/${appId}/simplelist/simple-lists/${listId}`);
};

export const deleteListService = async (appId: string, listId: number) => {
  return del(`/applications/${appId}/simplelist/simple-lists/${listId}`);
};

export const updateListService = async (appId: string, listId: number, list: ListModel) => {
  return patch(`/applications/${appId}/simplelist/simple-lists/${listId}`, {
    data: list,
  });
};

export const getListElementsService = async (
  appId: string,
  listId: number,
  queryParams: ElementsQueryParams,
) => {
  return get(`/applications/${appId}/simplelist/simple-lists/${listId}/simple-elements`, {
    params: queryParams,
  });
};

export const createListElementService = async (
  appId: string,
  listId: number,
  listElement: ListElementModel,
) => {
  return post(`/applications/${appId}/simplelist/simple-lists/${listId}/simple-elements`, {
    data: listElement,
  });
};

export const getListElementService = async (
  appId: string,
  listId: number,
  listElementId: number,
) => {
  return get(
    `/applications/${appId}/simplelist/simple-lists/${listId}/simple-elements/${listElementId}`,
  );
};

export const deleteListElementService = async (
  appId: string,
  listId: number,
  listElementId: number,
) => {
  return del(
    `/applications/${appId}/simplelist/simple-lists/${listId}/simple-elements/${listElementId}`,
  );
};

export const updateListElementService = async (
  appId: string,
  listId: number,
  listElementId: number,
  listElement: ListElementModel,
) => {
  return patch(
    `/applications/${appId}/simplelist/simple-lists/${listId}/simple-elements/${listElementId}`,
    {
      data: listElement,
    },
  );
};

export const updateListElementsOrderService = async (
  appId: string,
  listId: number,
  listElementsOrder: Array<Number>,
) => {
  return post(`/applications/${appId}/simplelist/simple-lists/${listId}/simple-elements/sort`, {
    data: listElementsOrder,
  });
};
