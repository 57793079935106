import moment from 'moment';

export interface DateRangeQueryParams {
  from?: string | moment.Moment;
  to?: string | moment.Moment;
}

export interface GeneralQueryParams {
  size: number;
  page: number;
  to?: string | moment.Moment;
  from?: string | moment.Moment;
  sort?: string;
}

export interface PageQueryParams {
  page: number;
  size: number;
}

export interface QueryParams extends PageQueryParams {
  from?: string | moment.Moment | null;
  to?: string | moment.Moment | null;
  limit?: number;
  waiting?: boolean;
  platforms?: string;
  status?: string;
  sort?: string;
  type?: string;
}

export interface ConversationFlowQueryParams extends PageQueryParams {
  [x: string]: any;
  searchKey?: string;
  searchValue?: string;
}

export interface EmployeePageQueryParams extends PageQueryParams {
  searchKey?: string;
  searchAttributeValue?: string;
}

export interface ConversationsQueryParams {
  from: moment.Moment | string;
  to: moment.Moment | string;
  page: number;
  prevPage: number | undefined;
  size: number;
  limit: number;
  waiting: boolean;
  platforms: string;
}

export interface ElementsQueryParams {
  direction: SQLDirection;
  sort: string;
}

export interface ListQueryParams extends QueryParams {
  direction: SQLDirection;
  sort: string;
}

export interface UsageStatisticsQueryParams {
  from: moment.Moment | string;
  to: moment.Moment | string;
  timezoneOffset: number;
  groupByValues?: string;
}

export type ApplicationEfficiencyReportType = 'percentage' | 'mean' | 'applications';

export interface ApplicationEfficiencyReportQueryParams {
  from: string;
  to: string;
  timezoneOffset: number;
  type: ApplicationEfficiencyReportType;
  positionId: number | null;
}

export interface ApplicationEfficiencyReportExport {
  from: string;
  to: string;
}

export interface UsageStatisticsDateParams {
  startDate: any;
  endDate: any;
}

export interface AuditLogQueryParams extends PageQueryParams {
  from: string | moment.Moment;
  to: string | moment.Moment;
  type?: string | undefined;
  userId?: number | undefined;
}

export interface AuditLogExportQueryParams {
  from: string;
  to: string;
  type: string | null;
  userId: number | null;
}

export interface IntentStatisticsQueryParams extends PageQueryParams {
  from: string | moment.Moment;
  to: string | moment.Moment;
  noneCount?: boolean;
  filterByValue?: string;
}

export interface ApplicantsQueryParams extends PageQueryParams {
  labels?: string;
  positions?: string;
  sort?: string;
  attributes?: string;
}

export interface CustomFieldsQueryParams {
  namespace: string;
}

export interface PositionsQueryParams extends PageQueryParams {
  labels?: number[];
  search?: string;
}

export enum SQLDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SubscribingListQueryParams extends GeneralQueryParams {
  filter?: string;
}

export interface BatchMessagesQueryParams extends PageQueryParams {
  from?: string | moment.Moment;
  to?: string | moment.Moment;
  platform?: string;
  name?: string;
}