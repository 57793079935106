import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import {
  positionStatus,
  positionStatusToDisplay,
  positionStatusColor,
} from '../../../shared/constants/positions.constants';
import { PositionStatus } from '../../../shared/models/positions.model';

import { updatePositionStatus } from '../../../redux/actions/positions.action';
import { hasPermission } from '../../../shared/services/permissions.service';

const DROPDOWN_WIDTH = 150;

interface Props {
  positionId: number;
  status: PositionStatus;
  disabled?: boolean;
  handleOnItemClick?: Function;
  updatePositionStatus: Function;
}

const PositionStatusDropdown: React.FC<Props> = ({
  positionId,
  status,
  disabled: propsDisabled = false,
  handleOnItemClick: propsHandleOnItemClick,
  updatePositionStatus,
}) => {
  const { appId } = useParams<{ appId: string }>();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdownDisabled = !hasPermission(['position_status_update']) || propsDisabled;

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleOnItemClick = async (selectedStatus: PositionStatus) => {
    if (status === selectedStatus) return;

    if (propsHandleOnItemClick) {
      propsHandleOnItemClick(selectedStatus);
      return;
    }

    await updatePositionStatus(appId, positionId, selectedStatus);
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        caret
        disabled={dropdownDisabled}
        style={{ width: DROPDOWN_WIDTH, padding: '2.5px 12px' }}
        color={positionStatusColor[status]}
      >
        {positionStatusToDisplay[status]}
      </DropdownToggle>
      <DropdownMenu style={{ minWidth: DROPDOWN_WIDTH, maxWidth: DROPDOWN_WIDTH }}>
        {Object.keys(positionStatus).map(status => (
          <DropdownItem
            key={status}
            style={{ textAlign: 'center' }}
            onClick={() => handleOnItemClick(status as PositionStatus)}
          >
            {positionStatusToDisplay[status]}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

const mapDispatchToProps = {
  updatePositionStatus,
};

export default connect(null, mapDispatchToProps)(PositionStatusDropdown);
